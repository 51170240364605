import React from "react";
import EssentialStyle from "../../../../style/EssentialStyle";
import { Collapse } from "react-bootstrap";
import CustomTooltip from "../../../tools/CustomTooltip";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHourglassStart, faHourglass, faFileCircleCheck, faPaperclip, faComment, faEdit, faTrash, faBars, faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import Colors from "../../../../constants/Colors";
import TooManyAvatars from "../../../tools/TooManyAvatars";
import TextEditor from "../../../tools/TextEditor/TextEditor";
import Tabs from "../../../tools/Tabs";
import AtaReuniaoTopicoNotas from "./AtaReuniaoTopicoComponents/AtaReuniaoTopicoNotas";
import AtaReuniaoTopicoTarefas from "./AtaReuniaoTopicoComponents/AtaReuniaoTopicoTarefas";
import AtaReuniaoTopicoPlanos from "./AtaReuniaoTopicoComponents/AtaReuniaoTopicoPlanos";
import AtaReuniaoTopicoDeliberacao from "./AtaReuniaoTopicoComponents/AtaReuniaoTopicoDeliberacao";
import Sig from "../../../../api/Sig";
import DataHelper from "../../../../helper/DataHelper";
import DefaultButton from "../../../tools/DefaultButton";
import {toast} from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
import CustomConfirm from "../../../tools/CustomConfirm";
import AtaReuniaoAnexosList from "../AtaReuniaoAnexos/AtaReuniaoAnexosList";
export default class AtaReuniaoTopicoCard extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            expanded: false,
            activeTab: 'deliberacao',
            hoverParticipantes: false,
        }
        this.timeoutTitulo = null;
        this.timeoutDescricao = null;
        this.timeoutDeliberacao = null;
    }

    componentDidUpdate(prevProps) {
        if(this.props.editOrder != prevProps.editOrder && this.props.editOrder == true) {
            this.setState({ expanded: false });
        }
    }

    updateTitle = async () => {
        if (!this.props.blockEdit) {
            try {
                await Sig.request('POST', `/ataReuniao${this.props.hash ? "Externa" : ""}/updateNomeTopico`, { 
                    id: this.props.topico.id,
                    hash: this.props.hash, 
                    nome: this.props.topico.nome 
                });
            } catch (error) {
                if(!this.timeoutTitulo)
                    this.timeoutTitulo = setTimeout(this.updateTitle, 500);
            }
        }
    }

    updateDescricao = async () => {
        if (!this.props.blockEdit) {
            try {
                await Sig.request('POST', `/ataReuniao${this.props.hash ? "Externa" : ""}/updateDescricaoTopico`, { 
                    id: this.props.topico.id, 
                    hash: this.props.hash,
                    descricao: this.props.topico.descricao 
                });
            } catch (error) {
                if(!this.timeoutDescricao)
                    this.timeoutDescricao = setTimeout(this.updateDescricao, 500);
            }
        }
    }

    changeTitleHandler = (value) => {
        if (this.props.topico.nome == value) return;
        
        if (!this.props.blockEdit || this.props.changeNomeTopicoCallback) {
            this.props.changeNomeTopicoCallback(this.props.topico.id, value);
            if (this.timeoutTitulo) clearTimeout(this.timeoutTitulo);
            this.timeoutTitulo = setTimeout(this.updateTitle, 500);
        }
    }

    changeDescricaoHandler = (value) => {
        if(this.props.topico.descricao == value) return;

        if (!this.props.blockEdit || this.props.changeDescricaoTopicoCallback) {
            this.props.changeDescricaoTopicoCallback(this.props.topico.id, value);
            if(this.timeoutDescricao) clearTimeout(this.timeoutDescricao);
            this.timeoutDescricao = setTimeout(this.updateDescricao, 500);
        }
        
    }

    addDeliberacao = async () => {
        if (!this.props.blockEdit || this.props.changeDeliberacaoTopicoCallback) {
            var response = await Sig.request('POST', `ataReuniao${this.props.hash ? "Externa" : ""}/addDeliberacaoTopico`, { 
                id: this.props.topico.id,
                hash: this.props.hash,
            });
            if(response.deliberacao) {
                this.props.changeDeliberacaoTopicoCallback(this.props.topico.id, response.deliberacao);
            }
        }
    } 

    addPlano = async () => {
        if (!this.props.blockEdit || this.props.changePlanosTopicoCallback) {
            var response = await Sig.request('GET', `ataReuniao${this.props.hash ? "Externa" : ""}/getPlanosTopico`, { 
                id_ata_reuniao_topico: this.props.topico.id,
                hash: this.props.hash, 
            });
            if(response.planos) {
                this.props.changePlanosTopicoCallback(this.props.topico.id, response.planos);
            }
        }
    }  

    updateDeliberacaoText = async () => {
        if (!this.props.blockEdit) {
            try {
                await Sig.request('POST', `/ataReuniao${this.props.hash ? "Externa" : ""}/updateDeliberacaoTopicoText`, { 
                    id: this.props.topico.deliberacao.id, 
                    hash: this.props.hash,
                    descricao: this.props.topico.deliberacao.descricao 
                });
            } catch (error) {
                if(!this.timeoutDeliberacao)
                    this.timeoutDeliberacao = setTimeout(this.updateDeliberacaoText, 500);
            }
        }
    }

    changeDeliberacaoText = async (value) => {
        if(this.props.topico.deliberacao?.descricao == value) return;

        if (!this.props.blockEdit || this.props.changeDeliberacaoTopicoCallback) {
            var deliberacao = this.props.topico.deliberacao;
            deliberacao.descricao = value;
            deliberacao.status = DataHelper.removeHtmlTags(value) == "" || DataHelper.removeHtmlTags(value) == " " ? 0 : 1;
            this.props.changeDeliberacaoTopicoCallback(this.props.topico.id, deliberacao);
            if(this.timeoutDeliberacao) clearTimeout(this.timeoutDeliberacao);
            this.timeoutDeliberacao = setTimeout(this.updateDeliberacaoText, 500);
        }
    }

    removeDeliberacao = async () => {
        if (!this.props.blockEdit || this.props.changeDeliberacaoTopicoCallback) {
            try {
                var response = await Sig.request('POST', `ataReuniao${this.props.hash ? "Externa" : ""}/removeDeliberacaoTopico`, { 
                    id: this.props.topico.id,
                    hash: this.props.hash, 
                });
                if(response.status == 200) {
                    this.props.changeDeliberacaoTopicoCallback(this.props.topico.id, null);
                } else {
                    throw new Error("Erro ao remover Deliberação");
                }
            } catch (error) {
                toast.error(error.message);
            }
        }
    }

    changeDeliberacaoStatus = async (status) => {
        if (!this.props.blockEdit || this.props.changeDeliberacaoTopicoCallback) {
            var response = await Sig.request('POST', `ataReuniao${this.props.hash ? "Externa" : ""}/changeDeliberacaoTopicoStatus`, { 
                id: this.props.topico.deliberacao.id,
                hash: this.props.hash, 
                status: status 
            });
            if(response.deliberacao) {
                this.props.changeDeliberacaoTopicoCallback(this.props.topico.id, response.deliberacao);
            }
        }
    }

    deleteTopico = async () => {
        if (!this.props.blockEdit || this.props.deleteTopicoCallback) {
            try {
                var response = await Sig.request('POST', `ataReuniao${this.props.hash ? "Externa" : ""}/deleteTopicoAtaReuniao`, { 
                    id: this.props.topico.id,
                    hash: this.props.hash,
                });
    
                if(response.status == 200) {
                    this.props.deleteTopicoCallback(this.props.topico.id);
                }
    
            } catch (error) {
                toast.error(error.message);
            }
        }
    }

    changeNotas = (notas) => {
        if (!this.props.blockEdit || this.props.changeNotasCallback) {
            this.props.changeNotasCallback(this.props.topico.id, notas);
        }
        
    }

    renderTitle() {

        return (
            <div style={{ ...EssentialStyle.rowFlexStart, paddingLeft: 3, paddingBottom: 0, flex: 1, width: "calc(100% - 45px)", gap: 10}}>
                {this.props.topico?.deliberacao &&
                    <CustomTooltip tooltip={this.props.topico.deliberacao.status == 0 ? "Aguardando Deliberação" : this.props.topico.deliberacao.status == 1 ? "Deliberação em Andamento" : "Deliberação Finalizada"} placement="right">
                        <FontAwesomeIcon icon={this.props.topico.deliberacao.status == 0 ? faHourglassStart : this.props.topico.deliberacao.status == 1 ? faHourglass : faFileCircleCheck} style={{ marginLeft: 10, color: this.props.topico.deliberacao.status == 0 ? Colors.dark : this.props.topico.deliberacao.status == 1 ? Colors.danger : Colors.success }} />
                    </CustomTooltip>
                }
                <div 
                    style={{
                        ...EssentialStyle.rowFlexStart,
                        maxWidth: "auto",
                        flex: 1
                    }}
                    onClick={(e) => { !this.props.blockEdit && e.stopPropagation()}}
                >
                    <span style={{ fontSize: 18, fontWeight: 700, color: Colors.dark, marginRight: 5, whiteSpace: "nowrap", marginBottom: 1 }}>
                        {(parseInt(this.props.topico?.drag_index) + 1) + ". "} 
                    </span>
                    <TextEditor
                        plainTextMode
                        noBorder
                        noMargin
                        defaultValue={this.props.topico?.nome}
                        disabled={(this.props.topico?.canEdit ?? true) && !this.props.blockEdit ? false : true}
                        placeholder="Nome do tópico..."
                        editorClassName={'plain-text-editor-quill ata-topico-item-title'}
                        onChange={this.changeTitleHandler}
                    /> 
                </div>
            </div>
        );
    }

    renderLeft = () => {
        return (
            <div style={{ ...EssentialStyle.rowFlexStart, width: this.props.isSmallScreen ? "100%" : "unset", overflow: "hidden", flex: 1 }}>
                <div style={{ ...EssentialStyle.rowFlexStart, flex: 1, gap: 5, justifyContent: "center"}}>
                    {this.props.editOrder && <FontAwesomeIcon icon={faBars} style={{ padding: "0px 5px", width: 20, height: 20, color: Colors.homePage.darkGrey, cursor: "grab", }} />}
                    {this.renderTitle()}
                </div>
            </div>
        );
    }

    renderRight = () => {
        let style = { height: 50, gap: 10 };

        if (this.props.isSmallScreen) {
            style = { ...EssentialStyle.rowSpaceBetween, alignItems: 'center', marginTop: 20, ...style, width: '100%' };
        } else {
            style = { ...EssentialStyle.rowSpaceBetween, alignItems: 'center', ...style };
        }

        return (
            <div style={style}>
                {this.renderAnexosCount()}
                {this.renderNotasCount()}
                {this.renderParticipantes()}
                {this.state.expanded && this.props.topico.canEdit && !this.props.blockEdit && 
                    <DefaultButton
                        leftIcon={<FontAwesomeIcon icon={faTrash} />}
                        style={{ color: Colors.dark, backgroundColor: Colors.secondaryButton, border: "unset", borderRadius: "50%", outline: "unset", padding: 0, width: 35, height: 35, marginLeft: 10, boxShadow: "unset" }}    
                        onClick={(e) => {
                            e.stopPropagation();
                            this.renderDeleteTopicoModal();
                        }}
                    />
                }
            </div>
        );
    }

    renderDeleteTopicoModal = () => {
        confirmAlert({
            customUI: ({ onClose }) => (
                <CustomConfirm
                    title={'Remover este tópico?'}
                    message={<p style={{ marginTop: 10, marginBottom: 10 }}>Esta ação é irreversível.</p>}
                    buttons={[
                        {
                            label: 'Remover',
                            color: Colors.error,
                            textColor: Colors.light,
                            onClick: async () => { 
                                this.deleteTopico();
                                onClose(); 
                            }
                        },
                        {
                            label: 'Cancelar',
                            onClick: () => { onClose(); }
                        },
                    ]}
                />
            )
        });
    }


    renderAnexosCount = () => {

        return (
            <div style={{ ...EssentialStyle.rowFlexStart, marginLeft: 2 }}>
                <div style={{ ...EssentialStyle.rowFlexCenter, width: 35, height: 35, borderRadius: "50%", backgroundColor: Colors.tag, position: "relative" }}>
                    <FontAwesomeIcon icon={faPaperclip} style={{ color: Colors.dark, fontSize: 15 }} />
                    <span style={{position: "absolute", right: 0, top: 0, fontSize: 12, fontWeight: 500}}>
                        {this.props.topico?.anexos?.length || 0}
                    </span>
                </div>
            </div>
        );
    }

    renderNotasCount = () => {

        return (
            <div style={{ ...EssentialStyle.rowFlexStart, marginLeft: 2 }}>
                <div style={{ ...EssentialStyle.rowFlexCenter, width: 35, height: 35, borderRadius: "50%", backgroundColor: Colors.tag, position: "relative" }}>
                <FontAwesomeIcon icon={faComment} style={{ color: Colors.dark, fontSize: 15 }} />
                    <span style={{position: "absolute", right: 0, top: 0, fontSize: 12, fontWeight: 500}}>
                        {this.props.topico?.notas?.length || 0}
                    </span>
                </div>
            </div>
        );
    }

    renderParticipantes = () => {
        let participantes = this.props.topico?.participantesTopico || [];
        
        if(participantes.length == 0) return null;

        return (
            <div 
                style={{ ...EssentialStyle.rowFlexStart, paddingLeft: 20, paddingRight: this.state.hoverParticipantes || !this.state.expanded || !this.props.topico.canEdit || this.props.blockEdit ? 5 : 30 }}
                onMouseEnter={() => this.setState({ hoverParticipantes: true })}
                onMouseLeave={() => this.setState({ hoverParticipantes: false })}
            >
                <TooManyAvatars participantsTotal={participantes.length} participantsVisiveis={this.props.isSmallScreen && !this.state.expanded ? 2 : 4} arrayParticipants={participantes} onlyMail/>
                {this.state.hoverParticipantes && this.state.expanded && this.props.topico.canEdit && !this.props.blockEdit &&
                    <DefaultButton 
                        leftIcon={<FontAwesomeIcon icon={faEdit} />}
                        style={{ color: Colors.dark, backgroundColor: Colors.secondaryButton, border: "unset", borderRadius: "50%", outline: "unset", padding: 0, width: 35, height: 35, marginLeft: -10, zIndex: 1, boxShadow: "unset" }}    
                        onClick={() => this.props.openModalParticipantesTopico(this.props.topico?.id, this.props.topico?.canEdit)}
                    />
                }
            </div>
        );
    }

    renderActiveTab = () => {
        switch (this.state.activeTab) {
            case 'notas':
                return <AtaReuniaoTopicoNotas 
                    idTopico={this.props.topico.id}
                    changeNotasCallback={this.changeNotas}
                    blockEdit={false}
                    hash={this.props.hash}
                />
            case 'tarefas':
                return <AtaReuniaoTopicoTarefas 
                    tarefas={this.props.topico.tarefas} 
                    canEdit={this.props.topico.canEdit}
                    participantes={this.props.topico.participantesTopico}
                    changeTarefaCallback={this.props.changeTarefaCallback}
                    idTopico={this.props.topico.id}
                    detailsModalTarefaCallback={this.props.detailsModalTarefaCallback}
                    hash={this.props.hash}
                />
            case 'planos':
                return <AtaReuniaoTopicoPlanos 
                    planos={this.props.topico.planos}
                    idTopico={this.props.topico.id}
                    idAtaReuniao={this.props.idAtaReuniao}
                    addPlanoCallback={this.addPlano}
                    blockEdit={false}
                    canEdit={this.props.topico.canEdit}
                    changePlanoCallback={this.props.changePlanoCallback}
                    hash={this.props.hash}
                />
            case 'deliberacao':
                return <AtaReuniaoTopicoDeliberacao 
                    canEdit={this.props.topico.canEdit && !this.props.blockEdit}
                    deliberacao={this.props.topico.deliberacao} 
                    addDeliberacaoCallback={this.addDeliberacao} 
                    changeDeliberacaoTextCallback={this.changeDeliberacaoText} 
                    changeDeliberacaoStatusCallback={this.changeDeliberacaoStatus}
                    removeDeliberacaoCallback={this.removeDeliberacao}
                />
            case 'anexos':
                return <AtaReuniaoAnexosList 
                    key={this.props.topico.id+"-topico"} 
                    type={'Topico'} 
                    nomeTopico={this.props.topico.nome}
                    anexos={this.props.topico.anexos} 
                    id={this.props.topico.id}
                    updateAnexoCallback={(anexo, deleteAnexo = false) => this.props.updateAnexoCallback(anexo, deleteAnexo, this.props.topico.id)}
                    canEdit={this.props.topico.canEdit && !this.props.blockEdit}
                    hash={this.props.hash}
                />
            default:
                break;
        }
    }

    renderCard = () => {

        var tabs = [
            { name: 'deliberacao', title: 'Deliberação' },
            { name: 'notas', title: 'Comentários' },
            { name: 'tarefas', title: 'Tarefas' },
            { name: 'planos', title: 'Planos de Ação' },
            { name: 'anexos', title: 'Anexos' },
        ]

        if (this.props.isSmallScreen) {
            var topicoStyle = {
                ...EssentialStyle.columnStart,
                width: "100%",
            };
        } else {
            var topicoStyle = {
                ...EssentialStyle.rowSpaceBetween,
                width: "100%",
                minHeight: 50,
            }
        }

        return (
            <div 
                className={"ata-topico-item"}
                draggable={this.props.editOrder}
                onDragStart={(e) => this.props.onDragStartItem(e, this.props.topico)}
                onDragEnd={(e) => this.props.onDragEndItem(e, this.props.topico)}
                onDragEnter={(e) => this.props.onDragEnterItem(e, this.props.topico)}
                onClick={() => {
                    if(this.props.editOrder) return;
                    this.setState({ expanded: !this.state.expanded })
                }}
                style={{ 
                    ...EssentialStyle.columnCenter,
                    ...EssentialStyle.card,
                    width: '100%', 
                    position: "relative", 
                    padding: "30px 10px",
                    paddingBottom: 0,
                    marginTop: this.props.draggingOverItem && this.props.draggingOverItem.id === this.props.topico.id && this.props.draggingOverItemTop ? 40 : 0,
                    marginBottom: this.props.draggingOverItem && this.props.draggingOverItem.id === this.props.topico.id && !this.props.draggingOverItemTop ? 40 : 0 
                }}
            >
                <div style={topicoStyle}>
                    {this.renderLeft()}
                    {this.renderRight()}
                </div>
                <Collapse in={this.state.expanded} style={{width: "100%", display: "flex", flexDirection: "column", gap: 5, transition: "all 0.5s ease"}} onClick={e => e.stopPropagation()}> 
                    <div>
                        { this.state.expanded &&
                        <>
                            <div style={{...EssentialStyle.rowFlexStart, width: "100%", marginTop: 10, gap: 10, alignItems: "flex-start"}}>
                                <div style={{...EssentialStyle.rowFlexStart, flex: 1}}>
                                    <TextEditor
                                        editorClassName={'bigger-placeholder-editor'}
                                        noBorder={true}
                                        disabled={(this.props.topico?.canEdit ?? true) && !this.props.blockEdit? false : true}
                                        defaultValue={this.props.topico?.descricao}
                                        placeholder="Considerações sobre o Tópico..."
                                        onChange={this.changeDescricaoHandler}
                                    /> 
                                </div>
                            </div>
                            <div style={{ ...EssentialStyle.columnStart, height: 'auto', width: '100%', borderRadius: 8, backgroundColor: Colors.white, marginBottom: 10}}>
                                <div style={{width: "auto"}}>
                                    <Tabs
                                        activeTab={this.state.activeTab}
                                        tabs={tabs}
                                        primaryBackgroundColor={Colors.white}
                                        primaryColor={Colors.dark}
                                        onTabChange={(tab) => this.setState({ activeTab: tab.name })}
                                        isSmallScreen={this.props.isSmallScreen} 
                                        style={{...EssentialStyle.rowFlexStart, width: "auto"}}
                                        tabStyle={{ 
                                            width: 'auto', 
                                            outline: 'unset', 
                                            borderColor: "unset", 
                                            boxShadow: 'unset', 
                                            borderBottomLeftRadius: 0, 
                                            borderBottomRightRadius: 0, 
                                            zIndex: 1, 
                                            border: "unset", 
                                            zIndex: 0,
                                            fontSize: this.state.isSmallScreen ? 12 : 16,
                                        }}
                                        activeTabStyle={{ 
                                            border: `1px solid ${Colors.homePage.lightGrey}`, 
                                            borderBottom: "unset",
                                        }}
                                    />
                                </div>
                                <div style={{ ...EssentialStyle.rowFlexStart, backgroundColor: Colors.white, borderRadius: 8, width: "100%", padding: "0px 15px", border: '1px solid ' + Colors.homePage.lightGrey, borderTopLeftRadius: 0, marginTop: "-1px" }}>
                                    {this.state.expanded && this.renderActiveTab()}
                                </div>
                            </div>
                        </>
                        }
                    </div>
                </Collapse>
                <div 
                    style={{...EssentialStyle.rowFlexCenter, width: "100%", padding: 0, padding: 5, cursor: this.props.editOrder ? "default" : "pointer", color: this.props.editOrder ? "transparent" : Colors.dark}}
                    onClick={() => {
                        if(this.props.editOrder) return;
                        this.setState({ expanded: !this.state.expanded })
                    }}
                >
                    {<FontAwesomeIcon icon={this.state.expanded ? faChevronUp : faChevronDown} />}
                </div>
            </div>
        );
    }

    render = () => {
        return this.renderCard();
    }

}
