import React from "react";
import '../PlanoAcaoRelatorio.css';

import { debounce } from 'lodash';

import EssentialStyle from "../../../../../style/EssentialStyle";
import Colors from "../../../../../constants/Colors";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort, faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons';
import DefaultLoader from "../../../../tools/DefaultLoader";
import PlanoAcaoRelatorioLinhaTabela from "./PlanoAcaoRelatorioLinhaTabela";
import Sig from "../../../../../api/Sig";
import { toast } from "react-toastify";

export default class PlanoAcaoRelatorioTabela extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            page: 1,
            hasMore: false,
            loading: true,
            requesting: false,
            sort: { sort: false, sortAsc: true, sortField: "" },
            filter: {},
            firstLoad: true,
            partialLoading: false
        }
        this.abortController = new AbortController();
        this.handleScrollDebounced = debounce(this.handleScroll, 100);
    }

    async componentDidMount() {
        if (document.querySelector('.relatorio-pa > div')) {
            document.querySelector('.relatorio-pa > div').addEventListener('scroll', this.handleScrollDebounced);
        }

        this.loadData(1);
    }

    componentDidUpdate(prevProps, prevState) {
        if (document.querySelector('.relatorio-pa > div')) {
            let listPlanosRelatorio = document.querySelector('.relatorio-pa > div');

            if (listPlanosRelatorio) {
                listPlanosRelatorio.removeEventListener('scroll', this.handleScrollDebounced);
                listPlanosRelatorio.addEventListener('scroll', this.handleScrollDebounced);
            }
        }

        if (prevProps.filter !== this.props.filter) {
            if(this.state.requesting) {
                this.abortController.abort();
                this.abortController = new AbortController();
            }

            this.setState({ 
                data: [],
                loading: true,
                requesting: false,
                firstLoad: true
            }, () => {
                this.loadData();
            });
        }
    }

    componentWillUnmount() {
        if (document.querySelector('.relatorio-pa > div')) {
            document.querySelector('.relatorio-pa > div').removeEventListener('scroll', this.handleScrollDebounced);
        }

        this.abortController.abort();
    }

    handleScroll = () => {
        const scrollableDiv = document.querySelector('.relatorio-pa > div');

        if (scrollableDiv) {
            const threshold = 100;
            if (scrollableDiv.scrollTop + scrollableDiv.clientHeight >= scrollableDiv.scrollHeight - threshold) {
                if (!this.state.loading && !this.state.requesting && this.state.hasMore) {
                    this.loadData(this.state.page + 1);
                }
            }
        }
    }

    async loadData(page = 1) {
        let timeout = null;

        if (this.state.requesting) return;
        this.setState({ requesting: true, partialLoading: true });

        let sort = 't.data_cadastro DESC';

        if (this.state.sort.sort) {
            sort = `${this.state.sort.sortField} ${this.state.sort.sortAsc ? `ASC` : `DESC`}`;
        }

        var req = await Sig.request(
            'POST', 
            'planoAcao/list', 
            {
                page, 
                limit: 10, 
                filter: JSON.stringify(this.props.filter), 
                order: sort,
                parceiro: this.props.parceiro ? 1 : 0, 
                etapas: 1,
                id_periodo_avaliacao: this.props.id_periodo_avaliacao || 0
            },
            Sig.getDefaultContentType(),
            Sig.getDefaultTransformTo(),
            this.abortController.signal
        );

        if (this.state.firstLoad) {
            this.setState({ loading: true, firstLoad: false });
        } else {
            timeout = setTimeout(() => {
                this.setState({ loading: true });
            }, 200);
        }

        if (timeout) {
            clearTimeout(timeout);
        }

        if (req.status != 200) {
            this.setState({ loading: false, requesting: false });
            return toast.error("Erro ao carregar dados");
        }

        if (req.data.length < 10)
            this.setState({ hasMore: false });
        else
            this.setState({ hasMore: true });

        this.setState(prevState => ({
            data: page === 1 ? req.data : [...prevState.data, ...req.data],
            page,
            loading: false
        }));

        this.setState({ requesting: false });
    }

    renderTabelaLinha = (item, index) => {
        return (
            <PlanoAcaoRelatorioLinhaTabela
                filter={this.props.filter}
                key={`tabela-lista-${index}-${item.id}`}
                item={item}
                type={this.props.view === 'etapas' ? 'etapa' : 'plano'}
                parceiro={this.props.parceiro}
                isSmallScreen={this.props.isSmallScreen}
                expanded
            />
        );
    }

    renderList() {
        if (this.state.data.length === 0 && !this.state.loading) return this.renderEmpty();

        return this.state.data.map((item, index) => this.renderTabelaLinha(item, index));
    }

    toogleSort = (field) => {
        let sort = {};

        if (this.state.sort.sortField === field && this.state.sort.sort) {
            if (!this.state.sort.sortAsc) {
                sort = { sort: false, sortAsc: true, sortField: "" };
            } else {
                sort = { sort: true, sortAsc: false, sortField: field };
            }
        } else {
            sort = { sort: true, sortAsc: true, sortField: field };
        }

        this.setState({ sort }, () => this.loadData(1));
    }

    renderLoading = () => {
        if (!this.state.requesting) return null;

        return (
            <tr
                style={{
                    height: 60,
                    backgroundColor: Colors.homePage.extraLightGrey,
                    borderBottom: `1px solid ${Colors.homePage.extraLightGrey}`
                }}>
                    <td colSpan={15} style={{ backgroundColor: Colors.homePage.extraLightGrey }}>
                        <div style={{ ...EssentialStyle.rowFlexStart, width: "100%", height: "100%", paddingLeft: 20 }}>
                            <DefaultLoader size={18} title={'Carregando...'} color={Colors.dark}/>
                        </div>
                    </td>
            </tr>
        )
    }

    renderParceiroColuna = (bordersRows) => {
        if(!this.props.parceiro) return null;

        return (
            <th
                onClick={() => this.props.view == 'planos' && this.toogleSort("idEmpresa.nome_fantasia")}
                style={{ padding: "0px 5px", ...bordersRows, whiteSpace: "nowrap", backgroundColor: Colors.homePage.extraLightGrey, cursor: this.props.view == 'planos' ? "pointer" : "default" }}
            >
                Empresa Parceira
                {this.props.view == 'planos' && <FontAwesomeIcon icon={this.state.sort.sortField == "idEmpresa.nome_fantasia" ? this.state.sort.sortAsc ? faSortDown : faSortUp : faSort} style={{ marginLeft: 5, color: Colors.homePage.darkGrey }} />}
            </th>
        );
    }

    render() {
        var bordersRows = { borderBottom: `1px solid ${Colors.homePage.lightGrey}`, borderRight: `1px solid ${Colors.homePage.lightGrey}` }

        if (this.props.loading) {
            return (
                <div style={{ ...EssentialStyle.columnCenter, width: '100%', height: '20vh' }}>
                    <DefaultLoader />
                </div>
            )
        }

        return (
            <div style={{ ...EssentialStyle.columnStart, maxHeight: "100%", flex: 1, borderRadius: 8, border: `1px solid ${Colors.homePage.lightGrey}`, borderCollapse: "collapse" }}>
                <div style={{ ...EssentialStyle.columnStart, maxHeight: "100%", flex: 1, fontSize: 14 }}>
                    <table style={{ borderRadius: 8, tableLayout: "auto", position: "relative", overflow: "hidden" }}>
                        <thead style={{ height: 30 }}>
                            <tr style={{ position: "sticky", zIndex: 3, top: 0, background: Colors.homePage.extraLightGrey }}>
                                <th
                                    className={"sticky-column"}
                                    onClick={() => this.props.view == 'planos' && this.toogleSort("t.nome")}
                                    style={{ zIndex: 2, left: 0, maxWidth: 300, minWidth: 300, padding: "0px 5px", ...bordersRows, whiteSpace: "nowrap", backgroundColor: Colors.homePage.extraLightGrey, cursor: this.props.view == 'planos' ? "pointer" : "default" }}
                                >
                                    <div>
                                        Plano de Ação / Atividade
                                        {this.props.view == 'planos' && <FontAwesomeIcon icon={this.state.sort.sortField == "t.nome" ? this.state.sort.sortAsc ? faSortDown : faSortUp : faSort} style={{ marginLeft: 5, color: Colors.homePage.darkGrey }} />}
                                    </div>
                                </th>
                                <th
                                    className={"sticky-column"}
                                    style={{ zIndex: 2, left: 300, maxWidth: 300, minWidth: 300, padding: "0px 5px", ...bordersRows, whiteSpace: "nowrap", backgroundColor: Colors.homePage.extraLightGrey }}
                                >
                                    Como?
                                </th>
                                <th
                                    className={"sticky-column"}
                                    style={{ zIndex: 2, left: 600, maxWidth: 300, minWidth: 300, padding: "0px 5px", ...bordersRows, whiteSpace: "nowrap", backgroundColor: Colors.homePage.extraLightGrey }}
                                >
                                    Por quê?
                                </th>

                                {this.renderParceiroColuna(bordersRows)}

                                <th
                                    onClick={() => this.props.view == 'planos' && this.toogleSort("t.tipo")}
                                    style={{ padding: "0px 5px", ...bordersRows, whiteSpace: "nowrap", backgroundColor: Colors.homePage.extraLightGrey, cursor: this.props.view == 'planos' ? "pointer" : "default" }}
                                >
                                    <div>
                                        Tipo
                                        {this.props.view == 'planos' && <FontAwesomeIcon icon={this.state.sort.sortField == "t.tipo" ? this.state.sort.sortAsc ? faSortDown : faSortUp : faSort} style={{ marginLeft: 5, color: Colors.homePage.darkGrey }} />}
                                    </div>
                                </th>
                                <th
                                    style={{ padding: "0px 5px", ...bordersRows, whiteSpace: "nowrap", backgroundColor: Colors.homePage.extraLightGrey }}
                                >
                                    Vínculos
                                </th>
                                <th
                                    style={{ padding: "0px 5px", ...bordersRows, whiteSpace: "nowrap", backgroundColor: Colors.homePage.extraLightGrey, cursor: this.props.view == 'planos' ? "pointer" : "default" }}
                                >
                                    <div>
                                        Situação
                                    </div>
                                </th>
                                <th
                                    onClick={() => this.props.view == 'planos' && this.toogleSort("(SELECT MAX(data_inicio) FROM plano_acao_etapa WHERE plano_acao_etapa.id_plano_acao = t.id)")}
                                    style={{ padding: "0px 5px", ...bordersRows, whiteSpace: "nowrap", backgroundColor: Colors.homePage.extraLightGrey, cursor: this.props.view == 'planos' ? "pointer" : "default" }}
                                >
                                    <div>
                                        Início
                                        {this.props.view == 'planos' && <FontAwesomeIcon icon={this.state.sort.sortField == "(SELECT MAX(data_inicio) FROM plano_acao_etapa WHERE plano_acao_etapa.id_plano_acao = t.id)" ? this.state.sort.sortAsc ? faSortDown : faSortUp : faSort} style={{ marginLeft: 5, color: Colors.homePage.darkGrey }} />}
                                    </div>
                                </th>
                                <th
                                    onClick={() => this.props.view == 'planos' && this.toogleSort("(SELECT MAX(data_fim) FROM plano_acao_etapa WHERE plano_acao_etapa.id_plano_acao = t.id)")}
                                    style={{ padding: "0px 5px", ...bordersRows, whiteSpace: "nowrap", backgroundColor: Colors.homePage.extraLightGrey, cursor: this.props.view == 'planos' ? "pointer" : "default" }}
                                >
                                    <div>
                                        Fim
                                        {this.props.view == 'planos' && <FontAwesomeIcon icon={this.state.sort.sortField == "(SELECT MAX(data_fim) FROM plano_acao_etapa WHERE plano_acao_etapa.id_plano_acao = t.id)" ? this.state.sort.sortAsc ? faSortDown : faSortUp : faSort} style={{ marginLeft: 5, color: Colors.homePage.darkGrey }} />}
                                    </div>
                                </th>
                                <th
                                    onClick={() => this.props.view == 'planos' && this.toogleSort("(SELECT SUM(orcamento_previsto) FROM plano_acao_etapa WHERE plano_acao_etapa.id_plano_acao = t.id)")}
                                    style={{ padding: "0px 5px", ...bordersRows, whiteSpace: "nowrap", backgroundColor: Colors.homePage.extraLightGrey, cursor: this.props.view == 'planos' ? "pointer" : "default" }}
                                >
                                    <div>
                                        Orçado (R$)
                                        {this.props.view == 'planos' && <FontAwesomeIcon icon={this.state.sort.sortField == "(SELECT SUM(orcamento_previsto) FROM plano_acao_etapa WHERE plano_acao_etapa.id_plano_acao = t.id)" ? this.state.sort.sortAsc ? faSortDown : faSortUp : faSort} style={{ marginLeft: 5, color: Colors.homePage.darkGrey }} />}
                                    </div>
                                </th>
                                <th
                                    onClick={() => this.props.view == 'planos' && this.toogleSort("(SELECT SUM(orcamento_realizado) FROM plano_acao_etapa WHERE plano_acao_etapa.id_plano_acao = t.id)")}
                                    style={{ padding: "0px 5px", ...bordersRows, whiteSpace: "nowrap", backgroundColor: Colors.homePage.extraLightGrey, cursor: this.props.view == 'planos' ? "pointer" : "default" }}
                                >
                                    <div>
                                        Realizado (R$)
                                        {this.props.view == 'planos' && <FontAwesomeIcon icon={this.state.sort.sortField == "(SELECT SUM(orcamento_realizado) FROM plano_acao_etapa WHERE plano_acao_etapa.id_plano_acao = t.id)" ? this.state.sort.sortAsc ? faSortDown : faSortUp : faSort} style={{ marginLeft: 5, color: Colors.homePage.darkGrey }} />}
                                    </div>
                                </th>
                                <th
                                    onClick={() => this.props.view == 'planos' && this.toogleSort("idColaborador.nome")}
                                    style={{ padding: "0px 5px", ...bordersRows, whiteSpace: "nowrap", backgroundColor: Colors.homePage.extraLightGrey, cursor: this.props.view == 'planos' ? "pointer" : "default" }}
                                >
                                    <div>
                                        Responsável
                                        {this.props.view == 'planos' && <FontAwesomeIcon icon={this.state.sort.sortField == "idColaborador.nome" ? this.state.sort.sortAsc ? faSortDown : faSortUp : faSort} style={{ marginLeft: 5, color: Colors.homePage.darkGrey }} />}
                                    </div>
                                </th>
                                <th style={{ padding: "0px 5px", ...bordersRows, whiteSpace: "nowrap", backgroundColor: Colors.homePage.extraLightGrey }}>Avaliador</th>
                                <th style={{ padding: "0px 5px", ...bordersRows, whiteSpace: "nowrap", backgroundColor: Colors.homePage.extraLightGrey }}>Participantes</th>
                                <th style={{ padding: "0px 5px", ...bordersRows, whiteSpace: "nowrap", backgroundColor: Colors.homePage.extraLightGrey }}>Prioridade</th>
                                <th style={{ padding: "0px 5px", borderBottom: `1px solid ${Colors.homePage.lightGrey}`, whiteSpace: "nowrap", backgroundColor: Colors.homePage.extraLightGrey }}>Comentários</th>
                            </tr>
                        </thead>
                        <tbody style={{ backgroundColor: "unset", color: Colors.homePage.darkGrey, fontWeight: 500 }}>
                            {!this.state.firstLoad ? this.renderLoading() : null}
                            {this.renderList()}
                            {this.renderLoading()}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}