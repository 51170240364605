import React, { Component } from 'react';
import './TableImporter.css';

import * as XLSX from 'xlsx';
import { withTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileAlt, faCheckCircle, faCircleExclamation, faTimesCircle, faDownload, faUpload } from '@fortawesome/free-solid-svg-icons'
import { confirmAlert } from 'react-confirm-alert';
import CustomConfirm from "../CustomConfirm";
import { toast } from "react-toastify";

import DefaultButton from '../DefaultButton';
import Colors from '../../../constants/Colors';
import EssentialStyle from '../../../style/EssentialStyle';
import DefaultLoader from '../DefaultLoader';
import SessionHelper from '../../../helper/SessionHelper';
import Constants from "../../../constants/Api";
import DataHelper from '../../../helper/DataHelper';
import TableImporterPreview from '../TableImporterPreview/TableImporterPreview';

const gateway = Constants.getSigEndPoint();

/**
 * TableImporter component for importing and previewing table data from XLS, XLSX, and CSV files.
 *
 * @component
 * @example
 * const onRead = (data) => { console.log(data); };
 * const onConfirm = (data) => { console.log(data); };
 * const onComplete = () => { console.log('Complete'); };
 * const importing = false;
 * const fileErrors = ['Error 1', 'Error 2'];
 * const importComplete = false;
 * return (
 *   <TableImporter onRead={onRead} onComplete={onComplete} onConfirm={onConfirm} importing={importing} fileErrors={fileErrors} importComplete={importComplete} />
 * )
 */
class TableImporter extends Component {
    state = {
        data: [],
        loading: true,
        loadingUpload: false,
        fileName: null,
        fileErrors: this.props.fileErrors || [],
        importing: this.props.importing || false,
        linesImported: this.props.linesImported || 0,
        importComplete: this.props.importComplete || false,
    };

    async componentDidMount() {
        this.setState({ loading: true }, this.load);
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.fileErrors !== this.props.fileErrors) {
            this.setState({ fileErrors: this.props.fileErrors });
        }

        if (prevProps.errorText !== this.props.errorText) {
            this.setState({ errorText: this.props.errorText });
        }

        if (prevProps.importing !== this.props.importing) {
            this.setState({ importing: this.props.importing });

            if (this.props.importing) {
                this.setState({ loading: true, loadingUpload: true });
            } else {
                this.setState({ loading: false, loadingUpload: false });
            }
        }

        if (prevProps.linesImported !== this.props.linesImported) {
            this.setState({ linesImported: this.props.linesImported });
        }

        if (prevProps.importComplete !== this.props.importComplete) {
            this.setState({ importComplete: this.props.importComplete });
        }
    }

    async load() {
        await this.props.i18n.loadNamespaces(['client_src_components_tools_TableImporter_TableImporter']);
        this.setState({ loading: false });
    }

    handleFileRead = async (event) => {
        this.setState({ addingNew: true, loadingUpload: true });

        const file = event.target.files[0];
        const maxSize = 10000000;

        if (file && file.size <= maxSize) {
            this.setState({ fileName: file.name });

            try {
                const reader = new FileReader();

                reader.onload = (e) => {
                    const data = new Uint8Array(e.target.result);
                    const workbook = XLSX.read(data, { type: 'array' });
                    const sheetName = workbook.SheetNames[0];
                    const worksheet = workbook.Sheets[sheetName];
                    let jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1, defval: '' });
                    jsonData = jsonData.filter(row => row.some(cell => cell !== ''));
                    
                    this.setState({ data: jsonData, loadingUpload: false }, () => {
                        if (this.props.onRead) this.props.onRead(this.state.data);
                    });
                };

                reader.readAsArrayBuffer(file);
            } catch (error) {
                toast.error(this.props.t('client_src_components_tools_TableImporter_TableImporter:erroAoLer'));
            }
        } else {
            if (file && file.size >= maxSize) {
                toast.warn(this.props.t('client_src_components_tools_TableImporter_TableImporter:mtoGrande'));
            } else {
                toast.warn(this.props.t('client_src_components_tools_TableImporter_TableImporter:erroDesconhecido'));
            }
        }

        this.setState({ addingNew: false, loadingUpload: false });
    }

    renderTable = () => {
        if (this.state.importComplete) return this.renderComplete();
        if (this.state.importing) return this.renderImporting();

        const { data } = this.state;
        if (data.length === 0) {
            return (
                <div style={{ ...EssentialStyle.columnCenter, height: '100%' }}>
                    <img
                        src={`${gateway}/img/undraw/undraw_spreadsheets.svg`}
                        alt={this.props.t('client_src_components_tools_TableImporter_TableImporter:imgAlt')}
                        style={{
                            width: 350,
                        }}
                    />
                    <div style={{ ...EssentialStyle.columnCenter, marginTop: 15 }}>
                        <p>{this.props.t('client_src_components_tools_TableImporter_TableImporter:importarPlanilhaDescricao')}</p>
                        {this.renderDownloadModel()}
                        {this.renderImportFile()}
                    </div>
                </div>
            );
        }

        let maxRows = 200;
        let maxCols = 100;
        let tooBig = data.length > maxRows || data[0].length > maxCols;

        return (
            <div className="table-importer-container">
                <div style={{ ...EssentialStyle.columnStart, width: '100%', marginTop: 10 }}>
                    {this.state.fileErrors.map((error, index) => (
                        <p key={index} style={{ color: Colors.error, fontWeight: '600', width: '100%' }}><FontAwesomeIcon icon={faCircleExclamation} /> {error}</p>
                    ))}
                </div>
                <TableImporterPreview fileModel={data} maxRows={maxRows} maxCols={maxCols} justPreview={true} />
                <div style={{ ...EssentialStyle.rowSpaceBetween, width: '100%', marginTop: 10 }}>
                    <div style={{ ...EssentialStyle.rowFlexStart }}>
                        {tooBig ? <p>{this.props.t('client_src_components_tools_TableImporter_TableImporter:tooBig')}</p> : null}
                    </div>
                    {this.renderConfirmButton()}
                </div>
            </div>
        );
    }

    removeFile = () => {
        confirmAlert({
            customUI: ({ onClose }) => (
                <CustomConfirm
                    title={`${this.props.t(`client_src_components_tools_TableImporter_TableImporter:removerAnexo`)}?`}
                    message={<p style={{ marginTop: 10, marginBottom: 10 }}>{this.props.t(`client_src_components_tools_TableImporter_TableImporter:nPodeSerDesfeita`)}</p>}
                    buttons={[
                        {
                            label: this.props.t('client_src_components_tools_TableImporter_TableImporter:remover'),
                            color: SessionHelper.getColor(),
                            textColor: Colors.light,
                            onClick: async () => {
                                this.setState({ loading: true }, () => {
                                    this.setState({ fileName: null, data: [] }, () => {
                                        this.setState({ loading: false });

                                        let input = document.getElementById('upload-table');
                                        input.value = '';
                                    });
                                });

                                onClose();
                            }
                        },
                        {
                            label: this.props.t('client_src_components_tools_TableImporter_TableImporter:cancelar'),
                            onClick: () => { onClose(); }
                        },
                    ]}
                />
            )
        });
    }

    renderAddedFile() {
        if (!this.state.fileName) return null;

        return (
            <div style={{ ...EssentialStyle.rowFlexStart }}>
                <DefaultButton
                    title={this.state.fileName}
                    rightIcon={<FontAwesomeIcon icon={faTimesCircle} />}
                    color={'transparent'}
                    tooltip={this.props.t('client_src_components_tools_TableImporter_TableImporter:removerAnexo')}
                    tooltipPlacement={'right'}
                    textColor={Colors.dark}
                    onClick={this.removeFile}
                />
            </div>
        );
    }

    renderDownloadModel = () => {
        return (
            <div style={{ ...EssentialStyle.rowFlexStart, marginBottom: 10 }}>
                <DefaultButton
                    title={this.props.t('client_src_components_tools_TableImporter_TableImporter:downloadModelo')}
                    leftIcon={<FontAwesomeIcon icon={faDownload} />}
                    color={Colors.success}
                    width={200}
                    textColor={Colors.light}
                    onClick={() => {
                        let { fileModel, lockColumns, alvoColumns } = this.props;

                        DataHelper.createAndStyleXLSX(XLSX, fileModel, this.props.t('client_src_components_tools_TableImporter_TableImporter:fileModel'));
                    }}
                />
            </div>
        );
    }

    renderImporting = () => {
        let { importing, linesImported } = this.state;
        if (!importing) return null;

        let percent = this.state.data.length > 0 ? Math.floor((linesImported / this.state.data.length) * 100) : 0;

        return (
            <div style={{ ...EssentialStyle.columnCenter, height: '100%' }}>
                <img
                    src={`${gateway}/img/undraw/undraw_spreadsheets.svg`}
                    alt={this.props.t('client_src_components_tools_TableImporter_TableImporter:imgAlt')}
                    style={{
                        width: 350,
                    }}
                />
                <div style={{ ...EssentialStyle.columnCenter, marginTop: 15 }}>
                    <div style={{ ...EssentialStyle.rowFlexCenter }}>
                        <DefaultLoader size={20} color={Colors.dark} />
                        <h3 style={{ marginLeft: 8 }}>{this.props.t('client_src_components_tools_TableImporter_TableImporter:importando')}</h3>
                    </div>
                    <p>{this.props.t('client_src_components_tools_TableImporter_TableImporter:progresso', { linesImported, total: this.state.data.length, percent: parseInt(percent) })}</p>
                </div>
            </div>
        );
    }

    renderComplete = () => {
        return (
            <div style={{ ...EssentialStyle.columnCenter, height: '100%' }}>
                <img
                    src={`${gateway}/img/undraw/undraw_complete-design.svg`}
                    alt={this.props.t('client_src_components_tools_TableImporter_TableImporter:imgCompleteAlt')}
                    style={{
                        width: 350,
                    }}
                />
                <div style={{ ...EssentialStyle.columnCenter, marginTop: 15 }}>
                    <p>{this.props.t('client_src_components_tools_TableImporter_TableImporter:importComplete')}</p>
                    <DefaultButton
                        title={this.props.t('client_src_components_tools_TableImporter_TableImporter:finalizar')}
                        leftIcon={<FontAwesomeIcon icon={faCheckCircle} />}
                        color={Colors.success}
                        width={200}
                        textColor={Colors.light}
                        onClick={() => {
                            if(this.props.onComplete) this.props.onComplete();
                        }}
                    />
                </div>
            </div>
        );
    }

    renderImportFile = () => {
        let disabled = this.state.loading || this.state.loadingUpload || this.state.fileName || this.state.importComplete || this.state.importing;

        return (
            <label htmlFor="upload-table">
                <div
                    style={{
                        borderRadius: 5,
                        cursor: disabled ? 'not-allowed' : 'pointer',
                        display: 'flex',
                        flexDirection: 'row',
                        height: 'auto',
                        width: '100%',
                        backgroundColor: disabled ? Colors.notSelected : Colors.tag,
                        width: 200,
                        alignContent: 'center',
                        alignItems: 'center',
                        justifyContent: 'center',
                        padding: 5
                    }
                    }>
                    {this.state.loadingUpload || this.state.loading ? <DefaultLoader size={14} color={Colors.dark} /> : <FontAwesomeIcon icon={faFileAlt} color={Colors.dark} />}
                    <div style={{ ...EssentialStyle.columnStart, marginLeft: 10 }}>
                        <a style={{ color: Colors.dark }}>{this.props.t('client_src_components_tools_TableImporter_TableImporter:anexar')}</a>
                    </div>
                </div>
            </label>
        );
    }

    renderConfirmButton = () => {
        if (this.state.data.length === 0) return null;

        return (
            <div style={{ ...EssentialStyle.columnCenter }}>
                <DefaultButton
                    title={this.props.importText ? this.props.importText : this.props.t('client_src_components_tools_TableImporter_TableImporter:importar')}
                    leftIcon={<FontAwesomeIcon icon={faUpload} />}
                    color={Colors.success}
                    textColor={Colors.light}
                    disabled={this.state.importComplete || this.state.importing || this.state.loading}
                    onClick={() => {
                        this.setState({ loading: true }, () => {
                            if (this.props.onConfirm) this.props.onConfirm(this.state.data);
                            this.setState({ loading: false });
                        });
                    }}
                />
            </div>
        )
    }

    renderHead = () => {
        return (
            <div style={{ ...EssentialStyle.rowSpaceBetween, marginBottom: 10 }}>
                <div style={{ ...EssentialStyle.rowFlexStart }}>
                    <input
                        multiple={false}
                        accept={".xlsx, .xls, .csv"}
                        style={{ display: 'none' }}
                        id="upload-table"
                        name="upload-table"
                        type="file"
                        onChange={this.handleFileRead}
                    />
                    {this.renderImportFile()}
                    {this.renderAddedFile()}
                </div>
                
                <div style={{ ...EssentialStyle.rowFlexStart }}>
                    {this.renderConfirmButton()}
                </div>
            </div>
        )
    }

    render() {
        return (
            <div className="table-importer-container">
                {this.renderHead()}
                {this.renderTable()}
            </div>
        );
    }
}

export default withTranslation()(TableImporter);