import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faClock, faPlus } from '@fortawesome/free-solid-svg-icons';
import Colors from "../../../constants/Colors";
import moment from "moment";
import DataHelper from "../../../helper/DataHelper";

export default class AtaReuniaoStatus extends React.Component {

    render() {
        let options = {
            finalizada: { color: Colors.white, backgroundColor: Colors.ataReuniao.status.backgroundColor['Finalizada'], text: `Finalizada em ${this.props.ata?.finalizado ? moment(this.props.ata?.finalizado).format('DD/MM/YY - HH:mm') : " -- "}`, icon: <FontAwesomeIcon icon={faCheck} /> },
            aberta: { color: Colors.dark, backgroundColor: Colors.ataReuniao.status.backgroundColor['Em Aberto'], text: `Em Aberto`, icon: <FontAwesomeIcon icon={faClock} /> },
            criada: { color: Colors.white, backgroundColor: Colors.ataReuniao.status.backgroundColor['Criada'], text: `Criada`, icon: <FontAwesomeIcon icon={faPlus} /> }
        };

        let status = this.props.ata?.status == "Finalizada" ? options.finalizada : this.props.ata?.status == "Em Aberto" ? options.aberta : options.criada;

        return (
            <div style={{backgroundColor: status.backgroundColor, color: status.color, display: "flex", alignItems: "center", gap: 5, padding: '1px 5px', borderRadius: 5, fontSize: 14, fontWeight: 600}}>
                {status.icon}
                {status.text}
            </div>
        )
    }
}