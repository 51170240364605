import React from "react";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faChartColumn, faEdit, faTimesCircle, faChartPie, faGauge, faCircle } from '@fortawesome/free-solid-svg-icons';

import './PreviwerModeloRelatorio.css';
import IdiomaHelper from "../../../../../helper/IdiomaHelper";
import DiagnosticoHelper from "../../../../../helper/diagnostico/DiagnosticoHelper";
import DataHelper from "../../../../../helper/DataHelper";
import DragAndDropHelper from "../../../../../helper/DragAndDropHelper";
import EssentialStyle from "../../../../../style/EssentialStyle";
import EllipsisText from "../../../../tools/EllipsisText";
import Colors from "../../../../../constants/Colors";

export default class PreviwerModeloRelatorio extends React.Component {

    constructor(props) {
        super(props);
        this.provisionalItemRef = React.createRef();
        this.state = {

        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.newModelo?.provisionalItem?.on && !prevProps.newModelo?.provisionalItem?.on) {
            this.provisionalItemRef?.current?.scrollIntoView({ behavior: 'smooth' });
        }
    }

    onDragStartItem = async (e, item) => {
        if (this.props.blockEdit) return;
        this.setState({ draggingItem: item, draggingOverItem: null, heightDragItem: e.target.offsetHeight });
        setTimeout(() => { e.target.style.display = "none" }, 5);
    }

    onDragEndItem = async (e, item) => {
        if (this.props.blockEdit) return;
        e.target.style.display = "flex";

        let items = [...this.props.newModelo.items];
        let draggingOverItem = this.state.draggingOverItem;

        if (draggingOverItem !== null) {
            items.splice(items.indexOf(this.state.draggingItem), 1);
            let indexToInsert = items.indexOf(draggingOverItem);
            if (this.state.top) {
                items.splice(indexToInsert, 0, item);
            } else {
                items.splice(indexToInsert + 1, 0, item);
            }
        }

        items = items.map((item, index) => {
            return { ...item, dragIndex: parseInt(index) + 1 };
        });

        this.setState({ draggingItem: null, draggingOverItem: null, left: false }, () => {
            this.props.onSetItems(items);
        });
    }

    onDragEnterItem = async (e, item) => {
        if (this.props.blockEdit) return;
        let top = DragAndDropHelper.checkTop(e);

        this.setState({ top });

        if (item.id !== this.state.draggingItem.id) {
            this.setState({ draggingOverItem: item });
        }
    }

    langHasText = (item) => {

        const text = item?.text || [];

        const indices = text
            .map((textItem, index) => DataHelper.removeHtmlTags(textItem.descricao.trim() || '') !== '' ? index : -1)
            .filter(index => index !== -1);

        if (indices.length === 0) {
            return false;
        } else if (indices.length === 1) {
            return indices[0].toString();
        } else {
            const langIndex = indices.find(index => text[index].id_idioma === DataHelper.convertSiglaToIdIdioma(this.props.lang));
            return langIndex !== undefined ? langIndex.toString() : indices[0].toString();
        }

    };

    getText = (item) => {
        return item.text?.[this.langHasText(item)]?.descricao ?? '';
    }

    getColunaNome = (chart) => {
        if (chart.coluna_nome == null || chart.coluna_nome.length == 0) return '';
        let coluna_nome = chart.coluna_nome.find(coluna => coluna.id_idioma === IdiomaHelper.getSiglaId(this.props.lang));
        return coluna_nome?.descricao ?? '';
    };

    getNodeNome = (chart) => {
        if (chart.node_nome == null || chart.node_nome.length == 0) return '';
        let node_nome = chart.node_nome.find(node => node.id_idioma === IdiomaHelper.getSiglaId(this.props.lang));
        return node_nome?.descricao ?? '';
    };

    renderItems() {

        let message = IdiomaHelper.getStruct({
            'pt_br': {
                text: 'Texto personalizado',
                performanceInd: 'Performance',
                result: 'Resultados da Avaliação',
                thResults: 'Resultados',
                performance: 'Performance',
                realizado: 'Realizado',
                media: 'Média',
                moda: 'Moda',
                operacao: 'Operação',
                grupo: 'Grupo',
                avaliacao: 'Avaliação',
                indicador: 'Indicador',
                resultado: 'Resultado',
                classificacao: 'Classificação',
            },
            'en': {
                text: 'Custom text',
                performanceInd: 'Performance',
                result: 'Avaliation Results',
                thResults: 'Results',
                performance: 'Performance',
                realizado: 'Accomplished',
                media: 'Average',
                moda: 'Mode',
                operacao: 'Operation',
                grupo: 'Group',
                avaliacao: 'Evaluation',
                indicador: 'Indicator',
                resultado: 'Result',
                classificacao: 'Classification',
            },
            'es': {
                text: 'Texto personalizado',
                performanceInd: 'Performance',
                result: 'Resultados de la Evaluación',
                thResults: 'Resultados',
                performance: 'Actuación',
                realizado: 'Logrado',
                media: 'Média',
                moda: 'Moda',
                operacao: 'Operación',
                grupo: 'Grupo',
                avaliacao: 'Evaluación',
                indicador: 'Indicador',
                resultado: 'Resultado',
                classificacao: 'Clasificación',
            },
        });

        const renderChart = (item) => {

            return (
                <div
                    key={`item-${item.id}-${item.type}-${item.dragIndex}`}
                    className={`item ${this.props.blockEdit ? '' : 'edit'}`}
                    onDragStart={(e) => { this.onDragStartItem(e, item) }}
                    onDragEnter={(e) => { this.onDragEnterItem(e, item) }}
                    onDragEnd={(e) => { this.onDragEndItem(e, item) }}
                    draggable={this.props.blockEdit ? false : true}
                    style={{
                        marginTop: this.state.draggingOverItem && this.state.draggingOverItem.id === item.id && this.state.top ? this.state.heightDragItem : 0,
                        marginBottom: this.state.draggingOverItem && this.state.draggingOverItem.id === item.id && !this.state.top ? this.state.heightDragItem : 5
                    }}
                >
                    {this.props.newModelo?.provisionalItem?.on && <FontAwesomeIcon icon={faEdit} className={`edit-icon ${!this.props.blockEdit ? 'hover-action' : ''}`} onClick={() => this.props.onEditItem(item)} />}
                    {this.props.newModelo?.provisionalItem?.on && <FontAwesomeIcon icon={faTimesCircle} className={`delete-icon ${!this.props.blockEdit ? 'hover-action' : ''}`} onClick={() => this.props.onDeleteItem(item)} />}
                    {
                        item.chartGroup.map((chart, index) => {
                            return (
                                <div key={chart.id} className="chart-demonstration" style={{ width: DiagnosticoHelper.getWidth(chart.largura) }}>
                                    <FontAwesomeIcon icon={faChartColumn} className="chart-icon" />
                                    <div className="column">{this.getColunaNome(chart)}</div>
                                    <div className="description">{this.getNodeNome(chart)}</div>
                                </div>
                            )
                        })
                    }
                </div>
            )
        }

        const renderText = (item) => {

            return (
                <div
                    key={`item-${item.id}-${item.type}-${item.dragIndex}`}
                    className={`item ${this.props.blockEdit ? '' : 'edit'}`}
                    onDragStart={(e) => { this.onDragStartItem(e, item) }}
                    onDragEnter={(e) => { this.onDragEnterItem(e, item) }}
                    onDragEnd={(e) => { this.onDragEndItem(e, item) }}
                    draggable={this.props.blockEdit ? false : true}
                    style={{
                        marginTop: this.state.draggingOverItem && this.state.draggingOverItem.id === item.id && this.state.top ? this.state.heightDragItem : 0,
                        marginBottom: this.state.draggingOverItem && this.state.draggingOverItem.id === item.id && !this.state.top ? this.state.heightDragItem : 5
                    }}
                >
                    {this.props.newModelo?.provisionalItem?.on && <FontAwesomeIcon icon={faEdit} className={`edit-icon ${!this.props.blockEdit ? 'hover-action' : ''}`} onClick={() => this.props.onEditItem(item)} />}
                    {this.props.newModelo?.provisionalItem?.on && <FontAwesomeIcon icon={faTimesCircle} className={`delete-icon ${!this.props.blockEdit ? 'hover-action' : ''}`} onClick={() => this.props.onDeleteItem(item)} />}
                    <div className="item-wrapper">
                        <span>{message[this.props.lang].text}</span>
                        {this.langHasText(item) !== null && <div className="text" dangerouslySetInnerHTML={{ __html: this.getText(item) }} />}
                    </div>
                </div>
            )
        }

        const renderPerformance = (item) => {

            let grupos = item?.grupos || [];

            grupos.sort((a, b) => {
                if (a.drag_index < b.drag_index) return -1;
                if (a.drag_index > b.drag_index) return 1;
                return 0;
            });


            return (
                <div
                    key={`item-${item.id}-${item.type}-${item.dragIndex}`}
                    className={`item ${this.props.blockEdit ? '' : 'edit'}`}
                    onDragStart={(e) => { this.onDragStartItem(e, item) }}
                    onDragEnter={(e) => { this.onDragEnterItem(e, item) }}
                    onDragEnd={(e) => { this.onDragEndItem(e, item) }}
                    draggable={this.props.blockEdit ? false : true}
                    style={{
                        marginTop: this.state.draggingOverItem && this.state.draggingOverItem.id === item.id && this.state.top ? this.state.heightDragItem : 0,
                        marginBottom: this.state.draggingOverItem && this.state.draggingOverItem.id === item.id && !this.state.top ? this.state.heightDragItem : 5
                    }}
                >
                    {this.props.newModelo?.provisionalItem?.on && <FontAwesomeIcon icon={faEdit} className={`edit-icon ${!this.props.blockEdit ? 'hover-action' : ''}`} onClick={() => this.props.onEditItem(item)} />}
                    {this.props.newModelo?.provisionalItem?.on && <FontAwesomeIcon icon={faTimesCircle} className={`delete-icon ${!this.props.blockEdit ? 'hover-action' : ''}`} onClick={() => this.props.onDeleteItem(item)} />}

                    <div style={{ ...EssentialStyle.rowFlexCenter, width: '100%', gap: 10, marginBottom: 5 }}>
                        <div>{message[this.props.lang].performanceInd}</div>
                        <div style={{ fontStyle: 'italic', fontSize: '0.7rem' }}>({message[this.props.lang][item.typeCalc ? item.typeCalc : 'operacao']})</div>
                        <div style={{ fontStyle: 'italic', fontSize: '0.7rem' }}>({message[this.props.lang][item.typeVisualizacao ? item.typeVisualizacao : 'realizado']})</div>
                    </div>

                    {grupos?.length > 0 &&
                        (grupos?.map((grupo, index) => {

                            let indicadores = grupo?.grupo?.indicadores || [];

                            return (
                                <div
                                    key={'provisional-group' + grupo?.grupo?.id ? grupo?.grupo?.id : 'notGrouped'}
                                    className="groupPerformance"
                                    style={{ width: DiagnosticoHelper.getWidth(grupo.largura) }}
                                >

                                    {grupo?.grupo?.textos?.find((text) => { return text.id_idioma == IdiomaHelper.getSiglaId(this.props.lang) })?.descricao || ''}

                                    <div className="indicadoresGrid" >
                                        {indicadores?.map((indicador) => {
                                            return (
                                                <div
                                                    key={indicador.id}
                                                    className="indicador"
                                                    style={{
                                                        width: '14.15vh'
                                                    }}
                                                >
                                                    <FontAwesomeIcon icon={item.chartType == 'donut' ? faChartPie : faGauge} className="chart-icon" />
                                                    <div style={{ width: '100%', maxHeight: '1.5rem', fontSize: '0.7rem' }}><EllipsisText text={indicador.indicadorNome} /></div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            )
                        }))
                    }
                </div>
            )
        }

        const renderPerformanceTable = (item) => {
            let grupos = item?.grupos || [];

            grupos.sort((a, b) => {
                if (a.drag_index < b.drag_index) return -1;
                if (a.drag_index > b.drag_index) return 1;
                return 0;
            });

            const renderTableList = (grupos) => {
                return (
                    <div className="table-container">
                        <table>
                            <thead>
                                <tr>
                                    <th>{message[this.props.lang].grupo}</th>
                                    <th>{message[this.props.lang].avaliacao}</th>
                                    <th>{message[this.props.lang].indicador}</th>
                                    <th>{message[this.props.lang].resultado}</th>
                                </tr>
                            </thead>

                            <tbody>
                                {grupos.map((grupo) => {
                                    let indicadores = grupo?.grupo?.indicadores || [];
                                    return indicadores.map((indicador, index) => {
                                        return (
                                            <tr>
                                                {index == 0 &&
                                                    <td rowSpan={indicadores.length}>
                                                        {grupo?.grupo?.textos?.find((text) => { return text.id_idioma == IdiomaHelper.getSiglaId(this.props.lang) })?.descricao || ''}
                                                    </td>
                                                }

                                                {index == 0 &&
                                                    <td rowSpan={indicadores.length}>
                                                        {message[this.props.lang].classificacao}
                                                    </td>
                                                }

                                                <td key={`indicador-${indicador.id}`}>
                                                    <EllipsisText text={indicador.indicadorNome} />
                                                </td>

                                                <td key={`indicador-resultado-${indicador.id}`}>
                                                    --%
                                                </td>
                                            </tr>
                                        )
                                    });
                                })}
                            </tbody>
                        </table>
                    </div>
                )
            }

            const renderTableColuna = (grupos) => {
                return (
                    <div className="table-container">
                        <table>
                            <thead>
                                <tr>
                                    <th>
                                        {message[this.props.lang].grupo}
                                    </th>

                                    {grupos.map((grupo) => {
                                        return (
                                            <th key={`coluna-${grupo.grupo.id}`} colSpan={grupo?.grupo?.indicadores?.length}>
                                                {grupo?.grupo?.textos?.find((text) => { return text.id_idioma == IdiomaHelper.getSiglaId(this.props.lang) })?.descricao || ''}
                                            </th>
                                        )
                                    })}
                                </tr>

                                <tr>
                                    <th>
                                        {message[this.props.lang].avaliacao}
                                    </th>

                                    {grupos.map((grupo) => {
                                        return (
                                            <th key={`coluna-avaliacao-${grupo.grupo.id}`} colSpan={grupo?.grupo?.indicadores?.length}>
                                                {message[this.props.lang].classificacao}
                                            </th>
                                        )
                                    })}
                                </tr>
                            </thead>

                            <tbody>
                                <tr>
                                    <td>
                                        {message[this.props.lang].indicador}
                                    </td>

                                    {grupos.map((grupo) => {
                                        let indicadores = grupo?.grupo?.indicadores || [];
                                        return indicadores.map((indicador) => {
                                            return (
                                                <td key={`indicador-${indicador.id}`}>
                                                    <EllipsisText text={indicador.indicadorNome} />
                                                </td>
                                            )
                                        });
                                    })}
                                </tr>

                                <tr>
                                    <td>
                                        {message[this.props.lang].resultado}
                                    </td>

                                    {grupos.map((grupo) => {
                                        let indicadores = grupo?.grupo?.indicadores || [];
                                        return indicadores.map((indicador) => {
                                            return (
                                                <td key={`indicador-resultado-${indicador.id}`}>
                                                    --%
                                                </td>
                                            )
                                        });
                                    })}
                                </tr>
                            </tbody>
                        </table>
                    </div>
                )
            }

            return (
                <div
                    key={`item-${item.id}-${item.type}-${item.dragIndex}`}
                    className={`item ${this.props.blockEdit ? '' : 'edit'}`}
                    onDragStart={(e) => { this.onDragStartItem(e, item) }}
                    onDragEnter={(e) => { this.onDragEnterItem(e, item) }}
                    onDragEnd={(e) => { this.onDragEndItem(e, item) }}
                    draggable={this.props.blockEdit ? false : true}
                    style={{
                        marginTop: this.state.draggingOverItem && this.state.draggingOverItem.id === item.id && this.state.top ? this.state.heightDragItem : 0,
                        marginBottom: this.state.draggingOverItem && this.state.draggingOverItem.id === item.id && !this.state.top ? this.state.heightDragItem : 5
                    }}
                >
                    
                    {this.props.newModelo?.provisionalItem?.on && <FontAwesomeIcon icon={faEdit} className={`edit-icon ${!this.props.blockEdit ? 'hover-action' : ''}`} onClick={() => this.props.onEditItem(item)} />}
                    {this.props.newModelo?.provisionalItem?.on && <FontAwesomeIcon icon={faTimesCircle} className={`delete-icon ${!this.props.blockEdit ? 'hover-action' : ''}`} onClick={() => this.props.onDeleteItem(item)} />}

                    <div style={{ ...EssentialStyle.rowFlexCenter, width: '100%', gap: 10 }}>
                        <div>{message[this.props.lang].performanceInd}</div>
                        <div style={{ fontStyle: 'italic', fontSize: '0.7rem' }}>({message[this.props.lang][item.typeCalc ? item.typeCalc : 'operacao']})</div>
                        <div style={{ fontStyle: 'italic', fontSize: '0.7rem' }}>({message[this.props.lang][item.typeVisualizacao ? item.typeVisualizacao : 'realizado']})</div>
                    </div>

                    {item?.formatTable == 'linha' ? renderTableList(grupos) : renderTableColuna(grupos)}

                </div>
            )
        }

        const renderResult = (item) => {

            const renderTableList = () => {

                if (!(item?.tableType == 'lista')) return null;
                if (item?.list?.colunas?.length == 0) return (
                    <span>{message[this.props.lang].selectColumn}</span>
                );

                let colunasItem = item.list.colunas;
                colunasItem = colunasItem.sort((a, b) => a.drag_index - b.drag_index);

                return (
                    <div>
                        <table>
                            <thead>
                                <tr>
                                    {colunasItem.map((coluna) => {
                                        return (
                                            <th key={`coluna-${coluna.id}`}>
                                                {coluna.descricao.find((coluna) => coluna.id_idioma == IdiomaHelper.getSiglaId(this.props.lang)).descricao}
                                            </th>
                                        )
                                    })}

                                    <th key={`coluna-result`}>
                                        {message[this.props.lang].thResults}
                                    </th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr>
                                    {colunasItem.map((coluna) => {
                                        return (
                                            <td key={`coluna-item-${coluna.id}`}>
                                                ...
                                            </td>
                                        )
                                    })}

                                    <td key={`coluna-item-result}`}>
                                        --%
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                )
            }

            const renderTableMatriz = () => {

                if (!(item?.tableType == 'matriz')) return null;
                if (!item?.matriz?.coluna_x || !item?.matriz?.coluna_y) return (
                    <span>{message[this.props.lang].selectColumn}</span>
                );

                let sentido = item?.matriz?.coluna_x?.drag_index - item?.matriz?.coluna_y?.drag_index;
                let linhas = 2;
                let colunas = 2;

                if (sentido > 0) { colunas = 3 } else { linhas = 3 }

                linhas = Array.from({ length: linhas }, (_, i) => i);
                colunas = Array.from({ length: colunas }, (_, i) => i);

                let coluna_x = item?.matriz?.coluna_x.descricao.find((coluna) => coluna.id_idioma == IdiomaHelper.getSiglaId(this.props.lang)).descricao;
                let coluna_y = item?.matriz?.coluna_y.descricao.find((coluna) => coluna.id_idioma == IdiomaHelper.getSiglaId(this.props.lang)).descricao;

                return (
                    <table>
                        <thead>
                            <tr>
                                <th />
                                {colunas.map((coluna, indexLinha) => {
                                    return (
                                        <th key={`header-col-${indexLinha}`}>
                                            {coluna_y}
                                        </th>
                                    )
                                })}
                            </tr>
                        </thead>

                        <tbody>
                            {linhas.map((linha, indexLinha) => {
                                return (
                                    <tr key={`row-${indexLinha}`}>
                                        <th>
                                            {coluna_x}
                                        </th>

                                        {colunas.map((coluna, indexColuna) => {
                                            return (
                                                <td key={`cell-${indexLinha}-${indexColuna}`}>
                                                    --%
                                                </td>
                                            )
                                        })}
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                )
            }

            return (
                <div
                    key={`item-${item.id}-${item.type}-${item.dragIndex}`}
                    className={`item ${this.props.blockEdit ? '' : 'edit'}`}
                    onDragStart={(e) => { this.onDragStartItem(e, item) }}
                    onDragEnter={(e) => { this.onDragEnterItem(e, item) }}
                    onDragEnd={(e) => { this.onDragEndItem(e, item) }}
                    draggable={this.props.blockEdit ? false : true}
                    style={{
                        marginTop: this.state.draggingOverItem && this.state.draggingOverItem.id === item.id && this.state.top ? this.state.heightDragItem : 0,
                        marginBottom: this.state.draggingOverItem && this.state.draggingOverItem.id === item.id && !this.state.top ? this.state.heightDragItem : 5
                    }}
                >
                    {this.props.newModelo?.provisionalItem?.on && <FontAwesomeIcon icon={faEdit} className={`edit-icon ${!this.props.blockEdit ? 'hover-action' : ''}`} onClick={() => this.props.onEditItem(item)} />}
                    {this.props.newModelo?.provisionalItem?.on && <FontAwesomeIcon icon={faTimesCircle} className={`delete-icon ${!this.props.blockEdit ? 'hover-action' : ''}`} onClick={() => this.props.onDeleteItem(item)} />}

                    {/* <span style={{ marginBottom: 5 }}>{message[this.props.lang].result}</span> */}

                    <div style={{ ...EssentialStyle.rowFlexCenter, width: '100%', gap: 10, marginBottom: 5 }}>
                        <div>{message[this.props.lang].result}</div>
                    </div>

                    <div className="tableResults">
                        {renderTableList()}
                        {renderTableMatriz()}
                    </div>
                </div>
            )
        }

        const renderDivider = (item) => {
            return (
                <div
                    key={`item-${item.id}-${item.type}-${item.dragIndex}`}
                    className={`item ${this.props.blockEdit ? '' : 'edit'}`}
                    onDragStart={(e) => { this.onDragStartItem(e, item) }}
                    onDragEnter={(e) => { this.onDragEnterItem(e, item) }}
                    onDragEnd={(e) => { this.onDragEndItem(e, item) }}
                    draggable={this.props.blockEdit ? false : true}
                    style={{
                        marginTop: this.state.draggingOverItem && this.state.draggingOverItem.id === item.id && this.state.top ? this.state.heightDragItem : 0,
                        marginBottom: this.state.draggingOverItem && this.state.draggingOverItem.id === item.id && !this.state.top ? this.state.heightDragItem : 5
                    }}
                >
                    {this.props.newModelo?.provisionalItem?.on && <FontAwesomeIcon icon={faTimesCircle} style={{top: 1}} className={`delete-icon ${!this.props.blockEdit ? 'hover-action' : ''}`} onClick={() => this.props.onDeleteItem(item)} />}

                    <div className="content box">
                        <span style={{ flex: 1, height: 2, backgroundColor: '#c3c3c3' }}></span>
                        <FontAwesomeIcon icon={faCircle} style={{ fontSize: 5, margin: '0px 5px' }} />
                        <span style={{ flex: 1, height: 2, backgroundColor: '#c3c3c3' }}></span>
                    </div>
                </div>
            )
        }

        let newModeloItems = (this.props.newModelo.items || []).sort((a, b) => a.dragIndex - b.dragIndex);

        return (
            <>
                {newModeloItems.map((item) => {
                    switch (item.type) {
                        case 'chart':
                            return <React.Fragment key={`item-modelo=${item.id}-${item.type}-${item.dragIndex}`}>{renderChart(item)}</React.Fragment>;
                        case 'text':
                            return <React.Fragment key={`item-modelo=${item.id}-${item.type}-${item.dragIndex}`}>{renderText(item)}</React.Fragment>;
                        case 'performance':
                            return <React.Fragment key={`item-modelo=${item.id}-${item.type}-${item.dragIndex}`}>{renderPerformance(item)}</React.Fragment>;
                        case 'performanceTable':
                            return <React.Fragment key={`item-modelo=${item.id}-${item.type}-${item.dragIndex}`}>{renderPerformanceTable(item)}</React.Fragment>;
                        case 'divider':
                            return <React.Fragment key={`item-modelo=${item.id}-${item.type}-${item.dragIndex}`}>{renderDivider(item)}</React.Fragment>;
                        case 'results':
                            return <React.Fragment key={`item-modelo=${item.id}-${item.type}-${item.dragIndex}`}>{renderResult(item)}</React.Fragment>;
                        default:
                            return <></>;
                    }
                })}
            </>
        )
    }

    renderProvisionalItem() {

        let message = IdiomaHelper.getStruct({
            'pt_br': {
                chart: 'Grupo de gráficos',
                text: 'Texto personalizado',
                performanceInd: 'Performance',
                result: 'Resultados da Avaliação',
                thResults: 'Resultados',
                selectColumn: 'Selecione as colunas',
                performance: 'Performance',
                realizado: 'Realizado',
                media: 'Média',
                moda: 'Moda',
                operacao: 'Operação',
                grupo: 'Grupo',
                avaliacao: 'Avaliação',
                indicador: 'Indicador',
                resultado: 'Resultado',
                classificacao: 'Classificação',
            },
            'en': {
                chart: 'Charts Group',
                text: 'Custom text',
                performanceInd: 'Performance',
                result: 'Avaliation Results',
                thResults: 'Results',
                selectColumn: 'Select the columns',
                performance: 'Performance',
                realizado: 'Accomplished',
                media: 'Average',
                moda: 'Mode',
                operacao: 'Operation',
                grupo: 'Group',
                avaliacao: 'Evaluation',
                indicador: 'Indicator',
                resultado: 'Result',
                classificacao: 'Classification',
            },
            'es': {
                chart: 'Grupo de gráficos',
                text: 'Texto personalizado',
                performanceInd: 'Performance',
                result: 'Resultados de la Evaluación',
                thResults: 'Resultados',
                selectColumn: 'Selecione as colunas',
                performance: 'Actuación',
                realizado: 'Logrado',
                media: 'Média',
                moda: 'Moda',
                operacao: 'Operación',
                grupo: 'Grupo',
                avaliacao: 'Evaluación',
                indicador: 'Indicador',
                resultado: 'Resultado',
                classificacao: 'Clasificación',
            },
        });

        let item = this.props.newModelo?.provisionalItem;

        const renderChart = () => {
            return (
                <div ref={this.provisionalItemRef} className="add-provisional-item">
                    {item?.chartGroup?.length > 0 ?
                        (
                            item.chartGroup.map((chart, index) => {
                                return (
                                    <div
                                        key={index}
                                        className="chart-demonstration"
                                        style={{ width: DiagnosticoHelper.getWidth(chart.largura) }}
                                    >
                                        <FontAwesomeIcon icon={faChartColumn} className="chart-icon" />
                                        <div className="column">{this.getColunaNome(chart)}</div>
                                        <div className="description">{this.getNodeNome(chart)}</div>
                                    </div>
                                )
                            })
                        )
                        :
                        (<span>{message[this.props.lang].chart}</span>)
                    }
                </div>
            )
        }

        const renderText = () => {

            return (
                <div ref={this.provisionalItemRef} className="add-provisional-item">
                    {this.langHasText(item) ?
                        <div className="text" dangerouslySetInnerHTML={{ __html: this.getText(item) }} />
                        :
                        (<span>{message[this.props.lang].text}</span>)
                    }
                </div>
            )
        }

        const renderPerformance = () => {

            let grupos = this.props.newModelo?.provisionalItem?.grupos || [];

            grupos.sort((a, b) => {
                if (a.drag_index < b.drag_index) return -1;
                if (a.drag_index > b.drag_index) return 1;
                return 0;
            });

            return (
                <div ref={this.provisionalItemRef} className="add-provisional-item">

                    <div style={{ ...EssentialStyle.rowFlexCenter, width: '100%', gap: 10 }}>
                        <span>{message[this.props.lang].performanceInd}</span>
                        <div style={{ fontStyle: 'italic', fontSize: '0.7rem' }}>({message[this.props.lang][item.typeCalc ? item.typeCalc : 'operacao']})</div>
                        <div style={{ fontStyle: 'italic', fontSize: '0.7rem' }}>({message[this.props.lang][item.typeVisualizacao ? item.typeVisualizacao : 'realizado']})</div>
                    </div>

                    {grupos?.length > 0 ?
                        (grupos?.map((grupo, index) => {
                            let indicadores = grupo?.grupo?.indicadores || [];
                            return (
                                <div
                                    key={'provisional-group-' + grupo?.grupo?.id ? grupo?.grupo?.id : 'notGrouped'}
                                    className="groupPerformance-demonstration"
                                    style={{ width: DiagnosticoHelper.getWidth(grupo.largura) }}
                                >
                                    {grupo?.grupo?.textos?.find((text) => { return text.id_idioma == IdiomaHelper.getSiglaId(this.props.lang) })?.descricao || ''}

                                    <div className="indicadoresGrid-demonstration" >
                                        {indicadores?.map((indicador) => {
                                            return (
                                                <div
                                                    key={`indicador-${indicador.id}`}
                                                    className="indicador-demonstration"
                                                    style={{
                                                        width: '14.15vh'
                                                    }}
                                                >
                                                    <FontAwesomeIcon icon={item.chartType == 'donut' ? faChartPie : faGauge} className="chart-icon" />
                                                    <div style={{ width: '100%', maxHeight: '1.5rem', fontSize: '0.7rem' }}><EllipsisText text={indicador.indicadorNome} /></div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            )
                        })
                        )
                        :
                        (null)
                    }
                </div>
            )
        }

        const renderPerformanceTable = () => {
            let grupos = this.props.newModelo?.provisionalItem?.grupos || [];

            grupos.sort((a, b) => {
                if (a.drag_index < b.drag_index) return -1;
                if (a.drag_index > b.drag_index) return 1;
                return 0;
            });

            const renderTableList = (grupos) => {
                return (
                    <div className="table-container">
                        <table>
                            <thead>
                                <tr>
                                    <th>{message[this.props.lang].grupo}</th>
                                    <th>{message[this.props.lang].avaliacao}</th>
                                    <th>{message[this.props.lang].indicador}</th>
                                    <th>{message[this.props.lang].resultado}</th>
                                </tr>
                            </thead>

                            <tbody>
                                {grupos.map((grupo) => {
                                    let indicadores = grupo?.grupo?.indicadores || [];
                                    return indicadores.map((indicador, index) => {
                                        return (
                                            <tr>
                                                {index == 0 &&
                                                    <td rowSpan={indicadores.length}>
                                                        {grupo?.grupo?.textos?.find((text) => { return text.id_idioma == IdiomaHelper.getSiglaId(this.props.lang) })?.descricao || ''}
                                                    </td>
                                                }

                                                {index == 0 &&
                                                    <td rowSpan={indicadores.length}>
                                                        {message[this.props.lang].classificacao}
                                                    </td>
                                                }

                                                <td key={`indicador-${indicador.id}`}>
                                                    <EllipsisText text={indicador.indicadorNome} />
                                                </td>

                                                <td key={`indicador-resultado-${indicador.id}`}>
                                                    --%
                                                </td>
                                            </tr>
                                        )
                                    });
                                })}
                            </tbody>
                        </table>
                    </div>
                )
            }

            const renderTableColuna = (grupos) => {
                return (
                    <div className="table-container">
                        <table>
                            <thead>
                                <tr>
                                    <th>
                                        {message[this.props.lang].grupo}
                                    </th>

                                    {grupos.map((grupo) => {
                                        return (
                                            <th key={`coluna-${grupo.grupo.id}`} colSpan={grupo?.grupo?.indicadores?.length}>
                                                {grupo?.grupo?.textos?.find((text) => { return text.id_idioma == IdiomaHelper.getSiglaId(this.props.lang) })?.descricao || ''}
                                            </th>
                                        )
                                    })}
                                </tr>

                                <tr>
                                    <th>
                                        {message[this.props.lang].avaliacao}
                                    </th>

                                    {grupos.map((grupo) => {
                                        return (
                                            <th key={`coluna-avaliacao-${grupo.grupo.id}`} colSpan={grupo?.grupo?.indicadores?.length}>
                                                {message[this.props.lang].classificacao}
                                            </th>
                                        )
                                    })}
                                </tr>
                            </thead>

                            <tbody>
                                <tr>
                                    <td>
                                        {message[this.props.lang].indicador}
                                    </td>

                                    {grupos.map((grupo) => {
                                        let indicadores = grupo?.grupo?.indicadores || [];
                                        return indicadores.map((indicador) => {
                                            return (
                                                <td key={`indicador-${indicador.id}`}>
                                                    <EllipsisText text={indicador.indicadorNome} />
                                                </td>
                                            )
                                        });
                                    })}
                                </tr>

                                <tr>
                                    <td>
                                        {message[this.props.lang].resultado}
                                    </td>

                                    {grupos.map((grupo) => {
                                        let indicadores = grupo?.grupo?.indicadores || [];
                                        return indicadores.map((indicador) => {
                                            return (
                                                <td key={`indicador-resultado-${indicador.id}`}>
                                                    --%
                                                </td>
                                            )
                                        });
                                    })}
                                </tr>
                            </tbody>
                        </table>
                    </div>
                )
            }

            return (
                <div ref={this.provisionalItemRef} className="add-provisional-item">

                    <div style={{ ...EssentialStyle.rowFlexCenter, width: '100%', gap: 10 }}>
                        <span>{message[this.props.lang].performanceInd}</span>
                        <div style={{ fontStyle: 'italic', fontSize: '0.7rem' }}>({message[this.props.lang][item.typeCalc ? item.typeCalc : 'operacao']})</div>
                        <div style={{ fontStyle: 'italic', fontSize: '0.7rem' }}>({message[this.props.lang][item.typeVisualizacao ? item.typeVisualizacao : 'realizado']})</div>
                    </div>

                    {this.props.newModelo?.provisionalItem?.formatTable == 'linha' ? renderTableList(grupos) : renderTableColuna(grupos)}

                </div>
            )
        }

        const renderResult = () => {

            const renderTableList = () => {

                if (!(item?.tableType == 'lista')) return null;
                if (!item?.list?.colunas || item?.list?.colunas?.length == 0) return (
                    <span>{message[this.props.lang].selectColumn}</span>
                );

                let colunasItem = item.list.colunas;
                colunasItem = colunasItem.sort((a, b) => a.drag_index - b.drag_index);

                return (
                    <div>
                        <table>
                            <thead>
                                <tr>
                                    {colunasItem.map((coluna) => {
                                        return (
                                            <th key={`coluna-${coluna.id}`}>
                                                {coluna.descricao.find((coluna) => coluna.id_idioma == IdiomaHelper.getSiglaId(this.props.lang)).descricao}
                                            </th>
                                        )
                                    })}

                                    <th key={`coluna-result`}>
                                        {message[this.props.lang].thResults}
                                    </th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr>
                                    {colunasItem.map((coluna) => {
                                        return (
                                            <td key={`coluna-item-${coluna.id}`}>
                                                ...
                                            </td>
                                        )
                                    })}

                                    <td key={`coluna-item-result}`}>
                                        --%
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                )
            }

            const renderTableMatriz = () => {

                if (!(item?.tableType == 'matriz')) return null;
                if (!item?.matriz?.coluna_x || !item?.matriz?.coluna_y) return (
                    <span>{message[this.props.lang].selectColumn}</span>
                );

                let sentido = item?.matriz?.coluna_x?.drag_index - item?.matriz?.coluna_y?.drag_index;
                let linhas = 2;
                let colunas = 2;

                if (sentido > 0) { colunas = 3 } else { linhas = 3 }

                linhas = Array.from({ length: linhas }, (_, i) => i);
                colunas = Array.from({ length: colunas }, (_, i) => i);

                let coluna_x = item?.matriz?.coluna_x.descricao.find((coluna) => coluna.id_idioma == IdiomaHelper.getSiglaId(this.props.lang)).descricao;
                let coluna_y = item?.matriz?.coluna_y.descricao.find((coluna) => coluna.id_idioma == IdiomaHelper.getSiglaId(this.props.lang)).descricao;

                return (
                    <table>
                        <thead>
                            <tr>
                                <th />
                                {colunas.map((coluna, indexLinha) => {
                                    return (
                                        <th key={`header-col-${indexLinha}`}>
                                            {coluna_y}
                                        </th>
                                    )
                                })}
                            </tr>
                        </thead>

                        <tbody>
                            {linhas.map((linha, indexLinha) => {
                                return (
                                    <tr key={`row-${indexLinha}`}>
                                        <th>
                                            {coluna_x}
                                        </th>

                                        {colunas.map((coluna, indexColuna) => {
                                            return (
                                                <td key={`cell-${indexLinha}-${indexColuna}`}>
                                                    --%
                                                </td>
                                            )
                                        })}
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                )
            }

            return (
                <div ref={this.provisionalItemRef} className="add-provisional-item">
                    <span style={{ marginBottom: 5 }}>{message[this.props.lang].result}</span>

                    <div className="tableResults-demonstration">
                        {renderTableList()}
                        {renderTableMatriz()}
                    </div>
                </div>
            )
        }

        const renderDivider = () => {
            return (
                <div ref={this.provisionalItemRef} className="add-provisional-item">
                    <div className="content box">
                        <span style={{ flex: 1, height: 2, backgroundColor: '#c3c3c3' }}></span>
                        <FontAwesomeIcon icon={faCircle} style={{ fontSize: 5, margin: '0px 5px' }} />
                        <span style={{ flex: 1, height: 2, backgroundColor: '#c3c3c3' }}></span>
                    </div>
                </div>
            )
        }

        if (item?.on) {
            switch (item.type) {
                case 'chart':
                    return renderChart();
                case 'text':
                    return renderText();
                case 'performance':
                    return renderPerformance();
                case 'performanceTable':
                    return renderPerformanceTable();
                case 'results':
                    return renderResult();
                case 'divider':
                    return renderDivider();
                default:
                    return <></>;
            }
        }
    }

    renderAddLineDemonstration() {

        if (this.props.blockEdit) return;

        return (
            <div className="exemplo-add-line">
                <span className="line"></span>
                <FontAwesomeIcon icon={faPlus} className="plus-icon" />
                <span className="line"></span>
            </div>
        )
    }

    render() {

        let message = IdiomaHelper.getStruct({
            'pt_br': { title: 'Nome do Modelo', cancelar: 'Cancelar', salvar: 'Salvar', header: 'Cabeçalho', observation: 'Observação', recomendation: 'Recomendação' },
            'en': { title: 'Template Name', cancelar: 'Cancel', salvar: 'Save', header: 'Header', observation: 'Observation', recomendation: 'Recomendation' },
            'es': { title: 'Nombre del Modelo', cancelar: 'Cancelar', salvar: 'Guardar', header: 'Encabezado', observation: 'Observación', recomendation: 'Recomendación' },
        });

        return (
            <div className="PreviwerModeloRelatorio folder">
                <div className="exemplo-fixed">{message[this.props.lang].header}<span>{this.props.newModelo.nome}</span></div>
                {this.renderItems()}
                {this.renderProvisionalItem()}
                {this.renderAddLineDemonstration()}
                <div className="exemplo-fixed">{message[this.props.lang].recomendation}</div>
                <div className="exemplo-fixed">{message[this.props.lang].observation}</div>
            </div>
        )

    }
}