import React from "react";
import DefaultLoader from "../../../../../tools/DefaultLoader";
import { withTranslation } from 'react-i18next';
import Sig from "../../../../../../api/Sig";

import './HistoricoQuestao.css';
import UserAvatar from "../../../../../tools/UserAvatar";

class HistoricoQuestao extends React.Component {
    state = {
        loading: true,
        historico: []
    }

    async componentDidMount() {
        await this.props.i18n.loadNamespaces(['client_src_components_modules_diagnostico_Avaliacao_Aplicacao_HistoricoQuestao_HistoricoQuestao']);
        
        await this.loadHistorico();
    }

    async loadHistorico() {
        this.setState({loading: true});
        
        let {historico} = await Sig.request('POST', `diagnostico/avaliacao/getHistoricoNode`, { hash: this.props.hash, idNode: this.props.questao.id });
        
        this.setState({historico: historico || [], loading: false });
    }

    componentDidUpdate(prevProps) {
        if(prevProps.refresh != this.props.refresh && this.props.refresh) {
            this.loadHistorico();
        }
    }

    renderLoadingHistorico() {
        return (
            <div className="loading-historico">
                <DefaultLoader/>
            </div>
        )
    }

    renderHeader() {
        return (
            <div className="header-historico-content">
                <div className="header-historico-title">{this.props.t('client_src_components_modules_diagnostico_Avaliacao_Aplicacao_HistoricoQuestao_HistoricoQuestao:historico_alteracoes')}</div>
            </div>
        )
    }

    renderHistorico() {
        return (
            <div className="historico-content">
                {this.state.historico?.length > 0 ? this.state.historico.map((historico, index) => {
                    return (
                        <div key={index} className="historico-item">
                            <div className="historico-item-content">
                                <div className="historico-item-usuario">
                                    <UserAvatar user={historico.responsavel} showName showNameAdaptative/>
                                </div>
                                <div className="historico-item-data">
                                    {new Date(historico.data).toLocaleDateString()} - {new Date(historico.data).toLocaleTimeString()}
                                </div>
                            </div>
                            <div className="historico-item-texto">
                                {historico.texto}
                            </div>
                        </div>
                    )
                })
                    :
                    <div className="historico-empty">{this.props.t('client_src_components_modules_diagnostico_Avaliacao_Aplicacao_HistoricoQuestao_HistoricoQuestao:historico_vazio')}</div>
                }
            </div>
        )
    }

    renderDetailing() {
        return (
            <div className={ (this.props.isSmallContainer ? "mobile " : "") + "historico" }>
                    { this.state.loading && this.renderLoadingHistorico()}
                    {!this.state.loading && this.renderHeader()}
                    {!this.state.loading && this.renderHistorico()}
            </div>
        )
    }

    render() {
        return this.renderDetailing();
    }
}

export default withTranslation()(HistoricoQuestao);