import React from 'react';
import Sig from "../../../../../api/Sig";
import DefaultButton from '../../../../tools/DefaultButton';
import Colors from '../../../../../constants/Colors';
import EssentialStyle from '../../../../../style/EssentialStyle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusSquare } from '@fortawesome/free-solid-svg-icons';
import PlanoAcaoCardRelatorio from '../../../planoAcao/PlanoAcaoRelatorio/PlanoAcaoCardRelatorio';
import ModalVinculaTopico from './ModalVinculaTopico';

export default class AtaReuniaoTopicoPlanos extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      adding: false,
      newTarefaDescricao: "",
      modalAddPlano: false,
      planos: [],
    };
    this.addTarefaInput = React.createRef();
  }

  async componentDidMount() {
    if (this.props.planos.length === 0) {
      this.loadPlanos();
    } else {
      this.setState({ planos: this.props.planos });
    }
  }

  async componentDidUpdate(prevProps) {
    if (this.props.planos && this.props.planos.length > prevProps.planos.length) {
      this.setState({ planos: this.props.planos });
    }
  }

  loadPlanos = async () => {
    
    let req = await Sig.request('GET', `ataReuniao${this.props.hash ? "Externa" : ""}/getPlanosTopico`, { 
      id_ata_reuniao_topico: this.props.idTopico,
      hash: this.props.hash 
    });
    if (req.status === 200) {
      this.setState({ planos: req.planos });
    }

  }

  renderCreatePlano() {

    if (this.props.blockEdit || this.props.hash) return null;
    return (
      <>
        <DefaultButton
          onClick={() => { this.setState({ adding: true }) }}
          leftIcon={<FontAwesomeIcon icon={faPlusSquare} />}
          color={Colors.success}
          tooltipPlacement={'bottom'}
          title={"Adicionar Plano de Ação"}
        />
        {this.renderModalAddPlano()}
      </>
    );
  }

  renderModalAddPlano() {

    if (!this.state.adding) return null;

    const closeModal = async () => {
      this.setState({ adding: false });
      await this.props.addPlanoCallback();
    }

    return (
      <ModalVinculaTopico
        idTopico={this.props.idTopico}
        idAtaReuniao={this.props.idAtaReuniao}
        closeModal={async () => closeModal()}
      />
    )
  }

  render() {
    if (!this.state.planos || this.state.planos.length === 0) {
      return (
        <div style={{ ...EssentialStyle.columnStart, width: "100%", padding: "15px 0px", gap: 10, alignItems: "center" }}>
          {!this.state.adding &&
            <span style={{ fontSize: 20, color: Colors.homePage.grey }}>Nenhum Plano de Ação encontrado</span>
          }

          {this.props.canEdit &&
            this.renderCreatePlano()
          }
          
        </div>
      );
    }

    return (
      <div style={{ ...EssentialStyle.columnStart, padding: "15px 0px", width: "100%", alignItems: "center", gap: 10 }}>
        {
          this.state.planos.map((plano, index) => {
            return (
              <PlanoAcaoCardRelatorio key={`plano-acao-card-${plano.id}-${index}`} plano={plano} onClick={(codigo) => window.parent.postMessage({ type: '_blank', url: `/planoAcao/detalhar/${codigo}` }, '*')} displayEtapas={true} isSmallScreen={this.props.isSmallScreen} noMargin />
            )
          })
        }
        {this.props.canEdit && this.renderCreatePlano()}
      </div>
    );
  }
}