import React from "react";
import ReactDOM from 'react-dom';

import DataHelper from "../../../../../helper/DataHelper";
import IdiomaHelper from "../../../../../helper/IdiomaHelper";
import DiagnosticoHelper from "../../../../../helper/diagnostico/DiagnosticoHelper";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsis, faChevronDown, faCalendar, faPencil } from '@fortawesome/free-solid-svg-icons';
import { Dropdown, Collapse } from 'react-bootstrap';
import { toast } from "react-toastify";
import { confirmAlert } from 'react-confirm-alert';
import Colors from "../../../../../constants/Colors";
import CustomConfirm from "../../../../tools/CustomConfirm";
import moment from "moment";
import DefaultLoader from "../../../../tools/DefaultLoader";

import './ProcessoCard.css';

export default class ProcessoCard extends React.Component {

    state = {
        loadingProcesso: true,
        processo: {},
        isOpen: false,
    }

    componentDidMount() {
        this.loadProcesso();
    }

    loadProcesso = async () => {
        
        this.setState({ loadingProcesso: true });

        let processo = {};

        if ( this.props.id ) {
            processo = await DiagnosticoHelper.getProcesso(this.props.id);
        } else {
            processo = this.props.processo;
        }

        this.setState({ processo, loadingProcesso: false });
    }

    deletePeriodoAvaliacao = async () => {
        let message = IdiomaHelper.getStruct({
            'pt_br': {
                title: 'Deletar Processo',
                message: `
                    <div>
                        <p><strong>Deseja realmente deletar este Processo?</strong></p>
                        <p>Ao deletar este Processoo, todos os dados relacionados a ele serão perdidos, incluindo:</p>
                        <ul>
                            <li>Períodos de Avaliação</li>
                            <li>Questionários</li>
                            <li>Avaliações</li>
                            <li>Relatórios</li>
                            <li>Todos os outros dados relacionados a este período de avaliação</li>
                        </ul>
                        <p style={{color:'red'}}>Esta ação é irreversível.</p>
                    </div>
                `,
                confirm: 'Deletar',
                cancel: 'Cancelar',
                success: 'Processo deletado com sucesso',
                error: 'Erro ao deletar Processo',
            },
            'en': {
                title: 'Delete Process',
                message: `
                    <div>
                        <p><strong>Do you really want to delete this Process?</strong></p>
                        <p>By deleting this Process, all related data will be lost, including:</p>
                        <ul>
                            <li>Periods of Evaluation</li>
                            <li>Questionnaires</li>
                            <li>Evaluations</li>
                            <li>Reports</li>
                            <li>All other data related to this Process</li>
                        </ul>
                        <p style={{color:'red'}}>This action is irreversible.</p>
                    </div>
                `,
                confirm: 'Delete',
                cancel: 'Cancel',
                success: 'Process deleted successfully',
                error: 'Error deleting Process',
            },
            'es': {
                title: 'Eliminar Proceso',
                message: `
                    <div>
                        <p><strong>¿Realmente desea eliminar este Proceso?</strong></p>
                        <p>Al eliminar este Proceso, se perderán todos los datos relacionados, incluidos:</p>
                        <ul>
                            <li>Períodos de Evaluación</li>
                            <li>Cuestionarios</li>
                            <li>Evaluaciones</li>
                            <li>Informes</li>
                            <li>Todos los demás datos relacionados con este Proceso</li>
                        </ul>
                        <p style={{color:'red'}}>Esta acción es irreversible.</p>
                    </div>
                `,
                confirm: 'Eliminar',
                cancel: 'Cancelar',
                success: 'Proceso eliminado con éxito',
                error: 'Error al eliminar el Proceso',
            }
        });
        
        confirmAlert({
            customUI: ({ onClose }) => (
                <CustomConfirm
                    title={message[this.props.lang].title}
                    message={<div dangerouslySetInnerHTML={{ __html: message[this.props.lang].message }} style={{ marginTop: 10, marginBottom: 10 }} />}
                    buttons={[
                        {
                            label: message[this.props.lang].confirm,
                            color: Colors.error,
                            textColor: Colors.light,
                            onClick: async () => {
                                if (window.confirm(message[this.props.lang].title)) {
                                    let response = await DiagnosticoHelper.deleteProcesso(this.state.processo.id);
                                    if (response) {
                                        this.props.onDelete();
                                        toast.success(message[this.props.lang].success);
                                    } else {
                                        toast.error(message[this.props.lang].error);
                                    }

                                    onClose();
                                } else {
                                    onClose();
                                }
                            }
                        },
                        {
                            label: message[this.props.lang].cancel,
                            onClick: () => { onClose(); }
                        },
                    ]}
                />
            )
        });
    }

    editPeriodoAvaliacao = async () => {
        let message = IdiomaHelper.getStruct({
            'pt_br': {
                title: 'Editar Processo',
                message: 'Você realmente deseja editar este Processo?',
                confirm: 'Editar',
                cancel: 'Cancelar',
            },
            'en': {
                title: 'Edit Process',
                message: 'Do you really want to edit this Process?',
                confirm: 'Edit',
                cancel: 'Cancel',
            },
            'es': {
                title: 'Editar Proceso',
                message: '¿Realmente deseas editar este Proceso?',
                confirm: 'Editar',
                cancel: 'Cancelar',
            }
        });
        
        confirmAlert({
            customUI: ({ onClose }) => (
                <CustomConfirm
                    title={message[this.props.lang].title}
                    message={<div dangerouslySetInnerHTML={{ __html: message[this.props.lang].message }} style={{ marginTop: 10, marginBottom: 10 }} />}
                    buttons={[
                        {
                            label: message[this.props.lang].confirm,
                            color: Colors.danger,
                            textColor: Colors.light,
                            onClick: async () => {
                                this.props.onEdit(this.state.processo.id);
                                onClose();
                            }
                        },
                        {
                            label: message[this.props.lang].cancel,
                            onClick: () => { onClose(); }
                        },
                    ]}
                />
            )
        });
    }

    // ===== Funções de Render =====

    renderTitle() {
        return (
            <div className={`title ${this.state.loadingProcesso ? 'loading' : ''}`}>
                {!this.state.loadingProcesso && DataHelper.removeHtmlTags(this.state.processo.nome)}
            </div>
        )
    }

    renderTagsInfos() {
        const { lang } = this.props;
        const { loadingProcesso, processo } = this.state;
        const { canEdit, canView } = processo;
        
    
        const message = IdiomaHelper.getStruct({
            'pt_br': { sing: ' Periodo de Avaliação', mult: ' Periodos de Avaliação', create: 'Criar' },
            'en': { sing: ' Assessment Period',  mult: ' Assessment Periods', create: 'create' },
            'es': { sing: ' Período de evaluación',  mult: ' Períodos de evaluación', create: 'crear' },
        });

        const tagPeriodos = () => {
            const nPeriodos = processo?.periodos?.length || 0;
            const tagMessage = nPeriodos != 1 ? message[lang]?.mult : message[lang]?.sing;

            if (nPeriodos == 0 && !loadingProcesso && canEdit) {
                return (
                    <span 
                        className="tag-info create"
                        onClick={() => { window.parent.postMessage({ type: '_blank', url: `/diagnostico/periodoAvaliacao/${this.state.processo.id}?tipo=novo` }, '*')}}
                    >
                        <FontAwesomeIcon icon={faPencil}/>
                        {message[lang]?.create}
                        {message[lang]?.sing}
                    </span> 
                )
            } else if (canView) {
                return (
                    <div className="tag-info">
                        {loadingProcesso ? <DefaultLoader size={20}/> : nPeriodos}
                        {tagMessage}
                    </div>
                );
            }
        }
        
        return (
            <>
                {tagPeriodos()}
            </>
        )
    }

    renderOptions = () => {
        const portalTarget = document.getElementById('root');

        if (!this.state.processo.canEdit) return (null);
        return (
            <Dropdown className="optionsProjeto" onClick={(e) => { e.stopPropagation() }}>
                <Dropdown.Toggle
                    onChange={() => {}}
                    role="button"
                    style={{ 
                            backgroundColor: Colors.secondaryButton, 
                            color: Colors.dark, 
                            border: 'none',
                            zIndex: 25
                    }}
                    drop={'top'}
                > 
                    <FontAwesomeIcon icon={faEllipsis} />
                </Dropdown.Toggle>
                {ReactDOM.createPortal(
                    <Dropdown.Menu style={{padding: 2, color: Colors.dark}}> 
                        <Dropdown.Item 
                            key={`add-vinculpo-btn`} 
                            onClick={this.editPeriodoAvaliacao}
                        >
                                <span style={{ fontSize: 14 }}>Editar Processo</span>
                        </Dropdown.Item>

                        <Dropdown.Item 
                            key={`delete-periodo-btn`} 
                            onClick={() => {this.deletePeriodoAvaliacao()}}
                        >
                                <span style={{ fontSize: 14 }}>Deletar Processo</span>
                        </Dropdown.Item>
                    </Dropdown.Menu>
                , portalTarget)}
            </Dropdown>
        );
    }

    renderDate() {
        return (
            <div className="date">
                <FontAwesomeIcon icon={faCalendar} style={{ color: Colors.dark, fontSize: 14, marginRight: 4 }} />
                <span>
                    {this.state.processo?.data_inicio ? moment(this.state.processo?.data_inicio).format('MM/YYYY') : "" }
                    {` ~ `}
                    {this.state.processo?.data_fim ? moment(this.state.processo?.data_fim).format('MM/YYYY') : ""}
                </span>
            </div>
        );
    }

    renderDescription() {

        let message = IdiomaHelper.getStruct({
            'pt_br': { description: 'Descrição'   },
            'en': { description: 'Description' },
            'es': { description: 'Descripción' },
        });

        let descricao = this.state.processo?.descricao || '';
        let isOpen = this.state.isOpen;

        if (this.state.loadingProcesso || !descricao || DataHelper.removeHtmlTags(descricao) == '') return (null);

        return (
            <div className={`description`}>
                <div className="title" onClick={() => {this.setState({ isOpen: !this.state.isOpen })}}>
                    {message[this.props.lang].description}
                    <FontAwesomeIcon
                        icon={faChevronDown}
                        style={{ 
                            transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)', 
                            transition: 'transform 0.3s ease' 
                        }}
                    />
                </div>
                <Collapse in={isOpen}>
                    <div className="content"  dangerouslySetInnerHTML={{ __html: this.state.processo?.descricao }}/>
                </Collapse>
            </div>
        )
    }

    renderCard() {
        return (
            <div className="ProcessoCard" style={{boxShadow: Colors.boxShadow}}>
                <div className="card-top">
                    <div className="card-left">
                        {this.renderTitle()}
                        {this.renderTagsInfos()}
                    </div>

                    <div className="card-right">
                        {this.renderOptions()}
                        {this.renderDate()}
                    </div>
                </div>
                <div className="card-down">
                    {this.renderDescription()}
                </div>
            </div>
        )
    }

    render() {
        return this.renderCard();
    }
}