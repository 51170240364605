import React, { forwardRef } from "react";

import TooManyAvatars from "../../../../tools/TooManyAvatars";
import LayoutHelper from "../../../../../helper/LayoutHelper";
import DataHelper from "../../../../../helper/DataHelper";
import LoadingPage from "../../../../../pages/LoadingPage";
import DiagnosticoHelper from "../../../../../helper/diagnostico/DiagnosticoHelper";
import DefaultButton from "../../../../tools/DefaultButton";
import Colors from "../../../../../constants/Colors";
import Select from 'react-select';
import TextEditor from "../../../../tools/TextEditor/TextEditor";
import { Form, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faArrowRight, faPrint, faPaperPlane, faPlusSquare, faBars, faColumns } from "@fortawesome/free-solid-svg-icons";
import { toast } from 'react-toastify';

import './Relatorio.css';
import DefaultLoader from "../../../../tools/DefaultLoader";

import 'moment/locale/es';
import 'moment/locale/pt';
import UnderConstruction from "../../../../tools/UnderConstruction";
import EssentialStyle from "../../../../../style/EssentialStyle";
import IdiomaHelper from "../../../../../helper/IdiomaHelper";

import { confirmAlert } from "react-confirm-alert";
import CustomConfirm from "../../../../tools/CustomConfirm";
import UserAvatar from "../../../../tools/UserAvatar";
import SessionHelper from "../../../../../helper/SessionHelper";

import RelatorioTextItem from "./RelatorioTextItem/RelatorioTextItem";
import RelatorioGraficosItem from "./RelatorioGraficosItem/RelatorioGraficosItem";
import RelatorioRecomendacao from "./RelatorioRecomendacao/RelatorioRecomendacao";
import RelatorioObservacao from "./RelatorioObservacao/RelatorioObservacao";
import RelatorioDividerItem from "./RelatorioDividerItem/RelatorioDividerItem";
import RelatorioPerformanceItem from "./RelatorioPerformanceItem/RelatorioPerformanceItem";
import RelatorioPerformanceTabelaItem from "./RelatorioPerformanceTabelaItem/RelatorioPerformanceTabelaItem";
import RelatorioTabelaResultadoItem from "./RelatorioTabelaResultadoItem/RelatorioTabelaResultadoItem";
import BarraDemonstracaoPeriodo from "../../../../tools/BarraDemonstracaoPeriodo";
import { withTranslation } from 'react-i18next';

import DatePicker from 'react-datepicker';
import moment from 'moment';


class Relatorio extends React.Component {

    constructor(props) {
        super(props);
        this.headerRef = React.createRef();
        this.headerResizeObserver = null;
        this.timeout = null;
    }

    state = {
        construction: false,
        loading: true,

        idRelatorio: this.props.id,
        relatorio: {},
        lang: this.props.lang || IdiomaHelper.getIdioma(),

        loadAddRecomendacaoSelect: false,
        loadAddRecomendacao: true,
        loadRecomendacao: false,
        addingRecomendation: false,

        loadAddObservation: false,
        addingObservation: false,

        colunas: [],
        colunasQuestionario: [],
        colunaSelected: null,
        nodes: [],
        nodesByNodePai: [],
        nodeSelected: null,
        description: '',
        observation: '',

        ColumnRecomendacoes: 2,
        ColumnObservations: 1,

        isSmallScreen: LayoutHelper.isSmallScreen(),
        scrolled: false,
        headerSize: 0,
        headerUnscrolledSize: 0,
    }

    async componentDidMount() {
        await this.props.i18n.loadNamespaces(['client_src_helper_diagnostico_DiagnosticoHelper']);

        await this.loadData();
        this.verifyScreen();
        window.addEventListener('resize', this.verifyScreen);
        window.addEventListener('message', this.handleMessage);
        window.addEventListener('scroll', this.handleScroll);
        this.timeout = setTimeout(this.setObserver, 500);
    }

    setObserver = () => {
        if(this.headerRef?.current && this.headerResizeObserver == null){
            this.headerResizeObserver = new ResizeObserver(this.handleResizeHeader);
            this.headerResizeObserver.observe(this.headerRef.current);
            this.setState({ headerSize: this.headerRef.current.offsetHeight, headerUnscrolledSize: this.headerRef.current.offsetHeight });
        } else {
            if(this.headerResizeObserver == null){
                this.timeout = setTimeout(this.setObserver, 500);
            }
        }
    }
    
    async componentWillUnmount() {
        window.removeEventListener('resize', this.verifyScreen);
        window.removeEventListener('message', this.handleMessage);
        window.removeEventListener('scroll', this.handleScroll);
        if(this.headerResizeObserver){
            this.headerResizeObserver.disconnect();
        }
    }

    verifyScreen = () => {
        if(this.state.isSmallScreen != LayoutHelper.isSmallScreen())
            this.setState({ isSmallScreen: LayoutHelper.isSmallScreen() });
    }

    handleResizeHeader = (entries) => {
        for (let entry of entries) {
            if(entry.target.offsetHeight != this.state.headerSize){
                this.setState({ headerSize: entry.target.offsetHeight });
            }

            if(!this.state.scrolled && entry.target.offsetHeight != this.state.headerUnscrolledSize){
                this.setState({ headerUnscrolledSize: entry.target.offsetHeight });
            }
        }
    }

    handleScroll = () => {
        if (!this.state.scrolled && window.scrollY > 200) {
            this.setState({ scrolled: true });
            return;
        }
        
        if(this.state.scrolled && window.scrollY <= 150){
            if(this.state.scrolled) this.setState({ scrolled: false });
        }
    }

    async loadData() {
        this.setState({ loading: true });

        await this.loadRelatorio();

        this.setState({ loading: false }, () => {
            this.loadColunas()
        });
    }

    async loadRelatorio() {
        let relatorio = await DiagnosticoHelper.getRelatorio(this.state.idRelatorio, 1, this.state.lang);
        this.setState({ relatorio, data_performance: moment(relatorio.data_performance), data_processo: moment(relatorio.data_processo) });
    }

    async loadColunas() {
        this.setState({ loadAddRecomendacao: true });
        let { colunas, colunasQuestionario } = await DiagnosticoHelper.getColunasQuestionarioForRelatorio(this.state.idRelatorio, this.state.lang);
        this.setState({ colunas, colunasQuestionario, loadAddRecomendacao: false });
    }

    async loadLang() {
        this.setState({ colunas: [], colunaSelected: null, nodes: [], nodeSelected: null });
        await this.loadData();
    }    

    renderPage() {
        return (
            <div className={`Relatorio`} style={{ '--background-color': SessionHelper.getBackgroundColor() }}>
                {this.state.scrolled && <span style={{zIndex: 999, height: this.state.headerSize + 15, width: "100%", backgroundColor: SessionHelper.getBackgroundColor(), position: "fixed", top: 0, left: 0}}/>}
                {this.renderHeader()}
                {this.renderLayout()}
                {this.renderRecomendacoes()}
                {this.renderObservacoes()}
            </div>
        );
    }

    renderHeader() {
        let message = IdiomaHelper.getStruct({
            'pt_br': {
                back: 'Voltar', print: 'Imprimir', process: 'Processo', period: 'Período', evaluators: 'Avaliadores', status: 'Status', companyEvaluator: 'Empresa Avaliadora', companyEvaluated: 'Empresa Avaliada', publish: 'Publicar',
            },
            'en': {
                back: 'Back', print: 'Print', process: 'Process', period: 'Period', evaluators: 'Evaluators', status: 'Status', companyEvaluator: 'Company Evaluator', companyEvaluated: 'Company Evaluated', publish: 'Publish',
            },
            'es': {
                back: 'Volver', print: 'Imprimir', process: 'Proceso', period: 'Período', evaluators: 'Evaluadores', status: 'Estado', companyEvaluator: 'Empresa Evaluadora', companyEvaluated: 'Empresa Evaluada', publish: 'Publicar',
            }
        });

        const publishRelatorio = async () => {
            let message = IdiomaHelper.getStruct({
                'pt_br': {
                    title: 'Publicar Relatório',
                    message: 'Deseja realmente publicar o relatório? Essa ação não pode ser desfeita',
                    confirm: 'Publicar',
                    cancel: 'Cancelar',
                },
                'en': {
                    title: 'Publish Report',
                    message: 'Do you really want to publish the report? This action cannot be undone',
                    confirm: 'Publish',
                    cancel: 'Cancel',
                },
                'es': {
                    title: 'Publicar Informe',
                    message: '¿Realmente desea publicar el informe? Esta acción no se puede deshacer',
                    confirm: 'Publicar',
                    cancel: 'Cancelar',
                }
            });
            
            confirmAlert({
                customUI: ({ onClose }) => (
                    <CustomConfirm
                        title={message[this.state.lang].title}
                        message={<p style={{ marginTop: 10, marginBottom: 10}}>{message[this.state.lang].message}</p>}
                        buttons={[
                            {
                                label: message[this.state.lang].confirm,
                                color: Colors.success,
                                textColor: Colors.light,
                                onClick: async () => {
                                    
                                    if (!await DiagnosticoHelper.publishRelatorio(this.state.idRelatorio)) {
                                        toast.error('Erro ao publicar relatório');
                                        return;
                                    }
                                    this.loadData();
                                    onClose();
                                }
                            },
                            {
                                label: message[this.state.lang].cancel,
                                onClick: () => { onClose(); }
                            },
                        ]}
                    />
                ),
                overlayClassName: 'relatorio-publish-confirm'
            });
        }

        const renderDataIndicadores = (tipoIndicador) => {

            let relatorio = this.state.relatorio;
            if (!relatorio.tem_indicadores) return <></>;

            let frequencia = relatorio['frequencia_'+tipoIndicador];

            let message = IdiomaHelper.getStruct({
                'pt_br': {
                    performance: 'Atualização de indicadores de Performance: ',
                    processo: 'Atualização de indicadores de Processo: ',
                },
                'en': {
                    performance: 'Performance indicators update: ',
                    processo: 'Process indicators update: ',
                },
                'es': {
                    performance: 'Actualización de indicadores de rendimiento: ',
                    processo: 'Actualización de indicadores de proceso: ',
                }
            });

            const DatePickerComponent = forwardRef(({ value, onClick }, ref) => (
                <Form.Control
                    onChange={() => { }}
                    onClick={onClick}
                    ref={ref}
                    style={{ 
                        cursor: frequencia == 0 || frequencia == null ? 'not-allowed' : 'pointer',
                        fontSize: 14, 
                        color: Colors.dark, 
                        fontWeight: 'bold', 
                        boxShadow: 'none', 
                        backgroundColor: Colors.homePage.lightGrey, 
                        padding: 1, 
                        height: '100%', 
                        minWidth: '100%', 
                        textAlign: 'center', 
                        zIndex: 1 
                    }}
                    value={value} >
                </Form.Control>
            ));
    
            return (
                <div className="infos" style={{...EssentialStyle.rowFlexEnd, gap: 10}}>
                    {message[this.state.lang][tipoIndicador]}
                    <div style={{
                        ...EssentialStyle.rowFlexCenter,
                        width: '5rem',
                    }}>
                        <DatePicker
                            selected={this.state['data_'+tipoIndicador] ? moment(this.state['data_'+tipoIndicador]).toDate() : null}
                            type="date"
                            onChange={(date) => {
                                this.setState({ ['data_'+tipoIndicador]: moment(date) }, () => {
                                });
                            }}
                            dateFormat="MM/yyyy"
                            showMonthYearPicker
                            customInput={<DatePickerComponent />}
                            popperPlacement="top-start"
                            disabled={frequencia == 0 || frequencia == null}
                            maxDate={new Date()}
                            minDate={new Date(relatorio.data_inicio_periodo)}
                        />
                    </div>
                </div>
                
            )
        }

        return (
            <>
                {this.state.scrolled && <span style={{height: this.state.headerUnscrolledSize, width: "100%"}}/>}
                <div className="header" style={{ boxShadow: Colors.boxShadow }} ref={this.headerRef}>
                    {/* Título */}
                    <div className="line">
                        <DefaultButton
                            tooltip={message[this.state.lang].back}
                            tooltipPlacement={'bottom'}
                            leftIcon={<FontAwesomeIcon icon={faChevronLeft} />}
                            color={Colors.secondaryButton}
                            textColor={Colors.dark}
                            loading={this.state.loading}
                            onClick={() => { this.props.closeRelatorio() }}
                        />

                        <div style={{ flex: 1, textAlign: 'center', padding: '0px 20px 0px 20px' }}>
                            <div className="nomeRelatorio">{this.state.relatorio.nome}</div>
                        </div>

                        {/* <DefaultButton
                            tooltip={message[this.state.lang].print}
                            tooltipPlacement={'bottom'}
                            leftIcon={<FontAwesomeIcon icon={faPrint} />}
                            color={Colors.secondaryButton}
                            textColor={Colors.dark}
                            loading={this.state.loading}
                            onClick={() => { toast.info('Função em desenvolvimento - Function under development - Función en desarrollo') }}
                        /> */}
                        <div style={{width: 38}}/>
                    </div>

                    {/* Subtítulo */}
                    <div className="line" style={{minHeight: 35}}>
                        {
                            this.state.scrolled && this.state.relatorio?.canPublish &&
                            <div className="infos" style={{position: 'absolute', left: 0, top: 0, width: 'auto'}}>
                                    <DefaultButton
                                    title={message[this.state.lang].publish}
                                    leftIcon={<FontAwesomeIcon icon={faPaperPlane} />}
                                    color={Colors.success}
                                    textColor={Colors.white}
                                    loading={this.state.loading}
                                    onClick={() => { publishRelatorio() }}
                                />
                            </div>
                        }
                        <div className="subTitle">
                            <div>{message[this.state.lang].process}: {this.state.relatorio.diagnosticoProcesso.nome} | {message[this.state.lang].period}: {this.state.relatorio.diagnosticoPeriodo.nome}</div>
                        </div>
                    </div>
                    
                    {/* Avaliadores | Status e Data */}
                    {!this.state.scrolled && 
                    <>
                        <div className="line">
                            <div className="infos">
                                <div className="info">
                                    <span style={{ marginRight: 10 }}>{message[this.state.lang].evaluators}:</span> <TooManyAvatars participantsTotal={this.state.relatorio?.diagnosticoAvaliacaoAvaliadores?.langth || 0} participantsVisiveis={10} arrayParticipants={this.state.relatorio?.diagnosticoAvaliacaoAvaliadores || []} />
                                </div>
                                <div className="info">
                                    <span style={{ marginRight: 5 }}>
                                        {message[this.state.lang].status}: 
                                    </span> 
                                    {DiagnosticoHelper.renderStatusRelatorio(this.state.relatorio.status, this.props.t, {marginRight: 5})} 
                                    |
                                    {DiagnosticoHelper.renderDataRelatorio(this.state.relatorio.data_cadastro, this.state.lang, {marginLeft: 5})}
                                </div>
                            </div>
                        </div>

                        {/* Empresa Avaliada | Data Performance */}
                        <div className="line">
                            <div className="infos" style={{ justifyContent: 'start', gap: 10 }}>
                                <div className="info">{message[this.state.lang].companyEvaluator}: {this.state.relatorio.avaliador.nome_fantasia}</div>
                                <FontAwesomeIcon icon={faArrowRight} />
                                <div className="info">{message[this.state.lang].companyEvaluated}: {this.state.relatorio.avaliado.nome_fantasia}</div>
                            </div>

                            {renderDataIndicadores('performance')}
                        </div>
                        
                        {/* Barra de Faixas | Data Processo */}
                        <div className="line">
                            <div className="infos">
                                <BarraDemonstracaoPeriodo 
                                    idPeriodo={this.state.relatorio.id_periodo} 
                                    width={'100%'}
                                    marginTop={'0px'}
                                    loading={this.state.loading}
                                    lang={this.state.lang}
                                    style={{ height: 35, marginTop: 0 }}
                                />
                            </div>

                            {renderDataIndicadores('processo')}
                        </div>
                        
                        {/* Ações | Tipo de Avaliação */}
                        <div className="line">
                            <div className="infos" style={{width: 'calc(100% - 100px)'}}>
                                {this.state.relatorio.canPublish &&
                                    <DefaultButton
                                        title={message[this.state.lang].publish}
                                        leftIcon={<FontAwesomeIcon icon={faPaperPlane} />}
                                        color={Colors.success}
                                        textColor={Colors.white}
                                        loading={this.state.loading}
                                        onClick={() => { publishRelatorio() }}
                                    />}
                            </div>

                            {DiagnosticoHelper.renderTipoAvaliacao(this.state.relatorio.tipo_avaliacao, this.props.t)}
                        </div>
                    </>}
                </div>
            </>
        );
    }

    renderLayout() {

        let itemsLayout = this.state.relatorio?.items?.sort((a, b) => a.dragIndex - b.dragIndex);
        return (
            <>
                {itemsLayout.map((item) => {
                    switch (item.type) {
                        case 'text':
                            return (
                                <React.Fragment key={`${item.id}-${item.type}`}>
                                    <RelatorioTextItem
                                        item={item}
                                        onChange={(value) => { this.changeText(item, value) }}
                                        canEdit={this.state.relatorio.canEdit}
                                        status={this.state.relatorio.status}
                                        lang={this.state.lang}
                                        isSmallScreen={this.state.isSmallScreen}
                                    />
                                </React.Fragment>
                            )
                        case 'chart':
                            return (
                                <React.Fragment key={`${item.id}-${item.type}`}>
                                    <RelatorioGraficosItem
                                        item={item}
                                        lang={this.state.lang}
                                        isSmallScreen={this.state.isSmallScreen}
                                        data_processo={this.state.data_processo}
                                    />
                                </React.Fragment>
                            )
                        case 'divider':
                            return (
                                <React.Fragment key={`${item.id}-${item.type}`}>
                                    <RelatorioDividerItem/>
                                </React.Fragment>
                            )
                        case 'performance':
                            return (
                                <React.Fragment key={`${item.id}-${item.type}`}>
                                    <RelatorioPerformanceItem
                                        item={item}
                                        idEmpresaAvaliada={this.state.relatorio.avaliado.id}
                                        status={this.state.relatorio.status}
                                        canEdit={this.state.relatorio.canEdit}
                                        lang={this.state.lang}
                                        isSmallScreen={this.state.isSmallScreen}
                                        data_performance={this.state.data_performance}
                                        pageHeaderHeight={this.state.scrolled ? this.state.headerSize + 15 : 0}
                                    />
                                </React.Fragment>
                            )
                        case 'performanceTable':
                            return (
                                <React.Fragment key={`${item.id}-${item.type}`}>
                                    <RelatorioPerformanceTabelaItem
                                        item={item}
                                        idDiagnosticoPeriodo={this.state.relatorio.id_periodo}
                                        idEmpresaAvaliada={this.state.relatorio.avaliado.id}
                                        status={this.state.relatorio.status}
                                        canEdit={this.state.relatorio.canEdit}
                                        lang={this.state.lang}
                                        isSmallScreen={this.state.isSmallScreen}
                                        data_performance={this.state.data_performance}
                                        pageHeaderHeight={this.state.scrolled ? this.state.headerSize + 15 : 0}
                                    />
                                </React.Fragment>
                            )

                        case 'results':
                            return (
                                <React.Fragment key={`${item.id}-${item.type}`}>
                                    <RelatorioTabelaResultadoItem
                                        item={item}
                                        idEmpresaAvaliada={this.state.relatorio.avaliado.id}
                                        status={this.state.relatorio.status}
                                        canEdit={this.state.relatorio.canEdit}
                                        lang={this.state.lang}
                                        data_processo={this.state.data_processo}
                                        data_performance={this.state.data_performance}
                                        pageHeaderHeight={this.state.scrolled ? this.state.headerSize + 15 : 0}
                                    />
                                </React.Fragment>
                            )
                        default:
                            return null;
                    }
                })}
            </>
        );
    }

    renderRecomendacoes() {
        let message = IdiomaHelper.getStruct({
            'pt_br': {
                title: 'Recomendações',
                add: 'Adicionar Recomendação',
                back: 'Voltar',
                nome: 'Nome',
                selectColuna: 'Selecione a Coluna',
                selectItens: 'Selecione os Itens',
                recomendacaoPlaceholder: 'Escreva sua recomendação',
                adicionar: 'Adicionar',
                addPa: 'Plano Ação',
                addPaTooltip: 'Criar Plano de Ação vinculado com Recomendação',
                delete: 'Excluir',
                switchTable: 'Visualizar em Tabela',
                switchPanel: 'Visualizar em Painel',
            },
            'en': {
                title: 'Recommendations',
                add: 'Add Recommendation',
                back: 'Back',
                nome: 'Name',
                selectColuna: 'Select Column',
                selectItens: 'Select Items',
                recomendacaoPlaceholder: 'Write your recommendation',
                adicionar: 'Add',
                addPa: 'Action Plan',
                addPaTooltip: 'Create Action Plan linked to Recommendation',
                delete: 'Delete',
                switchTable: 'View in Table',
                switchPanel: 'View in Panel',
            },
            'es': {
                title: 'Recomendaciones',
                add: 'Agregar Recomendación',
                back: 'Volver',
                nome: 'Nombre',
                selectColuna: 'Seleccione la Columna',
                selectItens: 'Seleccione los Elementos',
                recomendacaoPlaceholder: 'Escribe tu recomendación',
                adicionar: 'Agregar',
                addPa: 'Plan de Acción',
                addPaTooltip: 'Crear un Plan de Acción vinculado a la Recomendación',
                delete: 'Eliminar',
                switchTable: 'Ver en Tabla',
                switchPanel: 'Ver en Panel',
            }
        });

        const setNodeColuna = async (idColuna) => {
            this.setState({ loadAddRecomendacaoSelect: true });

            let nodes = [];
            let nodesByNodePai = [];

            if (idColuna) {
                const result = await DiagnosticoHelper.getItensColuna(idColuna, this.state.lang);
                nodes = result.nodes;
                nodesByNodePai = result.nodesByNodePai;
            }

            let nodeSelected = null;

            this.setState({ colunaSelected: idColuna, nodes, nodesByNodePai, nodeSelected, loadAddRecomendacaoSelect: false });
        }

        const deleteRecomendacao = async (idRecomendacao) => {
            this.setState({ loadAddRecomendacao: true });
    
            if (! await DiagnosticoHelper.deleteRecomendacao(idRecomendacao)) {
                toast.error('Erro ao excluir recomendação');
                this.setState({ loadAddRecomendacao: false });
                return;
            }
    
            this.setState({ loadAddRecomendacao: false }, () => {
                this.loadRelatorio();
            });
        }

        const saveRecomendacao = async () => {
            let message = IdiomaHelper.getStruct({
                'pt_br': {
                    error: 'Preencha todos os campos'
                },
                'en': {
                    error: 'Fill in all fields'
                },
                'es': {
                    error: 'Rellene todos los campos'
                }
            });
    
            if (!this.state.idRelatorio || !this.state.colunaSelected || !this.state.nodeSelected || !this.state.description || this.state.description == '' || DataHelper.removeHtmlTags(this.state.description) == '') {
    
                toast.error(message[this.props.lang].error);
                return;
            }
    
            let recomendacao = {
                idRelatorio: this.state.idRelatorio,
                idColuna: this.state.colunaSelected,
                idNode: this.state.nodeSelected,
                descricao: this.state.description
            }
    
            this.setState({ loadAddRecomendacao: true });
    
            if (!await DiagnosticoHelper.saveRecomendacao(recomendacao)) {
                toast.error('Erro ao salvar recomendação');
                this.setState({ loadAddRecomendacao: false, addingRecomendation: false });
                return;
            }
    
            this.setState({ loadAddRecomendacao: false, addingRecomendation: false, colunaSelected: null, nodeSelected: null, description: '' }, () => {
                this.loadRelatorio();
            });
        }

        const handleViewRecomendation = () => {

            let ColumnRecomendacoes = this.state.ColumnRecomendacoes;
            ColumnRecomendacoes = ColumnRecomendacoes === 1 ? 2 : 1;
    
            this.setState({ ColumnRecomendacoes });
        }

        const renderSwitchRecomendation = () => {

            return (
    
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                    <FontAwesomeIcon icon={faBars} />
                    <Form.Switch
                        type="switch"
                        disabled
                        checked={this.state.ColumnRecomendacoes === 1 ? false : true}
                        style={{ marginLeft: 3, marginRight: 3 }}
                    />
                    <FontAwesomeIcon icon={faColumns} />
                </div>
            )
        }

        const renderRecomendacoes = () => {

            if (!this.state.relatorio.recomendacoes || this.state.relatorio.recomendacoes.length == 0) return <></>
            return (
                <div className="contentRecomendacoes">
                    <Form style={{ width: '100%', padding: '0px 0px 0px 10px' }}>
                        <Row >
                            {this.state.relatorio.recomendacoes.map((recomendacao, index) => {
                                return (
                                    <RelatorioRecomendacao
                                        loading={this.state.loading}
                                        key={recomendacao.id}
                                        recomendacao={recomendacao}
                                        ColumnRecomendacoes={this.state.ColumnRecomendacoes}
                                        lang={this.state.lang}
                                        canEdit={this.state.relatorio.canEdit}
                                        canCreatePlanoAcao={this.state.relatorio.canCreatePlanoAcao}
                                        deleteRecomendacao={(id) => deleteRecomendacao(id)}
                                    />
                                )
                            })}
                        </Row>
                    </Form>
                </div>
            )
        }

        const renderAddRecomendacao = () => {

            let optionsColunas = this.state.colunasQuestionario.map((questionario) => {
                return {
                    label: questionario.questionario.nome,
                    options: questionario.colunas.map((coluna) => {
                        return {
                            value: coluna.id,
                            label: coluna?.descricao?.find((item) => item.id_idioma == IdiomaHelper.getSiglaId(this.state.lang))?.descricao ?? '',
                        }
                    })
                }
            });

            let coluna = this.state.colunas.find((coluna) => coluna.id == this.state.colunaSelected);

            let optionsNodes = [];
            if (parseFloat(coluna?.drag_index || 0) !== 0) {
                optionsNodes= this.state.nodesByNodePai?.map((node) => {
                    return {
                        label: node?.descricao,
                        options: node?.filhos?.map((node) => {
                            return {
                                value: node?.id,
                                label: node?.descricao,
                            }
                        })
                    }
                });
            } else {
                optionsNodes = this.state.nodes?.map((node) => {
                    return {
                        value: node?.id,
                        label: node?.descricao,
                    }
                });
            }

            return (
                <div className="content">
                    <div className="node">
                        <DefaultButton
                            leftIcon={this.state.addingRecomendation ? <FontAwesomeIcon icon={faChevronLeft} /> : <FontAwesomeIcon icon={faPlusSquare} />}
                            tooltip={this.state.addingRecomendation ? message[this.state.lang].back : message[this.state.lang].add}
                            color={Colors.success}
                            onClick={() => { this.setState({ addingRecomendation: this.state.addingRecomendation ? false : true }) }}
                        />

                        {this.state.addingRecomendation &&
                            <Select
                                options={optionsColunas}
                                value={DataHelper.formatSelectData(this.state.colunas, 'id', 'descricao').find((coluna) => coluna.value == this.state.colunaSelected)}
                                onChange={(e) => { setNodeColuna(e.value) }}
                                styles={{
                                    container: (provided) => ({
                                        ...provided,
                                        width: '18rem', // Define a largura mínima
                                    }),
                                }}
                                menuPortalTarget={document.body}
                                placeholder={message[this.state.lang].selectColuna}
                                menuPlacement="auto"
                                menuPosition="fixed"
                            />
                        }

                        {this.state.addingRecomendation && !this.state.loadAddRecomendacaoSelect &&
                            <Select
                                options={optionsNodes}
                                value={DataHelper.formatSelectData(this.state.nodes, 'id', 'descricao').find((node) => node.value == this.state.nodeSelected)}
                                onChange={(e) => { this.setState({ nodeSelected: e.value }) }}
                                styles={{
                                    container: (provided) => ({
                                        ...provided,
                                        width: 'calc(100vw - 18rem - 98px)', // Define a largura mínima
                                    }),
                                }}
                                menuPortalTarget={document.body}
                                placeholder={message[this.state.lang].selectItens}
                                menuPlacement="auto"
                                menuPosition="fixed"
                            />
                        }
                    </div>

                    {this.state.addingRecomendation &&
                        <div className="description">
                            <div style={{ width: '100%' }}>
                                <TextEditor
                                    defaultValue={this.state.description || ""}
                                    onChange={(value) => { this.setState({ description: value }) }}

                                    placeholder={message[this.state.lang].recomendacaoPlaceholder}
                                />
                            </div>
                        </div>
                    }

                    {this.state.addingRecomendation &&
                        <div className="description" style={{ justifyContent: 'end', marginTop: -15 }}>
                            <DefaultButton
                                title={message[this.state.lang].adicionar}
                                color={Colors.success}
                                onClick={() => { saveRecomendacao() }}
                            />
                        </div>
                    }
                </div>
            )
        }

        if (this.state.loadRecomendacao) {
            return (
                <div className="recomendacoes" style={{ boxShadow: Colors.boxShadow }}>
                    <div className="titleRecomendacoes">{message[this.state.lang].title}</div>
                    <DefaultLoader />
                </div>
            )
        }

        let showRecomendacoes = ((this.state.relatorio.recomendacoes && this.state.relatorio.recomendacoes.length > 0) ||
            this.state.relatorio.status == 0
        );

        if (showRecomendacoes) return (
            <div className="recomendacoes" style={{ boxShadow: Colors.boxShadow }}>
                <div className="titleRecomendacoesLine">
                    <div style={{ width: 102 }}></div>
                    <div className="titleRecomendacoes">
                        {message[this.state.lang].title}
                    </div>
                    <div style={{ width: 102 }}>
                        <DefaultButton tooltip={this.state.view === 'normal' ? message[this.state.lang].switchTable : message[this.state.lang].switchPanel} tooltipPlacement={'left'} leftIcon={renderSwitchRecomendation()} color={Colors.secondaryButton} textColor={Colors.dark} loading={this.state.loading} onClick={() => { handleViewRecomendation() }} style={{ marginRight: 8 }} />
                    </div>
                </div>
                {renderRecomendacoes()}
                {this.state.relatorio.canEdit && !this.state.loadAddRecomendacao && renderAddRecomendacao()}
            </div>
        );
    }

    renderObservacoes() {

        let message = IdiomaHelper.getStruct({
            'pt_br': {
                title: 'Observações', add: 'Adicionar Observação', back: 'Voltar', observationPlaceholder: 'Escreva sua observação',
            },
            'en': {
                title: 'Observations', add: 'Add Observation', back: 'Back', observationPlaceholder: 'Write your observation',
            },
            'es': {
                title: 'Observaciones', add: 'Agregar Observación', back: 'Volver', observationPlaceholder: 'Escribe tu observación',
            }
        });

        const deleteObservation = async(idObservacao) => {
            this.setState({ loadAddObservation: true });
    
            if (!DiagnosticoHelper.deleteObservation(idObservacao)) {
                toast.error('Erro ao excluir observação');
                this.setState({ loadAddObservation: false });
                return;
            }
    
            this.setState({ loadAddObservation: false }, () => {
                this.loadRelatorio();
            });
        }

        const saveObservation = async () => {
            let message = IdiomaHelper.getStruct({
                'pt_br': {
                    error: 'Preencha todos os campos'
                },
                'en': {
                    error: 'Fill in all fields'
                },
                'es': {
                    error: 'Rellene todos los campos'
                }
            });
    
            if (!this.state.idRelatorio || !this.state.observation || this.state.observation == '' || DataHelper.removeHtmlTags(this.state.observation) == '') {
    
                toast.error(message[this.props.lang].error);
                return;
            }
    
            let observacao = {
                idRelatorio: this.state.idRelatorio,
                observacao: this.state.observation
            }
    
            this.setState({ loadAddObservation: true });
    
            if (!await DiagnosticoHelper.saveObservation(observacao)) {
                toast.error('Erro ao salvar recomendação');
                this.setState({ loadAddObservation: false, addingObservation: false });
                return;
            }
    
            this.setState({ loadAddObservation: false, addingObservation: false, observation: '' }, () => {
                this.loadRelatorio();
            });
        }

        const handleViewObservation = () => {

            let ColumnObservations = this.state.ColumnObservations;
            ColumnObservations = ColumnObservations === 1 ? 2 : 1;
    
            this.setState({ ColumnObservations });
        }

        const renderSwitchObservation = () => {

            return (
    
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                    <FontAwesomeIcon icon={faBars} />
                    <Form.Switch
                        type="switch"
                        disabled
                        checked={this.state.ColumnObservations === 1 ? false : true}
                        style={{ marginLeft: 3, marginRight: 3 }}
                    />
                    <FontAwesomeIcon icon={faColumns} />
                </div>
            )
        }

        const renderObservations = () => {

            if (!this.state.relatorio.observacoes || this.state.relatorio.observacoes.langth == 0) return <></>
            return (
                <div className="content">
                    <Form style={{ width: '100%', padding: '0px 0px 0px 10px' }}>
                        <Row >
                            {this.state.relatorio?.observacoes.map((observacao, index) => {
                                return (
                                    <RelatorioObservacao
                                        index={index}
                                        key={observacao.id}
                                        observacao={observacao}
                                        lang={this.state.lang}
                                        canEdit={this.state.relatorio.canEdit}
                                        deleteObservation={(id) => deleteObservation(id)}
                                        ColumnObservations={this.state.ColumnObservations}
                                    />
                                )
                            })}
                        </Row>
                    </Form>
                </div>
            )
        }

        const renderAddObservacoes = () => {
            
            return (
                <div className="content">
                    <div className="node">
                        <DefaultButton
                            leftIcon={this.state.addingObservation ? <FontAwesomeIcon icon={faChevronLeft} /> : <FontAwesomeIcon icon={faPlusSquare} />}
                            tooltip={this.state.addingObservation ? message[this.state.lang].back : message[this.state.lang].add}
                            color={Colors.success}
                            onClick={() => { this.setState({ addingObservation: this.state.addingObservation ? false : true }) }}
                        />
                        
                        {this.state.addingObservation &&
                            <div style={{...EssentialStyle.rowFlexStart, gap: 10}}>
                                <UserAvatar id={SessionHelper.getUser().id} showName/>
                            </div>
                        }
                    </div>

                    {this.state.addingObservation &&
                        <div className="description">
                            <div style={{ width: '100%' }}>
                                <TextEditor
                                    defaultValue={this.state.observation || ""}
                                    onChange={(value) => { this.setState({ observation: value }) }}
                                    placeholder={message[this.state.lang].observationPlaceholder}
                                />
                            </div>
                        </div>
                    }

                    {this.state.addingObservation &&
                        <div className="description" style={{ justifyContent: 'end', marginTop: -15 }}>
                            <DefaultButton
                                disabled={this.state.observation == '' || DataHelper.removeHtmlTags(this.state.observation) == ''}
                                title={message[this.state.lang].add}
                                color={Colors.success}
                                onClick={() => { saveObservation() }}
                            />
                        </div>
                    }
                </div>
            )
        }

        let showObservations = (
            (this.state.relatorio.observacoes && this.state.relatorio.observacoes.length > 0) ||
            this.state.relatorio.status == 0
        );

        if (showObservations) return (
            <div className="observacoes" style={{ boxShadow: Colors.boxShadow }}>
                <div className="titleObservacoesLine">
                    <div style={{ width: 102 }}></div>

                    <div className="titleObservacoes">
                        {message[this.state.lang].title}
                    </div>

                    <div style={{ width: 102 }}>
                        <DefaultButton tooltipPlacement={'left'} leftIcon={renderSwitchObservation()} color={Colors.secondaryButton} textColor={Colors.dark} loading={this.state.loading} onClick={() => { handleViewObservation() }} style={{ marginRight: 8 }} />
                    </div>
                </div>
                {renderObservations()}
                {this.state.relatorio.canEdit && !this.state.loadAddObservation && renderAddObservacoes()}
            </div>
        );
    }

    render() {
        if (this.state.loading) return <LoadingPage />;
        if (this.state.construction) return <UnderConstruction height={'100vh'} />

        return this.renderPage();
    }
}

export default withTranslation()(Relatorio);