import React from "react";
import { withTranslation } from "react-i18next";
import Sig from "../../../api/Sig";
import EssentialStyle from "../../../style/EssentialStyle";
import DefaultLoader from "../../tools/DefaultLoader";
import Constants from "../../../constants/Api";
import PlanoAcaoEtapaCard from "./PlanoAcaoEtapaCard/PlanoAcaoEtapaCard";
import Colors from "../../../constants/Colors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd, faCheck, faTimes, faChevronUp, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { Form } from 'react-bootstrap';
import DefaultButton from "../../tools/DefaultButton";
import { toast } from "react-toastify";
import KeyboardHelper from "../../../helper/KeyboardHelper";
import SessionHelper from "../../../helper/SessionHelper";
import TextEditor from "../../tools/TextEditor/TextEditor";
import DataHelper from "../../../helper/DataHelper";

const gateway = Constants.getSigEndPoint();

class PlanoAcaoEtapaList extends React.Component {
    state = {
        loading: true,
        etapas: [],
        orderBy: null,
        adding: false,
        atividade: '',
        justificativa: '',
        descricao: '',
        headerStickyTop: this.props.headerStickyTop,
        isSmallScreen: this.props.isSmallScreen,
    }

    componentDidMount = async () => {
        await this.props.i18n.loadNamespaces(['client_src_components_modules_planoAcao_PlanoAcaoEtapasList']);
        await this.loadData();
    }

    componentDidUpdate = async (prevProps) => {
        if (
            (prevProps.filter.people !== this.props.filter.people)
            || (prevProps.filter.search !== this.props.filter.search)
            || (prevProps.filter.status !== this.props.filter.status)
            || (prevProps.filter.prioridades !== this.props.filter.prioridades)
            || (prevProps.filter.data_inicio !== this.props.filter.data_inicio)
            || (prevProps.filter.data_fim !== this.props.filter.data_fim)
            || (prevProps.filter.ocultarConcluidos !== this.props.filter.ocultarConcluidos)
        ) {
            this.setState({ loadingEtapas: true });
            await this.loadData(true);
            this.setState({ loadingEtapas: false });
        }

        if (this.props.headerStickyTop !== prevProps.headerStickyTop) {
            this.setState({ headerStickyTop: this.props.headerStickyTop });
        }

        if (this.props.isSmallScreen !== prevProps.isSmallScreen) {
            this.setState({ isSmallScreen: this.props.isSmallScreen });
        }

        if (prevProps.detailed !== this.props.detailed) {
            this.setState({ justificativa: '', descricao: '' });
        }

        if (this.props.shouldUpdateEtapas && prevProps.shouldUpdateEtapas !== this.props.shouldUpdateEtapas) {
            this.setState({ loadingEtapas: true });
            await this.loadData(true);
            this.setState({ loadingEtapas: false });
        }
    }

    loadData = async (quiet = false) => {
        if (!quiet) this.setState({ loading: true });
        let etapas = await Sig.request('POST', 'planoAcao/listEtapasPlano', {
            codigo: this.props.codigo,
            orderBy: this.state.orderBy,
            people: this.props.filter.people || [],
            search: this.props.filter.search || '',
            status: this.props.filter.status || [],
            prioridades: this.props.filter.prioridades || [],
            data_inicio: this.props.filter.data_inicio || '',
            data_fim: this.props.filter.data_fim || '',
            ocultarConcluidos: this.props.filter.ocultarConcluidos || false
        });
        this.setState({ etapas: etapas || [] }, () => { 
            this.setState({ loading: false });
            this.props.updateEtapas(); 
        });
    }

    getHeaderHeight = () => {
        let headerComponents = document.getElementsByClassName('pa-header-component');
        let headerHeight = 0;
        for (let headerComponent of headerComponents) {
            headerHeight += headerComponent.clientHeight;
        }
        return headerHeight;
    }

    renderLoading() {
        return (
            <div style={{ ...EssentialStyle.columnCenter, width: '100%', height: `calc(100vh - ${this.getHeaderHeight()}px)`}}>
                <DefaultLoader />
            </div>
        );
    }

    controlOrderBy = async (field) => {
        this.setState({ loadingEtapas: true });
        let order = `ASC`;
        if (this.state.orderBy && this.state.orderBy.includes(field)) {
            if (this.state.orderBy.includes('DESC')) {
                this.setState({ orderBy: null }, async () => {
                    await this.loadData(true);
                    this.setState({ loadingEtapas: false });
                });
                return;
            }
            order = this.state.orderBy.includes('ASC') ? 'DESC' : 'ASC';
        }
        this.setState({ orderBy: `${field} ${order}` }, async () => {
            await this.loadData(true);
            this.setState({ loadingEtapas: false });
        });
    }

    renderOrderByIcon(field) {
        if (this.state.orderBy && this.state.orderBy.includes(field)) {
            return this.state.orderBy.includes('ASC') ?
                <FontAwesomeIcon style={{ marginRight: 8 }} icon={faChevronUp} />
                : <FontAwesomeIcon style={{ marginRight: 8 }} icon={faChevronDown} />;
        }
        return null;
    }

    renderListHeader() {
        let spanStyle = {
            fontWeight: 500,
            cursor: 'pointer',
            userSelect: 'none'
        }
        return (
            <div style={{...EssentialStyle.rowFlexStart, width: '100%', position: 'sticky', top: this.state.headerStickyTop, paddingTop: 15, backgroundColor: SessionHelper.getBackgroundColor(), zIndex: 1}}>
                <div style={{
                    ...EssentialStyle.rowFlexStart,
                    backgroundColor: Colors.white,
                    fontSize: 14,
                    width: '100%',
                    borderTopLeftRadius: 5,
                    borderTopRightRadius: 5,
                    border: `1px solid ${Colors.border}`,
                    userSelect: 'none',
                    padding: 10,
                }}>
                    <span
                        onClick={() => { this.controlOrderBy('atividade') }}
                        style={{ ...spanStyle, width: 'calc(100% - 430px)' }}
                    >
                        {this.renderOrderByIcon('atividade')}
                        {this.props.i18n.t('client_src_components_modules_planoAcao_PlanoAcaoEtapasList:atividades')}
                    </span>
                    <span
                        onClick={() => { this.controlOrderBy('status') }}
                        style={{ ...spanStyle, width: '120px', textAlign: 'left' }}
                    >
                        {this.renderOrderByIcon('status')}
                        {this.props.i18n.t('client_src_components_modules_planoAcao_PlanoAcaoEtapasList:status')}
                    </span>
                    <span
                        onClick={() => { this.controlOrderBy('data_inicio') }}
                        style={{ ...spanStyle, width: '110px', textAlign: 'left' }}
                    >
                        {this.renderOrderByIcon('data_inicio')}
                        {this.props.i18n.t('client_src_components_modules_planoAcao_PlanoAcaoEtapasList:inicio_fim')}
                    </span>
                    <span
                        onClick={() => { this.controlOrderBy('prioridade') }}
                        style={{ ...spanStyle, width: '200px', textAlign: 'right' }}
                    >
                        {this.renderOrderByIcon('prioridade')}
                        {this.props.i18n.t('client_src_components_modules_planoAcao_PlanoAcaoEtapasList:prioridade_responsavel')}
                    </span>
                </div>
            </div>
        );
    }

    addEtapa = async () => {
        if (this.props.detailed) {
            if (!this.state.atividade || !this.state.descricao || !this.state.justificativa) {
                if (!this.state.atividade) {
                    toast.info(this.props.i18n.t('client_src_components_modules_planoAcao_PlanoAcaoEtapasList:o_que_sera_feito_vazio'));
                    return;
                }
                if (!this.state.descricao) {
                    toast.info(this.props.i18n.t('client_src_components_modules_planoAcao_PlanoAcaoEtapasList:como_sera_feito_vazio'));
                    return;
                }
                if (!this.state.justificativa) {
                    toast.info(this.props.i18n.t('client_src_components_modules_planoAcao_PlanoAcaoEtapasList:por_que_sera_feito_vazio'));
                    return;
                }
            }
        } else {
            if (!this.state.atividade) {
                toast.info(this.props.i18n.t('client_src_components_modules_planoAcao_PlanoAcaoEtapasList:atividade_vazia'));
                return;
            }
        }

        let atividade = await Sig.request(
            'POST',
            'planoAcao/addEtapa',
            {
                codigo: this.props.codigo,
                atividade: this.state.atividade,
                justificativa: this.state.justificativa,
                descricao: this.state.descricao,
                idColaborador: this.props.filter.people.length === 1 ? this.props.filter.people[0] : null
            }
        );

        if (atividade) {
            let etapas = this.state.etapas;
            etapas.push(atividade);
            this.setState({
                etapas,
                atividade: '',
                justificativa: '',
                descricao: '',
                adding: false
            }, () => { window.scrollTo(0, document.body.scrollHeight); this.props.updateEtapas();  });
        }
    }

    renderDetailedCreateAtividade() {
        let cardStyle = {
            ...EssentialStyle.rowFlexStart,
            backgroundColor: Colors.white,
            fontSize: 16,
            width: '100%',
            borderBottomLeftRadius: 5,
            borderBottomRightRadius: 5,
            border: `1px solid ${Colors.border}`,
            fontWeight: 500,
            borderTop: 'none',
            padding: 10
        };

        return this.state.adding ?
            (
                <div style={{
                    ...cardStyle,
                    ...EssentialStyle.columnCenter,
                    cursor: 'default',
                    gap: 8
                }}>
                    <TextEditor
                        ref={ref => this.addOqueInput = ref}
                        defaultValue={this.state.atividade}
                        placeholder={this.props.i18n.t('client_src_components_modules_planoAcao_PlanoAcaoEtapasList:o_que_sera_feito')}
                        onChange={(value) => {
                            this.setState({ atividade: value })
                        }}
                        maxHeight={65}
                        mentions={DataHelper.formatMentionData(this.props.participantes, 'id', 'nome')}
                        hideToolbar
                        noMargin
                        disabledEnterEvent
                        onKeyDown={(evt) => {
                            KeyboardHelper.handleShortcut(
                                evt,
                                ["Enter", "Escape"],
                                [() => { 
                                    if (evt.shiftKey) return;
                                    this.addComoInput.focus();
                                }, (e) => { 
                                    e.preventDefault();
                                    this.setState({ atividade: '', adding: false });
                                }]
                            )
                        }}
                    />
                    <TextEditor
                        ref={ref => this.addComoInput = ref}
                        defaultValue={this.state.descricao}
                        placeholder={this.props.i18n.t('client_src_components_modules_planoAcao_PlanoAcaoEtapasList:como_sera_feito')}
                        onChange={(value) => {
                            this.setState({ descricao: value })
                        }}
                        maxHeight={65}
                        mentions={DataHelper.formatMentionData(this.props.participantes, 'id', 'nome')}
                        hideToolbar
                        noMargin
                        disabledEnterEvent
                        onKeyDown={(evt) => {
                            KeyboardHelper.handleShortcut(
                                evt,
                                ["Enter", "Escape"],
                                [() => { 
                                    if (evt.shiftKey) return;
                                    this.addPorqueInput.focus();
                                }, (e) => { 
                                    e.preventDefault();
                                    this.setState({ descricao: '', adding: false });
                                }]
                            )
                        }}
                    />
                    <TextEditor
                        ref={ref => this.addPorqueInput = ref}
                        defaultValue={this.state.justificativa}
                        placeholder={this.props.i18n.t('client_src_components_modules_planoAcao_PlanoAcaoEtapasList:por_que_sera_feito')}
                        onChange={(value) => {
                            this.setState({ justificativa: value });
                        }}
                        maxHeight={65}
                        mentions={DataHelper.formatMentionData(this.props.participantes, 'id', 'nome')}
                        hideToolbar
                        noMargin
                        disabledEnterEvent
                        onKeyDown={(evt) => {
                            KeyboardHelper.handleShortcut(
                                evt,
                                ["Enter", "Escape"],
                                [() => { 
                                    if (evt.shiftKey) return;
                                    this.addEtapa();
                                }, (e) => { 
                                    e.preventDefault();
                                    this.setState({ justificativa: '', adding: false });
                                }]
                            )
                        }}
                    />
                    <div style={{ ...EssentialStyle.rowFlexStart, width: '100%' }}>
                        <DefaultButton
                            width={48}
                            className="me-1"
                            leftIcon={<FontAwesomeIcon icon={faTimes} />}
                            color={Colors.dark}
                            loading={this.state.loading}
                            onClick={() => { this.setState({ atividade: '', descricao: '', justificativa: '', adding: false }) }}
                        />
                        <DefaultButton
                            width={48}
                            leftIcon={<FontAwesomeIcon icon={faCheck} />}
                            color={Colors.dark}
                            loading={this.state.loading}
                            onClick={this.addEtapa}
                        />
                    </div>
                </div>
            )
            :
            (
                <div
                    onClick={() => { this.setState({ adding: true }, () => { this.addOqueInput.focus(); }); }}
                    style={{
                        ...cardStyle,
                        cursor: 'pointer',
                        borderTop: `1px solid ${Colors.border}`
                    }}>
                    <FontAwesomeIcon icon={faAdd} />
                    <span style={{ marginLeft: 8 }}>
                        {this.props.i18n.t('client_src_components_modules_planoAcao_PlanoAcaoEtapasList:adicionar')}
                    </span>
                </div>
            );
    }

    renderCreateAtividade() {
        let cardStyle = {
            ...EssentialStyle.rowFlexStart,
            backgroundColor: Colors.white,
            fontSize: 16,
            width: '100%',
            borderBottomLeftRadius: 5,
            borderBottomRightRadius: 5,
            border: `1px solid ${Colors.border}`,
            fontWeight: 500,
            borderTop: 'none',
            padding: 10
        };

        return this.state.adding ?
            (
                <div style={{
                    ...cardStyle,
                    cursor: 'default',
                    gap: 8
                }}>
                    <TextEditor
                        ref={ref => this.addAtividadeInput = ref}
                        defaultValue={this.state.atividade}
                        placeholder={this.props.i18n.t('client_src_components_modules_planoAcao_PlanoAcaoEtapasList:descricao_da_atividade')}
                        onChange={(value) => {
                            this.setState({ atividade: value })
                        }}
                        height={45}
                        mentions={DataHelper.formatMentionData(this.props.participantes, 'id', 'nome')}
                        hideToolbar
                        noMargin
                        disabledEnterEvent
                        onKeyDown={(evt) => {
                            KeyboardHelper.handleShortcut(
                                evt,
                                ["Enter", "Escape"],
                                [() => { 
                                    if (evt.shiftKey) return;
                                    this.addEtapa();
                                }, (e) => { 
                                    e.preventDefault();
                                    this.setState({ atividade: '', adding: false })
                                }]
                            )
                        }}
                    />
                    <DefaultButton
                        width={48}
                        leftIcon={<FontAwesomeIcon icon={faTimes} />}
                        color={Colors.dark}
                        loading={this.state.loading}
                        onClick={() => { this.setState({ atividade: '', adding: false }) }}
                    />
                    <DefaultButton
                        width={48}
                        leftIcon={<FontAwesomeIcon icon={faCheck} />}
                        color={Colors.dark}
                        loading={this.state.loading}
                        onClick={this.addEtapa}
                    />
                </div>
            )
            :
            (
                <div
                    onClick={() => { 
                        if (!this.props.permissions?.canEdit) return;
                        this.setState({ adding: true }, () => { this.addAtividadeInput.focus(); }); 
                    }}
                    style={{
                        ...cardStyle,
                        cursor: this.props.permissions?.canEdit ? 'pointer' : 'default',
                        borderTop: `1px solid ${Colors.border}`
                    }}>
                    {this.props.permissions?.canEdit &&
                        <>
                            <FontAwesomeIcon icon={faAdd} />
                            <span style={{ marginLeft: 8 }}>
                                {this.props.i18n.t('client_src_components_modules_planoAcao_PlanoAcaoEtapasList:adicionar')}
                            </span>
                        </>
                    }
                </div>
            );
    }

    renderEmptyEtapas() {
        if ((this.props.filter.people && this.props.filter.people.length > 0) || 
            (this.props.filter.search && this.props.filter.search !== "") ||
            (this.props.filter.status && this.props.filter.status.length > 0) ||   
            (this.props.filter.prioridades && this.props.filter.prioridades.length > 0) ||
            (this.props.filter.data_inicio && this.props.filter.data_inicio !== "") ||
            (this.props.filter.data_fim && this.props.filter.data_fim !== ""))
        {
            return (
                <div style={{
                    ...EssentialStyle.columnCenter,
                    backgroundColor: Colors.white,
                    color: Colors.disabled,
                    border: `1px solid ${Colors.border}`,
                    borderBottom: 'none',
                    paddingTop: 20,
                    paddingBottom: 20,
                    width: '100%'
                }}>
                    <span>
                        {this.props.i18n.t('client_src_components_modules_planoAcao_PlanoAcaoEtapasList:nenhuma_etapa_encontrada')}
                    </span>
                </div>
            );
        }
        return (
            <div style={{
                ...EssentialStyle.columnCenter,
                backgroundColor: Colors.white,
                color: Colors.disabled,
                border: `1px solid ${Colors.border}`,
                borderBottom: 'none',
                paddingTop: 20,
                paddingBottom: 20,
                width: '100%'
            }}>
                <span>
                    {this.props.i18n.t('client_src_components_modules_planoAcao_PlanoAcaoEtapasList:ainda_nao_ha_etapas_para_criar')}
                    &nbsp;
                    <FontAwesomeIcon icon={faAdd} />
                    &nbsp;
                    {this.props.i18n.t('client_src_components_modules_planoAcao_PlanoAcaoEtapasList:adicionar')}
                </span>
            </div>
        );
    }

    renderLoadingEtapas() {
        return (
            <div style={{
                ...EssentialStyle.columnCenter,
                backgroundColor: Colors.white,
                color: Colors.disabled,
                border: `1px solid ${Colors.border}`,
                borderBottom: 'none',
                height: this.state.etapas.length * 54,
                maxHeight: window.innerHeight - 200,
                width: '100%'
            }}>
                <DefaultLoader />
            </div>
        );
    }

    renderEtapas() {
        if (this.state.loadingEtapas) return this.renderLoadingEtapas();
        if (this.state.etapas?.length === 0) return this.renderEmptyEtapas();
        return this.state.etapas.map((etapa, index) => {
            return (
                <PlanoAcaoEtapaCard 
                    key={`plano-acao-etapa-${index}`} 
                    detailed={this.props.detailed} 
                    etapa={{...etapa}}
                    hasAvaliador={this.props.hasAvaliador}
                    participantes={this.props.participantes} 
                    noTopBorder={index == 0 ? true : false} 
                    detailsModalCallback={this.props.detailsModalCallback}
                    updateEtapas={() => { this.props.updateEtapas() }}
                    noEdit={!this.props.permissions?.canEdit}
                />
            );
        });
    }

    renderListMobile() {
        return (
            <div style={{ maxWidth: '100%', overflowX: 'scroll' }}>
                <div style={{ width: '1200px' }}>
                    {this.renderListHeader()}
                    {this.renderEtapas()}
                    {this.props.detailed ? this.renderDetailedCreateAtividade() : this.renderCreateAtividade()}
                </div>
            </div>
        );
    }

    renderListDesktop() {
        return (
            <>
                {this.renderListHeader()}
                {this.renderEtapas()}
                {this.props.detailed ? this.renderDetailedCreateAtividade() : this.renderCreateAtividade()}
            </>
        );
    }

    renderList() {
        return (
            <div style={{ ...EssentialStyle.columnCenter, width: '100%', paddingBottom: 20, position: "relative" }}>
                {this.state.isSmallScreen ? this.renderListMobile() : this.renderListDesktop()}
            </div>
        );
    }

    render() {
        return (
            <>
                {this.state.loading ? this.renderLoading() : this.renderList()}
            </>
        );
    }
}

export default withTranslation()(PlanoAcaoEtapaList);