import React from "react";
import { withTranslation } from "react-i18next";

import Colors from "../../../../../constants/Colors";
import EssentialStyle from "../../../../../style/EssentialStyle";
import Select from 'react-select'
import Sig from "../../../../../api/Sig";
import LayoutHelper from "../../../../../helper/LayoutHelper";
import DefaultLoader from "../../../../tools/DefaultLoader";
import ChartHelper from "../../../../../helper/inicio/ChartHelper";
import Constants from "../../../../../constants/Api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBookmark, faBookOpen, faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import { Form } from "react-bootstrap";

import './PlanejamentoEstrategicoResumo.css'
import DataHelper from "../../../../../helper/DataHelper";
import ProjetoObjetivoCardChart from "../../../pe/projeto/ProjetoObjetivoCardChart/ProjetoObjetivoCardChart";
import { Collapse } from "react-bootstrap";
import DefaultButton from "../../../../tools/DefaultButton";
import CustomTooltip from "../../../../tools/CustomTooltip";
import SessionHelper from "../../../../../helper/SessionHelper";
import ProgressBarCustom from "../../../../tools/ProgressBar/ProgressBarCustom";
import DetalhamentoObjetivoResumo from "../../../pe/projeto/DetalhamentoObjetivoResumo/DetalhamentoObjetivoResumo";

const gateway = Constants.getSigEndPoint();

class PlanejamentoEstrategicoResumo extends React.Component {
    state = {
        loadingTitle: true,
        loadingContent: true,
        mapasUser: [],
        selectedMapa: null,
        objetivoSelectedId: null,
        perspectivaSelectedId: null,
        index: null,
        typeView: 'indicadores',
        permissao_indicador: true,
        permissao_projeto: true,
        permissao_projeto: 0,
    }

    async componentDidMount() {
        await this.props.i18n.loadNamespaces(['client_src_components_modules_inicio_charts_PlanejamentoEstrategicoResumo_PlanejamentoEstrategicoResumo']);
        window.addEventListener('resize', this.verifyScreen);
        this.loadData();
    }

    async componentDidUpdate(prevProps) {
        if (prevProps.mapaId !== this.props.mapaId) {
            this.setState({ mapaId: this.props.mapaId });
            this.loadData();
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.verifyScreen);
    }

    verifyScreen = () => {
        this.setState({ isSmallScreen: LayoutHelper.isSmallScreen() });
    }

    async loadData() {
        this.setState({ loadingTitle: true });

        let { mapasUser, preSelectedMapa } = await Sig.request('GET', 'pe/mapa/getMapaUser', { mapaId: null, select: true });
        let selectedMapa = preSelectedMapa

        if (!preSelectedMapa && mapasUser.length > 0) {
            selectedMapa = mapasUser[0];
        }

        this.setState({ mapasUser, selectedMapa, loadingTitle: false }, () => {   
            if(this.state.selectedMapa == null) {
                this.setState({ loadingContent: false });
                return;
            }
            this.loadMapa();
        });
    }

    async loadMapa() {
        this.setState({ loadingContent: true });
        let { selectedMapa } = this.state;
        let { mapa, perspectivas, permissao_indicador, permissao_projeto } = await Sig.request('GET', 'pe/mapa/getMapa', { id: selectedMapa.value });
        this.setState({ mapa, permissao_indicador, permissao_projeto, perspectivas, loadingContent: false });
    }

    changeMapa = (selectedMapa) => {
        this.setState({ selectedMapa }, () => {
            if (selectedMapa) {
                this.loadMapa(selectedMapa.value);
            }
        });
    }

    changeObjetivo = (objetivoSelectedId, perspectivaSelectedId, index) => {
        this.setState({
            objetivoSelectedId: objetivoSelectedId == this.state.objetivoSelectedId ? null : objetivoSelectedId,
            perspectivaSelectedId: perspectivaSelectedId,
            index
        });
    }

    renderTitle() {

        return (
            <div className={"titlePlanejamento"} style={{ color: Colors.homePage.lightGrey, borderBottom: `1px solid ${Colors.homePage.line}` }}>
                <div style={EssentialStyle.rowFlexStart}>
                    {!this.state.loadingTitle && 
                        <CustomTooltip tooltip={this.props.i18n.t('client_src_components_modules_inicio_charts_PlanejamentoEstrategicoResumo_PlanejamentoEstrategicoResumo:openStrategicMapInNewTab')}>
                            <FontAwesomeIcon
                                icon={faArrowUpRightFromSquare}
                                style={{
                                    color: Colors.dark,
                                    marginRight: 10,
                                    marginLeft: 10,
                                    cursor: 'pointer'
                                }}
                                onClick={() => {
                                    if (this.state.selectedMapa?.value) window.parent.postMessage({ type: '_blank', url: `/pe/mapa?id=${this.state.selectedMapa.value}` }, '*'); 
                                }}
                            />
                        </CustomTooltip>
                    }

                    {!this.state.loadingTitle &&
                        <Select
                            styles={{
                                control: (base, status) => ({ ...base, ...EssentialStyle.titleBoxHomePage, border: 'none' }),
                                singleValue: (provided) => ({ ...provided, color: Colors.homePage.title }),
                                option: (provided, state) => ({
                                    ...provided,
                                    color: 'black',
                                    backgroundColor: state.isFocused ? 'lightgray' : 'none',
                                }),
                            }}

                            options={this.state.mapasUser}
                            value={this.state.selectedMapa}
                            onChange={(e) => this.changeMapa(e)}
                            placeholder={this.props.i18n.t('client_src_components_modules_inicio_charts_PlanejamentoEstrategicoResumo_PlanejamentoEstrategicoResumo:selectStrategicMap')}
                        />
                    }
                </div>

                {this.state.typeView == 'projetos' && this.state.selectedMapa?.value && <ProgressBarCustom tipo={'mapaEstrategico'} id={this.state.selectedMapa.value} style={{ width: '100%', padding: '5px 5px' }}/>}

                <DefaultButton
                    id={'switch-view-objetivo'}
                    leftIcon={
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                            <CustomTooltip
                                tooltip={this.props.i18n.t('client_src_components_modules_inicio_charts_PlanejamentoEstrategicoResumo_PlanejamentoEstrategicoResumo:viewIndicators')}
                                placement={'left'}
                            >
                                <FontAwesomeIcon icon={faBookmark} />
                            </CustomTooltip>
                            <Form.Switch
                                type="switch"
                                disabled={!this.state.podeEditarPriorizacao}
                                checked={this.state.typeView === 'projetos'}
                                style={{ marginLeft: 3, marginRight: 3 }}
                            />
                            <CustomTooltip
                                tooltip={this.props.i18n.t('client_src_components_modules_inicio_charts_PlanejamentoEstrategicoResumo_PlanejamentoEstrategicoResumo:viewProjects')}
                                placement={'left'}
                            >
                                <FontAwesomeIcon icon={faBookOpen} />
                            </CustomTooltip>
                        </div>
                    }
                    color={Colors.secondaryButton}
                    textColor={Colors.dark}
                    loading={this.state.loading}
                    onClick={() => { this.setState({ typeView: this.state.typeView === 'indicadores' ? 'projetos' : 'indicadores' }) }}
                />

            </div>
        )
    }

    renderContent() {

        const renderloadingContent = () => {
            return (
                <div className={"loadingPlanejamento"}>
                    <DefaultLoader />
                </div>
            );
        }

        const renderEmptyPerspectivas = () => {
            return (
                <div className={"emptyPlanejamento"}>
                    <div style={{
                        ...EssentialStyle.columnCenter,
                        width: '100%'
                    }}>
                        <img
                            src={`${gateway}/img/undraw/undraw_financial_data.svg`}
                            alt={this.props.t('client_src_components_modules_inicio_charts_PlanejamentoEstrategicoResumo_PlanejamentoEstrategicoResumo:altPessoasOlhandoQuadro')}
                            style={{
                                height: '80%',
                                maxHeight: 200
                            }}
                        />
                        <span style={{ ...EssentialStyle.titleBoxHomePage }}>
                            {this.props.t('client_src_components_modules_inicio_charts_PlanejamentoEstrategicoResumo_PlanejamentoEstrategicoResumo:noPerspectivesAndObjectives_1')} <strong>{this.props.t('client_src_components_modules_inicio_charts_PlanejamentoEstrategicoResumo_PlanejamentoEstrategicoResumo:noPerspectivesAndObjectives_2')}</strong> {this.props.t('client_src_components_modules_inicio_charts_PlanejamentoEstrategicoResumo_PlanejamentoEstrategicoResumo:noPerspectivesAndObjectives_3')} <strong>{this.props.t('client_src_components_modules_inicio_charts_PlanejamentoEstrategicoResumo_PlanejamentoEstrategicoResumo:noPerspectivesAndObjectives_4')}</strong>!
                        </span>
                    </div>
                </div>
            );
        }

        const renderListPerspectivas = () => {
            return (
                <div className={"bodyPlanejamento"}>
                    {this.state.perspectivas.map((perspectiva, index) => renderPerspectiva(perspectiva))}
                </div>
            );
        }

        const renderPerspectiva = (perspectiva) => {
            let nomePerspectiva = perspectiva.perspectiva.nome;
            return (
                <div className={"perspectivaPlanejamento"} key={`key-persectiva-${perspectiva.perspectiva.id}`}>
                    <div className={"nomePerspectiva"}>
                        <span>{DataHelper.removeHtmlTags(nomePerspectiva)}</span>
                        <hr className={"line"} />
                    </div>
                    <div className={"listObjetivos"}>
                        {perspectiva.objetivos.map((objetivo, index) =>
                            <ProjetoObjetivoCardChart
                                key={`key-objetivo-${objetivo.objetivo.id}`}
                                objetivo={objetivo.objetivo}
                                permissao_indicador={this.state.permissao_indicador}
                                permissao_projeto={this.state.permissao_projeto}
                                style={{ margin: 0, maxWidth: 250, minWidth: 250, maxHeight: 130, minHeight: 130 }}
                                onClick={() => this.changeObjetivo(objetivo.objetivo.id, perspectiva.perspectiva.id, index)}
                                selectedStyle={{ borderRadius: '8px 8px 0px 0px' }}
                                isSelected={this.state.objetivoSelectedId == objetivo.objetivo.id}
                                tipeView={this.state.typeView}
                            />
                        )}
                    </div>
                    <Collapse in={this.state.perspectivaSelectedId == perspectiva.perspectiva.id && this.state.objetivoSelectedId != null}>
                        <div className={"collapseDetalhamento"}>
                            {this.state.perspectivaSelectedId == perspectiva.perspectiva.id
                                && this.state.objetivoSelectedId != null
                                && <DetalhamentoObjetivoResumo
                                    id={this.state.objetivoSelectedId}
                                    permissao_indicador={this.state.permissao_indicador}
                                    permissao_projeto={this.state.permissao_projeto}
                                    index={this.state.index}
                                    typeView={this.state.typeView}
                                    callbackTypeView={(typeView) => this.changeTypeView(typeView)}
                                />}
                        </div>
                    </Collapse>
                </div>
            );
        }

        if (this.state.loadingContent) return renderloadingContent();
        return this.state.perspectivas && this.state.perspectivas.length > 0 ? renderListPerspectivas() : renderEmptyPerspectivas();

    }

    render() {

        return (
            <div
                className={"cardPlanejamento"}
                style={{ ...EssentialStyle.card, minHeight: `calc(100vh - ${ChartHelper.getHeaderHeight()}px - 60px)` }}
            >
                {this.renderTitle()}
                {this.renderContent()}
            </div>
        );
    }
}

export default withTranslation()(PlanejamentoEstrategicoResumo);