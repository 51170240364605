import React from "react";
import { withTranslation } from "react-i18next";
import Colors from "../../../constants/Colors";
import DefaultButton from "../../tools/DefaultButton";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink, faTimes, faCheck } from '@fortawesome/free-solid-svg-icons';
import EssentialStyle from "../../../style/EssentialStyle";
import DataHelper from "../../../helper/DataHelper";
import Sig from "../../../api/Sig";
import PlanoAcaoEtapaDependenciaAdd from "./PlanoAcaoEtapaDependenciaAdd";
import PlanoAcaoEtapaCard from "./PlanoAcaoEtapaCard/PlanoAcaoEtapaCard";

class PlanoAcaoEtapaDependencia extends React.Component {
    state = {
        addingNew: false,
        addingSelectedDependencia: null,
        addingSelectedTipo: null,
        dependencias: [],
        tipos: [],
        dependeciasPossiveis: [],
        loading: true,
        tiposReversos: []
    }

    async componentDidMount() {
        await this.props.i18n.loadNamespaces(["client_src_components_modules_planoAcao_PlanoAcaoEtapaDependencia"])
        await this.loadData();
        this.setState({ loading: false });
    }

    async loadData() {
        this.setState({ loading: true });
        await this.loadTipos();
        await this.loadDependencias();
        this.setState({ loading: false });
    }

    async loadDependencias() {
        this.setState({ loading: true });
        let dependencias = await Sig.request('POST', 'planoAcao/listDependencias', { id: this.props.id });
        this.setState({ dependencias: dependencias || [] }, () => { this.setState({ loading: false }) });
    }

    async loadTipos() {
        let tipos = await Sig.request('POST', 'planoAcao/listDependeciaTipos');
        this.setState({ 
            tipos: tipos.tipos, 
            addingSelectedTipo: DataHelper.transformArrayToSelectData(tipos.tipos)[0], 
            tiposReversos: tipos.tiposReversos 
        });
    }

    saveDependencia = async (tipo, dependencia) => {
        this.setState({ loading: true });
        await Sig.request('POST', 'planoAcao/saveEtapaDependencia', { id: this.props.id, tipo: tipo, dependencia: dependencia });
        await this.loadDependencias();
        this.setState({ loading: false });
    }

    renderAddNew() {
        return this.state.addingNew ?
            this.state.loading ? null : (
                <div style={{ ...EssentialStyle.columnCenter, width: '100%' }}>
                    <PlanoAcaoEtapaDependenciaAdd
                        tipos={DataHelper.transformArrayToSelectData(this.state.tipos)}
                        defaultTipo={this.state.addingSelectedTipo}
                        id={this.props.id}
                        saveCallback={(keepDates) => { this.setState({ addingNew: false }); this.loadDependencias(); this.props.updateEtapasCallback(); this.props.updateModalCallback(keepDates); }}
                        changeCallback={(tipo, dependencia) => {
                            this.setState({ addingSelectedDependencia: dependencia, addingSelectedTipo: tipo });
                        }}
                    />
                </div>
            ) :
            (
                <div style={{ ...EssentialStyle.columnCenter, width: '100%' }}>
                    {!this.state.loading && this.renderDependeciasList()}
                    {
                        !this.props.disabled &&
                        <DefaultButton
                            title={this.props.i18n.t('client_src_components_modules_planoAcao_PlanoAcaoEtapaDependencia:adicionar_dependencia')}
                            color={Colors.tag}
                            textColor={Colors.dark}
                            style={{ marginBottom: 20, width: '100%' }}
                            loading={this.state.loading}
                            leftIcon={<FontAwesomeIcon icon={faLink} />}
                            onClick={() => {
                                this.setState({ addingNew: true })
                            }}
                        />
                    }
                </div>
            );
    }

    renderDependeciasList() {
        let tiposDependencias = this.state.tipos.map(tipo => ([]));
        this.state.dependencias.forEach(dependencia => {
            if (dependencia.origem) {
                tiposDependencias[dependencia.dependecia_tipo].push(dependencia);
            } else {
                tiposDependencias[this.state.tiposReversos[dependencia.dependecia_tipo]].push(dependencia);
            }
        });
        return (
            <div style={{ ...EssentialStyle.columnCenter, width: '100%', paddingBottom: 10 }}>
                {tiposDependencias.map((dependencias, index) => {
                    return (
                        <div key={index} style={{ ...EssentialStyle.columnCenter, width: '100%', paddingTop: dependencias.length ? 10 : 0 }}>
                            {dependencias.length ? <div style={{ ...EssentialStyle.rowFlexStart, fontWeight: 600, fontSize: 12, width: '100%', marginBottom: 8 }}>{this.state.tipos[index].toUpperCase()}</div> : null}
                            {dependencias.map((dependencia, index2) => {
                                return (
                                    <div key={dependencia.id} style={{ ...EssentialStyle.rowSpaceBetween, width: '100%' }}>
                                         <PlanoAcaoEtapaCard 
                                            key={`plano-acao-etapa-${index}-${index2}`} 
                                            resumed={true}
                                            detailed={false}
                                            etapa={{ ...dependencia }} 
                                            participantes={this.props.participantes} 
                                            borderBottom={index2 == dependencias.length - 1 ? `1px solid ${Colors.border}` : `none`}
                                            removeCallback={async (id_etapa, callback) => {
                                                this.setState({ loading: true });
                                                await Sig.request('POST', 'planoAcao/removeEtapaDependencia', { id: dependencia.id_dependencia });
                                                await this.loadDependencias();
                                                this.props.updateEtapasCallback(); 
                                                this.props.updateModalCallback(true);
                                                this.setState({ loading: false });
                                            }}
                                            detailsModalCallback={this.props.detailsModalCallback}
                                        />
                                    </div>
                                );
                            })}
                        </div>
                    );
                })}
            </div>
        );
    }

    renderDependencias() {
        return (
            <div style={{ ...EssentialStyle.columnCenter, width: '100%' }}>
                {this.renderAddNew()}
            </div>
        );
    }

    render() {
        return (
            <div style={{ width: '100%', marginBottom: 10 }}>
                {this.renderDependencias()}
            </div>
        );
    }
}

export default withTranslation()(PlanoAcaoEtapaDependencia);