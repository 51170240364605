import React from "react";

import './ConfigEstrutura.css';
import IdiomaHelper from "../../../../../../helper/IdiomaHelper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamation, faExclamationTriangle,  faLock, faPlusSquare, faChevronLeft, faBars, faQuestionCircle, faTimesCircle, faPaperPlane, faTrash } from "@fortawesome/free-solid-svg-icons";
import CustomTooltip from "../../../../../tools/CustomTooltip";
import DefaultButton from "../../../../../tools/DefaultButton";
import Colors from "../../../../../../constants/Colors";
import EssentialStyle from "../../../../../../style/EssentialStyle";
import {Collapse} from "react-bootstrap";
import SessionHelper from "../../../../../../helper/SessionHelper";
import { Form, Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import DragAndDropHelper from "../../../../../../helper/DragAndDropHelper";

export default class ConfigEstrutura extends React.Component {

    state = {
        colunas: this.props.colunas || [],
        addCuluna: false,
        coluna: {
            texto: IdiomaHelper.getIdiomas().map(idioma => ({ id_idioma: idioma.id, descricao: '' })),
            id_diagnostico_periodo_questionario: null,
            hasNodes: false,
        },
    }

    componentDidMount() {
        this.props.reload();
    }

    componentDidUpdate(prevProps) {
        
        if (prevProps.colunas !== this.props.colunas) {
            this.setState({ colunas: this.props.colunas });
        }
    }

    message = (lang) => {
        return IdiomaHelper.getStruct({
            'pt_br': { 
                obsOne: 'Na tabela do questionário, a última coluna será destinada às perguntas que serão feitas.',
                obsTwo: 'As demais colunas servem para o agrupamento das áreas de cada questão.',
                obsThree: 'Para preencher esta tabela com as perguntas desejadas, é necessário ter pelo menos uma coluna, e todas devem ter o nome preenchido em pelo menos um idioma.',
                blocked: 'Edição bloqueada',
                blockedInfo: 'A edição deste questionário está bloqueado, pois já foi respondido por algum usuário ou você não permissão para editar.',
                questionInfo: 'Esta coluna será utilizada para as perguntas que serão feitas no questionário.',
                addColumnPlaceholder: 'Nome da coluna...',
                addColumn: 'Adicionar coluna',
                add: 'Adicionar',
                editColumn: 'Editar coluna',
                save: 'Salvar',
                delete: 'Excluir',
                errorText: 'Adicione o nome da coluna em ao menos uma linguagem',
                textExample: 'Exemplo de como está ficando a estrutura do questionário',
                columnExample: 'Pergunta',
            },
            'en': { 
                obsOne: 'In the questionnaire table, the last column will be used for the questions that will be asked.',
                obsTwo: 'The other columns are used to group the areas of each question.',
                obsThree: 'To fill this table with the desired questions, it is necessary to have at least one column, and all must have the name filled in at least one language.',
                blocked: 'Blocked edition',
                blockedInfo: 'Editing this questionnaire is blocked, as it has already been answered by some user or you do not have permission to edit.',
                questionInfo: 'This column will be used for the questions that will be asked in the questionnaire.',
                addColumnPlaceholder: 'Column name...',
                addColumn: 'Add column',
                add: 'Add',
                editColumn: 'Edit column',
                save: 'Save',
                delete: 'Delete',
                errorText: 'Add the column name in at least one language',
                textExample: 'Example of how the questionnaire structure is looking',
                columnExample: 'Question',
            },
            'es': { 
                obsOne: 'En la tabla del cuestionario, la última columna se utilizará para las preguntas que se harán.',
                obsTwo: 'Las demás columnas se utilizan para agrupar las áreas de cada pregunta.',
                obsThree: 'Para completar esta tabla con las preguntas deseadas, es necesario tener al menos una columna, y todas deben tener el nombre completado en al menos un idioma.',
                blocked: 'Edición bloqueada',
                blockedInfo: 'La edición de este cuestionario está bloqueada, ya que ya ha sido respondida por algún usuario o no tiene permiso para editar.',
                questionInfo: 'Esta columna se utilizará para las preguntas que se harán en el cuestionario.',
                addColumnPlaceholder: 'Nombre de la columna...',
                addColumn: 'Agregar columna',
                add: 'Agregar',
                editColumn: 'Editar columna',
                save: 'Salvar',
                delete: 'Eliminar',
                errorText: 'Agregue el nombre de la columna en al menos un idioma',
                textExample: 'Ejemplo de cómo está quedando la estructura del cuestionario',
                columnExample: 'Pregunta',
            },
        })[lang];
    }

    addColumn = () => { 

        if(!(this.state.coluna.texto.some(texto => texto.descricao && texto.descricao.trim() !== ''))) {
            toast.error(this.message(this.props.lang).errorText);
            return;
        }

        let coluna = this.state.coluna;
        let colunas = this.state.colunas;

        if (this.state.addCuluna === 'new') {         

            coluna.drag_index = 0;
            colunas.map(coluna => coluna.drag_index = Number(coluna.drag_index) + 1);

            colunas = [coluna, ...colunas];

        } else {

            colunas = colunas.map(col => {
                if (col.drag_index === coluna.drag_index) {
                    return coluna;
                }
                return col;
            });

        }

        this.setState({
            colunas: [...colunas],
            addCuluna: false,
            coluna: {
                texto: IdiomaHelper.getIdiomas().map(idioma => ({ id_idioma: idioma.id, descricao: '' })),
                hasNodes: false,
                id_diagnostico_periodo_questionario: null,
            },
        }, () => {
            this.props.callback(colunas);
        });
    }

    deleteColumn = () => {
            
            let coluna = this.state.coluna;
            let colunas = this.state.colunas;
    
            colunas = colunas.filter(col => col.drag_index !== coluna.drag_index);
    
            this.setState({
                colunas: [...colunas],
                addCuluna: false,
                coluna: {
                    texto: IdiomaHelper.getIdiomas().map(idioma => ({ id_idioma: idioma.id, descricao: '' })),
                    hasNodes: false,
                    id_diagnostico_periodo_questionario: null,
                },
            }, () => {
                this.props.callback(colunas);
            });
    }

    renderIsBlocked() {

        if (!this.props.blocked) return (null);
        return (
            <CustomTooltip tooltip={this.message(this.props.lang).blockedInfo} position="top">
                <div className="blocked">
                    <FontAwesomeIcon icon={faLock} />
                    {this.message(this.props.lang).blocked}
                </div>
            </CustomTooltip>
        )
    }

    renderObservation() {
        return (
            <div className="observation">
                { this.props.blocked && this.renderIsBlocked()}
                {!this.props.blocked && <span><FontAwesomeIcon icon={faExclamationTriangle} style={{ marginRight: 10 }}/>{this.message(this.props.lang).obsOne}</span>}
                {!this.props.blocked && <span><FontAwesomeIcon icon={faExclamationTriangle} style={{ marginRight: 10 }}/>{this.message(this.props.lang).obsTwo}</span>}
                {!this.props.blocked && <span><FontAwesomeIcon icon={faExclamationTriangle} style={{ marginRight: 10 }}/>{this.message(this.props.lang).obsThree}</span>}
            </div>
        )
    }

    renderAddButton() {

        const renderAddButton = () => {
            return (
                <DefaultButton
                    title={IdiomaHelper.getStruct({
                        'pt_br': 'Adicionar coluna',
                        'en': 'Add column',
                        'es': 'Agregar columna',
                    })[this.props.lang]}
                    leftIcon={<FontAwesomeIcon icon={faPlusSquare} />}
                    color={Colors.success}
                    onClick={() => { !this.props.blocked && this.setState({ addCuluna: 'new' }) }}
                    disabled={this.props.blocked || this.props.blockedDrag}
                />
            )
        }

        const renderBackButton = () => {
            return (
                <DefaultButton
                    leftIcon={<FontAwesomeIcon icon={faChevronLeft} />}
                    color={Colors.secondaryButton}
                    textColor={Colors.dark}
                    onClick={() => 
                        this.setState({ 
                            addCuluna: false,
                            coluna: {
                                texto: IdiomaHelper.getIdiomas().map(idioma => ({ id_idioma: idioma.id, descricao: '' })),
                                hasNodes: false,
                                id_diagnostico_periodo_questionario: null,
                            }
                        })
                    }
                    disabled={this.props.blocked}
                />
            )
        }

        return ( this.state.addCuluna ? renderBackButton() : renderAddButton())
    }

    renderListColunas() {

        let colunas = this.state.colunas;

        const onDragStartItem = async (e, item) => {
            this.setState({ draggingItem: item, draggingOverItem: null, widthDragItem: e.target.offsetWidth });
            setTimeout(() => { e.target.style.opacity = 0.5 }, 5);
        }
    
        const onDragEndItem = async (e, item) => {
            e.target.style.opacity = 1;
    
            let draggingOverItem = this.state.draggingOverItem;
            
            if (draggingOverItem !== null) {
                colunas.splice(colunas.indexOf(this.state.draggingItem), 1);
                let indexToInsert = colunas.indexOf(draggingOverItem);
                if(this.state.left) {
                    colunas.splice(indexToInsert, 0, item);
                } else {
                    colunas.splice(indexToInsert + 1, 0, item);
                }
            }

            colunas = colunas.map((coluna, index) => {
                return {...coluna, drag_index: index + 1};
            });
    
            this.setState({ colunas: colunas}, () => {
                this.setState({ draggingItem: null, draggingOverItem: null, left: false }, () => this.props.callback(colunas));
            });
        }
    
        const onDragEnterItem = async (e, item) => {

            let left = DragAndDropHelper.checkLeft(e);
    
            this.setState({ left });
    
            if (item.id !== this.state.draggingItem.id) {
                this.setState({ draggingOverItem: item });
            }
        }

        const onDragOverItem = async (e) => {
            e.preventDefault(); // Impede o comportamento padrão do navegador
        };

        colunas = this.state.colunas.sort((a, b) => a.drag_index - b.drag_index);

        return (
            <div className="line-colunas">
                {colunas.map((coluna, index) => {

                    let isLast = colunas.length == (index + 1);
                    let isSelect = this.state.addCuluna === 'edit' && this.state.coluna.drag_index === coluna.drag_index;

                    const descricao = coluna?.texto?.find(nome => nome.id_idioma === IdiomaHelper.getSiglaId(this.props.lang))?.descricao;
                    let name;
                    if (descricao && descricao !== '') {
                        name = descricao;
                    } else {
                        name = isLast ? this.message(this.props.lang).columnExample : <FontAwesomeIcon icon={faExclamation} />;
                    }

                    return (
                        <div 
                            key={`coluna-${coluna.nome}-${coluna.id}`}
                            className={`coluna ${isLast && 'last'}  ${!this.props.blocked && !this.props.blockedDrag && 'drag'}`}
                            onDragStart={(e) => { onDragStartItem(e, coluna) }}
                            onDragEnter={(e) => { onDragEnterItem(e, coluna) }}
                            onDragEnd={(e) => { onDragEndItem(e, coluna) }}
                            onDragOver={(e) => { onDragOverItem(e) }}
                            draggable={ !this.props.blocked && !this.props.blockedDrag && this.state.addCuluna === false }
                            onClick={() => { !this.props.blocked && this.setState({addCuluna: 'edit', coluna: coluna}) }}
                            style={{
                                marginLeft: this.state.draggingOverItem && this.state.draggingOverItem.id === coluna.id && this.state.left ? this.state.widthDragItem : 0,
                                marginRight: this.state.draggingOverItem && this.state.draggingOverItem.id === coluna.id && !this.state.left ? this.state.widthDragItem : 0,
                                backgroundColor: isSelect ? SessionHelper.getColor() : ''
                            }}
                        >
                            { this.props.blocked && <FontAwesomeIcon icon={faLock} />}
                            { !this.props.blocked && !this.props.blockedDrag && this.state.addCuluna == false && <FontAwesomeIcon icon={faBars}/> }
                            { name }
                            { isLast && <CustomTooltip tooltip={this.message(this.props.lang).questionInfo} position="top"><FontAwesomeIcon icon={faQuestionCircle} /></CustomTooltip> }
                        </div>
                    )
                })}
            </div>
        )
    }

    renderExemplo() {

        let colunas = this.state.colunas;
        colunas = colunas.sort((a, b) => a.drag_index - b.drag_index);

        return (
            <div className="exemplo">
                <div className="title">
                    {this.message(this.props.lang).textExample}
                </div>
                <div className="table-content">
                    <table >
                        <thead>
                            <tr>
                                {colunas.map((coluna, index) => {
                                    let isLast = colunas.length == (index + 1);
                                    const descricao = coluna?.texto?.find(nome => nome.id_idioma === IdiomaHelper.getSiglaId(this.props.lang))?.descricao;
                                    let name;
                                    if (descricao && descricao !== '') {
                                        name = descricao;
                                    } else {
                                        name = isLast ? this.message(this.props.lang).columnExample : <FontAwesomeIcon icon={faExclamation} />;
                                    }

                                    return (
                                        <th key={`coluna-exemple-${coluna.id}`}>
                                            { name }
                                        </th>
                                    )
                                })}
                            </tr>
                        </thead>
                    </table>
                </div>
            </div>
        )
    }

    renderAddBanner() {

        const banner = () => {
            return (
                <div className="collapse-content">
                    <div className="headerClose" style={{backgroundColor: SessionHelper.getColor()}}>
                        <div/>
                        <div className="title">
                            {this.message(this.props.lang)[this.state.addCuluna === 'new' ? 'addColumn' : 'editColumn']}
                        </div>
                        <FontAwesomeIcon 
                            icon={faTimesCircle} 
                            style={{ cursor: 'pointer' }} 
                            onClick={() => 
                                this.setState({
                                    addCuluna: false,
                                    coluna: {
                                        texto: IdiomaHelper.getIdiomas().map(idioma => ({ id_idioma: idioma.id, descricao: '' })),
                                        hasNodes: false,
                                        id_diagnostico_periodo_questionario: null,
                                    }
                                })
                            } 
                        />
                    </div>
                    <Form>
                        <Row className="mb-3">
                            {IdiomaHelper.getIdiomas().map((idioma, index) => {
                                return (
                                    <Form.Group className='mb-2' as={Col} xs={12} md={12} key={`idioma-list-${idioma.sigla}`}>
                                        <Form.Label className="form-label-xsm space-between">
                                            {idioma.nome}
                                        </Form.Label>
                                        <div>
                                            <Form.Control 
                                                type="text"
                                                value={this.state.coluna.texto.find(coluna => coluna.id_idioma === idioma.id).descricao}
                                                placeholder={this.message(idioma.sigla).addColumnPlaceholder}
                                                onChange={(event) => {
                                                    const newDescricao = event.target.value;
                                                    this.setState(prevState => ({
                                                        coluna: {
                                                            ...prevState.coluna,
                                                            texto: prevState.coluna.texto.map(coluna => 
                                                                coluna.id_idioma === idioma.id ? { ...coluna, descricao: newDescricao } : coluna
                                                            )
                                                        }
                                                    }));
                                                }}
                                            />
                                        </div>
                                    </Form.Group>
                                )
                            })}
                        </Row>

                        <Row>
                            <div style={{...EssentialStyle.rowFlexEnd, gap: 10}}>

                                {this.state.addCuluna === 'edit' && 
                                    <DefaultButton
                                        title={this.message(this.props.lang).delete}
                                        leftIcon={<FontAwesomeIcon icon={faTrash} />}
                                        color={Colors.error }
                                        onClick={() => {
                                            this.deleteColumn();
                                        }}
                                        disabled={this.props.blocked || this.props.blockedDrag}
                                    />
                                }

                                <DefaultButton
                                    title={this.message(this.props.lang)[this.state.addCuluna === 'new' ? 'add' : 'save']}
                                    leftIcon={<FontAwesomeIcon icon={faPaperPlane} />}
                                    color={Colors.success}
                                    onClick={() => {
                                        this.addColumn();
                                    }}
                                />
                            </div>
                        </Row>
                    </Form>
                    
                </div>
            )
        }

        return (
            <div className={"content-banner"}>
                <Collapse in={this.state.addCuluna ? true : false} style={{width: 500}}>
                    { this.state.addCuluna ? banner() : <></> }
                </Collapse>
            </div>
        )
    }

    renderConfigEstrutura() {
        return (
            <div className="ConfigEstrutura">
                {this.renderObservation()}
                <div className="content">
                    <div style={{...EssentialStyle.rowFlexStart, alignItems: 'center', gap: 10}}>
                        {this.renderAddButton()}
                        {this.renderListColunas()}
                    </div>
                </div>
                {this.renderExemplo()}
                {this.renderAddBanner()}
            </div>
        )
    }

    render() {
        return this.renderConfigEstrutura();
    }
}