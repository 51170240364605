import React from "react";

import './RetrospectivaExport2024.css';

import domtoimage from 'dom-to-image';
import moment from 'moment';
import { toast } from "react-toastify";

import EssentialStyle from "../../../../style/EssentialStyle";
import Constants from '../../../../constants/Api';
import Colors from "../../../../constants/Colors";
import DefaultButton from '../../../tools/DefaultButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import DefaultLoader from "../../../tools/DefaultLoader";
import Sig from "../../../../api/Sig";

const gateway = Constants.getSigEndPoint();

export default class RetrospectivaExport2024 extends React.Component {
    state = {
        resumo: this.props.resumo,
        screenHeight: this.props.screenHeight,
        isSmallScreen: this.props.isSmallScreen,
        exporting: false,
        shareButtonClicked: false
    }

    async componentDidUpdate(prevProps) {
        if (prevProps.resumo !== this.props.resumo) {
            this.setState({ resumo: this.props.resumo });
        }

        if (prevProps.screenHeight !== this.props.screenHeight) {
            this.setState({ screenHeight: this.props.screenHeight });
        }

        if (prevProps.isSmallScreen !== this.props.isSmallScreen) {
            this.setState({ isSmallScreen: this.props.isSmallScreen });
        }
    }

    renderLeft() {
        return (
            <div
                style={{
                    ...EssentialStyle.columnCenter,
                    alignItems: 'center',
                    width: '40%',
                    height: '100%'
                }}
            >
                <img
                    src={`${gateway}/img/retrospectiva/perfil/${this.state?.resumo?.arquetipo?.image}`}
                    alt="Imagem do Arquetipo"
                    style={{
                        height: this.state.screenHeight * 0.4
                    }}
                />
            </div>
        );
    }

    exportAndShare = async () => {
        this.setState({ shareButtonClicked: true, exporting: true }, async () => {
            setTimeout(async () => {
                const scaleFactor = 1.5;
                const image = document.getElementById('retrospectiva-export');

                try {
                    let png = await domtoimage.toPng(image, {
                        borderRadius: 5,
                        style: {
                            transform: `scale(${scaleFactor})`,
                            transformOrigin: 'top left',
                            width: `${image.offsetWidth}px`,
                            height: `${image.offsetHeight}px`
                        },
                        width: image.offsetWidth * scaleFactor,
                        height: image.offsetHeight * scaleFactor
                    });

                    // Download File
                    // let timestamp = new Date().getTime();

                    // var link = document.createElement('a');
                    // link.download = `retrospectiva-sig-${timestamp}.png`;
                    // link.href = png;
                    // link.click();
                    // link.remove();

                    setTimeout(async () => {
                        // Convert base64 to Blob
                        const base64Response = await fetch(png);
                        const blob = await base64Response.blob();

                        // Create FormData and append the Blob
                        const formData = new FormData();
                        formData.append('file', blob, 'retrospectiva.png');

                        const req = await Sig.request("POST", '/retrospectiva/uploadImageAndGenerateUrl', formData, 'multipart/form-data');

                        if (req.status != 200) {
                            throw new Error('Erro ao gerar a imagem');
                        }

                        let url = req?.url;
                        // let url = `https://teste.sig.grupoadvis.com.br/img/retrospectiva/upload/2024-26165.html`;

                        // Construct the LinkedIn sharing URL
                        const linkedInUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(url)}&text=${encodeURIComponent(`Confira minha retrospectiva 2024 no SIG, a plataforma de gestão que utilizamos para alavancar o crescimento!`)}`;

                        // Open the LinkedIn sharing URL in a new window
                        window.open(linkedInUrl, '_blank');

                        this.setState({ exporting: false });
                    }, 1000);
                } catch (error) {
                    toast.error('Houve um problema ao gerar a imagem');
                    this.setState({ exporting: false });
                }
            }, 3000);
        });
    }

    getMonthName(mesString) {
        let mes = moment(mesString, 'MM/YY').format('MMMM');
        mes = mes.charAt(0).toUpperCase() + mes.slice(1);
        return mes;
    }

    renderRight() {
        let mesString = this.state?.resumo?.melhorMes;
        let mes = this.getMonthName(mesString);

        if (mes == "Invalid date") mes = null;

        return (
            <div
                style={{
                    ...EssentialStyle.columnCenter,
                    alignItems: 'flex-start',
                    width: this.state.isSmallScreen && !this.state.exporting ? '100%' : '65%',
                    height: '100%',
                    paddingBottom: this.state.isSmallScreen && !this.state.exporting ? 100 : 0
                }}
            >
                <span
                    className="retro-default-text"
                    style={{
                        fontSize: this.state.isSmallScreen && !this.state.exporting ? '1.8rem' : '2.1rem',
                        marginTop: this.state.isSmallScreen && !this.state.exporting ? 50 : 0,
                        marginBottom: this.state.isSmallScreen && !this.state.exporting ? 30 : 0,
                        textAlign: 'left',
                        width: '100%',
                    }}
                >
                    Em  <b className={`${!this.state.exporting ? `shine` : ``}`}>2024</b> meu perfil foi de <b className={`${!this.state.exporting ? `shine` : ``}`}>{this.state?.resumo?.arquetipo?.nome}</b>
                </span>
                <span style={{ fontSize: '1.2rem', maxWidth: this.state.isSmallScreen && !this.state.exporting ? '100%' : '85%' }}>
                    {this.state?.resumo?.arquetipo?.resumo}
                </span>
                <span
                    style={{
                        ...EssentialStyle.columnCenter,
                        alignItems: 'flex-start',
                        width: '100%',
                        fontSize: this.state.isSmallScreen && !this.state.exporting ? '1.4rem' : '1.6rem',
                        textAlign: this.state.isSmallScreen && !this.state.exporting ? 'left' : 'justify',
                    }}
                    className="retro-default-text"
                >
                    <div style={{ marginTop: 12 }} />

                    {this.state?.resumo?.gmr ?
                        <span style={{ marginTop: 12 }}>
                            Minha performance geral em painéis foi de <b className={`${!this.state.exporting ? `shine` : ``}`}>{this.state?.resumo?.gmr.toFixed(2).replace('.', ',')}%</b>
                        </span>
                        : null}

                    {mes ?
                        <span style={{ marginTop: 12 }}>
                            Meu mês de maior performance foi <b className={`${!this.state.exporting ? `shine` : ``}`}>{mes}</b>
                        </span>
                        : null}

                    {this.state?.resumo?.pdis ?
                        <span style={{ marginTop: 12 }}>
                            Desenvolvi <b className={`${!this.state.exporting ? `shine` : ``}`}>{this.state?.resumo?.pdis}</b> PDI's
                        </span>
                        : null}

                    {this.state?.resumo?.feedbacks ?
                        <span style={{ marginTop: 12 }}>
                            Participei de <b className={`${!this.state.exporting ? `shine` : ``}`}>{this.state?.resumo?.feedbacks}</b> feedbacks
                        </span>
                        : null}

                    {this.state?.resumo?.interacoes ?
                        <span style={{ marginTop: 12 }}>
                            Interagi com <b className={`${!this.state.exporting ? `shine` : ``}`}>{this.state?.resumo?.interacoes}</b> colaboradores no SIG
                        </span>
                        : null}
                </span>

                {this.state.exporting ?
                    <div style={{
                        ...EssentialStyle.rowFlexEnd,
                        width: '100%',
                        paddingRight: '150px'
                    }}>
                        <img
                            src={`${gateway}/img/retrospectiva/retrosig24.png`}
                            alt="Logo da Retrospectiva SIG 2024, com elementos geométricos a esquerda e RetroSIG escrito em uma fonte moderna a direita."
                            style={{
                                height: 64,
                                marginRight: 20
                            }}
                        />
                        <img
                            src={`${gateway}/img/retrospectiva/retrosig24_advis.png`}
                            alt="Logo da Grupo Advis."
                            style={{
                                height: 32
                            }}
                        />
                    </div>
                    :
                    <div
                        style={{
                            ...EssentialStyle.rowFlexEnd,
                            flexDirection: this.state.isSmallScreen && !this.state.exporting ? 'column-reverse' : 'row',
                            justifyContent: this.state.isSmallScreen && !this.state.exporting ? 'center' : 'flex-end',
                            width: '100%',
                            marginTop: this.state.isSmallScreen && !this.state.exporting ? 50 : 30,
                            paddingRight: '3%'
                        }}
                    >
                        {this.state.shareButtonClicked ?
                            <DefaultButton
                                color={'#E8E8E4'}
                                textColor={'#3082EE'}
                                style={{
                                    borderRadius: '32px',
                                    paddingLeft: '20px',
                                    paddingRight: '20px',
                                    fontSize: '1.2rem',
                                    fontWeight: '600',
                                    marginRight: this.state.isSmallScreen ? 0 : 10
                                }}
                                leftIcon={<FontAwesomeIcon icon={faTimes} />}
                                title={'Fechar'}
                                onClick={this.props.exitCallback}
                            />
                            : null}
                        <DefaultButton
                            color={'#3082EE'}
                            style={{
                                borderRadius: '32px',
                                paddingLeft: '20px',
                                paddingRight: '20px',
                                fontSize: '1.2rem',
                                fontWeight: '600',
                                marginBottom: this.state.isSmallScreen ? 15 : 0
                            }}
                            leftIcon={<FontAwesomeIcon icon={faLinkedin} />}
                            title={'Compartilhar no LinkedIn'}
                            onClick={this.exportAndShare}
                        />
                    </div>
                }



            </div>
        );
    }

    renderResumo() {
        return (
            <div
                style={{
                    ...EssentialStyle.columnCenter,
                    width: '100%',
                    height: '100%'
                }}
            >
                <div
                    id="retrospectiva-export"
                    className={`${this.state.exporting ? `last-retro-gradient` : ``}`}
                    style={{
                        ...EssentialStyle.rowSpaceBetween,
                        width: this.state.exporting ? '1600px' : '100%',
                        height: this.state.exporting ? `${1600 / 1.91}px` : '100%',
                        paddingLeft: '30px',
                        paddingRight: '30px',
                    }}
                >
                    {this.state.isSmallScreen && !this.state.exporting ? null : this.renderLeft()}
                    {this.renderRight()}
                </div>
            </div>
        );
    }

    render() {
        return (
            <div
                style={{
                    ...EssentialStyle.columnCenter,
                    width: '100%',
                    height: this.state.isSmallScreen ? 'auto' : this.state.screenHeight
                }}
            >
                {this.state.exporting ?
                    <div
                        className="last-retro-gradient"
                        style={{
                            ...EssentialStyle.rowFlexCenter,
                            width: '100%',
                            height: this.state.screenHeight,
                            position: 'absolute'
                        }}>
                        <DefaultLoader color={'#E8E8E4'} />
                        <div style={{ ...EssentialStyle.columnStart }}>
                            <b className="shine" style={{ marginLeft: 10 }}>Exportanto e preparando compartilhamento...</b>
                            <span className="shine" style={{ marginLeft: 10, fontStyle: 'italic' }}>Permita os pop-ups para compartilhar</span>
                        </div>
                    </div>
                    : null}

                {this.renderResumo()}
            </div>
        );
    }
}