import React, { useRef, useImperativeHandle, forwardRef } from "react";
import { Collapse } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import Colors from "../../../../constants/Colors";
import EssentialStyle from "../../../../style/EssentialStyle";
import SessionHelper from "../../../../helper/SessionHelper";
import TextEditor from "../../../tools/TextEditor/TextEditor";
import Sig from "../../../../api/Sig";

class AtaReuniaoConvocacaoTextoImpressao extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            convocacao_texto_impressao: this.props.convocacao_texto_impressao,
            isSmallScreen: this.props.isSmallScreen,
            expandConvocacaoBar: false,
        };
        this.timeout = null;
    }

    componentDidUpdate(prevProps) {
        if (prevProps.convocacao_texto_impressao !== this.props.convocacao_texto_impressao && this.props.convocacao_texto_impressao !== this.state.convocacao_texto_impressao) {
            this.setState({ convocacao_texto_impressao: this.props.convocacao_texto_impressao });
        }
    }

    updateText = async () => {
        if (this.props.canEdit) {
            try {
                await Sig.request('POST', `/ataReuniao${this.props.hash ? "Externa" : ""}/updateConvocacaoTextoImpressao`, { id: this.props.idAta, convocacao_texto_impressao: this.state.convocacao_texto_impressao, hash: this.props.hash });
            } catch (error) {
                if (!this.timeout)
                    this.timeout = setTimeout(this.updateText, 500);
            }
        }
    }

    changeTextHandler = (value) => {
        if (this.props.convocacao_texto_impressao === value) return;

        if (this.props.canEdit && this.props.updateConvocacaoTextoImpressaoCallback) {
            this.props.updateConvocacaoTextoImpressaoCallback(value);
            if (this.timeout) clearTimeout(this.timeout);
            this.timeout = setTimeout(this.updateText, 500);
        }
    }

    render() {
        let styleContainer = {
            ...EssentialStyle.columnCenter,
            minHeight: 35,
            boxShadow: `0px 1px 10px 0px  rgba(50, 50, 50, 0.2)`,
            backgroundColor: Colors.white,
            width: '100%',
            overflow: "hidden"
        };

        let styleHeader = {
            ...EssentialStyle.rowFlexCenter,
            padding: '0 10px',
            minHeight: 35,
            backgroundColor: Colors.white,
            width: '100%',
            cursor: 'pointer'
        };

        return (
            <div
                style={{
                    ...EssentialStyle.columnCenter,
                    width: '100%',
                    backgroundColor: SessionHelper.getBackgroundColor(),
                    paddingTop: "2px",
                    paddingBottom: 8,
                }}
                ref={this.props.innerRef}
            >
                <div style={{ ...styleContainer, borderBottomLeftRadius: 8, borderBottomRightRadius: 8 }}>
                    <div style={styleHeader} onClick={() => this.setState({ expandConvocacaoBar: !this.state.expandConvocacaoBar })} >
                        {this.state.expandConvocacaoBar ?
                            <FontAwesomeIcon icon={faChevronUp} style={{ marginRight: 8 }} /> :
                            <FontAwesomeIcon icon={faChevronDown} style={{ marginRight: 8 }} />
                        }
                        {
                            this.state.expandConvocacaoBar ? 'Ocultar Registro de Convocação' : 'Registro de Convocação'
                        }
                    </div>

                    <Collapse in={this.state.expandConvocacaoBar} style={{ width: '100%', transition: "all 0.2s ease" }}>
                        <div>
                            <div style={{ ...EssentialStyle.columnCenter, width: '100%', padding: 10, minHeight: 120 }}>
                                <span style={{ fontSize: 18, fontWeight: 500, marginBottom: 10, color: Colors.homePage.darkGrey }}>Texto de convocação para impressão</span>
                                <TextEditor
                                    defaultValue={this.state.convocacao_texto_impressao || ""}
                                    onChange={this.changeTextHandler}
                                    placeholder={'Digite o texto do registro de convocação da reunião...'}
                                    minHeight={50}
                                    disabled={!this.props.canEdit}
                                />
                            </div>
                        </div>
                    </Collapse>
                </div>
            </div>
        );
    }
}

export default forwardRef((props, ref) => {
    const innerRef = useRef();
    useImperativeHandle(ref, () => innerRef.current);
    return <AtaReuniaoConvocacaoTextoImpressao {...props} innerRef={innerRef} />;
});