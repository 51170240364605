import React, { Suspense } from "react";
import RootNavigator from './navigation/RootNavigator';
import ErrorBoundary from "./components/tools/ErrorBoundary";
import CustomSuspense from "./components/tools/CustomSuspense";
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';

import { registerLocale, setDefaultLocale } from "react-datepicker";
import pt from 'date-fns/locale/pt-BR';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-tooltip/dist/react-tooltip.css';

registerLocale('pt', pt);
setDefaultLocale('pt');

export default class App extends React.Component {

    render() {
        return (
            <ErrorBoundary>
                <Suspense fallback={<CustomSuspense />}>
                    <I18nextProvider i18n={i18n}>
                        <RootNavigator />
                    </I18nextProvider>
                </Suspense>
            </ErrorBoundary>
        );
    }
}
