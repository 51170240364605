import React from "react";
import DataHelper from "../../../helper/DataHelper";
import { Form, Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faCalendarDays, faTimes, faCircleChevronLeft, faCircleChevronRight } from "@fortawesome/free-solid-svg-icons";
import AddTag from "../../tools/AddTag";
import Select from 'react-select'
import DefaultButton from "../../tools/DefaultButton";
import moment from "moment";
import Colors from "../../../constants/Colors";
import Sig from "../../../api/Sig";
import DatePicker from "react-datepicker";
import { forwardRef } from 'react';
import DefaultLoader from "../../tools/DefaultLoader";
import EssentialStyle from "../../../style/EssentialStyle";
import LayoutHelper from "../../../helper/LayoutHelper";
import Tabs from "../../tools/Tabs";
import DatePickerHelper from "../../../helper/DatePickerHelper";

export default class AtaReuniaoListSearch extends React.Component {
    state = {
        loading: true,
        typingTimeout: null,
        name: this.props.nome,
        tipoAta: null,
        vinculos: null,
        status: null,
        tags: null,
        colabs: [],
        dataInicio: null,
        dataFim: null,
        colabsList: [],
        tipoAtaList: [],
        isSmallScreen: this.props.isSmallScreen,
        type: this.props.type,
        activeTab: 'cadastro',
    }

    async componentDidMount() {
        await this.loadData();

        if(this.state.type === 'relatorio'){
            this.setState({ activeTab: 'cadastro', dataInicio: moment().subtract(12, 'months').toDate(), dataFim: moment().toDate() }, () => this.filterCallback());
        }

        this.setState({ name: this.props.nome || null });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isSmallScreen !== this.props.isSmallScreen) {
            this.setState({ isSmallScreen: this.props.isSmallScreen });
        }
        if (prevProps.type !== this.props.type) {
            this.setState({ type: this.props.type }, () =>{
                if(this.state.type === 'relatorio'){
                    this.setState({ activeTab: 'cadastro', dataInicio: moment().subtract(12, 'months').toDate(), dataFim: moment().toDate() }, () => this.filterCallback());
                } else {
                    this.setState({ activeTab: 'cadastro', dataInicio: null, dataFim: null, status: null }, () => this.filterCallback());
                }
            });
        }

        if( this.props.nome != prevProps.nome || (this.props.advancedFilter && this.props.advancedFilter != prevProps.advancedFilter && this.props.nome != this.state.name) ){
            this.setState({ name: this.props.nome });
        }
    }

    async loadData() {
        this.setState({ loading: true });

        let req = await Sig.request('GET', 'ataReuniao/getFilterList');

        if (req && req.status === 200) {
            this.setState({ colabsList: req.colaboradores, tipoAtaList: req.tipos });
        }

        this.setState({ loading: false })
    }

    limpar = () => {
        this.setState({
            loading: true,
            activeTab: 'cadastro',
            nome: null,
            tipoAta: null,
            tags: null,
            colabs: [],
            dataInicio: this.state.type == 'relatorio' ? moment().subtract(12, 'months').toDate() : null,
            dataFim: this.state.type == 'relatorio' ? moment().toDate() : null,
        }, () => { 
            this.filterCallback();
            this.setState({ loading: false }) 
        });
    }

    renderDatePickerPeriodo() {
        const CustomPicker = forwardRef(({ value, onClick }, ref) => (
            <Form.Control onClick={onClick} ref={ref} value={value} readOnly />
        ));

        let dataMoment1 = this.state.dataInicio ? moment(this.state.dataInicio).toDate() : null;
        let dataMoment2 = this.state.dataFim ? moment(this.state.dataFim).toDate() : null;

        const tabs = [
            { 
                name: 'inicio', 
                title: 'Data de Início', 
                icon: 
                    <div style={{position: "relative"}}>
                        <FontAwesomeIcon style={{fontSize: 20 }} icon={faCalendarDays} /> 
                        <FontAwesomeIcon style={{position: "absolute",  fontSize: 12, bottom: 0, left: -5  }} icon={faCircleChevronLeft} />
                    </div> 
            },
            { 
                name: 'cadastro', 
                title: 'Data de Cadastro', 
                icon: 
                    <div>
                        <FontAwesomeIcon style={{fontSize: 20 }} icon={faCalendarDays} />
                    </div>
            },
            { 
                name: 'fim', 
                title: 'Data de Fim', 
                icon: 
                    <div style={{position: "relative"}}>
                        <FontAwesomeIcon style={{fontSize: 20 }} icon={faCalendarDays} />  
                        <FontAwesomeIcon style={{position: "absolute",  fontSize: 12, bottom: 0, right: -5  }} icon={faCircleChevronRight} />
                    </div> 
            },
        ];

        return (
            <div className="d-flex flex-row gap-3">
                <DatePicker
                    wrapperClassName="w-100"
                    selected={dataMoment1}
                    startDate={dataMoment1}
                    endDate={dataMoment2}
                    selectsRange
                    onChange={(dates) => {
                        const [start, end] = dates;
                        this.setState({ dataInicio: start, dataFim: end });
                    }}
                    dateFormat={this.state.type === 'relatorio' ? "dd/MM/yyyy" : "MM/yyyy"}
                    showMonthYearPicker={this.state.type === 'relatorio' ? false : true} 
                    customInput={<CustomPicker />}
                    renderCustomHeader={(props) =>  
                        DatePickerHelper.renderCustomDatePickerHeader(props)
                    }
                />
                {this.state.type == 'relatorio' && !this.props.noDateSelector && 
                    <Tabs 
                        isSmallScreen={this.state.isSmallScreen}
                        tabs={tabs}
                        activeTab={this.state.activeTab}
                        onTabChange={this.handleTabChange}
                        tabStyle={{ width: 50 }}
                        style={{ marginTop: 0}}
                        onlyIcon={true}
                    />  
                }
            </div>
        );
    }

    filterCallback = (closeFilter = false) => {
        
        this.props.filterCallback({
            nome: this.state.name,
            tipoData: this.state.activeTab ? this.state.activeTab : null,
            tipoAta: this.state.tipoAta ? this.state.tipoAta.map(item => item.value) : null,
            tags: this.state.tags ? this.state.tags.map(item => item.value) : null,
            colabs: this.state.colabs ? this.state.colabs.map(item => item.value) : null,
            dataInicio: this.state.dataInicio ? DataHelper.getDefaultDbDateFormat(this.state.dataInicio) : null,
            dataFim: this.state.dataFim ? DataHelper.getDefaultDbDateFormat(this.state.dataFim) : null
        }, closeFilter);
    }

    handleTabChange = (tab) => {
        if(this.state.activeTab === tab.name) return;
        var dataInicio = this.state.dataInicio;
        var dataFim = this.state.dataFim;
        this.setState({ activeTab: tab.name, dataInicio, dataFim });
    }

    renderLoading() {
        return (
            <div className="box-advancedSearch">
                <Form className="w-100" style={{ minHeight: 330, ...EssentialStyle.columnCenter }}>
                    <DefaultLoader />
                </Form>
            </div>
        )
    }

    renderNameFilter() {
        return (
            <Form.Group as={Col}>
                <Form.Label className="form-label-xsm">Nome da Ata de Reunião</Form.Label>
                <Form.Control className="form-control-xsm" type="text" placeholder="Nome da Ata de Reunião..." value={this.state.name || ''} onChange={(event) => { this.setState({ name: event.target.value }) }} />
            </Form.Group>
        );
    }

    renderTypeFilter() {
        return (
            <Form.Group as={Col}>
                <Form.Label className="form-label-xsm">Tipo de Ata</Form.Label>
                <Select
                    className="form-control-xsm"
                    options={DataHelper.formatSelectData(this.state.tipoAtaList, 'id', 'nome')}
                    placeholder={'Selecione o Tipo de Ata'}
                    noOptionsMessage={DataHelper.getSelectEmptyMessage}
                    isClearable
                    isSearchable
                    isMulti
                    value={this.state.tipoAta}
                    onChange={(value) => { this.setState({ tipoAta: value }); }}
                    menuPortalTarget={document.body} 
                    styles={{ menuPortal: base => ({ ...base, zIndex: 10 }) }}
                />
            </Form.Group>
        );
    }

    renderTagsFilter() {
        return (
            <Form.Group as={Col}>
                <AddTag value={this.state.tags} fontSize={"xsm"} onChange={(value) => { this.setState({ tags: value }) }} zIndex={10} parceiro={this.state.parceirosChecked} />
            </Form.Group>
        );
    }

    renderPeopleFilter() {
        return (
            <Form.Group as={Col}>
                <Form.Label className="form-label-xsm">Colaboradores</Form.Label>
                <Select
                    className="form-control-xsm"
                    options={DataHelper.formatSelectData(this.state.colabsList, 'email', 'nome')}
                    placeholder={'Selecione os colaboradores'}
                    noOptionsMessage={DataHelper.getSelectEmptyMessage}
                    isClearable
                    isSearchable
                    isMulti
                    value={this.state.colabs}
                    onChange={(value) => { this.setState({ colabs: value }); }}
                    menuPortalTarget={document.body} 
                    styles={{ menuPortal: base => ({ ...base, zIndex: 10 }) }}
                />
            </Form.Group>
        );
    }

    renderPeriodoFilter() {
        return (
            <Form.Group as={Col}>
                <Form.Label className="form-label-xsm">Período</Form.Label>
                {this.renderDatePickerPeriodo()}
            </Form.Group>
        );
    }

    renderButtons() {
        return (
            <div className="mb-0 d-flex flex-row-reverse">
                <Form.Group>
                    <DefaultButton className="me-1" color={Colors.error} leftIcon={<FontAwesomeIcon icon={faTimes} />} title={'Limpar'} loading={this.state.loading} onClick={this.limpar} />
                    <DefaultButton color={Colors.success} leftIcon={<FontAwesomeIcon icon={faSearch} />} title={'Filtrar'} loading={this.state.loading} onClick={() => this.filterCallback(true)} />
                </Form.Group>
            </div>
        );
    }

    renderDesktop() {
        return (
            <div className="box-advancedSearch">
                <Form className="w-100">
                    <Row className="mb-3">
                        {this.renderNameFilter()}
                        {this.renderTypeFilter()}
                    </Row>

                    <Row className="mb-1">
                        {this.renderTagsFilter()}
                    </Row>

                    <Row className="mb-3">
                        {this.renderPeopleFilter()}
                        {this.renderPeriodoFilter()}
                    </Row>


                    {this.renderButtons()}
                </Form>
            </div>
        );
    }

    renderMobile() {
        return (
            <div className="box-advancedSearch" style={{ maxHeight: window.innerHeight - 150, overflowY: 'auto' }}>
                <Form className="w-100">
                    <Row className="mb-3">{this.renderNameFilter()}</Row>
                    <Row className="mb-3">{this.renderTypeFilter()}</Row>
                    <Row className="mb-1">{this.renderTagsFilter()}</Row>
                    <Row className="mb-3">{this.renderPeopleFilter()}</Row>
                    <Row className="mb-3">{this.renderPeriodoFilter()}</Row>
                    {this.renderButtons()}
                </Form>
            </div>
        );
    }

    render() {
        return this.state.loading ? this.renderLoading() : (this.state.isSmallScreen ? this.renderMobile() : this.renderDesktop());
    }
}