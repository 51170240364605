import React from "react";
import { withTranslation } from "react-i18next";

import './PlanoAcaoCardRelatorio.css';

import EssentialStyle from "../../../../style/EssentialStyle";
import Tag from "../../../tools/Tag";
import UserAvatar from "../../../tools/UserAvatar";
import Colors from "../../../../constants/Colors";
import CustomTooltip from "../../../tools/CustomTooltip";
import ProgressBarCustom from "../../../tools/ProgressBar/ProgressBarCustom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEquals, faChevronUp, faChevronDown, faCalendar, faExclamationTriangle, faEye } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment';
import { Collapse } from "react-bootstrap";
import PlanoAcaoEtapaCard from "../PlanoAcaoEtapaCard/PlanoAcaoEtapaCard";
import DefaultButton from "../../../tools/DefaultButton";
import TooManyTags from "../../../tools/TooManyTags";
import Sig from "../../../../api/Sig";
import PlanoAcaoHelper from "../../../../helper/planoAcao/PlanoAcaoHelper";
import DataHelper from "../../../../helper/DataHelper";
import SessionHelper from "../../../../helper/SessionHelper";
import IdiomaHelper from "../../../../helper/IdiomaHelper";

class PlanoAcaoCardRelatorio extends React.Component {
    state = {
        loading: true,
        progressBarHovered: false,
        displayEtapas: this.props.expanded || false,
        isSmallScreen: this.props.isSmallScreen,
    }
    
    async componentDidMount() {
        this.setState({ loading: false });
        await this.props.i18n.loadNamespaces(['client_src_components_modules_planoAcao_PlanoAcaoRelatorio_PlanoAcaoCardRelatorio']);
        const warningMessage = this.hasWarning();
        this.setState({ warningMessage });
    }

    componentDidUpdate(prevProps) {

        if (prevProps.isSmallScreen !== this.props.isSmallScreen) {
            this.setState({ isSmallScreen: this.props.isSmallScreen });
        }
    }

    renderTendencia() {
        if (this.props.plano?.tendencia) {
            let icon = null;

            if (this.props.plano?.tendencia?.value == -1) icon = <FontAwesomeIcon icon={faChevronDown} style={{ color: this.props.plano?.tendencia?.color, fontSize: 18, marginRight: 4 }} />
            if (this.props.plano?.tendencia?.value == 1) icon = <FontAwesomeIcon icon={faChevronUp} style={{ color: this.props.plano?.tendencia?.color, fontSize: 18, marginRight: 4 }} />
            if (this.props.plano?.tendencia?.value == null) icon = <FontAwesomeIcon icon={faEquals} style={{ color: this.props.plano?.tendencia?.color, fontSize: 18, marginRight: 4 }} />

            return (
                <CustomTooltip tooltip={this.props.plano?.tendencia?.msg} style={{ marginLeft: 8 }}>
                    {icon}
                </CustomTooltip>
            );
        }
    }

    hasWarning() {
        let response = [];

        let hasEtapas = this.props.plano.etapas && this.props.plano.etapas.length > 0;
        if (!hasEtapas) {
            response.push(this.props.t('client_src_components_modules_planoAcao_PlanoAcaoRelatorio_PlanoAcaoCardRelatorio:planoAcaoSemEtapas'));
        }

        let hasWarningMetodologia = this.props.plano.hasWarningProgresso;
        if (hasWarningMetodologia) {
            response.push(this.props.t('client_src_components_modules_planoAcao_PlanoAcaoRelatorio_PlanoAcaoCardRelatorio:inconsistenciaMetodologia'));
        }

        return response.join(" | ");
    }


    renderTitle() {

        return (
            <div style={{ ...EssentialStyle.rowFlexStart, paddingLeft: 3, paddingBottom: 0, flex: 1, width: "calc(100% - 45px)" }}>
                <Tag tag={{ id: `${this.props.plano.codigo}-pa-code`, nome: this.props.plano.codigo }} />
                {this.state.warningMessage != false &&
                    <CustomTooltip tooltip={this.state.warningMessage} placement="right">
                        <FontAwesomeIcon icon={faExclamationTriangle} style={{ marginLeft: 10 }} className={'icon warning'} />
                    </CustomTooltip>
                }
                {this.renderTendencia()}
                <span style={{
                    marginLeft: 6,
                    fontSize: 18,
                    fontWeight: 500,
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    maxWidth: "auto",
                    flex: 1,
                }}>
                    {DataHelper.removeHtmlTags(this.props.plano.nome)}
                </span>
            </div>
        );
    }

    renderCompany() {
        if (this.props.plano?.id_empresa == SessionHelper.getData().id_empresa) return null;

        return (
            <div style={{ ...EssentialStyle.rowFlexStart, marginLeft: 2 }}>
                <div style={{ backgroundColor: Colors.tag, color: Colors.dark, fontSize: 12, padding: 5, borderRadius: 5, fontWeight: 600 }}>{this.props.plano?.nome_empresa}</div>
                {this.props.plano?.tags?.length ? <span style={{ marginLeft: 6, marginRight: 4, fontWeight: 200 }}>|</span> : null}
            </div>
        );
    }

    renderLeft() {
        return (
            <div style={{ ...EssentialStyle.rowFlexStart, width: this.props.isSmallScreen ? "100%" : "unset", overflow: "hidden", flex: 1 }}>
                <UserAvatar user={this.props.plano.responsavel} />
                <div style={{ ...EssentialStyle.columnStart, flex: 1, gap: 5, justifyContent: "center" }}>
                    {this.renderTitle()}
                    <div style={{
                        ...EssentialStyle.rowFlexStart
                    }}>
                        {this.renderCompany()}
                        {this.renderTags()}
                    </div>
                </div>
            </div>
        );
    }

    renderStatus() {
        let propStatus = this.props.plano?.status || 'Não Iniciado';

        return (
            <div style={{ ...EssentialStyle.rowFlexStart, marginLeft: 2 }}>
                <div style={{ backgroundColor: Colors.planoAcao.status.backgroundColor[propStatus], color: Colors.planoAcao.status.color[propStatus], fontSize: 12, padding: 5, borderRadius: 5, marginRight: 2, fontWeight: 600 }}>
                    {this.props.t(`client_src_components_modules_planoAcao_PlanoAcaoRelatorio_PlanoAcaoCardRelatorio:status.${IdiomaHelper.getTextTokenName(this.props.plano?.status)}`)}
                    {(this.props.plano?.status == 'Concluído' || this.props.plano?.status == 'Cancelado') && this.props.plano?.data_conclusao ? ` - ${moment(this.props.plano?.data_conclusao).format('DD/MM/YY')}` : ''}
                </div>
            </div>
        );
    }

    renderPeriodo() {
        return (
            <div style={{ ...EssentialStyle.rowFlexStart, marginLeft: 2, backgroundColor: Colors.tag, color: Colors.dark, fontSize: 12, padding: 5, borderRadius: 5, fontWeight: 600 }}>
                <FontAwesomeIcon icon={faCalendar} style={{ color: Colors.dark, fontSize: 14, marginRight: 4 }} />
                <span>{this.props.plano?.data_inicio != 0 ? moment(this.props.plano?.data_inicio).format('DD/MM/YY') : "- -"}{this.props.plano?.data_fim ? `~ ${moment(this.props.plano?.data_fim).format('DD/MM/YY')}` : ``}</span>
            </div>
        );
    }

    renderProggressBar() {
        return (
            <ProgressBarCustom
                tipo={'planoAcao'}
                id={this.props.plano.codigo}
                progresso={this.props.plano.progresso}
                style={{ width: this.props.isSmallScreen ? 100 : 250 }} />
        );
    }

    renderUltimaAlteracao() {
        if (!this.props.plano?.ultima_alteracao || !this.props.plano?.ultima_alteracao_colaborador) return;
        if (!moment(this.props.plano.ultima_alteracao).isValid()) return;

        const data = moment(this.props.plano.ultima_alteracao);
        const diffDays = moment().diff(data, 'days');

        const suffix = (diffDays < 6 && diffDays > -6) ? "" : this.props.t('client_src_components_modules_planoAcao_PlanoAcaoRelatorio_PlanoAcaoCardRelatorio:em') + " ";

        let dataString = moment(data).calendar();
        dataString = dataString.charAt(0).toLowerCase() + dataString.slice(1);

        return (
            <span
                style={{
                    fontSize: 12,
                    color: Colors.homePage.grey,
                    marginLeft: 5,
                    fontWeight: 200
                }}
            >
                {this.props.t('client_src_components_modules_planoAcao_PlanoAcaoRelatorio_PlanoAcaoCardRelatorio:atualizadoPor', {"suffix": suffix, "dataString": dataString, "colaborador": this.props.plano.ultima_alteracao_colaborador})} 
            </span>
        );
    }

    renderRight() {
        let style = { height: 50, gap: 5 };

        if (this.props.isSmallScreen) {
            style = { ...EssentialStyle.rowSpaceBetween, alignItems: 'center', marginTop: 20, ...style, width: '100%' };
        } else {
            style = { ...EssentialStyle.rowSpaceBetween, alignItems: 'center', ...style };
        }

        return (
            <div style={{ ...EssentialStyle.columnCenter }}>
                <div style={style}>
                    {this.renderStatus()}
                    {this.renderPeriodo()}
                    {this.renderProggressBar()}
                    <DefaultButton leftIcon={<FontAwesomeIcon icon={faEye} />} style={{ height: 25, width: 25, padding: 0 }} onClick={(e) => { e.stopPropagation(); this.props.onClick(this.props.plano?.codigo); }} />
                </div>
                <div style={{ ...EssentialStyle.rowFlexEnd, width: "100%" }}>
                    {this.renderUltimaAlteracao()}
                </div>
            </div>

        );
    }

    getPeople = () => {
        let people = [];

        if (!this.props.plano) return people;

        if (this.props.plano?.responsavel) people.push(this.props.plano.responsavel);
        if (this.props.plano?.superior) people.push(this.props.plano.superior);
        if (this.props.plano?.participantes) people = people.concat(this.props.plano.participantes);

        people.sort((a, b) => {
            return a.nome.localeCompare(b.nome);
        });

        return people;
    }

    renderTags() {
        if (!this.props.plano?.tags || this.props.plano?.tags.length == 0) return null;
        let ignoreCalc = this.props.plano?.id_empresa == SessionHelper.getData().id_empresa;
        let companyNameLength = (this.props.plano?.nome_empresa?.length || 0) * 11;

        return (
            <div style={{ minHeight: 28 }}>
                <TooManyTags max={4} maxWidth={`calc(${this.props.isSmallScreen ? 85 : 50}vw - ${ignoreCalc ? 0 : companyNameLength}px)`} tags={this.props.plano?.tags || []} />
            </div>
        );
    }

    renderEtapas() {
        if (!this.state.displayEtapas) return null;
        if (this.props.plano?.etapas?.length == 0) {
            return (
                <div style={{
                    ...EssentialStyle.columnCenter,
                    width: "100%",
                    padding: 10,
                    fontSize: 18,
                    fontWeight: 500
                }}
                >
                    {this.props.t('client_src_components_modules_planoAcao_PlanoAcaoRelatorio_PlanoAcaoCardRelatorio:nenhumaEtapaCadastrada')}
                </div>
            );
        }
        
        let filteredEtapas = PlanoAcaoHelper.filterSelectedEtapas(this.props.plano?.etapas || [], this.props.filter);

        return filteredEtapas.map((etapa, index) => {
            return (
                <PlanoAcaoEtapaCard 
                    key={`etapa-plano-acao-card-${this.props.plano?.codigo}-${index}`} 
                    etapa={etapa} 
                    resumed={this.props.isSmallScreen} 
                    participantes={this.getPeople()} 
                    onClick={(idEtapa) => window.parent.postMessage({ type: '_blank', url: `/planoAcao/detalhar/${this.props.plano.codigo}?etapa=${idEtapa}` }, '*')} 
                    noEdit 
                />
            );
        });
    }

    renderCard() {
        let cardStyle = {
            ...EssentialStyle.card,
            marginTop: this.props.noMargin ? 0 : 10,
            padding: 10,
            cursor: 'pointer',
            width: "100%",
            minHeight: 90,
            ...EssentialStyle.columnCenter,
        };

        if (this.props.isSmallScreen) {
            var planoStyle = {
                ...EssentialStyle.columnStart,
                width: "100%",
            };
        } else {
            var planoStyle = {
                ...EssentialStyle.rowSpaceBetween,
                width: "100%",
                minHeight: 70,
            }
        }

        return (
            <div
                style={cardStyle}
                className="plano-acao-card"
            >
                <div style={planoStyle} onClick={() => this.setState({ displayEtapas: this.props.displayEtapas ? !this.state.displayEtapas : false })}>
                    {this.renderLeft()}
                    {this.renderRight()}
                </div>
                <Collapse in={this.state.displayEtapas && this.props.displayEtapas}>
                    <div style={{ width: "100%" }}>
                        <div style={{ ...EssentialStyle.columnSpaceEvenly, width: "100%", marginTop: 10, borderBottom: this.props.plano?.etapas?.length > 0 ? "1px solid " + Colors.homePage.extraLightGrey : "unset" }}>
                            {this.renderEtapas()}
                        </div>
                    </div>
                </Collapse>
            </div>
        );
    }

    render() {
        return this.renderCard();
    }
}

export default withTranslation()(PlanoAcaoCardRelatorio);