import React from "react";

import Colors from "../../../../constants/Colors";
import DefaultButton from "../../../../components/tools/DefaultButton";

import DiagnosticoHelper from "../../../../helper/diagnostico/DiagnosticoHelper";
import IdiomaHelper from "../../../../helper/IdiomaHelper";
import DataHelper from "../../../../helper/DataHelper";
import Select from 'react-select';

import './Avaliacao.css';
import { withTranslation } from "react-i18next";
import AvaliacaoCard from "./AvaliacaoCard";
import ModalAvaliadores from "../GradesAvaliacao/ModalAvaliadores/ModalAvaliadores";
import DefaultLoader from "../../../tools/DefaultLoader";
import Constants from "../../../../constants/Api";
import EssentialStyle from "../../../../style/EssentialStyle";

const gateway = Constants.getSigEndPoint();

class Avaliacoes extends React.Component {

    state = {
        lang: IdiomaHelper.getIdioma(),
        reloadPeriodo: 0,

        loadingPeriodosAvaliacao: true,
        loadingAvaliacoes: false,
        loading: false,

        periodosAvaliacao: [],
        idPeriodoAvaliacao: null,

        showUploadModal: false,

        avaliacoes: [],

        editingAvaliacaoAvaliadores: null,
    }

    async componentDidMount() {
        this.setState({ loading: true });
        await this.props.i18n.loadNamespaces(['client_src_components_modules_diagnostico_Avaliacao_Avaliacoes']);
        await this.props.i18n.loadNamespaces(['client_src_helper_diagnostico_DiagnosticoHelper']);

        await this.loadPeriodos();
    }

    loadPeriodos = async () => {
        
        this.setState({ loadingPeriodosAvaliacao: true });

        let { periodos, canEdit, canView } = await DiagnosticoHelper.getPeriodosAvaliacao(this.props.idProcesso);
        
        let idPeriodoAvaliacao = null;

        let localId = DiagnosticoHelper.getPeriodoAvaliacaoLocal(this.props.idProcesso);

        if (localId && periodos.find(periodo => periodo.id == localId)) {
            idPeriodoAvaliacao = localId;
        } else {
            idPeriodoAvaliacao = periodos.length > 0 ? periodos[0].id : null;
        }

        this.setState({ periodosAvaliacao: periodos, idPeriodoAvaliacao, canEdit, canView, loadingPeriodosAvaliacao: false }, 
            async () => { if (this.state.idPeriodoAvaliacao) {
                await this.loadAvaliacoes();
                await this.loadAvaliadoresPossiveis();
            } else {
                this.setState({ loading: false, loadAvaliacoes: false });
            }
        });
    }

    loadAvaliacoes = async (load = true) => {
        this.setState({ loadingAvaliacoes: load ? true : false });

        let avaliacoes = await DiagnosticoHelper.getAvaliacoesEmpresaAvaliada(this.state.idPeriodoAvaliacao, 1);
        if(avaliacoes) {
            this.setState({
                avaliacoes: avaliacoes || [],
                loadingAvaliacoes: false,
            });
        }
    }
    
    loadAvaliadoresPossiveis = async () => {
        let avaliadores = await DiagnosticoHelper.getAvaliadoresPossiveisEmpresaFilha(this.state.idPeriodoAvaliacao);

        this.setState({ avaliadores: DataHelper.formatSelectData(avaliadores, 'id', 'nome'), loading: false });
    }

    reloadPeriodo = async () => {
        this.setState({ reloadPeriodo: this.state.reloadPeriodo + 1 });
    }

    changePeriodo = (idPeriodoAvaliacao) => {
        this.setState({ idPeriodoAvaliacao },
            () => { this.loadAvaliacoes() }
        );
    }

    changeEditAvaliadores = (avaliacao) => {
        if (!avaliacao) {   
            this.setState({ editingAvaliacaoAvaliadores: null });
        } else {
            this.setState({ editingAvaliacaoAvaliadores: {...avaliacao, avaliadores: DataHelper.formatSelectData(avaliacao.avaliadores, 'id', 'nome')} });
        }
    }

    renderHeader() {

        return (
            <div className="Avaliacoes-header" style={{boxShadow: Colors.boxShadow}}>
               <div className="header-left">
                    <Select
                        styles={{ control: (base) => ({ ...base, border: 'none', minWidth: '40vw', marginRight: 2 }) }}
                        options={DataHelper.formatSelectData(this.state.periodosAvaliacao, 'id', 'nome')}
                        value={DataHelper.formatSelectData(this.state.periodosAvaliacao, 'id', 'nome').find(option => option.value === this.state.idPeriodoAvaliacao)}
                        onChange={(valueSelected) => {  this.changePeriodo(valueSelected.value); DiagnosticoHelper.setPeriodoAvaliacaoLocal(valueSelected.value, this.props.idProcesso) }}
                        placeholder={this.props.t('client_src_components_modules_diagnostico_Avaliacao_Avaliacoes:selectPlaceholder')}
                        noOptionsMessage={DataHelper.getSelectEmptyMessage}
                        isSearchable
                        menuPortalTarget={document.body}
                    />
               </div>

                <div className="header-right">
                    <DefaultButton
                        style={{ width: 38, height: 38 }}
                        fontSize={this.props.isSmallScreen ? 14 : "unset"}
                        color={Colors.secondaryButton}
                        textColor={Colors.dark}
                        link={'https://advis.freshdesk.com/support/solutions/folders/63000238312'}
                    />
                </div>
            </div>
        )
    }

    saveAvaliacao = async () => {
    
        if (this.state.loading) {
            return;
        }

        this.setState({ loading: true });

        let { avaliacao } = await DiagnosticoHelper.saveAvaliacao(this.state.editingAvaliacaoAvaliadores, 2);

        var avaliacoes = [...this.state.avaliacoes].map(a => {
            if (a.id == avaliacao.id) {
                return avaliacao;
            }
            return a;
        });

        this.setState({
            loading: false,
            editingAvaliacaoAvaliadores: null,
            avaliacoes,
        })
    }

    renderEditingAvaliadores() {
        return(
            <ModalAvaliadores 
                avaliadores={this.state.avaliadores}
                openEditAvaliadores={this.state.editingAvaliacaoAvaliadores} 
                onClose={() => this.setState({ editingAvaliacaoAvaliadores: false })}
                onSave={() => this.saveAvaliacao()} 
                changeAvaliacao={(avaliacao) => {this.setState({ editingAvaliacaoAvaliadores: avaliacao })}}
                loading={this.state.loading}
                canEdit={this.state.editingAvaliacaoAvaliadores?.tipo == 2}
                lang={this.state.lang}
                noGroups={true}
            />
        )
    }

    renderAvaliacoes() {
        if(this.state.loadingAvaliacoes || this.state.loading ) {
            return (
                <div className="Avaliacoes-container" style={{ justifyContent: 'center', alignItems: 'center' }}>
                    <DefaultLoader />
                </div>
            )
        }

        if(this.state.avaliacoes.length == 0) {
            return (
                <div style={{
                    ...EssentialStyle.columnCenter,
                    width: '100%', textAlign: 'center',
                    height: 'calc(100vh - 59px)',
                    color: Colors.homePage.grey, fontSize: "1.2em", fontWeight: 500
                }}>
                    <img
                        src={`${gateway}/img/undraw/undraw_empty_street.svg`}
                        alt={this.props.t('client_src_components_modules_diagnostico_Avaliacao_Avaliacoes:alt_noAvaliacoes')}
                        style={{
                            height: '15rem',
                            maxHeight: 250,
                            maxWidth: '100%',
                            marginBottom: '1rem',
                        }}
                    />
                    {this.props.t('client_src_components_modules_diagnostico_Avaliacao_Avaliacoes:noAvaliacoes')}
                </div>
            )
        }

        return (
            <div className="Avaliacoes-container">
                {this.state.avaliacoes?.map(avaliacao => (
                    <AvaliacaoCard 
                        key={avaliacao.id}
                        avaliacao={avaliacao} 
                        changeEditAvaliadores={this.changeEditAvaliadores} 
                        editingAvaliacaoAvaliadores={this.state.editingAvaliacaoAvaliadores} 
                        canEdit={avaliacao.tipo == 2}
                    />
                ))}
            </div>
        )
    }

    render() {
        return (
            <div className="Avaliacoes">
                {this.renderHeader()}
                {this.state.idPeriodoAvaliacao ? this.renderAvaliacoes() : DiagnosticoHelper.renderPeriodoNotSelected(this.props.t, this.state.loadingPeriodosAvaliacao)}
                {this.state.editingAvaliacaoAvaliadores ? this.renderEditingAvaliadores() : null}
            </div>
        )
    }
}

export default withTranslation()(Avaliacoes);