import React from "react";
import { withTranslation } from "react-i18next";

import ReactDOM from "react-dom";
import Sig from "../../../../api/Sig";
import Colors from "../../../../constants/Colors";
import LayoutHelper from "../../../../helper/LayoutHelper";
import EssentialStyle from "../../../../style/EssentialStyle";
import DefaultButton from "../../../tools/DefaultButton";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink, faSlidersH, faSearch, faCheck, faTimes, faPlusSquare, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import Tabs from "../../../tools/Tabs";
import Modal from "../../../tools/Modal";
import DefaultLoader from "../../../tools/DefaultLoader";
import { Form, InputGroup,  } from "react-bootstrap";
import { toast } from 'react-toastify';

class ModalVinculaMarco extends React.Component {

    state = {
        isSmallScreen: LayoutHelper.isSmallScreen(),
        loadingModalAddPlano: true,
        planosLimited: [],
        planosLimitedCopy: [],
        activeTab: 'criar',
        tabs: [
            { name: 'criar', title: "criar" },
            { name: 'vincular', title: "vincular" }
        ],
        textFilter: '',
        addingVinculo: null,
        hasWarning: false,
        page: 1,
    };

    async componentDidMount() {
        this.setState({ loadingModalAddPlano: true });
        await this.props.i18n.loadNamespaces(['client_src_components_modules_pe_canvas_ModalVinculaMarco']);
        this.setState({
            tabs: [
                { name: 'criar', title: this.props.i18n.t('client_src_components_modules_pe_canvas_ModalVinculaMarco:criar') },
                { name: 'vincular', title: this.props.i18n.t('client_src_components_modules_pe_canvas_ModalVinculaMarco:vincular') },
            ],
         });
        await this.loadPlanosVinculo();
    }

    loadPlanosVinculo = async () => {
        let response = await Sig.request('GET', 'planoAcao/getPlanosSemMarco');
        this.setState({ planosLimited: response.planos, planosLimitedCopy: response.planos, loadingModalAddPlano: false });
    }

    handleTabChange = (tab) => {
        if(this.state.activeTab === tab.name) return;
        this.setState({ activeTab: tab.name });
    }

    handleSearch = (text) => {
        this.setState({ textFilter: text });

        let planos = this.state.planosLimitedCopy.filter((plano) => {
            const searchText = text.toUpperCase();
            return plano.nome.toUpperCase().indexOf(searchText) > -1 || plano.codigo.toUpperCase().indexOf(searchText) > -1;
        });

        this.setState({ planosLimited: planos });
    }

    vinculaPlano = async (id, alterar) => {

        let idMarco = this.props.idMarco;

        if(idMarco) {

            let response = await Sig.request('POST', 'planoAcao/vinculaMarcoPlano', { id_marco: idMarco, id_plano: id, alterar });

            if(response.vinculo) {
                toast.success('Plano de ação vinculado com sucesso.');
                this.props.closeModal(true);
            }
        } else {
            toast.error('Erro ao vincular plano de ação.');
            this.props.closeModal(false);
        }
    }

    renderContentModal() {
        return (
            <>
                {this.renderTitle()}
                {this.state.loadingModalAddPlano ? this.renderLoading() : this.renderBody()}
                {this.renderFooter()}
            </>
        )
    }

    renderTitle() {
        return (
            <div style={{fontSize: 22, fontWeight: 600, color: Colors.homePage.grey, marginBottom: 10}}>
                <FontAwesomeIcon icon={faLink} style={{ marginRight: 10 }} />
                {this.props.i18n.t('client_src_components_modules_pe_canvas_ModalVinculaMarco:criar_acoes')}
            </div>
        )
    }

    renderBody() {
        return (
            <>
                {/* Tabs e buscador */}
                <div style={{...EssentialStyle.rowSpaceBetween, gap: 10, width: '100%'}}>
                    <Tabs 
                        tabs={this.state.tabs} 
                        activeTab={this.state.activeTab} 
                        isSmallScreen={this.state.isSmallScreen} 
                        onTabChange={this.handleTabChange}
                        tabStyle={{width: this.state.isSmallScreen ? '32vw' : 90, maxWidth: 100}}
                    />

                    { this.state.activeTab === 'vincular' && this.renderInputSearch() }
                </div>

                {/* Conteúdo */}
                {
                    <div style={{marginTop: 10, width: '100%'}}>
                        {
                            this.state.activeTab === 'criar' 
                                ?
                            this.renderCriarPlano()
                                :
                            (this.state.addingVinculo != null ? this.renderConfirmarVinculo() : this.renderListPlanos())
                        }
                    </div>
                }
            </>
        )
    }

    renderCriarPlano() {
        return (
            <div style={{...EssentialStyle.rowFlexCenter, width: '100%', height: '12rem'}}>
                <DefaultButton
                    title={this.props.i18n.t('client_src_components_modules_pe_canvas_ModalVinculaMarco:criar_acoes_button')}
                    leftIcon={<FontAwesomeIcon icon={faPlusSquare} />}
                    color={Colors.success}
                    textColor={Colors.white}
                    style={{padding: '5px 20px'}}
                    onClick={() => window.parent.postMessage({ type: 'navigate', url: `/planoAcao?tipo=novo&modulo=7&marco=${this.props.idMarco}` }, '*')}
                />
            </div>
        )
    }

    selectPlanoVinculo(plano) {

        this.setState({ addingVinculo: plano, page: 1, hasWarning: this.props.tipoProgresso !== plano.tipo_progresso });
    }

    renderListPlanos() {
        return (
            <div style={{height: '12rem', overflowY: 'auto'}}>
                {
                    this.state.planosLimited.length ? 
                    this.state.planosLimited.map((plano, index) => {
                        return (
                            <div
                                key={plano.id}
                                style={{
                                    ...EssentialStyle.rowSpaceBetween,
                                    width: '100%',
                                    borderBottom: `1px solid ${Colors.homePage.grey}`,
                                    padding: 5,
                                    gap: 5,
                                }}
                            >
                                <div style={{...EssentialStyle.rowFlexStart, gap: 5}}>
                                    <div style={{backgroundColor: Colors.homePage.grey, color: Colors.white, padding: '2px 5px', borderRadius: 5}}>{plano.codigo}</div>
                                    {plano.nome}
                                </div>

                                <DefaultButton
                                    leftIcon={<FontAwesomeIcon icon={faLink} />}
                                    color={Colors.success}
                                    textColor={Colors.white}
                                    style={{padding: '2px 15px'}}
                                    onClick={() => this.selectPlanoVinculo(plano)}
                                />
                            </div>
                        )
                    })
                    :
                    this.renderEmpty()
                }
            </div>
        )
    }

    renderConfirmarVinculo() {

        const nextPage = () => {
            if (!this.state.hasWarning) {
                this.vinculaPlano(this.state.addingVinculo.id, false);
            } else {
                this.setState({page: this.state.page + 1})
            }
        }

        const PageOne = () => {
            return (
                <div style={{...EssentialStyle.rowFlexCenter, width: '100%', height: '12rem', gap: 10}}>
                    <div style={{...EssentialStyle.columnCenter, width: '85%', gap: 30}}>
                        <span>{this.props.i18n.t('client_src_components_modules_pe_canvas_ModalVinculaMarco:confirmar_vinculo', { nome: this.state.addingVinculo.nome })}</span>

                        <div style={{...EssentialStyle.rowFlexEnd, width: '100%', gap: 10}}>
                            <DefaultButton
                                leftIcon={<FontAwesomeIcon icon={faCheck} />}
                                color={Colors.success}
                                textColor={Colors.white}
                                title={this.props.i18n.t('client_src_components_modules_pe_canvas_ModalVinculaMarco:confirmar_vinculo_button')}
                                onClick={() => nextPage()}
                            />

                            <DefaultButton
                                leftIcon={<FontAwesomeIcon icon={faTimes} />}
                                color={Colors.error}
                                textColor={Colors.white}
                                title={this.props.i18n.t('client_src_components_modules_pe_canvas_ModalVinculaMarco:cancelar')}
                                onClick={() => this.setState({addingVinculo: null})}
                            />
                        </div>
                    </div>
                </div>
            )
        }

        const PageTwo = () => {
            return (
                <div style={{...EssentialStyle.rowFlexCenter, width: '100%', height: '12rem', gap: 10}}>
                    <div style={{...EssentialStyle.columnCenter, width: '85%', gap: 30}}>
                        <span>
                            {this.props.i18n.t('client_src_components_modules_pe_canvas_ModalVinculaMarco:nao_mesma_metodologia_1') + " "}
                            <strong>{this.props.i18n.t('client_src_components_modules_pe_canvas_ModalVinculaMarco:nao_mesma_metodologia_2')}</strong>
                            {", " + this.props.i18n.t('client_src_components_modules_pe_canvas_ModalVinculaMarco:nao_mesma_metodologia_3')}
                        </span>
                    
                        <div style={{...EssentialStyle.rowSpaceBetween, width: '100%', gap: 10}}>
                            <div style={{...EssentialStyle.rowFlexStart, gap: 10}}>
                                <DefaultButton
                                    leftIcon={<FontAwesomeIcon icon={faChevronLeft} />}
                                    tooltip={'Voltar'}
                                    color={Colors.disabled}
                                    textColor={Colors.white}
                                    onClick={() => this.setState({page: 1, addingVinculo: null})}
                                />
                            </div>
                            <div style={{...EssentialStyle.rowFlexEnd, gap: 10}}>
                                <DefaultButton
                                    leftIcon={<FontAwesomeIcon icon={faCheck} />}
                                    title={this.props.i18n.t('client_src_components_modules_pe_canvas_ModalVinculaMarco:alterar')}
                                    color={Colors.success}
                                    textColor={Colors.white}
                                    onClick={() => this.vinculaPlano(this.state.addingVinculo.id, true)}
                                />

                                <DefaultButton
                                    leftIcon={<FontAwesomeIcon icon={faTimes} />}
                                    color={Colors.dark}
                                    textColor={Colors.white}
                                    title={this.props.i18n.t('client_src_components_modules_pe_canvas_ModalVinculaMarco:nao_alterar')}
                                    onClick={() => this.vinculaPlano(this.state.addingVinculo.id, false)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )
        }


        switch (this.state.page) {
            case 1:
                return PageOne();
            case 2:
                return PageTwo();
            default:
                return null;
        }

    }

    renderFooter() {
        return (

            <div style={{...EssentialStyle.rowFlexEnd, width: '100%', marginTop: 10}}>
                <DefaultButton 
                    title={this.props.i18n.t('client_src_components_modules_pe_canvas_ModalVinculaMarco:fechar')}
                    color={Colors.dark}
                    textColor={Colors.white}
                    onClick={() => this.props.closeModal(false)}
                />
            </div>
        )
    }

    renderInputSearch() {
        
        let searchIconStyle = { borderRight: 'none', color: Colors.disabled, width: 40 };

        return (
            <InputGroup>
                <InputGroup.Text style={searchIconStyle}>
                    <FontAwesomeIcon
                        icon={faSearch}
                    />
                </InputGroup.Text>
                <Form.Control type="text"
                    style={{ borderLeft: 'none' }}
                    placeholder={this.props.i18n.t('client_src_components_modules_pe_canvas_ModalVinculaMarco:buscar')}
                    onChange={(event) => { this.handleSearch(event.target.value) }}
                    value={this.state.textFilter}
                />
            </InputGroup>
        )
    }

    renderEmpty() {
        return (
            <div style={{...EssentialStyle.columnCenter, width: '100%', height: '12rem'}}>
                <div style={{fontSize: 18, color: Colors.homePage.grey}}>
                    {this.props.i18n.t('client_src_components_modules_pe_canvas_ModalVinculaMarco:nenhum_plano')}
                </div>
            </div>
        )
    }

    renderLoading() {
        return (
            <div style={{ ...EssentialStyle.columnCenter, width: '100%', height: '12rem' }}>
                <DefaultLoader />
            </div>
        )
    }

    render() {
        return (
            <Modal style={{width: this.state.isSmallScreen ? '95vw' : '80vw', transition: 'height 0.5s ease'}}>
                {this.renderContentModal()}
            </Modal>
        )
    }
}

export default withTranslation()(ModalVinculaMarco);