import React, { Component } from 'react';
import './ConceitoPicker.css';

import { Form } from 'react-bootstrap';
import Colors from '../../../constants/Colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faMinusCircle, faPlusCircle, faTrash, faPen } from '@fortawesome/free-solid-svg-icons';


class ConceitoPicker extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hover: null,
        }
    }

    renderConceito() {

        const { value, escala } = this.props;

        return (
            <Form.Group className={"conceitos-picker"}>
                {escala.conceitos.map((conceito, index) => {
                    return (
                        <div
                            className={"conceito " + (value == conceito.valor || Math.round(value) == Math.round(conceito.valor) ? 'selected' : '') + (this.props.disabled ? ' disabled-conceito' : '')}
                            type="radio"
                            key={index}
                            label={conceito.descricao}
                            name="conceito"
                            style={{boxShadow: Colors.boxShadow }}
                            onClick={() => {
                                if(this.props.disabled) return;
                                this.props.onChange(conceito.valor)}
                            }
                        >
                            <div className="conceito-left">
                                <div className="conceito-cor" style={{ backgroundColor: conceito.cor }}/>
                                <div className="conceito-title">{conceito.descricao}</div>
                            </div>
                            <div className="conceito-right">
                                <div className="conceito-valor">{conceito.valor}</div>
                            </div>
                        </div>
                    );
                })
                }
            </Form.Group>
        );
    }

    renderStarConceito() {
        let conceitosSorted = [...this.props.escala?.conceitos].sort((a, b) => a.valor - b.valor);

        return (
            <div className="StarPicker" style={{ ...this.props.styleContainer }}>
                {
                    this.props.title &&
                    <div className="star-title">
                        {this.props.title ? this.props.title : null}
                    </div>
                }
                <div className="star-content">
                    {<FontAwesomeIcon 
                        icon={faMinusCircle} 
                        style={{ fontSize: '1rem', color: '#d0d0d0', marginRight: 15, cursor: this.props.disabled ? 'default' : 'pointer' }} 
                        onClick={() => { 
                            if(this.props.disabled || this.props.editMode || conceitosSorted?.length == 0) return;

                            let min = Math.min(...this.props.escala?.conceitos.map(c => Number(c.valor)));
                            let currIndex = conceitosSorted.findIndex(c => c.valor && c.valor == this.props.value);

                            if(!currIndex){
                                this.props.onChange && this.props.onChange(min);
                            }
                            
                            (currIndex > 0) && this.props.onChange && this.props.onChange(Number(conceitosSorted[currIndex - 1].valor));
                        }}
                    />}
                    <div className="stars">
                        {conceitosSorted.map((conceito, i) => {
                            let valorConceitoAtual = parseFloat(conceito.valor);
                            let valorConceitoHovered = this.state.hover ? parseFloat(this.state.hover.valor) : 0;
                            
                            return(
                                <div
                                    className={`star ${valorConceitoAtual <= parseFloat(this.props.value) ? 'active' : ''} ${this.state.hover ? (valorConceitoAtual == valorConceitoHovered ? 'hover' : `unhovered-${valorConceitoAtual < valorConceitoHovered ? 'left' : 'right'}`) : ''} ${this.props.disabled ? 'star-disabled' : ''}`}
                                    key={`star-${i}`}
                                    style={{ ...this.props.styleStar }}
                                    onMouseEnter={() => { this.setState({ hover: {...conceito} }); }}
                                    onMouseLeave={() => { this.setState({ hover: null }); }}
                                    onClick={() => {
                                        if(this.props.disabled || this.props.editMode) return;
                                        this.props.onChange && this.props.onChange(Number(conceito.valor));
                                    }}
                                >
                                    <FontAwesomeIcon icon={faStar}/>
                                    {this.state.hover && this.state.hover.valor == conceito.valor && 
                                        <span className="star-value">
                                            {conceito.valor}%
                                        </span>
                                    }
                                    {
                                        this.props.editMode && this.state.hover && this.state.hover.valor == conceito.valor &&
                                        <div className="star-items">
                                            <div className="star-delete" onClick={() => {
                                                if(this.props.disabled) return;

                                                let conceitoAtual = this.props.escala.conceitos.find(conc => conc.id && conc.id === conceito.id);
                                                if(!conceitoAtual.id)
                                                    return;
                                                this.props.deleteConceitoCallback && this.props.deleteConceitoCallback(conceitoAtual);
                                            }}>
                                                <FontAwesomeIcon icon={faTrash} style={{ color: Colors.error }}/>
                                            </div>
                                            
                                            <div className="star-edit" onClick={() => {
                                                if(this.props.disabled) return;

                                                let conceitoAtual = this.props.escala.conceitos.find(conc => conc.id && conc.id === conceito.id);
                                                if(!conceitoAtual.id)
                                                    return;         
                                                this.props.editConceitoCallback && this.props.editConceitoCallback(conceitoAtual);
                                            }}>
                                                <FontAwesomeIcon icon={faPen} style={{ color: Colors.dark }}/>
                                            </div>
                                        </div>
                                    }
                                </div>
                            )
                        })}
                    </div>
                    {<FontAwesomeIcon 
                        icon={faPlusCircle} 
                        style={{ fontSize: '1rem', color: '#d0d0d0', marginLeft: 15, cursor: this.props.disabled ? 'default' : 'pointer' }} 
                        onClick={() => { 
                            if(this.props.disabled || this.props.editMode || conceitosSorted?.length == 0) return;

                            let max = Math.max(...this.props.escala?.conceitos.map(c => Number(c.valor)));

                            let currIndex = conceitosSorted.findIndex(c => c.valor && Number(c.valor) == Number(this.props.value));

                            if(!currIndex){
                                this.props.onChange && this.props.onChange(max);
                            }

                            (currIndex < conceitosSorted.length - 1) && this.props.onChange && this.props.onChange(Number(conceitosSorted[currIndex + 1].valor));
                        }}
                    />}
                </div>
            </div>
        )

    }

    render() {
        if(this.props.type == 'star')
            return this.renderStarConceito();

        return this.renderConceito();
    }
}

export default ConceitoPicker;