import React from "react";
import domtoimage from 'dom-to-image';
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faCheckCircle, faDotCircle, faImages } from "@fortawesome/free-solid-svg-icons";
import DefaultButton from "../DefaultButton";
import Modal from "../Modal";
import Colors from "../../../constants/Colors";
import DefaultLoader from "../DefaultLoader";
import { withTranslation } from 'react-i18next';

import './ButtonDownloadImages.css';

class ButtonDownloadImages extends React.Component {
    state = {
        showModal: false,
        selectedImages: [],
        increase: false,
    }

    componentDidMount() {
        this.props.i18n.loadNamespaces(['client_src_components_tools_ButtonDownloadImages_ButtonDownloadImages']);
    }

    print = async () => {
        const scaleFactor = 4; // Fator de escala para melhorar a qualidade da imagem
        
        if (!this.state.print) {
            this.setState({ print: true }, () => {
                setTimeout(async () => {
                    let selectedImages = document.querySelectorAll('.image.selected');
                    if (selectedImages) {
                        let index = 0;
                        for (const image of selectedImages) {
                            try {
                                let png = await domtoimage.toPng(image, {
                                    bgcolor: 'white',
                                    borderRadius: 5,
                                    style: {
                                        transform: `scale(${scaleFactor})`,
                                        transformOrigin: 'top left',
                                        width: `${image.offsetWidth}px`,
                                        height: `${image.offsetHeight}px`
                                    },
                                    width: image.offsetWidth * scaleFactor,
                                    height: image.offsetHeight * scaleFactor
                                });

                                var link = document.createElement('a');
                                link.download = `image${index}.png`;
                                link.href = png;
                                link.click();
                                link.remove();
                                index++;
                            } catch (error) {
                                toast.error(this.props.t('client_src_components_tools_ButtonDownloadImages_ButtonDownloadImages:problem_generating_image'));
                            }
                        }
                    }

                    this.setState({ print: false, width: 100 }, () => {this.close();});
                }, 1000);
            });
        }
    };

    close = () => {
        this.setState({ selectedImages: [], showModal: false });
    }

    handleClick(index) {
        this.setState(prevState => {
          const selectedImages = [...prevState.selectedImages];
          if (selectedImages.includes(index)) {
            return { selectedImages: selectedImages.filter(i => i !== index) };
          } else {
            selectedImages.push(index);
            let increase = true;
            return { selectedImages, increase };
          }
        }, () => {
            // Remove the increase class after the animation
            if (this.state.increase) {
                setTimeout(() => this.setState({ increase: false }), 300);
            }
        });
    }

    renderModal() {
        return (
            <Modal style={{width: this.props.isSmallScreen ? '95vw' : '90vw', height: '95vh'}}>
                {this.renderTitle()}
                {this.renderBody()}
                {this.renderFooter()}
            </Modal>
        )
    }

    renderTitle() {
        return (
            <div className={'ModalDownloadImages__header'}>
                <FontAwesomeIcon icon={faDownload} style={{marginRight: 10}} />
                {this.props.title}
            </div>
        )
    }

    renderBody() {
        const renderLoading = () => {
            return (
                <div className="ModalDownloadImages__body">
                    <DefaultLoader />
                </div>
            )
        }

        const renderCharts = () => {
            return (
                <div className="ModalDownloadImages__body">
                    <div className="ModalDownloadImages__infos">
                        <span className={`message ${this.props.imageComponents.length === this.state.selectedImages.length ? 'all' : 'notAll'}`}>
                            <FontAwesomeIcon icon={faImages} className="fa-icon" style={{ marginRight: 10 }} />
                            {this.props.imageComponents.length === this.state.selectedImages.length ? this.props.t('client_src_components_tools_ButtonDownloadImages_ButtonDownloadImages:all_images_selected') : this.props.t('client_src_components_tools_ButtonDownloadImages_ButtonDownloadImages:select_images')}
                        </span>
                        <div className={`count ${this.state.increase ? 'increase' : ''}`}>
                            {this.state.selectedImages.length} {this.state.selectedImages.length > 1 ? this.props.t('client_src_components_tools_ButtonDownloadImages_ButtonDownloadImages:items_selected') : this.props.t('client_src_components_tools_ButtonDownloadImages_ButtonDownloadImages:item_selected')}
                        </div>
                    </div>

                    <div className="ModalDownloadImages__list">
                        {this.props.imageComponents.map((chart, index) => (
                            <div 
                                key={index}
                                style={chart.style}
                                className={`image ${index} ${this.state.selectedImages.includes(index) ? 'selected' : ''}`}
                                onClick={() => {this.handleClick(index)}}
                            >
                                {chart.image}
                            </div>
                        ))}
                    </div>

                    <div className="ModalDownloadImages__checks">
                        {this.props.imageComponents.map((chart, index) => (
                            <div 
                                key={index}
                                className={`checkbox ${index} ${this.state.selectedImages.includes(index) ? 'selected' : ''}`}
                                onClick={() => {this.handleClick(index)}}
                            >
                                <FontAwesomeIcon icon={this.state.selectedImages.includes(index) ? faCheckCircle : faDotCircle} />
                            </div>
                        ))}
                    </div>
                </div>
            )
        }

        if (this.props.loading) return renderLoading();
        return renderCharts();
    }

    renderFooter() {
        return (
            <div className={'ModalDownloadImages__footer'}>
                <DefaultButton
                    title={this.props.t('client_src_components_tools_ButtonDownloadImages_ButtonDownloadImages:download')}
                    leftIcon={<FontAwesomeIcon icon={faDownload} />}
                    color={Colors.success}
                    disabled={this.state.selectedImages.length === 0}
                    textColor={Colors.white}
                    onClick={() => { this.print(); }}
                />

                <DefaultButton 
                    title={this.props.t('client_src_components_tools_ButtonDownloadImages_ButtonDownloadImages:close')}
                    color={Colors.dark}
                    textColor={Colors.white}
                    onClick={() => {this.close()}}
                />
            </div>
        )
    }

    render() {
        return (
            <>
                <DefaultButton
                    onClick={() => {
                        this.props.updateImages();
                        this.setState({ showModal: !this.state.showModal });
                    }}
                    leftIcon={<FontAwesomeIcon icon={faDownload} />}
                    tooltip={this.props.t('client_src_components_tools_ButtonDownloadImages_ButtonDownloadImages:download_graphs_tooltip')}
                    tooltipPlacement={'left'}
                    color={this.props.color}
                    textColor={this.props.textColor}
                    disabled={this.props.loading || false}
                />

                {this.state.showModal && this.renderModal()}
            </>
        )
    }
}

export default withTranslation()(ButtonDownloadImages);