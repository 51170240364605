import React from "react";
import ReactDOM from "react-dom";
import Colors from '../../constants/Colors';
import LayoutHelper from "../../helper/LayoutHelper";
import EssentialStyle from "../../style/EssentialStyle";

export default class Modal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isSmallScreen: LayoutHelper.isSmallScreen(),
        };
    }

    async componentDidMount() {
        window.removeEventListener('resize', this.verifyScreen);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.verifyScreen);
    }

    verifyScreen = () => {
        this.setState({ isSmallScreen: LayoutHelper.isSmallScreen() });
    }

    modal() {
        return (
            <div 
                className={ this.props.className || "" }
                style={{ 
                    ...EssentialStyle.columnStart, 
                    position: "fixed", 
                    top: this.state.isSmallScreen ? "calc(50% - 7.5vh)" : "50%", 
                    left: "50%", 
                    transform: "translate(-50%, -50%)", 
                    minWidth: this.state.isSmallScreen ? 385 : 550, 
                    width: this.state.isSmallScreen ? '95vw' : '50vw', 
                    backgroundColor: Colors.white, 
                    zIndex: 20, 
                    borderRadius: 8, 
                    boxShadow: `0px 0px 20px 20px rgba(50, 50, 50, 0.1)`, 
                    padding: 15,
                    ...this.props.style,
                    maxHeight: this.state.isSmallScreen ? '80vh' : '90vh',
                }}
                onDragOver={(e) => e.preventDefault()}
                onDrop={(e) => e.preventDefault()} 
            >
                {this.props.children}
            </div>    
        )
    }

    render() {
        const element = document.getElementById('root');

        return (
            ReactDOM.createPortal(
                this.modal(),
                element
            )
        );
    }
}