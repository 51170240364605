import React from "react";

import './RetrospectivaPDI2024.css';
import EssentialStyle from "../../../../style/EssentialStyle";
import Constants from '../../../../constants/Api';
import Colors from "../../../../constants/Colors";

const gateway = Constants.getSigEndPoint();

export default class RetrospectivaPDI2024 extends React.Component {
    state = {
        pdis: this.props.pdis,
        screenHeight: this.props.screenHeight,
        isSmallScreen: this.props.isSmallScreen
    }

    async componentDidUpdate(prevProps) {
        if (prevProps.pdis !== this.props.pdis) {
            this.setState({ pdis: this.props.pdis });
        }

        if (prevProps.screenHeight !== this.props.screenHeight) {
            this.setState({ screenHeight: this.props.screenHeight });
        }

        if (prevProps.isSmallScreen !== this.props.isSmallScreen) {
            this.setState({ isSmallScreen: this.props.isSmallScreen });
        }
    }

    renderLeft() {
        return (
            <div
                style={{
                    ...EssentialStyle.columnCenter,
                    alignItems: 'center',
                    width: this.state.isSmallScreen ? '100%' : '40%',
                    paddingLeft: this.state.isSmallScreen ? 0 : '30px',
                    height: '100%',
                    paddingTop: this.state.isSmallScreen ? '100px' : 0
                }}
            >
                <div
                    style={{
                        fontSize: '20rem',
                        fontWeight: '600',
                        marginTop: '-6rem'
                    }}
                >
                    {this.state?.pdis}
                </div>
                <div
                    style={{
                        fontSize: '6rem',
                        fontWeight: '100',
                        marginTop: '-6rem'
                    }}
                >
                    PDI'S
                </div>
                <div
                    style={{
                        fontSize: '2rem',
                        fontWeight: '600'
                    }}
                >
                    CONCLUÍDOS
                </div>
            </div>
        );
    }

    renderRight() {
        return (
            <div
                style={{
                    ...EssentialStyle.columnStart,
                    height: this.state.isSmallScreen ? 'auto' : '100%',
                    width: this.state.isSmallScreen ? '100%' : '60%',
                    paddingRight: this.state.isSmallScreen ? 10 : '30px',
                    paddingLeft: this.state.isSmallScreen ? 10 : '30px'
                }}
            >
                <span
                    className="retro-default-text"
                    style={{
                        minWidth: this.state.isSmallScreen ? '100%' : '95%',
                        marginTop: '40px',
                        fontWeight: '400'
                    }}
                >
                    {this.renderText()}
                    <div
                        style={{
                            ...EssentialStyle.columnEnd,
                            marginBottom: this.state.isSmallScreen ? '160px' : 0,
                        }}
                    >
                        <img
                            src={`${gateway}/img/retrospectiva/retrosig24_balls.png`}
                            alt="Logo da Retrospectiva SIG 2024, com elementos geométricos a esquerda e RetroSIG escrito em uma fonte moderna a direita."
                            style={{
                                width: this.state.isSmallScreen ? '200px' : this.state.screenHeight * 0.55
                            }}
                        />
                    </div>
                </span>
            </div>
        );
    }

    renderText() {
        if (this.state?.pdis > 0) {
            return `Nosso desenvolvimento é nossa prioridade, certo? E você fez isso acontecer, concluindo ${this.state?.pdis} PDIs ao longo do ano. Suas iniciativas de crescimento mostram que o futuro só tende a brilhar mais!`;
        }
    }


    renderPDI() {
        return (
            <div
                style={{
                    ...EssentialStyle.columnCenter,
                    width: '100%',
                    height: '100%'
                }}
            >
                <div
                    style={{
                        ...EssentialStyle.rowSpaceBetween,
                        flexDirection: this.state.isSmallScreen ? 'column' : 'row',
                        width: '100%',
                        height: this.state.isSmallScreen ? 'auto' : '65%'
                    }}
                >
                    {this.renderLeft()}
                    {this.renderRight()}
                </div>
            </div>
        );
    }

    render() {
        return (
            <div
                style={{
                    ...EssentialStyle.columnCenter,
                    width: '100%',
                    height: this.state.isSmallScreen ? 'auto' : this.state.screenHeight
                }}
            >
                {this.renderPDI()}
            </div>
        );
    }
}