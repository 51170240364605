import React from 'react';
import '../GradeAvaliacao/GradeAvaliacao.css';

import { withTranslation } from 'react-i18next';
import { Form, Row, Col } from 'react-bootstrap';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import SessionHelper from '../../../../../helper/SessionHelper';
import DiagnosticoHelper from '../../../../../helper/diagnostico/DiagnosticoHelper';
import EssentialStyle from '../../../../../style/EssentialStyle';
import Colors from '../../../../../constants/Colors';
import DefaultButton from '../../../../tools/DefaultButton';
import Modal from "../../../../tools/Modal";


class ModalAvaliadores extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            canEdit: props.canEdit || false,
            loading: false,
        };
    }

    async componentDidMount() {
        this.setState({ loading: true });
        await this.props.i18n.loadNamespaces(['client_src_components_modules_diagnostico_GradesAvaliacao_ModalAvaliadores_ModalAvaliadores']);
        await this.props.i18n.loadNamespaces(['client_src_helper_diagnostico_DiagnosticoHelper']);

        this.setState({ loading: false, avaliadores: this.props.openEditAvaliadores?.avaliadores || [] });
    }

    async componentDidUpdate(prevProps) {
        if (prevProps.canEdit != this.props.canEdit) {
            this.setState({ canEdit: this.props.canEdit });
        }
    }

    renderEditingAvaliadores() {

        let avaliadoresSelected = this.props.openEditAvaliadores.avaliadores || [];
        if(avaliadoresSelected.length >= this.props.avaliadores.length && !this.props.noGroups){
            if(this.props.avaliadores.every(avaliador => avaliadoresSelected.find(avaliadorSelected => avaliadorSelected.value === avaliador.value))){
                avaliadoresSelected = avaliadoresSelected.filter(avaliador => !this.props.avaliadores.map(a => a.value).includes(avaliador.value));
                avaliadoresSelected = [{ value: '*', label: this.props.t('client_src_components_modules_diagnostico_GradesAvaliacao_ModalAvaliadores_ModalAvaliadores:selectionTodosAvaliadores') }, ...avaliadoresSelected];
            }
        }

        let publicadoresSelected = this.props.openEditAvaliadores.publicadores || [];
        if(publicadoresSelected.length >= this.props.avaliadores.length && !this.props.noGroups){
            if(this.props.avaliadores.every(avaliador => publicadoresSelected.find(avaliadorSelected => avaliadorSelected.value === avaliador.value))){
                publicadoresSelected = publicadoresSelected.filter(avaliador => !this.props.avaliadores.map(a => a.value).includes(avaliador.value));
                publicadoresSelected = [{ value: '*', label: this.props.t('client_src_components_modules_diagnostico_GradesAvaliacao_ModalAvaliadores_ModalAvaliadores:selectionTodosPublicadores') }, ...publicadoresSelected];
            }
        }

        if (this.state.canEdit && !this.props.loading && !this.state.loading) {
            return (
                <Modal style={{ width: '50vw', height: 'auto' }} className="GradeAvaliacao">
                    <div
                        style={{
                            ...EssentialStyle.rowFlexCenter,
                            marginBottom: 20,
                            width: 'calc(100% + 30px)',
                            backgroundColor: SessionHelper.getColor(),
                            margin: '-15px -15px 10px -15px',
                            color: Colors.light,
                            fontWeight: 'bold',
                            padding: 5,
                        }}
                    >
                        {this.props.t('client_src_components_modules_diagnostico_GradesAvaliacao_ModalAvaliadores_ModalAvaliadores:modalEdit')}
                    </div>
                    <Form className="w-100">
                        <Row className="mb-3">
                            <Form.Group as={Col} xs={12} md={12} className="mb-3">
                                <Form.Label>
                                    <div className="labelBanner">
                                        {this.props.t('client_src_components_modules_diagnostico_GradesAvaliacao_ModalAvaliadores_ModalAvaliadores:modalEditEmpresa')}
                                    </div>
                                </Form.Label>
                                <div>{this.props.openEditAvaliadores?.empresaAvaliada?.nome_fantasia || ''}</div>
                            </Form.Group>

                            <Form.Group as={Col} xs={12} md={6} className="mb-3">
                                <Form.Label>
                                    <div className="labelBanner">
                                        {this.props.t('client_src_components_modules_diagnostico_GradesAvaliacao_ModalAvaliadores_ModalAvaliadores:modalEditTipo')}
                                    </div>
                                </Form.Label>
                                {DiagnosticoHelper.renderTipoAvaliacao(this.props.openEditAvaliadores?.tipo, this.props.t)}
                            </Form.Group>

                            <Form.Group as={Col} xs={12} md={6} className="mb-3">
                                <Form.Label>
                                    <div className="labelBanner">
                                        {this.props.t('client_src_components_modules_diagnostico_GradesAvaliacao_ModalAvaliadores_ModalAvaliadores:modalEditQuestionario')}
                                    </div>
                                </Form.Label>
                                <div>{this.props.openEditAvaliadores?.questionario?.nome || ''}</div>
                            </Form.Group>

                            <Form.Group as={Col} xs={12} md={12} className="mb-5">
                                <Form.Label>
                                    <div className="labelBanner">
                                        {this.props.t('client_src_components_modules_diagnostico_GradesAvaliacao_ModalAvaliadores_ModalAvaliadores:modalEditAvaliadores')}
                                    </div>
                                </Form.Label>
                                <Select
                                    options={
                                        this.props.noGroups ?
                                            this.props.avaliadores
                                        :
                                            [
                                                { value: '*', label: this.props.t('client_src_components_modules_diagnostico_GradesAvaliacao_ModalAvaliadores_ModalAvaliadores:selectionTodosAvaliadores') },
                                                ...this.props.avaliadores
                                            ]
                                    }
                                    placeholder={this.props.t('client_src_components_modules_diagnostico_GradesAvaliacao_ModalAvaliadores_ModalAvaliadores:bannerPlaceHolderAvaliadores')}
                                    onChange={(value) => {
                                        if(this.props.changeAvaliacao){
                                            if (value?.find(v => v.value === '*')) {
                                                var avaliadores = value.filter(v => v.value !== '*');
                                                avaliadores = [...avaliadores, ...this.props.avaliadores];
                                                avaliadores = avaliadores.filter((v, i, a) => a.findIndex(t => (t.value === v.value)) === i);

                                                this.props.changeAvaliacao({
                                                    ...this.props.openEditAvaliadores, 
                                                    avaliadores: avaliadores
                                                });
                                                return;
                                            }
                                            this.props.changeAvaliacao({...this.props.openEditAvaliadores, avaliadores: value});
                                        }
                                    }}
                                    value={avaliadoresSelected}
                                    isMulti
                                    isSearchable
                                    isCleanable
                                />
                            </Form.Group>
                            <Form.Group as={Col} xs={12} md={12} className="mb-5">
                                <Form.Label>
                                    <div className="labelBanner">
                                        {this.props.t('client_src_components_modules_diagnostico_GradesAvaliacao_ModalAvaliadores_ModalAvaliadores:modalEditPublicadores')}
                                    </div>
                                </Form.Label>
                                <Select
                                    options={
                                        this.props.noGroups ?
                                            this.props.avaliadores
                                        :
                                            [
                                                { value: '*', label: this.props.t('client_src_components_modules_diagnostico_GradesAvaliacao_ModalAvaliadores_ModalAvaliadores:selectionTodosPublicadores') },
                                                ...this.props.avaliadores
                                            ]
                                    }
                                    placeholder={this.props.t('client_src_components_modules_diagnostico_GradesAvaliacao_ModalAvaliadores_ModalAvaliadores:bannerPlaceHolderPublicadores')}
                                    onChange={(value) => {
                                        if(this.props.changeAvaliacao){
                                            if (value?.find(v => v.value === '*')) {
                                                var publicadores = value.filter(v => v.value !== '*');
                                                publicadores = [...publicadores, ...this.props.avaliadores];
                                                publicadores = publicadores.filter((v, i, a) => a.findIndex(t => (t.value === v.value)) === i);

                                                this.props.changeAvaliacao({
                                                    ...this.props.openEditAvaliadores, 
                                                    publicadores: publicadores
                                                });
                                                return;
                                            }
                                            this.props.changeAvaliacao({...this.props.openEditAvaliadores, publicadores: value});
                                        }
                                    }}
                                    value={publicadoresSelected}
                                    isMulti
                                    isSearchable
                                    isCleanable
                                />
                            </Form.Group>
                        </Row>
                        <Row>
                            <Form.Group as={Col} xs={12} md={12}>
                                <div style={{ ...EssentialStyle.rowFlexEnd, gap: 10 }}>
                                    <DefaultButton
                                        title={this.props.t('client_src_components_modules_diagnostico_GradesAvaliacao_ModalAvaliadores_ModalAvaliadores:cancel')}
                                        leftIcon={<FontAwesomeIcon icon={faTimesCircle} />}
                                        color={Colors.error}
                                        onClick={() => {
                                            if(this.props.onClose) {
                                                this.props.onClose()
                                            }
                                        }}
                                    />
                                    <DefaultButton
                                        title={this.props.t('client_src_components_modules_diagnostico_GradesAvaliacao_ModalAvaliadores_ModalAvaliadores:save')}
                                        leftIcon={<FontAwesomeIcon icon={faPaperPlane} />}
                                        color={Colors.success}
                                        onClick={() => {
                                            if(this.props.onSave) {
                                                this.props.onSave()
                                            }
                                        }}
                                        disabled={this.props.loading || this.state.loading}
                                    />
                                </div>
                            </Form.Group>
                        </Row>
                    </Form>
                </Modal>
            );
        }
        return null;
    }

    render() {
        return <>{this.renderEditingAvaliadores()}</>;
    }
}

export default withTranslation()(ModalAvaliadores);