import React from "react";

export default class SessionHelper {
    
    constructor() {
        this._token = null;
        this._color = null;
        this._secondary_color = null;
        this._background_color = null;
        this._user = null;
        this._data = null;
        this._language = null;
        this._timezone = null;
        this._permission_type = null;
        this._constants = {
            isDevelopment: false,
            isTestUser: false
        };
    }

    static setToken(token) {
        this._token = token;
    }

    static getToken() {
        return this._token;
    }

    static setColor(color) {
        this._color = color;
    }

    static getColor() {
        return this._color;
    }

    static setSecondaryColor(color) {
        this._secondary_color = color;
    }

    static getSecondaryColor() {
        return this._secondary_color;
    }

    static setUser(color) {
        this._user = color;
    }

    static getUser() {
        return this._user;
    }

    static setBackgroundColor(color) {
        this._background_color = color;
    }

    static getBackgroundColor() {
        return this._background_color;
    }

    static setData(data) {
        this._data = data;
    }

    static getData() {
        return this._data;
    }

    static setConstants(constants) {
        this._constants = constants;
    }

    static getConstants() {
        return this._constants;
    }

    static setPermissionType(type) {
        this._permission_type = type;
    }

    static getPermissionType() {
        return this._permission_type;
    }

    static setLanguage(language) {
        this._language = language;
    }

    static getLanguage() {
        return this._language;
    }

    static setTimezone(timezone) {
        this._timezone = timezone;
    }

    static getTimezone() {
        return this._timezone;
    }

    static destroy() {
        this._token = null;
        this._data = null;
        this._permission_type = null;

        return true;
    }

    static isDevelopmentMode() {
        return '_self' in React.createElement('div');
    }
}