import React from "react";
import Modal from "../../../tools/Modal";
import EssentialStyle from "../../../../style/EssentialStyle";
import DefaultButton from "../../../tools/DefaultButton";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import Colors from "../../../../constants/Colors";
import { toast } from 'react-toastify';
import Sig from "../../../../api/Sig";
import TextEditor from "../../../tools/TextEditor/TextEditor";
import DataHelper from "../../../../helper/DataHelper";
import Select from "react-select";

export default class AtaReuniaoConvocacaoModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            isSmallScreen: this.props.isSmallScreen,
            convocacao: this.props.convocacao,
            participantesAConvocar: DataHelper.formatSelectData(this.props.participantes.filter((p) => !p.convocado), 'email', 'nome'),
        };
    }

    enviarConvocacao = async () => {
        this.setState({ loading: true });
        if(this.state.participantesAConvocar.length == 0) {
            toast.error('Selecione ao menos um participante para convocar');
            this.setState({ loading: false });
            return;
        }

        if(!this.state.convocacao || this.state.convocacao.length == "" || this.state.convocacao == "<p></p>") {
            toast.error('Digite a convocação da reunião');
            this.setState({ loading: false });
            return;
        }


        var participantesAConvocar = this.state.participantesAConvocar.map(p => p.value);
        var req = await Sig.request('POST', `ataReuniao${this.props.hash ? "Externa" : ""}/enviarConvocacao`, { 
            id: this.props.idAtaReuniao,
            hash: this.props.hash, 
            participantesAConvocar: JSON.stringify(participantesAConvocar), 
            convocacao: this.state.convocacao
        });
        if (req.status == 200) {
            toast.success('Convocação enviada com sucesso');
            this.props.updateParticipantesConvocadosCallback(req.participantes);
            this.props.updateConvocacaoEnviadaCallback();
            this.props.closeCallback();
        } else {
            toast.error('Erro ao enviar convocação, tente novamente');
        }
        this.setState({ loading: false });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isSmallScreen !== this.props.isSmallScreen) {
            this.setState({ isSmallScreen: this.props.isSmallScreen });
        }
        if (prevProps.convocacao !== this.props.convocacao && this.props.convocacao != this.state.convocacao) {
            this.setState({ convocacao: this.props.convocacao });
        }
        if (prevProps.participantes !== this.props.participantes && this.props.participantes != this.state.participantesAConvocar) {
            this.setState({ participantesAConvocar: DataHelper.formatSelectData(this.props.participantes) });
        }
    }

    render() {
        const modalRoot = document.getElementById('modal-root');
        var convocacao = '<p>Olá, [Nome do Colaborador]<br>'+ 
        this.state.convocacao + '<p>[Tópicos visíveis ao Colaborador]</p>';

        return (
            <Modal style={{ width: this.props.isSmallScreen ? '85vw' : '90vw' }}>
                <div style={{...EssentialStyle.columnStart, width: '100%', minHeight: 300, maxHeight: "85vh", overflowY: "auto", marginTop: 30}}>
                    <div style={{...EssentialStyle.rowSpaceBetween, width: 'auto', padding: 10, alignSelf: "flex-end", marginBottom: 10, position: "absolute", top: 10}}>
                        <DefaultButton
                                leftIcon={<FontAwesomeIcon icon={faClose} />}
                                color={"transparent"}
                                textColor={Colors.dark}
                                style={{width: 35, height: 35, padding: 0, alignSelf: 'flex-end'}}
                                onClick={this.props.closeCallback}
                            />
                    </div>
                    <div style={{...EssentialStyle.columnCenter, width: '100%', padding: "0px 20px", height: "100%"}}>
                        <span style={{fontSize: 20, fontWeight: 500, marginBottom: 10, color: Colors.homePage.darkGrey}}>Email de Convocação</span>
                        <div style={{...(this.props.isSmallScreen ? EssentialStyle.columnCenter : EssentialStyle.rowFlexCenter), gap: 10}}>
                            <TextEditor
                                width={this.props.isSmallScreen ? "100%" : "50%"}
                                minHeight={100}
                                editorStyle={{width: "100%", minHeight: "100%"}}
                                defaultValue={this.props.convocacao || ""}
                                onChange={(value) => { this.setState({ convocacao: value }) }}
                                placeholder={'Digite a convocação da reunião...'}
                            />
                            <div style={{width: this.props.isSmallScreen ? "100%" : "50%", gap: 5}}>
                                <span style={{fontSize: 18, fontWeight: 500, marginBottom: 10, color: Colors.homePage.darkGrey}}>Prévia da Convocação</span>
                                <span dangerouslySetInnerHTML={{ __html: convocacao }}/>
                            </div>
                        </div>
                        <span style={{fontSize: 20, fontWeight: 500, marginBottom: 10, color: Colors.homePage.darkGrey}}>Participantes a Convocar</span>
                        <Select
                            menuPortalTarget={modalRoot}
                            formatOptionLabel={({ label, value }) => (
                                <div style={{
                                    ...EssentialStyle.rowFlexStart,
                                    paddingLeft: 8,
                                    fontWeight: 400,
                                    width: '100%',
                                    cursor: 'pointer',
                                }}>
                                    {this.props.participantes.find(p => p.email == value)?.convocado ? label + " (Já Convocado)" : label}
                                </div>
                            )}
                            styles={{ container: (base) => ({ ...base, width: '100%' }), menuPortal: (base) => ({ ...base, zIndex: 9999}) }}
                            options={DataHelper.formatSelectData(this.props.participantes, 'email', 'nome')}
                            placeholder={this.props.participantes.filter(p => !p.convocado).length == 0 ? 'Todos participantes já foram convocados, selecione quem reconvocar' : 'Selecione os participantes que serão Convocados'}
                            noOptionsMessage={() => "Todos os participantes possíveis já foram selecionados"}
                            isClearable isSearchable isMulti
                            value={this.state.participantesAConvocar}
                            onChange={(value) => { this.setState({ participantesAConvocar: value }) }}
                        />
                    </div>
                </div>
                <DefaultButton
                    disabled={this.state.loading || this.state.participantesAConvocar.length == 0}
                    style={{placeSelf: 'flex-end', margin: 10}}
                    color={Colors.success}
                    title={"Enviar Convocação"}
                    onClick={this.enviarConvocacao}
                />
            </Modal>
        );
    }
}