import React from "react";

import './RetrospectivaFeedback2024.css';
import EssentialStyle from "../../../../style/EssentialStyle";
import Constants from '../../../../constants/Api';
import Colors from "../../../../constants/Colors";

const gateway = Constants.getSigEndPoint();

export default class RetrospectivaFeedback2024 extends React.Component {
    state = {
        feedbacks: this.props.feedbacks,
        screenHeight: this.props.screenHeight,
        isSmallScreen: this.props.isSmallScreen
    }

    async componentDidUpdate(prevProps) {
        if (prevProps.feedbacks !== this.props.feedbacks) {
            this.setState({ feedbacks: this.props.feedbacks });
        }

        if (prevProps.screenHeight !== this.props.screenHeight) {
            this.setState({ screenHeight: this.props.screenHeight });
        }

        if (prevProps.isSmallScreen !== this.props.isSmallScreen) {
            this.setState({ isSmallScreen: this.props.isSmallScreen });
        }
    }

    renderLeft() {
        let scale = this.state?.feedbacks?.enviados > this.state?.feedbacks?.recebidos ? this.state?.feedbacks?.enviados : this.state?.feedbacks?.recebidos;

        let sent = (this.state?.feedbacks?.enviados / scale) * 100;
        let received = (this.state?.feedbacks?.recebidos / scale) * 100;

        return (
            <div
                style={{
                    ...EssentialStyle.columnEnd,
                    alignItems: 'center',
                    width: this.state.isSmallScreen ? '100%' : '55%',
                    paddingLeft: this.state.isSmallScreen ? 0 : '30px',
                    height: '100%'
                }}
            >
                <div
                    style={{
                        ...EssentialStyle.rowSpaceAround,
                        alignItems: 'flex-end',
                        height: '300px',
                        paddingLeft: this.state.isSmallScreen ? 0 : '10%',
                        paddingRight: this.state.isSmallScreen ? 0 : '10%',
                        width: '100%',
                    }}
                >
                    {this.state?.feedbacks?.enviados >= 1 &&
                        <div
                            style={{
                                ...EssentialStyle.columnEnd,
                                alignItems: 'center',
                                height: '100%',
                                fontSize: this.state.isSmallScreen ? '20px' : '40px',
                                fontWeight: '600',
                                color: '#E8E8E4',
                            }}
                        >
                            <div
                                style={{
                                    ...EssentialStyle.columnEnd,
                                    height: 'calc(100% - 20px)',
                                }}
                            >
                                <div
                                    style={{
                                        ...EssentialStyle.columnEnd,
                                        alignItems: 'center',
                                        height: `${sent}%`,
                                        width: '90px',
                                        backgroundColor: '#E8E8E4',
                                        color: '#A22C2D',
                                        fontSize: '40px',
                                        fontWeight: '600',
                                        borderRadius: '64px'
                                    }}
                                >
                                    {this.state?.feedbacks?.enviados}
                                </div>
                            </div>
                            <span>Enviados</span>
                        </div>
                    }
                    {this.state?.feedbacks?.recebidos > 0 &&
                        <div
                            style={{
                                ...EssentialStyle.columnEnd,
                                alignItems: 'center',
                                height: '100%',
                                fontSize: this.state.isSmallScreen ? '20px' : '40px',
                                fontWeight: '600',
                                color: '#E8E8E4',
                            }}
                        >
                            <div
                                style={{
                                    ...EssentialStyle.columnEnd,
                                    height: 'calc(100% - 20px)',
                                }}
                            >
                                <div
                                    style={{
                                        ...EssentialStyle.columnEnd,
                                        alignItems: 'center',
                                        height: `${received}%`,
                                        width: '90px',
                                        backgroundColor: '#A22C2D',
                                        color: '#E8E8E4',
                                        fontSize: '40px',
                                        fontWeight: '600',
                                        borderRadius: '64px'
                                    }}
                                >
                                    {this.state?.feedbacks?.recebidos}
                                </div>
                            </div>
                            <span>Recebidos</span>
                        </div>
                    }
                </div>
                <span
                    className="retro-default-text"
                    style={{
                        minWidth: '95%',
                        marginTop: '40px',
                        fontWeight: '400'
                    }}
                >
                    {this.renderText()}
                </span>
            </div>
        );
    }

    renderText() {
        if (this.state?.feedbacks?.enviados >= 1) {
            return `Você deu ${this.state?.feedbacks?.enviados} feedbacks, criando um ambiente de diálogo que fortaleceu não apenas a comunicação, mas também a evolução da equipe. Impulsionar o desenvolvimento dos talentos é o caminho!`;
        }

        if (this.state?.feedbacks?.recebidos > 0) {
            return `Você recebeu ${this.state?.feedbacks?.recebidos} feedback${this.state?.feedbacks?.recebidos > 1 ? `s` : ``}, e esperamos que isso tenha contribuído para fortalecer a comunicação com seu gestor. Aproveitar o direcionamento de feedbacks para criar seu plano de desenvolvimento individual é o caminho!`;
        }
    }

    renderRight() {
        return (
            <div
                style={{
                    ...EssentialStyle.columnStart,
                    height: '100%',
                    width: '45%',
                    paddingRight: '30px',
                }}
            >
                <div
                    style={{
                        ...EssentialStyle.rowFlexEnd,
                        alignItems: 'center',
                        height: '100%',
                        width: '100%',
                    }}
                >
                    <div
                        style={{
                            ...EssentialStyle.columnStart,
                            fontWeight: '200',
                            fontSize: '20rem',
                            textDecoration: 'underline',
                            marginRight: '5rem'
                        }}
                    >
                        {this.state?.feedbacks?.total}
                    </div>
                    <div
                        style={{
                            ...EssentialStyle.columnStart,
                            fontSize: '3rem',
                        }}
                    >
                        <span>F</span>
                        <span>E</span>
                        <span>E</span>
                        <span>D</span>
                        <span><b>B</b></span>
                        <span><b>A</b></span>
                        <span><b>C</b></span>
                        <span><b>K</b></span>
                        <span><b>S</b></span>
                    </div>
                </div>
            </div>
        );
    }

    renderRightMobile() {
        return (
            <div
                style={{
                    ...EssentialStyle.rowFlexCenter,
                    marginBottom: '50px',
                }}
            >
                <span
                    style={{
                        fontSize: '80px',
                        textDecoration: 'underline',
                        marginRight: '20px'
                    }}
                >{this.state?.feedbacks?.total} </span>
                <span
                    style={{
                        fontSize: '40px',
                    }}
                >FEED<b>BACKS</b></span>
            </div>
        )
    }

    renderFeedback() {
        return (
            <div
                style={{
                    ...EssentialStyle.columnCenter,
                    width: '100%',
                    height: '100%'
                }}
            >
                <div
                    style={{
                        ...EssentialStyle.rowSpaceBetween,
                        flexDirection: this.state.isSmallScreen ? 'column-reverse' : 'row',
                        width: '100%',
                        height: this.state.isSmallScreen ? 'auto' : '65%'
                    }}
                >
                    {this.renderLeft()}
                    {!this.state.isSmallScreen ? this.renderRight() : this.renderRightMobile()}
                </div>
            </div>
        );
    }

    render() {
        return (
            <div
                style={{
                    ...EssentialStyle.columnCenter,
                    width: '100%',
                    height: this.state.isSmallScreen ? 'auto' : this.state.screenHeight
                }}
            >
                {this.renderFeedback()}
            </div>
        );
    }
}