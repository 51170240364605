import React from "react";
import LayoutHelper from "../../../../../helper/LayoutHelper";
import DefaultLoader from "../../../../tools/DefaultLoader";
import AnalyticsHelper from "../../../../../helper/inicio/AnalyticsHelper";
import EssentialStyle from "../../../../../style/EssentialStyle";
import SessionHelper from "../../../../../helper/SessionHelper";
import Colors from "../../../../../constants/Colors";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withTranslation } from 'react-i18next';

class ModulosAnalytics extends React.Component {
    state = {
        loading: true,
        selectedModulo: null,
        hoverModulo: null,
        isSmallScreen: LayoutHelper.isSmallScreen(),
        transform: 'translateY(+100%)',
        opacity: 0,
    }

    async componentDidMount() {
        window.addEventListener('resize', this.verifyScreen);
        await this.props.i18n.loadNamespaces(['client_src_helper_inicio_AnalyticsHelper']);
        this.loadData();
    }

    async componentDidUpdate(prevProps) {
        if (this.props.idEmpresas !== prevProps.idEmpresas) {
            this.setState({ selectedModulo: null }, () => {this.loadData()});
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.verifyScreen);
    }

    verifyScreen = () => {
        this.setState({ isSmallScreen: LayoutHelper.isSmallScreen() });    
    }

    loadData = async () => {
        this.setState({ loading: true });

        let idEmpresas = this.props.idEmpresas.map(id => id.value);
        let modulos = await AnalyticsHelper.getModulos(idEmpresas, this.props.t);
        
        this.setState({ modulos, loading: false });
    }

    changeModulo = (idModulo) => {
        this.setState({ selectedModulo: this.state.selectedModulo === idModulo ? null : idModulo}, () => {this.props.changeModulo(this.state.selectedModulo)});
    }

    renderModulo(modulo) {

        let isSelected = this.state.selectedModulo === modulo.id;

        let style = {
            ...EssentialStyle.columnCenter,
            margin: this.state.isSmallScreen ? '0px 7px 0px 0px' : '0px 0px 7px 0px',
            width: this.state.isSmallScreen ? '9rem' : '100%',
            minWidth: this.state.isSmallScreen ? '9rem' : '',
            boxShadow: this.state.hoverModulo === modulo.id ? 'rgba(50, 50, 50, 0.5) 1px 1px 10px 0px' : Colors.boxShadow,
            borderRadius: 10,
            height: this.state.isSmallScreen ? '3rem' : (isSelected ? '5rem' : '3rem'),
            transition: 'height 0.3s ease 0s',
            color: isSelected ? 'white' : SessionHelper.getColor(),
            backgroundColor: isSelected ? SessionHelper.getColor() : 'transparent',
            cursor: 'pointer',
            padding: 5,
        }

        return (
            <div key={`key-${modulo.id}`}
                style={style} 
                onClick={() => {this.changeModulo(modulo.id)}}
                onMouseEnter={() => {this.setState({hoverModulo: modulo.id})}}
                onMouseLeave={() => {this.setState({hoverModulo: null})}}
            >
                <FontAwesomeIcon icon={modulo.icon} size="2x" />
                {isSelected && !this.state.isSmallScreen && modulo.title}
            </div>
        )
    }

    renderContent() {
        return (
            <>
            {
                this.state.modulos.map((modulo) => {
                    return this.renderModulo(modulo);
                })
            }
            </>
        );
    }

    renderloading() {
        return (
            <div style={{ height: '100%', width: '100%'}}>
                <DefaultLoader />
            </div>
        );
    }

    render() {
        let style = {
            margin: this.state.isSmallScreen ? '10px 5px 10px 5px' : '10px 5px 5px 5px',
            width: 'calc(100% - 10px)',
            height: 'calc(100% - 10px)',
            boxShadow: Colors.boxShadow,
            overflowX: this.state.isSmallScreen ? 'auto' : 'hidden', 
            overflowY: this.state.isSmallScreen ? 'hidden' : 'auto', 
            borderRadius: 10,
            padding: 5,
            display: this.state.isSmallScreen ? 'grid' : '',
            gridAutoFlow: this.state.isSmallScreen ? 'column' : ''
        };

        return (
            <div style={style}>
                { this.state.loading ? this.renderloading() : this.renderContent() }
            </div>
        );
    }
}

export default withTranslation()(ModulosAnalytics);