import React from "react";
import { withTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusSquare, faHandshake, faBriefcase, faUpload, faDownload } from '@fortawesome/free-solid-svg-icons'
import Colors from "../../constants/Colors";
import DefaultButton from "../../components/tools/DefaultButton";
import LoadingPage from "../LoadingPage";
import { toast } from "react-toastify";
import EssentialStyle from "../../style/EssentialStyle";
import AddPlanoAcao from "../../components/forms/planoAcao/add/AddPlanoAcao";
import { Collapse, Form, Col } from "react-bootstrap";
import DefaultTableSearch from "../../components/tools/DefaultTableSearch";
import PlanoAcaoCard from "../../components/modules/planoAcao/PlanoAcaoCard/PlanoAcaoCard";
import DefaultLoader from "../../components/tools/DefaultLoader";
import Sig from "../../api/Sig";
import PlanoAcaoListSearch from "../../components/modules/planoAcao/PlanoAcaoListSearch";
import LayoutHelper from "../../helper/LayoutHelper";
import PlanoAcaoRelatorio from "../../components/modules/planoAcao/PlanoAcaoRelatorio/PlanoAcaoRelatorio";
import CustomTooltip from "../../components/tools/CustomTooltip";
import Tabs from "../../components/tools/Tabs";
import moment from "moment";
import ModalUploadEtapaPlanoAcao from "../../components/modules/planoAcao/ModalUploadEtapaPlanoAcao/ModalUploadEtapaPlanoAcao";
import ModalDownloadEtapaPlanoAcao from "../../components/modules/planoAcao/ModalDownloadEtapaPlanoAcao/ModalDownloadEtapaPlanoAcao";

class PlanoAcaoPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            requesting: false,
            loading: true,
            loadingHeader: true,
            showWizard: false,
            showEdit: false,
            advancedFilter: false,
            editId: null,
            canCreate: false,
            data: [],
            filter: {},
            page: 1,
            hasMore: true,
            firstLoad: true,
            firstFilterLoad: true,
            headerSize: 0,
            isSmallScreen: LayoutHelper.isSmallScreen(),
            activeTab: 'lista',
            parceirosChecked: false,
            showParceiros: false,
            showImportModal: false,
            showExportModal: false,
            partialLoading: true
        }
        this.headerRef = React.createRef();
        this.interval = null;
    }

    async componentDidMount() {
        await this.props.i18n.loadNamespaces(['client_src_pages_planoAcao_PlanoAcaoPage']);
        this.handleUrlParams();
        window.parent.postMessage({ type: 'focus' }, '*');
        window.addEventListener('resize', this.verifyScreen);
        window.addEventListener('keydown', this.handleKeyDown);
        window.addEventListener('keyup', this.handleKeyUp);
        if (document.querySelector('.listPlanos')) {
            document.querySelector('.listPlanos').addEventListener('scroll', this.handleScroll);
        }
        this.interval = setInterval(this.updateHeaderSize, 10);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.activeTab === 'lista' && document.querySelector('.listPlanos')) {
            let listPlanos = document.querySelector('.listPlanos');

            if (listPlanos) {
                listPlanos.removeEventListener('scroll', this.handleScroll);
                listPlanos.addEventListener('scroll', this.handleScroll);
            }
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.verifyScreen);
        window.removeEventListener('keydown', this.handleKeyDown);
        window.removeEventListener('keyup', this.handleKeyUp);
        if (document.querySelector('.listPlanos')) {
            document.querySelector('.listPlanos').removeEventListener('scroll', this.handleScroll);
        }
    }

    handleKeyDown = (event) => {
        if (event.altKey) {
            this.setState({ isAltKeyPressed: true });
        }
    }

    handleKeyUp = (event) => {
        if (!event.altKey) {
            this.setState({ isAltKeyPressed: false });
        }
    }

    verifyScreen = () => {
        if (this.state.isSmallScreen == LayoutHelper.isSmallScreen()) return;
        this.setState({ isSmallScreen: LayoutHelper.isSmallScreen() }, () => {
            this.updateHeaderSize();
            this.setState({ isSmallScreen: LayoutHelper.isSmallScreen() }, () => {
                if (this.state.showFilters && this.state.isSmallScreen) {
                    this.setState({ showFilters: false });
                }
            });
        });
    }

    updateHeaderSize = () => {
        if (this.headerRef.current) {
            this.setState({ headerSize: this.headerRef.current.clientHeight });
            if (this.interval) clearInterval(this.interval);
        }
    }

    handleUrlParams = () => {
        window.parent.postMessage({ type: 'get_url' }, '*');

        const listenerFunction = async (event) => {
            if (event.data.type === 'return_url') {
                const urlObj = new URL(event.data.url);
                const queryParams = new URLSearchParams(urlObj.search);
                const tipo = queryParams.get('tipo');

                if (tipo && (tipo == "novo" || tipo == "editar")) {
                    this.setState({ showWizard: true });
                } else {
                    await this.getConfigColaborador();
                }

                window.removeEventListener('message', listenerFunction);
            }
        };

        window.addEventListener('message', listenerFunction);
    }

    handleScroll = () => {
        const listPlanosDiv = document.querySelector('.listPlanos');
        if (listPlanosDiv) {
            if (listPlanosDiv.scrollTop + listPlanosDiv.clientHeight >= listPlanosDiv.scrollHeight) {
                if (!this.state.loading && this.state.hasMore && this.state.activeTab === 'lista') {
                    this.loadData(this.state.page + 1);
                }
            }
        }
    }

    async getConfigColaborador() {
        let req = await Sig.request('GET', 'config/colaborador/getPlanoAcaoConfig');

        if (req.status != 200) {
            return toast.error(this.props.i18n.t('client_src_pages_planoAcao_PlanoAcaoPage:erro_carregar_configuracao'));
        }

        let headerReq = await Sig.request('GET', 'planoAcao/getHeaderConfig');

        if (headerReq.status != 200) {
            return toast.error(this.props.i18n.t('client_src_pages_planoAcao_PlanoAcaoPage:erro_carregar_configuracao'));
        }

        this.setState({ showParceiros: headerReq.showParceiros, activeTab: req.tipo_visualizacao, parceirosChecked: req.parceiro && headerReq.showParceiros }, () => {
            if (this.state.activeTab == 'relatorio') {
                this.setState({
                    filter: { tipoData: 'cadastro', dataInicio: moment().subtract(12, 'months').toDate(), dataFim: moment().toDate() }
                }, () => this.loadData(1));
            } else {
                this.loadData(1);
            }
        });
    }

    async setConfigColaborador() {
        let req = await Sig.request('POST', 'config/colaborador/setPlanoAcaoConfig', { tipo_visualizacao: this.state.activeTab, parceiro: this.state.parceirosChecked ? 1 : 0 });

        if (req.status != 200) {
            return toast.error(this.props.i18n.t('client_src_pages_planoAcao_PlanoAcaoPage:erro_salvar_configuracao'));
        }
    }

    async loadData(page = 1) {
        if (this.state.requesting) return;

        this.setState({ requesting: true, partialLoading: true });

        if (this.state.activeTab == 'relatorio') {
            var req = {
                status: 200,
                data: []
            };
        } else if (this.state.activeTab == 'lista') {
            var req = await Sig.request('POST', 'planoAcao/list', { page, limit: 10, filter: JSON.stringify(this.state.filter), parceiro: this.state.parceirosChecked ? 1 : 0 });
        } else {
            return;
        }

        if (this.state.firstLoad) {
            this.setState({ firstLoad: false, loadingHeader: false });
        } else {
            this.setState({ loadingHeader: false });
        }

        if (req.status != 200) {
            this.setState({ loading: false, requesting: false });
            return toast.error(this.props.i18n.t('client_src_pages_planoAcao_PlanoAcaoPage:erro_carregar_dados'));
        }

        if (this.state.activeTab == 'lista') {
            if (req.data.length < 10)
                this.setState({ hasMore: false });
            else
                this.setState({ hasMore: true });
        }

        this.setState(prevState => ({
            data: page === 1 || this.state.activeTab == 'relatorio' ? req.data : [...prevState.data, ...req.data],
            requesting: false,
            page,
            loading: false
        }));
    }

    handleToggleExpand = (advancedFilter) => {
        this.setState({ advancedFilter: advancedFilter });
    }

    filter = async (filter, closeFilter = false) => {
        if (this.state.firstFilterLoad) {
            this.setState({ firstFilterLoad: false });
            return;
        }

        this.setState({ loading: true, filter }, () => {
            this.loadData(1);
            if (closeFilter) this.handleToggleExpand(false);
        });
    }

    handleSearch = async (text) => {
        this.setState({ loading: true, filter: { ...this.state.filter, nome: text } }, () => {
            this.loadData(1);
        });
    }

    countFilters = () => {
        let count = 0;
        for (let key in this.state.filter) {
            if ((this.state.filter[key] && !Array.isArray(this.state.filter[key]))) {
                if (key == 'dataInicio' && this.state.filter['dataFim']) {
                    count++;
                } else if (key == 'dataFim' || key == 'tipoData') {
                    continue;
                } else {
                    count++;
                }
            } else if (Array.isArray(this.state.filter[key]) && this.state.filter[key].length > 0) {
                count++;
            }
        }
        return count;
    }

    renderSwitch() {
        return (
            <div style={{ ...EssentialStyle.rowFlexCenter }}>
                <CustomTooltip tooltip={this.props.i18n.t('client_src_pages_planoAcao_PlanoAcaoPage:tooltip_visualizar_planos_empresa')}>
                    <FontAwesomeIcon icon={faBriefcase} />
                </CustomTooltip>
                <Form.Switch
                    readOnly={true}
                    type="switch"
                    checked={this.state.parceirosChecked}
                    style={{ marginLeft: 3, marginRight: 3 }}
                />
                <CustomTooltip tooltip={this.props.i18n.t('client_src_pages_planoAcao_PlanoAcaoPage:tooltip_visualizar_planos_parceiros')}>
                    <FontAwesomeIcon icon={faHandshake} />
                </CustomTooltip>
            </div>
        )
    }

    renderImportModal = () => {
        if (!this.state.showImportModal) return null;

        return (
            <ModalUploadEtapaPlanoAcao
                closeModal={() => this.setState({ showImportModal: false }, () => { this.loadData() })}
            />
        );
    }

    renderExportModal = () => {
        if (!this.state.showExportModal) return null;

        return (
            <ModalDownloadEtapaPlanoAcao
                closeModal={() => this.setState({ showExportModal: false }, () => { this.loadData() })}
                isSmallScreen={this.state.isSmallScreen}
            />
        );
    }

    renderMassActions = () => {
        return (
            <div className="box-advancedSearch">
                <Form className="w-100">
                     <Form.Group as={Col}>
                        <Form.Label className="form-label-xsm">Ações em Massa</Form.Label>
                        <div style={{ ...EssentialStyle.rowFlexStart, gap: 10 }}>
                            {this.renderImportButton()}
                            {this.renderExportButton()}
                        </div>
                    </Form.Group>
                </Form>
            </div>
        );
    }

    renderExportButton = () => {
        return (
            <DefaultButton
                onClick={() => this.setState({ showExportModal: true })}
                color={Colors.dark}
                leftIcon={<FontAwesomeIcon icon={faDownload} />}
                style={{ height: 24, fontSize: 14, paddingTop: 0 }}
                disabled={this.state.parceirosChecked}
                title={this.props.i18n.t('client_src_pages_planoAcao_PlanoAcaoPage:exportar_planos_acao')}
            />
        );
    }

    renderImportButton = () => {
        return (
            <DefaultButton
                onClick={() => this.setState({ showImportModal: true })}
                leftIcon={<FontAwesomeIcon icon={faUpload} />}
                color={Colors.dark}
                style={{ height: 24, fontSize: 14, paddingTop: 0 }}
                disabled={this.state.parceirosChecked}
                title={this.props.i18n.t('client_src_pages_planoAcao_PlanoAcaoPage:importar_planos_acao')}
            />
        );
    }

    renderHeader = () => {
        const tabs = [
            { name: 'lista', title: this.props.i18n.t('client_src_pages_planoAcao_PlanoAcaoPage:lista') },
            { name: 'relatorio', title: this.props.i18n.t('client_src_pages_planoAcao_PlanoAcaoPage:relatorio') }
        ];

        if (!this.state.loadingHeader) return (
            <div style={{ width: "100%", top: 0, position: "sticky", zIndex: 5 }}>
                <div ref={this.headerRef} style={{ ...EssentialStyle.rowFlexCenter, ...EssentialStyle.card, padding: 10, flexWrap: "wrap" }}>
                    <div style={{ ...EssentialStyle.rowSpaceBetween, width: "100%" }}>
                        <div style={{ ...EssentialStyle.columnStart, gap: 10 }}>
                            <DefaultButton
                                onClick={() => this.setState({ showWizard: true })}
                                leftIcon={<FontAwesomeIcon icon={faPlusSquare} />}
                                disabled={this.state.parceirosChecked}
                                color={Colors.success}
                                tooltip={this.state.parceirosChecked ? this.props.i18n.t('client_src_pages_planoAcao_PlanoAcaoPage:tooltip_ambiente_parceiro') : ''}
                                tooltipPlacement={'bottom'}
                                title={this.state.isSmallScreen ? "" : this.props.i18n.t('client_src_pages_planoAcao_PlanoAcaoPage:plano_acao')}
                            />
                        </div>
                        <div style={{ ...EssentialStyle.rowFlexCenter, gap: 10 }}>
                            <DefaultTableSearch
                                handleSearch={this.handleSearch}
                                onEmpty={() => {
                                    if (this.state.activeTab != 'relatorio') {
                                        this.loadData(1)
                                    }
                                }}
                                toggleExpand={this.handleToggleExpand}
                                appliedFilters={this.countFilters()}
                                text={this.state.filter.nome}
                                advancedFilter={this.state.advancedFilter}
                                type={'planoAcao'}
                            />
                            {!this.state.isSmallScreen && this.state.showParceiros &&
                                <DefaultButton
                                    tooltipPlacement={'left'}
                                    leftIcon={this.renderSwitch()}
                                    color={Colors.secondaryButton}
                                    textColor={Colors.dark}
                                    loading={this.state.loading}
                                    onClick={() => { this.setState({ parceirosChecked: !this.state.parceirosChecked }, () => { this.loadData(1); this.setConfigColaborador(); }) }}
                                />
                            }
                            {!this.state.isSmallScreen &&
                                <Tabs
                                    tabs={tabs}
                                    activeTab={this.state.activeTab}
                                    isSmallScreen={this.state.isSmallScreen}
                                    onTabChange={this.handleTabChange}
                                    tabStyle={{ width: this.state.isSmallScreen ? '32vw' : 90, maxWidth: 100 }}
                                />
                            }
                            <DefaultButton
                                style={{ width: 38, height: 38, marginRight: this.state.isSmallScreen ? 5 : 10 }}
                                fontSize={this.state.isSmallScreen ? 14 : "unset"}
                                color={Colors.secondaryButton}
                                textColor={Colors.dark}
                                link={this.state.activeTab == 'lista' ? 'https://advis.freshdesk.com/support/solutions/articles/63000221651-planos-de-a%C3%A7%C3%A3o' : 'https://advis.freshdesk.com/support/solutions/articles/63000221651-planos-de-a%C3%A7%C3%A3o#relatorios'}
                            />
                        </div>
                    </div>
                    {this.state.isSmallScreen &&
                        <div style={{ ...EssentialStyle.rowSpaceBetween, width: '100%', marginTop: 10 }}>
                            <Tabs
                                tabs={tabs}
                                activeTab={this.state.activeTab}
                                isSmallScreen={this.state.isSmallScreen}
                                onTabChange={this.handleTabChange}
                                tabStyle={{ width: this.state.isSmallScreen ? '32vw' : 90, maxWidth: 100 }}
                            />
                            {this.state.showParceiros &&
                                <DefaultButton
                                    tooltipPlacement={'left'}
                                    leftIcon={this.renderSwitch()}
                                    color={Colors.secondaryButton}
                                    textColor={Colors.dark}
                                    loading={this.state.loading}
                                    onClick={() => { this.setState({ parceirosChecked: !this.state.parceirosChecked }, () => { this.loadData(1); }) }}
                                />
                            }
                        </div>
                    }
                </div>
                <Collapse in={!this.state.isSmallScreen && this.state.isAltKeyPressed && !this.state.advancedFilter}>
                    <div style={{
                        position: "absolute",
                        zIndex: 5,
                        width: "100%",
                    }}
                    >
                        {this.renderMassActions()}
                    </div>
                </Collapse>
                <Collapse in={this.state.advancedFilter}>
                    <div style={{
                        position: "absolute",
                        zIndex: 5,
                        width: "100%",
                    }}
                    >
                        <PlanoAcaoListSearch
                            filterCallback={this.filter}
                            isSmallScreen={this.state.isSmallScreen}
                            type={this.state.activeTab}
                            nome={this.state.filter.nome}
                            advancedFilter={this.state.advancedFilter}
                            parceirosChecked={this.state.parceirosChecked}
                        />
                    </div>
                </Collapse>
            </div>
        );
    }

    renderPlanoAcaoCard(item, index) {
        return <PlanoAcaoCard
            key={item.id}
            plano={item}
            isSmallScreen={this.state.isSmallScreen}
            parceirosChecked={this.state.parceirosChecked}
            onClick={(plano, novaAba = false) => {
                window.parent.postMessage({ type: novaAba ? '_blank' : 'navigate', url: `/planoAcao/detalhar/${plano.codigo}` }, '*');
            }}
        />
    }

    handleTabChange = (tab) => {
        if (this.state.activeTab === tab.name) return;
        this.setState({ activeTab: tab.name, loading: true, hasMore: tab.name == 'lista' ? true : false, firstLoad: true, firstFilterLoad: false, page: 1, data: [] }, () => {
            this.setConfigColaborador();
        });
    }

    renderList() {
        if (this.state.data.length === 0 && !this.state.loading) return this.renderEmpty();

        return (
            <div className={'listPlanos'} style={{ maxHeight: `calc(100vh - ${this.state.headerSize}px - 14px)`, overflowY: 'auto', width: "calc(100% + 7)", margin: '0px -7px', padding: '0px 7px' }}>
                {this.state.data.map((item, index) => this.renderPlanoAcaoCard(item, index))}
                {this.state.requesting && <div style={{ ...EssentialStyle.columnCenter, padding: 10, width: '100%', marginTop: 10 }}><DefaultLoader /></div>}
                {this.state.isSmallScreen && <div style={{ height: '7rem', width: '100%', marginTop: 10 }} />}
            </div>
        );
    }

    renderEmpty() {
        return (
            <div style={{ padding: 50, backgroundColor: 'white', marginTop: 20, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', boxShadow: Colors.boxShadow, border: '0.3px solid lightgrey', borderRadius: 5, textAlign: 'center' }}>
                <h5>{this.props.i18n.t('client_src_pages_planoAcao_PlanoAcaoPage:nenhum_plano_acao')}</h5>
                <div style={{ marginTop: 20 }} />
                <DefaultButton
                    onClick={() => this.setState({ showWizard: true })}
                    leftIcon={<FontAwesomeIcon icon={faPlusSquare} />}
                    color={Colors.success}
                    title={this.state.isSmallScreen ? "" : this.props.i18n.t('client_src_pages_planoAcao_PlanoAcaoPage:plano_acao')}
                />
            </div>
        )
    }

    addCallback = async () => {
        this.setState({ editId: null, showWizard: false, showEdit: false }, this.loadData);
    }

    editCallback = async (id) => {
        this.setState({ editId: id }, () => this.setState({ showEdit: true }));
    }

    renderContent() {
        if (this.state.loading || !this.state.filter) return <LoadingPage height={`calc(100vh - ${this.state.headerSize}px - 14px)`} />

        if (this.state.activeTab === 'lista') return this.renderList();
        if (this.state.activeTab === 'relatorio') {
            return (
                <PlanoAcaoRelatorio
                    loading={this.state.loading}
                    isSmallScreen={this.state.isSmallScreen}
                    headerSize={this.state.headerSize}
                    parceiro={this.state.parceirosChecked}
                    filter={this.state.filter}
                />
            );
        }
    }

    render() {
        if (this.state.showWizard) return <AddPlanoAcao addCallback={this.addCallback} />

        return (
            <div style={{ padding: 7 }}>
                {this.renderHeader()}
                {this.renderContent()}
                {this.renderImportModal()}
                {this.renderExportModal()}
            </div>
        );
    }
}

export default withTranslation()(PlanoAcaoPage);