import React from "react";
import EssentialStyle from "../../../../../../style/EssentialStyle";
import UserAvatar from "../../../../../tools/UserAvatar";
import Sig from "../../../../../../api/Sig";
import Colors from "../../../../../../constants/Colors";
import moment from "moment";
import DefaultLoader from "../../../../../tools/DefaultLoader";
import DataHelper from "../../../../../../helper/DataHelper";

export default class AtaReuniaoTopicoTarefaHistorico extends React.Component {
    state = {
        historico: [],
        loading: true
    }

    async componentDidMount() {
        await this.loadData();
    }

    async componentDidUpdate(prevProps) {
        if (prevProps.reload !== this.props.reload) {
            if(this.props.reload) await this.loadData();
        }
    }

    loadData = async () => {
        this.setState({ loading: true });

        const response = await Sig.request('GET', `ataReuniao${this.props.hash ? "Externa" : ""}/getHistoricoTarefaTopico`, { idTarefa: this.props.id, hash: this.props.hash });    
        let historico = [];

        if(response && response.status === 200){
            historico = response?.historico || [];
        }

        this.setState({ historico }, () => { this.setState({ loading: false }) });
        if(this.props.reloadedCallback) this.props.reloadedCallback();
    }

    render() {
        if(this.state.loading) return <div style={{ ...EssentialStyle.columnCenter, minHeight: 100, width: '100%' }}><DefaultLoader /></div>;

        return (
            <div style={{ ...EssentialStyle.columnStart, width: '100%', height: 'auto', backgroundColor: Colors.white}}>
                <div style={{ ...EssentialStyle.columnStart, width: '100%', height: 'auto' }}>
                    {this.state.historico?.length > 0 ? this.state.historico.map((h, index) => {
                        var responsavel = h.responsavel;
                        if(!h.coluna) return null;
                        var coluna = h.coluna;
                        var valor = h.valor ? h.valor : null;
                        var valor_anterior = h.valor_anterior ? h.valor_anterior : null;
                        

                        return (
                        <div className="historicoTarefa" key={h.id} style={{ ...EssentialStyle.columnStart, width: '100%', borderBottom: `1px solid ${Colors.homePage.extraLightGrey}`, padding: "10px 5px" }}>
                            <div style={{ ...EssentialStyle.columnCenter, width: '100%', height: 'auto' }}>
                                <div style={{ ...EssentialStyle.rowSpaceBetween, width: '100%', marginBottom: 8 }}>
                                    <div style={{ width: 'calc(100% - 125px)' }}>
                                        { responsavel ? <UserAvatar user={responsavel} showNameAdaptative showName /> : <UserAvatar nome={h.responsavel.nome} showNameAdaptative showName /> }
                                    </div>
                                    <span style={{display: "flex", width: "125px", justifyContent: "flex-end", fontSize: "0.7rem", fontWeight: "bold", color: Colors.homePage.grey}}>{moment(h.data).format("D/MM/YYYY [às] HH:mm")}</span>
                                </div>
                                <div style={{...EssentialStyle.columnStart, placeSelf: "flex-start"}}>
                                    {coluna ? 
                                        <div>
                                            <span style={{fontWeight: "bold"}}>{coluna}</span>
                                            {valor && valor_anterior ? <span> alterado de:</span> : null}
                                        </div>
                                    : null}
                                    {valor && valor_anterior ?
                                        <>
                                            <span style={{textDecorationLine: "underline", paddingLeft: 10}}>
                                                {coluna == 'Descrição' ? DataHelper.removeHtmlAndReplaceListItems(valor_anterior) : valor_anterior}
                                            </span> 
                                            <span> 
                                                {"Para:"} 
                                            </span>                                        
                                            <span style={{textDecorationLine: "underline", paddingLeft: 10}}>
                                                {coluna == 'Descrição' ? DataHelper.removeHtmlAndReplaceListItems(valor) : valor}
                                            </span>
                                        </>
                                    : 
                                        null
                                    }
                                </div>
                            </div>
                        </div>);
                    }):
                        <div style={{ ...EssentialStyle.rowFlexCenter, width: '100%', height: 'auto', padding: 15, backgroundColor: Colors.white }}>
                            Nenhum histórico disponível
                        </div>
                    }
                </div>
            </div>
        )
    }
}