import React, { forwardRef } from "react";
import './ModalResumoNodeQuestionario.css';
import DiagnosticoHelper from "../../../helper/diagnostico/DiagnosticoHelper";
import Modal from "../Modal";
import EssentialStyle from "../../../style/EssentialStyle";
import Colors from "../../../constants/Colors";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTable, faChevronLeft, faLineChart, faCertificate, faLightbulb } from "@fortawesome/free-solid-svg-icons";
import DefaultButton from "../DefaultButton";
import DefaultLoader from "../DefaultLoader";
import IdiomaHelper from "../../../helper/IdiomaHelper";
import IndicadorReferenciaCard from "../../modules/diagnostico/Indicadores/IndicadorReferenciaCard/IndicadorReferenciaCard";
import EllipsisText from "../EllipsisText";
import PlanoAcaoCard from "../../modules/planoAcao/PlanoAcaoCard/PlanoAcaoCard";
import PlanoAcaoCardRelatorio from "../../modules/planoAcao/PlanoAcaoRelatorio/PlanoAcaoCardRelatorio";
import LayoutHelper from "../../../helper/LayoutHelper";
import { Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import moment from "moment";
import StarButton from "../../modules/diagnostico/StarButton/StarButton";


export default class ModalResumoNodeQuestionario extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            miniLoading: false,
            idNode: this.props.idNode || null,
            idAvaliacao: this.props.idAvaliacao || null,
            node: {},
            lang: IdiomaHelper.getIdioma(),
            isSmallScreen: false,

            data_processo: this.props.data_processo || null,
            data_inicio_periodo: this.props.data_inicio_periodo || null,
            data_fim_periodo: this.props.data_fim_periodo || null,
            frequencia_processo: this.props.frequencia_processo || null,

        };
        this.modalRef = React.createRef();
    }

    async componentDidMount() {
        window.addEventListener('resize', this.verifyScreen);
        await this.loadData(true);
        this.verifyScreen();
    }

    async componentDidUpdate(prevProps) {
        if (prevProps.idNode !== this.props.idNode || prevProps.idAvaliacao !== this.props.idAvaliacao) {
            this.setState({
                idNode: this.props.idNode,
                idAvaliacao: this.props.idAvaliacao,
            }, () => {
                this.loadData(true);
            })
        }

        if (prevProps.data_processo !== this.props.data_processo && prevProps.data_processo !== null) {
            this.setState({ data_processo: this.props.data_processo }, () => {
                this.loadData(false);
            })
        }
    }
    
    componentWillUnmount() {
        window.removeEventListener('resize', this.verifyScreen);
    }

    verifyScreen = () => {
        this.setState({ 
            isSmallScreen: LayoutHelper.isSmallScreen(),
        });
    }

    async loadData(viewLoading = true) {

        if (!this.state.idNode) this.setState({ node: {} });

        this.setState({ 
            loading: viewLoading ? true : false,
            miniLoading: true 
        });

        let { node, data_inicio_periodo, data_fim_periodo, frequencia_processo, data_processo } = await DiagnosticoHelper.getResumoNodeQuestionario(this.state.idNode, this.state.idAvaliacao, this.state.data_processo);

        this.setState({ node, data_inicio_periodo, data_fim_periodo, frequencia_processo, data_processo, loading: false, miniLoading: false });
    }

    message() {
        const message = IdiomaHelper.getStruct({
            'pt_br': { 
                detalhamento: 'Detalhamento da Resposta',
                resultado: 'Resultado',
                empty: 'Nenhum indicador vinculado',
                processo: 'Indicadores de Processo',
                performance: 'Indicadores de Performance',
                filhos: 'Itens Filhos',
                emptyFilhos: 'Nenhum item filho',
                metricaProcesso: 'Assumindo a Performance Média do(s) Indicador(es) de Processo',
                metricaMedia: 'Assumindo a Performance Média do(s) item(s) filho(s), proporcionalmente ao número de Perguntas vinculadas',
                metricaResposta: 'Assumindo a Performance da Resposta',
                back: 'Voltar',
                planosAcao: 'Planos de Ação',
                fechar: 'Fechar',
            },
            'en': { 
                detalhamento: 'Response Details',
                resultado: 'Result',
                empty: 'No indicators linked',
                processo: 'Process Indicators',
                performance: 'Performance Indicators',
                filhos: 'Child Items',
                emptyFilhos: 'No child items',
                metricaProcesso: 'Assuming the Average Performance of the Process Indicator(s)',
                metricaMedia: 'Assuming the Average Performance of the child item(s), proportionally to the number of linked Questions',
                metricaResposta: 'Assuming the Response Performance',
                back: 'Back',
                planosAcao: 'Action Plans',
                fechar: 'Close',
            },
            'es': { 
                detalhamento: 'Detalles de la Respuesta',
                resultado: 'Resultado',
                empty: 'Ningún indicador vinculado',
                processo: 'Indicadores de Proceso',
                performance: 'Indicadores de Rendimiento',
                filhos: 'Elementos secundarios',
                emptyFilhos: 'Ningún elemento secundario',
                metricaProcesso: 'Asumiendo el Rendimiento Promedio del(os) Indicador(es) de Proceso',
                metricaMedia: 'Asumiendo el Rendimiento Promedio del(os) elemento(s) secundario(s), proporcionalmente al número de Preguntas vinculadas',
                metricaResposta: 'Asumiendo el Rendimiento de la Respuesta',
                back: 'Volver',
                planosAcao: 'Planes de Acción',
                fechar: 'Cerrar',
            },
        });

        return message[this.state.lang];
    }

    renderTitle() {

        let { node } = this.state;

        return (
            <div style={{...EssentialStyle.rowSpaceBetween, width: '100%'}}>
                <div style={{fontSize: 22, fontWeight: 600, color: Colors.homePage.grey, marginBottom: 10}}>
                    <FontAwesomeIcon icon={faTable} style={{ marginRight: 10 }} />
                    <span style={{ fontStyle: 'italic' }}>({node?.coluna?.descricao?.find(c => c.id_idioma === IdiomaHelper.getSiglaId(this.state.lang))?.descricao})</span>
                    <span> {node?.texto?.find(t => t.id_idioma === IdiomaHelper.getSiglaId(this.state.lang))?.descricao}</span>
                </div>

                {this.state.idAvaliacao && <StarButton idNode={this.state.idNode} idAvaliacao={this.state.idAvaliacao} isDisabled={false} onChange={(status) => {this.props.changeEstrela && this.props.changeEstrela(this.state.idNode, this.state.idAvaliacao, status)}}/>}
            </div>
        )
    }

    renderDataPicker = () => {

        const DatePickerComponent = forwardRef(({ value, onClick }, ref) => (
            <Form.Control
                onChange={() => { }}
                onClick={onClick}
                ref={ref}
                style={{ 
                    cursor: frequencia == 0 || frequencia == null ? 'not-allowed' : 'pointer',
                    fontSize: 14, 
                    color: Colors.dark, 
                    fontWeight: 'bold', 
                    boxShadow: 'none', 
                    backgroundColor: Colors.homePage.lightGrey, 
                    padding: 1, 
                    height: '100%', 
                    minWidth: '100%', 
                    textAlign: 'center', 
                    zIndex: 1 
                }}
                value={value} >
            </Form.Control>
        ));

        let data_processo = this.state.data_processo;
        let frequencia = this.state.frequencia_processo;
        let data_inicio_periodo = this.state.data_inicio_periodo;
        let data_fim_periodo = this.state.data_fim_periodo;

        return (
            <div className="infos" style={{...EssentialStyle.rowFlexEnd, gap: 10}}>
                <div style={{
                    ...EssentialStyle.rowFlexCenter,
                    width: '5rem',
                }}>
                    <DatePicker
                        selected={data_processo ? moment(data_processo).toDate() : null}
                        type="date"
                        onChange={(date) => {
                            this.setState({ data_processo: moment(date) }, () => {
                                this.loadData(false);
                            });
                        }}
                        dateFormat="MM/yyyy"
                        showMonthYearPicker
                        customInput={<DatePickerComponent />}
                        popperPlacement="top-start"
                        disabled={frequencia == 0 || frequencia == null}
                        maxDate={new Date(data_fim_periodo)}
                        minDate={new Date(data_inicio_periodo)}
                    />
                </div>
            </div>
            
        )
    }

    renderBody() {

        let { node } = this.state;

        const renderDetalhamentoResposta = () => {

            let hasIndicadorProcesso = node?.resultados?.hasIndicadorProcesso;
            let hasFilhos = node?.filhos?.length > 0;

            return (
                <div style={{...EssentialStyle.columnStart, width: '100%', gap: 5}}>
                    
                    <div className="labelTitle">{this.message().detalhamento}</div>

                    <div style={{ ...EssentialStyle.rowFlexStart, width: '100%', height: 50, gap: 10 }}>
                        <div
                            className="value-resposta"
                            style={{
                                backgroundColor: node.resultados.cor,
                                cursor: 'pointer',
                                flexShrink: 0, // Impedir que o elemento encolha
                            }}
                        >
                            {this.message().resultado}: {parseFloat(node.resultados.valor).toFixed(1)}%
                        </div>

                        <div style={{ flex: 1, fontSize: '0.8rem' }}>
                            {hasIndicadorProcesso ? 
                                this.message().metricaProcesso 
                                : 
                                (hasFilhos ? 
                                    this.message().metricaMedia
                                    :
                                    this.message().metricaResposta
                                )
                            }
                        </div>

                        {this.renderDataPicker()}
                    </div>
                </div>
            )
        }

        const renderFilhosNode = () => {
            const { node } = this.state;
            const { changeNode } = this.props;
        
            if (!node?.filhos?.length && !node?.id_node_pai) return null;
        
            return (
                <div style={{ ...EssentialStyle.columnStart, width: '100%', height: '100%', gap: 5 }}>
                    <div className="labelTitle">
                        {node?.id_node_pai && this.props.changeNode && (
                            <FontAwesomeIcon 
                                icon={faChevronLeft} 
                                style={{ cursor: 'pointer', marginRight: 5 }} 
                                onClick={() => this.props.changeNode && this.props.changeNode(node.id_node_pai)} />
                        )}
                        {node?.filhos[0] ? node?.filhos[0].coluna?.descricao?.find(c => c.id_idioma === IdiomaHelper.getSiglaId(this.state.lang))?.descricao : this.message().back}
                    </div>
                    <div className="listFilhos">
                        {node?.filhos?.length > 0 ? node.filhos.map((filho) => (
                            <div
                                key={`key-item-filho-${filho.id}`}
                                className="itemFilho"
                                onClick={() => changeNode && changeNode(filho.id)}
                                style={{ cursor: changeNode ? 'pointer' : 'default' }}
                            >
                                <div style={{ width: this.state.idAvaliacao ? 'calc(100% - 50px)' : '100%', height: '100%'}}>
                                    <EllipsisText
                                        text={filho.texto.find(t => t.id_idioma === IdiomaHelper.getSiglaId(this.state.lang))?.descricao}
                                    />
                                </div>
                                {this.state.idAvaliacao && (
                                    <div className="value-resposta" style={{ backgroundColor: filho.resultados.cor }}>
                                        {parseFloat(filho.resultados.valor).toFixed(1)}%
                                    </div>
                                )}
                            </div>
                        )) : (
                            <div
                                key={`key-item-filho-empty`}
                                className="itemFilho"
                            >
                                {this.message().emptyFilhos}
                            </div>
                        )}
                    </div>
                </div>
            );
        };

        const renderIndicadoresNode = () => {

            let indicadoresProcesso = node?.indicadoresReferencia?.filter(i => parseInt(i.tipo_vinculo) === 1);
            let indicadoresPerformance = node?.indicadoresReferencia?.filter(i => parseInt(i.tipo_vinculo) === 0);

            const renderIndicadorProcesso = () => {
                return (
                    <div style={{...EssentialStyle.columnStart, width: '100%', height: '100%', gap: 5}}>
                        
                        <div className="labelTitle">
                            <FontAwesomeIcon icon={faCertificate}/>
                            {this.message().processo}
                        </div>
                        
                        {indicadoresProcesso.map((indicadorReferencia, index) => {
                            return (
                                <IndicadorReferenciaCard
                                    key={`indicadorpainel-${indicadorReferencia.indicador.id}`}
                                    periodoIndicadorReferencia={indicadorReferencia}
                                    lang={this.state.lang}
                                    isVinculoNode={true}
                                    showGroupName={true}
                                    style={{ width: 'calc(100% - 10px)', marginRigth: 5, marginLefth: 5 }}
                                />
                            )
                        })}
                    </div>
                )
            }

            const renderIndicadorPerformance = () => {
                return (
                    <div style={{...EssentialStyle.columnStart, width: '100%', height: '100%', gap: 5}}>

                        <div className="labelTitle">
                            <FontAwesomeIcon icon={faLineChart}/>
                            {this.message().performance}
                        </div>

                        {indicadoresPerformance.map((indicadorReferencia, index) => {
                            return (
                                <IndicadorReferenciaCard
                                    key={`indicadorpainel-${indicadorReferencia.indicador.id}`}
                                    periodoIndicadorReferencia={indicadorReferencia}
                                    lang={this.state.lang}
                                    isVinculoNode={true}
                                    showGroupName={true}
                                    style={{ width: 'calc(100% - 10px)', marginRigth: 5, marginLefth: 5 }}
                                />
                            )
                        })}
                    </div>
                )
            }

            if (indicadoresProcesso?.length == 0 && indicadoresPerformance?.length == 0) return (
                <div style={{...EssentialStyle.rowCenter, width: '100%', height: '100%'}}>
                    <span className="labelEmpty">{this.message().empty}</span>
                </div>
            );

            return (
                <div style={{...EssentialStyle.columnStart, width: '100%', gap: 15}}>
                    {indicadoresProcesso?.length > 0 && renderIndicadorProcesso()}
                    {indicadoresPerformance?.length > 0 && renderIndicadorPerformance()}
                </div>
            )
        }

        const renderIndicadoresNodeRespota = () => {

            let indicadoresProcesso = node?.indicadoresProcesso;
            let indicadoresPerformance = node?.indicadoresReferencia?.filter(i => parseInt(i.tipo_vinculo) === 0);

            const renderIndicadorProcesso = () => {

                return (
                    <div style={{...EssentialStyle.columnStart, width: '100%', height: '100%', gap: 5}}>
                        
                        <div className="labelTitle">
                            <FontAwesomeIcon icon={faCertificate}/>
                            {this.message().processo}
                        </div>
                        
                        {indicadoresProcesso.map((indicadorReferencia, index) => {

                            let faixas = indicadorReferencia?.faixas?.map(f => {
                                return {
                                    cor: f.cor,
                                    min: f?.indicadorFaixa?.min,
                                    max: f?.indicadorFaixa?.max,
                                    descricao: f.textos.find(d => d.id_idioma === IdiomaHelper.getSiglaId(this.state.lang))?.texto,
                                }
                            });

                            return (
                                <IndicadorReferenciaCard
                                    key={`indicadorpainel-${indicadorReferencia.indicador.id}`}
                                    style={{ width: 'calc(100% - 10px)', marginRigth: 5, marginLefth: 5 }}
                                    periodoIndicadorReferencia={indicadorReferencia}
                                    resultados={true}
                                    lang={this.state.lang}
                                    isVinculoNode={true}
                                />
                            )
                        })}
                    </div>
                )
            }

            const renderIndicadorPerformance = () => {
                return (
                    <div style={{...EssentialStyle.columnStart, width: '100%', height: '100%', gap: 5}}>

                        <div className="labelTitle">
                            <FontAwesomeIcon icon={faLineChart}/>
                            {this.message().performance}
                        </div>

                        {indicadoresPerformance.map((indicadorReferencia, index) => {
                            return (
                                <IndicadorReferenciaCard
                                    key={`indicadorpainel-${indicadorReferencia.indicador.id}`}
                                    periodoIndicadorReferencia={indicadorReferencia}
                                    lang={this.state.lang}
                                    showGroupName={true}
                                    isVinculoNode={true}
                                    style={{ width: 'calc(100% - 10px)', marginRigth: 5, marginLefth: 5 }}
                                />
                            )
                        })}
                    </div>
                )
            }

            if (indicadoresProcesso?.length == 0 && indicadoresPerformance?.length == 0) return (
                null
            );

            return (
                <div style={{...EssentialStyle.columnStart, width: '100%', gap: 15}}>
                    {indicadoresProcesso?.length > 0 && renderIndicadorProcesso()}
                    {indicadoresPerformance?.length > 0 && renderIndicadorPerformance()}
                </div>
            )
        }

        const renderPlanosAcao = () => {

            let planosAcao = node?.planosAcao;

            const renderPlanoAcaoCard = (item, index) => {
                return <PlanoAcaoCardRelatorio
                    key={item.id}
                    plano={item}
                    isSmallScreen={this.state.isSmallScreen}
                    parceirosChecked={true}
                    noMargin={true}
                    displayEtapas={false}
                    onClick={(codigo, novaAba = true) => {
                        window.parent.postMessage({ type: novaAba ? '_blank' : 'navigate', url: `/planoAcao/detalhar/${codigo}` }, '*');
                    }}
                />
            }

            if (!planosAcao?.length) return null;
            return (
                <div style={{...EssentialStyle.columnStart, width: '100%', height: '100%', gap: 5}}>
                    <div className="labelTitle">
                        <FontAwesomeIcon icon={faLightbulb}/>
                        {this.message().planosAcao}
                    </div>
                    {planosAcao.map((item, index) => renderPlanoAcaoCard(item, index))}
                </div>
            )
        }

        return (
            <div className="bodyNode">
                {this.state.idAvaliacao && renderDetalhamentoResposta()}
                {renderFilhosNode()}
                {this.state.idAvaliacao ? renderIndicadoresNodeRespota() : renderIndicadoresNode()}
                {this.state.idAvaliacao && renderPlanosAcao()}
            </div>
        )
    }

    renderFooter() {
        let { node } = this.state;

        return (
            <div style={{...EssentialStyle.rowSpaceBetween, width: '100%', marginTop: 10}}>
                <span className="hiddenID">{node?.id}</span>
                <DefaultButton 
                    title={this.message().fechar}
                    color={Colors.dark}
                    textColor={Colors.white}
                    onClick={() => this.props.closeModal()}
                />
            </div>
        )
    }

    renderContentModal() {
        return (
            <div style={{...EssentialStyle.columnStart, justifyContent: 'start', width: '100%', flex: 1, overflowY: 'auto'}}>
                {this.renderTitle()}
                {this.renderBody()}
                {this.renderFooter()}
            </div>
        )
    }

    renderLoading() {
        return (
            <div style={{...EssentialStyle.rowCenter, width: '100%', height: '100%'}}>
                <DefaultLoader />
            </div>
        )
    }

    render() {
        return (
            <Modal 
                className={"ModalResumoNodeQuestionario"}
                style={{ width: '90vw'}}
                ref={this.modalRef}
            >
                {this.state.miniLoading && <div className="miniLoading"><DefaultLoader size={15}/></div>}
                {this.state.loading ? this.renderLoading() : this.renderContentModal()}
            </Modal>
        )
    }

}