import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import DefaultLoader from "../../../../tools/DefaultLoader";
import LayoutHelper from "../../../../../helper/LayoutHelper";
import Colors from "../../../../../constants/Colors";
import './ModalIndicadoresAvaliacao.css';
import IndicadoresList from "./IndicadoresList";

import { withTranslation } from 'react-i18next';

class ModalIndicadoresAvaliacao extends React.Component {
    state = {
        loading: true,
    }

    async componentDidMount() {
        await this.props.i18n.loadNamespaces(['client_src_components_modules_diagnostico_Avaliacao_Aplicacao_ModalIndicadoresAvaliacao']);
        this.setState({ loading: false });
    }

    renderLoadingModal() {
        return (
            <div className="loading-modalIndicadores">
                <DefaultLoader/>
            </div>
        )
    }

    renderHeaderModal() {
        return (
            <div className="header-modalIndicadores-content">
                <div className="header-modalIndicadores-title-objetivo">{this.props.node?.descricao}</div>
            </div>
        )
    }

    renderCardsIndicadores() {
        var indicadoresPerformance = this.props.node?.indicadoresVinculados?.filter(indicador => indicador.tipo ==  0);
        var indicadoresProcesso = this.props.node?.indicadoresVinculados?.filter(indicador => indicador.tipo ==  1);

        return (
            <div className="content-modalIndicadores-container">
                {
                    indicadoresPerformance.length > 0 &&
                    <div key={`indicadores-performance`} className="content-modalIndicadores-wrapper">
                    <div className="line" style={{backgroundColor: Colors.homePage.line}}></div>

                    <div className="header-modalIndicadores-title">
                        {this.props.t('client_src_components_modules_diagnostico_Avaliacao_Aplicacao_ModalIndicadoresAvaliacao:indicadoresPerformance')}
                    </div>
                        
                    <div className="content-modalIndicadores-content">
                        <IndicadoresList indicadores={indicadoresPerformance} />
                    </div>
                    </div>
                }
                {
                    indicadoresProcesso.length > 0 &&
                    <div key={`indicadores-processo`} className="content-modalIndicadores-wrapper">
                        <div className="line" style={{backgroundColor: Colors.homePage.line}}></div>

                        <div className="header-modalIndicadores-title">
                            {this.props.t('client_src_components_modules_diagnostico_Avaliacao_Aplicacao_ModalIndicadoresAvaliacao:indicadoresProcesso')}
                        </div>
                            
                        <div className="content-modalIndicadores-content">
                            <IndicadoresList indicadores={indicadoresProcesso} />
                        </div>
                    </div>
                }
            </div>
        )
    }

    renderDetailing() {
        return (
            <div className={ LayoutHelper.isSmallScreen() ? "modalIndicadores-mobile" : "modalIndicadores" } style={{boxShadow: Colors.boxShadow}}>
                <div className="modalIndicadores-content">
                    <div 
                        onClick={() => { this.props.closeModal() }} 
                        className="close-button" boxShadow={Colors.boxShadow}>
                        <FontAwesomeIcon style={{ fontSize: 20, color: Colors.dark }} icon={faTimes} />
                    </div>

                    { this.state.loading && this.renderLoadingModal()}
                    {!this.state.loading && this.renderHeaderModal()}
                    {!this.state.loading && this.renderCardsIndicadores()}

                </div>
            </div>
        )
    }

    render() {
        return this.renderDetailing();
    }
}

export default withTranslation()(ModalIndicadoresAvaliacao);