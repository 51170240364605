import React from "react";

import './RetrospectivaShare2024.css';
import EssentialStyle from "../../../../style/EssentialStyle";
import Constants from '../../../../constants/Api';
import Colors from "../../../../constants/Colors";
import moment from 'moment';
import DefaultButton from '../../../tools/DefaultButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';

const gateway = Constants.getSigEndPoint();

export default class RetrospectivaShare2024 extends React.Component {
    state = {
        arquetipo: this.props.arquetipo,
        screenHeight: this.props.screenHeight,
        isSmallScreen: this.props.isSmallScreen
    }

    async componentDidUpdate(prevProps) {
        if (prevProps.arquetipo !== this.props.arquetipo) {
            this.setState({ arquetipo: this.props.arquetipo });
        }

        if (prevProps.screenHeight !== this.props.screenHeight) {
            this.setState({ screenHeight: this.props.screenHeight });
        }

        if (prevProps.isSmallScreen !== this.props.isSmallScreen) {
            this.setState({ isSmallScreen: this.props.isSmallScreen });
        }
    }

    renderLeft() {
        return (
            <div
                style={{
                    ...EssentialStyle.columnCenter,
                    alignItems: 'center',
                    width: this.state.isSmallScreen ? '100%' : '50%',
                    height: '100%'
                }}
            >
                <img
                    src={`${gateway}/img/retrospectiva/perfil/${this.state?.arquetipo?.image}`}
                    alt="Imagem do Arquetipo"
                    style={{
                        height: this.state.screenHeight * 0.5
                    }}
                />
            </div>
        );
    }

    renderRight() {
        return (
            <div
                style={{
                    ...EssentialStyle.columnCenter,
                    alignItems: 'center',
                    width: this.state.isSmallScreen ? '100%' : '50%',
                    height: '100%',
                    paddingBottom: this.state.isSmallScreen ? 50 : 0
                }}
            >
                <span
                    className="retro-default-text"
                    style={{
                        fontSize: '2rem',
                        textAlign: 'left',
                        width: '100%',
                    }}
                >
                    Seu perfil é de <b>{this.state?.arquetipo?.nome}</b>
                </span>
                <span
                    className="retro-default-text"
                    style={{
                        fontSize: this.state.isSmallScreen ? '1.1rem' : '1.4rem',
                    }}
                >
                    {this.state?.arquetipo?.descricao}
                </span>
            </div>
        );
    }

    renderArquetipo() {
        return (
            <div
                style={{
                    ...EssentialStyle.columnCenter,
                    width: '100%',
                    height: '100%'
                }}
            >
                <div
                    style={{
                        ...EssentialStyle.rowSpaceBetween,
                        flexDirection: this.state.isSmallScreen ? 'column-reverse' : 'row',
                        width: '100%',
                        height: this.state.isSmallScreen ? '100%' : '65%',
                        paddingLeft: this.state.isSmallScreen ? 10 : '30px',
                        paddingRight: this.state.isSmallScreen ? 10 : '30px',
                        paddingTop: this.state.isSmallScreen ? 80 : 0
                    }}
                >
                    {this.renderLeft()}
                    {this.renderRight()}
                </div>
            </div>
        );
    }

    render() {
        return (
            <div
                style={{
                    ...EssentialStyle.columnCenter,
                    width: '100%',
                    height: this.state.isSmallScreen ? 'auto' : this.state.screenHeight
                }}
            >
                {this.renderArquetipo()}
            </div>
        );
    }
}