import React from "react";

import './RetrospectivaInteracoes2024.css';
import EssentialStyle from "../../../../style/EssentialStyle";
import Constants from '../../../../constants/Api';
import Colors from "../../../../constants/Colors";
import moment from 'moment';
import UserAvatar from '../../../tools/UserAvatar';

const gateway = Constants.getSigEndPoint();

export default class RetrospectivaInteracoes2024 extends React.Component {
    state = {
        interacoes: this.props.interacoes,
        screenHeight: this.props.screenHeight,
        isSmallScreen: this.props.isSmallScreen
    }

    async componentDidUpdate(prevProps) {
        if (prevProps.interacoes !== this.props.interacoes) {
            this.setState({ interacoes: this.props.interacoes });
        }

        if (prevProps.screenHeight !== this.props.screenHeight) {
            this.setState({ screenHeight: this.props.screenHeight });
        }

        if (prevProps.isSmallScreen !== this.props.isSmallScreen) {
            this.setState({ isSmallScreen: this.props.isSmallScreen });
        }
    }

    renderTop() {
        return (
            <div
                style={{
                    ...EssentialStyle.rowFlexStart,
                    flexDirection: this.state.isSmallScreen ? 'column-reverse' : 'row',
                    alignItems: 'center',
                    width: '100%',
                    height: this.state.isSmallScreen ? 'auto' : '20%'
                }}
            >
                <span
                    className="retro-default-text"
                    style={{
                        width: this.state.isSmallScreen ? '100%' : '60%',
                        fontWeight: '400'
                    }}
                >
                    Nada como construir e crescer juntos! Em 2024, você participou de {this.state?.interacoes?.total} interações significativas com sua equipe aqui no SIG, colaborando para a superação dos desafios. Equipe forte, resultados extraordinários!
                </span>
                <div
                    style={{
                        ...EssentialStyle.columnStart,
                        alignItems: 'flex-end',
                        width: this.state.isSmallScreen ? '100%' : '40%',
                        marginTop: this.state.screenHeight * 0.10,
                        marginBottom: this.state.isSmallScreen ? 50 : 0,
                    }}
                >
                    <span style={{ fontSize: '6rem', fontWeight: '600' }}>{this.state?.interacoes?.total}</span>
                    <span style={{ fontSize: this.state.isSmallScreen ? '4rem' : '6rem', fontWeight: '100', backgroundColor: '#A22C2D', paddingLeft: 20, paddingRight: 20 }}>Interações</span>
                    <span style={{ fontSize: '2rem', fontWeight: '300' }}>com colaboradores</span>
                </div>
            </div>
        );
    }

    renderBottom() {
        return (
            <div
                style={{
                    ...EssentialStyle.columnStart,
                    alignItems: this.state.isSmallScreen ? 'center' : 'flex-start',
                    height: this.state.isSmallScreen ? '100%' : '80%',
                    width: '100%',
                    fontSize: '6.5rem',
                    fontWeight: '200',
                    marginTop: '40px'
                }}
            >
                <span style={{ fontWeight: '600', fontSize: '1.5rem', marginBottom: 30 }}>Top 3 colaboradores interagidos:</span>
                {this.renderColaboradores()}
            </div>
        );
    }

    renderColaboradores() {
        if (!this.state.interacoes?.colaboradores) return null;

        return this.state.interacoes.colaboradores.map((colaborador, index) => {
            // the smaller the index the bigger the size
            let sizeBasedOnIndex = (1 - (index * 0.1)) * (this.state.screenHeight * 0.185);
            let isEven = index % 2 === 0;

            let styleAvatar = {
                width: this.state.isSmallScreen ? '100%' : '90%',
                marginBottom: !isEven && !this.state.isSmallScreen ? 20 : 10
            };

            if (!isEven && !this.state.isSmallScreen) {
                styleAvatar = { ...EssentialStyle.rowFlexEnd, ...styleAvatar };
            } else {
                styleAvatar = { ...EssentialStyle.rowFlexStart, ...styleAvatar };
            }

            if(this.state.isSmallScreen) {
                styleAvatar = { ...styleAvatar, height: sizeBasedOnIndex * 1.2 };
            }

            return (
                <div style={styleAvatar}>
                    <div style={{ ...EssentialStyle.rowFlexStart }}>
                        <UserAvatar id={colaborador.id} size={sizeBasedOnIndex} />
                        <span style={{ fontSize: this.state.isSmallScreen ? (sizeBasedOnIndex * 0.2) : (sizeBasedOnIndex * 0.3), marginLeft: sizeBasedOnIndex * 0.1, fontWeight: '600' }}>{colaborador.nome}</span>
                        <span style={{ fontSize: this.state.isSmallScreen ? (sizeBasedOnIndex * 0.2) : (sizeBasedOnIndex * 0.3), marginLeft: sizeBasedOnIndex * 0.1, fontWeight: '200' }}>{colaborador.ocorrencias}</span>
                    </div>
                </div>
            );
        });
    }

    renderInteracoes() {
        return (
            <div
                style={{
                    ...EssentialStyle.columnCenter,
                    width: '100%',
                    height: '100%'
                }}
            >
                <div
                    style={{
                        ...EssentialStyle.columnCenter,
                        width: '100%',
                        height: this.state.isSmallScreen ? '100%' : '65%',
                        paddingLeft: this.state.isSmallScreen ? 10 : '30px',
                        paddingRight: this.state.isSmallScreen ? 10 : '30px',
                    }}
                >
                    {this.renderTop()}
                    {this.renderBottom()}
                </div>
            </div>
        );
    }

    render() {
        return (
            <div
                style={{
                    ...EssentialStyle.columnCenter,
                    width: '100%',
                    height: this.state.isSmallScreen ? 'auto' : this.state.screenHeight
                }}
            >
                {this.renderInteracoes()}
            </div>
        );
    }
}