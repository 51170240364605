import React from "react";
import './PlanoAcaoQuadroCard.css';

import { forwardRef } from "react";
import { Form } from "react-bootstrap";

import Sig from "../../../../api/Sig";
import EssentialStyle from "../../../../style/EssentialStyle";
import DefaultLoader from "../../../tools/DefaultLoader";
import DefaultButton from "../../../tools/DefaultButton";
import DataHelper from "../../../../helper/DataHelper";
import KeyboardHelper from "../../../../helper/KeyboardHelper";
import Colors from "../../../../constants/Colors";
import UserAvatar from "../../../tools/UserAvatar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp, faCalendar, faExclamationTriangle, faClock, faPencil, faTimes, faCheck, faLink } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";

import Select, { components } from 'react-select';
import PlanoAcaoHelper from "../../../../helper/planoAcao/PlanoAcaoHelper";
import DatePicker from 'react-datepicker';
import moment from "moment";
import CustomTooltip from "../../../tools/CustomTooltip";
import TextEditor from "../../../tools/TextEditor/TextEditor";
import DatePickerHelper from "../../../../helper/DatePickerHelper";

export default class PlanoAcaoQuadroCard extends React.Component {
    constructor(props) {
        super(props);
        this.titleHoveredTimeout = null;
    }

    state = {
        selectOpen: false,
        loading: false,
        hovered: false,
        dragging: false,
        editing: false,
        participantes: this.props.participantes || [],
        prioridade: this.props.etapa?.prioridade ? DataHelper.formatSelectedData(this.props.etapa, 'prioridade', 'prioridade_text') : null,
        selectedUser: this.props.etapa?.colaborador ? DataHelper.formatSelectedData(this.props.etapa?.colaborador, 'id', 'nome') : null,
        status: this.props.etapa?.status_value || 0,
        data_inicio: this.props.etapa?.data_inicio ? moment(this.props.etapa?.data_inicio).toDate() : null,
        data_fim: this.props.etapa?.data_fim ? moment(this.props.etapa?.data_fim).toDate() : null,
        titleHovered: false,
        updatingTitle: false,
        editingTitle: false,
        atividade: this.props.etapa?.atividade,
        justificativa: this.props.etapa?.justificativa,
        descricao: this.props.etapa?.descricao,
        prev: {
            atividade: this.props.etapa?.atividade,
            justificativa: this.props.etapa?.justificativa,
            descricao: this.props.etapa?.descricao,
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.participantes !== this.props.participantes) {
            this.setState({ participantes: this.props.participantes });
        }

        if (prevProps.etapa !== this.props.etapa) {
            this.setState({
                prioridade: this.props.etapa?.prioridade ? DataHelper.formatSelectedData(this.props.etapa, 'prioridade', 'prioridade_text') : null,
                selectedUser: this.props.etapa?.colaborador ? DataHelper.formatSelectedData(this.props.etapa?.colaborador, 'id', 'nome') : null,
                status: this.props.etapa?.status_value || 0,
                data_inicio: this.props.etapa?.data_inicio ? moment(this.props.etapa?.data_inicio).toDate() : null,
                data_fim: this.props.etapa?.data_fim ? moment(this.props.etapa?.data_fim).toDate() : null,
                atividade: this.props.etapa?.atividade,
                justificativa: this.props.etapa?.justificativa,
                descricao: this.props.etapa?.descricao,
                prev: {
                    atividade: this.props.etapa?.atividade,
                    justificativa: this.props.etapa?.justificativa,
                    descricao: this.props.etapa?.descricao,
                }
            });
        }
    }

    getUserFromParticipantes = (id) => {
        return this.state.participantes.find(p => p.id === id);
    }

    renderHasDependencias() {
        if(!this.props.etapa.has_dependencias) return null;

        return (
            <CustomTooltip tooltip={'Esta etapa possui dependências'} placement={'auto'}>
                <FontAwesomeIcon icon={faLink} className={'icon dependencias'} />
            </CustomTooltip>
        );
    }

    DropdownIndicator = ({children, ...props}) => {
        const { selectProps } = props;
        const isMenuOpen = selectProps.menuIsOpen;

        if (isMenuOpen) return null;

        return (
            <components.DropdownIndicator {...props}>
                <div style={{ cursor: 'pointer' }}>
                    <UserAvatar user={this.getUserFromParticipantes(this.state.selectedUser.value)} />
                </div>
            </components.DropdownIndicator>
        );
    };

    renderPeopleSelect() {

        return (
            <div
                style={{
                    ...EssentialStyle.rowFlexStart,
                    marginRight: -10,
                    fontSize: 14
                }}
                onClick={e => e.stopPropagation()}
            >
                <Select
                    onMenuOpen={() => this.setState({ selectOpen: 'people' })}
                    onMenuClose={() => this.setState({ selectOpen: false })}
                    ref={ref => this.selectPeople = ref}
                    styles={{
                        control: (base, state) => ({
                            ...base,
                            ...EssentialStyle.titleBoxHomePage,
                            border: 'none',
                            width: state.isFocused ? '210px' : '70px',
                            boxShadow: state.isFocused ? 'none' : null,
                            backgroundColor: "transparent",
                            marginRight: 8
                        })
                    }}
                    value={this.state.selectedUser}
                    options={DataHelper.formatSelectData(this.state.participantes, 'id', 'nome')}
                    formatOptionLabel={({ label, value }) => (
                        <div style={{ ...EssentialStyle.rowFlexStart, paddingLeft: 8, cursor: 'pointer' }}>
                            <UserAvatar user={this.getUserFromParticipantes(value)} showNameAdaptative showName />
                        </div>
                    )}
                    placeholder={''}
                    noOptionsMessage={DataHelper.getSelectEmptyMessage}
                    isSearchable={false}
                    components={{ DropdownIndicator: this.DropdownIndicator, IndicatorSeparator: null }}
                    onChange={async (value) => {
                        this.setState({
                            selectedUser: value
                        }, async () => {
                            if (this.selectPeople) this.selectPeople.blur();

                            await Sig.request('POST', 'planoAcao/updateResponsavelEtapa', {
                                id: this.props.etapa.id,
                                responsavel: value.value
                            });
                        });
                    }}
                    isDisabled={!this.props?.canEdit}
                />

            </div>
        );
    }

    renderSelectPrioridade() {
        if (this.state.selectOpen && this.state.selectOpen !== 'prioridade') return null;

        const DropdownIndicator = (props) => {
            const { selectProps } = props;
            const isMenuOpen = selectProps.menuIsOpen;

            if (isMenuOpen) return null;

            return (
                <components.DropdownIndicator {...props}>
                    <div style={{ cursor: 'pointer' }}>
                        {PlanoAcaoHelper.getPrioridadeIcon()[this.state.prioridade.label]}
                    </div>
                </components.DropdownIndicator>
            );
        };

        return (
            <div
                style={{
                    ...EssentialStyle.rowFlexStart,
                    marginRight: -25,
                    fontSize: 14,
                }}
                onClick={e => e.stopPropagation()}
            >
                <Select
                    onMenuOpen={() => this.setState({ selectOpen: 'prioridade' })}
                    onMenuClose={() => this.setState({ selectOpen: false })}
                    ref={ref => this.selectPrioridade = ref}
                    styles={{
                        control: (base, state) => ({
                            ...base,
                            ...EssentialStyle.titleBoxHomePage,
                            border: 'none',
                            width: state.isFocused ? '140px' : '47px',
                            boxShadow: state.isFocused ? 'none' : null,
                            backgroundColor: "transparent",
                            marginRight: 8
                        })
                    }}
                    value={this.state.prioridade}
                    options={PlanoAcaoHelper.getEtapaPrioridades()}
                    formatOptionLabel={({ label }) => (
                        <div style={{ ...EssentialStyle.rowFlexStart, paddingLeft: 8, cursor: 'pointer' }}>
                            <div style={{ marginRight: 8 }}>{PlanoAcaoHelper.getPrioridadeIcon()[label]}</div> {label}
                        </div>
                    )}
                    placeholder={''}
                    noOptionsMessage={DataHelper.getSelectEmptyMessage}
                    isSearchable={false}
                    closeMenuOnSelect={true}
                    components={{ DropdownIndicator, IndicatorSeparator: null }}
                    onChange={async (value) => {
                        this.setState({
                            prioridade: value
                        }, async () => {
                            if (this.selectPrioridade) this.selectPrioridade.blur();

                            await Sig.request('POST', 'planoAcao/updatePrioridadeEtapa', {
                                id: this.props.etapa.id,
                                prioridade: value.value
                            });
                        });
                    }}
                    isDisabled={!this.props?.canEdit}
                />

            </div>
        );
    }


    CustomPicker = forwardRef(({ value, onClick }, ref) => (
        <div style={{
            ...EssentialStyle.rowFlexCenter,
            fontSize: 14,
            color: Colors.dark,
            fontWeight: 'bold',
            cursor: 'pointer',
            backgroundColor: Colors.secondaryButton,
            padding: 1,
            height: '100%',
            width: 170,
            textAlign: 'center',
            borderRadius: 4,
            paddingLeft: 5,
            marginRight: 8
        }}
        >
            <FontAwesomeIcon icon={faCalendar} />
            <Form.Control
                onChange={() => { }}
                onClick={onClick}
                ref={ref => this.periodoPicker = ref}
                style={{
                    marginLeft: 2,
                    fontSize: 14,
                    color: Colors.dark,
                    fontWeight: 'bold',
                    cursor: 'pointer',
                    boxShadow: 'none',
                    backgroundColor: Colors.secondaryButton,
                    padding: 1,
                    height: '100%',
                    textAlign: 'center',
                }}
                value={value} >
            </Form.Control>
        </div>
    ));

    renderDatePicker() {
        if (this.state.selectOpen && this.state.selectOpen !== 'data') return null;

        let dataMoment1 = this.state.data_inicio ? moment(this.state.data_inicio).toDate() : null;
        let dataMoment2 = this.state.data_fim ? moment(this.state.data_fim).toDate() : null;

        return (
            <DatePicker
                wrapperClassName="w-100"
                portalId="calendar-portal"
                popperClassName="calendar-popper"
                popperPlacement="bottom-end"
                selected={dataMoment1}
                startDate={dataMoment1}
                endDate={dataMoment2}
                selectsRange
                readOnly={this.props?.canEdit ? false : true}
                onChange={async (dates) => {
                    const [start, end] = dates;
                    this.setState({ data_inicio: start, data_fim: end });

                    if (!start || !end) return;

                    if (this.periodoPicker) this.periodoPicker.blur();

                    await Sig.request('POST', 'planoAcao/updatePeriodoEtapa', {
                        id: this.props.etapa.id,
                        data_inicio: DataHelper.getDefaultDbDateFormat(start),
                        data_fim: DataHelper.getDefaultDbDateFormat(end)
                    });

                    this.props.updateEtapas();
                }}
                disabled
                dateFormat="dd/MM/yy"
                customInput={<this.CustomPicker />}
                renderCustomHeader={(props) =>  
                    DatePickerHelper.renderCustomDatePickerHeader(props)
                }
            />
        );
    }

    isWarning = () => {
        return !(!!this.props.etapa?.atividade && !!this.props.etapa?.justificativa && !!this.props.etapa?.descricao);
    }

    renderAtrasada() {
        if (!this.props.etapa.atrasada || (this.props.etapa.status == 2)) return null;

        if (!this.props.etapa.atrasada || (this.props.etapa.status == 3)) {
            return (
                <CustomTooltip tooltip={'Esta etapa foi concluída com atraso'} placement="left">
                    <FontAwesomeIcon icon={faClock} className={'icon atrasado-concluido'} />
                </CustomTooltip>
            );
        }

        return (
            <CustomTooltip tooltip={'Esta etapa está atrasada'} placement="right">
                <FontAwesomeIcon icon={faClock} className={'icon atrasado'} />
            </CustomTooltip>
        );
    }

    renderWarning() {
        if (!this.isWarning()) return null;

        let tooltipTitle = '';

        if (!this.props.etapa?.justificativa && !this.props.etapa?.descricao) {
            tooltipTitle = 'Preencha os campos de "Como?" e "Por quê?"';
        } else if (!this.props.etapa?.justificativa) {
            tooltipTitle = 'Preencha o campo de "Por quê?"';
        } else if (!this.props.etapa?.descricao) {
            tooltipTitle = 'Preencha o campo de "Como?"';
        }

        return (
            <CustomTooltip tooltip={tooltipTitle} placement="right">
                <FontAwesomeIcon icon={faExclamationTriangle} className={'icon warning'} />
            </CustomTooltip>
        );
    }

    renderTitle(text) {
        return (
            <div
                onMouseEnter={() => this.handleTitleHoverMouseEnter()}
                onMouseLeave={() => this.handleTitleHoverMouseLeave()}
                style={{ ...EssentialStyle.rowFlexStart, width: '100%' }}
            >
                <span style={{
                    marginLeft: 6,
                    fontSize: 16,
                    textAlign: 'left',
                    width: '100%',
                    fontWeight: 500,
                    textDecoration: this.state.titleHovered && this.props?.canEdit ? 'underline' : 'none',
                }}>
                    {DataHelper.removeHtmlAndReplaceListItems(text)}
                    {this.state.titleHovered && this.props?.canEdit && (
                        <DefaultButton
                            width={48}
                            className="ms-1"
                            leftIcon={<FontAwesomeIcon icon={faPencil} />}
                            color={'transparent'}
                            textColor={Colors.dark}
                            loading={this.state.updatingTitle}
                            onClick={(e) => {
                                e.stopPropagation();
                                
                                this.setState(
                                    { 
                                        editingTitle: true, 
                                        prev: { 
                                            atividade: this.state.atividade, 
                                            justificativa: this.state.justificativa, 
                                            descricao: this.state.descricao 
                                        }
                                    }, () => {
                                    if (this.editTitleInput) this.editTitleInput.focus();
                                    const length = this.editTitleInput.value?.length || 0;
                                    this.editTitleInput.editor.setSelection(length, 0);
                                });
                            }}
                        />
                    )}
                </span>
            </div>
        );
    }

    handleTitleHoverMouseEnter = (hovered) => {
        this.titleHoveredTimeout = setTimeout(() => {
            this.setState({ titleHovered: true });
        }, 400);
    }

    handleTitleHoverMouseLeave = () => {
        if (this.titleHoveredTimeout) clearTimeout(this.titleHoveredTimeout);

        this.setState({ titleHovered: false });
    }

    editTitle = async () => {
        if (!this.state.atividade) {
            toast.info('O campo de descrição da atividade não pode estar vazio.');
            return;
        }

        this.setState({ updatingTitle: true });

        await Sig.request('POST', 'planoAcao/updateEtapaText', {
            id: this.props.etapa.id,
            atividade: this.state.atividade,
            justificativa: this.state.justificativa,
            descricao: this.state.descricao
        });

        this.setState({ updatingTitle: false, editingTitle: false });
    }

    renderEditTitle() {
        return (
            <div style={{ ...EssentialStyle.columnEnd, maxWidth: '100%', minWidth: '100%' }}
                onClick={e => e.stopPropagation()}
            >
                <TextEditor
                    ref={ref => this.editTitleInput = ref}
                    defaultValue={this.state.atividade}
                    placeholder="Descrição da Atividade..."
                    onChange={(value) => {
                        this.setState({ atividade: value })
                    }}
                    maxHeight={200}
                    mentions={DataHelper.formatMentionData(this.props.participantes, 'id', 'nome')}
                    hideToolbar
                    noMargin
                    disabledEnterEvent
                    onKeyDown={(evt) => {
                        KeyboardHelper.handleShortcut(
                            evt,
                            ["Enter", "Escape"],
                            [() => { 
                                if (evt.shiftKey) return;
                                this.editTitle();
                            }, (e) => { 
                                e.preventDefault();
                                this.setState({ atividade: this.state.prev?.atividade, editingTitle: false, editingTitle: false });
                            }]
                        )
                    }}
                />
                <div style={{ ...EssentialStyle.rowFlexEnd, width: '100%', marginTop: 8 }}>
                    <DefaultButton
                        width={48}
                        className="me-1"
                        leftIcon={<FontAwesomeIcon icon={faTimes} />}
                        color={Colors.dark}
                        loading={this.state.updatingTitle}
                        onClick={(e) => { e.stopPropagation(); this.setState({ atividade: this.state.prev?.atividade, editingTitle: false }) }}
                    />
                    <DefaultButton
                        width={48}
                        leftIcon={<FontAwesomeIcon icon={faCheck} />}
                        color={Colors.dark}
                        loading={this.state.updatingTitle}
                        onClick={(e) => { e.stopPropagation(); this.editTitle(); }}
                    />
                </div>
            </div>
        );
    }

    openDetailsModal = () => {
        this.props.detailsModalCallback(this.props.etapa.id);
    }

    renderNormal() {
        return (
            <>
                {this.state.editingTitle ? this.renderEditTitle() : this.renderTitle(this.state.atividade)}
                <div style={{
                    ...EssentialStyle.rowSpaceBetween,
                    width: '100%'
                }}
                >
                    <div style={{ ...EssentialStyle.rowFlexStart }} onClick={e => e.stopPropagation()}>
                        {this.renderDatePicker()}
                    </div>
                    <div style={{ ...EssentialStyle.rowFlexEnd }}>
                        <div style={{ ...EssentialStyle.rowFlexStart, marginRight: -20 }}>
                            {this.renderHasDependencias()}
                            {this.renderAtrasada()}
                            {this.renderWarning()}
                        </div>
                        {this.renderSelectPrioridade()}
                        {this.renderPeopleSelect()}
                    </div>
                </div>
            </>
        );
    }

    renderCard() {
        return (
            <div
                onClick={this.openDetailsModal}
                draggable={this.state.editingTitle || !this.props?.canEdit ? false : true}
                onDragStart={(e) => {
                    if (!this.state.editingTitle) {
                        this.props.onDragStart(e);
                        this.setState({ dragging: true, editing: false });
                    }
                }}
                onDragEnter={!this.state.editingTitle ? this.props.onDragEnter : undefined}
                onDragEnd={(e) => {
                    if (!this.state.editingTitle) {
                        this.props.onDragEnd(e);
                        this.setState({ dragging: false });
                    }
                }}
                onMouseOver={() => {
                    this.setState(
                        { hovered: true }
                    );
                }}
                onMouseLeave={() => {
                    this.setState(
                        { hovered: false },
                    );
                }}
                style={{
                    ...EssentialStyle.columnSpaceBetween,
                    marginTop: this.props.dragControl?.draggingOverItem && this.props.dragControl?.draggingOverItem.id === this.props.etapa.id && this.props.dragControl?.draggingOverItemTop ? 80 : 4,
                    marginBottom: this.props.dragControl?.draggingOverItem && this.props.dragControl?.draggingOverItem.id === this.props.etapa.id && !this.props.dragControl?.draggingOverItemTop ? 80 : 4,
                }}
                className={`plano-acao-quadro-card ${this.isWarning() ? 'warning' : ''}`}
            >
                {this.renderNormal()}
            </div>
        );
    }

    render() {
        return this.renderCard();
    }
}