import { registerLocale, setDefaultLocale } from "react-datepicker";
import pt from 'date-fns/locale/pt-BR';
import en from 'date-fns/locale/en-US';
import es from 'date-fns/locale/es';
import moment from 'moment';
import 'moment/locale/pt-br';
import 'moment/locale/en-gb';
import 'moment/locale/es';
import i18n from '../i18n'; // Import the i18n configuration

export default class LocaleHelper {
    static setDefaultLocale(language) {
        switch (language) {
            case 'pt_br':
                registerLocale('pt', pt);
                setDefaultLocale('pt');
                moment.locale('pt-br');
                i18n.changeLanguage('pt_br');
                break;
            case 'en':
                registerLocale('en', en);
                setDefaultLocale('en');
                moment.locale('en');
                i18n.changeLanguage('en');
                break;
            case 'es':
                registerLocale('es', es);
                setDefaultLocale('es');
                moment.locale('es');
                i18n.changeLanguage('es');
                break;
            default:
                registerLocale('pt', pt);
                setDefaultLocale('pt');
                moment.locale('pt-br');
                i18n.changeLanguage('pt_br');
                break;
        }
    }
}