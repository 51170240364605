import React from "react";
import LayoutHelper from "../../../../helper/LayoutHelper";
import EssentialStyle from "../../../../style/EssentialStyle";
import DefaultButton from "../../../tools/DefaultButton";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faDownload, faAdd } from '@fortawesome/free-solid-svg-icons';
import Colors from "../../../../constants/Colors";
import Sig from "../../../../api/Sig";
import Constants from "../../../../constants/Api";
import { toast } from "react-toastify";
import CustomConfirm from "../../../tools/CustomConfirm";
import { Form, Row, Col } from "react-bootstrap";
import { confirmAlert } from 'react-confirm-alert';
import SessionHelper from "../../../../helper/SessionHelper";
import CustomTooltip from "../../../tools/CustomTooltip";
import DataHelper from "../../../../helper/DataHelper";

export default class AtaReuniaoAnexosList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            fileToUpload: null,
            fileToUploadPreview: null,
            name: null,
            loadingUpload: false,
            isSmallScreen: LayoutHelper.isSmallScreen(),
        };
        this.fileInputRef = React.createRef();
    }

    componentDidMount() {
        window.addEventListener('resize', this.onResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.onResize);
    }

    onResize = () => {
        this.setState({ isSmallScreen: LayoutHelper.isSmallScreen() });
    }

    removeFile = async (file) => {
        this.setState({ loadingUpload: true });

        const req = await Sig.request("POST", `ataReuniao${this.props.hash ? "Externa" : ""}/removeFile`+this.props.type, { 
            file: JSON.stringify(file), 
            id_relation: this.props.id,
            hash: this.props.hash 
        });

        if (req.status === 200) {
            toast.success('Arquivo removido!');
            this.props.updateAnexoCallback(file, true);
        } else {
            toast.error('Ocorreu um erro ao remover o arquivo, tente novamente mais tarde.')
        }

        this.setState({ loadingUpload: false });
    }

    handleFileUpload = async (v) => {
        this.setState({ loadingUpload: true });

        const file = v.target.files[0];
        const maxSize = 10 * 1024 * 1024; 

        if (file && file.size <= maxSize) {
            this.setState({ fileToUpload: file, fileToUploadPreview: URL.createObjectURL(file), name: !this.state.name ? file.name.slice(0, file.name.lastIndexOf('.')).replace(/[\W_]+/g, "") : this.state.name.replace(/[\W_]+/g, "") });
        } else {
            if (file && file.size >= maxSize) {
                toast.warn('O arquivo é muito grande, use um de menos de 10 MB');
            } else {
                toast.warn('O arquivo não cumpre os requisitos necessários.');
            }
        }

        this.setState({ loadingUpload: false }, this.renderFilePreviewModal);
    }

    doUploadFile = async () => {
        this.setState({ loadingUpload: true });

        const formData = new FormData();
        if(this.props.hash) formData.append('hash', this.props.hash);
        formData.append('file', this.state.fileToUpload);
        formData.append('nome', this.state.name);
        formData.append('id_relation', this.props.id);

        const req = await Sig.request("POST", `ataReuniao${this.props.hash ? "Externa" : ""}/uploadFile`+this.props.type, formData, 'multipart/form-data');

        this.setState({ loadingUpload: false });

        if (req.status === 200 && req.anexo) {
            toast.success('Arquivo inserido com sucesso!');
            this.setState({ fileToUpload: null, fileToUploadPreview: null, name: '' }, 
                () => this.fileInputRef.current.value = null
            );
            this.props.updateAnexoCallback(req.anexo);
        } else {
            toast.error('Erro ao inserir arquivo!');
            this.setState({ fileToUpload: null, fileToUploadPreview: null, name: '' },
                () => this.fileInputRef.current.value = null
            );
        }
    }

    renderFilePreviewModal = () => {
        confirmAlert({
            customUI: ({ onClose }) => (
                <CustomConfirm
                    title={'Fazer upload de arquivo'}
                    message={
                        <>
                        <Row className="mb-3">
                            <Form.Group as={Col}>
                                <Form.Label>Nome do Arquivo</Form.Label>
                                <Form.Control type="text" placeholder="Arquivo x..." defaultValue={this.state.name || ``} onChange={(event) => { this.setState({ name: event.target.value }) }} />
                            </Form.Group>
                        </Row>
                        </>
                    }
                    buttons={[
                        {
                            label: 'Salvar',
                            color: Colors.success,
                            textColor: Colors.light,
                            onClick: async () => { 
                                this.doUploadFile();
                                onClose(); 
                            }
                        },
                        {
                            label: 'Cancelar',
                            onClick: () => { 
                                this.setState({ fileToUpload: null, fileToUploadPreview: null, name: '' }, 
                                    () => this.fileInputRef.current.value = null
                                );
                                onClose(); 
                            }
                        },
                    ]}
                    onClickOutside={() => {
                        onClose();
                    }}
                />
            )
        });
    }

    renderAddAnexo = () => {
        return (
            <>
                <input
                    ref={this.fileInputRef}
                    multiple={false}
                    accept="image/*, .pdf, .doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document, .xlsx, .xls, .pptx, .ppt"
                    style={{ display: 'none' }}
                    id={"upload-template"+this.props.id+"-"+this.props.type}
                    name={"upload-template"+this.props.id+"-"+this.props.type}
                    type="file"
                    onChange={this.handleFileUpload}
                />
                <label htmlFor={"upload-template"+this.props.id+"-"+this.props.type}>
                    <div
                        onClick={() => { 
                            this.setState({ fileToUpload: null, fileToUploadPreview: null, name: '' }, 
                                () => this.fileInputRef.current.value = null
                            );
                        }}
                        style={{
                            ...EssentialStyle.columnCenter, textAlign: 'center', minWidth: 100, minHeight: 100, maxHeight: 100, maxWidth: 100, cursor: 'pointer',
                            padding: 0, backgroundColor: Colors.light, border: '1px solid '+Colors.homePage.lightGrey, borderRadius: 5, color: Colors.homePage.grey,
                        }}
                    >
                        <FontAwesomeIcon icon={faAdd} style={{fontSize: 30, color: Colors.homePage.grey}}/>
                        Adicionar Anexo
                    </div>
                </label>
            </>
        )
    }

    renderFileDeleteModal = (file) => {
        confirmAlert({
            customUI: ({ onClose }) => (
                <CustomConfirm
                    title={'Remover este Anexo?'}
                    message={'Essa ação é irreversível'}
                    buttons={[
                        {
                            label: 'Remover',
                            color: SessionHelper.getColor(),
                            textColor: Colors.light,
                            onClick: () => { this.removeFile(file); onClose(); }
                        },
                        {
                            label: 'Cancelar',
                            onClick: () => { onClose(); }
                        },
                    ]}
                />
            )
        });
    }

    render() {
        const prefix = `${Constants.getSigEndPoint()}/upload/geral`;

        return (
            <div key={this.props.id+"-"+this.props.type} style={{...EssentialStyle.columnStart, width: '100%', padding: 10}}>
                <div style={{...EssentialStyle.rowFlexStart, width: '100%', gap: 10}}>
                    <span style={{fontSize: 18, fontWeight: 500, color: Colors.homePage.grey}}>
                        {this.props.type === 'Ata' ? 
                            'Anexos da Reunião' 
                        : 
                            this.props.type === 'Topico' ? 
                                this.props.nomeTopico ? 
                                    DataHelper.removeHtmlTags(this.props.nomeTopico)                                :
                                    'Tópico sem nome'
                        : 
                            'Anexos' 
                        }
                    </span>
                    <span style={{height: 0, alignSelf: "center", borderBottom: '2px solid ' + Colors.homePage.grey, flex: 1 }}/>
                    <DefaultButton
                        leftIcon={<FontAwesomeIcon icon={faDownload} />}
                        color={"transparent"}
                        tooltip={"Baixar os anexos "+ (this.props.type === 'Ata' ? 'da reunião' : 'deste tópico')}
                        textColor={Colors.homePage.grey}
                        style={{width: 35, height: 35, padding: 0, fontSize: 18}}
                        onClick={async () => { 
                            const toastId = toast.loading("Efetuando Download...");
                            try {
                                var response = await Sig.request("POST", `ataReuniao${this.props.hash ? "Externa" : ""}/downloadCompressedFiles`+this.props.type, { 
                                    id_relation: this.props.id,
                                    hash: this.props.hash 
                                }, 'application/x-www-form-urlencoded; charset=UTF-8', 'blob');
                                
                                if(response.status === 200) {
                                    var url = window.URL.createObjectURL(new Blob([response]));
                                    var link = document.createElement('a');
                                    link.href = url;
                                    link.setAttribute('download', 'Anexos.zip');
                                    document.body.appendChild(link);
                                    link.click();
                                    toast.update(toastId, { render: "Download Concluído!", type: "success", isLoading: false, autoClose: 5000 });
                                } else {
                                    toast.update(toastId, { render: "Erro no Download!", type: "error", isLoading: false, autoClose: 5000 });
                                }
                            } catch (error) {
                                toast.update(toastId, { render: "Erro no Download!", type: "error", isLoading: false, autoClose: 5000 });
                            }
                        }}
                    />
                </div>
                <div style={{...EssentialStyle.rowFlexStart, width: '100%', padding: 10, overflowX: "auto", gap: 5}}>       
                    {this.props.anexos && this.props.anexos.length > 0 && this.props.anexos.map((anexo, index) => {
                        return (
                            <div 
                                key={`key-${anexo.name}`} 
                                onClick={async () => {
                                    const toastId = toast.loading("Efetuando Download...");
                                    try {

                                        var response = await Sig.request("POST", `ataReuniao${this.props.hash ? "Externa" : ""}/downloadSingleAnexo`+this.props.type, { 
                                            id_relation: this.props.id,
                                            hash: this.props.hash,
                                            referencia: anexo.url 
                                        }, 'application/x-www-form-urlencoded; charset=UTF-8', 'blob');
                                        
                                        if(response.status === 200) {
                                            var url = window.URL.createObjectURL(new Blob([response]));
                                            var link = document.createElement('a');
                                            link.href = url;
                                            link.setAttribute('download', anexo.name);
                                            document.body.appendChild(link);
                                            link.click();
                                            toast.update(toastId, { render: "Download Concluído!", type: "success", isLoading: false, autoClose: 5000 });
                                        } else {
                                            toast.update(toastId, { render: "Erro no Download!", type: "error", isLoading: false, autoClose: 5000 });
                                        }
                                    } catch (error) {
                                        toast.update(toastId, { render: "Erro no Download!", type: "error", isLoading: false, autoClose: 5000 });
                                    }

                                }}
                                style={{
                                    ...EssentialStyle.columnStart, minWidth: 100, minHeight: 100, padding: 5, borderRadius: 5, cursor: 'pointer',
                                    backgroundColor: Colors.tag, border: '1px solid '+Colors.homePage.grey, maxHeight: 100, maxWidth: 100
                                }}
                                
                            >
                                { this.props.canEdit &&
                                <DefaultButton
                                    leftIcon={<FontAwesomeIcon icon={faClose} />}
                                    color={"transparent"}
                                    textColor={Colors.homePage.grey}
                                    style={{width: 25, height: 25, padding: 0, placeSelf: 'flex-end'}}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        this.renderFileDeleteModal(anexo)}
                                    }
                                />}
                                <CustomTooltip 
                                    tooltip={anexo.name} 
                                    placement="auto"
                                    style={{width: '100%'}}
                                >
                                    <span style={{
                                        fontSize: 12, 
                                        fontWeight: 500, 
                                        color: Colors.homePage.grey, 
                                        textAlign: 'center',
                                        display: '-webkit-box',
                                        WebkitBoxOrient: 'vertical',
                                        WebkitLineClamp: 3,
                                        overflow: 'hidden',
                                        wordWrap: 'break-word',
                                        width: '100%',
                                    }}>
                                        {anexo.name}
                                    </span>
                                </CustomTooltip>
                            </div>
                        )}
                    )}
                    {this.props.canEdit ? this.renderAddAnexo() : this.props.anexos.length === 0 && <span style={{minHeight: 100, fontSize: 16, fontWeight: 500, color: Colors.homePage.grey, alignContent: "center"}}>Sem anexos</span>} 
                </div>
            </div>
        );
    }
}