import React from "react";

import "./ModalUploadEtapaPlanoAcao.css";
import Modal from "../../../tools/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload, faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import DefaultButton from "../../../tools/DefaultButton";
import Colors from "../../../../constants/Colors";
import EssentialStyle from "../../../../style/EssentialStyle";
import DefaultLoader from "../../../tools/DefaultLoader";
import TableImporter from "../../../tools/TableImporter/TableImporter";
import Sig from "../../../../api/Sig";

export default class ModalUploadEtapaPlanoAcao extends React.Component {

    state = {
        loading: true,
        loadingDownloadModelo: false,
        importing: false,
        linesImported: 0,
        importComplete: false,
        modelo: false
    }

    async componentDidMount() {
        await this.load();
    }

    load = async () => {
        this.setState({ loading: true });

        await this.getModel();

        this.setState({ loading: false });
    }

    getModel = async () => {
        let req = await Sig.request('POST', 'planoAcao/getModeloImportacao');

        if (req.status == 200) {
            this.setState({ modelo: req.modelo });
        }
    }

    renderTitle() {
        return (
            <div className="titleModal">
                <div className="titleLeft">
                    <FontAwesomeIcon icon={faUpload} />
                    Importar Planos de Ação e Etapas de Plano de Ação
                </div>
            </div>
        )
    }

    importData = async (data) => {
        this.setState({ importing: true });

        // Função para enviar um chunk ao backend
        const sendChunk = async (chunk) => {
            const response = await Sig.request('POST', 'planoAcao/importarPlanos', { lote: JSON.stringify(chunk) });

            if (response.error) {
                this.setState({ fileErrors: response.fileErrors, importing: false, importComplete: false });
                return false;
            } else {
                this.setState({ linesImported: this.state.linesImported + chunk.length });
                return true;
            }
        };

        // Enviar todos os chunks sequencialmente
        const sendAllChunks = async () => {
            for (const chunk of dataChunks) {
                let success = await sendChunk(chunk);
                if (!success) return;
            }
            this.setState({ importing: false, importComplete: true });
        };

        let completeData = data;
        // completeData.shift();

        let dataChunks = [];
        const chunkSize = 100;
        for (let i = 0; i < completeData.length; i += chunkSize) {
            dataChunks.push(completeData.slice(i, i + chunkSize));
        }

        await sendAllChunks();
    }

    renderUploadFile = () => {
        return (
            <div className="bodyModal">
                <TableImporter
                    fileModel={this.state.modelo}
                    onConfirm={(data) => { this.importData(data) }}
                    importing={this.state.importing}
                    fileErrors={this.state.fileErrors}
                    importComplete={this.state.importComplete}
                    onComplete={this.props.closeModal}
                    linesImported={this.state.linesImported}
                />
            </div>
        )
    }

    renderLoading = () => {
        return (
            <div className="bodyModal loading">
                <DefaultLoader />
            </div>
        )
    }

    renderBody() {
        if (this.state.loading) return this.renderLoading();
        return this.renderUploadFile();
    }

    renderFooter() {
        return (
            <div style={{ ...EssentialStyle.rowFlexEnd, width: '100%', marginTop: 10 }}>
                <DefaultButton
                    title={'Fechar'}
                    color={Colors.dark}
                    textColor={Colors.white}
                    onClick={() => {
                        this.props.closeModal()
                    }}
                />
            </div>
        )
    }

    render() {
        return (
            <Modal
                className={"ModalUploadEtapaPlanoAcao"}
                style={{
                    width: '95vw',
                    height: '100vh',
                    transition: 'height 0.5s ease',
                    boxShadow: '0px 0px 20px 20px rgba(50, 50, 50, 0.5)',
                    justifyContent: 'space-between',
                }}
                onClose={this.props.closeModal}
            >
                {this.renderTitle()}
                {this.renderBody()}
                {this.renderFooter()}
            </Modal>
        )
    }
}