import React from 'react';
import { useInView } from 'react-intersection-observer';

const InViewWrapper = ({ onInViewChange, children, placeholderHeight }) => {
    const [ref, inView] = useInView({
        delay: 100,
        rootMargin: '100%'
    });

    React.useEffect(() => {
        if (onInViewChange) {
            onInViewChange(inView);
        }
    }, [inView, onInViewChange]);

    return (
        <div ref={ref} style={{ minHeight: inView ? 'auto' : placeholderHeight }}>
            {inView ? children : null}
        </div>
    );
};

export default InViewWrapper;