import React from "react";
import './RelatorioPerformanceTabelaItem.css';
import Colors from "../../../../../../constants/Colors";
import DiagnosticoHelper from "../../../../../../helper/diagnostico/DiagnosticoHelper";
import moment from "moment";
import DefaultLoader from "../../../../../tools/DefaultLoader";
import { Modal } from 'react-bootstrap';
import EssentialStyle from "../../../../../../style/EssentialStyle";
import IdiomaHelper from "../../../../../../helper/IdiomaHelper";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faTimes, faSave, faEdit, faChevronLeft, faChevronRight, faLessThan, faGreaterThanEqual, faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import CustomTooltip from "../../../../../tools/CustomTooltip";
import Select from 'react-select';

import Highcharts from "highcharts/highcharts.js";
import highchartsMore from "highcharts/highcharts-more.js"
import accessibility from 'highcharts/modules/accessibility';
import solidgauge from "highcharts/modules/solid-gauge";
import EllipsisText from "../../../../../tools/EllipsisText";
import DefaultButton from "../../../../../tools/DefaultButton";
import { withTranslation } from 'react-i18next';

highchartsMore(Highcharts);
accessibility(Highcharts);
solidgauge(Highcharts);


class RelatorioPerformanceTabelaItem extends React.Component {

    constructor(props) {
        super(props);
        this.itemRef = React.createRef();
        this.headerRef = React.createRef();
        this.timeout = null;

        this.state = {
            loading: true,
            miniLoading: false,

            idItem: this.props?.item?.id || null,
            grupos: this.props?.item?.grupos || [],
            data: this.props.data_performance || null,
            formatoTabela: this.props?.item?.formatTable || 'coluna',
            calculo: this.props?.item?.typeCalc || 'moda',
            typeVisualizacao: this.props?.item?.typeVisualizacao || 'moda',
            displayFaixas: this.props?.item?.displayFaixas || false,

            idEmpresaAvaliada: this.props?.idEmpresaAvaliada || null,
            status: this.props?.status || 1,
            canEdit: this.props?.canEdit || false,

            estrutura: [],

            editingGroupId: null,
            availableFaixas: [],
            selectedFaixa: null,
            editingGroupName: '',
            typesCalc: [],
            showModal: false,
            pinHeader: false,
            headerPosition: 'unset',

            expandFaixas: false,
            faixas: [],
        };
    }

    async componentDidMount() {
        await this.props.i18n.loadNamespaces(['client_src_helper_diagnostico_DiagnosticoHelper']);

        this.setState({ typesCalc: DiagnosticoHelper.getOptionsModeloRelatorio('typesCalc', this.props.t) });

        this.loadIndicadoresPerformance(true);
        window.addEventListener('scroll', this.handleScroll);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.data_performance !== this.props.data_performance && prevProps.data_performance !== null) {
            this.setState({ data: this.props.data_performance }, () => {
                this.loadIndicadoresPerformance(false);
            });
        }
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = () => {
        if(this.timeout) {
            return;
        }

        this.timeout = setTimeout(() => {
            this.checkScroll();
            this.timeout = null;
        }
        , 100);
    }

    checkScroll = () => {
        let minTop = this.props.pageHeaderHeight ?  ( this.props.pageHeaderHeight - 5 ) : -5;

        if (this.itemRef?.current) {
            const { top, bottom } = this.itemRef?.current?.getBoundingClientRect();
            let pinHeader = false;
            if ((top < minTop) && !(bottom < (minTop + 120))) {
                pinHeader = true;
            }

            if(this.state.pinHeader !== pinHeader) {
                this.setState({ pinHeader });
            }
            let headerPosition = pinHeader ? (minTop - top) : 'unset';
            this.setState({ headerPosition });
        }
    }

    async loadIndicadoresPerformance(viewLoading = true) {
        this.setState({ loading: viewLoading ? true : false, miniLoading: true });
        
        let dataSelect = moment(this.state.data).format('YYYY-MM-DD');
        let idItem = this.state.idItem;

        let { estrutura, calculo } = await DiagnosticoHelper.getEstruturaIndicadoresPerformanceTabela(idItem, dataSelect);

        let faixas = [];
        if(this.props?.idDiagnosticoPeriodo){
            let req = await DiagnosticoHelper.getFaixasPeriodo(this.props?.idDiagnosticoPeriodo);
            faixas = req?.faixas || [];
        }

        this.setState({ estrutura, calculo, loading: false, miniLoading: false, faixas });
    }
    
    message = () => {
        const message = IdiomaHelper.getStruct({
            'pt_br': {
                title: 'Performance',
                calculo: 'Tipo de cálculo da faixa',
                moda: 'Moda',
                modaInfo: 'A faixa que mais se repete no conjunto de indicadores deste grupo.',
                media: 'Média',
                mediaInfo: 'A faixa em que se enquadra a média aritmética das performances dos indicadores deste grupo.',
                realizado: 'Realizado',
                performance: 'Performance',
                grupo: 'Grupo',
                avaliacao: 'Avaliação',
                indicador: 'Indicador',
                resultado: 'Resultado',
                classificacao: 'Classificação',
                calculoEditTitle: 'Edição do Tipo de Cálculo',
                calculoEditInfo: 'Selecione o tipo de cálculo para definir a faixa deste Grupo ou selecione uma faixa fixa para esse Grupo com base em sua avaliação.',
                salvar: 'Salvar',
                cancelar: 'Cancelar',
                displayFaixas: 'Exibir Faixas dos Indicadores',
                hideFaixas: 'Ocultar Faixas dos Indicadores',
            },
            'en': {
                title: 'Performance',
                calculo: 'Calculation type of the range',
                moda: 'Mode',
                modaInfo: 'The range that repeats the most in the set of indicators in this group.',
                media: 'Average',
                mediaInfo: 'The range in which the arithmetic average of the performances of the indicators in this group fits.',
                realizado: 'Realized',
                performance: 'Performance',
                grupo: 'Group',
                avaliacao: 'Evaluation',
                indicador: 'Indicator',
                resultado: 'Result',
                classificacao: 'Classification',
                calculoEditTitle: 'Edit Calculation Type',
                calculoEditInfo: 'Select the calculation type of the range for the indicators of this group or select a fixed range for this Group based on its evaluation.',
                salvar: 'Save',
                cancelar: 'Cancel',
                displayFaixas: 'Display Indicators Ranges',
                hideFaixas: 'Hide Indicators Ranges',
            },
            'es': {
                title: 'Performance',
                calculo: 'Tipo de cálculo del rango',
                moda: 'Moda',
                modaInfo: 'La franja que más se repite en el conjunto de indicadores de este grupo.',
                media: 'Média',
                mediaInfo: 'La franja en la que se encuadra la media aritmética de las actuaciones de los indicadores de este grupo.',
                realizado: 'Realizado',
                performance: 'Performance',
                grupo: 'Grupo',
                avaliacao: 'Evaluación',
                indicador: 'Indicador',
                resultado: 'Resultado',
                classificacao: 'Clasificación',
                calculoEditTitle: 'Edición del Tipo de Cálculo',
                calculoEditInfo: 'Seleccione el tipo de cálculo para definir la franja de este Grupo o seleccione una franja fija para este Grupo en función de su evaluación.',
                salvar: 'Salvar',
                cancelar: 'Cancelar',
                displayFaixas: 'Mostrar Franjas de los Indicadores',
                hideFaixas: 'Ocultar Franjas de los Indicadores',
            },
        });

        return message[this.props.lang];
    }

    infoGrupo = (faixa) => {
        return (
            <div dangerouslySetInnerHTML={{ __html: faixa.informativo }} />
        );
    }

    handleEditClick = (grupo, faixas) => {
        const indicador = Object.values(grupo.indicadores)[0]; // Pega o primeiro indicador para acessar as faixas
        this.setState({
            editingGroupId: grupo.grupo.id,
            editingGroupName: grupo?.grupo?.textos?.find(texto => texto.id_idioma === IdiomaHelper.getSiglaId(this.props.lang))?.descricao || '',
            availableFaixas: indicador.indicadorRef.faixas || [],
            selectedFaixa: grupo?.faixa?.save ? grupo.faixa.id : this.state.calculo,
            showModal: true,
        });
    }

    handleFaixaChange = async (grupoId, faixaId) => {
        this.setState({ miniLoading: true, showModal: false });
        
        try {
            // Adicione aqui sua lógica para salvar a mudança via API
            await DiagnosticoHelper.updateGrupoFaixaPerformanceTabela(grupoId, faixaId, this.state.idItem);
            
            // Atualiza a interface
            await this.loadIndicadoresPerformance(false);
            this.props.onUpdateFaixa && this.props.onUpdateFaixa();

        } catch (error) {
            console.error("Erro ao atualizar faixa:", error);
        } finally {
            this.setState({ 
                editingGroupId: null,
                miniLoading: false 
            });
        }
    }

    handleCloseModal = () => {
        this.setState({ showModal: false, editingGroupId: null });
    }

    renderEditButton(grupo, faixa) {
        return (
            this.props.canEdit && this.props.status != 1 ? (
                <FontAwesomeIcon 
                    icon={faEdit} 
                    style={{cursor: 'pointer'}}
                    onClick={() => this.handleEditClick(grupo, faixa)}
                />
            ) : <div/>
        );
    }

    renderEditModal() {

        let faixas = this.state.availableFaixas || [];

        faixas.sort((a, b) => a.min + b.min);

        let faixasSelect = faixas.map(faixa => ({
            value: faixa.id,
            label: faixa.textos.find(t => t.id_idioma === IdiomaHelper.getSiglaId(this.props.lang))?.texto
        }));

        faixasSelect = [...this.state.typesCalc, ...faixasSelect];

        return (
            <Modal show={this.state.showModal} onHide={this.handleCloseModal}>
                <Modal.Header>
                    <Modal.Title>{this.message().calculoEditTitle}: <strong>{this.state.editingGroupName}</strong></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{
                        marginBottom: '15px',
                        fontSize: '0.8rem',
                        color: '#666',
                    }}>
                        {this.message().calculoEditInfo}
                    </div>

                    <Select
                        value={faixasSelect.find(faixa => faixa.value === this.state.selectedFaixa)}
                        options={faixasSelect}
                        onChange={(e) => this.setState({ selectedFaixa: e ? e.value : null })}
                        styles={{
                            control: (provided) => ({
                                ...provided,
                                fontSize: '0.8rem',
                                padding: '2px',
                                maxWidth: '100%',
                                minWidth: '100%',
                            })
                        }}
                        autoFocus
                    />
                </Modal.Body>
                <Modal.Footer>
                    <DefaultButton
                        color={Colors.error}
                        leftIcon={<FontAwesomeIcon icon={faTimes} />}
                        onClick={this.handleCloseModal}
                        loading={this.state.loading}
                        title={this.message().cancelar}
                    />
                    <DefaultButton
                        color={Colors.success}
                        leftIcon={<FontAwesomeIcon icon={faSave} />}
                        title={this.message().salvar}
                        loading={this.state.loading}
                        onClick={() => this.handleFaixaChange(this.state.editingGroupId, this.state.selectedFaixa)}
                    />
                </Modal.Footer>
            </Modal>
        );
    }

    renderTabelaColuna(grupos) {
        return (
            <div className="table-container">
                <table>
                    <thead>
                        <tr>
                            <th className={'sticky-left-header-column'} style={{ width: '200px' }}>
                                {this.message().grupo}
                            </th>

                            {grupos.map((grupo) => {
                                let indicadores = grupo?.indicadores || [];
                                const indicadoresArray = Array.isArray(indicadores) ? indicadores : Object.values(indicadores);
                                return (
                                    <th key={`coluna-${grupo.grupo.id}`} colSpan={indicadoresArray.length}>
                                        {grupo?.grupo?.textos?.find(texto => texto.id_idioma === IdiomaHelper.getSiglaId(this.props.lang))?.descricao || ''}
                                    </th>
                                )
                            })}
                        </tr>

                        <tr>
                            <th className={'sticky-left-header-column'} style={{ width: '200px' }}>
                                {this.message().avaliacao}
                            </th>

                            {grupos.map((grupo) => {

                                let indicadores = grupo?.indicadores || [];
                                const indicadoresArray = Array.isArray(indicadores) ? indicadores : Object.values(indicadores);
                                const faixa = grupo?.faixa;

                                return (
                                    <th key={`coluna-avaliacao-${grupo.grupo.id}`} colSpan={indicadoresArray?.length} style={{ backgroundColor: faixa?.cor, color: '#fff' }}>
                                        <div style={{
                                            ...EssentialStyle.rowFlexCenter, 
                                            gap: 5,
                                            width: '100%',
                                            justifyContent: 'space-between' // Coloca elementos nas extremidades
                                        }}>
                                            <div/>

                                            <div style={{...EssentialStyle.rowFlexCenter, gap: 5}}>
                                                {faixa?.textos?.find(texto => texto.id_idioma === IdiomaHelper.getSiglaId(this.props.lang))?.texto}
                                                
                                                <CustomTooltip tooltip={this.infoGrupo(faixa)} maxWidth={'150px'}>
                                                    <FontAwesomeIcon icon={faInfoCircle} />
                                                </CustomTooltip>
                                            </div>
                                            
                                            {this.renderEditButton(grupo, faixa)}
                                        </div>
                                    </th>
                                )
                            })}
                        </tr>
                    </thead>

                    <tbody>
                        <tr>
                            <th className={'sticky-left-header-column'} style={{ width: '200px' }}>
                                {this.message().indicador}
                                <span className="indicador-expand-faixas-vertical" onClick={() => this.setState({ expandFaixas: !this.state.expandFaixas })}>
                                    <CustomTooltip tooltip={this.state.expandFaixas ? this.message().hideFaixas : this.message().displayFaixas}>
                                        <FontAwesomeIcon icon={this.state.expandFaixas ? faChevronUp : faChevronDown} />
                                    </CustomTooltip>
                                </span>
                            </th>

                            {grupos.map((grupo) => {
                                let indicadores = grupo?.indicadores || [];
                                const indicadoresArray = Array.isArray(indicadores) ? indicadores : Object.values(indicadores);
                                
                                return indicadoresArray.map((indicador) => {
                                    return (
                                        <td key={`indicador-${indicador.indicadorRef.id}`}>
                                            <EllipsisText text={indicador.indicadorRef.indicadorNome} />
                                        </td>
                                    )
                                });
                            })}
                        </tr>

                        { this.state.displayFaixas && this.state.expandFaixas && this.state.faixas.map((faixa, index) => {
                                return (
                                    <tr key={`faixa-${faixa.id}`}>
                                        <th className={'sticky-left-header-column'} style={{ width: '200px', padding: 0 }}>
                                            <div style={{...EssentialStyle.rowFlexCenter, gap: 5, width: '100%', height: '100%', backgroundColor: faixa.cor, color: '#fff', padding: '5px'}}>
                                                <span style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', flex: '1' }}>
                                                    {faixa.textos.find(texto => texto.id_idioma === IdiomaHelper.getSiglaId(this.props.lang))?.texto}
                                                </span>
                                                <FontAwesomeIcon icon={index == 0 ? faLessThan : faGreaterThanEqual} style={{ marginLeft: '5px' }}/>
                                            </div>
                                        </th>

                                        {grupos.map((grupo) => {
                                            let indicadores = grupo?.indicadores || [];
                                            const indicadoresArray = Array.isArray(indicadores) ? indicadores : Object.values(indicadores);
                                            
                                            return indicadoresArray.map((indicador) => {
                                                return (
                                                    <td key={`faixa-${indicador.indicadorRef.faixas.id}-indicador-${indicador.indicadorRef.id}`}>
                                                        {index == 0 ? indicador?.faixasRef?.find(f => f.id_faixa === faixa.id)?.max : indicador?.faixasRef?.find(f => f.id_faixa === faixa.id)?.min} {this.getUnitSymbol(indicador)}
                                                    </td>
                                                )
                                            });
                                        })}
                                    </tr>
                                )
                            })
                        }

                        <tr>
                            <th className={'sticky-left-header-column'} style={{ width: '200px' }}>
                                {(this.message()[`${this.state.typeVisualizacao}`])}
                            </th>

                            {grupos.map((grupo) => {

                                let indicadores = grupo?.indicadores || [];
                                const indicadoresArray = Array.isArray(indicadores) ? indicadores : Object.values(indicadores);
                                
                                return indicadoresArray.map((indicador) => {

                                    let realizadoFormat = indicador.resultados.realizadoRealizado || '0';
                                    let performanceFormat = indicador.resultados.performanceRealizado || '0';
                                    let valorFormat = this.state.typeVisualizacao == 'realizado' ? realizadoFormat : performanceFormat;
                                    let color = indicador?.resultados?.faixa?.cor || '#000';

                                    return (
                                        <td 
                                            key={`indicador-resultado-${indicador.indicadorRef.id}`} 
                                            style={{ backgroundColor: color, color: '#fff', fontWeight: 'bold', fontSize: '1rem' }}>
                                            {valorFormat}
                                        </td>
                                    )
                                });
                            })}
                        </tr>
                    </tbody>
                </table>
                {this.renderEditModal()}
            </div>
        )
    }

    getUnitSymbol(indicador) {
        const unitType = indicador?.indicadorRef?.indicador?.unidade_medida;
        switch (unitType) {
            case 'Percentual':
            case 'Percentual por Cálculo':
                return '%';
            case 'Moeda':
            case 'Moeda por Cálculo':
                return this.getMoeda(this.state.periodoIndicadorReferencia.indicador.moeda);
            case 'Quantidade':
            case 'Quantidade por Cálculo':
                return '';
            case 'Dias':
            case 'Dias por Cálculo':
                return this.state.isSmallScreen ? this.message().diasSmall : this.message().dias;
            case 'Horas':
            case 'Horas por Cálculo':
                return this.state.isSmallScreen ? this.message().horasSmall : this.message().horas;
            default:
                return '';
        }
    }

    renderTabelaLinha(grupos) {
        return (
            <div className="table-container">
                <table>
                    <thead style={{ top: this.state.headerPosition }} className={`sticky-header`}>
                        <tr>
                            <th>{this.message().grupo}</th>
                            <th>{this.message().avaliacao}</th>
                            <th>
                                <div className="expand-faixas-th">
                                    {this.message().indicador}
                                    <span className="indicador-expand-faixas-horizontal" onClick={() => this.setState({ expandFaixas: !this.state.expandFaixas })}>
                                        <CustomTooltip tooltip={this.state.expandFaixas ? this.message().hideFaixas : this.message().displayFaixas}>
                                            <FontAwesomeIcon icon={this.state.expandFaixas ? faChevronLeft : faChevronRight} />
                                        </CustomTooltip>
                                    </span>
                                </div>
                            </th>
                            {this.state.displayFaixas && this.state.expandFaixas && this.state.faixas.map((faixa, index) => {
                                return (
                                    <th key={`faixa-${faixa.id}`} style={{ width: '200px', padding: 0 }}>
                                        <div style={{...EssentialStyle.rowFlexCenter, gap: 5, width: '100%', height: '100%', backgroundColor: faixa.cor, color: '#fff', padding: '5px'}}>
                                            <span style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', flex: '1' }}>
                                                {faixa.textos.find(texto => texto.id_idioma === IdiomaHelper.getSiglaId(this.props.lang))?.texto}
                                            </span>
                                            <FontAwesomeIcon icon={index == 0 ? faLessThan : faGreaterThanEqual} style={{ marginLeft: '5px' }}/>
                                        </div>
                                    </th>
                                )
                            })}
                            <th>{(this.message()[`${this.state.typeVisualizacao}`])}</th>
                        </tr>
                    </thead>

                    <tbody>
                        {grupos.map((grupo) => {
                            let indicadores = grupo?.indicadores || [];
                            let faixa = grupo?.faixa;
                            const indicadoresArray = Array.isArray(indicadores) ? indicadores : Object.values(indicadores);

                            return indicadoresArray.map((indicador, index) => {

                                let realizadoFormat = indicador.resultados.realizadoRealizado || '0';
                                let performanceFormat = indicador.resultados.performanceRealizado || '0';
                                let valorFormat = this.state.typeVisualizacao == 'realizado' ? realizadoFormat : performanceFormat;
                                let color = indicador?.resultados?.faixa?.cor || '#000';

                                return (
                                    <tr key={indicador.indicadorRef.id}>
                                        {index == 0 &&
                                            <th rowSpan={indicadoresArray.length} style={{ backgroundColor: '#fff' }}>
                                                {grupo?.grupo?.textos?.find(texto => texto.id_idioma === IdiomaHelper.getSiglaId(this.props.lang))?.descricao || ''}
                                            </th>
                                        }

                                        {index == 0 &&
                                            <th rowSpan={indicadoresArray.length} style={{ backgroundColor: '#fff' }}>
                                                <div style={{
                                                    ...EssentialStyle.rowFlexCenter, 
                                                    gap: 5,
                                                    width: '100%',
                                                    justifyContent: 'space-between' // Coloca elementos nas extremidades
                                                }}>
                                                    <div/>

                                                    <div style={{...EssentialStyle.rowFlexCenter, padding: '5px', borderRadius: '5px', backgroundColor: faixa?.cor, color: '#fff', gap: '5px', fontSize: '1rem'}}>
                                                        {faixa?.textos?.find(texto => texto.id_idioma === IdiomaHelper.getSiglaId(this.props.lang))?.texto}
                                                    
                                                        <CustomTooltip tooltip={this.infoGrupo(faixa)} maxWidth={'150px'}>
                                                            <FontAwesomeIcon icon={faInfoCircle} />
                                                        </CustomTooltip>
                                                    </div>

                                                    {this.renderEditButton(grupo, faixa)}

                                                </div>
                                            </th>
                                        }

                                        <td key={`indicador-${indicador.id}`}>
                                            <EllipsisText text={indicador.indicadorRef.indicadorNome} />
                                        </td>

                                        {this.state.displayFaixas && this.state.expandFaixas && this.state.faixas.map((faixa, index) => {
                                            return (
                                                <td key={`faixa-${faixa.id}-indicador-${indicador.indicadorRef.id}`}>
                                                    {index == 0 ? indicador?.faixasRef?.find(f => f.id_faixa === faixa.id)?.max : indicador?.faixasRef?.find(f => f.id_faixa === faixa.id)?.min} {this.getUnitSymbol(indicador)}
                                                </td>
                                            )
                                        }
                                        )}

                                        <td 
                                            key={`indicador-resultado-${indicador.indicadorRef.id}`} 
                                            style={{ backgroundColor: color, color: '#fff', fontWeight: 'bold', fontSize: '1rem' }}>
                                            {valorFormat}
                                        </td>
                                    </tr>
                                )
                            });
                        })}
                    </tbody>
                </table>
                {this.renderEditModal()}
            </div>
        )
    }

    renderTabela() {

        let grupos = this.state.estrutura;

        // Ordenar pelo drag_index
        grupos.sort((a, b) => {
            return a?.grupo?.drag_index - b?.grupo?.drag_index;
        });

        return (
            <div className="RelatorioPerformanceTabelaItem" style={{ boxShadow: Colors.boxShadow }} ref={this.itemRef}>
                {this.state.miniLoading && <div className="miniLoading"><DefaultLoader size={15}/></div>}
                <div className={`headerIndicadoresPerformanceTabela ${this.state.pinHeader && this.state.formatoTabela != 'coluna' ? 'pin-header' : ''}`} style={{ top: this.state.headerPosition }} ref={this.headerRef}>{this.message().title}</div>
                {this.state.formatoTabela == 'coluna' ? this.renderTabelaColuna(grupos) : this.renderTabelaLinha(grupos)}
            </div>
        )
    }

    renderLoading() {
        return (
            <div className="RelatorioPerformanceItem" style={{ boxShadow: Colors.boxShadow }}>
                <DefaultLoader/>
            </div>
        )
    }

    render() {
        
        if (this.state.loading) return this.renderLoading();
        if (this.state.estrutura.length === 0) return (null);
        return this.renderTabela();
    }
}

export default withTranslation()(RelatorioPerformanceTabelaItem);