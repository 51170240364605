import React from "react";
import Modal from "../../../tools/Modal";
import EssentialStyle from "../../../../style/EssentialStyle";
import DefaultButton from "../../../tools/DefaultButton";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import Colors from "../../../../constants/Colors";
import { toast } from 'react-toastify';
import Sig from "../../../../api/Sig";
import TextEditor from "../../../tools/TextEditor/TextEditor";
import DataHelper from "../../../../helper/DataHelper";
import Select from "react-select";

export default class AtaReuniaoFinalizacaoModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            isSmallScreen: this.props.isSmallScreen,
            participantesANotificar: DataHelper.formatSelectData(this.props.participantes.filter((p) => !p.recebeu_email_ata_finalizada), 'email', 'nome'),
            participantes: this.props.participantes.filter(p => p.participou).length > 0 ? DataHelper.formatSelectData(this.props.participantes.filter(p => p.participou), 'email', 'nome') : DataHelper.formatSelectData(this.props.participantes, 'email', 'nome'),
            participantesAssinatura: this.props.participantes.filter(p => p.deve_assinar).length > 0 ? DataHelper.formatSelectData(this.props.participantes.filter(p => p.deve_assinar), 'email', 'nome') : DataHelper.formatSelectData(this.props.participantes, 'email', 'nome'),
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isSmallScreen !== this.props.isSmallScreen) {
            this.setState({ isSmallScreen: this.props.isSmallScreen });
        }
        if (prevProps.participantes !== this.props.participantes && this.props.participantes != this.state.participantesANotificar) {
            this.setState({ 
                participantesANotificar: DataHelper.formatSelectData(this.props.participantes), 
                participantes: this.props.participantes.filter(p => p.participou).length > 0 ? DataHelper.formatSelectData(this.props.participantes.filter(p => p.participou), 'email', 'nome') : DataHelper.formatSelectData(this.props.participantes, 'email', 'nome'),
                participantesAssinatura: this.props.participantes.filter(p => p.deve_assinar).length > 0 ? DataHelper.formatSelectData(this.props.participantes.filter(p => p.deve_assinar), 'email', 'nome') : DataHelper.formatSelectData(this.props.participantes, 'email', 'nome'),
            });
        }
    }

    render() {
        const modalRoot = document.getElementById('modal-root');

        return (
            <Modal style={{ width: this.state.isSmallScreen ? '90vw' : '70vw' }}>
                <div style={{...EssentialStyle.columnCenter, width: '100%', minHeight: 350, maxHeight: "90%", overflowY: "auto"}}>
                    <div style={{...EssentialStyle.rowSpaceBetween, width: 'auto', padding: 10, alignSelf: "flex-end", marginBottom: 10, position: "absolute", top: 10}}>
                        <DefaultButton
                                leftIcon={<FontAwesomeIcon icon={faClose} />}
                                color={"transparent"}
                                textColor={Colors.dark}
                                style={{width: 35, height: 35, padding: 0, alignSelf: 'flex-end'}}
                                onClick={this.props.closeCallback}
                            />
                    </div>
                    <span style={{fontSize: 20, fontWeight: 500, marginBottom: 5, color: Colors.homePage.darkGrey}}>Quem participou da Reunião?</span>
                    <div style={{...EssentialStyle.columnCenter, width: '100%', padding: "0px 20px", height: "100%", marginBottom: 20}}>
                        <Select
                            menuPortalTarget={modalRoot}
                            styles={{ container: (base) => ({ ...base, width: '100%' }), menuPortal: (base) => ({ ...base, zIndex: 9999, position: "absolute"}) }}
                            options={DataHelper.formatSelectData(this.props.participantes, 'email', 'nome')}
                            placeholder={'Selecione quem participou da Reunião'}
                            noOptionsMessage={() => "Todos os participantes possíveis já foram selecionados"}
                            isClearable isSearchable isMulti
                            value={this.state.participantes}
                            onChange={(value) => { this.setState({ participantes: value }) }}
                        />
                    </div>
                    <span style={{fontSize: 20, fontWeight: 500, marginBottom: 5, color: Colors.homePage.darkGrey}}>Quem deve ser notificado?</span>
                    <div style={{...EssentialStyle.columnCenter, width: '100%', padding: "0px 20px", height: "100%", marginBottom: 20}}>
                        <Select
                            menuPortalTarget={modalRoot}
                            formatOptionLabel={({ label, value }) => (
                                <div style={{
                                    ...EssentialStyle.rowFlexStart,
                                    paddingLeft: 8,
                                    fontWeight: 400,
                                    width: '100%',
                                    cursor: 'pointer',
                                }}>
                                    {this.props.participantes.find(p => p.email == value)?.recebeu_email_ata_finalizada ? label + " (Já Notificado)" : label}
                                </div>
                            )}
                            styles={{ container: (base) => ({ ...base, width: '100%' }), menuPortal: (base) => ({ ...base, zIndex: 9999, position: "absolute"}) }}
                            options={DataHelper.formatSelectData(this.props.participantes, 'email', 'nome')}
                            placeholder={this.props.participantes.filter(p => !p.recebeu_email_ata_finalizada).length == 0 ? 'Todos participantes já foram notificados, selecione quem deve ser notificado novamente' : 'Selecione os participantes que serão Notificados'}
                            noOptionsMessage={() => "Todos os participantes possíveis já foram selecionados"}
                            isClearable isSearchable isMulti
                            value={this.state.participantesANotificar}
                            onChange={(value) => { this.setState({ participantesANotificar: value }) }}
                        />
                    </div>
                    <span style={{fontSize: 20, fontWeight: 500, marginBottom: 5, color: Colors.homePage.darkGrey}}>Quem deve Assinar a Ata?</span>
                    <div style={{...EssentialStyle.columnCenter, width: '100%', padding: "0px 20px", height: "100%", marginBottom: 10}}>
                        <Select
                            menuPortalTarget={modalRoot}
                            styles={{ container: (base) => ({ ...base, width: '100%' }), menuPortal: (base) => ({ ...base, zIndex: 9999, position: "absolute"}) }}
                            options={DataHelper.formatSelectData(this.props.participantes, 'email', 'nome')}
                            placeholder={'Selecione quem deve assinar a Ata de Reunião'}
                            noOptionsMessage={() => "Todos os participantes possíveis já foram selecionados"}
                            isClearable isSearchable isMulti
                            value={this.state.participantesAssinatura}
                            onChange={(value) => { this.setState({ participantesAssinatura: value }) }}
                        />
                    </div>
                </div>
                <DefaultButton
                    disabled={this.state.loading}
                    style={{placeSelf: 'flex-end', margin: 10}}
                    color={Colors.success}
                    title={"Finalizar Ata"}
                    onClick={() => {
                        this.setState({ loading: true });
                        this.props.changeAtaStatusCallback(this.state.participantes, this.state.participantesANotificar, this.state.participantesAssinatura);
                        this.props.closeCallback();
                    }}
                />
            </Modal>
        );
    }
}