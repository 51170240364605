import React from "react";

import './StarPicker.css';
import IdiomaHelper from "../../../helper/IdiomaHelper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar, faMinusCircle, faPlusCircle } from "@fortawesome/free-solid-svg-icons";


export default class StarPicker extends React.Component {

    state = {
        max: this.props.max || 5,
        value: this.props.value || 1,

        hover: null,
    }

    componentDidMount() {
        this.setState({ max: this.props.max, value: this.props.value });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.max !== this.props.max) {
            this.setState({ max: this.props.max });
        }

        if (prevProps.value !== this.props.value || this.state.value !== this.props.value) {
            this.setState({ value: this.props.value });
        }

        if (this.state.value > this.props.max) {
            this.setState({ value: this.props.max }, () => {
                this.props.onChange && this.props.onChange(this.state.value);
            });
        }
    }

    message = (lang) => {
        return IdiomaHelper.getStruct({
            'pt_br': { 
                estrela: 'Exemplo de Escala de Estrela',
            },
            'en': { 
                estrela: 'Example of Star Scale',
            },
            'es': { 
                estrela: 'Ejemplo de Escala de Estrella',
            },
        })[lang];
    }

    renderPreView() {
        return (
            <div className="StarPicker" style={{ ...this.props.styleContainer }}>
                {
                    this.props.title &&
                    <div className="star-title">
                        {this.props.title ? this.props.title : null}
                    </div>
                }
                <div className="star-content">
                    {<FontAwesomeIcon 
                        icon={faMinusCircle} 
                        style={{ fontSize: '1rem', color: '#d0d0d0', marginRight: 5, cursor: this.props.disabled ? 'default' : 'pointer' }} 
                        onClick={() => { 
                            if(this.props.disabled) return;

                            (this.state.value - 1 >= 1) && (this.setState({value: this.state.value - 1}, () => { 
                                this.props.onChange && this.props.onChange(this.state.value); 
                            }))
                        }}
                    />}
                    <div className="stars">
                        {Array.from({ length: this.state.max }).map((_, i) => (
                            <FontAwesomeIcon
                                key={`star-${i}`}
                                icon={faStar}
                                style={{ ...this.props.styleStar }}
                                className={`star ${(i < this.state.value) ? 'active' : ''} ${i < this.state.hover ? 'hover' : ''} ${this.props.disabled ? 'star-disabled' : ''}`}
                                onClick={() => {
                                    if(this.props.disabled) return;
                                    this.setState({ value: i + 1 });
                                    this.props.onChange && this.props.onChange(i + 1);
                                }}
                                onMouseEnter={() => { this.setState({ hover: i + 1 }); }}
                                onMouseLeave={() => { this.setState({ hover: null }); }}
                            />
                        ))}
                    </div>
                    {<FontAwesomeIcon 
                        icon={faPlusCircle} 
                        style={{ fontSize: '1rem', color: '#d0d0d0', marginLeft: 5, cursor: this.props.disabled ? 'default' : 'pointer' }} 
                        onClick={() => { 
                            if(this.props.disabled) return;

                            (this.state.value + 1 <= this.state.max) && (this.setState({value: this.state.value + 1}, () => { 
                                this.props.onChange && this.props.onChange(this.state.value); 
                            }))
                        }}
                    />}
                </div>
            </div>
        )
    }

    render() {
        return this.renderPreView();
    }
}