import React from "react";
import { withTranslation } from "react-i18next";

import './OrcamentoProjeto.css';
import DefaultLoader from "../../../../tools/DefaultLoader";
import Colors from "../../../../../constants/Colors";
import EssentialStyle from "../../../../../style/EssentialStyle";
import PlanoAcaoOrcamentoCard from "./PlanoAcaoOrcamentoCard";

class OrcamentoProjetos extends React.Component {

    state = {
        loading: true,
        isSmallScreen: this.props.isSmallScreen,
        planos: this.props.planos || [],
        totalPrevistoValue: this.props.orcamento.orcamento_previsto_value,
        totalRealizadoValue: this.props.orcamento.orcamento_realizado_value,
        saldoOrcamentoValue: this.props.orcamento.orcamento_saldo_value,
        totalPrevisto: this.props.orcamento.orcamento_previsto,
        totalRealizado: this.props.orcamento.orcamento_realizado,
        saldoOrcamento: this.props.orcamento.orcamento_saldo,
    }

    componentDidUpdate(prevProps) {
        if(prevProps.orcamento !== this.props.orcamento) {
            this.setState({
                totalPrevistoValue: this.props.orcamento.orcamento_previsto_value,
                totalRealizadoValue: this.props.orcamento.orcamento_realizado_value,
                saldoOrcamentoValue: this.props.orcamento.orcamento_saldo_value,
                totalPrevisto: this.props.orcamento.orcamento_previsto,
                totalRealizado: this.props.orcamento.orcamento_realizado,
                saldoOrcamento: this.props.orcamento.orcamento_saldo,
            });
        }

        if (prevProps.planos !== this.props.planos) {
            this.setState({ planos: this.props.planos });
        }
        if (prevProps.isSmallScreen !== this.props.isSmallScreen) {
            this.setState({ isSmallScreen: this.props.isSmallScreen });
        }
    }

    async componentDidMount() {
        await this.props.i18n.loadNamespaces(['client_src_components_modules_pe_projeto_OrcamentoProjetos_OrcamentoProjetos']);
        this.setState({ loading: false });
    }

    renderTotalGeral() {

        return (
            <div className={"box-orcamento-total"}>
                <div className={`celula`} style={{borderTopLeftRadius: 8, borderBottomLeftRadius: 8}}>
                    {this.props.i18n.t('client_src_components_modules_pe_projeto_OrcamentoProjetos_OrcamentoProjetos:Total_Previsto')}
                    <span className={`celula-valor`}>{this.state.totalPrevistoValue}</span>
                </div>

                <div className={`celula`}>
                    {this.props.i18n.t('client_src_components_modules_pe_projeto_OrcamentoProjetos_OrcamentoProjetos:Total_Realizado')}
                    <span className={`celula-valor`}>{this.state.totalRealizadoValue}</span>
                </div>

                <div className={`celula`} style={{backgroundColor: (this.state.saldoOrcamento >= 0 ? Colors.success : Colors.error), borderTopRightRadius: 8, borderBottomRightRadius: 8, color: Colors.white}}>
                    {this.props.i18n.t('client_src_components_modules_pe_projeto_OrcamentoProjetos_OrcamentoProjetos:Saldo_Total')}
                    <span className={`celula-valor`}>{this.state.saldoOrcamentoValue}</span>
                </div>
            </div>
        )
    }

    renderList() {
        var filteredPlanos = [...this.state.planos].filter((p) => {return((p.orcamento_previsto_value) || (p.orcamento_realizado_value))});

        if(filteredPlanos.length == 0) return null;

        return (
            <div style={{minWidth: "100%", width: 'max-content', marginTop: 10}}>
                <div style={{...EssentialStyle.rowFlexStart, width: "auto", zIndex: 1}}>
                    <div style={{
                        ...EssentialStyle.rowSpaceBetween,
                        backgroundColor: Colors.white,
                        fontSize: 14,
                        width: '100%',
                        borderTopLeftRadius: 5,
                        borderTopRightRadius: 5,
                        border: `1px solid ${Colors.border}`,
                        borderBottom: "none",
                        padding: 10,
                    }}>
                        <div style={{ ...EssentialStyle.rowFlexStart, width: 'calc(100% - 575px)', minWidth: 100 }}>
                            <span
                                style={{ fontWeight: 500, width: "100%", textAlign: 'left', overflow: 'hidden', textOverflow: 'ellipsis', textWrap: 'nowrap' }}
                            >
                                {this.props.i18n.t('client_src_components_modules_pe_projeto_OrcamentoProjetos_OrcamentoProjetos:Plano_de_Acao')}
                            </span>
                        </div>
                        <div style={{ ...EssentialStyle.rowFlexStart, width: "480px", gap: 15}}>
                            <span
                                style={{ fontWeight: 500, width: 150, textAlign: 'left' }}
                            >
                                {this.state.isSmallScreen ? this.props.i18n.t('client_src_components_modules_pe_projeto_OrcamentoProjetos_OrcamentoProjetos:Previsto') : this.props.i18n.t('client_src_components_modules_pe_projeto_OrcamentoProjetos_OrcamentoProjetos:Orcamento_Previsto')}
                            </span>
                            <span
                                style={{ fontWeight: 500, width: 150, textAlign: 'left' }}
                            >
                                {this.state.isSmallScreen ? this.props.i18n.t('client_src_components_modules_pe_projeto_OrcamentoProjetos_OrcamentoProjetos:Realizado') : this.props.i18n.t('client_src_components_modules_pe_projeto_OrcamentoProjetos_OrcamentoProjetos:Orcamento_Realizado')}
                            </span>
                            <span
                                style={{ fontWeight: 500, width: 150, textAlign: 'left', paddingLeft: 15 }}
                            >
                                {this.props.i18n.t('client_src_components_modules_pe_projeto_OrcamentoProjetos_OrcamentoProjetos:Saldo')}
                            </span>
                        </div>
                        <span
                            style={{ fontWeight: 500, width: '80px', textAlign: 'left' }}
                            >
                            {this.props.i18n.t('client_src_components_modules_pe_projeto_OrcamentoProjetos_OrcamentoProjetos:Responsavel')}
                        </span>
                    </div>
                </div>
                {filteredPlanos.map((plano, index) => {
                    return (
                        <PlanoAcaoOrcamentoCard 
                            key={"plano-"+index} 
                            plano={plano} 
                            isSmallScreen={this.state.isSmallScreen}
                            borderBottom={index == filteredPlanos.length - 1 ? `1px solid ${Colors.border}` : 'none'}
                        />
                    )
                })}
            </div>
        )
    }

    renderLoading() {
        return (
            <div className={"loading"}>
                <DefaultLoader />
            </div>
        )
    }

    render() {

        if (this.state.loading) return this.renderLoading();
        return (
            <div className={"box-orcamento"}>
                {this.renderTotalGeral()}
                <div style={{width: '100%', minWidth: "100%", maxWidth: "100%", overflowX: "auto"}}>
                    {this.renderList()}
                </div>
            </div>
        );
    }
}

export default withTranslation()(OrcamentoProjetos);