import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpBackend from 'i18next-http-backend';

import Constants from './constants/Api';

let initParams = {
    backend: {
        loadPath: `${Constants.getSigEndPoint()}/locale/getTranslations?lang={lng}&namespace={ns}` // Path to load translation files from Yii backend
    },
    lng: 'pt_br', // Default language
    fallbackLng: 'pt_br', // Fallback language
    ns: [], // Start with an empty namespace array for dynamic loading
    defaultNS: [], // Default namespace
    interpolation: {
        escapeValue: false, // React already safes from xss
        prefix: '{', // Custom prefix for interpolation
        suffix: '}'  // Custom suffix for interpolation
    } // Return empty string for missing keys during parsing
};

if(Constants.isProd()) {
    initParams.parseMissingKeyHandler = () => '-';
} else {
    //To enable debug mode
    // initParams.debug = true;
}

i18n
    .use(HttpBackend) // Load translations using http backend
    .use(initReactI18next) // Passes i18n down to react-i18next
    .init(initParams);

export default i18n;