// Bibliotecas externas
import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle, faSave } from '@fortawesome/free-solid-svg-icons';
import { withTranslation } from 'react-i18next';

// Helpers
import SessionHelper from "../../../../../../helper/SessionHelper";
import DiagnosticoHelper from "../../../../../../helper/diagnostico/DiagnosticoHelper";
import IdiomaHelper from "../../../../../../helper/IdiomaHelper";

// Estilos
import Colors from "../../../../../../constants/Colors";
// - Comentado até que seja adicionado algo - 
// import './AddTexto.css';

// Componentes
import DefaultButton from "../../../../../tools/DefaultButton";
import DefaultLoader from "../../../../../tools/DefaultLoader";
import TextEditor from "../../../../../tools/TextEditor/TextEditor";


class AddTexto extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,

            item: props.item,

            grupos: [],
            indicadores: [],

            grupoSelected: null,
            widthSelected: 6,

            openModal: false,

            draggingItem: null,
            draggingOverItem: null,
            widthDragItem: 0,
            left: false,

            headerHeight: 0,

            chartTypes: [],
            chartType: null,
            typesCalc: [], 
            typeCalc: null,
            typesVisualizacao: [],
            typeVisualizacao: null,
            
        }
            
    }

    headerRef = React.createRef();

    async componentDidMount() {
        await this.props.i18n.loadNamespaces(['client_src_components_modules_diagnostico_CriacaoRelatorio_AddModuloRelatorio_AddTexto_AddTexto']);
        await this.props.i18n.loadNamespaces(['client_src_helper_diagnostico_DiagnosticoHelper']);

        await this.loadData();
        this.measureHeaderHeight();
        window.addEventListener('resize', this.measureHeaderHeight);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.measureHeaderHeight);
    }

    measureHeaderHeight = () => {
        const headerElement = document.querySelector('.criacaoRelatorio');
        if (headerElement) {
            const height = headerElement.offsetHeight;
            if (height !== this.state.headerHeight) {
                this.setState({ headerHeight: height });
            }
        }
    }

    async loadData() {
        this.setState({ loading: true });

        let text = this.props.item.text || [];
        if (text.length === 0) {
            IdiomaHelper.getIdiomas().forEach((idiomaObj) => {
                text.push({
                    descricao: '',
                    id_idioma: idiomaObj.id,
                    sigla: idiomaObj.sigla
                });
            });
        }
        
        this.setState({
            item: {
                ...this.props.item,
                on: true,
                type: this.props.type,
                text: text,
            }
        }, () => { this.props.onSetItem(this.state.item) } );

        this.setState({ loading: false });
    }

    setText = (id_idioma, newValue) => {

        this.setState(prevState => ({
            item: {
                ...prevState.item,
                text: prevState.item.text.map(textItem => 
                    textItem.id_idioma === id_idioma
                        ? { ...textItem, descricao: newValue }
                        : textItem
                )
            }
        }), () => {this.props.onSetItem(this.state.item)});
    }

    renderHeader = () => {
        return (
            <div className="headerClose" style={{backgroundColor: SessionHelper.getColor()}}>
                <div/>
                {DiagnosticoHelper.getFunctionsModulosModeloRelatorioByType(this.props.type, this.props.t)?.title ?? ''}
                <FontAwesomeIcon icon={faTimesCircle} style={{ cursor: 'pointer' }} onClick={() => this.props.onClose()} />
            </div>
        )
    }

    renderBody = () => {

        let item = this.state.item;

        const idiomas = IdiomaHelper.getIdiomas();

        return (
            <div className="bodyModulo">
        
                {/* Campos de edição de texto */}
                <div className="content box">
                    {idiomas.map((idiomaObj) => {
                        let descricao = item?.text?.find(textItem => textItem.id_idioma == idiomaObj.id)?.descricao ?? false;
                        if (descricao === false) return null;
                        return (
                            <div key={`input-idioma-${idiomaObj.id}`} className="text">
                                <span>{idiomaObj.nome}</span>
                                <TextEditor
                                    editorClassName={"fill-container-editor"}
                                    placeholder={this.props.i18n.t('client_src_components_modules_diagnostico_CriacaoRelatorio_AddModuloRelatorio_AddTexto_AddTexto:placeholderTextEditor')}
                                    defaultValue={descricao}
                                    onChange={(newValue) => { this.setText(idiomaObj.id, newValue) }}
                                    noMargin
                                />
                            </div>
                        )
                    })}
                </div>

                <div className="buttons">
                    <DefaultButton
                        title={this.props.i18n.t('client_src_components_modules_diagnostico_CriacaoRelatorio_AddModuloRelatorio_AddTexto_AddTexto:saveButtonTitle')}
                        leftIcon={<FontAwesomeIcon icon={faSave}/>}
                        color={Colors.success}
                        tooltip={this.props.i18n.t('client_src_components_modules_diagnostico_CriacaoRelatorio_AddModuloRelatorio_AddTexto_AddTexto:saveButtonTooltip')}
                        onClick={() => {this.props.addModule()}}
                    />
                </div>

            </div>
        );
    }

    renderLoading() {
        return (
            <div className="bodyModulo" style={{justifyContent: 'center', alignItems: 'center', overflow: 'hidden', height: "calc(100% - 3rem)"}}>
                <DefaultLoader/>
            </div>
        )
    }

    renderContent() {

        return (
            <div className="ModuloConfig" style={{maxHeight: `calc(${this.state.headerHeight}px - 20px)`, minHeight: '40vh'}}>
                {this.renderHeader()}
                {!this.state.loading ? this.renderBody() : this.renderLoading()}
            </div>
        )
    }

    render() {
        if (!this.props.type) return <></>;
        return this.renderContent();
    }
}

export default withTranslation()(AddTexto);