import React from "react";

import Colors from "../../../../constants/Colors";
import DefaultButton from "../../../../components/tools/DefaultButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusSquare, faChevronLeft, faPencil, faTrash, faLock, faPaperPlane } from "@fortawesome/free-solid-svg-icons";

import DiagnosticoHelper from "../../../../helper/diagnostico/DiagnosticoHelper";
import IdiomaHelper from "../../../../helper/IdiomaHelper";
import DataHelper from "../../../../helper/DataHelper";
import Select from 'react-select';

import './Questionarios.css';
import Questionario from "./Questionario/Questionario";
import Tabs from "../../../tools/Tabs";

import { confirmAlert } from 'react-confirm-alert';
import CustomConfirm from "../../../tools/CustomConfirm";
import DefaultLoader from "../../../tools/DefaultLoader";
import { withTranslation } from 'react-i18next';

const searchableFields = ['nome'];

class Questionarios extends React.Component {

    state = {
        lang: IdiomaHelper.getIdioma(),
        loadingPeriodosAvaliacao: true,
        periodosAvaliacao: [],
        idPeriodoAvaliacao: null,

        loadingQuestionarios: true,
        questionarios: [],

        canEdit: false,
        canView: false,

        editing: false,
        nameEditing: '',
        isEditingName: false,
        canEditQuantionario: false,

        activeTab: 'one',
        tabs: [
            {
                name: 'one', 
                title: {
                    'pt_br': 'Escala',
                    'en': 'Scale',
                    'es': 'Escala'
                }
            },
            {
                name: 'two', 
                title: {
                    'pt_br': 'Estrutura',
                    'en': 'Structure',
                    'es': 'Estructura'
                }
            },
            {
                name: 'three', 
                title: {
                    'pt_br': 'Questões',
                    'en': 'Questions',
                    'es': 'Preguntas'
                }
            }
        ],
    }

    async componentDidMount() {
        await this.props.i18n.loadNamespaces(['client_src_helper_diagnostico_DiagnosticoHelper']);

        this.loadPeriodos();
    }

    loadPeriodos = async () => {
        
        this.setState({ loadingPeriodosAvaliacao: true });

        let { periodos, canEdit, canView } = await DiagnosticoHelper.getPeriodosAvaliacao(this.props.idProcesso);
        
        let idPeriodoAvaliacao = null;

        let localId = DiagnosticoHelper.getPeriodoAvaliacaoLocal(this.props.idProcesso);

        if (localId && periodos.find(periodo => periodo.id == localId)) {
            idPeriodoAvaliacao = localId;
        } else {
            idPeriodoAvaliacao = periodos.length > 0 ? periodos[0].id : null;
        }
            

        this.setState({ periodosAvaliacao: periodos, idPeriodoAvaliacao, canEdit, canView, loadingPeriodosAvaliacao: false }, () => {
            if (idPeriodoAvaliacao) {
                this.loadQuestionarios();
            }
        });
    }

    loadQuestionarios = async () => {
        this.setState({ loadingQuestionarios: true });
        
        let {questionarios, faixas} = await DiagnosticoHelper.getQuestionarios(this.state.idPeriodoAvaliacao, 0);

        this.setState({ questionarios, faixas, loadingQuestionarios: false });
    }

    changePeriodo = (idPeriodoAvaliacao) => {
        this.setState({ idPeriodoAvaliacao }, () => this.loadQuestionarios());
    }

    deleteQuestionario = async (id) => {

        const deleteQuestionario = async () => {
            let deleteQuestionario = await DiagnosticoHelper.deleteQuestionario(id);

            if (deleteQuestionario) {
                this.loadQuestionarios();
            } else {
                alert('Erro ao deletar questionário - Error to delete questionnaire - Error al eliminar cuestionario');
            }
        }

        let message = IdiomaHelper.getStruct({
            'pt_br': {
                title: 'Excluir Questionário?',
                message: `<p>Deseja realmente Excluir este Questionário? Essa ação não pode ser desfeita e você perderá todas as informações relacionadas a este questionário, como:</p>
                    <ol>
                        <li>Escala de avaliação configurada</li>
                        <li>Estrutura do questionário</li>
                        <li>Questões já inseridas</li>
                    </ol>`,
                confirm: 'Excluir',
                cancel: 'Cancelar',
            },
            'en': {
                title: 'Delete Questionnaire?',
                message: `<p>Do you really want to delete this Questionnaire? This action cannot be undone and you will lose all information related to this questionnaire, such as:</p>
                    <ol>
                        <li>Configured evaluation scale</li>
                        <li>Questionnaire structure</li>
                        <li>Questions already inserted</li>
                    </ol>`,
                confirm: 'Delete',
                cancel: 'Cancel',
            },
            'es': {
                title: '¿Eliminar Cuestionario?',
                message: `<p>¿Realmente quieres eliminar este Cuestionario? Esta acción no se puede deshacer y perderás toda la información relacionada con este cuestionario, como:</p>
                    <ol>
                        <li>Escala de evaluación configurada</li>
                        <li>Estructura del cuestionario</li>
                        <li>Preguntas ya insertadas</li>
                    </ol>`,
                confirm: 'Eliminar',
                cancel: 'Cancelar',
            }
        });
        
        confirmAlert({
            customUI: ({ onClose }) => (
                <CustomConfirm
                    title={message[this.state.lang].title}
                    message={<p style={{ marginTop: 10, marginBottom: 10}}  dangerouslySetInnerHTML={{ __html: message[this.state.lang].message }}/>}
                    buttons={[
                        {
                            label: message[this.state.lang].confirm,
                            color: Colors.error,
                            textColor: Colors.light,
                            onClick: async () => {
                                deleteQuestionario();
                                onClose();
                            }
                        },
                        {
                            label: message[this.state.lang].cancel,
                            onClick: () => { onClose(); }
                        },
                    ]}
                />
            )
        });


    }

    renderHeader() {

        const message = IdiomaHelper.getStruct({
            'pt_br': {
                selectPlaceholder: 'Selecione um Período de Avaliação',
            },
            'en': {
                selectPlaceholder: 'Select an Evaluation Period',
            },
            'es': {
                selectPlaceholder: 'Seleccione un Período de Evaluación',
            }
        });

        return (
            <div className="questionarios-header" style={{boxShadow: Colors.boxShadow}}>
               <div className="header-left">
                    <Select
                        styles={{ control: (base) => ({ ...base, border: 'none', minWidth: '40vh', marginRight: 2 }) }}
                        options={DataHelper.formatSelectData(this.state.periodosAvaliacao, 'id', 'nome')}
                        value={DataHelper.formatSelectData(this.state.periodosAvaliacao, 'id', 'nome').find(option => option.value === this.state.idPeriodoAvaliacao)}
                        onChange={(valueSelected) => {  this.changePeriodo(valueSelected.value); DiagnosticoHelper.setPeriodoAvaliacaoLocal(valueSelected.value, this.props.idProcesso) }}
                        placeholder={message[this.state.lang].selectPlaceholder}
                        noOptionsMessage={DataHelper.getSelectEmptyMessage}
                        isSearchable
                        menuPortalTarget={document.body}
                    />
               </div>

                <div className="header-right">
                    <DefaultButton
                        style={{ width: 38, height: 38 }}
                        fontSize={this.props.isSmallScreen ? 14 : "unset"}
                        color={Colors.secondaryButton}
                        textColor={Colors.dark}
                        link={'https://advis.freshdesk.com/support/solutions/folders/63000238312'}
                    />
                </div>
            </div>
        )
    }

    renderQuestionarios() {

        let message = IdiomaHelper.getStruct({
            'pt_br': { 
                loading: 'Carregando Questionários...',
                add: 'Adicionar Questionário',
                editing: 'Editando ',
             },
            'en': { 
                loading: 'Loading Questionnaires...',
                add: 'Add Questionnaire',
                editing: 'Editing ',
             },
            'es': { 
                loading: 'Cargando Cuestionarios...',
                add: 'Agregar Cuestionario',
                editing: 'Editando ',
             },
        });

        // const handleEditName = async () => {
        //     if (this.state.isEditingName && this.state.nameEditing !== '') {

        //         let { questionario } = await DiagnosticoHelper.setQuestionarioNome(this.state.idPeriodoAvaliacao, this.state.editing, this.state.nameEditing);
                
        //         if ( questionario.id ) {
        //             this.setState({ isEditingName: false, editing: questionario.id  });
        //         } else {
        //             alert('Erro ao editar questionário - Error to edit questionnaire - Error al editar cuestionario');
        //         }

        //     } else {
        //         this.setState({ isEditingName: true });
        //     }
        // }
            
        const addButtom = () => {
            return (
                <DefaultButton
                    fontSize={this.props.isSmallScreen ? 14 : "unset"}
                    title={message[this.state.lang].add}
                    leftIcon={<FontAwesomeIcon icon={faPlusSquare} />}
                    color={Colors.success}
                    disabled={!this.state.canEdit}
                    onClick={() => this.setState({ editing: true })}
                    style={{ whiteSpace: 'nowrap' }}
                />
            )
        }

        const isEditing = () => {

            let text = message[this.state.lang].editing + this.state.nameEditing;
            let canEdit = this.state.editing && this.state.canEditQuantionario;

            return (
                <div 
                    className="isEditing"
                >
                    <FontAwesomeIcon 
                        icon={this.state.isEditingName ? faPaperPlane : faPencil}
                        onClick={() => { if (canEdit) this.setState({isEditingName: !this.state.isEditingName})  }}
                        style={{ cursor: canEdit ? 'pointer' : 'default' }}
                    />

                    {this.state.isEditingName ?
                        <input 
                            type="text"
                            value={this.state.nameEditing}
                            onChange={(e) => this.setState({ nameEditing: e.target.value })}
                            onKeyDown={(e) => { if (e.key === 'Enter' && canEdit) this.setState({isEditingName: false}) }}
                            style={{
                                backgroundColor: 'transparent',
                                border: '0.5px solid white',
                                borderRadius: 8,
                            }}
                        />
                        :
                        text
                    }
                    <span className="animated-ellipsis"/>
                </div>
            )
        }

        const listQuestionarios = () => {

            if (!this.state.questionarios) return (null);
            if (this.state.loadingQuestionarios) return (
                <div className="list-questionarios" style={{ overflow: 'hidden' }}>
                    <DefaultLoader/>
                </div>
            );
            return (
                <div className="list-questionarios">
                    {this.state.questionarios.map((questionario, index) => {
                        return(
                            <span 
                                key={index} 
                                className={`questionarios-option ${questionario.canEdit ? 'delete' : 'locked'}`}
                                onClick={() => this.setState({ editing: questionario.id })}
                            >
                                {<FontAwesomeIcon 
                                    className={'icon-delete'} 
                                    icon={faTrash} 
                                    style={{ color: Colors.error }} 
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        questionario.canEdit && this.deleteQuestionario(questionario.id);
                                    }}
                                />}

                                {<FontAwesomeIcon 
                                    className={'icon-locked'} 
                                    icon={faLock} 
                                    style={{ color: Colors.error }} 
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        
                                    }}
                                />}
                                
                                {questionario.nome}
                            </span>
                        )
                    })}
                </div>
            )
        }

        const backButtom = () => {
            return (
                <DefaultButton
                    fontSize={this.props.isSmallScreen ? 14 : "unset"}
                    leftIcon={<FontAwesomeIcon icon={faChevronLeft} />}
                    color={Colors.secondaryButton}
                    textColor={Colors.dark}
                    onClick={() => this.setState({ editing: false }, () => this.loadQuestionarios())}
                />
            )
        }

        const tabsEdit = () => {
            return (
                <Tabs
                     activeTab={this.state.activeTab}
                     tabs={this.state.tabs}
                     isSmallScreen={this.state.isSmallScreen}
                     onTabChange={(tab) => {this.setState({ activeTab: tab.name })}}
                     lang={this.state.lang}
                 />
            )
        }

        return (
            <div className="questionarios-container-padding">
                <div className="questionarios-container" style={{boxShadow: Colors.boxShadow}}>
                    <div className="questionarios-options">
                        {this.state.editing ? backButtom() : addButtom()}
                        {this.state.editing ? isEditing() : listQuestionarios()}
                        {this.state.editing && tabsEdit()}
                    </div>
                    <Questionario 
                        idQuestionario={this.state.editing}
                        idPeriodoAvaliacao={this.state.idPeriodoAvaliacao}
                        lang={this.state.lang}
                        onAdd={() => this.setState({ editing: true })}
                        canEdit={this.state.canEdit}
                        activeTab={this.state.activeTab}
                        onTabChange={(tab) => {this.setState({ activeTab: tab })}}
                        faixas={this.state.faixas}
                        nome={this.state.nameEditing}
                        isEditingName={this.state.isEditingName}
                        setNameEditing={(name, canEdit) => this.setState({ nameEditing: name || this.state.nameEditing, canEditQuantionario: canEdit || this.state.canEditQuantionario})} 
                    />
                </div>
            </div>
        )
    }

    render() {
        return (
            <div className="Questionarios">
                {this.renderHeader()}
                {this.state.idPeriodoAvaliacao ? this.renderQuestionarios() : DiagnosticoHelper.renderPeriodoNotSelected(this.props.t, this.state.loadingPeriodosAvaliacao)}
            </div>
        )
    }
}

export default withTranslation()(Questionarios);