import React from "react";
import LayoutHelper from "../../../../helper/LayoutHelper";
import Modal from "../../../tools/Modal";
import EssentialStyle from "../../../../style/EssentialStyle";
import DefaultButton from "../../../tools/DefaultButton";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faDownload, faFileLines, faFile } from '@fortawesome/free-solid-svg-icons';
import Colors from "../../../../constants/Colors";
import AtaReuniaoAnexosList from "./AtaReuniaoAnexosList";
import { toast } from 'react-toastify';
import Sig from "../../../../api/Sig";
import CustomTooltip from "../../../tools/CustomTooltip";
import { Form } from "react-bootstrap";

export default class AtaReuniaoAnexosModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            fileToUpload: null,
            fileToUploadPreview: null,
            loadingUpload: false,
            isSmallScreen: LayoutHelper.isSmallScreen(),
        };
        this.modalRef = React.createRef();
        this.fileInputRef = React.createRef();
    }

    componentDidMount() {
        window.addEventListener('resize', this.onResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.onResize);
    }

    onResize = () => {
        this.setState({ isSmallScreen: LayoutHelper.isSmallScreen() });
    }

    renderAnexos = () => {
        return (
            <div style={{...EssentialStyle.columnStart, width: '100%', padding: 10, maxHeight: "80vh", overflowY: "auto"}}>
                <AtaReuniaoAnexosList 
                    key={this.props.id+"-ata"}
                    type={'Ata'} 
                    anexos={this.props.anexos} 
                    id={this.props.id}
                    updateAnexoCallback={this.props.updateAnexoCallback}
                    canEdit={this.props.canEdit && !this.props.finalizado}
                    hash={this.props.hash}
                />
                {this.props.topicos && this.props.topicos.length > 0 && this.props.topicos.map((topico, index) => {
                    return (
                        <AtaReuniaoAnexosList 
                            key={topico.id+"-topico"} 
                            type={'Topico'} 
                            nomeTopico={topico.nome}
                            anexos={topico.anexos} 
                            id={topico.id}
                            updateAnexoCallback={(anexo, deleteAnexo = false) => this.props.updateAnexoCallback(anexo, deleteAnexo, topico.id)}
                            canEdit={topico.canEdit && !this.props.finalizado}
                            hash={this.props.hash}
                        />
                    )

                })}
            </div>
        )
    }

    renderSwitch() {

        return (

            <div style={{...EssentialStyle.rowFlexCenter, width: 'auto', padding: "0px 10px", borderRadius: 8}}>
                <CustomTooltip tooltip={"Downloads sem marca d'água"} placement="auto" style={{paddingRight: 10, color: Colors.homePage.grey}}>
                    <FontAwesomeIcon className="left-icon" icon={faFileLines} />
                </CustomTooltip>

                <Form.Switch
                    type="switch"
                    disabled={!this.props.canEdit}
                    checked={!this.props.downloadSemWatermark}
                    onChange={async () => {
                        var req = await Sig.request("POST", `ataReuniao${this.props.hash ? "Externa" : ""}/updateAtaDownloadSemWatermark`, { 
                            id_relation: this.props.id,
                            hash: this.props.hash, 
                            download_sem_watermark: this.props.downloadSemWatermark ? 0 : 1 
                        });
                        
                        if(req.status === 200)
                            this.props.changeDownloadSemWatermarkCallback();
                    }}
                />

                <CustomTooltip tooltip={"Downloads com marca d'água"} placement="auto" style={{color: Colors.homePage.grey}}>
                    <div style={{...EssentialStyle.rowFlexCenter, position: "relative"}}>
                        <FontAwesomeIcon icon={faFile} />
                        <span style={{position: "absolute", fontSize: 12, color: Colors.white}}>
                            A
                        </span>
                    </div> 
                </CustomTooltip>
            </div>
        )
    }

    render() {
        return (
            <Modal style={{ width: this.state.isSmallScreen ? '95vw' : '70vw' }} ref={this.modalRef}>
                <div style={{...EssentialStyle.columnStart, width: '100%', minHeight: 400}}>
                    <div style={{...EssentialStyle.rowSpaceBetween, width: 'auto', padding: 10, alignSelf: "flex-end", marginBottom: 10}}>
                        {this.props.canEdit && this.renderSwitch()}
                        <DefaultButton
                            leftIcon={<FontAwesomeIcon icon={faDownload} />}
                            tooltip={"Baixar todos os anexos"}
                            color={"transparent"}
                            textColor={Colors.homePage.grey}
                            style={{width: 35, height: 35, padding: 0, fontSize: 18, alignSelf: 'flex-end'}}
                            onClick={async () => {
                                const toastId = toast.loading("Efetuando Download...");
                                try {

                                    var response = await Sig.request("POST", `ataReuniao${this.props.hash ? "Externa" : ""}/downloadAllCompressedFilesAta`, { 
                                        id_relation: this.props.id,
                                        hash: this.props.hash, 
                                    }, 'application/x-www-form-urlencoded; charset=UTF-8', 'blob');
                                    
                                    if(response.status === 200) {
                                        var url = window.URL.createObjectURL(new Blob([response]));
                                        var link = document.createElement('a');
                                        link.href = url;
                                        link.setAttribute('download', 'Anexos_Ata_'+ this.props.nomeAta.replace(/ /g, '_') +'.zip');
                                        document.body.appendChild(link);
                                        link.click();
                                        toast.update(toastId, { render: "Download Concluído!", type: "success", isLoading: false, autoClose: 5000 });
                                    } else {
                                        toast.update(toastId, { render: "Erro no Download!", type: "error", isLoading: false, autoClose: 5000 });
                                    }
                                } catch (error) {
                                    toast.update(toastId, { render: "Erro no Download!", type: "error", isLoading: false, autoClose: 5000 });
                                }
                            }}
                        />
                        <DefaultButton
                            leftIcon={<FontAwesomeIcon icon={faClose} />}
                            color={"transparent"}
                            textColor={Colors.dark}
                            style={{width: 35, height: 35, padding: 0, alignSelf: 'flex-end'}}
                            onClick={this.props.closeModalAnexosCallback}
                        />
                    </div>
                    {this.renderAnexos()}
                </div>
            </Modal>
        );
    }
}