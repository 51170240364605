import React, { Component } from 'react';
import './SidebarAvaliacao.css';

import { withTranslation } from 'react-i18next';
import DefaultButton from '../../../../tools/DefaultButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import DefaultLoader from '../../../../tools/DefaultLoader';
import DefaultTableSearch from '../../../../tools/DefaultTableSearch';
import SidebarAvaliacaoNode from './SidebarAvaliacaoNode';
import { Nav } from 'react-bootstrap';
import DiagnosticoHelper from '../../../../../helper/diagnostico/DiagnosticoHelper';
import Constants from "../../../../../constants/Api";
import IdiomaHelper from '../../../../../helper/IdiomaHelper';

const gateway = Constants.getSigEndPoint();

class SidebarAvaliacao extends Component {
    constructor(props) {
        super(props);
        this.state = {
            minWidth: 200,
            isResizing: false,
            lastDownX: 0,
            writingColor: this.props.pageStyles.logoFilename == 'logo-advis-white' ? 'white' : 'black',
            filter: this.props.pageStyles.logoFilename == 'logo-advis-white' ? 'brightness(0.8)' : 'brightness(1.3)',
            openNodes: [],
            searchText: '',
        };
        this.callbackTimeout = null;
    }

    componentDidUpdate(prevProps) {
        if (prevProps.pageStyles.logoFilename !== this.props.pageStyles.logoFilename) {
            this.setState({
                writingColor: this.props.pageStyles.logoFilename == 'logo-advis-white' ? 'white' : 'black',
                filter: this.props.pageStyles.logoFilename == 'logo-advis-white' ? 'brightness(0.8)' : 'brightness(1.3)',
            });
        }
    }

    toggleSidebar = () => {
        this.props.setSidebarState(!this.props.isOpen);
    };

    setSelectedNode = (id, viewAll = false) => {
        this.props.setSelectedNode(id, viewAll);
    }

    getOpenNodesRecursively = (id, nodes = DiagnosticoHelper.getNodesRecursively(this.props.avaliacao?.questionario?.nodes)) => {
        var openNodes = [];

        nodes.filter(node => node.id === id).map(node => {
            if (node.id_node_pai) {
                openNodes = [...openNodes, ...this.getOpenNodesRecursively(node.id_node_pai, nodes)];
            }
        });

        openNodes.push(id);

        return openNodes;
    }

    setOpenNodes = (id) => {
        var openNodes = this.getOpenNodesRecursively(id);

        this.setState({
            openNodes: openNodes
        }, () => this.handleSearch(null));
    }

    setNodeState = (id, isOpen) => {
        var openNodes = this.state.openNodes;
        if (isOpen) {
            if (!openNodes.includes(id)) {
                openNodes.push(id);
            }
        } else {
            openNodes = openNodes.filter(node => node !== id);
        }

        this.setState({
            openNodes: openNodes
        });
    }

    handleMouseDown = (e) => {
        e.preventDefault();
        this.setState({
            isResizing: true,
            lastDownX: e.clientX
        });
    };

    handleMouseMove = (e) => {
        if (!this.state.isResizing) return;

        const offsetRight = document.body.offsetWidth - (e.clientX - document.body.offsetLeft);
        const newWidth = document.body.offsetWidth - offsetRight;

        if (newWidth < this.state.minWidth) {
            this.setSidebarWidth(this.state.minWidth);
            return;
        }

        if (newWidth > document.body.clientWidth / 2) {
            this.setSidebarWidth(document.body.clientWidth / 2);
            return;
        }

        this.setSidebarWidth(newWidth);
    };

    setSidebarWidth = (width) => {
        if(this.callbackTimeout) {
            clearTimeout(this.callbackTimeout);
        }
        
        this.callbackTimeout = setTimeout(() => {
            this.props.setSidebarWidth(width);
        }, 50);
    }

    handleMouseUp = () => {
        this.setState({ isResizing: false });
    };

    async componentDidMount() {   
        await this.props.i18n.loadNamespaces(['client_src_components_modules_diagnostico_Avaliacao_Aplicacao_SidebarAvaliacao']);
        document.addEventListener('mousemove', this.handleMouseMove);
        document.addEventListener('mouseup', this.handleMouseUp);
    }

    componentWillUnmount() {
        document.removeEventListener('mousemove', this.handleMouseMove);
        document.removeEventListener('mouseup', this.handleMouseUp);
    }

    renderSidebarHeader = () => {

        const styles = {
            headerContainter: {
                display: 'flex',
                placeContent: 'end',
            },
            expandButton: {
                cursor: 'pointer',
                fontSize: '20px',
            }
        };

        return (
            <div style={styles.headerContainter}>
                <DefaultButton title="" onClick={this.toggleSidebar} style={{ ...styles.expandButton }} color={"transparent"} leftIcon={<FontAwesomeIcon icon={faBars} />} />
            </div>
        );
    }

    renderSidebarFooter = () => {
        const styles = {
            footerContainer: {
                display: 'flex', placeContent: 'center', height: '65px', bottom: '-10px', position: 'absolute',
                maxWidth: '100%', width: '100%',
                backgroundColor: this.props.pageStyles.headerColor ? this.props.pageStyles.headerColor : '#FFFFFF',
            },
            advisLogo: {
                position: 'relative', margin: '0 auto',
                cursor: 'pointer', padding: 15, placeContent: 'center',
            },
            expandButton: {
                position: 'absolute', left: 10, top: 10,
                cursor: 'pointer',
                fontSize: '20px',
                justifySelf: 'end',
            }
        };

        return (
            <div style={styles.footerContainer}>
                <a
                    style={styles.advisLogo}
                    onClick={() => window.parent.postMessage({ type: 'navigate', url: `/` }, '*')}
                >
                    <img src={`${gateway}/img/${this.props.pageStyles.logoFilename ? this.props.pageStyles.logoFilename : 'logo-advis-black'}.png`} alt="Advis Consultoria" height="32" />
                </a>

                <DefaultButton title="" onClick={this.toggleSidebar} style={{ ...styles.expandButton }} color={"transparent"} leftIcon={<FontAwesomeIcon icon={faBars} />} />
                {this.props.children}
            </div>
        );

    }

    handleSearch = (text) => {
        this.setState({
            searchText: text || ''
        }, () => {
            this.filterNodes();
        });
    }

    filterNodes = () => {
        if (!this.state.searchText) {
            this.setState({
                filteredNodes: null
            });
            return;
        }


        var nodes = this.props.avaliacao?.questionario?.nodes;
        nodes = DiagnosticoHelper.getNodesRecursively(nodes);

        var filteredNodes = nodes.filter(node => {
            var text = node.coluna ? `${node.coluna}: ${node.descricao}` : node.descricao;
            return text.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "").includes(this.state.searchText.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, ""));
        });

        this.setState({
            filteredNodes: filteredNodes
        });
    }

    renderFilteredNodes = () => {
        return this.state.filteredNodes?.map((node, index) => {
            return (
                <SidebarAvaliacaoNode
                    key={index}
                    node={node}
                    camada={0}
                    maxCamadas={0}
                    writingColor={this.state.writingColor}
                    filter={this.state.filter}
                    selectedNode={null}
                    setSelectedNode={this.setSelectedNode}
                    setNodeState={(id, isOpen) => this.setOpenNodes(id)}
                    openNodes={[]}
                />
            )
        });
    }

    renderSidebarContent = () => {
        if (this.props.loading) {
            return (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', width: '100%' }}>
                    <DefaultLoader color={this.props.pageStyles.secondaryColor} />
                </div>
            );
        }

        var progresso = 0;
        if (this.props.avaliacao?.questionario?.qtdPerguntas > 0) {
            progresso = Math.round((this.props.avaliacao?.questionario?.qtdPerguntasRespondidas / this.props.avaliacao?.questionario?.qtdPerguntas) * 100);
        }

        return (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', flex: 1, width: '100%', gap: '8px', paddingTop: '20px', maxHeight: 'calc(100% - 64px)', maxWidth: '100%' }}>
                <h1 style={{ width: '100%', fontSize: '16px', color: this.state.writingColor, lineHeight: '1', margin: '0px', padding: '0px 10px', overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }} >
                    {this.props.avaliacao?.questionario?.nome}
                </h1>
                <h2 style={{ width: '100%', fontSize: '14px', color: this.state.writingColor, filter: this.state.filter, lineHeight: '1', margin: '0px', padding: '0px 10px', overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
                    {this.props.avaliacao?.processo?.nome} - {this.props.avaliacao?.periodoAvaliacao?.nome}
                </h2>
                <h2 style={{ width: '100%', fontSize: '14px', color: this.state.writingColor, filter: this.state.filter, lineHeight: '1', margin: '0px', padding: '0px 10px', overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
                    {this.props.avaliacao?.empresaAvaliada?.nome}
                </h2>
                <span style={{ minWidth: '100%', height: '1px', backgroundColor: this.state.writingColor, margin: '0px', filter: this.state.filter, padding: '0px 10px' }} />
                <div style={{ width: '100%', padding: '0px 10px' }}>
                    <DefaultTableSearch
                        handleSearch={this.handleSearch}
                        onEmpty={() => { this.handleSearch(null) }}
                        hideExpand={true} width={'100%'}
                        text={this.state.searchText}
                        type={"sidebarAvaliacao"}
                    />
                </div>
                {

                    <Nav style={{ width: '100%', paddingTop: '5px', maxHeight: 'calc(100% - 160px)', flex: 1, overflowY: 'auto', scrollbarGutter: 'stable', paddingLeft: '10px', display: 'flex', flexDirection: 'column', flexWrap: 'nowrap' }}>
                        {
                            !this.state.searchText ?
                                this.props.avaliacao?.questionario?.nodes.map((node, index) => {
                                    return (
                                        <SidebarAvaliacaoNode
                                            key={index}
                                            node={node}
                                            camada={0}
                                            writingColor={this.state.writingColor}
                                            filter={this.state.filter}
                                            maxCamadas={this.props.avaliacao?.questionario?.colunas?.length > 1 ? this.props.avaliacao?.questionario?.colunas?.length - 2 : 0}
                                            selectedNode={this.props.selectedNode}
                                            setSelectedNode={this.setSelectedNode}
                                            setNodeState={this.setNodeState}
                                            openNodes={this.state.openNodes}
                                            searchText={this.state.searchText}
                                        />

                                    )
                                })
                                :
                                this.renderFilteredNodes()
                        }
                    </Nav>
                }
                <span style={{ minWidth: '100%', height: '1px', backgroundColor: this.state.writingColor, margin: '0px', filter: this.state.filter, padding: '0px 10px' }} />
                <div
                    style={{ width: '100%', padding: '0px 10px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}
                    role="button" onClick={() => this.setSelectedNode(null, true)}
                >
                    <h2 style={{ fontSize: '14px', color: this.state.writingColor, filter: this.state.filter, lineHeight: '1.2', margin: '0px' }}>
                        {this.props.avaliacao?.questionario?.qtdPerguntasRespondidas + "/" + this.props.avaliacao?.questionario?.qtdPerguntas + " " + this.props.t('client_src_components_modules_diagnostico_Avaliacao_Aplicacao_SidebarAvaliacao:questoesRespondidas')}
                    </h2>
                    <h2 style={{ fontSize: '14px', color: this.state.writingColor, filter: this.state.filter, lineHeight: '1.2', margin: '0px', textDecoration: 'underline' }}>
                        {this.props.t('client_src_components_modules_diagnostico_Avaliacao_Aplicacao_SidebarAvaliacao:verTudo')}
                    </h2>
                </div>
            </div>
        );
    }

    renderSmall = () => {
        const { isOpen } = this.props;

        const styles = {
            sidebar: {
                width: '100%',
                left: 0,
                position: 'relative',
                minHeight: isOpen ? 'calc(100vh - 65px)' : '65px',
                height: isOpen ? 'calc(100vh - 65px)' : '65px',
                maxHeight: 'calc(100vh - 65px)',
                backgroundColor: this.props.pageStyles.headerColor ? this.props.pageStyles.headerColor : '#FFFFFF',
                boxShadow: '2px 0 5px rgba(0,0,0,0.1)',
                padding: '10px',
                boxSizing: 'border-box',
                display: 'flex',
                flexDirection: 'column',
                transition: 'all 0.3s ease-out',
            },
            sidebarContent: {
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
                maxWidth: '100%',
                maxHeight: '100%',
                position: 'relative',
            },
            resizeHandle: {
                display: 'none',
            }
        };

        return (
            <div style={styles.sidebar}>
                {isOpen &&
                    <div
                        style={styles.resizeHandle}
                        onMouseDown={this.handleMouseDown}
                    />
                }
                <div style={styles.sidebarContent} className="avaliacao-scrollbar">
                    {
                        isOpen &&
                        this.renderSidebarContent()
                    }
                    {this.renderSidebarFooter()}
                </div>
            </div>
        );
    }

    renderNormal = () => {
        const { isOpen, sidebarWidth: width } = this.props;

        const styles = {
            sidebar: {
                width: `${width}px`,
                position: 'fixed',
                top: 0,
                left: isOpen ? 0 : `calc(-${width}px + 60px`,
                height: '100%',
                backgroundColor: this.props.pageStyles.headerColor ? this.props.pageStyles.headerColor : '#FFFFFF',
                boxShadow: '2px 0 5px rgba(0,0,0,0.1)',
                padding: '10px',
                boxSizing: 'border-box',
                display: 'flex',
                flexDirection: 'column',
                transition: 'all 0.3s ease-out'
            },
            sidebarContent: {
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
                maxHeight: '100%',
            },
            resizeHandle: {
                width: '5px',
                cursor: 'ew-resize',
                height: '100%',
                position: 'absolute',
                top: 0,
                right: 0,
                bottom: 0,
            }
        };

        return (
            <div style={styles.sidebar}>
                {isOpen &&
                    <div
                        style={styles.resizeHandle}
                        onMouseDown={this.handleMouseDown}
                    />
                }
                <div style={styles.sidebarContent} className="avaliacao-scrollbar">
                    {this.renderSidebarHeader()}
                    {
                        isOpen &&
                        this.renderSidebarContent()
                    }
                </div>
            </div>
        );
    }

    render() {

        return (
            this.props.isSmallScreen ?
                this.renderSmall() : this.renderNormal()

        );
    }
}

export default withTranslation()(SidebarAvaliacao);