import React from 'react';
import './PlanoAcaoRelatorio.css';

import EssentialStyle from '../../../../style/EssentialStyle';
import DefaultLoader from '../../../tools/DefaultLoader';
import { Collapse, Form } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import Colors from '../../../../constants/Colors';
import SessionHelper from '../../../../helper/SessionHelper';
import DefaultButton from '../../../tools/DefaultButton';
import PlanoAcaoRelatorioTabela from './PlanoAcaoRelatorioComponents/PlanoAcaoRelatorioTabela';
import PlanoAcaoRelatorioLista from './PlanoAcaoRelatorioComponents/PlanoAcaoRelatorioLista';
import PlanoAcaoRelatorioStatus from './PlanoAcaoRelatorioComponents/PlanoAcaoRelatorioStatus';
import PlanoAcaoRelatorioDetails from './PlanoAcaoRelatorioComponents/PlanoAcaoRelatorioDetails';
import PlanoAcaoRelatorioColaboradores from './PlanoAcaoRelatorioComponents/PlanoAcaoRelatorioColaboradores';
import PlanoAcaoRelatorioParceiros from './PlanoAcaoRelatorioComponents/PlanoAcaoRelatorioParceiros';
import PlanoAcaoRelatorioTimeline from './PlanoAcaoRelatorioComponents/PlanoAcaoRelatorioTimeline/PlanoAcaoRelatorioTimeline';
import Tabs from '../../../tools/Tabs';
import Sig from '../../../../api/Sig';
import { toast } from "react-toastify";
import LoadingPage from '../../../../pages/LoadingPage';
import RelatorioDiagnosticoColunas from '../../diagnostico/RelatorioPlanosAcao/RelatorioPlanosAcaoComponents/RelatorioDiagnosticoColunas';

export default class PlanoAcaoRelatorio extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            displayContent: true,
            activeTipoVisaoTab: 'lista',
            counter: 0,
            view: 'planos',
            loading: true,
            activeTabTimeline: 'semana',
            secondaryFilter: this.props.filter
        }
        this.abortController = new AbortController();
    }

    async componentDidMount() {
        await this.load();
    }

    setFilterCallback = async (secondaryFilter) => {
        this.setState({ secondaryFilter });
    }

    async load() {
        this.setState({ loading: true, counter: 0 });

        let req = await Sig.request(
            'POST', 
            'planoAcao/getTotalCounter', 
            { 
                filter: JSON.stringify(this.props.filter), 
                parceiro: this.props.parceiro ? 1 : 0, 
                relations: 0,
                etapas: 0,
                id_periodo_avaliacao: this.props.id_periodo_avaliacao || 0
            },
            Sig.getDefaultContentType(),
            Sig.getDefaultTransformTo(),
            this.abortController.signal
        );

        if (req.status != 200) {
            this.setState({ loading: false });
            return toast.error("Erro ao carregar dados");
        }

        this.setState({ counter: req.total, loading: false });
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            this.props.filter !== prevProps.filter || 
            this.props.id_periodo_avaliacao !== prevProps.id_periodo_avaliacao ||
            this.props.parceiro !== prevProps.parceiro
        ) {
            this.setFilterCallback(this.props.filter);
            return this.load();
        }
    }

    componentWillUnmount() {
        this.abortController.abort();
    }

    renderList = () => {
        return (
            <PlanoAcaoRelatorioLista 
                parceiro={this.props.parceiro} 
                filter={this.state.secondaryFilter} 
                isSmallScreen={this.props.isSmallScreen} 
                view={this.state.view} 
                loading={this.state.loading} 
                id_periodo_avaliacao={this.props.id_periodo_avaliacao}
            />
        )
    }

    renderEmpty = () => {
        return (
            <div style={{ ...EssentialStyle.columnCenter, ...EssentialStyle.card, padding: 10, width: '100%', marginTop: 10, height: 150, marginBottom: 10 }}>
                <h2 style={{ color: Colors.homePage.grey, fontSize: 20, fontWeight: 500, textAlign: 'center' }}>Nenhum plano de ação encontrado</h2>
            </div>
        );
    }

    renderTable = () => {
        return (
            <PlanoAcaoRelatorioTabela 
                parceiro={this.props.parceiro} 
                filter={this.state.secondaryFilter} 
                isSmallScreen={this.props.isSmallScreen} 
                view={this.state.view} 
                loading={this.state.loading} 
                id_periodo_avaliacao={this.props.id_periodo_avaliacao}
            />	
        )
    }

    renderTimeline = () => {
        return (
            <PlanoAcaoRelatorioTimeline 
                parceiro={this.props.parceiro} 
                filter={this.state.secondaryFilter} 
                isSmallScreen={this.props.isSmallScreen} 
                view={this.state.view} 
                loading={this.state.loading}  
                granularidade={this.state.activeTabTimeline}
                id_periodo_avaliacao={this.props.id_periodo_avaliacao}
            />
        )
    }

    handleTipoVisaoTabs = (tab) => {
        this.setState({ activeTipoVisaoTab: tab.name });
    }

    handleTimelineTabChange = (tab) => {
        this.setState({ activeTabTimeline: tab.name });
    }

    renderContent = () => {
        const tabs = [
            { name: 'lista', title: 'Lista' },
            { name: 'tabela', title: 'Tabela' },
            { name: 'timeline', title: 'Timeline' },
        ];

        const timelineTabs = [
            { name: "ano", title: "Ano" },
            { name: 'mes', title: 'Mês' },
            { name: 'semana', title: 'Semana' },
        ];

        return (
            <>
                <div
                    style={{
                        ...EssentialStyle.rowSpaceBetween,
                        height: 'auto',
                        minHeight: 40,
                        backgroundColor: SessionHelper.getBackgroundColor(),
                        width: "100%",
                        position: "sticky",
                        left: 0,
                        flexWrap: "wrap",
                        gap: 5,
                        margin: '3px 0px',
                    }}
                >
                    {this.state.displayContent ?
                        <Tabs
                            isSmallScreen={this.props.isSmallScreen}
                            tabs={tabs}
                            activeTab={this.state.activeTipoVisaoTab}
                            onTabChange={this.handleTipoVisaoTabs}
                            style={{ placeSelf: "center", marginTop: 0 }}
                            tabStyle={{ width: this.props.isSmallScreen ? '25vw' : 90, maxWidth: 150, height: 30, padding: 0, alignItems: "center", justifyContent: "center" }}
                        />
                        :
                        <div></div>
                    }
                    <div style={{ ...EssentialStyle.rowFlexCenter, gap: 10, marginRight: 10, position: 'sticky', left: "calc(100%)" }}>
                        {this.state.activeTipoVisaoTab == 'timeline' && this.state.displayContent &&
                            <Tabs
                                isSmallScreen={this.props.isSmallScreen}
                                tabs={timelineTabs}
                                activeTab={this.state.activeTabTimeline}
                                onTabChange={this.handleTimelineTabChange}
                                style={{ placeSelf: "right", marginTop: 0 }}
                                tabStyle={{ width: this.props.isSmallScreen ? '28vw' : 90, maxWidth: 150, height: 30, padding: 0, alignItems: "center", justifyContent: "center" }}
                            />
                        }
                        <DefaultButton
                            leftIcon={!this.state.displayContent ? <FontAwesomeIcon icon={faChevronDown} /> : <FontAwesomeIcon icon={faChevronUp} />}
                            color={"transparent"}
                            textColor={Colors.dark}
                            onClick={() => this.setState({ displayContent: !this.state.displayContent })}
                            style={{ width: 30, height: 30, alignItems: "center", justifyContent: "center", padding: 0 }}
                        />
                    </div>
                </div>
                <div style={{ ...EssentialStyle.columnStart, borderRadius: 8, maxHeight: this.state.displayContent ? "unset" : 40, flex: 1, backgroundColor: SessionHelper.getBackgroundColor(), transition: "all 0.5s ease" }}>
                    <Collapse in={this.state.displayContent} style={{ transition: "all 0.5s ease", height: "calc(100% - 40px)", maxHeight: "calc(100% - 40px)", width: "100%" }}>
                        <div>
                            {this.handleRenderActiveTipoVisaoTab()}
                        </div>
                    </Collapse>
                </div>
            </>
        )
    }

    handleRenderActiveTipoVisaoTab = () => {
        if (this.props.loading) {
            return (
                <div style={{ ...EssentialStyle.columnCenter, width: '100%', height: '40vh' }}>
                    <DefaultLoader />
                </div>
            );
        }

        if (this.state.activeTipoVisaoTab === 'lista') {
            return this.renderList();
        } else if (this.state.activeTipoVisaoTab === 'tabela') {
            return this.renderTable();
        } else if (this.state.activeTipoVisaoTab === 'timeline') {
            return this.renderTimeline();
        }
    }

    renderAtrasoMedio = () => {
        if (this.props.parceiro) {
            return (
                <PlanoAcaoRelatorioParceiros
                    isSmallScreen={this.props.isSmallScreen}
                    setFilterCallback={this.setFilterCallback}
                    filter={this.state.secondaryFilter}
                    id_periodo_avaliacao={this.props.id_periodo_avaliacao}
                />
            )
        }

        return (
            <PlanoAcaoRelatorioColaboradores
                isSmallScreen={this.props.isSmallScreen}
                setFilterCallback={this.setFilterCallback}
                filter={this.state.secondaryFilter}
                id_periodo_avaliacao={this.props.id_periodo_avaliacao}
            />
        );
    }
    
    renderColunasDiagnostico = () => {
        if (!this.props.id_periodo_avaliacao) return null;

        return (
            <RelatorioDiagnosticoColunas
                isSmallScreen={this.props.isSmallScreen}
                setFilterCallback={this.setFilterCallback}
                filter={this.state.secondaryFilter}
                id_periodo_avaliacao={this.props.id_periodo_avaliacao}
            />
        );
    }

    renderDetails = () => {
        if(this.props.id_periodo_avaliacao) return null;

        return (
            <PlanoAcaoRelatorioDetails 
                setFilterCallback={this.setFilterCallback}
                isSmallScreen={this.props.isSmallScreen}
                filter={this.state.secondaryFilter} 
                parceiro={this.props.parceiro}
            />
        )
    }

    render() {
        if (this.props.loading || this.state.loading) {
            return <LoadingPage height={`calc(100vh - ${this.props.headerSize}px - 14px)`} />;
        }

        if (this.state.counter < 1 && !this.state.loading) {
            return (
                <div style={{ ...EssentialStyle.rowSpaceBetween }}>
                    <div style={{ ...EssentialStyle.columnCenter, padding: 10, width: '100%', marginTop: 10 }}>
                        <h2 style={{ color: Colors.homePage.grey, fontSize: 20, fontWeight: 500, textAlign: 'center' }}>Nenhum plano de ação encontrado</h2>
                    </div>
                </div>
            );
        }

        return (
            <div className={"relatorio-pa"} style={{ ...EssentialStyle.columnStart, height: `calc(100vh - ${this.props.headerSize}px - 14px)`, width: "calc(100% + 14px)", gap: 2, margin: '0px -7px' }}>
                <>
                    <div style={{
                        maxHeight: "100%",
                        width: "100%",
                        overflowX: "auto",
                        padding: '0px 7px',
                        marginBottom: this.props.isSmallScreen ? '6rem' : 0,
                    }}
                    >
                        <PlanoAcaoRelatorioStatus 
                            setFilterCallback={this.setFilterCallback}
                            counter={this.state.counter} 
                            isSmallScreen={this.props.isSmallScreen} 
                            filter={this.props.filter} 
                            parceiro={this.props.parceiro} 
                            id_periodo_avaliacao={this.props.id_periodo_avaliacao}
                        />
                        {this.renderDetails()}
                        {this.renderAtrasoMedio()}
                        {this.renderColunasDiagnostico()}
                        {this.renderContent()}
                    </div>
                </>
            </div>
        );
    }
}