import React, { Component } from 'react';
import moment from 'moment';
import './SidebarAvaliacao.css';
import IndicadorGauge from '../../../inicio/charts/indicadores/IndicadorGauge';
import Colors from '../../../../../constants/Colors';

class IndicadoresList extends Component {
    render() {
        const style = {
            display: 'flex',
            width: '100%',
            minHeight: '200px',
            height: 'auto',
            maxHeight: '100%',
            overflowY: 'auto',
        };

        return (
            <div className="avaliacao-scrollbar" style={{...style, ...this.props.style}}>
                {this.props.indicadores?.map((indicador, index) => {
                    return (
                        <div key={index} style={{minWidth: "180px", width: "100%", height: "200px", maxWidth: "250px", display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                            <IndicadorGauge
                                nome
                                nomeIndicador={indicador.nome}
                                id_indicador={indicador.id_indicador}
                                id_gmr_painel={indicador.id_gmr_painel}
                                data={indicador.data}
                                acumulado={indicador.acumulado}
                                meta={indicador.meta}
                                metaValor={indicador.metaValue}
                                id_empresa={indicador.id_empresa}
                                gauge={true}
                                mergulho={false}
                                link={false}
                            />
                            <div 
                                style={{
                                    height: '24px', 
                                    borderRadius: 8,
                                    padding: '0 10px',
                                    backgroundColor: Colors.homePage.grey,
                                    color: Colors.light  
                                }}
                            > 
                                {moment(indicador.data).format('MM/YYYY')} 
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    }
}

export default IndicadoresList;