import React from "react";

import Colors from "../../../../constants/Colors";
import DefaultButton from "../../../../components/tools/DefaultButton";

import DiagnosticoHelper from "../../../../helper/diagnostico/DiagnosticoHelper";
import IdiomaHelper from "../../../../helper/IdiomaHelper";
import DataHelper from "../../../../helper/DataHelper";
import Select from 'react-select';

import './GradesAvaliacao.css';
import GradeAvaliacao from "./GradeAvaliacao/GradeAvaliacao";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload, faEnvelopeOpenText } from "@fortawesome/free-solid-svg-icons";
import Modal from "../../../tools/Modal";
import TableImporter from "../../../tools/TableImporter/TableImporter";
import ModalUploadResultadosAvaliacao from "./ModalUploadResultadosAvaliacao/ModalUploadResultadosAvaliacao";
import { withTranslation } from 'react-i18next';

const searchableFields = ['nome'];

class GradesAvaliacao extends React.Component {

    state = {
        lang: IdiomaHelper.getIdioma(),
        reloadPeriodo: 0,

        loadingPeriodosAvaliacao: true,

        periodosAvaliacao: [],
        idPeriodoAvaliacao: null,

        showUploadModal: false,
    }

    async componentDidMount() {
        await this.props.i18n.loadNamespaces(['client_src_helper_diagnostico_DiagnosticoHelper']);

        this.loadPeriodos();
    }

    loadPeriodos = async () => {
        
        this.setState({ loadingPeriodosAvaliacao: true });

        let { periodos, canEdit, canView } = await DiagnosticoHelper.getPeriodosAvaliacao(this.props.idProcesso);
        
        let idPeriodoAvaliacao = null;

        let localId = DiagnosticoHelper.getPeriodoAvaliacaoLocal(this.props.idProcesso);

        if (localId && periodos.find(periodo => periodo.id == localId)) {
            idPeriodoAvaliacao = localId;
        } else {
            idPeriodoAvaliacao = periodos.length > 0 ? periodos[0].id : null;
        }
            

        this.setState({ periodosAvaliacao: periodos, idPeriodoAvaliacao, canEdit, canView, loadingPeriodosAvaliacao: false });
    }

    reloadPeriodo = async () => {
        this.setState({ reloadPeriodo: this.state.reloadPeriodo + 1 });
    }

    changePeriodo = (idPeriodoAvaliacao) => {
        this.setState({ idPeriodoAvaliacao });
    }

    renderHeader() {

        const message = IdiomaHelper.getStruct({
            'pt_br': {
                selectPlaceholder: 'Selecione um Período de Avaliação',
                upload: 'Importação de Resultados',
                tooltipOrientacoes: 'Orientações de Preenchimento dos Questionários',
            },
            'en': {
                selectPlaceholder: 'Select an Evaluation Period',
                upload: 'Import Results', 
                tooltipOrientacoes: 'Guidelines for completing the questionnaires',
            },
            'es': {
                selectPlaceholder: 'Seleccione un Período de Evaluación',
                upload: 'Importar resultados',
                tooltipOrientacoes: 'Orientaciones para completar los cuestionarios',
            }
        });

        return (
            <div className="gradesAvaliacao-header" style={{boxShadow: Colors.boxShadow}}>
               <div className="header-left">
                    <Select
                        styles={{ control: (base) => ({ ...base, border: 'none', minWidth: '40vh', marginRight: 2 }) }}
                        options={DataHelper.formatSelectData(this.state.periodosAvaliacao, 'id', 'nome')}
                        value={DataHelper.formatSelectData(this.state.periodosAvaliacao, 'id', 'nome').find(option => option.value === this.state.idPeriodoAvaliacao)}
                        onChange={(valueSelected) => {  this.changePeriodo(valueSelected.value); DiagnosticoHelper.setPeriodoAvaliacaoLocal(valueSelected.value, this.props.idProcesso) }}
                        placeholder={message[this.state.lang].selectPlaceholder}
                        noOptionsMessage={DataHelper.getSelectEmptyMessage}
                        isSearchable
                        menuPortalTarget={document.body}
                    />
               </div>

                <div className="header-right">
                    

                    { !(!this.state.canEdit || !this.state.idPeriodoAvaliacao) && 
                        <DefaultButton
                            leftIcon={<FontAwesomeIcon icon={faUpload} />}
                            title={message[this.state.lang].upload}
                            color={Colors.secondaryButton}
                            textColor={Colors.dark}
                            onClick={() => { this.setState({showUploadModal: !this.state.showUploadModal}); }}
                            disabled={!this.state.canEdit || !this.state.idPeriodoAvaliacao}
                        />
                    }

                    <DefaultButton
                        style={{ width: 38, height: 38 }}
                        fontSize={this.props.isSmallScreen ? 14 : "unset"}
                        color={Colors.secondaryButton}
                        textColor={Colors.dark}
                        link={'https://advis.freshdesk.com/support/solutions/folders/63000238312'}
                    />
                </div>
            </div>
        )
    }

    renderGradeAvaliacao() {
        return (
            <div className="gradesAvaliacao-container-padding">
                <div className="gradesAvaliacao-container" style={{boxShadow: Colors.boxShadow}}>
                    <GradeAvaliacao
                        idPeriodoAvaliacao={this.state.idPeriodoAvaliacao}
                        reloadPeriodo={this.state.reloadPeriodo}
                        lang={this.state.lang}
                    />
                </div>
            </div>
        )
    }

    renderUploadModal() {

        
        if (this.state.showUploadModal){
            return (
                <ModalUploadResultadosAvaliacao
                    idPeriodoAvaliacao={this.state.idPeriodoAvaliacao}
                    closeModal={() => this.setState({showUploadModal: false}, () => {this.reloadPeriodo()})}
                />
            )
        }
    }

    render() {
        return (
            <div className="GradesAvaliacao">
                {this.renderHeader()}
                {this.state.idPeriodoAvaliacao ? this.renderGradeAvaliacao() : DiagnosticoHelper.renderPeriodoNotSelected(this.props.t, this.state.loadingPeriodosAvaliacao)}
                {this.state.idPeriodoAvaliacao && this.renderUploadModal()}
            </div>
        )
    }
}

export default withTranslation()(GradesAvaliacao);