// Bibliotecas externas
import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle, faSave, faCircle } from '@fortawesome/free-solid-svg-icons';
import { withTranslation } from 'react-i18next';

// Helpers
import SessionHelper from "../../../../../../helper/SessionHelper";
import DiagnosticoHelper from "../../../../../../helper/diagnostico/DiagnosticoHelper";

// Estilos
import Colors from "../../../../../../constants/Colors";
// - Comentado até que seja adicionado algo - 
// import './AddDivisor.css';

// Componentes
import DefaultButton from "../../../../../tools/DefaultButton";
import DefaultLoader from "../../../../../tools/DefaultLoader";


class AddDivisor extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,

            item: props.item,

            headerHeight: 0,
        }
    }

    headerRef = React.createRef();

    async componentDidMount() {
        await this.props.i18n.loadNamespaces(['client_src_components_modules_diagnostico_CriacaoRelatorio_AddModuloRelatorio_AddDivisor_AddDivisor']);
        await this.props.i18n.loadNamespaces(['client_src_helper_diagnostico_DiagnosticoHelper']);

        await this.loadData();
        this.measureHeaderHeight();
        window.addEventListener('resize', this.measureHeaderHeight);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.measureHeaderHeight);
    }

    measureHeaderHeight = () => {
        const headerElement = document.querySelector('.criacaoRelatorio');
        if (headerElement) {
            const height = headerElement.offsetHeight;
            if (height !== this.state.headerHeight) {
                this.setState({ headerHeight: height });
            }
        }
    }

    async loadData() {
        this.setState({ loading: true });
        
        this.setState({
            item: {
                ...this.props.item,
                on: true,
                type: this.props.type,
                
            }
        }, () => { this.props.onSetItem(this.state.item) } );

        this.setState({ loading: false });
    }

    renderHeader = () => {
        return (
            <div className="headerClose" style={{backgroundColor: SessionHelper.getColor()}}>
                <div/>
                {DiagnosticoHelper.getFunctionsModulosModeloRelatorioByType(this.props.type, this.props.t)?.title ?? ''}
                <FontAwesomeIcon icon={faTimesCircle} style={{ cursor: 'pointer' }} onClick={() => this.props.onClose()} />
            </div>
        )
    }

    renderBody = () => {

        return (
            <div className="bodyModulo">
        
                {/* Divisor */}
                <div className="content box" style={{flexDirection: 'row', width: '100%', padding: 20, marginBottom: 0, marginTop: 20}}>
                    <span style={{flex: 1, height: 2, backgroundColor: '#c3c3c3'}}></span>
                    <FontAwesomeIcon icon={faCircle} style={{fontSize: 5, margin: '0px 5px'}}/>
                    <span style={{flex: 1, height: 2, backgroundColor: '#c3c3c3'}}></span>
                </div>

                <div className="buttons">
                    <DefaultButton
                        title={this.props.i18n.t('client_src_components_modules_diagnostico_CriacaoRelatorio_AddModuloRelatorio_AddDivisor_AddDivisor:saveButtonTitle')}
                        leftIcon={<FontAwesomeIcon icon={faSave}/>}
                        color={Colors.success}
                        tooltip={this.props.i18n.t('client_src_components_modules_diagnostico_CriacaoRelatorio_AddModuloRelatorio_AddDivisor_AddDivisor:saveButtonTooltip')}
                        onClick={() => {this.props.addModule()}}
                    />
                </div>

            </div>
        );
    }

    renderLoading() {
        return (
            <div className="bodyModulo" style={{justifyContent: 'center', alignItems: 'center', overflow: 'hidden', height: "calc(100% - 3rem)"}}>
                <DefaultLoader/>
            </div>
        )
    }

    renderContent() {

        return (
            <div className="ModuloConfig" style={{maxHeight: `calc(${this.state.headerHeight}px - 20px)`, minHeight: '20vh'}}>
                {this.renderHeader()}
                {!this.state.loading ? this.renderBody() : this.renderLoading()}
            </div>
        )
    }

    render() {
        if (!this.props.type) return <></>;
        return this.renderContent();
    }
}

export default withTranslation()(AddDivisor);