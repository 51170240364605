import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';
import IdiomaHelper from '../../../../helper/IdiomaHelper';
import Tabs from '../../../tools/Tabs';
import Select from 'react-select';
import DataHelper from '../../../../helper/DataHelper';
import Sig from '../../../../api/Sig';
import TextEditor from '../../../tools/TextEditor/TextEditor';
import Colors from '../../../../constants/Colors';
import EssentialStyle from '../../../../style/EssentialStyle';
import DefaultButton from '../../../tools/DefaultButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import DiagnosticoHelper from '../../../../helper/diagnostico/DiagnosticoHelper';
import DefaultLoader from '../../../tools/DefaultLoader';

class OrientacaoCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            activeTab: 'apresentacao',
            textos: {},
            texto: this.props.orientacao.textos.find(t => t.tipo == 1 && t.id_idioma == IdiomaHelper.getSiglaId(this.props.selectedLang))?.texto || "",
        };
        this.saveTimeout = null;
    }

    onChangeQuestionario = async (value) => {
        if (this.props.viewOnly) return;

        this.props.onChangeOrientacao({
            ...this.props.orientacao,
            questionarios: value.map(v => ({ id: v.value, nome: v.label }))
        });

        if (this.saveTimeout) {
            this.saveTimeout = clearTimeout(this.saveTimeout);
        }
        this.saveTimeout = setTimeout(this.saveText, 500);
    }

    async componentDidMount() {
        this.setState({ loading: true });
        await this.props.i18n.loadNamespaces(['client_src_components_modules_diagnostico_Orientacao_OrientacaoCard']);
        await this.props.i18n.loadNamespaces(['client_src_helper_diagnostico_DiagnosticoHelper']);
        this.setState({ loading: false });
    }

    componentDidUpdate(prevProps) {

        if (prevProps.selectedLang !== this.props.selectedLang) {
            this.setState({ texto: this.props.orientacao.textos.find(t => t.tipo == DiagnosticoHelper.getTipoTextosOrientacaoByName(this.state.activeTab, this.props.t) && t.id_idioma == IdiomaHelper.getSiglaId(this.props.selectedLang))?.texto || "" });
        }

        if (prevProps.forceSave != this.props.forceSave && this.props.forceSave) {
            if (this.props.viewOnly) return;
            if (this.saveTimeout) {
                this.saveTimeout = clearTimeout(this.saveTimeout);
            }
            this.saveText();
        }
    }

    async componentWillUnmount() {
        if (this.props.viewOnly) return;
        if (this.saveTimeout) {
            this.saveTimeout = clearTimeout(this.saveTimeout);
            await this.saveText(false);
        }
    }

    saveText = async (retry = true) => {
        if (this.props.viewOnly) return;

        if (this.saveTimeout) {
            this.saveTimeout = clearTimeout(this.saveTimeout);
        }

        var req = await Sig.request('POST', '/diagnostico/orientacoes/saveOrientacao', {
            idPeriodoAvaliacao: this.props.idPeriodoAvaliacao,
            orientacao: JSON.stringify(this.props.orientacao)
        });

        if (req.status != 200 && !this.saveTimeout && retry) {
            this.saveTimeout = setTimeout(this.saveText, 500);
        }

        return true;
    }

    changeText = (value) => {
        this.setState({ texto: value });

        if (this.props.viewOnly) return;

        if (this.props.orientacao.textos.find(t => t.tipo == DiagnosticoHelper.getTipoTextosOrientacaoByName(this.state.activeTab, this.props.t) && t.id_idioma == IdiomaHelper.getSiglaId(this.props.selectedLang))) {

            this.props.onChangeOrientacao({
                ...this.props.orientacao,
                textos:
                    this.props.orientacao.textos.map(t => {
                        if (t.id_idioma == IdiomaHelper.getSiglaId(this.props.selectedLang) && t.tipo == DiagnosticoHelper.getTipoTextosOrientacaoByName(this.state.activeTab, this.props.t)) {
                            return { ...t, texto: value };
                        }
                        return t;
                    })
            });

        } else {
            this.props.onChangeOrientacao({
                ...this.props.orientacao,
                textos: [
                    ...this.props.orientacao.textos,
                    {
                        id_idioma: IdiomaHelper.getSiglaId(this.props.selectedLang),
                        tipo: DiagnosticoHelper.getTipoTextosOrientacaoByName(this.state.activeTab, this.props.t),
                        texto: value
                    }
                ]
            });
        }

        if (this.saveTimeout) {
            clearTimeout(this.saveTimeout);
        }
        this.saveTimeout = setTimeout(this.saveText, 500);
    }

    changeTab = async (tab) => {
        if (this.saveTimeout && !this.props.viewOnly) {
            this.saveTimeout = clearTimeout(this.saveTimeout);
            await this.saveText();
        }

        this.setState({ activeTab: tab.name, texto: this.props.orientacao.textos.find(t => t.tipo == DiagnosticoHelper.getTipoTextosOrientacaoByName(tab.name, this.props.t) && t.id_idioma == IdiomaHelper.getSiglaId(this.props.selectedLang))?.texto || "" });
    }

    render() {
        if (this.state.loading) {
            return (
                <div className="orientacao-card">
                    <div style={{ ...EssentialStyle.rowFlexCenter, height: '150px', width: '100%' }}>
                        <DefaultLoader />
                    </div>
                </div>
            )
        }

        var tipoText = this.props.t('client_src_components_modules_diagnostico_Orientacao_OrientacaoCard:' + this.state.activeTab);
        var orientacao = '<p>';
        if (this.state.activeTab == 'apresentacao') {
            orientacao += this.props.t('client_src_components_modules_diagnostico_Orientacao_OrientacaoCard:greetings') + '<br><br>';
        } 
        if (this.state.activeTab == 'orientacao') {
            orientacao += '<h2 class="ql-align-center"><strong>' + this.props.t('client_src_components_modules_diagnostico_Orientacao_OrientacaoCard:orientationIntro') + '</strong></h2> <p class="ql-align-center"><br></p>' + 
                    '<p class="ql-align-center">' + this.props.t('client_src_components_modules_diagnostico_Orientacao_OrientacaoCard:orientationText') + '</p><p class="ql-align-center"><br></p>';
        }
        orientacao += this.state.texto;
        orientacao += '</p>';

        const tabs = [
            {
                name: 'apresentacao',
                title: this.props.t('client_src_components_modules_diagnostico_Orientacao_OrientacaoCard:apresentacao'),
            },
            {
                name: 'orientacao',
                title: this.props.t('client_src_components_modules_diagnostico_Orientacao_OrientacaoCard:orientacao'),
            },
            {
                name: 'conclusao',
                title: this.props.t('client_src_components_modules_diagnostico_Orientacao_OrientacaoCard:conclusao'),
            }
        ];

        return (
            <div key={this.props.orientacao?.id} className="orientacao-card">
                <div className="orientacao-card-header">
                    <Select
                        options={DataHelper.formatSelectData(this.props.questionariosDisponiveis, 'id', 'nome')}
                        value={DataHelper.formatSelectData(this.props.orientacao.questionarios || [], 'id', 'nome')}
                        onChange={(value) => { this.onChangeQuestionario(value) }}
                        placeholder={
                            this.props.index == 0 && this.props.questionariosDisponiveis?.length == this.props.questionarios?.length ?
                                this.props.t('client_src_components_modules_diagnostico_Orientacao_OrientacaoCard:placeholderQuestionariosDefault')
                                :
                                this.props.t('client_src_components_modules_diagnostico_Orientacao_OrientacaoCard:placeholderQuestionarios')
                        }
                        noOptionsMessage={() => this.props.t('client_src_components_modules_diagnostico_Orientacao_OrientacaoCard:noOptionsQuestionarios')}
                        isSearchable
                        isMulti
                        isDisabled={this.props.viewOnly}
                        menuPlacement="auto"
                        styles={{ container: (base) => ({ ...base, flex: 1, width: this.props.isSmallScreen ? "100%" : "unset" }) }}
                    />
                    <div className="header-buttons">
                        <Tabs
                            tabs={tabs}
                            activeTab={this.state.activeTab}
                            isSmallScreen={this.state.isSmallScreen}
                            onTabChange={this.changeTab}
                        />
                        {
                            !this.props.viewOnly &&
                            <DefaultButton
                                leftIcon={<FontAwesomeIcon icon={faTrash} />}
                                color={"transparent"}
                                textColor={Colors.dark}
                                style={{ width: 35, height: 35, padding: 0, alignSelf: 'flex-end' }}
                                onClick={() => this.props.onDelete(this.props.orientacao.id)}
                            />
                        }
                    </div>
                </div>
                <div className="orientacao-card-info">
                    <span className="orientacao-info-text">{this.props.t('client_src_components_modules_diagnostico_Orientacao_OrientacaoCard:' + this.state.activeTab + 'Info')}</span>
                    <span className="horizontal-line" />
                </div>
                <div className="orientacao-card-body">
                    {
                        !this.props.viewOnly &&
                        <TextEditor
                            width={this.props.isSmallScreen ? "100%" : "50%"}
                            height={'100%'}
                            editorStyle={{ width: "100%", height: "100%" }}
                            defaultValue={this.state.texto || ""}
                            onChange={this.changeText}
                            placeholder={this.props.t('client_src_components_modules_diagnostico_Orientacao_OrientacaoCard:placeholderTexto', { tipo: tipoText })}
                            editorClassName={"fill-container-editor"}
                            noMargin
                            allowAlignments
                        />
                    }
                    <div style={{ width: this.props.isSmallScreen || this.props.viewOnly ? "100%" : "50%", gap: 5, padding: "0px 10px" }}>
                        <span style={{ fontSize: 18, fontWeight: 500, marginBottom: 10, color: Colors.homePage.darkGrey }}>{this.props.t('client_src_components_modules_diagnostico_Orientacao_OrientacaoCard:preview')}</span>
                        <TextEditor
                            noBorder
                            noMargin
                            disabled
                            defaultValue={orientacao}
                            allowAlignments
                            hideToolbar
                            placeholder={' '}
                            backgroundColor={this.props.viewOnly ? "transparent" : Colors.white}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(OrientacaoCard);