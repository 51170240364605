import React from "react";

import './AddModuloRelatorio.css';
import DiagnosticoHelper from "../../../../../helper/diagnostico/DiagnosticoHelper";
import TextEditor from "../../../../tools/TextEditor/TextEditor";
import IdiomaHelper from "../../../../../helper/IdiomaHelper";
import DefaultButton from "../../../../tools/DefaultButton";
import Colors from "../../../../../constants/Colors";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faTimesCircle, faPaperPlane, faEye, faBars, faInfoCircle, faSave } from '@fortawesome/free-solid-svg-icons';
import DataHelper from "../../../../../helper/DataHelper";
import Select from "react-select";
import EssentialStyle from "../../../../../style/EssentialStyle";
import { toast } from "react-toastify";
import SessionHelper from "../../../../../helper/SessionHelper";
import DragAndDropHelper from "../../../../../helper/DragAndDropHelper";
import SelectWidth from "../../../../tools/SelectWidth/SelectWidth";
import DefaultLoader from "../../../../tools/DefaultLoader";
import CustomTooltip from "../../../../tools/CustomTooltip";
import IndicadorReferenciaCard from "../../Indicadores/IndicadorReferenciaCard/IndicadorReferenciaCard";
import { Form, Collapse } from "react-bootstrap";
import ModalPeriodoGrupoIndicadores from "../../Indicadores/ModalPeriodoGrupoIndicadores/ModalPeriodoGrupoIndicadores";
import AddPerformanceTabela from "./AddPerformanceTable/AddPerformanceTable";
import { withTranslation } from 'react-i18next';
import AddPerformance from "./AddPerformance/AddPerformance";
import AddDivisor from "./AddDivisor/AddDivisor";
import AddTexto from "./AddTexto/AddTexto";
import AddResultadoGraficos from "./AddResultadoGraficos/AddResultadoGraficos";
import AddResultadoTabela from "./AddResultadoTabela/AddResultadoTabela";


class AddModuloRelatorio extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            lang: props.lang,
            item: props.item,
        }       
    }


    async componentDidMount() {
        await this.loadData();
    }

    async componentDidUpdate(prevProps) {
        if (prevProps.type !== this.props.type) {
            await this.loadData();
        }
    }

    async loadData() {
        this.setState({ loading: true });

        this.setState({ item: {...this.props.item, on: true} }, () => { this.props.onSetItem(this.state.item) });
        
        this.setState({ loading: false });
    }

    renderBodyChart() {

        return (
            <AddResultadoGraficos 
                {...this.props}
                onSetItem={(item) => this.props.onSetItem(item)}
                addModule={() => {this.props.addModule && this.props.addModule()}}
            />
        )

    }

    renderBodyText() {

        return (
            <AddTexto 
                {...this.props}
                onSetItem={(item) => this.props.onSetItem(item)}
                addModule={() => {this.props.addModule && this.props.addModule()}}
            />
        )

    }

    renderBodyDivider() {

        return (
            <AddDivisor 
                {...this.props}
                onSetItem={(item) => this.props.onSetItem(item)}
                addModule={() => {this.props.addModule && this.props.addModule()}}
            />
        )
    }

    renderBodyResults() {

        return (
            <AddResultadoTabela
                {...this.props}
                onSetItem={(item) => this.props.onSetItem(item)}
                addModule={() => {this.props.addModule && this.props.addModule()}}
            />
        )
    }

    renderBodyPerformance() {

        return (
            <AddPerformance 
                {...this.props}
                onSetItem={(item) => this.props.onSetItem(item)}
                addModule={() => {this.props.addModule && this.props.addModule()}}
                lang={this.props.lang}
            />
        )
    }

    renderBodyPerformanceTable() {

        return (
            <AddPerformanceTabela 
                {...this.props}
                onSetItem={(item) => this.props.onSetItem(item)}
                addModule={() => {this.props.addModule && this.props.addModule()}}
                lang={this.props.lang}
            />
        )

    }

    renderContent() {
        if (!this.props.type) return <></>;

        switch (this.props.type) {
            case 'chart':
                return this.renderBodyChart();
            case 'text':
                return this.renderBodyText();
            case 'divider':
                return this.renderBodyDivider();
            case 'performance':
                return this.renderBodyPerformance();
            case 'results':
                return this.renderBodyResults();
            case 'performanceTable':
                return this.renderBodyPerformanceTable();
            default:
                return <></>;
        }
    }

    renderLoading() {
        return (
            <div className="ModuloConfig" 
                style={{ 
                    height: '11rem', 
                    width: "100%",
                    maxHeight: `calc(${this.state.headerHeight}px - 20px)`
                }}>
                <div className="headerClose" style={{backgroundColor: SessionHelper.getColor()}}>
                </div>
                
                <div className="bodyModulo" style={{justifyContent: 'center', alignItems: 'center', overflow: 'hidden', height: "calc(100% - 3rem)"}}>
                    <DefaultLoader/>
                </div>
            </div>
        )
    }

    render() {
        if (!this.props.type) return <></>;
        if (this.state.loading) return this.renderLoading();
        return this.renderContent();
    }
}

export default withTranslation()(AddModuloRelatorio);