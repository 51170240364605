import React from "react";
import {withTranslation} from "react-i18next";
import Select from 'react-select';
import { components } from "react-select";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faCheck } from '@fortawesome/free-solid-svg-icons';
import { toast } from "react-toastify";

import EssentialStyle from "../../../style/EssentialStyle";
import DataHelper from "../../../helper/DataHelper";
import Sig from "../../../api/Sig";
import DefaultButton from "../../tools/DefaultButton";
import Colors from "../../../constants/Colors";
import CustomConfirm from "../../tools/CustomConfirm";
import { confirmAlert } from "react-confirm-alert";
import SessionHelper from "../../../helper/SessionHelper";

class PlanoAcaoEtapaDependenciaAdd extends React.Component {
    state = {
        loading: true,
        tipos: this.props.tipos,
        selectedTipo: this.props.defaultTipo,
        selectedDependecia: null,
        dependenciasPossiveis: [],
    }

    async componentDidMount() {
        await this.props.i18n.loadNamespaces(["client_src_components_modules_planoAcao_PlanoAcaoEtapaDependenciaAdd"]);
        await this.loadDependenciasPossiveis();
    }

    async loadDependenciasPossiveis() {
        this.setState({ loading: true });
        let dependencias = await Sig.request('POST', 'planoAcao/listDependenciasPossiveis', { id: this.props.id || 0 });
        this.setState({ dependenciasPossiveis: dependencias }, () => { this.setState({ loading: false }) });
    }

    renderSelectTipo = () => {
        return (
            <div
                style={{
                    ...EssentialStyle.rowFlexStart,
                    width: '202px',
                    fontSize: 14
                }}
            >
                <Select
                    ref={ref => this.selectPrioridade = ref}
                    styles={{
                        container: (base, state) => ({
                            ...base,
                            width: '100%',
                        }),
                        control: (base, state) => ({
                            ...base,
                            ...EssentialStyle.titleBoxHomePage,
                            width: '100%',
                            backgroundColor: "transparent"
                        })
                    }}
                    value={{
                        value: this.state.selectedTipo?.value || 0,
                        label: this.props.i18n.t(`client_src_components_modules_planoAcao_PlanoAcaoEtapaDependenciaAdd:tipo_${this.state.selectedTipo?.value || 0}`)
                    }}
                    options={this.state.tipos.map(tipo => ({ value: tipo.value, label: this.props.i18n.t(`client_src_components_modules_planoAcao_PlanoAcaoEtapaDependenciaAdd:tipo_${tipo.value}`) }))}
                    placeholder={''}
                    noOptionsMessage={DataHelper.getSelectEmptyMessage}
                    isSearchable={false}
                    closeMenuOnSelect={true}
                    components={{ IndicatorSeparator: null }}
                    onChange={async (value) => {
                        this.setState({
                            selectedTipo: value
                        }, () => {
                            this.props.changeCallback(this.state.selectedTipo, this.state.selectedDependecia);
                        });
                    }}
                />
            </div>
        );
    }

    renderSelectDepedencia = () => {
        var options = this.state.dependenciasPossiveis.filter(dep => dep.tiposRestritos == null || !dep.tiposRestritos.includes(this.state.selectedTipo.value));
        return (
            <div
                style={{
                    ...EssentialStyle.rowFlexStart,
                    width: 'calc(100% - 202px)',
                    paddingLeft: 6,
                    fontSize: 14
                }}
            >
                <Select
                    ref={ref => this.selectedDependecia = ref}
                    styles={{
                        container: (base, state) => ({
                            ...base,
                            width: '100%',
                        }),
                        control: (base, state) => ({
                            ...base,
                            ...EssentialStyle.titleBoxHomePage,
                            width: '100%',
                            backgroundColor: "transparent"
                        })
                    }}
                    value={this.state.selectedDependecia}
                    options={DataHelper.formatSelectData(options, 'id', 'atividade')}
                    formatOptionLabel={({ label, value }) => (
                        <div style={{
                            ...EssentialStyle.rowFlexStart,
                            paddingLeft: 8,
                            fontWeight: 400,
                            width: '100%',
                            cursor: 'pointer'
                        }}>
                            {DataHelper.removeHtmlTags(label)}
                        </div>
                    )}
                    placeholder={this.props.i18n.t('client_src_components_modules_planoAcao_PlanoAcaoEtapaDependenciaAdd:selecione_a_dependencia')}
                    noOptionsMessage={DataHelper.getSelectEmptyMessage}
                    isSearchable={true}
                    closeMenuOnSelect={true}
                    components={{ IndicatorSeparator: null }}
                    onChange={async (value) => {
                        this.setState({
                            selectedDependecia: value
                        }, () => {
                            this.props.changeCallback(this.state.selectedTipo, this.state.selectedDependecia);
                        });
                    }}
                />
            </div>
        );
    }

    blockWarningModal = () => { 
        return (
            confirmAlert({
                customUI: ({ onClose }) => (
                    <CustomConfirm
                        title={this.props.i18n.t('client_src_components_modules_planoAcao_PlanoAcaoEtapaDependenciaAdd:deseja_adicionar_dependencia')}
                        message={
                            <p style={{ marginTop: 10, marginBottom: 10 }}>
                                {this.props.i18n.t('client_src_components_modules_planoAcao_PlanoAcaoEtapaDependenciaAdd:datas_alteradas_automaticamente')}
                            </p>
                        }
                        buttons={[
                            {
                                label: this.props.i18n.t('client_src_components_modules_planoAcao_PlanoAcaoEtapaDependenciaAdd:adicionar'),
                                color: SessionHelper.getColor(),
                                textColor: Colors.light,
                                onClick: () => { this.saveDependencia(); onClose(); }
                            },
                            {
                                label: this.props.i18n.t('client_src_components_modules_planoAcao_PlanoAcaoEtapaDependenciaAdd:cancelar'),
                                onClick: () => { onClose(); }
                            },
                        ]}
                    />
                )
            })
        );
    }

    validateDependencia = () => {
        if (!this.state.selectedTipo?.value < 0 || !this.state.selectedDependecia?.value)
            return toast.info(this.props.i18n.t('client_src_components_modules_planoAcao_PlanoAcaoEtapaDependenciaAdd:selecionar_a_depedencia'));
        
        if (this.state.selectedTipo.value === 0 || this.state.selectedTipo.value === 1) {
            this.blockWarningModal();
            return;
        }
        this.saveDependencia();
    }

    saveDependencia = async () => {
        this.setState({ loading: true });
        let tipo = this.state?.selectedTipo?.value;
        await Sig.request('POST', 'planoAcao/saveEtapaDependencia', { 
            id_plano_acao_etapa_origem: this.props.id || 0, 
            id_plano_acao_etapa_alvo: this.state.selectedDependecia.value, 
            tipo: tipo
        });
        this.setState({ addingNew: false, selectedDependecia: null }, () => { 
            this.setState({ loading: false });
            this.props.saveCallback(tipo !== 0); 
        });
    }

    render() {
        return (
            <div style={{ ...EssentialStyle.columnCenter, width: '100%' }}>
                <div style={{ ...EssentialStyle.rowSpaceBetween, width: '100%' }}>
                    {this.renderSelectTipo()}
                    {!this.state.loading && this.renderSelectDepedencia()}
                </div>
                <div style={{ ...EssentialStyle.rowSpaceBetween, width: '100%', marginTop: 10 }}>
                    <DefaultButton
                        title={this.props.i18n.t('client_src_components_modules_planoAcao_PlanoAcaoEtapaDependenciaAdd:cancelar')}
                        color={Colors.error}
                        textColor={Colors.white}
                        leftIcon={<FontAwesomeIcon icon={faTimes} />}
                        loading={this.state.loading}
                        style={{ width: '49%' }}
                        onClick={() => {
                            this.setState({ addingNew: false, addingSelectedDependencia: null });
                            this.props.saveCallback(true);
                        }}
                    />
                    <DefaultButton
                        title={this.props.i18n.t('client_src_components_modules_planoAcao_PlanoAcaoEtapaDependenciaAdd:adicionar')}
                        color={Colors.success}
                        textColor={Colors.white}
                        leftIcon={<FontAwesomeIcon icon={faCheck} />}
                        loading={this.state.loading}
                        style={{ width: '49%' }}
                        onClick={this.validateDependencia}
                    />
                </div>
            </div>
        );
    }
}

export default withTranslation()(PlanoAcaoEtapaDependenciaAdd);