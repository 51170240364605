import React from "react";

import './RetrospectivaMelhorMes2024.css';
import EssentialStyle from "../../../../style/EssentialStyle";
import Constants from '../../../../constants/Api';
import Colors from "../../../../constants/Colors";
import moment from 'moment';

const gateway = Constants.getSigEndPoint();

export default class RetrospectivaMelhorMes2024 extends React.Component {
    state = {
        melhorMes: this.props.melhorMes,
        screenHeight: this.props.screenHeight,
        isSmallScreen: this.props.isSmallScreen
    }

    async componentDidUpdate(prevProps) {
        if (prevProps.melhorMes !== this.props.melhorMes) {
            this.setState({ melhorMes: this.props.melhorMes });
        }

        if (prevProps.screenHeight !== this.props.screenHeight) {
            this.setState({ screenHeight: this.props.screenHeight });
        }

        if (prevProps.isSmallScreen !== this.props.isSmallScreen) {
            this.setState({ isSmallScreen: this.props.isSmallScreen });
        }
    }

    renderTop() {
        let mesString = this.state.melhorMes.mes;
        let mes = this.getMonthName(mesString);

        return (
            <div
                style={{
                    ...EssentialStyle.rowFlexStart,
                    alignItems: 'center',
                    width: '100%',
                    height: '20%'
                }}
            >
                <span
                    className="retro-default-text"
                    style={{
                        minWidth: this.state.isSmallScreen ? '100%' : '95%',
                        marginTop: '40px',
                        fontWeight: '400'
                    }}
                >
                    O mês de maior destaque em produtividade foi: <b>{mes}</b>! Entre metas, projetos e entregas, esse foi o período em que você realmente brilhou. Será que já dá para eleger seu mês favorito do ano?
                </span>
            </div>
        );
    }

    renderMesAnterior() {
        if(!this.state.melhorMes?.anterior?.mes) return null;

        let mesString = this.state.melhorMes?.anterior?.mes;
        let mes = this.getMonthName(mesString);

        let performance = this.state.melhorMes?.anterior?.performance.toFixed(1).replace('.', ',');


        return (
            <div>{mes} {performance}%</div>
        );
    }

    renderMesAtual() {
        let mesString = this.state.melhorMes?.mes;
        let mes = this.getMonthName(mesString);

        let performance = this.state.melhorMes?.performance.toFixed(1).replace('.', ',');

        return (
            <div style={{ fontWeight: '600' }}>{mes} {performance}%</div>
        );
    }

    renderMesSubsequente() {
        if(!this.state.melhorMes?.proximo?.mes) return null;

        let mesString = this.state.melhorMes?.proximo?.mes;
        let mes = this.getMonthName(mesString);

        let performance = this.state.melhorMes?.proximo?.performance.toFixed(1).replace('.', ',');

        return (
            <div>{mes} {performance}%</div>
        );
    }

    getMonthName(mesString) {
        let mes = moment(mesString, 'MM/YY').format('MMMM');
        mes = mes.charAt(0).toUpperCase() + mes.slice(1);
        return mes;
    }

    renderBottom() {
        return (
            <div
                style={{
                    ...EssentialStyle.columnStart,
                    alignItems: this.state.isSmallScreen ? 'center' : 'flex-start',
                    height: this.state.isSmallScreen ? '350px' : '80%',
                    width: '100%',
                    fontSize: this.state.isSmallScreen ? '4rem' : '6.5rem',
                    fontWeight: '200',
                    marginTop: '40px'
                }}
            >
                {this.renderMesAnterior()}
                {this.renderMesAtual()}
                {this.renderMesSubsequente()}
            </div>
        );
    }

    renderMelhorMes() {
        return (
            <div
                style={{
                    ...EssentialStyle.columnCenter,
                    width: '100%',
                    height: '100%'
                }}
            >
                <div
                    style={{
                        ...EssentialStyle.columnCenter,
                        width: '100%',
                        height: this.state.isSmallScreen ? '100%' : '65%',
                        paddingLeft: this.state.isSmallScreen ? 10 : '30px',
                        paddingRight: this.state.isSmallScreen ? 10 : '30px',
                    }}
                >
                    {this.renderTop()}
                    {this.renderBottom()}
                </div>
            </div>
        );
    }

    render() {
        return (
            <div
                style={{
                    ...EssentialStyle.columnCenter,
                    width: '100%',
                    height: this.state.isSmallScreen ? 'auto' : this.state.screenHeight
                }}
            >
                {this.renderMelhorMes()}
            </div>
        );
    }
}