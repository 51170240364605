import React from "react";
import '../PlanoAcaoRelatorio.css';

import EssentialStyle from '../../../../../style/EssentialStyle';
import DefaultLoader from '../../../../tools/DefaultLoader';
import { Collapse } from "react-bootstrap";
import Colors from "../../../../../constants/Colors";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import SessionHelper from '../../../../../helper/SessionHelper';
import moment from 'moment';
import Sig from "../../../../../api/Sig";

export default class PlanoAcaoRelatorioDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            contadores: {},
            expanded: false,
            tipoVinculos: [],
            validacao: [],
            tendencia: [],
            eficacia: [],
            loading: true,
            filteredFields: []
        }
        this.abortController = new AbortController();
    }

    async componentDidMount() {
        await this.load();
    }

    load = async () => {
        this.setState({ loading: true, contadores: {}, tipoVinculos: [], validacao: [], tendencia: [], eficacia: [] });

        let filter = { ...this.props.filter };
        delete filter.ids;
        delete filter.ids_etapas;
        delete filter.ids_planos;

        let req = await Sig.request(
            'POST', 
            'planoAcao/getContadoresDetalhamento', 
            { 
                filter: JSON.stringify(filter), 
                parceiro: this.props.parceiro ? 1 : 0, 
                relations: 0, 
                etapas: 0,
                id_periodo_avaliacao: this.props.id_periodo_avaliacao || 0
            },
            Sig.getDefaultContentType(),
            Sig.getDefaultTransformTo(),
            this.abortController.signal
        );

        if (req.status == 200 && req.tipos) {
            this.setState({
                contadores: req.counters,
                tipoVinculos: Object.keys(req.tipos.vinculos).map((e, key) => req.tipos.vinculos[key]),
                validacao: Object.keys(req.tipos.validacao).map((e, key) => req.tipos.validacao[key]),
                tendencia: req.tipos.tendencia,
                eficacia: req.tipos.eficacia
            }, () => {
                this.setState({ loading: false });
            });
        }
    }

    componentWillUnmount() {
        this.abortController.abort();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.filter !== this.props.filter) {
            let prevWithoutIds = { ...prevProps.filter };
            let withoutIds = { ...this.props.filter };

            delete prevWithoutIds.ids;
            delete prevWithoutIds.ids_etapas;
            delete prevWithoutIds.ids_planos;
            delete withoutIds.ids;
            delete withoutIds.ids_etapas;
            delete withoutIds.ids_planos;

            if (JSON.stringify(prevWithoutIds) !== JSON.stringify(withoutIds)) {
                let filter = { ...this.props.filter };
                delete filter.ids;
                this.props.setFilterCallback(filter);
                this.setState({ filteredFields: [] });
                this.load();
            }
        }
    }

    isFiltered = (field) => {
        if(!this.state.filteredFields) return false;
        if (this.state.filteredFields.includes(field)) return true;
        return false;
    }

    filterField = (field, ids = []) => {
        if(!ids?.length) return;
        
        let filteredFields = [];
        if(!this.isFiltered(field)) filteredFields = [ field ];

        this.setState({ filteredFields }, () => {
            let filter = { ...this.props.filter, ids: filteredFields.length ? ids : [] };

            this.props.setFilterCallback(filter);
        });
    }

    renderContador = (content) => {
        if (this.state.loading) return '--';
        return content;
    }

    getCursor = (ids) => {
        if (ids?.length) return "pointer";
        return "not-allowed";
    }

    renderCounters = () => {
        return (
            <div style={{ ...EssentialStyle.rowSpaceAround, gap: 15, flexWrap: "wrap", paddingBottom: 5 }}>
                <div style={{ ...EssentialStyle.columnCenter, gap: 5 }}>
                    <span style={{ fontSize: 18, fontWeight: 500, color: Colors.homePage.grey }}>Vínculos</span>
                    <div style={{ ...(this.props.isSmallScreen ? EssentialStyle.columnCenter : EssentialStyle.rowFlexCenter), width: this.props.isSmallScreen ? "100%" : "auto", gap: 10 }}>
                        <div
                            onClick={() => {
                                this.filterField('semVinculo', this.state?.contadores?.semVinculo);
                            }}
                            style={{
                                ...EssentialStyle.columnCenter, border: `1px solid ${Colors.homePage.grey}`, borderRadius: 8, zIndex: this.isFiltered ? 2 : 1,
                                backgroundColor: this.isFiltered('semVinculo') ? Colors.homePage.lightGrey : Colors.homePage.extraLightGrey,
                                height: 200, width: 150, gap: 5, padding: 5, cursor: this.getCursor(this.state?.contadores?.semVinculo), outline: this.isFiltered('semVinculo') ? `2px solid ${Colors.dark}` : "unset"
                            }}
                        >
                            <span style={{ fontSize: 50, fontWeight: 500, width: "100%", textAlign: "center", lineHeight: "100%", color: Colors.homePage.darkGrey }}>
                                {this.renderContador(this.state?.contadores?.semVinculo?.length)}
                            </span>
                            <span style={{ fontSize: 18, fontWeight: 500, width: "100%", textAlign: "center", lineHeight: "100%", color: Colors.homePage.grey }}>
                                Sem Vínculo com Resultados
                            </span>
                        </div>
                        <div style={{ ...EssentialStyle.rowFlexCenter }}>
                            <div
                                onClick={() => {
                                    this.filterField('totalVinculados', this.state?.contadores?.totalVinculados);
                                }}
                                style={{
                                    ...EssentialStyle.columnCenter, border: `1px solid ${Colors.homePage.grey}`, borderTopLeftRadius: 8, borderBottomLeftRadius: 8, zIndex: this.isFiltered('totalVinculados') ? 2 : 1,
                                    backgroundColor: this.isFiltered('totalVinculados') ? Colors.homePage.lightGrey : Colors.homePage.extraLightGrey,
                                    height: 200, width: 150, gap: 5, padding: 5, cursor: this.getCursor(this.state?.contadores?.totalVinculados), outline: this.isFiltered('totalVinculados') ? `2px solid ${Colors.dark}` : "unset"
                                }}
                            >
                                <span style={{ fontSize: 50, fontWeight: 500, width: "100%", textAlign: "center", lineHeight: "100%", color: Colors.homePage.darkGrey }}>
                                    {this.renderContador(this.state?.contadores?.totalVinculados?.length)}
                                </span>
                                <span style={{ fontSize: 18, fontWeight: 500, width: "100%", textAlign: "center", lineHeight: "100%", color: Colors.homePage.grey }}>
                                    Vinculados com Resultados
                                </span>
                            </div>
                            <div style={{ ...EssentialStyle.columnCenter, height: 200, width: 300, borderTopRightRadius: 8, borderBottomRightRadius: 8, border: `1px solid ${Colors.homePage.grey}`, borderLeft: "unset", backgroundColor: Colors.homePage.extraLightGrey, borderCollapse: 'collapse' }}>
                                {this.state.tipoVinculos.map((tipo, index) => {
                                    if (index == 0 || index == 7) return null;
                                    return (
                                        <div
                                            onClick={() => {
                                                this.filterField(`tipoVinculo-${index}`, this.state?.contadores?.vinculados[tipo]);
                                            }}
                                            key={"tipo-" + index}
                                            style={{
                                                ...EssentialStyle.rowFlexCenter, outline: this.isFiltered(`tipoVinculo-${index}`) ? `2px solid ${Colors.dark}` : "unset", borderTopRightRadius: index == 1 ? 8 : 0, zIndex: this.isFiltered(`tipoVinculo-${index}`) ? 2 : 1,
                                                borderBottom: index != (this.state.tipoVinculos.length - 1) ? `1px solid ${Colors.homePage.grey}` : "unset", width: "100%", flex: 1, borderBottomRightRadius: index == (this.state.tipoVinculos.length - 1) ? 8 : 0,
                                                textAlign: 'center', cursor: this.getCursor(this.state?.contadores?.vinculados[tipo]), backgroundColor: this.isFiltered(`tipoVinculo-${index}`) ? Colors.homePage.lightGrey : Colors.homePage.extraLightGrey
                                            }}
                                        >
                                            <span style={{ alignContent: "center", fontSize: 16, fontWeight: 500, color: Colors.homePage.darkGrey, height: "100%", width: 45, borderRight: `1px solid ${Colors.homePage.grey}` }}>
                                                {this.renderContador(this.state?.contadores?.vinculados[tipo]?.length)}
                                            </span>
                                            <span style={{ alignContent: "center", fontSize: 14, fontWeight: 500, color: Colors.homePage.grey, flex: 1, height: "100%", }}>
                                                {tipo}
                                            </span>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ ...EssentialStyle.columnCenter, gap: 5 }}>
                    <span style={{ fontSize: 18, fontWeight: 500, color: Colors.homePage.grey }}>Verificação de Eficácia</span>
                    <div style={{ ...(this.props.isSmallScreen ? EssentialStyle.columnCenter : EssentialStyle.rowFlexCenter), width: this.props.isSmallScreen ? "100%" : "auto", gap: 10, flexWrap: "wrap" }}>
                        <div style={{ ...EssentialStyle.columnCenter, height: 200, width: 350 }}>
                            <div
                                onClick={() => {
                                    this.filterField('totalValidacao', this.state?.contadores?.totalValidacao);
                                }}
                                style={{
                                    ...EssentialStyle.columnCenter, border: `1px solid ${Colors.homePage.grey}`, borderTopLeftRadius: 8, borderTopRightRadius: 8,
                                    backgroundColor: this.isFiltered('totalValidacao') ? Colors.homePage.lightGrey : Colors.homePage.extraLightGrey,
                                    width: "100%", gap: 2, padding: 5, cursor: this.getCursor(this.state?.contadores?.totalValidacao), zIndex: this.isFiltered('totalValidacao') ? 2 : 1, outline: this.isFiltered('totalValidacao') ? `2px solid ${Colors.dark}` : "unset"
                                }}
                            >
                                <span style={{ fontSize: 40, fontWeight: 500, width: "100%", textAlign: "center", lineHeight: "100%", color: Colors.homePage.darkGrey }}>
                                    {this.renderContador(this.state?.contadores?.totalValidacao?.length)}
                                </span>
                                <span style={{ fontSize: 15, fontWeight: 500, width: "100%", textAlign: "center", lineHeight: "100%", color: Colors.homePage.grey }}>
                                    Com Superior / Avaliador
                                </span>
                            </div>
                            <div style={{ ...EssentialStyle.columnCenter, width: "100%", borderBottomLeftRadius: 8, borderBottomRightRadius: 8, border: `1px solid ${Colors.homePage.grey}`, borderTop: "unset", backgroundColor: Colors.homePage.extraLightGrey, borderCollapse: "collapse", flex: 1 }}>
                                {this.state.validacao.map((validacao, index) => {
                                    return (
                                        <div
                                            onClick={() => {
                                                this.filterField(`validacao-${index}`, this.state?.contadores?.validacao[validacao]);
                                            }}
                                            key={"validacao-" + index}
                                            style={{
                                                ...EssentialStyle.rowFlexCenter, borderBottom: index != (this.state.validacao.length - 1) ? `1px solid ${Colors.homePage.grey}` : "unset", borderBottomLeftRadius: index == (this.state.validacao.length - 1) ? 8 : 0, borderBottomRightRadius: index == (this.state.validacao.length - 1) ? 8 : 0,
                                                width: "100%", flex: 1, textAlign: 'center', 
                                                cursor: this.getCursor(this.state?.contadores?.validacao[validacao]), 
                                                backgroundColor: this.isFiltered(`validacao-${index}`) ? Colors.homePage.lightGrey : Colors.homePage.extraLightGrey,
                                                outline: this.isFiltered(`validacao-${index}`) ? `2px solid ${Colors.dark}` : "unset", zIndex: this.isFiltered(`validacao-${index}`) ? 2 : 1
                                            }}
                                        >
                                            <span style={{ alignContent: "center", fontSize: 16, fontWeight: 500, color: Colors.homePage.darkGrey, height: "100%", width: 45, borderRight: `1px solid ${Colors.homePage.grey}` }}>
                                                {this.renderContador(this.state?.contadores?.validacao[validacao]?.length)}
                                            </span>
                                            <span style={{ alignContent: "center", fontSize: 14, fontWeight: 500, color: Colors.homePage.grey, flex: 1, height: "100%", }}>
                                                {this.renderContador(validacao)}
                                            </span>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                        <div style={{ ...EssentialStyle.columnCenter, height: 200, width: 350 }}>
                            <div style={{ ...EssentialStyle.columnCenter, border: `1px solid ${Colors.homePage.grey}`, borderTopLeftRadius: 8, borderTopRightRadius: 8, backgroundColor: Colors.homePage.extraLightGrey, width: "100%", gap: 2, padding: 5 }}>
                                <span style={{ fontSize: 25, fontWeight: 500, width: "100%", textAlign: "center", lineHeight: "100%", color: Colors.homePage.darkGrey }}>
                                    Tendência
                                </span>
                                <span style={{ fontSize: 15, fontWeight: 500, width: "100%", textAlign: "center", lineHeight: "100%", color: Colors.homePage.grey }}>
                                    Planos não Concluídos / Verificados
                                </span>
                            </div>
                            <div style={{ ...EssentialStyle.columnCenter, width: "100%", borderBottomLeftRadius: 8, borderBottomRightRadius: 8, border: `1px solid ${Colors.homePage.grey}`, borderTop: "unset", backgroundColor: Colors.homePage.extraLightGrey, borderCollapse: "collapse", flex: 1 }}>
                                {this.state.tendencia.map((tendencia, index) => {
                                    return (
                                        <div
                                            onClick={() => {
                                                this.filterField(`tendencia-${index}`, this.state?.contadores?.tendencia[tendencia]);
                                            }}
                                            key={"tendencia-" + index}
                                            style={{
                                                ...EssentialStyle.rowFlexCenter, borderBottom: index != (this.state.tendencia.length - 1) ? `1px solid ${Colors.homePage.grey}` : "unset", zIndex: this.isFiltered(`tendencia-${index}`) ? 2 : 1,
                                                width: "100%", flex: 1, textAlign: 'center', 
                                                cursor: this.getCursor(this.state?.contadores?.tendencia[tendencia]), 
                                                backgroundColor: this.isFiltered(`tendencia-${index}`) ? Colors.homePage.lightGrey : Colors.homePage.extraLightGrey,
                                                outline: this.isFiltered(`tendencia-${index}`) ? `2px solid ${Colors.dark}` : "unset", borderBottomLeftRadius: index == (this.state.tendencia.length - 1) ? 8 : 0, borderBottomRightRadius: index == (this.state.tendencia.length - 1) ? 8 : 0,
                                            }}
                                        >
                                            <span style={{ alignContent: "center", fontSize: 16, fontWeight: 500, color: Colors.homePage.darkGrey, height: "100%", width: 45, borderRight: `1px solid ${Colors.homePage.grey}` }}>
                                                {this.renderContador(this.state?.contadores?.tendencia[tendencia]?.length)}
                                            </span>
                                            <span style={{ alignContent: "center", fontSize: 14, fontWeight: 500, color: Colors.homePage.grey, flex: 1, height: "100%", }}>
                                                {tendencia}
                                            </span>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                        <div style={{ ...EssentialStyle.columnCenter, height: 200, width: 350 }}>
                            <div style={{ ...EssentialStyle.columnCenter, border: `1px solid ${Colors.homePage.grey}`, borderTopLeftRadius: 8, borderTopRightRadius: 8, backgroundColor: Colors.homePage.extraLightGrey, width: "100%", gap: 2, padding: 5 }}>
                                <span style={{ fontSize: 25, fontWeight: 500, width: "100%", textAlign: "center", lineHeight: "100%", color: Colors.homePage.darkGrey }}>
                                    Eficácia
                                </span>
                                <span style={{ fontSize: 15, fontWeight: 500, width: "100%", textAlign: "center", lineHeight: "100%", color: Colors.homePage.grey }}>
                                    Planos Concluídos e com Eficácia Verificada
                                </span>
                            </div>
                            <div style={{ ...EssentialStyle.columnCenter, width: "100%", borderBottomLeftRadius: 8, borderBottomRightRadius: 8, border: `1px solid ${Colors.homePage.grey}`, borderTop: "unset", backgroundColor: Colors.homePage.extraLightGrey, borderCollapse: "collapse", flex: 1 }}>
                                {this.state.eficacia.map((eficacia, index) => {
                                    return (
                                        <div
                                            onClick={() => {
                                                this.filterField(`eficacia-${index}`, this.state?.contadores?.eficacia[eficacia]);
                                            }}
                                            key={"eficacia-" + index}
                                            style={{
                                                ...EssentialStyle.rowFlexCenter, borderBottom: index != (this.state.eficacia.length - 1) ? `1px solid ${Colors.homePage.grey}` : "unset", zIndex: this.isFiltered(`eficacia-${index}`) ? 2 : 1,
                                                width: "100%", flex: 1, textAlign: 'center', 
                                                cursor: this.getCursor(this.state?.contadores?.eficacia[eficacia]), 
                                                backgroundColor: this.isFiltered(`eficacia-${index}`) ? Colors.homePage.lightGrey : Colors.homePage.extraLightGrey,
                                                outline: this.isFiltered(`eficacia-${index}`) ? `2px solid ${Colors.dark}` : "unset", borderBottomLeftRadius: index == (this.state.eficacia.length - 1) ? 8 : 0, borderBottomRightRadius: index == (this.state.eficacia.length - 1) ? 8 : 0,
                                            }}
                                        >
                                            <span style={{ alignContent: "center", fontSize: 16, fontWeight: 500, color: Colors.homePage.darkGrey, height: "100%", width: 45, borderRight: `1px solid ${Colors.homePage.grey}` }}>
                                                {this.renderContador(this.state?.contadores?.eficacia[eficacia]?.length)}
                                            </span>
                                            <span style={{ alignContent: "center", fontSize: 14, fontWeight: 500, color: Colors.homePage.grey, flex: 1, height: "100%", }}>
                                                {eficacia}
                                            </span>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        );
    }

    renderDetails = () => {
        return (
            <div className="pa-details-component" style={{ ...EssentialStyle.columnCenter, width: '100%', backgroundColor: SessionHelper.getBackgroundColor(), paddingTop: "3px", position: 'sticky', left: 0 }}>
                <div style={{ ...EssentialStyle.columnCenter, minHeight: 35, boxShadow: `0px 1px 10px 0px  rgba(50, 50, 50, 0.1)`, borderRadius: 8, backgroundColor: Colors.white, width: '100%', overflow: "hidden" }}>

                    <div style={{ ...EssentialStyle.rowFlexCenter, cursor: 'pointer', minHeight: 35, gap: 8 }} onClick={() => this.setState({ expanded: !this.state.expanded })} >
                        {this.state.expanded ?
                            <FontAwesomeIcon icon={faChevronUp} /> :
                            <FontAwesomeIcon icon={faChevronDown} />
                        }
                        {
                            this.state.expanded ? 'Ocultar Detalhamento' : 'Detalhamento'
                        }
                        {
                            this.state.expanded && this.state.loading ? <DefaultLoader size={18} color={Colors.dark} /> : null
                        }
                    </div>

                    <Collapse in={this.state.expanded} style={{ width: '100%', transition: "all 0.2s ease" }}>
                        <div style={{ ...(this.props.isSmallScreen ? EssentialStyle.columnCenter : EssentialStyle.rowFlexCenter), width: '100%' }}>
                            <div style={{ ...(this.props.isSmallScreen ? EssentialStyle.columnCenter : EssentialStyle.rowFlexCenter), width: '100%', padding: 10, paddingTop: 0, gap: 15 }}>
                                {this.renderCounters()}
                            </div>
                        </div>
                    </Collapse>
                </div>
            </div>
        )
    }

    render() {
        return (
            this.renderDetails()
        )
    }
}