import React, { Component } from 'react';
import { Container, Nav } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronUp, faCheck } from '@fortawesome/free-solid-svg-icons';
import Colors from '../../../../../constants/Colors';


class SidebarAvaliacaoNode extends Component {
    constructor(props) {
        super(props);
    }

    changeOpen = () => {
        if(this.props.selectedNode == this.props.node.id) {
            this.props.setSelectedNode(null);
        } else if(this.props.openNodes.includes(this.props.node?.id) && this.props.camada < this.props.maxCamadas) {
            this.props.setSelectedNode(null);
        } else {
            this.props.setSelectedNode(this.props.node.id);
        }

        this.props.setNodeState(this.props.node?.id, !this.props.openNodes.includes(this.props.node?.id));
    }

    calculateHeightRecursively = (node, camada = 0, first = true) => {
        var height = first ? 12 : 50;
        if(node?.childNodes?.length > 0 && this.props.openNodes.includes(node?.id)) {
            if(camada < this.props.maxCamadas) {
                node?.childNodes?.map((child, index) => {
                    if(index < node?.childNodes?.length - 1 || !first) {
                        height += this.calculateHeightRecursively(child, camada + 1, false);
                    }
                });
            }
        }

        return height;
    }

    render() {
        const isOpen = this.props.openNodes.includes(this.props.node?.id);

        var highlighted = false;
        var isChildOpen = false;

        if(this.props.camada == this.props.maxCamadas){
            isChildOpen = this.props.node?.childNodes?.filter(child => this.props.selectedNode == child.id).length > 0 ? true : false;
        }

        if(isOpen && (this.props.camada < this.props.maxCamadas)) {
            highlighted = true;
        } else if( this.props.camada == this.props.maxCamadas) {
            highlighted = this.props.selectedNode == this.props.node.id;
        }

        if(isChildOpen) {
            highlighted = true;
        }

        var height = 12;
        var totalHeight = this.calculateHeightRecursively(this.props.node, this.props.camada);

        return (
            <Container fluid style={{padding: 0, margin: 0}}>
                <Nav.Item 
                    style={{
                        padding: '10px', fontSize: `${16 - (this.props.camada*1)}px`, height: '45px', marginBottom: '5px',
                        fontWeight: '500', cursor: 'pointer', color: this.props.writingColor, borderRadius: 10, lineHeight: '1.1',
                        display: 'flex', placeItems: 'center',
                        backgroundColor: highlighted ? (this.props.writingColor == 'white' ? 'rgba(255,255,255,.1)' : 'rgba(0,0,0,.1)') : 'unset', 
                        outline: (this.props.selectedNode == this.props.node.id) ? (this.props.writingColor == 'white' ? '2px solid rgba(255,255,255,0.8)' : '2px solid rgba(0,0,0,1.2)' ) : 'unset',
                    }} 
                    onClick={() => {this.changeOpen()}}>
                        <div style={{width: '100%', display: 'flex', justifyContent: 'space-between'}}>
                            <span style={{textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', flex: 1, alignContent: 'center', filter: highlighted ? 'unset' : this.props.filter,}}>
                            {
                                this.props.node?.descricao && this.props.node?.coluna ? 
                                    `${this.props.node?.coluna}: ${this.props.node?.descricao}` 
                                :
                                    ""
                            }
                            </span>
                        {
                            this.props.node?.qtdPerguntas && this.props.node?.qtdPerguntas > 0 &&
                                <span 
                                    style={{
                                        display: 'flex', fontSize: '18px', color: Colors.white, 
                                        width: 25, height: 25, placeContent: 'center', 
                                        backgroundColor: this.props.node?.qtdPerguntas - this.props.node?.qtdPerguntasRespondidas > 0 ? Colors.danger : Colors.success, 
                                        borderRadius: '8px', alignItems: 'center'
                                    }} 
                                >
                                    {this.props.node?.qtdPerguntas - this.props.node?.qtdPerguntasRespondidas > 0 ? this.props.node?.qtdPerguntas - this.props.node?.qtdPerguntasRespondidas : <FontAwesomeIcon icon={faCheck} />}
                                </span>
                        }
                        {
                            this.props.camada < this.props.maxCamadas && this.props.node?.childNodes?.length > 0 &&
                                <span style={{width: 25, height: 25, placeContent: 'center', display: 'flex', alignItems: 'center'}}>
                                    <FontAwesomeIcon icon={isOpen ? faChevronUp : faChevronRight} style={{fontSize: '18px'}} />
                                </span>
                        }
                        </div>
                </Nav.Item>
                {
                    (this.props.camada < this.props.maxCamadas) ? 
                        <Container style={{padding: 0, paddingLeft: 15, display: isOpen ? 'block' : 'none', position: 'relative'}}>
                            <span 
                                style={{
                                    position: 'absolute', top: 0, 
                                    minHeight: totalHeight, 
                                    backgroundColor: this.props.writingColor, borderRadius: '0px 10px 10px 0px', width: 1
                                }}
                            >
                                {
                                    this.props.node?.childNodes?.map((node, index) => {
                                        var output = (
                                            <span style={{position: 'absolute', top: height, left: 0, width: 8, height: 10, borderBottomLeftRadius: '15px', borderLeft: `1px solid ${this.props.writingColor}`, borderBottom: `1px solid ${this.props.writingColor}`}}></span>
                                        )
                                        height += this.calculateHeightRecursively(node, this.props.camada + 1, false);
                                        return output;
                                    })
                                }
                            </span>
                            {
                                this.props.node?.childNodes?.map((node, index) => (
                                    <SidebarAvaliacaoNode 
                                        key={index} 
                                        node={node} 
                                        camada={this.props.camada + 1} 
                                        writingColor={this.props.writingColor} 
                                        filter={this.props.filter}
                                        maxCamadas={this.props.maxCamadas}
                                        selectedNode={this.props.selectedNode}
                                        setSelectedNode={this.props.setSelectedNode}
                                        setNodeState={this.props.setNodeState}
                                        openNodes={this.props.openNodes}
                                    />
                                ))
                            }
                        </Container>
                    : null
                }
            </Container>
        );
    }
}

export default SidebarAvaliacaoNode;