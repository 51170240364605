import React from 'react';
import Colors from '../../../../../../constants/Colors';
import { faLink } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Sig from '../../../../../../api/Sig';
import EllipsisText from '../../../../../tools/EllipsisText';
import ResizableTimelineElement from '../../../../../tools/ResizableTimelineElement/ResizableTimelineElement';
import 'moment/locale/pt-br'
import moment from 'moment';
import DataHelper from '../../../../../../helper/DataHelper';
import CustomTooltip from '../../../../../tools/CustomTooltip';
import UserAvatar from '../../../../../tools/UserAvatar';
import { Collapse } from 'react-bootstrap';
import EssentialStyle from '../../../../../../style/EssentialStyle';
import PlanoAcaoHelper from '../../../../../../helper/planoAcao/PlanoAcaoHelper';

export default class PlanoAcaoRelatorioTimelineLinha extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            edit: false,
            expanded: this.props.expanded || false
        };
        this.scrollRef = React.createRef();
    }

    renderHasDependencias(etapa) {
        if(!etapa.has_dependencias) return null;

        return (
            <CustomTooltip tooltip={'Esta etapa possui dependências'} placement="right">
                <FontAwesomeIcon icon={faLink} className={'icon dependencias'} />
            </CustomTooltip>
        );
    }

    getBackgroundColorEtapa(index) {
        if(this.props.type == 'etapas') {
            return index % 2 == 0 ? Colors.white : Colors.tag;
        }
        return index % 2 == 0 ? '#F0F0F0' : Colors.homePage.extraLightGrey;
    }

    renderLineEtapa(etapa, totalDuration, quantDias, offsetLeft, index) {
        const etapaStart = moment(etapa.data_inicio).startOf('day').toDate().getTime() - this.props.timelineStart;
        const etapaDuration = moment(etapa.data_fim).endOf('day').toDate().getTime() - moment(etapa.data_inicio).startOf('day').toDate().getTime();
        const etapaPosition = Math.round((etapaStart / totalDuration) * quantDias) * this.props.daySize;
        const etapaLength = Math.round((etapaDuration / totalDuration) * quantDias) * this.props.daySize;
        
        return (
            <div key={index} 
                style={{ 
                    display: "flex", 
                    flexDirection: "row",
                    position: 'relative',
                    marginTop: 0,
                    marginBottom: 0 
                }} 
            >
                <div style={{ height: "40px", left: 0, zIndex: 4, width: offsetLeft, display: "flex", justifyContent: "center", padding: "0px 5px", alignItems: "center", borderRight: `1px solid ${Colors.homePage.grey}`, position: "sticky", backgroundColor: this.getBackgroundColorEtapa(index), paddingLeft: this.props.type == 'planos' ? 15 : 0 }}
                >
                    <div style={{display: 'flex', height: "100%", width: "40px", alignItems: 'center', justifyContent: 'center'}}>
                        <UserAvatar user={etapa.colaborador} size={35}/>
                    </div>
                    <div style={{height: "100%", width: "calc(100% - 40px)", fontWeight: 500}}>
                        <EllipsisText text={DataHelper.removeHtmlAndReplaceListItems(etapa.atividade)} />
                    </div>
                    { this.renderHasDependencias(etapa) }
                </div>
                <div style={{ left: offsetLeft, width: `${this.props.daySize * quantDias}px`, paddingTop: '5px', paddingBottom: '5px', display: 'flex', flexDirection: 'column', backgroundColor: this.getBackgroundColorEtapa(index)}}>
                    <ResizableTimelineElement
                        element={{...etapa}}
                        elementPosition={etapaPosition}
                        elementLength={etapaLength}
                        width={this.props.daySize * quantDias}
                        changeElementCallback={() => {}}
                        timelineStart={this.props.timelineStart}
                        timelineEnd={this.props.timelineEnd}
                        edit={this.state.edit}
                        setEditId={() => {window.parent.postMessage({ type: '_blank', url: `/planoAcao/detalhar/${etapa.codigoPlanoAcao}?etapa=${etapa.id}` }, "*");}}
                        isSmallScreen={this.props.isSmallScreen}
                        type={'etapa'}
                        selectOnClick={true}
                        daySize={this.props.daySize}
                    />
                </div>
            </div>
        );
    }

    renderLinePlano(plano, totalDuration, quantDias, offsetLeft, index){

        const planoStart = moment(plano.data_inicio).startOf('day').toDate().getTime() - this.props.timelineStart;
        const planoDuration = moment(plano.data_fim).endOf('day').toDate().getTime() - moment(plano.data_inicio).startOf('day').toDate().getTime();
        const planoPosition = Math.round((planoStart / totalDuration) * quantDias) * this.props.daySize;
        const planoLength = Math.round((planoDuration / totalDuration) * quantDias) * this.props.daySize;
        const nomeEmpresaLength = (plano?.nome_empresa?.length || 0) * 6.2;

        return (
            <>
                <div key={plano.id} 
                    style={{ 
                        display: "flex", 
                        flexDirection: "row",
                        position: 'relative',
                        marginTop: 0,
                        marginBottom: 0 
                    }} 
                >
                    <div style={{ height: "40px", left: 0, zIndex: 4, width: offsetLeft, display: "flex", justifyContent: "center", padding: "0px 5px", alignItems: "center", borderRight: `1px solid ${Colors.homePage.grey}`, position: "sticky", backgroundColor: `${index % 2 == 0 ? Colors.white : Colors.tag}` , cursor: plano.etapas && plano.etapas.length > 0 ? "pointer" : "default" }}
                        onClick={() => this.setState({expanded: !this.state.expanded})}
                    >
                        <div style={{display: 'flex', height: "100%", width: "40px", alignItems: 'center', justifyContent: 'center'}}>
                            <UserAvatar user={plano.responsavel} size={35}/>
                        </div>
                        <div style={{height: "100%", width: `calc(100% - ${this.props.parceiro ? 40 + (nomeEmpresaLength) : 40}px)`, fontWeight: 500 }}>
                            <EllipsisText text={plano.nome} />
                        </div>
                        {this.props.parceiro ? 
                            <div style={{fontWeight: 500, fontSize: 11, marginTop: 2, fontWeight: 200, width: nomeEmpresaLength }}>
                                <EllipsisText text={plano.nome_empresa} />
                            </div>
                        : null}
                    </div>
                    <div style={{ left: offsetLeft, width: `${this.props.daySize * quantDias}px`, paddingTop: '5px', paddingBottom: '5px', display: 'flex', flexDirection: 'column', backgroundColor: `${index % 2 == 0 ? Colors.white : Colors.tag}`}}>
                        <ResizableTimelineElement
                            element={{...plano}}
                            elementPosition={planoPosition}
                            elementLength={planoLength}
                            width={this.props.daySize * quantDias}
                            timelineStart={this.props.timelineStart}
                            timelineEnd={this.props.timelineEnd}
                            isSmallScreen={this.props.isSmallScreen}
                            setEditId={() => {window.parent.postMessage({ type: '_blank', url: `/planoAcao/detalhar/${plano.codigo}` }, "*");}}
                            type={'plano'}
                            selectOnClick={true}
                            daySize={this.props.daySize}
                            />
                    </div>
                </div>
                <Collapse in={this.state.expanded} style={{width: '100%', opacity: this.state.expanded ? 100 : 0, transition: 'all 0.5s ease'}}>
                    <div>
                        {plano?.etapas?.length > 0 && this.renderEtapas(plano, totalDuration, quantDias, offsetLeft)}
                    </div>
                </Collapse>
            </>
        )
                
    }

    renderEtapas(plano, totalDuration, quantDias, offsetLeft) {
        let filteredEtapas = PlanoAcaoHelper.filterSelectedEtapas(plano?.etapas, this.props.filter);

        return filteredEtapas.map((etapa, index) => {
            return this.renderLineEtapa(etapa, totalDuration, quantDias, offsetLeft, index);
        });
    }

    renderLine() {
        var { timelineStart, timelineEnd } = this.props;

        var offsetLeft = 400;
        if(this.props.isSmallScreen) 
            offsetLeft = 250;

        var totalDuration = timelineEnd - timelineStart;
        var quantDias = Math.round(moment(new Date(timelineEnd)).diff(moment(new Date(timelineStart)), 'days', true));

        if(!this.props.element?.data_inicio || !this.props.element?.data_fim || this.props.element?.data_inicio == 0 || this.props.element?.data_fim == 0) {
            return 
        }

        if(this.props.type == 'planos' && this.props.element) {
            return this.renderLinePlano(this.props.element, totalDuration, quantDias, offsetLeft, this.props.index);
        }
        if(this.props.type == 'etapas' && this.props.element) {
            return this.renderLineEtapa(this.props.element, totalDuration, quantDias, offsetLeft, this.props.index);
        }

        return null;
    }

    render() {
        return this.renderLine();
    }
}