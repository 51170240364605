import React from "react";
import DefaultLoader from "./DefaultLoader";
import CustomTooltip from "./CustomTooltip";
import UserAvatar from "./UserAvatar";
import Sig from "../../api/Sig";
import DataHelper from "../../helper/DataHelper";

export default class TooManyAvatars extends React.Component {
    state = {
        loading: true,
        participants: []
    }

    async componentDidMount() {
        await this.loadUsers();
    }

    async componentDidUpdate(prevProps) {
        if (prevProps.arrayParticipants !== this.props.arrayParticipants) {
            await this.loadUsers();
        }
    }

    loadUsers = async () => {
        this.setState({ loading: true });
        let arrayParticipants = [];
        if(this.props.arrayParticipants) {
            
            if(this.props.arrayParticipants.length > 0 && (this.props.arrayParticipants[0]?.label || this.props.arrayParticipants[0]?.nome)) {
                
                arrayParticipants = this.props.arrayParticipants;                

            } else {
                //SE VIER APENAS UM ARRAY DE IDS DE COLABORADORES
                let listIds = [];

                if (this.props.arrayParticipants.length > 0 && this.props.arrayParticipants[0].id) {
                    for(let i = 0; i < this.props.arrayParticipants.length; i++) {
                        listIds.push(this.props.arrayParticipants[i].id);  
                    }
                } else {
                    listIds = this.props.arrayParticipants;
                }
                
                let colabsWithNames = await Sig.request('GET', 'config/colaborador/getColaboradoresListId', { ids: listIds }) || [];                            
                arrayParticipants = colabsWithNames;
            }

            this.setState({ participants: DataHelper.sortObjectsByName(arrayParticipants)}, () => {
                this.setState({ loading: false });
            });
        }
    }

    onlyNamesArray(participants) {
        participants = participants.slice(this.props.participantsVisiveis, this.props.participantsTotal);
        let names = [];

        participants.map((participant) => {
            names.push(participant.nome ? participant.nome : participant.label);
        })
        return names;
    }

    renderLoadingPlaceholder() {
        let size = this.props.size ? this.props.size : 38;
        let fontSize = this.props.font ? this.props.font : 15;

        return (
            <div style={{ height: size, width: size, border: '2px solid white', borderRadius: '100%', backgroundColor: 'grey', display: 'flex', alignItems: 'center', justifyContent: 'center', color: 'white', fontWeight: 'bold', fontSize: fontSize }}>
                <DefaultLoader size={fontSize} color={'white'} />
            </div>
        )
    }
    
    renderLoading() {
        return (
            this.props.arrayParticipants.length ? (
                <div style={{ display: 'flex', flexDirection: 'row', marginLeft: 5 }}>
                    {Array.from({ length: this.props.participantsVisiveis }).map((participant, key) => {
                        return (
                            <div key={key} style={{ marginLeft: -10 }}>
                                {this.renderLoadingPlaceholder()}
                            </div>
                        )
                    })}
                </div>
            ) : null
        )
    }
    
    render() {

        if (this.state.loading) return this.renderLoading();

        let nomes = this.onlyNamesArray(this.state.participants);
        let size = this.props.size ? this.props.size : 38;
        let fontSize = this.props.fontSize ? this.props.fontSize : 12;

        return (
            <div style={{ display: 'flex', flexDirection: 'row', marginLeft: 5 }}>
                {this.state.participants.slice(0, this.props.participantsVisiveis).map((participant, index) => {
                    return (
                        <div style={{ marginLeft: -10 }} key={participant.id ? participant.id : participant.value ? participant.value : "user-"+index}>
                            {!participant.id && !participant.value && participant.nome && participant.nome != "" || (this.props.onlyMail && participant.email) ?
                                <UserAvatar user={participant} size={size} onlyMail={this.props.onlyMail}/>
                                
                            :
                                <UserAvatar id={participant.id ? participant.id : participant.value} size={size}/>
                            } 
                        </div>
                    )
                })}
        
                {this.state.participants.length > this.props.participantsVisiveis && (
                    <CustomTooltip key="tooltip" placement={'bottom'} tooltip={<span>{nomes.map((element, index) => { return (<div key={index}>{element}</div>) })}</span>}>
                        <div
                            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontWeight: 'bold', backgroundColor: 'grey', marginLeft: -10, fontSize: fontSize, color: 'white', width: size, height: size, border: '2px solid white', borderRadius: '50%' }}>
                            +{this.props.participantsTotal - this.props.participantsVisiveis}
                        </div>
                    </CustomTooltip>
                )}
            </div>
        )
    }
}