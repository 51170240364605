import React from "react";
import Sig from "../../../../../api/Sig";
import IdiomaHelper from "../../../../../helper/IdiomaHelper";

import './IndicadoresConfig.css';
import Colors from "../../../../../constants/Colors";
import DiagnosticoHelper from "../../../../../helper/diagnostico/DiagnosticoHelper";
import DefaultLoader from "../../../../tools/DefaultLoader";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash, faPaperPlane, faPlusSquare, faLayerGroup } from '@fortawesome/free-solid-svg-icons';
import Select from 'react-select'
import DataHelper from "../../../../../helper/DataHelper";
import DefaultButton from "../../../../tools/DefaultButton";
import EssentialStyle from "../../../../../style/EssentialStyle";
import ModalPeriodoGrupoIndicadores from "../ModalPeriodoGrupoIndicadores/ModalPeriodoGrupoIndicadores";
import IndicadorReferenciaCard from "../IndicadorReferenciaCard/IndicadorReferenciaCard";
import Constants from "../../../../../constants/Api";
const gateway = Constants.getSigEndPoint();

export default class IndicadoresConfig extends React.Component {

    state = {
        loading: true,
        useIndicadores: true,
        saving: false,

        idPeriodoAvaliacao: this.props.idPeriodoAvaliacao || null,

        oculto: true,
        listPeriodoIndicadores: [],
        listPeriodoGruposIndicadores: [],
        empresas: [],

        addingGrupo: false,

        indicadores: [],
        indicador: null,

        canEdit: false,
        canView: false,
    }

    componentDidMount() {
        if (this.state.idPeriodoAvaliacao) {
            this.loadPeriodoAvaliacao();

        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.idPeriodoAvaliacao !== prevProps.idPeriodoAvaliacao) {
            this.setState({ idPeriodoAvaliacao: this.props.idPeriodoAvaliacao }, () => {
                this.loadPeriodoAvaliacao();
            });
        }
    }

    async loadPeriodoAvaliacao () {

        this.setState({ loading: true });

        let periodoAvaliacao = await DiagnosticoHelper.getPeriodoAvaliacaoIndicadores(this.state.idPeriodoAvaliacao);
        let indicadores = await Sig.request('POST', 'helper/getIndicadores', { raw_object: true }) || [];

        this.setState({ 
            indicadores,
            useIndicadores: periodoAvaliacao.tem_indicadores,
            listPeriodoIndicadores: periodoAvaliacao.indicadores,
            listPeriodoGruposIndicadores: periodoAvaliacao.grupos,
            empresas: periodoAvaliacao.empresas,
            canEdit: periodoAvaliacao.canEdit,
            canView: periodoAvaliacao.canView,
            loading: false,
         });
    }

    async loadPeriodoIndicadoresAndGrupo() {

        let periodoAvaliacao = await DiagnosticoHelper.getPeriodoAvaliacaoIndicadores(this.state.idPeriodoAvaliacao);

        this.setState({ 
            listPeriodoIndicadores: periodoAvaliacao.indicadores,
            listPeriodoGruposIndicadores: periodoAvaliacao.grupos,
         });
    }

    async savePeriodoIndicador(select) {

        this.setState({ saving: true });
        let idPeriodoAvaliacao = this.state.idPeriodoAvaliacao;

        let periodoAvaliacao = await DiagnosticoHelper.saveIndicadorPeriodo(idPeriodoAvaliacao, this.state.indicador.value);

        this.setState({ 
            listPeriodoIndicadores: periodoAvaliacao.indicadores,
            empresas: periodoAvaliacao.empresas,
            canEdit: periodoAvaliacao.canEdit,
            canView: periodoAvaliacao.canView,
            indicador: null,
            saving: false,
        });
    }

    async addIndicadorGrupo(grupo, draggingItem) {
        
        let idPeriodoAvaliacao = this.state.idPeriodoAvaliacao;

        let periodoAvaliacao = await DiagnosticoHelper.saveIndicadorPeriodoGrupo(idPeriodoAvaliacao, grupo.id, draggingItem);

        this.setState({ 
            listPeriodoIndicadores: periodoAvaliacao.indicadores,
            listPeriodoGruposIndicadores: periodoAvaliacao.grupos,
        });
    }

    message() {
        const message = IdiomaHelper.getStruct({
            'pt_br': { 
                labelAdd: 'Selecione o Indicador',
                labelIndicador: 'Indicadores Adicionados',
                labelIndicadorOculto: 'Indicadores Desagrupados',
                labelGrupo: 'Grupos de Indicadores',
                labelCountIndicadores: 'Indicadores',
                labelCountIndicador: 'Indicador',
                placeholder_indicador: 'Selecione o Indicador...',
                tooltip_addIndicador: 'Adicionar Indicador',
                add_grupo: 'Adicionar Grupo de Indicadores',
                notUseIndicadores: 'Para utilizar indicadores neste Período de avaliação, ative a opção "Habilitar Indicadores" na edição do período de avaliação.',
            },
            'en': { 
                labelAdd: 'Select the Indicator',
                labelIndicador: 'Added Indicators',
                labelIndicadorOculto: 'Ungrouped Indicators',
                labelGrupo: 'Indicator Groups',
                labelCountIndicadores: 'Indicators',
                labelCountIndicador: 'Indicator',
                placeholder_indicador: 'Select the Indicator...',
                tooltip_addIndicador: 'Add Indicator',
                add_grupo: 'Add Group of Indicators',
                notUseIndicadores: 'To use indicators in this evaluation period, enable the "Enable Indicators" option in the evaluation period edition.',
            },
            'es': { 
                labelAdd: 'Seleccione el Indicador',
                labelIndicador: 'Indicadores Añadidos',
                labelIndicadorOculto: 'Indicadores Desagrupados',
                labelGrupo: 'Grupos de Indicadores',
                labelCountIndicadores: 'Indicadores',
                labelCountIndicador: 'Indicador',
                placeholder_indicador: 'Seleccione el Indicador...',
                tooltip_addIndicador: 'Agregar Indicador',
                add_grupo: 'Agregar Grupo de Indicadores',
                notUseIndicadores: 'Para utilizar indicadores en este período de evaluación, habilite la opción "Habilitar Indicadores" en la edición del período de evaluación.',
            },
        });

        return message[this.props.lang];
    }

    renderSelectIndicadores() {

        let options = this.state.indicadores?.length > 0 ? DataHelper.formatSelectData(this.state.indicadores, 'id', 'nome') : [];

        options = options.filter(option => {
            return !this.state.listPeriodoIndicadores.find(indicador => indicador.indicador.id === option.value);
        });


        if (options.length === 0) return null;
        return(

            <div className="addIndicador">
                <div className="titleIndicadores">
                    <span className="title">{this.message().labelAdd}</span>
                    <div className="title-line"></div>
                </div>

                <div style={{...EssentialStyle.rowSpaceBetween, gap: 10}}>
                    <Select
                        className="select-indicador"
                        options={options}
                        value={this.state.indicador}
                        onChange={(indicador) => {  this.setState({ indicador }) }}
                        placeholder={this.message(this.state.lang).placeholder_indicador}
                        styles={{
                            container: (provided) => ({
                                ...provided,
                                width: 'calc(100% - 48px)',
                                maxWidth: 'calc(100% - 48px)',
                            })
                        }}
                    />

                    <DefaultButton
                        leftIcon={<FontAwesomeIcon icon={faPaperPlane}/>}
                        color={Colors.success}
                        tooltip={this.message(this.state.lang).tooltip_addIndicador}
                        disabled={this.state.indicador == null}
                        onClick={() => { this.savePeriodoIndicador(); }}
                    />
                </div>
            </div>
        );
    }

    renderListIndicadores() {

        const onDragStartItem = async (e, item) => {
            this.setState({ draggingItem: item });
            setTimeout(() => { e.target.style.display = "none" }, 5);
        }
    
        const onDragEndItem = async (e, item) => {
            setTimeout(() => { e.target.style.display = "flex" }, 1000);
            this.setState({ draggingItem: null });
        }

        return (
            <div className="listIndicadores">
                <div className="titleIndicadores">
                    <span className="title">{this.state.oculto ? this.message().labelIndicadorOculto : this.message().labelIndicador}</span>
                    <div className="title-line"></div>
                    <FontAwesomeIcon
                        style={{ cursor: 'pointer', marginLeft: 10 }}
                        icon={this.state.oculto ? faEyeSlash : faEye} 
                        onClick={() => { this.setState({ oculto: !this.state.oculto }) }}
                    />                
                </div>

                {this.state.listPeriodoIndicadores.map(indicadorPainel => {
                    if ( !indicadorPainel.hasGrupo || !this.state.oculto ) return (
                        <IndicadorReferenciaCard
                            key={`indicadorpainel-${indicadorPainel.indicador.id}`}
                            periodoIndicadorReferencia={indicadorPainel}
                            afterDeletePeriodoIndicador={() => this.loadPeriodoIndicadoresAndGrupo()}
                            afterUnlinkIndicador={() => this.loadPeriodoIndicadoresAndGrupo()}
                            onDragStartItem={(e, item) => { onDragStartItem(e, item) }}
                            onDragEndItem={(e, item) => { onDragEndItem(e, item) }}
                            draggable={indicadorPainel.hasGrupo ? false : true}
                            lang={this.props.lang}
                            showGroupName={true}
                        />
                    )
                })}
            </div>
        )
    }

    renderGrupos() {

        const addNewGrupo = () => {
            return (
                <div 
                    className="groupIndicadores new"
                    onClick={() => {
                        this.setState({ addingGrupo: true });
                    }}
                >
                    <FontAwesomeIcon icon={faPlusSquare} className="iconAdd" />
                    <span className="title">{this.message().add_grupo}</span>

                    <FontAwesomeIcon className={"iconPosition"} icon={faLayerGroup}/>   
                </div>
            );
        }

        const grupoView = (grupo) => {
            return (
                <div 
                    key={grupo.id}
                    className="groupIndicadores"
                    onClick={() => {
                        this.setState({ addingGrupo: grupo });
                    }}
                    onDragEnter={(e) => {
                        e.preventDefault();
                        if (!e.currentTarget.contains(e.relatedTarget)) {
                            e.currentTarget.classList.add('drag-enter');
                        }
                    }}
                    onDragOver={(e) => {
                        e.preventDefault();
                    }}
                    onDrop={(e) => {
                        e.preventDefault();
                        if (!e.currentTarget.contains(e.relatedTarget)) {
                            e.currentTarget.classList.remove('drag-enter');
                            this.addIndicadorGrupo(grupo, this.state.draggingItem);
                        }
                    }}
                    onDragLeave={(e) => {
                        if (!e.currentTarget.contains(e.relatedTarget)) {
                            e.currentTarget.classList.remove('drag-enter');
                        }
                    }}
                >
                    <div className="count">
                        <div className="number">{grupo?.indicadores?.length || 0}</div>
                        <div>{grupo?.indicadores?.length > 1 ? this.message().labelCountIndicadores : this.message().labelCountIndicador}</div>
                    </div>

                    <div className="some">
                        <div className="number">+1</div>
                        <div>{this.message().labelCountIndicador}</div>
                    </div>

                    <div className="line"/>

                    <span className="title">
                        { grupo?.textos?.find(texto => texto.id_idioma === IdiomaHelper.getSiglaId(this.props.lang))?.descricao || this.message().labelGrupo }
                    </span>     

                    <FontAwesomeIcon className={"iconPosition"} icon={faLayerGroup}/>     
                </div>
            );
        }

        return(
            <div className="GroupIndicadores">
                <div className="titleIndicadores">
                    <span className="title">{this.message().labelGrupo}</span>
                    <div className="title-line"></div>
                </div>
                <div className="listGroupIndicadores">
                    {this.state.listPeriodoGruposIndicadores.map(grupo => {
                        return ( grupoView(grupo) )
                    })}

                    {addNewGrupo()}
                </div>
            </div>
        );
    }

    renderModalConfigPeriodoGrupo() {
        
        if (this.state.addingGrupo) {

            let grupo = this.state.addingGrupo;

            return(
                <ModalPeriodoGrupoIndicadores
                    grupo={grupo}
                    lang={this.props.lang}
                    idPeriodoAvaliacao={this.state.idPeriodoAvaliacao}
                    listPeriodoIndicadores={this.state.listPeriodoIndicadores}
                    closeModal={() => this.setState({addingGrupo: false}, () => this.loadPeriodoIndicadoresAndGrupo()) }
                    reload={async () => await this.loadPeriodoIndicadoresAndGrupo()}
                />
            )
        }
    }

    renderLoading() {
        return (
            <div className="IndicadoresConfig loading">
                <DefaultLoader />
            </div>
        )
    }

    renderNotUseIndicadores() {
        return (
            <div className="IndicadoresConfig notUseIndicadores">
                <img
                    src={`${gateway}/img/undraw/undraw_safe_lock.svg`}
                    alt="Uma mulher olhando para um campo de seleção."
                    style={{
                        height: '14rem',
                        maxHeight: 250,
                        marginBottom: '1rem',
                    }}
                />
                <span style={{maxWidth: '60vw', marginLeft: 15}}>
                    {this.message().notUseIndicadores}
                </span>
            </div>
        )
    }

    render() {

        if (this.state.loading) return this.renderLoading();
        if (!this.state.useIndicadores) return this.renderNotUseIndicadores();
        return (
            <div className="IndicadoresConfig">
                {this.renderSelectIndicadores()}
                {this.renderListIndicadores()}
                {this.renderGrupos()}

                {this.renderModalConfigPeriodoGrupo()}
            </div>
        )
    }
}
