import React, {forwardRef} from "react";
import ReactDOM from "react-dom";
import Select, { components } from 'react-select';

import Sig from "../../../../../api/Sig";
import EssentialStyle from "../../../../../style/EssentialStyle";
import Colors from "../../../../../constants/Colors";
import { Form, Dropdown } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faChevronDown, faChevronUp, faClose, faEllipsis, faCheck, faMagic, faUndo, faQuestionCircle, faCheckCircle, faTimesCircle, } from '@fortawesome/free-solid-svg-icons';
import DataHelper from "../../../../../helper/DataHelper";
import moment from "moment";
import DatePicker from "react-datepicker";
import UserAvatar from "../../../../tools/UserAvatar";
import PlanoAcaoHelper from "../../../../../helper/planoAcao/PlanoAcaoHelper";
import DefaultButton from "../../../../tools/DefaultButton";
import TextEditor from "../../../../tools/TextEditor/TextEditor";
import DefaultLoader from "../../../../tools/DefaultLoader";
import CustomTooltip from "../../../../tools/CustomTooltip";
import UploadFiles from "../../../../tools/UploadFiles";
import { confirmAlert } from "react-confirm-alert";
import CustomConfirm from "../../../../tools/CustomConfirm";
import { toast } from "react-toastify";
import Tabs from "../../../../tools/Tabs";
import DatePickerHelper from "../../../../../helper/DatePickerHelper";
import AtaReuniaoTopicoTarefaNotas from "./AtaReuniaoTopicoTarefaComponents/AtaReuniaoTopicoTarefaNotas";
import AtaReuniaoTopicoTarefaHistorico from "./AtaReuniaoTopicoTarefaComponents/AtaReuniaoTopicoTarefaHistorico";


export default class AtaReuniaoTopicoTarefaModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            idTarefa: this.props.idTarefa,
            loading: true,
            tarefa: null,
            tarefaOriginal: null,
            participantes: [],
            activeTab: 'comentarios',
            reloadHistorico: false,
            canEdit: false,
            canDelete: false,
        }
        this.selectResponsavel = React.createRef();
        this.selectStatus = React.createRef();
        this.selectPrioridade = React.createRef();
        this.periodoPicker = React.createRef();
    }

    async componentDidMount() {
        this.setState({loading: true});
        await this.loadData();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isSmallScreen !== this.props.isSmallScreen) {
            this.setState({
                isSmallScreen: this.props.isSmallScreen
            });
        }
        if(prevProps.idTarefa !== this.props.idTarefa) {
            this.setState({
                loading: true,
                idTarefa: this.props.idTarefa,
            }, () => this.loadData());
        }

    }

    loadData = async (keepChanges = false, keepDates = false) => {
        if(this.state.idTarefa){
            const response = await Sig.request('GET', `ataReuniao${this.props.hash ? "Externa" : ""}/getTarefaTopico`, { 
                id: this.state.idTarefa,
                hash: this.props.hash
            });
            if(response && response.status === 200){
                this.setState({
                    tarefa: keepChanges ? keepDates ? this.state.tarefa : {...this.state.tarefa, data_inicio: response.tarefa.data_inicio, data_fim: response.tarefa.data_fim} : response.tarefa,
                    tarefaOriginal: response.tarefa,
                    canEdit: response.tarefa?.canEdit,
                    canDelete: response.tarefa?.canDelete,
                });

                this.loadParticipantes(response.tarefa.id_ata_reuniao_topico);
            } else {
                this.props.detailsModalCallback();
            }
        }
    }

    loadParticipantes = async (idTopico) => {
        const response = await Sig.request('GET', `ataReuniao${this.props.hash ? "Externa" : ""}/getParticipantesTopico`, { 
            id: idTopico,
            hash: this.props.hash 
        });

        if(response && response.status === 200){
            this.setState({
                participantes: response.participantesTopico ? [...response.participantesTopico] : [],
            }, () => { this.setState({ loading: false }) });
        } else {
            this.props.detailsModalCallback();
        }
    }

    saveTarefa = async () => {
        if(this.state.tarefa.id){
            const response = await Sig.request('POST', `ataReuniao${this.props.hash ? "Externa" : ""}/updateTarefaTopico`, {
                id: this.state.tarefa.id,
                hash: this.props.hash,
                fields: JSON.stringify({
                    data_inicio: this.state.tarefa.data_inicio,
                    data_fim: this.state.tarefa.data_fim,
                    descricao: this.state.tarefa.descricao,
                    status: this.state.tarefa.status,
                    prioridade: this.state.tarefa.prioridade,
                    responsavel: this.state.tarefa.responsavel.id,
                    anexos: this.state.tarefa.anexos,
                })
            });
            if(response && response.status === 200){
                this.setState({
                    tarefaOriginal: response.tarefa,
                    tarefa: response.tarefa,
                    canEdit: response.tarefa?.canEdit,
                    canDelete: response.tarefa?.canDelete,
                }, () => {
                    this.setState({ reloadHistorico: true });
                    this.props.updateTarefaCallback(response.tarefa.id_ata_reuniao_topico, response.tarefa);
                });
            } 
        } 
    }

    getUserFromParticipantes = (id) => {
        return this.state.participantes.find(user => user.id == id);
    }

    reloadHistorico = () => {
        if(!this.state.reloadHistorico && this.state.activeTab === 'historico') {
            this.setState({ reloadHistorico: true });
        }
    }

    uploadCallback = async (file) => {
            this.reloadHistorico();
            this.loadData(true, true) 
    }

    removeFileCallback = async (file) => {
        this.reloadHistorico();
        this.loadData(true, true)
    }

    renderAnexos = () => {
        return (
            <UploadFiles 
                uploadCallback={this.uploadCallback} 
                removeFileCallback={this.removeFileCallback} 
                id={this.state.idTarefa} 
                action={`ataReuniao${this.props.hash ? "Externa" : ""}/AnexoTarefa`} 
                limit={10}
            />
        );
    }

    renderStatusSelector() {
        let minWidths = [
            120,
            175,
            100,
            100,
        ]

        const DropdownIndicator = (props) => {
            const { selectProps } = props;
            const isMenuOpen = selectProps.menuIsOpen;

            return (
                <components.DropdownIndicator {...props} >
                    <div style={{
                        ...EssentialStyle.rowFlexStart,
                        backgroundColor: PlanoAcaoHelper.getEtapaStatus()[this.state.tarefa.status].backgroundColor,
                        color: PlanoAcaoHelper.getEtapaStatus()[this.state.tarefa.status].color,
                        fontSize: 12,
                        fontWeight: 600,
                        paddingLeft: 8,
                        paddingRight: 8,
                        paddingBottom: 2,
                        paddingTop: 2,
                        minWidth: minWidths[this.state.tarefa.status],
                        borderRadius: 4,
                        cursor: 'pointer'
                    }}>
                        {isMenuOpen ? <FontAwesomeIcon icon={faChevronUp} /> : <FontAwesomeIcon icon={faChevronDown} />}
                        <div style={{ marginRight: 8 }} />
                        {this.state.tarefa.status > -1 ? PlanoAcaoHelper.getEtapaStatus()[this.state.tarefa.status].label.toUpperCase() : 'Status'}
                    </div>
                </components.DropdownIndicator>
            );
        };

        return (
            <div
                style={{
                    ...EssentialStyle.rowFlexStart,
                    fontSize: 14
                }}
                onClick={(e) => e.stopPropagation()}
            >
                <Select
                    ref={ref => this.selectStatus = ref}
                    styles={{
                        container: (base, state) => ({
                            ...base,
                            width: 'auto',
                            minWidth: 'max-content',
                        }),
                        control: (base, state) => ({
                            ...base,
                            ...EssentialStyle.titleBoxHomePage,
                            border: 'none',
                            width: '100%',
                            minWidth: '100%',
                            boxShadow: state.isFocused ? 'none' : null,
                            backgroundColor: "transparent",
                            marginRight: 8,
                            justifyContent: "flex-start"
                        }),
                        valueContainer: (base, state) => ({
                            ...base,
                            display: 'contents'
                        }),
                        menu: (base, state) => ({
                            ...base,
                            width: '100%',
                            minWidth: '220px',
                            right: 0,
                            marginRight: 8,
                            margin: 0,
                            borderRadius: 4,
                            boxShadow: '0px 2px 2px 2px  rgba(50, 50, 50, 0.1)',
                        }),
                    }}
                    value={this.state.tarefa.status}
                    options={PlanoAcaoHelper.getEtapaStatus()}
                    formatOptionLabel={({ label, value }) => (
                        <div style={{
                            ...EssentialStyle.rowFlexStart,
                            paddingLeft: 8,
                            fontWeight: 600,
                            cursor: 'pointer',
                            color: PlanoAcaoHelper.getEtapaStatus()[value].backgroundColor,
                        }}>
                            {label}
                        </div>
                    )}
                    placeholder={''}
                    noOptionsMessage={DataHelper.getSelectEmptyMessage}
                    isSearchable={false}
                    components={{ DropdownIndicator, IndicatorSeparator: null }}
                    onChange={async (value) => {
                        this.setState({
                            tarefa: { ...this.state.tarefa, status: value.value.toString()}
                        }, async () => {
                            if (this.selectStatus) this.selectStatus.blur();
                        });
                    }}
                    isDisabled={!this.state.canEdit}
                />

            </div>
        );
    }

    renderDatePicker = (value, onChangeCallback, minDate = moment().subtract(10, 'years').toDate(), maxDate = moment().add(10, 'years').toDate()) => {
        const CustomPicker = forwardRef(({ onClick, date }, ref) => {
            return (<div style={{
                ...EssentialStyle.rowFlexCenter,
                fontSize: 14,
                color: Colors.dark,
                fontWeight: 'bold',
                cursor: 'pointer',
                backgroundColor: Colors.secondaryButton,
                padding: 1,
                height: '100%',
                width: this.state.isSmallScreen ? 110 : 120,
                textAlign: 'center',
                borderRadius: 4,
                paddingLeft: 5,
                marginRight: 8
            }}>
                <FontAwesomeIcon icon={faCalendar} />
                <Form.Control
                    onChange={() => { }}
                    onClick={onClick}
                    ref={ref => this.periodoPicker = ref}
                    style={{
                        marginLeft: 2,
                        fontSize: 14,
                        color: Colors.dark,
                        fontWeight: 'bold',
                        cursor: 'pointer',
                        boxShadow: 'none',
                        backgroundColor: Colors.secondaryButton,
                        padding: 1,
                        height: '100%',
                        textAlign: 'center',
                    }}
                    value={date} 
                    readOnly
                />
            </div>);
    });

        let date = moment().toDate();
        if (value) date = moment(value).toDate();

        return (
            <DatePicker
                wrapperClassName="w-100"
                selected={date}
                type="date"
                minDate={minDate}
                maxDate={maxDate}
                onChange={(event) => {
                    let changedDate = moment(event);
                    onChangeCallback(changedDate);
                }}
                dateFormat="dd/MM/YYYY"
                customInput={<CustomPicker date={moment(date).format('DD/MM/YYYY')}/>}
                renderCustomHeader={(props) =>  
                    DatePickerHelper.renderCustomDatePickerHeader(props, minDate, maxDate)
                }
                disabled={!this.state.canEdit}
            />
        )
    };

    renderSelectPrioridade = () => {

        return (
            <div
                style={{
                    ...EssentialStyle.rowFlexStart,
                    fontSize: 14
                }}
            >
                <Select
                    ref={ref => this.selectPrioridade = ref}
                    styles={{
                        container: (base, state) => ({
                            ...base,
                            width: '100%',
                        }),
                        control: (base, state) => ({
                            ...base,
                            ...EssentialStyle.titleBoxHomePage,
                            width: '100%',
                            backgroundColor: "transparent",
                            marginRight: 8
                        })
                    }}
                    value={DataHelper.formatSelectedData(this.state.tarefa, 'prioridade', 'prioridade_text')}
                    options={PlanoAcaoHelper.getEtapaPrioridades()}
                    formatOptionLabel={({ label, value }) => (
                        <div style={{ ...EssentialStyle.rowFlexStart, paddingLeft: 8, cursor: 'pointer' }}>
                            <div style={{ marginRight: 8 }}>{PlanoAcaoHelper.getPrioridadeIcon()[label]}</div> {label}
                        </div>
                    )}
                    placeholder={''}
                    noOptionsMessage={DataHelper.getSelectEmptyMessage}
                    isSearchable={false}
                    closeMenuOnSelect={true}
                    components={{ IndicatorSeparator: null }}
                    onChange={async (value) => {
                        this.setState({
                            tarefa: {...this.state.tarefa, prioridade: value.value.toString(), prioridade_text: value.label}
                        }, async () => {
                            if (this.selectPrioridade) this.selectPrioridade.blur();
                        });
                    }}
                    isDisabled={!this.state.canEdit}
                />

            </div>
        );
    }

    renderSelectResponsavel = () => {

        const singleValue = ({
            children,
            ...props
          }) => (
            <components.SingleValue {...props}>
                {children}
            </components.SingleValue>
          );

        return (
            <div
                style={{
                    ...EssentialStyle.rowFlexStart,
                    fontSize: 14,
                    width: '100%',
                }}
            >
                <Select
                    menuPosition={'absolute'}
                    ref={ref => this.selectResponsavel = ref}
                    styles={{
                        container: (base, state) => ({
                            ...base,
                            width: '100%',
                        }),
                        control: (base, state) => ({
                            ...base,
                            ...EssentialStyle.titleBoxHomePage,
                            width: '100%',
                            backgroundColor: "transparent",
                            marginRight: 8
                        }),
                        SingleValue: (base, state) => ({
                            ...base,
                            ...EssentialStyle.titleBoxHomePage,
                            width: '100%',
                            backgroundColor: "transparent",
                            marginRight: 8
                        })
                    }}
                    value={DataHelper.formatSelectedData(this.state.tarefa.responsavel, 'id', 'nome')}
                    options={DataHelper.formatSelectData(this.state.participantes, 'id', 'nome')}
                    formatOptionLabel={({ label, value }) => (
                        <div style={{ ...EssentialStyle.rowFlexStart, paddingLeft: 8, cursor: 'pointer' }}>
                            <UserAvatar user={this.state.participantes.find(p => p.id == value)} showNameAdaptative showName />
                        </div>
                    )}
                    placeholder={''}
                    noOptionsMessage={DataHelper.getSelectEmptyMessage}
                    isSearchable={false}
                    components={{ SingleValue: singleValue, IndicatorSeparator: null }}
                    onChange={async (value) => {
                        const novoColaborador = this.state.participantes.find(p => p.id == value.value);
                        this.setState({
                            tarefa: { ...this.state.tarefa, responsavel: { ...this.state.tarefa.responsavel, id: novoColaborador.id, nome: novoColaborador.nome, avatar: novoColaborador.avatar }
                             }
                        }, async () => {
                            if (this.selectResponsavel) this.selectResponsavel.blur();
                        });
                    }}
                    isDisabled={!this.state.canEdit}
                />
            </div>
        );
    }      

    renderTextEditor = () => {

        return(
            <div 
                style={{ backgroundColor: Colors.white, height: 'auto', minHeight: 60, width: '100%' }}
                  
            >
                { !this.state.loadingTextComo && <TextEditor 
                    defaultValue={this.state.tarefa.descricao}
                    placeholder={"Descrição da Tarefa..."}
                    onChange={(descricao) => this.setState({ tarefa: { ...this.state.tarefa, descricao } })}
                    minHeight={90}
                    disabled={!this.state.canEdit}
                />}
            </div>
        );
    }

    renderDeleteTarefaModal = () => {
        confirmAlert({
            customUI: ({ onClose }) => (
                <CustomConfirm
                    title={'Remover esta tarefa?'}
                    message={<p style={{ marginTop: 10, marginBottom: 10 }}>Esta ação é irreversível.</p>}
                    buttons={[
                        {
                            label: 'Remover',
                            color: Colors.error,
                            textColor: Colors.light,
                            onClick: async () => { 
                                var response = await Sig.request('POST', `ataReuniao${this.props.hash ? "Externa" : ""}/deleteTarefaTopico`, { 
                                    id: this.state.tarefa.id,
                                    hash: this.props.hash 
                                });
            
                                if(response.status == 200) {
                                    this.props.updateTarefaCallback(this.state.tarefa.id_ata_reuniao_topico, this.state.tarefa, true);
                                }
                                onClose();
                                this.props.detailsModalCallback();
                            }
                        },
                        {
                            label: 'Cancelar',
                            onClick: () => { onClose(); }
                        },
                    ]}
                />
            )
        });
    }

    renderOptions = () => {
        //por enquanto será feito assim, quando tiverem mais opções o dropdown sera exibido em outras situações	
        if(!this.state.canDelete) return null;

        const portalTarget = document.getElementById('root');
        return (
            <Dropdown className="optionsModalTarefa">
                <CustomTooltip style={{ height: 'auto' }} placement={"auto"} tooltip={"Opções"}>
                    <Dropdown.Toggle
                        onChange={() => {}}
                        style={{ 
                                backgroundColor: "transparent", 
                                color: Colors.dark, 
                                border: 'none',
                                zIndex: 25
                        }}
                    > 
                        <FontAwesomeIcon icon={faEllipsis} />
                    </Dropdown.Toggle>
                </CustomTooltip>
                {ReactDOM.createPortal(
                    <Dropdown.Menu style={{padding: 2, color: Colors.dark}}> 
                        <Dropdown.Item 
                            key={`delete-tarefa-btn`} 
                            onClick={ this.renderDeleteTarefaModal }
                        >
                                <span style={{ fontSize: 14 }}>Excluir Tarefa</span>
                        </Dropdown.Item>
                    </Dropdown.Menu>
                , portalTarget)}
            </Dropdown>
        );
    }

    handleTabChange = (tab) => {
        this.setState({ activeTab: tab.name });
    }

    renderComentarios = () => {
        return(
            <AtaReuniaoTopicoTarefaNotas idTarefa={this.state.idTarefa} hash={this.props.hash}/>
        )
    }

    renderHistorico = () => {
        return(
            <AtaReuniaoTopicoTarefaHistorico id={this.state.idTarefa} reload={this.state.reloadHistorico} reloadedCallback={() => this.setState({ reloadHistorico: false })}  hash={this.props.hash}/>
        )
    }
    
    render() {

        if(!this.state.tarefa) return null;

        var changed = false;

        if(this.state.tarefaOriginal && this.state.tarefa) 
        {
            changed = true;
            if( this.state.tarefa.id == this.state.tarefaOriginal.id && this.state.tarefa.data_inicio == this.state.tarefaOriginal.data_inicio && 
                this.state.tarefa.data_fim == this.state.tarefaOriginal.data_fim && this.state.tarefa.descricao == this.state.tarefaOriginal.descricao &&
                this.state.tarefa.status == this.state.tarefaOriginal.status && this.state.tarefa.prioridade == this.state.tarefaOriginal.prioridade && 
                this.state.tarefa.responsavel?.id == this.state.tarefaOriginal.responsavel?.id )
            {
                changed = false;
            }
        }

        const el = document.getElementById('root');

        const footer = () => {
            const tabs = [
                { name: 'comentarios', title: 'Comentários' },
                { name: 'historico', title: 'Histórico' },
            ];

            return (
                <div style={{ ...EssentialStyle.columnStart, height: 'auto', width: '100%', borderRadius: 8, backgroundColor: Colors.white}}>
                    <div style={{width: "auto"}}>
                        <Tabs 
                            activeTab={this.state.activeTab}
                            tabs={tabs}
                            primaryBackgroundColor={Colors.white}
                            primaryColor={Colors.dark}
                            onTabChange={this.handleTabChange}
                            isSmallScreen={this.state.isSmallScreen} 
                            style={EssentialStyle.rowFlexStart}
                            tabStyle={{ 
                                width: 'auto', 
                                outline: 'unset', 
                                borderColor: "unset", 
                                boxShadow: 'unset', 
                                borderBottomLeftRadius: 0, 
                                borderBottomRightRadius: 0, 
                                zIndex: 1, 
                                border: "unset", 
                                zIndex: 0,
                            }}
                            activeTabStyle={{ 
                                border: `1px solid ${Colors.homePage.lightGrey}`, 
                                borderBottom: "unset",
                            }}
                        />
                    </div>
                    <div style={{ ...EssentialStyle.rowFlexEnd, backgroundColor: Colors.white, borderRadius: 8, width: "100%", padding: "0px 15px", border: '1px solid ' + Colors.homePage.lightGrey, borderTopLeftRadius: 0, marginTop: "-1px" }}>
                        {this.state.activeTab === 'comentarios' ? this.renderComentarios() : this.renderHistorico()}
                    </div>
                </div>
            );
        };

        const modal = () => {

            if(this.state.loading) {
                return (
                    <div style={{ ...EssentialStyle.columnCenter, position: "fixed", right: "2vw", top: "2.5vh", width: this.state.isSmallScreen ? '95vw' : '35vw', height: '95vh', backgroundColor: Colors.white, zIndex: 20, borderRadius: 8, boxShadow: `0px 2px 5px 5px  rgba(50, 50, 50, 0.1)` }}>
                        <DefaultLoader color={Colors.dark} size={32}/>
                    </div>
                )
            }
            return (
                <div style={{ ...EssentialStyle.columnStart, position: "fixed", right: "2vw", top: "2.5vh", minWidth: this.state.isSmallScreen ? 385 : 550, width: this.state.isSmallScreen ? '95vw' : '40vw', height: '95vh', backgroundColor: Colors.white, zIndex: 20, borderRadius: 8, boxShadow: `0px 2px 5px 5px  rgba(50, 50, 50, 0.1)`, paddingBottom: 15 }}>
                    <div style={{ ...EssentialStyle.rowFlexEnd, backgroundColor: Colors.white, borderRadius: 8, width:"100%", height: 25, gap: 5, padding: "0px 15px", margin: "15px 0px" }}>
                            <div style={{ ...EssentialStyle.rowFlexStart, gap: 5, width: "auto", height: "100%", flexGrow: 1 }}>
                                {
                                    changed && this.state.canEdit ? 
                                    <DefaultButton title={'Salvar'} tooltipPlacement={"auto"} color={Colors.success} textColor={Colors.white} onClick={this.saveTarefa} />
                                    : null
                                }
                            </div>
                            <div style={{ ...EssentialStyle.rowFlexStart, gap: 5, width: "auto", height: "100%" }}>
                                {this.renderOptions()}
                                <DefaultButton title={''} tooltip={"Fechar"} tooltipPlacement={"auto"} leftIcon={<FontAwesomeIcon icon={faClose} />} color={"transparent"} textColor={Colors.dark} onClick={() => this.props.detailsModalCallback()} />
                            </div>
                    </div>
                    <div style={{ ...EssentialStyle.columnStart, width: '100%', height: 'calc(100% - 25px)', overflowY: 'auto', padding: 15, paddingTop: 0, paddingRight: this.state.isSmallScreen ? 15 : 0, scrollbarGutter: "stable" }}>
                        <div style={{...EssentialStyle.rowSpaceBetween, width: "100%", height: "auto", padding: "0px 10px", alignItems: "start", flexWrap: "wrap", justifyContent: this.state.isSmallScreen ? "space-evenly" : "space-between"}}>
                            <div style={{...(this.state.isSmallScreen ? EssentialStyle.rowFlexCenter : EssentialStyle.rowFlexStart)}}>
                                <Form.Group style={EssentialStyle.columnStart}>
                                    <Form.Label style={{fontSize: 10, marginBottom: 0}}>Início</Form.Label>
                                    {this.renderDatePicker(
                                        this.state.tarefa.data_inicio, 
                                        (date) => { 
                                            this.setState({ tarefa: {...this.state.tarefa, data_inicio : moment(date).format('YYYY-MM-DD')} });
                                        }, 
                                        moment().subtract(100, 'years').toDate(),
                                        this.state.tarefa.data_fim ? moment(this.state.tarefa.data_fim).toDate() : moment().add(100, 'years').toDate())}
                                </Form.Group>
                                <Form.Group style={EssentialStyle.columnStart}>
                                    <Form.Label style={{fontSize: 10, marginBottom: 0}}>Fim</Form.Label>
                                    {this.renderDatePicker(
                                        this.state.tarefa.data_fim, 
                                        (date) => {
                                            this.setState({ tarefa: {...this.state.tarefa, data_fim: moment(date).format('YYYY-MM-DD')} });
                                        }, 
                                        this.state.tarefa.data_inicio ? moment(this.state.tarefa.data_inicio).toDate() : moment().subtract(100, 'years').toDate())}
                                </Form.Group>
                            </div>
                            <Form.Group style={this.state.isSmallScreen ? EssentialStyle.columnCenter : EssentialStyle.columnStart}>
                                <Form.Label style={{fontSize: 10, marginBottom: 0}}>Status</Form.Label>
                                {this.renderStatusSelector()}
                            </Form.Group>
                        </div>
                        <Form.Group style={{width: "100%", height: "auto", padding: "0px 10px"}}>
                            <Form.Label>Descrição da Tarefa</Form.Label>
                            {this.renderTextEditor()}
                        </Form.Group>
                        <Form.Group style={{width: "100%", height: "auto", padding: "0px 10px", marginBottom: "10px"}}>
                            <Form.Label>Prioridade</Form.Label>
                            {this.renderSelectPrioridade()}    
                        </Form.Group>
                        <Form.Group style={{width: "100%", height: "auto",  padding: "0px 10px", marginBottom: "10px"}}>
                            <Form.Label>Responsável</Form.Label>
                            {this.renderSelectResponsavel()}    
                        </Form.Group>
                        <Form.Group style={{width: "100%", height: "auto", padding: "0px 10px", marginBottom: "10px"}}>
                            <Form.Label>Anexos</Form.Label>
                            {this.renderAnexos()}
                        </Form.Group>
                        <div style={{width: "100%", height: "auto", padding: "10px", marginBottom: "10px"}}>
                            {footer()}
                        </div>
                    </div>
                    {/* {this.renderClonarTarefaModal()} */}
                </div>
            );
        }

        return (
            ReactDOM.createPortal(
                modal(),
                el
            )
        );
    }
}
