import React from "react";
import './AddAtaReuniao.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import DefaultButton from "../../../tools/DefaultButton";
import Colors from "../../../../constants/Colors";
import EssentialStyle from "../../../../style/EssentialStyle";
import { Form, Row, Col } from "react-bootstrap";
import Select from "react-select";
import DataHelper from "../../../../helper/DataHelper";
import AddTag from "../../../tools/AddTag";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import DefaultLoader from "../../../tools/DefaultLoader";
import Sig from "../../../../api/Sig";
import DatePicker from "react-datepicker";
import DatePickerHelper from "../../../../helper/DatePickerHelper";
import moment from "moment";
import { toast } from "react-toastify";
import AtaReuniaoParticipantes from "../../../modules/ataReuniao/AtaReuniaoParticipantes/AtaReuniaoParticipantes";

export default class AddAtaReuniao extends React.Component {
    state = {
        step: 1,
        loading: true,
        assunto: "",
        url: "",
        type: null,
        tags: [],
        confidential: false,
        local: "",
        tiposReuniao: [],
        date: null,
        modo: null,
        id: null,
        participantes: [],
        possiveisParticipantes: [],
        tiposPermissao: [], 
        tiposFuncoes: [],
        podeConfidenciar: false,
        hash: null,
    }

    async componentDidMount() {
        this.handleUrlParams();
    }

    handleUrlParams = () => {
        window.parent.postMessage({ type: 'get_url' }, '*');

        const listenerFunction = async (event) => {
            if (event.data.type === 'return_url') {
                const urlObj = new URL(event.data.url);
                const queryParams = new URLSearchParams(urlObj.search);
                const tipo = queryParams.get('tipo');

                if (tipo && (tipo == "novo" || tipo == "editar")) {
                    await this.loadData(true, queryParams);
                } else {
                    await this.loadData();
                }

                window.removeEventListener('message', listenerFunction);
            }
        };

        window.addEventListener('message', listenerFunction);
    }

    async loadData(getParams = false, queryParams = null) {

        this.setState({ loading: true });

        if (getParams && queryParams && queryParams.get('tipo') == 'editar') {
            this.setState({ modo: "editar" });
            await this.loadEditar(getParams, queryParams);
        } else {
            if(queryParams && queryParams.get('hash') != null) {
                toast.error("Você não tem permissão para acessar essa página!");
                window.parent.postMessage({ type: 'navigate', url: `/login` }, '*');
            }
            this.setState({ modo: "novo" });
            await this.loadNovo();
        }

        this.setState({ loading: false });
    }

    async loadNovo() {

        var type = this.state.type;

        // seta a URL para novo se não tiver parâmetros
        window.parent.postMessage({ type: 'add_url_param', param: 'tipo', value: 'novo' }, '*');

        // Busca de informaçõa para criar o plano de ação (possiveis vinculos, tipos de planos, colaboradores)
        let {tiposReuniao, possiveisParticipantes, tiposPermissao, tiposFuncoes, podeConfidenciar} = await Sig.request('GET', 'ataReuniao/getSelectsOptions');
        let criador = await Sig.request('GET', 'ataReuniao/getCriador');

        this.setState({ 
            criador,
            tiposReuniao,
            participantes: [criador],
            possiveisParticipantes,
            tiposPermissao ,
            tiposFuncoes,
            podeConfidenciar,
            type: type == null ? DataHelper.formatSelectData(tiposReuniao, "id", "nome")[0] : type,
            url: "",
            date: null,
            assunto: "",
            tags: [],
            confidential: false,
            local: "",
        }, () => {
            this.setState({ loading: false })
        });
    }

    async loadEditar(getParams, queryParams) {
        let id = queryParams.get('id');
        let hash = queryParams.get('hash');
        this.setState({ id, hash });

        // Busca de informaçõa para criar o plano de ação (possiveis vinculos, tipos de planos, colaboradores)
        let {tiposReuniao, possiveisParticipantes, tiposPermissao, tiposFuncoes, podeConfidenciar} = await Sig.request('GET', `ataReuniao${hash ? "Externa" : ""}/getSelectsOptions`, {hash});
        let {ata} = await Sig.request('GET', `ataReuniao${hash ? "Externa" : ""}/getAtaReuniao`, {
            id,
            hash
        });

        if(!ata || ata == null) {
            toast.error("Ata de Reunião não encontrada!");
            if(hash) {
                window.parent.postMessage({ type: 'navigate', url: `/login` }, '*');
            } else {
                window.parent.postMessage({ type: 'navigate', url: `/ataReuniao` }, '*');
            }
        }

        this.setState({ 
            criador: ata.criador,
            participantes: ata.participantes,
            tiposReuniao,
            possiveisParticipantes,
            tiposPermissao ,
            tiposFuncoes,
            podeConfidenciar,
            type: ata.tipo ? DataHelper.formatSelectData(tiposReuniao, "id", "nome").find((tipo) => tipo.value == ata.tipo) : null,
            assunto: ata.assunto,
            tags: ata.tags ? DataHelper.formatSelectData(ata.tags, "id", "nome") : [],
            confidential: ata.confidencial == 1,
            local: ata.local,
            url: ata.url,
            date: moment(ata.data).toDate(),
        }, () => {
            this.setState({ loading: false })
        });
    }

    save = async () => {

        if (!this.validarParticipantes()) {
            return;
        } else {
            this.setState({ loading: "Salvando..." });
            let response = await Sig.request('POST', `ataReuniao${this.state.hash ? "Externa" : ""}/saveAtaReuniao`, { 
                id: this.state.id || null,
                hash: this.state.hash,
                ataReuniao: JSON.stringify({
                    assunto: this.state.assunto,
                    tipo: this.state.type.value,
                    tags: this.state.tags,
                    confidencial: this.state.confidential ? 1 : 0,
                    local: this.state.local,
                    url: this.state.url,
                    date: DataHelper.getDefaultDbDateFormat(this.state.date),
                    participantes: this.state.participantes
                })
            });
            if (response.status == 200) {
                var ataReuniao = response.ataReuniao;
                toast.success("Ata de Reunião Salva com sucesso!");
                if(this.state.hash) {
                    window.parent.postMessage({ type: 'navigate', url: `/ataReuniaoExterna/detalhar/${ataReuniao.id}?hash=${this.state.hash}` }, '*');
                } else {
                    window.parent.postMessage({ type: 'navigate', url: `/ataReuniao/detalhar/${ataReuniao.id}` }, '*');
                }
            }
        }
    }

    step() {
        if (this.state.step > 1) {
            this.setState({ step: this.state.step - 1 });
        } else if (this.state.modo == "novo") {
            window.parent.postMessage({ type: 'delete_url_params' }, '*');
            this.props.addCallback();
        } else if (this.state.modo == "editar") {
            if(this.state.hash) {
                window.parent.postMessage({ type: 'navigate', url: `/ataReuniaoExterna/detalhar/${this.state.id}?hash=${this.state.hash}` }, '*');
            } else {
                window.parent.postMessage({ type: 'navigate', url: `/ataReuniao/detalhar/${this.state.id}` }, '*');
            }        
        }
    }

    nextStep() {
        if (this.state.step === 1) {
            if (!this.state.assunto || !this.state.type || !this.state.date) {
                    toast.error('Preencha todos os campos obrigatórios!');
                return;
            } else {
                this.setState({ step: this.state.step + 1});
            }
        }
    }

    //Funções de Renderização

    renderLoading() {
        return (
            <div style={{ minWidth: '100%', minHeight: '100vh', ...EssentialStyle.rowFlexCenter }}>
                <DefaultLoader title={typeof this.state.loading === 'string' ? this.state.loading : null} style={{ width: "100%", height: "100%" }} />
            </div>
        )
    }

    renderStepBack() {
        return (
            <div style={{ position: 'absolute', top: 10, left: 10 }}>
                <DefaultButton title={''} leftIcon={<FontAwesomeIcon icon={faChevronLeft} />} color={Colors.secondaryButton} textColor={Colors.dark} loading={this.state.loading} onClick={() => { this.step() }} style={{ marginRight: 8 }} />
            </div>
        );
    }

    renderHelp() {
        return (
            <div style={{ position: 'absolute', top: 10, right: 10 }}>
                <DefaultButton 
                style={{ width: 38, height: 38, marginLeft: 8}}
                fontSize={this.state.isSmallScreen ? 14 : "unset"}
                color={Colors.secondaryButton}
                textColor={Colors.dark} 
                link={ 'https://advis.freshdesk.com/support/solutions/articles/63000225982-atas-de-reuni%C3%A3o#configurar'}/>
            </div>
        );
    } // Colocar link das atas //////////// mudar

    renderSteps() {
        let stepContent;

        switch (this.state.step) {
            case 1:
                stepContent = this.renderFormOne();
                break;
            case 2:
                stepContent = this.renderFormTwo();
                break;
            default:
                stepContent = <div>Passo desconhecido</div>;
        }

        return stepContent;
    }

    renderFormOne() {

        return (
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', alignContent: 'center', minHeight: 'inherit' }}>
                <Form style={{ width: '90%', paddingTop: '3rem' }}>
                    <Row className="mb-3">
                        <Form.Group as={Col} md={6} xxl={6} className="mb-3">
                            <Form.Label>
                                Assunto*
                            </Form.Label>
                            <Form.Control
                                type="text"
                                placeholder={"Assunto da Reunião..."}
                                value={this.state.assunto ? this.state.assunto : ""}
                                onChange={(event) => { this.setState({ assunto: event.target.value }) }}
                            />
                        </Form.Group>

                        <Form.Group as={Col} md={6} xxl={3} className="mb-3">
                            <Form.Label>Tipo*</Form.Label>
                            <Select
                                options={DataHelper.formatSelectData(this.state.tiposReuniao, 'id', 'nome')}
                                placeholder={'Selecione o tipo de Reunião'} noOptionsMessage={DataHelper.getSelectEmptyMessage}
                                value={this.state.type}
                                onChange={(value) => { this.setState({ type: value }) }}
                            />
                        </Form.Group>
                        
                        <Form.Group as={Col} md={6} xxl={3} className="mb-1">
                            <Form.Label>Data*</Form.Label>
                            {this.renderDatePicker()}
                        </Form.Group>

                        <Form.Group as={Col} md={6} xxl={6} className="mb-3">
                            <Form.Label>Local</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder={"Local da Reunião..."}
                                value={this.state.local ? this.state.local : ""}
                                onChange={(event) => { this.setState({ local: event.target.value }) }}
                            />
                        </Form.Group>

                        <Form.Group as={Col} md={6} xxl={6} className="mb-3">
                            <Form.Label>Link da Reunião</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder={"Link da Reunião..."}
                                value={this.state.url ? this.state.url : ""}
                                onChange={(event) => { this.setState({ url: event.target.value }) }}
                            />
                        </Form.Group>

                        <AddTag md={6} xxl={8} selected={this.state.tags} onChange={(value) => { this.setState({ tags: value }) }} hash={this.state.hash}/>

                        <Form.Group as={Col} md={6} xxl={4} className="mb-1">
                            <Form.Check
                                disabled={!this.state.podeConfidenciar}
                                className="confidential-checkbox"
                                type="checkbox"
                                label="Confidencial"
                                checked={this.state.confidential}
                                onChange={(event) => { this.setState({ confidential: event.target.checked }) }}
                            />
                        </Form.Group>

                    </Row>

                    <div className="mb-3 d-flex flex-row-reverse">
                        <Form.Group>
                            <DefaultButton
                                color={Colors.success}
                                rightIcon={<FontAwesomeIcon icon={faChevronRight} />}
                                title={'Avançar'}
                                loading={this.state.loading}
                                onClick={() => { this.nextStep() }}
                            />
                        </Form.Group>
                    </div>
                </Form>
            </div>
        );
    }

    CustomPicker = React.forwardRef(({ value, onClick }, ref) => (
        <Form.Control onClick={onClick} ref={ref} value={value} readOnly placeholder={"Data e Hora da Reunião"}/>
    ));

    renderDatePicker() {
        let dateMoment = this.state.date && this.state.date != null ? moment(this.state.date).toDate() : null;

        return (
            <DatePicker
                wrapperClassName="w-100"
                selected={dateMoment}
                onChange={(date) => {
                    this.setState({ date: date });
                }}
                dateFormat="dd/MM/yyyy HH:mm"
                customInput={<this.CustomPicker />}
                showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={30}
                timeCaption="Hora"
                renderCustomHeader={(props) =>  
                    DatePickerHelper.renderCustomDatePickerHeader(props)
                }
            />
        );
    }

    changeParticipanteCallback = (index, participante) => {
        let participantes = [...this.state.participantes];
        
        if (index != -1) {
            participantes[index] = participante;
        }

        this.setState({ participantes });
    }

    validarEmail = (email) => {
        var isValido = false;

        var regex = /^[\w\-\.]+@([\w-]+\.)+[\w-]{2,}$/gm;
        isValido =  regex.test(email);

        var participantes = [...this.state.participantes].filter((participante) => {
            return participante.email == email;
        })

        if(participantes.length > 1) {
            isValido = false;
        }
        return isValido;
    }

    validarParticipantes = () => {
        let participantes = [...this.state.participantes];
        
        participantes = participantes.filter((participante) => {
            return (!participante.id && (!participante.nome || participante.nome == "" || !this.validarEmail(participante.email))) || participante.permissao == null || participante.funcao == null;
        });

        if(participantes.length > 0) {
            toast.error('Preencha todos os participantes corretamente!');
            return false;
        }

        return true;
    }

    addParticipanteCallback = () => {
        if(this.validarParticipantes()) {
            let participantes = [...this.state.participantes];
            participantes.push({
                id: null,
                nome: null,
                email: null,
                permissao: this.state.participantes.length > 0 ? this.state.participantes[this.state.participantes.length-1].permissao : null,
                funcao: 0,
            });
            this.setState({ participantes });
        }
    }

    renderParticipantes = () => {
        
        return (
            <AtaReuniaoParticipantes 
                criador={this.state.criador}
                participantes={this.state.participantes?.length > 0 ? [...this.state.participantes] : []} 
                changeCallback={this.changeParticipanteCallback} 
                addCallback={this.addParticipanteCallback} 
                removeCallback={(index) => {
                    if(index == -1) return;
                    let participantes = [...this.state.participantes];
                    participantes.splice(index, 1);
                    this.setState({ participantes });
                }}
                selectsOptions={{
                    "tiposFuncoes": this.state.tiposFuncoes,
                    "possiveisParticipantes": this.state.possiveisParticipantes,
                    "tiposPermissao": this.state.tiposPermissao
                }}
                tipoReuniao={this.state.type}
            />
        );
    }

    renderFormTwo() {
        return (
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', alignContent: 'center', minHeight: 'inherit', paddingTop: '3rem' }}>
                <Form style={{ width: '90%' }}>
                    <Row className="mb-3">
                        {this.renderParticipantes()}
                    </Row>


                    <div className="mb-3 d-flex flex-row-reverse">
                        <Form.Group>
                            <DefaultButton
                                color={Colors.success}
                                rightIcon={<FontAwesomeIcon icon={faChevronRight} />}
                                title={'Salvar'}
                                loading={this.state.loading}
                                onClick={() => { this.save() }}
                            />
                        </Form.Group>
                    </div>
                </Form>
            </div>
        );
    }

    render() {

        if (this.state.loading) return this.renderLoading();

        return (
            <div style={{ minWidth: '100%', minHeight: '100vh' }}>
                {this.renderStepBack()}
                {this.renderHelp()}
                {this.renderSteps()}
            </div>
        );
    }
}