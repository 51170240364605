import React from 'react';

import { debounce } from 'lodash';

import EssentialStyle from "../../../../../../style/EssentialStyle";
import DefaultLoader from '../../../../../tools/DefaultLoader';
import Colors from "../../../../../../constants/Colors";
import Sig from '../../../../../../api/Sig';
import 'moment/locale/pt-br'
import moment from 'moment';
import PlanoAcaoRelatorioTimelineLinha from './PlanoAcaoRelatorioTimelineLinha';
import { toast } from "react-toastify";
import PlanoAcaoHelper from '../../../../../../helper/planoAcao/PlanoAcaoHelper';

export default class PlanoAcaoRelatorioTimeline extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            timelineStart: 0,
            timelineEnd: 0,
            granularidade: this.props.granularidade,
            daySize: this.props.granularidade == 'mes' ? 4 : this.props.granularidade == "semana" ? 16 : 1,
            data: [],
            page: 1,
            hasMore: false,
            loading: true,
            requesting: false,
            filter: {},
            firstLoad: true,
            partialLoading: false
        };
        this.scrollRef = React.createRef();
        this.handleScrollDebounced = debounce(this.handleScroll, 100);
    }

    async componentDidMount() {
        if (document.querySelector('#timeline-container')) {
            document.querySelector('#timeline-container').addEventListener('scroll', this.handleScrollDebounced);
        }

        var timelineStart = moment(new Date()).subtract(6, 'months').startOf('month').startOf('day').toDate().getTime();
        var timelineEnd = moment(new Date()).add(24, 'months').endOf('month').endOf('day').toDate().getTime();
        this.setState({ loading: false, timelineStart, timelineEnd });

        this.scrollToCurrentDate();

        this.loadData(1);
    }

    componentDidUpdate = async (prevProps, prevState) => {
        if (document.querySelector('#timeline-container')) {
            let listPlanosRelatorio = document.querySelector('#timeline-container');

            if (listPlanosRelatorio) {
                listPlanosRelatorio.removeEventListener('scroll', this.handleScrollDebounced);
                listPlanosRelatorio.addEventListener('scroll', this.handleScrollDebounced);
            }
        }

        if (this.state.granularidade != this.props.granularidade) {
            this.setState({ daySize: this.props.granularidade == 'mes' ? 4 : this.props.granularidade == "semana" ? 16 : 1, granularidade: this.props.granularidade }, () => {
                this.scrollToCurrentDate();
            });
        }
        
        if (prevProps.filter !== this.props.filter) {
            if(this.state.requesting) {
                this.abortController.abort();
                this.abortController = new AbortController();
            }

            this.setState({ 
                data: [],
                loading: true,
                requesting: false,
                firstLoad: true
            }, () => {
                this.loadData();
            });
        }
    }

    componentWillUnmount() {
        if (document.querySelector('#timeline-container')) {
            document.querySelector('#timeline-container').removeEventListener('scroll', this.handleScrollDebounced);
        }
    }

    handleScroll = () => {
        const scrollableDiv = document.querySelector('#timeline-container');

        if (scrollableDiv) {
            const threshold = 100;
            if (scrollableDiv.scrollTop + scrollableDiv.clientHeight >= scrollableDiv.scrollHeight - threshold) {
                if (!this.state.loading && !this.state.requesting && this.state.hasMore) {
                    this.loadData(this.state.page + 1);
                }
            }
        }
    }

    async loadData(page = 1) {
        let timeout = null;

        if (this.state.requesting) return;
        this.setState({ requesting: true, partialLoading: true });

        let screenHeigth = window.innerHeight * 0.65;
        let cardHeight = 40;
        let limit = Math.ceil(screenHeigth / cardHeight) * 2;

        var req = await Sig.request('POST', 'planoAcao/list', { 
            page, 
            limit: limit, 
            filter: JSON.stringify(this.props.filter), 
            parceiro: this.props.parceiro ? 1 : 0, 
            etapas: 1,
            id_periodo_avaliacao: this.props.id_periodo_avaliacao || 0
        });

        if (this.state.firstLoad) {
            this.setState({ loading: true, firstLoad: false });
        } else {
            timeout = setTimeout(() => {
                this.setState({ loading: true });
            }, 200);
        }

        if (timeout) {
            clearTimeout(timeout);
        }

        if (req.status != 200) {
            this.setState({ loading: false, requesting: false });
            return toast.error("Erro ao carregar dados");
        }

        if (req.data.length < 10)
            this.setState({ hasMore: false });
        else
            this.setState({ hasMore: true });

        this.setState(prevState => ({
            data: page === 1 ? req.data : [...prevState.data, ...req.data],
            page,
            loading: false
        }));

        this.setState({ requesting: false });
    }

    scrollToCurrentDate = () => {
        if (!this.scrollRef.current)
            setTimeout(this.scrollToCurrentDate, 100);
        else {
            var left = (moment().subtract(15, 'day').diff(moment(new Date(this.state.timelineStart)), 'days') * this.state.daySize);
            this.scrollRef?.current?.scrollTo({ top: 0, left: left, behavior: 'smooth' })
        }
    }

    renderEmpty = () => {
        return (
            <div style={{ ...EssentialStyle.columnCenter, ...EssentialStyle.card, padding: 10, width: '100%', marginTop: 10, height: "calc(100% - 40px)" }}>
                <h2 style={{ color: Colors.homePage.grey, fontSize: 20, fontWeight: 500, textAlign: 'center' }}>Nenhum plano de ação encontrado</h2>
            </div>
        );
    }

    renderList() {
        if (this.state.data.length === 0 && !this.state.loading) return this.renderEmpty();

        return this.state.data.map((item, index) => this.renderTimelineElement(item, index));
    }


    renderTimelineElement = (item, index) => {
        return (
            <PlanoAcaoRelatorioTimelineLinha
                filter={this.props.filter}
                expanded={PlanoAcaoHelper.getPlanoShouldBeExpanded(this.props.filter)}
                parceiro={this.props.parceiro}
                timelineStart={this.state.timelineStart}
                timelineEnd={this.state.timelineEnd}
                element={item}
                isSmallScreen={this.props.isSmallScreen}
                key={`timeline-element-${index}`}
                index={index}
                type={this.props.view}
                changePlanoCallback={this.props.view == 'planos' ? (plano) => this.changePlanoCallback(plano) : () => { }}
                daySize={this.state.daySize}
            />
        )
    }
    renderTimeline = () => {
        var { timelineStart, timelineEnd } = this.state;

        var offsetLeft = 400;
        if (this.props.isSmallScreen)
            offsetLeft = 250;

        var quantMonths = Math.round(moment(new Date(timelineEnd)).diff(moment(new Date(timelineStart)), 'months', true));
        var quantDias = Math.round(moment(new Date(timelineEnd)).diff(moment(new Date(timelineStart)), 'days', true));
        var quantSemanas = Math.ceil(quantDias / 7);
        var quantAnos = Math.ceil(moment(new Date(timelineEnd)).diff(moment(new Date(timelineStart)), 'years', true));

        return (
            <div style={{
                width: '100%',
                height: "auto",
                maxHeight: `65vh`,
                marginBottom: 10
            }}>
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", height: "100%", backgroundColor: Colors.white, borderRadius: 8, boxShadow: `0px 5px 5px 0px  rgba(50, 50, 50, 0.2)`, maxHeight: "inherit", overflow: 'hidden' }}>
                    <div style={{ display: "flex", flexDirection: "column", width: '100%', height: "max-content", alignItems: 'center', justifyContent: 'flex-start', maxHeight: "inherit", overflow: 'hidden' }}>
                        <div id={'timeline-container'} ref={this.scrollRef} style={{ width: "max-content", maxWidth: "100%", height: "max-content", maxHeight: "100%", position: "relative", display: "flex", flexDirection: "column", justifyContent: "flex-start", maxHeight: "inherit", overflow: "auto" }}>
                            <div style={{
                                width: `${(this.state.daySize * quantDias) + offsetLeft}px`, minHeight: this.state.granularidade == "semana" ? "90px" : "60px",
                                display: "flex", flexDirection: "row",
                                position: "sticky", top: 0, zIndex: 5
                            }}
                            >
                                <div style={{
                                    width: offsetLeft, lineHeight: this.state.granularidade == "semana" ? "90px" : "60px", position: "sticky", left: 0, borderRight: `1px solid ${Colors.homePage.grey}`,
                                    height: this.state.granularidade == "semana" ? "90px" : "60px", display: "flex", zIndex: 10, backgroundColor: Colors.tag, fontWeight: 500,
                                    flexDirection: "column", justifyContent: "center", alignItems: "center", borderBottom: `1px solid ${Colors.tag}`
                                }}>
                                    <div style={{ ...EssentialStyle.rowFlexStart, gap: 10 }}>
                                        {this.state.requesting ? <DefaultLoader size={18} color={Colors.dark} /> : null}
                                        {this.props.view == 'planos' ? "Planos de Ação / Etapas" : "Etapas"}
                                    </div>
                                </div>
                                <div style={{ width: `${this.state.daySize * quantDias}px`, display: "flex", flexDirection: "column", position: "relative", zIndex: 4 }}>
                                    {this.state.granularidade == "ano" &&
                                        <div style={{ display: "flex", minWidth: "100%", height: "30px", flexDirection: "row", justifyContent: 'center', alignItems: "center" }}>
                                            {
                                                Array.from({ length: quantAnos }, (_, i) => i).map((_, index) => {
                                                    var currentDate = moment(new Date(timelineStart)).add(index, 'years').toDate().getTime();
                                                    if (index == 0) {
                                                        var daysInYear = Math.round(moment(new Date(currentDate)).endOf('year').diff(moment(new Date(currentDate)), 'days', true));
                                                    }
                                                    else if (index == quantAnos - 1) {
                                                        var daysInYear = Math.round(moment(new Date(timelineEnd)).diff(moment(new Date(currentDate)).startOf('year'), 'days', true));
                                                    }
                                                    else {
                                                        var daysInYear = Math.round(moment(new Date(currentDate)).endOf('year').diff(moment(new Date(currentDate)).startOf('year'), 'days', true));
                                                    }

                                                    return (
                                                        <div key={"year-" + index} style={{
                                                            width: `${daysInYear * this.state.daySize}px`, lineHeight: "30px", fontWeight: 500,
                                                            minHeight: "60px", display: "flex", position: "relative", borderBottom: `1px solid ${Colors.tag}`,
                                                            flexDirection: "column", justifyContent: "center", alignItems: "center",
                                                            backgroundColor: index % 2 == 1 ? Colors.tag : Colors.white
                                                        }}>
                                                            {moment(new Date(currentDate)).format('YYYY')}
                                                        </div>
                                                    );
                                                })
                                            }
                                        </div>
                                    }
                                    <div style={{ display: "flex", minWidth: "100%", height: this.state.granularidade == "mes" ? "60px" : "30px", flexDirection: "row", justifyContent: 'center', alignItems: "center" }}>
                                        {
                                            Array.from({ length: quantMonths }, (_, i) => i).map((_, index) => {
                                                var daysInMonth = moment(new Date(timelineStart)).add(index, 'months').daysInMonth();
                                                return (
                                                    <div key={"month-" + index} style={{
                                                        width: `${daysInMonth * this.state.daySize}px`, lineHeight: this.state.granularidade == "mes" ? "28px" : "30px", fontWeight: 500,
                                                        minHeight: this.state.granularidade == "mes" ? "60px" : "30px", display: "flex", position: "relative", borderBottom: `1px solid ${Colors.tag}`,
                                                        flexDirection: "column", justifyContent: "center", alignItems: "center",
                                                        backgroundColor: this.state.granularidade != "mes" && index % 2 == 1 ? Colors.tag : Colors.white
                                                    }}>
                                                        {moment(new Date(timelineStart)).add(index, 'months').format(this.state.granularidade == "ano" ? 'MM' : 'MMM/YYYY')}
                                                    </div>
                                                );
                                            })
                                        }
                                    </div>
                                    {this.state.granularidade == "semana" &&
                                        <div style={{ display: "flex", minWidth: "100%", height: "30px", flexDirection: "row", alignItems: "center" }}>
                                            {
                                                Array.from({ length: quantSemanas }, (_, i) => i).map((_, index) => {
                                                    var currentWeek = moment(new Date(timelineStart)).add(index, 'weeks');
                                                    var daysInWeek = 7;
                                                    if (index == 0) {
                                                        var weekdayStart = currentWeek.day();
                                                        daysInWeek -= weekdayStart - 1;
                                                    }
                                                    if (index == quantSemanas - 1) {
                                                        var weekdayEnd = currentWeek.day();
                                                        var daysToEnd = parseInt(moment(new Date(timelineEnd)).format('D')) - parseInt(currentWeek.format('D'));
                                                        if (daysToEnd) {
                                                            weekdayEnd += daysToEnd;
                                                        }
                                                        daysInWeek = weekdayEnd;
                                                    }
                                                    return (
                                                        <div key={"week-" + index} style={{
                                                            width: `${daysInWeek * this.state.daySize}px`, lineHeight: "28px", fontWeight: 500,
                                                            minHeight: "30px", display: "flex", position: "relative", borderBottom: `1px solid ${Colors.tag}`,
                                                            flexDirection: "column", justifyContent: "center", alignItems: "center",
                                                            backgroundColor: Colors.white, borderTop: `1px solid ${Colors.tag}`, borderRight: `1px solid ${Colors.tag}`,
                                                        }}>
                                                            {daysInWeek > 6 ? "Semana " + currentWeek.isoWeek() : daysInWeek >= 2 ? currentWeek.isoWeek() : null}
                                                        </div>
                                                    );
                                                })
                                            }
                                        </div>
                                    }
                                    {this.state.granularidade == "semana" &&
                                        <div style={{ display: "flex", minWidth: "100%", height: "30px", flexDirection: "row", alignItems: "center", fontSize: 10 }}>
                                            {
                                                Array.from({ length: quantDias }, (_, i) => i).map((_, index) => {
                                                    var currentDay = moment(new Date(timelineStart)).add(index, 'days');
                                                    return (
                                                        <div key={"day-" + index} style={{
                                                            width: `${this.state.daySize}px`, lineHeight: "28px", fontWeight: 500,
                                                            minHeight: "30px", display: "flex", position: "relative", borderBottom: `1px solid ${Colors.tag}`,
                                                            flexDirection: "column", justifyContent: "center", alignItems: "center",
                                                            backgroundColor: Colors.white, borderRight: `1px solid ${Colors.tag}`,
                                                        }}>
                                                            {currentDay.date()}
                                                        </div>
                                                    );
                                                })
                                            }
                                        </div>
                                    }
                                </div>
                            </div>
                            <div style={{ width: "max-content", height: "100%", display: "flex", justifyContent: "flex-start", alignItems: "flex-start", position: "relative", flexDirection: "column" }}>
                                {this.renderList()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderLoading() {
        return (
            <div style={{ ...EssentialStyle.columnCenter, width: '100%', height: `calc(40vh)` }}>
                <DefaultLoader />
            </div>
        );
    }

    render() {
        return this.state.loading ? this.renderLoading() : this.renderTimeline();
    }
}