import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHistory, faSort, faLayerGroup, faStar, faQuestionCircle, faCog, faTableCells } from '@fortawesome/free-solid-svg-icons'
import DefaultButton from "../../../tools/DefaultButton";
import Colors from "../../../../constants/Colors";
import { Dropdown, ButtonGroup } from "react-bootstrap";
import { toast } from "react-toastify";
import EssentialStyle from "../../../../style/EssentialStyle";
import LayoutHelper from "../../../../helper/LayoutHelper";
import SwotQuadrant from "./SwotQuadrant";
import LoadingPage from "../../../../pages/LoadingPage";
import UserAvatar from "../../../tools/UserAvatar";
import SwotHistory from "./SwotHistory";
import EditSwot from "../../../forms/pe/edit/EditSwot";
import GutMatrix from "../../../tools/GutMatrix";
import Sig from "../../../../api/Sig";
import CustomTooltip from "../../../tools/CustomTooltip";
import TooManyAvatars from "../../../tools/TooManyAvatars";
import EllipsisText from "../../../tools/EllipsisText";

export default class SwotView extends React.Component {
    state = {
        step: 0,
        loading: true,
        fromProjectId: null,
        swot: [],
        participants: [],
        name: '',
        tags: [],
        responsable: null,
        participants: [],
        quadrants: [],
        isResponsavel: null,
        isParticipante: null,
        acesso: null,
        permissao: null,
        description: '',
        showGut: false,
        showSettings: false,
        showHistory: false,
        draggingOverQuadrant: null,
        draggingItem: null,
        draggingOverItem: null,
        selectedOrder: localStorage.getItem('swot_order_option') || 'drag_index',
        maxValue: false,
        orderTypes: {
            drag_index: 'Drag and Drop',
            data_cadastro: 'Data de Criação',
            data_atualizacao: 'Data de Atualização',
            descricao: 'Alfabética',
            points: 'Priorização GUT'
        },
        flipDirectionSmallDevice: LayoutHelper.flipDirectionSmallDevice('row'),
        flipValuesSmallDevice: LayoutHelper.flipValuesSmallDevice('50', '100'),
        isSmallScreen: LayoutHelper.isSmallScreen()
    }

    async componentDidMount() {
        await this.loadData();
        window.addEventListener('resize', this.verifyScreen);
    }

    verifyScreen = () => {
        this.setState({
            flipDirectionSmallDevice: LayoutHelper.flipDirectionSmallDevice('row'), 
            flipValuesSmallDevice: LayoutHelper.flipValuesSmallDevice('50', '100'),
            isSmallScreen: LayoutHelper.isSmallScreen()
        });
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.verifyScreen);
    }

    async loadData() {
        this.setState({ loading: true });

        try {
            const { swot, participantes, quadrantes, escala, acesso, permissao, isResponsavel, isParticipante } = await Sig.request('GET', `pe/swot/getSwot?id=${this.props.id}`);
            const gut = await Sig.request('GET', 'pe/swot/getMatrizGut', { id: this.props.id });

            // Verifique se quadrantes e gut.criterios existem antes de iterar
            if (gut && gut.criterios && Array.isArray(quadrantes)) {
                quadrantes.forEach((quadrant) => {
                    if (quadrant.itens && Array.isArray(quadrant.itens)) {
                        quadrant.itens.forEach((item) => {
                            const criterio = gut.criterios.find((c) => c.id === item.id);
                            if (criterio) {
                                item.pontuacao = criterio.pontuacao; // Atualiza a pontuação do item
                            }
                        });
                    }
                });
            }

            this.setState({ 
                participants: participantes || [], 
                swot: swot || {},
                quadrants: quadrantes || [],
                acesso: acesso || null,
                permissao: permissao || null,
                isResponsavel: isResponsavel || null,
                isParticipante: isParticipante || null,
                name: swot?.nome || '', 
                description: swot?.descricao || '', 
                gutMaxValue: escala || false 
            }, () => {
                this.setState({ loading: false });
                window.parent.postMessage({ type: 'add_url_param', param: 'id', value: this.props.id }, '*'); // Atualiza a URL
            });
        } catch (error) {
            console.error('Erro ao carregar dados da SWOT:', error);
            this.setState({ loading: false });
            toast.error('Erro ao carregar dados da SWOT.');
        }
    }

    async handleFavorite() {
        this.setState({ loading: true });

        let response = await Sig.request('POST', `pe/swot/setFavorito`, { id: this.props.id, favorito: this.state.isFavorite ? 0 : 1 });

        await this.loadData();

        this.setState({ loading: false });
        
        if (response.response == 200){
            toast.success('Alterado com sucesso');
        } else {
            toast.error('Você não tem permissão para realizar essa ação');
        }
    }

    getMaxValue = (criterio) => {
        let maxValue = 1;

        criterio.map((criterio, key) => {

            if (criterio.pontuacao && criterio.pontuacao.length) {
                maxValue *= criterio.pontuacao[criterio.pontuacao.length - 1].valor;
            }
        });

        this.setState({ maxValue: maxValue });
    }

    gutCallback = async () => {
        this.setState({ showGut: false });
        await this.loadData();
    }

    historyCallback = () => {
        this.setState({ showHistory: false });
    }

    settingsCallback = async () => {
        this.setState({ showSettings: false });
        await this.loadData();
    }

    handleOrderChange(key) {

        this.setState({ selectedOrder: key });
        window.localStorage.setItem('swot_order_option', key);
    }

    renderHeader() {
        var participants = this.state.participants;

        var lastButtons = (
            <>
                <DefaultButton 
                    tooltip={'Configurações'} 
                    tooltipPlacement={'left'} 
                    leftIcon={<FontAwesomeIcon icon={faCog} />} 
                    color={Colors.secondaryButton} 
                    textColor={Colors.dark} 
                    loading={this.state.loading} 
                    onClick={() => { this.setState({ showSettings: true }) }} 
                    style={{ width: this.state.isSmallScreen ? 30 : 40, height: this.state.isSmallScreen ? 30 : 40, fontSize: this.state.isSmallScreen ? 14 : "unset", display: "flex", alignItems: "center", justifyContent: "center" }} 
                    disabled={!(this.state.acesso == "pe_swot_administrador" || this.state.permissao == "pe_swot_editar" || this.state.isResponsavel)} 
                />
                <DefaultButton 
                    tooltip={'Listar Análises'} 
                    tooltipPlacement={'left'} 
                    leftIcon={<FontAwesomeIcon icon={faTableCells} />} 
                    color={Colors.secondaryButton} 
                    textColor={Colors.dark} 
                    loading={this.state.loading} 
                    onClick={() => { this.props.callback(); }} 
                    style={{ width: this.state.isSmallScreen ? 30 : 40, height: this.state.isSmallScreen ? 30 : 40, fontSize: this.state.isSmallScreen ? 14 : "unset", display: "flex", alignItems: "center", justifyContent: "center" }} 
                />
                <DefaultButton 
                    style={{ width: this.state.isSmallScreen ? 30 : 40, height: this.state.isSmallScreen ? 30 : 40}}
                    fontSize={this.state.isSmallScreen ? 14 : "unset"}
                    color={Colors.secondaryButton}
                    textColor={Colors.dark} 
                    link={"https://advis.freshdesk.com/support/solutions/articles/63000283129-swot"}/>
            </> );
        
        var userAvatars = (
            <div style={{ display: 'flex', flexDirection: 'row', marginLeft: 20 }}>

                <div style={{ marginLeft: -10 }}>
                    <UserAvatar noLoading={true} id={this.state.swot.id_responsavel} />
                </div>
                <div style={{ marginLeft: -4 }}>
                    <TooManyAvatars participantsTotal={participants.length} participantsVisiveis={2} arrayParticipants={participants} />
                </div>
            </div>
        )

        return (
            <div style={{ ...EssentialStyle.rowSpaceBetween, width: this.state.isSmallScreen ? 'calc(99%)' : 'calc(98%)', height: this.state.isSmallScreen ? '20vh' : '10vh', minHeight: this.state.isSmallScreen ? '120px' : '85px', boxSizing: 'border-box', padding: 5, margin: this.state.isSmallScreen ? '2px 0px 0px 2px' : '10px 0px 0px 12px', backgroundColor: 'white', boxShadow: Colors.boxShadow, border: '0.3px solid lightgrey', borderRadius: 8 }}>
                <div></div>
                <div style={{ display: 'flex', paddingLeft: this.state.isSmallScreen? 4 : 8, flexDirection: 'column', alignItems: 'center', justifyContent: 'center', minWidth: this.state.isSmallScreen ? '52%' : '35%', flexGrow: 1}}>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: "100%", justifyContent: "center" }}>
                        <div style={{ fontWeight: 'bold', textAlign: "center", fontSize: this.state.isSmallScreen ? 18 : 22, overflow: "hidden", maxWidth: this.state.isSmallScreen ? "calc(100% - 40px)" : "calc(100% - 160px)", width: "max-content", position: "relative", height: "35px" }}>
                            <EllipsisText text={this.state.name} />
                        </div>
                        <CustomTooltip placement={'bottom'} tooltip={<span id={`description`}>{this.state.description ? this.state.description : 'Sem Descrição'}</span>}>
                            <FontAwesomeIcon style={{ marginLeft: 8 }} color={'grey'} icon={faQuestionCircle} />
                        </CustomTooltip>
                        {this.state.isSmallScreen ? null : userAvatars}
                    </div>
                    {this.state.isSmallScreen ? userAvatars : null}
                    {this.state.maxValue !== false && (
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <span style={{ color: 'rgb(66, 82, 110)', fontSize: this.state.isSmallScreen ? 8 : 11}}>
                                (Pontuação Máxima de Priorização: {this.state.maxValue} pontos)
                            </span>
                        </div>
                    )}
                </div>
                <div style={{ display: 'flex', gap: this.state.isSmallScreen ? 4 : 8, flexDirection: 'row', alignItems: 'center', width: "auto", maxWidth: this.state.isSmallScreen ? '45%' : '65%', minWidth: this.state.isSmallScreen ? '185px' : '420px', flexWrap: 'wrap', justifyContent: 'center', marginRight: '8px' }}>
                    <DefaultButton 
                        tooltip={this.state.isFavorite ? 'Remover Favorito' : 'Marcar como Favorito'} 
                        tooltipPlacement={'left'} 
                        leftIcon={<FontAwesomeIcon icon={faStar} color={this.state.isFavorite ? Colors.favorite : Colors.dark} />} 
                        color={Colors.secondaryButton} 
                        textColor={Colors.dark} 
                        loading={this.state.loading} 
                        onClick={() => { this.handleFavorite() }} 
                        style={{ width: this.state.isSmallScreen ? 30 : 40, height: this.state.isSmallScreen ? 30 : 40, fontSize: this.state.isSmallScreen ? 14 : "unset", display: "flex", alignItems: "center", justifyContent: "center"}} 
                        disabled={!(this.state.acesso == "pe_swot_administrador" || this.state.permissao == "pe_swot_editar")}
                    />
                    <DefaultButton 
                        tooltip={'Histórico'} 
                        tooltipPlacement={'left'} 
                        leftIcon={<FontAwesomeIcon icon={faHistory} />} 
                        color={Colors.secondaryButton} 
                        textColor={Colors.dark} 
                        loading={this.state.loading} 
                        onClick={() => { this.setState({ showHistory: true }) }} 
                        style={{ width: this.state.isSmallScreen ? 30 : 40, height: this.state.isSmallScreen ? 30 : 40, fontSize: this.state.isSmallScreen ? 14 : "unset", display: "flex", alignItems: "center", justifyContent: "center" }} />
                    <DefaultButton 
                        tooltip={'Matriz de Priorização'} 
                        tooltipPlacement={'left'} 
                        leftIcon={<FontAwesomeIcon icon={faLayerGroup} />} 
                        color={Colors.secondaryButton} 
                        textColor={Colors.dark} 
                        loading={this.state.loading} 
                        onClick={() => { this.setState({ showGut: true }) }} 
                        style={{ width: this.state.isSmallScreen ? 30 : 40, height: this.state.isSmallScreen ? 30 : 40, fontSize: this.state.isSmallScreen ? 14 : "unset", display: "flex", alignItems: "center", justifyContent: "center" }} 
                        disabled={!(this.state.acesso == "pe_swot_administrador" || this.state.permissao == "pe_swot_editar" || this.state.isResponsavel)} 
                    />
                    {this.state.isSmallScreen ? lastButtons : null}
                    <Dropdown as={ButtonGroup}>
                        <Dropdown.Toggle value={this.state.selectedOrder} style={{ border: 0, backgroundColor: Colors.secondaryButton, color: Colors.dark , height: this.state.isSmallScreen ? 30 : 40, fontSize: this.state.isSmallScreen ? 12 : "unset" }}> <FontAwesomeIcon icon={faSort} style={{ marginRight: 8 }} /> <span style={{ marginRight: 8 }}>{this.state.orderTypes[this.state.selectedOrder]}</span></Dropdown.Toggle>
                        <Dropdown.Menu>
                            {Object.keys(this.state.orderTypes).map(key => (
                                <Dropdown.Item key={`order-opt-${key}`} onClick={() => { this.handleOrderChange(key) }} style={{ backgroundColor: key === this.state.selectedOrder ? Colors.dark : "unset", fontSize: this.state.isSmallScreen ? 12 : "unset" }} active={key === this.state.selectedOrder}>{this.state.orderTypes[key]}</Dropdown.Item>
                            ))}
                        </Dropdown.Menu>
                    </Dropdown>
                    {!this.state.isSmallScreen ? lastButtons : null}
                </div>
            </div>
        );
    }

    onDragOverQuadrant = (e, id) => {
        this.setState({ draggingOverQuadrant: id });
        e.stopPropagation();
        e.preventDefault();
    }

    onDropQuadrant = async (e, id, top, callback) => {
        if (!this.state.draggingOverQuadrant && this.state.draggingItem) return;

        try {
            await Sig.request(
                'POST',
                `pe/swot/reordenarDragIndexQuadrante`,
                {
                    id: this.state.draggingItem.id,
                    id_quadrante: this.state.draggingOverQuadrant,
                    top: top ? 1 : 0,
                    dragging_over: this.state.draggingOverItem && this.state.draggingOverItem.id ? this.state.draggingOverItem.id : 0,
                }
            );
        } catch (e) {
            toast.error('Erro ao atualizar quadrante');
        }

        callback();
    }

    onDragStartItem = async (e, item) => {
        this.setState({ draggingItem: item, draggingOverItem: null });
        setTimeout(() => { e.target.style.display = "none" }, 10);
    }

    onDragEndItem = async (e, item) => {
        this.setState({ draggingOverQuadrant: null, draggingItem: null, draggingOverItem: null });
    }

    onDragEnterItem = async (e, item) => {
        if (item.id !== this.state.draggingItem.id) {
            this.setState({ draggingOverItem: item });
        }
    }

    renderQuadrant(key) {
        return (
            <SwotQuadrant
                dragControl={{ draggingOverQuadrant: this.state.draggingOverQuadrant, draggingItem: this.state.draggingItem, draggingOverItem: this.state.draggingOverItem }}
                onDragOverQuadrant={this.onDragOverQuadrant}
                onDropQuadrant={this.onDropQuadrant}
                onDragStartItem={this.onDragStartItem}
                onDragEndItem={this.onDragEndItem}
                onDragEnterItem={this.onDragEnterItem}
                name={this.state.quadrants[key].nome}
                id={this.state.quadrants[key].id}
                id_swot={this.props.id}
                orderBy={this.state.selectedOrder}
                gutMaxValue={this.state.gutMaxValue}
                key={key}
            />
        );
    }

    renderSwot() {
        return (
            <div style={{ ...EssentialStyle.columnStart, minWidth: '100%', overflow:'hidden' }}>
                <div style={{ ...EssentialStyle.rowSpaceBetween, flexDirection:  this.state.flipDirectionSmallDevice, minWidth: 'inherit', minHeight: `calc(50vh - 58px)`, maxHeight: `calc(${this.state.flipValuesSmallDevice} - 58px)` }}>
                    {this.renderQuadrant(0)}
                    {this.renderQuadrant(1)}
                </div>
                <div style={{ ...EssentialStyle.rowSpaceBetween, flexDirection: this.state.flipDirectionSmallDevice, minWidth: 'inherit', minHeight: `calc(50vh - 58px)`, maxHeight: `calc(${this.state.flipValuesSmallDevice} - 58px)` }}>
                    {this.renderQuadrant(2)}
                    {this.renderQuadrant(3)}
                </div>
            </div>
        );
    }

    render() {

        if (this.state.showGut && (this.state.acesso == "pe_swot_administrador" || this.state.permissao == "pe_swot_editar" || this.state.isResponsavel)) return <GutMatrix idSwot={this.props.id} gutCallback={this.gutCallback} />
        if (this.state.showHistory) return <SwotHistory idSwot={this.props.id} historyCallback={this.historyCallback} />
        if (this.state.showSettings && (this.state.acesso == "pe_swot_administrador" || this.state.permissao == "pe_swot_editar" || this.state.isResponsavel)) return <EditSwot idSwot={this.props.id} settingsCallback={this.settingsCallback} deleteCallback={() => { this.props.callback() }} />

        return this.state.loading ? <LoadingPage /> : (
            <div style={{ width: 'auto', height: 'auto'}}>
                {this.renderHeader()}
                {this.renderSwot()}
            </div>
        );
    }
}