import React from "react";
import Sig from "../../../../../api/Sig";
import Colors from "../../../../../constants/Colors";
import LayoutHelper from "../../../../../helper/LayoutHelper";
import EssentialStyle from "../../../../../style/EssentialStyle";
import DefaultButton from "../../../../tools/DefaultButton";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink, faSlidersH, faSearch, faCheck, faTimes, faPlusSquare, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import Tabs from "../../../../tools/Tabs";
import Modal from "../../../../tools/Modal";
import DefaultLoader from "../../../../tools/DefaultLoader";
import { Form, InputGroup } from "react-bootstrap";
import { toast } from 'react-toastify';

export default class ModalVinculaTopico extends React.Component {

    state = {
        isSmallScreen: LayoutHelper.isSmallScreen(),
        loading: true,
        planosLimited: [],
        planosLimitedCopy: [],
        activeTab: 'criar',
        tabs: [
            { name: 'criar', title: 'Criar' },
            { name: 'vincular', title: 'Vincular' }
        ],
        textFilter: '',
        filter: {},
        addingVinculo: null,
        page: 1,
        firstLoad: true,
    };

    async componentDidMount() {
        this.setState({ loading: true });
        await this.loadPlanosVinculo();
    }

    async componentWillUnmount() {
        if (document.querySelector('.listPlanos')) {
            document.querySelector('.listPlanos').addEventListener('scroll', this.handleScroll);
        }
    }

    loadPlanosVinculo = async (page = 1) => {
        
        let timeout = null;

        if (this.state.firstLoad) {
            this.setState({ loading: true, firstLoad: false });
        } else {
            timeout = setTimeout(() => {
                this.setState({ loading: true });
            }, 200);
        }

        var req = await Sig.request('POST', 'planoAcao/list', { page, limit: 10, filter: JSON.stringify(this.state.filter), parceiro: 0 });

        if (timeout) {
            clearTimeout(timeout);
        }

        if (req.status != 200) {
            return toast.error("Erro ao carregar dados");
        }

        if(req.data.length < 10) {
            this.setState({ hasMore: false });
        } else {
            this.setState({ hasMore: true });
        }

        this.setState(prevState => ({
            planos: page === 1 ? req.data : [...prevState.planos, ...req.data],
            loading: false,
            page
        }));

    }

    handleTabChange = (tab) => {
        if(this.state.activeTab === tab.name) return;
        this.setState({ activeTab: tab.name }, () => {
            if(this.state.activeTab == 'vincular') {
                if (document.querySelector('.listPlanos')) {
                    document.querySelector('.listPlanos').addEventListener('scroll', this.handleScroll);
                }
            } else {
                if (document.querySelector('.listPlanos')) {
                    document.querySelector('.listPlanos').removeEventListener('scroll', this.handleScroll);
                }
            }
        });
    }

    handleSearch = (text) => {
        this.setState({ textFilter: text });

        let filter = {
            nome: text,
            codigo: text,
        }

        this.setState({ filter }, () => {
            this.loadPlanosVinculo();
        });
    }

    handleScroll = () => {
        const listPlanosDiv = document.querySelector('.listPlanos');
        if (listPlanosDiv) {
            if (listPlanosDiv.scrollTop + listPlanosDiv.clientHeight >= listPlanosDiv.scrollHeight) {
                if (!this.state.loading && this.state.hasMore && this.state.activeTab === 'vincular') {
                    this.loadPlanosVinculo(this.state.page + 1);
                }
            }
        }
    }

    vinculaPlano = async (id) => {

        let id_ata_reuniao = this.props.idAtaReuniao;
        let id_ata_reuniao_topico = this.props.idTopico;

        if(id_ata_reuniao && id_ata_reuniao_topico && id) {

            let response = await Sig.request('POST', 'planoAcao/vinculaTopicoPlano', { id_ata_reuniao, id_ata_reuniao_topico, id_plano: id });

            if(response.vinculo) {
                toast.success('Plano de ação vinculado com sucesso.');
                this.props.closeModal(true);
            }
        } else {
            toast.error('Erro ao vincular plano de ação.');
            this.props.closeModal(false);
        }
    }

    renderContentModal() {
        return (
            <>
                {this.renderTitle()}
                {this.renderBody()}
                {this.renderFooter()}
            </>
        )
    }

    renderTitle() {
        return (
            <div style={{fontSize: 22, fontWeight: 600, color: Colors.homePage.grey, marginBottom: 10}}>
                <FontAwesomeIcon icon={faLink} style={{ marginRight: 10 }} />
                Criar Plano de Ação ou Vincular com já existente?
            </div>
        )
    }

    renderBody() {
        return (
            <>
                {/* Tabs e buscador */}
                <div style={{...EssentialStyle.rowSpaceBetween, gap: 10, width: '100%'}}>
                    <Tabs 
                        tabs={this.state.tabs} 
                        activeTab={this.state.activeTab} 
                        isSmallScreen={this.state.isSmallScreen} 
                        onTabChange={this.handleTabChange}
                        tabStyle={{width: this.state.isSmallScreen ? '32vw' : 90, maxWidth: 100}}
                    />

                    { this.state.activeTab === 'vincular' && this.renderInputSearch() }
                </div>

                {/* Conteúdo */}
                { 
                    <div style={{marginTop: 10, width: '100%'}}>
                        {
                            this.state.activeTab === 'criar' 
                                ?
                            this.renderCriarPlano()
                                :
                            (this.state.addingVinculo != null ? this.renderConfirmarVinculo() : this.renderListPlanos())
                        }
                    </div>
                }
            </>
        )
    }

    renderCriarPlano() {
        return (
            <div style={{...EssentialStyle.rowFlexCenter, width: '100%', height: '12rem'}}>
                <DefaultButton
                    title={'Criar Plano de Ação para o Tópico'}
                    leftIcon={<FontAwesomeIcon icon={faPlusSquare} />}
                    color={Colors.success}
                    textColor={Colors.white}
                    style={{padding: '5px 20px'}}
                    onClick={() => window.parent.postMessage({ type: 'navigate', url: `/planoAcao?tipo=novo&modulo=9&id_ata_reuniao=${this.props.idAtaReuniao}&id_ata_reuniao_topico=${this.props.idTopico}` }, '*')}
                />
            </div>
        )
    }

    selectPlanoVinculo(plano) {

        this.setState({ addingVinculo: plano });
    }

    renderListPlanos() {
        return (
            <div className={'listPlanos'} style={{height: '12rem', overflowY: 'auto'}}>
                {
                    this.state.planos.length ? 
                    <>
                        {this.state.planos.map((plano, index) => {
                            return (
                                <div
                                    key={plano.id}
                                    style={{
                                        ...EssentialStyle.rowSpaceBetween,
                                        width: '100%',
                                        borderBottom: `1px solid ${Colors.homePage.grey}`,
                                        padding: 5,
                                        gap: 5,
                                    }}
                                >
                                    <div style={{...EssentialStyle.rowFlexStart, gap: 5}}>
                                        <div style={{backgroundColor: Colors.homePage.grey, color: Colors.white, padding: '2px 5px', borderRadius: 5}}>{plano.codigo}</div>
                                        {plano.nome}
                                    </div>

                                    <DefaultButton
                                        leftIcon={<FontAwesomeIcon icon={faLink} />}
                                        color={Colors.success}
                                        textColor={Colors.white}
                                        style={{padding: '2px 15px'}}
                                        onClick={() => this.selectPlanoVinculo(plano)}
                                    />
                                </div>
                            )
                        })}
                        {this.renderLoading()}
                    </>
                    :
                    this.renderEmpty()
                }
            </div>
        )
    }

    renderConfirmarVinculo() {

        return (
            <div style={{...EssentialStyle.rowFlexCenter, width: '100%', height: '12rem', gap: 10}}>
                <div style={{...EssentialStyle.columnCenter, width: '85%', gap: 30}}>
                    <span>Confimar vínculo com o plano de ação: {this.state.addingVinculo.nome}?</span>

                    <div style={{...EssentialStyle.rowFlexEnd, width: '100%', gap: 10}}>
                        <DefaultButton
                            leftIcon={<FontAwesomeIcon icon={faCheck} />}
                            color={Colors.success}
                            textColor={Colors.white}
                            title={'Confirmar Vínculo'}
                            onClick={() => this.vinculaPlano(this.state.addingVinculo.id)}
                        />

                        <DefaultButton
                            leftIcon={<FontAwesomeIcon icon={faTimes} />}
                            color={Colors.error}
                            textColor={Colors.white}
                            title={'Cancelar'}
                            onClick={() => this.setState({addingVinculo: null})}
                        />
                    </div>
                </div>
            </div>
        )
    }

    renderFooter() {
        return (

            <div style={{...EssentialStyle.rowFlexEnd, width: '100%', marginTop: 10}}>
                <DefaultButton 
                    title={'Fechar'}
                    color={Colors.dark}
                    textColor={Colors.white}
                    onClick={() => this.props.closeModal(false)}
                />
            </div>
        )
    }

    renderInputSearch() {
        
        let searchIconStyle = { borderRight: 'none', color: Colors.disabled, width: 40 };

        return (
            <InputGroup>
                <InputGroup.Text style={searchIconStyle}>
                    <FontAwesomeIcon
                        icon={faSearch}
                    />
                </InputGroup.Text>
                <Form.Control type="text"
                    style={{ borderLeft: 'none' }}
                    placeholder={"Buscar..."}
                    onChange={(event) => { this.handleSearch(event.target.value) }}
                    value={this.state.textFilter}
                />
            </InputGroup>
        )
    }

    renderEmpty() {
        return (
            <div style={{...EssentialStyle.columnCenter, width: '100%', height: '12rem'}}>
                <div style={{fontSize: 18, color: Colors.homePage.grey}}>
                    Nenhum plano de ação disponível para vincular.
                </div>
            </div>
        )
    }

    renderLoading() {

        if (!this.state.loading) return <></>;
        return (
            <div style={{ ...EssentialStyle.columnCenter, width: '100%', height: 'calc(4rem)' }}>
                <DefaultLoader />
            </div>
        )
    }

    render() {
        return (
            <Modal style={{width: this.state.isSmallScreen ? '95vw' : '80vw', transition: 'height 0.5s ease'}}>
                {this.renderContentModal()}
            </Modal>
        )
    }
}