import React from "react";
import '../PlanoAcaoRelatorio.css';

import { debounce } from 'lodash';

import EssentialStyle from "../../../../../style/EssentialStyle";
import Colors from "../../../../../constants/Colors";
import DefaultLoader from "../../../../tools/DefaultLoader";
import PlanoAcaoCardRelatorio from "../PlanoAcaoCardRelatorio";
import Sig from "../../../../../api/Sig";
import { toast } from "react-toastify";
import PlanoAcaoHelper from "../../../../../helper/planoAcao/PlanoAcaoHelper";
import DefaultButton from "../../../../tools/DefaultButton";
export default class PlanoAcaoRelatorioLista extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            page: 1,
            hasMore: false,
            loading: true,
            requesting: false,
            filter: {},
            firstLoad: true,
            partialLoading: false,
            showLoadMoreButton: false
        }
        this.abortController = new AbortController();
        this.handleScrollDebounced = debounce(this.handleScroll, 100);
        this.loadMoreButtonTimer = null;
    }
    

    async componentDidMount() {
        if (document.querySelector('.relatorio-pa > div')) {
            document.querySelector('.relatorio-pa > div').addEventListener('scroll', this.handleScrollDebounced);
        }

        this.loadData(1);
    }

    componentDidUpdate(prevProps, prevState) {
        if (document.querySelector('.relatorio-pa > div')) {
            let listPlanosRelatorio = document.querySelector('.relatorio-pa > div');

            if (listPlanosRelatorio) {
                listPlanosRelatorio.removeEventListener('scroll', this.handleScrollDebounced);
                listPlanosRelatorio.addEventListener('scroll', this.handleScrollDebounced);
            }
        }

        if (prevProps.filter !== this.props.filter) {
            if(this.state.requesting) {
                this.abortController.abort();
                this.abortController = new AbortController();
            }

            this.setState({ 
                data: [],
                loading: true,
                requesting: false,
                firstLoad: true
            }, () => {
                this.loadData();
            });
        }
    }

    componentWillUnmount() {
        if (document.querySelector('.relatorio-pa > div')) {
            document.querySelector('.relatorio-pa > div').removeEventListener('scroll', this.handleScrollDebounced);
        }
        this.abortController.abort();
        if(this.loadMoreButtonTimer) clearTimeout(this.loadMoreButtonTimer);
    }

    handleScroll = () => {
        const scrollableDiv = document.querySelector('.relatorio-pa > div');

        if (scrollableDiv) {
            const threshold = 100;
            if (scrollableDiv.scrollTop + scrollableDiv.clientHeight >= scrollableDiv.scrollHeight - threshold) {
                if (!this.state.loading && !this.state.requesting && this.state.hasMore) {
                    this.loadData(this.state.page + 1);
                }
            }
        }
    }

    async loadData(page = 1) {
        let timeout = null;

        if (this.state.requesting) return;
        this.setState({ requesting: true, partialLoading: true, showLoadMoreButton: false });

        var req = await Sig.request(
            'POST', 
            'planoAcao/list', 
            { 
                page, 
                limit: 10, 
                filter: JSON.stringify(this.props.filter),
                parceiro: this.props.parceiro ? 1 : 0, 
                etapas: 1,
                id_periodo_avaliacao: this.props.id_periodo_avaliacao || 0
            },
            Sig.getDefaultContentType(),
            Sig.getDefaultTransformTo(),
            this.abortController.signal
        );

        if (this.state.firstLoad) {
            this.setState({ loading: true, firstLoad: false });
        } else {
            timeout = setTimeout(() => {
                this.setState({ loading: true });
            }, 200);
        }

        if (timeout) {
            clearTimeout(timeout);
        }

        if (req.status != 200) {
            this.setState({ loading: false, requesting: false });
            return toast.error("Erro ao carregar dados");
        }

        if (req.data.length < 10)
            this.setState({ hasMore: false });
        else
            this.setState({ hasMore: true });

        this.setState(prevState => ({
            data: page === 1 ? req.data : [...prevState.data, ...req.data],
            page,
            loading: false
        }));

        this.setState({ requesting: false }, () => {
            if(this.loadMoreButtonTimer) clearTimeout(this.loadMoreButtonTimer);

            this.loadMoreButtonTimer = setTimeout(() => {
                this.setState({ showLoadMoreButton: true });
            }, 2000);
        });
    }

    renderPlanoAcaoCard(item, index) {
        return (
            <div style={{ marginBottom: 6, padding: 2 }}>
                <PlanoAcaoCardRelatorio 
                    filter={this.props.filter}
                    expanded={PlanoAcaoHelper.getPlanoShouldBeExpanded(this.props.filter)} 
                    key={`pa-card-relatorio-${item?.codigo}`} 
                    plano={item}
                    displayEtapas={true}
                    onClick={(codigo) => window.parent.postMessage({ type: '_blank', url: `/planoAcao/detalhar/${codigo}` }, '*')} 
                    isSmallScreen={this.props.isSmallScreen} 
                    noMargin 
                />
            </div>
        );
    }

    renderEmpty = () => {
        return (
            <div style={{ ...EssentialStyle.columnCenter, ...EssentialStyle.card, padding: 10, width: '100%', marginTop: 10, height: "calc(100% - 40px)" }}>
                <h2 style={{ color: Colors.homePage.grey, fontSize: 20, fontWeight: 500, textAlign: 'center' }}>Nenhum plano de ação encontrado</h2>
            </div>
        );
    }

    renderLoadingPagination = () => {
        if(this.state.requesting) {
            return (
                <div style={{ ...EssentialStyle.columnCenter, padding: 10, width: '100%', marginTop: 10 }}>
                    <DefaultLoader />
                </div>
            );
        }

        if(this.state.hasMore && this.state.showLoadMoreButton) {
            return (
                <div style={{ ...EssentialStyle.columnCenter, padding: 10, width: '100%', marginTop: 10 }}>
                    <DefaultButton 
                        title="Carregar mais"
                        onClick={() => this.loadData(this.state.page + 1)}
                        color={'transparent'}
                        textColor={Colors.homePage.grey}
                        style={{ border: `1px solid ${Colors.homePage.grey}` }}
                        loading={this.state.loading}
                    />
                </div>
            );
        }
    }

    renderList() {
        if (this.state.data.length === 0 && !this.state.loading) return this.renderEmpty();

        return (
            <div style={{ width: "100%" }}>
                {this.state.data.map((item, index) => this.renderPlanoAcaoCard(item, index))}
                {this.renderLoadingPagination()}
                {this.state.isSmallScreen && <div style={{ height: '7rem', width: '100%', marginTop: 10 }} />}
            </div>
        );
    }

    render() {
        return this.renderList();
    }
}