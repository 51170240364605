import Sig from "../../api/Sig";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartColumn, faFont, faTableCells, faImage, faImages, faGripLines, faCode, faClock, faCheckCircle, faExclamationTriangle, faBuilding, faUserCheck, faStopwatch, faCertificate, faLineChart, faTable } from "@fortawesome/free-solid-svg-icons";
import Colors from "../../constants/Colors";
import EssentialStyle from "../../style/EssentialStyle";
import Constants from "../../constants/Api";
import IdiomaHelper from "../IdiomaHelper";
import CacheHelper from "../CacheHelper";
import CustomTooltip from "../../components/tools/CustomTooltip";
import LoadingPage from "../../pages/LoadingPage";
const gateway = Constants.getSigEndPoint();


export default class DiagnosticoHelper {

    constructor() {
        this._idPeriodoAvaliacao = false;
    }

    // await this.props.i18n.loadNamespaces(['client_src_helper_diagnostico_DiagnosticoHelper']);

    // ========== Funções de Apoio ==========

    static getTipoTextosOrientacao = (t) => {
        return [
            { id: 0, name: 'orientacao', title: t('client_src_helper_diagnostico_DiagnosticoHelper:orientacao') },
            { id: 1, name: 'apresentacao', title: t('client_src_helper_diagnostico_DiagnosticoHelper:apresentacao') },
            { id: 2, name: 'conclusao', title: t('client_src_helper_diagnostico_DiagnosticoHelper:conclusao') },
        ]
    }

    static getTipoTextosOrientacaoByName = (name, t) => {
        let tipos = this.getTipoTextosOrientacao(t);
        return tipos.find(t => t.name == name)?.id || 0;
    }

    static getModulosModeloRelatorio = (t) => {
        const moduleTypes = [
            { id: 1, title: t('client_src_helper_diagnostico_DiagnosticoHelper:chart'), type: 'chart', icon: <FontAwesomeIcon icon={faChartColumn} style={{fontSize: '2rem'}}/> },
            { id: 2, title: t('client_src_helper_diagnostico_DiagnosticoHelper:results'), type: 'results', icon: <FontAwesomeIcon icon={faTableCells} style={{fontSize: '2rem'}}/> },
            { id: 3, title: t('client_src_helper_diagnostico_DiagnosticoHelper:performance'), type: 'performance', icon: <FontAwesomeIcon icon={faLineChart} style={{fontSize: '2rem'}}/> },
            { id: 4, title: t('client_src_helper_diagnostico_DiagnosticoHelper:performanceTable'), type: 'performanceTable', icon: <FontAwesomeIcon icon={faTable} style={{fontSize: '2rem'}}/> },
            { id: 5, title: t('client_src_helper_diagnostico_DiagnosticoHelper:text'), type: 'text', icon: <FontAwesomeIcon icon={faFont} style={{fontSize: '2rem'}}/> },
            { id: 6, title: t('client_src_helper_diagnostico_DiagnosticoHelper:divider'), type: 'divider', icon: <FontAwesomeIcon icon={faGripLines} style={{fontSize: '2rem'}}/> },
            // { id: 7, title: t('client_src_helper_diagnostico_DiagnosticoHelper:code'), type: 'code', icon: <FontAwesomeIcon icon={faCode} style={{fontSize: '2rem'}}/> },
            // { id: 8, title: t('client_src_helper_diagnostico_DiagnosticoHelper:images'), type: 'images', icon: <FontAwesomeIcon icon={faImages} style={{fontSize: '2rem'}}/> },
        ];

        return moduleTypes;
    }

    static getFunctionsModulosModeloRelatorioByType = (type, t) => {
        return {
            title: t(`client_src_helper_diagnostico_DiagnosticoHelper:${type}Title`),
        }
    }

    static getOptionsModeloRelatorio = (optionsName, t) => {

        switch (optionsName) {
            case 'formatTables':
                return [
                    { value: 'coluna', label: t('client_src_helper_diagnostico_DiagnosticoHelper:colunaOption') },
                    { value: 'linha', label: t('client_src_helper_diagnostico_DiagnosticoHelper:linhaOption')  },
                ];
            case 'typesCalc':
                return [
                    { value: 'moda', label: t('client_src_helper_diagnostico_DiagnosticoHelper:modaOption')  },
                    { value: 'media', label: t('client_src_helper_diagnostico_DiagnosticoHelper:mediaOption')  },
                    { value: 'operacao', label: t('client_src_helper_diagnostico_DiagnosticoHelper:operacaoOption')  },
                ];
            case 'typesVisualizacao':
                return [
                    { value: 'realizado', label: t('client_src_helper_diagnostico_DiagnosticoHelper:realizadoOption')  },
                    { value: 'performance', label: t('client_src_helper_diagnostico_DiagnosticoHelper:performanceOption')  },
                ];
            case 'chartTypes':
                return [
                    { value: 'donut', label: 'Donuts' },
                    { value: 'gauge', label: 'Gauge' },
                ];
            default:
                return [];
        }
    }

    static getDefaultOptionModeloRelatorio = (optionsName, t) => {

        switch (optionsName) {
            case 'formatTables':
                return { value: 'coluna', label: t('client_src_helper_diagnostico_DiagnosticoHelper:colunaOption') };
            case 'typesCalc':
                return { value: 'operacao', label: t('client_src_helper_diagnostico_DiagnosticoHelper:operacaoOption') };
            case 'typesVisualizacao':
                return { value: 'realizado', label: t('client_src_helper_diagnostico_DiagnosticoHelper:realizadoOption') };
            case 'chartTypes':
                return { value: 'donut', label: 'Donuts' };    
            default:
                return [];
        }
    }

    static getWidth = (width, transformToInt = false) => {
        const widthMap = {
            1: '25%',
            2: '33.33%',
            3: '50%',
            4: '66.66%',
            5: '75%',
            6: '100%',
        };

        if(transformToInt) return Number(widthMap[width].replace('%', ''));
        return widthMap[width];
        
    }

    static getWidthToForm = (width, isSmallScreen) => {
        const widthMap = {
            1: 3,
            2: 4,
            3: 6,
            4: 8,
            5: 9,
            6: 12,
        };

        return isSmallScreen ? 'w-100' : widthMap[width] || 'w-100';
        
    }

    static selectWidthOptions = () => {
        return [
            { id: 1, largura: '25%' },
            { id: 2, largura: '33%' },
            { id: 3, largura: '50%' },
            { id: 4, largura: '66%' },
            { id: 5, largura: '75%' },
            { id: 6, largura: '100%' },
        ];
    }

    static getPeriodoAvaliacaoLocal = (idProcesso) => {

        if(!this._idPeriodoAvaliacao) {
            this._idPeriodoAvaliacao = localStorage.getItem(`idPeriodoAvaliacao-processo-${idProcesso}`) || false;
        };

        return this._idPeriodoAvaliacao;
    }

    static setPeriodoAvaliacaoLocal = async (idPeriodoAvaliacao, idProcesso) => {

        this._idPeriodoAvaliacao = idPeriodoAvaliacao;
        localStorage.setItem(`idPeriodoAvaliacao-processo-${idProcesso}`, idPeriodoAvaliacao);

    }
        
    // ========== Funções Ajax ==========

    // -> Processos

    static getProcessos = async (relations = 0) => {
        const response = await Sig.request("GET", "/diagnostico/processo/getProcessos", { relations: relations });
        return response;
    }

    static getProcesso = async (idProcesso, relations = 0) => {
        const response = await Sig.request("GET", "/diagnostico/processo/getProcesso", { id: idProcesso, relations: relations});
        return response.processo;
    }

    static getInfosCriarProcesso = async (idProcesso) => {
        if (idProcesso === true) idProcesso = null;
        const response = await Sig.request("GET", "/diagnostico/processo/getInfosCriarProcesso", { idProcesso });
        return response;
    }

    static saveProcesso = async (processo) => {
        processo = JSON.stringify(processo);
        const response = await Sig.request("POST", "/diagnostico/processo/saveProcesso", { processo });
        return response.processo;
    }

    static deleteProcesso = async (idProcesso) => {
        const response = await Sig.request("POST", "/diagnostico/processo/deleteProcesso", { id: idProcesso });
        return response.processoExcluido;
    }

    static revisarTextoProcesso = async (texto) => {
        const response = await Sig.request("POST", "/diagnostico/processo/revisarTextoProcesso", { texto });
        return response.texto;
    }

    static isEmpresaAvaliadora = async (idProcesso) => {
        const response = await Sig.request("GET", "/diagnostico/processo/getIsEmpresaAvaliadora", { id: idProcesso });
        return response.isEmpresaAvaliadora;
    }

    // -> Período de Avaliação

    static getPeriodosAvaliacao = async (idProcesso, relations = 0) => {
        const response = await Sig.request("GET", "/diagnostico/periodoAvaliacao/getPeriodosAvaliacao", { idProcesso, relations: relations });
        return response;
    }

    static getPeriodoAvaliacao = async (idPeriodoAvaliacao, relations = 0) => {
        const response = await Sig.request("GET", "/diagnostico/periodoAvaliacao/getPeriodoAvaliacao", { idPeriodoAvaliacao, relations: relations });
        return response.periodos;
    }

    static getInfosAdicionaisPeriodoAvaliacao = async (idPeriodoAvaliacao) => {
        const response = await Sig.request("GET", "/diagnostico/periodoAvaliacao/getInfosAdicionaisPeriodoAvaliacao", { idPeriodoAvaliacao });
        return response;
    }

    static getInfosCriarPeriodoAvaliacao = async (idProcesso, idPeriodo) => {
        if (idPeriodo === true) idPeriodo = null;
        const response = await Sig.request("GET", "/diagnostico/periodoAvaliacao/getInfosCriarPeriodoAvaliacao", { idProcesso, idPeriodo });
        return response;
    }

    static getColorsPeriodoAvaliacao = async (idPeriodoAvaliacao) => {
        let response = await Sig.request("GET", "/diagnostico/periodoAvaliacao/getColorsPeriodoAvaliacao", { idPeriodoAvaliacao });
        return response.colors;
    }

    static getFaixasPeriodo = async (idPeriodoAvaliacao) => {
        let response = await Sig.request("GET", "/diagnostico/periodoAvaliacao/getFaixasPeriodo", { idPeriodoAvaliacao });
        return response;
    }

    static savePeriodoAvaliacao = async (idProcesso, periodoAvaliacao) => {
        periodoAvaliacao = JSON.stringify(periodoAvaliacao);
        const response = await Sig.request("POST", "/diagnostico/periodoAvaliacao/savePeriodoAvaliacao", { idProcesso, periodoAvaliacao });
        return response.periodoAvaliacao;
    }

    static deletePeriodoAvaliacao = async (idPeriodoAvaliacao) => {
        const response = await Sig.request("POST", "/diagnostico/periodoAvaliacao/deletePeriodoAvaliacao", { id: idPeriodoAvaliacao });
        return response.periodoAvaliacaoExcluido;
    }

    // -> Relatório

    static getRelatorios = async (idPeriodoAvaliacao, textFilter = null, status = 1, avaliador, avaliado, relations = 1, lang = 1, page = 1, limit = 10) => {
        let response = await Sig.request("GET", "/diagnostico/relatorio/getRelatorios", { idPeriodoAvaliacao, textFilter,  status, avaliador, avaliado, relations, lang: IdiomaHelper.getSiglaId(lang), page, limit });
        return response.relatorios;
    }

    static getRelatorio = async (idRelatorio, relations = 1, lang = IdiomaHelper.getIdioma()) => {
        if (lang) lang = IdiomaHelper.getSiglaId(lang);
        let response = await Sig.request("GET", "/diagnostico/relatorio/getRelatorio", { idRelatorio, relations, lang });
        return response.relatorio;
    }

    static getNumRelatorios = async (idPeriodoAvaliacao, avaliador, avaliado, relations = 1, lang = IdiomaHelper.getIdioma()) => {
        if (lang) lang = IdiomaHelper.getSiglaId(lang);
        let response = await Sig.request("GET", "/diagnostico/relatorio/getNumRelatorios", { idPeriodoAvaliacao, avaliador, avaliado, relations, lang });
        return response;
    }

    static getPlanosAcaoByRecomendacao = async (idRecomendacao) => {
        let response = await Sig.request("GET", "/diagnostico/relatorio/getPlanosAcaoByRecomendacao", { idRecomendacao });
        return response.planos;
    }

    static getResultadoGrafico = async (id, data_processo) => {
        let response = await Sig.request("POST", "/diagnostico/relatorio/getResultadoGrafico", { id, data_processo });
        return response;
    }

    static getEstruturaIndicadoresPerformance = async (idItem, data) => {
        let response = await Sig.request("GET", "/diagnostico/relatorio/getEstruturaIndicadoresPerformance", { idItem, data});
        return response;
    }

    static getEstruturaIndicadoresPerformanceTabela = async (idItem, data) => {
        let response = await Sig.request("GET", "/diagnostico/relatorio/getEstruturaIndicadoresPerformanceTabela", {idItem, data});
        return response;
    }

    static getEstruturaTabelaResultados = async (idItem, data_processo = null) => {
        let response = await Sig.request("GET", "/diagnostico/relatorio/getEstruturaTabelaResultados", { idItem, data_processo, idIdioma: IdiomaHelper.getSiglaId(IdiomaHelper.getIdioma()) });
        return response;
    }

    static getInfosAddRelatorio = async (idPeriodoAvaliacao) => {
        const response = await Sig.request("GET", "/diagnostico/relatorio/getInfosAddRelatorio", { idPeriodoAvaliacao });
        return response;
    }

    static updateGrupoFaixaPerformance = async (grupoId, faixaId, idItem) => {
        const response = await Sig.request("GET", "/diagnostico/relatorio/updateGrupoFaixaPerformance", { grupoId, faixaId, idItem });
        return response;
    }

    static updateGrupoFaixaPerformanceTabela = async (grupoId, faixaId, idItem) => {
        const response = await Sig.request("GET", "/diagnostico/relatorio/updateGrupoFaixaPerformanceTabela", { grupoId, faixaId, idItem });
        return response;
    }

    static saveTextGrupoTextText = async (idGrupoTexto, lang, value) => {
        lang = IdiomaHelper.getSiglaId(lang);
        const response = await Sig.request("POST", "/diagnostico/relatorio/saveTextGrupoTextText", { idGrupoTexto, lang, value });
        return response.grupoTexto;
    }

    static saveRecomendacao = async (recomendacao) => {
        recomendacao = JSON.stringify(recomendacao);
        const response = await Sig.request("POST", "/diagnostico/relatorio/saveRecomendacao", { recomendacao });
        return response.recomendacao;
    }

    static saveObservation = async (observacao) => {
        observacao = JSON.stringify(observacao);
        const response = await Sig.request("POST", "/diagnostico/relatorio/saveObservation", { observacao });
        return response.observacao;
    }

    static editRecomendation = async (recomendacao) => {
        recomendacao = JSON.stringify(recomendacao);
        const response = await Sig.request("POST", "/diagnostico/relatorio/editRecomendation", { recomendacao });
        return response.recomendacao;
    }

    static editObservation = async (observacao) => {
        observacao = JSON.stringify(observacao);
        const response = await Sig.request("POST", "/diagnostico/relatorio/editObservation", { observacao });
        return response.observacao;
    }

    static deleteRecomendacao = async (idRecomendacao) => {
        const response = await Sig.request("POST", "/diagnostico/relatorio/deleteRecomendacao", { id: idRecomendacao });
        return response;
    }

    static deleteObservation = async (idObservacao) => {
        const response = await Sig.request("POST", "/diagnostico/relatorio/deleteObservation", { id: idObservacao });
        return response;
    }

    static deleteRelatorio = async (idRelatorio) => {
        const response = await Sig.request("POST", "/diagnostico/relatorio/deleteRelatorio", { id: idRelatorio });
        return response.relatorioExcluido;
    }

    static publishRelatorio = async (idRelatorio) => {
        const response = await Sig.request("POST", "/diagnostico/relatorio/publishRelatorio", { id: idRelatorio });
        return response;
    }

    static getModelosRelatorio = async (idPeriodoAvaliacao, lang = IdiomaHelper.getIdioma()) => {
        if (lang) lang = IdiomaHelper.getSiglaId(lang);
        const response = await Sig.request("GET", "/diagnostico/relatorio/getModelosRelatorio", { idPeriodoAvaliacao, lang });
        return response.modelos;
    }

    static saveModeloRelatorio = async (modelo, idPeriodoAvaliacao) => {
        const response = await Sig.request("POST", "/diagnostico/relatorio/saveModeloRelatorio", { modelo: JSON.stringify(modelo), idPeriodoAvaliacao });
        return response.modelo;
    }

    static deleteModeloRelatorio = async (idModeloRelatorio) => {
        const response = await Sig.request("POST", "/diagnostico/relatorio/deleteModeloRelatorio", { id: idModeloRelatorio });
        return response;
    }

    static createRelatorioPorModelo = async (idModeloRelatorio, idEmpresas, tipos) => {
        let response = await Sig.request("POST", "/diagnostico/relatorio/createRelatorioPorModelo", { id: idModeloRelatorio, idEmpresas: idEmpresas, tipos });
        return response;
    }

    static getOptionsAgrupamentoIconesTabelaResultado = async () => {
        const response = await Sig.request("GET", "/diagnostico/relatorio/getOptionsAgrupamentoIconesTabelaResultados")
        return response;
    }

    // -> Indicadores

    static getPeriodoAvaliacaoIndicadores = async (idPeriodoAvaliacao) => {
        const response = await Sig.request("GET", "/diagnostico/indicadores/getPeriodoAvaliacaoIndicadores", { idPeriodoAvaliacao });
        return response.periodoAvaliacao;
    }

    static getPeriodoAvaliacaoIndicador = async (idIndicadorReferenciaCard) => {
        const response = await Sig.request("GET", "/diagnostico/indicadores/getPeriodoAvaliacaoIndicador", { idIndicadorReferenciaCard });
        return response.periodoIndicadorReferencia;
    }

    static getPeriodoAvaliacaoGrupoIndicadores = async (idPeriodoAvaliacaoGrupoIndicadores) => {
        const response = await Sig.request("GET", "/diagnostico/indicadores/getPeriodoAvaliacaoGrupoIndicadores", { idPeriodoAvaliacaoGrupoIndicadores });
        return response.periodoAvaliacaoGrupoIndicadores;
    }

    static getIndicadoresPerformance = async (idPeriodoAvaliacao) => {
        const response = await Sig.request("GET", "/diagnostico/indicadores/getIndicadoresPerformance", { idPeriodoAvaliacao });
        return response;
    }

    static saveIndicadorPeriodo = async (idPeriodoAvaliacao, idIndicador) => {
        if (!idPeriodoAvaliacao || !idIndicador) return false;

        const response = await Sig.request("POST", "/diagnostico/indicadores/saveIndicadorPeriodo", { idPeriodoAvaliacao, idIndicador });
        return response.periodoAvaliacao;
    }

    static saveIndicadorEquivalentePeriodo = async (idIndicadorReferecia, idEmpresa, idIndicador, idPainel) => {
        if (!idIndicadorReferecia || !idEmpresa) return false;

        const response = await Sig.request("POST", "/diagnostico/indicadores/saveIndicadorEquivalentePeriodo", { idIndicadorReferecia, idEmpresa, idIndicador, idPainel });
        return response.periodoAvaliacao;
    }

    static saveIndicadorConfig = async (idIndicador, acumulado, faixas, meta) => {
        const response = await Sig.request("POST", "/diagnostico/indicadores/saveIndicadorConfig", { idIndicador, acumulado, faixas, meta });
        return response.periodoAvaliacao;
    }

    static savePeriodoGrupoIndicadores = async (grupo) => {
        grupo = JSON.stringify(grupo);
        const response = await Sig.request("POST", "/diagnostico/indicadores/savePeriodoGrupoIndicadores", { grupo });
        return response.grupo;
    }

    static saveIndicadorPeriodoGrupo = async (idPeriodoAvaliacao, idGrupo, idIndicadorReferencia) => {
        const response = await Sig.request("POST", "/diagnostico/indicadores/saveIndicadorPeriodoGrupo", { idPeriodoAvaliacao, idGrupo, idIndicadorReferencia });
        return response.periodoAvaliacao;
    }

    static deleteIndicadorPeriodo = async (idIndicadorPeriodo) => {
        const response = await Sig.request("POST", "/diagnostico/indicadores/deleteIndicadorPeriodo", { id: idIndicadorPeriodo });
        return response.periodoAvaliacao;
    }

    static deleteVinculoIndicadorPeriodo = async (idIndicadorPeriodo, idGrupo) => {
        const response = await Sig.request("POST", "/diagnostico/indicadores/deleteVinculoIndicadorPeriodo", { id: idIndicadorPeriodo, idGrupo });
        return response.periodoAvaliacao;
    }

    static deletePeriodoGrupoIndicadores = async (idGrupo) => {
        const response = await Sig.request("POST", "/diagnostico/indicadores/deletePeriodoGrupoIndicadores", { id: idGrupo });
        return response?.response == true ? true : false;
    }

    // -> Questionário

    static getQuestionarios = async (idPeriodoAvaliacao, relations = 0) => {
        const response = await Sig.request("GET", "/diagnostico/questionario/getQuestionarios", { idPeriodoAvaliacao, relations });
        return response;
    }

    static getQuestionario = async (idQuestionario, idPeriodoAvaliacao, relations = 0) => {
        const response = await Sig.request("GET", "/diagnostico/questionario/getQuestionario", { id: idQuestionario, idPeriodoAvaliacao, relations });
        return response.questionario;
    }

    static getColunasQuestionario = async (idQuestionario = null, idPeriodoAvaliacao = null, lang = IdiomaHelper.getIdioma(), relations = 1) => {
        if (lang) lang = IdiomaHelper.getSiglaId(lang);
        const response = await Sig.request("GET", "/diagnostico/questionario/getColunasQuestionario", { idQuestionario, idPeriodoAvaliacao, lang, relations });
        return response;
    }

    static getColunasQuestionarioForRelatorio = async (idRelatorio, lang = IdiomaHelper.getIdioma()) => {
        if (lang) lang = IdiomaHelper.getSiglaId(lang);
        const response = await Sig.request("GET", "/diagnostico/questionario/getColunasQuestionarioForRelatorio", { idRelatorio, lang });
        return response;
    }
    
    static getColunasQuestionarioDiagnosticoAvaliacao = async (idDiagnosticoAvaliacao, lang = IdiomaHelper.getIdioma()) => {
        if (lang) lang = IdiomaHelper.getSiglaId(lang);
        const response = await Sig.request("GET", "/diagnostico/questionario/getColunasQuestionarioDiagnosticoAvaliacao", { idDiagnosticoAvaliacao, lang });
        return response.colunas;
    }
    
    static getItensColuna = async (idColuna, lang = IdiomaHelper.getIdioma(), relations = 0) => {
        if (lang) lang = IdiomaHelper.getSiglaId(lang);
        const response = await Sig.request("GET", "/diagnostico/questionario/getItensColuna", { idColuna, lang, relations });
        return response;
    }

    static getItensColunaPai = async (idColuna, lang = IdiomaHelper.getIdioma()) => {
        if (lang) lang = IdiomaHelper.getSiglaId(lang);
        const response = await Sig.request("GET", "/diagnostico/questionario/getItensColunaPai", { idColuna, lang });
        return response.itens;
    }

    static getModeloAvaliacaoRespostas = async (avaliacoes) => {
        avaliacoes = JSON.stringify(avaliacoes);
        const response = await Sig.request("POST", "/diagnostico/questionario/getModeloAvaliacaoRespostas", { avaliacoes });
        return response;
    }

    static getQuestionarioModelo = async (idQuestionario) => {
        const response = await Sig.request("GET", "/diagnostico/questionario/getQuestionarioModelo", { idQuestionario });
        return response.modelo;
    }

    static importQuestionario = async (lote, idQuestionario) => {
        lote = JSON.stringify(lote);
        const response = await Sig.request("POST", "/diagnostico/questionario/importQuestionario", { lote, idQuestionario });
        return response;
    }

    static setQuestionarioNome = async (idDiagnosticoPeriodo = null, idQuestionario = null, nome) => {
        const response = await Sig.request("POST", "/diagnostico/questionario/setQuestionarioNome", { idDiagnosticoPeriodo, idQuestionario, nome });
        return response;
    }

    static setQuestionarioEscala = async (idQuestionario, escalas, idDiagnosticoPeriodo = null) => {
        escalas = JSON.stringify(escalas);
        const response = await Sig.request("POST", "/diagnostico/questionario/setQuestionarioEscala", { idQuestionario, escalas, idDiagnosticoPeriodo });
        return response.questionario;
    }

    static setQuestionarioEstrutura = async (idQuestionario, colunas, idDiagnosticoPeriodo = null) => {
        colunas = JSON.stringify(colunas);
        const response = await Sig.request("POST", "/diagnostico/questionario/setQuestionarioEstrutura", { idQuestionario, colunas, idDiagnosticoPeriodo });
        return response.questionario;
    }

    static setNodeQuestionario = async (idQuestionario, node) => {
        node = JSON.stringify(node);
        const response = await Sig.request("POST", "/diagnostico/questionario/setNodeQuestionario", { idQuestionario, node });
        return response;
    }

    static deleteQuestionario = async (idQuestionario) => {
        const response = await Sig.request("POST", "/diagnostico/questionario/deleteQuestionario", { id: idQuestionario });
        return response.questionarioExcluido;
    }

    static deleteNodeQuestionario = async (idNode) => {
        const response = await Sig.request("POST", "/diagnostico/questionario/deleteNodeQuestionario", { id: idNode });
        return response;
    }

    // -> Grade de Avaliação

    static getGradeAvaliacao = async (idPeriodoAvaliacao) => {
        const response = await Sig.request("GET", "/diagnostico/gradeAvaliacao/getGradeAvaliacao", { idPeriodoAvaliacao });
        return response;
    }

    static getInfosAddAvaliacao = async (idPeriodoAvaliacao) => {
        const response = await Sig.request("GET", "/diagnostico/gradeAvaliacao/getInfosAddAvaliacao", { idPeriodoAvaliacao });
        return response;
    }

    static getAvaliadoresPossiveisEmpresaFilha = async (idPeriodoAvaliacao) => {
        const response = await Sig.request("GET", "/diagnostico/gradeAvaliacao/getAvaliadoresPossiveisEmpresaFilha", { idPeriodoAvaliacao });
        return response.colaboradores;
    }

    static getTiposAvaliacao = async (idPeriodoAvaliacao) =>{
        const response = await Sig.request("GET", "/diagnostico/gradeAvaliacao/getTiposAvaliacao", { idPeriodoAvaliacao });
        return response.tipos;
    }

    static setAvaliacao = async (idPeriodoAvaliacao, avaliacoes, getGrade = 0) => {
        const response = await Sig.request("POST", "/diagnostico/gradeAvaliacao/setAvaliacao", { idPeriodoAvaliacao, avaliacoes, getGrade });
        return response;
    }

    //getParameter: 0 - retorna nada, 1 - retorna grade, 2 - retorna avaliacao
    static saveAvaliacao = async (avaliacao, getParameter = 0) => {
        avaliacao = JSON.stringify(avaliacao);
        const response = await Sig.request("POST", "/diagnostico/gradeAvaliacao/saveAvaliacao", { avaliacao, getParameter });
        return response;
    }

    static importAvaliacaoRespostas = async (lote, idQuestionario) => {
        lote = JSON.stringify(lote);
        const response = await Sig.request("POST", "/diagnostico/gradeAvaliacao/importAvaliacaoRespostas", { lote, idQuestionario });
        return response;
    }
    
    static getLinkAvaliacaoAvaliador = async (id_avaliacao, id_colaborador) => {
        const response = await Sig.request("GET", "/diagnostico/gradeAvaliacao/getLinkAcessoAvaliador", { id_avaliacao, id_colaborador });
        return response;
    }

    static sendMailAvaliacao = async (id_avaliacao, colaboradores) => {
        const response = await Sig.request("POST", "/diagnostico/gradeAvaliacao/sendEmailAvaliacao", { id_avaliacao, colaboradores: JSON.stringify(colaboradores) });
        return response;
    }

    static sendAvaliacoesPeriodo = async (idPeriodoAvaliacao) => {
        const response = await Sig.request("POST", "/diagnostico/gradeAvaliacao/sendEmailAvaliacoesPeriodo", { id_periodo: idPeriodoAvaliacao });
        return response;
    }

    // -> Avaliação

    static getAvaliacoes = async (status, idPeriodoAvaliacao, relations = 0) => {
        const response = await Sig.request("GET", "/diagnostico/avaliacao/getAvaliacoes", { status, idPeriodoAvaliacao, relations });
        return response.avaliacoes;
    }

    static getAvaliacoesEmpresaAvaliada = async (idPeriodoAvaliacao, relations = 0) => {
        const response = await Sig.request("GET", "/diagnostico/avaliacao/getAvaliacoesEmpresaAvaliada", { idPeriodoAvaliacao, relations });
        return response.avaliacoes;
    }

    static deleteAvaliacao = async (idAvaliacao) => {
        const response = await Sig.request("POST", "/diagnostico/avaliacao/deleteAvaliacao", { id: idAvaliacao });
        return response.avaliacaoExcluida;
    }

    // -> Resultado

    static getHeatmapData = async (idPeriodoAvaliacao, idColuna, idQuestionario, idTipo, lang = IdiomaHelper.getIdioma()) => {
        if (lang) lang = IdiomaHelper.getSiglaId(lang);
        let response = await Sig.request("GET", "/diagnostico/resultado/getHeatmapData", { idPeriodoAvaliacao, idColuna, idQuestionario, idTipo, lang });
        return response;
    }

    static getResultadoAvaliacaoItem = async (idDiagnosticoPeriodoQuestionarioNode, idDiagnosticoAvaliacao, lang = IdiomaHelper.getIdioma()) => {
        if (lang) lang = IdiomaHelper.getSiglaId(lang);
        const response = await Sig.request("GET", "/diagnostico/resultado/getResultadoAvaliacaoItem", { idDiagnosticoPeriodoQuestionarioNode, idDiagnosticoAvaliacao, lang });
        return response.resultado;
    }

    static getResumoNodeQuestionario = async (idNode, idAvaliacao = null, data_processo = null) => {
        const response = await Sig.request("GET", "/diagnostico/resultado/getResumoNodeQuestionario", { idNode, idAvaliacao, data_processo });
        return response;
    }

    static getStarStatus = async(idNode, idAvaliacao) => {
        const response = await Sig.request("GET", "/diagnostico/resultado/getStarStatus", { idNode, idAvaliacao });
        return response;
    }

    static updateStarStatus = async(idNode, idAvaliacao, status) => {
        const response = await Sig.request("POST", "/diagnostico/resultado/updateStarStatus", { idNode, idAvaliacao, status });
        return response;
    }

    // ========== Funções de Utilidade ==========

    static getColor = (colors, media) => {

        colors = colors.sort((a, b) => a.from - b.from);

        let color = colors.find(c => c.from <= media && c.to >= media);
        if (!color) {
            return media < colors[0].from ? colors[0].color : colors[colors.length - 1].color;
        } else {
            return color.color
        }
    }

    static renderPeriodoNotSelected = (t, loading = false) => {
        if (loading) {
            return (
                <div style={{
                    ...EssentialStyle.columnCenter,
                    width: '100%',
                    height: 'calc(100vh - 59px)',
                }}>
                    <LoadingPage /> 
                </div>
            );
        }

        return (
            <div style={{
                ...EssentialStyle.columnCenter,
                width: '100%',
                height: 'calc(100vh - 59px)',
            }}>
                <img
                    src={`${gateway}/img/undraw/undraw_select.svg`}
                    alt={t('client_src_helper_diagnostico_DiagnosticoHelper:altUndrawSelect')}
                    style={{
                        height: '15rem',
                        maxHeight: 250,
                        marginBottom: '1rem',
                    }}
                />
                <span style={{maxWidth: '60vw', marginLeft: 15}}>
                    {t('client_src_helper_diagnostico_DiagnosticoHelper:selectPeriodoToContinue')}
                </span>
            </div>
        );
    }

    static renderNotSelected = (message, lang, t) => {
        
        if (!message) {
            message = t('client_src_helper_diagnostico_DiagnosticoHelper:selectToContinue');
        } else {
            message = message[lang]
        }
        
        return (
            <div style={{
                ...EssentialStyle.columnCenter,
                width: '100%',
                height: '100%',
            }}>
                <img
                    src={`${gateway}/img/undraw/undraw_selected_box.svg`}
                    alt={t('client_src_helper_diagnostico_DiagnosticoHelper:altUndrawSelectedBox')}
                    style={{
                        height: '14rem',
                        maxHeight: 250,
                        marginBottom: '1rem',
                    }}
                />
                <span style={{maxWidth: '60vw', marginLeft: 15}}>
                    {message}
                </span>
            </div>
        );
    }

    static renderEmpty = (text, style = {}, styleImg = {}, t) => {
        return (
            <div style={{
                ...EssentialStyle.columnCenter,
                width: '100%',
                height: '100%',
                ...style,
            }}>
                <img
                    src={`${gateway}/img/undraw/undraw_void.svg`}
                    alt={t('client_src_helper_diagnostico_DiagnosticoHelper:altUndrawVoid')}
                    style={{
                        height: '14rem',
                        maxHeight: 250,
                        marginBottom: '1rem',
                        ...styleImg
                    }}
                />
                <span style={{maxWidth: '60vw', marginLeft: 15}}>
                    {text}
                </span>
            </div>
        );
    }

    static renderEmptySearch = (text, style = {}, styleImg = {}, t) => {
        return (
            <div style={{
                ...EssentialStyle.columnCenter,
                width: '100%',
                height: '100%',
                ...style,
            }}>
                <img
                    src={`${gateway}/img/undraw/undraw_search_engines.svg`}
                    alt={t('client_src_helper_diagnostico_DiagnosticoHelper:altUndrawSearchEngines')}
                    style={{
                        height: '14rem',
                        maxHeight: 250,
                        marginBottom: '1rem',
                        ...styleImg
                    }}
                />
                <span style={{maxWidth: '60vw', marginLeft: 15}}>
                    {text}
                </span>
            </div>
        );
    }

    static renderStatusPeriodoAvaliacao = (periodoAvaliacao, t) => {
        
        let message = { 
            0: t('client_src_helper_diagnostico_DiagnosticoHelper:NãoIniciado'),
            1: t('client_src_helper_diagnostico_DiagnosticoHelper:EmAndamento'),
            2: t('client_src_helper_diagnostico_DiagnosticoHelper:Finalizado'),
            3: t('client_src_helper_diagnostico_DiagnosticoHelper:Atrasado'),
        };

        let color = Colors.diagnostico.periodoAvaliacao.status.color[periodoAvaliacao?.status] ?? Colors.diagnostico.periodoAvaliacao.status.color[0];
        color = periodoAvaliacao?.isAtrasado ? Colors.diagnostico.periodoAvaliacao.status.color[3] : color;

        let backgroundColor = Colors.diagnostico.periodoAvaliacao.status.backgroundColor[periodoAvaliacao?.status] ?? Colors.diagnostico.periodoAvaliacao.status.backgroundColor[0];
        backgroundColor = periodoAvaliacao?.isAtrasado ? Colors.diagnostico.periodoAvaliacao.status.backgroundColor[3] : backgroundColor;

        const tag = () => {
            return (
                <div className="status" style={{color, backgroundColor, gap: 5, borderRadius: 5, fontSize: 12, padding: '5px 10px', fontWeight: 600 }}>
                    <FontAwesomeIcon icon={periodoAvaliacao?.isAtrasado ? faStopwatch : faClock} style={{ color, marginRight: 4 }} />
                    <span>{periodoAvaliacao?.status ? message[periodoAvaliacao?.status] : ' - - '}</span>
                </div>
            )
        }

        if (!periodoAvaliacao?.isAtrasado) return tag();
        if (periodoAvaliacao?.isAtrasado) {
            return (
                <CustomTooltip tooltip={message[3]} style={{cursor: 'default'}}>
                    {tag()}
                </CustomTooltip>
            )
        }
    }

    static renderStatusIndicador = (indicadorPainel, t, style = {}) => {

        let { text, bool } = DiagnosticoHelper.getStatusIndicador(indicadorPainel, t);

        return (
            <CustomTooltip tooltip={text}>
                <FontAwesomeIcon 
                    icon={ bool ? faCheckCircle : faExclamationTriangle }
                    color={ bool ? Colors.success : Colors.danger }
                    style={{...style}}
                />
            </CustomTooltip>
        )
    }

    static getStatusIndicador = (indicadorPainel, t) => {
        const message = { 
            0: t('client_src_helper_diagnostico_DiagnosticoHelper:sucesso'),
            1: t('client_src_helper_diagnostico_DiagnosticoHelper:semEquivalência'),
            2: t('client_src_helper_diagnostico_DiagnosticoHelper:semMeta'),
            3: t('client_src_helper_diagnostico_DiagnosticoHelper:semFaixa'),
        };

        let text = indicadorPainel?.status?.map(status => message[status]).join(', ');

        return {
            bool: indicadorPainel?.status?.length == 0 ? true : false,
            text: text,
        }
    }

    static getStatusAvaliacaoNome = (status, t) => {
        const message = {
            0: t('client_src_helper_diagnostico_DiagnosticoHelper:avaliacaoNãoEnviada'),
            1: t('client_src_helper_diagnostico_DiagnosticoHelper:avaliacaoEnviada'),
            2: t('client_src_helper_diagnostico_DiagnosticoHelper:avaliacaoEmAndamento'),
            3: t('client_src_helper_diagnostico_DiagnosticoHelper:avaliacaoFinalizada'),
            4: t('client_src_helper_diagnostico_DiagnosticoHelper:avaliacaoAvaliaçãoAtrasada'),
        };

        return message[status];
    }

    static renderStatusAvaliacao = (status = null, isAtrasado = null, avaliacao, lang, t) => {
        
        const messageData = {
            0: t('client_src_helper_diagnostico_DiagnosticoHelper:avaliacaoNãoEnviada'),
            1: t('client_src_helper_diagnostico_DiagnosticoHelper:avaliacaoEnviadadata'),
            2: t('client_src_helper_diagnostico_DiagnosticoHelper:avaliacaoEmAndamentodata'),
            3: t('client_src_helper_diagnostico_DiagnosticoHelper:avaliacaoFinalizadadata'),
        };

        const message = {
            0: t('client_src_helper_diagnostico_DiagnosticoHelper:avaliacaoNãoEnviada'),
            1: t('client_src_helper_diagnostico_DiagnosticoHelper:avaliacaoEnviada'),
            2: t('client_src_helper_diagnostico_DiagnosticoHelper:avaliacaoEmAndamento'),
            3: t('client_src_helper_diagnostico_DiagnosticoHelper:avaliacaoFinalizada'),
            4: t('client_src_helper_diagnostico_DiagnosticoHelper:avaliacaoAvaliaçãoAtrasada'),
        };

        if (!status) status = 0;

        let text = "";
        let color = "";
        let backgroundColor = "";

        if (avaliacao) {

            status = avaliacao.status;
            isAtrasado = avaliacao.isAtrasado;

            text = messageData[status];
            
            if (status == 0) {
                color = Colors.diagnostico.avaliacao.status.color[isAtrasado ? 4 : status];
                backgroundColor = Colors.diagnostico.avaliacao.status.backgroundColor[isAtrasado ? 4 : status];
            } else if (status == 1) {
                color = Colors.diagnostico.avaliacao.status.color[isAtrasado ? 4 : status];
                backgroundColor = Colors.diagnostico.avaliacao.status.backgroundColor[isAtrasado ? 4 : status];
                text += moment(avaliacao.data_envio).locale(lang).format('L LT');
            } else if (status == 2) {
                color = Colors.diagnostico.avaliacao.status.color[isAtrasado ? 4 : status];
                backgroundColor = Colors.diagnostico.avaliacao.status.backgroundColor[isAtrasado ? 4 : status];
                text += moment(avaliacao.data_inicio).locale(lang).format('L LT');
            } else if (status == 3) {
                color = Colors.diagnostico.avaliacao.status.color[status];
                backgroundColor = Colors.diagnostico.avaliacao.status.backgroundColor[status];
                text += moment(avaliacao.data_fim).locale(lang).format('L LT');
            }

        } else {
            text = this.getStatusAvaliacaoNome(status, t);
            color = Colors.diagnostico.avaliacao.status.color[isAtrasado && status != 3 ? 4 : status];
            backgroundColor = Colors.diagnostico.avaliacao.status.backgroundColor[isAtrasado && status != 3 ? 4 : status];
        }

        const tag = () => {
            return (
                <div 
                    style={{
                        ...EssentialStyle.rowFlexCenter, 
                        gap: 5, 
                        padding: 5,
                        borderRadius: 5,
                        backgroundColor: backgroundColor,
                        color: color,
                        fontWeight: 'bold',
                        fontSize: '0.7rem',
                        cursor: 'default',
                    }}
                >
                    {text}
                    {isAtrasado && <FontAwesomeIcon icon={faStopwatch} style={{marginLeft: 5}}/>}
                </div>
            )
        }

        if (!isAtrasado) return tag();
        if (isAtrasado) {
            return (
                <CustomTooltip tooltip={message[4]} style={{cursor: 'default'}}>
                    {tag()}
                </CustomTooltip>
            )
        }
    }

    static renderStatusRelatorio = (status, t, style) => {
        
        let message = {
            desenvolvimento: t('client_src_helper_diagnostico_DiagnosticoHelper:desenvolvimento'),
            publicado: t('client_src_helper_diagnostico_DiagnosticoHelper:publicado'),
        };

        return (
            <div
                style={{ 
                    borderRadius: 5, 
                    padding: '5px 10px', 
                    backgroundColor: Colors.planoAcao.status.backgroundColor[status == 0 ? 'Em Desenvolvimento' : 'Concluído'], 
                    color: Colors.planoAcao.status.color[status == 0 ? 'Em Desenvolvimento' : 'Concluído'], 
                    fontSize: 12, 
                    fontWeight: 'bold', 
                    ...style 
                }}
            >
                {status == 0 ? message.desenvolvimento : message.publicado}
            </div>
        )
    }

    static renderDataRelatorio = (date, lang, style) => {
        return (
            <div 
                style={{
                    backgroundColor: Colors.homePage.extraLightGrey, 
                    borderRadius: 5, 
                    padding: '5px 10px',
                    fontSize: 12,
                    fontWeight: 'bold',
                    ...style
                }}
            >
                {moment(date).locale(lang).format('L LT')}
                <FontAwesomeIcon icon={faClock} style={{marginLeft: 5}}/>
            </div>
        );
    }

    static renderTipoAvaliacao = (tipo, t, showAvaliador = false) => {
        
        const message = {
            1: t('client_src_helper_diagnostico_DiagnosticoHelper:Avaliação'),
            2: t('client_src_helper_diagnostico_DiagnosticoHelper:Autoavaliação'),
            tooltip1: t('client_src_helper_diagnostico_DiagnosticoHelper:tooltipAvaliacao'),
            tooltip2: t('client_src_helper_diagnostico_DiagnosticoHelper:tooltipAutoavaliacao'),
        };

        const icon = {
            1: <FontAwesomeIcon icon={faBuilding} />,
            2: <FontAwesomeIcon icon={faUserCheck} />,
        };

        return (
            <CustomTooltip tooltip={message[`tooltip${tipo}`]}>
                <div 
                    style={{
                        ...EssentialStyle.rowFlexCenter, 
                        gap: 5, 
                        padding: 5,
                        borderRadius: 5,
                        backgroundColor: Colors.diagnostico.avaliacao.tipo[tipo],
                        fontWeight: 'bold',
                        color: 'white',
                        fontSize: '0.85rem',
                    }}
                >
                    {icon[tipo]}
                    {message[tipo]}
                </div>
            </CustomTooltip>
        )
    }

    static getNodesRecursively(nodes)
    {
        var children = [];
        nodes.map(node => {
            if(node.childNodes.length > 0) {
                children = [...children, ...this.getNodesRecursively(node.childNodes)];
            }

            children.push(node);
        });

        return children;
    }
}