import React, {forwardRef} from "react";
import EssentialStyle from "../../../../../style/EssentialStyle";
import TextEditor from "../../../../tools/TextEditor/TextEditor";
import DefaultButton from "../../../../tools/DefaultButton";
import Colors from "../../../../../constants/Colors";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faChevronDown, faChevronUp, faClock, faExclamationTriangle, faPencil, faTimes, faCalendar, faClose } from '@fortawesome/free-solid-svg-icons';
import DataHelper from "../../../../../helper/DataHelper";
import Sig from "../../../../../api/Sig";
import PlanoAcaoHelper from "../../../../../helper/planoAcao/PlanoAcaoHelper";
import DatePickerHelper from "../../../../../helper/DatePickerHelper";
import KeyboardHelper from "../../../../../helper/KeyboardHelper";
import DatePicker from "react-datepicker";
import { Form } from "react-bootstrap";
import Select, { components } from 'react-select';
import UserAvatar from "../../../../tools/UserAvatar";
import CustomTooltip from "../../../../tools/CustomTooltip";
import { toast } from "react-toastify";
import moment from "moment";
import { confirmAlert } from "react-confirm-alert";
import CustomConfirm from "../../../../tools/CustomConfirm";


export default class AtaReuniaoTarefaCard extends React.Component {

    state={
        participantes: this.props.participantes || [],
        selectOpen: false,
        prioridade: this.props.tarefa?.prioridade_text ? DataHelper.formatSelectedData(this.props.tarefa, 'prioridade', 'prioridade_text') : { label: 'Baixa', value: 0 },
        selectedUser: this.props.tarefa?.responsavel ? DataHelper.formatSelectedData(this.props.tarefa?.responsavel, 'email', 'nome') : null,
        status: this.props.tarefa?.status || 0,
        data_inicio: this.props.tarefa?.data_inicio ? moment(this.props.tarefa?.data_inicio).toDate() : null,
        data_fim: this.props.tarefa?.data_fim ? moment(this.props.tarefa?.data_fim).toDate() : null,
        descricao: this.props.tarefa?.descricao,
        titleHovered: false,
        editingTitle: false,
        updatingTitle: false,
        prev: {
            descricao: this.props.tarefa?.descricao,
            selectedUser: this.props.tarefa?.responsavel ? DataHelper.formatSelectedData(this.props.tarefa?.responsavel, 'email', 'nome') : null,
            prioridade: this.props.tarefa?.prioridade_text ? DataHelper.formatSelectedData(this.props.tarefa, 'prioridade', 'prioridade_text') : null,
            status: this.props.tarefa?.status || 0,
            data_inicio: this.props.tarefa?.data_inicio ? moment(this.props.tarefa?.data_inicio).toDate() : null,
            data_fim: this.props.tarefa?.data_fim ? moment(this.props.tarefa?.data_fim).toDate() : null,
        }
    }

    componentDidUpdate = (prevProps) => {
        const newDataInicio = this.props.tarefa?.data_inicio ? moment(this.props.tarefa.data_inicio).toDate() : null;
        const newDataFim = this.props.tarefa?.data_fim ? moment(this.props.tarefa.data_fim).toDate() : null;

        if(prevProps.tarefa?.descricao != this.props.tarefa?.descricao) {
            this.setState({
                descricao: this.props.tarefa?.descricao,
                prev: { ...this.state.prev, descricao: this.props.tarefa?.descricao }
            });
        }

        if(prevProps.tarefa?.data_inicio != this.props.tarefa?.data_inicio) {
            this.setState({
                data_inicio: newDataInicio,
                prev: { ...this.state.prev, data_inicio: newDataInicio }
            });
        }
        if(prevProps.tarefa?.data_fim != this.props.tarefa?.data_fim) {
            this.setState({
                data_fim: newDataFim,
                prev: { ...this.state.prev, data_fim: newDataFim }
            });
        }

        if(prevProps.tarefa?.status != this.props.tarefa?.status) {
            this.setState({
                status: this.props.tarefa?.status || 0,
                prev: { ...this.state.prev, status: this.props.tarefa?.status || 0 }
            });
        }

        if(prevProps.tarefa?.prioridade != this.props.tarefa?.prioridade) {
            this.setState({
                prioridade: this.props.tarefa?.prioridade_text ? DataHelper.formatSelectedData(this.props.tarefa, 'prioridade', 'prioridade_text') : null,
                prev: { ...this.state.prev, prioridade: this.props.tarefa?.prioridade_text ? DataHelper.formatSelectedData(this.props.tarefa, 'prioridade', 'prioridade_text') : null }
            });
        }

        if(prevProps.tarefa?.responsavel != this.props.tarefa?.responsavel) {
            this.setState({
                selectedUser: this.props.tarefa?.responsavel ? DataHelper.formatSelectedData(this.props.tarefa?.responsavel, 'email', 'nome') : null,
                prev: { ...this.state.prev, selectedUser: this.props.tarefa?.responsavel ? DataHelper.formatSelectedData(this.props.tarefa?.responsavel, 'email', 'nome') : null }
            });
        }
    }


    getUserFromParticipantes = (email) => {
        return this.state.participantes.find(user => user.email == email);
    }

    renderStatusSelect() {
        let minWidths = [
            120,
            175,
            100,
            100,
        ]

        const DropdownIndicator = (props) => {
            const { selectProps } = props;
            const isMenuOpen = selectProps.menuIsOpen;

            return (
                <components.DropdownIndicator {...props} >
                    <div style={{
                        ...EssentialStyle.rowFlexStart,
                        backgroundColor: PlanoAcaoHelper.getEtapaStatus()[this.state.status].backgroundColor,
                        color: PlanoAcaoHelper.getEtapaStatus()[this.state.status].color,
                        fontSize: 12,
                        fontWeight: 600,
                        paddingLeft: 8,
                        paddingRight: 8,
                        paddingBottom: 2,
                        paddingTop: 2,
                        minWidth: minWidths[this.state.status],
                        borderRadius: 4,
                        cursor: 'pointer'
                    }}>
                        {isMenuOpen ? <FontAwesomeIcon icon={faChevronUp} /> : <FontAwesomeIcon icon={faChevronDown} />}
                        <div style={{ marginRight: 8 }} />
                        {this.state.status > -1 ? PlanoAcaoHelper.getEtapaStatus()[this.state.status].label.toUpperCase() : 'Status'}
                    </div>
                </components.DropdownIndicator>
            );
        };

        return (
            <div
                style={{
                    ...EssentialStyle.rowFlexStart,
                    fontSize: 14
                }}
                onClick={(e) => e.stopPropagation()}
            >
                <Select
                    ref={ref => this.selectStatus = ref}
                    styles={{
                        container: (base, state) => ({
                            ...base,
                            width: 'auto',
                            minWidth: 'max-content',
                        }),
                        control: (base, state) => ({
                            ...base,
                            ...EssentialStyle.titleBoxHomePage,
                            border: 'none',
                            width: '100%',
                            minWidth: '100%',
                            boxShadow: state.isFocused ? 'none' : null,
                            backgroundColor: "transparent",
                            marginRight: 8,
                            justifyContent: "flex-start"
                        }),
                        valueContainer: (base, state) => ({
                            ...base,
                            display: 'contents'
                        }),
                        menu: (base, state) => ({
                            ...base,
                            width: '100%',
                            minWidth: '220px',
                            right: 0,
                            marginRight: 8,
                            margin: 0,
                            borderRadius: 4,
                            boxShadow: '0px 2px 2px 2px  rgba(50, 50, 50, 0.1)',
                        }),
                    }}
                    value={this.state.status}
                    options={PlanoAcaoHelper.getEtapaStatus()}
                    formatOptionLabel={({ label, value }) => (
                        <div style={{
                            ...EssentialStyle.rowFlexStart,
                            paddingLeft: 8,
                            fontWeight: 600,
                            cursor: 'pointer',
                            color: PlanoAcaoHelper.getEtapaStatus()[value].backgroundColor,
                        }}>
                            {label}
                        </div>
                    )}
                    placeholder={''}
                    noOptionsMessage={DataHelper.getSelectEmptyMessage}
                    isSearchable={false}
                    components={{ DropdownIndicator, IndicatorSeparator: null }}
                    onChange={async (value) => {
                        this.setState({
                            status: value.value
                        }, async () => {
                            if (this.selectPeople) this.selectPeople.blur();

                            var response = await Sig.request('POST', `ataReuniao${this.props.hash ? "Externa" : ""}/updateTarefaTopico`, {
                                id: this.props.tarefa.id,
                                hash: this.props.hash,
                                fields: JSON.stringify({
                                    status: value.value
                                })
                            });

                            if(response.status == 200) {
                                this.setState({ prev: { ...this.state.prev, status: value.value } });
                                this.props.updateTarefa(response.tarefa);
                            } else {
                                this.setState({ status: this.state.prev.status });
                            }
                            
                        });
                    }}
                    isDisabled={this.props.noEdit}
                />

            </div>
        );
    }

    renderDatePicker() {
        const CustomPicker = forwardRef(({ value, onClick }, ref) => (
            <div style={{
                ...EssentialStyle.rowFlexCenter,
                fontSize: 14,
                color: Colors.dark,
                fontWeight: 'bold',
                cursor: 'pointer',
                backgroundColor: Colors.secondaryButton,
                padding: 1,
                height: '100%',
                width: 170,
                textAlign: 'center',
                borderRadius: 4,
                paddingLeft: 5,
                marginRight: 8
            }}
            >
                <FontAwesomeIcon icon={faCalendar} />
                <Form.Control
                    onChange={() => { }}
                    onClick={onClick}
                    ref={ref => this.periodoPicker = ref}
                    style={{
                        marginLeft: 2,
                        fontSize: 14,
                        color: Colors.dark,
                        fontWeight: 'bold',
                        cursor: 'pointer',
                        boxShadow: 'none',
                        backgroundColor: Colors.secondaryButton,
                        padding: 1,
                        height: '100%',
                        textAlign: 'center',
                    }}
                    value={value} >
                </Form.Control>
            </div>
        ));

        let dataMoment1 = this.state.data_inicio ? moment(this.state.data_inicio).toDate() : null;
        let dataMoment2 = this.state.data_fim ? moment(this.state.data_fim).toDate() : null;

        return (
            <div onClick={(e) => e.stopPropagation()}>
                <DatePicker
                    wrapperClassName="w-100"
                    portalId="calendar-portal"
                    selected={dataMoment1}
                    startDate={dataMoment1}
                    endDate={dataMoment2}
                    selectsRange
                    onChange={async (dates) => {
                        const [start, end] = dates;
                        this.setState({ data_inicio: start, data_fim: end });
                        
                        if (!start || !end) return;
                        
                        if (this.periodoPicker) this.periodoPicker.blur();
                        
                        var response = await Sig.request('POST', `ataReuniao${this.props.hash ? "Externa" : ""}/updateTarefaTopico`, {
                            id: this.props.tarefa.id,
                            hash: this.props.hash,
                            fields: JSON.stringify({
                                data_inicio: DataHelper.getDefaultDbDateFormat(start),
                                data_fim: DataHelper.getDefaultDbDateFormat(end)
                            })
                        });

                        if(response.status == 200) {
                            this.setState({ prev: { ...this.state.prev, data_inicio: start, data_fim: end } });
                            this.props.updateTarefa(response.tarefa);
                        } else {
                            this.setState({ data_inicio: this.state.prev.data_inicio, data_fim: this.state.prev.data_fim });
                        }

                    }}
                    dateFormat="dd/MM/yy"
                    customInput={<CustomPicker />}
                    disabled={this.props.noEdit}
                    renderCustomHeader={(props) =>  
                        DatePickerHelper.renderCustomDatePickerHeader(props)
                    }
                />
            </div>
        );
    }

    renderSelectPrioridade() {
        const DropdownIndicator = (props) => {
            const { selectProps } = props;
            const isMenuOpen = selectProps.menuIsOpen;

            if (isMenuOpen) return null;

            return (
                <components.DropdownIndicator {...props}>
                    <div style={{ cursor: 'pointer' }}>
                        {PlanoAcaoHelper.getPrioridadeIcon()[this.state.prioridade.label]}
                    </div>
                </components.DropdownIndicator>
            );
        };

        return (
            <div
                style={{
                    ...EssentialStyle.rowFlexStart,
                    fontSize: 14
                }}
                onClick={(e) => e.stopPropagation()}
            >
                <Select
                    ref={ref => this.selectPrioridade = ref}
                    styles={{
                        control: (base, state) => ({
                            ...base,
                            ...EssentialStyle.titleBoxHomePage,
                            border: 'none',
                            width: state.isFocused ? '140px' : '47px',
                            boxShadow: state.isFocused ? 'none' : null,
                            backgroundColor: "transparent",
                            marginRight: 8
                        })
                    }}
                    value={this.state.prioridade}
                    options={PlanoAcaoHelper.getEtapaPrioridades()}
                    formatOptionLabel={({ label }) => (
                        <div style={{ ...EssentialStyle.rowFlexStart, paddingLeft: 8, cursor: 'pointer' }}>
                            <div style={{ marginRight: 8 }}>{PlanoAcaoHelper.getPrioridadeIcon()[label]}</div> {label}
                        </div>
                    )}
                    placeholder={''}
                    noOptionsMessage={DataHelper.getSelectEmptyMessage}
                    isSearchable={false}
                    closeMenuOnSelect={true}
                    components={{ DropdownIndicator, IndicatorSeparator: null }}
                    onChange={async (value) => {
                        this.setState({
                            prioridade: value
                        }, async () => {
                            if (this.selectPrioridade) this.selectPrioridade.blur();

                            var response = await Sig.request('POST', `ataReuniao${this.props.hash ? "Externa" : ""}/updateTarefaTopico`, {
                                id: this.props.tarefa.id,
                                hash: this.props.hash,
                                fields: JSON.stringify({
                                    prioridade: value.value
                                })
                            });

                            if(response.status == 200) {
                                this.setState({ prev: { ...this.state.prev, prioridade: value } });
                                this.props.updateTarefa(response.tarefa);
                            } else {
                                this.setState({ prioridade: this.state.prev.prioridade });
                            }

                        });
                    }}
                    isDisabled={this.props.noEdit}
                />

            </div>
        );
    }

    DropdownIndicator = (props) => {
        const { selectProps } = props;
        const isMenuOpen = selectProps.menuIsOpen;

        if (isMenuOpen) return null;

        return (
            <components.DropdownIndicator {...props}>
                <div style={{ cursor: 'pointer' }}>
                    <UserAvatar user={this.state.selectedUser ? this.getUserFromParticipantes(this.state.selectedUser.value) : null} onlyMail/>
                </div>
            </components.DropdownIndicator>
        );
    };

    SingleValue = ({
        children,
        ...props
      }) => (
        <components.SingleValue {...props}>
            {children}
        </components.SingleValue>
      );

    renderPeopleSelect() {

        return (
            <div
                style={{
                    ...EssentialStyle.rowFlexStart,
                    fontSize: 14
                }}
                onClick={(e) => e.stopPropagation()}
            >
                <Select
                    ref={ref => this.selectPeople = ref}
                    styles={{
                        control: (base, state) => ({
                            ...base,
                            ...EssentialStyle.titleBoxHomePage,
                            border: 'none',
                            width: state.isFocused ? '210px' : '70px',
                            boxShadow: state.isFocused ? 'none' : null,
                            backgroundColor: "transparent",
                            marginRight: 8
                        })
                    }}
                    value={this.state.selectedUser}
                    options={DataHelper.formatSelectData(this.state.participantes, 'email', 'nome')}
                    formatOptionLabel={({ label, value }) => (
                        <div style={{ ...EssentialStyle.rowFlexStart, paddingLeft: 8, cursor: 'pointer' }}>
                            <UserAvatar user={this.getUserFromParticipantes(value)} showNameAdaptative showName onlyMail/>
                        </div>
                    )}
                    placeholder={''}
                    noOptionsMessage={DataHelper.getSelectEmptyMessage}
                    isSearchable={false}
                    components={{ DropdownIndicator: this.DropdownIndicator, SingleValue: this.SingleValue, IndicatorSeparator: null }}
                    onChange={async (value) => {
                        this.setState({
                            selectedUser: value
                        }, async () => {
                            if (this.selectPeople) this.selectPeople.blur();

                            var response = await Sig.request('POST', `ataReuniao${this.props.hash ? "Externa" : ""}/updateTarefaTopico`, {
                                id: this.props.tarefa.id,
                                hash: this.props.hash,
                                fields: JSON.stringify({
                                    responsavel: this.getUserFromParticipantes(value.value).id
                                })
                            });

                            if(response.status == 200) {
                                this.setState({ prev: { ...this.state.prev, selectedUser: value } });
                                this.props.updateTarefa(response.tarefa);
                            } else {
                                this.setState({ selectedUser: this.state.prev.selectedUser });
                            }

                        });
                    }}
                    isDisabled={this.props.noEdit}
                />

            </div>
        );
    }

    handleTitleHoverMouseEnter = () => {
        this.titleHoveredTimeout = setTimeout(() => {
            this.setState({ titleHovered: true });
        }, 400);
    }

    handleTitleHoverMouseLeave = () => {
        if (this.titleHoveredTimeout) clearTimeout(this.titleHoveredTimeout);

        this.setState({ titleHovered: false });
    }

    editTitle = async () => {
        if (!this.state.descricao) {
            toast.info('O campo de descrição da tarefa não pode estar vazio.');
            return;
        }

        this.setState({ updatingTitle: true });

        var response = await Sig.request('POST', `ataReuniao${this.props.hash ? "Externa" : ""}/updateTarefaTopico`, {
            id: this.props.tarefa.id,
            hash: this.props.hash,
            fields: JSON.stringify({
                descricao: this.state.descricao
            })
        });

        if(response.status == 200) {
            this.setState({ updatingTitle: false, editingTitle: false, prev: { descricao: this.state.descricao } });   
            this.props.updateTarefa(response.tarefa);
        } else {
            this.setState({ updatingTitle: false, editingTitle: false, descricao: this.state.prev.descricao });
        } 
    }

    renderTitle(text) {
        return (
            <div
                style={{ ...EssentialStyle.rowFlexStart, overflow: "hidden" }}
                onMouseEnter={() => {if(!this.props.noEdit) this.handleTitleHoverMouseEnter()}}
                onMouseLeave={() => {if(!this.props.noEdit) this.handleTitleHoverMouseLeave()}}
            >
                {this.renderAtrasada()}
                {this.renderWarning()}
                <span
                    style={{
                        marginLeft: 6,
                        fontSize: 16,
                        fontWeight: 500,
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textDecoration: this.state.titleHovered ? 'underline' : 'none',
                        textOverflow: 'ellipsis',
                        maxWidth: '100%'
                    }}
                >
                    {DataHelper.removeHtmlAndReplaceListItems(text)}
                </span>
                {this.state.titleHovered && (
                    <DefaultButton
                        width={48}
                        className="ms-1"
                        leftIcon={<FontAwesomeIcon icon={faPencil} />}
                        color={'transparent'}
                        textColor={Colors.dark}
                        loading={this.state.updatingTitle}
                        onClick={(e) => {
                            e.stopPropagation();

                            this.setState(
                                { 
                                    editingTitle: true, 
                                }, () => {
                                if (this.editTitleInput) this.editTitleInput.focus();
                                const length = this.editTitleInput.value?.length || 0;
                                this.editTitleInput.editor.setSelection(length, 0);
                            });
                        }}
                    />
                )}
            </div>
        );
    }

    renderEditTitle(height = 45) {
        return (
            <div key={"tarefa-"+this.props.tarefa.id} style={{ ...EssentialStyle.rowFlexStart, height: 64, flexGrow: 1, gap: 5 }} onClick={e => e.stopPropagation()}>
                <TextEditor
                    ref={ref => this.editTitleInput = ref}
                    defaultValue={this.state.descricao}
                    placeholder={'O que será feito?'}
                    onChange={(value) => {
                        this.setState({ descricao: value });
                    }}
                    height={height}
                    hideToolbar
                    noMargin
                    disabledEnterEvent
                    onKeyDown={(evt) => {
                        KeyboardHelper.handleShortcut(
                            evt,
                            ["Enter", "Escape"],
                            [() => { 
                                if (evt.shiftKey) return;
                                this.editTitle();
                            }, (e) => { 
                                e.preventDefault();
                                this.setState({ descricao: this.state.prev.descricao, editingTitle: false });
                            }]
                        )
                    }}
                />
                <DefaultButton
                    width={48}
                    leftIcon={<FontAwesomeIcon icon={faTimes} />}
                    color={Colors.dark}
                    loading={this.state.updatingTitle}
                    onClick={(e) => { e.stopPropagation(); this.setState({ descricao: this.state.prev.descricao, editingTitle: false }) }}
                />
                <DefaultButton
                    width={48}
                    leftIcon={<FontAwesomeIcon icon={faCheck} />}
                    color={Colors.dark}
                    loading={this.state.updatingTitle}
                    onClick={(e) => { e.stopPropagation(); this.editTitle() }}
                />
            </div>
        );
    }

    renderAtrasada() {
        if (!this.props.tarefa.atrasada || (this.props.tarefa.status == 2)) return null;

        if (!this.props.tarefa.atrasada || (this.props.tarefa.status == 3)) {
            return (
                <CustomTooltip tooltip={'Esta tarefa foi concluída com atraso'} placement={'auto'}>
                    <FontAwesomeIcon icon={faClock} className={'icon atrasado-concluido'} />
                </CustomTooltip>
            );
        }

        return (
            <CustomTooltip tooltip={'Esta tarefa está atrasada'} placement={'auto'}>
                <FontAwesomeIcon icon={faClock} className={'icon atrasado'} />
            </CustomTooltip>
        );
    }

    isWarning = () => {

        let response = false;
        let descricao = false;

        if (!this.props.tarefa.descricao || DataHelper.removeHtmlTags(this.props.tarefa.descricao) == '' || DataHelper.removeHtmlTags(this.props.tarefa.descricao) == 'null') {
            response = true;
            descricao = "'Descrição da Tarefa'\n";
        };

        if (response) {
            response = "Preencha o campo de:\n";
            if (descricao) response += descricao;
            return response;
        }

        return response;

    }

    renderWarning() {
        if (!this.isWarning()) return null;

        let tooltipTitle = this.isWarning();

        return (
            <CustomTooltip tooltip={tooltipTitle} placement="right">
                <FontAwesomeIcon icon={faExclamationTriangle} className={'icon warning'} />
            </CustomTooltip>
        );
    }

    onClick = async () => {
        if(this.props.detailsModalCallback)
            this.props.detailsModalCallback(this.props.tarefa.id);
        else if(this.props.onClick)
            this.props.onClick(this.props.tarefa.id);
    }

    renderDeleteTarefaModal = () => {
        confirmAlert({
            customUI: ({ onClose }) => (
                <CustomConfirm
                    title={'Remover esta tarefa?'}
                    message={<p style={{ marginTop: 10, marginBottom: 10 }}>Esta ação é irreversível.</p>}
                    buttons={[
                        {
                            label: 'Remover',
                            color: Colors.error,
                            textColor: Colors.light,
                            onClick: async () => { 
                                var response = await Sig.request('POST', `ataReuniao${this.props.hash ? "Externa" : ""}/deleteTarefaTopico`, { 
                                    hash: this.props.hash,
                                    id: this.props.tarefa.id 
                                });
            
                                if(response.status == 200) {
                                    this.props.deleteTarefa(this.props.tarefa);
                                }
                                onClose();
                            }
                        },
                        {
                            label: 'Cancelar',
                            onClick: () => { onClose(); }
                        },
                    ]}
                />
            )
        });
    }

    renderDeleteButton() {
        return (
            <DefaultButton
                width={48}
                tooltip={'Remover tarefa'}
                leftIcon={<FontAwesomeIcon icon={faClose} />}
                color={Colors.secondaryButton}
                textColor={Colors.dark}
                tooltipPlacement={'bottom'}
                onClick={(e) => {e.stopPropagation(); this.renderDeleteTarefaModal()}}
                style={{ padding: 0, width: 25, height: 25, borderRadius: "50%" }}
            />
        );
    }

    render() {
        if(!this.state) return null;

        let cardStyle = {
            width: '100%',
            paddingLeft: 10,
            paddingRight: 10,
            backgroundColor: Colors.white,
            border: `1px solid ${Colors.border}`,
            cursor: 'pointer',
            borderRadius:  8
        }

        return (
            <div style={cardStyle} className={`ata-reuniao-topico-tarefa ${this.isWarning() ? 'warning' : ''}`} onClick={this.onClick}>
                <div 
                    style={{
                        ...EssentialStyle.rowSpaceBetween,
                        width: '100%',
                        height: 45,
                    }}
                    onClick={(e) => {
                        if (this.props.onClick) {
                            e.stopPropagation();
                            if (e.ctrlKey || e.metaKey) {
                                e.preventDefault();
                                this.props.onClick(this.props.tarefa, false);
                            } else {
                                this.props.onClick(this.props.tarefa, true);
                            }
                        }
                    }}
                    onMouseDown={(e) => {
                        if (e.button == 1 && this.props.onClick) {
                            e.stopPropagation();
                            e.preventDefault();
                            this.props.onClick(this.props.tarefa, false);
                        }
                    }}
                >
                    {this.state.editingTitle ? this.renderEditTitle() : this.renderTitle(this.state.descricao)}
                    <div style={{ ...EssentialStyle.rowFlexEnd }}>
                        {this.renderStatusSelect()}
                        {this.renderDatePicker()}
                        {this.renderSelectPrioridade()}
                        {this.renderPeopleSelect()}
                        {this.props.canDelete && this.renderDeleteButton()}
                    </div>
                </div>
            </div>
        )
    }
}