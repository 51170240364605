import React from "react";
import Colors from "../../../../../constants/Colors";
import EssentialStyle from "../../../../../style/EssentialStyle";
import Select from 'react-select'
import Sig from "../../../../../api/Sig";
import LayoutHelper from "../../../../../helper/LayoutHelper";
import DataHelper from "../../../../../helper/DataHelper";
import ModulosAnalytics from "./ModulosAnalytics";
import ModuloGraphicsAnalytics from "./ModuloGraphicsAnalytics";
import DefaultLoader from "../../../../tools/DefaultLoader";
import ChartHelper from "../../../../../helper/inicio/ChartHelper";
import { withTranslation } from 'react-i18next';

class Analytics extends React.Component {
    state = {
        loadingTitle: true,
        loadingContent: true,
        empresaIsAdvis: false,
        outrasEmpresas: [],
        empresaSelected: [],
        selectedModulo: null,
        isSmallScreen: LayoutHelper.isSmallScreen(),
        transform: 'translateY(+100%)',
        opacity: 0,
    }

    async componentDidMount() {
        window.addEventListener('resize', this.verifyScreen);
        await this.props.i18n.loadNamespaces(['client_src_components_modules_inicio_charts_analytics_Analytics']);
        this.loadData();
    }

    async componentDidUpdate(prevProps) {
        
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.verifyScreen);
    }

    verifyScreen = () => {
        this.setState({ isSmallScreen: LayoutHelper.isSmallScreen() });    
    }

    loadData = async () => {
        this.setState({ loadingTitle: true, loadingContent: true});
        let {empresaIsAdvis, outrasEmpresas} = await Sig.request('GET', 'inicio/getInicioPermission');

        outrasEmpresas = DataHelper.formatSelectData(outrasEmpresas, 'id', 'nome_fantasia');

        let empresaSelected = !empresaIsAdvis ? outrasEmpresas : [];

        this.setState({ empresaIsAdvis, outrasEmpresas, empresaSelected, loadingTitle: false, loadingContent: false });
    }

    changeEmpresa = (empresaSelected) => {
        this.setState({ empresaSelected, selectedModulo: null });
    }

    changeModulo = (idModulo) => {
        this.setState({ selectedModulo: this.state.selectedModulo === idModulo ? null : idModulo});
    }

    renderTitle() {

        return (
            <div style={{...EssentialStyle.cardTitle, height: 40, borderBottom: `1px solid ${Colors.homePage.line}`}}>
                <span style={{ ...EssentialStyle.titleBoxHomePage, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    {this.props.t('client_src_components_modules_inicio_charts_analytics_Analytics:estatisticas_do_sig')}
                </span>
                <div style={{ ...EssentialStyle.rowFlexEnd, width: this.state.isSmallScreen ? 300 : '50vw' }}>
                    {!this.state.loadingTitle && this.state.empresaIsAdvis && 
                        <Select
                            styles={{ 
                                container: (provided) => ({
                                    ...provided,
                                    width: '100%',
                                    maxHeight: 40,
                                }),
                                control: (provided, state) => ({
                                    ...provided,
                                    color: 'black',
                                    backgroundColor: "transparent",
                                    maxHeight: 40,
                                    overflowY: 'auto',
                                }),
                                valueContainer: (provided, state) => ({
                                    ...provided,
                                    color: Colors.homePage.title,
                                    overflowY: 'auto', // Permite rolagem vertical se necessário
                                    maxHeight: '100px', // Limita a altura máxima dos itens selecionados
                                }),
                                option: (provided, state) => ({
                                    ...provided,
                                    color: 'black',
                                    backgroundColor: state.isFocused ? 'lightgray' : 'none',
                                }),
                            }}
                            isMulti
                            options={this.state.outrasEmpresas}
                            value={this.state.empresaSelected}
                            onChange={(e) => this.changeEmpresa(e)}
                            placeholder={this.props.t('client_src_components_modules_inicio_charts_analytics_Analytics:todas_empresas')}
                        />
                    }
                    {!this.state.loadingTitle && !this.state.empresaIsAdvis && 
                        <span style={{ ...EssentialStyle.titleBoxHomePage, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', marginRight: 20 }}>
                            {this.state.outrasEmpresas[0].label}
                        </span>
                    }
                </div>
            </div>
        )
    }

    renderloadingContent() {

        let styles = {
            content: {
                ...(this.state.isSmallScreen ? EssentialStyle.columnStart : EssentialStyle.rowFlexStart),
                width: '100%',
                height: 'calc(100% - 40px)',
                ...EssentialStyle.columnCenter,
            }
        }

        return (
            <div style={styles.content}>
                <DefaultLoader />
            </div>
        );
    }

    renderContent() {
        
        let styles = {
            content: {
                ...(this.state.isSmallScreen ? EssentialStyle.columnStart : EssentialStyle.rowFlexStart),
                width: '100%',
                height: 'calc(100% - 40px)',
            },
            modules: {
                width: this.state.isSmallScreen ? '100%' : '10rem',
                height: this.state.isSmallScreen ? '4rem' : '100%',
            },
            infos: {
                width: this.state.isSmallScreen ? '100%' : 'calc(100% - 10rem)',
                height: this.state.isSmallScreen ? 'calc(100% - 4rem)' : '100%',
            }
        }

        return (
            <div style={styles.content}>
                <div style={styles.modules}><ModulosAnalytics idEmpresas={this.state.empresaSelected} changeModulo={(idModulo) => {this.changeModulo(idModulo)}} idModulo={this.state.selectedModulo}/></div>
                <div style={styles.infos}><ModuloGraphicsAnalytics idEmpresas={this.state.empresaSelected} idModulo={this.state.selectedModulo}/></div>
            </div>
        );
    }

    render() {

        let styles = {
            page: {
                ...EssentialStyle.columnStart,
                ...EssentialStyle.card,
                width: '100%',
                height: '100%',
                minHeight: `calc(100vh - ${ChartHelper.getHeaderHeight()}px - 60px)`,
                padding: 10,
            },
        }

        return (
            <div style={styles.page}>
                { this.renderTitle() }
                { this.state.loadingContent ? this.renderloadingContent() : this.renderContent() }
            </div>
        );
    }
}

export default withTranslation()(Analytics);