import React from "react";
import '../../components/modules/diagnostico/Orientacao/Orientacao.css';

import { withTranslation } from 'react-i18next';
import EssentialStyle from "../../style/EssentialStyle";
import LayoutHelper from "../../helper/LayoutHelper";
import LoadingPage from "../LoadingPage";
import DiagnosticoHelper from "../../helper/diagnostico/DiagnosticoHelper";
import DataHelper from "../../helper/DataHelper";
import { Form } from "react-bootstrap";
import Select from "react-select";
import Colors from "../../constants/Colors";
import UnderConstruction from "../../components/tools/UnderConstruction";
import Sig from "../../api/Sig";
import DefaultLoader from "../../components/tools/DefaultLoader";
import DefaultButton from "../../components/tools/DefaultButton";
import OrientacaoCard from "../../components/modules/diagnostico/Orientacao/OrientacaoCard";
import IdiomaHelper from "../../helper/IdiomaHelper";
import Tabs from "../../components/tools/Tabs";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

class DiagnosticoOrientacoesPage extends React.Component {

    state = {
        construction: false,
        loadingHeader: true,
        isSmallScreen: LayoutHelper.isSmallScreen(),
        idProcesso: null,
        periodos: [],
        idPeriodoAvaliacao: null,
        isEmpresaAvaliadora: false,

        loading: true,
        orientacoes: [],
        questionarios: [],
        questionariosDisponiveis: [],
        orientacoesDefault: [],

        activeTab: IdiomaHelper.getIdioma(),
        forceCardsSave: false,
    }
    
    async componentDidMount() {
        await this.props.i18n.loadNamespaces(['client_src_pages_diagnostico_DiagnosticoOrientacoesPage']);
        await this.props.i18n.loadNamespaces(['client_src_helper_diagnostico_DiagnosticoHelper']);

        this.handleUrlParams();
        window.addEventListener('resize', this.verifyScreen);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.verifyScreen);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.forceCardsSave !== this.state.forceCardsSave && this.state.forceCardsSave) {
            this.setState({ forceCardsSave: false });
        }
    }

    handleUrlParams = () => {
        window.parent.postMessage({ type: 'get_url' }, '*');

        const listenerFunction = async (event) => {
            if (event.data.type === 'return_url') {
                const urlObj = new URL(event.data.url);
                const queryParams = new URLSearchParams(urlObj.search);
                window.removeEventListener('message', listenerFunction);

                // Extract the last segment from the pathname
                const pathSegments = urlObj.pathname.split('/');
                const lastSegment = pathSegments[pathSegments.length - 1];

                this.setState({ 
                    idProcesso: parseInt(lastSegment), 
                }, () => this.loadData());
            }
        };

        window.addEventListener('message', listenerFunction);
    }

    verifyScreen = () => {
        if(this.state.isSmallScreen !== LayoutHelper.isSmallScreen()){
            this.setState({ isSmallScreen: LayoutHelper.isSmallScreen() });
        }
    }

    async loadData() {
        this.setState({ loadingHeader: true });

        let { periodos, canEdit, canView } = await DiagnosticoHelper.getPeriodosAvaliacao(this.state.idProcesso);
        
        let idPeriodoAvaliacao = null;
        let localId = DiagnosticoHelper.getPeriodoAvaliacaoLocal(this.props.idProcesso);

        if (localId && periodos.find(p => p.id == localId)) {
            idPeriodoAvaliacao = localId;
        } else {
            idPeriodoAvaliacao = periodos.length > 0 ? periodos[0].id : null;
        }

        let isEmpresaAvaliadora = await DiagnosticoHelper.isEmpresaAvaliadora(this.state.idProcesso);

        this.setState({ loadingHeader: false, periodos, idPeriodoAvaliacao, isEmpresaAvaliadora }, () => this.loadOrientacoes());
    }

    getQuestionariosDisponiveis(orientacoes, questionarios) {
        var questionariosIndisponiveis = orientacoes.reduce((acc, orientacao) => {
            return acc.concat(orientacao.questionarios.map(questionario => questionario.id));
        }, []);

        var questionariosDisponiveis = questionarios.filter(questionario => 
            !questionariosIndisponiveis.includes(questionario.id)
        );

        return questionariosDisponiveis;
    }

    async loadOrientacoes() {
        this.setState({ loading: true });

        let {orientacoes, orientacoes_padrao} = await Sig.request('GET', '/diagnostico/orientacoes/getOrientacoes', {idPeriodoAvaliacao: this.state.idPeriodoAvaliacao});

        let {questionarios} = await DiagnosticoHelper.getQuestionarios(this.state.idPeriodoAvaliacao);

        var questionariosDisponiveis = this.getQuestionariosDisponiveis(orientacoes, questionarios);

        this.setState({ orientacoes, questionarios, questionariosDisponiveis, orientacoesDefault: orientacoes_padrao, loading: false });
    }

    async addOrientacao() {
        var req = await Sig.request('POST', '/diagnostico/orientacoes/saveOrientacao', {idPeriodoAvaliacao: this.state.idPeriodoAvaliacao, orientacao: JSON.stringify({textos: this.state.orientacoesDefault, questionarios: []})});

        if(req.status === 200) {
            this.setState({orientacoes: [...this.state.orientacoes, req.orientacao]}, () => {
                var questionariosDisponiveis = this.getQuestionariosDisponiveis(this.state.orientacoes, this.state.questionarios);
                this.setState({questionariosDisponiveis});
            });
        }
    }

    deleteOrientacao = async (id) => {
        var req = await Sig.request('POST', '/diagnostico/orientacoes/deleteOrientacao', {idOrientacao: id});

        if(req.status === 200) {
            this.setState({orientacoes: this.state.orientacoes.filter((orientacao) => orientacao.id !== id)},
                () => {
                    var questionariosDisponiveis = this.getQuestionariosDisponiveis(this.state.orientacoes, this.state.questionarios);
                    this.setState({questionariosDisponiveis});
                }
            );
        }
    }

    onChangeOrientacao = (orientacao) => {
        this.setState({orientacoes: this.state.orientacoes.map(o => {
            if(o.id === orientacao.id) {
                return orientacao;
            }
            return o;
        })}, () => {
            var questionariosDisponiveis = this.getQuestionariosDisponiveis(this.state.orientacoes, this.state.questionarios);
            this.setState({questionariosDisponiveis});
        });
    }

    renderHeader() {
        let localStyle = {
            boxShadow: Colors.boxShadow,
            ...(this.state.isSmallScreen ? EssentialStyle.columnCenter : EssentialStyle.rowSpaceBetween)
        }

        let outerStyle = {
            width: "100%", 
            top: 0, 
            position: "sticky", 
            zIndex: 5
        };

        return (
            <div style={outerStyle}>
                <div className="header" style={localStyle}>
                    <div className="left" style={{ width: this.state.isSmallScreen ? '100%' : '' }}>
                        <Form.Group>
                            <Select
                                styles={{ control: (base) => ({ ...base, border: 'none', minWidth: '40vh', marginRight: 2 }) }}
                                options={DataHelper.formatSelectData(this.state.periodos, 'id', 'nome')}
                                value={DataHelper.formatSelectData(this.state.periodos, 'id', 'nome').find(option => option.value === this.state.idPeriodoAvaliacao)}
                                onChange={(valueSelected) => { this.setState({ idPeriodoAvaliacao: valueSelected.value });  DiagnosticoHelper.setPeriodoAvaliacaoLocal(valueSelected.value, this.props.idProcesso); }}
                                placeholder={'Selecione um Período de Avaliação'}
                                noOptionsMessage={DataHelper.getSelectEmptyMessage}
                                isSearchable
                                menuPortalTarget={document.body}
                            />
                        </Form.Group>
                    </div>
                    <div className="right">
                        <Tabs 
                            tabs={[
                                { name: 'pt_br', title: this.props.t('client_src_pages_diagnostico_DiagnosticoOrientacoesPage:portugues') },
                                { name: 'en', title: this.props.t('client_src_pages_diagnostico_DiagnosticoOrientacoesPage:ingles') },
                                { name: 'es', title: this.props.t('client_src_pages_diagnostico_DiagnosticoOrientacoesPage:espanhol') },
                            ]}
                            activeTab={this.state.activeTab}
                            onTabChange={(tab) => this.setState({ activeTab: tab.name, forceCardsSave: true })}
                        />
                    </div>
                </div>
            </div>
        )
    }

    renderContent() {
        const loading = () => {
                return (
                    <div className="body loading">
                        <DefaultLoader/>
                    </div>
                )
            }
    
        const content = () => {
            return (
                <div className="body">
                    {this.state.orientacoes && this.state.orientacoes.length > 0 ? 
                        this.state.orientacoes.map((orientacao, index) => {
                            return (
                                <OrientacaoCard 
                                    key={orientacao.id} 
                                    index={index} 
                                    orientacao={{...orientacao}} 
                                    questionarios={this.state.questionarios} 
                                    questionariosDisponiveis={this.state.questionariosDisponiveis} 
                                    onChangeOrientacao={this.onChangeOrientacao} 
                                    isSmallScreen={this.state.isSmallScreen}
                                    onDelete={this.deleteOrientacao}
                                    idPeriodoAvaliacao={this.state.idPeriodoAvaliacao}
                                    selectedLang={this.state.activeTab}
                                    forceSave={this.state.forceCardsSave}
                                    orientacoesDefault={this.state.orientacoesDefault}
                                />
                            )
                        }) 
                    : 
                    // show the default orientacoes if there are no orientacoes
                        <div className="empty">
                            {this.props.t('client_src_pages_diagnostico_DiagnosticoOrientacoesPage:no_orientacoes')}
                            <br/>
                            <OrientacaoCard
                                index={0}
                                orientacao={{textos: this.state.orientacoesDefault, questionarios: []}}
                                questionarios={this.state.questionarios}
                                questionariosDisponiveis={this.state.questionariosDisponiveis}
                                isSmallScreen={this.state.isSmallScreen}
                                idPeriodoAvaliacao={this.state.idPeriodoAvaliacao}
                                selectedLang={this.state.activeTab}
                                forceSave={this.state.forceCardsSave}
                                orientacoesDefault={this.state.orientacoesDefault}
                                viewOnly
                            />
                        </div>
                    }
                    <DefaultButton 
                        title={this.props.t('client_src_pages_diagnostico_DiagnosticoOrientacoesPage:add')}
                        leftIcon={<FontAwesomeIcon icon={faPlus} />}
                        color={Colors.success}
                        textColor={Colors.white}
                        onClick={() => {
                            this.addOrientacao();
                        }}
                    />
                </div>
            )
        }
                    
        if (this.state.loading) return loading();
        return content();

    }

    renderPage() {
        return (
            <div className="DiagnosticoOrientacao">
                {this.renderHeader()}
                {this.state.idPeriodoAvaliacao ? this.renderContent() : DiagnosticoHelper.renderPeriodoNotSelected(this.props.t)}
            </div>
        );
    }

    render() {

        if (this.state.construction) return <UnderConstruction height={'100vh'}/>
        if (this.state.loadingHeader) return <LoadingPage />;
        if (this.state.idProcesso === null)
        {
            window.parent.postMessage({ type: 'navigate', url: '/diagnostico' }, '*');
            return(
                <div/>
            )
        }

        return (
            this.renderPage()
        );
    }
}

export default withTranslation()(DiagnosticoOrientacoesPage);