import React from "react";
import ReactDOM from "react-dom";
import { forwardRef } from "react";
import Sig from "../../../../../api/Sig";
import LayoutHelper from "../../../../../helper/LayoutHelper";
import DefaultLoader from "../../../../tools/DefaultLoader";
import AnalyticsHelper from "../../../../../helper/inicio/AnalyticsHelper";
import EssentialStyle from "../../../../../style/EssentialStyle";
import SessionHelper from "../../../../../helper/SessionHelper";
import DatePicker from "react-datepicker";
import { Form } from "react-bootstrap";
import Constants from "../../../../../constants/Api";
import moment from 'moment';
import Colors from "../../../../../constants/Colors";
import { Dropdown } from "react-bootstrap";
import Highcharts from "highcharts/highcharts.js";
import HighchartsReact from "highcharts-react-official";
import variablePie from "highcharts/modules/variable-pie.js";
import UnderConstruction from "../../../../tools/UnderConstruction";
import DatePickerHelper from "../../../../../helper/DatePickerHelper";
import { withTranslation } from 'react-i18next';

const gateway = Constants.getSigEndPoint();
variablePie(Highcharts);

class ModuloGraphicsAnalytics extends React.Component {

        state = {
            loading: true,
            loadingOne: true,
            loadingTwo: true,
            idModulo: this.props.idModulo,
            idEmpresas: this.props.idEmpresas,
            
            data_inicio: moment().subtract(1, 'month').toDate(),
            data_fim: moment().toDate(),
            nivelAcessoSituacao: "todos",
            nivelAcessoLogs: "todos",
            nivelOptions: this.props.idEmpresas.length == 0 || (Array.isArray(this.props.idEmpresas) && this.props.idEmpresas.find(emp => emp.value === '2')) ? AnalyticsHelper.getPermissoesColaboradores(this.props.t) : AnalyticsHelper.getPermissoesColaboradoresClientes(this.props.t),
            colaboradoresSituacao: null,
            colaboradoresLogs: null,
            graphicColaboradores: null,
            
            isSmallScreen: LayoutHelper.isSmallScreen(),
        }

    async componentDidMount() {
        window.addEventListener('resize', this.verifyScreen);
        await this.props.i18n.loadNamespaces(['client_src_components_modules_inicio_charts_analytics_ModuloGraphicsAnalytics', 'client_src_helper_inicio_AnalyticsHelper']);
        this.loadData();
    }

    async componentDidUpdate(prevProps) {
        if (this.props.idEmpresas !== prevProps.idEmpresas || this.props.idModulo !== prevProps.idModulo) {
            this.setState({ idModulo: this.props.idModulo, idEmpresas: this.props.idEmpresas }, () => {
                this.loadData();
                this.setState({nivelOptions: this.props.idEmpresas.length == 0 || (Array.isArray(this.props.idEmpresas) && this.props.idEmpresas.find(emp => emp.value === '2')) ? AnalyticsHelper.getPermissoesColaboradores(this.props.t) : AnalyticsHelper.getPermissoesColaboradoresClientes(this.props.t)});
            });
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.verifyScreen);
    }

    verifyScreen = () => {
        this.setState({ isSmallScreen: LayoutHelper.isSmallScreen() });    
    }

    loadData = async () => {
        this.setState({ loading: true });

        if (this.state.idModulo) {
            switch (this.state.idModulo) {
                case "config":
                    this.loadConfig();
                    break;
                default:
                    this.setState({ loading: false });
                    break;
            }
        } else {
            this.setState({ loading: false });
        }
    }

    loadConfig = async () => {

        let data_inicio = moment(this.state.data_inicio).toISOString();
        let data_fim = moment(this.state.data_fim).toISOString();
        let idEmpresas = this.state.idEmpresas.map(empresa => empresa.value);

        let {colaboradores} = await Sig.request('GET', 'config/colaborador/getAnalytcsColaboradores', {idEmpresas});
        let {logsAcesso, periodo} = await Sig.request('GET', 'config/colaborador/getAnalytcsLogs', {idEmpresas, data_inicio, data_fim});

        let colaboradoresLogs = {
            "data": logsAcesso,
            "periodo": periodo,
        }

        this.setState({ colaboradoresSituacao: colaboradores, colaboradoresLogs, loading: false }, () => {
            this.calculateGraphicConfigColaboradores();
            this.calculateGraphicConfigLogs();
        });
    }

    calculateGraphicConfigColaboradores = () => {
        this.setState({loadingOne: true});
        let colaboradores  = this.state.colaboradoresSituacao;

        let graphicColaboradores = {
            series: [
                {
                    name: 'Proporção',
                    colorByPoint: true,
                    data: [],
                }
            ],
            label: [],
        }

        let soma = 0;
        AnalyticsHelper.getStatesColaboradores(this.props.t).forEach((value, key) => {
            
            let colaboradoresStatus = colaboradores[key];
            let valor = 0;
            
            if (this.state.nivelAcessoSituacao == "todos") {
                valor = Object.values(colaboradoresStatus).reduce((acumulador, valorAtual) => acumulador + valorAtual, 0);
            } else {
                const nivelMapping = AnalyticsHelper.getNivelMapping();
                const accessLevel = nivelMapping[this.state.nivelOptions[this.state.nivelAcessoSituacao]] || this.state.nivelOptions[this.state.nivelAcessoSituacao];
                valor = colaboradoresStatus[accessLevel];
            }
            soma = soma + valor;
            graphicColaboradores.series[0].data.push({
                name: value,
                y: valor,
                color: Colors.graphics[key],
            });
        });

        graphicColaboradores.series[0].data.forEach((value, key) => {
            value.yPercent = `${Math.round((value.y / soma) * 100)}`;
        });

        this.setState({ graphicColaboradores, loadingOne: false});
    }

    calculateAndLoadGraphicConfigLogs = async () => {
        this.setState({loadingTwo: true});

        let data_inicio = moment(this.state.data_inicio).toISOString();
        let data_fim = moment(this.state.data_fim).toISOString();
        let idEmpresas = this.state.idEmpresas.map(empresa => empresa.value);

        let {logsAcesso, periodo} = await Sig.request('GET', 'config/colaborador/getAnalytcsLogs', {idEmpresas, data_inicio, data_fim});
        let soma = Object.values(logsAcesso[this.state.nivelOptions[this.state.nivelAcessoLogs]])
        .reduce((acc, valorAtual) => acc + valorAtual, 0);

        let graphicLogs = {
            series: [
                {
                    name: this.props.t('client_src_components_modules_inicio_charts_analytics_ModuloGraphicsAnalytics:numero_de_acessos'),
                    color: SessionHelper.getColor(),
                    data: Object.values(logsAcesso[this.state.nivelOptions[this.state.nivelAcessoLogs]]),
                    marker: {
                        enabled: false
                    }
                }
            ],
            periodo,
            soma,
        }

        this.setState({ graphicLogs, loadingTwo: false});
    }

    calculateGraphicConfigLogs = () => {
        this.setState({loadingTwo: true});
        let logsAcesso = this.state.colaboradoresLogs?.data;
        let periodo = this.state.colaboradoresLogs?.periodo;
        const nivelMapping = AnalyticsHelper.getNivelMapping();
        const accessLevel = nivelMapping[this.state.nivelOptions[this.state.nivelAcessoLogs]] || this.state.nivelOptions[this.state.nivelAcessoLogs];
    
        if (!logsAcesso || !this.state.nivelOptions[this.state.nivelAcessoLogs] || !logsAcesso[accessLevel]) {
            this.setState({ loadingTwo: false });
            return;
        }
    
        let soma = Object.values(logsAcesso[accessLevel])
        .reduce((acc, valorAtual) => acc + valorAtual, 0);
    
        let graphicLogs = {
            series: [
                {
                    name: this.props.t('client_src_components_modules_inicio_charts_analytics_ModuloGraphicsAnalytics:numero_de_acessos'),
                    color: SessionHelper.getColor(),
                    data: Object.values(logsAcesso[accessLevel]),
                    marker: {
                        enabled: false
                    }
                }
            ],
            periodo,
            soma,
        }
    
        console.log('graphicLogs:', graphicLogs);
    
        this.setState({ graphicLogs, loadingTwo: false });
    }

    loadGraphics = (key) => {
        switch (key) {
            case "nivelAcessoSituacao":
                this.calculateGraphicConfigColaboradores();
                break;
            case "nivelAcessoLogs":
                this.calculateGraphicConfigLogs();
                break;
        }
    }

    handleDropdown(key, value) {
        this.setState({ [key]: value },() => {this.loadGraphics(key)});
    }

    renderDatePicker(stateProp, loading = false, callback = () => { }, title) {
        const DatePickerComponent = forwardRef(({ value, onClick }, ref) => (
            <Form.Control
                onChange={() => { }}
                onClick={onClick}
                ref={ref}
                style={{ marginLeft: 10, fontSize: 12, color: Colors.dark, cursor: 'pointer', boxShadow: 'none', backgroundColor: Colors.homePage.lightGrey, padding: 1, height: '100%', width: 80, textAlign: 'center' }}
                value={value} >
            </Form.Control>
        ));

        return (
            <div style={{
                ...EssentialStyle.rowFlexStart,
                color: Colors.dark,
                fontSize: 12
            }}>
                {title || ``}
                <DatePicker
                    selected={this.state[stateProp]}
                    type="date"
                    onChange={(event) => {
                        let novoInicio = moment(event);
                        if (novoInicio.toDate() !== this.state[stateProp]) {
                            this.setState({ [stateProp]: novoInicio.toDate() }, callback);
                        }
                    }}
                    dateFormat="dd/MM/yyyy"
                    customInput={<DatePickerComponent />}
                    popperPlacement="top-start"
                    disabled={loading}
                    renderCustomHeader={
                        (props) => DatePickerHelper.renderCustomDatePickerHeader(props)
                    } 
                />
            </div>
        )
    }

    renderEmptyModulo() {
        return (
            <div style={{...EssentialStyle.rowFlexCenter, height: '100%', width: '100%'}}>
                <UnderConstruction/>
            </div>
        );
    }

    renderNullModulo () {
        return (
            <div style={{...EssentialStyle.rowFlexCenter, height: '100%', width: '100%'}}>
                <img
                    src={`${gateway}/img/undraw/undraw_select.svg`}
                    alt={this.props.t('client_src_components_modules_inicio_charts_analytics_ModuloGraphicsAnalytics:uma_mulher_olhando')}
                    style={{
                        height: '85%',
                        maxHeight: 200,
                    }}
                />
                <span style={{maxWidth: 150}}>
                {this.props.t('client_src_components_modules_inicio_charts_analytics_ModuloGraphicsAnalytics:selecione_um_modulo')}
                </span>
            </div>
        );
    }

    renderConfig() {
        let style = {
            box: {
                ...(this.state.isSmallScreen ? EssentialStyle.columnSpaceAround : EssentialStyle.rowFlexCenter),
                height: '100%',
                width: '100%',
            },
            container: {
                ...EssentialStyle.columnSpaceAround,
                height: this.state.isSmallScreen ? '50%' : '100%',
                width: this.state.isSmallScreen ? '100%' : '50%',
                padding: 10,
            },
            nameContainer:{
                ...EssentialStyle.rowFlexStart,
                height: 30,
                width: '100%',
            },
            contentContainer: {
                ...(this.state.isSmallScreen ? EssentialStyle.columnStart : EssentialStyle.columnSpaceAround),
                alignItems: this.state.isSmallScreen ? 'start' : 'center',
                height: 'calc(100% - 30px)',
                width: '100%',
            },
            dropdown: {
                ...EssentialStyle.rowSpaceBetween,
                flexWrap: 'wrap',
                height: 65,
                width: '100%',
            },
            data:{
                ...EssentialStyle.rowFlexStart,
                marginTop: this.state.isSmallScreen ? 10 : 0,
            },
            graficoSituacao: {
                ...EssentialStyle.columnSpaceAround,
                height: this.state.isSmallScreen ? 'calc(100%)' : 'calc(100% - 65px)',
                width: this.state.isSmallScreen ? 'calc(100%)' : '100%',
            },
            graficoLogs: {
                ...EssentialStyle.columnSpaceAround,
                height: this.state.isSmallScreen ? 'calc(100%)' : 'calc(100% - 65px)',
                width: this.state.isSmallScreen ? 'calc(100%)' : '100%',
            }
        };
    
        let portalTarget = document.getElementById('root');
        return (
            <div style={style.box}>
                <div style={style.container}>
                    <span style={style.nameContainer}>{this.props.t('client_src_components_modules_inicio_charts_analytics_ModuloGraphicsAnalytics:situacao_dos_colaboradores')}</span>
                    <div style={style.contentContainer}>
                        <div style={{...style.dropdown, ...EssentialStyle.rowFlexStart}}>
                            <Dropdown>
                                <Dropdown.Toggle
                                    onChange={() => {}}
                                    value={ this.state.nivelOptions[this.state.nivelAcessoSituacao] } 
                                    style={{ 
                                        backgroundColor: Colors.secondaryButton, 
                                        color: Colors.dark, 
                                        padding: '3px 6px',
                                        borderColor: 'black', 
                                    }}
                                > 
                                    <span style={{ marginRight: 5, fontSize: 12 }}>{this.state.nivelOptions[this.state.nivelAcessoSituacao]}</span>
                                </Dropdown.Toggle>
                                {ReactDOM.createPortal(<Dropdown.Menu> 
                                    {Object.keys(this.state.nivelOptions).map(key => (
                                        <Dropdown.Item 
                                            key={`order-opt-${key}`} 
                                            onClick={() => { this.handleDropdown("nivelAcessoSituacao", key) }} style={key === this.state.nivelAcessoSituacao ? { backgroundColor: Colors.dark } : {}} 
                                            active={key === this.state.nivelAcessoSituacao}
                                        >
                                                <span style={{ fontSize: 12 }}>{this.state.nivelOptions[key]}</span>
                                        </Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                                , portalTarget)}
                            </Dropdown>
                        </div>
                        <div style={style.graficoSituacao}>
                            {
                                !this.state.loadingOne ?
                                <HighchartsReact
                                    highcharts={Highcharts}
                                    constructorType={'chart'}
                                    containerProps={{ style: { height: "100%", width: "100%" } }}
                                    options={{
                                        chart: {
                                            type: 'variablepie',
                                            backgroundColor: 'transparent',
                                            margin: [`${this.state.isSmallScreen ? 10 : 30}`, null, null, null]
                                        },
                                        title: {
                                            text: "",
                                        },
                                        tooltip: {
                                            borderWidth: 0,
                                            backgroundColor: 'none',
                                            shadow: false,
                                            pointFormat: '<span style="font-size: 2em; text-align: center; color: {point.color}; font-weight: bold">{point.yPercent}%</span>',
                                            positioner: function (labelWidth) {
                                                return {
                                                    x: 0,
                                                    y: `${(this.chart.plotHeight-10)}`
                                                };
                                            }
                                        },
                                        legend: {
                                            align: "right",
                                            verticalAlign: "middle",
                                            layout: 'vertical',
                                            floating: false,
                                            enabled: true,
                                            labelFormatter: function () {
                                                return this.name + ': ' + this.y;
                                            }
                                        },
                                        plotOptions: {
                                            variablepie: {
                                                dataLabels: {
                                                    enabled: false,
                                                },
                                            }
                                        },
                                        series: [{
                                            minPointSize: 40,
                                            showInLegend: true,
                                            innerSize: '65%',
                                            borderRadius: 1,
                                            data: this.state.graphicColaboradores.series[0].data,
                                        }],
                                        credits: {
                                            enabled: false
                                        },
                                    }}
                                />
                                :
                                <DefaultLoader/>
                            }
                        </div>
                    </div>
                </div>
    
                <div style={{...style.container, 
                    borderLeft: this.state.isSmallScreen ? '': '1px solid rgba(50, 50, 50, 0.5)',
                    borderTop: this.state.isSmallScreen ? '1px solid rgba(50, 50, 50, 0.5)': ''
                }}>
                    <span style={style.nameContainer}>{this.props.t('client_src_components_modules_inicio_charts_analytics_ModuloGraphicsAnalytics:acessos')}</span>
                    <div style={style.contentContainer}>
                        <div style={{...style.dropdown}}>
                            <Dropdown>
                                <Dropdown.Toggle
                                    onChange={() => {}}
                                    value={ this.state.nivelOptions[this.state.nivelAcessoLogs] } 
                                    style={{ 
                                        backgroundColor: Colors.secondaryButton, 
                                        color: Colors.dark, 
                                        padding: '3px 6px',
                                        borderColor: 'black', 
                                    }}
                                > 
                                    <span style={{ marginRight: 5, fontSize: 12 }}>{this.state.nivelOptions[this.state.nivelAcessoLogs]}</span>
                                </Dropdown.Toggle>
                                {ReactDOM.createPortal(<Dropdown.Menu> 
                                    {Object.keys(this.state.nivelOptions).map(key => (
                                        <Dropdown.Item 
                                            key={`order-opt-${key}`} 
                                            onClick={() => { this.handleDropdown("nivelAcessoLogs", key) }} style={key === this.state.nivelAcessoLogs ? { backgroundColor: Colors.dark } : {}} 
                                            active={key === this.state.nivelAcessoLogs}
                                        >
                                                <span style={{ fontSize: 12 }}>{this.state.nivelOptions[key]}</span>
                                        </Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                                , portalTarget)}
                            </Dropdown>
    
                            <div style={style.data}>
                                <span>{this.props.t('client_src_components_modules_inicio_charts_analytics_ModuloGraphicsAnalytics:de')} </span>
                                {this.renderDatePicker('data_inicio', this.state.loadingTwo, () => {this.calculateAndLoadGraphicConfigLogs()})}
                                <span style={{marginLeft: 6}}>{this.props.t('client_src_components_modules_inicio_charts_analytics_ModuloGraphicsAnalytics:ate')}</span>
                                {this.renderDatePicker('data_fim', this.state.loadingTwo, () => {this.calculateAndLoadGraphicConfigLogs()})}
                            </div>
                        </div>
                        <div style={style.graficoSituacao}>
                            {
                                !this.state.loadingTwo && this.state.graphicLogs ?
                                <HighchartsReact
                                    highcharts={Highcharts}
                                    constructorType={'chart'}
                                    containerProps={{ style: { height: "100%", width: "100%" } }}
                                    options={{
                                        chart: {
                                            type: 'spline',
                                            backgroundColor: 'transparent',
                                            margin: [`${this.state.isSmallScreen ? 10 : 30}`, null, null, null]
                                        },
                                        title: {
                                            text: `${this.props.t('client_src_components_modules_inicio_charts_analytics_ModuloGraphicsAnalytics:total_de_acessos')}: ${this.state.graphicLogs.soma}`,
                                            style: {
                                                color: SessionHelper.getColor(),
                                                fontSize: 14,
                                            }
                                        },
                                        xAxis: {
                                            categories: this.state.graphicLogs.periodo,
                                            accessibility: {
                                                description: 'Período dos Logas de acesso.'
                                            }
                                        },
                                        yAxis: {
                                            title: {
                                                text: this.props.t('client_src_components_modules_inicio_charts_analytics_ModuloGraphicsAnalytics:numero_de_acessos'),
                                            },
                                        },
                                        tooltip: {
                                            borderWidth: 0,
                                            backgroundColor: 'white',
                                            shadow: true,
                                            pointFormat: `<span style="font-size: 2em; text-align: center; color: ${SessionHelper.getSecondaryColor()}; font-weight: bold">{point.y}</span>`,
                                        },
                                        plotOptions: {
                                            variablepie: {
                                                dataLabels: {
                                                    enabled: false,
                                                },
                                            }
                                        },
                                        series: [this.state.graphicLogs.series[0]],
                                        credits: {
                                            enabled: false
                                        },
                                    }}
                                />
                                :
                                <DefaultLoader/>
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    renderContent() {

        switch (this.state.idModulo) {
            case null:
                return this.renderNullModulo();
            case "config":
                return this.renderConfig();
            default:
                return this.renderEmptyModulo();
        }
    }

    renderloading() {
        return (
            <div style={{...EssentialStyle.columnCenter, height: '100%', width: '100%'}}>
                <DefaultLoader />
            </div>
        );
    }

    render() {
        let style = {
            margin: '10px 5px 5px 5px',
            width: 'calc(100% - 10px)',
            height: this.state.isSmallScreen ? '100%' : 'calc(100% - 10px)',
            boxShadow: Colors.boxShadow,
            borderRadius: 10,
            padding: 5,
        };

        return (
            <div style={style}>
                { this.state.loading ? this.renderloading() : this.renderContent() }
            </div>
        );
    }
}

export default withTranslation()(ModuloGraphicsAnalytics);