// Bibliotecas externas
import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle, faPaperPlane, faBars, faSave } from '@fortawesome/free-solid-svg-icons';
import Select from "react-select";
import { toast } from "react-toastify";
import { withTranslation } from 'react-i18next';

// Helpers
import DataHelper from "../../../../../../helper/DataHelper";
import SessionHelper from "../../../../../../helper/SessionHelper";
import DragAndDropHelper from "../../../../../../helper/DragAndDropHelper";
import DiagnosticoHelper from "../../../../../../helper/diagnostico/DiagnosticoHelper";
import IdiomaHelper from "../../../../../../helper/IdiomaHelper";

// Estilos
import Colors from "../../../../../../constants/Colors";
import EssentialStyle from "../../../../../../style/EssentialStyle";
// - Comentado até que seja adicionado algo - 
// import './AddResultadoGraficos.css';

// Componentes
import DefaultButton from "../../../../../tools/DefaultButton";
import DefaultLoader from "../../../../../tools/DefaultLoader";
import SelectWidth from "../../../../../tools/SelectWidth/SelectWidth";


class AddResultadoGraficos extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,

            item: props.item,

            colunas: [],
            colunasQuestionario: [],
            colunaSelected: null,
            nodes: [],
            nodeSelected: [],
            widthSelected: 6,

            draggingItem: null,
            draggingOverItem: null,
            widthDragItem: 0,
            left: false,

            headerHeight: 0,
        }
            
    }

    headerRef = React.createRef();

    async componentDidMount() {
        await this.props.i18n.loadNamespaces(['client_src_components_modules_diagnostico_CriacaoRelatorio_AddModuloRelatorio_AddResultadoGraficos_AddResultadoGraficos']);
        await this.props.i18n.loadNamespaces(['client_src_helper_diagnostico_DiagnosticoHelper']);

        await this.loadData();
        this.measureHeaderHeight();
        window.addEventListener('resize', this.measureHeaderHeight);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.measureHeaderHeight);
    }

    measureHeaderHeight = () => {
        const headerElement = document.querySelector('.criacaoRelatorio');
        if (headerElement) {
            const height = headerElement.offsetHeight;
            if (height !== this.state.headerHeight) {
                this.setState({ headerHeight: height });
            }
        }
    }

    async loadData() {
        this.setState({ loading: true });
        
        await this.loadAllColumns();

        this.setState({
            item: {
                ...this.props.item,
                on: true,
                type: this.props.type,
                chartGroup: this.props.item.chartGroup || [],
            }
        }, () => { this.props.onSetItem(this.state.item) } );

        this.setState({ loading: false });
    }

    async loadAllColumns() {
        let { colunas, colunasQuestionario } = await DiagnosticoHelper.getColunasQuestionario(null, this.props.idPeriodoAvaliacao, null, null);

        colunasQuestionario = colunasQuestionario.map((questionario) => {
            return {
                id: questionario.questionario.id,
                label: questionario.questionario.nome,
                options: questionario.colunas.map((coluna) => {
                    return {
                        value: coluna.id,
                        label: coluna.descricao.find((item) => item.id_idioma == IdiomaHelper.getSiglaId(this.props.lang))?.descricao ?? '',
                    }
                }),
            }
        });

        this.setState({ colunas, colunasQuestionario });
    }

    onDragStartItem = async (e, item) => {
        
        this.setState({ 
            draggingItem: item, 
            draggingOverItem: null, 
            widthDragItem: e.target.offsetWidth 
        });

        setTimeout(() => { e.target.style.display = "none" }, 5);
    }

    onDragEndItem = async (e, item) => {
        e.target.style.display = "flex";

        let charts = [...this.state.item.chartGroup];
        let draggingOverItem = this.state.draggingOverItem;
        
        if (draggingOverItem !== null) {
            charts.splice(charts.indexOf(this.state.draggingItem), 1);
            let indexToInsert = charts.indexOf(draggingOverItem);
            if(this.state.left) {
                charts.splice(indexToInsert, 0, item);
            } else {
                charts.splice(indexToInsert + 1, 0, item);
            }
        }

        charts = charts.map((chart, index) => {
            return {...chart, dragIndex: index + 1};
        });

        this.setState({ item: {...this.state.item, chartGroup: charts} }, () => {
            this.props.onSetItem(this.state.item);
            this.setState({ draggingItem: null, draggingOverItem: null, left: false });
        });
    }

    onDragEnterItem = async (e, item) => {

        let left = DragAndDropHelper.checkLeft(e);

        this.setState({ left });

        if (item.id !== this.state.draggingItem.id) {
            this.setState({ draggingOverItem: item });
        }
    }

    setNodeColuna = async (idColuna) => {
    
        if (this.state.colunaSelected != idColuna) {
    
            // Pega a coluna selecionada
            let coluna = this.state.colunas.find((coluna) => coluna.id == idColuna);

            // Seta as variaveis de lista de nodes e node selecionado
            let nodes = [], nodeSelected = null;

            // Se a coluna tiver pai, pega os itens da coluna pai como agrupador
            if (coluna.hasPai) { nodes = await DiagnosticoHelper.getItensColunaPai(idColuna, null); }

            this.setState({ colunaSelected: idColuna, nodes, nodeSelected });
        }
    }

    addChartToGroup = async () => {

        if (!this.state.colunaSelected) {
            return toast.error(this.props.i18n.t('client_src_components_modules_diagnostico_CriacaoRelatorio_AddModuloRelatorio_AddResultadoGraficos_AddResultadoGraficos:select_column'));
        }

        if (!this.state.widthSelected) {
            return toast.error(this.props.i18n.t('client_src_components_modules_diagnostico_CriacaoRelatorio_AddModuloRelatorio_AddResultadoGraficos_AddResultadoGraficos:select_width'));
        }

        if (this.state.colunas.find((coluna) => coluna.id == this.state.colunaSelected).hasPai && !this.state.nodeSelected) {
            return toast.error(this.props.i18n.t('client_src_components_modules_diagnostico_CriacaoRelatorio_AddModuloRelatorio_AddResultadoGraficos_AddResultadoGraficos:select_items'));
        }

        let chart = {
            id: this.state.item.chartGroup?.length > 0 
            ? Math.max(...this.state.item.chartGroup.map(chart => chart.id)) + 1 
            : 1,
            dragIndex: this.state.item.chartGroup?.length > 0 
            ? Math.max(...this.state.item.chartGroup.map(chart => chart.dragIndex)) + 1 
            : 1,
            largura: this.state.widthSelected,
            id_diagnostico_periodo_questionario_coluna: this.state.colunaSelected,
            coluna_nome: this.state.colunas.find((coluna) => coluna.id == this.state.colunaSelected).descricao,
            node: this.state.nodeSelected,
            node_nome: this.state.nodeSelected ? this.state.nodes.find((node) => node.id == this.state.nodeSelected).descricao : null,
        }

        let item = {
            ...this.state.item, 
            chartGroup: [...this.state.item?.chartGroup, chart]
        };

        this.setState({ item, nodeSelected: null }, () => { this.props.onSetItem(this.state.item); });
    }

    orderBy = (list, field) => {
        return list.sort((a, b) => {
            if (a[field] < b[field]) return -1;
            if (a[field] > b[field]) return 1;
            return 0;
        });
    }

    renderHeader = () => {
        return (
            <div className="headerClose" style={{backgroundColor: SessionHelper.getColor()}}>
                <div/>
                {DiagnosticoHelper.getFunctionsModulosModeloRelatorioByType(this.props.type, this.props.t)?.title ?? ''}
                <FontAwesomeIcon icon={faTimesCircle} style={{ cursor: 'pointer' }} onClick={() => this.props.onClose()} />
            </div>
        )
    }

    renderBody = () => {

        let item = this.state.item;

        const colunasByQuestionario = this.state.colunasQuestionario;
        const colunaSelcted = DataHelper.formatSelectDataLang(this.state.colunas, 'id', 'descricao', this.props.lang).find((coluna) => coluna.value == this.state.colunaSelected) || null;
        const coluna = this.state.colunaSelected ? this.state.colunas.find(coluna => coluna.id === this.state.colunaSelected) : null;
        
        const nodes = DataHelper.formatSelectDataLang(this.state.nodes, 'id', 'descricao', this.props.lang);
        const nodeSelected = DataHelper.formatSelectDataLang(this.state.nodes, 'id', 'descricao', this.props.lang).find((node) => node.value == this.state.nodeSelected) || [];

        const chartGroup = this.orderBy((item.chartGroup || []), 'dragIndex');
        
        return(
            <div className="bodyModulo">

                <div className="content box">

                    {/* Definição de Coluna */}
                    <>
                        <span>{this.props.i18n.t('client_src_components_modules_diagnostico_CriacaoRelatorio_AddModuloRelatorio_AddResultadoGraficos_AddResultadoGraficos:questionnaire_column')}</span>
                        <Select
                            options={colunasByQuestionario}
                            value={colunaSelcted}
                            onChange={(e) => { this.setNodeColuna(e.value) }}
                            styles={{
                                container: (provided) => ({
                                    ...provided,
                                    width: '100%',
                                    marginBottom: '20px'
                                }),
                                menu: (provided) => ({
                                    ...provided,
                                    zIndex: 9999
                                }),
                                menuPortal: (provided) => ({
                                    ...provided,
                                    zIndex: 9999
                                }),
                            }}
                            menuPortalTarget={document.body}
                            placeholder={this.props.i18n.t('client_src_components_modules_diagnostico_CriacaoRelatorio_AddModuloRelatorio_AddResultadoGraficos_AddResultadoGraficos:select_column')}
                            menuPlacement="auto"
                            menuPosition="fixed"
                        />
                    </>

                    {/* Definição de Itens */}
                    <>
                    {coluna != null &&
                        <>
                            <span>{this.props.i18n.t('client_src_components_modules_diagnostico_CriacaoRelatorio_AddModuloRelatorio_AddResultadoGraficos_AddResultadoGraficos:graph_items')}</span>
                            {coluna.hasPai ?
                                <Select
                                    options={nodes}
                                    value={nodeSelected}
                                    onChange={(e) => { this.setState({ nodeSelected: e.value }) }}
                                    styles={{
                                        container: (provided) => ({
                                            ...provided,
                                            width: '100%',
                                            marginBottom: '20px'
                                        }),
                                        menu: (provided) => ({
                                            ...provided,
                                            zIndex: 9999 // Certifique-se de que o menu tenha um z-index alto
                                        }),
                                        menuPortal: (provided) => ({
                                            ...provided,
                                            zIndex: 9999 // Certifique-se de que o portal tenha um z-index alto
                                        }),
                                    }}
                                    isClearable
                                    menuPortalTarget={document.body}
                                    placeholder={this.props.i18n.t('client_src_components_modules_diagnostico_CriacaoRelatorio_AddModuloRelatorio_AddResultadoGraficos_AddResultadoGraficos:select_item')}
                                    menuPlacement="auto"
                                    menuPosition="fixed"
                                />
                                :
                                <div style={{width: '100%', marginBottom: '20px', border: '1px solid hsl(0, 0%, 80%)', borderRadius: 4, padding: '5px 8px'}}>
                                    {this.props.i18n.t('client_src_components_modules_diagnostico_CriacaoRelatorio_AddModuloRelatorio_AddResultadoGraficos_AddResultadoGraficos:all_items')}
                                </div>
                            }
                        </>
                    }
                    </>

                    {/* Definição de Largura */}
                    <>
                        <span>{this.props.i18n.t('client_src_components_modules_diagnostico_CriacaoRelatorio_AddModuloRelatorio_AddResultadoGraficos_AddResultadoGraficos:graph_width')}</span>
                        <div style={{...EssentialStyle.rowSpaceBetween, width: '100%', marginBottom: 20}}>
                            <SelectWidth
                                onClick={(width) => this.setState({ widthSelected: width })}
                                widthSelected={this.state.widthSelected}
                            />
                            <DefaultButton
                                leftIcon={<FontAwesomeIcon icon={faPaperPlane}/>}
                                color={Colors.success}
                                tooltip={this.props.i18n.t('client_src_components_modules_diagnostico_CriacaoRelatorio_AddModuloRelatorio_AddResultadoGraficos_AddResultadoGraficos:add_to_group')}
                                onClick={() => this.addChartToGroup()}
                            />
                        </div>
                    </>

                </div>

                <div className="content box">
                    <span>{this.props.i18n.t('client_src_components_modules_diagnostico_CriacaoRelatorio_AddModuloRelatorio_AddResultadoGraficos_AddResultadoGraficos:added_graphs')}</span>
                    {chartGroup?.length > 0 ?
                        <div className="createdChartGroup">
                            {chartGroup?.map((chart) => {
                                return (
                                    <React.Fragment key={chart.id}>
                                        {this.renderGrupoGrafico(chart)}
                                    </React.Fragment>
                                )
                            })}
                        </div>
                        :
                        <div style={{width: '100%', marginBottom: 20, borderRadius: 4, padding: '5px 8px'}}>
                            {this.props.i18n.t('client_src_components_modules_diagnostico_CriacaoRelatorio_AddModuloRelatorio_AddResultadoGraficos_AddResultadoGraficos:no_graphs')}
                        </div>
                    }

                </div>
                
                <div className="buttons">
                    <DefaultButton
                        title={this.props.i18n.t('client_src_components_modules_diagnostico_CriacaoRelatorio_AddModuloRelatorio_AddResultadoGraficos_AddResultadoGraficos:save')}
                        leftIcon={<FontAwesomeIcon icon={faSave}/>}
                        color={Colors.success}
                        tooltip={this.props.i18n.t('client_src_components_modules_diagnostico_CriacaoRelatorio_AddModuloRelatorio_AddResultadoGraficos_AddResultadoGraficos:add_to_report')}
                        onClick={() => {
                            if(this.state.item.chartGroup.length > 0) {
                                this.props.addModule();
                            } else {
                                toast.error(this.props.i18n.t('client_src_components_modules_diagnostico_CriacaoRelatorio_AddModuloRelatorio_AddResultadoGraficos_AddResultadoGraficos:add_at_least_one'));
                            }
                        }}
                    />
                </div>
            </div>
        )
    }

    renderGrupoGrafico = (chart) => {
        return (
            <div 
                key={`chart-demonstration-${chart.id}`} 
                className="item-added"
                onDragStart={(e) => { this.onDragStartItem(e, chart) }}
                onDragEnter={(e) => { this.onDragEnterItem(e, chart) }}
                onDragEnd={(e) => { this.onDragEndItem(e, chart) }}
                draggable={true}
                style={{
                    overflow: 'hidden',
                    height: '5rem',
                    marginLeft: this.state.draggingOverItem && this.state.draggingOverItem.id === chart.id && this.state.left ? this.state.widthDragItem : 0,
                    marginRight: this.state.draggingOverItem && this.state.draggingOverItem.id === chart.id && !this.state.left ? this.state.widthDragItem : 0
                }}
            >
                <div className="line">
                    <FontAwesomeIcon 
                        icon={faBars} 
                        className="icon-drag"
                    />

                    <div className="chart-name">
                        <div className="column">{chart?.coluna_nome?.find((item) => item.id_idioma == IdiomaHelper.getSiglaId(this.props.lang))?.descricao ?? ''}</div>
                        <div className="description">{chart?.node_nome?.find((item) => item.id_idioma == IdiomaHelper.getSiglaId(this.props.lang))?.descricao ?? ''}</div>
                    </div>
                    
                    <FontAwesomeIcon 
                        icon={faTimesCircle} 
                        className="icon-delete" 
                        onClick={() => {
                            let chartGroup = this.state.item.chartGroup.filter((item) => item.id != chart.id);
                            this.setState({ item: {...this.state.item, chartGroup} }, () => { this.props.onSetItem(this.state.item) });
                        }}
                    />
                </div>

                <div className="bar-grupo">
                    <div className="bar-grupo-valor" style={{width: DiagnosticoHelper.getWidth(chart.largura), backgroundColor: SessionHelper.getColor() }}>
                        {DiagnosticoHelper.getWidth(chart.largura)}
                    </div>
                </div>
            </div>
        )
    }

    renderLoading() {
        return (
            <div className="bodyModulo" style={{justifyContent: 'center', alignItems: 'center', overflow: 'hidden', height: "calc(100% - 3rem)"}}>
                <DefaultLoader/>
            </div>
        )
    }

    renderContent() {

        return (
            <div className="ModuloConfig" style={{maxHeight: `calc(${this.state.headerHeight}px - 20px)`, minHeight: '50vh'}}>
                {this.renderHeader()}
                {!this.state.loading ? this.renderBody() : this.renderLoading()}
            </div>
        )
    }

    render() {
        if (!this.props.type) return <></>;
        return this.renderContent();
    }
}

export default withTranslation()(AddResultadoGraficos);