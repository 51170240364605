import React from "react";

import Colors from "../../../../constants/Colors";
import DefaultButton from "../../../../components/tools/DefaultButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusSquare } from "@fortawesome/free-solid-svg-icons";

import ListPeriodoAvaliacao from "./ListPeriodoAvaliacao/ListPeriodoAvaliacao";

import DefaultTableSearch from "../../../tools/DefaultTableSearch";
import IdiomaHelper from "../../../../helper/IdiomaHelper";
import DataHelper from "../../../../helper/DataHelper";
import Select from 'react-select';

import './PeriodoAvaliacao.css';
import DiagnosticoHelper from "../../../../helper/diagnostico/DiagnosticoHelper";

const searchableFields = ['nome', 'data_inicio', 'data_fim'];

export default class PeriodoAvaliacao extends React.Component {

    state = {
        lang: IdiomaHelper.getIdioma(),
        loadingPeriodosAvaliacao: true,
        periodosAvaliacao: [],
        countPeriodosAvaliacao: 0,
        canEdit: false,
        canView: false,
    }

    componentDidMount() {
        this.loadPeriodosAvaliacao();
    }

    loadPeriodosAvaliacao = async () => {
        
        this.setState({ loadingPeriodosAvaliacao: true });

        let periodos = [];
        let canEdit = false;
        let canView = false;

        if (this.props.idProcesso) {
            ({ periodos, canEdit, canView } = await DiagnosticoHelper.getPeriodosAvaliacao(this.props.idProcesso, 1));
        }

        this.setState({ periodosAvaliacao: periodos, countPeriodosAvaliacao: periodos?.lenght, canEdit, canView, loadingPeriodosAvaliacao: false });
    }

    renderHeader() {

        const message = IdiomaHelper.getStruct({
            'pt_br': { buttom: 'Adicionar Período de Avaliação' },
            'en': { buttom: 'Add Evaluation Period' },
            'es': { buttom: 'Agregar Período de Evaluación' },
        });

        return (
            <div className="periodo-avaliacao-header" style={{boxShadow: Colors.boxShadow}}>
               <div className="header-left">
                    <DefaultButton
                        title={message[this.state.lang].buttom}
                        leftIcon={<FontAwesomeIcon icon={faPlusSquare} />}
                        color={Colors.success}
                        onClick={() => { this.props.onShowWizard(true); }}
                        disabled={!this.state.canEdit}
                    />
               </div>

                <div className="header-right">
                    <DefaultTableSearch
                        fields={searchableFields} 
                        loading={this.state.loadingPeriodosAvaliacao}
                        searchObjects={true} 
                        data={this.state.periodosAvaliacao} 
                        onDataUpdate={(data) => { this.setState({ periodosAvaliacao: data }) }} 
                        onEmpty={() => { this.loadPeriodosAvaliacao() }}
                    />
                    <DefaultButton
                        style={{ width: 38, height: 38 }}
                        fontSize={this.props.isSmallScreen ? 14 : "unset"}
                        color={Colors.secondaryButton}
                        textColor={Colors.dark}
                        link={'https://advis.freshdesk.com/support/solutions/folders/63000238312'}
                    />
                </div>
            </div>
        )
    }

    renderListPeriodoAvaliacao() {
        return (
            <div className="periodo-avaliacao-list">
                <ListPeriodoAvaliacao 
                    loadingPeriodosAvaliacao={this.state.loadingPeriodosAvaliacao}
                    periodosAvaliacao={this.state.periodosAvaliacao}
                    countPeriodosAvaliacao={this.state.countPeriodosAvaliacao}
                    isSmallScreen={this.props.isSmallScreen} 
                    lang={this.state.lang}
                    onEdit={(idPeriodoAvaliacao) => {this.props.onShowWizard(idPeriodoAvaliacao)}}
                    onDelete={() => {this.loadPeriodosAvaliacao()}}
                    canEdit={this.state.canEdit}
                    canView={this.state.canView}
                />
            </div>
        )
    }

    render() {
        return (
            <div className="PeriodoAvaliacao">
                {this.renderHeader()}
                {this.renderListPeriodoAvaliacao()}
            </div>
        )
    }
}