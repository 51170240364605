import React from 'react';
import './Retrospectiva2024.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faChevronDown } from '@fortawesome/free-solid-svg-icons'
import EssentialStyle from '../../../../style/EssentialStyle';
import DefaultLoader from '../../../tools/DefaultLoader';
import Sig from '../../../../api/Sig';
import Colors from '../../../../constants/Colors';
import LayoutHelper from '../../../../helper/LayoutHelper';
import Constants from '../../../../constants/Api';
import RetrospectivaGMR2024 from '../RetrospectivaGMR2024/RetrospectivaGMR2024';
import RetrospectivaPlanoAcao2024 from '../RetrospectivaPlanoAcao2024/RetrospectivaPlanoAcao2024';
import RetrospectivaFeedback2024 from '../RetrospectivaFeedback2024/RetrospectivaFeedback2024';
import RetrospectivaPDI2024 from '../RetrospectivaPDI2024/RetrospectivaPDI2024';
import RetrospectivaMelhorMes2024 from '../RetrospectivaMelhorMes2024/RetrospectivaMelhorMes2024';
import RetrospectivaInteracoes2024 from '../RetrospectivaInteracoes2024/RetrospectivaInteracoes2024';
import RetrospectivaShare2024 from '../RetrospectivaShare2024/RetrospectivaShare2024';
import RetrospectivaExport2024 from '../RetrospectivaExport2024/RetrospectivaExport2024';

const gateway = Constants.getSigEndPoint();

export default class Retrospectiva2024 extends React.Component {
    state = {
        loading: true,
        isSmallScreen: LayoutHelper.isSmallScreen(),
        screenHeight: LayoutHelper.getScreenHeight(),
        colaboradorNome: ``,
        colaboradorSexo: ``,
        loadingPhrase: ``,
        gmr: {},
        interacoes: {},
        pdis: 0,
        feedbacks: {},
        resumo: {},
        planosAcao: {},
        scrollY: 0,
    }

    async componentDidMount() {
        this.setState({ loadingPhrase: this.getRandomLoadingPhrase() });

        let phraseInterval = setInterval(() => {
            this.setState({ loadingPhrase: this.getRandomLoadingPhrase() });
        }, 2500);

        let data = await Sig.request(`GET`, `retrospectiva/getRetrospectiva`);

        if (data?.status === 200) {
            delete data.status;

            this.setState({ loading: false, ...data }, () => {
                this.scrollOneAtTime();
                clearInterval(phraseInterval);
            });
        } else {
            this.exitPage();
        }

        window.addEventListener('resize', this.verifyScreen);
    }

    verifyScreen = () => {
        this.setState({ isSmallScreen: LayoutHelper.isSmallScreen(), screenHeight: LayoutHelper.getScreenHeight() });
    }

    scrollOneAtTime = () => {
        let isScrolling = false;
        const scrollTimeout = 500;

        const handleScroll = (deltaY) => {
            if (isScrolling) {
                return;
            }

            isScrolling = true;

            // if (deltaY > 0) {
            //     this.scrollNext();
            // } else {
            //     this.scrollPrev();
            // }

            setTimeout(() => {
                isScrolling = false;
                this.setState({ scrollY: window.scrollY });
            }, scrollTimeout);
        };

        if(!this.state.isSmallScreen) {
            document.addEventListener('wheel', (e) => {
                e.preventDefault();
                handleScroll(e.deltaY);
            });
    
            document.addEventListener('keydown', (e) => {
                e.preventDefault();
    
                if (e.key === 'ArrowDown' || e.key === 'ArrowRight') {
                    this.scrollNext();
                } else if (e.key === 'ArrowUp' || e.key === 'ArrowLeft') {
                    this.scrollPrev();
                }
            });
        }
    }

    exitPage = () => {
        window.parent.postMessage({ type: `navigate`, url: `/` }, '*');
    }

    scrollPrev = () => {
        const currentScroll = window.scrollY;
        const prevScroll = currentScroll - this.state.screenHeight;
        window.scrollTo({
            top: prevScroll,
            behavior: 'smooth'
        });

        this.setState({ scrollY: prevScroll });
    }

    scrollNext = () => {
        const currentScroll = window.scrollY;
        const nextScroll = currentScroll + this.state.screenHeight;
        window.scrollTo({
            top: nextScroll,
            behavior: 'smooth'
        });

        this.setState({ scrollY: nextScroll });
    }

    getDegradeColor = (color) => {
        return `linear-gradient(0deg, ${color} 0%, ${color} 100%)`;
    }

    getRandomLoadingPhrase = () => {
        const phrases = [
            "Calculando o ROI do café da manhã...",
            "Alinhando os KPIs com a máquina de café...",
            "Ajustando a estratégia de crescimento do happy hour...",
            "Sincronizando com o humor do chefe...",
            "Revisando o plano de ação para a sexta-feira...",
            "Calculando o impacto dos memes na produtividade...",
            "Analisando a eficiência das piadas internas...",
            "Gerando relatórios de produtividade dos intervalos...",
            "Ajustando a estratégia de engajamento dos almoços...",
            "Calculando o valor agregado das reuniões de corredor...",
            "Sincronizando com a última tendência de GIFs...",
            "Avaliando o impacto dos emojis nos e-mails...",
            "Preparando a análise SWOT do lanche da tarde...",
            "Otimizando a pipeline de ideias brilhantes...",
            "Revisando a matriz de riscos das piadas ruins...",
            "Calculando o NPS dos memes do Slack...",
            "Ajustando a estratégia de retenção dos bons momentos..."
        ];
    
        return phrases[Math.floor(Math.random() * phrases.length)];
    }

    renderLoading() {
        return (
            <div
                className='loading-gradient'
                style={{ ...EssentialStyle.columnCenter, width: '100%', minHeight: '100.1vh' }}
            >
                {this.getMainImage()}
                <DefaultLoader color={Colors.background} />
                <span className='shine retro-default-text' style={{ marginTop: 20 }}><b>Preparando sua retrospectiva do SIG...</b></span>
                <span className='shine retro-default-text' style={{ marginTop: 20, fontSize: '1rem' }}>{this.state.loadingPhrase}</span>
            </div>
        )
    }

    goToSite() {
        window.parent.postMessage({ type: `navigate`, url: `https://grupoadvis.com.br` }, '*');
    }

    renderLogo() {
        if(this.state.scrollY < (this.state.screenHeight - 100)) {
            return null;
        }

        return (
            <div
                className='slide-in'
                style={{
                    position: 'fixed',
                    left: 10,
                    top: 10,
                    color: Colors.light,
                    cursor: 'pointer',
                    userSelect: 'none'
                }}
                onClick={this.exitPage}
            >
                <img
                    src={`${gateway}/img/retrospectiva/retrosig24_reduced.png`}
                    alt="Logo da Retrospectiva SIG 2024, com elementos geométricos a esquerda e RetroSIG escrito em uma fonte moderna a direita."
                    style={{
                        height: 48
                    }}
                />
            </div>
        );
    }

    renderAdvisLogo() {
        if(this.state.scrollY < (this.state.screenHeight - 100)) {
            return null;
        }

        return (
            <div
                className='slide-in'
                style={{
                    position: 'fixed',
                    right: 60,
                    top: 20,
                    color: Colors.light,
                    cursor: 'pointer',
                    userSelect: 'none'
                }}
                onClick={this.goToSite}
            >
                <img
                    src={`${gateway}/img/retrospectiva/retrosig24_advis.png`}
                    alt="Logo da Retrospectiva SIG 2024, com elementos geométricos a esquerda e RetroSIG escrito em uma fonte moderna a direita."
                    style={{
                        height: 28
                    }}
                />
            </div>
        );
    }

    renderExit() {
        return (
            <div
                style={{
                    position: 'fixed',
                    height: 28,
                    width: 28,
                    right: 10,
                    top: 10,
                    color: Colors.light,
                    cursor: 'pointer',
                    userSelect: 'none'
                }}
                onClick={this.exitPage}
            >
                <FontAwesomeIcon icon={faTimes} style={{ fontSize: 28, userSelect: 'none' }} />
            </div>
        );
    }

    renderNext() {
        if (this.state.scrollY >= (this.state.screenHeight * 8)) {
            return null;
        }

        return (
            <div
                style={{
                    position: 'fixed',
                    height: 28,
                    width: 28,
                    bottom: 10,
                    color: Colors.light,
                    cursor: 'pointer',
                    userSelect: 'none'
                }}
                onClick={this.scrollNext}
            >
                <FontAwesomeIcon icon={faChevronDown} style={{ fontSize: 28, userSelect: 'none' }} className='bounce' />
            </div>
        );
    }

    getMainImage() {
        let imgStyle = {};

        if (this.state.isSmallScreen) {
            imgStyle = {
                width: window.innerWidth * 0.8
            }
        } else {
            imgStyle = {
                height: this.state.screenHeight * 0.35
            }
        }

        return (
            <img
                src={`${gateway}/img/retrospectiva/retrosig24.png`}
                alt="Logo da Retrospectiva SIG 2024, com elementos geométricos a esquerda e RetroSIG escrito em uma fonte moderna a direita."
                style={{
                    ...imgStyle
                }}
            />
        );
    }

    renderIntro() {
        return (
            <div
                style={{
                    ...EssentialStyle.columnCenter,
                    width: '100%',
                    height: this.state.screenHeight
                }}
            >
                <div className='slide-in'>{this.getMainImage()}</div>
                <span
                    className='retro-intro-text slide-in'
                >
                    Olá, {this.state.colaboradorNome}! Que ano cheio de desafios, hein? Vamos dar uma olhada na sua jornada em 2024 e celebrar as conquistas que você construiu ao longo do caminho. {this.state?.colaboradorSexo == "Feminino" ? "Preparada" : "Preparado"} para a retrospectiva?
                </span>
            </div>
        );
    }

    renderGmr() {
        return <RetrospectivaGMR2024 gmr={this.state.gmr} screenHeight={this.state.screenHeight} isSmallScreen={this.state.isSmallScreen} />;
    }

    renderPlanoAcao() {
        return this.state?.planosAcao?.qtdPlanosAcao > 0 ? <RetrospectivaPlanoAcao2024 planosAcao={this.state.planosAcao} screenHeight={this.state.screenHeight} isSmallScreen={this.state.isSmallScreen} /> : null;
    }

    renderFeedbacks() {
        return this.state?.feedbacks?.total > 0 && (this.state?.feedbacks?.enviados > 0 || this.state?.feedbacks?.recebidos > 0) ? <RetrospectivaFeedback2024 feedbacks={this.state.feedbacks} screenHeight={this.state.screenHeight} isSmallScreen={this.state.isSmallScreen} /> : null;
    }

    renderPDIs() {
        return this.state?.pdis > 0 ? <RetrospectivaPDI2024 pdis={this.state.pdis} screenHeight={this.state.screenHeight} isSmallScreen={this.state.isSmallScreen} /> : null;
    }

    renderMelhorMes() {
        return this.state?.gmr?.melhorMes && this.state?.gmr?.resultadoGMR?.resultado ? <RetrospectivaMelhorMes2024 melhorMes={this.state?.gmr?.melhorMes} screenHeight={this.state.screenHeight} isSmallScreen={this.state.isSmallScreen} /> : null;
    }

    renderInteracoes() {
        return this.state?.interacoes?.total > 0 ? <RetrospectivaInteracoes2024 interacoes={this.state.interacoes} screenHeight={this.state.screenHeight} isSmallScreen={this.state.isSmallScreen} /> : null;
    }

    renderShare() {
        return <RetrospectivaShare2024 arquetipo={this.state?.arquetipo} screenHeight={this.state.screenHeight} isSmallScreen={this.state.isSmallScreen} />;
    }

    renderExport() {
        return <RetrospectivaExport2024 exitCallback={this.exitPage} resumo={this.state?.resumo} screenHeight={this.state.screenHeight} isSmallScreen={this.state.isSmallScreen} />;
    }

    renderContent() {
        return (
            <div
                className='retro-gradient'
                style={{
                    ...EssentialStyle.columnCenter,
                    width: '100%',
                    color: '#E8E8E4'
                }}
            >
                {this.renderExit()}
                {!this.state.isSmallScreen ? this.renderNext() : null}
                {this.renderLogo()}
                {this.renderAdvisLogo()}

                {this.renderIntro()}
                {this.state?.gmr?.paineisGMR?.length > 0 ? this.renderGmr() : null}
                {this.renderPlanoAcao()}
                {this.renderFeedbacks()}
                {this.renderPDIs()}
                {this.renderMelhorMes()}
                {this.renderInteracoes()}
                {this.renderShare()}
                {this.renderExport()}
            </div>
        )
    }

    render() {
        return (
            <div style={{ ...EssentialStyle.columnCenter, width: '100%' }}>
                {this.state.loading ? this.renderLoading() : this.renderContent()}
            </div>
        );
    }
}