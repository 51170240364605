import React from "react";
import './ListProcessos.css';
import DiagnosticoHelper from "../../../../../helper/diagnostico/DiagnosticoHelper";
import DefaultLoader from '../../../../tools/DefaultLoader';
import IdiomaHelper from "../../../../../helper/IdiomaHelper";
import ProcessoCard from "../ProcessoCard/ProcessoCard";
import DefaultButton from "../../../../tools/DefaultButton";
import Colors from "../../../../../constants/Colors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusSquare } from "@fortawesome/free-solid-svg-icons";
import { withTranslation } from 'react-i18next';

class ListProcessos extends React.Component {

    state = {
        loadingProcessos: this.props.loadingProcessos || true,
        processos: this.props.processos || [],
    }

    componentDidUpdate(prevProps) {

        if (prevProps.processos !== this.props.processos) {
            this.setState({ processos: this.props.processos });
        }

        if (prevProps.loadingProcessos !== this.props.loadingProcessos) {
            this.setState({ loadingProcessos: this.props.loadingProcessos });
        }
    }

    async componentDidMount() {
        await this.props.i18n.loadNamespaces(['client_src_helper_diagnostico_DiagnosticoHelper']);

        if (!this.props.processos) {
            this.loadProcessos();
        }
    }

    loadProcessos = async () => {
        
        this.setState({ loadingProcessos: true });

        let processos = [];

        if (!this.props.processos) {
            let response = await DiagnosticoHelper.getProcessos();
            processos = response.processos;
        }

        this.setState({ processos, loadingProcessos: false });
    }

    renderLoading() {

        const message = IdiomaHelper.getStruct({
            'pt_br': { loading: 'Carregando Processos...' },
            'en': { loading: 'Loading Processes...' },
            'es': { loading: 'Cargando Procesos...' },
        });

        return (
            <div className={"ListProcessos loading"}>
                <DefaultLoader title={message[this.props.lang].loading}/>
            </div>
        );
    }

    renderEmptyList() {

        const message = IdiomaHelper.getStruct({
            'pt_br': { empty: 'Nenhum Processo existente, crie um para iniciar!', emptySearch: 'Nenhum Processo encontrado', buttom: 'Adicionar Processo' },
            'en': { empty: 'No existing Process, create one to start!', emptySearch: 'No Process found', buttom: 'Add Process' },
            'es': { empty: '¡No existe ningún Proceso, crea uno para comenzar!', emptySearch: 'No se encontró ningún Proceso', buttom: 'Agregar Proceso' },
        });

        if (this.props.countProcessos == 0) return (
            <div className={"ListProcessos emptyList"}>
                {DiagnosticoHelper.renderEmpty(message[this.props.lang].empty,  {height: '17rem', marginBottom: '1rem'}, {height: '15rem'}, this.props.t)}
                <DefaultButton
                    title={message[this.props.lang].buttom}
                    leftIcon={<FontAwesomeIcon icon={faPlusSquare} />}
                    color={Colors.success}
                    onClick={() => { this.props.onEdit(true); }}
                    disabled={!this.props.canEdit}
                />
            </div>
        );

        return (
            <div className={"ListProcessos emptyList"}>
                {DiagnosticoHelper.renderEmptySearch(message[this.props.lang].emptySearch,  {height: '17rem', marginBottom: '1rem'}, {height: '15rem'}, this.props.t)}
            </div>
        );
    }

    renderListProcessos() {
        return (
            <>
                {this.state.processos.map((processo, index) => {
                    return (
                        <ProcessoCard 
                            key={`card-processo-${processo.id}`} 
                            processo={processo} 
                            lang={this.props.lang} 
                            onDelete={() => { !this.props.processos ? this.loadPeriodosAvaliacao() : this.props.onDelete() }}
                            onEdit={(idProcesso) => { this.props.onEdit(idProcesso) }}
                        />
                    );
                })}
            </>
        )
    }

    render() {

        if (this.state.loadingProcessos) return this.renderLoading();
        if (this.state.processos.length === 0) return this.renderEmptyList();
        return this.renderListProcessos();
    }
}

export default withTranslation()(ListProcessos);