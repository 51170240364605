import React, { Component } from 'react';
import Constants from "../../constants/Api";
import Sig from '../../api/Sig';
import DefaultButton from './DefaultButton';

const gateway = Constants.getSigEndPoint();

export default class HeaderSimple extends Component {

    render() {
        return (
            <header
                style={{
                    display: this.props.display ? this.props.display : 'block',
                    position: "static",
                    height: "65px",
                }}
            >
                <div style={{
                    position: 'fixed', display: 'flex', justifyContent: 'center',
                    top: 0, left: 0, right: 0,
                    borderBottom: '1px solid rgba(0,0,0,.2)',
                    boxShadow: '0 0 4px rgba(0,0,0,.2)',
                    height: '65px',
                    backgroundColor: this.props.pageStyles.headerColor ? this.props.pageStyles.headerColor : '#FFFFFF'
                }}>
                    <a 
                        style={{
                            position: 'relative',
                            margin: '0 auto',
                            cursor: 'pointer',
                            padding: 15,
                            placeContent: 'center',
                        }} 
                        onClick={() => window.parent.postMessage({ type: 'navigate', url: `/` }, '*')}
                    >
                        <img src={`${gateway}/${ this.props.logo ? this.props.logo : 'img/logo-advis-black.png'}`} alt="Logo Empresa" height="32" />
                    </a>
                    {this.props.children}
                </div>
            </header>
        );
    }
}