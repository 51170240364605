import { faAdd, faCancel, faCheck, faDownLeftAndUpRightToCenter, faSave, faTimes, faUpRightAndDownLeftFromCenter, faUserCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Colors from "../../../../constants/Colors"; 
import React from "react";
import EssentialStyle from "../../../../style/EssentialStyle";
import { Col, Form, Image, Modal } from 'react-bootstrap'
import DefaultLoader from "../../../tools/DefaultLoader";
import SwotHelper from "../../../../helper/pe/SwotHelper";
import KeyboardHelper from "../../../../helper/KeyboardHelper";
import DefaultButton from "../../../tools/DefaultButton";
import SessionHelper from "../../../../helper/SessionHelper";
import DataHelper from "../../../../helper/DataHelper";
import { toast } from "react-toastify";
import SwotItem from "./SwotItem";
import LayoutHelper from "../../../../helper/LayoutHelper";
import moment from "moment";
import Sig from "../../../../api/Sig";
import DragAndDropHelper from "../../../../helper/DragAndDropHelper";

export default class SwotQuadrant extends React.Component {
    state = {
        loading: true,
        items: [],
        progressOrder: {},
        description: '',
        adding: false,
        lowestIndex: 0,
        expand: false,
        flipValuesSmallDevice: LayoutHelper.flipValuesSmallDevice('50vh', '70vh')
    }

    async componentDidMount() {
        await this.loadData();
        window.addEventListener('resize', this.verifyScreen);
    }

    verifyScreen = () => {
        this.setState({
            flipValuesSmallDevice: LayoutHelper.flipValuesSmallDevice('50vh', '70vh')
        });
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.verifyScreen);
    }


    async componentDidUpdate(prevProps, prevState) {

        if (prevProps.orderBy !== this.props.orderBy) {

            await this.loadData();
        }
    }

    async loadData() {

        this.setState({ loading: true });

        // document.body.style.overflow = 'hidden';

        let orderBy = '';

        if (this.props.orderBy && this.props.orderBy !== 'points') {
            orderBy = `${this.props.orderBy} ASC`;
        }

        let items = (await Sig.request('GET', 'pe/swot/getQuadranteItens', { id: this.props.id, order: orderBy }))?.quadrante_itens;

        if(!items) items = [];

        items.forEach(item => {
            if (item.drag_index < this.state.lowestIndex) this.setState({ lowestIndex: item.drag_index });
        });

        this.setState({ items }, () => { this.setState({ loading: false }) });
    }

    addSwotItem = async () => {
        if (!this.state.name) return toast.info('Informe o nome da TAG');

        this.setState({ loading: true });

        await Sig.request('POST', 'pe/swot/addQuadranteItem', { id_pe_swot_quadrante: this.props.id, id_pe_swot: this.props.id_swot, drag_index: this.state.lowestIndex, descricao: this.state.name });
        await this.loadData();

        this.setState({ name: '', adding: false }, () => { this.setState({ loading: false }) });
    }

    renderCardHeader(title, color) {
        return (
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', padding: '5px 10px 5px 10px', backgroundColor: color, color: Colors.light, fontWeight: 'bold', width: '100%', borderRadius: '5px 5px 0px 0px' }}>
                {title}
                <div onClick={() => { this.props.expanded ? this.props.expandedCallback() : this.setState({ expand: true }) }} style={{ cursor: 'pointer' }}>
                    { this.props.expanded ? <FontAwesomeIcon icon={faDownLeftAndUpRightToCenter} /> : <FontAwesomeIcon icon={faUpRightAndDownLeftFromCenter} /> }
                </div>
            </div>
        );
    }

    renderEmpty() {
        return (
            <div style={{ ...EssentialStyle.columnCenter, height: this.props.maxHeight ? this.props.maxHeight : ''}}>
                <div style={{ ...EssentialStyle.rowFlexStart, color: Colors.swot.addButton }}>
                    <span style={{ marginRight: 8 }}>Clique em</span>
                    <FontAwesomeIcon icon={faAdd} />
                    <span style={{ marginLeft: 8 }}>para adicionar um novo item.</span>
                </div>
            </div>
        );
    }

    renderCardFooter() {
        return this.state.adding ?
            <div style={{ ...EssentialStyle.rowFlexStart, padding: 5, letterSpacing: 1.1, color: Colors.swot.addButton, fontSize: 12, fontWeight: 'bold', width: 'calc(100% - 20px)', borderTop: `.5px solid ${Colors.swot.addButton}`, cursor: 'pointer', marginLeft: '10px', marginRight: '10px' }}>
                <Form.Control ref={ref => this.addSwotItemInput = ref} className="w-100 me-1" type="text" 
                value={this.state.name}
                placeholder="Descrição do item..." 
                onChange={(event) => { this.setState({ name: event.target.value }) }}
                onKeyDown={(evt) => { KeyboardHelper.handleShortcut(evt, ["Enter", "Escape"], [this.addSwotItem, (e) => { e.preventDefault(); this.setState({ name: '', adding: false }); }]) }} />
                <DefaultButton width={48} className="me-1" leftIcon={<FontAwesomeIcon icon={faTimes} />} color={Colors.swot.addButton} loading={this.state.loading} onClick={() => { this.setState({ name: '', adding: false }) }} />
                <DefaultButton width={48} leftIcon={<FontAwesomeIcon icon={faCheck} />} color={Colors.swot.addButton} loading={this.state.loading} onClick={this.addSwotItem} />
            </div>
            :
            <div onClick={() => { this.setState({ adding: true }, () => { this.addSwotItemInput.focus(); }); }} style={{ ...EssentialStyle.rowFlexStart, padding: 8, letterSpacing: 1.1, color: Colors.swot.addButton, fontSize: 12, fontWeight: 'bold', width: 'calc(100% - 20px)', borderTop: `.5px solid ${Colors.swot.addButton}`, cursor: 'pointer', marginLeft: '10px', marginRight: '10px' }}>
                <FontAwesomeIcon icon={faAdd} />
                <span style={{ marginLeft: 8 }}>ADICIONAR ITEM</span>
            </div>
            ;
    }

    renderContent() {
        if (this.state.loading) return <DefaultLoader color={Colors.swot[SwotHelper.getSwotKey(this.props.name)]} />;
        if (!this.state.loading && !this.state.items.length) return this.renderEmpty();

        let items = this.state.items;

        if (this.props.orderBy === 'points') {
            items.sort((a, b) => {
                let orderA = this.state.progressOrder[a.id] || 0;
                let orderB = this.state.progressOrder[b.id] || 0;

                return orderB - orderA;
            })
        }

        return (
            <div
                style={{
                    ...EssentialStyle.columnStart,
                    overflowY: 'auto',
                    overflowX: 'hidden',
                    minWidth: '100%',
                    padding: 5,
                    paddingLeft: 8,
                    height: this.props.maxHeight || `calc(${this.state.flipValuesSmallDevice} - 137px)`
                }}>
                {items.map((item, key) => {
                    return (
                        <SwotItem
                            key={`${key}-${item.id}`}
                            orderCallback={async (points) => {
                                let order = this.state.progressOrder;
                                order[item.id] = points;

                                if(this.state.progressOrder[item.id] && this.state.progressOrder[item.id] === points) return;

                                this.setState({ progressOrder: order });
                            }}
                            deleteCallback={() => { this.loadData() }}
                            id_swot={this.props.id_swot}
                            id={item.id}
                            item={item}
                            color={Colors.swot[SwotHelper.getSwotKey(this.props.name)]}
                            dragControl={this.props.dragControl}
                            onDragStart={(e) => { this.props.onDragStartItem(e, item); }}
                            onDragEnd={(e) => { this.props.onDragEndItem(e, item); }}
                            onDragEnter={(e) => { this.props.onDragEnterItem(e, item); }}
                            gutMaxValue={this.props.gutMaxValue}
                            expanded={this.props.expanded?true:false}  
                        />
                    );
                })}
            </div>
        );
    }

    renderExpand() {
        return (
            <Modal
                show={this.state.expand}
                fullscreen={true}
                centered
                backdrop={""}
                style={{ zIndex: 9 }}
            >
                <SwotQuadrant
                    {...this.props}
                    expanded={true}
                    expandedCallback={() => { this.setState({ expand: false }); this.loadData(); }}
                    maxHeight={'88vh'}
                />
            </Modal>
        );
    }

    render() {
        return this.state.expand ? this.renderExpand() : (
            <div
                onDragOver={(e) => { this.props.onDragOverQuadrant(e, this.props.id); }}
                onDrop={(e) => { 
                    e.preventDefault();
                    let top = DragAndDropHelper.checkTop(e);
                    
                    this.props.onDropQuadrant(e, this.props.id, top, () => { this.loadData() }); 
                }}
                style={this.props.expanded ? { overflowY: 'auto', backgroundColor: this.props.dragControl.draggingOverQuadrant === this.props.id ? Colors.notSelected : Colors.secondaryButton } : { ...EssentialStyle.rowSpaceBetween, ...style.swotCard, backgroundColor: this.props.dragControl.draggingOverQuadrant === this.props.id ? Colors.notSelected : Colors.secondaryButton }}>
                {this.renderCardHeader(this.props.name, Colors.swot[SwotHelper.getSwotKey(this.props.name)])}
                {this.renderContent()}
                {this.renderCardFooter()}
            </div>
        );
    }
}

const style = {
    swotCard: {
        flexDirection: 'column',
        justifyContent: 'space-between',
        backgroundColor: Colors.secondaryButton,
        borderRadius: 5,
        margin: '10px 10px 0px 10px',
        minHeight: 'inherit',
        width: '100%',
        height: '100%',
        boxShadow: 'rgba(50, 50, 50, 0.1) 1px 1px 10px 0px',
        border: '0.3px solid lightgrey'
    },
    swotCardWrapper: {
        height: '100%',
        width: '100%',
        overflowY: 'auto'
    }
}