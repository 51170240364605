import React from "react";

import './RetrospectivaGMR2024.css';
import EssentialStyle from "../../../../style/EssentialStyle";
import GaugeCircle from "../../../tools/GaugeCircle/GaugeCircle";
import EllipsisText from "../../../tools/EllipsisText";

export default class RetrospectivaGMR2024 extends React.Component {
    state = {
        gmr: this.props.gmr,
        screenHeight: this.props.screenHeight,
        isSmallScreen: this.props.isSmallScreen
    }

    async componentDidUpdate(prevProps) {
        if (prevProps.gmr !== this.props.gmr) {
            this.setState({ gmr: this.props.gmr });
        }

        if (prevProps.screenHeight !== this.props.screenHeight) {
            this.setState({ screenHeight: this.props.screenHeight });
        }

        if (prevProps.isSmallScreen !== this.props.isSmallScreen) {
            this.setState({ isSmallScreen: this.props.isSmallScreen });
        }
    }

    renderConditionalText() {
        let resultado = this.state.gmr?.resultadoGMR?.resultado;
        let metas = this.state.gmr?.metasSuperadas;

        if (resultado >= 80) {
            return (
                <span
                    className='retro-default-text'
                    style={{
                        width: this.state.isSmallScreen ? '100%' : '70%'
                    }}
                >
                    Você não apenas traçou metas – você as superou! {metas > 0 ? <b>Esse ano você superou {metas > 1 ? `${metas} metas` : `${metas} meta`}!</b> : ``} A cada objetivo alcançado, você mostrou do que é capaz. Tem coisa melhor do que riscar metas da lista e ir além? Parabéns por cada vitória conquistada!
                </span>
            );
        }

        if (resultado < 80 && metas > 0) {
            return (
                <span
                    className='retro-default-text'
                    style={{
                        width: this.state.isSmallScreen ? '100%' : '70%'
                    }}
                >
                    2024 se mostrou um ano desafiador frente às metas estabelecidas, <b>mas ainda assim você atingiu {metas > 1 ? `${metas} metas` : `${metas} meta`}.</b> Use os aprendizados para redefinir seus objetivos e seguir com ainda mais determinação no próximo ano.
                </span>
            );
        }

        return (
            <span
                className='retro-default-text'
                style={{
                    width: this.state.isSmallScreen ? '100%' : '70%'
                }}
            >
                2024 se mostrou um ano muito desafiador, e suas metas acabaram não sendo atingidas. Use os aprendizados para redefinir seus objetivos e seguir com ainda mais determinação no próximo ano.
            </span>
        );
    }

    renderGmr() {
        let treatedResult = this.state.gmr?.resultadoGMR?.resultado.toFixed(1).replace('.', ',');

        let gaugesRow = this.state.gmr?.resultadoPaineisGMR?.map((painel, index) => {
            return (
                <div key={`group-${index}`} style={{
                    ...EssentialStyle.rowSpaceEvenly,
                    width: '100%',
                    height: `100%`,
                }}>
                    <div style={{
                        ...EssentialStyle.columnCenter,
                        width: '100%',
                        height: '100%',
                    }}>
                        <div style={{ display: painel.nome ? '' : 'none', marginBottom: 20 }}>
                            <EllipsisText text={painel.nome} />
                        </div>
                        <GaugeCircle
                            min={0}
                            max={100}
                            color={painel.cor}
                            textColor={'#E8E8E4'}
                            progress={painel.resultado}
                            title={''}
                        />
                    </div>
                </div>
            );
        });

        return (
            <div
                style={{
                    ...EssentialStyle.columnCenter,
                    width: '100%',
                }}
            >
                <div
                    style={{
                        ...EssentialStyle.rowSpaceBetween,
                        flexDirection: this.state.isSmallScreen ? 'column-reverse' : 'row',
                        width: this.state.isSmallScreen ? '100%' : '70%',
                    }}
                >
                    {this.renderConditionalText()}
                    <div
                        style={{
                            ...EssentialStyle.columnCenter,
                            width: this.state.isSmallScreen ? '100%' : '30%'
                        }}
                    >
                        <span
                            style={{
                                fontSize: '3rem',
                                fontWeight: '800'
                            }}
                        >
                            {treatedResult}%
                        </span>
                        <span
                            style={{
                                fontSize: '1.1rem',
                                fontWeight: '600'
                            }}
                        >
                            Performance Geral
                        </span>
                    </div>
                </div>
                <div
                    style={{
                        ...EssentialStyle.rowFlexCenter,
                        flexDirection: this.state.isSmallScreen ? 'column' : 'row',
                        width: this.state.isSmallScreen ? '100%' : '70%',
                        height: this.state.isSmallScreen ?  window.innerHeight : window.innerHeight * 0.35,
                        marginTop: 50
                    }}
                >
                    {gaugesRow}
                </div>
            </div>
        );
    }

    render() {
        return (
            <div
                style={{
                    ...EssentialStyle.columnCenter,
                    width: '100%',
                    height: this.state.isSmallScreen ? 'auto' : this.state.screenHeight
                }}
            >
                {this.renderGmr()}
            </div>
        );
    }
}