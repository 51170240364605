export default class LayoutHelper {
    static isSmallScreen() {
        return window.innerWidth < 689;
    }

    static flipDirectionSmallDevice(direction) {
        return LayoutHelper.isSmallScreen() && direction === 'row' ? 'column' : 'row';
    }

    static flipValuesSmallDevice(onBig, onSmall) {
        return LayoutHelper.isSmallScreen() ? onSmall : onBig;
    }

    static setSliderColors(gradient, id = false) {

        let rangeInput;

        if (id) {
            rangeInput = document.getElementById(id);
        } else {
            rangeInput = document.querySelector("input[type='range']");
        }

        if(!gradient) gradient = 'linear-gradient(to right, #EEEEEE 20%, #DDDDDD 20%, #DDDDDD 40%, #CCCCCC 40%, #CCCCCC 60%, #AAAAAA 60%, #AAAAAA 80%, #888888 80%, #888888 100%)';

        if (rangeInput) {
            rangeInput.style.background = gradient;
        }
    }

    static darkenColor(color, percent) {
        var num = parseInt(color.replace("#",""), 16),
            amt = Math.round(2.55 * percent),
            R = (num >> 16) - amt,
            B = (num >> 8 & 0x00FF) - amt,
            G = (num & 0x0000FF) - amt;
        return "#" + (0x1000000 + (R<255?R<1?0:R:255)*0x10000 + (B<255?B<1?0:B:255)*0x100 + (G<255?G<1?0:G:255)).toString(16).slice(1);
    }

    static getScreenHeight() {
        return window.innerHeight;
    }
}