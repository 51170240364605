import React from "react";

import './ModuleRelatorio.css';

// Componente que gera o o botão de módlo a ser adicionado ao relatório
export default class ModuleRelatorio extends React.Component {

    state = {
        
    }

    render() {
        return(
            <div 
                className={`ModuleRelatorio modulo ${this.props.selected ? 'selected' : ''}`} 
                onClick={() => {this.props.onClick && this.props.onClick()}}
            >
                {this.props.modulo.icon}
                <div style={{textAlign: 'center'}}>{this.props.modulo.title}</div>
            </div>
        )
    }
}