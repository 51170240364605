import React, { forwardRef, useRef, useImperativeHandle } from "react";

import EssentialStyle from "../../../../style/EssentialStyle";
import SessionHelper from "../../../../helper/SessionHelper";
import Colors from "../../../../constants/Colors";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faEnvelope, faCheck, faEdit, faCog, faTrash, faLink, faPaperclip, faClockRotateLeft, faPrint, faFile, faFileLines } from '@fortawesome/free-solid-svg-icons';
import DefaultButton from "../../../tools/DefaultButton";
import TooManyTags from "../../../tools/TooManyTags";
import TooManyAvatars from "../../../tools/TooManyAvatars";
import AtaReuniaoStatus from "../AtaReuniaoStatus";
import CustomTooltip from "../../../tools/CustomTooltip";
import moment from "moment";
import Form from "react-bootstrap/Form";


class AtaReuniaoHeader extends React.Component {
    
    componentDidUpdate(prevProps) {
        if(prevProps.scrolled != this.props.scrolled && this.props.innerRef?.current) {
            this.props.innerRef.current.animate(
                [
                    { opacity: 0, visibility: 'hidden', height: 'auto' },
                    { opacity: 1, visibility: 'visible', height: 'auto' }
                ],
                { duration: 300, fill: 'forwards', easing: 'ease-in-out' }
            )
        }
    }

    renderTipo = () => {
        let tipos = [
            { color: Colors.ataReuniao.tipo.backgroundColor[0], text: `Conselho`, description: `Conselho`, key: 0, textColor: Colors.light },
            { color: Colors.ataReuniao.tipo.backgroundColor[1], text: `Comitê`, description: `Comitê`, key: 4, textColor: Colors.light },
            { color: Colors.ataReuniao.tipo.backgroundColor[2], text: `Diretoria`, description: `Diretoria`, key: 1, textColor: Colors.light },
            { color: Colors.ataReuniao.tipo.backgroundColor[3], text: `Gerencial`, description: `Gerencial`, key: 2, textColor: Colors.light },
            { color: Colors.ataReuniao.tipo.backgroundColor[4], text: `Operacional`, description: `Operacional`, key: 3, textColor: Colors.light },
            { color: Colors.ataReuniao.tipo.backgroundColor[5], text: `Sócios`, description: `Sócios`, key: 5, textColor: Colors.light },
        ];

        let tipo = tipos.find(t => t.key == this.props.ata?.tipo);

        if (!tipo) return null;

        return (
            <div style={{ ...EssentialStyle.rowFlexEnd, minHeight: 28 }}>
                <CustomTooltip key={"tipo_ata_reuniao-tooltip"} placement={'bottom'} tooltip={tipo.description}>
                    <div style={{ ...EssentialStyle.rowFlexStart, marginLeft: 2 }}>
                        <div style={{ backgroundColor: tipo.color, color: tipo.textColor, fontSize: 12, padding: 5, borderRadius: 5, marginRight: 2, fontWeight: 600 }}>{tipo.text}</div>
                    </div>
                </CustomTooltip>
            </div>
        );
    }

    renderCenter = () => {
        var date = "";
        if(this.props.ata?.data){
            date = moment(this.props.ata?.data).format('DD/MM/YY - HH:mm');
        } else {
            date = "-";         
        }

        return (
            <div style={{ ...EssentialStyle.columnStart, width: this.props.isSmallScreen ? '100%' : 'auto' }}>
                <div style={{ ...EssentialStyle.rowFlexStart, paddingLeft: 3, paddingTop: 10 }}>
                    <span style={{ fontSize: 14, color: Colors.disabled, fontWeight: 200, marginRight: 8 }}>Tipo </span>
                    <span style={{ fontSize: 14, color: Colors.dark, fontWeight: 600 }}>{this.renderTipo()}</span>
                </div>
                <div style={{ ...EssentialStyle.rowFlexStart, paddingLeft: 3, paddingTop: 10 }}>
                    <span style={{ fontSize: 14, color: Colors.disabled, fontWeight: 200, marginRight: 8 }}>Período </span>
                    <span style={{ fontSize: 14, color: Colors.dark, fontWeight: 600 }}>{date}</span>
                </div>
                <div style={{ ...EssentialStyle.rowFlexStart, paddingLeft: 3, paddingTop: 10, width: '100%' }}>
                    <span style={{ fontSize: 14, color: Colors.disabled, fontWeight: 200, marginRight: 8 }}>Situação </span>
                    <AtaReuniaoStatus ata={this.props.ata} />
                </div>
            </div>
        );
    }

    validateAndFormatUrl = (url) => {
        const urlPattern = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/.*)?$/;

        if (!/^https?:\/\//i.test(url)) {
            url = 'https://' + url;
        }

        if (urlPattern.test(url)) {
            return url;
        } else {
            return null;
        }
    }

    renderSwitch() {

        return (

            <div style={{...EssentialStyle.rowFlexCenter, width: 'auto', padding: "0px 10px", borderRadius: 8, backgroundColor: Colors.secondaryButton, height: 38}}>
                <CustomTooltip tooltip={"Impressão sem marca d'água"} placement="auto" style={{paddingRight: 10, color: Colors.homePage.darkGrey}}>
                    <FontAwesomeIcon className="left-icon" icon={faFileLines} />
                </CustomTooltip>

                <Form.Switch
                    type="switch"
                    disabled={!this.props.canEdit || !this.props.canEditDragIndex}
                    checked={!this.props.ata.impressao_sem_watermark}
                    onChange={this.props.updateImpressaoSemWatermarkCallback}
                />

                <CustomTooltip tooltip={"Impressão com marca d'água"} placement="auto" style={{color: Colors.homePage.darkGrey}}>
                    <div style={{...EssentialStyle.rowFlexCenter, position: "relative"}}>
                        <FontAwesomeIcon icon={faFile} />
                        <span style={{position: "absolute", fontSize: 12, color: Colors.secondaryButton}}>
                            A
                        </span>
                    </div> 
                </CustomTooltip>
            </div>
        )
    }

    renderButtons() {
        let notInCompany = (this.props.ata.id_empresa != SessionHelper.getData()?.id_empresa && !this.props.hash) ? true : false;

        return (
            <div style={{ ...EssentialStyle.rowFlexEnd, flex: '1 1 auto', justifyContent: 'flex-end', gap: 8, flexWrap: "wrap"}}>
            {
                this.props.ata.topicos && this.props.ata.topicos.length > 0 &&
                <>
                    {this.props.canEdit && this.props.canEditDragIndex && this.renderSwitch()}
                    <DefaultButton
                        tooltip={'Imprimir Ata'}
                        tooltipPlacement={'bottom'}
                        leftIcon={<FontAwesomeIcon icon={faPrint} />}
                        color={Colors.secondaryButton}
                        textColor={Colors.dark}
                        loading={this.props.loading}
                        onClick={this.props.imprimirAtaCallback}
                    />
                </>
            }
            {
                this.props.ata?.url && this.validateAndFormatUrl(this.props.ata?.url) &&
                <DefaultButton
                    tooltip={'Acessar Reunião'}
                    tooltipPlacement={'bottom'}
                    leftIcon={<FontAwesomeIcon icon={faLink} />}
                    color={Colors.secondaryButton}
                    textColor={Colors.dark}
                    loading={this.props.loading}
                    onClick={() => {
                        var url = this.validateAndFormatUrl(this.props.ata?.url);
                        if(url)
                            window.parent.postMessage({ type: '_blank', url }, '*');
                    }}
                />
            }
            <DefaultButton
                tooltip={'Anexos'}
                tooltipPlacement={'bottom'}
                leftIcon={<FontAwesomeIcon icon={faPaperclip} />}
                color={Colors.secondaryButton}
                textColor={Colors.dark}
                loading={this.props.loading}
                onClick={this.props.openAnexosModal}
            />
            
            <DefaultButton
                tooltip={'Histórico de alterações'}
                tooltipPlacement={'bottom'}
                leftIcon={<FontAwesomeIcon icon={faClockRotateLeft} />}
                color={Colors.secondaryButton}
                textColor={Colors.dark}
                loading={this.props.loading}
                onClick={this.props.openHistoricoModal}
            />

            {
                this.props.canEdit && !this.props.ata.finalizado &&
                <>
                    <DefaultButton
                        tooltip={notInCompany ? 'Acesse o ambiente do parceiro para fazer modificações' : 'Configurações'}
                        tooltipPlacement={'bottom'}
                        style={{ display: this.props.canEdit ? '' : 'none' }}
                        disabled={notInCompany ? true : !this.props.canEdit}
                        leftIcon={<FontAwesomeIcon icon={faCog} />}
                        color={Colors.secondaryButton}
                        textColor={Colors.dark}
                        loading={this.props.loading}
                        onClick={() => {
                            if(this.props.hash)
                                window.parent.postMessage({ type: 'navigate', url: `/ataReuniaoExterna/detalhar?tipo=editar&id=${this.props.ata?.id}&hash=${this.props.hash}` }, '*');
                            else 
                                window.parent.postMessage({ type: 'navigate', url: `/ataReuniao?tipo=editar&id=${this.props.ata?.id}` }, '*');
                        }}
                    />
                    {
                        !this.props.hash &&
                        <DefaultButton
                        tooltip={notInCompany ? 'Acesse o ambiente do parceiro para fazer modificações' : 'Excluir'}
                        tooltipPlacement={'bottom'}
                        style={{ display: this.props.canEdit ? '' : 'none' }}
                        disabled={notInCompany ? true : !this.props.canEdit}
                        leftIcon={<FontAwesomeIcon icon={faTrash} />}
                        color={Colors.secondaryButton}
                        textColor={Colors.dark}
                        loading={this.props.loading}
                        onClick={this.props.deleteAtaReuniaoCallback} 
                        />
                    }
                </>
            }

            <DefaultButton 
            style={{ width: 38, height: 38}}
            fontSize={this.props.isSmallScreen ? 14 : "unset"}
            color={Colors.secondaryButton}
            textColor={Colors.dark} 
            link={'https://advis.freshdesk.com/support/solutions/articles/63000225982-atas-de-reuni%C3%A3o'}/>
            
        </div>
        )
    }

    renderRight() {

        return (
            <div style={{ ...EssentialStyle.columnCenter, width: this.props.isSmallScreen ? '100%' : '30%', marginTop: this.props.isSmallScreen ? 8 : 0, flex: 1 }}>

                <div style={{ ...EssentialStyle.rowSpaceBetween, width: '100%', flexWrap: 'wrap' }}>
                    {this.renderButtons()}
                </div>
                <div style={{ ...EssentialStyle.rowFlexEnd, width: '100%', flexWrap: 'wrap', marginTop: this.props.isSmallScreen ? 8 : 0 }}>
                    <div style={{ ...EssentialStyle.rowFlexEnd, flex: this.props.isSmallScreen ? '0' : '1 1 auto', justifyContent: 'flex-end', gap: 8, minHeight: 40, alignItems: "center" }}>
                        {this.props.ata?.confidencial == 1 && 
                            <span 
                            style={{ 
                                fontSize: 14, 
                                color: Colors.error, 
                                fontWeight: 200, 
                                marginRight: 8, 
                                width: 125, 
                                height: 32, 
                                borderRadius: 5, 
                                border: "1px solid "+Colors.error, 
                                textAlign: "center",
                                fontWeight: 600,
                                fontSize: 18,
                                lineHeight: "30px"
                            }}>
                                Confidencial
                            </span>
                        }
                    </div>
                    {this.props.isSmallScreen && 
                        <div style={{ ...EssentialStyle.rowSpaceBetween, flexWrap: 'wrap' }}>
                            <div style={{ ...EssentialStyle.rowFlexEnd, minHeight: 40, gap: 8 }}>
                                {
                                    this.props.canEditDragIndex && !this.props.ata.finalizado &&
                                        <DefaultButton
                                            title={this.props.ata.convocacaoEnviada ? 'Reenviar Convocação' : 'Enviar Convocação'}
                                            tooltipPlacement={'auto'}
                                            leftIcon={<FontAwesomeIcon icon={faEnvelope} />}
                                            color={Colors.info}
                                            textColor={Colors.light}
                                            loading={this.props.loading}
                                            onClick={this.props.openConvocacaoModal}
                                        />	
                                }
                                {
                                    this.props.canEditDragIndex &&
                                        <DefaultButton
                                            title={!this.props.ata.finalizado ? 'Finalizar Ata' : 'Reabrir Ata'}
                                            tooltipPlacement={'auto'}
                                            leftIcon={!this.props.ata.finalizado ? <FontAwesomeIcon icon={faCheck} /> : <FontAwesomeIcon icon={faEdit} />}
                                            color={!this.props.ata.finalizado ? Colors.success : Colors.secondaryButton}
                                            textColor={!this.props.ata.finalizado ? Colors.light : Colors.dark}
                                            loading={this.props.loading}
                                            onClick={!this.props.ata.finalizado ? this.props.openFinalizacaoModal : this.props.changeAtaStatusCallback}
                                        />
                                }
                            </div>
                        </div>
                    }
                </div>
                {!this.props.isSmallScreen && <div style={{ ...EssentialStyle.rowSpaceBetween, width: '100%', flexWrap: 'wrap' }}>
                    <div style={{ ...EssentialStyle.rowFlexEnd, flex: '1 1 auto', justifyContent: 'flex-end', minHeight: 40, gap: 8, flexWrap: 'wrap' }}>
                        {
                            this.props.canEdit && !this.props.ata.finalizado &&
                                <DefaultButton
                                    title={this.props.ata.convocacaoEnviada ? 'Reenviar Convocação' : 'Enviar Convocação'}
                                    tooltipPlacement={'auto'}
                                    leftIcon={<FontAwesomeIcon icon={faEnvelope} />}
                                    color={Colors.info}
                                    textColor={Colors.light}
                                    loading={this.props.loading}
                                    onClick={this.props.openConvocacaoModal}
                                />	
                        }
                        {   
                            this.props.canEdit &&
                                <DefaultButton
                                    title={!this.props.ata.finalizado ? 'Finalizar Ata' : 'Reabrir Ata'}
                                    tooltipPlacement={'auto'}
                                    leftIcon={!this.props.ata.finalizado ? <FontAwesomeIcon icon={faCheck} /> : <FontAwesomeIcon icon={faEdit} />}
                                    color={!this.props.ata.finalizado ? Colors.success : Colors.secondaryButton}
                                    textColor={!this.props.ata.finalizado ? Colors.light : Colors.dark}
                                    loading={this.props.loading}
                                    onClick={!this.props.ata.finalizado ? this.props.openFinalizacaoModal : this.props.changeAtaStatusCallback}
                                />
                        }
                    </div>
                </div>}
            </div>
        );
    }

    renderSmall = () => { 
        return (
            <div style={{ ...EssentialStyle.rowFlexStart, width: '100%', gap: 8 }}>
                <div style={{ ...EssentialStyle.rowSpaceBetween, paddingLeft: 3, gap: 8, flex: 1 }}>
                    <div>
                        <DefaultButton tooltip={'Voltar'} tooltipPlacement={'bottom'} leftIcon={<FontAwesomeIcon icon={faChevronLeft} />} color={Colors.secondaryButton} textColor={Colors.dark} loading={this.props.loading} onClick={() => { window.parent.postMessage({ type: 'navigate', url: `/ataReuniao` }, '*'); }} style={{ marginRight: 8 }} />
                    </div>
                    <div style={{ ...EssentialStyle.rowFlexStart, flex: 1 }}>
                        {this.renderTitle()}
                    </div>
                    <div style={{ ...EssentialStyle.rowFlexEnd}}>
                        {this.renderParticipantes(true)}
                    </div>
                </div>
                <div style={{ ...EssentialStyle.rowFlexCenter, width: 'auto' }}>
                    {this.renderButtons()}
                </div>
            </div>
        );

    }
    renderTitle = () => {
        return (
            <div style={{ ...EssentialStyle.rowFlexStart }}>
                <span style={{
                    marginLeft: 6,
                    fontSize: 18,
                    fontWeight: 500,
                    textWrap: 'wrap',   
                }}>
                    {this.props.ata?.assunto}
                </span>
            </div>
        );
    }

    renderTags = () => {
        if(this.props.ata?.tags?.length > 0) 
            return (
                <div style={{ minHeight: 28 }}>
                    <TooManyTags max={4} maxWidth={this.props.isSmallScreen ? '95vw' : '40vw'} tags={ this.props.ata?.tags || [] } />
                </div>
            );

        return (<div style={{ flex: '1 1 auto', minHeight: 28 }} />);
    }

    renderParticipantes = (noText = false) => {

        var participantes = this.props.ata?.participantes?.length > 0 ? [...this.props.ata.participantes] : [];

        if(!participantes || participantes.length == 0) 
            return (<div style={{ flex: '1 1 auto', minHeight: 40 }} />);

        return (
            <div style={{ ...EssentialStyle.rowFlexStart, flex: '1 1 auto', minHeight: 40 }}>
                {!noText && <span style={{ marginRight: 8, fontSize: 14, color: Colors.dark, fontWeight: 200 }}>Participantes </span>}	
                <TooManyAvatars participantsTotal={participantes.length} participantsVisiveis={4} arrayParticipants={participantes} onlyMail/>
            </div>
        );
    }

    renderLeft = () => {
        let style = { width: '40%' };

        if (this.props.isSmallScreen) {
            style = { ...style, ...EssentialStyle.columnStart, width: '100%', maxWidth: '100%' };
        } else {
            style = { ...style, ...EssentialStyle.columnStart };
        }

        return (
            <div style={style}>
                <div style={{ ...EssentialStyle.rowSpaceBetween, paddingLeft: 3, paddingBottom: 10 }}>
                    <DefaultButton tooltip={'Voltar'} tooltipPlacement={'bottom'} leftIcon={<FontAwesomeIcon icon={faChevronLeft} />} color={Colors.secondaryButton} textColor={Colors.dark} loading={this.props.loading} onClick={() => { window.parent.postMessage({ type: 'navigate', url: `/ataReuniao` }, '*'); }} style={{ marginRight: 8 }} />
                    {this.renderTitle()}
                </div>

                {this.renderTags()}

                {this.renderParticipantes()}
            </div>
        );
    }

    render = () => {
        let style = { width: '100%' };

        if (this.props.isSmallScreen) {
            style = { ...style, ...EssentialStyle.columnCenter };
        } else {
            style = { ...style, ...EssentialStyle.rowSpaceBetween };
        }

        var displayConvocacaoBar = this.props.ata.convocacaoEnviada && (this.props.ata.tipo == 0 || this.props.ata.tipo == 5) ? true : false;

        return (
            <div
                className={"ata-header-component"}
                ref={this.props.innerRef}
                style={{
                    ...EssentialStyle.columnCenter,
                    backgroundColor: SessionHelper.getBackgroundColor(),
                    width: '100%',
                    position: this.props.isSmallScreen ? 'static' : 'fixed',
                    top: 0,
                    zIndex: 2,
                }}>
                <div style={{ 
                    ...EssentialStyle.card, 
                    ...EssentialStyle.columnStart, 
                    width: '100%', 
                    padding: 10, 
                    borderBottomLeftRadius: displayConvocacaoBar ? 0 : 8,
                    borderBottomRightRadius : displayConvocacaoBar ? 0 : 8,
                    boxShadow: displayConvocacaoBar ? 'unset' : `0px 1px 10px 0px  rgba(50, 50, 50, 0.2)`
                }}>
                    <div style={style}>
                        {
                        this.props.scrolled ?
                            
                            this.renderSmall()
                        :
                            <>
                                {this.renderLeft()}
                                {this.renderCenter()}
                                {this.renderRight()}
                            </>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default forwardRef((props, ref) => {
    const innerRef = useRef();
    useImperativeHandle(ref, () => innerRef.current);
    return <AtaReuniaoHeader {...props} innerRef={innerRef} />;
});

