import React from "react";

import EssentialStyle from "../../../../style/EssentialStyle";
import LayoutHelper from "../../../../helper/LayoutHelper";
import LoadingPage from "../../../../pages/LoadingPage";
import DataHelper from "../../../../helper/DataHelper";
import Colors from "../../../../constants/Colors";

import ListRelatorios from "./ListRelatorios/ListRelatorios";
import CriacaoRelatorio from "../CriacaoRelatorio/CriacaoRelatorio";
import Relatorio from "./Relatorio/Relatorio";
import DiagnosticoHelper from "../../../../helper/diagnostico/DiagnosticoHelper";
import DefaultButton from "../../../tools/DefaultButton";
import Select from 'react-select';
import { Form } from "react-bootstrap";

import './RelatoriosProcesso.css';
import Tabs from "../../../tools/Tabs";
import IdiomaHelper from "../../../../helper/IdiomaHelper";
import SessionHelper from "../../../../helper/SessionHelper";
import { withTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { InputGroup } from "react-bootstrap";

class RelatoriosProcesso extends React.Component {

    state = {
        loading: true,
        activeTabRelatorio: 'publicados',
        tabsRelatorios: [
            {
                name: 'publicados', 
                title: {
                    'pt_br': 'Publicados',
                    'en': 'Published',
                    'es': 'Publicados'
                }
            }
        ],
        idProcesso: this.props.idProcesso,
        idPeriodoAvaliacao: null,
        periodos: [],
        lang: IdiomaHelper.getIdioma(),
        idRelatorioOpen: null,
        isEmpresaAvaliadora: false,
        isSmallScreen: LayoutHelper.isSmallScreen(),
        textFilter: '',

        headerHeight: 0,
    }

    headerRef = React.createRef();

    async componentDidMount() {
        await this.props.i18n.loadNamespaces(['client_src_helper_diagnostico_DiagnosticoHelper']);

        this.loadData();
        this.measureHeaderHeight();
        window.addEventListener('resize', this.verifyScreen);
        window.addEventListener('resize', this.measureHeaderHeight);

    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.verifyScreen);
        window.removeEventListener('resize', this.measureHeaderHeight);
    }

    measureHeaderHeight = () => {
        const headerElement = document.querySelector('.header-content');
        if (headerElement) {
            const height = headerElement.offsetHeight;
            if (height !== this.state.headerHeight) {
                this.setState({ headerHeight: height });
            }
        }
    }

    async loadData() {
        this.setState({ loading: true });

        let { periodos, canEdit, canView } = await DiagnosticoHelper.getPeriodosAvaliacao(this.state.idProcesso);

        let idPeriodoAvaliacao = null;
        let localId = DiagnosticoHelper.getPeriodoAvaliacaoLocal(this.props.idProcesso);

        if (localId && periodos.find(periodo => periodo.id == localId)) {
            idPeriodoAvaliacao = localId;
        } else {
            idPeriodoAvaliacao = periodos.length > 0 ? periodos[0].id : null;
        }

        let isEmpresaAvaliadora = await DiagnosticoHelper.isEmpresaAvaliadora(this.state.idProcesso);

        if (isEmpresaAvaliadora) {
            this.setState({
                tabsRelatorios: [
                    ...this.state.tabsRelatorios,
                    {
                        name: 'emElaboracao', title: IdiomaHelper.getStruct({
                            'pt_br': 'Em Elaboração',
                            'en': 'Being Developed',
                            'es': 'En Elaboración'
                        })
                    },
                    {
                        name: 'modelos',
                        title: IdiomaHelper.getStruct({
                            'pt_br': 'Modelos',
                            'en': 'Templates',
                            'es': 'Modelos'
                        })
                    },
                ],
            });
        }

        await this.loadNumRelatorios(idPeriodoAvaliacao, isEmpresaAvaliadora);

        this.setState({ periodos, isEmpresaAvaliadora, idPeriodoAvaliacao }, () => {
            this.setState({ loading: false });
        });
    }

    async loadNumRelatorios(idPeriodoAvaliacao = this.state.idPeriodoAvaliacao, isEmpresaAvaliadora = this.state.isEmpresaAvaliadora) {

        let numRelatorios = {
            publicados: 0,
            emElaboracao: 0
        };
        
        if (idPeriodoAvaliacao) {
            let paramsRelatorios = {
                idPeriodoAvaliacao: idPeriodoAvaliacao,
                relations: 0,
                lang: this.state.lang
            }
    
            if(isEmpresaAvaliadora) {
                paramsRelatorios.avaliador = true;
                paramsRelatorios.avaliado = false;
            } else {
                paramsRelatorios.avaliador = false;
                paramsRelatorios.avaliado = true;
            }
    
            numRelatorios = await DiagnosticoHelper.getNumRelatorios(
                paramsRelatorios.idPeriodoAvaliacao, 
                paramsRelatorios.avaliador, 
                paramsRelatorios.avaliado, 
                paramsRelatorios.relations, 
                paramsRelatorios.lang
            );
        }

        let activeTabRelatorio = this.state.activeTabRelatorio;

        if (numRelatorios.publicados > 0) {
            activeTabRelatorio = 'publicados';
        } else if (numRelatorios.emElaboracao > 0 && isEmpresaAvaliadora) {
            activeTabRelatorio = 'emElaboracao';
        } else {
            activeTabRelatorio = 'publicados';
        }

        this.setState({ activeTabRelatorio });
    }

    verifyScreen = () => {
        this.setState({ isSmallScreen: LayoutHelper.isSmallScreen() });
    }

    openRelatorioCallback = (idRelatorio) => {
        this.setState({ idRelatorioOpen: idRelatorio });
    }

    renderPage() {
        return (
            <div className="relatorioPage">
                {this.renderHeader()}
                {this.state.idPeriodoAvaliacao ? this.renderBody() : DiagnosticoHelper.renderPeriodoNotSelected(this.props.t, this.state.loading)}
            </div>
        );
    }

    renderHeader() {

        let localStyleContent = {
            boxShadow: Colors.boxShadow,
            ...(this.state.isSmallScreen ? EssentialStyle.columnCenter : EssentialStyle.rowSpaceBetween),
        }

        let localStyle = {
            backgroundColor: SessionHelper.getBackgroundColor() || Colors.background,
        }

        return (
            <div className="header sticky-header" style={localStyle}>
                <div className="header-content" style={localStyleContent}>
                    <div className="left" style={{ width: this.state.isSmallScreen ? '100%' : '' }}>
                        <Form.Group>
                            <Select
                                styles={{ control: (base) => ({ ...base, border: 'none', minWidth: '40vh', marginRight: 2 }) }}
                                options={DataHelper.formatSelectData(this.state.periodos, 'id', 'nome')}
                                value={DataHelper.formatSelectData(this.state.periodos, 'id', 'nome').find(option => option.value === this.state.idPeriodoAvaliacao)}
                                onChange={(valueSelected) => { this.setState({ idPeriodoAvaliacao: valueSelected.value });  DiagnosticoHelper.setPeriodoAvaliacaoLocal(valueSelected.value, this.props.idProcesso)}}
                                placeholder={'Selecione um Período de Avaliação'}
                                noOptionsMessage={DataHelper.getSelectEmptyMessage}
                                isSearchable
                                menuPortalTarget={document.body}
                            />
                        </Form.Group>
                    </div>

                    <div className="right" style={{ width: this.state.isSmallScreen ? '100%' : '' }}>
                        {this.renderTabs('activeTabRelatorio', 'tabsRelatorios')}
                        {this.renderInputSearch()}

                        <DefaultButton
                            style={{ width: 38, height: 38 }}
                            fontSize={14}
                            color={Colors.secondaryButton}
                            textColor={Colors.dark}
                            link={'https://advis.freshdesk.com/support/solutions/folders/63000238312'} />
                    </div>
                </div>
            </div>
        )
    }

    renderInputSearch() {
            
        let searchIconStyle = { borderRight: 'none', color: Colors.disabled, width: 40 };
        let inputGroupStyle = { width: '100%', maxWidth: 200 };

        let placeholder = IdiomaHelper.getStruct({
            'pt_br': 'Pesquisar',
            'en': 'Search',
            'es': 'Buscar'
        });

        return (
            <InputGroup style={inputGroupStyle}>
                <InputGroup.Text style={searchIconStyle}>
                    <FontAwesomeIcon
                        icon={faSearch}
                    />
                </InputGroup.Text>
                <Form.Control type="text"
                    style={{ borderLeft: 'none' }}
                    placeholder={placeholder[this.state.lang]}
                    onChange={(event) => { this.setState({ textFilter: event.target.value }) }}
                    value={this.state.textFilter}
                />
            </InputGroup>
        )
    }

    renderTabs(itemState, itensTabs) {
        if(!this.state.isEmpresaAvaliadora) return <div />;

        return (
           <Tabs 
                activeTab={this.state[itemState]}
                tabs={this.state[itensTabs]}
                isSmallScreen={this.state.isSmallScreen}
                onTabChange={(tab) => {
                    this.measureHeaderHeight(); 
                    this.setState({ [itemState]: tab.name });
                }}
                tabStyle={{ width: this.state.isSmallScreen ? '30vw' : 150 }}
                lang={this.state.lang}
            />
        )
    }

    renderBody() {
        return (
            <div 
                className="body"
                style={{ 
                    height: `calc(100% - ${this.state.headerHeight}px - ${this.state.isSmallScreen ? 30 : 20}px)`,
                }}
            >
                {this.renderActiveTab(this.state.activeTabRelatorio)}
            </div>
        )
    }

    renderActiveTab(tab) {
        switch (tab) {
            case 'publicados':
            case 'emElaboracao':
                return (
                    <ListRelatorios
                        isEmpresaAvaliadora={this.state.isEmpresaAvaliadora}
                        idPeriodoAvaliacao={this.state.idPeriodoAvaliacao}
                        status={tab == 'emElaboracao' ? 0 : 1}
                        lang={this.state.lang}
                        openRelatorio={(idRelatorioOpen) => this.openRelatorioCallback(idRelatorioOpen)}
                        textFilter={this.state.textFilter}
                    />
                );
            case 'modelos':
                return (
                    <CriacaoRelatorio idPeriodoAvaliacao={this.state.idPeriodoAvaliacao} lang={this.state.lang} isSmallScreen={this.state.isSmallScreen}/>
                );
            default:
                return <div />;
        }
    }

    render() {

        if (this.state.idRelatorioOpen) return <Relatorio id={this.state.idRelatorioOpen} closeRelatorio={() => this.openRelatorioCallback(null)} lang={this.state.lang} />
        return this.state.loading ? <LoadingPage /> : this.renderPage();
    }
}

export default withTranslation()(RelatoriosProcesso);