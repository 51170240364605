import { faCheck, faEdit, faTimes, faLink, faPlusSquare, faList } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import EssentialStyle from "../../../../style/EssentialStyle";
import { Form, Row, Col, ProgressBar } from "react-bootstrap";
import DefaultLoader from "../../../tools/DefaultLoader";
import Colors from "../../../../constants/Colors";
import DefaultButton from "../../../tools/DefaultButton";
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import SwotHelper from "../../../../helper/pe/SwotHelper";
import KeyboardHelper from "../../../../helper/KeyboardHelper";
import CustomConfirm from "../../../tools/CustomConfirm";
import Sig from "../../../../api/Sig";
import DataHelper from "../../../../helper/DataHelper";
import Select from 'react-select'

import CustomTooltip from "../../../tools/CustomTooltip";
import SessionHelper from "../../../../helper/SessionHelper";
import EllipsisText from "../../../tools/EllipsisText";

export default class SwotItem extends React.Component {
    state = {
        loading: true,
        dragging: false,
        editing: false,
        mapas: [],
        perspectivas: [],
        objetivos: [],
        id_pe_mapa: null,
        id_perspectiva: null,
        id_objetivo: null,
        objetivoNome: null,
        selectObjetivo: true,
        elementos: [],
        item: {},
        progress: 100,
        color: this.props.color || Colors.primary,
        showDelete: false,
        delayHandler: null 
    }

    async componentDidMount() {
        this.setProgressBarColor();
        await this.loadItem();
    }

    setProgressBarColor() {
        if (this.progressBar) {
            const inner = this.progressBar.querySelector(".progress-bar");
            if (inner) inner.style.backgroundColor = this.state.color;
        }
    }

    async loadItem() {
        this.setState({ loading: true });

        let item;

        if (this.props.item && this.props.item.descricao) {
            item = this.props.item;
        } else {
            item = (await Sig.request('GET', 'pe/swot/getQuadranteItem', { id: this.props.id })).quadrante_item;
        }

        let progress = await SwotHelper.getGutItemValue(this.props.id_swot, this.props.id);
        let { elementos } = await Sig.request('POST', 'pe/swot/getElementosMapaSwotItem', { id_pe_swot_quadrante_item: this.props.id, id_pe_swot: this.props.id_swot });

        if (this.props.orderCallback) this.props.orderCallback(progress);

        this.setState({ item: item || {}, progress, elementos }, () => { this.setState({ loading: false }) });
    }

    updateName = async () => {
        if (!this.state.name) return toast.info('Informe a descrição do item');

        this.setState({ loading: true });

        try {
            if (this.props.id) await Sig.request('POST', 'pe/swot/updateQuadranteItem', { id: this.props.id, descricao: this.state.name });
        } catch (e) {
            toast.error('Erro ao atualizar nome');
        }

        if (this.props.deleteCallback) this.props.deleteCallback();
    }

    deleteItem = async () => {
        this.setState({ loading: true });

        try {
            if (this.props.id) await Sig.request('POST', 'pe/swot/deleteQuadranteItem', { id: this.props.id });
        } catch (e) {
            toast.error('Erro ao remover');
        }

        if (this.props.deleteCallback) this.props.deleteCallback();
    }

    renderHoverButtons() {
        if (this.state.showDelete && !this.state.editing && !this.state.dragging) {
            return (
                <div style={{ ...EssentialStyle.rowFlexStart }}>
                    <DefaultButton
                        color={Colors.success}
                        tooltip={'Vincular a um objetivo do Mapa Estratégico'}
                        style={{ marginRight: 8 }}
                        loading={this.state.loading}
                        leftIcon={<FontAwesomeIcon icon={faLink} />}
                        onClick={async () => {
                            this.setState({ loading: true });
                            let { mapas } = await Sig.request('GET', 'pe/mapa/list', { relations: 0 }) || [];
                            if(mapas?.length > 0){
                                var mapaInicial = mapas.find(e => e.favorito == 1)?.id;
                                if(!mapaInicial )
                                    mapaInicial = mapas.sort((a, b) => a.data_cadastro - b.dataCadastro)[0].id;
                                if (mapaInicial) {
                                    var { perspectivas } = await Sig.request('POST', 'pe/mapa/getPerspectivas', { id: mapaInicial }) || [];
                                }
                            }
                            this.setState({ mapas, loading: false, editing: 'objetivo', id_pe_mapa: mapaInicial ? mapaInicial : null, perspectivas: mapaInicial ? perspectivas : [], id_perspectiva: null });
                        }}
                    />
                    <DefaultButton
                        color={Colors.dark}
                        tooltip={'Editar'}
                        style={{ marginRight: 8 }}
                        loading={this.state.loading}
                        leftIcon={<FontAwesomeIcon icon={faEdit} />}
                        onClick={() => {
                            this.setState({ editing: 'nome', name: this.state.item.descricao })
                        }}
                    />
                    <DefaultButton
                        color={Colors.error}
                        tooltip={'Remover'}
                        loading={this.state.loading}
                        leftIcon={<FontAwesomeIcon icon={faTimes} />}
                        onClick={() => {
                            confirmAlert({
                                customUI: ({ onClose }) => (
                                    <CustomConfirm
                                        title={'Remover este item?'}
                                        message={<p style={{ marginTop: 10, marginBottom: 10 }}>Essa ação não pode ser desfeita</p>}
                                        buttons={[
                                            {
                                                label: 'Remover',
                                                color: SessionHelper.getColor(),
                                                textColor: Colors.light,
                                                onClick: () => { this.deleteItem(); onClose(); }
                                            },
                                            {
                                                label: 'Cancelar',
                                                onClick: () => { onClose(); }
                                            },
                                        ]}
                                    />
                                )
                            });
                        }}
                    />
                </div>
            );
        }
    }

    renderProggressBar() {
        if (!this.state.showDelete && !this.state.editing) {
            return (
                <div
                    style={{ width: '40%' }}
                >
                    <ProgressBar
                        min={0}
                        max={this.props.gutMaxValue}
                        animated={this.state.loading}
                        ref={ref => this.progressBar = ref}
                        now={this.state.progress}
                        label={this.state.progress}
                    />
                </div>
            );
        }
    }

    renderEditingName() {
        return (
            <div style={{
                ...EssentialStyle.rowFlexStart,
                padding: 8,
                letterSpacing: 1.1,
                color: Colors.swot.addButton,
                fontSize: 12, fontWeight: 'bold',
                cursor: 'pointer',
                width: '100%'
            }}>
                <Form.Control
                    value={this.state.name}
                    ref={ref => this.addSwotItemInput = ref}
                    className="me-1"
                    type="text"
                    placeholder="Descrição do item..."
                    onChange={(event) => {
                        this.setState({ name: event.target.value })
                    }}
                    onKeyDown={(evt) => {
                        KeyboardHelper.handleShortcut(
                            evt,
                            ["Enter", "Escape"],
                            [this.updateName, (e) => { e.preventDefault(); this.setState({ name: '', editing: false }); }]
                        )
                    }}
                />

                <DefaultButton
                    width={48}
                    className="me-1"
                    leftIcon={<FontAwesomeIcon icon={faTimes} />}
                    color={Colors.swot.addButton}
                    loading={this.state.loading}
                    onClick={() => { this.setState({ name: '', editing: false }) }}
                />

                <DefaultButton
                    width={48}
                    leftIcon={<FontAwesomeIcon icon={faCheck} />}
                    color={Colors.swot.addButton}
                    loading={this.state.loading}
                    onClick={this.updateName}
                />
            </div>
        );
    }

    saveObjetivo = async () => {
        if (!this.state.id_pe_mapa) return toast.error('Selecione um Mapa Estratégico');
        if (!this.state.id_perspectiva) return toast.error('Selecione uma Perspectiva');

        if (this.state.objetivoNome && !this.state.id_objetivo) {
            this.setState({ loading: true });

            let object = {
                x: 0,
                y: 0,
                width: 500,
                height: 198,
                hover: false,
                valor: `<p>${this.state.objetivoNome}</p>`,
                tipo: `objetivo_estrategico`,
                cor_fundo: '#e0e0e0',
                cor_borda: 'transparent',
                cor_texto: '#000000',
                id_pe_mapa_perspectiva: this.state.id_perspectiva,
                prioritario: 0,
                id_indicadores: null,
                id_gmr_painel: null
            };

            let objetivoRequestData = {
                idRelation: this.state.id_pe_mapa,
                model: 'PeMapaElemento',
                relationField: 'id_pe_mapa'
            }

            Object.keys(object).forEach((key, index) => { objetivoRequestData[key] = object[key]; });

            let { elemento } = await Sig.request('POST', 'pe/diagrama/addElemento', objetivoRequestData);

            if (elemento?.id) {
                this.setState({ loading: false, editing: false }, async () => {
                    let propValue = JSON.stringify([{ value: this.props.id, label: this.state.item.descricao, id_pe_swot: this.props.id_swot }]);

                    let propRequestData = {
                        id: elemento.id,
                        prop: 'id_pe_swot_quadrante_item',
                        model: 'PeMapaElemento',
                        value: propValue
                    }

                    let propRequest = await Sig.request('POST', 'pe/diagrama/updateElementoProp', propRequestData);

                    if (propRequest?.elemento?.id) {
                        toast.success('Objetivo adicionado e vinculado com sucesso');
                        await this.loadItem();
                    }
                });
            }
        } else if (!this.state.objetivoNome && this.state.id_objetivo) {
            let { elemento, fatores } = await Sig.request('POST', 'pe/mapa/getElemento', {
                id: this.state.id_objetivo,
                model: 'PeMapaElemento'
            });

            if (elemento?.id) {
                let propValue = [{ value: this.props.id, label: this.state.item.descricao, id_pe_swot: this.props.id_swot }];

                for (let fator of fatores) {
                    if (this.props.id_swot == fator.id_pe_swot) propValue.push({ value: fator.id_pe_swot_quadrante_item, label: "", id_pe_swot: fator.id_pe_swot });
                }

                let propRequestData = {
                    id: elemento.id,
                    prop: 'id_pe_swot_quadrante_item',
                    model: 'PeMapaElemento',
                    value: JSON.stringify(propValue)
                }

                let propRequest = await Sig.request('POST', 'pe/diagrama/updateElementoProp', propRequestData);

                if (propRequest?.elemento?.id) {
                    toast.success('Objetivo vinculado com sucesso');
                    await this.loadItem();
                }
            }
        }

        this.setState({ loading: false, id_pe_mapa: null, id_perspectiva: null, id_objetivo: null, objetivoNome: null, editing: false });
    }

    renderEditingObjetivo() {
        return (
            <div style={{
                ...EssentialStyle.columnStart,
                padding: 8,
                color: Colors.swot.addButton,
                fontWeight: 'bold',
                width: '100%',
                height: '100%'
            }}>
                <div
                    style={{
                        ...EssentialStyle.rowSpaceBetween,
                        width: '100%',
                        paddingTop: 8,
                        paddingBottom: 8,
                        marginBottom: 8,
                        borderBottom: `.1px solid ${Colors.disabled}`
                    }}
                >
                    {this.renderTitleInfo(100)}
                    <FontAwesomeIcon
                        style={{ cursor: 'pointer', height: 18, width: 18 }}
                        icon={faTimes}
                        onClick={() => { this.setState({ name: '', editing: false }) }}
                    />
                </div>
                <div
                    style={{
                        ...EssentialStyle.rowFlexStart,
                        width: '100%'
                    }}
                >
                    <Form className="w-100">
                        <Row className="mb-3">
                            <Form.Group as={Col}>
                                <Form.Label>Mapa Estratégico</Form.Label>
                                <Select
                                    options={DataHelper.formatSelectData(this.state.mapas, 'id', 'nome')}
                                    placeholder={'Mapa Estratégico'}
                                    value={DataHelper.formatSelectedData(this.state.mapas.find(e => e.id === this.state.id_pe_mapa), 'id', 'nome')}
                                    noOptionsMessage={DataHelper.getSelectEmptyMessage}
                                    isClearable
                                    isSearchable
                                    onChange={async (value) => {
                                        this.setState({ id_pe_mapa: value ? value.value : null });

                                        if (value && value.value) {
                                            let { perspectivas } = await Sig.request('POST', 'pe/mapa/getPerspectivas', { id: value.value });

                                            this.setState({ perspectivas, id_perspectiva: null, id_objetivo: null, objetivoNome: null });
                                        }
                                    }}
                                />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label>Perspectiva</Form.Label>
                                <Select
                                    value={DataHelper.formatSelectedData(this.state.perspectivas.find(e => e.id === this.state.id_perspectiva), 'id', 'nome', (row) => DataHelper.removeHtmlTags(row.nome))}
                                    options={DataHelper.formatSelectData(this.state.perspectivas, 'id', 'nome', (row) => DataHelper.removeHtmlTags(row.nome))}
                                    placeholder={'Perspectiva'}
                                    noOptionsMessage={DataHelper.getSelectEmptyMessage}
                                    isClearable
                                    isSearchable
                                    onChange={async (value) => {
                                        this.setState({ id_perspectiva: value ? value.value : null });

                                        if (value && value.value) {
                                            let { objetivos } = await Sig.request('POST', 'pe/swot/listObjetivosNaoVinculadosFator',
                                                {
                                                    id_pe_mapa_perspectiva: value.value,
                                                    id_pe_swot_quadrante_item: this.props.id,
                                                    id_pe_swot: this.props.id_swot
                                                }
                                            );

                                            this.setState({ objetivos, id_objetivo: null, objetivoNome: null });
                                        }
                                    }}
                                />
                            </Form.Group>
                        </Row>

                        <Row className="mb-3">
                            <div
                                style={{
                                    ...EssentialStyle.rowSpaceBetween,
                                    width: '100%',
                                    padding: 8,
                                    paddingLeft: 13,
                                    paddingRight: 13,
                                    marginBottom: 8
                                }}
                            >
                                <DefaultButton
                                    color={Colors.dark}
                                    leftIcon={this.state.selectObjetivo ? <FontAwesomeIcon icon={faEdit} /> : <FontAwesomeIcon icon={faList} />}
                                    tooltip={this.state.selectObjetivo ? 'Adicionar novo objetivo' : 'Selecionar objetivo existente'}
                                    style={{ marginRight: 8 }}
                                    loading={this.state.loading}
                                    onClick={() => {
                                        this.setState({ selectObjetivo: !this.state.selectObjetivo, id_objetivo: null, objetivoNome: null })
                                    }}
                                />

                                {this.state.selectObjetivo ?
                                    <Form.Group as={Col}>
                                        <Select
                                            value={DataHelper.formatSelectedData(this.state.objetivos.find(e => e.id === this.state.id_objetivo), 'id', 'valor', (row) => DataHelper.removeHtmlTags(row.valor))}
                                            options={DataHelper.formatSelectData(this.state.objetivos, 'id', 'valor', (row) => DataHelper.removeHtmlTags(row.valor))}
                                            placeholder={'Selecione um objetivo'}
                                            noOptionsMessage={DataHelper.getSelectEmptyMessage}
                                            isClearable
                                            isSearchable
                                            onChange={(value) => { this.setState({ id_objetivo: value ? value.value : null }) }}
                                        />
                                    </Form.Group>
                                    :
                                    <Form.Control
                                        value={this.state.objetivoNome}
                                        ref={ref => this.addObjetivoInput = ref}
                                        className="w-100"
                                        type="text"
                                        placeholder="Nome do novo objetivo..."
                                        onChange={(event) => {
                                            this.setState({ objetivoNome: event.target.value, id_objetivo: null })
                                        }}
                                        onKeyDown={(evt) => {
                                            KeyboardHelper.handleShortcut(
                                                evt,
                                                ["Enter", "Escape"],
                                                [this.saveObjetivo, (e) => { e.preventDefault(); this.setState({ objetivoNome: '', id_objetivo: null }) }]
                                            )
                                        }}
                                    />
                                }


                            </div>

                        </Row>

                        <div className="mb-3 d-flex flex-row-reverse">
                            <Form.Group>
                                <DefaultButton style={{ marginRight: 8 }} color={Colors.dark} leftIcon={<FontAwesomeIcon icon={faLink} />} title={'Ver Vinculados'} loading={this.state.loading} onClick={() => { this.setState({ editing: 'list_objetivos' }) }} />
                                <DefaultButton color={Colors.success} leftIcon={<FontAwesomeIcon icon={faPlusSquare} />} title={'Adicionar'} loading={this.state.loading} onClick={this.saveObjetivo} />
                            </Form.Group>
                        </div>
                    </Form>
                </div>
            </div>
        );
    }

    renderTitleInfo(maxSize = 32) {

        return (
            <div style={{
                ...EssentialStyle.rowSpaceBetween,
                width: '60%',
                flexGrow: 1,
                paddingRight: "10px",
            }}>
                {this.state.elementos?.length > 0 && !this.state.editing &&
                    <CustomTooltip
                        placement={'top'}
                        tooltip={`${this.state.elementos.length} objetivos vinculados`}
                    >
                        <div style={{
                            height: 14,
                            width: 14,
                            backgroundColor: Colors.error,
                            borderRadius: '50%',
                            cursor: 'pointer',
                            marginRight: 8
                        }}
                            onClick={() => {
                                this.setState({ editing: 'list_objetivos' });
                            }}
                        />
                    </CustomTooltip>

                }
                <div 
                    onDoubleClick={() => { this.setState({ editing: 'nome', name: this.state.item.descricao }) }}
                    style={{ fontWeight: 'bold', color: Colors.swot.addButton, display: "flex", position: "relative", height: "30px", width: "calc(100%)" }}>
                        <EllipsisText text={this.state.item.descricao} />
                </div>
            </div>
        );
    }

    removeVinculoObjetivo = async (objetivo) => {
        if (!objetivo?.elemento?.id) return toast.error('Objetivo não encontrado');

        let { elemento, fatores } = await Sig.request('POST', 'pe/mapa/getElemento', {
            id: objetivo.elemento.id,
            model: 'PeMapaElemento'
        });

        if (elemento?.id) {
            let propValue = [];

            for (let fator of fatores) {
                if (this.props.id_swot == fator.id_pe_swot && this.props.id !== fator.id_pe_swot_quadrante_item) {
                    propValue.push({ value: fator.id_pe_swot_quadrante_item, label: "", id_pe_swot: fator.id_pe_swot });
                }
            }

            let propRequestData = {
                id: elemento.id,
                prop: 'id_pe_swot_quadrante_item',
                model: 'PeMapaElemento',
                value: JSON.stringify(propValue)
            }

            let propRequest = await Sig.request('POST', 'pe/diagrama/updateElementoProp', propRequestData);

            if (propRequest?.elemento?.id) {
                toast.success('Vínculo removido com sucesso');
                await this.loadItem();
            }
        }
    }

    renderExistingObjetivos(objetivo) {
        return (
            <div
                style={{
                    ...EssentialStyle.rowSpaceBetween,
                    width: '100%',
                    height: 36,
                    marginBottom: 8,
                    borderBottom: `.1px solid ${Colors.disabled}`,
                    padding: 8
                }}
            >
                <div
                    style={{
                        ...EssentialStyle.rowFlexStart
                    }}
                >
                    <span style={{ marginRight: 8 }}>{DataHelper.removeHtmlTags(objetivo.elemento.valor)}</span>
                    <CustomTooltip tooltip={'Perspectiva'} placement={'top'}>
                        <span style={{ marginRight: 8, fontWeight: '200', fontSize: 12 }}>{DataHelper.removeHtmlTags(objetivo.perspectiva)}</span>
                    </CustomTooltip>
                    <CustomTooltip tooltip={'Mapa Estratégico'} placement={'top'}>
                        <span style={{ marginRight: 8, fontWeight: '200', fontSize: 12 }}>{objetivo.mapa}</span>
                    </CustomTooltip>
                </div>
                <CustomTooltip tooltip={'Remover vínculo com objetivo'}>
                    <FontAwesomeIcon
                        style={{ cursor: 'pointer', height: 18, width: 18 }}
                        icon={faTimes}
                        onClick={() => {
                            confirmAlert({
                                customUI: ({ onClose }) => (
                                    <CustomConfirm
                                        title={'Remover vínculo com objetivo?'}
                                        message={<p style={{ marginTop: 10, marginBottom: 10 }}>Essa ação não pode ser desfeita</p>}
                                        buttons={[
                                            {
                                                label: 'Remover',
                                                color: SessionHelper.getColor(),
                                                textColor: Colors.light,
                                                onClick: () => {
                                                    this.removeVinculoObjetivo(objetivo);
                                                    onClose();
                                                }
                                            },
                                            {
                                                label: 'Cancelar',
                                                onClick: () => { onClose(); }
                                            },
                                        ]}
                                    />
                                )
                            });
                        }}
                    />
                </CustomTooltip>
            </div>
        );
    }

    renderListObjetivos() {
        return (
            <div style={{
                ...EssentialStyle.columnStart,
                padding: 8,
                color: Colors.swot.addButton,
                fontWeight: 'bold',
                width: '100%',
                height: '100%'
            }}>
                <div
                    style={{
                        ...EssentialStyle.rowSpaceBetween,
                        width: '100%',
                        paddingTop: 8,
                        paddingBottom: 8,
                        marginBottom: 8,
                        borderBottom: `.1px solid ${Colors.disabled}`
                    }}
                >
                    {this.renderTitleInfo(100)}
                    <FontAwesomeIcon
                        style={{ cursor: 'pointer', height: 18, width: 18 }}
                        icon={faTimes}
                        onClick={() => { this.setState({ name: '', editing: false }) }}
                    />
                </div>
                <div
                    style={{
                        ...EssentialStyle.columnCenter,
                        width: '100%'
                    }}
                >
                    {this.state.elementos.map((objetivo, index) => this.renderExistingObjetivos(objetivo))}
                </div>
            </div>
        );
    }

    renderTitle() {
        if (this.state.loading) {
            return <DefaultLoader size={20} color={this.state.color} />;
        } else {
            if (this.state.editing) {
                if (this.state.editing === 'nome') return this.renderEditingName();
                if (this.state.editing === 'objetivo') return this.renderEditingObjetivo();
                if (this.state.editing === 'list_objetivos') return this.renderListObjetivos();
            } else {
                return this.renderTitleInfo();
            }
        }
    }

    getHeigth() {
        if (this.state.editing === 'objetivo') return 280;
        if (this.state.editing === 'list_objetivos') return (this.state.elementos.length * 50) + 100;
        return 50;
    }

    render() {
        return (
            <div
                onDragStart={(e) => {
                    if (!this.state.editing) {
                        this.props.onDragStart(e);
                        this.setState({ dragging: true, editing: false, showDelete: false }, () => {
                            this.setProgressBarColor();
                        });
                    }
                }}
                onDragEnter={!this.state.editing ? this.props.onDragEnter : undefined}
                onDragEnd={(e) => {
                    if (!this.state.editing) {
                        this.props.onDragEnd(e);
                        this.setState({ dragging: false });
                    }
                }}
                draggable={this.state.editing ? false : true}
                onMouseOver={() => {
                    this.setState(
                        { hovered: true },
                        () => {
                            if (this.state.hovered) this.setState({ showDelete: true })
                        });
                }}
                onMouseLeave={() => {
                    this.setState(
                        { hovered: false, showDelete: false },
                        () => {
                            this.setProgressBarColor();
                        });
                }}
                style={{
                    ...EssentialStyle.rowSpaceBetween,
                    minWidth: 'calc(100% - 10px)',
                    height: this.getHeigth(),
                    transition: 'height 0.3s ease',
                    opacity: this.state.dragging ? 0.8 : 1,
                    border: this.state.dragging ? `2px dashed ${Colors.notSelected}` : 0,
                    cursor: !this.state.editing ? 'move' : 'default',
                    backgroundColor: Colors.background,
                    margin: 4,
                    padding: 8,
                    borderRadius: 5,
                    marginTop: this.props.dragControl.draggingOverItem && this.props.dragControl.draggingOverItem.id === this.props.id ? 64 : 4
                }}
            >
                <div
                    style={{
                        ...EssentialStyle.rowSpaceBetween,
                        minWidth: '100%',
                        minHeight: 50
                    }}
                >
                    {this.renderTitle()}
                    {this.renderProggressBar()}
                    {this.renderHoverButtons()}
                </div>
            </div>
        );
    }
}