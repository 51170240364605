import React from "react";
import { toast } from "react-toastify";

import IdiomaHelper from "../../../../../../helper/IdiomaHelper";
import DiagnosticoHelper from "../../../../../../helper/diagnostico/DiagnosticoHelper";
import Colors from "../../../../../../constants/Colors";

import TextEditor from "../../../../../tools/TextEditor/TextEditor";

import './RelatorioTextItem.css';

export default class RelatorioTextItem extends React.Component {
    
    state = {
        item: this.props.item,

    }

    componentDidUpdate(prevProps) {
        if (prevProps.item !== this.props.item) {
            this.setState({ item: this.props.item });
        }
    }


    message = () => {
        const message = IdiomaHelper.getStruct({
            'pt_br': { publicado: '', emDesenvolvimento: 'Escreva seu texto personalizado aqui...'},
            'en': { publicado: '', emDesenvolvimento: 'Write your custom text here...' },
            'es': { publicado: '', emDesenvolvimento: 'Escribe tu texto personalizado aquí...' }
        });

        return message;
    } 
    
    getText = (item) => {
        let text = item?.text?.find((t) => t.id_idioma == IdiomaHelper.getSiglaId(this.props.lang))?.descricao ?? 'testeee';
        return text;
    }
    
    changeText = (item, value) => {
        let text = item.text.find((t) => t.id_idioma == IdiomaHelper.getSiglaId(this.props.lang));
        if (!text) {
            text = { id_idioma: IdiomaHelper.getSiglaId(this.props.lang), descricao: value };
            item.text.push(text);
        } else {
            text.descricao = value;
        }

        // this.setState({
        //     relatorio: {
        //         ...this.state.relatorio,
        //         items: this.state.relatorio.items.map((i) => {
        //             if (i.id == item.id && i.type == item.type) return item;
        //             return i;
        //         })
        //     }
        // });

        this.setState({ item });
    }
    
    saveText = async (item, value) => {
        let response = DiagnosticoHelper.saveTextGrupoTextText(item.id, this.props.lang, value);

        if (!response) {
            toast.error('Erro ao salvar texto');
            return;
        }
    }

    render() {
        return (
            <div className="RelatorioTextItem" style={{ boxShadow: Colors.boxShadow }}>
                {this.props.canEdit ?
                    <TextEditor
                        defaultValue={ this.getText(this.state.item)}
                        onChange={(value) => { this.changeText(this.state.item, value) }}
                        onBlur={(value) => { this.saveText(this.state.item, value) }}
                        noMargin
                        placeholder={this.props.status == 1 ? this.message()[this.props.lang].publicado : this.message()[this.props.lang].emDesenvolvimento}
                    />
                    :
                    <div className={"texto"} dangerouslySetInnerHTML={{ __html: this.getText(this.state.item) }} />
                }
            </div>
        );
    }
}