import React from "react";
import { withTranslation } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusSquare } from '@fortawesome/free-solid-svg-icons'
import Colors from "../../constants/Colors";
import DefaultButton from "../../components/tools/DefaultButton";
import LoadingPage from "../LoadingPage";
import { toast } from "react-toastify";
import AddAtualizacao from "../../components/forms/atualizacoes/add/AddAtualizacao";
import CanvasList from "../../components/forms/pe/list/CanvasList";
import Sig from "../../api/Sig";
import CanvasView from "../../components/modules/pe/canvas/CanvasView";
import AtualizacoesTimeline from "../../components/modules/atualizacoes/AtualizacoesTimeline";
import EssentialStyle from "../../style/EssentialStyle";
import EditAtualizacao from "../../components/forms/atualizacoes/edit/EditAtualizacao";
import EllipsisText from "../../components/tools/EllipsisText";
import SessionHelper from "../../helper/SessionHelper";

class AtualizacoesPage extends React.Component {
    state = {
        loading: true,
        showWizard: false,
        showEdit: false,
        editId: null,
        canCreate: false
    }

    async componentDidMount() {
        const { i18n } = this.props;
        await i18n.loadNamespaces(['client_src_pages_atualizacoes_AtualizacoesPage']);

        await this.loadData();        
    }

    async loadData() {
        this.setState({ loading: true });

        let { podeCriar } = await Sig.request('POST', 'ajuda/atualizacoes/getPodeCriar');

        this.setState({ canCreate: podeCriar, loading: false });
    }

    renderHeader = () => {
        return (
            <div style={{ ...EssentialStyle.rowSpaceBetween, ...EssentialStyle.card, padding: 10 }}>
                {this.state.canCreate ?
                    <DefaultButton
                        onClick={() => this.setState({ showWizard: true })}
                        leftIcon={<FontAwesomeIcon icon={faPlusSquare} />}
                        color={Colors.success}
                        title="Nota de Atualização"
                    />
                    :
                    <div />
                }
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: this.state.canCreate ? "calc(100% - 200px)" : "100%", justifyContent: "center" }}>
                    <div style={{ fontWeight: 'bold', textAlign: "center", fontSize: this.state.isSmallScreen ? 18 : 22, overflow: "hidden", maxWidth: this.state.isSmallScreen ? "calc(100% - 40px)" : "calc(100% - 160px)", width: "max-content", position: "relative", height: "35px" }}>
                        <EllipsisText text={this.props.t('client_src_pages_atualizacoes_AtualizacoesPage:atualizacoes')} />
                    </div>
                </div>
                <div />
            </div>
        );
    }

    addCallback = async () => {
        this.setState({ editId: null, showWizard: false, showEdit: false }, this.loadData);
    }

    editCallback = async (id) => {
        this.setState({ editId: id }, () => this.setState({ showEdit: true }));
    }

    render() {
        if (this.state.loading) return <LoadingPage />
        if (this.state.showWizard) return <AddAtualizacao addCallback={this.addCallback} />;
        if (this.state.showEdit) return <EditAtualizacao addCallback={this.addCallback} id={this.state.editId} />;

        return (
            <>
                {this.renderHeader()}
                <AtualizacoesTimeline canCreate={this.state.canCreate} editCallback={this.editCallback} />
            </>
        );
    }
}

export default withTranslation()(AtualizacoesPage);