import React from "react";
import { withTranslation } from "react-i18next";
import EssentialStyle from "../../../style/EssentialStyle";
import UserAvatar from "../../tools/UserAvatar";
import Sig from "../../../api/Sig";
import Colors from "../../../constants/Colors";
import moment from "moment";
import DefaultLoader from "../../tools/DefaultLoader";

class PlanoAcaoEtapaHistoricoList extends React.Component {
    state = {
        historico: [],
        loading: true
    }

    async componentDidMount() {
        await this.props.i18n.loadNamespaces(["client_src_components_modules_planoAcao_PlanoAcaoEtapaHistoricoList"]);
        await this.loadData();
    }

    async componentDidUpdate(prevProps) {
        if (prevProps.reload !== this.props.reload) {
            if (this.props.reload) await this.loadData();
        }
    }

    loadData = async () => {
        this.setState({ loading: true });

        const response = await Sig.request('GET', 'planoAcao/getEtapaHistorico', { idEtapa: this.props.idEtapa });
        let historico = [];

        if (response && response.status === 200) {
            historico = response?.historico || [];
        }

        this.setState({ historico }, () => { this.setState({ loading: false }); });
        if (this.props.reloadedCallback) this.props.reloadedCallback();
    }

    render() {
        if (this.state.loading)
            return (
                <div style={{ ...EssentialStyle.columnCenter, minHeight: 100, width: '100%' }}>
                    <DefaultLoader />
                </div>
            );

        return (
            <div style={{ ...EssentialStyle.columnStart, width: '100%', height: 'auto', backgroundColor: Colors.white }}>
                <div style={{ ...EssentialStyle.columnStart, width: '100%', height: 'auto' }}>
                    {this.state.historico?.length > 0 ? this.state.historico.map((h, index) => {
                        const responsavel = this.props.participantes.find(p => p.nome === h.responsavel);
                        const alteracoes = h.descricao.split("<br>");

                        return (
                            <div className="historicoEtapa" key={h.id} style={{ ...EssentialStyle.columnStart, width: '100%', borderBottom: `1px solid ${Colors.homePage.extraLightGrey}`, padding: "10px 5px" }}>
                                <div style={{ ...EssentialStyle.columnCenter, width: '100%', height: 'auto' }}>
                                    <div style={{ ...EssentialStyle.rowSpaceBetween, width: '100%', marginBottom: 8 }}>
                                        <div style={{ width: 'calc(100% - 125px)' }}>
                                            {responsavel ? <UserAvatar user={responsavel} showNameAdaptative showName /> : <UserAvatar nome={h.responsavel} showNameAdaptative showName />}
                                        </div>
                                        <span style={{ display: "flex", width: "125px", justifyContent: "flex-end", fontSize: "0.7rem", fontWeight: "bold", color: Colors.homePage.grey }}>
                                            {moment(h.data).format("D/MM/YYYY " + `[${this.props.i18n.t('client_src_components_modules_planoAcao_PlanoAcaoEtapaHistoricoList:as')}]` + " HH:mm")}
                                        </span>
                                    </div>
                                    <div style={{ whiteSpace: "nowrap" }}>
                                        {alteracoes ? alteracoes.map((a, i) => (
                                            <span key={"historico-" + i} style={{ display: "flex", width: "100%", flexWrap: "wrap", whiteSpace: "break-spaces" }} dangerouslySetInnerHTML={{ __html: a }} />
                                        )) : null}
                                    </div>
                                </div>
                            </div>
                        );
                    }) :
                        <div style={{ ...EssentialStyle.rowFlexCenter, width: '100%', height: 'auto', padding: 15, backgroundColor: Colors.white }}>
                            {this.props.i18n.t('client_src_components_modules_planoAcao_PlanoAcaoEtapaHistoricoList:nenhum_historico_disponivel')}
                        </div>
                    }
                </div>
            </div>
        );
    }
}

export default withTranslation()(PlanoAcaoEtapaHistoricoList);