import React from "react";
import Sig from "../../../../../../api/Sig";
import EssentialStyle from "../../../../../../style/EssentialStyle";
import DefaultLoader from "../../../../../tools/DefaultLoader";
import AtaReuniaoTopicoNotaCard from "./AtaReuniaoTopicoTarefaNotaCard";
import { toast } from 'react-toastify';
import TextEditor from "../../../../../tools/TextEditor/TextEditor";
import DefaultButton from "../../../../../tools/DefaultButton";
import Colors from "../../../../../../constants/Colors";
import KeyboardHelper from "../../../../../../helper/KeyboardHelper";
import CustomConfirm from "../../../../../tools/CustomConfirm";
import { confirmAlert } from "react-confirm-alert";
import DataHelper from "../../../../../../helper/DataHelper";

export default class AtaReuniaoTopicoTarefaNotas extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            comentarios: [],
            descricao: '',
            loading: false,
            loadingAddNew: false,
            addInputFocused: false,
            idComentarioUrl: null
        };
    }

    async componentDidMount() {
        await this.loadData();
    }

    async loadData() {
        if (!this.props.idTarefa) return;

        this.setState({ loading: true });

        let {comentarios, participantes} = await Sig.request("GET", `/ataReuniao${this.props.hash ? "Externa" : ""}/listComentariosTarefaTopico`, { id: this.props.idTarefa, hash: this.props.hash });

        this.setState({ comentarios, participantes }, () => { this.setState({ loading: false }) });
    }

    editCallback = async (comentario) => {
        
        let req = await Sig.request("POST", `/ataReuniao${this.props.hash ? "Externa" : ""}/saveComentarioTarefaTopico`,
            {
                id: comentario.id,
                hash: this.props.hash,
                descricao: comentario.descricao,
                id_ata_reuniao_topico_tarefa: comentario.id_ata_reuniao_topico_tarefa
            }
        );
        
        if (req && req.status === 200) {
            let comentarios = this.state.comentarios;
            let index = comentarios.findIndex(c => c.id === comentario.id);
            comentarios[index] = req.comentario;
            this.setState({ comentarios });
        } else {
            toast.error("Erro ao editar comentário");
        }
    }

    removeCallback = async (comentario) => {
        confirmAlert({
            customUI: ({ onClose }) => (
                <CustomConfirm
                    title={'Remover este comentário?'}
                    message={<p style={{ marginTop: 10, marginBottom: 10 }}>Esta ação é irreversível.</p>}
                    buttons={[
                        {
                            label: 'Remover',
                            color: Colors.error,
                            textColor: Colors.light,
                            onClick: async () => { 
                                let comentarios = this.state.comentarios;
                                let index = comentarios.findIndex(c => c.id === comentario.id);
                                comentarios.splice(index, 1);

                                let req = await Sig.request("POST", `/ataReuniao${this.props.hash ? "Externa" : ""}/removeComentarioTarefaTopico`, { id: comentario.id, hash: this.props.hash });

                                if (req && req.status === 200) {
                                    this.setState({ comentarios });
                                } else {
                                    toast.error("Erro ao remover comentário");
                                }    

                                onClose(); 
                            }
                        },
                        {
                            label: 'Cancelar',
                            onClick: () => { onClose(); }
                        },
                    ]}
                />
            )
        });
    }

    saveComentario = async () => {
        if (!this.state.descricao) {
            toast.error("Escreva o comentário");
            return;
        }

        this.setState({ loadingAddNew: true });

        let req = await Sig.request("POST", `/ataReuniao${this.props.hash ? "Externa" : ""}/saveComentarioTarefaTopico`,
            {
                descricao: this.state.descricao,
                id_ata_reuniao_topico_tarefa: this.props.idTarefa,
                hash: this.props.hash,
                id: 0
            }
        );

        if (req && req.status === 200) {
            let comentario = req.comentario;
            let comentarios = [comentario, ...this.state.comentarios];
            this.setState({ comentarios, loadingAddNew: false });
        } else {
            toast.error("Erro ao adicionar comentário");
            this.setState({ loadingAddNew: false });
        }

        if (this.addInput && this.addInput.getEditor) {
            const quillEditor = this.addInput.getEditor();
            if (quillEditor && typeof quillEditor.blur === 'function') {
                quillEditor.blur();
            }
            quillEditor.setContents([]);
        }

        this.setState({ descricao: '', addInputFocused: false });
    }

    renderComentarios() {
        return (
            <div style={{ width: '100%' }}>
                {this.state.loading && <div style={{ ...EssentialStyle.columnCenter, width: '100%', minHeight: 100 }}>
                    <DefaultLoader />
                </div>}
                {!this.state.loading && this.state.comentarios?.length ?
                    this.state.comentarios.map((comentario, index) => (
                        <AtaReuniaoTopicoNotaCard
                            key={`ata-topico-comentario-${index}-${comentario.id}`}
                            comentario={comentario}
                            editCallback={this.editCallback}
                            selectedUrl={this.state.idComentarioUrl == comentario.id}
                            removeCallback={this.removeCallback}
                            participantes={this.state.participantes}
                            last={index === this.state.comentarios.length - 1}
                        />
                    ))
                    :
                    null
                }
            </div>
        );
    }

    renderActionButtons() {
        return (
            <div style={{ ...EssentialStyle.rowFlexStart, width: '100%', marginBottom: 8 }}>
                <DefaultButton
                    title={'Salvar'}
                    color={Colors.success}
                    textColor={Colors.white}
                    loading={this.state.loadingAddNew}
                    style={{ height: 26, ...EssentialStyle.rowFlexCenter, marginRight: 8 }}
                    onClick={this.saveComentario}
                />
                <DefaultButton
                    title={'Cancelar'}
                    color={Colors.tag}
                    textColor={Colors.dark}
                    loading={this.state.loadingAddNew}
                    style={{ height: 26, ...EssentialStyle.rowFlexCenter }}
                    onClick={() => {
                        if (this.addInput && this.addInput.getEditor) {
                            const quillEditor = this.addInput.getEditor();
                            if (quillEditor && typeof quillEditor.blur === 'function') {
                                quillEditor.blur();
                            }
                            quillEditor.setContents([]);
                        }

                        this.setState({ descricao: '', addInputFocused: false });
                    }}
                />
            </div>
        );
    }

    renderAddComentario() {

        if (this.props.blockEdit) {
            return (
                <div style={{ ...EssentialStyle.columnCenter, width: '100%', height: 'auto', marginTop: 20 }}/>
            );
        }

        return (
            <div
                style={{ ...EssentialStyle.columnCenter, width: '100%', height: 'auto', marginTop: 12 }}
            >
                <div
                    style={{ width: '100%' }}
                    onClick={() => {
                        if (!this.state.addInputFocused) this.setState({ addInputFocused: true });
                    }}
                >
                    <TextEditor
                        ref={ref => this.addInput = ref}
                        defaultValue={this.state.descricao}
                        placeholder={"Adicionar comentário..."}
                        onChange={(value) => {
                            this.setState({ descricao: value })
                        }}
                        hideToolbar={false}
                        mentions={DataHelper.formatMentionData(this.state.participantes, 'id', 'nome')}
                        maxHeight={300}
                        noMargin={false}
                        onKeyDown={(evt) => {
                            KeyboardHelper.handleShortcut(
                                evt,
                                ["Enter", "Escape"],
                                [
                                    () => {
                                        if (!(evt.shiftKey || evt.ctrlKey || evt.metaKey)) return;

                                        this.saveComentario();
                                    },
                                    (e) => {
                                        e.preventDefault();

                                        if (this.addInput && this.addInput.getEditor) {
                                            const quillEditor = this.addInput.getEditor();
                                            if (quillEditor && typeof quillEditor.blur === 'function') {
                                                quillEditor.blur();
                                            }
                                            quillEditor.setContents([]);
                                        }

                                        this.setState({ descricao: '', addInputFocused: false });
                                    }]
                            )
                        }}
                    />
                </div>

                {this.state.addInputFocused ? this.renderActionButtons() : null}
            </div>
        );
    }

    render() {
        return (
            <div style={{ ...EssentialStyle.columnCenter, width: '100%' }}>
                {this.renderAddComentario()}
                {this.renderComentarios()}
            </div>
        );
    }
}