// Bibliotecas externas
import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle, faPaperPlane, faSave } from '@fortawesome/free-solid-svg-icons';
import Select from "react-select";
import { toast } from "react-toastify";
import { withTranslation } from 'react-i18next';
import { Form } from "react-bootstrap";

// Helpers
import DataHelper from "../../../../../../helper/DataHelper";
import SessionHelper from "../../../../../../helper/SessionHelper";
import DiagnosticoHelper from "../../../../../../helper/diagnostico/DiagnosticoHelper";
import IdiomaHelper from "../../../../../../helper/IdiomaHelper";

// Estilos
import Colors from "../../../../../../constants/Colors";
import EssentialStyle from "../../../../../../style/EssentialStyle";
// - Comentado até que seja adicionado algo - 
// import './AddResultadoTabela.css';

// Componentes
import DefaultButton from "../../../../../tools/DefaultButton";
import DefaultLoader from "../../../../../tools/DefaultLoader";

class AddResultadoTabela extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,

            item: props.item,

            colunas: [],
            colunasQuestionario: [],
            colunaSelected: null,
            nodes: [],
            nodeSelected: [],
            widthSelected: 6,

            draggingItem: null,
            draggingOverItem: null,
            widthDragItem: 0,
            left: false,

            headerHeight: 0,
        }
            
    }

    headerRef = React.createRef();

    async componentDidMount() {
        await this.props.i18n.loadNamespaces(['client_src_components_modules_diagnostico_CriacaoRelatorio_AddModuloRelatorio_AddResultadoTabela_AddResultadoTabela']);
        await this.props.i18n.loadNamespaces(['client_src_helper_diagnostico_DiagnosticoHelper']);

        await this.loadData();
        this.measureHeaderHeight();
        window.addEventListener('resize', this.measureHeaderHeight);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.measureHeaderHeight);
    }

    measureHeaderHeight = () => {
        const headerElement = document.querySelector('.criacaoRelatorio');
        if (headerElement) {
            const height = headerElement.offsetHeight;
            if (height !== this.state.headerHeight) {
                this.setState({ headerHeight: height });
            }
        }
    }

    async loadData() {
        this.setState({ loading: true });
        
        await this.loadQuestionarios();
        if (this.props.item.questionario) this.loadColumnsQuestionario(this.props.item.questionario.id, false);
        await this.loadOptionsAgrupamentoIcones();

        this.setState({
            item: {
                ...this.props.item,
                on: true,
                type: this.props.type,
                questionario: this.props.item.questionario || null,
                tableType: this.props.item.tableType || 'lista',
            }
        }, () => { this.props.onSetItem(this.state.item) } );

        this.setState({ loading: false });
    }

    async loadQuestionarios() {
        let { questionarios } = await DiagnosticoHelper.getQuestionarios(this.props.idPeriodoAvaliacao, 0);
        this.setState({ questionarios }, () => {
            if (questionarios && questionarios.length == 1 && this.state.item.questionario == null) {
                this.setState({ item: {...this.state.item, questionario: questionarios[0]} }, () => { 
                    this.props.onSetItem(this.state.item);
                    this.loadColumnsQuestionario(questionarios[0].id);
                });
            }
        });
    }

    async loadColumnsQuestionario(idQuestionario, setQuestionario = true) {
        let {colunas} = await DiagnosticoHelper.getColunasQuestionario(idQuestionario, this.props.idPeriodoAvaliacao, null, null);
        
        if (setQuestionario) {
            this.setState({ 
                item: {...this.state.item, list: {...this.state.item.list, colunas: []}, matriz: {coluna_x: null, coluna_y: null} },
                colunas,
                colunaSelected: null,
            }, () => { this.props.onSetItem(this.state.item) });
        } else {
            this.setState({ colunas }, () => { this.props.onSetItem(this.state.item) });
        }
    }   

    async loadOptionsAgrupamentoIcones() {
        let {options} = await DiagnosticoHelper.getOptionsAgrupamentoIconesTabelaResultado();

        if(options){
            this.setState({optionsAgrupamentoIcones: DataHelper.transformArrayToSelectData(options)})
        }
    }

    verify = () => {

        let item = this.state.item;

        if (!item.questionario) {
            return toast.error(this.props.i18n.t('client_src_components_modules_diagnostico_CriacaoRelatorio_AddModuloRelatorio_AddResultadoTabela_AddResultadoTabela:voce_deve_escolher_questionario'));
        }

        if (item?.tableType == 'lista' && (!item?.list || !item?.list?.colunas || item?.list?.colunas?.length == 0)) {
            return toast.error(this.props.i18n.t('client_src_components_modules_diagnostico_CriacaoRelatorio_AddModuloRelatorio_AddResultadoTabela_AddResultadoTabela:voce_deve_escolher_coluna'));
        }

        if (item?.tableType == 'matriz' && (!item?.matriz || !item?.matriz?.coluna_x || !item?.matriz?.coluna_y)) {
            return toast.error(this.props.i18n.t('client_src_components_modules_diagnostico_CriacaoRelatorio_AddModuloRelatorio_AddResultadoTabela_AddResultadoTabela:selecione_todas_colunas_matriz'));
        }

        this.props.addModule && this.props.addModule();
    }

    renderHeader = () => {
        return (
            <div className="headerClose" style={{backgroundColor: SessionHelper.getColor()}}>
                <div/>
                {DiagnosticoHelper.getFunctionsModulosModeloRelatorioByType(this.props.type, this.props.t)?.title ?? ''}
                <FontAwesomeIcon icon={faTimesCircle} style={{ cursor: 'pointer' }} onClick={() => this.props.onClose()} />
            </div>
        )
    }

    renderBody = () => {

        let item = this.state.item;

        let questionarios = DataHelper.formatSelectData(this.state.questionarios, 'id', 'nome');
        let questionarioSelect = item?.questionario ? questionarios.find((questionario) => questionario.value === item?.questionario?.id) : null;
        
        return(
            <div className="bodyModulo">

                <div className="content box">
                    <span>
                        {this.props.i18n.t('client_src_components_modules_diagnostico_CriacaoRelatorio_AddModuloRelatorio_AddResultadoTabela_AddResultadoTabela:questionario')}
                    </span>

                    <Select
                        options={questionarios}
                        value={questionarioSelect}
                        placeholder={this.props.i18n.t('client_src_components_modules_diagnostico_CriacaoRelatorio_AddModuloRelatorio_AddResultadoTabela_AddResultadoTabela:selecione_questionario')}
                        onChange={(e) => { 
                            this.setState({ 
                                item: { 
                                    ...this.state.item, 
                                    questionario: this.state.questionarios.find((item) => item.id == e.value),
                                    list: {colunas: []}, 
                                    matriz: {coluna_x: null, coluna_y: null}
                                }
                            }, () => { 
                                    this.props.onSetItem(this.state.item);
                                    this.loadColumnsQuestionario(e.value);
                            }) 
                        }}
                        styles={{
                            container: (provided) => ({
                                ...provided,
                                width: '100%',
                                marginBottom: '20px'
                            }),
                            menu: (provided) => ({
                                ...provided,
                                zIndex: 9999
                            }),
                            menuPortal: (provided) => ({
                                ...provided,
                                zIndex: 9999
                            }),
                        }}
                        menuPortalTarget={document.body}
                        menuPlacement="auto"
                        menuPosition="fixed"
                    />

                    <span>
                        {this.props.i18n.t('client_src_components_modules_diagnostico_CriacaoRelatorio_AddModuloRelatorio_AddResultadoTabela_AddResultadoTabela:formato_tabela')}
                    </span>

                    <DefaultButton
                        tooltipPlacement={'left'} 
                        leftIcon={
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                                {this.props.i18n.t('client_src_components_modules_diagnostico_CriacaoRelatorio_AddModuloRelatorio_AddResultadoTabela_AddResultadoTabela:formato_lista')}
                                <Form.Switch
                                    type="switch"
                                    disabled
                                    checked={ item.tableType === 'matriz' ? true : false }
                                    style={{ marginLeft: 3, marginRight: 3 }}
                                />
                                {this.props.i18n.t('client_src_components_modules_diagnostico_CriacaoRelatorio_AddModuloRelatorio_AddResultadoTabela_AddResultadoTabela:formato_matriz')}
                            </div>
                        }
                        color={Colors.secondaryButton} 
                        textColor={Colors.dark}
                        style={{ width: '100%' }}
                        onClick={() => { 
                            let item = {
                                ...this.state.item,
                                tableType: this.state.item.tableType === 'lista' ? 'matriz' : 'lista',
                                colunas: []
                            }
                            this.setState({ item }, () => { this.props.onSetItem(this.state.item) });
                        }} 
                    />

                </div>

                {item.questionario && 
                    <div className="content box">
                        {this.renderSeletorColunas()}
                        {item.tableType == 'lista' && this.renderTableList()}
                    </div>
                }
                
                <div className="buttons">
                    <DefaultButton
                        title={this.props.i18n.t('client_src_components_modules_diagnostico_CriacaoRelatorio_AddModuloRelatorio_AddResultadoTabela_AddResultadoTabela:salvar')}
                        leftIcon={<FontAwesomeIcon icon={faSave}/>}
                        color={Colors.success}
                        tooltip={this.props.i18n.t('client_src_components_modules_diagnostico_CriacaoRelatorio_AddModuloRelatorio_AddResultadoTabela_AddResultadoTabela:salvar_tooltip')}
                        onClick={() => { this.verify(); }}
                    />
                </div>
            </div>
        )
    }

    renderSeletorColunas = () => {
                
        const renderSeletorList = () => {

            const addIndicadorsToTabelaList = () => {
                if (!this.state.colunaSelected) return;

                let item = {
                    ...this.state.item,
                    list: {
                        ...this.state.item.list,
                        colunas: this.state.item.list?.colunas ? [...this.state.item.list.colunas, this.state.colunaSelected] : [this.state.colunaSelected]
                    }
                }

                this.setState({ item, colunaSelected: null }, () => { this.props.onSetItem(this.state.item) });
            }
            
            let colunasDisponiveis = this.state.colunas.filter((coluna) => !this.state.item?.list?.colunas?.some((item) => item.id === coluna.id)) ||  this.state.colunas;
            let colunasOptions = DataHelper.formatSelectDataLang(colunasDisponiveis, 'id', 'descricao', this.props.lang);
            let colunasSelected = this.state.colunaSelected ? colunasOptions.find((item) => item.value === this.state.colunaSelected.id) : null
            
            return (
                <>
                    <span>
                        {this.props.i18n.t('client_src_components_modules_diagnostico_CriacaoRelatorio_AddModuloRelatorio_AddResultadoTabela_AddResultadoTabela:adicionar_coluna_tabela')}
                    </span>

                    <div style={{ ...EssentialStyle.rowFlexStart, width: '100%', gap: 5}}>
                        <Select
                            options={colunasOptions}
                            value={colunasSelected}
                            placeholder={this.props.i18n.t('client_src_components_modules_diagnostico_CriacaoRelatorio_AddModuloRelatorio_AddResultadoTabela_AddResultadoTabela:selecione_coluna')}
                            onChange={(e) => { this.setState({ colunaSelected: this.state.colunas.find((item) => item.id == e.value)} , () => { this.props.onSetItem(this.state.item) }) }}
                            styles={{
                                container: (provided) => ({
                                    ...provided,
                                    width: 'calc(100% - 45px)',
                                    marginBottom: '20px'
                                }),
                                menu: (provided) => ({
                                    ...provided,
                                    zIndex: 9999
                                }),
                                menuPortal: (provided) => ({
                                    ...provided,
                                    zIndex: 9999
                                }),
                            }}
                            menuPortalTarget={document.body}
                            menuPlacement="auto"
                            menuPosition="fixed"
                        />

                        <DefaultButton
                            leftIcon={<FontAwesomeIcon icon={faPaperPlane}/>}
                            color={Colors.success}
                            tooltip={this.props.i18n.t('client_src_components_modules_diagnostico_CriacaoRelatorio_AddModuloRelatorio_AddResultadoTabela_AddResultadoTabela:adicionar_coluna_tooltip')}
                            onClick={addIndicadorsToTabelaList}
                            style={{marginBottom: 20, padding: '4px 10px'}}
                        />
                    </div>
                </>
            )
        }

        const renderSeletorMatriz = () => {

            const addColunaToTabelaMatriz = (col, e) => {

                let coluna = this.state.colunas.find((coluna) => coluna.id == e.value);

                let item = {
                    ...this.state.item,
                    matriz: {
                        coluna_x: col == 'x' ? coluna : this.state.item.matriz?.coluna_x,
                        coluna_y:  col == 'y' ? coluna : (this.state.item.matriz?.coluna_x?.id == coluna.id ? this.state.item.matriz?.coluna_y : null),
                    }
                }

                if (col == 'x') {
                    let colunas = this.state.colunas ?? [];
                    colunas.sort((a, b) => a.drag_index - b.drag_index);

                    let colunasExpanded = [];
                    let index = colunas.findIndex((coluna_x) => coluna_x.id == coluna.id);

                    if (index > 0) colunasExpanded.push(colunas[index - 1]);

                    this.setState({ colunasExpanded });

                    if (colunasExpanded.length == 1) {
                        item = {
                            ...item,
                            matriz: {
                                ...item.matriz,
                                coluna_y: colunasExpanded[0]
                            }
                        }
                    } else {
                        item = {
                            ...item,
                            matriz: {
                                ...item.matriz,
                                coluna_y: null
                            }
                        }
                    }
                }

                this.setState({ item }, () => { this.props.onSetItem(this.state.item) });
            }

            let colunas = this.state.colunas ?? [];
            colunas.sort((a, b) => a.drag_index - b.drag_index);

            let colunasOptions = DataHelper.formatSelectDataLang(this.state.colunas, 'id', 'descricao', this.props.lang);
            let colunasSelected = this.state.item.matriz?.coluna_x ? colunasOptions.find((item) => item.value === this.state.item.matriz.coluna_x.id) : null

            let optionsAgrupamento = this.state.optionsAgrupamentoIcones ?? [];
            let optionsAgrupamentoSelected = optionsAgrupamento?.find(p => p.value == this.state.item?.tipo_agrupamento_icones)
            
            return (
                <div style={{ ...EssentialStyle.rowFlexStart, width: '100%', gap: 5, flexWrap: 'wrap'}}>
                    
                    <div style={{ ...EssentialStyle.columnStart, width: 'calc(50% - 2.5px)', gap: 5}}>
                        <span>
                            {this.props.i18n.t('client_src_components_modules_diagnostico_CriacaoRelatorio_AddModuloRelatorio_AddResultadoTabela_AddResultadoTabela:adicionar_coluna_tabela')}
                        </span>
                        <Select
                            options={colunasOptions}
                            value={colunasSelected}
                            placeholder={this.props.i18n.t('client_src_components_modules_diagnostico_CriacaoRelatorio_AddModuloRelatorio_AddResultadoTabela_AddResultadoTabela:selecione_coluna')}
                            onChange={(e) => addColunaToTabelaMatriz('x', e)}
                            styles={{
                                container: (provided) => ({
                                    ...provided,
                                    width: '100%',
                                    marginBottom: '20px'
                                }),
                                menu: (provided) => ({
                                    ...provided,
                                    zIndex: 9999
                                }),
                                menuPortal: (provided) => ({
                                    ...provided,
                                    zIndex: 9999
                                }),
                            }}
                            menuPortalTarget={document.body}
                            menuPlacement="auto"
                            menuPosition="fixed"
                        />
                    </div>

                    <div style={{ ...EssentialStyle.columnStart, width: 'calc(50% - 2.5px)', gap: 5}}>
                        <span>
                            {this.props.i18n.t('client_src_components_modules_diagnostico_CriacaoRelatorio_AddModuloRelatorio_AddResultadoTabela_AddResultadoTabela:coluna_expandida')}
                        </span>

                        <Select
                            options={DataHelper.formatSelectDataLang(this.state.colunasExpanded, 'id', 'descricao', this.props.lang)}
                            value={this.state.item.matriz?.coluna_y ? DataHelper.formatSelectDataLang(this.state.colunas, 'id', 'descricao', this.props.lang).find((item) => item.value === this.state.item.matriz.coluna_y.id) : null}
                            placeholder={this.props.i18n.t('client_src_components_modules_diagnostico_CriacaoRelatorio_AddModuloRelatorio_AddResultadoTabela_AddResultadoTabela:selecione_coluna')}
                            onChange={(e) => addColunaToTabelaMatriz('y', e)}
                            styles={{
                                container: (provided) => ({
                                    ...provided,
                                    width: '100%',
                                    marginBottom: '20px'
                                }),
                                menu: (provided) => ({
                                    ...provided,
                                    zIndex: 9999
                                }),
                                menuPortal: (provided) => ({
                                    ...provided,
                                    zIndex: 9999
                                }),
                            }}
                            menuPortalTarget={document.body}
                            menuPlacement="auto"
                            menuPosition="fixed"
                        />
                    </div>

                    <div style={{ ...EssentialStyle.columnStart, width: '100%', gap: 5}}>
                        <span>
                            {this.props.i18n.t('client_src_components_modules_diagnostico_CriacaoRelatorio_AddModuloRelatorio_AddResultadoTabela_AddResultadoTabela:posicao_icones')}
                        </span>
                        <Select
                            options={optionsAgrupamento}
                            value={optionsAgrupamentoSelected}
                            placeholder={this.props.i18n.t('client_src_components_modules_diagnostico_CriacaoRelatorio_AddModuloRelatorio_AddResultadoTabela_AddResultadoTabela:selecione_posicao_icones')}
                            onChange={(e) => this.setState({ item: { ...this.state.item, tipo_agrupamento_icones: e.value } }, () => { this.props.onSetItem(this.state.item)})}
                            styles={{
                                container: (provided) => ({
                                    ...provided,
                                    width: '100%',
                                    marginBottom: '20px'
                                }),
                                menu: (provided) => ({
                                    ...provided,
                                    zIndex: 9999
                                }),
                                menuPortal: (provided) => ({
                                    ...provided,
                                    zIndex: 9999
                                }),
                            }}
                            menuPortalTarget={document.body}
                            menuPlacement="auto"
                            menuPosition="fixed"
                        />
                    </div>
                    
                </div>
            )
        }

        return this.state.item.tableType == 'lista' ? renderSeletorList() : renderSeletorMatriz();
    }

    renderTableList = () => {

        const removeColunaList = (id) => {
            let item = {
                ...this.state.item,
                list: {
                    ...this.state.item.list,
                    colunas: this.state.item.list?.colunas.filter((coluna) => coluna.id !== id)
                }
            }

            this.setState({ item }, () => { this.props.onSetItem(this.state.item) });
        }

        let colunasItem = this.state.item?.list?.colunas ?? [];
        colunasItem.sort((a, b) => a.drag_index - b.drag_index);

        if (colunasItem.length === 0) return null;
        return (
            <>
                <span>{this.props.i18n.t('client_src_components_modules_diagnostico_CriacaoRelatorio_AddModuloRelatorio_AddResultadoTabela_AddResultadoTabela:exemplo_tabela_resultados')}</span>
                
                <div className="table-container">
                    <table className="table-results">
                        <thead>
                            <tr className="rowHeader">
                                {colunasItem.map((coluna) => {
                                    return(
                                        <th key={`coluna-${coluna.id}`} className={"cellHeader"}>

                                            {coluna.descricao.find((coluna) => coluna.id_idioma == IdiomaHelper.getSiglaId(this.props.lang)).descricao}
                                            <FontAwesomeIcon 
                                                icon={faTimesCircle} 
                                                style={{ cursor: 'pointer', color: Colors.error, fontSize: '1rem', marginLeft: 5 }} 
                                                onClick={() => { removeColunaList(coluna.id) }}
                                            />
                                        </th>
                                    )
                                })}

                                <th key={`coluna-result`} className={"cellHeader result"}>
                                    {this.props.i18n.t('client_src_components_modules_diagnostico_CriacaoRelatorio_AddModuloRelatorio_AddResultadoTabela_AddResultadoTabela:resultados')}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                {colunasItem.map((coluna) => {
                                    return(
                                        <td key={`coluna-item-${coluna.id}`} className={"cell"}>
                                            ...
                                        </td>
                                    )
                                })}

                                <td key={`coluna-item-result}`} className={"cell result"}>
                                    {'--%'}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </>
        )
    }

    renderLoading() {
        return (
            <div className="bodyModulo" style={{justifyContent: 'center', alignItems: 'center', overflow: 'hidden', height: "calc(100% - 3rem)"}}>
                <DefaultLoader/>
            </div>
        )
    }

    renderContent() {

        return (
            <div className="ModuloConfig" style={{maxHeight: `calc(${this.state.headerHeight}px - 20px)`, minHeight: '30vh'}}>
                {this.renderHeader()}
                {!this.state.loading ? this.renderBody() : this.renderLoading()}
            </div>
        )
    }

    render() {
        if (!this.props.type) return <></>;
        return this.renderContent();
    }
}

export default withTranslation()(AddResultadoTabela);