import React from "react";

import './SelectWidth.css';
import DiagnosticoHelper from "../../../helper/diagnostico/DiagnosticoHelper";
import SessionHelper from "../../../helper/SessionHelper";

export default class SelectWidth extends React.Component {

    state = {
        hoverItemWidth: null,
    }
    render () {

        let array = DiagnosticoHelper.selectWidthOptions();

        return (
            <div className="selectWidth">
            {array.map((item, index) => {
                return (
                    <div
                        key={item.id}
                        className={`itemWidth ${item.id <= this.state.hoverItemWidth ? 'itemWidth-hover' : ''} ${item.id <= this.props.widthSelected ? 'itemWidth-selected' : ''}`}
                        style={{ backgroundColor: item.id <= this.props.widthSelected ? SessionHelper.getColor() : '' }}
                        onClick={() => { this.props.onClick(item.id) }}
                        onMouseEnter={() => { this.setState({ hoverItemWidth: item.id }) }}
                        onMouseLeave={() => { this.setState({ hoverItemWidth: null }) }}
                    >
                        {(this.props.widthSelected == item.id || this.state.hoverItemWidth == item.id) && item.largura}
                    </div>
                )
            })}
        </div>
        )
    }
}