import React, { Component } from 'react';
import './AvaliacaoQuestaoCard.css';
import InViewWrapper from '../../../../tools/InViewWrapper';    

import EssentialStyle from '../../../../../style/EssentialStyle';
import StarPicker from '../../../../tools/StarPicker/StarPicker';
import NumericoPicker from '../../../../tools/NumericoPicker/NumericoPicker';
import ConceitoPicker from '../../../../tools/ConceitoPicker/ConceitoPicker';
import TextEditor from '../../../../tools/TextEditor/TextEditor';
import Sig from '../../../../../api/Sig';
import UploadFiles from '../../../../tools/UploadFiles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp, faChevronLeft, faChevronRight, faGaugeSimpleHigh } from '@fortawesome/free-solid-svg-icons'
import { Collapse } from "react-bootstrap";
import IdiomaHelper from '../../../../../helper/IdiomaHelper';
import Colors from '../../../../../constants/Colors';
import DefaultButton from '../../../../tools/DefaultButton';
import HistoricoQuestao from './HistoricoQuestao/HistoricoQuestao.js';

import { withTranslation } from 'react-i18next';
import CustomTooltip from '../../../../tools/CustomTooltip';
import UserAvatar from '../../../../tools/UserAvatar';

class AvaliacaoQuestaoCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            isOpen: false,
            selectors: [],
            isInView: false,
            height: ((this.props.questao?.resposta && this.props.questao?.resposta?.valor) || this.props.questao.indicadoresVinculados?.length > 0) ? "108px" : "98px",
            
            opacity: 1,
            transform: 'translateX(0)',
            subscreens: ['adicionais', 'historico'],
            subscreenAtual: 'adicionais',
            refreshHistorico: false,
        }
        this.timeoutDescricao = null;
        this.containerRef = React.createRef();
    }

    handleInViewChange = (inView) => {
        this.setState({ isInView: inView });
    }

    async componentDidMount() {
        await this.props.i18n.loadNamespaces(['client_src_components_modules_diagnostico_Avaliacao_Aplicacao_AvaliacaoQuestaoCard']);
        this.setState({ loading: false });
    }

    componentDidUpdate(prevProps) {

        if(this.containerRef.current && this.containerRef.current.clientHeight !== this.state.height) {
            this.setState({ height: this.containerRef.current.clientHeight });
        }

        if ((prevProps.questao?.resposta?.valor != this.props.questao?.resposta?.valor || 
            prevProps.questao?.resposta?.descricao != this.props.questao?.resposta?.descricao ||
            prevProps.questao?.resposta?.anexos != this.props.questao?.resposta?.anexos) && !this.state.refreshHistorico) {
            this.setState({ refreshHistorico: true }, 
                () => setTimeout(() => this.setState({ refreshHistorico: false }), 1000)
            );
        }
        
    }

    getAnimations() {
        return {
            opacity: this.state.opacity,
            transform: this.state.transform,
            transition: 'opacity .3s ease-in-out, transform .6s ease-in-out'
        }
    }
    
    fadeAnimations(swipeRight = true) {
        this.setState({ opacity: 0, transform: `translateX(${swipeRight ? `-100%` : `+100%`})` });
    }

    triggerAnimations() {
        this.setState({ opacity: 1, transform: 'translateX(0)' });
    }

    changePage(increase = true, pageNumber = null) {
        let page = this.state.subscreens.indexOf(this.state.subscreenAtual);
        if (increase) {
            page = page + 1;
        } else {
            page = page - 1;
        }

        if(pageNumber !== null) {
            page = pageNumber;
            increase = pageNumber > this.state.subscreens.indexOf(this.state.subscreenAtual);
        } 

        if(page === this.state.subscreens.indexOf(this.state.subscreenAtual)) return;

        if (page < 0) {
            page = this.state.subscreens.length - 1;
        } else if (page > (this.state.subscreens.length - 1)) {
            page = 0;
        }

        this.fadeAnimations(increase);

        setTimeout(() => {
            this.setState({ subscreenAtual: this.state.subscreens[page] }, () => {
                this.triggerAnimations();
            });
        }, 400);
    }

    changeDescricaoRespostaHandler = (value) => {
        if (this.props.questao?.resposta?.descricao == value) return;

        if (this.props.onChangeDescricaoNode) {
            this.props.onChangeDescricaoNode(value, this.props.questao);
            if (this.timeoutDescricao) clearTimeout(this.timeoutDescricao);
            var resposta = { ...this.props.questao.resposta };
            resposta.descricao = value;
            this.timeoutDescricao = setTimeout(async () => {
                var respostaSalva = await this.updateDescricao(this.props.questao?.id, resposta)
                if (respostaSalva) {
                    this.props.onChangeResposta(respostaSalva);
                }
            }, 500);
        }
    }

    message(lang = this.props.lang) {
        let message = IdiomaHelper.getStruct({
            'pt_br': {
                consideracoesPlaceholder: 'Observações sobre a resposta...',
            },
            'en': {
                consideracoesPlaceholder: 'Response observations...',
            },
            'es': {
                consideracionesPlaceholder: 'observaciones de la respuesta...',
            }
        })

        return message[lang];
    }

    updateDescricao = async (idNode, resposta) => {
        var req = null;
        try {

            req = await Sig.request('POST', '/diagnostico/avaliacao/updateRespostaNode', {
                hash: this.props.hash,
                idNode,
                resposta: JSON.stringify(resposta)
            });
        } catch (e) {
            return false;
        }

        if (req && req.status === 200) {
            return req.data;
        }

        return false;
    }

    renderSubscreen = () => {
        switch (this.state.subscreenAtual) {
            case 'adicionais':
                return this.renderQuestaoAdicionais();
            case 'historico':
                return <HistoricoQuestao questao={this.props.questao} hash={this.props.hash} refresh={this.state.refreshHistorico} isSmallContainer={this.props.isSmallContainer}/>;
        }
    }

    renderSelector = (questao) => {
        var escala = this.props.escalas.find(e => e.id === questao.id_escala);
        var resposta = null;
        if (questao.resposta) {
            resposta = questao.resposta.valor;
        }

        if (!escala) return null;

        switch (escala.tipo) {
            case 'estrela':
                return (
                    <div className="star-selector">
                        <ConceitoPicker
                            onChange={(value) => {
                                this.props.onChangeValue({ target: { value: value } }, questao);
                            }}
                            escala={escala}
                            value={Math.trunc(resposta)}
                            type={'star'}
                            disabled={this.props.readOnly}
                        />
                    </div>
                );
            case 'conceito':
                return (
                    <ConceitoPicker
                        escala={escala}
                        value={resposta}
                        onChange={(value) => this.props.onChangeValue({ target: { value: value } }, questao)}
                        disabled={this.props.readOnly}
                    />
                );
            case 'numerica':
                return (
                    <NumericoPicker
                        id={questao.id}
                        escala={escala}
                        value={resposta}
                        faixas={this.props.faixas}
                        onChange={(e) => this.props.onChangeValue({ target: { value: e } }, questao)}
                        disabled={this.props.readOnly}
                    />
                );
        }
    }

    renderIndicadorResult = () => {
        var faixas = this.props.faixas;
        var cor = null;
        if (faixas && faixas.length > 0) {
            faixas.map(
                faixa => {
                    if (this.props.questao?.resultadoIndicadores?.valor >= faixa.min && this.props.questao?.resultadoIndicadores?.valor <= faixa.max) {
                        cor = faixa.cor;
                    }
                }
            )
            if(!cor && this.props.questao?.resultadoIndicadores?.valor < faixas[0].min) cor = faixas[0].cor;
            if(!cor && this.props.questao?.resultadoIndicadores?.valor > faixas[faixas.length - 1].max) cor = faixas[faixas.length - 1].cor;
        }

        if(!cor) cor = Colors.homePage.grey;

        var valor = this.props.questao?.resultadoIndicadores?.valor && this.props.questao?.resultadoIndicadores?.valor != 0 ? Math.round(this.props.questao?.resultadoIndicadores?.valor) + '%' : null;

        return (
            //circle with color cor and value valor
            <CustomTooltip
                tooltip={this.props.t('client_src_components_modules_diagnostico_Avaliacao_Aplicacao_AvaliacaoQuestaoCard:verResultadoIndicadores')}
                tooltipPlacement={'auto'}
            >
                {
                    valor ? 
                        <div style={{
                            display: 'flex', placeContent: 'center', alignItems: 'center', padding: "0px 10px",
                            borderRadius: "8px", minWidth: 40, height: 40, backgroundColor: cor, 
                            color: 'white', fontSize: 16, fontWeight: 500, cursor: 'pointer',
                        }}
                            onClick={() => this.props.showIndicadorModal(this.props.questao)}
                            >
                            {valor}
                        </div>
                    : 
                        <FontAwesomeIcon 
                            icon={faGaugeSimpleHigh} 
                            style={{ fontSize: 35, color: cor, cursor: 'pointer' }} 
                            onClick={() => this.props.showIndicadorModal(this.props.questao)}
                        />
                }
            </CustomTooltip>
        )
    }

    renderQuestaoAdicionais = (questao) => {
        return(
            <div className={"questao-adicionais " + (this.props.isSmallContainer ? 'small' : '')}>
                <div className={"questao-observacao"}>
                    <TextEditor
                        editorClassName={"fill-container-editor"}
                        maxHeight={220}
                        placeholder={this.message().consideracoesPlaceholder}
                        defaultValue={questao?.resposta?.descricao}
                        onChange={this.changeDescricaoRespostaHandler}
                        noMargin
                        disabled={this.props.readOnly}
                    />
                </div>
                <div className={"questao-uploads"}>
                    <UploadFiles
                        uploadCallback={this.uploadCallback}
                        removeFileCallback={this.removeFileCallback}
                        id={this.props.questao?.resposta?.id}
                        action={`diagnostico/avaliacao/anexoQuestao`}
                        limit={10}
                        hash={this.props.hash}
                        style={{ maxHeight: '220px' }}
                        noMarginBottom
                        disabled={this.props.readOnly}
                    />
                </div>
            </div>
        );
    }

    render() {
        const { questao } = this.props;
        var displayCollapse = questao?.resposta?.valor != null;

        return (
            <InViewWrapper onInViewChange={this.handleInViewChange} placeholderHeight={this.state.height}>
                <div ref={this.containerRef} key={this.props.key} className={"avaliacao-questao-card"} style={{ ...EssentialStyle.card, paddingBottom: (displayCollapse ? '0px' : '30px'), paddingTop: '30px' }}>
                    <div className={"questao " + (this.props.isSmallContainer ? 'small' : '')}>
                        <div className={"questao-descricao"}>
                            <h2>{questao?.descricao}</h2>
                        </div>
                        <div className={"questao-resposta"}>
                            {this.renderSelector(questao)}
                        </div>
                        <div className={"questao-icons"}>
                            {questao?.resposta?.id_colaborador && <UserAvatar id={questao?.resposta?.id_colaborador} />}
                            {questao?.indicadoresVinculados?.length > 0 && this.renderIndicadorResult()}
                        </div>
                    </div>
                    {
                        displayCollapse &&
                        <>
                            <CustomTooltip
                                tooltip={this.state.isOpen ? this.props.t('client_src_components_modules_diagnostico_Avaliacao_Aplicacao_AvaliacaoQuestaoCard:ocultarObservacoesEvidencias') : this.props.t('client_src_components_modules_diagnostico_Avaliacao_Aplicacao_AvaliacaoQuestaoCard:exibirObservacoesEvidencias')}
                                tooltipPlacement={'auto'}
                            >
                                <div
                                    type="button"
                                    className={"questao-adicionais-button"}
                                    onClick={() => this.setState({ isOpen: !this.state.isOpen })}
                                >
                                    <FontAwesomeIcon icon={this.state.isOpen ? faChevronUp : faChevronDown} />
                                </div>
                            </CustomTooltip>
                            <Collapse in={this.state.isOpen} style={{overflow: 'hidden'}} className={"questao-adicionais-collapse"}>
                                <div>
                                    {this.state.isOpen &&
                                        <div style={{...this.getAnimations()}} className={"questao-adicionais-container"}>
                                            <DefaultButton
                                                leftIcon={<FontAwesomeIcon icon={faChevronLeft} style={{ color: Colors.homePage.grey }} />}
                                                color={'transparent'}
                                                onClick={() => { this.changePage(false) }}
                                                style={{ marginBottom: 0, marginRight: 10, outline:'none', boxShadow: 'none' }}
                                            />
                                            {
                                                this.renderSubscreen()
                                            }
                                            <DefaultButton
                                                leftIcon={<FontAwesomeIcon icon={faChevronRight} style={{ color: Colors.homePage.grey }} />}
                                                color={'transparent'}
                                                onClick={() => { this.changePage(true) }}
                                                style={{marginBottom: 0, marginLeft: 10, outline:'none', boxShadow: 'none' }}
                                            />
                                        </div>
                                    }
                                </div>
                            </Collapse>
                        </>
                    }
                </div>
            </InViewWrapper>
        );
    }
}

export default withTranslation()(AvaliacaoQuestaoCard);