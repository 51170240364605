import React from "react";
import Sig from "../../../api/Sig";

import './IndicadorPainelSelect.css';

import EssentialStyle from "../../../style/EssentialStyle";
import { Form, Row, Col } from "react-bootstrap";
import IdiomaHelper from "../../../helper/IdiomaHelper";
import Select from 'react-select'
import DataHelper from "../../../helper/DataHelper";
import DefaultButton from "../DefaultButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane, faExclamationTriangle, faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import Colors from "../../../constants/Colors";
import DefaultLoader from "../DefaultLoader";


export default class IndicadorPainelSelect extends React.Component {

    state = {
        loading: false,

        indicadores: [],

        indicador: DataHelper.formatSelectedData(this.props.indicador, 'id', 'nome') || null,
        painel: DataHelper.formatSelectedData(this.props.painel, 'id', 'nome') || null,

        invalid: false,
        lang: this.props.lang || 'pt_br',
    }

    message(lang) {
        const message = IdiomaHelper.getStruct({
            'pt_br': { 
                label: 'Selecione o Indicador e Painel',
                placeholder_indicador: 'Selecione o Indicador...',
                placeholder_painel: 'Selecione o Painel...',
                tooltip_addIndicador: 'Adicionar Indicador',
            },
            'en': { 
                label: 'Select the Indicator and Panel',
                placeholder_indicador: 'Select the Indicator...',
                placeholder_painel: 'Select the Panel...',
                tooltip_addIndicador: 'Add Indicator'
            },
            'es': { 
                label: 'Seleccione el Indicador y Panel',
                placeholder_indicador: 'Seleccione el Indicador...',
                placeholder_painel: 'Seleccione el Panel...',
                tooltip_addIndicador: 'Agregar Indicador'
            },
        });

        return message[lang];
    }

    async componentDidMount() {

        if (this.state.indicadores.length == 0) {
            let firstLoad = true;
            await this.getIndicadores(firstLoad);
        }
    }

    componentDidUpdate(prevProps) {
        if(prevProps.painel?.id !== this.props.painel?.id) {
            this.setState({ painel: DataHelper.formatSelectedData(this.props.painel, 'id', 'nome') || null });
        }

        if (prevProps.indicador?.id !== this.props.indicador?.id) {
            this.setState({ indicador: DataHelper.formatSelectedData(this.props.indicador, 'id', 'nome') || null }, () => {
                this.getPaineis(this.state.indicador?.value);
            });
        }
    }

    getIndicadores = async (first) => {

        this.setState({loading: true});

        let idEmpresa = this.props.empresa?.id || null;

        let indicadorProp = this.state.indicador;
        let indicador = null;
        
        let indicadores = await Sig.request('POST', 'helper/getIndicadoresPaineisGmr', { raw_object: true, idEmpresa }) || [];
        
        if (!indicadores.find(indicadoresIndicador => indicadoresIndicador.id === indicador?.value)) indicador = null;
        
        if (indicadores.length === 1 && !indicadorProp) {
            indicador = DataHelper.formatSelectedData(indicadores[0], 'id', 'nome');
        } else {
            indicador = indicadorProp;
        }

        this.setState({ indicadores, loading: false }, () => {
            if (indicador && !indicadorProp) this.changeIndicador(indicador);
            if (indicador && indicadorProp) this.getPaineis(indicador.value);
        });
    }

    getPaineis = async (id_indicador) => {
        this.setState({loading: true});

        let idEmpresa = this.props.empresa?.id || null;

        let painelProp = this.state.painel;
        let painel = null;
        let paineis = await Sig.request('POST', 'helper/getPaineisIndicador', { id_indicador, raw_object: true, idEmpresa }) || [];

        if (!paineis.find(painelPaineis => painelPaineis.id === painel?.value)) painel = null;
        
        if (paineis.length === 1 && !painelProp) {
            painel = DataHelper.formatSelectedData(paineis[0], "id", "nome");
        } else {
            painel = painelProp;
        }

        this.setState({ paineis, painel, loading: false }, () => {
            if (painel && !painelProp) this.changePainel(painel);
        });
    }

    sendIndicadorPainel(){
        
        let select = {
            indicador: this.state.indicadores?.find(indicador => indicador.id == this.state.indicador?.value) || null,
            painel: this.state.paineis?.find(painel => painel.id == this.state.painel?.value) || null,
        }

        if (this.props.empresa) {
            select = { ...select, empresa: this.props.empresa };
            this.props.callback && this.props.callback(select);

        } else {
            this.setState({ indicador: null, painel: null, paineis: null }, () => {
                this.props.callback && this.props.callback(select);
            } );
        }

        
    }

    changeIndicador(indicador) {
        this.setState({ indicador: indicador }, () => {
            if (this.props.sync) this.sendIndicadorPainel();
            this.getPaineis(indicador.value);
        });
    }

    changePainel(painel) {
        this.setState({ painel: painel }, () => {
            if (this.props.sync) this.sendIndicadorPainel();
        });
    }

    renderButtonSave() {

        if (!this.props.sync) return (
            <DefaultButton
                leftIcon={<FontAwesomeIcon icon={faPaperPlane}/>}
                color={Colors.success}
                tooltip={this.message(this.state.lang).tooltip_addIndicador}
                disabled={this.state.indicador == null || this.state.painel == null}
                onClick={() => {
                    this.sendIndicadorPainel();
                }}
            />
        )

        if (this.state.loading) return (<DefaultLoader />);
        if (this.state.indicador == null || this.state.painel == null) return (<FontAwesomeIcon icon={faExclamationTriangle} color={Colors.danger} />);
        return (<FontAwesomeIcon icon={faCheckCircle} color={Colors.success} />);

    }

    render() {
        return (
            <div className="IndicadorPainelSelect">
                {this.props.label && <div>{this.message(this.state.lang).label}</div>}
                <Form style={{maxWidth: '100%'}}>

                    {this.props.empresa &&
                        <Row style={{maxWidth: '100%'}}>
                            <Col md={3}>
                                <div className="labelEmpresa">{this.props.empresa.nome_fantasia}</div>
                            </Col>
                        </Row>
                    }

                    <Row className="selects-indicador-painel">
                        <Col md={5}>
                            <Select
                                className="select-indicador"
                                options={this.state.indicadores?.length > 0 ? DataHelper.formatSelectData(this.state.indicadores, 'id', 'nome') : []}
                                value={this.state.indicador}
                                onChange={(indicador) => { this.changeIndicador(indicador); }}
                                placeholder={this.message(this.state.lang).placeholder_indicador}
                                styles={{
                                    container: (provided) => ({
                                        ...provided,
                                        width: '100%',
                                        maxWidth: '100% !important',
                                    })
                                }}
                            />
                        </Col>

                        <Col md={5}>
                            <Select
                                className="select-painel"
                                options={ this.state.paineis?.length > 0 ? DataHelper.formatSelectData(this.state.paineis, 'id', 'nome') : [] }
                                value={this.state.painel}
                                onChange={(painel) => { this.changePainel(painel) }}
                                placeholder={this.message(this.state.lang).placeholder_painel}
                                styles={{
                                    container: (provided) => ({
                                        ...provided,
                                        width: '100%',
                                        maxWidth: '100% !important',
                                    })
                                }}
                            />
                        </Col>

                        <Col md={2} style={{...EssentialStyle.rowFlexEnd}}>
                            {this.renderButtonSave()}
                        </Col>
                    </Row>
                </Form>
            </div>
        )
    }
}