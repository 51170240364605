import React from "react";
import LayoutHelper from "../../helper/LayoutHelper";
import Processo from "../../components/modules/diagnostico/Processo/Processo";
import AddProcesso from "../../components/forms/diagnostico/add/AddProcesso";

export default class DiagnosticoProcessosPage extends React.Component {

    state = {
        showWizard: false,
        isSmallScreen: LayoutHelper.isSmallScreen(),
    }

    async componentDidMount() {
        window.addEventListener('resize', this.verifyScreen);
        window.addEventListener('message', this.handleMessage);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.verifyScreen);
        window.removeEventListener('message', this.handleMessage);
    }

    handleMessage = (event) => {
        if (event.data.type === 'popstate') {
            if (event.data.url === this.state.lastPopState) {
                this.setState({ showDetailsModal: null, lastPopState: null });
                return;
            }

            this.setState({ lastPopState: event.data.url });
            this.handleUrlParams();
        }
    }

    verifyScreen = () => {
        this.setState({ isSmallScreen: LayoutHelper.isSmallScreen() });
    }

    showWizard = (param) => {
        
        this.setState({ showWizard: param }, () => {
            if (param === true) {
                // Adicionar Processo
                window.parent.postMessage({ type: 'delete_url_params' }, '*');
                window.parent.postMessage({ type: 'add_url_param', param: "tipo", value: 'novo' }, '*');
            } else if (param) {
                // Editar Processo
                window.parent.postMessage({ type: 'delete_url_params' }, '*');
                window.parent.postMessage({ type: 'add_url_param', param: "tipo", value: 'editar' }, '*');
            } else {
                // Voltar para a página de Processos
                window.parent.postMessage({ type: 'delete_url_params' }, '*');
            }
        });
    }

    render() {
        if (this.state.showWizard) return <AddProcesso idProcesso={this.state.idProcesso} showWizard={this.state.showWizard} isSmallScreen={this.state.isSmallScreen} onBack={() => {this.showWizard(false)}}/>;
        return <Processo isSmallScreen={this.state.isSmallScreen} onShowWizard={(param) => {this.showWizard(param)}} />;
    }
}