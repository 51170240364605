import React, { Component } from 'react';
import './NumericoPicker.css';

import { Form } from 'react-bootstrap';
import LayoutHelper from '../../../helper/LayoutHelper';

class NumericoPicker extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: this.props.value || 0
        }
        this.interval = null;
    }

    componentDidMount() {
        this.setSliderColors();

        this.setState({ value: this.props.value });
    }

    componentDidUpdate(prevProps) {
        if(prevProps.faixas !== this.props.faixas) {
            this.setSliderColors();
        }

        if(prevProps.value !== this.props.value) {
            this.setState({ value: this.props.value });
        }
    }

    setSliderColors = () => {
        if (this.props.faixas.length > 0) {
            let gradient = 'linear-gradient(to right, ';
            
            this.props.faixas.forEach((intervalo, index) => {

                let vals = ['min', 'max'];

                if (intervalo.min > 100) intervalo.max = 100;

                vals.forEach((val, keyVal) => {
                    gradient += `${intervalo.cor} ${intervalo[val]}%,`;
                });
            });

            gradient = gradient.substring(0, gradient.length - 1);
            gradient += ')';


            LayoutHelper.setSliderColors(gradient, `diagnostico-avaliacao-escala-slider-${this.props.id ? `-${this.props.id}` : ''}`);
        }
    }

    onChange = (e) => {
        if(this.state.value === e.target.value) return;
        
        var value = e.target.value;
        if(Number(e.target.value) > Number(this.props.escala.max)) {
            value = this.props.escala.max;
        } else if(Number(e.target.value) < Number(this.props.escala.min)) {
            value = this.props.escala.min;
        }

        this.setState({ value: value });

        if(this.interval) this.interval = clearTimeout(this.interval);
        this.interval = setTimeout(() => {
            if(!this.state.value || isNaN(this.state.value)) {
                return;
            }
            this.props.onChange(this.state.value);
        }, 500);
    }

    render() {

        return (
            <div className="numerico-picker">
                <div className="numerico-container">
                    <Form.Control 
                    //number with no decimal cases
                        id="diagnostico-avaliacao-escala-input"
                        type="number"
                        value={parseFloat(this.state.value).toFixed(0)}
                        placeholder={'xx% ...'}
                        min={this.props.escala.min}
                        max={this.props.escala.max}
                        step={this.props.step || 1}
                        onChange={this.onChange}
                        style={{width: 80}}
                        disabled={this.props.disabled}
                    />
                    <Form.Control
                        id={`diagnostico-avaliacao-escala-slider-${this.props.id ? `-${this.props.id}` : ''}`}
                        className="diagnostico-avaliacao-escala-slider"
                        type="range"
                        min={this.props.escala.min}
                        max={this.props.escala.max}
                        value={this.state.value}
                        onChange={this.onChange}
                        disabled={this.props.disabled}
                    />
                </div>
            </div>
        );
    }
}

export default NumericoPicker;