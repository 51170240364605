import React from "react";
import { withTranslation } from "react-i18next";

import Sig from "../../../../../../api/Sig";

import moment from "moment";
import DatePicker from 'react-datepicker';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartLine, faTable, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { Form, Row, Col } from "react-bootstrap";
import { forwardRef } from "react";

import EssentialStyle from "../../../../../../style/EssentialStyle";
import Constants from "../../../../../../constants/Api";
import IndicadorGauge from "../../../../inicio/charts/indicadores/IndicadorGauge";
import ChartHelper from "../../../../../../helper/inicio/ChartHelper";
import Colors from "../../../../../../constants/Colors";

import Tabs from "../../../../../tools/Tabs";
import DefaultButton from "../../../../../tools/DefaultButton";
import DefaultLoader from "../../../../../tools/DefaultLoader";
import HistoryIndicador from "../../../../../tools/HistoryIndicador/HistoryIndicador";


import './IndicadoresObjetivo.css';

const gateway = Constants.getSigEndPoint();

class IndicadoresObjetivo extends React.Component {

    state = {
        loading: true,
        idProjeto: this.props.idProjeto,
        isSmallScreen: this.props.isSmallScreen,
        indicadores: null,
        indicador: null,
        historyDataInicio: moment().subtract(1, 'year').startOf('month').toDate(),
        historyDataFim: moment().subtract(1, 'month').endOf('month').toDate(),
        indicadoresData: moment().subtract(1, 'month').startOf('month').toDate(),
        acumulado: true,
        showEvolucaoResultado: "grafico",
    }

    async componentDidMount() {
        await this.props.i18n.loadNamespaces(['client_src_components_modules_pe_projeto_DetalhamentoObjetivoResumo_IndicadoresObjetivo_IndicadoresObjetivo']);
        if (!this.props.permissao_indicador == 0) {
            this.loadData();
        }
    }

    async componentDidUpdate(prevProps) {
        if (prevProps.idProjeto !== this.props.idProjeto || prevProps.idObjetivo !== this.props.idObjetivo) {
            this.setState({ idProjeto: this.props.idProjeto, idObjetivo: this.props.idObjetivo });
            this.loadData();
        }

        if (prevProps.isSmallScreen !== this.props.isSmallScreen) {
            this.setState({ isSmallScreen: this.props.isSmallScreen });
        }
    }

    loadData = async () => {
        this.setState({ loading: true });

        let idObjetivo = null;

        if (!this.props.idObjetivo && this.state.idProjeto) {
            let objetivo = await this.loadObjetivo();
            idObjetivo = objetivo?.id ?? null;
        } else {
            idObjetivo = this.props.idObjetivo;
        }

        if (!idObjetivo) {
            this.setState({ loading: false });
            return;
        } else {
            await this.loadIndicadores(idObjetivo);
        }
    }

    loadObjetivo = async () => {
        let { objetivo } = (await Sig.request('GET', 'pe/projeto/getProjeto', { id: this.state.idProjeto, relations: 1 }));
        return objetivo;
    }

    loadIndicadores = async (idObjetivo) => {
        let { indicadores } = (await Sig.request('GET', 'pe/mapa/getIndicadoresObjetivo', { id: idObjetivo }));
        this.setState({ idObjetivo, indicadores, loading: false });
    }

    selectIndicadorExpanded = (indicador) => {
        this.setState({ indicador: this.state.indicador == indicador ? null : indicador });
    }

    renderDatePicker(stateProp, callback = () => { }) {
        const DatePickerComponent = forwardRef(({ value, onClick }, ref) => (
            <Form.Control
                onChange={() => { }}
                onClick={onClick}
                ref={ref}
                style={{ marginLeft: 5, fontSize: 14, color: Colors.dark, fontWeight: 'bold', cursor: 'pointer', boxShadow: 'none', backgroundColor: Colors.homePage.lightGrey, padding: 1, height: '100%', width: 90, textAlign: 'center', zIndex: 1 }}
                value={value} >
            </Form.Control>
        ));

        return (
            <div style={{
                ...EssentialStyle.rowFlexStart
            }}>
                <DatePicker
                    selected={this.state[stateProp]}
                    type="date"
                    onChange={(event) => {
                        let novoInicio = moment(event);

                        if (stateProp === 'historyDataInicio' && novoInicio.isAfter(this.state.historyDataFim)) {
                            return;
                        }
                        if (stateProp === 'historyDataFim' && novoInicio.isBefore(this.state.historyDataInicio)) {
                            return;
                        }

                        if (novoInicio.toDate() !== this.state[stateProp]) {
                            this.setState({ [stateProp]: novoInicio.toDate() }, callback);
                        }
                    }}
                    dateFormat="MM/yyyy"
                    showMonthYearPicker
                    customInput={<DatePickerComponent />}
                    popperPlacement="top-start"
                />
            </div>
        )
    }

    renderLoading() {
        return (
            <div className="boxDescricao">
                <div className="loading">
                    <DefaultLoader />
                </div>
            </div>
        )
    }

    renderIndicadoresObjetivo() {

        return (
            <div className="boxIndicadoresObjetivo">
                {this.state.indicadores && this.state.indicadores.length > 0 ? this.renderIndicadoresGrid() : this.renderEmptyIndicadores()}
            </div>
        )
    }

    renderIndicadoresGrid() {
        let indicadores = this.state.indicadores || [];
        let widthBox = ChartHelper.getWidth(100) - 20;
        let minWidthGauge = this.state.isSmallScreen ? 100 : 200;
        let nMaxGaugeLine = Math.ceil(widthBox / minWidthGauge);
        let nLine = Math.ceil(indicadores.length / nMaxGaugeLine);
        let nGaugeLine = Math.ceil(indicadores.length / nLine);

        let indicadoresGroups = [];
        for (let i = 0; i < indicadores.length; i += nGaugeLine) {
            indicadoresGroups.push(indicadores.slice(i, i + nGaugeLine));
        }

        let gaugesGrid = indicadoresGroups.map((group, index) => {
            return (
                <div key={`group-${index}`} style={{
                    ...EssentialStyle.rowSpaceEvenly,
                    width: '100%',
                    height: `${100 / indicadoresGroups.length}%`,
                    minHeight: 80,
                    maxHeight: 300,
                }}>
                    {
                        group.map((indicador, ig_index) => {
                            return (
                                <div key={`ig_index_${ig_index}`} style={{
                                    ...EssentialStyle.rowFlexCenter,
                                    display: 'flex',
                                    width: `${100 / (group.length)}%`,
                                    height: '15rem',
                                    padding: 5,
                                }}>
                                    <IndicadorGauge
                                        nome
                                        id_indicador={indicador.id_indicador}
                                        id_gmr_painel={indicador.id_gmr_painel}
                                        data={this.state.indicadoresData}
                                        acumulado={this.state.acumulado}
                                        gauge={true}
                                        mergulho={false}
                                        link={true}
                                        onClick={() => { this.selectIndicadorExpanded(indicador); }}
                                    />
                                </div>
                            );
                        })
                    }
                </div>
            );
        });

        return (
            <div className={"gridContainer"} style={{ justifyContent: nLine == 1 ? 'center' : 'default' }}>
                <div className="configIndicadores">
                    <DefaultButton
                        style={{ width: 100, height: 25, padding: 0, marginLeft: 10, fontSize: 12 }}
                        textColor={this.state.acumulado ? Colors.white : Colors.dark}
                        color={this.state.acumulado ? Colors.success : Colors.homePage.lightGrey}
                        title={<b>{!this.state.acumulado ? this.props.i18n.t('client_src_components_modules_pe_projeto_DetalhamentoObjetivoResumo_IndicadoresObjetivo_IndicadoresObjetivo:mensal') : this.props.i18n.t('client_src_components_modules_pe_projeto_DetalhamentoObjetivoResumo_IndicadoresObjetivo_IndicadoresObjetivo:acumulado')}</b>}
                        tooltip={!this.state.acumulado ? this.props.i18n.t('client_src_components_modules_pe_projeto_DetalhamentoObjetivoResumo_IndicadoresObjetivo_IndicadoresObjetivo:alternar_para_acumulado') : this.props.i18n.t('client_src_components_modules_pe_projeto_DetalhamentoObjetivoResumo_IndicadoresObjetivo_IndicadoresObjetivo:alternar_para_mensal')}
                        onClick={() => { this.setState({ acumulado: !this.state.acumulado }) }}
                    />

                    {this.renderDatePicker('indicadoresData')}
                </div>
                {gaugesGrid}
            </div>
        );
    }

    renderEmptyIndicadores() {
        return (
            <div className="emptyIndicadores">
                <img
                    src={`${gateway}/img/undraw/undraw_pie_graph.svg`}
                    alt={this.props.t('client_src_components_modules_pe_projeto_DetalhamentoObjetivoResumo_IndicadoresObjetivo_IndicadoresObjetivo:altEmptyIndicadoresObjetivo')}
                    style={{
                        width: '15%'
                    }}
                />
                <div style={{
                    ...EssentialStyle.columnCenter,
                    alignItems: 'flex-start',
                    alignContent: 'flex-start',
                }}>
                    <span style={{
                        ...EssentialStyle.titleBoxHomePage,
                        marginBottom: 10
                    }}>{this.props.i18n.t('client_src_components_modules_pe_projeto_DetalhamentoObjetivoResumo_IndicadoresObjetivo_IndicadoresObjetivo:objetivo_sem_indicadores')}</span>
                </div>
            </div>
        );
    }

    renderIndicadorExpanded() {

        var handleTabChange = (tab) => {
            this.setState({ showEvolucaoResultado: tab.name })
        }

        const tabs = [
            { 
                name: 'grafico', 
                title: this.props.t('client_src_components_modules_pe_projeto_DetalhamentoObjetivoResumo_IndicadoresObjetivo_IndicadoresObjetivo:grafico'),
                icon: <FontAwesomeIcon icon={faChartLine} /> 
            },
            { 
                name: 'tabela', 
                title: this.props.t('client_src_components_modules_pe_projeto_DetalhamentoObjetivoResumo_IndicadoresObjetivo_IndicadoresObjetivo:tabela'),
                icon: <FontAwesomeIcon icon={faTable} /> 
            },
        ];

        return (
            <div className="boxIndicadoresObjetivo">
                <div className="gridContainer">
                    <div className="configIndicadores space-between">
                        <div className={"child-config"} style={{ ...EssentialStyle.rowFlexStart }}>
                            <span style={{ fontSize: 12, fontWeight: 'bold', color: Colors.dark }}>{this.props.i18n.t('client_src_components_modules_pe_projeto_DetalhamentoObjetivoResumo_IndicadoresObjetivo_IndicadoresObjetivo:period')}</span>
                            <div style={{ ...EssentialStyle.rowFlexEnd }}>
                                {this.renderDatePicker("historyDataInicio")}
                                {this.renderDatePicker("historyDataFim")}
                            </div>
                        </div>
                        <Tabs
                            className={"child-config"}
                            isSmallScreen={this.state.isSmallScreen}
                            tabs={tabs}
                            onTabChange={handleTabChange}
                            activeTab={this.state.showEvolucaoResultado}
                            style={{ ...(EssentialStyle.rowFlexCenter) }}
                            tabStyle={{ width: this.state.isSmallScreen ? '33vw' : 150, textAlign: 'center', padding: 0, borderRadius: 5 }}
                        />
                        <div className={"config-gauge child-config"}>

                            <FontAwesomeIcon className={"hover"} icon={faArrowLeft} onClick={() => { this.selectIndicadorExpanded(this.state.indicador); }} />

                            <DefaultButton
                                style={{ width: 100, height: 25, padding: 0, marginLeft: 10, fontSize: 12 }}
                                textColor={this.state.acumulado ? Colors.white : Colors.dark}
                                color={this.state.acumulado ? Colors.success : Colors.homePage.lightGrey}
                                title={<b>{!this.state.acumulado ? this.props.i18n.t('client_src_components_modules_pe_projeto_DetalhamentoObjetivoResumo_IndicadoresObjetivo_IndicadoresObjetivo:mensal') : this.props.i18n.t('client_src_components_modules_pe_projeto_DetalhamentoObjetivoResumo_IndicadoresObjetivo_IndicadoresObjetivo:acumulado')}</b>}
                                tooltip={!this.state.acumulado ? this.props.i18n.t('client_src_components_modules_pe_projeto_DetalhamentoObjetivoResumo_IndicadoresObjetivo_IndicadoresObjetivo:alternar_para_acumulado') : this.props.i18n.t('client_src_components_modules_pe_projeto_DetalhamentoObjetivoResumo_IndicadoresObjetivo_IndicadoresObjetivo:alternar_para_mensal')}
                                onClick={() => { this.setState({ acumulado: !this.state.acumulado }) }}
                            />

                            {this.renderDatePicker('indicadoresData')}
                        </div>
                    </div>
                    <div className={"indicadorExpanded"}>
                        <div className={"historyChart"}>
                            <HistoryIndicador
                                id_indicador={this.state.indicador.id_indicador}
                                id_gmr_painel={this.state.indicador.id_gmr_painel}
                                data_inicio={this.state.historyDataInicio}
                                data_fim={this.state.historyDataFim}
                                acumulado={this.state.acumulado}
                                isSmallScreen={this.state.isSmallScreen}
                                showEvolucaoResultado={this.state.showEvolucaoResultado}
                            />
                        </div>
                        <div className={"gaugeChart"}>
                            <IndicadorGauge
                                nome
                                id_indicador={this.state.indicador.id_indicador}
                                id_gmr_painel={this.state.indicador.id_gmr_painel}
                                data={this.state.indicadoresData}
                                acumulado={this.state.acumulado}
                                gauge={true}
                                mergulho={true}
                                link={true}
                                onClick={() => { this.selectIndicadorExpanded(this.state.indicador); }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderPermissao() {
        return (
            <div className="nonPermissionIndicadoresObjetivo">
                <img
                    src={`${gateway}/img/undraw/undraw_safe_lock.svg`}
                    alt={this.props.t('client_src_components_modules_pe_projeto_DetalhamentoObjetivoResumo_IndicadoresObjetivo_IndicadoresObjetivo:altNonPermissionIndicadoresObjetivo')}
                    style={{
                        width: '15rem',
                        maxHeight: '100%',
                    }}
                />
                <div style={{
                    ...EssentialStyle.columnCenter,
                    alignItems: 'flex-start',
                    alignContent: 'flex-start',
                }}>
                    <span style={{
                        ...EssentialStyle.titleBoxHomePage,
                        marginBottom: 10
                    }}>
                        {this.props.i18n.t('client_src_components_modules_pe_projeto_DetalhamentoObjetivoResumo_IndicadoresObjetivo_IndicadoresObjetivo:sem_permissao_1') + " "} 
                        <strong>
                            {this.props.i18n.t('client_src_components_modules_pe_projeto_DetalhamentoObjetivoResumo_IndicadoresObjetivo_IndicadoresObjetivo:sem_permissao_2')} 
                        </strong>
                        {" " + this.props.i18n.t('client_src_components_modules_pe_projeto_DetalhamentoObjetivoResumo_IndicadoresObjetivo_IndicadoresObjetivo:sem_permissao_3') + " "}
                        <strong>
                            {this.props.i18n.t('client_src_components_modules_pe_projeto_DetalhamentoObjetivoResumo_IndicadoresObjetivo_IndicadoresObjetivo:sem_permissao_4')}
                        </strong> 
                        {" " + this.props.i18n.t('client_src_components_modules_pe_projeto_DetalhamentoObjetivoResumo_IndicadoresObjetivo_IndicadoresObjetivo:sem_permissao_5')}.
                    </span>
                </div>
            </div>
        )
    }

    render() {
        if (!this.props.permissao_indicador) return this.renderPermissao();
        if (this.state.loading) return this.renderLoading();
        return this.state.indicador ? this.renderIndicadorExpanded() : this.renderIndicadoresObjetivo();
    }
}

export default withTranslation()(IndicadoresObjetivo);