import React from 'react';
import LayoutHelper from '../../../helper/LayoutHelper';
import Modal from '../../tools/Modal';
import Colors from '../../../constants/Colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog, faTimes, faCheck, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import EssentialStyle from '../../../style/EssentialStyle';
import DefaultLoader from '../../tools/DefaultLoader';
import DefaultButton from '../../tools/DefaultButton';
import Sig from '../../../api/Sig';
import InicioConfigOrdem from './InicioConfigOrdem';
import ConfigHelper from '../../../helper/inicio/ConfigHelper';
import SessionHelper from '../../../helper/SessionHelper';
import UserAvatar from '../../tools/UserAvatar';
import { Form } from "react-bootstrap";
import CustomTooltip from '../../tools/CustomTooltip';
import { withTranslation } from 'react-i18next';

class InicioConfigModal extends React.Component {
    state = {
        isSmallScreen: LayoutHelper.isSmallScreen(),
        loading: true,
        ordem: [],
        ordemCustomizada: false
    }

    async componentDidMount() {
        await this.props.i18n.loadNamespaces(['client_src_components_modules_inicio_InicioConfigModal']);
        window.addEventListener('resize', this.verifyScreen);
        let ordem = await ConfigHelper.getMyPagesAvailable(this.props.userId, this.props.viewUserConfig);
        let subordinadoConfig = await ConfigHelper.getSubordinadoCustomOrder();
        this.setState({ ordem, loading: false, ordemCustomizada: subordinadoConfig.ordem_customizada_subordinado });
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.verifyScreen);
    }

    verifyScreen = () => {
        this.setState({ isSmallScreen: LayoutHelper.isSmallScreen() });
    }

    renderLoading() {
        return (
            <div style={{ ...EssentialStyle.columnCenter, width: '100%', height: '100%' }}>
                <DefaultLoader />
            </div>
        )
    }

    saveOrderConfig = async () => {
        this.setState({ loading: true });

        let response = await Sig.request('POST', 'inicio/saveOrdemConfig', {
            colaboradorAlvo: this.props.userId,
            viewUserConfig: this.props.viewUserConfig,
            ordem: JSON.stringify(this.state.ordem),
            ordemCustomizada: this.state.ordemCustomizada ? 1 : 0
        });

        if (response) {
            this.props.onClose();
            this.props.reloadCallback();
        }

        this.setState({ loading: false });
    }

    renderFooter() {
        return (
            <div style={{ ...EssentialStyle.rowCenter, width: '100%', marginTop: 20 }}>
                <DefaultButton
                    title={this.props.t('client_src_components_modules_inicio_InicioConfigModal:save')}
                    onClick={this.saveOrderConfig}
                    color={Colors.success}
                    style={{ width: '100%' }}
                    leftIcon={<FontAwesomeIcon icon={faCheck} />}
                    disabled={this.state.loading || this.getOrdemReadOnly()}
                    loading={this.state.loading}
                />
            </div>
        );
    }

    renderBody() {
        return (
            <div style={{ ...EssentialStyle.columnStart, width: '100%', height: '100%', overflowY: 'auto' }}>
                {this.renderOrderConfig()}
                {this.props.userId == SessionHelper.getData().id_usuario && this.renderOrderConfigSubordinados()}
            </div>
        )
    }

    setOrdem = (ordem) => {
        this.setState({ ordem });
    }

    renderOrderConfigSubordinadosSwitch() {
        return (
            <div style={{ ...EssentialStyle.rowFlexCenter }}>
                <Form.Switch
                    type="switch"
                    disabled
                    checked={this.state.ordemCustomizada}
                    style={{ marginLeft: 3, marginRight: 3 }}
                />
            </div>
        );
    }

    getOrdemReadOnly = () => {
        let readOnly = true;

        let flag = this.state.ordemCustomizada;
        let isMe = this.props.userId == SessionHelper.getData().id_usuario;

        if (!flag || isMe) readOnly = false;

        return readOnly;
    }

    renderOrderConfigSubordinados() {
        return (
            <div style={{ ...EssentialStyle.rowFlexStart, minWidth: '100%', marginBottom: 20, marginTop: 0 }}>
                <DefaultButton
                    leftIcon={this.renderOrderConfigSubordinadosSwitch()}
                    color={Colors.secondaryButton}
                    textColor={Colors.dark}
                    loading={this.state.loading}
                    style={{ ...EssentialStyle.rowFlexStart }}
                    onClick={() => { this.setState({ ordemCustomizada: !this.state.ordemCustomizada }) }}
                    title={
                        <div style={{ ...EssentialStyle.rowFlexStart }}>
                            {this.props.t('client_src_components_modules_inicio_InicioConfigModal:replicate_display_order')}
                            <CustomTooltip tooltip={this.props.t('client_src_components_modules_inicio_InicioConfigModal:display_order_tooltip')} placement={'top'}>
                                <FontAwesomeIcon icon={faInfoCircle} style={{ marginLeft: 8 }} />
                            </CustomTooltip>
                        </div>
                    }
                />
            </div>
        );
    }

    renderOrderConfig() {
        return <InicioConfigOrdem userId={this.props.userId} viewUserConfig={this.props.viewUserConfig} callback={this.setOrdem} readOnly={this.getOrdemReadOnly()} />
    }

    renderModalContent() {
        return (
            <div style={{ ...EssentialStyle.columnStart, height: '85vh', width: '100%' }}>
                {this.renderTitle()}
                {this.state.loading ? this.renderLoading() : this.renderBody()}
                {this.renderFooter()}
            </div>
        )
    }

    renderTitle() {
        return (
            <div style={{ ...EssentialStyle.rowSpaceBetween, width: '100%', fontSize: 22, fontWeight: 600, color: Colors.homePage.grey, marginBottom: 10 }}>
                <div style={{ ...EssentialStyle.rowFlexStart }}>
                    <FontAwesomeIcon icon={faCog} style={{ marginRight: 10 }} />
                    {this.props.t('client_src_components_modules_inicio_InicioConfigModal:settings')}
                    {this.props.userId != SessionHelper.getData().id_usuario ? <div style={{ ...EssentialStyle.rowFlexStart, marginLeft: 8 }}> <span style={{ marginRight: 8 }}>{this.props.t('client_src_components_modules_inicio_InicioConfigModal:for')}</span> <UserAvatar showName showNameAdaptative id={this.props.userId} /></div> : null}
                </div>

                <FontAwesomeIcon icon={faTimes} style={{ color: Colors.homePage.grey, cursor: 'pointer' }} onClick={this.props.onClose} />
            </div>
        )
    }

    render() {

        return (
            <Modal style={{ width: this.state.isSmallScreen ? '95vw' : '90vw' }}>
                {this.renderModalContent()}
            </Modal>
        )
    }
}

export default withTranslation()(InicioConfigModal);