import React from "react";
import { withTranslation } from "react-i18next";
import DataHelper from "../../../helper/DataHelper";
import { Form, InputGroup, Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faTimes } from "@fortawesome/free-solid-svg-icons";
import Select from 'react-select';
import DefaultButton from "../../tools/DefaultButton";
import moment from "moment";
import Colors from "../../../constants/Colors";
import Sig from "../../../api/Sig";
import DatePicker from "react-datepicker";
import { forwardRef } from 'react';
import DefaultLoader from "../../tools/DefaultLoader";
import EssentialStyle from "../../../style/EssentialStyle";
import LayoutHelper from "../../../helper/LayoutHelper";
import PlanoAcaoHelper from "../../../helper/planoAcao/PlanoAcaoHelper";
import DatePickerHelper from "../../../helper/DatePickerHelper";

class PlanoEtapaSearch extends React.Component {
    state = {
        loading: true,
        prioridades: [],
        dataInicio: null,
        dataFim: null,
        status: [],
        ocultarConcluidos: this.props.defaultOcultarConcluidos ? true : false,
        isSmallScreen: LayoutHelper.isSmallScreen()
    }

    async componentDidMount() {
        await this.props.i18n.loadNamespaces(['client_src_components_modules_planoAcao_PlanoAcaoEtapaSearch'])
        await this.loadData();
    }

    async loadData() {
        this.setState({ loading: true });
        let req = await Sig.request('GET', 'planoAcao/getFilterList');
        if (req && req.status === 200) {
            this.setState({ colabsList: req.colaboradores, tipoPlanoList: req.tipos, vinculoList: req.vinculos, statusList: req.statusList });
        }
        this.setState({ loading: false })
    }

    limpar = () => {
        this.setState({
            loading: true,
            prioridades: [],
            dataInicio: null,
            dataFim: null,
            status: [],
            ocultarConcluidos: false
        }, () => { this.props.filterCallback({}); this.setState({ loading: false }) });
    }

    renderDatePickerPeriodo() {
        const CustomPicker = forwardRef(({ value, onClick }, ref) => (
            <Form.Control onClick={onClick} ref={ref} value={value} readOnly />
        ));

        let dataMoment1 = this.state.dataInicio ? moment(this.state.dataInicio).toDate() : null;
        let dataMoment2 = this.state.dataFim ? moment(this.state.dataFim).toDate() : null;

        return (
            <DatePicker
                wrapperClassName="w-100"
                portalId="calendar-portal"
                selected={dataMoment1}
                startDate={dataMoment1}
                endDate={dataMoment2}
                selectsRange
                onChange={(dates) => {
                    const [start, end] = dates;
                    this.setState({ dataInicio: start, dataFim: end });
                }}
                dateFormat="dd/MM/yyyy"
                customInput={<CustomPicker />}
                renderCustomHeader={(props) =>  
                    DatePickerHelper.renderCustomDatePickerHeader(props)
                }
            />
        );
    }

    filterCallback = () => {
        this.props.filterCallback({
            prioridades: this.state.prioridades ? this.state.prioridades.map(item => item.value) : null,
            status: this.state.status ? this.state.status.map(item => item.value) : null,
            dataInicio: this.state.dataInicio ? DataHelper.getDefaultDbDateFormat(this.state.dataInicio) : null,
            dataFim: this.state.dataFim ? DataHelper.getDefaultDbDateFormat(this.state.dataFim) : null,
            ocultarConcluidos: this.state.ocultarConcluidos
        });
    }

    renderLoading() {
        return (
            <div className="box-advancedSearch">
                <Form className="w-100" style={{ minHeight: 330, ...EssentialStyle.columnCenter }}>
                    <DefaultLoader />
                </Form>
            </div>
        )
    }

    renderStatusFilter() {
        return (
            <Form.Group as={Col}>
                <Form.Label className="form-label-xsm">
                    {this.props.i18n.t('client_src_components_modules_planoAcao_PlanoAcaoEtapaSearch:status')}
                </Form.Label>
                <Select
                    className="form-control-xsm"
                    options={PlanoAcaoHelper.getEtapaStatus().map(item => ({ value: item?.value, label: this.props.i18n.t(`client_src_components_modules_planoAcao_PlanoAcaoEtapaSearch:status_${item.value}`) }))}
                    placeholder={this.props.i18n.t('client_src_components_modules_planoAcao_PlanoAcaoEtapaSearch:selecione_o_status')}
                    noOptionsMessage={DataHelper.getSelectEmptyMessage}
                    isClearable
                    isSearchable
                    isMulti
                    value={this.state.status}
                    onChange={(value) => { this.setState({ status: value }); }}
                    menuPortalTarget={document.body} 
                    styles={{menuPortal: base => ({ ...base, zIndex: 10 })}}/>
            </Form.Group>
        );
    }

    renderPrioridadeFilter() {
        return (
            <Form.Group as={Col}>
                <Form.Label className="form-label-xsm">
                    {this.props.i18n.t('client_src_components_modules_planoAcao_PlanoAcaoEtapaSearch:prioridade')}
                </Form.Label>
                <Select
                    className="form-control-xsm"
                    options={PlanoAcaoHelper.getEtapaPrioridades().map(item => ({ value: item?.value, label: this.props.i18n.t(`client_src_components_modules_planoAcao_PlanoAcaoEtapaSearch:prioridade_${item.value}`) }))}
                    placeholder={this.props.i18n.t('client_src_components_modules_planoAcao_PlanoAcaoEtapaSearch:selecione_a_prioridade')}
                    noOptionsMessage={DataHelper.getSelectEmptyMessage}
                    isClearable
                    isSearchable
                    isMulti
                    value={this.state.prioridades}
                    onChange={(value) => { this.setState({ prioridades: value }); }}
                    menuPortalTarget={document.body} 
                    styles={{menuPortal: base => ({ ...base, zIndex: 10 })}}/>
            </Form.Group>
        );
    }

    renderPeriodoFilter() {
        return (
            <Form.Group as={Col}>
                <Form.Label className="form-label-xsm">
                    {this.props.i18n.t('client_src_components_modules_planoAcao_PlanoAcaoEtapaSearch:periodo')}
                </Form.Label>
                {this.renderDatePickerPeriodo()}
            </Form.Group>
        );
    }

    renderOcultarConcluidos() {
        return (
            <Form.Group as={Col}>
                <Form.Check
                    type="checkbox"
                    id="ocultarConcluidos"
                    label={this.props.i18n.t('client_src_components_modules_planoAcao_PlanoAcaoEtapaSearch:ocultar_concluidos_ha_mais_de_7_dias')}
                    checked={this.state.ocultarConcluidos}
                    onChange={(e) => { this.setState({ ocultarConcluidos: e.target.checked }); }}
                />
            </Form.Group>
        );
    }

    renderButtons() {
        return (
            <div className="mb-0 d-flex flex-row-reverse">
                <Form.Group>
                    <DefaultButton 
                        className="me-1" 
                        color={Colors.error} 
                        leftIcon={<FontAwesomeIcon icon={faTimes} />} 
                        title={this.props.i18n.t('client_src_components_modules_planoAcao_PlanoAcaoEtapaSearch:limpar')} 
                        loading={this.state.loading} 
                        onClick={this.limpar} 
                    />
                    <DefaultButton 
                        color={Colors.success} 
                        leftIcon={<FontAwesomeIcon icon={faSearch} />} 
                        title={this.props.i18n.t('client_src_components_modules_planoAcao_PlanoAcaoEtapaSearch:filtrar')} 
                        loading={this.state.loading} 
                        onClick={this.filterCallback} 
                    />
                </Form.Group>
            </div>
        );
    }

    renderDesktop() {
        return (
            <div className="box-advancedSearch">
                <Form className="w-100">
                    <Row className="mb-3">
                        {this.renderStatusFilter()}
                        {this.renderPrioridadeFilter()}
                    </Row>
                    <Row className="mb-3">
                        {this.renderPeriodoFilter()}
                    </Row>
                    <Row className="mb-3">
                        {this.renderOcultarConcluidos()}
                    </Row>
                    {this.renderButtons()}
                </Form>
            </div>
        );
    }

    renderMobile() {
        return (
            <div className="box-advancedSearch">
                <Form className="w-100">
                    <Row className="mb-3">{this.renderPrioridadeFilter()}</Row>
                    <Row className="mb-3">{this.renderPeriodoFilter()}</Row>
                    <Row className="mb-3">{this.renderStatusFilter()}</Row>
                    <Row className="mb-3">{this.renderOcultarConcluidos()}</Row>
                    {this.renderButtons()}
                </Form>
            </div>
        );
    }

    render() {
        return this.state.loading ? this.renderLoading() : (this.state.isSmallScreen ? this.renderMobile() : this.renderDesktop());
    }
}
export default withTranslation()(PlanoEtapaSearch);