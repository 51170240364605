import React, { forwardRef } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight, faMagic } from '@fortawesome/free-solid-svg-icons'
import DefaultButton from "../../../tools/DefaultButton";
import Colors from "../../../../constants/Colors";
import { Form, Row, Col } from "react-bootstrap";
import EssentialStyle from "../../../../style/EssentialStyle";
import DefaultLoader from "../../../tools/DefaultLoader";
import IdiomaHelper from "../../../../helper/IdiomaHelper";
import { toast } from "react-toastify";
import DiagnosticoHelper from "../../../../helper/diagnostico/DiagnosticoHelper";
import TextEditor from "../../../tools/TextEditor/TextEditor";
import DataHelper from "../../../../helper/DataHelper";

export default class AddProcesso extends React.Component {
    state = {
        step: 1,
        loading: true,
        loadingGpt: false,
        saving: false,
        lang: IdiomaHelper.getIdioma(),

        // form one
        name: '',
        descricao: '',
        descricaoAntiga: ' ',
    }

    componentDidMount() {
        this.load();
    }

    async load() {
        this.setState({ loading: true });

        let { processo, canEdit, canView } = await DiagnosticoHelper.getInfosCriarProcesso(this.props.showWizard);

        if (!canEdit) {
            let message = IdiomaHelper.getStruct({
                'pt_br': { title: 'Sem permissão', message: 'Você não tem permissão para criar ou editar o processo' },
                'en': { title: 'No permission', message: 'You do not have permission to create or edit the process' },
                'es': { title: 'Sin permiso', message: 'No tienes permiso para crear o editar el proceso' },
            });

            toast.error(message[this.state.lang].message);
            this.props.onBack();
        }

        if (processo?.id){
            this.setState({
                id: processo.id,
                name: processo.nome,
                descricao: processo.descricao,
            });
        }

        this.setState({ loading: false });
    }

    step(calc) {

        let step = this.state.step + calc;

        if (step < 1) {
            this.props.onBack();
        } else if (step == 2) {
            this.savePeriodoAvaliacao();
        } else {
            this.setState({ step });
        }
    }

    savePeriodoAvaliacao = async () => {
        
        const message = IdiomaHelper.getStruct({
            'pt_br': {empty: 'Preencha todos os campos obrigatórios', success: 'Processo salvo com sucesso', error: 'Erro ao salvar Processo'},
            'en': {empty: 'Fill in all required fields', success: 'Process saved successfully', error: 'Error saving Process'},
            'es': {empty: 'Complete todos los campos obligatorios', success: 'Proceso guardado con éxito', error: 'Error al guardar Proceso'},
        });

        if ( !this.state.name ) {
            toast.error(message[this.state.lang].empty);
            return;
        }

        let processo = {
            nome: this.state.name,
            descricao: this.state.descricao,
        }

        if (this.state.id) {
            processo.id = this.state.id;
        }

        this.setState({ saving: true });

        let save = await DiagnosticoHelper.saveProcesso(processo);

        if (save) {
            toast.success(message[this.state.lang].success);
            window.parent.postMessage({ type: 'navigate', url: `/diagnostico` }, '*');
        } else {
            toast.error(message[this.state.lang].error);
            this.setState({ saving: false });
        }
    }

    textReview = async () => {
        this.setState({ loadingGpt: true, descricaoAntiga: this.state.descricao });
        let valor = await DiagnosticoHelper.revisarTextoProcesso(this.state.descricao);

        if (valor) {
            this.setState({ descricao: valor });
        }

        this.setState({ loadingGpt: false });
    }

    // Funções de renderização da página

    renderSaving() {
        const message = IdiomaHelper.getStruct({
            'pt_br': { saving: 'Salvando Processo' },
            'en': { saving: 'Saving Process' },
            'es': { saving: 'Guardando Proceso' },
        });

        return (
            <div style={{ minWidth: '100%', minHeight: '100vh', ...EssentialStyle.rowFlexCenter }}>
                <DefaultLoader title={message[this.state.lang].saving} style={{ width: "100%", height: "100%" }} />
            </div>
        )
    }

    renderStepBack() {
        return (
            <div style={{ position: 'fixed', top: 10, left: 10 }}>
                <DefaultButton 
                    title={''} 
                    leftIcon={<FontAwesomeIcon icon={faChevronLeft} />} 
                    color={Colors.secondaryButton} 
                    textColor={Colors.dark} 
                    loading={this.state.loading} 
                    onClick={() => { this.step(-1) }} 
                    style={{ marginRight: 8 }} 
                />
            </div>
        );
    }

    renderHelp() {
        return (
            <div style={{ position: 'fixed', top: 10, right: 10 }}>
                <DefaultButton 
                style={{ width: 38, height: 38, marginLeft: 8}}
                fontSize={this.props.isSmallScreen ? 14 : "unset"}
                color={Colors.secondaryButton}
                textColor={Colors.dark} 
                link={'https://advis.freshdesk.com/support/solutions/folders/63000238312'}/>
            </div>
        );
    }

    renderSteps() {
        let stepContent;

        switch (this.state.step) {
            case 1:
                stepContent = this.renderFormOne();
                break;
            default:
                stepContent = <div>Passo desconhecido</div>;
        }

        return stepContent;
    }

    renderFormOne() {

        const message = IdiomaHelper.getStruct({
            'pt_br': { name: 'Nome do Processo', description: 'Descrição', revision: 'Revisar', save: 'Salvar', back: 'Voltar' },
            'en': { name: 'Evaluation Period Name', description: 'Description', revision: 'Review', save: 'Save', back: 'Back' },
            'es': { name: 'Nombre del Período de Evaluación', description: 'Descripción', revision: 'Revisar', save: 'Guardar', back: 'Volver' },
        });

        return (
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', alignContent: 'center', minHeight: 'inherit' }}>
                <Form style={{ width: '70%' }}>
                    <Row className="mb-3">
                        <Form.Group as={Col} md={12} xxl={12} className="mb-3">
                            <Form.Label>
                                {message[this.state.lang].name}*
                            </Form.Label>
                            <Form.Control
                                type="text"
                                placeholder={message[this.state.lang].name + ' ...'}
                                value={this.state.name || ""}
                                loading={this.state.loading}
                                onChange={(event) => { this.setState({ name: event.target.value }) }}
                            />
                        </Form.Group>

                        <Form.Group as={Col} md={12} xxl={12} className="mb-3">
                            <Form.Label>{message[this.state.lang].description}</Form.Label>
                            <TextEditor
                                disabled={this.state.loadingGpt}
                                defaultValue={this.state.descricao ?? ''}
                                placeholder={message[this.state.lang].description + ' ...'}
                                onChange={(value) => { this.setState({ descricao: value }) }}
                            />
                        </Form.Group>
                    </Row>

                    <div className="mb-3 d-flex flex-row-reverse">
                        <Form.Group style={{...EssentialStyle.rowFlexEnd, gap: 10}}>
                            {this.state.descricaoAntiga?.length > 1 &&
                                <DefaultButton
                                    color={Colors.warning}
                                    width={'auto'}
                                    style={{display: 'flex', flexDirection: 'row', justifyContent: 'start', alignItems: 'center' ,gap: 5}}
                                    leftIcon={<FontAwesomeIcon icon={faChevronLeft} />}
                                    title={message[this.state.lang].back}
                                    disabled={this.state.loading || this.state.loadingGpt}
                                    onClick={() => { this.setState({ descricao: this.state.descricaoAntiga, descricaoAntiga: '' }) }}
                                />
                            }
                            <DefaultButton
                                color={Colors.success}
                                leftIcon={<FontAwesomeIcon icon={faMagic} />}
                                loading={this.state.loadingGpt || this.state.loading}
                                title={message[this.state.lang].revision}
                                disabled={this.state.saving || this.state.loading || this.state.loadingGpt || !this.state.descricao || DataHelper.removeHtmlTags(this.state.descricao ?? '').length < 50}
                                onClick={this.textReview}
                            />
                            <DefaultButton
                                color={Colors.success}
                                rightIcon={<FontAwesomeIcon icon={faChevronRight} />}
                                title={message[this.state.lang].save}
                                loading={this.state.loading}
                                onClick={() => { this.step(1) }}
                            />
                        </Form.Group>
                    </div>
                </Form>
            </div>
        );
    }

    render() {

        if (this.state.saving) return this.renderSaving();

        return (
            <div style={{ minWidth: '100%', minHeight: '100vh' }}>
                {this.renderStepBack()}
                {this.renderHelp()}
                {this.renderSteps()}
            </div>
        );
    }
}