import { faInfinity } from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

import DefaultLoader from "./DefaultLoader";
import DiagnosticoHelper from "../../helper/diagnostico/DiagnosticoHelper";
import IdiomaHelper from "../../helper/IdiomaHelper";

export default class BarraDemonstracaoPeriodo extends React.Component {
    state = {
        infinito: false,
        idPeriodo: this.props.idPeriodo,
        faxas: this.props.faxas,
        faixas:[
            { min: 0, max: 60, infinito: 0, color: '#cecbcb', textos: [] },
            { min: 60, max: 90, infinito: 0, color: '#cecbcb', textos: [] },
            { min: 90, max: 100, infinito: 0, color: '#cecbcb', textos: [] },
        ],
        loading: true,
    }

    componentDidMount(){
        this.loadFaixas();
    }

    componentDidUpdate(prevProps){
        if (prevProps.idPeriodo != this.props.idPeriodo){
            this.setState({ idPeriodo: this.props.idPeriodo }, () => {
                this.loadFaixas();
            });
        }
    }

    loadFaixas = async () => {
        this.setState({ loading: true });

        let {faixas} = await DiagnosticoHelper.getFaixasPeriodo(this.state.idPeriodo);

        this.setState({ loading: false, faixas });
    }

    renderLoading(){
        return (
            <div style={{ height: 45, border: '0.3px solid #cecbcb', borderRadius: 5, width: this.props.width, display: 'flex', overflow: 'hidden', marginTop: this.props.marginTop ? this.props.marginTop : 20, paddingLeft: 5 }}>
                <DefaultLoader/>
            </div>
        )
    }

    render() {

        if (this.props.loading || this.state.loading) return this.renderLoading();

        let sortedIntervals = this.state.faixas.sort((a, b) => a.min - b.min);
        
        return this.state.faixas == null ? null : (
           <div style={{ height: 45, border: '0.3px solid #cecbcb', borderRadius: 5, display: 'flex', overflow: 'hidden', marginTop: 20, ...this.props.style }}>
                
                { sortedIntervals.map((interval, index) => {
                    let width = interval.max - interval.min;
                    return (
                        <div key={`item-barra-demonstracao-${index}`} style={{ width: this.props.proportional ? `${interval.infinito == 1 ? '50' : width}%` : 'default', minWidth: interval.min < 100 ? 40 : 45, backgroundColor: interval.cor, color: 'white', display: 'flex', justifyContent: 'left', alignItems: 'center', padding: '0px 10px' }}>
                            <span style={{ fontSize: 12, fontWeight: 'bold' }}>
                                {this.props.percentos && (interval.min)}
                                {this.props.percentos && ' - '}
                                {this.props.percentos && (interval.infinito == 1 ? <FontAwesomeIcon icon={faInfinity} /> : (interval.max + '%'))} 
                                {interval.textos.find((item) => item.id_idioma == IdiomaHelper.getSiglaId(this.props.lang))?.texto ?? ''}
                            </span>
                        </div>
                    );
                })}

           </div>
        );
    }
}