import FuncionalidadesHelper from "./FuncionalidadesHelper";
import { faUser, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

export default class AnalyticsHelper {
    
    static getModulos = async (idEmpresas, t) => {
        let funcionalidadesReturn = [];

        let funcionalidades  = await FuncionalidadesHelper.getFuncionalidades(idEmpresas);

        const funcionalidadesAnalytics = AnalyticsHelper.getFuncionalidadesAnalytics(t);

        if (funcionalidades.length === 0) {
            for (let key in funcionalidadesAnalytics) {
                funcionalidadesReturn.push(funcionalidadesAnalytics[key]);
            }
            return funcionalidadesReturn;
        }

        for (let key in funcionalidades) {
            let referencia = funcionalidades[key].referencia;
            if (funcionalidadesAnalytics[referencia]) {
                funcionalidadesReturn.push(funcionalidadesAnalytics[referencia]);
            }
        }
        return funcionalidadesReturn;
    }

    static getStatesColaboradores (t) {
        return [
            t('client_src_helper_inicio_AnalyticsHelper:bloqueado'), 
            t('client_src_helper_inicio_AnalyticsHelper:ativo'), 
            t('client_src_helper_inicio_AnalyticsHelper:inativo')
        ];
    }

    static getPermissoesColaboradores (t) {
        return {
            todos: t('client_src_helper_inicio_AnalyticsHelper:todos_niveis'),
            administradorMaster: t('client_src_helper_inicio_AnalyticsHelper:administrador_master'),
            consultorAdvis: t('client_src_helper_inicio_AnalyticsHelper:consultor_advis'),
            administrador: t('client_src_helper_inicio_AnalyticsHelper:administrador'),
            gestor: t('client_src_helper_inicio_AnalyticsHelper:gestor_empresa'),
            colaborador: t('client_src_helper_inicio_AnalyticsHelper:colaborador')
        }
    }

    static getPermissoesColaboradoresClientes (t) {
        return {
            todos: t('client_src_helper_inicio_AnalyticsHelper:todos_niveis'),
            administrador: t('client_src_helper_inicio_AnalyticsHelper:administrador'),
            gestor: t('client_src_helper_inicio_AnalyticsHelper:gestor_empresa'),
            colaborador: t('client_src_helper_inicio_AnalyticsHelper:colaborador')
        }
    }

    static getNivelMapping() {
        return {
            'All Levels': 'Todos Níveis',
            "Todos los Niveles": 'Todos Níveis',
            'Master Administrator': 'Administrador Master',
            "Administrador Maestro": 'Administrador Master',
            'Consultant Advis': 'Consultor Advis',
            'Consultor Advis': 'Consultor Advis',
            'Administrator': 'Administrador',
            'Administrador': 'Administrador',
            'Company Manager': 'Gestor da Empresa',
            "Gerente de la Empresa": 'Gestor da Empresa',
            'Collaborator': 'Colaborador',
            'Colaborador': 'Colaborador'
        };
    }

    static getFuncionalidadesAnalytics(t) {
        return {
            "config": {
                "id": "config",
                "title": t('client_src_helper_inicio_AnalyticsHelper:colaboradores_title'),
                "name": t('client_src_helper_inicio_AnalyticsHelper:colaboradores_name'),
                "icon": faUser,
            },
            "pe_swot": {
                "id": "pe_swot",
                "title": t('client_src_helper_inicio_AnalyticsHelper:swot_title'),
                "name": t('client_src_helper_inicio_AnalyticsHelper:swot_name'),
                "icon": faEyeSlash,
            },
            "pe_visao": {
                "id": "pe_visao",
                "title": t('client_src_helper_inicio_AnalyticsHelper:visoes_title'),
                "name": t('client_src_helper_inicio_AnalyticsHelper:visoes_name'),
                "icon": faEyeSlash,
            },
            "pe_mapa": {
                "id": "pe_mapa",
                "title": t('client_src_helper_inicio_AnalyticsHelper:mapas_title'),
                "name": t('client_src_helper_inicio_AnalyticsHelper:mapas_name'),
                "icon": faEyeSlash,
            },
            "pe_projeto": {
                "id": "pe_projeto",
                "title": t('client_src_helper_inicio_AnalyticsHelper:projetos_title'),
                "name": t('client_src_helper_inicio_AnalyticsHelper:projetos_name'),
                "icon": faEyeSlash,
            },
            "pe_canvas": {
                "id": "pe_canvas",
                "title": t('client_src_helper_inicio_AnalyticsHelper:canvas_title'),
                "name": t('client_src_helper_inicio_AnalyticsHelper:canvas_name'),
                "icon": faEyeSlash,
            },
            "gmr": {
                "id": "gmr",
                "title": t('client_src_helper_inicio_AnalyticsHelper:gmr_title'),
                "name": t('client_src_helper_inicio_AnalyticsHelper:gmr_name'),
                "icon": faEyeSlash,
            },
            "gd": {
                "id": "gd",
                "title": t('client_src_helper_inicio_AnalyticsHelper:gd_title'),
                "name": t('client_src_helper_inicio_AnalyticsHelper:gd_name'),
                "icon": faEyeSlash,
            },
            "plano_acao": {
                "id": "plano_acao",
                "title": t('client_src_helper_inicio_AnalyticsHelper:pa_title'),
                "name": t('client_src_helper_inicio_AnalyticsHelper:pa_name'),
                "icon": faEyeSlash,
            },
        };
    }
}