import React from "react";

import SessionHelper from "../../../../../../helper/SessionHelper";
import ButtonDownloadImages from "../../../../../tools/ButtonDownloadImages/ButtonDownloadImages";
import IndicadorGauge from "../IndicadorGauge";
import PainelPerformanceLineChart from "../PainelPerformanceLineChart/PainelPerformanceLineChart";
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import { withTranslation } from 'react-i18next';

class ButtonDownloadGraficosIndicadores extends React.Component {
    state = {
        loading: false,
        charts: [],
    }

    async componentDidMount() {
        this.setState({ loading: true });
        await this.props.i18n.loadNamespaces([
            'client_src_components_modules_inicio_charts_indicadores_ButtonDownloadGraficosIndicadores_ButtonDownloadGraficosIndicadores'
        ]);
        this.setState({ loading: false });
    }

    loadCharts() {
        this.setState({ loading: true });
        let charts = [];

        if (this.props.indicadoresExpandidos) charts = this.loadIndicadoresExpandidos(charts);
        if (this.props.painel) charts = this.loadPainel(charts);
        if (this.props.indicadores) charts = this.loadIndicadores(charts);

        this.setState({ charts, loading: false });
    }

    loadIndicadores(charts) {
        let { indicadores, id_gmr_painel, data, acumulado } = this.props.indicadores;

        indicadores.forEach((indicador) => {
            charts.push({
                style: {width: 400, minWidth: 400, height: 310},
                image: <IndicadorGauge
                    key={indicador.id}
                    nome
                    id_indicador={indicador.id}
                    id_gmr_painel={id_gmr_painel}
                    data={data}
                    acumulado={acumulado}
                    gauge={true}
                    mergulho={false}
                    fontMultiple={-4}
                />
            })
        });

        return charts;
    }

    loadPainel(charts) {
        let { painel, dataInicio, dataFim } = this.props.painel;
        charts.push({
            style: {width: 570, minWidth: 570, height: 310},
            image: <PainelPerformanceLineChart
                painel={painel}
                loading={this.state.loading || this.props.loading}
                dataInicio={dataInicio}
                dataFim={dataFim}
                fontMultiple={-4}
                dataLabels={true}
            />
        });

        return charts;
    }

    loadIndicadoresExpandidos(charts) {
        let { options } = this.props.indicadoresExpandidos;

        if (!options || options == null) return charts;
        
        charts.push({
            style: {width: 570, minWidth: 570, height: 310},
            image: <HighchartsReact
                highcharts={Highcharts}
                constructorType={'chart'}
                containerProps={{ style: { width: '100%', height: '100%' } }}
                options={options}
                fontMultiple={-4}
            />
        });

        return charts;
    }

    render() {
        return (
            <ButtonDownloadImages
                color={'transparent'}
                textColor={SessionHelper.getColor()}
                loading={(this.props.loading || this.state.loading) || false}
                imageComponents={this.state.charts}
                updateImages={() => {this.loadCharts()}}
                isSmallScreen={this.props.isSmallScreen}
                title={this.props.t('client_src_components_modules_inicio_charts_indicadores_ButtonDownloadGraficosIndicadores_ButtonDownloadGraficosIndicadores:title')}
            />
        )
    }
}

export default withTranslation()(ButtonDownloadGraficosIndicadores);