import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusSquare } from '@fortawesome/free-solid-svg-icons'
import Colors from "../../constants/Colors";
import DefaultButton from "../../components/tools/DefaultButton";
import { toast } from "react-toastify";
import EssentialStyle from "../../style/EssentialStyle";
import { Collapse, Form, Row, Col } from "react-bootstrap";
import DefaultTableSearch from "../../components/tools/DefaultTableSearch";
import DefaultLoader from "../../components/tools/DefaultLoader";
import Sig from "../../api/Sig";
import AtaReuniaoListSearch from "../../components/modules/ataReuniao/AtaReuniaoListSearch";
import LayoutHelper from "../../helper/LayoutHelper";
import Tabs from "../../components/tools/Tabs";
import moment from "moment";
import AtaReuniaoCard from "../../components/modules/ataReuniao/AtaReuniaoCard/AtaReuniaoCard";
import AddAtaReuniao from "../../components/forms/ataReuniao/add/AddAtaReuniao";
import { confirmAlert } from 'react-confirm-alert';
import CustomConfirm from "../../components/tools/CustomConfirm";
import SessionHelper from "../../helper/SessionHelper";
import Modal from "../../components/tools/Modal";
import DatePickerHelper from "../../helper/DatePickerHelper";
import DatePicker from "react-datepicker";
import DataHelper from "../../helper/DataHelper";
import AtaRelatorio from "../../components/modules/ataReuniao/AtaRelatorio/AtaRelatorio";

export default class AtaReuniaoPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            scrolled: false,
            loading: true,
            loadingHeader: true,
            showWizard: false,
            advancedFilter: false,
            editId: null,
            canCreate: false,
            data: [],
            filter: {},
            page: 1,
            hasMore: true,
            firstLoad: true,
            headerSize: 0,
            isSmallScreen: LayoutHelper.isSmallScreen(),
            activeTab: 'lista',
            cloneOptions: {
                keepConvocacao: false,
                keepParticipantes: false,
                keepTopicos: false,
                keepConteudoTopicos: false,
                assunto: null,
                date: null,
            },
        }
        this.headerRef = React.createRef();
        this.interval = null;
    }

    async componentDidMount() {
        this.handleUrlParams();
        window.addEventListener('resize', this.verifyScreen);
        window.addEventListener('scroll', this.handleScroll);
        this.interval = setInterval(this.updateHeaderSize, 10);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.verifyScreen);
        window.removeEventListener('scroll', this.handleScroll);
    }

    verifyScreen = () => {
        if (this.state.isSmallScreen == LayoutHelper.isSmallScreen()) return;
        this.setState({ isSmallScreen: LayoutHelper.isSmallScreen() }, () => {
            this.updateHeaderSize();
            if (this.state.showFilters && this.state.isSmallScreen)
                this.setState({ showFilters: false });
        });
    }

    updateHeaderSize = () => {
        if (this.headerRef.current) {
            this.setState({ headerSize: this.headerRef.current.clientHeight });
            if (this.interval) clearInterval(this.interval);
        }
    }

    handleUrlParams = () => {
        window.parent.postMessage({ type: 'get_url' }, '*');

        const listenerFunction = async (event) => {
            if (event.data.type === 'return_url') {
                const urlObj = new URL(event.data.url);
                const queryParams = new URLSearchParams(urlObj.search);
                const tipo = queryParams.get('tipo');

                if (tipo && (tipo == "novo" || tipo == "editar")) {
                    this.setState({ showWizard: true });
                } else {
                    await this.getConfigColaborador();
                }

                window.removeEventListener('message', listenerFunction);
            }
        };

        window.addEventListener('message', listenerFunction);
    }

    handleScroll = () => {
        if (window.innerHeight + document.documentElement.scrollTop === document.documentElement.offsetHeight) {
            if (!this.state.loading && this.state.hasMore && this.state.activeTab == 'lista') {
                this.loadData(this.state.page + 1);
            }
        }
    }

    async getConfigColaborador() {
        // let req = await Sig.request('GET', 'config/colaborador/getAtaConfig');
        
        // if (req.status != 200) {
        //     return toast.error("Erro ao carregar configuração.");
        // }
        
        this.setState({ activeTab: 'lista' }, () => {
            if(this.state.activeTab == 'relatorio') {
                this.setState({ filter: { tipoData: 'cadastro', dataInicio: moment().subtract(12, 'months').toDate(), dataFim: moment().toDate() }
                }, () => this.loadData(1));
            } else {
                this.loadData(1);
            }
        });
    }

    async setConfigColaborador() {
        // let req = await Sig.request('POST', 'config/colaborador/setAtaConfig', { tipo_visualizacao: this.state.activeTab, parceiro: this.state.parceirosChecked ? 1 : 0 });

        // if (req.status != 200) {
        //     return toast.error("Erro ao salvar configuração.");
        // }
    }

    async loadData(page = 1) {
        let timeout = null;

        if (this.state.firstLoad) {
            this.setState({ loading: true, firstLoad: false, loadingHeader: false });
        } else {
            timeout = setTimeout(() => {
                this.setState({ loading: true, loadingHeader: false });
            }, 200);
        }

        if (this.state.activeTab == 'relatorio') {
            this.setState({ hasMore: false, loading: false, data: [] });
            return;
        } else {
            var req = await Sig.request('POST', 'ataReuniao/list', { page, limit: 10, filter: JSON.stringify(this.state.filter) });
            if (this.state.activeTab != 'lista') return;
        }

        if (timeout) {
            clearTimeout(timeout);
        }

        if (req.status != 200) {
            return toast.error("Erro ao carregar dados");
        }

        if (this.state.activeTab == 'lista') {
            if(req.data.length < 10)
                this.setState({ hasMore: false });
            else
                this.setState({ hasMore: true });
        }

        this.setState(prevState => ({
            data: page === 1 || this.state.activeTab == 'relatorio' ? req.data : [...prevState.data, ...req.data],
            loading: false,
            page
        }));

    }

    loadSingleAta = async () => {
        if(!this.state.loading && this.state.activeTab == 'lista' && this.state.hasMore) {
            this.setState({ loading: true });
            
            var req = await Sig.request('POST', 'ataReuniao/list', { page: 10, limit: 1, filter: JSON.stringify(this.state.filter) });
            if (this.state.activeTab != 'lista') return;

            if(req.data.length < 1)
                this.setState({ hasMore: false });
            else
                this.setState({ hasMore: true });

            this.setState(prevState => ({
                data:  [...prevState.data, ...req.data],
                loading: false,
            }));
        }
    }


    handleToggleExpand = (advancedFilter) => {
        this.setState({ advancedFilter: advancedFilter });
    }

    filter = async (filter, closeFilter = false) => {
        this.setState({ loading: true, filter }, () => {
            this.loadData(1);
            if (closeFilter) this.handleToggleExpand(false);
        });
    }

    handleSearch = async (text) => {
        this.setState({ loading: true, filter: { ...this.state.filter, nome: text } }, () => {
            this.loadData(1);
        });
    }

    countFilters = () => {
        let count = 0;
        for (let key in this.state.filter) {
            if ((this.state.filter[key] && !Array.isArray(this.state.filter[key]))) {
                if (key == 'dataInicio' && this.state.filter['dataFim']) {
                    count++;
                } else if (key == 'dataFim' || key == 'tipoData') {
                    continue;
                } else {
                    count++;
                }
            } else if (Array.isArray(this.state.filter[key]) && this.state.filter[key].length > 0) {
                count++;
            }
        }
        return count;
    }

    renderHeader = () => {
        const tabs = [
            { name: 'lista', title: 'Lista' }, 
            { name: 'relatorio', title: 'Relatório' }
        ];

        if ( !this.state.loadingHeader ) return (
            <div style={{width: "100%", top: 0, position: "sticky", zIndex: 5}}>
                <div ref={this.headerRef} style={{ ...EssentialStyle.rowFlexCenter, ...EssentialStyle.card, padding: 10, flexWrap: "wrap"}}>
                    <div style={{ ...EssentialStyle.rowSpaceBetween, width: "100%" }}>
                        <DefaultButton
                            onClick={() => this.setState({ showWizard: true })}
                            leftIcon={<FontAwesomeIcon icon={faPlusSquare} />}
                            color={Colors.success}
                            title={this.state.isSmallScreen ? "" : "Ata de Reunião"}
                        />
                        <div style={{ ...EssentialStyle.rowFlexCenter, gap: 10 }}>
                            <DefaultTableSearch
                                handleSearch={this.handleSearch}
                                onEmpty={() => {
                                    if (this.state.activeTab != 'relatorio') {
                                        this.loadData(1)
                                    }
                                }}
                                toggleExpand={this.handleToggleExpand}
                                appliedFilters={this.countFilters()}
                                text={this.state.filter.nome}
                                advancedFilter={this.state.advancedFilter}
                                type={'ataReuniao'}
                            />
                            {!this.state.isSmallScreen && 
                                <Tabs 
                                    tabs={tabs} 
                                    activeTab={this.state.activeTab} 
                                    isSmallScreen={this.state.isSmallScreen} 
                                    onTabChange={this.handleTabChange}
                                    tabStyle={{width: this.state.isSmallScreen ? '32vw' : 90, maxWidth: 100}}
                                /> 
                            }   
                            <DefaultButton
                                style={{ width: 38, height: 38, marginRight: this.state.isSmallScreen ? 5 : 10 }}
                                fontSize={this.state.isSmallScreen ? 14 : "unset"}
                                color={Colors.secondaryButton}
                                textColor={Colors.dark}
                                link={this.state.activeTab == 'lista' ? 'https://advis.freshdesk.com/support/solutions/articles/63000225982-atas-de-reunião' : 'https://advis.freshdesk.com/support/solutions/articles/63000225982-atas-de-reunião#relatorios'}
                            />
                        </div>
                    </div>
                    {this.state.isSmallScreen &&
                        <div style={{ ...EssentialStyle.rowSpaceBetween, width: '100%', marginTop: 10 }}>
                            <Tabs 
                                tabs={tabs}
                                activeTab={this.state.activeTab} 
                                isSmallScreen={this.state.isSmallScreen} 
                                onTabChange={this.handleTabChange}
                                tabStyle={{width: this.state.isSmallScreen ? '32vw' : 90, maxWidth: 100}}
                            />
                        </div>
                    }
                </div>
                <Collapse in={this.state.advancedFilter}>
                    <div style={{ position: "absolute", zIndex: 5, width: "100%" }}>
                        <AtaReuniaoListSearch
                            filterCallback={this.filter}
                            isSmallScreen={this.state.isSmallScreen}
                            type={this.state.activeTab}
                            nome={this.state.filter.nome}
                            advancedFilter={this.state.advancedFilter}
                            noDateSelector
                        />
                    </div>
                </Collapse>
            </div>
        );
    }

    renderAtaCard(item, index) {
        return <AtaReuniaoCard 
            key={item.id} 
            ata={item}
            onClick={(ata, novaAba = false) => { 
                window.parent.postMessage({ type: novaAba ? '_blank' :'navigate', url: `/ataReuniao/detalhar/${ata.id}` }, '*'); 
            }} 
            deleteAtaReuniaoCallback={this.deleteAtaReuniao}
            cloneAtaReuniaoCallback={() => this.setState({ showCloneModal: item.id, cloneOptions: { ...this.state.cloneOptions, assunto: item.assunto + " (Cópia)", date: item.data } })}
            updateAtaReuniaoCallback={this.updateAtaReuniao}
        />
    }

    handleTabChange = (tab) => {
        if (this.state.activeTab === tab.name) return;
        this.setState({ activeTab: tab.name, loading: true, hasMore: tab.name == 'lista' ? true : false, firstLoad: true, page: 1, data: [] }, () => {
            this.setConfigColaborador();
        });
    }

    renderList() {
        if (this.state.data.length === 0 && !this.state.loading) return this.renderEmpty();

        return (
            <>
                {this.state.data.map((item, index) => this.renderAtaCard(item, index))}
                {this.state.loading && <div style={{ ...EssentialStyle.columnCenter, padding: 10, width: '100%', marginTop: 10 }}><DefaultLoader /></div>}
            </>
        );
    }

    renderRelatorio() {
        return (
            <AtaRelatorio filter={this.state.filter} isSmallScreen={this.state.isSmallScreen} loading={this.state.loading} headerSize={this.state.headerSize} />
        );
    }

    renderEmpty() {
        return (
            <div style={{ padding: 50, backgroundColor: 'white', marginTop: 20, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', boxShadow: Colors.boxShadow, border: '0.3px solid lightgrey', borderRadius: 5, textAlign: 'center' }}>
                <h5>Até o momento, sua empresa ainda não criou nenhuma <strong>Ata de Reunião</strong>.</h5>
                <div style={{ marginTop: 20 }} />
                <DefaultButton
                    onClick={() => this.setState({ showWizard: true })}
                    leftIcon={<FontAwesomeIcon icon={faPlusSquare} />}
                    color={Colors.success}
                    title={this.state.isSmallScreen ? "" : "Ata de Reunião"}
                />
            </div>
        )
    }

    changeCloneOptions = (key, value) => {
        this.setState({ cloneOptions: {...this.state.cloneOptions, [key]: value } }, () => {
            if(!this.state.cloneOptions.keepTopicos && this.state.cloneOptions.keepConteudoTopicos) {
                this.setState({ cloneOptions: {...this.state.cloneOptions, keepConteudoTopicos: false } });
            }
        });
    }

    CustomPicker = React.forwardRef(({ value, onClick }, ref) => (
        <Form.Control onClick={onClick} ref={ref} value={value} readOnly placeholder={"Data e Hora da Reunião"}/>
    ));

    renderDatePicker() {
        let dateMoment = this.state.cloneOptions.date && this.state.cloneOptions.date != null ? moment(this.state.cloneOptions.date).toDate() : null;

        return (
            <DatePicker
                wrapperClassName="w-100"
                selected={dateMoment}
                onChange={(date) => {
                    this.setState({ cloneOptions: {...this.state.cloneOptions, date: DataHelper.getDefaultDbDateFormat(date)} });
                }}
                dateFormat="dd/MM/yyyy HH:mm"
                customInput={<this.CustomPicker />}
                showTimeSelect
                renderCustomHeader={(props) =>  
                    DatePickerHelper.renderCustomDatePickerHeader(props)
                }
            />
        );
    }

    renderCloneOptions() {
        return (
            <div style={{display: 'flex', flexDirection: 'column', gap: 10, minWidth: "100%"}}>
                <div style={{display: 'flex', gap: 10, width: "100%", placeContent: "flex-start"}}>
                    <Form.Group as={Col} md={8} xxl={8} className="mb-3">
                        <Form.Label>
                            Assunto
                        </Form.Label>
                        <Form.Control
                            type="text"
                            placeholder={"Assunto da Reunião..."}
                            value={this.state.cloneOptions.assunto && this.state.cloneOptions.assunto != null ? this.state.cloneOptions.assunto : ""}
                            onChange={(event) => { 
                                this.setState({ cloneOptions: { ...this.state.cloneOptions, assunto: event.target.value.trim() != "" ?  event.target.value : null } }) }}
                        />
                    </Form.Group>
                    <Form.Group as={Col} md={4} xxl={4} className="mb-1 w-30">
                        <Form.Label>Data</Form.Label>
                        {this.renderDatePicker()}
                    </Form.Group>
                </div>
                <Form.Check 
                    type="checkbox"
                    label="Manter Participantes"
                    checked={this.state.cloneOptions.keepParticipantes ? true : false}
                    onChange={(e) => {
                        this.changeCloneOptions('keepParticipantes', e.target.checked ? true : false);
                    }}
                />
                <Form.Check 
                    type="checkbox"
                    label="Manter Convocação"
                    checked={this.state.cloneOptions.keepConvocacao ? true : false}
                    onChange={(e) => {
                        this.changeCloneOptions('keepConvocacao', e.target.checked ? true : false);
                    }}
                />
                <Form.Check 
                    type="checkbox"
                    label="Manter Tópicos"
                    checked={this.state.cloneOptions.keepTopicos ? true : false}
                    onChange={(e) => {
                        this.changeCloneOptions('keepTopicos', e.target.checked ? true : false);
                    }}
                />
                <Form.Check 
                    type="checkbox"
                    label="Manter o Conteúdo dos Tópicos"
                    disabled={!this.state.cloneOptions.keepTopicos}
                    checked={this.state.cloneOptions.keepConteudoTopicos ? true : false}
                    onChange={(e) => {
                        this.changeCloneOptions('keepConteudoTopicos', e.target.checked ? true : false);
                    }}
                />
            </div>
        );
    }

    renderCloneAtaModal = () => {
        if(!this.state.showCloneModal) return null;
        return (
            <Modal style={{ width: this.state.isSmallScreen ? '95vw' : '70vw' }}>
                <div style={{ ...EssentialStyle.columnCenter, padding: '20px', textAlign: 'left', minHeight: 200, minWidth: "100%" }}>
                    <div style={{ ...EssentialStyle.rowSpaceBetween, width: "100%" }}>
                        <h3>Clonar Ata de Reunião</h3>
                    </div>
                    {this.renderCloneOptions()}
                    <div style={{display: 'flex', gap: 10, marginTop: 20, width: "100%", placeContent: "flex-end"}}>
                        <DefaultButton
                            title={"Clonar"}
                            onClick={async () => {
                                let cloneAta = await Sig.request('POST', 'ataReuniao/cloneAtaReuniao', { id: this.state.showCloneModal, cloneOptions: JSON.stringify(this.state.cloneOptions) });

                                if (cloneAta.status == 200) {
                                    toast.success("Ata de reunião clonada com sucesso");
                                    this.setState({ 
                                        showCloneModal: null, 
                                        cloneOptions: { 
                                            keepParticipantes: false, 
                                            keepTopicos: false, 
                                            keepConteudoTopicos: false, 
                                            assunto: null, 
                                            date: null 
                                        } 
                                    }, 
                                        () => this.loadData(1)
                                    );
                                }
                            }}
                        />
                        <DefaultButton
                            title={"Cancelar"}
                            color={Colors.homePage.grey}
                            onClick={() => this.setState({ showCloneModal: null, cloneOptions: { keepConvocacao: false, keepParticipantes: false, keepTopicos: false, keepConteudoTopicos: false, assunto: null, date: null } })}
                        />
                    </div>
                </div>
            </Modal>
        );
    }

    deleteAtaReuniao = async (id) => {
        confirmAlert({
            customUI: ({ onClose }) => (
                <CustomConfirm
                    title={`Excluir esta Ata de Reunião?`}
                    message={<p style={{ marginTop: 10, marginBottom: 10 }}>Essa ação não pode ser desfeita</p>}
                    buttons={[
                        {
                            label: 'Remover',
                            color: SessionHelper.getColor(),
                            textColor: Colors.light,
                            onClick: async () => {
                                let deletePlanoAcao = await Sig.request('POST', 'ataReuniao/deleteAtaReuniao', { id });

                                if (deletePlanoAcao.status == 200) {
                                    toast.success("Ata de reunião excluída com sucesso");
                                    var atas = [...this.state.data];
                                    atas = atas.filter(ata => ata.id != id);
                                    this.setState({ data: atas }, () => this.loadSingleAta());
                                }

                                onClose();
                            }
                        },
                        {
                            label: 'Cancelar',
                            onClick: () => { onClose(); }
                        },
                    ]}
                />
            )
        });

    }

    updateAtaReuniao = (ata) => {
        var atas = [...this.state.data];
        var index = atas.findIndex(a => a.id == ata.id);
        atas[index] = ata;
        this.setState({ data: atas });
    }

    addCallback = async () => {
        this.setState({ editId: null, showWizard: false}, this.loadData);
    }

    render() {
        if (this.state.showWizard) return <AddAtaReuniao addCallback={this.addCallback}/>

        return (
            <>
                {this.renderHeader()}
                {this.state.activeTab == 'lista' ? this.renderList() : null}
                {this.state.activeTab == 'lista' && this.renderCloneAtaModal()}
                {this.state.activeTab == 'relatorio' && this.renderRelatorio()}
            </>
        );
    }
}