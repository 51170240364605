import React from "react";
import DataHelper from "../../../../helper/DataHelper";
import { Form, InputGroup, Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faTimes } from "@fortawesome/free-solid-svg-icons";
import AddTag from "../../../tools/AddTag";
import Select from 'react-select'
import DefaultButton from "../../../tools/DefaultButton";
import moment from "moment";
import Colors from "../../../../constants/Colors";
import Sig from "../../../../api/Sig";


export default class SwotListSearch extends React.Component {

    state = {
        loading: true,
        typingTimeout: null,
        data: this.props.data,
        auxData: this.props.data,
        colabs: [],
        name: null,
        description: null,
        responsable: null,
        responsableObj: null,
        participants: [],
        tags: [],
    }

    async componentDidMount() {
        await this.loadData();
    }

    async loadData() {
        this.setState({ loading: true });

        const { colaboradores, swots } = await Sig.request('GET', 'pe/swot/getSwotsWithColaboradores');

        let step = this.state.step;
        if (!swots.length) { step = 1 };

        this.setState({ colabs: colaboradores, swots, step }, () => { this.setState({ loading: false }) });
    }

    searchObject(object, text) {
        let valid = false;

        if (object === null) return false;

        if (typeof object === 'object'){
            if(Array.isArray(object)){

                let ids = object.map((item) => (item.id));
                let textIds = text.map((text) => (text.value));
                valid = textIds.every((item) => (ids.includes(item)));

            } else {
                valid = object.id == text;
            }

        } else {
            valid = object.toString().toUpperCase().indexOf(text.toUpperCase()) > -1;
        }

        return valid;
    }

    filter = () => {

        let data = this.state.data;
        let result = [];

        result = data.filter((item, key) => {

            let valid = false;

            let criterios = 0;
            let criteriosValidados = 0;

            if (this.state.name && this.state.name.length > 0) {
                criterios++;
                valid = this.searchObject(item.nome, this.state.name);
                if (valid) { criteriosValidados++; }
            }

            if (this.state.description && this.state.description.length > 1) {
                criterios++;
                valid = this.searchObject(item.descricao, this.state.description);
                if (valid) { criteriosValidados++; }
            }

            if (this.state.responsable) {
                criterios++;
                valid = this.searchObject(item.id_responsavel, this.state.responsable);
                if (valid) { criteriosValidados++; }
            }

            if (this.state.participants.length) {
                criterios++;
                valid = this.searchObject(item.participants, this.state.participants);
                if (valid) { criteriosValidados++; }
            }

            if (this.state.tags.length) {
                criterios++;
                valid = this.searchObject(item.tags, this.state.tags);
                if (valid) { criteriosValidados++; }
            }

            valid  = criteriosValidados == criterios ? true : false;
            return valid;
        });

        this.setState({ auxData: result });
        this.props.onDataUpdate(result);

    }

    limpar = () => {
        this.setState({ name: null, description: null, responsableObj: null, responsable: null, participants: [], tags: [] }, () => { this.filter() });
    }

    render() {
        return (
            <div className="box-advancedSearch">
                <Form className="w-100">
                    <Row className="m1-3">
                        <Form.Group as={Col}>
                            <Form.Label className="form-label-xsm">Nome da Análise SWOT</Form.Label>
                            <Form.Control className="form-control-xsm" type="text" placeholder="Nome..." value={this.state.name || ''} onChange={(event) => { this.setState({ name: event.target.value }) }} />
                        </Form.Group>

                        <Form.Group as={Col}>
                            <Form.Label className="form-label-xsm">Responsável pela Análise</Form.Label>
                            <Select 
                                className="form-control-xsm" 
                                options={DataHelper.formatSelectData(this.state.colabs, 'id', 'nome')} 
                                placeholder={'Selecione o responsável'} 
                                noOptionsMessage={DataHelper.getSelectEmptyMessage} 
                                isClearable 
                                isSearchable 
                                value={this.state.responsableObj} 
                                onChange={(value) => { this.setState({ responsable: value ? value.value : null, responsableObj: value ? value : null }); }}
                                menuPortalTarget={document.body} />
                        </Form.Group>

                        <Form.Group as={Col}>
                            <Form.Label className="form-label-xsm">Participantes</Form.Label>
                            <Select 
                                className="form-control-xsm" 
                                options={DataHelper.formatSelectData(this.state.colabs, 'id', 'nome')} 
                                placeholder={'Selecione os participantes'} 
                                noOptionsMessage={DataHelper.getSelectEmptyMessage} 
                                isClearable 
                                isSearchable 
                                isMulti  
                                value={this.state.participants} 
                                onChange={(value) => { this.setState({ participants: value }) }} 
                                menuPortalTarget={document.body} />
                        </Form.Group>
                    </Row>

                    <Row className="mb-1">
                        <Col md={6}>
                            <Form.Group>
                                <Form.Label className="form-label-xsm">Descrição</Form.Label>
                                <Form.Control className="form-control-xsm" type="text" placeholder="Descrição..." value={this.state.description || ''} onChange={(event) => { this.setState({ description: event.target.value }) }} />
                            </Form.Group>
                        </Col>
                        
                        <Col md={6}>
                            <AddTag value={this.state.tags} fontSize={"xsm"} onChange={(value) => { this.setState({ tags: value }) }} />
                        </Col>
                    </Row>

                    <div className="mb-0 d-flex flex-row-reverse">
                        <Form.Group>
                            <DefaultButton className="me-1" color={Colors.error} leftIcon={<FontAwesomeIcon icon={faTimes} />} title={'Limpar'} loading={this.state.loading} onClick={this.limpar} />
                            <DefaultButton color={Colors.success} leftIcon={<FontAwesomeIcon icon={faSearch} />} title={'Filtrar'} loading={this.state.loading} onClick={this.filter} />
                        </Form.Group>
                    </div>
                </Form>
            </div>
        )
    }
}