export default class DragAndDropHelper {
    static checkTop(e, relativeDropPositionSmallerThan = 0.5) {
        const dropPosition = e.pageY;
        const elementHeight = e.target.offsetHeight;
        const relativeDropPosition = (dropPosition - e.target.offsetTop) / elementHeight;

        let top = false;

        if (relativeDropPosition < relativeDropPositionSmallerThan) {
            top = true;
        } else {
            top = false;
        }

        return top;
    }

    static checkLeft(e, relativeDropPositionSmallerThan = 0.5) {
        const dropPosition = e.pageX;

        // Obter o retângulo de delimitação do elemento alvo e do elemento pai
        const parentRect = e.target.parentElement.getBoundingClientRect();
        const elementWidth = e.target.offsetWidth;
        const relativeDropPosition = (dropPosition - parentRect.left) / elementWidth;

        let left = false;
        
        if (relativeDropPosition < relativeDropPositionSmallerThan) {
            left = true;
        } else {
            left = false;
        }
    
        return left;
    }

    static checkTopTimeline(e, relativeDropPositionSmallerThan = 0.5, offset = 0) {
        const offsetTop = e.currentTarget.parentNode.parentNode.offsetTop + offset;
        const dropPosition = e.pageY;
        const elementHeight = e.currentTarget.parentNode.offsetHeight;
        const relativeDropPosition = (dropPosition - (e.currentTarget.parentNode.offsetTop + offsetTop)) / elementHeight;

        let top = false;

        if (relativeDropPosition < relativeDropPositionSmallerThan) {
            top = true;
        } else {
            top = false;
        }

        return top;
    }

    static checkTopTopicosAtaReuniao(e, relativeDropPositionSmallerThan = 0.5) {
        const offsetTop = e.currentTarget.parentNode.offsetTop;
        const dropPosition = e.pageY;
        const elementHeight = e.currentTarget.offsetHeight;
        const relativeDropPosition = (dropPosition - (e.currentTarget.offsetTop + offsetTop)) / elementHeight;

        let top = false;

        if (relativeDropPosition < relativeDropPositionSmallerThan) {
            top = true;
        } else {
            top = false;
        }

        return top;
    }
}