import React from 'react';
import EssentialStyle from '../../../../../style/EssentialStyle';
import TextEditor from '../../../../tools/TextEditor/TextEditor';
import DefaultButton from '../../../../tools/DefaultButton';
import Colors from '../../../../../constants/Colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faEdit, faCheck, faPlusSquare } from '@fortawesome/free-solid-svg-icons';
import { confirmAlert } from 'react-confirm-alert';
import CustomConfirm from '../../../../tools/CustomConfirm';
import SessionHelper from '../../../../../helper/SessionHelper';

export default class AtaReuniaoTopicoDeliberacao extends React.Component {

    deleteDeliberacaoModal = async () => {
        confirmAlert({
            customUI: ({ onClose }) => (
                <CustomConfirm
                    title={`Excluir a deliberação?`}
                    message={<p style={{ marginTop: 10, marginBottom: 10 }}>Essa ação não pode ser desfeita</p>}
                    buttons={[
                        {
                            label: 'Remover',
                            color: SessionHelper.getColor(),
                            textColor: Colors.light,
                            onClick: async () => {
                                await this.props.removeDeliberacaoCallback();
                                onClose();
                            }
                        },
                        {
                            label: 'Cancelar',
                            onClick: () => { onClose(); }
                        },
                    ]}
                />
            )
        });
    }

    render() {
        if(!this.props.deliberacao) {
            return (
                <div style={{...EssentialStyle.columnCenter, width: "100%", padding: "15px 0px", gap: 10}}>
                    <span style={{fontSize: 20, color: Colors.homePage.grey}}>Tópico sem Deliberação</span>
                    { this.props.canEdit &&
                        <DefaultButton
                            onClick={this.props.addDeliberacaoCallback}
                            leftIcon={<FontAwesomeIcon icon={faPlusSquare} />}
                            color={Colors.success}
                            tooltipPlacement={'bottom'}
                            title={"Adicionar"}
                        />
                    }
                </div>
            );
        }

        return (
            <div style={{...EssentialStyle.rowFlexCenter, padding: "15px 0px", width: "100%"}}> 
                <TextEditor
                    disabled={this.props.deliberacao?.status == 2 || !this.props.canEdit ? true : false}
                    noMargin
                    defaultValue={this.props.deliberacao.descricao}
                    placeholder="Deliberação..."
                    onChange={this.props.changeDeliberacaoTextCallback}
                />
                { this.props.canEdit &&
                    <>
                        <DefaultButton
                            style={{padding: 0, width: 40, height: 40, borderRadius: "50%"}}
                            onClick={this.deleteDeliberacaoModal}
                            leftIcon={<FontAwesomeIcon icon={faTrash} />}
                            color={"transparent"}
                            textColor={Colors.dark}
                            tooltipPlacement={'bottom'}
                            tooltip={"Remover"}
                            />
                        {this.props.deliberacao?.status == 2 ? 
                            <DefaultButton
                            style={{padding: 0, width: 40, height: 40, borderRadius: "50%"}}
                            onClick={() => this.props.changeDeliberacaoStatusCallback(1)}
                            leftIcon={<FontAwesomeIcon icon={faEdit} />}
                            color={"transparent"}
                            textColor={Colors.dark}
                            tooltipPlacement={'bottom'}
                            tooltip={"Marcar como não concluído"}
                            />
                            : this.props.deliberacao?.status == 1 ?
                            <DefaultButton
                            style={{padding: 0, width: 40, height: 40, borderRadius: "50%"}}
                            onClick={() => this.props.changeDeliberacaoStatusCallback(2)}
                            leftIcon={<FontAwesomeIcon icon={faCheck} />}
                            color={"transparent"}
                            textColor={Colors.dark}
                            tooltipPlacement={'bottom'}
                            tooltip={"Marcar como concluído"}
                            />
                            : null
                        }
                    </>
                }
            </div>
        );
    }
}