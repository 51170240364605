import React from "react";

import './IndicadorReferenciaCard.css';
import DiagnosticoHelper from "../../../../../helper/diagnostico/DiagnosticoHelper";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faUnlink, faCog, faBuilding, faChevronUp, faSave, faInfoCircle, faExchangeAlt, faLineChart, faCertificate, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import IdiomaHelper from "../../../../../helper/IdiomaHelper";
import EllipsisText from "../../../../tools/EllipsisText";
import { confirmAlert } from 'react-confirm-alert';
import CustomConfirm from "../../../../tools/CustomConfirm";
import Colors from "../../../../../constants/Colors";
import { Collapse, Form } from "react-bootstrap";
import DefaultLoader from "../../../../tools/DefaultLoader";
import SessionHelper from "../../../../../helper/SessionHelper";
import EssentialStyle from "../../../../../style/EssentialStyle";
import CustomTooltip from "../../../../tools/CustomTooltip";
import DefaultButton from "../../../../tools/DefaultButton";
import { toast } from "react-toastify";
import ModalCorrespondencia from "../ModalCorrespondencia/ModalCorrespondencia";
import Modal from "../../../../tools/Modal";
import moment from "moment";
import LayoutHelper from "../../../../../helper/LayoutHelper";
import { withTranslation } from 'react-i18next';


class IndicadorReferenciaCard extends React.Component {

    state = {
        periodoIndicadorReferencia: this.props.periodoIndicadorReferencia,
        loadingCheck: false,
        openConfig: false,
        editing: false,
        modalCorrespondencia: false,
        showModalConfig: false,
        isSmallScreen: LayoutHelper.isSmallScreen(),
    }

    async componentDidMount() {
        await this.props.i18n.loadNamespaces(['client_src_helper_diagnostico_DiagnosticoHelper']);

        window.addEventListener('resize', this.updateScreenSize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateScreenSize);
    }

    updateScreenSize = () => {
        if(this.state.isSmallScreen !== LayoutHelper.isSmallScreen())
            this.setState({ isSmallScreen: LayoutHelper.isSmallScreen() });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.periodoIndicadorReferencia !== this.props.periodoIndicadorReferencia) {
            this.setState({ periodoIndicadorReferencia: this.props.periodoIndicadorReferencia });
        }
    }

    async loadPeriodoIndicador(callback) {
        let periodoIndicadorReferencia = await DiagnosticoHelper.getPeriodoAvaliacaoIndicador(this.state.periodoIndicadorReferencia.id);
        this.setState({ periodoIndicadorReferencia }, () => {callback && callback()});
    }

    async deletePeriodoIndicador() {
        
        const deletePeriodoIndicador = async () => {
            await DiagnosticoHelper.deleteIndicadorPeriodo(this.state.periodoIndicadorReferencia.id);
            this.props.afterDeletePeriodoIndicador && this.props.afterDeletePeriodoIndicador();
        }

        let message = IdiomaHelper.getStruct({
            'pt_br': {
                title: 'Excluir Indicador do Período de Avaliação?',
                message: `
                    <p>Tem certeza de que deseja excluir este Indicador? Esta ação é irreversível e resultará na perda das seguintes informações:</p>
                    <ol>
                        <li>Período de Referência utilizado</li>
                        <li>Equivalência do Indicador com as Empresas avaliadas neste Período de Avaliação</li>
                        <li>Grupos e metas associadas, caso este Indicador esteja em um grupo de indicadores</li>
                        <li>Vínculos do Indicador com questões do Questionário, se houver</li>
                    </ol>
                `,
                confirm: 'Excluir',
                cancel: 'Cancelar',
            },
            'en': {
                title: 'Delete Indicator from Evaluation Period?',
                message: `
                    <p>Are you sure you want to delete this Indicator and Panel? This action is irreversible and will result in the loss of the following information:</p>
                    <ol>
                        <li>Reference Period used</li>
                        <li>Equivalence of the Indicator and Panel with the Companies evaluated in this Evaluation Period</li>
                        <li>Groups and goals associated, if this Indicator and Panel are in a group of indicators</li>
                        <li>Links of the Indicator with questions from the Questionnaire, if any</li>
                    </ol>
                `,
                confirm: 'Delete',
                cancel: 'Cancel',
            },
            'es': {
                title: '¿Eliminar Indicador del Período de Evaluación?',
                message: `
                    <p>¿Está seguro de que desea eliminar este Indicador? Esta acción es irreversible y resultará en la pérdida de la siguiente información:</p>
                    <ol>
                        <li>Período de referencia utilizado</li>
                        <li>Equivalencia del Indicador con las Empresas evaluadas en este Período de Evaluación</li>
                        <li>Grupos y metas asociadas, en caso de que este Indicador esté en un grupo de indicadores</li>
                        <li>Vínculos del Indicador con preguntas del Cuestionario, si los hay</li>
                    </ol>
                `,
                confirm: 'Eliminar',
                cancel: 'Cancelar',
            }
        });
                
        confirmAlert({
            customUI: ({ onClose }) => (
                <CustomConfirm
                    title={message[this.props.lang].title}
                    message={<p style={{ marginTop: 10, marginBottom: 10}}  dangerouslySetInnerHTML={{ __html: message[this.props.lang].message }}/>}
                    buttons={[
                        {
                            label: message[this.props.lang].confirm,
                            color: Colors.error,
                            textColor: Colors.light,
                            onClick: async () => {
                                deletePeriodoIndicador();
                                onClose();
                            }
                        },
                        {
                            label: message[this.props.lang].cancel,
                            onClick: () => { onClose(); }
                        },
                    ]}
                />
            )
        });

    }

    async unlinkIndicador() {

        if (!this.state.periodoIndicadorReferencia.hasGrupo) return;

        this.setState({ loadingCheck: true });
        await DiagnosticoHelper.deleteVinculoIndicadorPeriodo(this.state.periodoIndicadorReferencia.id, this.state.periodoIndicadorReferencia.hasGrupo.id);
        
        this.props.afterUnlinkIndicador && this.props.afterUnlinkIndicador();
        this.loadPeriodoIndicador(() => {this.setState({ loadingCheck: false })});
    }

    async linkIndicador() {

        let idPeriodoAvaliacao = this.props.periodoIndicadorReferencia.id_diagnostico_periodo;
        let idGrupo = this.props.idGrupo;
        let idIndicadorReferencia = this.props.periodoIndicadorReferencia.id;

        this.setState({ loadingCheck: true });
        await DiagnosticoHelper.saveIndicadorPeriodoGrupo(idPeriodoAvaliacao, idGrupo, idIndicadorReferencia);

        this.props.afterLinkIndicador && this.props.afterLinkIndicador();
        this.loadPeriodoIndicador(() => {this.setState({ loadingCheck: false })});
    }

    async saveIndicadorPainelConfig() {
            
        this.setState({ loading: true });

        let faixas = this.props.periodoIndicadorReferencia.faixas;
        let acumulado = this.state.periodoIndicadorReferencia.acumulado;
        let idIndicador = this.state.periodoIndicadorReferencia.id;
        let meta = this.state.periodoIndicadorReferencia.meta || null;
        faixas = JSON.stringify(faixas);
        
        let periodoAvaliacao = await DiagnosticoHelper.saveIndicadorConfig(idIndicador, acumulado, faixas, meta);
        let newSelect = periodoAvaliacao?.indicadores?.find((indicador) => indicador.id === this.state.periodoIndicadorReferencia.id ) || this.state.periodoIndicadorReferencia;

        this.setState({ 
            periodoIndicadorReferencia: newSelect,
            loading: false,
        });
    }

    async saveIndicadorPainelConfigFaixas() {
        this.setState({ loading: true });

        if (!this.verifyFaixas()) {
            this.setState({ loading: false, editing: false });
            return;
        }

        let faixas = this.state.periodoIndicadorReferencia.faixas;
        let acumulado = this.state.periodoIndicadorReferencia.acumulado;
        let idIndicador = this.state.periodoIndicadorReferencia.id;
        let meta = this.state.periodoIndicadorReferencia.meta;
        faixas = JSON.stringify(faixas);
        
        let periodoAvaliacao = await DiagnosticoHelper.saveIndicadorConfig(idIndicador, acumulado, faixas, meta);

        if (periodoAvaliacao) {
            toast.success(this.message().saved)
        }

        let newSelect = periodoAvaliacao?.indicadores?.find((indicador) => indicador.id === this.state.periodoIndicadorReferencia.id ) || this.state.periodoIndicadorReferencia;

        this.setState({ 
            periodoIndicadorReferencia: newSelect,
            loading: false,
            editing: false,
        }, () => {
            this.props.onUpdate && this.props.onUpdate(newSelect);
        });
    }

    verifyFaixas() {

        let intervalos = [];
        let response = true;

        for (let intervalo of this.state.periodoIndicadorReferencia.faixas) {

            if (!intervalo?.indicadorFaixa?.min || !intervalo?.indicadorFaixa?.max) {
                toast.error(this.message().errorFaixasFaltantes);
                return false;
            }

            intervalos.push(Number(intervalo.indicadorFaixa.min));
            intervalos.push(Number(intervalo.indicadorFaixa.max));

        }

        intervalos.sort((a, b) => a - b);
        
        // // Verificar se começa em ZERO
        // if (intervalos[0] != 0) {
        //     toast.error(this.message().errorFaixa0);
        //     return
        // }
        
        // Retira o menor e maior valor do array
        intervalos.shift();
        intervalos.pop();

        // Valida se todos elementos passuem duas repetiçoes e não sobra nenhum com menos ou mais de duas
        for (let i = 0; i < intervalos.length; i += 2) {
            if (intervalos[i] !== intervalos[i + 1]) {
                toast.error(this.message().errorFaixasSobrepostos);
                return false;
            }
        }


        return response;
    }

    message () {
        const message = IdiomaHelper.getStruct({
            'pt_br': {
                tooltipTrash: 'Excluir Indicador do Período de Avaliação',
                tooltipRemove: 'Remover Indicador',
                tooltipTrashVinculo: 'Remover vínculo com Indicador',
                tooltipUnLink: 'Desvincular Indicador do Grupo',
                tooltipCog: 'Configurações do Indicador',
                tooltipBuilding: 'Configurar Equivalência do Indicador com as Empresas Avaliadas',
                tooltipInciadorNota: `
                    <h2>Indicador de Processo</h2>
                    <p>Este indicador será usar o resultado deste item no questionário, com as seguintes condições:</p>
                    <ul>
                        <li>A performance do indicador será considerada como resultado deste item no diagnóstico</li>
                        <li>Se houver mais de um indicador de processo neste item, o resultado será calculado com base na média da performance dos indicadores</li>
                        <li>Se houver itens filhos vinculados a este item, os resultados dos filhos NÃO serão considerados para formar seu resultado</li>
                    </ul>
                `,
                tooltipInciadorRelacao: `
                    <h2>Indicador de Performance</h2>
                    <p>Este indicador <strong>NÃO</strong> será usado para gerar o resultado deste item do questionário, apenas será estabelecido um vínculo para demonstrar que existe relação entre a pergunta e este indicador.</p>
                `,
                metaTooltip: 'Insira a meta que o indicador deve atingir no período de avaliação para ser calculado a sua Performance.',
                acumulated: 'Acumulado',
                month: 'Mensal',
                meta: 'Meta',
                altern_to: 'Alternar para',
                titleFaixas: 'Faixas',
                errorFaixasFaltantes: 'Preencha todos os campos de faixas',
                errorFaixasSobrepostos: 'As faixas não podem se sobrepor ou faltar valores',
                errorFaixa0: 'A primeira faixa deve começar em 0',
                saved: "Faixas salvas com sucesso!",
                save: 'Salvar',
                unidade: 'Unidade de medida',
                interpretacao: 'Interpretação do indicador',
                infoFaixas: `Insira o valor mínimo e máximo do Realizado a ser considerado para cada faixa, observando a Unidade de medida e o Totalizador do Indicador. O sistema usará esses valores para determinar a Faixa do Indicador.`,
                close: 'Fechar',
                dias: 'dias',
                diasSmall: 'd',
                horas: 'horas',
                horasSmall: 'h',
            },
            'en': {
                tooltipTrash: 'Delete Indicator from Evaluation Period',
                tooltipRemove: 'Remove Indicator',
                tooltipTrashVinculo: 'Remove Indicator Link',
                tooltipUnLink: 'Unlink Indicator from Group',
                tooltipCog: 'Indicator Settings',
                tooltipBuilding: 'Configure Indicator Equivalence with Evaluated Companies',
                metaTooltip: 'Enter the goal that the indicator must reach in the evaluation period to calculate its Performance.',
                tooltipInciadorNota: `
                    <h2>Process Indicator</h2>
                    <p>This indicator will be used to generate the result of this item in the questionnaire, with the following conditions:</p>
                    <ul>
                        <li>The Indicator Performance will be considered as the result of this item in the questionnaire</li>
                        <li>If there is more than one Process Indicator in this item, the result will be calculated based on the average of the performance of the indicators</li>
                        <li>If there are child items linked to this item, the results of the children <strong>WILL NOT</strong> be considered to form its result</li>
                    </ul>
                `,
                tooltipInciadorRelacao: `
                    <h2>Performance Indicator</h2>
                    <p>This indicator <strong>WILL NOT</strong> be used to generate the result of this item in the questionnaire, it will only be linked to demonstrate that there is a relationship between the question and this indicator.</p>
                `,
                acumulated: 'Acumulated',
                month: 'Monthly',
                meta: 'Goal',
                altern_to: 'Altern to',
                titleFaixas: 'Ranges',
                errorFaixasFaltantes: 'Fill in all ranges fields',
                errorFaixasSobrepostos: 'Ranges cannot overlap or miss values',
                errorFaixa0: 'The first range must start at 0',
                saved: "Ranges saved successfully!",
                save: 'Save',
                unidade: 'Unit of measure',
                interpretacao: 'Indicator interpretation',
                infoFaixas: `Fill in the range fields with the minimum and maximum values that the indicator can reach. These values must represent the actual value reached, not a subjective evaluation. The system will use these values to determine the range of the indicator.`,
                close: 'Close',
                dias: 'days',
                diasSmall: 'd',
                horas: 'hours',
                horasSmall: 'h',
            },
            'es': {
                tooltipTrash: 'Eliminar Indicador del Período de Evaluación',
                tooltipRemove: 'Eliminar Indicador',
                tooltipTrashVinculo: 'Eliminar Vínculo con Indicador',
                tooltipUnLink: 'Desvincular Indicador del Grupo',
                tooltipCog: 'Configuraciones del Indicador',
                tooltipBuilding: 'Configurar la equivalencia de indicadores con empresas evaluadas',
                tooltipInciadorNota: `
                    <h2>Indicador de Proceso</h2>
                    <p>Este indicador se utilizará para generar el resultado de este elemento en el cuestionario, con las siguientes condiciones:</p>
                    <ul>
                        <li>La performance del indicador se considerará como el resultado de este elemento en el cuestionario</li>
                        <li>Si hay más de un indicador de proceso en este elemento, el resultado se calculará en función del promedio de la performance de los indicadores</li>
                        <li>Si hay elementos hijos vinculados a este elemento, los resultados de los hijos <strong>NO</strong> se considerarán para formar su resultado</li>
                    </ul>
                `,
                tooltipInciadorRelacao: ` 
                    <h2>Indicador de Performance</h2>
                    <p>Este indicador <strong>NO</strong> se utilizará para generar el resultado de este elemento en el cuestionario, solo se vinculará para demostrar que existe una relación entre la pregunta y este indicador.</p>
                `,
                metaTooltip: 'Ingrese la meta que el indicador debe alcanzar en el período de evaluación para calcular su rendimiento.',
                acumulated: 'Acumulado',
                month: 'Mensual',
                meta: 'Meta',
                altern_to: 'Alternar para',
                titleFaixas: 'Rangos',
                errorFaixasFaltantes: 'Complete todos los campos de rangos',
                errorFaixasSobrepostos: 'Los rangos no pueden superponerse o faltar valores',
                errorFaixa0: 'El primer rango debe comenzar en 0',
                saved: "Rangos guardados con éxito!",
                save: 'Salvar',
                unidade: 'Unidad de medida',
                interpretacao: 'Interpretación del indicador',
                infoFaixas: `Complete los campos de rango con los valores mínimo y máximo que el indicador puede alcanzar. Estos valores deben representar el valor real alcanzado, no una evaluación subjetiva. El sistema usará estos valores para determinar el rango del indicador.`,
                close: 'Cerrar',
                dias: 'días',
                diasSmall: 'd',
                horas: 'horas',
                horasSmall: 'h',
            }
        });

        return message[this.props.lang];
    }

    infoPerformanceIndicador() {
    
        let indicadorRef = this.state.periodoIndicadorReferencia;

        let message = IdiomaHelper.getStruct({
            'pt_br': {
                tooltip: `
                    <div>
                        <h5>Performance do Indicador</h5>
                        <p>Performance do Indicador Realizado em ${moment(indicadorRef?.dataRealizado).locale(this.props.lang).format('MM/YYYY')}:
                        <b>${indicadorRef?.performanceRealizado}</b></p>
                        <br/>
                        <ul>
                            <li><b>Meta:</b> ${indicadorRef?.meta}</li>
                            <li><b>Realizado:</b> ${indicadorRef?.realizadoRealizado}</li>
                            <li><b>Performance:</b> ${indicadorRef?.performanceRealizado}</li>
                        </ul>
                    </div>
                `
            },
            'en': {
                tooltip: `
                    <div>
                        <h5>Indicator Performance</h5>
                        <p>Indicator Performance Realized in ${moment(indicadorRef?.dataRealizado).locale(this.props.lang).format('MM/YYYY')}:
                        <b>${indicadorRef?.performanceRealizado}</b></p>
                        <br/>
                        <ul>
                            <li><b>Goal:</b> ${indicadorRef?.meta}</li>
                            <li><b>Realized:</b> ${indicadorRef?.realizadoRealizado}</li>
                            <li><b>Performance:</b> ${indicadorRef?.performanceRealizado}</li>
                        </ul>
                    </div>
                `
            },
            'es': {
                tooltip: `
                    <div>
                        <h5>Rendimiento del Indicador</h5>
                        <p>Rendimiento del Indicador Realizado en ${moment(indicadorRef?.dataRealizado).locale(this.props.lang).format('MM/YYYY')}:
                        <b>${indicadorRef?.performanceRealizado}</b></p>
                        <br/>
                        <ul>
                            <li><b>Meta:</b> ${indicadorRef?.meta}</li>
                            <li><b>Realizado:</b> ${indicadorRef?.realizadoRealizado}</li>
                            <li><b>Performance:</b> ${indicadorRef?.performanceRealizado}</li>
                        <ul>
                    <div/>
                `
            }
        });

        return <div dangerouslySetInnerHTML={{ __html: message[this.props.lang].tooltip}} />
    }

    renderItemCheckBox() {

        let indicadorRef = this.state.periodoIndicadorReferencia;
        let disabled = indicadorRef.hasGrupo?.id ? !(indicadorRef.hasGrupo?.id === this.props.idGrupo) : false;

        return (
            <div 
                className={`IndicadorReferenciaCard ${disabled ? 'disabled' : ''}`}
                onClick={() => { this.props.onClick && this.props.onClick(indicadorRef) }}
                style={{...this.props.style, cursor: this.props.onClick ? 'pointer' : 'default'}}
            >
                <div className="lineCard">
                    <div>{indicadorRef.indicador.nome}</div>

                    <div className="infos">

                        { indicadorRef.hasGrupo && !(indicadorRef.hasGrupo?.id === this.props.idGrupo) &&
                            <div className="groupName">
                                <EllipsisText text={`${indicadorRef.hasGrupo?.textos?.find(desc => desc.id_idioma === IdiomaHelper.getSiglaId(this.props.lang))?.descricao || ''}`}/>
                            </div>
                        }

                        {
                            this.state.loadingCheck ?
                                <DefaultLoader size={15} color={Colors.primary} />
                                :
                                <Form.Check 
                                    type="checkbox"
                                    checked={indicadorRef.hasGrupo ? true : false }
                                    disabled={disabled}
                                    onChange={(e) => { 
                                        if (e.target.checked) {
                                            this.linkIndicador();
                                        } else {
                                            this.unlinkIndicador();
                                        }
                                    }}
                                />
                        }
                    </div>
                </div>
                
            </div>
        )
    }

    renderItemVinculado() {

        let indicadorRef = this.state.periodoIndicadorReferencia;

        const verifyMeta = () => {
            if (indicadorRef.meta === null || indicadorRef.meta === '') {
                toast.warn(this.message().metaTooltip);
                this.setState({ showModalConfig: true, openConfig: true });
            } else {
                this.props.updateTipoVinculo(indicadorRef.id);
            }
        }

        const card = () => {
            return (
                <div className="lineCard">
                    <div style={{ ...EssentialStyle.rowFlexStart, gap: 10 }}>
                        {DiagnosticoHelper.renderStatusIndicador(indicadorRef, this.props.t, {fontSize: '2em'})}
                        {indicadorRef.indicador.nome}
                    </div>

                    <div className="infos">

                        { indicadorRef.hasGrupo && this.props.showGroupName &&
                            <div className="groupName">
                                <EllipsisText text={`${indicadorRef.hasGrupo?.textos?.find(desc => desc.id_idioma === IdiomaHelper.getSiglaId(this.props.lang))?.descricao || ''}`}/>
                            </div>
                        }

                        {this.props.deleteVinculo &&
                            <CustomTooltip tooltip={this.message().tooltipTrashVinculo}>
                                <FontAwesomeIcon
                                    icon={faTrash}
                                    className={"iconButton hoverScale"}
                                    style={{ color: Colors.error }}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        this.props.deleteVinculo(indicadorRef.id);
                                    }}
                                />
                            </CustomTooltip>
                        }

                        {this.props.updateTipoVinculo && 
                            <DefaultButton
                                tooltipPlacement={'left'} 
                                leftIcon={
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                                        
                                        <CustomTooltip maxWidth={500} tooltip={<div style={{width: 300}} dangerouslySetInnerHTML={{ __html: this.message().tooltipInciadorRelacao }}/>}>
                                            <FontAwesomeIcon icon={faLineChart} />
                                        </CustomTooltip>

                                        <Form.Switch
                                            type="switch"
                                            disabled
                                            checked={Number(indicadorRef.tipo_vinculo) === 0 || !indicadorRef.tipo_vinculo  ? false : true }
                                            style={{ marginLeft: 3, marginRight: 3 }}
                                        />
                                        
                                        <CustomTooltip maxWidth={500} tooltip={<div style={{width: 300}} dangerouslySetInnerHTML={{ __html: this.message().tooltipInciadorNota }}/>}>
                                            <FontAwesomeIcon icon={faCertificate} />
                                        </CustomTooltip>
                                        
                                    </div>
                                }
                                color={Colors.secondaryButton} 
                                textColor={Colors.dark} 
                                loading={this.state.loading} 
                                onClick={() => { this.props.updateTipoVinculo && verifyMeta() }} 
                            />
                        }

                        {this.props.resultados && 
                            <CustomTooltip tooltip={this.infoPerformanceIndicador()} style={{ maxWidth: 250 }} >
                                <div style={{ ...EssentialStyle.rowFlexCenter, fontWeight: 'bold', backgroundColor: indicadorRef?.faixa?.cor ? indicadorRef?.faixa?.cor : Colors.homePage.lightGrey, color: 'white', height: '34px', padding: '0px 10px', borderRadius: 5, gap: 10, cursor: 'default' }}>
                                    <span style={{ fontStyle: 'italic', fontSize: '0.9rem', cursor: 'default'}}>{moment(indicadorRef?.dataRealizado).locale(this.props.lang).format('MM/YYYY')}</span>
                                    <FontAwesomeIcon icon={faArrowRight} />
                                    {indicadorRef?.performanceRealizado}
                                </div>
                            </CustomTooltip>
                        }

                    </div>
                </div>
            )
        }

        const modalConfig = () => {
            return (
                <Modal 
                    style={{
                        width: '80vw',
                        minWidth: '70vw',
                        transition: 'height 0.5s ease', 
                        boxShadow: '0px 0px 20px 20px rgba(50, 50, 50, 0.5)', 
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }} 
                >
                    {this.renderItem()}
                    <div style={{...EssentialStyle.rowFlexEnd, width: '100%', marginTop: 10}}>
                        <DefaultButton 
                            title={this.message().close}
                            color={Colors.dark}
                            textColor={Colors.white}
                            onClick={() => this.setState({ showModalConfig: false, openConfig: false }) }
                        />
                    </div>
                </Modal>
            )
        }

        if (this.state.showModalConfig) return modalConfig();
        return (
            <div 
                className="IndicadorReferenciaCard"
                style={{...this.props.style}}

                draggable={this.props.draggable ? (this.state.openConfig ? false : true) : false}
                onDragStart={(e) => { this.props.onDragStartItem && this.props.onDragStartItem(e, indicadorRef.id) }}
                onDragEnter={(e) => { this.props.onDragEnterItem && this.props.onDragEnterItem(e, indicadorRef.id) }}
                onDragEnd={  (e) => { 
                    if (this.props.onDragEndItem) {
                        this.props.onDragEndItem(e, indicadorRef.id);
                        this.loadPeriodoIndicador();
                    }
                }}
                onDragOver={(e) => { e.preventDefault() }}
                onClick={() => { this.props.onClick && this.props.onClick(indicadorRef) }}
            >
                {card()}
            </div>
        )
    }

    getMoeda(moeda) {
        switch (moeda) {
            case 'BRL':
                return 'R$';
            case 'USD':
                return '$';
            case 'EUR':
                return '€';
            default:
                return 'R$';
        }
    }

    getUnitSymbol() {
        const unitType = this.state.periodoIndicadorReferencia.indicador.unidade_medida;
        switch (unitType) {
            case 'Percentual':
            case 'Percentual por Cálculo':
                return '%';
            case 'Moeda':
            case 'Moeda por Cálculo':
                return this.getMoeda(this.state.periodoIndicadorReferencia.indicador.moeda);
            case 'Quantidade':
            case 'Quantidade por Cálculo':
                return '';
            case 'Dias':
            case 'Dias por Cálculo':
                return this.state.isSmallScreen ? this.message().diasSmall : this.message().dias;
            case 'Horas':
            case 'Horas por Cálculo':
                return this.state.isSmallScreen ? this.message().horasSmall : this.message().horas;
            default:
                return '';
        }
    }

    renderItem() {

        let indicadorRef = this.state.periodoIndicadorReferencia;

        const card = () => {
            return (
                <div className="lineCard">
                    <div style={{ ...EssentialStyle.rowFlexStart, gap: 10, width: '90%' }}>
                        {DiagnosticoHelper.renderStatusIndicador(indicadorRef, this.props.t, {fontSize: '2em'})}
                        {indicadorRef.indicador.nome}
                    </div>
                    <div className="infos">

                        {/* Nome do Grupo em que o Indicador está vinculado */}
                        { indicadorRef.hasGrupo && this.props.showGroupName &&
                            <div className="groupName">
                                <EllipsisText text={`${indicadorRef.hasGrupo?.textos?.find(desc => desc.id_idioma === IdiomaHelper.getSiglaId(this.props.lang))?.descricao || ''}`}/>
                            </div>
                        }

                        {/* Desvincula o indicador do grupo */}
                        {indicadorRef.canEdit && indicadorRef.hasGrupo && !this.props.notDesvincula &&
                            <CustomTooltip tooltip={this.message().tooltipUnLink}>
                                <FontAwesomeIcon
                                    icon={faUnlink}
                                    className={"iconButton hoverScale"}
                                    style={{ color: "#d74d4e" }}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        this.unlinkIndicador()
                                    }}
                                />
                            </CustomTooltip>
                        }

                        {/* Exclui o indicador do período de avaliação */}
                        {indicadorRef.canEdit && !this.props.notDelete &&
                            <CustomTooltip tooltip={this.message().tooltipTrash}>
                                <FontAwesomeIcon
                                    icon={faTrash}
                                    className={"iconButton hoverScale"}
                                    style={{ color: "#d74d4e" }}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        this.deletePeriodoIndicador();
                                    }}
                                />
                            </CustomTooltip>
                        }

                        {/* Botão para remover o indicador via Props */}
                        {indicadorRef.canEdit && this.props.onRemove &&
                            <CustomTooltip tooltip={this.message().tooltipRemove}>
                                <FontAwesomeIcon
                                    icon={faTrash}
                                    className={"iconButton hoverScale"}
                                    style={{ color: "#d74d4e" }}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        this.props.onRemove(indicadorRef.id);
                                    }}
                                />
                            </CustomTooltip>
                        }

                        {/* Abre o modal de correspondência do indicador com as empresas filhas */}
                        {indicadorRef.canEdit && !this.props.notConfigChildren &&
                            <CustomTooltip tooltip={this.message().tooltipBuilding}>
                                <FontAwesomeIcon
                                    icon={faBuilding}
                                    className={`iconButton hoverScale ${indicadorRef.status.includes(1) ? 'alertIcon' : ''}`}
                                    style={{ color: indicadorRef.status.includes(1) ? Colors.danger : SessionHelper.getColor() }}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        this.setState({modalCorrespondencia: !this.state.modalCorrespondencia});
                                    }}
                                />
                            </CustomTooltip>
                        }

                        {/* Abre as configurações do indicador */}
                        {indicadorRef.canEdit && !this.props.notConfig &&
                            <CustomTooltip tooltip={this.message().tooltipCog}>
                                <FontAwesomeIcon
                                    icon={this.state.openConfig ? faChevronUp : faCog}
                                    className={`iconButton hoverScale ${this.state.openConfig || indicadorRef.status.includes(2) || indicadorRef.status.includes(3) ? 'alertIcon' : ''}`} 
                                    style={{ color: indicadorRef.status.includes(2) || indicadorRef.status.includes(3) ? Colors.danger : SessionHelper.getColor() }}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        this.setState({
                                            openConfig: !this.state.openConfig,
                                            editing: this.state.openConfig ? false : this.state.editing,
                                        }, () => { this.loadPeriodoIndicador(); });
                                    }}
                                />
                            </CustomTooltip>
                        }
                    </div>
                </div>
            )
        }

        const collapse = () => {
            return (
                <Collapse in={this.state.openConfig}>
                    <div style={{width: '100%', paddingTop: '1rem'}}>

                        <div className="lineCard infosIndicador">
                            <div className="infosIndicador"><span className="labelInfo">{this.message().unidade}</span><span className="value">{indicadorRef.indicador.unidade_medida}</span></div>
                            <div className="infosIndicador"><span className="labelInfo">{this.message().interpretacao}</span><span className="value">{indicadorRef.indicador.interpretacao}</span></div>
                            <DefaultButton
                                className="buttonAcumulado"
                                textColor={indicadorRef.acumulado ? Colors.white : Colors.dark}
                                color={indicadorRef.acumulado ? Colors.success : Colors.homePage.lightGrey}
                                title={<b>{!indicadorRef.acumulado ? this.message().month : this.message().acumulated}</b>}
                                tooltip={`${this.message().altern_to} ${indicadorRef.acumulado ? this.message().month : this.message().acumulated}`}
                                rightIcon={<FontAwesomeIcon icon={faExchangeAlt}/>}
                                onClick={() => { 
                                    this.setState({ 
                                        periodoIndicadorReferencia: {
                                            ...this.state.periodoIndicadorReferencia, 
                                            acumulado: !indicadorRef.acumulado
                                        } 
                                    }, () => { this.saveIndicadorPainelConfig() } ) 
                                }}
                                style={{
                                    ...EssentialStyle.rowFlexCenter,
                                    width: this.state.isSmallScreen ? 120 : 150,
                                    height: 30,
                                    fontSize: '1em',
                                }}
                            />
                        
                        </div>

                        <div className="lineCard meta">
                            <div className="meta">
                                <div style={{ ...EssentialStyle.rowFlexStart, gap: 10 }}>
                                    {this.message().meta}
                                    <CustomTooltip maxWidth={400} tooltip={this.message().metaTooltip}>
                                        <FontAwesomeIcon icon={faInfoCircle}/>
                                    </CustomTooltip>
                                </div>
                                <div className="input-with-unit">
                                    
                                <Form.Control
                                    style={{ height: '28px', padding: '0px 5px' }}
                                    pattern={"/^[-]?[0-9]*([.,][0-9]+)?$/"}
                                    type={"number"}
                                    className="form-control-xsm"
                                    value={indicadorRef?.meta || ""}
                                    onChange={(event) => { 
                                        let value = event.target.value;
                                        if (/^[-]?[0-9]*([.,][0-9]+)?$/.test(value)) {
                                            
                                            
                                            this.setState({ 
                                                periodoIndicadorReferencia: {
                                                    ...this.state.periodoIndicadorReferencia,
                                                    meta: value,
                                                },
                                                editing: true,
                                            });
                                        }
                                    }} 
                                    />
                                    <div className="unit-indicator">{this.getUnitSymbol()}</div>
                                </div>
                            </div>
                        </div>

                        <div className="lineCard configFaixas">
                            <div style={{ ...EssentialStyle.rowSpaceBetween, width: '100%' }}>
                                <div style={{ ...EssentialStyle.rowFlexStart, gap: 10 }}>
                                    {this.message().titleFaixas}
                                    <CustomTooltip maxWidth={400} tooltip={this.message().infoFaixas}>
                                        <FontAwesomeIcon icon={faInfoCircle}/>
                                    </CustomTooltip>
                                </div>
                            </div>

                            {indicadorRef.faixas.map((faixa, index) => {
                                const updateMinBasedInPreviousMax = (value) => {
                                    let faixas = [...this.state.periodoIndicadorReferencia.faixas];

                                    if ((index + 1) < faixas.length && !faixas[index + 1].indicadorFaixa.min) {
                                        faixas[index + 1].indicadorFaixa.min = faixa?.indicadorFaixa?.max || "";
                                        this.setState({
                                            periodoIndicadorReferencia: {
                                                ...this.state.periodoIndicadorReferencia,
                                                faixas,
                                            },
                                            editing: true,
                                        });
                                    }   
                                }

                                const updateMaxBasedInNextMin = (value) => {
                                    let faixas = [...this.state.periodoIndicadorReferencia.faixas];

                                    if ((index - 1) >= 0 && !faixas[index - 1].indicadorFaixa.max) {
                                        faixas[index - 1].indicadorFaixa.max = faixa?.indicadorFaixa?.min || "";
                                        this.setState({
                                            periodoIndicadorReferencia: {
                                                ...this.state.periodoIndicadorReferencia,
                                                faixas,
                                            },
                                            editing: true,
                                        });
                                    }   
                                }

                                return (
                                    <div key={`faixa-ind-${index}`} className="configFaixa">
                                        <div className="colorName" style={{ backgroundColor: faixa.cor }}>
                                            <EllipsisText text={faixa?.textos?.find((texto) => texto.id_idioma == IdiomaHelper.getSiglaId(this.props.lang))?.texto || '--'}/>
                                        </div>

                                        {this.state.openConfig && <div className="valores">
                                            
                                            <div className="input-with-unit">
                                                <Form.Control
                                                    style={{ height: '28px', padding: '0px 5px' }}
                                                    pattern={"/^[-]?[0-9]*([.,][0-9]+)?$/"}
                                                    step="0.10"
                                                    type={"number"}
                                                    className="form-control-xsm"
                                                    value={faixa?.indicadorFaixa?.min || ""}
                                                    onBlur={updateMaxBasedInNextMin}
                                                    onKeyDown={(e) => { if (e.key === 'Enter') updateMaxBasedInNextMin() }}
                                                    onChange={(event) => { 
                                                        let value = event.target.value;
                                                        if (/^[-]?[0-9]*([.,][0-9]+)?$/.test(value)) {
                                                            let faixas = [...this.state.periodoIndicadorReferencia.faixas];

                                                            if (faixas[index].indicadorFaixa){
                                                                faixas[index].indicadorFaixa.min = value;
                                                            } else {
                                                                faixas[index].indicadorFaixa = { min: value, max: faixa?.indicadorFaixa?.max || "" };
                                                            }

                                                            this.setState({ 
                                                                periodoIndicadorReferencia: {
                                                                    ...this.state.periodoIndicadorReferencia,
                                                                    faixas,
                                                                },
                                                                editing: true,
                                                            });
                                                        }
                                                    }}
                                                />
                                                <div className="unit-indicator">{this.getUnitSymbol()}</div>
                                            </div>

                                            <span style={{ ...EssentialStyle.rowFlexCenter, minWidth: 35}}>← →</span>
                                            
                                            <div className="input-with-unit">
                                                <Form.Control
                                                    style={{ height: '28px', padding: '0px 5px' }}
                                                    pattern={"/^[-]?[0-9]*([.,][0-9]+)?$/"}
                                                    step="0.10"
                                                    type={"number"}
                                                    className="form-control-xsm"
                                                    value={faixa?.indicadorFaixa?.max || ""}
                                                    onBlur={updateMinBasedInPreviousMax}
                                                    onKeyDown={(e) => { if (e.key === 'Enter') updateMinBasedInPreviousMax() }}
                                                    onChange={(event) => { 
                                                        let value = event.target.value;

                                                        // Regex to allow only numbers, dots, and commas
                                                        if (/^[-]?[0-9]*([.,][0-9]+)?$/.test(value)) {
                                                            let faixas = [...this.state.periodoIndicadorReferencia.faixas];
                                                            
                                                            if (faixas[index].indicadorFaixa){
                                                                faixas[index].indicadorFaixa.max = event.target.value;
                                                            } else {
                                                                faixas[index].indicadorFaixa = { max: event.target.value, min: faixa?.indicadorFaixa?.min || "" };
                                                            }
                                                            
                                                            this.setState({ 
                                                                periodoIndicadorReferencia: {
                                                                    ...this.state.periodoIndicadorReferencia,
                                                                    faixas,
                                                                },
                                                                editing: true,
                                                            });
                                                        }
                                                    }}
                                                />
                                                <div className="unit-indicator">{this.getUnitSymbol()}</div>
                                            </div>
                                        </div>}
                                    </div>
                                )
                            })}
                        </div>

                        <div className="lineCard save">
                            <DefaultButton
                                leftIcon={<FontAwesomeIcon icon={faSave} />}
                                loading={this.state.loading}
                                title={this.message().save}
                                disabled={!this.state.editing}
                                color={Colors.success}
                                onClick={() => { this.saveIndicadorPainelConfigFaixas() }}
                            />
                        </div>

                    </div>
                </Collapse>
            )
        }

        const modalCorrespondencia = () => {
            return (
                this.state.modalCorrespondencia &&
                    <ModalCorrespondencia
                        select={this.state.periodoIndicadorReferencia}
                        lang={this.props.lang}
                        closeModal={() => 
                            this.setState({modalCorrespondencia: false}, () => {
                                this.loadPeriodoIndicador(
                                    () => {
                                        this.props.onUpdate && this.props.onUpdate(this.state.periodoIndicadorReferencia);
                                    }
                                );
                            }) 
                        }
                    />
            )
        }

        return (
            <div 
                className="IndicadorReferenciaCard"
                style={{
                    ...this.props.style,
                    cursor: this.props.onDragStartItem ? 'move' : 'default',
                }}
                draggable={this.props.draggable ? (this.state.openConfig ? false : true) : false}
                onDragStart={(e) => { this.props.onDragStartItem && this.props.onDragStartItem(e, indicadorRef.id) }}
                onDragEnter={(e) => { this.props.onDragEnterItem && this.props.onDragEnterItem(e, indicadorRef.id) }}
                onDragEnd={  (e) => { 
                    if (this.props.onDragEndItem) {
                        this.props.onDragEndItem(e, indicadorRef.id);
                        this.loadPeriodoIndicador();
                    }
                }}
                onDragOver={(e) => { e.preventDefault() }}
                onClick={() => { this.props.onClick && this.props.onClick(indicadorRef) }}
            >
                {card()}
                {collapse()}
                {modalCorrespondencia()}
            </div>
        )
    }

    render() {
        if (this.props.isCheckBox) return this.renderItemCheckBox();
        if (this.props.isVinculoNode) return this.renderItemVinculado();
        return this.renderItem();
    }
}

export default withTranslation()(IndicadorReferenciaCard);
