import React from "react";
import Sig from "../../api/Sig";
import LoadingPage from "../../pages/LoadingPage";
import SessionHelper from "../../helper/SessionHelper";
import LayoutHelper from "../../helper/LayoutHelper";
import EssentialStyle from "../../style/EssentialStyle";
import Colors from "../../constants/Colors";
import DefaultButton from "../../components/tools/DefaultButton";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDownUpAcrossLine } from '@fortawesome/free-solid-svg-icons';
import { confirmAlert } from 'react-confirm-alert';
import CustomConfirm from "../../components/tools/CustomConfirm";
import { toast } from 'react-toastify';
import AtaReuniaoTopicoList from "../../components/modules/ataReuniao/AtaReuniaoTopicoList";
import DefaultTableSearch from "../../components/tools/DefaultTableSearch";
import AtaHistory from "../../components/modules/ataReuniao/AtaHistory/AtaHistory";
import AtaReuniaoTopicoTarefaModal from "../../components/modules/ataReuniao/AtaReuniaoTopico/AtaReuniaoTopicoComponents/AtaReuniaoTopicoTarefaModal";
import AtaReuniaoAnexosModal from "../../components/modules/ataReuniao/AtaReuniaoAnexos/AtaReuniaoAnexosModal";
import AtaReuniaoTopicoModalParticipantes from "../../components/modules/ataReuniao/AtaReuniaoTopico/AtaReuniaoTopicoComponents/AtaReuniaoTopicoModalParticipantes";
import AtaReuniaoConvocacaoModal from "../../components/modules/ataReuniao/AtaReuniaoConvocacao/AtaReuniaoConvocacaoModal";
import AtaReuniaoConvocacaoTextoImpressao from "../../components/modules/ataReuniao/AtaReuniaoConvocacao/AtaReuniaoConvocacaoTextoImpressao";
import AtaReuniaoFinalizacaoModal from "../../components/modules/ataReuniao/AtaReuniaoFinalizacao/AtaReuniaoFinalizacaoModal";
import AtaReuniaoHeader from "../../components/modules/ataReuniao/AtaReuniaoHeader/AtaReuniaoHeader";
import AddAtaReuniao from "../../components/forms/ataReuniao/add/AddAtaReuniao";

export default class AtaReuniaoExternaDetalharPage extends React.Component {
    constructor(props) {
        super(props);
        this.state={
            id: null,
            hash: null,
            showDetailsModal: null,
            showConvocacaoModal: false,
            ata: {},
            isSmallScreen: LayoutHelper.isSmallScreen(),
            canEdit: false,
            headerHeight: 0,
            mainHeaderHeight: 0,
            enableEditOrder: false,
            canEditDragIndex: false,
            loading: true,
            searchText: null,
            showHistorico: false,
            showAnexosModal: false,
            modalParticipantesTopico: null,
            canEditParticipantesModal: false,
            showConvocacaoModal: false,
            showFinalizacaoModal: false,
            expandConvocacaoBar: false,
            convocacao_texto_impressao: null,
            convocacaoBarHeight: 0,
            showWizard: false,
            editId: null,
            scrolled: false,
            firstVisibleItem: null
        }
        this.mainHeaderRef = React.createRef();
        this.utilityRef = React.createRef();
        this.convocacaoRef = React.createRef();
        this.resizeObserver = new ResizeObserver(this.setConvocacaoBarHeight);
        this.mainHeaderObserver = new ResizeObserver(this.setMainHeaderHeight);
        this.interval = null;
        this.intervalObserver = null;
        this.intervalConvocacao = null;
    }

    async componentDidMount() {
        this.handleUrlParams();
        window.addEventListener('resize', this.verifyScreen);
        window.addEventListener('scroll', this.handleScroll);
        this.setMainHeaderHeight(false);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.verifyScreen);
        window.removeEventListener('scroll', this.handleScroll);
        if(this.resizeObserver) this.resizeObserver.disconnect();
        if(this.mainHeaderObserver) this.mainHeaderObserver.disconnect();
    }

    handleScroll = () => {
        if (document.documentElement.scrollHeight > document.documentElement.clientHeight + 200 && document.documentElement.scrollTop > 0 && !this.state.scrolled) {
            this.setState({ scrolled: true });
            
        } else if(this.state.scrolled && document.documentElement.scrollTop <= 0) {
            this.setState({ scrolled: false });
        }

        this.checkFirstVisibleItem();
    }

    checkFirstVisibleItem = () => {
        const items = document.documentElement.querySelectorAll('.ata-topico-item');
        let firstVisibleItem = null;
    
        for (let x = 0; x < items.length - 1; x++) {
            const item = items[x];
            const rect = item.getBoundingClientRect();
            if (50 >= rect.top && 70 < rect.bottom) {
                firstVisibleItem = { 'item': item, 'index': x+1 };
                break;
            }
        }
    
        if (firstVisibleItem) {
            this.setState({ firstVisibleItem });
        }
    }

    setMainHeaderHeight = (observer = true) => {
        this.interval = setInterval(() => {
            if (this.mainHeaderRef.current && this.utilityRef.current) {
                if(this.mainHeaderObserver && !observer) this.mainHeaderObserver.observe(this.mainHeaderRef.current, { box: 'border-box' });
                if(this.interval) clearInterval(this.interval);
                
                if((this.mainHeaderRef.current.clientHeight - 1) + (this.utilityRef.current.clientHeight - 1) != this.state.headerHeight)
                    this.setState({ headerHeight: (this.mainHeaderRef.current.clientHeight - 1) + (this.utilityRef.current.clientHeight - 1), mainHeaderHeight: (this.mainHeaderRef.current.clientHeight - 1) });
            }
        }, 100);
    }

    setConvocacaoBarHeight = () => {
        this.intervalConvocacao = setInterval(() => {
            if (this.convocacaoRef.current) {
                if(this.intervalConvocacao) clearInterval(this.intervalConvocacao);
                this.setState({ convocacaoBarHeight: this.convocacaoRef.current.clientHeight });
            } else if(!(this.state.ata.convocacaoEnviada && (this.state.ata.tipo == 0 || this.state.ata.tipo == 5))){
                if(this.intervalConvocacao) clearInterval(this.intervalConvocacao);
                this.setState({ convocacaoBarHeight: 0 });
            }
        }, 100);
    }

    verifyScreen = () => {
        this.setState({ isSmallScreen: LayoutHelper.isSmallScreen() }, () => {
            this.setMainHeaderHeight();
            if(this.state.ata.convocacaoEnviada && (this.state.ata.tipo == 0 || this.state.ata.tipo == 5)){
                this.setConvocacaoBarHeight();
            } else {
                this.setState({ convocacaoBarHeight: 0 });
            }
        });
    }

    handleUrlParams = () => {
        window.parent.postMessage({ type: 'get_url' }, '*');

        const listenerFunction = async (event) => {
            if (event.data.type === 'return_url') {
                const urlObj = new URL(event.data.url);
                const queryParams = new URLSearchParams(urlObj.search);
                window.removeEventListener('message', listenerFunction);

                // const tipo = queryParams.get('tipo');

                // Extract the last segment from the pathname
                const pathSegments = urlObj.pathname.split('/');
                const lastSegment = pathSegments[pathSegments.length - 1];

                const hash = queryParams.get('hash');
                const id = queryParams.get('id');
                const tipo = queryParams.get('tipo');

                if(lastSegment == 'detalhar' && tipo && id && hash){
                    this.setState({ id, hash, showWizard: true, tipo, editId: id });
                } else {
                    this.setState({ id: lastSegment, hash }, () => {
                        this.loadData(lastSegment, hash);
                    });
                }
            }
        };

        window.addEventListener('message', listenerFunction);
    }

    loadData = async (id, hash, quiet = false) => {
        if(!quiet)
            this.setState({ loading: true });

        let { ata, canEdit, canEditDragIndex } = await Sig.request('GET', 'ataReuniaoExterna/getAtaReuniao', { id, hash });

        this.setState({ ata, canEdit: canEdit ? canEdit : false, loading: false, canEditDragIndex }, () => {
            setTimeout(this.setConvocacaoBarHeight, 100);
            try {
                if(this.resizeObserver) this.resizeObserver.unobserve(this.convocacaoRef.current);
            } catch(e) {}
            this.intervalObserver = setInterval(() => {
                if(this.convocacaoRef.current){
                    if(this.intervalObserver) clearInterval(this.intervalObserver);
                    this.resizeObserver.observe(this.convocacaoRef.current);
                }
            }, 100);
                
        });
    }

    deleteAtaReuniao = async () => {
        confirmAlert({
            customUI: ({ onClose }) => (
                <CustomConfirm
                    title={`Excluir esta Ata de Reunião?`}
                    message={<p style={{ marginTop: 10, marginBottom: 10 }}>Essa ação não pode ser desfeita</p>}
                    buttons={[
                        {
                            label: 'Remover',
                            color: SessionHelper.getColor(),
                            textColor: Colors.light,
                            onClick: async () => {
                                let deletePlanoAcao = await Sig.request('POST', 'ataReuniaoExterna/deleteAtaReuniao', { id: this.state.id, hash: this.state.hash });

                                if (deletePlanoAcao.status == 200) {
                                    toast.success("Ata de reunião excluída com sucesso");
                                    window.parent.postMessage({ type: 'navigate', url: `/ataReuniao` }, '*');
                                } else {
                                    toast.error("Erro ao excluir Ata de Reunião");
                                    this.setState({ loading: false });
                                }

                                onClose();
                            }
                        },
                        {
                            label: 'Cancelar',
                            onClick: () => { onClose(); }
                        },
                    ]}
                />
            )
        });

    }

    addTopico = async () => {

        let { topico } = await Sig.request('POST', 'ataReuniaoExterna/addTopico', {idAtaReuniao: this.state.ata.id, hash: this.state.hash} );
        if(topico){
            let ata = {...this.state.ata};
            ata.topicos.push(topico);
            this.setState({ ata });
        }
    }

    updateTopicos = (topicos = null) => {
        if(!topicos) 
            this.loadData(this.state.id);
        else
            this.setState({ ata: {...this.state.ata, "topicos": topicos} });
    }

    handleSearch = async (search) => {
        this.setState({ searchText: search, enableEditOrder: search ? false : this.state.enableEditOrder });
    }

    detailsModalTarefaCallback = (idTarefaTopico) => {
        this.setState({ showDetailsModal: idTarefaTopico, enableEditOrder: false, modalParticipantesTopico: null, canEditParticipantesModal: false, showAnexosModal: false, showConvocacaoModal: false, showFinalizacaoModal: false });
    }

    openModalParticipantesTopico = (id, canEdit) => {
        this.setState({ modalParticipantesTopico: id, canEditParticipantesModal: canEdit, showDetailsModal: null, showAnexosModal: false, enableEditOrder: false, showConvocacaoModal: false, showFinalizacaoModal: false });
    }

    openConvocacaoModal = () => {
        this.setState({ showConvocacaoModal: true, showDetailsModal: null, enableEditOrder: false, modalParticipantesTopico: null, canEditParticipantesModal: false, showAnexosModal: false, showFinalizacaoModal: false });
    }

    openFinalizacaoModal = () => {
        this.setState({ showFinalizacaoModal: true, showDetailsModal: null, enableEditOrder: false, modalParticipantesTopico: null, canEditParticipantesModal: false, showAnexosModal: false, showConvocacaoModal: false });
    }

    updateTarefaCallback = (idTarefaTopico, tarefa, deleteTarefa = false) => {
        let ata = {...this.state.ata};
        let topico = ata.topicos.find(topico => topico.id == idTarefaTopico);
        if(topico){
            let tarefaIndex = topico.tarefas.findIndex(t => t.id == tarefa.id);
            if(tarefaIndex >= 0){
                if(deleteTarefa)
                    topico.tarefas.splice(tarefaIndex, 1);
                else
                    topico.tarefas[tarefaIndex] = tarefa;
                this.setState({ ata });
            }
        }
    }

    updateAnexo = (anexo, deleteAnexo = false, idTopico = null, idTopicoTarefa = null) => {
        let ata = {...this.state.ata};
        if(idTopico){
            let topico = ata.topicos.find(topico => topico.id == idTopico);
            if(topico){
                let anexoIndex = topico.anexos.findIndex(a => a.url == anexo.url);
                if(idTopicoTarefa){
                    let tarefa = topico.tarefas.find(tarefa => tarefa.id == idTopicoTarefa);
                    if(tarefa){
                        let anexoIndex = tarefa.anexos.findIndex(a => a.url == anexo.url);
                        if(anexoIndex >= 0){
                            if(deleteAnexo)
                                tarefa.anexos.splice(anexoIndex, 1);
                            else
                                tarefa.anexos[anexoIndex] = anexo;
                            this.setState({ ata });
                        } else {
                            tarefa.anexos.push(anexo);
                            this.setState({ ata });
                        }
                    }
                } else {
                    if(anexoIndex >= 0){
                        if(deleteAnexo)
                            topico.anexos.splice(anexoIndex, 1);
                        else
                            topico.anexos[anexoIndex] = anexo;
                        this.setState({ ata });
                    } else {
                        topico.anexos.push(anexo);
                        this.setState({ ata });
                    }
                }
            }
        } else {
            let anexoIndex = ata.anexos.findIndex(a => a.url == anexo.url);
            if(anexoIndex >= 0){
                if(deleteAnexo)
                    ata.anexos.splice(anexoIndex, 1);
                else
                    ata.anexos[anexoIndex] = anexo;
                this.setState({ ata });
            } else {
                ata.anexos.push(anexo);
                this.setState({ ata });
            }
        }
    }

    updateParticipantesConvocados = (participantes) => {
        let ata = {...this.state.ata};
        ata.participantes.forEach(p => {
            var participante = participantes.find(p2 => p2.email == p.email);
            if(participante)
                p.convocado = participante.convocado;
        });
        this.setState({ ata });
    }

    changeDownloadSemWatermark = () => {
        var ata = {...this.state.ata};
        ata.download_sem_watermark = !ata.download_sem_watermark;
        this.setState({ ata });
    }

    changeAtaStatus = async (participantes = null, participantesANotificar = null, participantesAssinatura = null) => {
        if(!this.state.ata.finalizado){
            let {status, ataReuniao } = await Sig.request('POST', 'ataReuniaoExterna/finalizarAta', { 
                id: this.state.ata.id,
                hash: this.state.hash,
                participantes: JSON.stringify(participantes.map(p => p.value)) ?? null, 
                participantesANotificar: JSON.stringify(participantesANotificar.map(p => p.value)) ?? null,
                participantesAssinatura: JSON.stringify(participantesAssinatura.map(p => p.value)) ?? null
            });
            if(status == 200 && ataReuniao){
                toast.success("Ata de Reunião finalizada");
                this.setState({ ata: { ...this.state.ata, finalizado: ataReuniao.finalizado, participantes: ataReuniao.participantes }});
            } else {
                toast.error("Erro ao finalizar Ata de Reunião");
            }
        } else {
            let {status, ataReuniao } = await Sig.request('POST', 'ataReuniaoExterna/reabrirAta', { id: this.state.ata.id, hash: this.state.hash });
            if(status == 200 && ataReuniao){
                toast.success("Ata de Reunião reaberta");
                this.setState({ ata: { ...this.state.ata, finalizado: ataReuniao.finalizado }});
            } else {
                toast.error("Erro ao reabrir Ata de Reunião");
            }
        }
    }

    imprimirAta = async () => {
        const toastId = toast.loading("Efetuando Download...");
        try{
            const req = await Sig.request("GET", `ataReuniaoExterna/exportarAta`, { id: this.state.ata.id, hash: this.state.hash }, 'application/x-www-form-urlencoded; charset=UTF-8', 'blob');
            if(req.status && req.status == 200) {
                var url = window.URL.createObjectURL(new Blob([req]));
                var link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `Ata de Reunião - ${this.state.ata.id}.pdf`);
                document.body.appendChild(link);
                link.click();
                toast.update(toastId, { render: "Download Concluído!", type: "success", isLoading: false, autoClose: 5000 });
            } else{
                toast.update(toastId, { render: "Erro no Download!", type: "error", isLoading: false, autoClose: 5000 });
            }
        } catch(e) {
            toast.update(toastId, { render: "Erro no Download!", type: "error", isLoading: false, autoClose: 5000 });
        }
    }

    updateImpressaoSemWatermark = async () => {
        var req = await Sig.request("POST", "ataReuniaoExterna/updateAtaImpressaoSemWatermark", { id_relation: this.state.ata?.id, hash: this.state.hash, impressao_sem_watermark: this.state.ata?.impressao_sem_watermark ? 0 : 1 });
            
        if(req.status === 200)
            this.setState({ ata: {...this.state.ata, impressao_sem_watermark: !this.state.ata?.impressao_sem_watermark} });
    }

    renderTopicosList = () => {
        var filteredTopicos = this.state.ata?.topicos || [];
        if(this.state.searchText)
            filteredTopicos = filteredTopicos.filter(topico => topico.nome && topico.nome.toLowerCase().includes(this.state.searchText.toLowerCase())) || [];
        return (
            <AtaReuniaoTopicoList 
                enableEditOrder={this.state.enableEditOrder} 
                isSmallScreen={this.state.isSmallScreen} 
                topicos={filteredTopicos || []}
                idAtaReuniao={this.state.ata.id}
                addTopicoCallback={this.addTopico} 
                headerHeight={this.state.headerHeight+this.state.convocacaoBarHeight} 
                updateTopicosCallback={this.updateTopicos} 
                participantes={this.state.ata?.participantes || []}
                detailsModalTarefaCallback={this.detailsModalTarefaCallback}
                updateAnexoCallback={this.updateAnexo}
                finalizado={this.state.ata.finalizado}
                canEdit={this.state.canEdit}
                openModalParticipantesTopicoCallback={this.openModalParticipantesTopico}
                hash={this.state.hash}
            />
        );
    }

    renderUtilityBar() {
        let style = { width: '100%' };
        let sectionStyle = {};

        if (this.state.isSmallScreen) {
            style = { ...style, ...EssentialStyle.columnCenter };
            sectionStyle = { ...sectionStyle, ...EssentialStyle.columnStart, width: '100%' };
        } else {
            style = { ...style, ...EssentialStyle.rowSpaceBetween };
            sectionStyle = { ...sectionStyle, ...EssentialStyle.rowFlexStart, maxWidth: '50%' };
        }

        return (
            <div
                className="pa-header-component"
                ref={this.utilityRef}
                style={{
                    ...EssentialStyle.columnCenter,
                    width: '100%',
                    paddingTop: 15,
                    paddingBottom: 6,
                    zIndex: 1,
                    position: 'sticky',
                    top: this.state.isSmallScreen ? "auto" : this.state.mainHeaderHeight + 1,
                    backgroundColor: SessionHelper.getBackgroundColor()
                }}>
                <div style={style}>
                    <div style={{ ...sectionStyle }} >
                        <DefaultTableSearch handleSearch={this.handleSearch} onEmpty={() => { this.handleSearch(null) }} hideExpand={true} width={this.state.isSmallScreen ? '100%' : 250} />
                        {this.state.scrolled && this.state.firstVisibleItem &&
                            <span style={{ fontSize: 18, fontWeight: 500, color: Colors.homePage.darkGrey, marginTop: 5, marginBottom: 5 }}>
                                {
                                    this.state.firstVisibleItem?.item?.querySelector('.ata-topico-item-title .ql-editor')?.innerText && 
                                    this.state.firstVisibleItem?.item?.querySelector('.ata-topico-item-title .ql-editor')?.innerText != '\n' &&
                                    this.state.firstVisibleItem?.item?.querySelector('.ata-topico-item-title .ql-editor')?.innerText != ''  ?
                                        this.state.firstVisibleItem?.index + " - " + this.state.firstVisibleItem?.item?.querySelector('.ata-topico-item-title .ql-editor')?.innerText 
                                        : 
                                        this.state.firstVisibleItem?.index + " - " + 'Tópico sem Nome'
                                }
                            </span>
                        }
                    </div>
                    <DefaultButton
                        title={this.state.enableEditOrder ? 'Concluir' : ''}
                        tooltip={this.state.enableEditOrder ? '': 'Editar ordem dos tópicos'}
                        tooltipPlacement={'bottom'}
                        leftIcon={this.state.enableEditOrder ? null : <FontAwesomeIcon icon={faArrowDownUpAcrossLine} />}
                        color={this.state.enableEditOrder ? Colors.success : Colors.secondaryButton}
                        textColor={this.state.enableEditOrder ? Colors.light : Colors.dark}
                        loading={this.state.loading}
                        disabled={this.state.searchText ? true : false}
                        onClick={() => { this.setState({ enableEditOrder: !this.state.enableEditOrder }) }}
                        style={{ display: this.state.canEditDragIndex && !this.state.ata.finalizado ? '' : 'none', alignSelf: 'flex-end', transition: 'all 0.15s ease-in-out' }}
                    />
                </div>
            </div>
        );
    }

    renderConvocacaoContent() {
        if (!this.state.ata.convocacaoEnviada || (this.state.ata.tipo != 0 && this.state.ata.tipo != 5) || !this.state.canEditDragIndex) return null;

        return (
            <AtaReuniaoConvocacaoTextoImpressao
                ref={this.convocacaoRef}
                canEdit={this.state.canEditDragIndex && !this.state.ata.finalizado}
                isSmallScreen={this.state.isSmallScreen}
                convocacao_texto_impressao={this.state.ata.convocacao_texto_impressao}
                updateConvocacaoTextoImpressaoCallback={(text) => this.setState({ ata: { ...this.state.ata, convocacao_texto_impressao: text } })}
                idAta={this.state.ata.id}
                hash={this.state.hash}
            />
        );
    }

    renderDetailsModalTarefaTopico() {

        if (!this.state.showDetailsModal) return null;

        return (
            <AtaReuniaoTopicoTarefaModal
                idTarefa={this.state.showDetailsModal}
                isSmallScreen={this.state.isSmallScreen}
                detailsModalCallback={this.detailsModalTarefaCallback}
                updateTarefaCallback={this.updateTarefaCallback}
                hash={this.state.hash}
            />
        )
    }

    renderParticipantesModalTopico() {
        if(!this.state.modalParticipantesTopico) return null;
        return (
            <AtaReuniaoTopicoModalParticipantes 
                idTopico={this.state.modalParticipantesTopico} 
                closeCallback={() => this.setState({ modalParticipantesTopico: null, canEditParticipantesModal: false })}
                updateTopicosCallback={this.updateTopicos}
                canEdit={this.state.canEditParticipantesModal && !this.state.ata.finalizado}
                hash={this.state.hash}
            />
        )
    }

    renderConvocacaoModal() {
        if(!this.state.showConvocacaoModal) return null;
        return (
            <AtaReuniaoConvocacaoModal 
                idAtaReuniao={this.state.ata.id}
                convocacao={this.state.ata.convocacao}
                participantes={this.state.ata.participantes}
                isSmallScreen={this.state.isSmallScreen}
                convocacaoEnviada={this.state.ata.convocacaoEnviada}
                closeCallback={() => this.setState({ showConvocacaoModal: false })}
                updateParticipantesConvocadosCallback={this.updateParticipantesConvocados}
                updateConvocacaoEnviadaCallback={() => this.setState({ ata: { ...this.state.ata, convocacaoEnviada: true } })}
                hash={this.state.hash}
            />
        )
    }

    renderFinalizacaoModal() {
        if(!this.state.showFinalizacaoModal) return null;
        return (
            <AtaReuniaoFinalizacaoModal
                isSmallScreen={this.state.isSmallScreen}
                participantes={this.state.ata.participantes}
                closeCallback={() => this.setState({ showFinalizacaoModal: false })}
                changeAtaStatusCallback={this.changeAtaStatus}
                hash={this.state.hash}
            />
        );
    }

    renderModalAnexosAta() {
        if(!this.state.showAnexosModal) return null;

        return (
            <AtaReuniaoAnexosModal 
                id={this.state.ata.id}
                anexos={this.state.ata.anexos} 
                topicos={this.state.ata.topicos}
                nomeAta={this.state.ata.assunto}
                closeModalAnexosCallback={() => this.setState({ showAnexosModal: false })} 
                updateAnexoCallback={this.updateAnexo}
                canEdit={this.state.canEdit}
                changeDownloadSemWatermarkCallback={this.changeDownloadSemWatermark}
                downloadSemWatermark={this.state.ata.download_sem_watermark}
                finalizado={this.state.ata.finalizado}
                hash={this.state.hash}
            />
        );
    }

    renderHeader() {
        return (
            <AtaReuniaoHeader 
                ref={this.mainHeaderRef}
                imprimirAtaCallback={this.imprimirAta} 
                openHistoricoModal={() => this.setState({ showHistorico: true })} 
                openAnexosModal={() => this.setState({ showAnexosModal: true })}
                deleteAtaReuniaoCallback={this.deleteAtaReuniao}
                openConvocacaoModal={this.openConvocacaoModal}
                openFinalizacaoModal={this.openFinalizacaoModal}
                changeAtaStatusCallback={this.changeAtaStatus}
                updateImpressaoSemWatermarkCallback={this.updateImpressaoSemWatermark}
                ata={this.state.ata}
                isSmallScreen={this.state.isSmallScreen}
                canEdit={this.state.canEdit}
                canEditDragIndex={this.state.canEditDragIndex}
                loading={this.state.loading}
                hash={this.state.hash}
                scrolled={this.state.scrolled}
            />
        )
    }

    renderPage = () => {
        return (
            <div style={{ ...EssentialStyle.columnCenter, width: '100%', position: "relative", marginBottom: this.state.isSmallScreen ? '6rem' : 0 }}>
                {this.renderHeader()}
                <span style={{ minHeight: this.state.isSmallScreen ? 0 : (this.state.mainHeaderHeight), width: "100%" }}></span>
                {this.renderConvocacaoContent()}
                {this.renderUtilityBar()}
                {this.renderTopicosList()}
                {this.renderDetailsModalTarefaTopico()}
                {this.renderModalAnexosAta()}
                {this.renderParticipantesModalTopico()}
                {this.renderConvocacaoModal()}
                {this.renderFinalizacaoModal()}
            </div>
        );
    }

    render() {
        if(this.state.showWizard) return <AddAtaReuniao />;

        if (this.state.showHistorico) return <AtaHistory id={this.state.ata.id} onClose={() => { this.setState({ showHistorico: false }) }} hash={this.state.hash}/>;
        return this.state.loading ? <LoadingPage /> : this.renderPage();
    }
}