import React from "react";
import ReactDOM from 'react-dom';

import DiagnosticoHelper from "../../../../../helper/diagnostico/DiagnosticoHelper";
import DefaultLoader from '../../../../tools/DefaultLoader';
import IdiomaHelper from "../../../../../helper/IdiomaHelper";

import './PeriodoAvaliacaoCard.css';
import Colors from "../../../../../constants/Colors";
import DataHelper from "../../../../../helper/DataHelper";
import TooManyAvatars from "../../../../tools/TooManyAvatars";
import { Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsis, faCalendar, faChartLine } from '@fortawesome/free-solid-svg-icons';
import moment from "moment";
import BarraDemonstracao from "../../../../tools/BarraDemonstracao";
import { toast } from "react-toastify";
import { confirmAlert } from 'react-confirm-alert';
import CustomConfirm from "../../../../tools/CustomConfirm";
import { withTranslation } from 'react-i18next';


class PeriodoAvaliacaoCard extends React.Component {

    state = {
        loadingPeriodoAvaliacao: true,
        periodoAvaliacao: this.props.periodoAvaliacao || {},
        infosAdicionais: {
            empresasAvaliadas: {
                total: 0,
                realizado: 0,
                color: 0,
            },
            avaliacoesFinalizadas: {
                total: 0,
                realizado: 0,
                color: 0,
            },
            relatoriosPublicados: {
                total: 0,
                realizado: 0,
                color: 0,
            },
        },
    }

    async componentDidMount() {
        await this.props.i18n.loadNamespaces(['client_src_helper_diagnostico_DiagnosticoHelper']);

        this.loadPeriodoAvaliacao();
    }

    loadPeriodoAvaliacao = async () => {
        
        this.setState({ loadingPeriodoAvaliacao: true });

        let periodoAvaliacao = [];

        if ( this.props.id )  periodoAvaliacao = await DiagnosticoHelper.getPeriodoAvaliacao(this.props.id);
        if ( !this.props.id ) periodoAvaliacao = this.props.periodoAvaliacao;

        let infosAdicionais = await DiagnosticoHelper.getInfosAdicionaisPeriodoAvaliacao(this.props?.id || this.props?.periodoAvaliacao?.id);
        
        this.setState({ periodoAvaliacao, infosAdicionais, loadingPeriodoAvaliacao: false });
    }

    deletePeriodoAvaliacao = async () => {
        let message = IdiomaHelper.getStruct({
            'pt_br': {
                title: 'Deletar Período de Avaliação',
                message: `
                    <div>
                        <p><strong>Deseja realmente deletar este período de avaliação?</strong></p>
                        <p>Ao deletar este período de avaliação, todos os dados relacionados a ele serão perdidos, incluindo:</p>
                        <ul>
                            <li>Questionários</li>
                            <li>Avaliações</li>
                            <li>Relatórios</li>
                            <li>Todos os outros dados relacionados a este período de avaliação</li>
                        </ul>
                        <p style={{color:'red'}}>Esta ação é irreversível.</p>
                    </div>
                `,
                confirm: 'Deletar',
                cancel: 'Cancelar',
                success: 'Período de Avaliação deletado com sucesso',
                error: 'Erro ao deletar Período de Avaliação',
            },
            'en': {
                title: 'Delete Evaluation Period',
                message: `
                    <div>
                        <p><strong>Do you really want to delete this evaluation period?</strong></p>
                        <p>By deleting this evaluation period, all related data will be lost, including:</p>
                        <ul>
                            <li>Questionnaires</li>
                            <li>Evaluations</li>
                            <li>Reports</li>
                            <li>All other data related to this evaluation period</li>
                        </ul>
                        <p style={{color:'red'}}>This action is irreversible.</p>
                    </div>
                `,
                confirm: 'Delete',
                cancel: 'Cancel',
                success: 'Evaluation Period deleted successfully',
                error: 'Error deleting Evaluation Period',
            },
            'es': {
                title: 'Eliminar Período de Evaluación',
                message: `
                    <div>
                        <p><strong>¿Realmente desea eliminar este período de evaluación?</strong></p>
                        <p>Al eliminar este período de evaluación, se perderán todos los datos relacionados, incluidos:</p>
                        <ul>
                            <li>Cuestionarios</li>
                            <li>Evaluaciones</li>
                            <li>Informes</li>
                            <li>Todos los demás datos relacionados con este período de evaluación</li>
                        </ul>
                        <p style={{color:'red'}}>Esta acción es irreversible.</p>
                    </div>
                `,
                confirm: 'Eliminar',
                cancel: 'Cancelar',
                success: 'Período de Evaluación eliminado con éxito',
                error: 'Error al eliminar el Período de Evaluación',
            }
        });
        
        confirmAlert({
            customUI: ({ onClose }) => (
                <CustomConfirm
                    title={message[this.props.lang].title}
                    message={<div dangerouslySetInnerHTML={{ __html: message[this.props.lang].message }} style={{ marginTop: 10, marginBottom: 10 }} />}
                    buttons={[
                        {
                            label: message[this.props.lang].confirm,
                            color: Colors.error,
                            textColor: Colors.light,
                            onClick: async () => {
                                if (window.confirm(message[this.props.lang].title)) {
                                    let response = await DiagnosticoHelper.deletePeriodoAvaliacao(this.state.periodoAvaliacao.id);

                                    if (response) {
                                        this.props.onDelete();
                                        toast.success(message[this.props.lang].success);
                                    } else {
                                        toast.error(message[this.props.lang].error);
                                    }

                                    onClose();
                                } else {
                                    onClose();
                                }
                            }
                        },
                        {
                            label: message[this.props.lang].cancel,
                            onClick: () => { onClose(); }
                        },
                    ]}
                />
            )
        });
    }

    editPeriodoAvaliacao = async () => {
        let message = IdiomaHelper.getStruct({
            'pt_br': {
                title: 'Editar Período de Avaliação',
                message: 'Você realmente deseja editar este Período de Avaliação?',
                confirm: 'Editar',
                cancel: 'Cancelar',
            },
            'en': {
                title: 'Edit Evaluation Period',
                message: 'Do you really want to edit this Evaluation Period?',
                confirm: 'Edit',
                cancel: 'Cancel',
            },
            'es': {
                title: 'Editar Período de Evaluación',
                message: '¿Realmente deseas editar este Período de Evaluación?',
                confirm: 'Editar',
                cancel: 'Cancelar',
            }
        });
        
        confirmAlert({
            customUI: ({ onClose }) => (
                <CustomConfirm
                    title={message[this.props.lang].title}
                    message={<div dangerouslySetInnerHTML={{ __html: message[this.props.lang].message }} style={{ marginTop: 10, marginBottom: 10 }} />}
                    buttons={[
                        {
                            label: message[this.props.lang].confirm,
                            color: Colors.danger,
                            textColor: Colors.light,
                            onClick: async () => {
                                this.props.onEdit(this.state.periodoAvaliacao.id);
                                onClose();
                            }
                        },
                        {
                            label: message[this.props.lang].cancel,
                            onClick: () => { onClose(); }
                        },
                    ]}
                />
            )
        });
    }

    clonePeriodoAvaliacao = async () => {
        toast.info('Função em desenvolvimento - Function under development - Función en desarrollo')
    }

    // ===== Funções de Render =====

    renderTitle() {
        return (
            <div className={`title ${this.state.loadingPeriodoAvaliacao ? 'loading' : ''}`}>
                {!this.state.loadingPeriodoAvaliacao && this.state.periodoAvaliacao?.tem_indicadores && <FontAwesomeIcon icon={faChartLine}/>}
                {!this.state.loadingPeriodoAvaliacao && DataHelper.removeHtmlTags(this.state.periodoAvaliacao.nome)}
            </div>
        )
    }

    renderTagsInfos() {

        const infosAdicionais = this.state.infosAdicionais;

        const message = IdiomaHelper.getStruct({
            'pt_br': {
                    empresasAvaliadas: 'Empresas Avaliadas',
                    avaliacoesFinalizadas: 'Avaliações Finalizadas',
                    relatoriosPublicados: 'Relatórios Publicados',
                },
                'en': {
                    empresasAvaliadas: 'Evaluated Companies',
                    avaliacoesFinalizadas: 'Completed Evaluations',
                    relatoriosPublicados: 'Published Reports',
                },
                'es': {
                    empresasAvaliadas: 'Empresas Evaluadas',
                    avaliacoesFinalizadas: 'Evaluaciones Completadas',
                    relatoriosPublicados: 'Informes Publicados',
                }
        });

        const tag = (texto, values) => {

            if (this.state.loadingPeriodoAvaliacao) return <div className="tag-example loading"></div>;
            if (!values || values?.total == 0) return null;

            let x = values?.realizado ?? 0;
            let y = values?.total ?? 0;
            let z = y !== 0 ? ((x / y) * 100).toFixed(1) : '0';
            let color = values?.color ?? '#e6e6e6';
            return <div className="tag-example" style={{backgroundColor: color}}>{x}/{y} {texto} {z}%</div>
        }

        return (
            <div className={`tags-infos`}>
                {tag(message[this.props.lang].empresasAvaliadas, infosAdicionais.empresasAvaliadas)}
                {tag(message[this.props.lang].avaliacoesFinalizadas, infosAdicionais.avaliacoesFinalizadas)}
                {tag(message[this.props.lang].relatoriosPublicados, infosAdicionais.relatoriosPublicados)}
            </div>
        )
    }

    renderAvaliadores() {
        
        const message = IdiomaHelper.getStruct({
            'pt_br': { evaluators: 'Avaliadores', emptyEvaluators: '(Sem Avaliadores definidos)' },
            'en': { evaluators: 'Evaluators', emptyEvaluators: '(No Evaluators defined)' },
            'es': { evaluators: 'Evaluadores', emptyEvaluators: '(Sin Evaluadores definidos)' },
        });

        return (
            <div className={`avaliadores`}>
                <span>{message[this.props.lang].evaluators}</span>
                {!this.state.loadingPeriodoAvaliacao && this.state.periodoAvaliacao?.colaboradores?.length > 0 ?
                    <>
                        
                        <TooManyAvatars 
                            participantsTotal={this.state.periodoAvaliacao?.colaboradores?.length} 
                            participantsVisiveis={20} 
                            arrayParticipants={this.state.periodoAvaliacao?.colaboradores}
                        />
                    </>
                    :
                    <span className="empty">{message[this.props.lang].emptyEvaluators}</span>
                }
            </div>
        )
    }

    renderOptions = () => {
        const portalTarget = document.getElementById('root');
        if (!this.state.periodoAvaliacao?.canEdit) return (null);
        return (
            <Dropdown className="optionsProjeto" onClick={(e) => { e.stopPropagation() }}>
                <Dropdown.Toggle
                    onChange={() => {}}
                    role="button"
                    style={{ 
                            backgroundColor: Colors.secondaryButton, 
                            color: Colors.dark, 
                            border: 'none',
                            zIndex: 25
                    }}
                    drop={'top'}
                > 
                    <FontAwesomeIcon icon={faEllipsis} />
                </Dropdown.Toggle>
                {ReactDOM.createPortal(
                    <Dropdown.Menu style={{padding: 2, color: Colors.dark}}> 
                        <Dropdown.Item 
                            key={`add-vinculpo-btn`} 
                            onClick={this.editPeriodoAvaliacao}
                        >
                                <span style={{ fontSize: 14 }}>Editar Período de Avaliação</span>
                        </Dropdown.Item>

                        <Dropdown.Item 
                            key={`clone-periodo-btn`} 
                            onClick={this.clonePeriodoAvaliacao}
                        >
                                <span style={{ fontSize: 14 }}>Clonar Período de Avaliação</span>
                        </Dropdown.Item>

                        <Dropdown.Item 
                            key={`delete-periodo-btn`} 
                            onClick={() => {this.deletePeriodoAvaliacao()}}
                        >
                                <span style={{ fontSize: 14 }}>Deletar Período de Avaliação</span>
                        </Dropdown.Item>
                    </Dropdown.Menu>
                , portalTarget)}
            </Dropdown>
        );
    }

    renderDateStatus() {
        return (
            <div className={`date-status`}>
                {this.renderDate()}
                {DiagnosticoHelper.renderStatusPeriodoAvaliacao(this.state.periodoAvaliacao, this.props.t)}
            </div>
        )
    }

    renderDate() {
        return (
            <div className="date">
                <FontAwesomeIcon icon={faCalendar} style={{ color: Colors.dark, fontSize: 14, marginRight: 4 }} />
                <span>{this.state.periodoAvaliacao?.data_inicio != 0 ? moment(this.state.periodoAvaliacao?.data_inicio).format('MM/YYYY') : "- -"}{this.state.periodoAvaliacao?.data_fim ? ` ~ ${moment(this.state.periodoAvaliacao?.data_fim).format('MM/YYYY')}` : ` : - -`}</span>
            </div>
        );
    }

    renderScale() {

        let faixas = this.state.periodoAvaliacao?.faixas?.map((interval) => {

            let colorAux = {
                from: interval.min,
                to: interval.max,
                color: interval.cor,
                infinito: 0,
            }

            return colorAux;
        }) ?? [];

        return (
            <BarraDemonstracao intervals={faixas} loading={this.state.loadingPeriodoAvaliacao} width={'20rem'} marginTop={'0px'} />
        )
    }

    renderCard() {
        return (
            <div className="PeriodoAvaliacaoCard" style={{boxShadow: Colors.boxShadow}}>
                <div className="card-left">
                    {this.renderTitle()}
                    {this.renderTagsInfos()}
                    {this.renderAvaliadores()}
                </div>

                <div className="card-right">
                    {this.renderOptions()}
                    {this.renderDateStatus()}
                    {this.renderScale()}
                </div>
            </div>
        )
    }

    render() {
        return this.renderCard();
    }
}

export default withTranslation()(PeriodoAvaliacaoCard);