import React, { forwardRef } from "react";
import './RelatorioTabelaResultadoItem.css';
import Colors from "../../../../../../constants/Colors";
import DiagnosticoHelper from "../../../../../../helper/diagnostico/DiagnosticoHelper";
import DefaultLoader from "../../../../../tools/DefaultLoader";
import IdiomaHelper from "../../../../../../helper/IdiomaHelper";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCertificate, faExclamation, faArrowDown, faLightbulb, faChevronDown } from '@fortawesome/free-solid-svg-icons';

import EllipsisText from "../../../../../tools/EllipsisText";
import ModalResumoNodeQuestionario from "../../../../../tools/ModalResumoNodeQuestionario/ModalResumoNodeQuestionario";
import EssentialStyle from "../../../../../../style/EssentialStyle";
import StarButton from "../../../StarButton/StarButton";

const MIN_WIDTH_CELL = '120px';
const MIN_WIDTH_CELL_LARGE = '150px';

export default class RelatorioTabelaResultadoItem extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            miniLoading: false,

            item: this.props?.item || null,
            idEmpresaAvaliada: this.props?.idEmpresaAvaliada || null,
            status: this.props?.status || 1,
            canEdit: this.props?.canEdit || false,
            heightCell: '2.5rem',
            heightHeaderCell: '3rem',
            data_processo: this.props?.data_processo || null,

            dataTabela: null,

            lang: IdiomaHelper.getIdioma(),

            openInfoNode: null,
            idAvaliacao: null,
            hoverLine: null,
            hoverColumn: null,
            pinHeader: false,
            headerPosition: 'unset',
        }
        this.itemRef = React.createRef();
    }

    componentDidMount() {
        this.loadTabelaResultados(true);
        window.addEventListener('scroll', this.handleScroll);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.data_processo !== this.props.data_processo && prevProps.data_processo !== null) {
            this.setState({ data_processo: this.props.data_processo }, () => {
                this.loadTabelaResultados(false);
            });
        }
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = () => {
        if(this.timeout) {
            return;
        }

        this.timeout = setTimeout(() => {
            this.checkScroll();
            this.timeout = null;
        }
        , 100);
    }

    checkScroll = () => {
        let minTop = this.props.pageHeaderHeight ? (this.props.pageHeaderHeight - 5) : -5;

        if (this.itemRef?.current) {
            const { top, bottom } = this.itemRef?.current?.getBoundingClientRect();
            let pinHeader = false;
            if ((top < minTop) && !(bottom < (minTop + 150))) {
                pinHeader = true;
            }

            if(this.state.pinHeader !== pinHeader) {
                this.setState({ pinHeader });
            }
            let headerPosition = pinHeader ? (minTop - top) : 'unset';
            this.setState({ headerPosition });
        }
    }

    message = () => {
        const message = IdiomaHelper.getStruct({
            'pt_br': {
                title: 'Resultados da Avaliação',
                resultados: 'Resultados',
            },
            'en': {
                title: 'Evaluation Results',
                resultados: 'Results',
            },
            'es': {
                title: 'Resultados de la Evaluación',
                resultados: 'Resultados',
            },
        });

        return message[this.state.lang];
    }

    async loadTabelaResultados(viewLoading = true) {
        this.setState({ loading: viewLoading ? true : false, miniLoading: true });

        let idItem = this.state.item.id;

        let dataTabela = await DiagnosticoHelper.getEstruturaTabelaResultados(idItem, this.state.data_processo);

        this.setState({ dataTabela, loading: false, miniLoading: false });
    }

    infoGrupo = (grupo, indicadores, faixa) => {

        let title = this.message()[`${this.state.calculo}`];
        let subTitle = this.message()[`${this.state.calculo}Info`];

        const mediaInfos = () => {
            return (
                <ul>
                    {Object.values(indicadores).map((indicador) => {
                        return (
                            <li key={indicador?.indicadorRef?.id || ''}>
                                <span>{indicador?.indicadorRef?.indicador?.nome}: </span>
                                {this.state.calculo == 'moda' ? indicador.resultados.realizadoRealizado : indicador.resultados.performanceRealizado}
                            </li>
                        )
                    })}
                </ul>
            )
        }

        const faixaSelect = () => {

            let media = Object.values(indicadores).map((indicador) => {
                return this.state.calculo == 'moda' ? indicador.resultados.realizadoRealizadoRaw : indicador.resultados.performance;
            }).reduce((acc, val) => acc + val, 0) / Object.values(indicadores).length;

            // Limita a duas casas decimais
            media = media.toFixed(2);

            return (
                <p>
                    <strong>{faixa.textos.find(texto => texto.id_idioma === IdiomaHelper.getSiglaId(this.state.lang))?.texto} ({faixa.min} - {faixa.max}): </strong>
                    <strong>{media}%</strong>
                </p>
            )
        }

        const modaInfos = () => {

            let faixas = Object.values(indicadores)[0].indicadorRef.faixas;

            let freqFaixas = Object.values(indicadores).map((indicador) => {
                return indicador.faixaPeriodo.id;
            });

            let freqCount = freqFaixas.reduce((acc, id) => {
                acc[id] = (acc[id] || 0) + 1;
                return acc;
            }, {});

            // Ordena as faixas pela frequência
            faixas.sort((a, b) => (freqCount[b.id] || 0) - (freqCount[a.id] || 0));

            // Encontra a maior frequência
            let maxFreq = Math.max(...Object.values(freqCount));

            return (
                <ul>
                    {faixas.map((faixa) => {
                        const isMostFrequent = freqCount[faixa.id] === maxFreq; // Verifica se é a faixa com maior frequência
                        return (
                            <li key={faixa.id} style={{ fontWeight: isMostFrequent ? 'bold' : 'normal' }}>
                                <span>{faixa.textos.find(texto => texto.id_idioma === IdiomaHelper.getSiglaId(this.state.lang))?.texto}: </span>
                                {freqCount[faixa.id] || 0}
                            </li>
                        )
                    })}
                </ul>
            )
        }

        return (
            <div>
                <h5>{title}</h5>
                <p>{subTitle}</p>
                <hr/>
                {this.state.calculo == 'moda' ? modaInfos() : mediaInfos()}
                {this.state.calculo == 'media' && <hr/>}
                {this.state.calculo == 'media' && faixaSelect()}
            </div>
        )

    }

    renderTabelaMatriz() {

        let { tabela, nodesComprimido, nodesExpandido, colunaExpandida, colunaComprimida, idAvaliacao } = this.state.dataTabela;
        if (!tabela) return null;

        const sortedTabela = Object.entries(tabela).sort(([nomeA], [nomeB]) => {
            const nomeLinhaA = nodesComprimido[nomeA][0]?.texto?.find(texto => texto.id_idioma === IdiomaHelper.getSiglaId(this.state.lang))?.descricao || '';
            const nomeLinhaB = nodesComprimido[nomeB][0]?.texto?.find(texto => texto.id_idioma === IdiomaHelper.getSiglaId(this.state.lang))?.descricao || '';
            return nomeLinhaA.localeCompare(nomeLinhaB);
        });

        return (
            <div 
                ref={this.itemRef}
                className="RelatorioTabelaResultadoItem" 
                style={{ boxShadow: Colors.boxShadow }} 
            >
                {this.state.miniLoading && <div className="miniLoading"><DefaultLoader size={15}/></div>}
                <div className={`headerTabelaResultado ${this.state.pinHeader ? 'pin-header' : ''}`} style={{ top: this.state.headerPosition }}>{this.message().title}</div>
                <div className="matrizContainer">
                    <div className={`line-chart line-header ${this.state.pinHeader ? 'pin-header' : ''}`} style={{ top: this.state.headerPosition }}>

                        <div
                            className="cel-chart header-cel header-cel-coluna"
                            style={{
                                borderRadius: '8px 0px 0px 0px',
                                backgroundColor: 'rgb(200, 200, 200)',
                                fontSize: '1.1em',
                                fontWeight: 300,
                                fontStyle: 'italic',
                                gap: '5px',
                                height: this.state.heightHeaderCell,
                                justifyContent: 'center',
                            }}
                        >
                            <FontAwesomeIcon icon={faArrowDown} style={{fontSize: '0.8rem'}}/>
                            {colunaComprimida.descricao?.find(texto => texto.id_idioma === IdiomaHelper.getSiglaId(this.state.lang))?.descricao || ''}
                            <FontAwesomeIcon icon={faArrowDown} style={{fontSize: '0.8rem'}}/>
                        </div>

                        {nodesExpandido.map((node, index) => {

                            let nome = node?.texto?.find(texto => texto.id_idioma === IdiomaHelper.getSiglaId(this.state.lang))?.descricao;
                            let isLast = index === nodesExpandido.length - 1;

                            return (
                                <div
                                    key={`cel-chart-${node.id}`}
                                    className="cel-chart header-cel"
                                    style={{
                                        minWidth: this.state.item?.tipo_agrupamento_icones == 2 ? MIN_WIDTH_CELL_LARGE : MIN_WIDTH_CELL,
                                        fontWeight: this.state.hoverColumn == node.id ? '900' : '',
                                        boxShadow: this.state.hoverColumn == node.id ? Colors.boxShadow : '',
                                        zIndex: this.state.hoverColumn == node.id ? 1 : '',
                                        borderRadius: isLast ? '0px 8px 0px 0px' : '0px',
                                        height: this.state.heightHeaderCell,
                                    }}
                                >
                                    <EllipsisText text={nome} style={{ minWidth: '', flex: 1 }} />

                                    { this.state.item?.tipo_agrupamento_icones == 2 && 
                                        <div className="fixed-icon-right">
                                            {node?.hasIndicadorProcessoTree ? (
                                                node?.hasIndicadorProcesso ? (
                                                    <FontAwesomeIcon icon={faCertificate}/>
                                                ) : (
                                                    <div style={{...EssentialStyle.columnStart, alignItems: 'center'}}>
                                                        <FontAwesomeIcon icon={faCertificate} style={{ marginBottom: -3 }}/>
                                                        <FontAwesomeIcon icon={faChevronDown} style={{ fontSize: '0.5rem'}}/>
                                                    </div>
                                                )
                                            ) : null}

                                            {node?.hasPlanoAcaoTree ? (
                                                node?.hasPlanoAcao ? (
                                                    <FontAwesomeIcon icon={faLightbulb}/>
                                                ) : (
                                                    <div style={{...EssentialStyle.columnStart, alignItems: 'center'}}>
                                                        <FontAwesomeIcon icon={faLightbulb} style={{ marginBottom: -3 }}/>  
                                                        <FontAwesomeIcon icon={faChevronDown} style={{ fontSize: '0.5rem'}}/>
                                                    </div>
                                                )
                                            ) : null}
                                        </div>
                                    }
                                </div>
                            )
                        })}
                    </div>

                    {sortedTabela.map(([nome, linha], indexLinha) => {

                        let isLastLinha = indexLinha === Object.entries(tabela).length - 1;
                        let nomeLinha = nodesComprimido[nome][0]?.texto?.find(texto => texto.id_idioma === IdiomaHelper.getSiglaId(this.state.lang))?.descricao;
                        
                        return (
                            <div key={`linha-chart-${nome}`} className={`line-chart line-body ${isLastLinha ? 'last-line-chart' : ''}`}>
                                
                                <div
                                    className="cel-chart header-cel header-cel-coluna"
                                    style={{
                                        backgroundColor: indexLinha % 2 == 0 ? 'rgb(220, 220, 220)' : 'rgb(200, 200, 200)',
                                        fontWeight: this.state.hoverLine == indexLinha ? '900' : '',
                                        boxShadow: this.state.hoverLine == indexLinha ? Colors.boxShadow : '',
                                        zIndex: this.state.hoverLine == indexLinha ? 1 : '',
                                        height: this.state.heightCell,
                                    }}
                                >
                                    <EllipsisText text={nomeLinha} style={{ minWidth: '', flex: 1 }} />

                                    { this.state.item?.tipo_agrupamento_icones == 1 &&
                                        <div className="fixed-icon-right">
                                            {nodesComprimido[nome][0]?.hasIndicadorProcessoTree ? (
                                                nodesComprimido[nome][0]?.hasIndicadorProcesso ? (
                                                    <FontAwesomeIcon icon={faCertificate}/>
                                                ) : (
                                                    <div style={{...EssentialStyle.columnStart, alignItems: 'center'}}>
                                                        <FontAwesomeIcon icon={faCertificate} style={{ marginBottom: -3 }}/>
                                                        <FontAwesomeIcon icon={faChevronDown} style={{ fontSize: '0.5rem'}}/>
                                                    </div>
                                                )
                                            ) : null}
                                            {nodesComprimido[nome][0]?.hasPlanoAcaoTree ? (
                                                nodesComprimido[nome][0]?.hasPlanoAcao ? (
                                                    <FontAwesomeIcon icon={faLightbulb}/>
                                                ) : (
                                                    <div style={{...EssentialStyle.columnStart, alignItems: 'center'}}>
                                                        <FontAwesomeIcon icon={faLightbulb} style={{ marginBottom: -3 }}/>
                                                        <FontAwesomeIcon icon={faChevronDown} style={{ fontSize: '0.5rem'}}/>
                                                    </div>
                                                )
                                            ) : null}
                                        </div>
                                    }
                                </div>

                                {linha.map((node, indexNode) => {

                                    let isLastNode = indexNode === linha.length - 1;
                                    let resultados = node?.resultados;
                                    return (
                                        <div
                                            key={`cel-chart-${nome}-${indexNode}`}
                                            className="cel-chart value-chart"
                                            style={{
                                                minWidth: this.state.item?.tipo_agrupamento_icones == 2 ? MIN_WIDTH_CELL_LARGE : MIN_WIDTH_CELL,
                                                backgroundColor: resultados?.cor || (indexLinha % 2 == 0 ? 'rgb(220, 220, 220)' : 'rgb(200, 200, 200)'),
                                                borderBottomRightRadius: isLastNode && isLastLinha ? '8px' : '0px',
                                                cursor: resultados?.valor ? 'pointer' : 'default',
                                                height: this.state.heightCell,
                                            }}
                                            onMouseEnter={() => this.setState({ hoverLine: indexLinha, hoverColumn: null })}
                                            onMouseLeave={() => this.setState({ hoverLine: null, hoverColumn: null })}
                                            onClick={() => this.setState({openInfoNode: node.id_node, idAvaliacao: node.id_avaliacao})}
                                        >
                                            <div 
                                                style={{
                                                    position: 'absolute',
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    justifyContent: 'end',
                                                    alignItems: 'start',
                                                    top: 5,
                                                    right: 5,
                                                    zIndex: 1,
                                                }}
                                            >
                                                <StarButton 
                                                    idNode={node.id_node}
                                                    idAvaliacao={node.id_avaliacao}
                                                    isDisabled={true}
                                                    styles={{icon:{fontSize: '0.7rem'}}}
                                                    status={resultados?.hasEstrela}
                                                    color={"white"}
                                                />
                                            </div>

                                            {resultados?.valor ? 
                                                `${parseFloat(resultados?.valor || 0).toFixed(1)}%` 
                                                : 
                                                '-'
                                            }
                                            
                                            { this.state.item?.tipo_agrupamento_icones == 0 &&
                                                <>
                                                    <div className="fixed-icon-left">
                                                        {resultados?.hasIndicadorProcessoTree ? (
                                                            resultados?.hasIndicadorProcesso ? (
                                                                <FontAwesomeIcon icon={faCertificate}/>
                                                            ) : (
                                                                <div style={{...EssentialStyle.columnStart, alignItems: 'center'}}>
                                                                    <FontAwesomeIcon icon={faCertificate} style={{ marginBottom: -3 }}/>
                                                                    <FontAwesomeIcon icon={faChevronDown} style={{ fontSize: '0.5rem'}}/>
                                                                </div>
                                                            )
                                                        ) : null}
                                                    </div>

                                                    
                                                    <div className="fixed-icon-right">
                                                        {resultados?.hasPlanoAcaoTree ? (
                                                            resultados?.hasPlanoAcao ? (
                                                                <FontAwesomeIcon icon={faLightbulb}/>
                                                            ) : (
                                                                <div style={{...EssentialStyle.columnStart, alignItems: 'center'}}>
                                                                    <FontAwesomeIcon icon={faLightbulb} style={{ marginBottom: -3 }}/>
                                                                    <FontAwesomeIcon icon={faChevronDown} style={{ fontSize: '0.5rem'}}/>
                                                                </div>
                                                            )
                                                        ) : null}
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    )
                                })}
                            </div>
                        )
                    })}
                </div>

                {this.renderInfoNode()}
            </div>
        )
    }

    renderTabelaLista() {

        let { tabela, colunas, idAvaliacao } = this.state.dataTabela;

        const getWidthTable = () => {
            return document.getElementsByClassName('RelatorioTabelaResultadoItem')[0]?.offsetWidth - 20 || '100vw';
        }

        const renderRows = (tabela, lang) => {
            return (
                tabela.map((node, index) => {
                    return (
                        <div 
                            key={`row-${node.id}`} 
                            className="table-row table-row-body"
                            style={{
                                backgroundColor: index % 2 == 0 ? 'rgb(250, 250, 250)' : 'rgb(240, 240, 240)',
                            }}
                        >
                            {renderCells(node, lang)}
                        </div>
                    );
                })
            );
        };

        const renderCells = (node, lang) => {
            let cells = [];

            if (node?.filhos?.length > 0) {
                node.filhos.forEach((filho) => {
                    cells.push(renderCells(filho, lang));
                });
            } else {
                cells.push(renderResult(node, false));
            }

            let coluna = colunas.find(coluna => coluna.id === node.id_coluna);
            let indexColuna = colunas.indexOf(coluna);
            let isLast = indexColuna === colunas.length - 1;

            return(
                <div className="table-row">
                    
                    {renderCell(node, isLast)}

                    <div className="table-column">
                        {cells.map((cell, index) => (
                            <div key={`cell-${node.id}-${index}`}>
                                {cell}
                            </div>
                        ))}
                    </div>
                </div>
            );
        };

        const renderCell = (node) => {

            let text = node.texto?.find(nome => nome.id_idioma === IdiomaHelper.getSiglaId(this.props.lang))?.descricao || false;
            
            return (
                <div 
                    key={`node-${node.id}`}
                    className={`table-cell`}
                    rowSpan={node.rows}
                    style={{ 
                        height: (`calc(${this.state.heightCell} * ${node.rows})`),
                        minHeight: (`(${this.state.heightCell}`),
                        width: (getWidthTable() / (colunas.length + 1)),
                    }}
                >
                    {text ? <EllipsisText text={text} /> : <FontAwesomeIcon icon={faExclamation} />}
                </div>
            )
        }

        const renderResult = (node) => {

            let resultados = node.resultados;

            return (
                <div
                key={`node-${node.id}-result`}
                    className={`table-cell no-border value-chart`}
                    rowSpan={node.rows}
                    style={{ 
                        backgroundColor: resultados?.cor || 'white',
                        height: (`calc(${this.state.heightCell} * ${node.rows})`),
                        width: (getWidthTable() / (colunas.length + 1)),
                        position: 'relative',
                        cursor: resultados?.valor ? 'pointer' : 'default',
                    }}
                    onClick={() => this.setState({openInfoNode: node.id, idAvaliacao: idAvaliacao})}
                >
                     <div 
                        style={{
                            position: 'absolute',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'end',
                            alignItems: 'start',
                            top: 5,
                            right: 5,
                            zIndex: 1,
                        }}
                    >
                        <StarButton 
                            idNode={node.id_node}
                            idAvaliacao={node.id_avaliacao}
                            isDisabled={true}
                            styles={{icon:{fontSize: '0.7rem'}}}
                            status={resultados?.hasEstrela}
                            color={"white"}
                        />
                    </div>

                    {parseFloat(resultados?.valor || 0).toFixed(1)}%

                    <div className="fixed-icon-right">

                        {resultados?.hasPlanoAcaoTree ? (
                            resultados?.hasPlanoAcao ? (
                                <FontAwesomeIcon icon={faLightbulb}/>
                            ) : (
                                <div style={{...EssentialStyle.columnStart, alignItems: 'center'}}>
                                    <FontAwesomeIcon icon={faLightbulb} style={{ marginBottom: -3 }}/>
                                    <FontAwesomeIcon icon={faChevronDown} style={{ fontSize: '0.5rem'}}/>
                                </div>
                            )
                        ) : null}
                    </div>

                    <div className="fixed-icon-left">
                        {resultados?.hasIndicadorProcessoTree ? (
                            resultados?.hasIndicadorProcesso ? (
                                <FontAwesomeIcon icon={faCertificate}/>
                            ) : (
                                <div style={{...EssentialStyle.columnStart, alignItems: 'center'}}>
                                    <FontAwesomeIcon icon={faCertificate} style={{ marginBottom: -3 }}/>
                                    <FontAwesomeIcon icon={faChevronDown} style={{ fontSize: '0.5rem'}}/>
                                </div>
                            )
                        ) : null}
                    </div>
                </div>
            )
        }
        
        if (!tabela) return null;
        return (
            <div 
                ref={this.itemRef}
                className="RelatorioTabelaResultadoItem" 
                style={{ boxShadow: Colors.boxShadow }}
            >
                {this.state.miniLoading && <div className="miniLoading"><DefaultLoader size={15}/></div>}
                <div className={`headerTabelaResultado ${this.state.pinHeader ? 'pin-header' : ''}`} style={{ top: this.state.headerPosition }}>{this.message().title}</div>
                <div className={`line-chart line-header ${this.state.pinHeader ? 'pin-header' : ''}`} style={{ top: this.state.headerPosition }}>
                    {colunas.map((node, index) => {

                        let nome = node?.texto?.find(texto => texto.id_idioma === IdiomaHelper.getSiglaId(this.state.lang))?.descricao;
                        let isFirst = index === 0;

                        return (
                            <div
                                key={`cel-chart-${node.id}`}
                                className="cel-chart header-cel"
                                style={{
                                    fontWeight: this.state.hoverColumn == node.id ? '900' : '',
                                    boxShadow: this.state.hoverColumn == node.id ? Colors.boxShadow : '',
                                    zIndex: this.state.hoverColumn == node.id ? 1 : '',
                                    borderRadius: isFirst ? '8px 0px 0px 0px' : '0px',
                                    height: this.state.heightHeaderCell,
                                }}
                            >
                                <EllipsisText text={nome} style={{ minWidth: '', maxWidth: 'calc(100% - 20px)' }} />
                            </div>
                        )
                    })}

                    <div
                        className="cel-chart header-cel"
                        style={{ 
                            borderRadius: '0px 8px 0px 0px',
                            fontSize: '1.1em',
                            fontWeight: 300,
                            fontStyle: 'italic',
                            gap: '5px',
                            height: this.state.heightHeaderCell,
                        }}
                    >
                        <FontAwesomeIcon icon={faArrowDown} style={{fontSize: '0.8rem'}}/>
                        {this.message().resultados}
                        <FontAwesomeIcon icon={faArrowDown} style={{fontSize: '0.8rem'}}/>
                    </div>

                </div>

                {renderRows(tabela, this.state.lang)}

                {this.renderInfoNode()}
            </div>
        )
    }

    renderLoading() {
        return (
            <div className="RelatorioTabelaResultadoItem" style={{ boxShadow: Colors.boxShadow }}>
                <DefaultLoader/>
            </div>
        )
    }

    renderInfoNode() {
        const { openInfoNode, idAvaliacao } = this.state;
        const { lang } = this.props;
    
        return openInfoNode && idAvaliacao && (
            <ModalResumoNodeQuestionario
                idNode={openInfoNode}
                idAvaliacao={idAvaliacao}
                lang={lang}
                changeNode={(idNode) => this.setState({ openInfoNode: idNode })}
                closeModal={() => this.setState({ openInfoNode: null, idAvaliacao: null })}
                data_processo={this.state.data_processo}
                changeEstrela={(idNode, idAvaliacao, status) => {
                    if (this.state.item.tableType == 'matriz') {
                        let tabela = this.state.dataTabela.tabela;
                        Object.entries(tabela).forEach(([nome, linha]) => {
                            linha.forEach((node) => {
                                if (node.id_node == idNode) {
                                    node.resultados.hasEstrela = status;
                                }
                            });
                        });

                    } else {
                        let tabela = this.state.dataTabela.tabela;

                        const updateEstrelaNaArvore = (node, idNode, status) => {

                            if (node.id === idNode) {
                                node.resultados.hasEstrela = status;
                                return true;
                            }
                            
                            // Verifica se o nó tem filhos
                            if (node.filhos && node.filhos.length > 0) {
                                // Percorre recursivamente todos os filhos
                                for (let i = 0; i < node.filhos.length; i++) {
                                    // Se encontrarmos o nó em qualquer subárvore, podemos retornar
                                    if (updateEstrelaNaArvore(node.filhos[i], idNode, status)) {
                                        return true;
                                    }
                                }
                            }
                            
                            // Se chegarmos aqui, o nó não foi encontrado nesta subárvore
                            return false;
                        };

                        tabela.forEach((node) => {
                            updateEstrelaNaArvore(node, idNode, status);
                        });
                    }
                }}
            />
        );
    }

    render() {
        if (this.state.loading) return this.renderLoading();
        return this.state.item.tableType == 'matriz' ? this.renderTabelaMatriz() : this.renderTabelaLista();
    }
}