import React, { createRef } from "react";
import IdiomaHelper from "../../../../../helper/IdiomaHelper";

import './ModalPeriodoGrupoIndicadores.css';
import Colors from "../../../../../constants/Colors";
import DefaultButton from "../../../../../components/tools/DefaultButton";
import DiagnosticoHelper from "../../../../../helper/diagnostico/DiagnosticoHelper";
import Modal from "../../../../tools/Modal";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLayerGroup, faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import EssentialStyle from "../../../../../style/EssentialStyle";
import { Form, Row, Col } from "react-bootstrap";
import IndicadorReferenciaCard from "../IndicadorReferenciaCard/IndicadorReferenciaCard";

import { confirmAlert } from 'react-confirm-alert';
import CustomConfirm from "../../../../tools/CustomConfirm";
import DefaultLoader from "../../../../tools/DefaultLoader";
import Operacao from "../../../../tools/Operacao/Operacao";


export default class ModalPeriodoGrupoIndicadores extends React.Component {
    operacoesRef = createRef(); // Adiciona uma ref para o componente Operacoes

    state = {
        grupo: this.props.grupo,
        idGrupo: this.props.idGrupo,
        listPeriodoIndicadores: this.props.listPeriodoIndicadores,
        editName: false,
        editIndicadores: false,
        inputRefs: IdiomaHelper.getIdiomas().map(() => createRef()),
        loading: true,
        loadReload: false
    }

    componentDidMount() {
        this.state.idGrupo ? this.loadGrupo() : this.defineTextDescricao();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.idGrupo !== this.props.idGrupo) {
            this.setState({ idGrupo: this.props.idGrupo }, () => {
                this.loadGrupo();
            });
        }

        if (prevProps.listPeriodoIndicadores !== this.props.listPeriodoIndicadores) {
            this.setState({ listPeriodoIndicadores: this.props.listPeriodoIndicadores });
        }
    }

    defineTextDescricao() {

        this.setState({loading: true});

        if (this.state.grupo === true) {
            this.setState({

                grupo: {
                    id_diagnostico_periodo: this.props.idPeriodoAvaliacao,
                    textos: IdiomaHelper.getIdiomas().map(idioma => {
                        return {
                            id: null,
                            id_diagnostico_periodo_grupo: null,
                            id_idioma: idioma.id,
                            descricao: ''
                        }
                    })
                }
            });
        }

        this.setState({loading: false});
    }

    handleKeyDown = (e, index) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            const nextInput = this.state.inputRefs[index + 1];
            if (nextInput) {
                nextInput.current.focus();
            }
        }
    }

    async loadGrupo(idGrupo = this.state?.grupo?.id || this.state.idGrupo) {

        this.setState({loading: true});

        let grupo = await DiagnosticoHelper.getPeriodoAvaliacaoGrupoIndicadores(idGrupo);
        this.setState({ grupo: grupo }, this.defineTextDescricao);
    }

    async saveGrupo() {
        
        let grupo = this.state.grupo;

        if (!grupo.textos.some(texto => texto.descricao && texto.descricao.trim() !== '')) {
            return;
        }

        grupo = await DiagnosticoHelper.savePeriodoGrupoIndicadores(grupo);

        this.setState({ grupo: grupo });
    }

    async deleteGrupo() {
        
        if (this.state.grupo.id) {
            
            const deleteGrupo = async () => {
                await DiagnosticoHelper.deletePeriodoGrupoIndicadores(this.state.grupo.id);
                this.props.closeModal();
            }

             let message = IdiomaHelper.getStruct({
                'pt_br': {
                    title: 'Excluir Grupo de Indicadores?',
                    message: `
                        <p>Tem certeza de que deseja excluir este Grupo de Indicadores? Esta ação é irreversível e resultará na perda das seguintes informações:</p>
                        <ol>
                            <li>Faixas de cores do grupo</li>
                            <li>Indicadores e painéis associados</li>
                        </ol>
                    `,
                    confirm: 'Excluir',
                    cancel: 'Cancelar',
                },
                'en': {
                    title: 'Delete Indicator Group?',
                    message: `
                        <p>Are you sure you want to delete this Indicator Group? This action is irreversible and will result in the loss of the following information:</p>
                        <ol>
                            <li>Group color bands</li>
                            <li>Associated Indicators and Panels</li>
                        </ol>
                    `,
                    confirm: 'Delete',
                    cancel: 'Cancel',
                },
                'es': {
                    title: '¿Eliminar Grupo de Indicadores?',
                    message: `
                        <p>¿Estás seguro de que quieres eliminar este Grupo de Indicadores? Esta acción es irreversible y resultará en la pérdida de la siguiente información:</p>
                        <ol>
                            <li>Bandas de colores del grupo</li>
                            <li>Indicadores y paneles asociados</li>
                        </ol>
                    `,
                    confirm: 'Eliminar',
                    cancel: 'Cancelar',
                }
            });
                            
            confirmAlert({
                customUI: ({ onClose }) => (
                    <CustomConfirm
                        title={message[this.props.lang].title}
                        message={<p style={{ marginTop: 10, marginBottom: 10}}  dangerouslySetInnerHTML={{ __html: message[this.props.lang].message }}/>}
                        buttons={[
                            {
                                label: message[this.props.lang].confirm,
                                color: Colors.error,
                                textColor: Colors.light,
                                onClick: async () => {
                                    deleteGrupo();
                                    onClose();
                                }
                            },
                            {
                                label: message[this.props.lang].cancel,
                                onClick: () => { onClose(); }
                            },
                        ]}
                    />
                )
            });
            
        }

        
    }

    async switchEditMode() {
                
        if (this.props.reload) {
            this.setState({loadReload: true});
            await this.props.reload();
            this.setState({loadReload: false});
        }

        this.setState({editIndicadores: !this.state.editIndicadores});
    }

    message(lang = this.props.lang) {
        const message = IdiomaHelper.getStruct({
            'pt_br': { 
                newTitle: 'Novo Grupo de Indicadores',
                title: 'Grupo de Indicadores',

                tooltipEditTitle: 'Editar nome do Grupo',
                tooltipEditIndicadores: 'Editar Indicadores do grupo',

                label_name: 'Nome do Grupo',
                placeholder_groupName: 'Digite o nome do grupo...',

                label_indicadores: 'Indicadores incluídos',
                label_editIndicadores: 'Editando Indicadores',
                label_operacao: 'Regra de Faixa do Grupo',

                emptyList: 'Nenhum indicador associado',

                close: 'Fechar',
            },
            'en': { 
                newTitle: 'New Indicator Group',
                title: 'Indicator Group',

                tooltipEditTitle: 'Edit Group Name',
                tooltipEditIndicadores: 'Edit Group Indicators',

                label_name: 'Group Name',
                placeholder_groupName: 'Enter the group name...',

                label_indicadores: 'Included Indicators',
                label_editIndicadores: 'Editing Indicators',
                label_operacao: 'Group Band Rule',

                emptyList: 'No associated indicators',

                close: 'Close',
            },
            'es': { 
                newTitle: 'Nuevo Grupo de Indicadores',
                title: 'Grupo de Indicadores',

                tooltipEditTitle: 'Editar nombre del Grupo',
                tooltipEditIndicadores: 'Editar Indicadores del grupo',

                label_name: 'Nombre del Grupo',
                placeholder_groupName: 'Ingrese el nombre del grupo...',

                label_indicadores: 'Indicadores incluidos',
                label_editIndicadores: 'Editando Indicadores',
                label_operacao: 'Regla de Banda del Grupo',

                emptyList: 'Ningún indicador asociado',

                close: 'Cerrar',
            },
        });

        return message[lang];
    }

    renderTitle() {
        const titleLeft = (
            <div className="titleLeft">
                <FontAwesomeIcon icon={faLayerGroup} onClick={this.props.close} />
                {this.state.grupo === true
                    ? `${this.message().newTitle}`
                    : `${this.message().title}: ${this.state.grupo?.textos?.find(desc => desc.id_idioma === IdiomaHelper.getSiglaId(this.props.lang))?.descricao || ''}`}
                {this.state.grupo !== true && (
                    <FontAwesomeIcon
                        className={"editName hoverScale"}
                        icon={faEdit}
                        onClick={() => { this.setState({ editName: !this.state.editName }) }}
                    />
                )}
            </div>
        );

        const titleRight = (
            <div className="titleRight">
                <FontAwesomeIcon
                    className={"hoverScale"}
                    style={{ color: Colors.error }}
                    icon={faTrash}
                    onClick={() => { this.deleteGrupo() }}
                />
            </div>
        );

        return (
            <div className="titleModal">
                {titleLeft}
                {titleRight}
            </div>
        );
    }

    renderBody() {        
        return (
            <div className="bodyModal">
                {this.renderEditNomeGrupo()}
                {this.renderListIndicadores()}
                {this.renderOperacaoFaixaGrupo()}
            </div>
        )
    }

    renderEditNomeGrupo() {

        let inputName = this.state.grupo === true || this.state.editName ? true : false;

        if (!inputName) return null;

        return (
            <>
                <div className="labelBody">
                    <span className="title">{this.message().label_name}</span>
                    <div className="title-line"></div>
                </div>

                <Form className="listInputName">
                    <Row className="w-100">
                        {IdiomaHelper.getIdiomas().map((idioma, index) => {
                            return (
                                <Form.Group className='mb-2' as={Col} xs={12} md={4} key={`idioma-list-${idioma.sigla}`}>
                                    <Form.Label className="form-label-xsm space-between">
                                        {idioma.nome}
                                    </Form.Label>
                                    <Form.Control 
                                        type="text"
                                        ref={this.state.inputRefs[index]}
                                        onKeyDown={(e) => this.handleKeyDown(e, index)}
                                        onBlur={(e) => this.saveGrupo()}
                                        placeholder={this.message(idioma.sigla).placeholder_groupName}
                                        value={this.state.grupo?.textos?.find(desc => desc.id_idioma === idioma.id)?.descricao || ''}
                                        onChange={(e) => {
                                            const newDescricao = e.target.value;
                                            this.setState(prevState => ({
                                                grupo: {
                                                    ...prevState.grupo,
                                                    textos: prevState.grupo.textos.map(texto => {
                                                        if (texto.id_idioma === idioma.id) {
                                                            return { ...texto, descricao: newDescricao };
                                                        }
                                                        return texto;
                                                    })
                                                }
                                            }));
                                        }}
                                    />
                                </Form.Group>
                            )
                        })}
                    </Row>
                </Form>
            </>
        )
    }

    renderListIndicadores() {
        const renderListIndicadores = () => {

            if (this.state.loading) return (
                <div className="listIndicadores" style={{...EssentialStyle.rowFlexCenter, height: '100%'}}>
                    <DefaultLoader size={32}/>
                </div>
            )
            return (
                this.state.grupo?.indicadores?.length > 0 ?
                    <div className="listIndicadores">
                        {this.state.grupo?.indicadores?.map(indicadorReferencia => {
                            return (
                                <IndicadorReferenciaCard
                                    key={`indicadorReferencia-${indicadorReferencia.indicador.id}`}
                                    periodoIndicadorReferencia={indicadorReferencia}
                                    afterDeletePeriodoIndicador={() => this.loadGrupo()}
                                    afterUnlinkIndicador={() => this.loadGrupo()}
                                    lang={this.props.lang}
                                />
                            )
                        })}
                    </div>
                    :
                    <div className="emptyList">
                        <span>{this.message().emptyList}</span>
                    </div>
            );
        }

        const renderListEditIndicadores = () => {
            return (
                <div className="listIndicadores">
                    {this.state.listPeriodoIndicadores?.map(indicadorReferencia => {
                        return (
                            <IndicadorReferenciaCard
                                key={`indicadorReferencia-${indicadorReferencia.indicador.id}`}
                                periodoIndicadorReferencia={indicadorReferencia}
                                lang={this.props.lang}
                                isCheckBox={true}
                                idGrupo={this.state.grupo.id}
                                afterUnlinkIndicador={() => {this.loadGrupo()}}
                                afterLinkIndicador={() => {this.loadGrupo()}}
                            />
                        );
                    })}
                </div>
            );
        }

        let editIndicadores = this.state.editIndicadores ? true : false;
        
        return (
            <>
                <div className="labelBody">
                    <span className="title">{this.state.editIndicadores ? this.message().label_editIndicadores : this.message().label_indicadores}</span>
                    <div className="title-line"></div>

                    {this.state.loadReload ?
                        <DefaultLoader size={16}/>
                        :
                        <FontAwesomeIcon 
                            className={"editIndicadores hoverScale"} 
                            icon={faEdit} 
                            style={{ marginLeft: 10, cursor: 'pointer' }} 
                            onClick={() => {this.switchEditMode()}} 
                        />
                    }
                </div>

                {!editIndicadores ? renderListIndicadores() : renderListEditIndicadores()}
            </>
        )
    }

    renderOperacaoFaixaGrupo() {
        
        let idGrupo = this.state.grupo?.id || this.state.idGrupo;

        if (idGrupo) return (
            <>
                <div className="labelBody">
                    <span className="title">{this.message().label_operacao}</span>
                    <div className="title-line"></div>
                </div>

                <Operacao
                    ref={this.operacoesRef}
                    styles={{ content: { width: '100%', padding: 0 }}}
                    id_diagnostico_periodo_grupo={idGrupo}
                />
            </>
        )
    }

    forceOperacoesFunction() {
        if (this.operacoesRef.current && this.operacoesRef.current.reload) {
            this.operacoesRef.current.reload();
        }
    }

    renderFooter() {
        return (
            <div style={{...EssentialStyle.rowFlexEnd, width: '100%', marginTop: 10}}>
                <DefaultButton 
                    title={this.message().close}
                    color={Colors.dark}
                    textColor={Colors.white}
                    onClick={() => this.props.closeModal(false)}
                />
            </div>
        )
    }

    render() {
        return (
            <Modal 
                className={"ModalPeriodoGrupoIndicadores"} 
                style={{
                    width: '90vw',
                    minWidth: '90vw',
                    height: '90vh', 
                    transition: 'height 0.5s ease', 
                    boxShadow: '0px 0px 20px 20px rgba(50, 50, 50, 0.5)', 
                    justifyContent: 'space-between'
                }} 
                close={this.props.closeModal}
            >
                {this.renderTitle()}
                {this.renderBody()}
                {this.renderFooter()}
            </Modal>
        );
    }
}
