import React from "react";
import SessionHelper from "../../../../../helper/SessionHelper";
import Colors from "../../../../../constants/Colors";
import EssentialStyle from "../../../../../style/EssentialStyle";
import DefaultLoader from "../../../../tools/DefaultLoader";
import DefaultButton from "../../../../tools/DefaultButton";
import { Form } from "react-bootstrap";
import Sig from "../../../../../api/Sig";
import ChartHelper from "../../../../../helper/inicio/ChartHelper";
import CustomTooltip from "../../../../tools/CustomTooltip";
import Highcharts from "highcharts/highcharts.js";
import HighchartsReact from "highcharts-react-official";
import variablePie from "highcharts/modules/variable-pie.js";
import DatePicker from "react-datepicker";
import { forwardRef } from 'react';
import moment from "moment";
import { toast } from 'react-toastify';
import Constants from "../../../../../constants/Api";
import UserAvatar from "../../../../tools/UserAvatar";
import PlanoAcao from "./PlanoAcao";
import LayoutHelper from "../../../../../helper/LayoutHelper";
import EllipsisText from "../../../../tools/EllipsisText";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, 
         faMaximize, 
         faMinimize, 
         faDollarSign, 
         faFileCircleMinus, 
         faFileCircleXmark, 
         faFileCircleCheck,  
         faLink, 
         faLinkSlash, 
         faChevronLeft,
         faHourglassStart,
         faHourglassEnd,
         faSquareCaretUp, 
         faSquareCaretDown,
         faSquareMinus,
         faSquare,
         faSquareXmark,
} from "@fortawesome/free-solid-svg-icons";
import { faArrowLeft, faFilter, faSearch, faBroom } from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import { Col, Row } from "react-bootstrap";
import DataHelper from "../../../../../helper/DataHelper";
import { withTranslation } from "react-i18next";

const gateway = Constants.getSigEndPoint();
variablePie(Highcharts);

class MeusPlanosDeAcao extends React.Component {

    state = {
        data_inicio: moment().subtract(1, 'years').toDate(),
        planosColaborador: [],
        totalPlanos: {},
        data: [],
        id_colaborador: this.props.userId,
        status: [
            "Não Iniciado",
            "Em Desenvolvimento",
            "Concluído",
            "Atrasado",
            "Cancelado",
        ],
        loading: false,
        transform: 'translateX(+100%)',
        opacity: 0,
        expanded: this.props.expandedPageOne,
        dateToShow: "fim",
        planoSelecionado: null,
        filterGrafico: [],
        isSmallScreen: LayoutHelper.isSmallScreen(),
        advancedFilter: false,
        filtro: {
            nome: null,
            tipoVinculo: null,
            tipo: null,
            tags: null,
        },
        filtroOptions: {
            tags: [],
            tipoVinculo: [],
            tipo: [],
        }
    }

    async componentDidMount() {
        await this.props.i18n.loadNamespaces(['client_src_components_modules_inicio_charts_meusPlanosAcao_MeusPlanosDeAcao']);
        window.addEventListener('resize', this.verifyScreen);
        await this.getInfoColaborador();
    }

    async componentDidUpdate(prevProps) {
        if (this.props.userId && this.props.userId !== prevProps.userId) {
            await this.getInfoColaborador();
        }

        if(this.state.expanded !== this.props.expandedPageOne)
            this.setState({ expanded: this.props.expandedPageOne });

        if(this.props.userId && this.props.userId != this.state.id_colaborador){
            this.setState({ id_colaborador: this.props.userId, planoSelecionado: null });
        }
        
        // Revisar ao implementar segunda parte da pagina
        if(this.props.expandedPageOne != false && this.state.isSmallScreen){
            this.setState({ expanded: false}, () => { this.props.expandCallbackPageOne(false)});
        }
        if(this.props.expandedPageOne === false && !this.state.isSmallScreen){
            this.setState({ expanded: "MeusPlanosAcao"}, () => { this.props.expandCallbackPageOne("MeusPlanosAcao")});
        }
        
    }

    verifyScreen = () => {
        this.setState({ isSmallScreen: LayoutHelper.isSmallScreen() });    
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.verifyScreen);
    }

    getInfoColaborador = async () => {
        try {
            this.setState({ loading: true, transform: 'translateY(0)', opacity: 1, advancedFilter: false });

            let { planosColaborador, totalPlanos, tags, tipoVinculo, tipo } = await Sig.request('GET', 'config/colaborador/getPlanosAcao', { 
                id_colaborador: this.props.userId, 
                data_inicio: moment(this.state.data_inicio).format('YYYY-MM-DD HH:mm:ss'),
                filtro: JSON.stringify(this.state.filtro),
            });

            let tipoVinculoList = Object.keys(tipoVinculo).map(key => ({
                id: key,
                nome: tipoVinculo[key]
            }));

            let tipoList = [];
            for (let tipoElement in tipo) {
                let chave = tipoElement;
                let valor = tipo[tipoElement];

                tipoList.push({id: chave, nome: valor})
            }

            let filtroOptions = {
                tags: DataHelper.formatSelectData(tags, 'id', 'nome'),
                tipoVinculo: DataHelper.formatSelectData(tipoVinculoList, 'id', 'nome'),
                tipo: DataHelper.formatSelectData(tipoList, 'id', 'nome'),
            }

            this.setState({ planosColaborador, totalPlanos, filtroOptions}, () => {
                this.calculaGrafico();
            });

        } catch (error) {
            toast.error(this.props.t('client_src_components_modules_inicio_charts_meusPlanosAcao_MeusPlanosDeAcao:error_fetching_team'));
        }
    }

    calculaGrafico(){
        this.setState({ loading: true});
        let data = [];

        let planosColaborador = this.state.planosColaborador;

        this.state.status.forEach(status => {
            let planosStatus = planosColaborador.filter(plano => plano.status === status);
            data.push({
                name: status,
                y: planosStatus.length || 0,
                yPercent: Math.round((planosStatus.length / planosColaborador.length) * 100),
                color: ChartHelper.getColor(status),
            });
        });

        this.setState({ data, loading: false });
    }

    renderGrafico() {
        let that = this;
        return (
            <div
                style={{ display: 'flex', width: '100%', height: `${this.state.isSmallScreen ? "400px" : "100%"}`, justifyContent: 'center'}}
            >
                <HighchartsReact
                    highcharts={Highcharts}
                    constructorType={'chart'}
                    containerProps={{ style: { height: "100%", width: "70%" } }}
                    options={{
                        chart: {
                            type: 'variablepie',
                            backgroundColor: 'transparent',
                            margin: [`${this.state.isSmallScreen ? 10 : 30}`, null, null, null]
                        },
                        title: {
                            text: "",
                        },
                        tooltip: {
                            borderWidth: 0,
                            backgroundColor: 'none',
                            shadow: false,
                            pointFormat: '<span style="font-size: 2em; text-align: center; color: {point.color}; font-weight: bold">{point.yPercent}%</span>',
                            positioner: function (labelWidth) {
                                return {
                                    x: 0,
                                    y: `${that.state.isSmallScreen ? 0 : (this.chart.plotHeight-10)}`
                                };
                            },
                            formatter: function () {
                                const translatedName = that.props.t(`client_src_components_modules_inicio_charts_meusPlanosAcao_MeusPlanosDeAcao:status.${this.point.name.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").replaceAll(' ', '_')}`);
                                return `<span>${translatedName}</span><br><span style="font-size: 2em; text-align: center; color: ${this.point.color}; font-weight: bold">${this.point.yPercent}%</span>`;
                            }
                        },
                        legend: {
                            align: `${this.state.isSmallScreen ? "center" : "right"}`,
                            verticalAlign: `${this.state.isSmallScreen ? "bottom" : "middle"}`,
                            layout: 'vertical',
                            floating: false,
                            enabled: true,
                            labelFormatter: function () {
                                return that.props.t(`client_src_components_modules_inicio_charts_meusPlanosAcao_MeusPlanosDeAcao:status.${this.name.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").replaceAll(' ', '_')}`) + ': ' + this.y
                            }
                        },
                        plotOptions: {
                            variablepie: {
                                dataLabels: {
                                    enabled: false,
                                },
                                point: {
                                    events: {
                                        legendItemClick: function () {
                                            let filterGrafico = [...that.state.filterGrafico];

                                            if (filterGrafico.includes(this.name)) {
                                                filterGrafico = filterGrafico.filter((item) => item !== this.name);
                                            } else {
                                                filterGrafico.push(this.name);
                                            }

                                            that.setState({ filterGrafico });
                                        }
                                    }
                                }
                            }
                        },
                        series: [{
                            minPointSize: 40,
                            showInLegend: true,
                            innerSize: '65%',
                            borderRadius: 1,
                            data: this.state.data
                        }],
                        credits: {
                            enabled: false
                        },
                    }}
                />
            </div>
        )
    }

    getCertificacaoIcons(){
        return {
            'Não Eficaz': <FontAwesomeIcon icon={faSquareCaretDown} style={{ fontSize: 22, color: "#d62728" }} />,
            'Parcialmente Eficaz': <FontAwesomeIcon icon={faSquareMinus} style={{ fontSize: 22, color: Colors.danger }} />,
            'Eficaz': <FontAwesomeIcon icon={faSquareCaretUp} style={{ fontSize: 22, color: "green" }} />,
            'Não Avaliado': <FontAwesomeIcon icon={faSquareXmark} style={{ fontSize: 22, color: Colors.homePage.lightGrey }} />,
        }
    }

    renderColaborador() {
        let planosColaborador = this.state.planosColaborador;
        
        if(planosColaborador === undefined || planosColaborador.length === 0) 
            return null;

        return( 
            <div style={{ width: "100%", minWidth: "350px", padding: '5px 5px 10px 3px', height: "90%"}}>
                <div style={{ alignItems: "center", padding: "5px 0px", display: "flex", flexDirection: "row", minWidth: `${this.state.isSmallScreen ? "100%" : "calc(100% - 15px)"}`, width: `${this.state.isSmallScreen ? "100%" : "calc(100% - 15px)"}`,
                            fontSize: `${this.state.isSmallScreen ? "12px" : "14px"}`, fontStyle: "normal", color: "#828282", borderBottom: `0.5px solid ${Colors.homePage.lightGrey}`}}>
                    <div style={{width: "8%", maxWidth: "60px", minWidth: `${this.state.isSmallScreen ? "35px" : "unset"}`, display: "flex", justifyContent: "center", alignItems: "center"}}>
                        {this.props.t('client_src_components_modules_inicio_charts_meusPlanosAcao_MeusPlanosDeAcao:status_text')}
                    </div>
                    <div style={{width: `${this.props.expandedPageOne == "MeusPlanosAcao" ? "18%" : "33%"}`, flexGrow: "1", display: "flex", justifyContent: "center", alignItems: "center"}}>
                        {this.state.isSmallScreen ? this.props.t('client_src_components_modules_inicio_charts_meusPlanosAcao_MeusPlanosDeAcao:planos') : this.props.t('client_src_components_modules_inicio_charts_meusPlanosAcao_MeusPlanosDeAcao:plano_de_acao')}
                    </div>
                    <div style={{width: "10%", maxWidth: "75px", minWidth: `${this.state.isSmallScreen ? "42px" : "unset"}`, display: "flex", justifyContent: "center", alignItems: "center"}}>
                        {this.props.t('client_src_components_modules_inicio_charts_meusPlanosAcao_MeusPlanosDeAcao:etapas')}
                    </div>
                    {this.props.expandedPageOne == "MeusPlanosAcao" ?
                        (<>
                        <div style={{width: "15%", maxWidth: "115px", display: "flex", justifyContent: "center", alignItems: "center"}}>
                            {this.props.t('client_src_components_modules_inicio_charts_meusPlanosAcao_MeusPlanosDeAcao:inicio')}
                        </div>
                        <div style={{width: "15%", maxWidth: "115px", display: "flex", justifyContent: "center", alignItems: "center"}}>
                            {this.props.t('client_src_components_modules_inicio_charts_meusPlanosAcao_MeusPlanosDeAcao:fim')}
                        </div>
                        </>) : 
                        (<div style={{width: "15%", maxWidth: "115px", gap: "2px", minWidth: `${this.state.isSmallScreen ? "65px" : "unset"}`, display: "flex", justifyContent: "center", alignItems: "center"}}>
                            <div style={{textAlign: "center"}}>
                                {this.state.dateToShow == 'fim' ? this.props.t('client_src_components_modules_inicio_charts_meusPlanosAcao_MeusPlanosDeAcao:fim') : this.props.t('client_src_components_modules_inicio_charts_meusPlanosAcao_MeusPlanosDeAcao:inicio')}
                            </div>
                            <DefaultButton
                                color={Colors.dark}
                                tooltip={this.props.t('client_src_components_modules_inicio_charts_meusPlanosAcao_MeusPlanosDeAcao:mudar_para_data_de', { variable: this.state.dateToShow == 'fim' ? this.props.t('client_src_components_modules_inicio_charts_meusPlanosAcao_MeusPlanosDeAcao:inicio') : this.props.t('client_src_components_modules_inicio_charts_meusPlanosAcao_MeusPlanosDeAcao:fim') })}
                                tooltipPlacement={"top"}
                                leftIcon={<FontAwesomeIcon icon={this.state.dateToShow == 'fim' ? faHourglassEnd : faHourglassStart} size="xs"/>}
                                style={{ width: 20, height: 20, display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: "transparent", 
                                        border: `1.5px solid ${Colors.homePage.grey}`, color: Colors.homePage.grey, borderRadius: "50%", padding: 0, marginBottom: 0}}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    this.setState({dateToShow: this.state.dateToShow == 'fim' ? "inicio" : "fim" });
                                }}
                            />
                        </div>)
                    }
                    <div style={{width: "12%", maxWidth: "90px", display: "flex", justifyContent: "center", alignItems: "center"}}>
                        <CustomTooltip tooltip={this.props.t('client_src_components_modules_inicio_charts_meusPlanosAcao_MeusPlanosDeAcao:responsavel')} placement="top" style={{alignItems: "center", width: "calc(100%)"}}>
                            <div style={{ width: "100%", display: "flex"}}>
                                <span style={{textAlign: "center", width: "100%", textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden"}}>{this.props.t('client_src_components_modules_inicio_charts_meusPlanosAcao_MeusPlanosDeAcao:responsavel')}</span>
                            </div>
                        </CustomTooltip>                     
                    </div>
                    <div style={{width: "22%", maxWidth: "165px", minWidth: `${this.state.isSmallScreen ? "85px" : "unset"}`, display: "flex", justifyContent: "center", alignItems: "center"}}>
                    </div>
                </div>
                <div style={{ width: "100%", height: "100%", overflowY: `${this.state.isSmallScreen ? "hidden" : "auto"}`, scrollbarGutter: `${this.state.isSmallScreen ? "unset" : "stable"}`, boxSizing: "border-box" }}>
                {planosColaborador.map((plano) => {
                    if (!this.state.filterGrafico.includes(plano.status)) {
                        var inicioText = this.props.t('client_src_components_modules_inicio_charts_meusPlanosAcao_MeusPlanosDeAcao:sem_data');
                        var fimText = this.props.t('client_src_components_modules_inicio_charts_meusPlanosAcao_MeusPlanosDeAcao:sem_data');
                        if(plano.data_inicio != null && plano.data_inicio !== "0000-00-00" && plano.data_inicio != 0){
                            inicioText = new Date(plano.data_inicio).toLocaleDateString({}, { day: "numeric", month: "short", year: "numeric", localeMatcher: "best fit" });
                            inicioText = inicioText.replaceAll("de ", "");
                            inicioText = inicioText.replace(".", "");
                        }
                        if(plano.data_fim != null && plano.data_fim !== "0000-00-00" && plano.data_fim != 0){
                            fimText = new Date(plano.data_fim).toLocaleDateString({}, { day: "numeric", month: "short", year: "numeric", localeMatcher: "best fit" });
                            fimText = fimText.replaceAll("de ", "");
                            fimText = fimText.replace(".", "");
                        }
                        
                        return (
                            <div key={plano.id} a-key={plano.id} style={{ display: "flex", margin: "0px", borderBottom: `0.5px solid ${Colors.homePage.lightGrey}`, alignItems: "center", 
                            fontSize: `${this.state.isSmallScreen ? "12px" : "14px"}`, fontStyle: "normal" , color: "#828282", cursor: "pointer", height: `${this.state.isSmallScreen ? "80px" : "40px"}`, minWidth: "100%"}}
                            onClick={(e) => {
                                let planoSelecionado = planosColaborador.find(planoAtual => e.currentTarget?.getAttribute("a-key") === planoAtual.id);
                                this.setState({planoSelecionado});
                            }}>
                                <div style={{width: "8%", maxWidth: "60px", minWidth: `${this.state.isSmallScreen ? "35px" : "unset"}`, display: "flex", justifyContent: "center", alignItems: "center"}}>
                                    <div style={{display: "flex", justifyContent: "center", height: "30px", alignItems: "center"}}>
                                        <CustomTooltip tooltip={this.props.t(`client_src_components_modules_inicio_charts_meusPlanosAcao_MeusPlanosDeAcao:status.${plano.status.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").replaceAll(' ', '_')}`)} placement="top">
                                            <div style={{width: 20, height: 20, backgroundColor: ChartHelper.getColor(plano.status), borderRadius: 5}} />
                                        </CustomTooltip>
                                    </div>
                                </div>
                                <div style={{width: `${this.props.expandedPageOne == "MeusPlanosAcao" || this.state.isSmallScreen ? "18%" : "33%"}`, flexGrow: "1", display: "flex", justifyContent: "center", alignItems: "center"}}>
                                    <div style={{display: "flex", height:`${this.state.isSmallScreen ? "50px" : "25px"}`, width:"calc(100%)", fontSize: `${this.state.isSmallScreen ? "12px" : "14px"}`, color: "black", textAlign: "left", fontWeight: "500"}}>
                                        <EllipsisText text={plano.nome} />
                                    </div>
                                </div>
                                <div style={{width: "10%", maxWidth: "75px", minWidth: `${this.state.isSmallScreen ? "42px" : "unset"}`, display: "flex", flexDirection: `${this.state.isSmallScreen ? "column" : "row"}`, justifyContent: "center", alignItems: "center", borderCollapse: "separate"}}>
                                    <CustomTooltip tooltip={this.props.t('client_src_components_modules_inicio_charts_meusPlanosAcao_MeusPlanosDeAcao:concluidas')} placement="top">
                                        <div style={{width: 30, height: 25, backgroundColor: "green", borderRadius: `${this.state.isSmallScreen ? "5px 5px 0px 0px": "5px 0px 0px 5px"}`, color: "black", margin: "auto",
                                                    border: `0.5px solid ${Colors.homePage.lightGrey}`, textAlign: "center", alignItems: "center", alignContent: "center"}}>
                                            {plano.qtdEtapasCompletas}
                                        </div>
                                    </CustomTooltip>
                                    <CustomTooltip tooltip={this.props.t('client_src_components_modules_inicio_charts_meusPlanosAcao_MeusPlanosDeAcao:total')} placement="top">
                                        <div style={{width: 30, height: 25, backgroundColor: Colors.homePage.lightGrey, borderRadius: `${this.state.isSmallScreen ? "0px 0px 5px 5px": "0px 5px 5px 0px"}`, color: "black",
                                                    border: `0.5px solid ${Colors.homePage.lightGrey}`, textAlign: "center", alignItems: "center", alignContent: "center"}}>
                                            {plano.qtdEtapas}
                                        </div>
                                    </CustomTooltip>
                                </div>
                                {this.props.expandedPageOne == "MeusPlanosAcao" ?
                                    (<>
                                    <div style={{width: "15%", maxWidth: "115px", display: "flex", justifyContent: "center", alignItems: "center"}}>
                                        {inicioText}
                                    </div>
                                    <div style={{width: "15%", maxWidth: "115px", display: "flex", justifyContent: "center", alignItems: "center"}}>
                                        {fimText}
                                    </div>
                                    </>) : 
                                    (<div style={{width: "15%", maxWidth: "115px", padding: `${this.state.isSmallScreen ? "0px 5px" : "0px 0px"}`, minWidth: `${this.state.isSmallScreen ? "65px" : "unset"}`, display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center"}}>
                                        {this.state.dateToShow == 'fim' ? fimText : inicioText}
                                    </div>)
                                }
                                <div style={{width: "12%", maxWidth: "90px", display: "flex", justifyContent: "center", alignItems: "center"}}>
                                    <UserAvatar size={35} id={plano.responsavel.id} placement={'top'}/>
                                </div>
                                <div style={{width: "22%", maxWidth: "165px", minWidth: `${this.state.isSmallScreen ? "85px" : "unset"}`, display: "flex", maxHeight: `${this.state.isSmallScreen ? "55px" : "30px"}`, flexWrap: `${this.state.isSmallScreen ? "wrap" : "nowrap"}`, flexDirection: `${this.state.isSmallScreen ? "column" : "row"}`, gap: `${this.state.isSmallScreen ? "5px" : "0px"}`, justifyContent: "space-between", alignItems: "center", textAlign: "center", paddingRight: "5px", paddingLeft: "5px"}}>
                                    <CustomTooltip tooltip={this.props.t(plano.qtdVinculos == 0 ? 'client_src_components_modules_inicio_charts_meusPlanosAcao_MeusPlanosDeAcao:nao_possui_vinculos' : 'client_src_components_modules_inicio_charts_meusPlanosAcao_MeusPlanosDeAcao:possui_vinculos')} placement="top">
                                        <div style={{display: "flex", justifyContent: "center", width: "20px", height: "20px", alignItems: "center"}}>    
                                            <FontAwesomeIcon style={{ fontSize: 14, color:`${plano.qtdVinculos == 0 ? "lightGray": "black" }` }} icon={ plano.qtdVinculos == 0 ? faLinkSlash : faLink} >
                                            </FontAwesomeIcon>
                                        </div>
                                    </CustomTooltip>
                                    <CustomTooltip tooltip={this.props.t('client_src_components_modules_inicio_charts_meusPlanosAcao_MeusPlanosDeAcao:certificacao', { variable: plano.certificacao })} placement="top">
                                        <div style={{display: "flex", justifyContent: "center", width: "20px", height: "20px", alignItems: "center"}}>    
                                            {this.getCertificacaoIcons()[plano.certificacao]}
                                        </div>
                                    </CustomTooltip>
                                    <CustomTooltip tooltip={this.props.t(plano.avaliacao == 0 ? 'client_src_components_modules_inicio_charts_meusPlanosAcao_MeusPlanosDeAcao:nao_avaliado' : plano.avaliacao == 1 ? 'client_src_components_modules_inicio_charts_meusPlanosAcao_MeusPlanosDeAcao:rejeitado' : 'client_src_components_modules_inicio_charts_meusPlanosAcao_MeusPlanosDeAcao:aprovado')} placement="top">
                                        <div style={{display: "flex", justifyContent: "center", width: "20px", height: "20px", alignItems: "center"}}>    
                                            <FontAwesomeIcon style={{ fontSize: 20, color:`${plano.avaliacao == 0 ? "lightGray": plano.avaliacao == 1 ? "#d62728" : "green"}` }} icon={ plano.avaliacao == 0 ? faFileCircleMinus : plano.avaliacao == 1 ? faFileCircleXmark : faFileCircleCheck} >
                                            </FontAwesomeIcon>
                                        </div>
                                    </CustomTooltip>
                                    <CustomTooltip tooltip={this.props.t(plano.orcamento == 0 ? 'client_src_components_modules_inicio_charts_meusPlanosAcao_MeusPlanosDeAcao:nenhum_orcamento_vinculado' : 'client_src_components_modules_inicio_charts_meusPlanosAcao_MeusPlanosDeAcao:orcamento_vinculado')} placement="top">
                                        <div style={{borderRadius: "3px", border:`2px ${plano.orcamento == 0 ? "lightGray": "black"} solid`, display: "flex", justifyContent: "center",
                                                    width: "20px", height: "20px", alignItems: "center"}}>
                                            <FontAwesomeIcon style={{ fontSize: 14, color:`${plano.orcamento == 0 ? "lightGray": "black"}` }} icon={faDollarSign} >
                                            </FontAwesomeIcon>
                                        </div>
                                    </CustomTooltip>
                                    <div style={{display: "flex", justifyContent: "center", alignItems: "center", width: "20px", height: `${this.state.isSmallScreen ? "45px" : "20px"}`}}>
                                        <DefaultButton
                                            color={Colors.dark}
                                            tooltip={this.props.t('client_src_components_modules_inicio_charts_meusPlanosAcao_MeusPlanosDeAcao:ir_para_plano_de_acao')}
                                            tooltipPlacement={"top"}
                                            leftIcon={<FontAwesomeIcon icon={faEye} size="xs"/>}
                                            style={{ width: 20, height: 20, display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 0, margin: 0}}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                window.parent.postMessage({ type: '_blank', url: `/planoAcao/detalhar/${plano.codigo}` }, '*')
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        )
                    }
                })}
                </div>
            </div>)
    }

    renderDatePickerInicio() {
        const CustomFormInput = forwardRef(({ value, onClick }, ref) => (
            <Form.Control 
                onChange={() => {}}
                onClick={onClick} 
                    ref={ref}
                    style={{ marginLeft: 10, fontSize: 14, color: Colors.homePage.darkGrey, cursor: 'pointer', boxShadow: 'none', backgroundColor: 'transparent', padding: 1, height: '100%', width: 100,  textAlign: 'center'}}
                    value={value} >
            </Form.Control>
        ));
        
        return (
            <DatePicker
                selected={this.state.data_inicio}
                type="date"
                onChange={(event) => {
                    let novoInicio = moment(event);
                    if (novoInicio.toDate() !== this.state.data_inicio){
                        this.setState({ data_inicio: novoInicio.toDate()}, () => {
                            this.getInfoColaborador();
                        });
                    }
                }}
                dateFormat="MM/yyyy"
                showMonthYearPicker
                customInput={<CustomFormInput />}
                popperPlacement="top-start"
            />
        )
    };

    renderAdvancedFilter(){
        return (
            <div style={{...EssentialStyle.rowSpaceBetween, alignItems: 'start', width: '100%', maxHeight: 300, height: '40%', minHeight: 200, padding: 10, overflow: 'auto', position: "absolute", zIndex: 2, backgroundColor: Colors.homePage.lightGrey, marginTop: 40}}>
                <div style={{ ...EssentialStyle.columnSpaceBetween, alignItems: 'center',  width: 'calc(100% - 15px)', height: '100%' }}>
                    <Form className="w-100">
                        <Row className="m1-2" style={{ marginBottom: 5 }}>
                            <Form.Group as={Col}>
                                <Form.Label className="form-label-xsm">{this.props.t('client_src_components_modules_inicio_charts_meusPlanosAcao_MeusPlanosDeAcao:nome_do_plano_de_acao')}</Form.Label>
                                    <Form.Control 
                                        className="form-control-xsm" 
                                        type="text" 
                                        placeholder={this.props.t('client_src_components_modules_inicio_charts_meusPlanosAcao_MeusPlanosDeAcao:placeholder_nome')}
                                        value={this.state.filtro.nome || ''} 
                                        onChange={(event) => { 
                                            this.setState(prevState => ({
                                                filtro: {
                                                    ...prevState.filtro,
                                                    nome: event.target.value
                                                }
                                            })); 
                                        }} 
                                    />
                            </Form.Group>

                            <Form.Group as={Col}>
                                <Form.Label className="form-label-xsm">{this.props.t('client_src_components_modules_inicio_charts_meusPlanosAcao_MeusPlanosDeAcao:tipo_de_plano_de_acao')}</Form.Label>
                                <Select 
                                    className="form-control-xsm" 
                                    options={this.state.filtroOptions.tipo} 
                                    placeholder={this.props.t('client_src_components_modules_inicio_charts_meusPlanosAcao_MeusPlanosDeAcao:selecione_um_tipo')} 
                                    noOptionsMessage={DataHelper.getSelectEmptyMessage} 
                                    isClearable 
                                    isSearchable
                                    isMulti
                                    value={this.state.filtro.tipo}
                                    onChange={(value) => { 
                                        this.setState(prevState => ({
                                            filtro: {
                                                ...prevState.filtro,
                                                tipo: value
                                            }
                                        })); 
                                    }}
                                    menuPortalTarget={document.body}
                                />
                            </Form.Group>
                        </Row>

                        <Row className="m1-2">
                            <Form.Group as={Col}>
                                <Form.Label className="form-label-xsm">{this.props.t('client_src_components_modules_inicio_charts_meusPlanosAcao_MeusPlanosDeAcao:vinculo_com_resultados')}</Form.Label>
                                <Select 
                                className="form-control-xsm" 
                                options={this.state.filtroOptions.tipoVinculo} 
                                placeholder={this.props.t('client_src_components_modules_inicio_charts_meusPlanosAcao_MeusPlanosDeAcao:selecione_um_vinculo')} 
                                noOptionsMessage={DataHelper.getSelectEmptyMessage} 
                                isClearable 
                                isSearchable
                                isMulti
                                value={this.state.filtro.tipoVinculo}
                                onChange={(value) => { 
                                    this.setState(prevState => ({
                                        filtro: {
                                            ...prevState.filtro,
                                            tipoVinculo: value
                                        }
                                    })); 
                                }}
                                menuPortalTarget={document.body} />
                            </Form.Group>
                            
                            <Form.Group as={Col}>
                                <Form.Label className="form-label-xsm">{this.props.t('client_src_components_modules_inicio_charts_meusPlanosAcao_MeusPlanosDeAcao:tags')}</Form.Label>
                                <Select 
                                className="form-control-xsm" 
                                options={this.state.filtroOptions.tags} 
                                placeholder={this.props.t('client_src_components_modules_inicio_charts_meusPlanosAcao_MeusPlanosDeAcao:selecione_uma_tag')}
                                noOptionsMessage={DataHelper.getSelectEmptyMessage} 
                                isClearable 
                                isSearchable
                                isMulti
                                value={this.state.filtro.tags} 
                                onChange={(value) => { 
                                    this.setState(prevState => ({
                                        filtro: {
                                            ...prevState.filtro,
                                            tags: value
                                        }
                                    })); 
                                }}
                                menuPortalTarget={document.body} />
                            </Form.Group>                            
                        </Row>
                    </Form>
                    
                    <div style={{ ...EssentialStyle.rowFlexEnd, alignItems: 'end', width: '100%', height: '100%', marginTop: 5 }}>
                        <DefaultButton
                            leftIcon={<FontAwesomeIcon icon={faBroom} />}
                            title={this.props.t('client_src_components_modules_inicio_charts_meusPlanosAcao_MeusPlanosDeAcao:limpar')}
                            color={Colors.error}
                            textColor={ 'white' }
                            onClick={() => { 
                                this.setState({ filtro: { nome: null, tipoVinculo: null, tipo: null, tags: null } }, () => {
                                    this.getInfoColaborador();
                                })
                            }}
                        />

                        <DefaultButton
                            leftIcon={<FontAwesomeIcon icon={faSearch} />}
                            title={this.props.t('client_src_components_modules_inicio_charts_meusPlanosAcao_MeusPlanosDeAcao:filtrar')}
                            color={SessionHelper.getColor()}
                            textColor={ 'white' }
                            style={{ marginLeft: 5 }}
                            onClick={() => { this.getInfoColaborador() }}
                        />
                    </div>
                </div>
            </div>
        )
    }

    renderLoading(bloco) {
        const trackColors = Highcharts.getOptions().colors.map(color =>
            new Highcharts.Color(color).setOpacity(0.3).get()
        );

        if (bloco === 2) {
            return (
                // <div style={{...EssentialStyle.columnStart, width: '100%', overflow: 'hidden'}}>
                //     {Array.from({ length: this.props.numberSubordinados }).map((elem, index) => (
                //         <div key={`plhdr-${index}`} style={{ marginTop: 5, width: '100%' }}>
                //             {this.renderLoadingPlaceholder()}
                //         </div>
                //     ))}
                // </div>
                <></>
            );
        } else {
            return (
                <div style={{...EssentialStyle.columnCenter, width: '100%', height: '100%'}}>
                    <DefaultLoader size={50} />
                </div>
            );
        }

    }

    renderBody() {
        return (
            <div style={{...EssentialStyle.rowFlexStart, width: '100%', height: 'calc(100% - 40px)'}}>
                { 
                    (!this.state.loading && this.state.totalPlanos.total == 0) 
                        ? (
                            <div style={{ ...EssentialStyle.columnCenter, width: '100%', height: '100%', minHeight: `${this.state.isSmallScreen ? "70vh" : "300px"}`}}>
                                <img
                                    src={`${gateway}/img/undraw/undraw_teamwork.svg`}
                                    alt={this.props.t('client_src_components_modules_inicio_charts_meusPlanosAcao_MeusPlanosDeAcao:alt_pessoas_colando_postits')}
                                    style={{
                                        height: '85%',
                                        maxHeight: 200,
                                        width: 350,
                                    }}
                                />
                                <span style={{ ...EssentialStyle.titleBoxHomePage }}>
                                    {this.props.t('client_src_components_modules_inicio_charts_meusPlanosAcao_MeusPlanosDeAcao:nenhum_plano_de_acao_encontrado_1')} <strong>{this.props.t('client_src_components_modules_inicio_charts_meusPlanosAcao_MeusPlanosDeAcao:nenhum_plano_de_acao_encontrado_2')}</strong> {this.props.t('client_src_components_modules_inicio_charts_meusPlanosAcao_MeusPlanosDeAcao:nenhum_plano_de_acao_encontrado_3')}
                                </span>
                            </div>
                        ):( this.state.loading ? this.renderLoading(1)
                            :(  <>
                                    <div style={{ display: 'flex', width: '100%', height: '100%', flexDirection: "column"}}>
                                        <div style={{ display: 'flex', width: '100%', height: '40%', paddingTop: 10}}>
                                            { this.renderGrafico() }
                                        </div>
                                        <div style={{ display: 'flex', width: 'calc(100% - 5px)', height: '55%', marginLeft: `${this.state.isSmallScreen ? "5px" : "10px"}` }}>
                                            { this.renderColaborador() }
                                        </div>
                                    </div>
                                </>))
                }
            </div>
        )
    }

    renderTitle() {
        return (
            <div 
                style={{
                    ...EssentialStyle.cardTitle, 
                    height: 40, 
                    borderBottom: `1px solid ${this.props.expandedPageOne != "MeusPlanosAcao" && this.props.expandedPageOne != false ? 'transparent' : Colors.homePage.line }`
                }}
            >
                { this.state.planoSelecionado == null ? null : 
                <DefaultButton
                    leftIcon={<FontAwesomeIcon icon={faChevronLeft} />}
                    color={'transparent'}
                    textColor={SessionHelper.getColor()}
                    style={{ margin: 0 }}
                    onClick={() => { this.setState({ planoSelecionado: null }); }}
                /> }
                <strong style={{ ...EssentialStyle.titleBoxHomePage, flexGrow: 1, height: "1.5em"}}>
                    <EllipsisText text={this.props.t('client_src_components_modules_inicio_charts_meusPlanosAcao_MeusPlanosDeAcao:meus_planos_de_acao', { variable: this.state.planoSelecionado != null ? " - " + this.state.planoSelecionado.nome : "" })} />
                </strong>
                <div style={{ ...EssentialStyle.rowFlexStart }}>

                    {
                        ( this.props.expandedPageOne == "MeusPlanosAcao" || this.props.expandedPageOne == false ) && this.state.planoSelecionado == null ?
                            <>
                                <DefaultButton
                                    leftIcon={ this.state.advancedFilter ? <FontAwesomeIcon icon={faArrowLeft} /> : <FontAwesomeIcon icon={faFilter} /> }
                                    tooltip={ this.state.advancedFilter ? this.props.t('client_src_components_modules_inicio_charts_meusPlanosAcao_MeusPlanosDeAcao:voltar') : this.props.t('client_src_components_modules_inicio_charts_meusPlanosAcao_MeusPlanosDeAcao:filtrar_planos') }
                                    tooltipPlacement={"top"}
                                    color={'transparent'}
                                    textColor={SessionHelper.getColor()}
                                    style={{ marginLeft: 5 }}
                                    onClick={() => { this.setState({ advancedFilter: !this.state.advancedFilter, viewCard: null })}}
                                />
                                <div style={{...EssentialStyle.rowFlexEnd, fontSize: 14, color: Colors.homePage.darkGrey, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}> 
                                    {this.props.t('client_src_components_modules_inicio_charts_meusPlanosAcao_MeusPlanosDeAcao:a_partir_de')}
                                    {this.renderDatePickerInicio()} 
                                </div>
                            </>
                            :
                            null
                    }
                    {/* Descomentar para habilitar o botão de maximizar/minimizar
                    
                    { this.state.isSmallScreen ? null :
                    <DefaultButton
                        leftIcon={this.props.expandedPageOne == "MeusPlanosAcao" ? <FontAwesomeIcon icon={faMinimize} /> : <FontAwesomeIcon icon={faMaximize} />}
                        color={'transparent'}
                        textColor={SessionHelper.getColor()}
                        style={{ marginLeft: 5 }}
                        onClick={() => { this.setState({ expanded: !this.state.expanded }, () => { this.props.expandCallbackPageOne(this.props.expandedPageOne != "MeusPlanosAcao" ? 'MeusPlanosAcao' : false) }); }}
                    />} */}
                </div>
            </div>
        )
    }

    renderContent() {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', height: '100%', minHeight: "410px", minWidth: `${this.props.expandedPageOne === "MeusPlanosAcao" ? "700px" : this.state.isSmallScreen ? "350px" : "650px"}` }}>
                { this.state.advancedFilter ? this.renderAdvancedFilter() : null }
                { this.renderTitle() }
                { this.state.planoSelecionado == null ? this.renderBody() : 
                <PlanoAcao 
                    userId={this.props.userId} 
                    expandCallbackPageOne={this.props.expandCallbackPageOne} 
                    expandedPageOne={this.props.expandedPageOne }
                    planoSelecionado={this.state.planoSelecionado}
                    isSmallScreen={this.state.isSmallScreen}/> }
            </div>
        )
    }

    render() {

        return (
            <div style={{
                transform: this.state.transform,
                opacity: this.state.opacity,
                transition: 'opacity .3s ease-in-out',
                width: '100%',
                height: '100%',
                padding: 10,
            }}>
                {this.renderContent()}
            </div>
        )
    }
}

export default withTranslation()(MeusPlanosDeAcao);
