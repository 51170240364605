import React from "react";
import { withTranslation } from "react-i18next";
import Sig from "../../../../api/Sig";
import { toast } from 'react-toastify';

import './PlanoAcaoTabelaOrcamento.css';
import DefaultLoader from "../../../tools/DefaultLoader";
import Colors from "../../../../constants/Colors";
import EssentialStyle from "../../../../style/EssentialStyle";
import PlanoAcaoEtapaCard from "../PlanoAcaoEtapaCard/PlanoAcaoEtapaCard";
import SessionHelper from "../../../../helper/SessionHelper";


class PlanoAcaoTabelaOrcamento extends React.Component {

    state = {
        loading: false,
        isSmallScreen: this.props.isSmallScreen,
        codigo: this.props.codigo || null,
        plano: this.props.plano || null,
        etapas: this.props.etapas || [],
        totalPrevisto: null,
        totalPrevistoValue: null,
        totalRealizado: null,
        totalRealizadoValue: null,
        saldoOrcamento: null,
        saldoOrcamentoValue: null,
    }

    componentDidUpdate(prevProps) {
        if (prevProps.codigo !== this.props.codigo) {
            this.setState({ codigo: this.props.codigo }, () => this.loadData());
        }
        if (prevProps.plano !== this.props.plano) {
            this.setState({ plano: this.props.plano }, () => this.loadData());
        }
        if (prevProps.etapas !== this.props.etapas) {
            this.setState({ etapas: this.props.etapas }, () => this.loadData());
        }
        if (prevProps.isSmallScreen !== this.props.isSmallScreen) {
            this.setState({ isSmallScreen: this.props.isSmallScreen });
        }
    }

    async componentDidMount() {
        await this.props.i18n.loadNamespaces(['client_src_components_modules_planoAcao_PlanoAcaoTabelaOrcamento_PlanoAcaoTabelaOrcamento']) 
        this.loadData();
    }

    async loadData() {
        this.setState({ loading: true });
        
        if (this.state.codigo) await this.loadEtapas();
        if (this.state.codigo) await this.loadPlano();
        
        await this.setTotal();
        
        this.setState({ loading: false });
    }

    loadEtapas = async () => {
        const etapas = await Sig.request('POST', 'planoAcao/listEtapasPlano', { codigo: this.state.codigo });
        this.setState({ etapas, countEtapas: etapas.length, atualizaEficacia: this.state.atualizaEficacia + 1 });
    }

    loadPlano = async () => {
        const planoAcao = await Sig.request('POST', 'planoAcao/getPlanoAcao', { codigo: this.state.codigo });

        if (!planoAcao?.data) {
            toast.error('Não foi possível encontrar o plano de ação');
            return window.parent.postMessage({ type: 'navigate', url: `/planoAcao` }, '*');
        } else {
            this.setState({ plano: planoAcao.data });
        }
    }

    setTotal = async () => {
        this.setState({ 
            totalPrevistoValue: this.state.plano.orcamento_previsto_value, 
            totalRealizadoValue: this.state.plano.orcamento_realizado_value, 
            saldoOrcamentoValue: this.state.plano.orcamento_saldo_value,
            totalPrevisto: this.state.plano.orcamento_previsto, 
            totalRealizado: this.state.plano.orcamento_realizado, 
            saldoOrcamento: this.state.plano.orcamento_saldo
        });
    }

    renderTotalGeral() {
        const { i18n } = this.props;

        return (
            <div className={"box-orcamento-total"}>
                {<div className={`celula`} style={{borderTopLeftRadius: 8, borderBottomLeftRadius: 8}}>
                    {i18n.t('client_src_components_modules_planoAcao_PlanoAcaoTabelaOrcamento_PlanoAcaoTabelaOrcamento:total_previsto')}
                    <span className={`celula-valor`}>{this.state.totalPrevistoValue}</span>
                </div>}
    
                {<div className={`celula`}>
                    {i18n.t('client_src_components_modules_planoAcao_PlanoAcaoTabelaOrcamento_PlanoAcaoTabelaOrcamento:total_realizado')}
                    <span className={`celula-valor`}>{this.state.totalRealizadoValue}</span>
                </div>}
    
                {<div className={`celula`} style={{backgroundColor: (this.state.saldoOrcamento >= 0 ? Colors.success : Colors.error), borderTopRightRadius: 8, borderBottomRightRadius: 8, color: Colors.white}}>
                    {i18n.t('client_src_components_modules_planoAcao_PlanoAcaoTabelaOrcamento_PlanoAcaoTabelaOrcamento:saldo_total')}
                    <span className={`celula-valor`}>{this.state.saldoOrcamentoValue}</span>
                </div>}
            </div>
        )
    }    

    renderList() {
        const { i18n } = this.props;
        var filteredEtapas = [...this.state.etapas].filter((e) => {return((e.orcamento_previsto && e.orcamento_previsto != 0) || (e.orcamento_realizado && e.orcamento_realizado != 0))});

        if(filteredEtapas.length == 0) return null;

        return (
            <div style={{minWidth: '100%', marginTop: 10, width: 'max-content'}}>
                <div style={{...EssentialStyle.rowFlexStart, width: 'auto', zIndex: 1}}>
                <div style={{
                        ...EssentialStyle.rowSpaceBetween,
                        backgroundColor: Colors.white,
                        fontSize: 14,
                        width: '100%',
                        borderTopLeftRadius: 5,
                        borderTopRightRadius: 5,
                        border: `1px solid ${Colors.border}`,
                        borderBottom: "none",
                        padding: 10,
                    }}>
                        <div style={{ ...EssentialStyle.rowFlexStart, width: 'calc(100% - 575px)', minWidth: 100 }}>
                            <span
                                style={{ fontWeight: 500, width: "100%", textAlign: 'left', overflow: 'hidden', textOverflow: 'ellipsis', textWrap: 'nowrap' }}
                            >
                                {i18n.t('client_src_components_modules_planoAcao_PlanoAcaoTabelaOrcamento_PlanoAcaoTabelaOrcamento:atividades')}
                            </span>
                        </div>
                        <div style={{ ...EssentialStyle.rowFlexStart, width: "480px", gap: 15}}>
                            <span
                                style={{ fontWeight: 500, width: 150, textAlign: 'left' }}
                            >
                                {this.state.isSmallScreen ? i18n.t('client_src_components_modules_planoAcao_PlanoAcaoTabelaOrcamento_PlanoAcaoTabelaOrcamento:previsto') : i18n.t('client_src_components_modules_planoAcao_PlanoAcaoTabelaOrcamento_PlanoAcaoTabelaOrcamento:orcamento_previsto')}
                            </span>
                            <span
                                style={{ fontWeight: 500, width: 150, textAlign: 'left' }}
                            >
                                {this.state.isSmallScreen ? i18n.t('client_src_components_modules_planoAcao_PlanoAcaoTabelaOrcamento_PlanoAcaoTabelaOrcamento:realizado') : i18n.t('client_src_components_modules_planoAcao_PlanoAcaoTabelaOrcamento_PlanoAcaoTabelaOrcamento:orcamento_realizado')}
                            </span>
                            <span
                                style={{ fontWeight: 500, width: 150, textAlign: 'left', paddingLeft: 15 }}
                            >
                                {i18n.t('client_src_components_modules_planoAcao_PlanoAcaoTabelaOrcamento_PlanoAcaoTabelaOrcamento:saldo')}
                            </span>
                        </div>
                        <span
                            style={{ fontWeight: 500, width: '80px', textAlign: 'left' }}
                        >
                            {i18n.t('client_src_components_modules_planoAcao_PlanoAcaoTabelaOrcamento_PlanoAcaoTabelaOrcamento:responsavel')}
                        </span>
                    </div>
                </div>
                {filteredEtapas.map((etapa, index) => {
                    return (
                        <PlanoAcaoEtapaCard
                            key={`plano-acao-etapa-orcamento-${etapa.id}`}
                            resumed={true}
                            dialog={true}
                            orcamento={true}
                            etapa={{ ...etapa }}
                            participantes={this.props.people}
                            hasAvaliador={this.state.plano?.superior}
                            borderBottom={index == filteredEtapas.length - 1 ? `1px solid ${Colors.border}` : 'none'}
                        />
                    )
                })}
            </div>
        )
    }

    renderLoading() {
        return (
            <div className={"loading"}>
                <DefaultLoader />
            </div>
        )
    }

    render() {

        if (this.state.loading) return this.renderLoading();
        return (
            <div className={"box-orcamento"}>
                {this.renderTotalGeral()}
                <div style={{width: '100%', minWidth: "100%", maxWidth: "100%", overflowX: "auto"}}>
                    {this.renderList()}
                </div>
            </div>
        );
    }
}
export default withTranslation()(PlanoAcaoTabelaOrcamento);