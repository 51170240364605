import React, { createRef } from "react";
import Sig from "../../../api/Sig";
import { Collapse, Dropdown, DropdownButton } from 'react-bootstrap';
import DefaultButton from "../DefaultButton";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusSquare, faSave, faTrash, faChevronUp, faBars, faEdit, faSync } from '@fortawesome/free-solid-svg-icons';
import Select from 'react-select';
import Colors from "../../../constants/Colors";
import { toast } from "react-toastify";

import './Operacao.css';
import EssentialStyle from "../../../style/EssentialStyle";
import DataHelper from "../../../helper/DataHelper";
import DragAndDropHelper from "../../../helper/DragAndDropHelper";
import { withTranslation } from 'react-i18next';
import { confirmAlert } from 'react-confirm-alert';
import CustomConfirm from "../CustomConfirm";

const COUNT_FAIXA = 'count(DiagnosticoPeriodoFaixa.id)';
const INDICADOR_FAIXA = 'DiagnosticoPeriodoIndicador.idFaixa';
const FAIXA = 'DiagnosticoPeriodoFaixa.id';
const VALOR = 'valor';
const OPERADOR = 'operador';
const BOOLEAN = 'boolean';
const IF = 'IF';
const ELSE = 'ELSE';
class Operacao extends React.Component {
    state = {
        loading: true,
        error: false,
        nErrors: 0,

        id: this.props.id || null,
        id_diagnostico_periodo_grupo: this.props.id_diagnostico_periodo_grupo || null,

        operacao: null,
        tiposOperacaoLogica: [],
        tiposRespostas: [],
        valores3ac: [],
        validadorTipos: [],
        indicadoresRef: [],
        faixas: [],

        editing: false,
        openLogica: [],
    }

    async componentDidMount() {
        await this.props.i18n.loadNamespaces(['client_src_components_tools_Operacao_Operacao']);
        this.loadOperacao();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.id !== this.props.id || prevProps.id_diagnostico_periodo_grupo !== this.props.id_diagnostico_periodo_grupo) {
            this.setState({
                id: this.props.id || null,
                id_diagnostico_periodo_grupo: this.props.id_diagnostico_periodo_grupo || null
            }, this.reload);
        }
    }

    reload = () => {
        this.loadOperacao();
    }

    loadOperacao = async () => {
        this.setState({ loading: true });

        try {
            let dataQuery = {
                id: this.state.id || null,
                id_diagnostico_periodo_grupo: this.state.id_diagnostico_periodo_grupo || null
            };

            let response = await Sig.request("GET", "/operacao/getOperacao", dataQuery);

            this.setState({ ...response, loading: false });
        } catch (error) {
            this.setState({ loading: false });
        }
    }

    addOperacaoLogica = async (tipo, position) => {
        // Create a new logic object
        const newLogica = {
            id: null,
            id_operacao: this.state.id || null,
            operacao: tipo || IF,
            ordem: position,
            tipo: null,
            valor: null,
            operacaoLogica3acs: tipo == IF ? [
                {
                    id: null,
                    id_operacao_logica: null,
                    ordem: 0,
                    tipo: null,
                    valor: null,
                    value: null,
                    label: null,
                }
            ] : null,
        };

        // Safely copy existing logics
        const logicasExistentes = [...(this.state.operacao?.operacaoLogicas || [])];

        // Update the order of existing logics
        const updatedLogicas = logicasExistentes.map(logica =>
            logica.ordem >= position ? { ...logica, ordem: logica.ordem + 1 } : logica
        );

        // Insert the new logic at the correct position
        updatedLogicas.splice(position, 0, newLogica);

        // Update the state with the new list of logics
        this.setState(prevState => ({
            operacao: {
                ...prevState.operacao,
                operacaoLogicas: updatedLogicas,
            },
            editing: null,
        }));
    }

    saveOperacaoLogica = async (operacaoLogica) => {

        // Verify if the logic is correct
        let response = this.verifyOperacaoLogica(operacaoLogica);
        if (response?.error) {
            toast.error(response.message);
            return;
        }

        try {
            // Prepare the data to be saved
            const dataSave = {
                id: this.state.operacao?.id || null,
                nome: this.state.operacao?.nome || '',
                id_empresa: this.state.operacao?.id_empresa || null,
                id_colaborador: this.state.operacao?.id_colaborador || null,
                data_criacao: this.state.operacao?.data_criacao || null,
                id_diagnostico_periodo_grupo: this.state.operacao?.id_diagnostico_periodo_grupo || this.state.id_diagnostico_periodo_grupo || null,
                operacaoLogica: operacaoLogica,
            };

            // Send the request to save the operation logic
            const { operacao, error } = await Sig.request("POST", "/operacao/saveOperacaoLogica", { operacao: JSON.stringify(dataSave) });

            if (error) {
                throw new Error("Erro ao salvar a operação.");
            }

            // Update the state with the saved operation logic
            this.setState(prevState => ({
                operacao: {
                    ...prevState.operacao,
                    id: operacao.id,
                    nome: operacao.nome,
                    id_empresa: operacao.id_empresa,
                    id_colaborador: operacao.id_colaborador,
                    data: operacao.data,
                    id_diagnostico_periodo_grupo: operacao.id_diagnostico_periodo_grupo,
                    idColaborador: operacao.idColaborador,
                    idDiagnosticoPeriodoGrupo: operacao.idDiagnosticoPeriodoGrupo,
                    idEmpresa: operacao.idEmpresa,
                    operacaoLogicas: prevState.operacao.operacaoLogicas.map(logica =>
                        logica.id === operacaoLogica.id ? operacao.operacaoLogica : logica
                    )
                },
                editing: false,
                openLogica: this.state.openLogica.filter(id => id !== operacaoLogica.id)
            }));

        } catch (error) {
            this.setState({ editing: false, openLogica: this.state.openLogica.filter(id => id !== operacaoLogica.id), error: true, nErrors: this.state.nErrors + 1 });
        }
    }

    setOperacaoLogica = async (selectedValue) => {
        // Update the logic with the selected value
        const updatedLogica = { ...selectedValue };

        this.setState(prevState => {
            // Find the parent operation in the state
            const operacaoLogicas = prevState.operacao.operacaoLogicas.map(logica =>
                logica.id === updatedLogica.id ? updatedLogica : logica
            );

            return {
                operacao: {
                    ...prevState.operacao,
                    operacaoLogicas: operacaoLogicas,
                },
            };
        });
    }

    removeOperacaoLogica = async (operacaoLogica) => {

        // Remove this operacaoLogica if it is not saved yet
        if (operacaoLogica.id == null) {
            const logicaId = operacaoLogica.id;

            this.setState(prevState => {
                // Use filter to remove the specified logic
                const updatedOperacaoLogicas = prevState.operacao.operacaoLogicas.filter(logica => logica.id !== logicaId);

                return {
                    operacao: {
                        ...prevState.operacao,
                        operacaoLogicas: updatedOperacaoLogicas
                    },
                    editing: false,
                    openLogica: this.state.openLogica.filter(id => id !== operacaoLogica.id)
                };
            });
        } else {

            confirmAlert({
                customUI: ({ onClose }) => (
                    <CustomConfirm
                        title={this.props.i18n.t('client_src_components_tools_Operacao_Operacao:title_confirm')}
                        message={<div dangerouslySetInnerHTML={{ __html: this.props.i18n.t('client_src_components_tools_Operacao_Operacao:subtitle_confirm') }} style={{ marginTop: 10, marginBottom: 10 }} />}
                        buttons={[
                            {
                                label: this.props.i18n.t('client_src_components_tools_Operacao_Operacao:delete_confirm'),
                                color: Colors.error,
                                textColor: Colors.light,
                                onClick: async () => {
                                    try {
                                        const { operacaoLogicas, error } = await Sig.request("GET", "/operacao/deleteOperacaoLogica", { id_operacao_logica: operacaoLogica.id });
                        
                                        this.setState(prevState => ({
                                            operacao: {
                                                ...prevState.operacao,
                                                operacaoLogicas: operacaoLogicas
                                            },
                                            editing: false,
                                            openLogica: this.state.openLogica.filter(id => id !== operacaoLogica.id)
                                        }));
                        
                                    } catch (error) {
                                        this.setState({ error: true, nErrors: this.state.nErrors + 1 });
                                    } finally {
                                        onClose();
                                    }
                                }
                            },
                            {
                                label: this.props.i18n.t('client_src_components_tools_Operacao_Operacao:cancel_confirm'),
                                onClick: () => { onClose(); }
                            },
                        ]}
                    />
                )
            });
            
        }
    }

    add3ac = async (operacaoLogica) => {

        const new3ac = {
            id: null,
            id_operacao_logica: operacaoLogica.id,
            ordem: operacaoLogica.operacaoLogica3acs.length,
            tipo: null,
            valor: null,
            value: null,
            label: null,
        }

        const operacaoLogica3acs = [...(operacaoLogica.operacaoLogica3acs || [])];
        operacaoLogica3acs.push(new3ac);

        this.setState(prevState => ({
            operacao: {
                ...prevState.operacao,
                operacaoLogicas: prevState.operacao.operacaoLogicas.map(logica =>
                    logica.id === operacaoLogica.id ? { ...logica, operacaoLogica3acs } : logica
                ),
            },
        }));
    }

    set3ac = async (selectedValue) => {
        // Update the 3ac with the selected value
        const updated3ac = { ...selectedValue };

        // Find the parent operacaoLogica
        const parentLogicaId = updated3ac.id_operacao_logica;

        this.setState(prevState => {
            // Find the parent operacaoLogica in the state
            const updatedOperacaoLogicas = prevState.operacao.operacaoLogicas.map(logica => {
                if (logica.id === parentLogicaId) {
                    // Update the specific 3ac in this logica
                    const updated3acs = (logica.operacaoLogica3acs || []).map(item =>
                        item.ordem === updated3ac.ordem ? updated3ac : item
                    );

                    return { ...logica, operacaoLogica3acs: updated3acs };
                }
                return logica;
            });

            return {
                operacao: {
                    ...prevState.operacao,
                    operacaoLogicas: updatedOperacaoLogicas
                }
            };
        });
    }

    remove3ac = async (operacaoLogica3acToRemove) => {
        // Find the parent operacaoLogica
        const parentLogicaId = operacaoLogica3acToRemove.id_operacao_logica;

        this.setState(prevState => {
            // Find the parent operacaoLogica in the state
            const updatedOperacaoLogicas = prevState.operacao.operacaoLogicas.map(logica => {
                if (logica.id === parentLogicaId) {
                    // Remove the specific 3ac from this logica
                    const updated3acs = (logica.operacaoLogica3acs || []).filter(item =>
                        item.ordem !== operacaoLogica3acToRemove.ordem
                    );

                    return { ...logica, operacaoLogica3acs: updated3acs };
                }
                return logica;
            });

            return {
                operacao: {
                    ...prevState.operacao,
                    operacaoLogicas: updatedOperacaoLogicas
                }
            };
        });
    }

    hasLogicaElse = () => {
        return this.state.operacao?.operacaoLogicas?.some(logica => logica.operacao === ELSE);
    }

    verifyOperacaoLogica = (operacaoLogica) => {

        if (operacaoLogica.operacao == IF) {
            if (operacaoLogica.operacaoLogica3acs.length == 0) {
                return {
                    error: true,
                    message: this.props.i18n.t('client_src_components_tools_Operacao_Operacao:if_condition_required')
                };
            } else {
                for (const operacaoLogica3ac of operacaoLogica.operacaoLogica3acs) {
                    let response = this.varifyOperacaoLogica3ac(operacaoLogica3ac);
                    if (response?.error) {
                        return response; // Interrompe a execução e retorna o erro
                    }
                }
            }
        } else if (operacaoLogica.operacao == ELSE) {
            if (operacaoLogica.operacaoLogica3acs?.length > 0) {
                return {
                    error: true,
                    message: this.props.i18n.t('client_src_components_tools_Operacao_Operacao:else_no_conditions')
                };
            }
        } else {
            return {
                error: true,
                message: this.props.i18n.t('client_src_components_tools_Operacao_Operacao:invalid_operation')
            };
        }

        if (operacaoLogica.tipo == null) {
            return {
                error: true,
                message: this.props.i18n.t('client_src_components_tools_Operacao_Operacao:select_return_type')
            }
        }

        if (operacaoLogica.tipo == FAIXA && operacaoLogica.valor == null) {
            return {
                error: true,
                message: this.props.i18n.t('client_src_components_tools_Operacao_Operacao:select_range')
            }
        }

        if (operacaoLogica.tipo == BOOLEAN && operacaoLogica.valor == null) {
            return {
                error: true,
                message: this.props.i18n.t('client_src_components_tools_Operacao_Operacao:select_boolean_value')
            }
        }

        return {
            error: false,
            message: this.props.i18n.t('client_src_components_tools_Operacao_Operacao:values_correctly_filled')
        };
    };

    varifyOperacaoLogica3ac = (operacaoLogica3ac) => {

        if (operacaoLogica3ac.tipo == null) {
            return {
                error: true,
                message: this.props.i18n.t('client_src_components_tools_Operacao_Operacao:select_or_delete_empty_condition')
            }
        }

        if (operacaoLogica3ac.tipo == VALOR && operacaoLogica3ac.valor == null) {
            return {
                error: true,
                message: this.props.i18n.t('client_src_components_tools_Operacao_Operacao:condition_value_required')
            }
        }

        if (operacaoLogica3ac.tipo == OPERADOR && operacaoLogica3ac.valor == null) {
            return {
                error: true,
                message: this.props.i18n.t('client_src_components_tools_Operacao_Operacao:operator_not_selected')
            }
        }

        // Itens da funcionalidade diagnosticoPeriodoGrupo

        if (operacaoLogica3ac.tipo == COUNT_FAIXA && operacaoLogica3ac.valor == null) {
            return {
                error: true,
                message: this.props.i18n.t('client_src_components_tools_Operacao_Operacao:range_not_selected')
            }
        }

        if (operacaoLogica3ac.tipo == INDICADOR_FAIXA && operacaoLogica3ac.valor == null) {
            return {
                error: true,
                message: this.props.i18n.t('client_src_components_tools_Operacao_Operacao:indicator_not_selected')
            }
        }

        if (operacaoLogica3ac.tipo == FAIXA && operacaoLogica3ac.valor == null) {
            return {
                error: true,
                message:  this.props.i18n.t('client_src_components_tools_Operacao_Operacao:return_range_not_selected')
            }
        }

        return {
            error: false,
            message: ""
        }
    }

    // Funções de renderização

    renderError() {
        return (
            <div>
                {this.props.i18n.t('client_src_components_tools_Operacao_Operacao:error_loading_operation')}

                <DefaultButton
                    leftIcon={<FontAwesomeIcon icon={faSync} />}
                    title={this.props.i18n.t('client_src_components_tools_Operacao_Operacao:try_again')}
                    onClick={this.loadOperacao}
                    color={Colors.warning}
                />
            </div>
        )
    }

    renderLoading() {
        return (
            <div className="operacao" style={{ ...this.props.styles?.content }}>
                <div className={"operacao-logica loading"} style={{ marginBottom: 5}}>
                    <div style={{ height: 30 }}/>
                    <div className="buttonCollapse">
                        <div />
                        <FontAwesomeIcon icon={faChevronUp}/>
                        <div />
                    </div>
                </div>
            </div>
        );
    }

    renderValor3ac(operacaoLogica3ac, isEditing) {

        const onDragStartItem = async (e, item) => {
            this.setState({ draggingItem: item, draggingOverItem: null, widthDragItem: e.target.offsetWidth });
            setTimeout(() => { e.target.style.display = "none" }, 5);
        };

        const onDragEndItem = async (e, item) => {
            e.target.style.display = "flex";

            // buscar as operacaologicas3acs deste irmas desta operacaologica3ac
            let operacaoLogica3acs = this.state.operacao.operacaoLogicas.find(operacaoLogica => operacaoLogica.id === item.id_operacao_logica).operacaoLogica3acs;
            let draggingOverItem = this.state.draggingOverItem;

            if (draggingOverItem !== null) {
                operacaoLogica3acs.splice(operacaoLogica3acs.indexOf(this.state.draggingItem), 1);
                let indexToInsert = operacaoLogica3acs.indexOf(draggingOverItem);
                if (this.state.left) {
                    operacaoLogica3acs.splice(indexToInsert, 0, item);
                } else {
                    operacaoLogica3acs.splice(indexToInsert + 1, 0, item);
                }
            }

            operacaoLogica3acs = operacaoLogica3acs.map((operacaoLogica3ac, index) => {
                return { ...operacaoLogica3ac, ordem: index };
            });

            this.setState({
                operacao: {
                    ...this.state.operacao,
                    operacaoLogicas: this.state.operacao.operacaoLogicas.map(operacaoLogica => {
                        if (operacaoLogica.id === item.id_operacao_logica) {
                            return { ...operacaoLogica, operacaoLogica3acs };
                        }
                        return operacaoLogica;
                    })
                }
            }, () => {
                this.setState({ draggingItem: null, draggingOverItem: null, left: false });
            });
        }

        const onDragEnterItem = async (e, item) => {

            let left = DragAndDropHelper.checkLeft(e);

            this.setState({ left });

            if (item.ordem !== this.state.draggingItem.ordem) {
                this.setState({ draggingOverItem: item });
            }
        }

        const renderInput = () => {

            const renderSelect = (options, value) => {
                return (
                    <Select
                        options={options}
                        value={value}
                        placeholder="..."
                        onChange={(e) => this.set3ac({ ...operacaoLogica3ac, valor: e.value })}
                        components={{ DropdownIndicator: () => null }} // Remove dropdown indicator
                        menuPortalTarget={document.body} // Renderiza o menu no body
                        styles={{
                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                            indicatorSeparator: () => ({ display: 'none' }),
                            control: (provided) => ({
                                ...provided,
                                border: 'none',  // Remove border
                                boxShadow: 'none',  // Remove box shadow
                                '&:hover': {
                                    border: 'none',  // Remove border on hover
                                },
                            }),
                            valueContainer: (provided) => ({
                                ...provided,
                                padding: '0 3px 0 0',
                            }),
                            menu: (provided) => ({
                                ...provided,
                                width: 'max-content',  // Ajusta ao conteúdo mais largo
                                minWidth: '100%',      // Não menor que o select
                                maxWidth: '300px',     // Opcional: limite máximo
                                zIndex: 9999,
                                position: 'absolute', // Garante que o menu seja posicionado fora do fluxo do DOM
                            }),
                            menuList: (provided) => ({
                                ...provided,
                                width: 'auto',
                            }),
                            // Para garantir que o texto nas opções não seja truncado
                            option: (provided) => ({
                                ...provided,
                                whiteSpace: 'normal',  // Permite quebra de linha
                            }),
                        }}
                    />
                )
            }

            if (operacaoLogica3ac.tipo == VALOR) {
                return (
                    <input
                        type="text"
                        value={operacaoLogica3ac.valor || ''}
                        placeholder="..."
                        onChange={(e) => {
                            const inputValue = e.target.value;
                            // Only allow numbers, empty string, or decimal point
                            if (inputValue === '' || /^-?\d*\,?\d*$/.test(inputValue)) {
                                this.set3ac({
                                    ...operacaoLogica3ac,
                                    valor: inputValue,
                                });
                            }
                        }}
                        style={{
                            border: 'none',
                            outline: 'none',
                            width: operacaoLogica3ac.valor ? `${Math.max(2, operacaoLogica3ac.valor.length * 0.615)}rem` : '2rem',
                            minWidth: '2rem',
                            backgroundColor: 'transparent'
                        }}
                    />
                )
            }

            if (operacaoLogica3ac.tipo == COUNT_FAIXA) {
                let opetions = DataHelper.formatSelectDataLang(this.state.faixas, 'id', 'textos', null, 'texto');
                let value = DataHelper.formatSelectDataLang(this.state.faixas, 'id', 'textos', null, 'texto')?.find(option => option.value === operacaoLogica3ac.valor) || null;
                return renderSelect(opetions, value);
            }

            if (operacaoLogica3ac.tipo == INDICADOR_FAIXA) {
                let options = DataHelper.formatSelectData(this.state.indicadoresRef, 'id', 'indicadorNome')
                let value = DataHelper.formatSelectData(this.state.indicadoresRef, 'id', 'indicadorNome')?.find(option => option.value === operacaoLogica3ac.valor) || null
                return renderSelect(options, value);
            }

            if (operacaoLogica3ac.tipo == FAIXA) {
                let options = DataHelper.formatSelectDataLang(this.state.faixas, 'id', 'textos', null, 'texto');
                let value = DataHelper.formatSelectDataLang(this.state.faixas, 'id', 'textos', null, 'texto')?.find(option => option.value === operacaoLogica3ac.valor) || null;
                return renderSelect(options, value);
            }
        }

        if (isEditing) {
            return (
                <div
                    className={"item-3ac editing"}
                    onDragStart={(e) => { onDragStartItem(e, operacaoLogica3ac) }}
                    onDragEnter={(e) => { onDragEnterItem(e, operacaoLogica3ac) }}
                    onDragEnd={(e) => { onDragEndItem(e, operacaoLogica3ac) }}
                    draggable={true}
                    style={{
                        marginLeft: this.state.draggingOverItem && this.state.draggingOverItem.ordem === operacaoLogica3ac.ordem && this.state.left ? this.state.widthDragItem : 0,
                        marginRight: this.state.draggingOverItem && this.state.draggingOverItem.ordem === operacaoLogica3ac.ordem && !this.state.left ? this.state.widthDragItem : 0
                    }}
                >
                    <div className={"iconDelete hoverScale"}>
                        <FontAwesomeIcon icon={faTrash} style={{ color: Colors.error }} onClick={() => this.remove3ac(operacaoLogica3ac)} />
                    </div>
                    <div className={"iconMove hoverScale"} >
                        <FontAwesomeIcon icon={faBars} style={{ color: Colors.dark }} />
                    </div>

                    <Select
                        options={this.state.valores3ac}
                        value={operacaoLogica3ac}
                        onChange={(e) => this.set3ac({ ...operacaoLogica3ac, ...e })}
                        components={{ DropdownIndicator: () => null }}
                        placeholder="..."
                        menuPortalTarget={document.body} // Renderiza o menu no body
                        styles={{
                            indicatorSeparator: () => ({ display: 'none' }),
                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                            control: (provided) => ({
                                ...provided,
                                border: 'none',  // Remove border
                                boxShadow: 'none',  // Remove box shadow
                                '&:hover': {
                                    border: 'none',  // Remove border on hover
                                },
                                padding: '0',
                                minWidth: '20px',
                            }),
                            valueContainer: (provided) => ({
                                ...provided,
                                padding: '0 3px',
                            }),
                            menu: (provided) => ({
                                ...provided,
                                width: 'max-content',  // Ajusta ao conteúdo mais largo
                                minWidth: '100%',      // Não menor que o select
                                maxWidth: '300px',     // Opcional: limite máximo
                            }),
                            menuList: (provided) => ({
                                ...provided,
                                width: 'auto',
                            }),
                            // Para garantir que o texto nas opções não seja truncado
                            option: (provided) => ({
                                ...provided,
                                whiteSpace: 'normal',  // Permite quebra de linha
                            })
                        }} />

                    {renderInput()}
                </div>
            )
        } else {

            if (operacaoLogica3ac.tipo == COUNT_FAIXA) {
                let faixas = DataHelper.formatSelectDataLang(this.state.faixas, 'id', 'textos', null, 'texto');
                let faixa = faixas?.find(faixa => faixa.value === operacaoLogica3ac.valor) || {};
                return (
                    <div className="item-3ac">
                        {`${operacaoLogica3ac.label} ${faixa.label}`}
                    </div>
                )
            }

            if (operacaoLogica3ac.tipo == INDICADOR_FAIXA) {
                let indicador = this.state.indicadoresRef.find(indicador => indicador.id === operacaoLogica3ac.valor);
                return (
                    <div className="item-3ac">
                        {indicador?.indicadorNome}
                    </div>
                )
            }

            if (operacaoLogica3ac.tipo == FAIXA) {
                let faixas = DataHelper.formatSelectDataLang(this.state.faixas, 'id', 'textos', null, 'texto');
                let faixa = faixas?.find(faixa => faixa.value === operacaoLogica3ac.valor) || {};
                return (
                    <div className="item-3ac">
                        {faixa.label}
                    </div>
                )
            }

            if (operacaoLogica3ac.tipo == VALOR) {
                return (
                    <div className="item-3ac">
                        {operacaoLogica3ac.valor}
                    </div>
                )
            }

            return (
                <div className="item-3ac">
                    {operacaoLogica3ac.label}
                </div>
            )
        }
    }

    renderOperacaoLogicaCondicao(operacaoLogica, isEditing) {

        const render3acs = () => {

            let operacaoLogica3acs = operacaoLogica.operacaoLogica3acs || [];
            operacaoLogica3acs.sort((a, b) => a.ordem - b.ordem);

            return (
                operacaoLogica3acs.map((operacaoLogica3ac) =>
                    <React.Fragment key={`operacao-logica-3ac-${operacaoLogica3ac.id} - ${operacaoLogica3ac.ordem}`}>
                        {this.renderValor3ac(operacaoLogica3ac, isEditing)}
                    </React.Fragment>
                )
            );
        }

        const renderAdd3ac = () => {
            return (
                <DefaultButton
                    leftIcon={<FontAwesomeIcon icon={faPlusSquare} />}
                    color={Colors.success}
                    onClick={() => this.add3ac(operacaoLogica)}
                />
            );
        }

        let labelOperacao = this.state.tiposOperacaoLogica?.find(tipo => tipo.value === operacaoLogica.operacao)?.label;

        return (

            <div className="operacao-logica-line">

                <div className="operacao-logica-operacao">{labelOperacao}</div>

                {operacaoLogica.operacao == IF &&
                    <div className="operacao-logica-condicao">
                        <div style={{ ...EssentialStyle.rowFlexStart, gap: 5 }}>{render3acs()}</div>
                        {isEditing && renderAdd3ac()}
                    </div>}

                <div style={{ ...EssentialStyle.rowFlexEnd, gap: 5 }}>
                    {!isEditing && this.state.editing == false && <FontAwesomeIcon className="hoverScale" icon={faEdit} style={{ color: Colors.dark }} onClick={() => this.setState({ editing: operacaoLogica.id })} />}
                    {isEditing && <FontAwesomeIcon className="hoverScale" icon={faSave} style={{ color: Colors.success }} onClick={() => { this.saveOperacaoLogica(operacaoLogica) }} />}
                    {isEditing && <FontAwesomeIcon className="hoverScale" icon={faTrash} style={{ color: Colors.error }} onClick={() => { this.removeOperacaoLogica(operacaoLogica) }} />}
                </div>

            </div>
        );
    }

    renderOperacaoLogicaResposta(operacaoLogica, isEditing) {

        const renderSelect = (options, value) => {
            return (
                <Select
                    options={options}
                    value={value}
                    placeholder="..."
                    onChange={(e) => {
                        this.setOperacaoLogica({
                            ...operacaoLogica,
                            ...e,
                            valor: e.value,
                        });
                    }}
                    // formatOptionLabel={({ label, cor }) => (
                    //     <div style={{
                    //         ...EssentialStyle.rowFlexStart,
                    //         paddingLeft: 8,
                    //         fontWeight: 600,
                    //         cursor: 'pointer',
                    //         color: 'white',
                    //         backgroundColor: cor,
                    //         borderRadius: 5,
                    //         padding: '2px 5px',
                    //     }}>
                    //         {label}
                    //     </div>
                    // )}
                    styles={{
                        valueContainer: (provided) => ({
                            ...provided,
                            padding: '0 3px',
                        }),
                        menu: (provided) => ({
                            ...provided,
                            width: 'max-content',  // Ajusta ao conteúdo mais largo
                            minWidth: '100%',      // Não menor que o select
                            maxWidth: '300px',     // Opcional: limite máximo
                            zIndex: 9999,
                        }),
                        menuList: (provided) => ({
                            ...provided,
                            width: 'auto',
                        }),
                        // Para garantir que o texto nas opções não seja truncado
                        option: (provided) => ({
                            ...provided,
                            whiteSpace: 'normal',  // Permite quebra de linha
                        })
                    }}
                />
            )
        }

        const renderInput = () => {
            if (this.state.tiposRespostas.tipo == FAIXA) {
                let options = DataHelper.formatSelectDataLang(this.state.faixas, 'id', 'textos', null, 'texto');
                options = options.map(option => ({ ...option, tipo: FAIXA }));
                options = options.map(option => (
                    { ...option, cor: this.state.faixas.find(faixa => faixa.id === option.value)?.cor || 'black' }
                ));
                let value = DataHelper.formatSelectDataLang(this.state.faixas, 'id', 'textos', null, 'texto')?.find(option => option.value === operacaoLogica.valor) || null;
                return renderSelect(options, value);
            }
        }

        const renderResposta = () => {
            if (this.state.tiposRespostas.tipo == FAIXA) {
                let faixas = DataHelper.formatSelectDataLang(this.state.faixas, 'id', 'textos', null, 'texto');
                let faixa = faixas?.find(faixa => faixa.value === operacaoLogica.valor) || {};
                return (
                    <div>
                        {faixa.label}
                    </div>
                )
            }
        }

        return (
            <div className="operacao-logica-resposta">
                <div className="operacao-logica-resposta-title">
                    {this.props.i18n.t('client_src_components_tools_Operacao_Operacao:return_then')}
                </div>
                <div className="operacao-logica-resposta-value">{this.state.tiposRespostas?.label}</div>
                {isEditing ? renderInput() : renderResposta()}
            </div>
        );
    }

    renderOperacaoLogica = (operacaoLogica) => {

        let isEditing = this.state.editing == operacaoLogica.id;
        let openLogica = isEditing || this.state.openLogica?.includes(operacaoLogica.id);

        return (
            <div className={`operacao-logica ${isEditing ? 'editing' : ''}`} key={`operacao-logica-${operacaoLogica.id}`}>
                {this.renderOperacaoLogicaCondicao(operacaoLogica, isEditing)}

                <div className="buttonCollapse" onClick={() => {
                    this.setState({
                        openLogica: this.state.openLogica?.includes(operacaoLogica.id)
                            ? this.state.openLogica.filter(id => id !== operacaoLogica.id) // Remove o ID
                            : [...(this.state.openLogica || []), operacaoLogica.id] // Adiciona o ID
                    });
                }}>
                    <div />
                    <FontAwesomeIcon icon={faChevronUp} className={`${openLogica ? '' : 'semiRotating'}`} />
                    <div />
                </div>

                <Collapse in={openLogica}>
                    <div>
                        {this.renderOperacaoLogicaResposta(operacaoLogica, isEditing)}
                    </div>
                </Collapse>
            </div>
        )
    }

    renderOperacao() {

        const renderButtonaddOperacaoLogica = (type, position = 0) => {

            if (this.state.editing != false) return <div style={{ height: 5 }} />;

            if (type == 'new') {
                return (
                    <div className="operacao" style={{ ...this.props.styles?.content }}>
                        <DefaultButton
                            leftIcon={<FontAwesomeIcon icon={faPlusSquare} />}
                            title="Adicionar lógica"
                            onClick={() => this.addOperacaoLogica(IF, 0)}
                            color={Colors.success}
                        />
                    </div>
                );
            }

            if (type == 'line') {
                return (
                    <div className="add-logica-line" onClick={() => this.addOperacaoLogica(IF, position)}>
                        <div />
                        <FontAwesomeIcon icon={faPlusSquare} style={{ color: Colors.dark }} />
                        <div />
                    </div>
                );
            }

            if (this.hasLogicaElse()) return null;

            return (
                <DropdownButton
                    id="dropdown-button-add-logica"
                    title="Adicionar lógica"
                    variant="success"
                    style={{ margin: '10px 0 0 0', width: 'auto' }}
                >
                    {this.state.tiposOperacaoLogica.map((tipo, index) => (
                        <Dropdown.Item
                            key={index}
                            onClick={() => this.addOperacaoLogica(tipo.value, position)}
                        >
                            {tipo.label}
                        </Dropdown.Item>
                    ))}
                </DropdownButton>
            );
        }

        if (this.state.loading) return this.renderLoading();
        if (this.state.operacao == null || this.state.operacao?.operacaoLogicas?.length == 0) return renderButtonaddOperacaoLogica('new');

        return (
            <div className="operacao" style={{ ...this.props.styles?.content }}>
                {this.state.operacao?.operacaoLogicas?.map((operacaoLogica, index) => (
                    <React.Fragment key={`operacao-logica-${operacaoLogica.id || index}`}>
                        {this.renderOperacaoLogica(operacaoLogica)}

                        {index < this.state.operacao.operacaoLogicas.length - 1 && (
                            <div key={`add-logica-line-${index}`}>
                                {renderButtonaddOperacaoLogica('line', index + 1)}
                            </div>
                        )}
                    </React.Fragment>
                ))}
                {renderButtonaddOperacaoLogica('add', this.state.operacao?.operacaoLogicas?.length || 0)}
            </div>
        );
    }

    render() {
        if (this.state.error) return this.renderError();
        return this.renderOperacao();
    }
}

export default withTranslation()(Operacao);