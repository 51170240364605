import React, { forwardRef, use } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight, faLink, faTimesCircle, faQuestionCircle, faClipboard, faUndo, faMagic } from '@fortawesome/free-solid-svg-icons'
import DefaultButton from "../../../tools/DefaultButton";
import Colors from "../../../../constants/Colors";
import { Form, Row, Col, Collapse, InputGroup } from "react-bootstrap";
import Select from 'react-select'
import DataHelper from "../../../../helper/DataHelper";
import EssentialStyle from "../../../../style/EssentialStyle";
import IntervalSelector from "../../../tools/IntervalSelector";
import DatePicker from 'react-datepicker';
import DefaultLoader from "../../../tools/DefaultLoader";
import IdiomaHelper from "../../../../helper/IdiomaHelper";
import { toast } from "react-toastify";
import moment from 'moment';
import DiagnosticoHelper from "../../../../helper/diagnostico/DiagnosticoHelper";

export default class AddPeriodoAvaliacao extends React.Component {
    state = {
        step: 1,
        loading: true,
        saving: false,
        lang: IdiomaHelper.getIdioma(),

        // form one
        name: null,
        colaboradores: [],
        empresas: [],
        empresasSelect: [],
        evaluators: [],
        data_inicio: moment(),
        data_fim: moment().add(1, 'year'),
        data_performance: moment(),
        automatico_performance: false,
        frequencia_performance: 1,
        data_processo: moment(),
        automatico_processo: false,
        frequencia_processo: 1,
        useIndicadores: false,
        faixas: [
            { from: 0, to: 30, color: Colors.metas[9], infinito: 0, id: null },
            { from: 30, to: 60, color: Colors.metas[5], infinito: 0, id: null },
            { from: 60, to: 80, color: Colors.metas[3], infinito: 0, id: null },
            { from: 80, to: 100, color: Colors.metas[4], infinito: 0, id: null },
        ],
    }

    componentDidMount() {
        this.load();
    }

    async load() {
        this.setState({ loading: true });

        let { periodo, colaboradores, empresas, canEdit, canView } = await DiagnosticoHelper.getInfosCriarPeriodoAvaliacao(this.props.idProcesso, this.props.showWizard);

        if (!canEdit) {
            let message = IdiomaHelper.getStruct({
                'pt_br': { title: 'Sem permissão', message: 'Você não tem permissão para criar ou editar o período de Avaliação' },
                'en': { title: 'No permission', message: 'You do not have permission to create or edit the Evaluation period' },
                'es': { title: 'Sin permiso', message: 'No tienes permiso para crear o editar el período de Evaluación' },
            });

            toast.error(message[this.state.lang].message);
            this.props.onBack();
        }

        if (periodo?.id){

            let faixas = periodo?.faixas?.map((interval) => {

                let colorAux = {
                    from: interval.min,
                    to: interval.max,
                    color: interval.cor,
                    infinito: 0,
                    textos: interval.textos,
                    id: interval.id, 
                }
    
                return colorAux;
            }) ?? [];

            this.setState({
                id: periodo.id,
                colaboradores: DataHelper.formatSelectData(colaboradores, 'id', 'nome') || [],
                empresas: DataHelper.formatSelectData(empresas, 'id', 'nome') || [],
                empresasSelect: DataHelper.formatSelectData(periodo.empresas, 'id', 'nome_fantasia') || [],
                name: periodo.nome || '',
                evaluators: DataHelper.formatSelectData(periodo.colaboradores, 'id', 'nome') || [],
                data_inicio: moment(periodo.data_inicio),
                data_fim: moment(periodo.data_fim),
                useIndicadores: periodo.tem_indicadores,
                data_performance: periodo.data_performance ? moment(periodo.data_performance) : moment(periodo.data_inicio),
                automatico_performance: periodo.frequencia_performance && periodo.frequencia_performance > 0 ? true : false,
                frequencia_performance: periodo.frequencia_performance ? periodo.frequencia_performance : 1,
                data_processo: periodo.data_processo ? moment(periodo.data_processo) : moment(periodo.data_inicio),
                automatico_processo: periodo.frequencia_processo && periodo.frequencia_processo > 0 ? true : false,
                frequencia_processo: periodo.frequencia_processo ? periodo.frequencia_processo : 1,
                faixas: faixas,
                loading: false,
            });
        } else {
            this.setState({ 
                colaboradores: DataHelper.formatSelectData(colaboradores, 'id', 'nome') || [],
                empresas: DataHelper.formatSelectData(empresas, 'id', 'nome') || [],
                empresasSelect: DataHelper.formatSelectData(empresas, 'id', 'nome') || [],
                loading: false 
            });
        }
    }

    step(calc) {

        let step = this.state.step + calc;

        if (step < 1) {
            this.props.onBack();
        } else if (step == 2) {
            this.savePeriodoAvaliacao();
        } else {
            this.setState({ step });
        }
    }

    faixasIsValid() {
        let faixas =[];
        let haveInfinito = false, response = true;

        for (let intervalo of this.state.faixas) {

            faixas.push(parseInt(intervalo.from));
            faixas.push(intervalo.infinito == 1 ? 2147483647 : parseInt(intervalo.to));
            if (intervalo.infinito) haveInfinito = true;
        }

        faixas.sort((a, b) => a - b);
        
        // Verificar se começa em ZERO
        if (faixas[0] != 0) response = false;
        
        // Retira o menor e maior valor do array
        faixas.shift();
        faixas.pop();

        // Valida se todos elementos passuem duas repetiçoes e não sobra nenhum com menos ou mais de duas
        for (let i = 0; i < faixas.length; i += 2) {
            if (faixas[i] !== faixas[i + 1]) response = false;;
        }

        return response;
    }

    savePeriodoAvaliacao = async () => {
        
        const message = IdiomaHelper.getStruct({
            'pt_br': {empty: 'Preencha todos os campos obrigatórios', errorfaixas: 'As faixas definidas não cobrem todo intervalo ou há faixas sobrepostos', errorProcesso: 'Processo não identificado', success: 'Período de Avaliação salvo com sucesso', error: 'Erro ao salvar Período de Avaliação'},
            'en': {empty: 'Fill in all required fields', errorfaixas: 'The defined ranges do not cover the entire range or there are overlapping ranges', errorProcesso: 'Process not identified', success: 'Evaluation Period saved successfully', error: 'Error saving Evaluation Period'},
            'es': {empty: 'Complete todos los campos obligatorios', errorfaixas: 'Los rangos definidos no cubren todo el rango o hay rangos superpuestos', errorProcesso: 'Proceso no identificado', success: 'Período de Evaluación guardado con éxito', error: 'Error al guardar Período de Evaluación'},
        });

        if (!this.state.name || !this.state.evaluators || this.state.evaluators.length == 0 || !this.state.data_inicio || !this.state.data_fim || !this.state.faixas) {
            toast.error(message[this.state.lang].empty);
            return;
        }

        if (!this.faixasIsValid()) {
            toast.warn(message[this.state.lang].errorfaixas);
            return;
        }

        if (!this.props.idProcesso) {
            toast.error(message[this.state.lang].errorProcesso);
            return;

        }

        let periodoAvaliacao = {
            nome: this.state.name,
            avaliadores: this.state.evaluators.map(e => e.value),
            empresas: this.state.empresasSelect.map(e => e.value),
            data_inicio: moment(this.state.data_inicio).format('YYYY-MM-DD HH:mm:ss'),
            data_fim: moment(this.state.data_fim).format('YYYY-MM-DD HH:mm:ss'),
            faixas: this.state.faixas,
            tem_indicadores: this.state.useIndicadores,
            data_performance: this.state.data_performance ? moment(this.state.data_performance).format('YYYY-MM-DD HH:mm:ss') : null,
            automatico_performance: this.state.automatico_performance,
            frequencia_performance: this.state.frequencia_performance,
            data_processo: this.state.data_processo ? moment(this.state.data_processo).format('YYYY-MM-DD HH:mm:ss') : null,
            automatico_processo: this.state.automatico_processo,
            frequencia_processo: this.state.frequencia_processo,
        }

        if (this.state.id) {
            periodoAvaliacao.id = this.state.id;
        }

        this.setState({ saving: true });

        let save = await DiagnosticoHelper.savePeriodoAvaliacao(this.props.idProcesso, periodoAvaliacao);

        if (save) {
            toast.success(message[this.state.lang].success);
            this.props.onBack();
        } else {
            toast.error(message[this.state.lang].error);
            this.setState({ saving: false });
        }
    }

    // Funções de renderização da página

    renderSaving() {
        const message = IdiomaHelper.getStruct({
            'pt_br': { saving: 'Salvando Período de Avaliação' },
            'en': { saving: 'Saving Evaluation Period' },
            'es': { saving: 'Guardando Período de Evaluación' },
        });

        return (
            <div style={{ minWidth: '100%', minHeight: '100vh', ...EssentialStyle.rowFlexCenter }}>
                <DefaultLoader title={message[this.state.lang].saving} style={{ width: "100%", height: "100%" }} />
            </div>
        )
    }

    renderDatePicker(datePeriodo) {

        const message = IdiomaHelper.getStruct({
            'pt_br': { 
                dateError: 'A data de início deve ser anterior à data de fim',
                dateErrorPerformance: 'A data de verificação para indicadores de performance deve ser entre a data de início e a data de fim',
                dateErrorProcesso: 'A data de verificação para indicadores de processo deve ser entre a data de início e a data de fim'
            },
            'en': { 
                dateError: 'The start date must be before the end date',
                dateErrorPerformance: 'The verification date for performance indicators must be between the start date and the end date',
                dateErrorProcesso: 'The verification date for process indicators must be between the start date and the end date'
            },
            'es': { 
                dateError: 'La fecha de inicio debe ser anterior a la fecha de fin',
                dateErrorPerformance: 'La fecha de verificación para indicadores de rendimiento debe estar entre la fecha de inicio y la fecha de fin',
                dateErrorProcesso: 'La fecha de verificación para indicadores de proceso debe estar entre la fecha de inicio y la fecha de fin'
            },
        });

        const DatePickerComponent = forwardRef(({ value, onClick }, ref) => (
            <Form.Control
                onChange={() => { }}
                onClick={onClick}
                ref={ref}
                style={{ fontSize: 14, color: Colors.dark, fontWeight: 'bold', cursor: 'pointer', boxShadow: 'none', backgroundColor: Colors.homePage.lightGrey, padding: 1, height: '100%', minWidth: '100%', textAlign: 'center', zIndex: 1 }}
                value={value} >
            </Form.Control>
        ));

        return (
            <div style={{
                ...EssentialStyle.rowFlexCenter,
                minWidth: '100%',
            }}>
                <DatePicker
                    selected={this.state?.[datePeriodo] ? moment(this.state?.[datePeriodo]).toDate() : null}
                    type="date"
                    onChange={(date) => {
                        if (datePeriodo == 'data_inicio' && ((this.state.data_fim && date < this.state.data_fim) || !this.state.data_fim)) {
                            this.setState({ data_inicio: moment(date) });

                        } else if (datePeriodo == 'data_fim' && ((this.state.data_inicio && date > this.state.data_inicio) || !this.state.data_inicio)) {
                            this.setState({ data_fim: moment(date) });

                        } else if (datePeriodo == 'data_performance' && ((this.state.data_fim && date <= this.state.data_fim)) && ((this.state.data_inicio && date >= this.state.data_inicio))) {
                            this.setState({ data_performance: moment(date) });
                        
                        } else if (datePeriodo == 'data_performance') {
                            toast.error(message[this.state.lang].dateErrorPerformance);

                        } else if (datePeriodo == 'data_processo' && ((this.state.data_fim && date <= this.state.data_fim)) && ((this.state.data_inicio && date >= this.state.data_inicio))) {
                            this.setState({ data_processo: moment(date) });

                        } else if (datePeriodo == 'data_processo') {
                            toast.error(message[this.state.lang].dateErrorProcesso);

                        } else {
                            toast.error(message[this.state.lang].dateError);
                        }
                    }}
                    dateFormat="MM/yyyy"
                    showMonthYearPicker
                    customInput={<DatePickerComponent />}
                    popperPlacement="top-start"
                />
            </div>
        )
    }

    renderStepBack() {
        return (
            <div style={{ position: 'fixed', top: 10, left: 10 }}>
                <DefaultButton 
                    title={''} 
                    leftIcon={<FontAwesomeIcon icon={faChevronLeft} />} 
                    color={Colors.secondaryButton} 
                    textColor={Colors.dark} 
                    loading={this.state.loading} 
                    onClick={() => { this.step(-1) }} 
                    style={{ marginRight: 8 }} 
                />
            </div>
        );
    }

    renderHelp() {
        return (
            <div style={{ position: 'fixed', top: 10, right: 10 }}>
                <DefaultButton 
                style={{ width: 38, height: 38, marginLeft: 8}}
                fontSize={this.props.isSmallScreen ? 14 : "unset"}
                color={Colors.secondaryButton}
                textColor={Colors.dark} 
                link={'https://advis.freshdesk.com/support/solutions/folders/63000238312'}/>
            </div>
        );
    }

    renderSteps() {
        let stepContent;

        switch (this.state.step) {
            case 1:
                stepContent = this.renderFormOne();
                break;
            default:
                stepContent = <div>Passo desconhecido</div>;
        }

        return stepContent;
    }

    calculateMonthsDifference(startDate, endDate) {
        return endDate.diff(startDate, 'months') + 1;
    }

    renderFormOne() {

        const message = IdiomaHelper.getStruct({
            'pt_br': { 
                name: 'Nome do Período de Avaliação', 
                evaluators: 'Avaliadores', 
                dateStart: 'Data de Início', 
                dateEnd: 'Data de Fim',
                datePerformance: 'Data de Verificação para Indicadores de Performance',
                dateProcesso: 'Data de Verificação para Indicadores de Processo',
                empresas: 'Empresas Participantes', 
                indicador: 'Indicador', 
                useIndicador: 'Habilitar vínculo com Indicadores',
                atualiazacaoAutomatica: 'Atualização Automática',
                frequencia: 'Frequência de Verificação',
                meses: 'mes(es)',
                scale: 'Escala de Avaliação', 
                save: 'Salvar' 
            },
            'en': { 
                name: 'Evaluation Period Name', 
                evaluators: 'Evaluators', 
                dateStart: 'Start Date', 
                dateEnd: 'End Date',
                datePerformance: 'Performance Indicator Verification Date',
                dateProcesso: 'Process Indicator Verification Date',
                empresas: 'Participating Companies', 
                indicador: 'Indicator', 
                useIndicador: 'Enable link with Indicators',
                atualiazacaoAutomatica: 'Automatic Update',
                frequencia: 'Verification Frequency',
                meses: 'month(s)',
                scale: 'Rating Scale', 
                save: 'Save' 
            },
            'es': { 
                name: 'Nombre del Período de Evaluación', 
                evaluators: 'Evaluadores', 
                dateStart: 'Fecha de Inicio', 
                dateEnd: 'Fecha de Fin',
                datePerformance: 'Fecha de Verificación para Indicadores de Rendimiento',
                dateProcesso: 'Fecha de Verificación para Indicadores de Proceso',
                empresas: 'Empresas participantes', 
                indicador: 'Indicador', 
                useIndicador: 'Habilitar vínculo con Indicadores',
                atualiazacaoAutomatica: 'Actualización Automática',
                frequencia: 'Frecuencia de Verificación',
                meses: 'mes(es)',
                scale: 'Escala de calificación', 
                save: 'Guardar' 
            },
        });

        return (
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', alignContent: 'center', minHeight: 'inherit' }}>
                <Form style={{ width: '80%', paddingTop: '5rem' }}>
                    <Row className="mb-3">
                        <Form.Group as={Col} md={12} xxl={6} className="mb-3">
                            <Form.Label>
                                {message[this.state.lang].name}*
                            </Form.Label>
                            <Form.Control
                                type="text"
                                placeholder={message[this.state.lang].name + ' ...'}
                                value={this.state.name || ""}
                                loading={this.state.loading}
                                onChange={(event) => { this.setState({ name: event.target.value }) }}
                            />
                        </Form.Group>

                        <Form.Group as={Col} md={12} xxl={6} className="mb-3">
                            <Form.Label>{message[this.state.lang].evaluators}*</Form.Label>
                            <Select
                                options={this.state.colaboradores}
                                placeholder={message[this.state.lang].evaluators + ' ...'}
                                noOptionsMessage={DataHelper.getSelectEmptyMessage}
                                value={this.state.evaluators}
                                isMulti
                                onChange={(value) => { this.setState({ evaluators: value }) }}
                                loading={this.state.loading}
                            />
                        </Form.Group>

                        <Form.Group as={Col} md={12} xxl={12} className="mb-3">
                            <Form.Label>{message[this.state.lang].empresas}*</Form.Label>
                            <Select
                                options={this.state.empresas}
                                placeholder={message[this.state.lang].empresas + ' ...'}
                                noOptionsMessage={DataHelper.getSelectEmptyMessage}
                                value={this.state.empresasSelect}
                                isMulti
                                onChange={(value) => { this.setState({ empresasSelect: value }) }}
                                loading={this.state.loading}
                            />
                        </Form.Group>

                        <Form.Group as={Col} md={6} xxl={6} className="mb-3">
                            <Form.Label>{message[this.state.lang].dateStart}*</Form.Label>
                            {this.renderDatePicker('data_inicio')}
                        </Form.Group>

                        <Form.Group as={Col} md={6} xxl={6} className="mb-3">
                            <Form.Label>{message[this.state.lang].dateEnd}*</Form.Label>
                            {this.renderDatePicker('data_fim')}
                        </Form.Group>

                        <Form.Group as={Col} md={12} xxl={12} className="mb-3">
                            <Form.Check 
                                type="checkbox"
                                className="mb-3"
                                label={message[this.state.lang].useIndicador} 
                                onChange={(e) => this.setState({ useIndicadores: e.target.checked })} 
                                checked={this.state.useIndicadores}
                            />
                            <Collapse in={this.state.useIndicadores}>
                                <div>
                                    <div style={{...EssentialStyle.columnStart, gap: 10, border: '1px solid #ccc', borderRadius: 8, padding: 5}}>
                                        <div style={{ ...EssentialStyle.rowFlexStartStart, width: '100%', gap: 10 }}>
                                            <div style={{ ...EssentialStyle.columnStart, width: '50%' }}>
                                                <Form.Label>{message[this.state.lang].datePerformance}</Form.Label>
                                                {this.renderDatePicker('data_performance')}
                                            </div>

                                            <div style={{ ...EssentialStyle.columnStartCenter, width: '15%', minWidth: '175px' }}>
                                                <Form.Label>{message[this.state.lang].atualiazacaoAutomatica}</Form.Label>
                                                <Form.Check 
                                                    type="checkbox" 
                                                    label={''} 
                                                    onChange={(e) => this.setState({ automatico_performance: e.target.checked })}
                                                    checked={ this.state.automatico_performance }
                                                />
                                            </div>

                                            {this.state.automatico_performance && (
                                                <div style={{ ...EssentialStyle.columnStart, width: '25%' }}>
                                                    <Form.Label>{message[this.state.lang].frequencia}</Form.Label>
                                                    <InputGroup>
                                                        <Form.Control
                                                            type="number"
                                                            min="1"
                                                            max={this.calculateMonthsDifference(this.state.data_inicio, this.state.data_fim)}
                                                            value={this.state.frequencia_performance}
                                                            onChange={(e) => this.setState({ frequencia_performance: e.target.value })}
                                                        />
                                                        <InputGroup.Text>{message[this.state.lang].meses}</InputGroup.Text>
                                                    </InputGroup>
                                                </div>
                                            )}
                                        </div>

                                        <div style={{ ...EssentialStyle.rowFlexStartStart, width: '100%', gap: 10 }}>
                                            <div style={{ ...EssentialStyle.columnStart, width: '50%' }}>
                                                <Form.Label>{message[this.state.lang].dateProcesso}</Form.Label>
                                                {this.renderDatePicker('data_processo')}
                                            </div>

                                            <div style={{ ...EssentialStyle.columnStartCenter, width: '15%', minWidth: '175px' }}>
                                                <Form.Label>{message[this.state.lang].atualiazacaoAutomatica}</Form.Label>
                                                <Form.Check 
                                                    type="checkbox" 
                                                    label={''} 
                                                    onChange={(e) => this.setState({ automatico_processo: e.target.checked })} 
                                                    checked={this.state.automatico_processo}
                                                />
                                            </div>
                                            
                                            { this.state.automatico_processo && (
                                                <div style={{ ...EssentialStyle.columnStart, width: '25%' }}>
                                                    <Form.Label>{message[this.state.lang].frequencia}</Form.Label>
                                                    <InputGroup>
                                                        <Form.Control
                                                            type="number"
                                                            min="1"
                                                            max={this.calculateMonthsDifference(this.state.data_inicio, this.state.data_fim)}
                                                            value={this.state.frequencia_processo}
                                                            onChange={(e) => this.setState({ frequencia_processo: e.target.value })}
                                                        />
                                                        <InputGroup.Text>{message[this.state.lang].meses}</InputGroup.Text>
                                                    </InputGroup>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </Collapse>
                        </Form.Group>

                        <Form.Group as={Col} md={12} xxl={12} className="mb-3">
                            <Form.Label>{message[this.state.lang].scale}*</Form.Label>
                            <IntervalSelector loading={this.state.loading} value={this.state.faixas} callback={(faixas) => { this.setState({ faixas }) }} faixaName={true} langs={IdiomaHelper.getIdiomas()}/>
                        </Form.Group>

                    </Row>

                    <div className="mb-3 d-flex flex-row-reverse">
                        <Form.Group>
                            <DefaultButton
                                color={Colors.success}
                                rightIcon={<FontAwesomeIcon icon={faChevronRight} />}
                                title={message[this.state.lang].save}
                                loading={this.state.loading}
                                onClick={() => { this.step(1) }}
                            />
                        </Form.Group>
                    </div>
                </Form>
            </div>
        );
    }

    render() {

        if (this.state.saving) return this.renderSaving();

        return (
            <div style={{ minWidth: '100%', minHeight: '100vh' }}>
                {this.renderStepBack()}
                {this.renderHelp()}
                {this.renderSteps()}
            </div>
        );
    }
}