import React from "react";

import DefaultButton from "../../../tools/DefaultButton";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusSquare, faFileAlt, faPencil, faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import { Form, Row, Col, Collapse, InputGroup } from "react-bootstrap";
import { toast } from "react-toastify";

import Select from "react-select";
import EssentialStyle from "../../../../style/EssentialStyle";
import DiagnosticoHelper from "../../../../helper/diagnostico/DiagnosticoHelper";
import SessionHelper from "../../../../helper/SessionHelper";
import LayoutHelper from "../../../../helper/LayoutHelper";
import DataHelper from "../../../../helper/DataHelper";
import Colors from "../../../../constants/Colors";

import './CriacaoRelatorio.css';
import DefaultLoader from "../../../tools/DefaultLoader";
import CustomTooltip from "../../../tools/CustomTooltip";
import IdiomaHelper from "../../../../helper/IdiomaHelper";
import AddModuloRelatorio from "./AddModuloRelatorio/AddModuloRelatorio";
import PreviwerModeloRelatorio from "./PreviwerModeloRelatorio/PreviwerModeloRelatorio";
import ModuleRelatorio from "./ModuleRelatorio/ModuleRelatorio";
import CustomConfirm from "../../../tools/CustomConfirm";
import { confirmAlert } from "react-confirm-alert";
import BoxCreateRelatorio from "./BoxCreateRelatorio/BoxCreateRelatorio";
import { withTranslation } from 'react-i18next';

class CriacaoRelatorio extends React.Component {

    state = {
        loading: false,
        addingModelo: true,
        addingModeloModuloType: null,
        addingGrupoGrafico: false,
        editingModelo: false,

        idPeriodoAvaliacao: this.props.idPeriodoAvaliacao,
        modelos: [],
        idModelo: null,

        newModelo: {
            nome: '',
            items: [],
            provisionalItem: {
                on: false,
                type: null,
                isEditing: false,
                dragIndex: 0,
                id: 0,
            },
        },

        itemEdit: {},
        savingEditModelo: false,

        colunas: [],
        colunaSelected: null,
        nodes: [],
        nodeSelected: [],
        widthSelected: 3,

        lang: this.props.lang,
        isSmallScreen: this.props.isSmallScreen || LayoutHelper.isSmallScreen(),

        headerHeight: 0,
    }

    async componentDidMount() {
        await this.props.i18n.loadNamespaces(['client_src_helper_diagnostico_DiagnosticoHelper']);

        await this.loadData();
        this.measureHeaderHeight();
        window.addEventListener('resize', this.measureHeaderHeight);
    }

    async componentDidUpdate(prevProps) {
        if (prevProps.idPeriodoAvaliacao !== this.props.idPeriodoAvaliacao) {
            this.setState({ idPeriodoAvaliacao: this.props.idPeriodoAvaliacao }, async () => {
                await this.loadData();
            });
        }

        if (prevProps.lang !== this.props.lang) {
            this.setState({ lang: this.props.lang });
        }

        if (prevProps.isSmallScreen !== this.props.isSmallScreen) {
            this.setState({ isSmallScreen: this.props.isSmallScreen });
            this.measureHeaderHeight();
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.measureHeaderHeight);
    }

    measureHeaderHeight = () => {
        const headerElement = document.querySelector('.header-content');
        if (headerElement) {
            const height = headerElement.offsetHeight;
            if (height !== this.state.headerHeight) {
                this.setState({ headerHeight: height });
            }
        }
    }

    async loadData() {
        this.setState({ loading: true });

        await this.loadModelos();

        this.setState({ loading: false });
    }

    async loadModelos() {
        let modelos = await DiagnosticoHelper.getModelosRelatorio(this.state.idPeriodoAvaliacao);
        this.setState({ modelos, addingModelo: false });
    }

    addGrupoGrafico() {
        let message = IdiomaHelper.getStruct({
            'pt_br': { textColuna: 'Selecione uma coluna', textLargura: 'Selecione a largura do gráfico', textItem: 'Selecione um item desta coluna' },
            'en': { textColuna: 'Select a column', textLargura: 'Select the chart width', textItem: 'Select an item from this column' },
            'es': { textColuna: 'Seleccione una columna', textLargura: 'Seleccione el ancho del gráfico', textItem: 'Seleccione un elemento de esta columna' },
        });

        if (!this.state.colunaSelected) {
            return toast.error(message[this.props.lang].textColuna);
        }

        if (!this.state.widthSelected) {
            return toast.error(message[this.props.lang].textLargura);
        }

        if (this.state.colunas.find((coluna) => coluna.id == this.state.colunaSelected).hasPai && this.state.nodeSelected.length == 0) {
            return toast.error(message[this.props.lang].textItem);
        }

        let grupoGrafico = {
            id: this.state.newModelo.graficos.length + 1,
            largura: this.state.widthSelected,
            id_diagnostico_periodo_questionario_coluna: this.state.colunaSelected,
            node: this.state.nodeSelected,
            nodes: this.state.nodes,
            edit: true,
            isNew: true,
        }

        this.setState({ newModelo: { ...this.state.newModelo, graficos: [...this.state.newModelo.graficos, grupoGrafico] } }, () => {
            this.setState({ colunaSelected: null, nodes: [], nodeSelected: [], widthSelected: 1, addingGrupoGrafico: false });
        });
    }

    async seveEditModelo() {
        let message = IdiomaHelper.getStruct({
            'pt_br': { textNome: 'Preencha o nome deste Modelo', textCampos: 'Adicione pelo menos um Grupo de Gráficos' },
            'en': { textNome: 'Fill in the name of this Template', textCampos: 'Add at least one Chart Group' },
            'es': { textNome: 'Complete el nombre de este Modelo', textCampos: 'Agregue al menos un Grupo de Gráficos' },
        });

        if (!this.state.newModelo.nome) {
            return toast.error(message[this.props.lang].textNome);
        }

        if (this.state.newModelo.graficos.length == 0) {
            return toast.error(message[this.props.lang].textCampos);
        }

        let modelo = await DiagnosticoHelper.saveModeloRelatorio(this.state.newModelo, this.state.idPeriodoAvaliacao);

        this.setState({ addingModelo: false, newModelo: { nome: '', graficos: [] } }, async () => {
            await this.loadModelos();
            this.setState({ idModelo: modelo.id });
        });
    }

    async deleteModelo(idModelo) {
        await DiagnosticoHelper.deleteModeloRelatorio(idModelo);

        this.setState({ idModelo: null }, () => { this.loadModelos() });
    }

    renderLoad() {

        return (
            <div className="criacaoRelatorio" style={{ boxShadow: Colors.boxShadow }}>
                <DefaultLoader />
            </div>
        )
    }

    renderBody() {

        return (
            <div 
                className="criacaoRelatorio" 
                style={{ 
                    boxShadow: Colors.boxShadow,
                }}
            >
                <div className="top">
                    {this.renderButtomAddModelo()}
                    {this.renderIsEditing()}
                    {this.renderIsCreating()}
                    {this.renderListModels()}
                </div>
                <div className="bottom">
                    {this.renderModel()}
                </div>
            </div>
        )
    }

    renderButtomAddModelo() {
        let message = IdiomaHelper.getStruct({
            'pt_br': { title: 'Criar Modelo' },
            'en': { title: 'Create Template' },
            'es': { title: 'Crear Modelo' },
        });

        return (
            <DefaultButton
                style={{ height: 38 }}
                color={Colors.success}
                title={message[this.props.lang].title}
                leftIcon={<FontAwesomeIcon icon={faPlusSquare} />}
                loading={this.state.loading}
                onClick={() => this.setState({ 
                    addingModelo: !this.state.addingModelo, 
                    idModelo: null,
                    editingModelo: false,
                    newModelo: this.state.idModelo ?
                        {
                            nome: '',
                            items: [],
                            provisionalItem: {
                                on: false,
                                type: null,
                                isEditing: false,
                                dragIndex: 0,
                                id: 0,
                            },
                        } 
                        : 
                        this.state.newModelo,
                }) }
            />
        )
    }

    renderIsEditing() {
        const message = IdiomaHelper.getStruct({
            'pt_br': { title: 'Editando Modelo' },
            'en': { title: 'Editing Template' },
            'es': { title: 'Editando Modelo' },
        });

        if (this.state.editingModelo) {
            return (
                <div className="isEditing">
                    <FontAwesomeIcon icon={faPencil}/>
                    <div className="title">{message[this.state.lang].title}</div>
                    <span className="animated-ellipsis"></span>
                </div>
            )
        }

        return <></>;
    }

    renderIsCreating() {
        const message = IdiomaHelper.getStruct({
            'pt_br': { title: 'Criando Modelo' },
            'en': { title: 'Creating Template' },
            'es': { title: 'Creando Modelo' },
        });

        if (this.state.addingModelo) {
            return (
                <div className="isEditing">
                    <FontAwesomeIcon icon={faPencil}/>
                    <div className="title">{message[this.state.lang].title}</div>
                    <span className="animated-ellipsis"></span>
                </div>
            )
        }

        return <></>;
    }

    renderListModels() {
        if (!this.state.modelos || this.state.modelos.length == 0) return <></>;
        return (
            <div className="listaModelos">
                {this.state.modelos.map((modelo, index) => {
                    return (
                        <div
                            key={`modelo-buttom-${modelo.id}`}
                            className="modelo"
                            style={{ backgroundColor: this.state.idModelo == modelo.id ? SessionHelper.getColor() : '' }}
                            onClick={() => this.setState({ 
                                idModelo: modelo.id == this.state.idModelo ? null : modelo.id, 
                                addingModelo: false, 
                                editingModelo: false,
                                newModelo: {
                                    nome: '',
                                    items: [],
                                    provisionalItem: {
                                        on: false,
                                        type: null,
                                        isEditing: false,
                                        dragIndex: 0,
                                        id: 0,
                                    },
                                },
                            })}
                        >
                            <div className="title">{modelo.nome}</div>
                        </div>
                    )
                })}
            </div>
        )
    }
    
    renderModel() {

        const message = IdiomaHelper.getStruct({
            'pt_br': { modelName: 'Nome do Modelo...', save: 'Salvar', saveTooltip: 'Salvar Modelo de Relatório', delete: 'Excluir', edit: 'Editar', clean: 'Limpar', back: 'Voltar' },
            'en': { modelName: 'Modelo Name...', save: 'Save', saveTooltip: 'Save Report Template', delete: 'Delete', edit: 'Edit', clean: 'Clean', back: 'Back' },
            'es': { modelName: 'Nombre del modelo...', save: 'Guardar', saveTooltip: 'Guardar plantilla de informe', delete: 'Eliminar', edit: 'Editar', clean: 'Limpiar', back: 'Volver' },
        });

        // ===== FUNCTIONS =====

        const seveModeloCriacao = () => {
            const message = IdiomaHelper.getStruct({
                'pt_br': { title: 'Salvar Modelo de Relatório?', message: 'Você está prestes a salvar este modelo de relatório. Isso permitirá que você crie vários relatórios usando este template. Não se preocupe, você poderá editá-lo mais tarde, se necessário.', confirm: 'Salvar', cancel: 'cancelar', errorName: 'Preencha o nome do Modelo', errorEmpty: 'Adicione pelo menos um item ao Modelo' },
                'en': { title: 'Save Report Template?', message: "You are about to save this report template. This will allow you to create multiple reports using this template. Do not worry, you can edit it later if needed.", confirm: 'Save', cancel: 'Cancel', errorName: 'Fill in the name of the Template', errorEmpty: 'Add at least one item to the Template' },
                'es': { title: '¿Guardar plantilla de informe?', message: 'Está a punto de guardar esta plantilla de informe. Esto le permitirá crear múltiples informes utilizando esta plantilla. No se preocupe, podrá editarlo más tarde si es necesario.', confirm: 'Salvar', cancel: 'Cancelar', errorName: 'Complete el nombre del Modelo', errorEmpty: 'Agregue al menos un elemento al Modelo' },
            });
    
            const saveEditModelo = async () => {
                if (this.state.savingEditModelo) return;
                this.setState({savingEditModelo: true});
                await DiagnosticoHelper.saveModeloRelatorio(this.state.newModelo, this.state.idPeriodoAvaliacao);
                await this.loadModelos();
                cleanModeloCriacao();
                this.setState({savingEditModelo: false, editingModelo: false});
            }
    
            if (!this.state.newModelo.nome || !DataHelper.removeHtmlTags(this.state.newModelo.nome)) {
                return toast.error(message[this.state.lang].errorName);
            }
    
            if (!this.state.newModelo.items || this.state.newModelo.items.length == 0) {
                return toast.error(message[this.state.lang].errorEmpty);
            }
    
            confirmAlert({
                customUI: ({ onClose }) => (
                    <CustomConfirm
                        title={message[this.state.lang].title}
                        message={
                            <p style={{ marginTop: 10, marginBottom: 10}}>{message[this.state.lang].message}</p>   
                        }
                        buttons={[
                            {
                                label: message[this.state.lang].confirm,
                                color: Colors.success,
                                textColor: Colors.light,
                                onClick: async () => {
                                    await saveEditModelo();
                                    onClose();
                                },
                            },
                            {
                                label: message[this.state.lang].cancel,
                                onClick: () => { onClose(); }
                            },
                        ]}
                    />
                )
            });
        }
    
        const cleanModeloCriacao = () => {
            this.setState({
                newModelo: {
                    nome: '',
                    items: [],
                    provisionalItem: {
                        on: false,
                        type: null,
                        isEditing: false,
                        dragIndex: 0,
                        id: 0,
                    },
                },
                addingModelo: false,
            });
        }

        const deleteModelo = () => {
            let message = IdiomaHelper.getStruct({
                'pt_br': { title: 'Excluir Modelo de Relatório?', message: 'Você está prestes a excluir este modelo de relatório. Isso não poderá ser desfeito.', confirm: 'Excluir', cancel: 'cancelar' },
                'en': { title: 'Delete Report Template?', message: "You are about to delete this report template. This cannot be undone.", confirm: 'Delete', cancel: 'Cancel' },
                'es': { title: '¿Eliminar plantilla de informe?', message: 'Está a punto de eliminar esta plantilla de informe. Esto no se puede deshacer.', confirm: 'Eliminar', cancel: 'Cancelar' },
            });
    
            confirmAlert({
                customUI: ({ onClose }) => (
                    <CustomConfirm
                        title={message[this.state.lang].title}
                        message={
                            <p style={{ marginTop: 10, marginBottom: 10}}>{message[this.state.lang].message}</p>   
                        }
                        buttons={[
                            {
                                label: message[this.state.lang].confirm,
                                color: Colors.error,
                                textColor: Colors.light,
                                onClick: async () => {
                                    await this.deleteModelo(this.state.idModelo);
                                    onClose();
                                },
                            },
                            {
                                label: message[this.state.lang].cancel,
                                onClick: () => { onClose(); }
                            },
                        ]}
                    />
                )
            });
        }

        const editModeloCriacao = () => {
            let modelo = this.state.modelos.find((modelo) => modelo.id == this.state.idModelo);
            const maxId = modelo.items.reduce((max, item) => (item.id > max ? item.id : max), 0);

            this.setState({ 
                editingModelo: true,
                newModelo: {
                    ...this.state.modelos.find((modelo) => modelo.id == this.state.idModelo),
                    provisionalItem: {
                        on: false,
                        type: null,
                        isEditing: false,
                        dragIndex: (maxId + 1),
                        id: (maxId + 1),
                    },
                }

            });
        }

        const backEditModelo = () => {
            cleanModeloCriacao();
            this.setState({ editingModelo: false });
        }

        const closeAddModuloRelatorio = () => {

            let isEditing = this.state.newModelo.provisionalItem.isEditing;
    
            if (isEditing) {
                const maxId = this.state.newModelo.items.reduce((max, item) => (item.id > max ? item.id : max), 0);
    
                this.setState({
                    addingModeloModuloType: null, 
                    newModelo: {
                        ...this.state.newModelo,
                        items: [...this.state.newModelo.items, this.state.itemEdit],
                        provisionalItem: {
                            on: false,
                            type: null,
                            isEditing: false,
                            dragIndex: maxId + 1,
                            id: maxId + 1,
                        },
                    },
                    itemEdit: {},
                });
            } else {
    
                const maxId = this.state.newModelo.items.reduce((max, item) => (item.id > max ? item.id : max), 0);
    
                this.setState({
                    addingModeloModuloType: null, 
                    newModelo: { 
                        ...this.state.newModelo, 
                        provisionalItem: { 
                            ...this.state.newModelo.provisionalItem, 
                            on: false, 
                            type: null,
                            dragIndex: maxId + 1,
                            id: maxId + 1,
                        } 
                    }
                });
            }
        }
    
        const addModuleRelatorio = () => {
            const maxId = this.state.newModelo.items.reduce((max, item) => (item.id > max ? item.id : max), 0);
    
            this.setState(prevState => {    
                return {
                    newModelo: {
                        ...prevState.newModelo,
                        items: [...prevState.newModelo.items, prevState.newModelo.provisionalItem],
                        provisionalItem: {
                            on: false,
                            type: null,
                            isEditing: false,
                            dragIndex: (maxId + 1),
                            id: (maxId + 1),
                        },
                    }
                };
            }, () => { closeAddModuloRelatorio() });
        }
    
        const editItem = (item) => {

            let newItems = this.state.newModelo.items.filter((i) => !(i.id == item.id && i.type == item.type));
            
            this.setState({
                newModelo: {
                    ...this.state.newModelo,
                    items: newItems,
                    provisionalItem: {
                        ...item,
                        isEditing: true // Adiciona a variável indicando que está em modo de edição
                    }
                },
                addingModeloModuloType: item.type,
                itemEdit: item,
            });
        }
    
        const deleteItem = (item) => {
            this.setState({newModelo: {...this.state.newModelo, items: this.state.newModelo.items.filter((i) => i.id !== item.id)}})
        }
    
        const setItems = (items) => {
            this.setState({newModelo: {...this.state.newModelo, items}});
        }

        // ===== RENDERs =====

        const renderCreateModelo = () => {
            return (
                <Form className="editModel" onSubmit={(event) => { event.preventDefault(); seveModeloCriacao() }}>
                    <Row className="editModel-row">
                        <Form.Group as={Col} md={6} style={{maxHeight: '100%', overflowY: 'auto'}}>
                            <PreviwerModeloRelatorio 
                                newModelo={this.state.newModelo} 
                                lang={this.state.lang}
                                onEditItem={(item) => { editItem(item) }}
                                onDeleteItem={(item) => { deleteItem(item) }}
                                onSetItems={(items) => { setItems(items) }}
                            />
                        </Form.Group>

                        <Form.Group as={Col} md={6} className="modulos">
                            <div className="grid_modulos">
                                {DiagnosticoHelper.getModulosModeloRelatorio(this.props.t).map((modulo, index) => {
                                    return (
                                        <ModuleRelatorio
                                            key={`modulo-buttom-${modulo.type}`}
                                            modulo={modulo}
                                            selected={this.state.addingModeloModuloType == modulo.type}
                                            onClick={() => { this.state.addingModeloModuloType == modulo.type ? closeAddModuloRelatorio() : this.setState({addingModeloModuloType: modulo.type}) }}
                                        />
                                    )
                                })}
                            </div>

                            <Collapse in={this.state.addingModeloModuloType ? true : false} style={{width: "100%"}}>
                                <div className="collapse-content">
                                    <AddModuloRelatorio
                                        lang={this.state.lang}
                                        type={this.state.addingModeloModuloType}
                                        item={this.state.newModelo.provisionalItem}
                                        onSetItem={(item) => { this.setState({newModelo: {...this.state.newModelo, provisionalItem: item}}) }}
                                        idPeriodoAvaliacao={this.state.idPeriodoAvaliacao}
                                        onClose={() => { closeAddModuloRelatorio() }}
                                        addModule={() => { addModuleRelatorio() }} 
                                    />
                                </div>
                            </Collapse>
                            
                            <div className="buttons-model">

                                <InputGroup>
                                    <InputGroup.Text>
                                        <FontAwesomeIcon
                                            icon={faFileAlt}
                                        />
                                    </InputGroup.Text>
                                    <Form.Control type="text"
                                        style={{ borderLeft: 'none' }}
                                        placeholder={message[this.state.lang].modelName}
                                        onChange={(event) => { 
                                            this.setState({ 
                                                newModelo: {
                                                    ...this.state.newModelo, 
                                                    nome: event.target.value 
                                                }
                                            });
                                        }}
                                        value={this.state.newModelo.nome}
                                    />
                                </InputGroup>
                                <DefaultButton
                                    title={message[this.state.lang].save}
                                    tooltip={message[this.state.lang].saveTooltip}
                                    color={Colors.success}
                                    onClick={() => { seveModeloCriacao() }}
                                />

                                {this.state.editingModelo ? 
                                    <DefaultButton
                                        title={message[this.state.lang].back}
                                        color={Colors.error}
                                        onClick={() => { backEditModelo() }}
                                    />
                                :
                                    <DefaultButton
                                        title={message[this.state.lang].clean}
                                        color={Colors.error}
                                        onClick={() => { cleanModeloCriacao() }}
                                    />
                                }
                            </div>
                        </Form.Group>
                    </Row>
                </Form>
            )
        };

        const renderViewModelo = () => {
            let modelo = this.state.modelos.find((modelo) => modelo.id == this.state.idModelo);
            
            if (!modelo) return <></>;

            return (
                <Form className="editModel" onSubmit={(event) => { event.preventDefault() }}>
                    <Row className="editModel-row">
                        <Form.Group as={Col} md={6} xxs={12} style={{maxHeight: '100%', overflowY: 'auto'}}>
                            <PreviwerModeloRelatorio 
                                newModelo={modelo}
                                blockEdit={true}
                                lang={this.state.lang}
                                onEditItem={(item) => { editItem(item) }}
                                onDeleteItem={(item) => { deleteItem(item) }}
                                onSetItems={(items) => { setItems(items) }}
                            />
                        </Form.Group>

                        <Form.Group as={Col} md={6} xxs={12} className="modulos">

                            <BoxCreateRelatorio idModelo={modelo.id} lang={this.props.lang} idPeriodoAvaliacao={this.props.idPeriodoAvaliacao}/>

                            <div className="buttons-model">
                                <DefaultButton
                                    title={message[this.state.lang].edit}
                                    color={Colors.success}
                                    onClick={() => { editModeloCriacao() }}
                                />

                                <DefaultButton
                                    title={message[this.state.lang].delete}
                                    color={Colors.error}
                                    onClick={() => { deleteModelo() }}
                                />
                            </div>
                        </Form.Group>
                    </Row>
                </Form>
            );
        }

        const renderNotSelected = () => {
            return DiagnosticoHelper.renderNotSelected(
                IdiomaHelper.getStruct({
                    'pt_br': 'Selecione ou Crie um Modelo de Relatório para Continuar.',
                    'en': 'Select or Create a Report Model to Continue.',
                    'es': 'Seleccione o Cree un Modelo de Informe para Continuar.',
                }),
                this.state.lang,
                this.props.t,
            );
        }

        // ===== RETURNs =====
        if (this.state.addingModelo || this.state.editingModelo) return renderCreateModelo();
        if (this.state.idModelo) return renderViewModelo();
        return renderNotSelected(null, null, this.props.t);
    }

    render() {
        return this.state.loading ? this.renderLoad() : this.renderBody();
    }
}

export default withTranslation()(CriacaoRelatorio);