import React from "react";

import "./ModalDownloadEtapaPlanoAcao.css";
import { withTranslation } from 'react-i18next';
import Modal from "../../../tools/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faExclamationTriangle, faInfoCircle, faTable } from "@fortawesome/free-solid-svg-icons";
import DefaultButton from "../../../tools/DefaultButton";
import Colors from "../../../../constants/Colors";
import EssentialStyle from "../../../../style/EssentialStyle";
import DefaultLoader from "../../../tools/DefaultLoader";
import TableImporter from "../../../tools/TableImporter/TableImporter";
import { Form, Row, Col } from "react-bootstrap";
import WindowedSelect from 'react-windowed-select';
import Sig from "../../../../api/Sig";
import DataHelper from "../../../../helper/DataHelper";
import PlanoAcaoListSearch from "../PlanoAcaoListSearch";
import TableImporterPreview from "../../../tools/TableImporterPreview/TableImporterPreview";

class ModalDownloadEtapaPlanoAcao extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            filter: {},
            planos: [],
            selectedPlanos: [],
            countPlanos: '-',
            countEtapas: '-',
            loadingDownloadModelo: false,
            importing: false,
            linesImported: 0,
            importComplete: false,
            modelo: false
        }
        this.abortController = new AbortController();
        this.abortControllerOptions = new AbortController();
    }

    async componentDidMount() {
        await this.props.i18n.loadNamespaces(['client_src_components_modules_planoAcao_ModalDownloadEtapaPlanoAcao_ModalDownloadEtapaPlanoAcao']);
        await this.load();
    }

    componentWillUnmount() {
        this.abortController.abort();
        this.abortControllerOptions.abort();
    }

    load = async () => {
        await this.loadPlanosSelection();
    }

    loadPlanosSelection = async () => {
        this.setState({ loading: true });

        this.abortControllerOptions.abort();
        this.abortControllerOptions = new AbortController();

        let req = await Sig.request(
            'POST', 
            'planoAcao/listSelectOptions', 
            { filter: JSON.stringify(this.state.filter) },
            Sig.getDefaultContentType(),
            Sig.getDefaultTransformTo(),
            this.abortControllerOptions.signal
        );

        if (req.status == 200) {
            this.setState({ planos: req }, async () => {
                this.setCountPlanos();
                this.setState({ loading: false }, async () => {
                    await this.getModel();
                });
            });
        }
    }

    getModel = async () => {
        this.setState({ loadingDownloadModelo: true });

        this.abortController.abort();
        this.abortController = new AbortController();

        let ids = [];

        if (this.state.selectedPlanos?.length) {
            ids = this.state.selectedPlanos.map(plano => plano.value);
        } else {
            ids = this.state.planos.map(plano => plano.id);
        }

        if(!ids?.length) return;
        ids = JSON.stringify(ids);

        let req = await Sig.request(
            'POST',
            'planoAcao/getModeloImportacao',
            { edit: true, ids },
            Sig.getDefaultContentType(),
            Sig.getDefaultTransformTo(),
            this.abortController.signal
        );

        if (req.status == 200) {
            this.setState({ modelo: req.modelo });
        }

        this.setState({ loadingDownloadModelo: false });
    }

    renderTitle() {
        return (
            <div className="titleModal">
                <div className="titleLeft">
                    <FontAwesomeIcon icon={faDownload} />
                    {this.props.t('client_src_components_modules_planoAcao_ModalDownloadEtapaPlanoAcao_ModalDownloadEtapaPlanoAcao:exportar_planos_e_etapas_de_plano')}
                </div>
            </div>
        )
    }

    renderLoadingPlaceholder = () => {
        let text = this.props.t('client_src_components_modules_planoAcao_ModalDownloadEtapaPlanoAcao_ModalDownloadEtapaPlanoAcao:selecione_ou_vazio_para_todos');
        if (this.state.loading) text = this.props.t('client_src_components_modules_planoAcao_ModalDownloadEtapaPlanoAcao_ModalDownloadEtapaPlanoAcao:carregando_planos');
        return text;
    }

    setCountPlanos = () => {
        this.setState({ countPlanos: '-', countEtapas: '-' });

        let planos = 0, etapas = 0;

        if (this.state.selectedPlanos?.length) {
            planos = this.state.selectedPlanos.length;
            this.state.selectedPlanos.forEach(plano => {
                let findPlano = this.state.planos.find(p => p.id == plano.value);
                etapas += parseInt(findPlano.etapasCount);
            });
        } else {
            planos = this.state.planos?.length;

            this.state.planos.forEach(plano => {
                etapas += parseInt(plano.etapasCount);
            });
        }

        this.setState({ countPlanos: planos, countEtapas: etapas });
    }

    filter = async (filter, closeFilter = false) => {
        this.setState({ loading: true, filter }, async () => {
            this.load();
        });
    }

    renderSelectPlanos = () => {
        return (
            <div className="bodyModal">
                <Form style={{ width: 'calc(100% - 10px)' }}>
                    <Row className="mb-3">
                        <Form.Group as={Col}>
                            <Form.Label>{this.props.t('client_src_components_modules_planoAcao_ModalDownloadEtapaPlanoAcao_ModalDownloadEtapaPlanoAcao:filtros')}</Form.Label>
                            <PlanoAcaoListSearch
                                filterCallback={this.filter}
                                isSmallScreen={this.props.isSmallScreen}
                                type={'lista'}
                                nome={false}
                                advancedFilter={true}
                                parceirosChecked={false}
                            />
                        </Form.Group>
                    </Row>
                    <Row className="mb-3">
                        <Form.Group as={Col}>
                            <Form.Label>{this.props.t('client_src_components_modules_planoAcao_ModalDownloadEtapaPlanoAcao_ModalDownloadEtapaPlanoAcao:planos_de_acao')}</Form.Label>
                            <WindowedSelect
                                isClearable isSearchable isMulti
                                isDisabled={this.state.loading}
                                options={DataHelper.formatSelectData(this.state.planos, 'id', 'nome')}
                                placeholder={this.renderLoadingPlaceholder()}
                                noOptionsMessage={DataHelper.getSelectEmptyMessage}
                                value={this.state.selectedPlanos}
                                onChange={(value) => {
                                    this.setState({ selectedPlanos: value }, () => {
                                        this.setCountPlanos();
                                        this.getModel();
                                    })
                                }}
                                windowThreshold={100}
                            />
                        </Form.Group>
                    </Row>
                    <Row className="mb-3">
                        <Form.Group as={Col}>
                            <Form.Label><FontAwesomeIcon icon={faInfoCircle} /> {this.props.t('client_src_components_modules_planoAcao_ModalDownloadEtapaPlanoAcao_ModalDownloadEtapaPlanoAcao:info_download', { countPlanos: this.state.countPlanos, countEtapas: this.state.countEtapas })}</Form.Label>
                            <Form.Label style={{ color: Colors.error }}><FontAwesomeIcon icon={faInfoCircle} /> {this.props.t('client_src_components_modules_planoAcao_ModalDownloadEtapaPlanoAcao_ModalDownloadEtapaPlanoAcao:will_lose_images')}</Form.Label>
                        </Form.Group>
                    </Row>
                    <Row className="mb-3">
                        <Form.Group as={Col}>
                            <Form.Label><FontAwesomeIcon icon={faTable} /> {this.props.t('client_src_components_modules_planoAcao_ModalDownloadEtapaPlanoAcao_ModalDownloadEtapaPlanoAcao:preview')}</Form.Label>
                            <TableImporterPreview fileModel={this.state.modelo} loading={this.state.loadingDownloadModelo || this.state.loading} />
                        </Form.Group>
                    </Row>
                </Form>
            </div>
        )
    }

    renderLoading = () => {
        return (
            <div className="bodyModal loading">
                <DefaultLoader />
            </div>
        )
    }

    renderBody() {
        return this.renderSelectPlanos();
    }

    renderFooter() {
        return (
            <div style={{ ...EssentialStyle.rowFlexEnd, width: '100%', marginTop: 10 }}>
                <DefaultButton
                    title={this.props.t('client_src_components_modules_planoAcao_ModalDownloadEtapaPlanoAcao_ModalDownloadEtapaPlanoAcao:fechar')}
                    color={Colors.dark}
                    textColor={Colors.white}
                    onClick={() => {
                        this.props.closeModal()
                    }}
                />
            </div>
        )
    }

    render() {
        return (
            <Modal
                className={"ModalUploadEtapaPlanoAcao"}
                style={{
                    width: '95vw',
                    height: '100vh',
                    transition: 'height 0.5s ease',
                    boxShadow: '0px 0px 20px 20px rgba(50, 50, 50, 0.5)',
                    justifyContent: 'space-between',
                }}
                onClose={this.props.closeModal}
            >
                {this.renderTitle()}
                {this.renderBody()}
                {this.renderFooter()}
            </Modal>
        )
    }
}

export default withTranslation()(ModalDownloadEtapaPlanoAcao);