import React, { Component } from 'react';
import './Avaliacao.css';

import EssentialStyle from '../../../../style/EssentialStyle';
import TooManyAvatars from '../../../tools/TooManyAvatars';
import DefaultButton from '../../../tools/DefaultButton';
import Colors from '../../../../constants/Colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faBuilding, faUserCheck } from '@fortawesome/free-solid-svg-icons';
import DiagnosticoHelper from '../../../../helper/diagnostico/DiagnosticoHelper';
import IdiomaHelper from '../../../../helper/IdiomaHelper';
import DataHelper from '../../../../helper/DataHelper';
import EllipsisText from '../../../tools/EllipsisText';
import { withTranslation } from 'react-i18next';
import CustomTooltip from '../../../tools/CustomTooltip';

class AvaliacaoCard extends Component {

    state = { 
        lang: IdiomaHelper.getIdioma(),
        loading: true,
    }

    async componentDidMount() {
        this.setState({ loading: true });
        await this.props.i18n.loadNamespaces(['client_src_components_modules_diagnostico_Avaliacao_AvaliacaoCard']);
        this.setState({ loading: false });
    }

    render() {
        const { avaliacao } = this.props;

        let isEditing = this.props.editingAvaliacaoAvaliadores?.id == avaliacao.id;

        const tipos = {
            1: { icon: <FontAwesomeIcon icon={faBuilding} />, text: this.props.t('client_src_components_modules_diagnostico_Avaliacao_AvaliacaoCard:avaliacao') },
            2: { icon: <FontAwesomeIcon icon={faUserCheck} />, text: this.props.t('client_src_components_modules_diagnostico_Avaliacao_AvaliacaoCard:autoavaliacao') },
        };

        const renderTipoAvaliacao = () => {
            var tipo = avaliacao.tipo;
            return (
                <div className="tipo-avaliacao">
                    <CustomTooltip tooltip={this.props.t(`client_src_components_modules_diagnostico_Avaliacao_AvaliacaoCard:tooltip${tipo}`)}>
                        <div 
                            style={{
                                ...EssentialStyle.columnCenter, 
                                gap: 5, 
                                padding: 5,
                                borderRadius: 5,
                                backgroundColor: Colors.diagnostico.avaliacao.tipo[tipo],
                                fontWeight: 'bold',
                                color: 'white',
                                fontSize: '0.85rem',
                            }}
                            >
                            <div style={{ ...EssentialStyle.rowFlexCenter, gap: 5 }}>
                                {tipos[tipo].icon}
                                {tipos[tipo].text}
                            </div>
                            {tipo == 1 ? avaliacao?.empresaAvaliadora?.nome_fantasia : ''}
                        </div>
                    </CustomTooltip>
                </div>
            )
        }

        const renderNomeQuestionario = () => (
            <div style={{ ...EssentialStyle.rowSpaceBetween }} className="nome-questionario">
                <EllipsisText text={DataHelper.removeHtmlAndReplaceListItems(avaliacao?.questionario?.nome)} />
            </div>
        )

        const renderAvaliadores = () => (
            <div style={{ ...EssentialStyle.rowSpaceBetween }} className="avaliadores">
                <div>
                    {avaliacao?.avaliadores.length > 0 && (
                        <TooManyAvatars
                            participantsTotal={avaliacao?.avaliadores.length}
                            participantsVisiveis={3}
                            arrayParticipants={avaliacao?.avaliadores}
                        />
                    )}
                </div>

                {this.props.canEdit &&
                    <DefaultButton
                        leftIcon={<FontAwesomeIcon icon={faEdit} />}
                        style={{ padding: '3px 6px' }}
                        color={Colors.success}
                        className="button-edit-avaliadores"
                        onClick={(e) => {
                            e.stopPropagation();
                            if (!isEditing) {
                                this.props.changeEditAvaliadores(avaliacao);
                            } else {
                                this.props.changeEditAvaliadores();
                            }
                        }}
                    />
                }
            </div>
        )

        const renderCelStatus = () => {
            return (
                <div className="status-avaliacao">
                    {DiagnosticoHelper.renderStatusAvaliacao(avaliacao.status, avaliacao.isAtrasada, avaliacao, this.state.lang, this.props.t)}
                </div>
            )
        }

        return (
            <div 
                style={EssentialStyle.card} 
                className={`avaliacao-card ${avaliacao.link ? "clickable" : ""}`} 
                key={avaliacao.id}
                onClick={() => {
                    if (avaliacao.link) {
                        window.parent.postMessage({ type: '_blank', url: avaliacao.link }, '*'); 
                    }
                }}
            >
                {renderNomeQuestionario()}
                <div className="itens-avaliacao">
                    {renderTipoAvaliacao()}
                    {renderAvaliadores()}
                    {renderCelStatus()}
                </div>
            </div>
        );
    }
}

export default withTranslation()(AvaliacaoCard);