import React from "react";

import DiagnosticoHelper from "../../../../../../helper/diagnostico/DiagnosticoHelper";
import Colors from "../../../../../../constants/Colors";

import { Form, Row, Col } from 'react-bootstrap';
import RelatorioGrafico from "./RelatorioGrafico/RelatorioGrafico";
import EssentialStyle from "../../../../../../style/EssentialStyle";

import './RelatorioGraficosItem.css';
import moment from "moment";
import DefaultLoader from "../../../../../tools/DefaultLoader";


export default class RelatorioGraficosItem extends React.Component {

    state = {
        item: this.props.item || null,
        idItem: this.props.idItem || null,
    }

    render () {

        let charts = this.state.item.chartGroup?.sort((a, b) => a.dragIndex - b.dragIndex);
        
        return (
            <div className="RelatorioGraficosItem" style={{ boxShadow: Colors.boxShadow }}>
                <Form style={{ width: '100%', ...EssentialStyle.columnCenter }}>
                    <Row className="g-2" style={{ width: '100%' }}>
                        {charts?.map((grafico) => (
                            <Col key={grafico.id} md={DiagnosticoHelper.getWidthToForm(grafico.largura, this.props.isSmallScreen)}>
                                <Form.Group>
                                    <RelatorioGrafico 
                                        key={`relatorio-grafico-${grafico.id}`} 
                                        grafico={grafico}
                                        data_processo={this.props.data_processo}
                                        isSmallScreen={this.props.isSmallScreen}
                                        lang={this.props.lang}
                                    />
                                </Form.Group>
                            </Col>
                        ))}
                    </Row>
                </Form>
            </div>
        );
    }

}