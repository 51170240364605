import React from "react";

import './RetrospectivaPlanoAcao2024.css';
import EssentialStyle from "../../../../style/EssentialStyle";
import Constants from '../../../../constants/Api';
import Colors from "../../../../constants/Colors";

const gateway = Constants.getSigEndPoint();

export default class RetrospectivaPlanoAcao2024 extends React.Component {
    state = {
        planosAcao: this.props.planosAcao,
        screenHeight: this.props.screenHeight,
        isSmallScreen: this.props.isSmallScreen
    }

    async componentDidUpdate(prevProps) {
        if (prevProps.planosAcao !== this.props.planosAcao) {
            this.setState({ planosAcao: this.props.planosAcao });
        }

        if (prevProps.screenHeight !== this.props.screenHeight) {
            this.setState({ screenHeight: this.props.screenHeight });
        }

        if (prevProps.isSmallScreen !== this.props.isSmallScreen) {
            this.setState({ isSmallScreen: this.props.isSmallScreen });
        }
    }

    renderLeft() {
        return (
            <div
                style={{
                    ...EssentialStyle.columnStart,
                    width: '45%',
                    height: '100%',
                }}
            >
                <div
                    style={{
                        ...EssentialStyle.columnStart,
                        paddingLeft: 30,
                        fontSize: 28,
                        fontWeight: '500',
                        marginBottom: 60,
                        fontSize: '3em',
                        marginTop: 60
                    }}
                >
                    <div
                        style={{
                            ...EssentialStyle.rowFlexStart,
                            backgroundColor: 'rgb(116, 0, 18)',
                            marginLeft: -15,
                            paddingRight: 15
                        }}
                    >
                        <span style={{ paddingLeft: 15 }}>PLANEJAR&</span>
                    </div>
                    <span>FAZER&</span>
                    <span>CHECAR&</span>
                    <div
                        style={{
                            ...EssentialStyle.rowFlexStart,
                            backgroundColor: 'rgb(116, 0, 18)',
                            marginLeft: -15,
                            paddingRight: 15
                        }}
                    >
                        <span style={{ paddingLeft: 15 }}>APRENDER</span>
                    </div>
                </div>
                <div
                    style={{
                        ...EssentialStyle.columnStart,
                        marginLeft: '30%',
                        marginTop: -(this.state.screenHeight * 0.04)
                    }}
                >
                    <img
                        src={`${gateway}/img/retrospectiva/retrosig24_balls.png`}
                        alt="Logo da Retrospectiva SIG 2024, com elementos geométricos a esquerda e RetroSIG escrito em uma fonte moderna a direita."
                        style={{
                            width: this.state.screenHeight * 0.46
                        }}
                    />
                </div>
            </div>
        );
    }

    renderRight() {
        return (
            <div
                style={{
                    ...EssentialStyle.columnStart,
                    height: '100%',
                    width: this.state.isSmallScreen ? '100%' : '55%',
                    paddingTop: this.state.isSmallScreen ? '20%' : '0%',
                    paddingBottom: this.state.isSmallScreen ? '20%' : '0%'
                }}
            >
                <div
                    style={{
                        ...EssentialStyle.columnEnd,
                        width: '100%',
                        textAlign: 'right',
                        paddingRight: 30,
                    }}
                >
                    <span
                        style={{
                            fontSize: this.state.isSmallScreen ? '60px' : '90px',
                            fontWeight: '600'
                        }}
                    >
                        {this.state.planosAcao.qtdPlanosAcao} Planos de Ação
                    </span>
                    <span
                        style={{
                            marginTop: '-5%',
                            fontSize: this.state.isSmallScreen ? '50px' : '80px',
                            fontWeight: '200'
                        }}
                    >
                        Concluídos
                    </span>
                    <span
                        style={{
                            marginTop: '8%',
                            fontSize: this.state.isSmallScreen ? '40px' : '70px',
                            fontWeight: '400'
                        }}
                    >
                        {this.state.planosAcao.qtdAtividades} Atividades
                    </span>
                    <span
                        className="retro-default-text"
                        style={{
                            minWidth: '95%',
                            marginTop: '12%',
                            fontWeight: '400'
                        }}
                    >
                        Planejar é essencial, mas é na execução onde a mágica acontece! Você concluiu {this.state.planosAcao.qtdPlanosAcao} {this.state.planosAcao.qtdPlanosAcao > 1 ? `planos` : `plano`} de ação e completou {this.state.planosAcao.qtdAtividades} {this.state.planosAcao.qtdAtividades > 1 ? `atividades` : `atividade`}. Seu impacto foi sentido em cada entrega realizada.
                    </span>
                </div>
            </div>
        );
    }

    renderPlanoAcao() {
        return (
            <div
                style={{
                    ...EssentialStyle.columnCenter,
                    width: '100%',
                    height: '100%'
                }}
            >
                <div
                    style={{
                        ...EssentialStyle.rowSpaceBetween,
                        flexDirection: this.state.isSmallScreen ? 'column-reverse' : 'row',
                        width: '100%',
                        height: this.state.isSmallScreen ? 'auto' : '65%'
                    }}
                >
                    {!this.state.isSmallScreen ? this.renderLeft() : null}
                    {this.renderRight()}
                </div>
            </div>
        );
    }

    render() {
        return (
            <div
                style={{
                    ...EssentialStyle.columnCenter,
                    width: '100%',
                    height: this.state.isSmallScreen ? 'auto' : this.state.screenHeight
                }}
            >
                {this.renderPlanoAcao()}
            </div>
        );
    }
}