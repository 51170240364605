import React from "react";
import EssentialStyle from "../../style/EssentialStyle";
import DiagnosticoHelper from "../../helper/diagnostico/DiagnosticoHelper";
import DataHelper from "../../helper/DataHelper";
import LayoutHelper from "../../helper/LayoutHelper";
import LoadingPage from "../LoadingPage";
import Colors from "../../constants/Colors";
import moment from "moment";
import Select from 'react-select';
import { Form } from "react-bootstrap";
import DefaultButton from "../../components/tools/DefaultButton";
import './DiagnosticoResultadoPage.css';
import UnderConstruction from "../../components/tools/UnderConstruction";
import IdiomaHelper from "../../helper/IdiomaHelper";
import DefaultTableSearch from "../../components/tools/DefaultTableSearch";
import { Collapse } from "react-bootstrap";
import PlanoAcaoListSearch from "../../components/modules/planoAcao/PlanoAcaoListSearch";
import { toast } from "react-toastify";
import Sig from "../../api/Sig";
import PlanoAcaoRelatorio from "../../components/modules/planoAcao/PlanoAcaoRelatorio/PlanoAcaoRelatorio";
import { withTranslation } from 'react-i18next';

class DiagnosticoPlanosAcaoPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            construction: false,
            data: [],
            filter: {},
            loading: true,
            loadingHeader: true,
            periodos: [],
            idPeriodoAvaliacao: null,
            isEmpresaAvaliadora: false,
            advancedFilter: false,
            idProcesso: null,
            headerSize: 0,
            lang: IdiomaHelper.getIdioma(),
            tipoGrafico: 1,
            isSmallScreen: LayoutHelper.isSmallScreen(),
        }
        this.headerRef = React.createRef();
        this.interval = null;
    }

    async componentDidMount() {
        await this.props.i18n.loadNamespaces(['client_src_helper_diagnostico_DiagnosticoHelper']);

        this.handleUrlParams();
        window.addEventListener('resize', this.verifyScreen);
        window.addEventListener('message', this.handleMessage);
        this.interval = setInterval(this.updateHeaderSize, 10);
    }

    updateHeaderSize = () => {
        if (this.headerRef.current) {
            this.setState({ headerSize: this.headerRef.current.clientHeight });
            if (this.interval) clearInterval(this.interval);
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.verifyScreen);
        window.removeEventListener('message', this.handleMessage);
    }

    countFilters = () => {
        let count = 0;
        for (let key in this.state.filter) {
            if ((this.state.filter[key] && !Array.isArray(this.state.filter[key]))) {
                if (key == 'dataInicio' && this.state.filter['dataFim']) {
                    count++;
                } else if (key == 'dataFim' || key == 'tipoData' || key == 'parceirosChecked') {
                    continue;
                } else {
                    count++;
                }
            } else if (Array.isArray(this.state.filter[key]) && this.state.filter[key].length > 0) {
                count++;
            }
        }
        return count;
    }

    handleToggleExpand = (advancedFilter) => {
        this.setState({ advancedFilter: advancedFilter });
    }

    filter = async (filter, closeFilter = false) => {
        this.setState({ loading: true, filter }, () => {
            this.loadPlanos(true);
            if (closeFilter) this.handleToggleExpand(false);
        });
    }

    handleSearch = async (text) => {
        this.setState({ loading: true, filter: { ...this.state.filter, nome: text } }, () => {
            this.loadPlanos(true);
        });
    }

    handleUrlParams = () => {
        window.parent.postMessage({ type: 'get_url' }, '*');

        const listenerFunction = async (event) => {
            if (event.data.type === 'return_url') {
                const urlObj = new URL(event.data.url);
                const queryParams = new URLSearchParams(urlObj.search);
                window.removeEventListener('message', listenerFunction);

                // Extract the last segment from the pathname
                const pathSegments = urlObj.pathname.split('/');
                const lastSegment = pathSegments[pathSegments.length - 1];

                this.setState({ idProcesso: parseInt(lastSegment) }, () => {
                    this.loadData();
                });
            }
        };

        window.addEventListener('message', listenerFunction);
    }

    handleMessage = (event) => {
        if (event.data.type === 'popstate') {
            if (event.data.url === this.state.lastPopState) {
                this.setState({ showDetailsModal: null, lastPopState: null });
                return;
            }

            this.setState({ lastPopState: event.data.url });
            this.handleUrlParams();
        }
    }

    verifyScreen = () => {
        this.setState({ isSmallScreen: LayoutHelper.isSmallScreen() });
    }

    async loadData() {
        this.setState({ loadingHeader: true });

        let { periodos, canEdit, canView } = await DiagnosticoHelper.getPeriodosAvaliacao(this.state.idProcesso);

        let idPeriodoAvaliacao = null;
        let localId = DiagnosticoHelper.getPeriodoAvaliacaoLocal(this.props.idProcesso);

        if (localId && periodos.find(p => p.id == localId)) {
            idPeriodoAvaliacao = localId;
        } else {
            idPeriodoAvaliacao = periodos.length > 0 ? periodos[0].id : null;
        }

        let isEmpresaAvaliadora = await DiagnosticoHelper.isEmpresaAvaliadora(this.state.idProcesso);

        this.setState({ loadingHeader: false, periodos, idPeriodoAvaliacao, isEmpresaAvaliadora, filter: {} }, () => this.loadPlanos());
    }

    async loadPlanos(silent = false) {
        this.setState({ loading: false });
    }

    onSelectTipo(tipo) {
        this.setState({ tipoGrafico: tipo });
    }

    renderPage() {
        return (
            <div className="DiagnosticoResultadoPage">
                {this.renderHeader()}
                {this.state.idPeriodoAvaliacao ? this.renderBody() : DiagnosticoHelper.renderPeriodoNotSelected(this.props.t, this.state.loading)}
            </div>
        );
    }

    renderHeader() {
        let localStyle = {
            boxShadow: Colors.boxShadow,
            ...(this.state.isSmallScreen ? EssentialStyle.columnCenter : EssentialStyle.rowSpaceBetween)
        }

        let outerStyle = {
            width: "100%",
            top: 0,
            position: "sticky",
            zIndex: 5
        };

        return (
            <div className="relatorio-header" style={outerStyle}>
                <div ref={this.headerRef} className="header" style={localStyle}>
                    <div className="left" style={{ width: this.state.isSmallScreen ? '100%' : '' }}>
                        <Form.Group>
                            <Select
                                styles={{ control: (base) => ({ ...base, border: 'none', minWidth: '40vh', marginRight: 2 }) }}
                                options={DataHelper.formatSelectData(this.state.periodos, 'id', 'nome')}
                                value={DataHelper.formatSelectData(this.state.periodos, 'id', 'nome').find(option => option.value === this.state.idPeriodoAvaliacao)}
                                onChange={(valueSelected) => { this.setState({ idPeriodoAvaliacao: valueSelected.value }, () => this.loadPlanos()); DiagnosticoHelper.setPeriodoAvaliacaoLocal(valueSelected.value, this.props.idProcesso); }}
                                placeholder={'Selecione um Período de Avaliação'}
                                noOptionsMessage={DataHelper.getSelectEmptyMessage}
                                isSearchable
                                menuPortalTarget={document.body}
                            />
                        </Form.Group>
                    </div>

                    <div className="right" style={{ width: this.state.isSmallScreen ? '100%' : 'unset', ...EssentialStyle.rowSpaceBetween }}>
                        <DefaultTableSearch
                            handleSearch={this.handleSearch}
                            onEmpty={() => { }}
                            toggleExpand={this.handleToggleExpand}
                            appliedFilters={this.countFilters()}
                            text={this.state.filter.nome}
                            advancedFilter={this.state.advancedFilter}
                            type={'relatorioDiagnostico'}
                        />
                        <DefaultButton
                            style={{ width: 38, height: 38, marginLeft: 8 }}
                            fontSize={14}
                            color={Colors.secondaryButton}
                            textColor={Colors.dark}
                            link={'https://advis.freshdesk.com/support/solutions/folders/63000238312'} />
                    </div>
                </div>
                <Collapse in={this.state.advancedFilter}>
                    <div style={{
                        position: "absolute",
                        zIndex: 5,
                        width: "100%",
                    }}
                    >
                        <PlanoAcaoListSearch
                            filterCallback={this.filter}
                            isSmallScreen={this.state.isSmallScreen}
                            type={'relatorioDiagnostico'}
                            nome={this.state.filter.nome}
                            advancedFilter={this.state.advancedFilter}
                            parceirosChecked={this.state.isEmpresaAvaliadora}
                            idPeriodoAvaliacao={this.state.idPeriodoAvaliacao}
                        />
                    </div>
                </Collapse>
            </div>
        )
    }

    renderBody() {
        if (this.state.idPeriodoAvaliacao === null) return (<div className="body">{DiagnosticoHelper.renderPeriodoNotSelected(this.props.t)}</div>);

        return (
            <PlanoAcaoRelatorio
                loading={this.state.loading}
                isSmallScreen={this.state.isSmallScreen}
                headerSize={this.state.headerSize}
                parceiro={this.state.isEmpresaAvaliadora ? 1 : 0}
                filter={this.state.filter}
                id_periodo_avaliacao={this.state.idPeriodoAvaliacao}
            />
        )
    }

    render() {
        if (this.state.construction) return <UnderConstruction height={'100vh'} />
        if (this.state.loadingHeader) return <LoadingPage />;
        if (this.state.idProcesso === null) return <div style={EssentialStyle.pageContainer}>Erro ao carregar página</div>; // Enviar para página de "Ver todos os processos"
        return this.renderPage();
    }
}

export default withTranslation()(DiagnosticoPlanosAcaoPage);