import React from "react";
import { withTranslation } from "react-i18next";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faMaximize, faMinimize, faArrowLeft, faFilter, faSearch, faBroom } from "@fortawesome/free-solid-svg-icons";
import SessionHelper from "../../../../helper/SessionHelper";
import Colors from "../../../../constants/Colors";
import EssentialStyle from "../../../../style/EssentialStyle";
import DefaultLoader from "../../../tools/DefaultLoader";
import DefaultButton from "../../../tools/DefaultButton";
import { Row, Col, Form, Collapse } from "react-bootstrap";
import Select from 'react-select'
import Sig from "../../../../api/Sig";
import DataHelper from "../../../../helper/DataHelper";
import UserAvatar from "../../../tools/UserAvatar";
import ChartHelper from "../../../../helper/inicio/ChartHelper";
import CustomTooltip from "../../../tools/CustomTooltip";
import Highcharts from "highcharts/highcharts.js";
import HighchartsReact from "highcharts-react-official";
import variablePie from "highcharts/modules/variable-pie.js";
import DatePicker from "react-datepicker";
import { forwardRef } from 'react';
import moment from "moment";
import { toast } from 'react-toastify';
import LayoutHelper from "../../../../helper/LayoutHelper";
import { Accordion } from "react-bootstrap";
import Constants from "../../../../constants/Api";
import IdiomaHelper from "../../../../helper/IdiomaHelper";

const gateway = Constants.getSigEndPoint();

variablePie(Highcharts);

class PlanosAcaoMinhaEquipe extends React.Component {
    state = {
        loading: true,
        subordinados: [],
        transform: 'translateY(+100%)',
        opacity: 0,
        hoverSubordinado: null,
        data_inicio: moment().subtract(1, 'years').toDate(),
        totalPlanos: {
            total: 0,
        },
        data: [],
        viewCard: null,
        expanded: this.props.expandedPageTwo,
        status: [
            "Não Iniciado",
            "Em Desenvolvimento",
            "Concluído",
            "Atrasado",
            "Cancelado",
        ],
        advancedFilter: false,
        filtro: {
            nome: null,
            tipoVinculo: null,
            tipo: null,
            tags: null,
        },
        filtroOptions: {
            tags: [],
            tipoVinculo: [],
            tipo: [],
        }
    }

    async componentDidMount() {
        await this.props.i18n.loadNamespaces(['client_src_components_modules_inicio_charts_PlanosAcaoMinhaEquipe']);
        await this.getEquipeColaborador();
    }

    async componentDidUpdate(prevProps) {
        if ((this.props.userId && this.props.userId !== prevProps.userId) 
            || (this.props.reloadEquipe !== prevProps.reloadEquipe)
            || (this.props.viewUserConfig !== prevProps.viewUserConfig)) {
                
            await this.getEquipeColaborador();
        }
    }

    getEquipeColaborador = async () => {
        try {
            this.setState({ loading: true, transform: 'translateY(0)', opacity: 1, advancedFilter: false });

            let { subordinados, totalPlanos, tags, tipoVinculo, tipo } = await Sig.request('GET', 'config/colaborador/getEquipePlanosAcao', { 
                id_colaborador: this.props.userId, 
                data_inicio: moment(this.state.data_inicio).format('YYYY-MM-DD HH:mm:ss'),
                filtro: JSON.stringify(this.state.filtro),
                viewUserConfig: this.props.viewUserConfig
            });
            
            let tipoVinculoList = Object.keys(tipoVinculo).map(key => ({
                id: key,
                nome: tipoVinculo[key]
            }));

            let tipoList = [];
            for (let tipoElement in tipo) {
                let chave = tipoElement;
                let valor = tipo[tipoElement];

                tipoList.push({id: chave, nome: valor})
            }

            let filtroOptions = {
                tags: DataHelper.formatSelectData(tags, 'id', 'nome'),
                tipoVinculo: DataHelper.formatSelectData(tipoVinculoList, 'id', 'nome'),
                tipo: DataHelper.formatSelectData(tipoList, 'id', 'nome'),
            }

            this.setState({ subordinados, totalPlanos, filtroOptions }, () => {
                this.calculaGrafico();
            });

        } catch (error) {
            toast.error(this.props.t('client_src_components_modules_inicio_charts_PlanosAcaoMinhaEquipe:errors.load_team'));
        }
    }

    calculaGrafico(){
        this.setState({ loading: true});
        let data = [];
        
        if (this.state.viewCard == null) {
            Object.entries(this.state.totalPlanos).forEach(([key, value]) => {

                if (key !== 'total') {
                    data.push({
                        name: key,
                        y: value,
                        yPercent: Math.round((value / this.state.totalPlanos.total) * 100),
                        color: ChartHelper.getColor(key),
                    })
                }
            });
        } else {

            let subordinado = this.state.subordinados.find(subordinado => subordinado.id === this.state.viewCard);

            this.state.status.forEach(status => {
                let planosStatus = subordinado.planos.filter(plano => plano.status === status);
                data.push({
                    name: status,
                    y: planosStatus.length || 0,
                    yPercent: Math.round((planosStatus.length / subordinado.planos.length) * 100),
                    color: ChartHelper.getColor(status),
                });
            });
        }

        this.setState({ data, loading: false });
    }

    renderDatePickerInicio() {
        const ExampleCustomInput1 = forwardRef(({ value, onClick }, ref) => (
            <Form.Control 
                onChange={() => {}}
                onClick={onClick} 
                    ref={ref}
                    style={{ marginLeft: 10, fontSize: 14, color: Colors.homePage.darkGrey, cursor: 'pointer', boxShadow: 'none', backgroundColor: 'transparent', padding: 1, height: '100%', width: 100,  textAlign: 'center'}}
                    value={value} >
            </Form.Control>
        ));
        
        return (
            <DatePicker
                selected={this.state.data_inicio}
                type="date"
                onChange={(event) => {
                    let novoInicio = moment(event);
                    if (novoInicio.toDate() !== this.state.data_inicio){
                        this.setState({ data_inicio: novoInicio.toDate()}, () => {
                            this.getEquipeColaborador();
                        });
                    }
                }}
                dateFormat="MM/yyyy"
                showMonthYearPicker
                customInput={<ExampleCustomInput1 />}
                popperPlacement={LayoutHelper.isSmallScreen() ? "bottom-start" : "top-start"}
            />
        )
    };

    renderLoadingPlaceholder() {
        let size = 40;

        return (
            <div>
                <div style={{
                    height: size, 
                    width: '100%', 
                    borderRadius: 5, 
                    background: `linear-gradient(270deg, ${Colors.homePage.lightGrey}, ${Colors.homePage.extraLightGrey}, ${Colors.homePage.lightGrey})`,
                    backgroundSize: '200% 200%',
                    animation: 'gradient 2s ease infinite',
                }}>
                </div>

                <style jsx>{`
                @keyframes gradient {
                    0% {background-position: 0% 50%;}
                    50% {background-position: 100% 50%;}
                    100% {background-position: 0% 50%;}
                }
                `}</style>
            </div>
        );
    }

    renderLoading(bloco) {
        const trackColors = Highcharts.getOptions().colors.map(color =>
            new Highcharts.Color(color).setOpacity(0.3).get()
        );

        if (bloco === 2) {
            return (
                <div style={{...EssentialStyle.columnStart, width: '100%', overflow: 'hidden'}}>
                    {Array.from({ length: this.props.numberSubordinados }).map((elem, index) => (
                        <div key={`plhdr-${index}`} style={{ marginTop: 5, width: '100%' }}>
                            {this.renderLoadingPlaceholder()}
                        </div>
                    ))}
                </div>
            );
        } else {
            return (
                <div style={{...EssentialStyle.columnCenter, width: '100%', height: '100%'}}>
                    <DefaultLoader size={50} />
                </div>
            );
        }

    }

    renderBarra(subordinado) {
        const status = this.state.status;

        const statusCounts = status.reduce((counts, status) => {
            counts[status] = subordinado.planos.filter(plano => plano.status === status).length;
            return counts;
        }, {});

        const total = subordinado.planos.length;

        const renderDiv = (status) => (
           
            <div 
                key={status}
                style={{ 
                    ...EssentialStyle.rowFlexStart,
                    height: (subordinado.id == this.state.subordinadoHovered && status != this.state.statusHovered) ? 30 : 35,
                    width: `${(statusCounts[status] / total) * 100}%`,
                    minWidth: statusCounts[status] > 0 ? 15 : 0,
                    boxShadow: subordinado.id == this.state.subordinadoHovered && status == this.state.statusHovered ? '0px 0px 10px rgba(0, 0, 0, 0.5)' : '0px 0px 0px rgba(0, 0, 0, 0)',
                    backgroundColor: ChartHelper.getColor(status),
                    transition: 'height 0.5s ease, boxShadow 0.5s ease',
                    overflow: 'visible',

                }}
                onMouseEnter={() => {
                    this.setState({ 
                        statusHovered: status, 
                        subordinadoHovered: subordinado.id
                    })
                }}
                onMouseLeave={() => { 
                    this.setState({ 
                        statusHovered: null, 
                        subordinadoHovered: null
                    }) 
                }}
            >
                { 
                    subordinado.id == this.state.subordinadoHovered && status == this.state.statusHovered ? 
                    <span style={{
                        color: 'white', 
                        fontWeight: 'bold', 
                        marginLeft: 5, 
                        opacity: 1, 
                        transition: 'opacity 0.3s ease-in-out' 
                    }}>
                        {subordinado.planos.filter(plano => plano.status === status).length}
                    </span> 
                    : 
                    <span style={{ 
                        color: 'white', 
                        fontWeight: '200', 
                        marginLeft: 5, 
                        opacity: 1, 
                        transition: 'opacity 0.3s ease-in-out' 
                    }}>
                        {subordinado.planos.filter(plano => plano.status === status).length}
                    </span>
                }
            </div>
        );

        return (
            <div style={{...EssentialStyle.rowSpaceBetween, width: '100%', height: 35, borderRadius: 5, marginLeft: 5, overflow: 'hidden'}}>
                {status.map(renderDiv)}
            </div>
        );
    }

    renderColaborador(subordinado) {

        // ordenar os planos por status
        subordinado.planos.sort((a, b) => {
            const statusOrder = {
                "Não Iniciado": 0,
                "Em Desenvolvimento": 1,
                "Concluído": 2,
                "Atrasado": 3,
                "Cancelado": 4,
            };

            return statusOrder[a.status] - statusOrder[b.status];
        });

        return(
            <div 
                key={subordinado.id}
                style={{ width: '100%', 
                    margin: '2px 5px 5px 2px',
                    borderRadius: 5,
                    boxShadow: `0px 0px 1px 1px ${Colors.homePage.extraLightGrey}`,
                    backgroundColor: subordinado.id == this.state.viewCard ? Colors.homePage.extraLightGrey : 'transparent',
                }}
            >
                <div 
                    style={{
                        padding: '5px 10px',
                        cursor: 'pointer',
                    }}
                    onClick={() => this.setState({ viewCard: this.state.viewCard == subordinado.id ? null : subordinado.id }, () => {
                        this.calculaGrafico();
                    })} 
                >
                    <div style={{...EssentialStyle.rowSpaceBetween, width: '100%' }}>
                        <div style={{ width: '50%' }}><UserAvatar size={35} id={subordinado.id} showName showNameAdaptative showNameplacement={'left'}/></div>
                        <div style={{ width: '50%', marginLeft: 5 }}>
                            { this.renderBarra(subordinado) }
                        </div>
                    </div>
                </div>
                <Collapse in={subordinado.id == this.state.viewCard}>
                    <div style={{ padding: '5px 5px 10px 3px' }}>
                        
                        <div style={{ display: 'flex', alignItems: 'center', color: Colors.homePage.grey, marginBottom: 5, marginLeft: 5, }}>
                            <span>{this.props.t('client_src_components_modules_inicio_charts_PlanosAcaoMinhaEquipe:chart.employee_plans')}</span>
                            <hr style={{ flexGrow: 1, margin: '5px 0px 5px 10px', border: 'none', borderTop: '1px solid' }} />
                        </div>

                        {subordinado.planos.map((plano) => {
                            return (
                                <div key={plano.id} style={{ ...EssentialStyle.rowFlexStart, marginLeft: 5, marginBottom: 5, paddingBottom: 5, borderBottom: `0.5px solid ${Colors.homePage.lightGrey}` }}>
                                    <CustomTooltip tooltip={plano.status} placement="left">
                                        <div style={{ width: 20, height: 20, backgroundColor: ChartHelper.getColor(plano.status), borderRadius: 5, marginRight: 5 }} />
                                    </CustomTooltip>
                                    <div style={{ fontSize: 14, color: Colors.homePage.darkGrey, width: 'calc(100% - 45px)'}}>
                                        {plano.nome}
                                    </div>
                                    <DefaultButton
                                        color={Colors.dark}
                                        tooltip={this.props.t('client_src_components_modules_inicio_charts_PlanosAcaoMinhaEquipe:tooltips.see_action_plan')}
                                        tooltipPlacement={"left"}
                                        leftIcon={<FontAwesomeIcon icon={faEye} size="xs"/>}
                                        style={{ marginLeft: 10, width: 20, height: 20, display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 0}}
                                        onClick={() => window.parent.postMessage({ type: '_blank', url: `/planoAcao/detalhar/${plano.codigo}` }, '*')}
                                    />
                                </div>
                            )
                        })}
                    </div>
                </Collapse>
            </div>
        )
    }

    renderSubordinados() {
        let colaboradorPlanos = this.state.subordinados.filter(colaborador => {
            return colaborador["planos"] && colaborador["planos"].length > 0;
        });
    
        return (
            <div style={{...EssentialStyle.columnStart, alignItems: 'start', height: '100%', width: '100%', paddingTop: 5, overflow: 'auto'}}>
                <Accordion defaultActiveKey="0" style={{ width: 'calc(100% - 5px)' }}>
                    {colaboradorPlanos.map((subordinado) => {
                        return this.renderColaborador(subordinado);
                    })}
                </Accordion>
            </div>
        )
    }

    renderGrafico() {
        let that = this;

        return (
            <div
                style={{ display: 'flex', width: '100%', height: '100%' }}
            >
                <HighchartsReact
                    highcharts={Highcharts}
                    constructorType={'chart'}
                    options={{
                        chart: {
                            type: 'variablepie',
                            backgroundColor: 'transparent',
                            margin: [null, null, 25, null]
                        },
                        title: {
                            text: this.state.viewCard 
                                ? this.state.subordinados.find(subordinado => subordinado.id == this.state.viewCard).nome 
                                : this.props.t('client_src_components_modules_inicio_charts_PlanosAcaoMinhaEquipe:chart.team'),
                            align: 'left',
                            style: {
                                fontSize: '16px',
                                fontWeight: '200'
                            }
                        },
                        tooltip: {
                            borderWidth: 0,
                            backgroundColor: 'none',
                            shadow: false,
                            style: {
                                fontSize: '12px'
                            },
                            pointFormat: '<span style="font-size: 2em; text-align: center; color: {point.color}; font-weight: bold">{point.yPercent}%</span>',
                            positioner: function (labelWidth) {
                                return {
                                    x: 0,
                                    y: (this.chart.plotHeight)
                                };
                            },
                            formatter: function () {
                                const translatedName = that.props.t(`client_src_components_modules_inicio_charts_PlanosAcaoMinhaEquipe:status.${IdiomaHelper.getTextTokenName(this.point.name)}`);
                                return `<span>${translatedName}</span><br><span style="font-size: 2em; text-align: center; color: ${this.point.color}; font-weight: bold">${this.point.yPercent}%</span>`;
                            }
                        },
                        legend: {
                            align: 'right',
                            verticalAlign: 'middle',
                            layout: 'vertical',
                            enabled: true,
                            labelFormatter: function () {
                                return that.props.t(`client_src_components_modules_inicio_charts_PlanosAcaoMinhaEquipe:status.${IdiomaHelper.getTextTokenName(this.name)}`) + ': ' + this.y;
                            }
                        },
                        plotOptions: {
                            variablepie: {
                                dataLabels: {
                                    enabled: false,
                                }
                            }
                        },
                        series: [{
                            minPointSize: 40,
                            showInLegend: true,
                            innerSize: '65%',
                            borderRadius: 1,
                            data: this.state.data
                        }],
                        credits: {
                            enabled: false
                        },
                    }}
                />
            </div>
        )
    }

    renderTitle() {
        return (
            <div 
                style={{
                    ...EssentialStyle.cardTitle, 
                    height: 40, 
                    borderBottom: `1px solid ${this.props.expandedPageTwo != "PlanoAcaoMinhaEquipe" && this.props.expandedPageTwo != false ? 'transparent' : Colors.homePage.line }` 
                }}
            >
                <strong style={{ ...EssentialStyle.titleBoxHomePage, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    {this.state.advancedFilter 
                        ? this.props.t('client_src_components_modules_inicio_charts_PlanosAcaoMinhaEquipe:title.filter') 
                        : LayoutHelper.isSmallScreen() 
                            ? this.props.t('client_src_components_modules_inicio_charts_PlanosAcaoMinhaEquipe:title.short')
                            : this.props.t('client_src_components_modules_inicio_charts_PlanosAcaoMinhaEquipe:title.full')
                    }
                </strong>
                <div style={{ ...EssentialStyle.rowFlexStart }}>

                    {
                        this.props.expandedPageTwo == "PlanoAcaoMinhaEquipe" || this.props.expandedPageTwo == false ?
                            <div style={{...EssentialStyle.rowFlexEnd, fontSize: 14, color: Colors.homePage.darkGrey, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>
                                {this.props.t('client_src_components_modules_inicio_charts_PlanosAcaoMinhaEquipe:date_from')} { this.renderDatePickerInicio() }
                            </div>
                            :
                            null
                    }

                    {      
                        this.props.expandedPageTwo == "PlanoAcaoMinhaEquipe" || this.props.expandedPageTwo == false ?
                            <DefaultButton
                                leftIcon={ this.state.advancedFilter ? <FontAwesomeIcon icon={faArrowLeft} /> : <FontAwesomeIcon icon={faFilter} /> }
                                tooltip={ this.state.advancedFilter 
                                    ? this.props.t('client_src_components_modules_inicio_charts_PlanosAcaoMinhaEquipe:buttons.back')
                                    : this.props.t('client_src_components_modules_inicio_charts_PlanosAcaoMinhaEquipe:buttons.filter_plans')
                                }
                                tooltipPlacement={"top"}
                                color={'transparent'}
                                textColor={SessionHelper.getColor()}
                                style={{ marginLeft: 5 }}
                                onClick={() => { this.setState({ advancedFilter: !this.state.advancedFilter, viewCard: null })}}
                            />
                            :
                            null
                    }
                    
                    <DefaultButton
                        leftIcon={this.props.expandedPageTwo == "PlanoAcaoMinhaEquipe" ? <FontAwesomeIcon icon={faMinimize} /> : <FontAwesomeIcon icon={faMaximize} />}
                        color={'transparent'}
                        textColor={SessionHelper.getColor()}
                        style={{ marginLeft: 5 }}
                        onClick={() => { this.setState({ expanded: !this.state.expanded }, () => { this.props.expandCallbackPageTwo(this.props.expandedPageTwo != "PlanoAcaoMinhaEquipe" ? 'PlanoAcaoMinhaEquipe' : false) }); }}
                    />
                </div>
            </div>
        )
    }

    renderBody() {
        return (
            <div style={{...EssentialStyle.rowFlexStart, width: '100%', height: 'calc(100% - 40px)'}}>
                { 
                    (!this.state.loading && this.state.totalPlanos.total == 0) 
                        ? (
                            <div style={{ ...EssentialStyle.columnCenter, width: '100%', height: '100%'}}>
                                <img
                                    src={`${gateway}/img/undraw/undraw_teamwork.svg`}
                                    alt={this.props.t('client_src_components_modules_inicio_charts_PlanosAcaoMinhaEquipe:alt_no_plans')}
                                    style={{
                                        height: '85%',
                                        maxHeight: 200,
                                        width: 350,
                                    }}
                                />
                                <span 
                                    style={{ ...EssentialStyle.titleBoxHomePage }}
                                >
                                    {this.props.t('client_src_components_modules_inicio_charts_PlanosAcaoMinhaEquipe:no_plans_1') + " "}
                                    <strong>{this.props.t('client_src_components_modules_inicio_charts_PlanosAcaoMinhaEquipe:no_plans_2')}</strong>
                                    {" " + this.props.t('client_src_components_modules_inicio_charts_PlanosAcaoMinhaEquipe:no_plans_3') + " "}
                                    <strong>{this.props.t('client_src_components_modules_inicio_charts_PlanosAcaoMinhaEquipe:no_plans_4')}</strong>
                                    {" " + this.props.t('client_src_components_modules_inicio_charts_PlanosAcaoMinhaEquipe:no_plans_5') + "."}
                                </span>
                            </div>
                        ):(
                            <div style={ LayoutHelper.isSmallScreen() ? layout.bodyColumn : layout.bodyRow }>
                                
                                <div style={ LayoutHelper.isSmallScreen() ? layout.graficSmall : layout.graficLarge }>
                                    {this.state.loading ? this.renderLoading(1) : this.renderGrafico()}
                                </div>

                                <div style={ LayoutHelper.isSmallScreen() ? layout.planosSmall : layout.planosLarge }>
                                    {this.state.loading ? this.renderLoading(2) : this.renderSubordinados()}
                                </div>

                            </div>
                        )
                }
            </div>
        )
    }

    rederAdvancedFilter(){
        return (
            <div style={{...EssentialStyle.rowSpaceBetween, alignItems: 'start', width: '100%', maxHeight: 'calc(100% - 40px)', height: 'calc(100% - 40px)', paddingTop: 10, overflow: 'auto'}}>
                <div style={{ ...EssentialStyle.columnSpaceBetween, alignItems: 'center',  width: 'calc(100% - 15px)', height: '100%', paddingLeft: 10 }}>
                    <Form className="w-100">
                        <Row className="m1-2" style={{ marginBottom: 5 }}>
                            <Form.Group as={Col}>
                                <Form.Label className="form-label-xsm">
                                    {this.props.t('client_src_components_modules_inicio_charts_PlanosAcaoMinhaEquipe:filters.plan_name')}
                                </Form.Label>
                                <Form.Control 
                                    className="form-control-xsm" 
                                    type="text" 
                                    placeholder={this.props.t('client_src_components_modules_inicio_charts_PlanosAcaoMinhaEquipe:placeholders.name')} 
                                    value={this.state.filtro.nome || ''} 
                                    onChange={(event) => { 
                                        this.setState(prevState => ({
                                            filtro: {
                                                ...prevState.filtro,
                                                nome: event.target.value
                                            }
                                        })); 
                                    }} 
                                />
                            </Form.Group>

                            <Form.Group as={Col}>
                                <Form.Label className="form-label-xsm">
                                    {this.props.t('client_src_components_modules_inicio_charts_PlanosAcaoMinhaEquipe:filters.plan_type')}
                                </Form.Label>
                                <Select 
                                    className="form-control-xsm" 
                                    options={this.state.filtroOptions.tipo} 
                                    placeholder={this.props.t('client_src_components_modules_inicio_charts_PlanosAcaoMinhaEquipe:placeholders.select_type')} 
                                    noOptionsMessage={this.props.t('client_src_components_modules_inicio_charts_PlanosAcaoMinhaEquipe:placeholders.no_options')}
                                    isClearable 
                                    isSearchable
                                    isMulti
                                    value={this.state.filtro.tipo}
                                    onChange={(value) => { 
                                        this.setState(prevState => ({
                                            filtro: {
                                                ...prevState.filtro,
                                                tipo: value
                                            }
                                        })); 
                                    }}
                                    //format output and option to display translated text
                                    formatOptionLabel={(option) => {
                                        return this.props.t(`client_src_components_modules_inicio_charts_PlanosAcaoMinhaEquipe:tipos_plano.${IdiomaHelper.getTextTokenName(option.label)}`)
                                    }}
                                    menuPortalTarget={document.body}
                                />
                            </Form.Group>
                        </Row>

                        <Row className="m1-2">
                            <Form.Group as={Col}>
                                <Form.Label className="form-label-xsm">
                                    {this.props.t('client_src_components_modules_inicio_charts_PlanosAcaoMinhaEquipe:filters.result_link')}
                                </Form.Label>
                                <Select 
                                    className="form-control-xsm" 
                                    options={this.state.filtroOptions.tipoVinculo} 
                                    placeholder={this.props.t('client_src_components_modules_inicio_charts_PlanosAcaoMinhaEquipe:placeholders.select_link')} 
                                    noOptionsMessage={this.props.t('client_src_components_modules_inicio_charts_PlanosAcaoMinhaEquipe:placeholders.no_options')}
                                    isClearable 
                                    isSearchable
                                    isMulti
                                    value={this.state.filtro.tipoVinculo}
                                    onChange={(value) => { 
                                        this.setState(prevState => ({
                                            filtro: {
                                                ...prevState.filtro,
                                                tipoVinculo: value
                                            }
                                        })); 
                                    }}
                                    formatOptionLabel={(option) => {
                                        return this.props.t(`client_src_components_modules_inicio_charts_PlanosAcaoMinhaEquipe:tipos_vinculo.${IdiomaHelper.getTextTokenName(option.label)}`)
                                    }}
                                    menuPortalTarget={document.body} 
                                />
                            </Form.Group>
                            
                            <Form.Group as={Col}>
                                <Form.Label className="form-label-xsm">
                                    {this.props.t('client_src_components_modules_inicio_charts_PlanosAcaoMinhaEquipe:filters.tags')}
                                </Form.Label>
                                <Select 
                                    className="form-control-xsm" 
                                    options={this.state.filtroOptions.tags} 
                                    placeholder={this.props.t('client_src_components_modules_inicio_charts_PlanosAcaoMinhaEquipe:placeholders.select_tag')} 
                                    noOptionsMessage={this.props.t('client_src_components_modules_inicio_charts_PlanosAcaoMinhaEquipe:placeholders.no_options')} 
                                    isClearable 
                                    isSearchable
                                    isMulti
                                    value={this.state.filtro.tags} 
                                    onChange={(value) => { 
                                        this.setState(prevState => ({
                                            filtro: {
                                                ...prevState.filtro,
                                                tags: value
                                            }
                                        })); 
                                    }}
                                    menuPortalTarget={document.body} 
                                />
                            </Form.Group>                            
                        </Row>
                    </Form>
                    
                    <div style={{ ...EssentialStyle.rowFlexEnd, alignItems: 'end', width: '100%', height: '100%', marginTop: 5 }}>
                        <DefaultButton
                            leftIcon={<FontAwesomeIcon icon={faBroom} />}
                            title={this.props.t('client_src_components_modules_inicio_charts_PlanosAcaoMinhaEquipe:buttons.clear')}
                            color={Colors.error}
                            textColor={ 'white' }
                            onClick={() => { 
                                this.setState({ filtro: { nome: null, tipoVinculo: null, tipo: null, tags: null } }, () => {
                                    this.getEquipeColaborador();
                                })
                            }}
                        />

                        <DefaultButton
                            leftIcon={<FontAwesomeIcon icon={faSearch} />}
                            title={this.props.t('client_src_components_modules_inicio_charts_PlanosAcaoMinhaEquipe:buttons.filter')}
                            color={SessionHelper.getColor()}
                            textColor={ 'white' }
                            style={{ marginLeft: 5 }}
                            onClick={() => { this.getEquipeColaborador() }}
                        />
                    </div>
                </div>
            </div>
        )
    }

    renderContent() {
        return (
            <div style={layout.cardContent}>
                { this.renderTitle() }
                { this.props.expandedPageTwo == "PlanoAcaoMinhaEquipe" || this.props.expandedPageTwo == false ? (this.state.advancedFilter ? this.rederAdvancedFilter() : this.renderBody()) : null }
            </div>
        )
    }

    render() {
        return (
            <div style={{ ...layout.card, transform: this.state.transform, opacity: this.state.opacity }}>
                {this.renderContent()}
            </div>
        )
    }
}

const layout = {

    card: {
        ...EssentialStyle.rowSpaceBetween,
        ...EssentialStyle.card,
        transition: 'opacity .3s ease-in-out, transform .6s ease-in-out',
        width: '100%',
        height: '100%',
        padding: 10
    },
    cardContent:{
        ...EssentialStyle.columnCenter,
        width: '100%', 
        height: '100%'
    },
    bodyRow: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        height: '100%'
    },
    bodyColumn: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%'
    },
    graficSmall: {
        display: 'flex', 
        width: '100%', 
        height: 'calc(60% - 4px)', 
        marginTop: 0,
        height: ChartHelper.getHeight(35)
    },
    graficLarge: {
        display: 'flex', 
        width: '55%', 
        height: 'calc(100% + 10px)', 
        marginTop: 0,
    },
    planosSmall: {
        display: 'flex', 
        width: '100%', 
        height: 'calc(40% - 4px)', 
        marginTop: 8,
    },
    planosLarge: {
        display: 'flex', 
        width: '45%', 
        height: '100%', 
        marginLeft: 8 
    },
}

export default withTranslation()(PlanosAcaoMinhaEquipe);