// Bibliotecas externas
import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle, faPaperPlane, faEye, faBars, faInfoCircle, faSave } from '@fortawesome/free-solid-svg-icons';
import Select from "react-select";
import { toast } from "react-toastify";
import { withTranslation } from 'react-i18next';

// Helpers
import DataHelper from "../../../../../../helper/DataHelper";
import SessionHelper from "../../../../../../helper/SessionHelper";
import DragAndDropHelper from "../../../../../../helper/DragAndDropHelper";
import DiagnosticoHelper from "../../../../../../helper/diagnostico/DiagnosticoHelper";

// Estilos
import Colors from "../../../../../../constants/Colors";
import EssentialStyle from "../../../../../../style/EssentialStyle";
// - Comentado até que seja adicionado algo - 
// import './AddPerformance.css';

// Componentes
import DefaultButton from "../../../../../tools/DefaultButton";
import DefaultLoader from "../../../../../tools/DefaultLoader";
import CustomTooltip from "../../../../../tools/CustomTooltip";
import ModalPeriodoGrupoIndicadores from "../../../Indicadores/ModalPeriodoGrupoIndicadores/ModalPeriodoGrupoIndicadores";
import SelectWidth from "../../../../../tools/SelectWidth/SelectWidth";


class AddPerformance extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,

            item: props.item,

            grupos: [],
            indicadores: [],

            grupoSelected: null,
            widthSelected: 6,

            openModal: false,

            draggingItem: null,
            draggingOverItem: null,
            widthDragItem: 0,
            left: false,

            headerHeight: 0,

            chartTypes: [],
            chartType: null,
            typesCalc: [], 
            typeCalc: null,
            typesVisualizacao: [],
            typeVisualizacao: null,
            
        }
            
    }

    headerRef = React.createRef();

    async componentDidMount() {
        await this.props.i18n.loadNamespaces(['client_src_components_modules_diagnostico_CriacaoRelatorio_AddModuloRelatorio_AddPerformance_AddPerformance']);
        await this.props.i18n.loadNamespaces(['client_src_helper_diagnostico_DiagnosticoHelper']);

        await this.setOptions();

        await this.loadData();
        this.measureHeaderHeight();
        window.addEventListener('resize', this.measureHeaderHeight);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.measureHeaderHeight);
    }

    measureHeaderHeight = () => {
        const headerElement = document.querySelector('.criacaoRelatorio');
        if (headerElement) {
            const height = headerElement.offsetHeight;
            if (height !== this.state.headerHeight) {
                this.setState({ headerHeight: height });
            }
        }
    }

    async loadData() {
        this.setState({ loading: true });
        
        await this.loadGruposAndIndicadores();

        this.setState({
            item: {
                ...this.props.item,
                on: true,
                type: this.props.type,
                grupos: this.props.item.grupos || [],
                chartType: this.props.item.chartType || 'gauge',
                typeCalc: this.props.item.typeCalc || 'operacao',
                typeVisualizacao: this.props.item.typeVisualizacao || 'realizado',
                displayFaixas: this.props.item.displayFaixas || false,
            }
        }, () => { this.props.onSetItem(this.state.item) } );

        this.setState({ loading: false });
    }

    async setOptions() {
        this.setState({
            chartTypes: DiagnosticoHelper.getOptionsModeloRelatorio('chartTypes', this.props.t),
            chartType: DiagnosticoHelper.getDefaultOptionModeloRelatorio('chartTypes', this.props.t),

            typesCalc: DiagnosticoHelper.getOptionsModeloRelatorio('typesCalc', this.props.t),
            typeCalc: DiagnosticoHelper.getDefaultOptionModeloRelatorio('typesCalc', this.props.t),
            
            typesVisualizacao: DiagnosticoHelper.getOptionsModeloRelatorio('typesVisualizacao', this.props.t),
            typeVisualizacao: DiagnosticoHelper.getDefaultOptionModeloRelatorio('typesVisualizacao', this.props.t)
        });
    }

    async loadGruposAndIndicadores() {

        let { indicadores, grupos } = await DiagnosticoHelper.getIndicadoresPerformance(this.props.idPeriodoAvaliacao);

        let itemGrupos = this.state.item?.grupos?.map((grupo) => {

            let grupoFind = grupos.find((grupoRequest) => grupoRequest.id == grupo.grupo.id);
            
            return { 
                ...grupo,
                grupo: grupoFind, 
            };
        });


        this.setState({ item: {...this.state.item, grupos: itemGrupos}, indicadores, grupos }, () => { this.props.onSetItem(this.state.item) });
    }

    onDragStartItem = async (e, item) => {
        
        this.setState({ 
            draggingItem: item, 
            draggingOverItem: null, 
            widthDragItem: e.target.offsetWidth 
        });

        setTimeout(() => { e.target.style.display = "none" }, 5);
    }

    onDragEndItem = async (e, item) => {
        e.target.style.display = "flex";

        let grupos = [...this.state.item.grupos];
        let draggingOverItem = this.state.draggingOverItem;
        
        if (draggingOverItem !== null) {
            grupos.splice(grupos.indexOf(this.state.draggingItem), 1);
            let indexToInsert = grupos.indexOf(draggingOverItem);
            if(this.state.left) {
                grupos.splice(indexToInsert, 0, item);
            } else {
                grupos.splice(indexToInsert + 1, 0, item);
            }
        }

        grupos = grupos.map((grupo, index) => {
            return {...grupo, drag_index: index + 1};
        });

        this.setState({ 
            item: {...this.state.item, grupos: grupos} 
            
        }, () => {
            this.props.onSetItem(this.state.item);
            this.setState({ 
                draggingItem: null, 
                draggingOverItem: null, 
                left: false 
            });
        });
    }

    onDragEnterItem = async (e, item) => {

        let left = DragAndDropHelper.checkLeft(e);

        this.setState({ left });

        if (item.grupo.id !== this.state.draggingItem.grupo.id) {
            this.setState({ draggingOverItem: item });
        }
    }

    addGrupo = () => {

        const setOptions = () => {
            let gruposAdded = this.state?.item?.grupos || [];
            
            let grupo = this.state.grupos.find((grupo) => grupo.id == this.state.grupoSelected.value);
            let newGrupo = {
                grupo,
                largura: this.state.widthSelected,
                drag_index: gruposAdded.length + 1
            }

            gruposAdded.push(newGrupo);

            return gruposAdded;
        }

        if (!this.state.grupoSelected) {
            return toast.error(this.props.i18n.t('client_src_components_modules_diagnostico_CriacaoRelatorio_AddModuloRelatorio_AddPerformance_AddPerformance:erro_selecione_grupo_indicadores'));
        }
        
        let gruposAdded = setOptions();

        let item = {
            ...this.state.item, 
            grupos: gruposAdded
        };

        if (!item.typeCalc){
            item = {
                ...item,
                typeCalc: this.state.typeCalc.value,
            }
        }

        if (!item.typeVisualizacao){
            item = {
                ...item,
                typeVisualizacao: this.state.typeVisualizacao.value,
            }
        }

        if (!item.chartType){
            item = {
                ...item,
                chartType: this.state.chartType.value
            }
        }

        this.setState({ item, grupoSelected: null }, () => { this.props.onSetItem(this.state.item) });

    }

    removeGroup = (idGrupo) => {
        let gruposAdded = this.state.item.grupos.filter((grupo) => grupo.grupo.id !== idGrupo);

        let item = {
            ...this.state.item,
            grupos: gruposAdded
        };

        this.setState({ item }, () => { this.props.onSetItem(this.state.item) });
    }

    verifyGrupos = () => {

        let grupos = this.state.item.grupos || [];
            
        if (grupos?.length === 0) {
            return toast.error(this.props.i18n.t('client_src_components_modules_diagnostico_CriacaoRelatorio_AddModuloRelatorio_AddPerformance_AddPerformance:erro_selecione_grupo_indicadores'));
        }

        for (const grupo of grupos) {
            if (grupo.grupo.indicadores.length > 0) {
                for (const indicador of grupo.grupo.indicadores) {
                    if (indicador.status.includes(2) || indicador.status.includes(3)) {                            
                        return toast.error(DiagnosticoHelper.getStatusIndicador(indicador, this.props.t).text);
                    }
                }
            }
        }

        this.props.addModule && this.props.addModule();

    }

    orderBy = (list, field) => {
        return list.sort((a, b) => {
            if (a[field] < b[field]) return -1;
            if (a[field] > b[field]) return 1;
            return 0;
        });
    }

    renderHeader = () => {
        return (
            <div className="headerClose" style={{backgroundColor: SessionHelper.getColor()}}>
                <div/>
                {DiagnosticoHelper.getFunctionsModulosModeloRelatorioByType(this.props.type, this.props.t)?.title ?? ''}
                <FontAwesomeIcon icon={faTimesCircle} style={{ cursor: 'pointer' }} onClick={() => this.props.onClose()} />
            </div>
        )
    }

    renderBody = () => {

        let item = this.state.item;

        let chartTypes = this.state.chartTypes;
        let chartType = item?.chartType ? chartTypes.find((option) => option.value == item.chartType) : this.state.chartType;

        let typesCalc = this.state.typesCalc;
        let typeCalc = item?.typeCalc ? typesCalc.find((option) => option.value == item.typeCalc) : this.state.typeCalc;

        let typesVisualizacao = this.state.typesVisualizacao;
        let typeVisualizacao = item?.typeVisualizacao ? typesVisualizacao.find((option) => option.value == item.typeVisualizacao) : this.state.typeVisualizacao;

        let gruposAdded = this.orderBy((item?.grupos || []), 'drag_index');
        let idsGruposAdded = gruposAdded?.map((grupo) => grupo.grupo.id) || [];

        let gruposOptions = DataHelper.formatSelectDataLang(this.state.grupos, 'id', 'textos', null, 'descricao');
        gruposOptions = gruposOptions.filter((grupo) => !idsGruposAdded.includes(grupo.value));

        return (
            <div className="bodyModulo">
        
                {/* Configurações dos Grupos */}
                <div className="content box">

                    {/* Tipo de calculo */}
                    <>
                        <span>
                            {this.props.i18n.t('client_src_components_modules_diagnostico_CriacaoRelatorio_AddModuloRelatorio_AddPerformance_AddPerformance:calculo_classificacao_grupos')}
                            <CustomTooltip tooltip={this.props.i18n.t('client_src_components_modules_diagnostico_CriacaoRelatorio_AddModuloRelatorio_AddPerformance_AddPerformance:tooltip_calculo_classificacao_grupos')} placement={'top'}>
                                <FontAwesomeIcon icon={faInfoCircle} style={{ marginLeft: 8 }} />
                            </CustomTooltip>
                        </span>

                        <Select
                            options={this.state.typesCalc}
                            value={typeCalc}
                            placeholder={this.props.i18n.t('client_src_components_modules_diagnostico_CriacaoRelatorio_AddModuloRelatorio_AddPerformance_AddPerformance:selecione_tipo_calculo')}
                            onChange={(e) => { this.setState({ item: { ...this.state.item, typeCalc: e.value } }, () => { this.props.onSetItem(this.state.item) }) }}
                            styles={{
                                container: (provided) => ({
                                    ...provided,
                                    width: '100%',
                                    marginBottom: '10px'
                                }),
                                menu: (provided) => ({
                                    ...provided,
                                    zIndex: 9999
                                }),
                                menuPortal: (provided) => ({
                                    ...provided,
                                    zIndex: 9999
                                }),
                            }}
                            menuPortalTarget={document.body}
                            menuPlacement="auto"
                            menuPosition="fixed"
                        />
                    </>

                    {/* Tipo de Gráfico */}
                    <>
                        <span>
                            {this.props.i18n.t('client_src_components_modules_diagnostico_CriacaoRelatorio_AddModuloRelatorio_AddPerformance_AddPerformance:tipo_grafico')}
                        </span>

                        <Select
                            options={chartTypes}
                            value={chartType}
                            placeholder={this.props.i18n.t('client_src_components_modules_diagnostico_CriacaoRelatorio_AddModuloRelatorio_AddPerformance_AddPerformance:selecione_tipo_grafico')}
                            onChange={(e) => { this.setState({ item: { ...this.state.item, chartType: e.value } }, () => { this.props.onSetItem(this.state.item) }) }}
                            styles={{
                                container: (provided) => ({
                                    ...provided,
                                    width: '100%',
                                    marginBottom: '10px'
                                }),
                                menu: (provided) => ({
                                    ...provided,
                                    zIndex: 9999
                                }),
                                menuPortal: (provided) => ({
                                    ...provided,
                                    zIndex: 9999
                                }),
                            }}
                            menuPortalTarget={document.body}
                            menuPlacement="auto"
                            menuPosition="fixed"
                        />
                    </>

                    {/* Tipo de Visualização */}
                    <>
                        <span>
                            {this.props.i18n.t('client_src_components_modules_diagnostico_CriacaoRelatorio_AddModuloRelatorio_AddPerformance_AddPerformance:tipo_visualizacao')}
                        </span>

                        <Select
                            options={typesVisualizacao}
                            value={typeVisualizacao}
                            placeholder={this.props.i18n.t('client_src_components_modules_diagnostico_CriacaoRelatorio_AddModuloRelatorio_AddPerformance_AddPerformance:selecione_tipo_visualizacao')}
                            onChange={(e) => { this.setState({ item: { ...this.state.item, typeVisualizacao: e.value } }, () => { this.props.onSetItem(this.state.item) }) }}
                            styles={{
                                container: (provided) => ({
                                    ...provided,
                                    width: '100%',
                                    marginBottom: '10px'
                                }),
                                menu: (provided) => ({
                                    ...provided,
                                    zIndex: 9999
                                }),
                                menuPortal: (provided) => ({
                                    ...provided,
                                    zIndex: 9999
                                }),
                            }}
                            menuPortalTarget={document.body}
                            menuPlacement="auto"
                            menuPosition="fixed"
                        />
                    </>
                </div>

                {/* Definição de Grupos */}
                <div className="content box">
                    
                    {/* Select para adicionar Grupos */}
                    <>
                        <span>
                            {this.props.i18n.t('client_src_components_modules_diagnostico_CriacaoRelatorio_AddModuloRelatorio_AddPerformance_AddPerformance:adicionar_grupo_indicadores')}
                            <CustomTooltip tooltip={this.props.i18n.t('client_src_components_modules_diagnostico_CriacaoRelatorio_AddModuloRelatorio_AddPerformance_AddPerformance:tooltip_adicionar_grupo_indicadores_info')} placement={'top'}>
                                <FontAwesomeIcon icon={faInfoCircle} style={{ marginLeft: 8 }} />
                            </CustomTooltip>
                        </span>

                        <Select
                            options={gruposOptions}
                            value={this.state.grupoSelected}
                            placeholder={this.props.i18n.t('client_src_components_modules_diagnostico_CriacaoRelatorio_AddModuloRelatorio_AddPerformance_AddPerformance:selecione_grupo_indicadores')}
                            onChange={(e) => {
                                this.setState({grupoSelected: e})
                            }}
                            styles={{
                                container: (provided) => ({
                                    ...provided,
                                    width: '100%',
                                    marginBottom: '20px'
                                }),
                                menu: (provided) => ({
                                    ...provided,
                                    zIndex: 9999
                                }),
                                menuPortal: (provided) => ({
                                    ...provided,
                                    zIndex: 9999
                                }),
                            }}
                            menuPortalTarget={document.body}
                            isMulti={false}
                            menuPlacement="auto"
                            menuPosition="fixed"
                        />
                            
                        <div style={{...EssentialStyle.rowSpaceBetween, width: '100%'}}>
                            <SelectWidth
                                onClick={(width) => this.setState({ widthSelected: width })}
                                widthSelected={this.state.widthSelected}
                            />
                            <DefaultButton
                                leftIcon={<FontAwesomeIcon icon={faPaperPlane}/>}
                                color={Colors.success}
                                tooltip={this.props.i18n.t('client_src_components_modules_diagnostico_CriacaoRelatorio_AddModuloRelatorio_AddPerformance_AddPerformance:tooltip_adicionar_grupo_indicadores')}
                                onClick={() => {this.addGrupo()}}
                                style={{marginBottom: 0}}
                            />
                        </div>
                    </>

                </div>

                {/* Visualização de Grupos e Indicadores */}
                {gruposAdded?.length > 0 && 
                    <div className="content box">
                        <span>{this.props.i18n.t('client_src_components_modules_diagnostico_CriacaoRelatorio_AddModuloRelatorio_AddPerformance_AddPerformance:grupos_adicionados')}</span>

                        <div style={{ ...EssentialStyle.rowFlexStart, width: '100%', gap: 5, overflowX: 'auto', minHeight: '4rem'}}>
                            {gruposAdded?.map((grupo) => {
                                return (
                                    <React.Fragment key={`grupo-${grupo.grupo.id}`}>
                                        {this.renderGrupo(grupo)}
                                    </React.Fragment>
                                )
                            })}
                        </div>
                    </div>
                }

                <div className="buttons">
                    <DefaultButton
                        title={this.props.i18n.t('client_src_components_modules_diagnostico_CriacaoRelatorio_AddModuloRelatorio_AddPerformance_AddPerformance:salvar')}
                        leftIcon={<FontAwesomeIcon icon={faSave}/>}
                        color={Colors.success}
                        tooltip={this.props.i18n.t('client_src_components_modules_diagnostico_CriacaoRelatorio_AddModuloRelatorio_AddPerformance_AddPerformance:tooltip_salvar')}
                        onClick={() => {this.verifyGrupos()}}
                    />
                </div>

            </div>
        );
    }

    renderGrupo = (grupo) => {

        let groupName = grupo.grupo.textos.find((text) => text.idioma === this.props.lang)?.descricao || grupo.grupo.textos[0].descricao;

        return (
            <div 
                key={`grupo-added-${grupo.grupo.id}`} 
                className="item-added"
                onDragStart={(e) => { this.onDragStartItem(e, grupo) }}
                onDragEnter={(e) => { this.onDragEnterItem(e, grupo) }}
                onDragEnd={(e) => { this.onDragEndItem(e, grupo) }}
                draggable={true}
                style={{
                    marginLeft: this.state.draggingOverItem && this.state.draggingOverItem.grupo.id === grupo.grupo.id && this.state.left ? this.state.widthDragItem : 0,
                    marginRight: this.state.draggingOverItem && this.state.draggingOverItem.grupo.id === grupo.grupo.id && !this.state.left ? this.state.widthDragItem : 0
                }}
            >
                <div className="line">

                    <FontAwesomeIcon 
                        icon={faBars} 
                        className="icon-drag"
                    />

                    {groupName}

                    <FontAwesomeIcon 
                        icon={faEye}
                        style={{cursor: 'pointer'}}
                        onClick={() => this.setState({ openModal: this.state.openModal === grupo?.grupo?.id ? null : grupo?.grupo?.id })}
                    />

                    <FontAwesomeIcon 
                        icon={faTimesCircle} style={{cursor: 'pointer'}} 
                        className="icon-delete" 
                        onClick={() => this.removeGroup(grupo.grupo.id)}
                    />
                </div>

                <div className="bar-grupo">
                    <div className="bar-grupo-valor" style={{width: DiagnosticoHelper.getWidth(grupo.largura), backgroundColor: SessionHelper.getColor() }}>
                        {DiagnosticoHelper.getWidth(grupo.largura)}
                    </div>
                </div>

                {this.state.openModal === grupo?.grupo?.id &&
                    <ModalPeriodoGrupoIndicadores
                        grupo={grupo}
                        idGrupo={grupo.grupo.id}
                        idPeriodoAvaliacao={this.props.idPeriodoAvaliacao}
                        listPeriodoIndicadores={this.state.indicadores}
                        lang={this.props.lang}
                        closeModal={() => {this.loadGruposAndIndicadores(); this.setState({openModal: null}); } }
                        reload={async () => await this.loadGruposAndIndicadores()}
                    />
                }
                    
            </div>
        )
    }

    renderLoading() {
        return (
            <div className="bodyModulo" style={{justifyContent: 'center', alignItems: 'center', overflow: 'hidden', height: "calc(100% - 3rem)"}}>
                <DefaultLoader/>
            </div>
        )
    }

    renderContent() {

        return (
            <div className="ModuloConfig" style={{maxHeight: `calc(${this.state.headerHeight}px - 20px)`, minHeight: '50vh'}}>
                {this.renderHeader()}
                {!this.state.loading ? this.renderBody() : this.renderLoading()}
            </div>
        )
    }

    render() {
        if (!this.props.type) return <></>;
        return this.renderContent();
    }
}

export default withTranslation()(AddPerformance);