import React from "react";
import DefaultLoader from "../../../../tools/DefaultLoader";
import EssentialStyle from "../../../../../style/EssentialStyle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown, faArrowUp, faMinus, faLink } from "@fortawesome/free-solid-svg-icons";

import Highcharts from "highcharts/highcharts.js";
import highchartsMore from "highcharts/highcharts-more.js"
import accessibility from 'highcharts/modules/accessibility';

import solidgauge from "highcharts/modules/solid-gauge";
import Colors from "../../../../../constants/Colors";
import Sig from "../../../../../api/Sig";
import moment from "moment";
import LayoutHelper from "../../../../../helper/LayoutHelper";
import EllipsisText from "../../../../tools/EllipsisText";
import GaugeCircle from "../../../../tools/GaugeCircle/GaugeCircle";

import { withTranslation } from "react-i18next";
import CustomTooltip from "../../../../tools/CustomTooltip";

highchartsMore(Highcharts);
accessibility(Highcharts);
solidgauge(Highcharts);

class IndicadorGauge extends React.Component {
    state = {
        loading: true,
        nome: '',
        metaValor: '--',
        realizadoValor: '--',
        performanceValor: '--%',
        saldo: '--',
        saldoIcon: '',
        desvio: '--',
        desvioIcon: '',
        color: Colors.notSelected,
        progress: 0,
        hover: false,
        canClick: true,
    }

    async componentDidMount() {
        await this.props.i18n.loadNamespaces(['client_src_components_modules_inicio_charts_indicadores_IndicadorGauge']);
        await this.getIndicador();
    }

    async componentDidUpdate(prevProps) {
        if ((prevProps.id_indicador !== this.props.id_indicador
            && prevProps.id_gmr_painel !== this.props.id_gmr_painel)
            || prevProps.acumulado !== this.props.acumulado
            || prevProps.data !== this.props.data) {
            await this.getIndicador();
        }
    }

    async getIndicador() {
        this.setState({ loading: true });

        let painel = await Sig.request('POST', 'inicio/getAcompanhamentoIndicador', {
            id_gmr_painel: this.props.id_gmr_painel,
            id_indicador: this.props.id_indicador,
            data: moment(this.props.data).unix(),
            acumulado: this.props.acumulado ? 1 : 0,
            id_empresa: this.props.id_empresa,
            meta: this.props.meta
        });

        if (painel && Object.keys(painel).length > 0) {

            this.setState({ 
                nome: painel?.nome ? painel?.nome : '' ?? '',
                metaValor: painel?.metaPeriodo ? painel?.metaPeriodo  : '--'  ?? '--',
                metaAjustadaValor: painel?.metaAjustadaPeriodo ? painel?.metaAjustadaPeriodo : null ?? null,
                realizadoValor: painel?.realizadoRealizado ? painel?.realizadoRealizado  : '--' ?? '--',
                performanceValor: this.props.acumulado ? (painel?.performanceRealizadoAll ?? '--%') : (painel?.performanceRealizado ?? '--%'),
                color: this.props.acumulado ? (painel?.performanceRealizadoAllColor ? painel?.performanceRealizadoAllColor : Colors.notSelected ?? Colors.notSelected) : (painel?.performanceRealizadoColor ? painel?.performanceRealizadoColor : Colors.notSelected ?? Colors.notSelected),
                progress: this.props.acumulado ? (painel?.performanceRealizadoAllRaw ?? 0) : (painel?.performance ?? 0),
                saldo: painel.saldoRealizado?.valor ? painel.saldoRealizado?.valor : '--' ?? '--',
                saldoIcon: painel.saldoRealizado?.setaReact ? painel.saldoRealizado?.setaReact : '' ?? '',
                desvio: painel.desvioRealizado?.valor ? painel.desvioRealizado?.valor : '--' ?? '--',
                desvioIcon: painel.desvioRealizado?.setaReact ? painel.desvioRealizado?.setaReact : '' ?? '',
                canClick: true,
            });

        } else {

            this.setState({
                nome: this.props.t('client_src_components_modules_inicio_charts_indicadores_IndicadorGauge:indicador_nao_encontrado'),
                canClick: false,
            });

        }

        this.setState({ loading: false });
    }

    renderLoading() {
        return (
            <div style={{
                ...EssentialStyle.columnCenter,
                width: '100%',
                height: '100%'
            }}>
                <DefaultLoader />
            </div>
        );
    }

    renderGauge() {

        return (
            <GaugeCircle
                min={0}
                max={100}
                color={this.state.color}
                progress={this.state.progress}
                title={''}
            />
        );
    }

    renderChart() {
        const progress = this.state.progress || 0;

        let gaugeLateral = LayoutHelper.isSmallScreen() && this.props.mergulho;

        let nome = (
            <div style={{ ...styles.lineName, display: this.props.nome ? '' : 'none'}}>
                <EllipsisText text={this.props.nomeIndicador ? this.props.nomeIndicador : this.state.nome} />
            </div>
        )

        let meta = (
            <div style={{...styles.lineData,
                backgroundColor: Colors.dark,
            }}>
                <CustomTooltip
                    tooltip={this.props.t('client_src_components_modules_inicio_charts_indicadores_IndicadorGauge:meta_tooltip')}
                >
                    {this.props.t('client_src_components_modules_inicio_charts_indicadores_IndicadorGauge:meta', { metaValor: this.props.metaValor ? this.props.metaValor : this.state.metaValor })}
                </CustomTooltip>
            </div>
        );

        let metaAjustada = this.state.metaAjustadaValor && !this.props.meta ? (
            <div style={{...styles.lineData, backgroundColor: Colors.dark }}>
                <CustomTooltip
                    tooltip={this.props.t('client_src_components_modules_inicio_charts_indicadores_IndicadorGauge:meta_ajustada_tooltip')}
                >
                    {this.props.t('client_src_components_modules_inicio_charts_indicadores_IndicadorGauge:meta_ajustada', { metaAjustadaValor: this.state.metaAjustadaValor })}
                </CustomTooltip>
            </div>
        ) : null;

        let realizado = (
            <div style={{...styles.lineData,
                background: `linear-gradient(to right, ${this.state.color} 0%, ${this.state.color} ${progress}%, ${Colors.notSelected} ${progress}%, ${Colors.notSelected} 100%)`,
            }}>
                <CustomTooltip
                    tooltip={this.props.t('client_src_components_modules_inicio_charts_indicadores_IndicadorGauge:realizado_tooltip')}
                >
                    {this.props.t('client_src_components_modules_inicio_charts_indicadores_IndicadorGauge:realizado', { realizadoValor: this.props.realizadoValor ? this.props.realizadoValor : this.state.realizadoValor })}
                </CustomTooltip>
            </div>
        );

        let gauge = (
            <div style={{ ...styles.lineGauge,
                height: gaugeLateral ? '100%' : `calc(100% - 44px - ${this.state.desvio == true ? '25px' : '0px'} - ${this.state.saldo == true ? '25px' : '0px'} - ${this.props.nome ? '22px' : '0px'}`,
                display: this.props.gauge ? 'flex' : 'none',
            }} >
                {this.renderGauge()}
            </div>
        );

        let desvio = (
            <div style={{...styles.lineData,
                backgroundColor: Colors.notSelected,
                display: this.props.desvio ? 'flex' : 'none',
            }}>
                {this.props.t('client_src_components_modules_inicio_charts_indicadores_IndicadorGauge:desvio', { desvio: this.state.desvio })}
                <FontAwesomeIcon
                    icon={this.state.desvio != '--' ? (this.state?.desvioIcon?.icon == 'faArrowUp' ? faArrowUp : this.state?.desvioIcon?.icon == 'faArrowDown' ? faArrowDown : faMinus) : faMinus}
                    style={{
                        color: this.state?.desvioIcon?.color || 'black',
                        marginRight: 10,
                        marginLeft: 10,
                        fontSize: 12
                    }}
                />
            </div>
        );

        let saldo = (
            <div style={{...styles.lineData,
                backgroundColor: Colors.notSelected,
                marginBottom: 0,
                display: this.props.saldo ? 'flex' : 'none',
            }}>
                {this.props.t('client_src_components_modules_inicio_charts_indicadores_IndicadorGauge:saldo', { saldo: this.state.saldo })}
                <FontAwesomeIcon
                    icon={this.state.saldo != '--' ? (this.state?.saldoIcon?.icon == 'faArrowUp' ? faArrowUp : this.state?.saldoIcon?.icon == 'faArrowDown' ? faArrowDown : faMinus) : faMinus}
                    style={{
                        color: this.state?.saldoIcon?.color || 'black',
                        marginRight: 10,
                        marginLeft: 10,
                        fontSize: 12
                    }}
                />
            </div>
        );

        let link = (
            <div style={{...styles.lineData,
                ...EssentialStyle.rowFlexEnd,
                marginBottom: 0,
                display: this.props.link ? 'flex' : 'none',
            }}>
                <FontAwesomeIcon
                    icon={faLink}
                    style={{
                        color: Colors.dark,
                        marginRight: 10,
                        marginLeft: 10,
                        fontSize: 12,
                        cursor: 'pointer'
                    }}
                    onClick={() => { 
                        window.parent.postMessage({ 
                            type: '_blank', 
                            url: `/acompanhamentoResultados/gerenciar/${this.props.id_gmr_painel}` 
                        }, '*') 
                    }}
                />
            </div>
        );

        let style = {
            container: {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center', 
                width: '100%',
                height: '100%',
                cursor: this.props.onClick ? (this.state.canClick ? 'pointer' : 'not-allowed') : 'default',
                borderRadius: 8,
                padding: gaugeLateral ? "5px 0px" : "5px 5px",
                boxShadow: this.state.hover ? Colors.boxShadow : 'none',
                backgroundColor: this.state.hover ? Colors.homePage.extraLightGrey : 'transparent',
                maxWidth: gaugeLateral ? '100%' : '30vw',
            },
            containerInfos: {
                ...EssentialStyle.columnCenter,
                fontSize: 13,
                width: gaugeLateral ? '45%' : '100%',
                height: '100%', 
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
            },
        }

        return (
            <div style={{
                ...style.container,
                paddingTop: this.props.nome ? 0 : undefined
                }}
                onMouseEnter={() => {if (this.props.onClick && this.state.canClick) this.setState({ hover: true })}}
                onMouseLeave={() => {if (this.props.onClick && this.state.canClick) this.setState({ hover: false })}}
                onClick={() => {
                    if (this.props.onClick && this.state.canClick) {
                        this.props.onClick();
                    }
                }}
            >
                <div style={{...EssentialStyle.rowFlexCenter, width: '100%', height: '100%'}}>
                    <div style={{ ...style.containerInfos }}>
                        {nome}
                        {meta}
                        {metaAjustada}
                        {realizado}
                        {!gaugeLateral && gauge}
                        {desvio}
                        {saldo}
                        {link}
                    </div>
                    {gaugeLateral && <div style={{ width: '45%', height: '100%' }}>{gauge}</div>}
                </div>
            </div>
        );
    }

    render() {
        return this.state.loading ? this.renderLoading() : this.renderChart()
    }
}

const styles = {

    lineName: {
        color: Colors.dark,
        fontWeight: 'bold',
        height: 22,
        fontSize: 14,
        textAlign: 'center',
        width: '100%',
        cursor: 'default',
        whiteSpace: 'normal'
    },
    lineData: {
        ...EssentialStyle.rowFlexCenter,
        width: '100%',
        height: 'calc(20% - 2px)',
        maxHeight: 25,
        minHeight: 15,
        fontSize: 12,
        fontWeight: 'bold',
        color: Colors.white,
        borderRadius: 8,
        marginBottom: 2,
        cursor: 'default',
    },
    lineGauge: {
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
    }
}

export default withTranslation()(IndicadorGauge);