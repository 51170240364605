import React from "react";

import { withTranslation } from 'react-i18next';
import EssentialStyle from "../../style/EssentialStyle";
import DiagnosticoHelper from "../../helper/diagnostico/DiagnosticoHelper";
import LayoutHelper from "../../helper/LayoutHelper";
import UnderConstruction from "../../components/tools/UnderConstruction";
import HeaderSimple from "../../components/tools/HeaderSimple";
import SidebarAvaliacao from "../../components/modules/diagnostico/Avaliacao/Aplicacao/SidebarAvaliacao";
import Sig from "../../api/Sig";
import DefaultLoader from "../../components/tools/DefaultLoader";
import AvaliacaoQuestaoCard from "../../components/modules/diagnostico/Avaliacao/Aplicacao/AvaliacaoQuestaoCard";
import Colors from "../../constants/Colors";
import Constants from "../../constants/Api";
import TextEditor from "../../components/tools/TextEditor/TextEditor";
import DefaultButton from "../../components/tools/DefaultButton";
import IdiomaHelper from "../../helper/IdiomaHelper";
import { confirmAlert } from 'react-confirm-alert';
import CustomConfirm from "../../components/tools/CustomConfirm";
import SessionHelper from "../../helper/SessionHelper";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faGaugeSimpleHigh } from '@fortawesome/free-solid-svg-icons';
import { Collapse } from "react-bootstrap";
import IndicadoresList from "../../components/modules/diagnostico/Avaliacao/Aplicacao/IndicadoresList";
import CustomTooltip from "../../components/tools/CustomTooltip";
import ModalIndicadoresAvaliacao from "../../components/modules/diagnostico/Avaliacao/Aplicacao/ModalIndicadoresAvaliacao";

const gateway = Constants.getSigEndPoint();

class DiagnosticoAvaliacaoAplicacaoPage extends React.Component {

    state = {
        construction: false,
        loading: true,
        hash: null,
        pageStyles: {
            color: null,
            logoFilename: null,
            backgroundColor: null,
            secondaryColor: null,
            headerColor: null,
            logoEmpresa: null,
        },
        avaliacao: null,
        isSmallScreen: LayoutHelper.isSmallScreen(),
        selectedNode: null,
        sidebarWidth: 400,
        sidebarIsOpen: true,
        displayAll: false,
        isQuestionarioAberto: false,
        lang: IdiomaHelper.getIdioma(),
        completed: false,
        expandedNode: null,
        isSmallContainer: false,
        readOnly: true,
    }
    
    async componentDidMount() {
        this.handleUrlParams();
        await this.props.i18n.loadNamespaces(['client_src_pages_diagnostico_DiagnosticoAvaliacaoAplicacaoPage']);
        window.addEventListener('resize', this.verifyScreen);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.verifyScreen);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.avaliacao?.questionario?.qtdPerguntasRespondidas != this.state.avaliacao?.questionario?.qtdPerguntasRespondidas) {
            let completed = this.state.avaliacao?.questionario?.qtdPerguntasRespondidas == this.state.avaliacao?.questionario?.qtdPerguntas ? true : false;
            if (completed != this.state.completed) {
                this.setState({ completed });
            }
        }
    }

    handleUrlParams = () => {
        window.parent.postMessage({ type: 'get_url' }, '*');

        const listenerFunction = async (event) => {
            if (event.data.type === 'return_url') {
                const urlObj = new URL(event.data.url);
                const queryParams = new URLSearchParams(urlObj.search);
                window.removeEventListener('message', listenerFunction);

                const hash = queryParams.get('hash');

                this.setState({ hash }, () => {
                    this.loadPageStyles();
                    this.loadAvaliacao();
                });
            }
        };

        window.addEventListener('message', listenerFunction);
    }

    loadPageStyles = async () => {
        let { logoFilename, backgroundColor, secondaryColor, headerColor, logoEmpresa } = await Sig.request('GET', '/diagnostico/avaliacao/getLayoutEmpresa', {
            hash: this.state.hash
        });

        this.setState({
            pageStyles: {
                logoFilename,
                backgroundColor,
                secondaryColor,
                headerColor,
                logoEmpresa
            },
        });
    }

    loadAvaliacao = async () => {
        this.setState({ loading: true });

        const avaliacao = await Sig.request('GET', '/diagnostico/avaliacao/getAvaliacaoCompleta', {
            hash: this.state.hash,
            lang: IdiomaHelper.getSiglaId(this.state.lang)
        });

        this.setState({ avaliacao, loading: false, readOnly: this.state.avaliacao?.avaliacao?.status != 3 ? false : avaliacao?.canEditFinalizada ? false : true }, () => {
            if(this.state.avaliacao?.questionario?.nodes?.length > 0) {
                let nodeId = this.getInitiallyOpenQuestionsRecursively(this.state.avaliacao?.questionario?.nodes[0]);
                if(nodeId) this.setSelectedNode(nodeId);
            }   
        });

    }

    getInitiallyOpenQuestionsRecursively = (node) => {
        if (node.childNodes.length > 0) {
            return this.getInitiallyOpenQuestionsRecursively(node.childNodes[0]);
        }
        return node.id_node_pai;
    }

    verifyScreen = () => {
        if(this.state.isSmallScreen != LayoutHelper.isSmallScreen())
            this.setState({ isSmallScreen: LayoutHelper.isSmallScreen() }, () => {
                if(this.state.isSmallContainer != this.isSmallContainer())
                    this.setState({ isSmallContainer: this.isSmallContainer() });
            });        
    }

    setSelectedNode = (selectedNode, viewAll = false) => {
        this.setState({ selectedNode, viewAll });
    }

    isSmallContainer = () => {
        return (this.state.isSmallScreen ? true : ((window.innerWidth - (this.state.sidebarIsOpen ? this.state.sidebarWidth : 60)) < 689));
    }

    setSidebarWidth = (sidebarWidth) => {
        this.setState({ sidebarWidth }, () => 
            this.setState({ isSmallContainer: this.isSmallContainer() })
        );
    }

    setSidebarState = (isOpen) => {
        this.setState({ sidebarIsOpen: isOpen },
            () => this.setState({ isSmallContainer: this.isSmallContainer() })
        );
    }

    onChangeValueNode = async (event, node) => {
        let resposta = null;
        if (event.target) {
            resposta = event.target.value;
        }

        let nodes = [...this.state.avaliacao?.questionario?.nodes];
        let qtdPerguntasRespondidas = this.state.avaliacao?.questionario?.qtdPerguntasRespondidas;

        let respostaNew = node.resposta;
        if (respostaNew) {
            respostaNew = { ...respostaNew, valor: resposta, id_escala: node.id_escala };
        } else {
            if (resposta) {
                respostaNew = { valor: resposta, id_escala: node.id_escala };
            } else {
                respostaNew = null;
            }
        }

        let savedResposta = await this.updateRespostaNode(node.id, respostaNew);
        if (!savedResposta) {
            return;
        }

        this.updateRespostaNodesRecursively(node, savedResposta, nodes);

        qtdPerguntasRespondidas = 0;
        nodes.map(node => qtdPerguntasRespondidas += node.qtdPerguntasRespondidas);

        this.setState({ avaliacao: { ...this.state.avaliacao, questionario: { ...this.state.avaliacao?.questionario, nodes, qtdPerguntasRespondidas } } });

    }

    onChangeDescricaoNode = async (descricao, node) => {
        let nodes = [...this.state.avaliacao?.questionario?.nodes];

        let respostaNew = node.resposta;
        if (respostaNew && respostaNew.descricao !== descricao) {
            respostaNew = { ...respostaNew, descricao };
        } else {
            return;
        }

        this.updateRespostaNodesRecursively(node, respostaNew, nodes);

        this.setState({ avaliacao: { ...this.state.avaliacao, questionario: { ...this.state.avaliacao?.questionario, nodes } } });
    }

    onChangeResposta = async (node, resposta) => {
        let nodes = [...this.state.avaliacao?.questionario?.nodes];

        this.updateRespostaNodesRecursively(node, resposta, nodes);

        this.setState({ avaliacao: { ...this.state.avaliacao, questionario: { ...this.state.avaliacao?.questionario, nodes } } });
    }

    renderPaginaInicio = () => {
        return (
            <div style={{ ...EssentialStyle.columnCenter, height: 'calc(100vh - 65px)', width: '100%', placeContent: 'center', padding: "20px 20px", gap: '10px' }}>
                <span style={{ fontSize: 50, fontWeight: 500, color: Colors.homePage.grey, lineHeight: '1.2', textWrap: 'wrap', textAlign: 'center' }}>
                    {this.state.avaliacao.questionario?.nome}
                </span>
                <div style={{ ...EssentialStyle.rowFlexCenter, maxWidth: this.state.isSmallScreen ? '90vw' : "40vw", height: "25%" }}>
                    <img src={`${gateway}/${this.state.pageStyles.logoEmpresa ? this.state.pageStyles.logoEmpresa : '/img/logo-advis-black.png'}`} alt="Logo da Empresa Avaliadora" style={{ height: "auto", width: "100%", maxHeight: '100%' }} />
                </div>
                <span style={{ fontSize: 20, fontWeight: 500, color: Colors.homePage.grey, lineHeight: '1.2', textWrap: 'wrap', textAlign: 'center' }}>
                    {this.props.t('client_src_pages_diagnostico_DiagnosticoAvaliacaoAplicacaoPage:orientacoesSidebarSelecao', { lateral: this.state.isSmallScreen ? "" : "lateral" })}
                </span>
            </div>
        )
    }

    renderAvaliacao = () => {

        if (!this.state.viewAll && !this.state.selectedNode) {
            return (
                this.renderPaginaInicio()
            );
        }

        if (this.state.loading) {
            return (
                <div>
                    <DefaultLoader />
                </div>
            );
        }

        if (this.state.viewAll) {
            return this.state.avaliacao?.questionario?.nodes?.map(node => {
                return this.renderNodeAndChilds(node);
            });
        }
        
        return this.renderNodeAndChilds(this.getSelectedNode());
    }

    renderIndicadorResult = (node) => {
        let faixas = this.state.avaliacao?.faixas;
        var cor = null;
        if (faixas && faixas.length > 0) {
            faixas.map(
                faixa => {
                    if (node?.resultadoIndicadores?.valor >= faixa.min && node?.resultadoIndicadores?.valor <= faixa.max) {
                        cor = faixa.cor;
                    }
                }
            )
            if(!cor && node?.resultadoIndicadores?.valor < faixas[0].min) cor = faixas[0].cor;
            if(!cor && node?.resultadoIndicadores?.valor > faixas[faixas.length - 1].max) cor = faixas[faixas.length - 1].cor;
        }

        if(!cor) cor = Colors.homePage.grey;

        let valor = node?.resultadoIndicadores?.valor && node?.resultadoIndicadores?.valor != 0 ? Math.round(node?.resultadoIndicadores?.valor) + '%' : null;

        return (
            <DefaultButton
                style={{ padding: 0, margin: 0, backgroundColor: 'transparent', borderColor: 'transparent', color: Colors.homePage.grey }}
                tooltip={this.props.t('client_src_pages_diagnostico_DiagnosticoAvaliacaoAplicacaoPage:verResultadoIndicadores')}
                disabled={this.state.loading}
                leftIcon={
                    <div style={{
                        backgroundColor: 'transparent',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        gap: 10,
                        justifyContent: this.props.justifyContent ? this.props.justifyContent : 'flex-start'
                    }}
                    >
                        {
                            valor &&
                            <>
                                <div style={{ fontSize: 20, fontWeight: 500 }}>{valor}</div>
                                <div style={{ width: 24, height: 24, borderRadius: 24 / 2, backgroundColor: cor, color: 'white'}}/>
                            </>
                        }
                            {valor == null ? <FontAwesomeIcon icon={faGaugeSimpleHigh} style={{ fontSize: 24, color: cor }} /> : null}
                    </div>
                }
                onClick={() => {
                    this.setState({ expandedNode: this.state.expandedNode == node.id ? null : node.id });
                }}
            />
        )
    }

    renderNodeAndChilds = (node, camada = 0) => { 
        if(node?.childNodes && node?.childNodes?.length > 0) {
            return (
                <div>
                    <div style={{ display: "flex", marginLeft: 20 + (camada * 10), marginRight: 20, marginBottom: 10, marginTop: 10, flexDirection: 'row', gap: 15, height: 30, placeContent: 'center', alignItems: 'center' }}>
                        <span style={{ fontSize: 18, fontWeight: 500, color: Colors.homePage.grey, lineHeight: '1.2', textWrap: 'wrap', textAlign: 'center' }}>
                            {node?.descricao}
                        </span>
                        <span style={{ flex: 1, borderBottom: '2px solid #ccc' }} />
                        {node?.indicadoresVinculados?.length > 0 && 
                            this.renderIndicadorResult(node)
                        }
                    </div>
                    {node?.childNodes?.map(child => {
                        return this.renderNodeAndChilds(child, camada + 1);
                    })}
                </div>
            )
        }
         
        return (
            <AvaliacaoQuestaoCard
                key={node.id}
                questao={{...node}}
                isSmallScreen={this.state.isSmallScreen}
                sidebarWidth={this.state.sidebarWidth}
                sidebarIsOpen={this.state.sidebarIsOpen}
                escalas={this.state.avaliacao?.questionario?.escalas}
                faixas={this.state.avaliacao?.faixas}
                onChangeValue={this.onChangeValueNode}
                onChangeDescricaoNode={this.onChangeDescricaoNode}
                onChangeResposta={this.onChangeResposta}
                hash={this.state.hash}
                lang={this.state.lang}
                isSmallContainer={this.state.isSmallContainer}
                showIndicadorModal={(node) => this.setState({ expandedNode: this.state.expandedNode == node.id ? null : node.id })}
                readOnly={this.state.readOnly}
            />
        )
    }

    getSelectedNode = () => {
        let nodes = DiagnosticoHelper.getNodesRecursively(this.state.avaliacao?.questionario?.nodes);

        return nodes.filter(node => node.id == this.state.selectedNode)[0];
    }

    getNode = (id) => {
        let nodes = DiagnosticoHelper.getNodesRecursively(this.state.avaliacao?.questionario?.nodes);

        return nodes.filter(node => node.id == id)[0];
    }

    updateRespostaNode = async (idNode, resposta) => {
        let req = null;
        try {

            req = await Sig.request('POST', '/diagnostico/avaliacao/updateRespostaNode', {
                hash: this.state.hash,
                idNode,
                resposta: JSON.stringify(resposta)
            });
        } catch (e) {
            return false;
        }

        if (req && req.status === 200) {
            return req.data;
        }

        return false;
    }

    updateRespostaNodesRecursively = (node, resposta, nodes) => {
        for (let i = 0; i < nodes.length; i++) {
            let nodeAtual = nodes[i];

            if (nodeAtual.id == node.id) {
                if (!nodeAtual.resposta) {
                    if (!resposta) {
                        return '0';
                    }

                    nodeAtual.resposta = resposta;
                    nodeAtual.qtdPerguntasRespondidas = 1;
                    return '+1';
                } else {
                    if (!resposta) {
                        nodeAtual.resposta = null;
                        nodeAtual.qtdPerguntasRespondidas = 0;
                        return '-1';
                    }

                    nodeAtual.resposta = resposta;
                    return '0';
                }
            } else if (nodeAtual.childNodes?.length > 0) {
                switch (this.updateRespostaNodesRecursively(node, resposta, nodeAtual.childNodes)) {
                    case '+1':
                        nodeAtual.qtdPerguntasRespondidas += 1;
                        return '+1';
                    case '-1':
                        nodeAtual.qtdPerguntasRespondidas -= 1;
                        return '-1';
                    default:
                        continue;
                }
            }

        }

        return '0';
    }

    // getLeafsRecursively = (node, leafs = []) => {
    //     if (node?.childNodes?.length > 0) {
    //         node?.childNodes?.map(child => {
    //             this.getLeafsRecursively(child, leafs);
    //         });
    //     } else {
    //         leafs.push(node);
    //     }

    //     return leafs;
    // }

    visualizarQuestionario = () => {
        this.setState({ isQuestionarioAberto: true , readOnly: this.state.avaliacao?.avaliacao?.status != 3 ? false : this.state.avaliacao?.canEditFinalizada ? false : true});
    }   

    iniciarQuestionario = async () => {
        if (this.state.avaliacao?.avaliacao?.status == 2) {
            this.setState({ isQuestionarioAberto: true, readOnly: this.state.avaliacao?.avaliacao?.status != 3 ? false : this.state.avaliacao?.canEditFinalizada ? false : true });
            return;
        }

        this.setState({ loading: true });

        let req = await Sig.request('POST', '/diagnostico/avaliacao/iniciarAvaliacao', {
            hash: this.state.hash,
            lang: IdiomaHelper.getSiglaId(this.state.lang)
        });

        if (req.status === 200) {
            this.setState({ avaliacao: { ...this.state.avaliacao, avaliacao: req.avaliacao }, isQuestionarioAberto: true });
        }

        this.setState({ loading: false });
    }

    reiniciarQuestionario = async () => {
        this.setState({ loading: true });

        let req = await Sig.request('POST', '/diagnostico/avaliacao/reiniciarAvaliacao', {
            hash: this.state.hash,
            lang: IdiomaHelper.getSiglaId(this.state.lang)
        });

        if (req.status === 200) {
            this.setState({ avaliacao: { ...this.state.avaliacao, avaliacao: req.avaliacao, questionario: req.questionario }, isQuestionarioAberto: true, readOnly: false });
        }

        this.setState({ loading: false });
    }

    finalizarQuestionario = async () => {
        this.setState({ loading: true });

        let req = await Sig.request('POST', '/diagnostico/avaliacao/finalizarAvaliacao', {
            hash: this.state.hash,
            lang: IdiomaHelper.getSiglaId(this.state.lang)
        });

        if (req.status === 200) {
            this.setState({ avaliacao: { ...this.state.avaliacao, avaliacao: req.avaliacao }, isQuestionarioAberto: false });
        }

        this.setState({ loading: false });
    }

    renderQuestionario = () => {
        return (
            <>
                <SidebarAvaliacao
                    pageStyles={this.state.pageStyles}
                    loading={this.state.loading}
                    avaliacao={this.state.avaliacao}
                    isSmallScreen={this.state.isSmallScreen}
                    selectedNode={this.state.selectedNode}
                    setSelectedNode={this.setSelectedNode}
                    setSidebarWidth={this.setSidebarWidth}
                    sidebarWidth={this.state.sidebarWidth}
                    isOpen={this.state.sidebarIsOpen}
                    setSidebarState={this.setSidebarState}
                    lang={this.state.lang}
                >
                    {
                            this.state.isQuestionarioAberto &&
                            <>
                            {
                                this.state.completed && this.state.avaliacao?.avaliacao?.status != 3 &&
                                <DefaultButton
                                    title={this.props.t('client_src_pages_diagnostico_DiagnosticoAvaliacaoAplicacaoPage:finalizarButton')}
                                    tooltipPlacement={'auto'}
                                    rightIcon={<FontAwesomeIcon icon={faCheck} />}
                                    color={this.state.pageStyles.headerColor ? this.state.pageStyles.headerColor : Colors.dark}
                                    textColor={this.state.pageStyles.logoFilename == 'logo-advis-white' ? 'white' : 'black'}
                                    onClick={() => this.renderFinalizarConfirm()}
                                    style={{ position: 'absolute', right: 5, top: 10, cursor: 'pointer', fontSize: '20px', justifySelf: 'end' }}
                                />
                            }
                            </>
                        }
                    {
                        this.state.completed && this.state.isQuestionarioAberto &&
                        <DefaultButton
                            title={this.props.t('client_src_pages_diagnostico_DiagnosticoAvaliacaoAplicacaoPage:finalizarButton')}
                            tooltipPlacement={'auto'}
                            rightIcon={<FontAwesomeIcon icon={faCheck} />}
                            color={this.state.pageStyles.headerColor ? this.state.pageStyles.headerColor : Colors.dark}
                            textColor={this.state.pageStyles.logoFilename == 'logo-advis-white' ? 'white' : 'black'}
                            onClick={() => this.renderFinalizarConfirm()}
                            style={{ position: 'absolute', right: 5, top: 10, cursor: 'pointer', fontSize: '20px', justifySelf: 'end' }}
                        />
                    }
                </SidebarAvaliacao>
                {
                    !this.state.isSmallScreen &&
                    <span style={{ minWidth: this.state.sidebarIsOpen ? this.state.sidebarWidth : '60px', minHeight: '100%', transition: 'all 0.5s' }} />
                }
                <div style={{ width: this.state.isSmallScreen ? '100%' : (this.state.sidebarIsOpen ? `calc(100% - ${this.state.sidebarWidth}px)` : `calc(100% - 60px)`), transition: 'all 0.5s', overflow: this.state.isSmallScreen ? 'unset' : 'auto' }}>
                    {this.state.construction ?
                        <UnderConstruction />
                        :
                        this.renderAvaliacao()
                    }
                </div>
            </>
        )
    }

    renderAvaliacaoFinalizada = () => {
        return (
            <div style={{ ...EssentialStyle.columnCenter, width: "100%", height: 'calc(100vh - 65px)', color: Colors.homePage.grey, fontSize: "2em", fontWeight: 500, gap: 20, padding: 15 }}>
                <span style={{ fontSize: 76, fontWeight: 500, color: Colors.homePage.grey, lineHeight: '1.2', textWrap: 'wrap', textAlign: 'center' }}>
                    {this.state.avaliacao.questionario?.nome}
                </span>
                <div style={{ ...EssentialStyle.rowFlexCenter, maxWidth: this.state.isSmallScreen ? '90vw' : "40vw", height: "25%" }}>
                    <img src={`${gateway}/${this.state.pageStyles.logoEmpresa ? this.state.pageStyles.logoEmpresa : '/img/logo-advis-black.png'}`} alt="Logo da Empresa Avaliadora" style={{ height: "auto", width: "100%", maxHeight: '100%' }} />
                </div>
                <div style={{ ...EssentialStyle.card, width: '100%', maxWidth: this.state.isSmallScreen ? '95vw' : '70vw', flex: 1, display: 'flex', flexDirection: 'column', minHeight: '200px', padding: 10 }}>
                    <TextEditor
                        defaultValue={this.state.avaliacao?.conclusao}
                        editorClassName={'fill-container-editor'}
                        height={'100%'}
                        maxHeight={'100%'}
                        placeholder={' '}
                        noMargin
                        noBorder
                        disabled
                        hideToolbar
                        allowAlignments
                    />
                </div>
                <div style={{ ...EssentialStyle.rowFlexCenter, gap: 20, height: 50 }}>
                    <DefaultButton
                        title={this.props.t('client_src_pages_diagnostico_DiagnosticoAvaliacaoAplicacaoPage:voltarParaHome')}
                        color={this.state.pageStyles.headerColor ? this.state.pageStyles.headerColor : Colors.dark}
                        textColor={this.state.pageStyles.logoFilename == 'logo-advis-white' ? 'white' : 'black'}
                        onClick={() => window.parent.postMessage({ type: 'navigate', url: `/` }, '*')}
                        width={200}
                        height={50}
                        style={{ fontWeight: 400 }}
                    />
                    <DefaultButton
                        title={this.state.avaliacao?.canEditFinalizada ? this.props.t('client_src_pages_diagnostico_DiagnosticoAvaliacaoAplicacaoPage:editarQuestionario') : this.props.t('client_src_pages_diagnostico_DiagnosticoAvaliacaoAplicacaoPage:visualizarQuestionario')}
                        color={this.state.pageStyles.headerColor ? this.state.pageStyles.headerColor : Colors.dark}
                        textColor={this.state.pageStyles.logoFilename == 'logo-advis-white' ? 'white' : 'black'}
                        onClick={() => this.visualizarQuestionario()}
                        width={200}
                        height={50}
                        style={{ fontWeight: 400 }}
                    />
                </div>
            </div>
        )
    }

    renderReiniciarConfirm = async () => {
        confirmAlert({
            customUI: ({ onClose }) => (
                <CustomConfirm
                    title={this.props.t('client_src_pages_diagnostico_DiagnosticoAvaliacaoAplicacaoPage:reiniciarMessageTitle')}
                    message={
                        <p style={{ marginTop: 10, marginBottom: 10 }}>
                            {this.props.t('client_src_pages_diagnostico_DiagnosticoAvaliacaoAplicacaoPage:reiniciarMessageBody')}
                        </p>
                    }
                    buttons={[
                        {
                            label: this.props.t('client_src_pages_diagnostico_DiagnosticoAvaliacaoAplicacaoPage:reiniciarButton'),
                            color: SessionHelper.getColor(),
                            textColor: Colors.light,
                            onClick: async () => {
                                await this.reiniciarQuestionario();

                                onClose();
                            }
                        },
                        {
                            label: this.props.t('client_src_pages_diagnostico_DiagnosticoAvaliacaoAplicacaoPage:cancelarButton'),
                            onClick: () => { onClose(); }
                        },
                    ]}
                />
            )
        });

    }

    renderFinalizarConfirm = async () => {
        confirmAlert({
            customUI: ({ onClose }) => (
                <CustomConfirm
                    title={this.props.t('client_src_pages_diagnostico_DiagnosticoAvaliacaoAplicacaoPage:finalizarMessageTitle')}
                    message={
                        <p style={{ marginTop: 10, marginBottom: 10 }}>
                            {this.props.t('client_src_pages_diagnostico_DiagnosticoAvaliacaoAplicacaoPage:finalizarMessageBody')}
                        </p>
                    }
                    buttons={[
                        {
                            label: this.props.t('client_src_pages_diagnostico_DiagnosticoAvaliacaoAplicacaoPage:finalizarButton'),
                            color: SessionHelper.getColor(),
                            textColor: Colors.light,
                            onClick: async () => {
                                await this.finalizarQuestionario();

                                onClose();
                            }
                        },
                        {
                            label: this.props.t('client_src_pages_diagnostico_DiagnosticoAvaliacaoAplicacaoPage:cancelarButton'),
                            onClick: () => { onClose(); }
                        },
                    ]}
                />
            )
        });
    }

    renderPaginaInicial = () => {
        //Caso avaliacao ja tenha sido finalizada
        if (this.state.avaliacao?.avaliacao?.status == 3) {
            return (
                this.renderAvaliacaoFinalizada()
            )
        }

        let orientacao = '<h2 class="ql-align-center"><strong>' + this.props.t('client_src_pages_diagnostico_DiagnosticoAvaliacaoAplicacaoPage:orientacaoIntro') + '</strong></h2><p><br></p>' +
            '<p class="ql-align-center">' + this.props.t('client_src_pages_diagnostico_DiagnosticoAvaliacaoAplicacaoPage:orientacaoText') + ' ' + 
            this.state.avaliacao?.processo?.nome + " - " + this.state.avaliacao?.periodoAvaliacao?.nome + ' - ' + this.state.avaliacao?.questionario?.nome + '</p><p class="ql-align-center"><br></p>' + 
            this.state.avaliacao?.orientacao;

        return (
            <div style={{ ...EssentialStyle.columnCenter, height: 'calc(100vh - 65px)', width: '100%', placeContent: 'flex-start', padding: "20px 20px", gap: '20px' }}>
                <div style={{ ...EssentialStyle.rowFlexCenter, maxWidth: this.state.isSmallScreen ? '90vw' : "40vw", height: "20%", maxHeight: "20%" }}>
                    <img src={`${gateway}/${this.state.pageStyles.logoEmpresa ? this.state.pageStyles.logoEmpresa : '/img/logo-advis-black.png'}`} alt="Logo da Empresa Avaliadora" style={{ height: "auto", width: "100%", maxHeight: '100%' }} />
                </div>
                <span style={{ fontSize: this.state.isSmallScreen ? 36 : 58, fontWeight: 500, color: Colors.homePage.grey, lineHeight: '1.2', textWrap: 'wrap', textAlign: 'center' }}>
                    {this.state.avaliacao?.processo?.nome + " - " + this.state.avaliacao?.periodoAvaliacao?.nome}
                </span>
                <span style={{ fontSize: this.state.isSmallScreen ? 36 : 58, fontWeight: 500, color: Colors.homePage.grey, lineHeight: '1.2', textWrap: 'wrap', textAlign: 'center' }}>
                    {this.state.avaliacao.questionario?.nome}
                </span>
                <div style={{ ...EssentialStyle.card, width: '100%', maxWidth: this.state.isSmallScreen ? '95vw' : '70vw', flex: 1, display: 'flex', flexDirection: 'column', minHeight: '200px', padding: 10 }}>
                    <TextEditor
                        defaultValue={orientacao}
                        editorClassName={'fill-container-editor'}
                        height={'100%'}
                        maxHeight={'100%'}
                        placeholder={' '}
                        noMargin
                        noBorder
                        disabled
                        hideToolbar
                        allowAlignments
                    />
                </div>
                <div style={{ ...EssentialStyle.rowFlexCenter, gap: 10 }}>
                    <DefaultButton
                        title={this.state.avaliacao?.avaliacao?.status == 2 ? this.props.t('client_src_pages_diagnostico_DiagnosticoAvaliacaoAplicacaoPage:continuar') : this.props.t('client_src_pages_diagnostico_DiagnosticoAvaliacaoAplicacaoPage:iniciar')}
                        color={this.state.pageStyles.headerColor ? this.state.pageStyles.headerColor : Colors.dark}
                        textColor={this.state.pageStyles.logoFilename == 'logo-advis-white' ? 'white' : 'black'}
                        onClick={() => this.iniciarQuestionario()}
                    />
                    {
                        this.state.avaliacao?.avaliacao?.status == 2 && this.state.avaliacao?.questionario?.qtdPerguntasRespondidas > 0 &&
                        <DefaultButton
                            title={this.props.t('client_src_pages_diagnostico_DiagnosticoAvaliacaoAplicacaoPage:reiniciar')}
                            color={Colors.dark}
                            textColor={'white'}
                            onClick={() => this.renderReiniciarConfirm()}
                        />
                    }
                </div>
            </div>
        )
    }

    renderPorcentagemConclusao = () => {
        const porcentagem = Math.round((this.state.avaliacao?.questionario?.qtdPerguntasRespondidas / this.state.avaliacao?.questionario?.qtdPerguntas) * 100);

        return (
            <div style={{ position: 'absolute', top: 10, right: 10, display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 5, textAlign: 'center', color: this.state.pageStyles.logoFilename == 'logo-advis-white' ? 'white' : 'black' }}>
                <span style={{ fontSize: 20, fontWeight: 500, lineHeight: '1', textWrap: 'wrap', textAlign: 'center' }}>
                    {porcentagem}%
                </span> 
                <span style={{ fontSize: 16, fontWeight: 400, lineHeight: '1', textWrap: 'wrap', textAlign: 'center' }}>
                    {this.props.t('client_src_pages_diagnostico_DiagnosticoAvaliacaoAplicacaoPage:concluido')}
                </span>
            </div>
        )
    }
        

    render() {

        if (this.state.loading) {
            return (
                <div style={{ ...EssentialStyle.rowFlexCenter, height: 'calc(100vh - 65px)', width: '100%', placeContent: 'center' }}>
                    <DefaultLoader />
                </div>
            );
        }

        return (
            <>
                {
                    this.state.hash &&
                    <HeaderSimple
                        logo={this.state.pageStyles.logoEmpresa && this.state.isQuestionarioAberto ? this.state.pageStyles.logoEmpresa : this.state.pageStyles.logoFilename ? ('img/' + this.state.pageStyles.logoFilename + ".png") : null}
                        pageStyles={this.state.pageStyles}
                        display={this.state.isSmallScreen && this.state.isQuestionarioAberto ? 'none' : 'block'}
                    >
                        {
                            this.state.isQuestionarioAberto && !this.state.sidebarIsOpen &&
                            <div style={{ 
                                display: 'flex', flexDirection: 'column', position: "absolute", top: "10px", gap: 5, 
                                left: "70px", maxWidth: "calc(50vw - 160px)", fontSize: 16, fontWeight: 500, color: this.state.pageStyles.logoFilename == 'logo-advis-white' ? 'white' : 'black' 
                                }}
                            >
                                <span 
                                    style={{  
                                        textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden',
                                        lineHeight: '1.2', textAlign: 'left', width: '100%',
                                    }}
                                >
                                    {this.state.avaliacao?.processo?.nome + " - " + this.state.avaliacao?.periodoAvaliacao?.nome + " | " +  this.state.avaliacao?.questionario?.nome}
                                </span>
                                <span 
                                    style={{  
                                        textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden',
                                        lineHeight: '1.2', textAlign: 'left', width: '100%',
                                        filter: this.state.pageStyles.logoFilename == 'logo-advis-white' ? 'brightness(0.8)' : 'brightness(1.3)'
                                    }}
                                    >
                                    {this.state.avaliacao?.empresaAvaliada?.id != this.state.avaliacao?.empresaAvaliadora?.id ? this.state.avaliacao?.empresaAvaliada?.nome : this.props.t('client_src_pages_diagnostico_DiagnosticoAvaliacaoAplicacaoPage:autoavaliacao')}
                                </span>
                            </div>
                        }
                        {
                            this.state.isQuestionarioAberto &&
                            <>
                            {
                                this.state.completed && this.state.avaliacao?.avaliacao?.status != 3 ?
                                    <DefaultButton
                                        title={this.props.t('client_src_pages_diagnostico_DiagnosticoAvaliacaoAplicacaoPage:finalizar')}
                                        rightIcon={<FontAwesomeIcon icon={faCheck} />}
                                        color={this.state.pageStyles.headerColor ? this.state.pageStyles.headerColor : Colors.dark}
                                        textColor={this.state.pageStyles.logoFilename == 'logo-advis-white' ? 'white' : 'black'}
                                        onClick={() => this.renderFinalizarConfirm()}
                                        height={40}
                                        style={{
                                            position: "absolute",
                                            top: "12.5px", right: "5px", fontWeight: 400,
                                            border: `1px solid ${this.state.pageStyles?.logoFilename == 'logo-advis-white' ? 'white' : 'black'}`,
                                            boxShadow: `${this.state.pageStyles?.logoFilename == 'logo-advis-white' ? 'rgba(220, 220, 220, 0.3)' : 'rgba(50, 50, 50, 0.3)'} 0px 0px 2px 1px`,
                                        }}
                                    />
                                :
                                    this.renderPorcentagemConclusao()
                            }
                            </>
                        }
                    </HeaderSimple>
                }
                <div
                    style={{
                        display: 'flex',
                        flexDirection: this.state.isSmallScreen ? 'column' : 'row',
                        width: '100%',
                        height: '100%',
                        maxHeight: 'calc(100vh - 65px)',
                        overflow: 'auto',
                    }}
                >
                    {this.state.isQuestionarioAberto ?
                        this.renderQuestionario()
                        :
                        this.renderPaginaInicial()
                    }
                </div>
                {this.state.isQuestionarioAberto && this.state.expandedNode &&
                    <ModalIndicadoresAvaliacao node={this.getNode(this.state.expandedNode)} closeModal={() => this.setState({ expandedNode: null })} />
                }
            </>
        )
    }
}

export default withTranslation()(DiagnosticoAvaliacaoAplicacaoPage);