import React from "react";
import './ListPeriodoAvaliacao.css';
import DiagnosticoHelper from "../../../../../helper/diagnostico/DiagnosticoHelper";
import DefaultLoader from '../../../../tools/DefaultLoader';
import IdiomaHelper from "../../../../../helper/IdiomaHelper";
import PeriodoAvaliacaoCard from "../PeriodoAvaliacaoCard/PeriodoAvaliacaoCard";
import DefaultButton from "../../../../tools/DefaultButton";
import Colors from "../../../../../constants/Colors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusSquare } from "@fortawesome/free-solid-svg-icons";
import { withTranslation } from 'react-i18next';


class ListPeriodoAvaliacao extends React.Component {

    state = {
        loadingPeriodosAvaliacao: this.props.loadingPeriodosAvaliacao || true,
        idProcesso: this.props.idProcesso || null,
        periodosAvaliacao: this.props.periodosAvaliacao || [],
        canEdit: this.props.canEdit || false,
        canView: this.props.canView || false,
    }

    componentDidUpdate(prevProps) {
        if (prevProps.idProcesso !== this.props.idProcesso) {
            this.setState({ idProcesso: this.props.idProcesso }, () => this.loadPeriodosAvaliacao());
        }

        if (prevProps.periodosAvaliacao !== this.props.periodosAvaliacao) {
            this.setState({ periodosAvaliacao: this.props.periodosAvaliacao });
        }

        if (prevProps.loadingPeriodosAvaliacao !== this.props.loadingPeriodosAvaliacao) {
            this.setState({ loadingPeriodosAvaliacao: this.props.loadingPeriodosAvaliacao });
        }
    }

    async componentDidMount() {
        await this.props.i18n.loadNamespaces(['client_src_helper_diagnostico_DiagnosticoHelper']);

        if (this.state.idProcesso) {
            this.loadPeriodosAvaliacao();
        }
    }

    loadPeriodosAvaliacao = async () => {
        
        this.setState({ loadingPeriodosAvaliacao: true });

        let periodos = [];
        let canEdit = false;
        let canView = false;

        if (this.state.idProcesso) {
            ({ periodos, canEdit, canView } = await DiagnosticoHelper.getPeriodosAvaliacao(this.state.idProcesso, 1));

        }

        this.setState({ periodosAvaliacao: periodos, canEdit, canView, loadingPeriodosAvaliacao: false });
    }

    renderLoading() {

        const message = IdiomaHelper.getStruct({
            'pt_br': { loading: 'Carregando Períodos de Avaliação...' },
            'en': { loading: 'Loading Evaluation Periods...' },
            'es': { loading: 'Cargando Períodos de Evaluación...' },
        });

        return (
            <div className={"ListPeriodoAvaliacao loading"}>
                <DefaultLoader title={message[this.props.lang].loading}/>
            </div>
        );
    }

    renderEmptyList() {

        const message = IdiomaHelper.getStruct({
            'pt_br': { empty: 'Nenhum Período de Avaliação cadastrado, crie um para iniciar!', emptySearch: 'Nenhum Período de Avaliação Encontrado', buttom: 'Adicionar Período de Avaliação' },
            'en': { empty: 'No Evaluation Periods registered, create one to start!', emptySearch: 'No Evaluation Periods Found', buttom: 'Add Evaluation Period' },
            'es': { empty: 'No hay Períodos de Evaluación registrados, ¡crea uno para comenzar!', emptySearch: 'No se encontraron Períodos de Evaluación', buttom: 'Agregar Período de Evaluación' },
        });

        if (this.props.countPeriodosAvaliacao == 0) return (
            <div className={"ListPeriodoAvaliacao emptyList"}>
                {DiagnosticoHelper.renderEmpty(message[this.props.lang].empty, {height: '17rem', marginBottom: '1rem'}, {height: '15rem'}, this.props.t)}
                <DefaultButton
                    title={message[this.props.lang].buttom}
                    leftIcon={<FontAwesomeIcon icon={faPlusSquare} />}
                    color={Colors.success}
                    onClick={() => { this.props.onEdit(true); }}
                    disabled={!this.props.canEdit}
                />
            </div>
        );

        return (
            <div className={"ListProcessos emptyList"}>
                {DiagnosticoHelper.renderEmptySearch(message[this.props.lang].emptySearch,  {height: '17rem', marginBottom: '1rem'}, {height: '15rem'}, this.props.t)}
            </div>
        );
    }

    renderListPeriodoAvaliacao() {
        return (
            <>
                {this.state.periodosAvaliacao.map((periodoAvaliacao, index) => {
                    return (
                        <PeriodoAvaliacaoCard 
                            key={`card-periodo-avaliacao-${periodoAvaliacao.id}`} 
                            periodoAvaliacao={periodoAvaliacao} 
                            lang={this.props.lang} 
                            onDelete={() => { this.state.idProcesso ? this.loadPeriodosAvaliacao() : this.props.onDelete() }}
                            onEdit={(idPeriodoAvaliacao) => { this.props.onEdit(idPeriodoAvaliacao) }}
                        />
                    );
                })}
            </>
        )
    }

    render() {

        if (this.state.loadingPeriodosAvaliacao) return this.renderLoading();
        if (this.state.periodosAvaliacao.length === 0) return this.renderEmptyList();
        return this.renderListPeriodoAvaliacao();
    }
}

export default withTranslation()(ListPeriodoAvaliacao);