import React from "react";
import './PlanoAcaoCard.css';
import { withTranslation } from 'react-i18next';
import EssentialStyle from "../../../../style/EssentialStyle";
import TooManyTags from "../../../tools/TooManyTags";
import Tag from "../../../tools/Tag";
import UserAvatar from "../../../tools/UserAvatar";
import Colors from "../../../../constants/Colors";
import TooManyAvatars from "../../../tools/TooManyAvatars";
import CustomTooltip from "../../../tools/CustomTooltip";
import ProgressBarCustom from "../../../tools/ProgressBar/ProgressBarCustom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEquals, faChevronUp, faChevronDown, faCalendar, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment';
import LayoutHelper from "../../../../helper/LayoutHelper";
import Sig from "../../../../api/Sig";
import SessionHelper from "../../../../helper/SessionHelper";
import PlanoAcaoHelper from "../../../../helper/planoAcao/PlanoAcaoHelper";
import DataHelper from "../../../../helper/DataHelper";

class PlanoAcaoCard extends React.Component {
    state = {
        loading: true,
        etapas: null,
        progressBarHovered: false,
    }

    async componentDidMount() {
        await this.props.i18n.loadNamespaces(['client_src_components_modules_planoAcao_PlanoAcaoCard_PlanoAcaoCard']);
        await this.loadData();
    }


    async loadData() {
        this.setState({ loading: true });
        let etapas = await Sig.request('POST', 'planoAcao/listEtapasPlano', { codigo: this.props.plano.codigo });
        this.setState({ etapas, loading: false });
    }

    renderTendencia() {
        if (this.props.plano?.tendencia) {
            let icon = null;

            if (this.props.plano?.tendencia?.value == -1) icon = <FontAwesomeIcon icon={faChevronDown} style={{ color: this.props.plano?.tendencia?.color, fontSize: 18, marginRight: 4 }} />
            if (this.props.plano?.tendencia?.value == 1) icon = <FontAwesomeIcon icon={faChevronUp} style={{ color: this.props.plano?.tendencia?.color, fontSize: 18, marginRight: 4 }} />
            if (this.props.plano?.tendencia?.value == null) icon = <FontAwesomeIcon icon={faEquals} style={{ color: this.props.plano?.tendencia?.color, fontSize: 18, marginRight: 4 }} />

            return (
                <CustomTooltip tooltip={this.props.plano?.tendencia?.msg} style={{ marginLeft: 8 }}>
                    {icon}
                </CustomTooltip>
            );
        }
    }


    renderTitle() {

        let hasEtapas = this.state.loading || this.state.etapas.length > 0;

        return (
            <div style={{ ...EssentialStyle.rowFlexStart, paddingLeft: 3, paddingBottom: 10, width: '100%' }}>
                <Tag tag={{ id: `${this.props.plano.codigo}-pa-code`, nome: this.props.plano.codigo }} />
                {!hasEtapas &&
                    <CustomTooltip tooltip={this.props.i18n.t('client_src_components_modules_planoAcao_PlanoAcaoCard_PlanoAcaoCard:tooltip_no_steps')} placement="right">
                        <FontAwesomeIcon icon={faExclamationTriangle} style={{ marginLeft: 10 }} className={'icon warning'} />
                    </CustomTooltip>
                }
                {this.renderTendencia()}
                <span style={{
                    marginLeft: 6,
                    fontSize: 18,
                    fontWeight: 500,
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    maxWidth: this.props.isSmallScreen ? `calc(100% - 70px - ${!hasEtapas ? 50 : 0}px)` : '50vw'
                }}>
                    {DataHelper.removeHtmlTags(this.props.plano.nome)}
                </span>
            </div>
        );
    }

    renderTags() {
        let ignoreCalc = this.props.plano?.id_empresa == SessionHelper.getData().id_empresa;
        let companyNameLength = (this.props.plano?.nome_empresa?.length || 0) * 11;

        return (
            <div style={{ minHeight: 28 }}>
                <TooManyTags max={4} maxWidth={`calc(${this.props.isSmallScreen ? 85 : 50}vw - ${ignoreCalc ? 0 : companyNameLength}px)`} tags={this.props.plano?.tags || []} />
            </div>
        );
    }

    renderPeople() {
        let spacing = 6;
        let style = { paddingLeft: 3, paddingTop: 10 };

        if (this.props.isSmallScreen) {
            spacing = 8;
            style = { ...EssentialStyle.columnStart, ...style };
        } else {
            style = { ...EssentialStyle.rowFlexStart, ...style };
        }


        return (
            <div style={style}>
                <div style={{ ...EssentialStyle.rowFlexStart }}>
                    <span style={{ marginRight: spacing, fontSize: 14, color: Colors.dark }}>{this.props.i18n.t('client_src_components_modules_planoAcao_PlanoAcaoCard_PlanoAcaoCard:responsible')}</span>
                    <UserAvatar id={this.props.plano.responsavel.id} />
                    {!this.props.isSmallScreen && <span style={{ height: 20, width: 1, backgroundColor: Colors.dark, marginLeft: spacing * 2 }}></span>}
                </div>
                {this.props.plano?.superior?.id &&
                    <div style={{ ...EssentialStyle.rowFlexStart, marginLeft: !this.props.isSmallScreen ? spacing * 2 : 0 }}>
                        <span style={{ marginRight: spacing, fontSize: 14, color: Colors.dark }}>{this.props.i18n.t('client_src_components_modules_planoAcao_PlanoAcaoCard_PlanoAcaoCard:evaluator')}</span>
                        <UserAvatar id={this.props.plano.superior.id} />
                        {!this.props.isSmallScreen && <span style={{ height: 20, width: 1, backgroundColor: Colors.dark, marginLeft: spacing * 2 }}></span>}
                    </div>
                }
                {this.props.plano?.participantes?.length ?
                    <div style={{ ...EssentialStyle.rowFlexStart, marginLeft: !this.props.isSmallScreen ? spacing * 2 : 0 }}>
                        <span style={{ marginRight: spacing * 2, fontSize: 14, color: Colors.dark }}>{this.props.i18n.t('client_src_components_modules_planoAcao_PlanoAcaoCard_PlanoAcaoCard:participants')}</span>
                        <TooManyAvatars participantsTotal={this.props.plano?.participantes?.length} participantsVisiveis={5} arrayParticipants={this.props.plano?.participantes} />
                    </div>
                    : null}
            </div>
        );
    }

    renderCompany() {
        if(this.props.plano?.id_empresa == SessionHelper.getData().id_empresa) return null;

        return (
            <div style={{ ...EssentialStyle.rowFlexStart, marginLeft: 2 }}>
                <div style={{ backgroundColor: Colors.tag, color: Colors.dark, fontSize: 12, padding: 5, borderRadius: 5, fontWeight: 600 }}>{this.props.plano?.nome_empresa}</div>
                {this.props.plano?.tags?.length ? <span style={{ marginLeft: 6, marginRight: 4, fontWeight: 200 }}>|</span> : null}
            </div>
        );
    }

    renderLeft() {
        return (
            <div style={{ 
                ...EssentialStyle.columnStart, 
                width: this.props.isSmallScreen ? '100%' : 'auto' 
            }}>
                {this.renderTitle()}
                <div style={{
                    ...EssentialStyle.rowFlexStart
                }}>
                    {this.renderCompany()}
                    {this.renderTags()}
                </div>
                {this.renderPeople()}
            </div>
        );
    }

    renderStatus() {
        let propStatus = this.props.plano?.status || this.props.i18n.t('client_src_components_modules_planoAcao_PlanoAcaoCard_PlanoAcaoCard:not_started');
        return (
            <div style={{ ...EssentialStyle.rowFlexStart, marginLeft: 2 }}>
                <div style={{ backgroundColor: Colors.planoAcao.status.backgroundColor[propStatus], color: Colors.planoAcao.status.color[propStatus], fontSize: 12, padding: 5, borderRadius: 5, marginRight: 2, fontWeight: 600 }}>
                    {this.props.plano?.status == 'Concluído' && this.props.i18n.t('client_src_components_modules_planoAcao_PlanoAcaoCard_PlanoAcaoCard:concluido')}
                    {this.props.plano?.status == 'Cancelado' && this.props.i18n.t('client_src_components_modules_planoAcao_PlanoAcaoCard_PlanoAcaoCard:cancelado')}
                    {this.props.plano?.status == 'Não Iniciado' && this.props.i18n.t('client_src_components_modules_planoAcao_PlanoAcaoCard_PlanoAcaoCard:nao_iniciado')}
                    {this.props.plano?.status == 'Em Desenvolvimento' && this.props.i18n.t('client_src_components_modules_planoAcao_PlanoAcaoCard_PlanoAcaoCard:em_desenvolvimento')}
                    {this.props.plano?.status == 'Atrasado' && this.props.i18n.t('client_src_components_modules_planoAcao_PlanoAcaoCard_PlanoAcaoCard:atrasado')}
                    {(this.props.plano?.status == 'Concluído' || this.props.plano?.status == 'Cancelado') && this.props.plano?.data_conclusao ? ` - ${moment(this.props.plano?.data_conclusao).format('DD/MM/YY')}` : ''}
                </div>
            </div>
        );
    }

    renderVinculos() {
        let vinculos = PlanoAcaoHelper.getVinculosTagsConfig();
        let vinculosPlano = this.props.plano?.vinculos || [];
        let showVinculos = [];

        if (vinculosPlano.length > 1) {
            vinculosPlano.sort((a, b) => {
                return a.tipo - b.tipo;
            });
        }

        vinculosPlano.forEach(vinculo => {
            let found = vinculos.find(v => v.key == vinculo.tipo);
            if (found) {
                if (!showVinculos.includes(found)) showVinculos.push(found);
            }
        });

        return (
            <div style={{ ...EssentialStyle.rowFlexEnd, minHeight: 28 }}>
                {showVinculos.length ?
                    <span style={{ fontSize: 14, color: Colors.dark, marginRight: 4 }}>
                        {showVinculos.length > 1 ? `${this.props.i18n.t('client_src_components_modules_planoAcao_PlanoAcaoCard_PlanoAcaoCard:links')}: ` : `${this.props.i18n.t('client_src_components_modules_planoAcao_PlanoAcaoCard_PlanoAcaoCard:link')}: `}
                    </span>
                    :
                    null
                }
                {showVinculos.map((vinculo, key) => {
                    return (
                        <CustomTooltip key={key + Math.random()} placement={'bottom'} tooltip={this.props.i18n.t(`client_src_components_modules_planoAcao_PlanoAcaoCard_PlanoAcaoCard:vinculo_description_${vinculo.key}`)}>
                            <div style={{ ...EssentialStyle.rowFlexStart, marginLeft: 2 }}>
                                <div style={{ backgroundColor: vinculo.color, color: vinculo.textColor, fontSize: 12, padding: 5, borderRadius: 5, marginRight: 2, fontWeight: 600 }}>{this.props.i18n.t(`client_src_components_modules_planoAcao_PlanoAcaoCard_PlanoAcaoCard:vinculo_text_${vinculo.key}`)}</div>
                            </div>
                        </CustomTooltip>

                    );
                })}
            </div>
        );
    }

    renderPeriodo() {
        return (
            <div style={{ ...EssentialStyle.rowFlexStart, marginLeft: 2, backgroundColor: Colors.tag, color: Colors.dark, fontSize: 12, padding: 5, borderRadius: 5, fontWeight: 600 }}>
                <FontAwesomeIcon icon={faCalendar} style={{ color: Colors.dark, fontSize: 14, marginRight: 4 }} />
                <span>{this.props.plano?.data_inicio != 0 ? moment(this.props.plano?.data_inicio).format('DD/MM/YY') : "- -"}{this.props.plano?.data_fim ? `~ ${moment(this.props.plano?.data_fim).format('DD/MM/YY')}` : ``}</span>
            </div>
        );
    }

    renderRight() {
        let style = { height: 120 };

        if (this.props.isSmallScreen) {
            style = { ...EssentialStyle.columnSpaceBetween, alignItems: 'flex-start', marginTop: 20, ...style, width: '100%' };
        } else {
            style = { ...EssentialStyle.columnSpaceBetween, alignItems: 'flex-end', ...style };
        }

        return (
            <div style={style}>
                {this.renderVinculos()}
                <div style={{ ...EssentialStyle.rowFlexEnd }}>
                    {this.renderStatus()}
                    {this.renderPeriodo()}
                </div>
                {!this.state.loading && <ProgressBarCustom tipo={'planoAcao'} id={this.props.plano.codigo} style={{width: this.props.isSmallScreen ? '92vw' : 286, height: 20 }} />}
                {this.renderUltimaAlteracao()}
            </div>
        );
    }

    renderUltimaAlteracao() {
        if(!this.props.plano?.ultima_alteracao || !this.props.plano?.ultima_alteracao_colaborador) return;
        if(!moment(this.props.plano.ultima_alteracao).isValid()) return;
        
        const data = moment(this.props.plano.ultima_alteracao);
        const diffDays = moment().diff(data, 'days');

        const suffix = (diffDays < 6 && diffDays > -6) ? '' : this.props.i18n.t('client_src_components_modules_planoAcao_PlanoAcaoCard_PlanoAcaoCard:on');    

        return (
            <span
                style={{
                    fontSize: 12,
                    color: Colors.homePage.grey,
                    marginLeft: 5,
                    fontWeight: 200
                }}
            >
                {this.props.i18n.t('client_src_components_modules_planoAcao_PlanoAcaoCard_PlanoAcaoCard:updated')} {suffix}{moment(data).calendar()} {this.props.i18n.t('client_src_components_modules_planoAcao_PlanoAcaoCard_PlanoAcaoCard:by')} {this.props.plano.ultima_alteracao_colaborador}
            </span>
        );
    }

    renderCard() {
        let cardStyle = {
            ...EssentialStyle.card,
            marginTop: 10,
            padding: 10,
            cursor: 'pointer',
            width: '100%',
        };

        if (this.props.isSmallScreen) {
            cardStyle = {
                ...cardStyle,
                ...EssentialStyle.columnStart,
            };
        } else {
            cardStyle = {
                ...cardStyle,
                ...EssentialStyle.rowSpaceBetween,
            }
        }

        return (
            <div
                onClick={(e) => {
                    if(this.props.onClick){
                        e.stopPropagation();
                        if (e.ctrlKey || e.metaKey) {
                            e.preventDefault();
                            this.props.onClick(this.props.plano, false);
                        } else {
                            this.props.onClick(this.props.plano, true);
                        }
                    }
                }}
                onMouseDown={(e) => {
                    if (e.button == 1 && this.props.onClick) {
                        e.stopPropagation();
                        e.preventDefault();
                        this.props.onClick(this.props.plano, false);
                    }
                }}
                style={cardStyle}
                className="plano-acao-card"
            >
                {this.renderLeft()}
                {this.renderRight()}
            </div>
        );
    }

    render() {
        return this.renderCard();
    }
}

export default withTranslation()(PlanoAcaoCard);