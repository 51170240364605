import React from "react";
import AtaReuniaoTopicoCard from "./AtaReuniaoTopico/AtaReuniaoTopicoCard";
import EssentialStyle from "../../../style/EssentialStyle";
import DefaultButton from "../../tools/DefaultButton";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusSquare } from '@fortawesome/free-solid-svg-icons';
import Colors from "../../../constants/Colors";
import Sig from "../../../api/Sig";
import DragAndDropHelper from "../../../helper/DragAndDropHelper";
import DefaultLoader from "../../tools/DefaultLoader";
import Constants from "../../../constants/Api";

const gateway = Constants.getSigEndPoint();

export default class AtaReuniaoTopicoList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            editOrder: this.props.enableEditOrder,
            draggingItem: null,
            draggingOverItem: null,
            draggingOverItemTop: null,
            loading: false,
        }
    }

    componentDidMount() {
        this.setState({ editOrder: this.props.enableEditOrder });
    }

    componentDidUpdate(prevProps) {
        if(this.props.enableEditOrder != prevProps.enableEditOrder) {
            this.setState({ editOrder: this.props.enableEditOrder });
        }
    }

    onDragStartItem = async (e, item) => {
        this.setState({ draggingItem: item, draggingOverItem: null });
        setTimeout(() => { e.target.style.display = "none" }, 10);
    }

    onDragEndItem = async (e, item) => {
        if(this.state.draggingItem && this.state.draggingOverItem) {
            try {
                var topicos = [...this.props.topicos];
                var drag_index = this.state.draggingOverItem.drag_index;
                if(this.state.draggingOverItemTop && drag_index > this.state.draggingItem.drag_index && drag_index > 0) {
                    drag_index--;
                }
                if(!this.state.draggingOverItemTop && drag_index < this.state.draggingItem.drag_index && drag_index < topicos.length - 1) {
                    drag_index++;
                }
                var draggingItemIndex = topicos.findIndex(e => e.id == this.state.draggingItem.id);
                var draggingOverItemIndex = topicos.findIndex(e => e.drag_index == drag_index);
                var draggingItem = topicos[draggingItemIndex];
                var draggingOverItem = topicos[draggingOverItemIndex];

                this.setState({ loading: true });
                var response = await Sig.request('POST', `ataReuniao${this.props.hash ? "Externa" : ""}/updateTopicoDragIndex`, {
                    id: draggingItem.id,
                    hash: this.props.hash,
                    drag_index: draggingOverItem.drag_index
                });
                var topicosUpdated = response.topicos;
                if(topicosUpdated) {
                    this.setState({ draggingItem: null, draggingOverItem: null, loading: false }, () => {
                        this.props.updateTopicosCallback(topicosUpdated);
                        setTimeout(() => { e.target.style.display = "flex" }, 10); 
                    });
                } else {
                    throw new Error("Erro ao atualizar tópico");
                }
            } catch (error) {
                this.setState({ draggingItem: null, draggingOverItem: null, loading: false });
                setTimeout(() => { e.target.style.display = "flex" }, 10); 
                return;
            }

        } else {
            this.setState({ draggingItem: null, draggingOverItem: null });
            setTimeout(() => { e.target.style.display = "flex" }, 10); 
        }   
    }

    onDragEnterItem = async (e, item) => {
        let top = DragAndDropHelper.checkTopTopicosAtaReuniao(e, 0.5);
        if (item.id !== this.state.draggingItem.id || top !== this.state.draggingOverItemTop) {
            this.setState({ draggingOverItemTop: top }, () => { this.setState({ draggingOverItem: item }) });
        }
    }

    changeDescricaoTopico = (id, descricao) => {
        var topicos = [...this.props.topicos];
        var topicoIndex = topicos.findIndex(e => e.id == id);
        topicos[topicoIndex].descricao = descricao;
        this.props.updateTopicosCallback(topicos);
    }

    changeNomeTopico = (id, nome) => {
        var topicos = [...this.props.topicos];
        var topico = topicos.find(e => e.id == id);
        topico.nome = nome;
        this.props.updateTopicosCallback(topicos);
    }

    changeDeliberacaoTopico = (id, deliberacao) => {
        var topicos = [...this.props.topicos];
        var topico = topicos.find(e => e.id == id);
        topico.deliberacao = deliberacao;
        this.props.updateTopicosCallback(topicos);
    }

    changePlanosTopico = (id, planos) => {
        var topicos = [...this.props.topicos];
        var topico = topicos.find(e => e.id == id);
        topico.planos = planos;
        this.props.updateTopicosCallback(topicos);
    }

    updateTopico = (topico) => {
        var topicos = [...this.props.topicos];
        var topicoIndex = topicos.findIndex(e => e.id == topico.id);
        topicos[topicoIndex] = topico;
        this.props.updateTopicosCallback(topicos);
    }
        
    updateParticipantesTopico = (id, participantes) => {
        var topicos = [...this.props.topicos];
        var topicoIndex = topicos.findIndex(e => e.id == id);
        topicos[topicoIndex].participantes = participantes;
        this.props.updateTopicosCallback(topicos);
    }

    deleteTopico = async (id) => {
        var topicos = [...this.props.topicos];
        var topicoIndex = topicos.findIndex(e => e.id == id);
        topicos.splice(topicoIndex, 1);
        this.props.updateTopicosCallback(topicos);
    }

    updateNotasTopico = (id, notas) => {
        var topicos = [...this.props.topicos];
        var topicoIndex = topicos.findIndex(e => e.id == id);
        topicos[topicoIndex].notas = notas;
        this.props.updateTopicosCallback(topicos);
    }

    changeTarefaTopico = (idTopico, tarefa, deleteTarefa = false) => {
        var topicos = [...this.props.topicos];
        var topicoIndex = topicos.findIndex(e => e.id == idTopico);
        var tarefaIndex = topicos[topicoIndex].tarefas.findIndex(e => e.id == tarefa.id);
        if(tarefaIndex == -1) {
            if(deleteTarefa) return;
            topicos[topicoIndex].tarefas.push(tarefa);
        } else {
            if(deleteTarefa) {
                topicos[topicoIndex].tarefas.splice(tarefaIndex, 1);
            } else {
                topicos[topicoIndex].tarefas[tarefaIndex] = tarefa;
            }
        }
        this.props.updateTopicosCallback(topicos);
    }

    renderList() {
        return (
            <div 
                style={{ 
                    ...(this.props.topicos?.length > 0 ? EssentialStyle.columnStart : EssentialStyle.columnCenter), 
                    width: '100%', paddingBottom: 20, position: "relative", gap: 10, padding: "10px 0px",
                    minHeight: `calc(100vh - ${this.props.headerHeight + 5}px)`, 
                    alignItems: this.props.topicos?.length > 0 ? "flex-start" : "center"}}>
                {
                    this.props.topicos?.length > 0 ? this.props.topicos.map((topico, index) => (
                        <AtaReuniaoTopicoCard 
                            key={"topico-"+index} 
                            topico={topico}
                            idAtaReuniao={this.props.idAtaReuniao}
                            blockEdit={this.props.finalizado ? true : false}
                            isSmallScreen={this.props.isSmallScreen}
                            onDragStartItem={this.onDragStartItem} 
                            onDragEndItem={this.onDragEndItem} 
                            onDragEnterItem={this.onDragEnterItem} 
                            editOrder={this.state.editOrder} 
                            draggingOverItem={this.state.draggingOverItem}
                            draggingOverItemTop={this.state.draggingOverItemTop}
                            changeNomeTopicoCallback={this.changeNomeTopico}
                            changeDescricaoTopicoCallback={this.changeDescricaoTopico}
                            changeDeliberacaoTopicoCallback={this.changeDeliberacaoTopico}
                            changePlanosTopicoCallback={this.changePlanosTopico}
                            openModalParticipantesTopico={this.props.openModalParticipantesTopicoCallback}
                            deleteTopicoCallback={this.deleteTopico}
                            changeNotasCallback={this.updateNotasTopico}
                            participantes={this.props.participantes}
                            changeTarefaCallback={this.changeTarefaTopico}
                            detailsModalTarefaCallback={this.props.detailsModalTarefaCallback}
                            updateAnexoCallback={this.props.updateAnexoCallback}
                            hash={this.props.hash}
                        />
                    )) 
                : 
                    <div style={{...EssentialStyle.columnCenter, marginTop: 10, color: Colors.homePage.grey, fontSize: "2em", fontWeight: 500}}>
                        <img
                            src={`${gateway}/img/undraw/undraw_create_re_57a3.svg`}
                            alt={"Ilustração de um homem criando um tópico"}
                            style={{
                                height: `calc(100vh - ${this.props.headerHeight + 5 + 220}px)`,
                                maxHeight: 200,
                                marginBottom: 10,
                            }}
                        />
                        Adicione os Tópicos da Reunião
                    </div>
                }
                { this.props.canEdit && !this.props.finalizado &&
                    <div style={{ ...EssentialStyle.rowFlexCenter, width: '100%', marginTop: 20 }}>
                        <DefaultButton
                            onClick={this.props.addTopicoCallback}
                            leftIcon={<FontAwesomeIcon icon={faPlusSquare} />}
                            color={Colors.success}
                            tooltipPlacement={'bottom'}
                            title={"Adicionar tópico"}
                        />
                    </div>
                }
            </div>
        );
    }

    render() {
        if(this.state.loading){
            return (
                <div style={{...EssentialStyle.columnCenter, width: '100%', height: '100%'}}>
                    <DefaultLoader />
                </div>
            );
        }

        return (
            <>
                {this.renderList()}
            </>
        );
    }
}