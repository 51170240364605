import React from "react";
import DefaultLoader from "../../../../../tools/DefaultLoader";
import { withTranslation } from 'react-i18next';
import Sig from "../../../../../../api/Sig";
import Modal from "../../../../../tools/Modal";
import Colors from "../../../../../../constants/Colors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import SessionHelper from "../../../../../../helper/SessionHelper";
import DefaultButton from "../../../../../tools/DefaultButton";

import './HistoricoAvaliacaoModal.css';
import UserAvatar from "../../../../../tools/UserAvatar";

class HistoricoAvaliacaoModal extends React.Component {
    state = {
        loading: true,
        idNodeFiltered: null,
        historico: []
    }

    async componentDidMount() {
        await this.props.i18n.loadNamespaces(['client_src_components_modules_diagnostico_Avaliacao_Aplicacao_HistoricoAvaliacaoModal_HistoricoAvaliacaoModal']);
        
        await this.loadHistorico();
    }

    async loadHistorico() {
        this.setState({loading: true});
        
        let {historico} = await Sig.request('POST', `diagnostico/gradeAvaliacao/getHistoricoAvaliacao`, { idAvaliacao: this.props.avaliacao?.id, idNode: this.state.idNodeFiltered || null });
        
        this.setState({historico: historico || [], loading: false });
    }

    componentDidUpdate(prevProps) {
        if(prevProps.refresh != this.props.refresh && this.props.refresh) {
            this.loadHistorico();
        }
    }

    renderLoadingHistorico() {
        return (
            <div className="loading-historico">
                <DefaultLoader/>
            </div>
        )
    }

    renderHistorico() {
        return (
            <div className="historico-content">
                {this.state.historico?.length > 0 ? this.state.historico.map((historico, index) => {
                    return (
                        <div key={index} className="historico-item">
                            <div className="historico-item-content">
                                <div className="historico-item-usuario">
                                    <UserAvatar user={historico.responsavel} showName showNameAdaptative/>
                                </div>
                                <div className="historico-item-data">
                                    {new Date(historico.data).toLocaleDateString()} - {new Date(historico.data).toLocaleTimeString()}
                                </div>
                            </div>
                            <div className="historico-item-texto">
                                <strong>
                                    {historico.caminho}
                                </strong>
                                <br/>
                                {historico.texto}
                            </div>
                        </div>
                    )
                })
                    :
                    <div className="historico-empty">{this.props.t('client_src_components_modules_diagnostico_Avaliacao_Aplicacao_HistoricoAvaliacaoModal_HistoricoAvaliacaoModal:historico_vazio')}</div>
                }
            </div>
        )
    }

    renderDetailing() {
        return (
            <Modal
                className="HistoricoAvaliacaoModal"
            >
                <div
                    className="ModalHeader"
                    style={{ color: Colors.light, backgroundColor: SessionHelper.getColor() }}
                >
                    <span
                        className="title"
                        style={{ color: Colors.light }}
                    >
                        {this.props.t('client_src_components_modules_diagnostico_Avaliacao_Aplicacao_HistoricoAvaliacaoModal_HistoricoAvaliacaoModal:historico_alteracoes')}
                    </span>
                    <DefaultButton
                        className={"close"}
                        title={''}
                        leftIcon={<FontAwesomeIcon icon={faClose} />}
                        textColor={Colors.light}
                        color={"transparent"}
                        height={30}
                        width={30}
                        onClick={() => this.props.onClose && this.props.onClose()}
                    />
                </div>
                <div className="ModalBody">
                    <div className={"historico"}>
                        { this.state.loading && this.renderLoadingHistorico()}
                        {!this.state.loading && this.renderHistorico()}
                    </div>
                </div>
            </Modal>
            
        )
    }

    render() {
        return this.renderDetailing();
    }
}

export default withTranslation()(HistoricoAvaliacaoModal);