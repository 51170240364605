import React from "react";
import Modal from "../../../../tools/Modal";
import LayoutHelper from "../../../../../helper/LayoutHelper";
import AtaReuniaoParticipantes from "../../AtaReuniaoParticipantes/AtaReuniaoParticipantes";
import EssentialStyle from "../../../../../style/EssentialStyle";
import Sig from "../../../../../api/Sig";
import DefaultButton from "../../../../tools/DefaultButton";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faAdd, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import Colors from "../../../../../constants/Colors";
import { toast } from "react-toastify";
import DefaultLoader from "../../../../tools/DefaultLoader";
import AtaReuniaoParticipanteItem from "../../AtaReuniaoParticipantes/AtaReuniaoParticipanteItem";
import CustomTooltip from "../../../../tools/CustomTooltip";



export default class AtaReuniaoTopicoModalParticipantes extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            participantesHerdados: [],
            loading: true,
            isSmallScreen: LayoutHelper.isSmallScreen(),
            participantes: [],
            oldParticipantes: [],
            selectsOptions: null,
        };
        this.modalRef = React.createRef();
    }

    async componentDidMount() {
        window.addEventListener('resize', this.verifyScreen);
        this.loadData();
    }

    async loadData() {
        this.setState({ loading: true });
        if(this.props.idTopico){

            var response = await Sig.request('GET', `ataReuniao${this.props.hash ? "Externa" : ""}/getParticipantesTopico`, {
                id: this.props.idTopico,
                hash: this.props.hash
            });

            if(response.status == 200) {
                this.setState({ 
                    oldParticipantes: [...response.participantes] || [],
                    participantesHerdados: response.participantesHerdados || [], 
                    participantes: response.participantes || [], 
                    selectsOptions: response.selectsOptions, 
                    criador: response.criadorAta 
                });
            }
        }
        this.setState({ loading: false });
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.verifyScreen);
    }

    verifyScreen = () => {
        if(this.state.isSmallScreen !== LayoutHelper.isSmallScreen())
            this.setState({ isSmallScreen: LayoutHelper.isSmallScreen() });
    }

    validarParticipantes = () => {
        let participantes = [...this.state.participantes];
        
        participantes = participantes.filter((participante) => {
            return (!participante.id && (!participante.nome || participante.nome == "" || !participante.email || participante.email == "" )) || participante.permissao == null;
        });

        if(participantes.length > 0) {
            toast.error('Preencha todos os participantes corretamente!');
            return false;
        }

        return true;
    }

    addParticipanteCallback = () => {
        if(this.validarParticipantes()) {
            let participantes = [...this.state.participantes];
            participantes.push({
                id: null,
                nome: null,
                email: null,
                permissao: this.state.participantes.length > 0 ? this.state.participantes[this.state.participantes.length-1].permissao : null,
                funcao: 0
            });
            this.setState({ participantes, oldParticipantes: [...participantes] });
        }
    }

    changeCallback = (index, participante) => {
        let participantes = [...this.state.participantes];
        participantes[index] = participante;
        this.setState({ participantes });
    }

    removeCallback = (index) => {  
        let participantes = [...this.state.participantes];
        participantes.splice(index, 1);
        this.setState({ participantes });
    }

    saveParticipantes = async () => {
        if(this.validarParticipantes()) {
            var response = await Sig.request('POST', `ataReuniao${this.props.hash ? "Externa" : ""}/updateParticipantesTopico`, { 
                id: this.props.idTopico,
                hash: this.props.hash, 
                participantes: JSON.stringify(this.state.participantes) 
            });

            if(response.status == 200) {
                this.props.updateTopicosCallback();
            }
        }
    }

    renderButtonAddParticipante = () => {
        return (
            <DefaultButton
                color={Colors.success}
                leftIcon={<FontAwesomeIcon icon={faAdd} />}
                title={"Adicionar Participante"}
                onClick={() => this.addParticipanteCallback()}
            />
        );
    }

    renderParticipantesEditor() {
        var participantesHerdados = this.state.participantesHerdados.filter((participante) => { 
            if(this.state.participantes.some((participanteSelecionado) => {
                return participanteSelecionado.email == participante.email;
            })) {
                return false;
            }
            return true;
        });

        return (
            <div style={{...EssentialStyle.columnCenter, minWidth: "100%"}}>
                    <DefaultButton
                        style={{padding: 0, width: 40, height: 40, placeSelf: 'flex-end', borderRadius: "50%"}}
                        onClick={() => this.props.closeCallback()}
                        color={"transparent"}
                        textColor={Colors.dark}
                        leftIcon={<FontAwesomeIcon icon={faClose}/>} 
                    />
                <div style={{ ...EssentialStyle.columnStart, alignItems: "center", padding: '20px', textAlign: 'center', minHeight: 200, minWidth: "100%", maxHeight: '70vh', overflowY: "auto" }}>
                    { this.state.loading ?
                        this.renderLoading()
                    : 
                        <>
                        <div style={{...EssentialStyle.rowFlexCenter, width: "100%", gap: 10}}>
                            <span style={{fontSize: 18, fontWeight: 400}}>Participantes de todos os tópicos da Ata de Reunião</span>
                            <CustomTooltip tooltip={"Participantes Herdados da Ata e que tem suas permissões aplicadas a todos os tópicos. Caso o participante seja adicionado a um tópico especifico, ele perderá acesso ao restante dos tópicos e poderá acessar/editar somente os tópicos nos quais for incluído."}>
                                <FontAwesomeIcon icon={faInfoCircle} style={{...EssentialStyle.icon, color: Colors.dark, cursor: 'help'}} />
                            </CustomTooltip>
                        </div>
                        <div style={{width: "100%", height: "auto"}}>  
                            {participantesHerdados && participantesHerdados.length > 0 &&
                                participantesHerdados.map((participante, index) => {
                                    return (
                                        <AtaReuniaoParticipanteItem 
                                            isTopico
                                            noEdit
                                            key={index} 
                                            index={index} 
                                            participante={participante} 
                                            changeCallback={() => {}} 
                                            removeCallback={() => {}}
                                            selectsOptions={this.state.selectsOptions}
                                        />
                                    )
                                })
                            }
                        </div>
                            
                        <span style={{fontSize: 18, fontWeight: 400}}>Participantes do Tópico</span>
                    {!this.state.participantes || this.state.participantes.length == 0 ?
                                <>
                                <span style={{fontSize: 22, fontWeight: 500, color: Colors.homePage.darkGrey, marginTop: 10, marginBottom: 20}}>Nenhum participante no tópico</span>
                                {this.props.canEdit && this.renderButtonAddParticipante()}
                            </>
                        :
                            <AtaReuniaoParticipantes 
                                noEdit={!this.props.canEdit}
                                isTopico
                                criador={this.state.criador}
                                participantes={this.state.participantes} 
                                selectsOptions={this.state.selectsOptions} 
                                changeCallback={this.changeCallback} 
                                removeCallback={this.removeCallback} 
                                addCallback={this.addParticipanteCallback}
                                minHeight={150}
                                overflowY={"unset"}
                            />
                        }
                        </>
                    }   
                </div>
                <DefaultButton
                    disabled={this.state.loading || this.state.participantes == this.state.oldParticipantes}
                    style={{placeSelf: 'flex-end', marginTop: 10}}
                    color={Colors.success}
                    title={"Salvar"}
                    onClick={this.saveParticipantes}
                />
            </div>
        );
    }

    renderLoading = () => {
        return (
            <div style={{...EssentialStyle.columnCenter, minWidth: '100%', minHeight: 250}}>
                <DefaultLoader />
            </div>
        );
    }

    render() {
        return (
            <Modal style={{ width: this.state.isSmallScreen ? '95vw' : '90vw' }} ref={this.modalRef}>
                {this.renderParticipantesEditor()}
            </Modal>
        );
    }
}