import React from "react";
import AtaReuniaoTarefaCard from "./AtaReuniaoTarefaCard";
import EssentialStyle from "../../../../../style/EssentialStyle";
import Colors from "../../../../../constants/Colors";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusSquare } from '@fortawesome/free-solid-svg-icons';
import DefaultButton from "../../../../tools/DefaultButton";
import Sig from "../../../../../api/Sig";
import DataHelper from "../../../../../helper/DataHelper";
import TextEditor from "../../../../tools/TextEditor/TextEditor";
import KeyboardHelper from "../../../../../helper/KeyboardHelper";
import { faAdd, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import Select, { components } from 'react-select';
import UserAvatar from "../../../../tools/UserAvatar";

export default class AtaReuniaoTopicoTarefas extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            adding: false,
            newTarefaDescricao: "",
            selectedUser: null
        };
        this.addTarefaInput = React.createRef();
    }

    saveNewTarefa = async () => {
        this.setState({ adding: false });
        var { tarefa } = await Sig.request("POST", `ataReuniao${this.props.hash ? "Externa" : ""}/addTarefa`, { 
            id_ata_reuniao_topico: this.props.idTopico, 
            hash: this.props.hash,
            descricao: this.state.newTarefaDescricao, 
            email_responsavel: this.state.selectedUser.value 
        });
        if (tarefa)
            this.props.changeTarefaCallback(this.props.idTopico, tarefa);
        this.setState({ newTarefaDescricao: "" });
    }

    focusInput = () => {
        if (this.addTarefaInput)
            this.addTarefaInput.focus();
        else {
            setTimeout(() => {
                this.focusInput();
            }, 50);
        }
    }

    DropdownIndicator = (props) => {
        const { selectProps } = props;
        const isMenuOpen = selectProps.menuIsOpen;

        if (isMenuOpen) return null;

        return (
            <components.DropdownIndicator {...props}>
                <div style={{ cursor: 'pointer' }}>
                    <UserAvatar user={this.props.participantes.find(p => p.email == this.state.selectedUser.value)} onlyMail />
                </div>
            </components.DropdownIndicator>
        );
    };

    SingleValue = ({
        children,
        ...props
    }) => (
        <components.SingleValue {...props}>
            {children}
        </components.SingleValue>
    );

    renderPeopleSelect() {

        return (
            <div
                style={{
                    ...EssentialStyle.rowFlexStart,
                    fontSize: 14
                }}
                onClick={(e) => e.stopPropagation()}
            >
                <Select
                    ref={ref => this.selectPeople = ref}
                    styles={{
                        control: (base, state) => ({
                            ...base,
                            ...EssentialStyle.titleBoxHomePage,
                            border: 'none',
                            width: state.isFocused ? '210px' : '70px',
                            boxShadow: state.isFocused ? 'none' : null,
                            backgroundColor: "transparent",
                            marginRight: 8
                        })
                    }}
                    value={this.state.selectedUser}
                    options={DataHelper.formatSelectData(this.props.participantes, 'email', 'nome')}
                    formatOptionLabel={({ label, value }) => (
                        <div style={{ ...EssentialStyle.rowFlexStart, paddingLeft: 8, cursor: 'pointer' }}>
                            <UserAvatar user={this.props.participantes.find(p => p.email == value)} showNameAdaptative showName onlyMail />
                        </div>
                    )}
                    placeholder={''}
                    noOptionsMessage={DataHelper.getSelectEmptyMessage}
                    isSearchable={false}
                    components={{ DropdownIndicator: this.DropdownIndicator, SingleValue: this.SingleValue, IndicatorSeparator: null }}
                    onChange={async (value) => {
                        this.setState({
                            selectedUser: value
                        }, async () => {
                            if (this.selectPeople) this.selectPeople.blur();

                            this.setState({ selectedUser: value });
                        });
                    }}
                    isDisabled={this.props.noEdit}
                />

            </div>
        );
    }

    renderCreateTarefa() {
        let cardStyle = {
            ...EssentialStyle.rowFlexStart,
            backgroundColor: Colors.white,
            fontSize: 16,
            width: '100%',
            borderRadius: 5,
            border: `1px solid ${Colors.border}`,
            fontWeight: 500,
            padding: 10
        };

        return this.state.adding ?
            (
                <div style={{
                    ...cardStyle,
                    cursor: 'default',
                    gap: 8
                }}>
                    {this.renderPeopleSelect()}
                    <TextEditor
                        ref={ref => this.addTarefaInput = ref}
                        defaultValue={this.state.newTarefaDescricao}
                        placeholder={"Descrição da Tarefa..."}
                        onChange={(value) => {
                            this.setState({ newTarefaDescricao: value })
                        }}
                        height={45}
                        hideToolbar
                        noMargin
                        disabledEnterEvent
                        onKeyDown={(evt) => {
                            KeyboardHelper.handleShortcut(
                                evt,
                                ["Enter", "Escape"],
                                [() => {
                                    if (evt.shiftKey) return;
                                    this.addEtapa();
                                }, (e) => {
                                    e.preventDefault();
                                    this.setState({ atividade: '', adding: false })
                                }]
                            )
                        }}
                    />
                    <DefaultButton
                        width={48}
                        leftIcon={<FontAwesomeIcon icon={faTimes} />}
                        color={Colors.dark}
                        onClick={() => { this.setState({ atividade: '', adding: false }) }}
                    />
                    <DefaultButton
                        width={48}
                        leftIcon={<FontAwesomeIcon icon={faCheck} />}
                        color={Colors.dark}
                        onClick={this.saveNewTarefa}
                    />
                </div>
            )
            :
            (
                <DefaultButton
                    onClick={() => { 
                        this.setState({ 
                            adding: true, 
                            selectedUser: this.props.participantes[0] ?  DataHelper.formatSelectedData(this.props.participantes[0], 'email', 'nome') : null
                        }, this.focusInput) }}
                    leftIcon={<FontAwesomeIcon icon={faPlusSquare} />}
                    color={Colors.success}
                    tooltipPlacement={'bottom'}
                    title={"Adicionar"}
                />
            );
    }

    render() {
        if (!this.props.tarefas || this.props.tarefas.length === 0) {
            return (
                <div style={{ ...EssentialStyle.columnStart, width: "100%", padding: "15px 0px", gap: 10, alignItems: "center" }}>
                    {!this.state.adding &&
                        <span style={{ fontSize: 20, color: Colors.homePage.grey }}>Nenhuma tarefa encontrada</span>
                    }
                    {this.props.canEdit &&
                        this.renderCreateTarefa()
                    }
                </div>
            );
        }

        return (
            <div style={{ ...EssentialStyle.columnStart, padding: "15px 0px", width: "100%", alignItems: "center", gap: 10 }}>
                {
                    this.props.tarefas.map((tarefa, index) => {
                        return (
                            <AtaReuniaoTarefaCard
                                key={"tarefa" + index}
                                noEdit={!tarefa.canEdit}
                                canDelete={this.props.canEdit}
                                tarefa={tarefa}
                                participantes={this.props.participantes}
                                updateTarefa={(tarefa) => this.props.changeTarefaCallback(this.props.idTopico, tarefa)}
                                detailsModalCallback={this.props.detailsModalTarefaCallback}
                                deleteTarefa={(tarefa) => this.props.changeTarefaCallback(this.props.idTopico, tarefa, true)}
                                hash={this.props.hash}
                            />
                        )
                    })
                }
                {this.props.canEdit && this.renderCreateTarefa()}
            </div>
        );
    }
}