import React from "react";
import ReactDOM from "react-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight, faEyeSlash, faEye } from "@fortawesome/free-solid-svg-icons";

import Colors from "../../../../constants/Colors";
import EssentialStyle from "../../../../style/EssentialStyle";
import { Dropdown } from "react-bootstrap";
import Sig from "../../../../api/Sig";
import UserAvatar from "../../../tools/UserAvatar";
import CustomTooltip from "../../../tools/CustomTooltip";
import Constants from "../../../../constants/Api";
import DefaultButton from "../../../tools/DefaultButton";
import SessionHelper from "../../../../helper/SessionHelper";
import DefaultLoader from "../../../tools/DefaultLoader";
import LayoutHelper from "../../../../helper/LayoutHelper";
import { withTranslation } from "react-i18next";

const gateway = Constants.getSigEndPoint();
const GD = 'gd';
const GMR = 'gmr';
const MEDIA = 'media';
class MinhaEquipe extends React.Component {
    state = {
        loading: true,
        loadingAdditional: false,
        time: false,
        subordinados: [],
        notas: [],
        transform: 'translateY(+100%)',
        opacity: 0,
        orderTypes: {
            alfabetica: 'Ordem Alfabética',
            maiorGD: 'Maior ao menor GD',
            menorGD: 'Menor ao maior GD',
            maiorGMR: 'Maior ao menor GMR',
            menorGMR: 'Menor ao maior GMR',
            maiorMedia: 'Maior ao menor Média',
            menorMedia: 'Menor ao maior Média',
        },
        selectedOrder: localStorage.getItem('equipe_order_option') || 'alfabetica',
        subordinadoSelected: [],
        hoverSubordinado: null,
        controller: new AbortController(),
        colaboradorRequest: null,
        blocked: true,
        isSmallScreen: LayoutHelper.isSmallScreen(),
        expandedMinhaEquipe: this.props.expandedPageTwoMinhaEquipe,
        loadingTranslation: true,
    }

    async componentDidMount() {
        window.addEventListener('resize', this.verifyScreen);

        await this.props.i18n.loadNamespaces(['client_src_components_modules_inicio_charts_MinhaEquipe']);

        this.setState({ loadingTranslation: false }, () =>
            this.setState({
                orderTypes: {
                    alfabetica: this.props.t('client_src_components_modules_inicio_charts_MinhaEquipe:order_types.alfabetica'),
                    maiorGD: this.props.t('client_src_components_modules_inicio_charts_MinhaEquipe:order_types.maiorGD'),
                    menorGD: this.props.t('client_src_components_modules_inicio_charts_MinhaEquipe:order_types.menorGD'),
                    maiorGMR: this.props.t('client_src_components_modules_inicio_charts_MinhaEquipe:order_types.maiorGMR'),
                    menorGMR: this.props.t('client_src_components_modules_inicio_charts_MinhaEquipe:order_types.menorGMR'),
                    maiorMedia: this.props.t('client_src_components_modules_inicio_charts_MinhaEquipe:order_types.maiorMedia'),
                    menorMedia: this.props.t('client_src_components_modules_inicio_charts_MinhaEquipe:order_types.menorMedia'),
                },
            })
        );

        await this.getEquipeColaborador();
    }

    async componentDidUpdate(prevProps) {
        if ((this.props.userId && this.props.userId !== prevProps.userId) 
            || (this.props.reloadEquipe !== prevProps.reloadEquipe) 
            || (this.props.viewUserConfig !== prevProps.viewUserConfig)) {
            
            this.setState({subordinadoSelected: []}, () => {this.getEquipeColaborador()});
            
        }

        if (this.props.expandedPageTwoMinhaEquipe !== prevProps.expandedPageTwoMinhaEquipe) {
            this.setState({ expandedMinhaEquipe: this.props.expandedPageTwoMinhaEquipe });
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.verifyScreen);
    }

    verifyScreen = () => {
        this.setState({ isSmallScreen: LayoutHelper.isSmallScreen() });    
    }

    getEquipeColaborador = async () => {
        this.setState({ loading: true, loadingAdditional: false, transform: 'translateY(0)', opacity: 1 });

        let time = new Date().getTime();
        var subordinados, processoAtual;
        this.setState({ time }, async () => {
            ({ subordinados, processoAtual } = await Sig.request('GET', 'config/colaborador/getEquipeAndProcessoAtual', { id_colaborador: this.props.userId, viewUserConfig: this.props.viewUserConfig}));
            
            if (time === this.state.time || !this.state.time) {
                this.setState({ subordinados: subordinados, loading: false, loadingAdditional: true, selectedOrder: 'alfabetica' }, () => {
                    this.orderColaboradores();
                    this.loadNotaSubordinados(subordinados, processoAtual);
                });
            }

            let { config } = await Sig.request('GET', 'inicio/getInicioConfigColaboradoresEquipe', { colaboradorAlvo: this.props.userId, viewUserConfig: this.props.viewUserConfig });
            
            if (config.length < 1) {
                this.props.sectedUserEquipe([]); 
            } else {
                let subordinadoSelected = [...this.state.subordinadoSelected];
                for (let i = 0; i < config.length; i++) {
                    if (this.state.subordinados.find(sub => sub.id === config[i].id_colaborador)) {
                        subordinadoSelected.push(config[i].id_colaborador);
                    }
                }
                this.setState({ subordinadoSelected }, () => { this.props.sectedUserEquipe(this.state.subordinadoSelected);});
            }
        });
    }

    loadNotaSubordinados(subordinados, processo){
        var count = 0;
        subordinados.forEach((element) => {

            Sig.request('GET', 'config/colaborador/getNotas', { id_colaborador: element.id, id_processoGmr: (processo ? processo.id : null) }).then((response) => {
                this.setState(previousState => ({ notas: [...previousState.notas, response] }));
                if(++count === subordinados.length){
                    this.setState({ loadingAdditional: false });
                }
            }).catch((error) => {
                let nota = {idColaborador: element.id, resultadoGD: null, resultadoGMR: null, resultadoMedia: null, 
                            processoGD: null, processoGMR: null, paineisGMR: null};
                this.setState(previousState => ({ notas: [...previousState.notas, nota] }));
                if(++count === subordinados.length){
                    this.setState({ loadingAdditional: false });
                }
            });
        });
    }

    orderColaboradores() {
        this.setState({ loading: true });
        let { subordinados } = this.state;

        if (subordinados.length > 1) {
            switch (this.state.selectedOrder) {
                case 'alfabetica':
                    subordinados = subordinados.sort((a, b) => a.nome.localeCompare(b.nome));
                    break;
                case 'maiorGD':
                    subordinados = subordinados.sort((a, b) => {
                        let aValue = parseFloat(this.state.notas.find(n => n.idColaborador == a.id)?.resultadoGD?.resultado);
                        let bValue = parseFloat(this.state.notas.find(n => n.idColaborador == b.id)?.resultadoGD?.resultado);

                        if ((isNaN(aValue) && aValue !== 0) && (isNaN(bValue) && bValue !== 0)) return 0; // ambos são nulos, então são iguais
                        if (isNaN(aValue) && aValue !== 0) return 1; // a é nulo, então b vem primeiro
                        if (isNaN(bValue) && bValue !== 0) return -1; // b é nulo, então a vem primeiro

                        return bValue - aValue; // se nenhum é nulo, classifique normalmente
                    });
                    break;
                case 'menorGD':
                    subordinados = subordinados.sort((a, b) => {
                        let aValue = parseFloat(this.state.notas.find(n => n.idColaborador == a.id)?.resultadoGD?.resultado);
                        let bValue = parseFloat(this.state.notas.find(n => n.idColaborador == b.id)?.resultadoGD?.resultado);
                    
                        if ((isNaN(aValue) && aValue !== 0) && (isNaN(bValue) && bValue !== 0)) return 0; // ambos são nulos, então são iguais
                        if (isNaN(aValue) && aValue !== 0) return 1; // a é nulo, então b vem primeiro
                        if (isNaN(bValue) && bValue !== 0) return -1; // b é nulo, então a vem primeiro
                    
                        return aValue - bValue; // se nenhum é nulo, classifique do menor para o maior
                    });
                    break;
                case 'maiorGMR':
                    subordinados = subordinados.sort((a, b) => {
                        let aValue = parseFloat(this.state.notas.find(n => n.idColaborador == a.id)?.resultadoGMR?.resultado);
                        let bValue = parseFloat(this.state.notas.find(n => n.idColaborador == b.id)?.resultadoGMR?.resultado);

                        if ((isNaN(aValue) && aValue !== 0) && (isNaN(bValue) && bValue !== 0)) return 0; // ambos são nulos, então são iguais
                        if (isNaN(aValue) && aValue !== 0) return 1; // a é nulo, então b vem primeiro
                        if (isNaN(bValue) && bValue !== 0) return -1; // b é nulo, então a vem primeiro

                        return bValue - aValue; // se nenhum é nulo, classifique normalmente
                    });                    
                    break;
                case 'menorGMR':
                    subordinados = subordinados.sort((a, b) => {
                        let aValue = parseFloat(this.state.notas.find(n => n.idColaborador == a.id)?.resultadoGMR?.resultado);
                        let bValue = parseFloat(this.state.notas.find(n => n.idColaborador == b.id)?.resultadoGMR?.resultado);

                        if ((isNaN(aValue) && aValue !== 0) && (isNaN(bValue) && bValue !== 0)) return 0; // ambos são nulos ou zero, então são iguais
                        if (isNaN(aValue) && aValue !== 0) return 1; // a é nulo ou zero, então b vem primeiro
                        if (isNaN(bValue) && bValue !== 0) return -1; // b é nulo ou zero, então a vem primeiro

                        return aValue - bValue; // se nenhum é nulo ou zero, classifique do menor para o maior
                    });
                    break;
                case 'maiorMedia':
                    subordinados = subordinados.sort((a, b) => {
                        let aValue = parseFloat(this.state.notas.find(n => n.idColaborador == a.id)?.resultadoMedia);
                        let bValue = parseFloat(this.state.notas.find(n => n.idColaborador == b.id)?.resultadoMedia);

                        if ((isNaN(aValue) && aValue !== 0) && (isNaN(bValue) && bValue !== 0)) return 0; // ambos são nulos, então são iguais
                        if (isNaN(aValue) && aValue !== 0) return 1; // a é nulo, então b vem primeiro
                        if (isNaN(bValue) && bValue !== 0) return -1; // b é nulo, então a vem primeiro

                        return bValue - aValue; // se nenhum é nulo, classifique normalmente
                    });                    
                    break;
                case 'menorMedia':
                    subordinados = subordinados.sort((a, b) => {
                        let aValue = parseFloat(this.state.notas.find(n => n.idColaborador == a.id)?.resultadoMedia);
                        let bValue = parseFloat(this.state.notas.find(n => n.idColaborador == b.id)?.resultadoMedia);
                    
                        if ((isNaN(aValue) && aValue !== 0) && (isNaN(bValue) && bValue !== 0)) return 0; // ambos são nulos, então são iguais
                        if (isNaN(aValue) && aValue !== 0) return 1; // a é nulo, então b vem primeiro
                        if (isNaN(bValue) && bValue !== 0) return -1; // b é nulo, então a vem primeiro
                    
                        return aValue - bValue; // se nenhum é nulo, classifique do menor para o maior
                    });
                    break;
            }
        }

        this.setState({ subordinados, loading: false });
    }

    handleOrderChange(key) {

        this.setState({ selectedOrder: key }, () => {
            this.orderColaboradores();
        });
        window.localStorage.setItem('equipe_order_option', key);
    }

    selectUser(id) {
        if (this.state.subordinadoSelected.includes(id)) {
            this.setState({ subordinadoSelected: this.state.subordinadoSelected.filter(sub => sub !== id) }, () => {this.props.sectedUserEquipe(this.state.subordinadoSelected)});
        } else {
            this.setState({ subordinadoSelected: [...this.state.subordinadoSelected, id] }, () => {this.props.sectedUserEquipe(this.state.subordinadoSelected)});
        }
    }

    renderTitle() {
        const portalTarget = document.getElementById('root');
        return (
            <div style={{...EssentialStyle.cardTitle, height: 40, borderBottom: `1px solid ${Colors.homePage.line}`}}>
                <span style={{ ...EssentialStyle.titleBoxHomePage, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    {this.state.expandedMinhaEquipe 
                        ? this.props.t('client_src_components_modules_inicio_charts_MinhaEquipe:title')
                        : this.props.t('client_src_components_modules_inicio_charts_MinhaEquipe:title_short')
                    }
                </span>
                <div style={{ ...EssentialStyle.rowFlexStart }}>
                    
                    <DefaultButton
                        leftIcon={this.state.blocked ? <FontAwesomeIcon icon={faEye} /> : <FontAwesomeIcon icon={faEyeSlash} />}
                        color={'transparent'}
                        textColor={'black'}
                        style={{ marginRight: 5, display: !this.state.expandedMinhaEquipe ? 'none' : ''}}
                        onClick={() => { this.setState({ blocked: !this.state.blocked }) }}
                        disabled={this.state.loading || this.state.loadingAdditional}
                    />
                    
                    <Dropdown style={{display: !this.state.expandedMinhaEquipe ? 'none' : ''}}>
                        <Dropdown.Toggle
                            onChange={() => {}}
                            value={this.state.selectedOrder} 
                            style={{ 
                                    backgroundColor: Colors.secondaryButton, 
                                    color: Colors.dark, 
                                    padding: '3px 6px',
                                    borderColor: 'black', 
                            }}
                            disabled={this.state.loading || this.state.loadingAdditional}
                        > 
                            <span style={{ marginRight: 5, fontSize: 12 }}>{this.state.orderTypes[this.state.selectedOrder]}</span>
                        </Dropdown.Toggle>
                        {ReactDOM.createPortal(<Dropdown.Menu> 
                            {Object.keys(this.state.orderTypes).map(key => (
                                <Dropdown.Item 
                                    key={`order-opt-${key}`} 
                                    onClick={() => { this.handleOrderChange(key) }} style={key === this.state.selectedOrder ? { backgroundColor: Colors.dark } : {}} 
                                    active={key === this.state.selectedOrder}
                                >
                                        <span style={{ fontSize: 12 }}>{this.state.orderTypes[key]}</span>
                                </Dropdown.Item>
                            ))}
                        </Dropdown.Menu>
                        , portalTarget)}
                    </Dropdown>

                </div>
            </div>
        )
    }

    getResultado(subordinado, tipo) {
        if (tipo === GMR) return subordinado.resultadoGMR;
        if (tipo === GD) return subordinado.resultadoGD;
        return subordinado.resultadoMedia;
    }

    getProcesso(subordinado, tipo) {
        if (tipo === GMR) return subordinado.processoGMR;
        if (tipo === GD) return subordinado.processoGD;
        return null;
    }

    getValor(resultado, tipo) {
        if (resultado && resultado.resultado != null) {
            return resultado.resultado.toString().slice(0, 4) + '%';
        }
        if (tipo === MEDIA && resultado) {
            return resultado.toString().slice(0, 4) + '%';
        }
        return 'N/A';
    }

    getCor(resultado) {
        let cor = resultado?.cor || Colors.homePage.darkGrey;
        return this.state.blocked ? SessionHelper.getColor() : cor;
    }

    getTooltip(tipo, nomeProcesso) {
        const title = tipo === GD 
            ? this.props.t('client_src_components_modules_inicio_charts_MinhaEquipe:tooltip.gd_title')
            : tipo === GMR 
                ? this.props.t('client_src_components_modules_inicio_charts_MinhaEquipe:tooltip.gmr_title')
                : this.props.t('client_src_components_modules_inicio_charts_MinhaEquipe:tooltip.media_title');
        
        const processo = nomeProcesso === 'N/A' 
            ? this.props.t('client_src_components_modules_inicio_charts_MinhaEquipe:tooltip.not_applicable')
            : nomeProcesso;
        
        const extra = tipo !== MEDIA 
            ? `${this.props.t('client_src_components_modules_inicio_charts_MinhaEquipe:tooltip.process_prefix')} ${processo}`
            : this.props.t('client_src_components_modules_inicio_charts_MinhaEquipe:tooltip.media_formula');

        return (
            <div style={{ ...EssentialStyle.columnCenter }}>
                <div><strong>{title}</strong></div>
                <div>{extra}</div>
            </div>
        )
    }

    getTitle(tipo) {
        return tipo === GD 
            ? this.props.t('client_src_components_modules_inicio_charts_MinhaEquipe:box_titles.gd')
            : tipo === GMR 
                ? this.props.t('client_src_components_modules_inicio_charts_MinhaEquipe:box_titles.gmr')
                : this.props.t('client_src_components_modules_inicio_charts_MinhaEquipe:box_titles.media');
    }

    renderBox(subordinado, tipo) {
        let width = 45;
        let height = 35;

        let resultado = this.getResultado(subordinado, tipo);
        let processo = this.getProcesso(subordinado, tipo);
        let valor = this.getValor(resultado, tipo);
        let cor = this.getCor(resultado);
        let nomeProcesso = processo ? processo.nome : 'N/A';
        let tooltip = this.getTooltip(tipo, nomeProcesso);
        let title = this.getTitle(tipo);

        return (
            <CustomTooltip tooltip={tooltip} placement="top">
                <div 
                    key={`box-${subordinado.id}-${tipo}`}
                    className={ valor === 'N/A' || tipo === MEDIA ? '' : 'boxMinhaEquipe'}
                    style={{
                        ...EssentialStyle.columnCenter, 
                        width, 
                        height, 
                        borderRadius: 5, 
                        background: cor, 
                        color: 'white', 
                        margin: '1px 1px',
                        cursor: valor === 'N/A' || tipo === MEDIA ? 'default' : 'pointer',
                    }}
                    onClick={(event) => {
                        event.stopPropagation();
                        if (valor !== 'N/A') {
                            const url = tipo === GD 
                                ? `/relatorioCompetencias/individual/${processo.id}?id_colaborador=${subordinado.colaborador.id}`
                                : `/acompanhamentoResultados/gerenciar/${subordinado.paineisGMR[0].id}?processo=${processo.id}`;

                            window.parent.postMessage({ type: '_blank', url }, '*');
                        }
                    }}
                >
                    <span style={{fontSize: 10, fontWeight: '300'}}>{title}</span>
                    <span style={{fontSize: 12, fontWeight: 'bold'}}>{this.state.blocked ? <FontAwesomeIcon icon={faEyeSlash}/> : valor}</span>
                </div>
            </CustomTooltip>
        );
    }

    renderSubordinado(subordinado) {
        return (
            <div 
                key={`sub-${subordinado.id}`} 
                style={{
                    ...(this.state.expandedMinhaEquipe ? EssentialStyle.rowSpaceBetween : EssentialStyle.rowFlexCenter), 
                    width: this.state.expandedMinhaEquipe ? 'calc(100% - 20px)' : '45px', 
                    marginTop: 5,
                    borderRadius: 5,
                    padding: '5px 3px',
                    margin: this.state.expandedMinhaEquipe ? '5px 10px 0px 10px' : '5px 0px 0px 10px',
                    cursor: 'pointer',
                    backgroundColor: this.state.subordinadoSelected.includes(subordinado.id) ? Colors.homePage.lightGrey : 'transparent',
                    boxShadow: this.state.hoverSubordinado === subordinado.id ? `0px 0px 5px 1px ${Colors.homePage.lightGrey}` : 'none',
                }}
                onClick={() => { this.selectUser(subordinado.id) }}
                onMouseEnter={() => { this.setState({ hoverSubordinado: subordinado.id }) }}
                onMouseLeave={() => { this.setState({ hoverSubordinado: null }) }}
            >
                <div style={{...EssentialStyle.rowFlexStart, width: this.state.expandedMinhaEquipe ? 'calc(100% - 150px)' : '100%'}}>
                    <UserAvatar key={`avatar-sub-${subordinado.id}`} size={40} id={subordinado.id} showName={this.state.expandedMinhaEquipe} showNameAdaptative placement={"top"}/>
                </div>

                <div style={{...EssentialStyle.rowFlexEnd, height: '100%', width: 145, flexWrap: 'wrap', display: this.state.expandedMinhaEquipe ? 'flex' : 'none'}}>
                    { this.state.loadingAdditional ? this.renderPerformanceLoading() : this.renderBoxes(subordinado) }
                </div>
            </div>
        )
    }

    renderBoxes(colaborador) {
        var completeColaborador = {colaborador: colaborador, ...(this.state.notas.find(n => n.idColaborador == colaborador.id))};

        return (
            <>
                {this.renderBox(completeColaborador, 'gd')}
                {this.renderBox(completeColaborador, 'gmr')}
                {this.renderBox(completeColaborador, 'media')}
            </>
        );
    }

    renderSubordinados() {
        return (
            this.state.subordinados.length > 0 ? 
            <div style={{width: 'calc(100% + 20px)', margin: '0px -10px', height: 'calc(100% - 45px)', overflow: 'auto' }}>
                {this.state.subordinados.map((subordinado) => (
                    this.renderSubordinado(subordinado)
                ))}
            </div> 
            : <div style={{ ...EssentialStyle.columnCenter, width: '100%', margin: '0px -10px', height: 'calc(100% - 45px)', overflow: 'auto' }}>
                <img
                    src={`${gateway}/img/undraw/undraw_team_up.svg`}
                    alt={this.props.t('client_src_components_modules_inicio_charts_MinhaEquipe:alt_no_subordinates')}
                    style={{
                        height: '60%',
                    }}
                />
                <span style={{ ...EssentialStyle.titleBoxHomePage }}>
                    {this.props.t('client_src_components_modules_inicio_charts_MinhaEquipe:no_subordinates')}
                </span>
            </div> 
        )
    }

    renderLoadingPlaceholder() {
        let size = 50;

        return (
            <div>
                <div style={{
                    height: size, 
                    width: '100%', 
                    borderRadius: 5, 
                    background: `linear-gradient(270deg, ${Colors.homePage.lightGrey}, ${Colors.homePage.extraLightGrey}, ${Colors.homePage.lightGrey})`,
                    backgroundSize: '200% 200%',
                    animation: 'gradient 2s ease infinite',
                }}>
                </div>

                <style jsx>{`
                @keyframes gradient {
                    0% {background-position: 0% 50%;}
                    50% {background-position: 100% 50%;}
                    100% {background-position: 0% 50%;}
                }
                `}</style>
            </div>
        );
    }

    renderLoading() {
        return (
            <div style={{...EssentialStyle.columnStart, width: '100%'}}>

                {Array.from({ length: this.props.numberSubordinados }).map((elem, index) => (
                    <div key={`plhdr-${index}`} style={{ marginTop: 5, width: '100%' }}>
                        {this.renderLoadingPlaceholder()}
                    </div>
                ))}
            </div>
        );
    }

    renderContent() {
        
        return (
            <div style={{
                ...EssentialStyle.columnStart,
                width: '100%',
                height: '100%',
            }}>
                { this.renderTitle() }
                { this.state.loading ? this.renderLoading() : this.renderSubordinados() }
            </div>
        );
    }

    render() {
        return (
            <div style={{ ...EssentialStyle.rowFlexStart, width: '100%', height: '100%' }}>
                <div style={{
                    ...EssentialStyle.rowSpaceBetween,
                    ...EssentialStyle.card,
                    transform: this.state.transform,
                    opacity: this.state.opacity,
                    transition: 'opacity .3s ease-in-out, transform .6s ease-in-out',
                    width: this.state.isSmallScreen ? '100%' : 'calc(100% - 20px)',
                    height: '100%',
                    padding: '10px 10px 5px 10px',
                }}>
                    {this.renderContent()}
                </div>

                <div style={{ ...EssentialStyle.columnCenter, width: this.state.isSmallScreen ? '0px' : '20px', height: '100%' }}>
                    <FontAwesomeIcon
                        className={"hoverScale"}
                        icon={this.state.expandedMinhaEquipe ? faChevronLeft : faChevronRight} 
                        style={{
                            color: SessionHelper.getColor(),
                            cursor: 'pointer',
                        }}
                        onClick={() => { this.props.expandCallbackPageTwoMinhaEquipe(!this.state.expandedMinhaEquipe) }}
                    />
                </div>
            </div>
        )
    }

    renderPerformanceLoading() {
        let height = 35, width = 45;
        let fontSize = this.props.font ? this.props.font : 15;
        let numberOfBoxes = 3;

        return (
            [...Array(numberOfBoxes)].map((elem, index) => {
                return (
                    <div key={`performance-loading-${index}`} style={{ height: height, width: width, margin: "0px 1px", borderRadius: "5px", backgroundColor: SessionHelper.getColor(), display: 'flex', alignItems: 'center', justifyContent: 'center', color: 'white', fontWeight: 'bold', fontSize: fontSize }}>
                        <DefaultLoader size={fontSize} color={'white'} />
                    </div>);
            })
        );
    }
}

export default withTranslation()(MinhaEquipe);