import React from "react";

import './ConfigEscala.css';
import IdiomaHelper from "../../../../../../helper/IdiomaHelper";
import DataHelper from "../../../../../../helper/DataHelper";
import Select from "react-select";
import { Form, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusSquare, faPaperPlane, faLock } from "@fortawesome/free-solid-svg-icons";
import Colors from "../../../../../../constants/Colors";
import DefaultButton from "../../../../../tools/DefaultButton";
import EssentialStyle from "../../../../../../style/EssentialStyle";
import { toast } from "react-toastify";
import CustomTooltip from "../../../../../tools/CustomTooltip";

export default class ConfigEscala extends React.Component {

    state = {
        defaultConceito: {
            descricao: IdiomaHelper.getIdiomas().map(idioma => ({ id_idioma: idioma.id, conceito: '' })),
            valor: 0,
        },
        conceito: null,
        oldEscala: null,
        conceitosDefaultEstrela: [
            { valor: 0, descricao: [], cor: '' },
            { valor: 25, descricao: [], cor: '' },
            { valor: 50, descricao: [], cor: '' },
            { valor: 75, descricao: [], cor: '' },
            { valor: 100, descricao: [], cor: '' },
        ],
    }

    componentDidUpdate(prevProps) {
        if(prevProps.editConceito !== this.props.editConceito && this.props.editConceito) {
            this.setState({ conceito: this.props.editConceito });
        }
    }

    message = (lang) => {
        return IdiomaHelper.getStruct({
            'pt_br': { 
                title: 'Escala padrão do questionário',
                configestrela: 'Quantidade de estrelas',
                confignumerica: 'Intervalo numérico',
                configconceito: 'Conceitos',
                selectTipoEscala: 'Selecione um tipo de escala',
                estrelas: 'Escala de Estrelas',
                numericas: 'Escala Numérica',
                conceito: 'Escala de Conceitos',
                addConceito: 'Adicionar Conceito',
                addEstrela: 'Adicionar Estrela',
                addConceitoSigla: 'Digite o conceito em português...',
                addConceitoValue: 'Valor do Conceito (0% - 100%)',
                addEstrelaValue: 'Valor da Estrela (0 - 100%)',
                errorValue: 'O valor deve estar entre 0 e 100',
                errorValueEqual: 'Altere o valor do conceito, pois já existe um conceito com esse valor',
                errorDescricao: 'Digite o conceito para ao menos um idioma',
                range: 'As Notas serão de x% até y%',
                rangeEstrela: 'Escala de x% Estrelas',
                blocked: 'Edição bloqueada',
                blockedInfo: 'A edição deste questionário está bloqueado, pois já foi respondido por algum usuário ou você não permissão para editar.',
                erroQtdEstrelas: 'A quantidade de estrelas deve ser de no Máximo 10',
            },
            'en': { 
                title: 'Questionnaire standard scale',
                configestrela: 'Amount of stars',
                confignumerica: 'Numeric range',
                configconceito: 'Concepts',
                selectTipoEscala: 'Select a scale type',
                estrelas: 'Star Scale',
                numericas: 'Numeric Scale',
                conceito: 'Concept Scale',
                addConceito: 'Add Concept',
                addEstrela: 'Add Star',
                addConceitoSigla: 'Type the concept in english...',
                addConceitoValue: 'Concept Value (0% - 100%)',
                addEstrelaValue: 'Star Value (0 - 100%)',
                errorValue: 'The value must be between 0 and 100',
                errorValueEqual: 'Change the concept value, as there is already a concept with that value',
                errorDescricao: 'Type the concept for at least one language',
                range: 'Notes will be from x% to y%',
                rangeEstrela: 'Scale of x% Stars',
                blocked: 'Blocked edition',
                blockedInfo: 'Editing this questionnaire is blocked, as it has already been answered by some user or you do not have permission to edit.',
                erroQtdEstrelas: 'The number of stars must be at most 10',
            },
            'es': { 
                title: 'Escala estándar del cuestionario',
                configestrela: 'Cantidad de estrellas',
                confignumerica: 'Intervalo numérico',
                configconceito: 'Conceptos',
                selectTipoEscala: 'Seleccione un tipo de escala',
                estrelas: 'Escala de Estrellas',
                numericas: 'Escala Numérica',
                conceito: 'Escala de Conceptos',
                addConceito: 'Agregar Concepto',
                addEstrela: 'Agregar Estrella',
                addConceitoSigla: 'Escriba el concepto en español...',
                addConceitoValue: 'Valor del Concepto (0% - 100%)',
                addEstrelaValue: 'Valor de la Estrella (0 - 100%)',
                errorValue: 'El valor debe estar entre 0 y 100',
                errorValueEqual: 'Cambie el valor del concepto, ya que ya existe un concepto con ese valor',
                errorDescricao: 'Escriba el concepto para al menos un idioma',
                range: 'Las Notas serán de x% hasta y%',
                rangeEstrela: 'Escala de x% Estrellas',
                blocked: 'Edición bloqueada',
                blockedInfo: 'La edición de este cuestionario está bloqueada, ya que ya ha sido respondida por algún usuario o no tiene permiso para editar.',
                erroQtdEstrelas: 'La cantidad de estrellas debe ser de un máximo de 10',
            },
        })[lang];
    }

    renderIsBlocked() {

        if (!this.props.blocked) return (null);
        return (
            <CustomTooltip tooltip={this.message(this.props.lang).blockedInfo} position="top">
                <div className="blocked">
                    <FontAwesomeIcon icon={faLock} />
                    {this.message(this.props.lang).blocked}
                </div>
            </CustomTooltip>
        )
    }

    renderConfigEscala() {

        const configEstrela = () => {
            return (
                <Form className="w-100 config-escala" onSubmit={(e) => {e.preventDefault();}}>
                    <Row>
                        <Form.Group as={Col}>
                            {this.renderIsBlocked()}
                        </Form.Group>
                    </Row> 

                    <Row>
                        <Form.Group as={Col}>
                            <Form.Label className="form-label-xsm space-between">
                                <div className="label">
                                    <span className="label-text">{this.message(this.props.lang)[`config${this.props.escala.tipo}`]}</span>
                                    <div className="label-line"></div>
                                </div>
                            </Form.Label>
                            <DefaultButton
                                leftIcon={ <FontAwesomeIcon icon={faPlusSquare}/>}
                                title={this.message(this.props.lang).addEstrela}
                                color={Colors.success}
                                style={{display: 'flex', alignItems: 'center'}}
                                onClick={() => {this.setState({conceito: this.state.defaultConceito})}}
                                disabled={this.props.blocked}
                            />
                        </Form.Group>
                    </Row>
                              
                    <Row>
                        <Form.Group as={Col}>
                            <Form.Label className="form-label-xsm space-between" style={{ fontSize: '1rem' }}>
                                {this.message(this.props.lang).rangeEstrela.replace('x%', this.props.escala?.conceitos?.length || 0)}
                            </Form.Label>
                        </Form.Group>
                    </Row>

                    {this.state.conceito && !this.props.blocked &&
                            <>
                                <Form.Group as={Col} xs={12} md={12}>
                                    <Form.Label className="form-label-xsm space-between">
                                        {this.message(this.props.lang).addEstrelaValue}
                                    </Form.Label>
                                    <div style={{...EssentialStyle.rowSpaceBetween}}>
                                        <Form.Control 
                                            type="number"
                                            value={this.state.conceito.valor}
                                            placeholder={'xx% ...'}
                                            min="0"
                                            max="100"
                                            step="1"
                                            onChange={(event) => { 
                                                const value = parseInt(event.target.value, 10);
                                                if (!isNaN(value) && value >= 0 && value <= 100 || event.target.value == 0) {
                                                    this.setState({ conceito: { ...this.state.conceito, valor: value } });
                                                }
                                            }}
                                            style={{width: '50%'}}
                                        />

                                        <DefaultButton
                                            leftIcon={ <FontAwesomeIcon icon={faPaperPlane}/>}
                                            color={Colors.success}
                                            style={{marginLeft: '1rem'}}
                                            onClick={() => {
                                                let id = this.state.conceito?.id || null;
                                                let valor = Number(this.state.conceito.valor);

                                                if (!(valor >= 0) || !(valor <= 100) || isNaN(valor)) {
                                                    return toast.error(this.message(this.props.lang).errorValue);
                                                }

                                                var conceitos = this.props.escala?.conceitos || [];

                                                // verificar se já não existe um conceito com esse valor
                                                if (conceitos.some(conceito => conceito.valor == valor && conceito.id !== null && conceito.id !== id)) {
                                                    return toast.error(this.message(this.props.lang).errorValueEqual);
                                                }

                                                if (id) {
                                                    var conceitos = conceitos.filter(conceito => (conceito?.id && conceito.id !== id) || !conceito?.id);
                                                }

                                                if(conceitos.length == 10) {
                                                    this.setState({ conceito: null });
                                                    return toast.error(this.message(this.props.lang).erroQtdEstrelas);
                                                }

                                                conceitos.push(this.state.conceito);

                                                this.props.callback({...this.props.escala, conceitos: conceitos});

                                                this.setState({ conceito: null });
                                            }}
                                        />
                                    </div>
                                </Form.Group>
                            </>
                        }
                </Form>
            )
        }

        const configNumerica = () => {
            return (
                <Form className="w-100 config-escala" onSubmit={(e) => {e.preventDefault();}}>
                    <Row>
                        <Form.Group as={Col}>
                            {this.renderIsBlocked()}
                        </Form.Group>
                    </Row> 

                    <Row>
                        <Form.Group as={Col}>
                            <Form.Label className="form-label-xsm space-between">
                                <div className="label">
                                    <span className="label-text">{this.message(this.props.lang)[`config${this.props.escala.tipo}`]}</span>
                                    <div className="label-line"></div>
                                </div>
                            </Form.Label>
                            <div className="range-container">
                                <Form.Control 
                                    style={{ height: 38, marginBottom: '1rem' }} 
                                    type="range" 
                                    min="0"
                                    max="100" 
                                    value={this.props.escala.max} 
                                    onChange={(event) => { 
                                        this.props.callback({ ...this.props.escala, max: event.target.value});
                                    }}
                                    disabled={this.props.blocked}
                                />
                            </div>
                        </Form.Group>
                    </Row>
                    
                    <Row>
                        <Form.Group as={Col}>
                            <Form.Label className="form-label-xsm space-between" style={{ fontSize: '1rem' }}>
                                {this.message(this.props.lang).range.replace('x%', 0).replace('y%', this.props.escala.max)}
                            </Form.Label>                            
                        </Form.Group>
                    </Row>
                </Form>
            )
        }

        const configConceito = () => {
            return (
                <Form className="w-100 config-escala" onSubmit={(e) => {e.preventDefault();}}>
                    <Row>
                        <Form.Group as={Col}>
                            {this.renderIsBlocked()}
                        </Form.Group>
                    </Row> 
                    <Row>
                        <Form.Group className='mb-2' as={Col} xs={12} md={12}>
                            <Form.Label className="form-label-xsm space-between">
                                <div className="label">
                                    <span className="label-text">{this.message(this.props.lang)[`config${this.props.escala.tipo}`]}</span>
                                    <div className="label-line"></div>
                                </div>
                            </Form.Label>
                            <DefaultButton
                                leftIcon={ <FontAwesomeIcon icon={faPlusSquare}/>}
                                title={this.message(this.props.lang).addConceito}
                                color={Colors.success}
                                style={{display: 'flex', alignItems: 'center'}}
                                onClick={() => {this.setState({conceito: this.state.defaultConceito})}}
                                disabled={this.props.blocked}
                            />
                        </Form.Group>

                        {this.state.conceito && !this.props.blocked &&
                            <>
                                {IdiomaHelper.getIdiomas().map((idioma, index) => {
                                    return (
                                        <Form.Group className='mb-2' as={Col} xs={12} md={12} key={`idioma-list-${idioma.sigla}`}>
                                            <Form.Label className="form-label-xsm space-between">
                                                {idioma.nome}
                                            </Form.Label>
                                            <div>
                                                <Form.Control 
                                                    type="text"
                                                    value={this.state.conceito.descricao.find(conceito => conceito.id_idioma === idioma.id).conceito}
                                                    placeholder={this.message(idioma.sigla).addConceitoSigla}
                                                    onChange={(event) => { 
                                                        const newDescricao = this.state.conceito.descricao.map(conceito => {
                                                            if (conceito.id_idioma === idioma.id) {
                                                                return { ...conceito, conceito: event.target.value };
                                                            }
                                                            return conceito;
                                                        });
                                                
                                                        this.setState(prevState => ({
                                                            conceito: {
                                                                ...prevState.conceito,
                                                                descricao: newDescricao
                                                            }
                                                        }));
                                                     }}
                                                />
                                            </div>
                                        </Form.Group>
                                    )
                                })}

                                <Form.Group as={Col} xs={12} md={12}>
                                    <Form.Label className="form-label-xsm space-between">
                                        {this.message(this.props.lang).addConceitoValue}
                                    </Form.Label>
                                    <div style={{...EssentialStyle.rowSpaceBetween}}>
                                        <Form.Control 
                                            type="number"
                                            value={this.state.conceito.valor}
                                            placeholder={'xx% ...'}
                                            min="0"
                                            max="100"
                                            step="1"
                                            onChange={(event) => { 
                                                const value = parseInt(event.target.value, 10);
                                                if (!isNaN(value) && value >= 0 && value <= 100 || event.target.value == 0) {
                                                    this.setState({ conceito: { ...this.state.conceito, valor: value } });
                                                }
                                            }}
                                            style={{width: '50%'}}
                                        />

                                        <DefaultButton
                                            leftIcon={ <FontAwesomeIcon icon={faPaperPlane}/>}
                                            color={Colors.success}
                                            style={{marginLeft: '1rem'}}
                                            onClick={() => {
                                                let id = this.state.conceito?.id || null;
                                                let valor = Number(this.state.conceito.valor);

                                                if (!(valor >= 0) || !(valor <= 100) || isNaN(valor)) {
                                                    return toast.error(this.message(this.props.lang).errorValue);
                                                }

                                                if (this.state.conceito.descricao.every(conceito => conceito.conceito === '')) {
                                                    return toast.error(this.message(this.props.lang).errorDescricao);
                                                }

                                                var conceitos = this.props.escala?.conceitos || [];

                                                // verificar se já não existe um conceito com esse valor
                                                if (conceitos.some(conceito => conceito.valor == valor && conceito.id !== null && conceito.id !== id)) {
                                                    return toast.error(this.message(this.props.lang).errorValueEqual);
                                                }

                                                if (id) {
                                                    var conceitos = conceitos.filter(conceito => (conceito?.id && conceito.id !== id) || !conceito?.id);
                                                }
                                                conceitos.push(this.state.conceito);

                                                this.props.callback({...this.props.escala, conceitos: conceitos, min: 0, max: 100});

                                                this.setState({ conceito: null });
                                            }}
                                        />
                                    </div>
                                </Form.Group>
                            </>
                        }
                    </Row>
                </Form>
            )
        }

        
        switch(this.props.escala.tipo) {
            case 'estrela':
                return configEstrela();
            case 'numerica':
                return configNumerica();
            case 'conceito':
                return configConceito();
        }
    }

    render() {

        let escalas = [
            { value: 'estrela', label: this.message(this.props.lang).estrelas },
            { value: 'numerica', label: this.message(this.props.lang).numericas },
            { value: 'conceito', label: this.message(this.props.lang).conceito },
        ]

        return (
            <div className="ConfigEscala">
                <Select
                    styles={{ 
                        control: (base) => ({
                            ...base,
                            width: '100%',
                            minWidth: '100%',
                            marginBottom: '1rem'
                        }),
                        menu: (base) => ({
                            ...base,
                            width: '100%'
                        }),
                        container: (base) => ({
                            ...base,
                            width: '100%'
                        }),
                     }}
                    options={escalas}
                    value={escalas.find(option => option.value === this.props.escala.tipo) || null}
                    onChange={(valueSelected) => {

                        let max;
                        let min;
                        let conceitos = [];
                        if (valueSelected.value === 'estrela') {
                            min = 0;
                            max = 100;
                            conceitos = this.state.conceitosDefaultEstrela;
                            
                        } else if (valueSelected.value === 'numerica') {
                            min = 0;
                            max = 10;

                        } else {
                            min = 0;
                            max = 100;

                        }

                        this.setState({ oldEscala: this.props.escala });

                        if(this.state.oldEscala && this.state.oldEscala.tipo == valueSelected.value) {
                            this.props.callback(this.state.oldEscala);
                        } else {
                            this.props.callback({ ...this.props.escala, tipo: valueSelected.value, min, max, conceitos });
                        }
                    }}
                    placeholder={this.message(this.props.lang).selectTipoEscala}
                    noOptionsMessage={DataHelper.getSelectEmptyMessage}
                    isSearchable
                    menuPortalTarget={document.body}
                    isDisabled={this.props.blocked}
                />
                
                {this.renderConfigEscala()}
            </div>
        )
    }
}