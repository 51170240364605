import React from "react";

import Colors from "../../../../constants/Colors";
import DefaultButton from "../../../../components/tools/DefaultButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusSquare } from "@fortawesome/free-solid-svg-icons";

import ListProcessos from "./ListProcessos/ListProcessos";
import DiagnosticoHelper from "../../../../helper/diagnostico/DiagnosticoHelper";
import DefaultTableSearch from "../../../tools/DefaultTableSearch";
import IdiomaHelper from "../../../../helper/IdiomaHelper";
import DataHelper from "../../../../helper/DataHelper";
import Select from 'react-select';

import './Processo.css';

const searchableFields = ['nome'];

export default class Processo extends React.Component {

    state = {
        lang: IdiomaHelper.getIdioma(),
        loadingProcessos: true,
        processos: [],
        countProcessos: 0,
        canEdit: false,
        canView: false,
    }

    componentDidMount() {
        this.loadProcessos();
    }

    loadProcessos = async () => {
        
        this.setState({ loadingProcessos: true });

        let { processos, canEdit, canView } = await DiagnosticoHelper.getProcessos(1);

        this.setState({ processos, countProcessos: processos?.lenght, canEdit, canView, loadingProcessos: false });
    }

    renderHeader() {

        const message = IdiomaHelper.getStruct({
            'pt_br': { buttom: 'Adicionar Processo' },
            'en': { buttom: 'Add Process' },
            'es': { buttom: 'Agregar Proceso' }
        });

        return (
            <div className="processo-header" style={{boxShadow: Colors.boxShadow}}>
               <div className="header-left">
                    <DefaultButton
                        title={message[this.state.lang].buttom}
                        leftIcon={<FontAwesomeIcon icon={faPlusSquare} />}
                        color={Colors.success}
                        onClick={() => { this.props.onShowWizard(true); }}
                        disabled={!this.state.canEdit}
                    />
               </div>

                <div className="header-right">
                    <DefaultTableSearch
                        fields={searchableFields} 
                        loading={this.state.loadingProcessos}
                        searchObjects={true} 
                        data={this.state.processos} 
                        onDataUpdate={(data) => { this.setState({ processos: data }) }} 
                        onEmpty={() => { this.loadProcessos() }}
                    />
                    <DefaultButton
                        style={{ width: 38, height: 38 }}
                        fontSize={this.props.isSmallScreen ? 14 : "unset"}
                        color={Colors.secondaryButton}
                        textColor={Colors.dark}
                        link={'https://advis.freshdesk.com/support/solutions/folders/63000238312'}
                    />
                </div>
            </div>
        )
    }

    renderListProcesso() {
        return (
            <div className="processo-list">
                <ListProcessos
                    loadingProcessos={this.state.loadingProcessos}
                    processos={this.state.processos}
                    countProcessos={this.state.countProcessos}
                    isSmallScreen={this.props.isSmallScreen} 
                    lang={this.state.lang}
                    onEdit={(idProcesso) => {this.props.onShowWizard(idProcesso)}}
                    onDelete={() => {window.parent.postMessage({ type: 'navigate', url: `/diagnostico` }, '*');}}
                    canEdit={this.state.canEdit}
                    canView={this.state.canView}
                />
            </div>
        )
    }

    render() {
        return (
            <div className="Processo">
                {this.renderHeader()}
                {this.renderListProcesso()}
            </div>
        )
    }
}