import React, { Component } from 'react';
import './TableImporterPreview.css';

import * as XLSX from 'xlsx';
import { withTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileAlt, faCheckCircle, faCircleExclamation, faTimesCircle, faDownload, faUpload } from '@fortawesome/free-solid-svg-icons'
import { confirmAlert } from 'react-confirm-alert';
import CustomConfirm from "../CustomConfirm";

import DefaultButton from '../DefaultButton';
import Colors from '../../../constants/Colors';
import EssentialStyle from '../../../style/EssentialStyle';
import DefaultLoader from '../DefaultLoader';
import SessionHelper from '../../../helper/SessionHelper';
import Constants from "../../../constants/Api";
import DataHelper from '../../../helper/DataHelper';
import IdiomaHelper from '../../../helper/IdiomaHelper';
import moment from 'moment';

const gateway = Constants.getSigEndPoint();

class TableImporterPreview extends Component {
    state = {
        loading: true,
        fileModel: this.props.fileModel,
        maxRows: this.props.maxRows || 50,
        maxColumns: this.props.maxColumns || 25
    };

    componentDidUpdate(prevProps) {
        if (prevProps.fileModel !== this.props.fileModel) {
            this.setState({ fileModel: this.props.fileModel });
        }
    }

    async componentDidMount() {
        await this.props.i18n.loadNamespaces(['client_src_components_tools_TableImporterPreview_TableImporterPreview']);
        this.setState({ loading: false });
    }

    renderEmpty() {
        return (
            <div style={{ ...EssentialStyle.columnCenter, height: '100%' }}>
                <img
                    src={`${gateway}/img/undraw/undraw_spreadsheets.svg`}
                    alt={this.props.t('client_src_components_tools_TableImporterPreview_TableImporterPreview:imgAlt')}
                    style={{
                        width: 350,
                    }}
                />
                <div style={{ ...EssentialStyle.columnCenter, marginTop: 15 }}>
                    {this.props.loading ?
                        <div style={{ ...EssentialStyle.rowFlexStart, gap: 8 }}>
                            <DefaultLoader size={20} />
                            {this.props.t('client_src_components_tools_TableImporterPreview_TableImporterPreview:loading')}
                        </div> :
                        <p>{this.props.t('client_src_components_tools_TableImporterPreview_TableImporterPreview:preview_empty')}</p>
                    }
                </div>
            </div>
        );
    }

    renderTable = () => {
        if (this.props.loading) return this.renderEmpty();

        let data = this.state.fileModel;

        if (!data || data.length === 0) return this.renderEmpty();

        let tableData = [];

        if (data.length > 0) {
            if (!this.props.justPreview) {
                const headers = Object.keys(data[0]);
                tableData.push(headers);

                data.forEach(row => {
                    const rowData = headers.map(header => row[header] || '');
                    tableData.push(rowData);
                });
            } else {
                tableData = data;
            }
        }

        let dataPreview = tableData;
        let tooBig = tableData.length > this.state.maxRows || tableData[0].length > this.state.maxColumns;

        if (tooBig) {
            dataPreview = tableData.slice(0, this.state.maxRows);
        } else {
            dataPreview = tableData;
        }

        if (this.props.justPreview) {
            return this.renderTableImporter(dataPreview);
        }

        return (
            <div className="table-importer-container">
                <div style={{ ...EssentialStyle.columnStart, width: '100%', marginTop: 10 }}>
                    {this.renderDownloadModel()}
                </div>
                {this.renderTableImporter(dataPreview)}
                <div style={{ ...EssentialStyle.columnStart, width: '100%', marginTop: 10 }}>
                    {tooBig ? <p>{this.props.t('client_src_components_tools_TableImporterPreview_TableImporterPreview:tooBig')}</p> : null}
                    {this.renderDownloadModel()}
                </div>
            </div>
        );
    }

    detectCellType = (value) => {
        // Handle null/undefined/empty
        if (value === null || value === undefined || value === '') {
            return 'empty';
        }

        // Boolean check
        if (typeof value === 'boolean') {
            return 'boolean';
        }

        // European format number check (1.234,56 or 0,00)
        if (typeof value === 'string') {
            // Check for European format numbers (comma as decimal separator)
            const europeanNumberRegex = /^-?(?:\d+|\d{1,3}(?:\.\d{3})+)(?:,\d+)?$/;
            if (europeanNumberRegex.test(value)) {
                // Convert European format to JS format for processing
                const jsNumber = Number(value.replace(/\./g, '').replace(',', '.'));
                if (!isNaN(jsNumber)) {
                    return 'number';
                }
            }

            // Date check for strings
            const dateRegex = /^(\d{4}-\d{2}-\d{2}|\d{1,2}\/\d{1,2}\/\d{2,4})$/;
            if (dateRegex.test(value)) {
                const dateObj = new Date(value);
                if (!isNaN(dateObj.getTime()) || moment(value, 'DD/MM/YYYY').isValid()) {
                    return 'date';
                }
            }
        }

        // Standard number check (JS format)
        if (typeof value === 'number' || (typeof value === 'string' && !isNaN(value) && !isNaN(parseFloat(value)))) {
            // Excel date check
            if (typeof value === 'number' && value > 1000 && value < 2958466) {
                const excelEpoch = new Date(1900, 0, 0);
                const possibleDate = new Date(excelEpoch.getTime() + (value - 1) * 86400000);

                let currentYear = new Date().getFullYear();

                if (possibleDate.getFullYear() > (currentYear - 10) && possibleDate.getFullYear() < (currentYear + 10)) {
                    return 'excel-date';
                }
            }
            return 'number';
        }

        // Default: text
        return 'text';
    }

    formatCellValue = (value, type) => {
        switch (type) {
            case 'excel-date':
                const excelEpoch = new Date(1900, 0, 0);
                const date = new Date(excelEpoch.getTime() + (value - 1) * 86400000);
                return date.toLocaleDateString();

            case 'date':
                // Try to parse the date with multiple possible formats
                const momentDate = moment(value, ['YYYY-MM-DD', 'DD/MM/YYYY', 'MM/DD/YYYY']);

                // Check if the date is valid before formatting
                if (momentDate.isValid()) {
                    return momentDate.format('DD/MM/YYYY');
                }

                // If moment parsing fails, try native Date as fallback
                const nativeDate = new Date(value);
                if (!isNaN(nativeDate.getTime())) {
                    return moment(nativeDate).format('DD/MM/YYYY');
                }

                // If all parsing fails, return the original value
                return value;

            case 'number':
                const num = typeof value === 'number' ? value : parseFloat(value);
                const isInteger = Number.isInteger(num);

                return num.toLocaleString(IdiomaHelper.getLocale(), {
                    maximumFractionDigits: isInteger ? 0 : 2,
                    minimumFractionDigits: isInteger ? 0 : 2
                });

            default:
                return value;
        }
    }

    getCellAlignment = (type) => {
        switch (type) {
            case 'number':
            case 'excel-date':
            case 'date':
                return 'right';
            case 'boolean':
                return 'center';
            default:
                return 'left';
        }
    }

    renderCell = (cell, index) => {
        const type = this.detectCellType(cell);

        return (
            <td key={index} className={`cell-type-${type}`} title={this.props.t(`client_src_components_tools_TableImporterPreview_TableImporterPreview:${type}`)} style={{ textAlign: this.getCellAlignment(type) }}>
                {this.formatCellValue(cell, type)}
            </td>
        );
    }

    renderTableImporter = (dataPreview) => {
        return (
            <table className="table-importer">
                <thead>
                    <tr>
                        {dataPreview[0].map((cell, index) => (
                            <th key={index}>{cell}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {dataPreview.slice(1).map((row, rowIndex) => (
                        <tr key={rowIndex}>
                            {row.map((cell, cellIndex) => this.renderCell(cell, cellIndex))}
                        </tr>
                    ))}
                </tbody>
            </table>
        );
    }

    renderDownloadModel = () => {
        return (
            <div style={{ ...EssentialStyle.rowFlexStart, marginBottom: 10 }}>
                <DefaultButton
                    title={this.props.t('client_src_components_tools_TableImporterPreview_TableImporterPreview:downloadModelo')}
                    leftIcon={<FontAwesomeIcon icon={faDownload} />}
                    color={Colors.success}
                    width={200}
                    textColor={Colors.light}
                    disabled={this.props.loading}
                    onClick={() => {
                        let { fileModel } = this.state;

                        DataHelper.createAndStyleXLSX(XLSX, fileModel, this.props.t('client_src_components_tools_TableImporterPreview_TableImporterPreview:fileModel'));
                    }}
                />
            </div>
        );
    }

    render() {
        return this.renderTable();
    }
}

export default withTranslation()(TableImporterPreview);