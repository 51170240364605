import React from "react";
import AtaReuniaoParticipanteItem from "./AtaReuniaoParticipanteItem";
import DefaultButton from "../../../tools/DefaultButton";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd } from '@fortawesome/free-solid-svg-icons';
import Colors from "../../../../constants/Colors";

export default class AtaReuniaoParticipantes extends React.Component {

    
    renderAtaReuniaoParticipantes() {

        var possiveisParticipantesItens = this.props.selectsOptions.possiveisParticipantes.filter((participante) => {
            return participante.id != this.props.criador?.id && (this.props.participantes.length == 0 || !this.props.participantes.some((participanteSelecionado) => {
                return participanteSelecionado.email == participante.email && participante.email != null;
            }));
        });

        return (
            <div style={{maxHeight: "40vh", minHeight: this.props.minHeight ? this.props.minHeight : 400, overflowY: this.props.overflowY ? this.props.overflowY : "auto", width: "100%"}}>
                {this.props.participantes && this.props.participantes.length > 0 &&
                    this.props.participantes.map((participante, index) => {
                        return (
                            //Card
                            <AtaReuniaoParticipanteItem 
                                isTopico={this.props.isTopico}
                                noEdit={this.props.criador?.id && this.props.criador.id == participante.id}
                                canEditFuncao={this.props.criador?.id && this.props.criador.id == participante.id}
                                key={index} 
                                index={index} 
                                participante={participante} 
                                changeCallback={this.props.changeCallback} 
                                removeCallback={this.props.removeCallback}
                                selectsOptions={{...this.props.selectsOptions, "possiveisParticipantes": possiveisParticipantesItens}}
                                tipoReuniao={this.props.tipoReuniao ? this.props.tipoReuniao : null}
                            />
                        );
                    })
                }

                <DefaultButton 
                    color={Colors.success}
                    leftIcon={<FontAwesomeIcon icon={faAdd} />}
                    title={"Adicionar Participante"} 
                    className="btn btn-primary" onClick={() => this.props.addCallback()}
                />
            </div>
        );
    }

    render() {
        return this.renderAtaReuniaoParticipantes();
    }
}