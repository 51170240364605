import React from "react";

import './ConfigTabela.css';
import IdiomaHelper from "../../../../../../helper/IdiomaHelper";
import EllipsisText from "../../../../../tools/EllipsisText";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamation, faEdit, faLock, faPlus, faTimesCircle, faPaperPlane, faTrash, faInfoCircle, faCertificate, faLineChart } from '@fortawesome/free-solid-svg-icons';
import { Collapse, Form, Row, Col } from 'react-bootstrap';
import SessionHelper from "../../../../../../helper/SessionHelper";
import DefaultButton from "../../../../../tools/DefaultButton";
import Colors from "../../../../../../constants/Colors";
import EssentialStyle from "../../../../../../style/EssentialStyle";
import DiagnosticoHelper from "../../../../../../helper/diagnostico/DiagnosticoHelper";
import { confirmAlert } from 'react-confirm-alert';
import CustomConfirm from "../../../../../tools/CustomConfirm";
import { toast } from 'react-toastify';
import Select from 'react-select';
import CustomTooltip from "../../../../../tools/CustomTooltip";
import IndicadorReferenciaCard from "../../../Indicadores/IndicadorReferenciaCard/IndicadorReferenciaCard";
import ModalResumoNodeQuestionario from "../../../../../tools/ModalResumoNodeQuestionario/ModalResumoNodeQuestionario";
import { withTranslation } from 'react-i18next';

class ConfigTabela extends React.Component {

    state = {
        tabela: this.props.tabela || [],
        nodeSelected: { 
            open: false,
            node: {
                texto: IdiomaHelper.getIdiomas().map(idioma => ({ id_idioma: idioma.id, descricao: '' })),
            },
        },
        indicadores: [],
        indicadoresSelect: [],
        indicadorSelected: null,
        heightCell: 35,

        importTabela: false,
    }

    async componentDidMount() {
        await this.props.i18n.loadNamespaces(['client_src_helper_diagnostico_DiagnosticoHelper']);

        this.props.reload();
        this.loadIndicadores();
    }

    componentDidUpdate(prevProps) {
        
        if (prevProps.tabela !== this.props.tabela) {
            this.setState({ tabela: this.props.tabela });
        }

    }

    loadIndicadores = async () => {

        let {indicadores} = await DiagnosticoHelper.getPeriodoAvaliacaoIndicadores(this.props.idPeriodoAvaliacao);
        let indicadoresSelect = indicadores.map(indicador => {
            return {
                value: indicador.id,
                label: indicador.indicador.nome,
            }
        });
        
        this.setState({ indicadores, indicadoresSelect });
    }

    message = (lang = this.props.lang) => {
        return IdiomaHelper.getStruct({
            'pt_br': { 
                editItem: 'Editando Item na coluna ',
                addItem: 'Adicionando Item na coluna ',
                addNewNode: 'Nome do novo item...',
                addIndicador: 'Vincular Indicador',
                addedIndicadores: 'Indicadores Vinculados',
                delete: 'Excluir',
                add: 'Adicionar',
                save: 'Salvar',
                errorText: 'Adicione o nome do Item em ao menos uma linguagem',
                tooltip_inciadorNota: `
                    <h2>Indicador de Processo</h2>
                    <p>Este indicador será usar o resultado deste item no questionário, com as seguintes condições:</p>
                    <ul>
                        <li>A performance do indicador será considerada como resultado deste item no diagnóstico</li>
                        <li>Se houver mais de um indicador de processo neste item, o resultado será calculado com base na média da performance dos indicadores</li>
                        <li>Se houver itens filhos vinculados a este item, os resultados dos filhos NÃO serão considerados para formar seu resultado</li>
                    </ul>
                `,
                tooltip_inciadorRelacao: `
                    <h2>Indicador de Performance</h2>
                    <p>Este indicador <strong>NÃO</strong> será usado para gerar o resultado deste item do questionário, apenas será estabelecido um vínculo para demonstrar que existe relação entre a pergunta e este indicador.</p>
                `,
            },
            'en': { 
                editItem: 'Editing Item in column ',
                addItem: 'Adding Item in column ',
                addNewNode: 'Name of the new item...',
                addIndicador: 'Link Indicator',
                addedIndicadores: 'Linked Indicators',
                delete: 'Delete',
                add: 'Add',
                save: 'Save',
                errorText: 'Add the Item name in at least one language',
                tooltip_inciadorNota: `
                    <h2>Process Indicator</h2>
                    <p>This indicator will be used to generate the result of this item in the questionnaire, with the following conditions:</p>
                    <ul>
                        <li>The Indicator Performance will be considered as the result of this item in the questionnaire</li>
                        <li>If there is more than one Process Indicator in this item, the result will be calculated based on the average of the performance of the indicators</li>
                        <li>If there are child items linked to this item, the results of the children <strong>WILL NOT</strong> be considered to form its result</li>
                    </ul>
                `,
                tooltip_inciadorRelacao: `
                    <h2>Performance Indicator</h2>
                    <p>This indicator <strong>WILL NOT</strong> be used to generate the result of this item in the questionnaire, it will only be linked to demonstrate that there is a relationship between the question and this indicator.</p>
                `,
            },
            'es': { 
                editItem: 'Editando Item en la columna ',
                addItem: 'Añadiendo Item en la columna ',
                addNewNode: 'Nombre del nuevo item...',
                addIndicador: 'Vincular Indicador',
                addedIndicadores: 'Indicadores Vinculados',
                delete: 'Eliminar',
                add: 'Agregar',
                save: 'Guardar',
                errorText: 'Agregue el nombre del Item en al menos un idioma',
                tooltip_inciadorNota: `
                    <h2>Indicador de Proceso</h2>
                    <p>Este indicador se utilizará para generar el resultado de este elemento en el cuestionario, con las siguientes condiciones:</p>
                    <ul>
                        <li>La performance del indicador se considerará como el resultado de este elemento en el cuestionario</li>
                        <li>Si hay más de un indicador de proceso en este elemento, el resultado se calculará en función del promedio de la performance de los indicadores</li>
                        <li>Si hay elementos hijos vinculados a este elemento, los resultados de los hijos <strong>NO</strong> se considerarán para formar su resultado</li>
                    </ul>
                `,
                tooltip_inciadorRelacao: ` 
                    <h2>Indicador de Performance</h2>
                    <p>Este indicador <strong>NO</strong> se utilizará para generar el resultado de este elemento en el cuestionario, solo se vinculará para demostrar que existe una relación entre la pregunta y este indicador.</p>
                `,
            }
        })[lang];
    }

    isEmpty() {
        return this.props.colunas.filter(coluna => coluna.id).length === 0;
    }

    // Funções de Estilo

    getWidthTable() {
        return document.getElementsByClassName('questionarios-options')[0]?.offsetWidth - 25 || 0;
    }

    onMouse(id_node, operation) {
        const elements = document.getElementsByClassName('id_' + id_node);
        for (let i = 0; i < elements.length; i++) {
            if (operation === 'add') {
                elements[i].classList.add('hover');
            } else {
                elements[i].classList.remove('hover');
            }
        }

        let elemento = elements[0];
        let id_node_pai = null;

        if (elemento) {
            const classes = elemento.classList;
            for (let j = 0; j < classes.length; j++) {
                if (classes[j].startsWith('id_node_pai_')) {
                    id_node_pai = classes[j].substring('id_node_pai_'.length);
                    break;
                }
            }
        }

        if (id_node_pai) {
            this.onMouse(id_node_pai, operation);
        }
        
    }

    // Funções de Ação do Banner

    onClickNode(node) {
        let { colunas } = this.props;
        let coluna = colunas.find(coluna => coluna.id === node.id_coluna);

        this.setState({
            nodeSelected: {
                ...this.state.nodeSelected,
                node,
                coluna,
                isNew: false,
                open: true,
            }
        });
    }

    onAddNode(id_node_pai, coluna, isLast, drag_index) {

        if (!this.state.nodeSelected.isNew){

            this.setState({ 
                nodeSelected: { 
                    ...this.state.nodeSelected,
                    node: {
                        ...this.state.nodeSelected.node,
                        id_node_pai: id_node_pai,
                        texto: IdiomaHelper.getIdiomas().map(idioma => ({ id_idioma: idioma.id, descricao: '' })),
                        drag_index,
                        indicadoresReferencia: [],
                    },
                    open: true,
                    coluna,
                    isLast,
                    isNew: true,
                },
            });

        } else {
            this.setState({ 
                nodeSelected: { 
                    ...this.state.nodeSelected, 
                    node: {
                        ...this.state.nodeSelected.node,
                        id_node_pai: id_node_pai,
                        drag_index,
                        indicadoresReferencia: [],
                    },
                    open: true,
                    coluna,
                    isLast,
                    isNew: true,
                },
            });
        }   
    }

    addIndicador = () => {
        
        let { indicadorSelected } = this.state;
        if (indicadorSelected) {

            let indicadoresVinculados = this.state.nodeSelected.node.indicadoresReferencia || [];
            let newIndicador = this.state.indicadores.find(indicador => indicador.id === indicadorSelected.value);
            
            newIndicador = {
                ...newIndicador,
                tipo_vinculo: 0,
            }


            if ( newIndicador && !(indicadoresVinculados?.find(indicadorReferencia => indicadorReferencia.id == newIndicador.id)) ) indicadoresVinculados.push(newIndicador);

            this.setState({  
                indicadorSelected: null,
                nodoSelected:{
                    ...this.state.nodeSelected,
                    node: {
                        ...this.state.nodeSelected.node,
                        indicadoresReferencia: indicadoresVinculados,
                    }
                }
            });
        }
    }

    deleteVinculo = (idIndicador) => {
        
        let indicadoresVinculados = this.state.nodeSelected.node.indicadoresReferencia || [];
        indicadoresVinculados = indicadoresVinculados.filter(indicador => indicador.id !== idIndicador);

        this.setState({
            nodeSelected:{
                ...this.state.nodeSelected,
                node: {
                    ...this.state.nodeSelected.node,
                    indicadoresReferencia: indicadoresVinculados,
                }
            }
        });
    }

    updateTipoVinculo = (id) => {
        this.setState(prevState => {
            const updatedIndicadores = prevState.nodeSelected.node.indicadoresReferencia.map(indicador => {
                if (indicador.id === id) {
                    return {
                        ...indicador,
                        tipo_vinculo: indicador.tipo_vinculo === 0 ? 1 : 0
                    };
                }
                return indicador;
            });
    
            return {
                nodeSelected: {
                    ...prevState.nodeSelected,
                    node: {
                        ...prevState.nodeSelected.node,
                        indicadoresReferencia: updatedIndicadores
                    }
                }
            };
        });
    }

    closeBanner = (callback) => {

        this.setState({ 
            nodeSelected: { 
                ...this.state.nodeSelected, 
                open: false,
            },

        }, () => { if (callback) callback() });
    }

    clearBanner = (callback) => {

        this.setState({
            nodeSelected: { 
                ...this.state.nodeSelected,
                node: {
                    texto: IdiomaHelper.getIdiomas().map(idioma => ({ id_idioma: idioma.id, descricao: '' })),
                },
            }

        }, () => { if (callback) callback() });
    }

    saveItem() {

        if (this.props.blocked) {
            return;
        }

        let { nodeSelected, tabela } = this.state;
        let node;

        if(!(nodeSelected.node.texto.some(texto => texto.descricao && texto.descricao.trim() !== ''))) {
            toast.error(this.message(this.props.lang).errorText);
            return;
        }

        const saveItem = async () => {
            if (nodeSelected.isNew) {
                node = {
                    id: null,
                    id_node_pai: nodeSelected?.node?.id_node_pai || null,
                    id_coluna: nodeSelected.coluna.id,
                    drag_index: nodeSelected?.node?.drag_index || null,
                    texto: nodeSelected?.node?.texto || [],
                    indicadores: nodeSelected?.node?.indicadoresReferencia || [],
                };
    
            } else {
                node = {
                    id: nodeSelected.node.id,
                    id_node_pai: nodeSelected?.node?.id_node_pai || null,
                    id_coluna: nodeSelected.coluna.id,
                    drag_index: nodeSelected?.node?.drag_index || null,
                    texto: nodeSelected?.node?.texto || [],
                    indicadores: nodeSelected?.node?.indicadoresReferencia || [],
                };
            }
    
            this.props.callback(this.props.idQuestionario, node);
            this.closeBanner(this.clearBanner);
        }

        if (tabela.length === 0) {

            let message = IdiomaHelper.getStruct({
                'pt_br': {
                    title: 'Adicionar Item na Tabela?',
                    message: `<p>Você realmente deseja adicionar este item à tabela?</p>
                        <ol>
                            <li>Ao adicionar um ou mais itens à tabela, você não poderá mais editar a estrutura da tabela.</li>
                            <li>Se precisar editar a estrutura após a inserção de um item, será necessário excluir os itens da tabela para permitir a edição da estrutura.</li>
                        </ol>`,
                    confirm: 'Adicionar',
                    cancel: 'Cancelar',
                },
                'en': {
                    title: 'Add Item to Table?',
                    message: `<p>Do you really want to add this item to the table?</p>
                        <ol>
                            <li>By adding one or more items to the table, you will no longer be able to edit the table structure.</li>
                            <li>If you need to edit the structure after inserting an item, you will need to delete the items from the table to allow editing of the structure.</li>
                        </ol>`,
                    confirm: 'Add',
                    cancel: 'Cancel',
                },
                'es': {
                    title: '¿Agregar Elemento a la Tabla?',
                    message: `<p>¿Realmente desea agregar este elemento a la tabla?</p>
                        <ol>
                            <li>Al agregar uno o más elementos a la tabla, ya no podrá editar la estructura de la tabla.</li>
                            <li>Si necesita editar la estructura después de insertar un elemento, deberá eliminar los elementos de la tabla para permitir la edición de la estructura.</li>
                        </ol>`,
                    confirm: 'Agregar',
                    cancel: 'Cancelar',
                }
            });
            
            confirmAlert({
                customUI: ({ onClose }) => (
                    <CustomConfirm
                        title={message[this.props.lang].title}
                        message={<p style={{ marginTop: 10, marginBottom: 10}}  dangerouslySetInnerHTML={{ __html: message[this.props.lang].message }}/>}
                        buttons={[
                            {
                                label: message[this.props.lang].confirm,
                                color: Colors.success,
                                textColor: Colors.light,
                                onClick: async () => {
                                    saveItem();
                                    onClose();
                                }
                            },
                            {
                                label: message[this.props.lang].cancel,
                                onClick: () => { onClose(); }
                            },
                        ]}
                    />
                )
            });
            
        } else {
            saveItem();
        }
        
    }

    onDeleteNode(idNode){

        if (this.props.blocked) {
            return;
        }

        let message = IdiomaHelper.getStruct({
            'pt_br': {
                title: 'Excluir Item da Tabela?',
                message: `<p>Deseja realmente Excluir este Item da Tabela? Essa ação não pode ser desfeita e levará as seguintes modificações:</p>
                    <ol>
                        <li>Caso este Item (pai) tenha itens (Filhos) associados a ele (a direita na tabela), todos os filhos serão também excluídos</li>
                        <li>Se este Item (pai) não tiver itens (Filhos) associados a ele, apenas o Item (pai) será excluído</li>
                        <li>Se este Item tiver Indicadores Vinculados, os mesmos serão desvinculados</li>
                    </ol>`,
                confirm: 'Excluir',
                cancel: 'Cancelar',
            },
            'en': {
                title: 'Delete Item from Table?',
                message: `<p>Do you really want to delete this Item from the Table? This action cannot be undone and will lead to the following changes:</p>
                    <ol>
                        <li>If this Item (parent) has items (Children) associated with it (to the right in the table), all children will also be deleted</li>
                        <li>If this Item (parent) does not have items (Children) associated with it, only the Item (parent) will be deleted</li>
                        <li>If this Item has Linked Indicators, they will be unlinked</li>
                    </ol>`,
                confirm: 'Delete',
                cancel: 'Cancel',
            },
            'es': {
                title: '¿Eliminar Elemento de la Tabla?',
                message: `<p>¿Realmente desea eliminar este Elemento de la Tabla? Esta acción no se puede deshacer y llevará a los siguientes cambios:</p>
                    <ol>
                        <li>Si este Elemento (padre) tiene elementos (Hijos) asociados a él (a la derecha en la tabla), todos los hijos también se eliminarán</li>
                        <li>Si este Elemento (padre) no tiene elementos (Hijos) asociados a él, solo se eliminará el Elemento (padre)</li>
                        <li>Si este Elemento tiene Indicadores Vinculados, se desvincularán</li>
                    <ol/>`,
                confirm: 'Eliminar',
                cancel: 'Cancelar',
            }
        });
        
        confirmAlert({
            customUI: ({ onClose }) => (
                <CustomConfirm
                    title={message[this.props.lang].title}
                    message={<p style={{ marginTop: 10, marginBottom: 10}}  dangerouslySetInnerHTML={{ __html: message[this.props.lang].message }}/>}
                    buttons={[
                        {
                            label: message[this.props.lang].confirm,
                            color: Colors.error,
                            textColor: Colors.light,
                            onClick: async () => {
                                this.props.deleteCallback(idNode);
                                this.closeBanner(this.clearBanner);
                                onClose();
                            }
                        },
                        {
                            label: message[this.props.lang].cancel,
                            onClick: () => { onClose(); }
                        },
                    ]}
                />
            )
        });
    }

    // Funções de Renderização

    renderTabela() {
        let { colunas, tabela } = this.props;
        colunas = colunas.sort((a, b) => a.drag_index - b.drag_index);
        
        if (tabela.length > 0) {
            tabela = tabela.sort((a, b) => a.drag_index - b.drag_index);
        }
        

        const renderHeader = (colunas) => {
            return (
                <div className="table-row table-row-header" style={{width: this.getWidthTable()}}>
                    {colunas.map((coluna, index) => {
                        let nome = coluna.texto?.find(nome => nome.id_idioma === IdiomaHelper.getSiglaId(this.props.lang))?.descricao || <FontAwesomeIcon icon={faExclamation} />;
                        let isLast = index === colunas.length - 1;
                        return (
                            <div 
                                key={`coluna-exemple-${coluna.id}`} 
                                className="table-header"
                                style={{width: (this.getWidthTable() / (colunas.length + 1)) * (isLast ? 2 : 1)  }}
                            >
                                {nome}
                            </div>
                        );
                    })}
                </div>
            );
        };
    
        const renderRows = (nodes, lang) => {
        
            return (
                nodes.map((node, index) => {
                    return (
                        <div key={`row-${node.id}`} className="table-row table-row-body">
                            {renderCells(node, lang)}
                        </div>
                    );
                })
            );
        };

        const renderCells = (node, lang) => {
            let cells = [];

            if (node.filhos) {
                node.filhos.forEach((filho) => {
                    cells.push(renderCells(filho, lang));
                });
            }

            let coluna = colunas.find(coluna => coluna.id === node.id_coluna);
            let indexColuna = colunas.indexOf(coluna);
            let isLast = indexColuna === colunas.length - 1;

            if (!isLast && !this.props.blocked) {
                let filhoIsLast = (indexColuna + 1) === (colunas.length - 1);
                let drag_index = (node.filhos.length + 1);

                cells.push(this.renderAddCell(node.id, colunas[(indexColuna + 1)], filhoIsLast, drag_index));
            }

            return(
                <div className="table-row">
                    
                    {this.renderCell(node, isLast)}

                    <div className="table-column">
                        {cells.map((cell, index) => (
                            <div key={`cell-${node.id}-${index}`}>
                                {cell}
                            </div>
                        ))}
                    </div>
                </div>
            );
        };

        const renderAddRow = () => {
            return (
                <div 
                    className="table-row table-row-body" 
                    style={{width: this.getWidthTable()}}
                >
                    {colunas.map((coluna, index) => {
                        
                        let isFirst = index === 0;
                        let isLast = index === colunas.length - 1;

                        if (isFirst) {
                            let drag_index = this.state.tabela.length;
                            return this.renderAddCell(null, coluna, isLast, drag_index)
                        } else {
                            return (
                                <div 
                                    key={`coluna-new-cell-${coluna.id}`} className="table-cell"
                                    style={{width: (this.getWidthTable() / (colunas.length + 1)) * (isLast ? 2 : 1)  }}
                                />
                            );
                        }

                    })}
                </div>
            )
        }
        
        return (
            <div className="table-content">
                {renderHeader(colunas)}
                {renderRows(tabela, this.props.lang)}
                {this.props.blocked ? null : renderAddRow()}
            </div>
        );
    }

    renderCell(node, isLast) {

        let text = node.texto?.find(nome => nome.id_idioma === IdiomaHelper.getSiglaId(this.props.lang))?.descricao || false;
        return (
            <div 
                key={`node-${node.id}`}
                className={`table-cell ${node.id_node_pai ? ('id_node_pai_' + node.id_node_pai) : ''} ${'id_' + node.id}`}
                rowSpan={node.rows}
                onMouseEnter={() => this.onMouse(node.id, 'add')}
                onMouseLeave={() => this.onMouse(node.id, 'remove')}
                style={{ 
                    height: (this.state.heightCell * (this.props.blocked ? node.rows : node.rowsEdit)),
                    width: (this.getWidthTable() / (this.props.colunas.length + 1)) * (isLast ? 2 : 1),
                }}
            >
                {text ? <EllipsisText text={text} /> : <FontAwesomeIcon icon={faExclamation} />}
                {/* <FontAwesomeIcon icon={faLock} className={`lock-icon ${!this.props.blocked ? '' : 'hover-action'}`} /> */}
                <FontAwesomeIcon icon={faInfoCircle} className={`info-icon ${!this.props.blocked ? '' : 'hover-action'}`}  onClick={() => this.setState({openInfoNode: node.id})}/> 
                <FontAwesomeIcon icon={faEdit} className={`edit-icon ${!this.props.blocked ? 'hover-action' : ''}`} onClick={() => this.onClickNode(node)} />
            </div>
        )
    }

    renderAddCell(id_node_pai, coluna, isLast, drag_index) {

        let colunas = this.props.colunas;

        return (
            <div 
                key={`coluna-new-cell-${coluna.id}`} className="table-cell"
                style={{
                    height: this.state.heightCell,
                    width: (this.getWidthTable() / (colunas.length + 1)) * (isLast ? 2 : 1)  
                }}
            >
                <FontAwesomeIcon 
                    icon={faPlus} 
                    className={`plus-icon`} 
                    onClick={() => {
                        this.onAddNode(id_node_pai, coluna, isLast, drag_index);
                    }}
                /> 
            </div>
        );
    }

    renderBanner() {

        const banner = () => {

            const renderBannerHeader = () => {
                return (
                    <div className="headerClose" style={{backgroundColor: SessionHelper.getColor()}}>
                        <div/>
                        <div className="title">
                            {this.message(this.props.lang)[this.state.nodeSelected.isNew ? 'addItem' : 'editItem']}
                            {this.state.nodeSelected?.coluna?.texto?.find(nome => nome.id_idioma === IdiomaHelper.getSiglaId(this.props.lang))?.descricao}
                        </div>
                        <FontAwesomeIcon 
                            icon={faTimesCircle} 
                            style={{ cursor: 'pointer' }} 
                            onClick={() =>  {

                                if (this.state.nodeSelected.isNew) {
                                    this.closeBanner(); 
                                } else {
                                    this.closeBanner( this.clearBanner ); 
                                }
                                
                            }} 
                        />
                    </div>
                )
            }

            let optionsSelect = this.state.indicadoresSelect?.filter(indicador => !(this.state.nodeSelected?.node?.indicadoresReferencia?.find(indicadorReferencia => indicadorReferencia.id == indicador.value)));
            let indicadores = this.state.nodeSelected?.node?.indicadoresReferencia || [];

            const renderBannerBody = () => {
                return (
                    <Form className="form-banner">
                        <Row className="mb-3">
                            {IdiomaHelper.getIdiomas().map((idioma, index) => {
                                return (
                                    <Form.Group className='mb-2' as={Col} xs={12} md={12} key={`idioma-list-${idioma.sigla}`}>
                                        <Form.Label className="form-label-xsm space-between">
                                            {idioma.nome}
                                        </Form.Label>
                                        <div>
                                            <Form.Control 
                                                type="text"
                                                value={this.state?.nodeSelected.node?.texto?.find(texto => texto.id_idioma === idioma.id)?.descricao || ''}
                                                placeholder={this.message(idioma.sigla).addNewNode}
                                                onChange={(event) => {
                                                    const newDescricao = event.target.value;
                                                    this.setState(prevState => ({
                                                        nodeSelected: {
                                                            ...prevState.nodeSelected,
                                                            node: {
                                                                ...prevState.nodeSelected.node,
                                                                texto: prevState.nodeSelected.node.texto.map(texto => {
                                                                    if (texto.id_idioma === idioma.id) {
                                                                        return { ...texto, descricao: newDescricao };
                                                                    }
                                                                    return texto;
                                                                })
                                                            }
                                                        }
                                                    }));
                                                }}
                                            />
                                        </div>
                                    </Form.Group>
                                )
                            })}
                        </Row>

                        {(optionsSelect?.length > 0 || indicadores.length > 0) && <Row className="mb-3">
                            <Form.Label className="form-label-xsm space-between">
                                <div className="lineDivisor"/>
                            </Form.Label>
                        </Row>}

                        {optionsSelect?.length > 0 && <Row className="mb-3">
                            <Form.Label className="form-label-xsm space-between">
                                {this.message(this.props.lang).addIndicador}
                            </Form.Label>
                            <div style={{ display: 'flex', gap: 10 }}>
                                <Select
                                    options={this.state.indicadoresSelect?.filter(indicador => !(this.state.nodeSelected?.node?.indicadoresReferencia?.find(indicadorReferencia => indicadorReferencia.id == indicador.value)))}
                                    isClearable
                                    isSearchable
                                    value={this.state.indicadorSelected}
                                    onChange={(value) => { this.setState({ indicadorSelected: value }); }}
                                    menuPortalTarget={document.body}
                                    menuPlacement="auto"
                                    styles={{ container: (provided) => ({ ...provided, width: '100%' }) }}
                                />
                                <DefaultButton
                                    leftIcon={<FontAwesomeIcon icon={faPaperPlane} />}
                                    onClick={() => { this.addIndicador() }}
                                />
                            </div>
                        </Row>}

                        {indicadores.length > 0 && <Row className="mb-5">
                            <Form.Label className="form-label-xsm space-between">
                                {this.message(this.props.lang).addedIndicadores}
                            </Form.Label>

                            <div className="list-indicadores">
                                {this.state.nodeSelected?.node?.indicadoresReferencia?.map((indicadorReferencia, index) => {
                                    return (
                                        <IndicadorReferenciaCard
                                            key={`indicadorpainel-${indicadorReferencia.indicador.id}`}
                                            periodoIndicadorReferencia={indicadorReferencia}
                                            updateTipoVinculo={(id) => this.updateTipoVinculo(id)}
                                            deleteVinculo={(id) => this.deleteVinculo(id)}
                                            lang={this.props.lang}
                                            isVinculoNode={true}
                                            style={{ width: 'calc(100% - 10px)', marginRigth: 5, marginLefth: 5 }}
                                        />
                                    )
                                })}
                            </div>
                        </Row>}

                        <Row>
                            <div style={{ ...EssentialStyle.rowFlexEnd, gap: 10}}>

                                {!this.state.nodeSelected.isNew  && 
                                    <DefaultButton
                                        title={this.message(this.props.lang).delete}
                                        leftIcon={<FontAwesomeIcon icon={faTrash} />}
                                        color={Colors.error}
                                        onClick={() => {
                                            if (this.state.nodeSelected.node.id){
                                                this.onDeleteNode(this.state.nodeSelected.node.id);
                                            }
                                        }}
                                    />
                                }

                                <DefaultButton
                                    title={this.message(this.props.lang)[this.state.nodeSelected.isNew ? 'add' : 'save']}
                                    leftIcon={<FontAwesomeIcon icon={faPaperPlane} />}
                                    color={Colors.success}
                                    onClick={() => {
                                        this.saveItem();
                                    }}
                                />
                            </div>
                        </Row>
                    </Form>
                )
            }

            return (
                <div className="collapse-content">
                    {renderBannerHeader()}
                    {renderBannerBody()}
                </div>
            )
        }

        return (
            <div className={"content-banner"}>
                <Collapse in={this.state.nodeSelected?.open == true} style={{width: 650, maxWidth: '95%', maxHeight: 'calc(100% - 20px)'}}>
                    { this.state.nodeSelected?.open == true ? banner() : <></> }
                </Collapse>
            </div>
        )
    }

    renderInfoNode() {
        const { openInfoNode } = this.state;
        const { lang } = this.props;
    
        return openInfoNode ? (
            <ModalResumoNodeQuestionario
                idNode={openInfoNode}
                lang={lang}
                changeNode={(idNode) => this.setState({ openInfoNode: idNode })}
                closeModal={() => this.setState({ openInfoNode: null })}
            />
        ) : null;
    }

    renderEmpty() {

        const message = IdiomaHelper.getStruct({
            'pt_br': { empty: 'Primeiro, defina a estrutura da Tabela do Questionário na aba anterior (Estrutura).' },
            'en': { empty: 'First, define the structure of the Questionnaire Table in the previous tab (Structure).' },
            'es': { empty: 'Primero, defina la estructura de la Tabla del Cuestionario en la pestaña anterior (Estructura).' },
        });

        return (
            <div className="ConfigTabela">
                <div className="empty-content">
                    {DiagnosticoHelper.renderEmpty(message[this.props.lang].empty, {height: '16rem', marginBottom: '1rem'}, {height: '14rem'}, this.props.t)}

                </div>
            </div>
        );
    }

    render() {
        if (this.isEmpty()) {
            return this.renderEmpty();
        }
    
        return (
            <div className="ConfigTabela">
                {this.renderTabela()}
                {this.renderBanner()}
                {this.renderInfoNode()}
            </div>
        );
    }
}

export default withTranslation()(ConfigTabela);