import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy, faEye, faPlusSquare, faStar, faTrash } from '@fortawesome/free-solid-svg-icons'
import DefaultButton from "../../../tools/DefaultButton";
import AddSwot from "../add/AddSwot";
import LoadingPage from "../../../../pages/LoadingPage";
import DefaultTable from "../../../tools/DefaultTable";
import moment from "moment";
import SwotView from "../../../modules/pe/swot/SwotView";
import { Collapse } from "react-bootstrap";
import DataHelper from "../../../../helper/DataHelper";
import DefaultTableSearch from "../../../tools/DefaultTableSearch";
import Colors from "../../../../constants/Colors";
import { confirmAlert } from "react-confirm-alert";
import { toast } from "react-toastify";
import UserAvatar from "../../../tools/UserAvatar";
import CustomConfirm from "../../../tools/CustomConfirm";
import Sig from "../../../../api/Sig";
import CustomTooltip from "../../../tools/CustomTooltip";
import EssentialStyle from "../../../../style/EssentialStyle";
import TooManyAvatars from "../../../tools/TooManyAvatars";
import SessionHelper from "../../../../helper/SessionHelper";
import TooManyTags from "../../../tools/TooManyTags";
import SwotListSearch from "../../../modules/pe/swot/SwotListSearch";

const searchableFields = ['nome', 'descricao', 'responsavel', 'participants', 'tags', 'data_atualizacao', 'data_cadastro'];

export default class SwotList extends React.Component {

    state = {
        swots: [],
        loading: true,
        showWizard: false,
        showSwot: 0,
        quadrants: [
            "Forças",
            "Fraquezas",
            "Oportunidades",
            "Ameaças"
        ],
        advancedFilter: false,

        acesso: null,
        permissao: null
    }

    async componentDidMount() {
        this.setProgressBarColor();
        await this.loadData();

        // Remove o atributo 'title' de todos os elementos
        document.querySelectorAll('[title]').forEach(el => el.removeAttribute('title'));
    }

    setProgressBarColor() {
        if (this.progressBar) {
            const inner = this.progressBar.querySelector(".progress-bar");
            if (inner) inner.style.backgroundColor = Colors.dark;
        }
    }

    async loadData(loading = true) {
        if (loading) {
            this.setState({ loading: true });
        }

        let { swots, acesso, permissao } = (await Sig.request('GET', 'pe/swot/getSwots', { relations: 1 }));
        let rowSwots = [];

        for (let swot of swots) {                
            let rowSwot = {
                ...swot.swot,
                participants: swot.participantes,
                tags: swot.tags,
                responsavel: swot.responsavel
            };

            rowSwot.favorito = swot.favorito;

            rowSwots.push(rowSwot);
        }

        this.setState({ swots: rowSwots, acesso, permissao }, () => { this.setState({ loading: false }) });
    }

    remove = async (id) => {
        this.setState({ loading: true });

        try {
            await Sig.request('POST', `pe/swot/delete`, { id: id });
        } catch (e) {
            toast.error('Erro ao remover');
        }

        this.loadData();
    }

    addFromProject = async (id) => {
        if (!id) return toast.warn('Selecione um SWOT');

        let req = await Sig.request('POST', `pe/swot/clonarSwot`, { id: id });

        if (req?.swot?.id) {
            await this.loadData();
            toast.success('SWOT inserido com sucesso!');
        }

        this.setState({ loading: false });
    }

    async handleFavorite(id, isFavorite) {
        this.setState({ loading: true });

        let response = await Sig.request('POST', `pe/swot/setFavorito`, { id: id, favorito: isFavorite ? 0 : 1 });
        await this.loadData();

        this.setState({ loading: false });
        if (response.response == 200){
            toast.success('Alterado com sucesso');
        } else {
            toast.error('Você não tem permissão para realizar essa ação');
        }
    }

    renderParticipants(participants) {
        if (participants && participants.length) {
            return <TooManyAvatars participantsTotal={participants.length} participantsVisiveis={3} arrayParticipants={participants} />;
        } else {
            return <div></div>
        }
    }

    renderTags(tags) {
        return <TooManyTags tags={tags}/>
    }

    handleToggleExpand = (advancedFilter) => {
        this.setState({ advancedFilter: advancedFilter });
    }

    renderHeader() {

        return (
            <>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: 10, marginBottom: 20 }}>
                    <DefaultButton color={Colors.success} title={'Adicionar SWOT'} leftIcon={<FontAwesomeIcon icon={faPlusSquare} />} loading={this.state.loading} onClick={() => { this.setState({ showWizard: true }) }} />
                    <div></div>
                    <DefaultTableSearch fields={searchableFields} searchObjects={true} data={this.state.swots} onDataUpdate={(data) => { this.setState({ swots: data }) }} onEmpty={() => { this.loadData(false) }} toggleExpand={this.handleToggleExpand}/>
                </div>
                <Collapse in={this.state.advancedFilter}>
                    <div>
                        <SwotListSearch fields={searchableFields} searchObjects={true} data={this.state.swots} onDataUpdate={(data) => { this.setState({ swots: data }) }} onEmpty={() => { this.loadData(false) }} />
                    </div>
                </Collapse>            
            </>
        )
    }

    renderActions(row) {

        let podeFavoritar = this.state.permissao == 'pe_swot_editar';
        let podeClonar = this.state.permissao == 'pe_swot_editar' || row.isResponsavel;
        let podeRemover = this.state.permissao == 'pe_swot_editar' || row.isResponsavel;

        return (

            <div style={EssentialStyle.rowFlexStart}>
                <CustomTooltip placement={'bottom'} tooltip={podeFavoritar ? (row.favorito ? 'Remover Favorito' : 'Marcar como Favorito') : "Sem Permissão Para Favoritar SWOTs"}>
                    <FontAwesomeIcon style={{ color: row.favorito ? Colors.favorite : 'grey', fontSize: 17 }} icon={faStar} onClick={() => {
                        if (podeFavoritar) {
                            confirmAlert({
                                customUI: ({ onClose }) => (
                                    <CustomConfirm
                                        title={row.favorito ? 'Remover Favorito?' : 'Marcar Favorito?'}
                                        buttons={[
                                            {
                                                label: !row.favorito ? 'Sim' : 'Remover',
                                                color: !row.favorito ? Colors.success : Colors.error,
                                                textColor: Colors.light,
                                                onClick: () => { this.handleFavorite(row.id, row.favorito); onClose(); }
                                            },
                                            {
                                                label: !row.favorito ? 'Não' : 'Cancelar',
                                                onClick: () => { onClose(); }
                                            },
                                        ]}
                                    />
                                )
                            });
                        }
                    }} />
                </CustomTooltip>
                <CustomTooltip placement={'bottom'} tooltip={podeClonar ? 'Clonar' : 'Sem Permissão Para Clonar essa SWOT'}>
                    <FontAwesomeIcon style={{ marginLeft: 20, fontSize: 17, color: 'grey' }} icon={faCopy} onClick={() => {
                        if (podeClonar) {
                            confirmAlert({
                                customUI: ({ onClose }) => (
                                    <CustomConfirm
                                        title={'Clonar este SWOT?'}
                                        buttons={[
                                            {
                                                label: 'Sim',
                                                color: Colors.success,
                                                textColor: Colors.light,
                                                onClick: () => { this.addFromProject(row.id); onClose(); }
                                            },
                                            {
                                                label: 'Não',
                                                onClick: () => { onClose(); }
                                            },
                                        ]}
                                    />
                                )
                            });
                        }
                    }} />
                </CustomTooltip>
                <CustomTooltip placement={'bottom'} tooltip={podeRemover ? 'Remover' : 'Sem Permissão Para Remover essa SWOT'}>
                    <FontAwesomeIcon style={{ marginLeft: 20, fontSize: 17, color: Colors.error }} icon={faTrash} onClick={() => {
                        if(podeRemover){ 
                            confirmAlert({
                                customUI: ({ onClose }) => (
                                    <CustomConfirm
                                        title={'Excluir este SWOT?'}
                                        message={<p style={{ marginTop: 10, marginBottom: 10 }}>Essa ação não pode ser desfeita</p>}
                                        buttons={[
                                            {
                                                label: 'Remover',
                                                color: SessionHelper.getColor(),
                                                textColor: Colors.light,
                                                onClick: () => { this.remove(row.id); onClose(); }
                                            },
                                            {
                                                label: 'Cancelar',
                                                onClick: () => { onClose(); }
                                            },
                                        ]}
                                    />
                                )
                            });
                        }
                    }} />
                </CustomTooltip>
            </div>
        )
    }

    renderSwots() {
        return (
            <div>
                <DefaultTable
                    title={this.renderHeader()}
                    columns={[
                        { name: 'SWOT', selector: row => row.nome, sortable: true, width: '12%' },
                        { name: 'Descrição', selector: row => row.descricao, sortable: true, width: '20%' },
                        { name: 'Responsável', cell: row => <UserAvatar id={row.id_responsavel} />, sortable: true, sortFunction: (a, b) => a.responsavel.nome > b.responsavel.nome },
                        { name: 'Participantes', cell: row => this.renderParticipants(row.participants), sortable: true, sortFunction: (a, b) => this.multiArraySort(a, b, 'participants') },
                        { name: 'Tags', cell: row => this.renderTags(row.tags), sortable: true, sortFunction: (a, b) => this.multiArraySort(a, b, 'tags'), width: '22%' },
                        { name: 'Data Atualização', cell: row => moment(row.data_atualizacao).format('DD/MM/YYYY HH:mm'), sortable: true, sortFunction: (a, b) => DataHelper.multiArraySort(a, b, 'data_atualizacao') },
                        { name: 'Criação', selector: row => moment(row.data_cadastro).format('DD/MM/YYYY HH:mm'), sortable: true, sortFunction: (a, b) => DataHelper.multiArraySort(a, b, 'data_cadastro') },
                        { name: 'Ações', selector: row => this.renderActions(row) },
                    ]}
                    data={this.state.swots}
                    onRowClicked={row => this.props.swotCallback(row.id)} // Chama o callback ao clicar na linha
                    pagination
                    pointerOnHover
                    highlightOnHover
                />
            </div>
        );
    }

    addSwotCallback = async () => {
        this.setState({ showWizard: false, showSwot: 0 });
        await this.loadData();
    }

    viewNewSwotCallback = (newSwotId) => {
        this.setState({ showWizard: false, showSwot: newSwotId });
    }

    render() {
        if (this.state.loading) return <LoadingPage />
        if (this.state.swots.length === 0) return this.props.callback();
        if (this.state.showWizard) return <AddSwot addSwotCallback={(newSwotId) => {this.viewNewSwotCallback(newSwotId)}} />
        if (this.state.showSwot) return <SwotView id={this.state.showSwot} callback={this.addSwotCallback} />

        return this.renderSwots();
    }
}