import React from "react";

import './GraficoMapaCalor.css';
import DiagnosticoHelper from "../../../../../../helper/diagnostico/DiagnosticoHelper";
import DataHelper from "../../../../../../helper/DataHelper";
import EssentialStyle from "../../../../../../style/EssentialStyle";
import DefaultLoader from "../../../../../tools/DefaultLoader";
import BarraDemonstracao from "../../../../../tools/BarraDemonstracao";
import EllipsisText from "../../../../../tools/EllipsisText";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp, faCertificate, faLightbulb } from '@fortawesome/free-solid-svg-icons';
import Select from 'react-select';
import Colors from "../../../../../../constants/Colors";
import { Collapse, Form, Row, Col } from "react-bootstrap";
import SessionHelper from "../../../../../../helper/SessionHelper";
import CustomTooltip from "../../../../../tools/CustomTooltip";
import IdiomaHelper from "../../../../../../helper/IdiomaHelper";
import { toast } from "react-toastify";
import ModalResumoNodeQuestionario from "../../../../../tools/ModalResumoNodeQuestionario/ModalResumoNodeQuestionario";
import StarButton from "../../../StarButton/StarButton";
import { withTranslation } from 'react-i18next';

class GraficoMapaCalor extends React.Component {

    state = {
        idPeriodoAvaliacao: this.props.idPeriodoAvaliacao,
        loading: false,
        loadingConfig: true,
        loadingChart: true,
        loadingSetQuestionario: false,
        colunas: [],
        colunaSelected: null,
        questionarios: [],
        questionarioSelected: null,
        chart: null,
        chartCopy: null,
        chartFooter: null,
        colors: null,
        hasQuestionario: true,

        orderY: [
            {
                value: 'alfabetica',
                label: IdiomaHelper.getStruct({
                    'pt_br': 'Alfabética',
                    'en': 'Alphabetical',
                    'es': 'Alfabético'
                })
            },
            {
                value: 'maiorMedia',
                label: IdiomaHelper.getStruct({
                    'pt_br': 'Maior Média',
                    'en': 'Highest Average',
                    'es': 'Promedio más alto'
                })
            },
            {
                value: 'menorMedia',
                label: IdiomaHelper.getStruct({
                    'pt_br': 'Menor Média',
                    'en': 'Lowest Average',
                    'es': 'Promedio más bajo'
                })
            },
        ],
        orderYNode: null,
        orderSelectedY: 'alfabetica',

        filters: false,
        filterNomeEmpresa: '',

        hoverLine: null,
        hoverColumn: null,

        openInfoNode: null,
        idAvaliacao: null,
    }

    async componentDidUpdate(prevProps) {
        if (this.props.idPeriodoAvaliacao !== prevProps.idPeriodoAvaliacao || this.props.lang !== prevProps.lang) {
            this.setState({ idPeriodoAvaliacao: this.props.idPeriodoAvaliacao }, async () => {
                await this.loadData();
            });
        }
    }

    async componentDidMount() {
        await this.loadData();
    }

    async loadData() {
        this.setState({ loadingConfig: true, loadingChart: true });

        let { questionarios } = await DiagnosticoHelper.getQuestionarios(this.state.idPeriodoAvaliacao, 0);
        let tipos = await DiagnosticoHelper.getTiposAvaliacao(this.state.idPeriodoAvaliacao);
        let colors = await DiagnosticoHelper.getColorsPeriodoAvaliacao(this.state.idPeriodoAvaliacao);

        colors = colors.map((interval) => {
            let colorAux = {
                from: interval.min,
                to: interval.max,
                color: interval.cor,
                infinito: 0,
            }

            return colorAux;
        })

        let questionarioSelected = questionarios.length > 0 ? questionarios[0].id : null;
        let tipoSelected = tipos.length > 0 ? tipos[0].id : null;

        let { colunas } = await DiagnosticoHelper.getColunasQuestionario(questionarioSelected, null, this.props.lang, null) || [];
        let colunaSelected = colunas.length > 0 ? colunas[0].id : null;

        this.setState(
            {
                colunas,
                colunaSelected,
                questionarios,
                questionarioSelected,
                tipos,
                tipoSelected,
                colors,
                loadingConfig: false
            },
            async () => {
                this.loadInfosChart();
            }
        );
    }

    async setQuestionario(questionarioSelected) {
        this.setState({ loadingSetQuestionario: true, questionarioSelected });

        let { colunas } = await DiagnosticoHelper.getColunasQuestionario(questionarioSelected, null, this.props.lang, null) || [];
        let colunaSelected = colunas.length > 0 ? colunas[0].id : null;

        this.setState({ questionarioSelected, colunaSelected, colunas, loadingSetQuestionario: false }, () => {
            this.loadInfosChart();
        });
    }

    setTipoAvaliacao(tipoSelected) {
        this.setState({ tipoSelected }, () => {
            this.loadInfosChart();
        });
    }

    setNodeColuna(colunaSelected) {
        this.setState({ colunaSelected }, () => {
            this.loadInfosChart();
        });
    }

    async loadInfosChart() {


        if (!this.state.questionarioSelected){
            toast.error(this.getMessage('hasNotQuestionarios'));
            this.setState({ loadingChart: false });
            return;
        }

        if (!this.state.tipoSelected){
            toast.error(this.getMessage('hasNotTipoAvaliacao'));
            this.setState({ loadingChart: false });
            return;
        }

        if (!this.state.colunaSelected){
            toast.error(this.getMessage('hasNotColuna'));
            this.setState({ loadingChart: false });
            return;
        }

        this.setState({ loadingChart: true });

        let {
            chart,
            chartFooter,
            hasQuestionario,
            hasAvaliacaoFinalizada
        } = await DiagnosticoHelper.getHeatmapData(
            this.state.idPeriodoAvaliacao,
            this.state.colunaSelected,
            this.state.questionarioSelected,
            this.state.tipoSelected,
            this.props.lang
        );

        if (hasQuestionario && hasAvaliacaoFinalizada) {
            chart = this.orderEmpresas(chart);
        }

        this.setState({ chart, chartCopy: chart, chartFooter, hasAvaliacaoFinalizada, loadingChart: false });
    }

    setOrderY(orderSelectedY) {
        this.setState({ orderSelectedY, orderYNode: null }, () => {
            this.setState({ chart: this.orderEmpresas(this.state.chart) });
        })
    }

    orderEmpresas(chart) {
        let orderSelectedY = this.state.orderSelectedY;

        if (this.state.orderYNode != null && this.state.orderYNode.node) {
            chart = chart.sort((a, b) => {
                let resultA = a.resultados.find((result) => result.node == this.state.orderYNode.node).resultados.valor;
                let resultB = b.resultados.find((result) => result.node == this.state.orderYNode.node).resultados.valor;

                if (this.state.orderYNode.order == 0) {
                    return resultA > resultB ? -1 : resultA < resultB ? 1 : 0;
                } else {
                    return resultA < resultB ? -1 : resultA > resultB ? 1 : 0;
                }
            });
        } else if (orderSelectedY == 'alfabetica') {
            chart = chart.sort((a, b) => {
                if (a.empresaAvaliada.nome_fantasia < b.empresaAvaliada.nome_fantasia) return -1;
                if (a.empresaAvaliada.nome_fantasia > b.empresaAvaliada.nome_fantasia) return 1;
                return 0;
            });
        } else if (orderSelectedY == 'maiorMedia' || orderSelectedY == 'menorMedia') {
            chart = chart.sort((a, b) => {

                const calcularMedia = (item) => {
                    const soma = item.resultados.reduce((acc, coluna) => acc + coluna.resultados.valor, 0);
                    return (soma / item.resultados.length).toFixed(1);
                };

                let mediaA = parseFloat(calcularMedia(a));
                let mediaB = parseFloat(calcularMedia(b));

                if (orderSelectedY == 'maiorMedia') {
                    return mediaA > mediaB ? -1 : mediaA < mediaB ? 1 : 0;
                } else {
                    return mediaA < mediaB ? -1 : mediaA > mediaB ? 1 : 0;
                }
            });
        }

        return chart;
    }

    filter() {
        let chart = this.state.chartCopy;
        let filterNomeEmpresa = this.state.filterNomeEmpresa.toLowerCase();

        if (filterNomeEmpresa && filterNomeEmpresa.length > 0) {
            chart = chart.filter((linha) => {
                return linha.empresaAvaliada.nome_fantasia.toLowerCase().includes(filterNomeEmpresa);
            });
        }

        chart = this.orderEmpresas(chart);

        this.setState({ chart });
    }

    orderByNode(node) {
        let orderYNode = null;
        if (this.state.orderYNode == null || this.state.orderYNode.node != node) {
            orderYNode = { node, order: 0 };
        } else if (this.state.orderYNode.node == node) {
            orderYNode = this.state.orderYNode.order == 1 ? null : { node, order: 1 };
        }

        this.setState({ orderYNode }, () => {
            this.setState({ chart: this.orderEmpresas(this.state.chart) })
        });
    }

    getMessage(text) {
        let message = IdiomaHelper.getStruct({
            'pt_br': {
                colunaSelected: 'Selecione a coluna',
                questionarioSelected: 'Selecione o questionário',
                chartHeader: 'Média das Avaliações',
                chartHeaderTotal: 'Geral por Empresa',
                chartHeaderTotalI: 'Média Aritmética de todas as respostas',
                chartFooter: `Total por ${this.state.colunas.length > 0 ? this.state.colunas?.find((coluna) => coluna.id == this.state.colunaSelected).descricao : ''}`,
                selectOrder: 'Selecione a ordem',
                formEmpresa: 'Nome da Empresa',
                formEmpresaPlaceHolder: 'Busque por empresa',
                empresaEmpty: 'Nenhuma empresa encontrada.',
                labelQuestionario: 'Questionário',
                labelTipoAvaliacao: 'Tipo de Avaliação',
                labelColuna: 'Coluna',
                labelOrderY: 'Ordem das Empresas',
                labelOrderX: 'Ordem das Colunas',
                labelBar: 'Escala',
                hasNotQuestionarios: 'Não há questionários para este período de avaliação.',
                hasNotTipoAvaliacao: 'Não há tipos de avaliação para este período de avaliação.',
                hasNotColuna: 'Não há colunas para este questionário.',
                notSelectQuestionarios: 'Selecione um questionário.',
                notSelectTipoAvaliacao: 'Selecione um tipo de avaliação.',
                notSelectColuna: 'Selecione uma coluna.',
            },
            'en': {
                colunaSelected: 'Select the column',
                questionarioSelected: 'Select the questionnaire',
                chartHeader: 'Average of Evaluations',
                chartHeaderTotal: 'General by Company',
                chartHeaderTotalI: 'Arithmetic mean of all answers',
                chartFooter: `Total by ${this.state.colunas.length > 0 ? this.state.colunas?.find((coluna) => coluna.id == this.state.colunaSelected).descricao : ''} `,
                selectOrder: 'Select the order',
                formEmpresa: 'Company Name',
                formEmpresaPlaceHolder: 'Search for company',
                empresaEmpty: 'No company found.',
                labelQuestionario: 'Questionnaire',
                labelTipoAvaliacao: 'Type of Evaluation',
                labelColuna: 'Column',
                labelOrderY: 'Order of Companies',
                labelOrderX: 'Order of Columns',
                labelBar: 'Scale',
                hasNotQuestionarios: 'There are no questionnaires for this evaluation period.',
                hasNotTipoAvaliacao: 'There are no evaluation types for this evaluation period.',
                hasNotColuna: 'There are no columns for this questionnaire.',
                notSelectQuestionarios: 'Select a questionnaire.',
                notSelectTipoAvaliacao: 'Select an evaluation type.',
                notSelectColuna: 'Select a column.',
            },
            'es': {
                colunaSelected: 'Seleccione la columna',
                questionarioSelected: 'Seleccione el cuestionario',
                chartHeader: 'Promedio de Evaluaciones',
                chartHeaderTotal: 'General por empresa',
                chartHeaderTotalI: 'Media aritmética de todas las respuestas.',
                chartFooter: `Total por ${this.state.colunas.length > 0 ? this.state.colunas?.find((coluna) => coluna.id == this.state.colunaSelected).descricao : ''}`,
                selectOrder: 'Seleccione el orden',
                formEmpresa: 'Busar por empresa',
                formEmpresaPlaceHolder: 'Nombre de la Empresa',
                empresaEmpty: 'No se encontró ninguna empresa.',
                labelQuestionario: 'Cuestionario',
                labelTipoAvaliacao: 'Tipo de Evaluación',
                labelColuna: 'Columna',
                labelOrderY: 'Orden de Empresas',
                labelOrderX: 'Orden de Columnas',
                labelBar: 'Escala',
                hasNotQuestionarios: 'No hay cuestionarios para este período de evaluación.',
                hasNotTipoAvaliacao: 'No hay tipos de evaluación para este período de evaluación.',
                hasNotColuna: 'No hay columnas para este cuestionario.',
                notSelectQuestionarios: 'Seleccione un cuestionario.',
                notSelectTipoAvaliacao: 'Seleccione un tipo de evaluación.',
                notSelectColuna: 'Seleccione una columna.',
            }
        });

        return message[this.props.lang][text];
    }

    selectGetMessage(options) {
        return options.map((option) => {
            return {
                value: option.value,
                label: option.label[this.props.lang],
            }
        });
    }

    renderInfoNode() {
        const { openInfoNode, idAvaliacao } = this.state;
        const { lang } = this.props;
    
        return openInfoNode && idAvaliacao && (
            <ModalResumoNodeQuestionario
                idNode={openInfoNode}
                idAvaliacao={idAvaliacao}
                lang={lang}
                changeNode={(idNode) => this.setState({ openInfoNode: idNode })}
                closeModal={() => this.setState({ openInfoNode: null, idAvaliacao: null })}
                changeEstrela={(idNode, idAvaliacao, status) => {
                    this.state.chart.map((linha) => {
                        if (linha.idAvaliacao == idAvaliacao) {
                            linha.resultados = linha.resultados.map((coluna) => {
                                if (coluna.node == idNode) {
                                    coluna.resultados.hasEstrela = status;
                                }
                                return coluna;
                            });
                        }
                        return linha;
                    });
                }}
            />
        );
    }

    renderBody() {
        return (
            <div
                className="containerBody"
                style={{ ...EssentialStyle.columnCenter }}
            >
                {this.renderConfigChart()}
                {this.renderChart()}
                {this.renderInfoNode()}
            </div>
        )
    }

    renderConfigChart() {

        const loadingConfigChart = () => {
            return (
                <div className="containerConfigChart" style={EssentialStyle.columnCenter}>
                    <DefaultLoader size={36} />
                </div>
            )
        }

        const configChart = () => {
            return (
                <div className="containerConfigChart" >

                    <div className="line">
                        <div className="left">
                            <div>
                                <span className="label" style={{ marginLeft: 0 }}>{this.getMessage('labelQuestionario')}</span>
                                <Select
                                    options={DataHelper.formatSelectData(this.state.questionarios, 'id', 'nome')}
                                    value={DataHelper.formatSelectData(this.state.questionarios, 'id', 'nome').find((questionario) => questionario.value == this.state.questionarioSelected)}
                                    styles={{ control: (base) => ({ ...base, minWidth: '10rem', height: '43px', marginTop: '5px' }), menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                    onChange={(e) => { this.setQuestionario(e.value) }}
                                    menuPortalTarget={document.body}
                                    placeholder={this.getMessage('questionarioSelected')}
                                    menuPlacement="auto"
                                    menuPosition="fixed"
                                    isDisabled={this.state.loadingSetQuestionario}
                                />
                            </div>
                            <div>
                                <span className="label" style={{ marginLeft: 0 }}>{this.getMessage('labelTipoAvaliacao')}</span>
                                <Select
                                    options={DataHelper.formatSelectData(this.state.tipos, 'id', 'nome')}
                                    value={DataHelper.formatSelectData(this.state.tipos, 'id', 'nome').find((tipo) => tipo.value == this.state.tipoSelected)}
                                    styles={{ control: (base) => ({ ...base, minWidth: '10rem', height: '43px', marginTop: '5px' }), menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                    onChange={(e) => { this.setTipoAvaliacao(e.value) }}
                                    menuPortalTarget={document.body}
                                    placeholder={this.getMessage('colunaSelected')}
                                    menuPlacement="auto"
                                    menuPosition="fixed"
                                />
                            </div>
                            <div>
                                <span className="label" style={{ marginLeft: 0 }}>{this.getMessage('labelColuna')}</span>
                                <Select
                                    options={DataHelper.formatSelectData(this.state.colunas, 'id', 'descricao')}
                                    value={DataHelper.formatSelectData(this.state.colunas, 'id', 'descricao').find((coluna) => coluna.value == this.state.colunaSelected)}
                                    styles={{ control: (base) => ({ ...base, minWidth: '10rem', height: '43px', marginTop: '5px' }), menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                    onChange={(e) => { this.setNodeColuna(e.value) }}
                                    menuPortalTarget={document.body}
                                    placeholder={this.getMessage('colunaSelected')}
                                    menuPlacement="auto"
                                    menuPosition="fixed"
                                    isDisabled={this.state.loadingSetQuestionario}
                                />
                            </div>

                            <div>
                                <span className="label" style={{ marginLeft: 0 }}>{this.getMessage('labelOrderY')}</span>
                                <Select
                                    options={this.selectGetMessage(this.state.orderY)}
                                    value={this.state.orderYNode == null ? this.selectGetMessage(this.state.orderY).find((item) => item.value == this.state.orderSelectedY) : null}
                                    styles={{ control: (base) => ({ ...base, minWidth: '10rem', height: '43px', marginTop: '5px' }), menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                    onChange={(e) => { this.setOrderY(e.value) }}
                                    menuPortalTarget={document.body}
                                    placeholder={this.getMessage('selectOrder')}
                                    menuPlacement="auto"
                                    menuPosition="fixed"
                                    
                                />
                            </div>

                            <div
                                className="buttonFilter"
                                style={{ backgroundColor: SessionHelper.getColor() }}
                                onClick={() => this.setState({ filters: !this.state.filters })}
                            >
                                {this.state.filterNomeEmpresa.length > 0 && (
                                    <div className="indicadorFiltro">1</div>
                                )}
                                <FontAwesomeIcon icon={this.state.filters ? faChevronUp : faChevronDown} />
                            </div>


                        </div>
                        <div>
                            <span className="label">{this.getMessage('labelBar')}</span>
                            <BarraDemonstracao intervals={this.state.colors} loading={this.state.loadingConfig} width={'20rem'} marginTop={'0px'} />
                        </div>
                    </div>

                    <Collapse in={this.state.filters}>
                        <Form 
                            style={{ width: '100%' }} 
                            onSubmit={(e) => {
                                e.preventDefault();
                                this.filter()
                            }}>

                            <Row className="mb-1" style={{ width: 'calc(100%)' }}>
                                <Form.Group as={Col}>
                                    <Form.Label className={"label"} style={{ marginLeft: 0 }}>{this.getMessage('formEmpresa')}</Form.Label>
                                    <Form.Control
                                        style={{ margin: '0 3px' }}
                                        placeholder={this.getMessage('formEmpresaPlaceHolder')}
                                        value={this.state.filterNomeEmpresa}
                                        onChange={(e) => {
                                            this.setState({ filterNomeEmpresa: e.target.value }, () => this.filter())}
                                        }
                                    />
                                </Form.Group>
                            </Row>

                        </Form>
                    </Collapse>

                </div>
            )
        }

        if (this.state.loadingConfig) return loadingConfigChart();
        return configChart();
    }

    renderChart() {


        const loadingChart = () => {
            return (
                <div className="containerChart" style={EssentialStyle.columnCenter}>
                    <DefaultLoader size={36} />
                </div>
            )
        }

        const chart = () => {

            let mediaGeral = this.state.chart.map((linha) => {
                return linha.resultadoMedia.valor;
            }).reduce((a, b) => a + b, 0) / this.state.chart.length;
            
            return (
                <div className="containerChart" >
                    {/* Cabeçalho da tabela de Mapa de calor */}
                    <div className="line-chart line-header">

                        <div
                            className="cel-chart header-cel"
                            style={{ borderRadius: '8px 0px 0px 8px' }}
                        >
                            {this.getMessage('chartHeader')}
                        </div>

                        {this.state.chart[0].resultados.map((coluna, indexColuna) => {
                            return (
                                <div
                                    key={`cel-chart-${coluna.node}`}
                                    className="cel-chart header-cel"
                                    style={{
                                        fontWeight: this.state.hoverColumn == coluna.node ? '900' : '',
                                        boxShadow: this.state.hoverColumn == coluna.node ? Colors.boxShadow : '',
                                        zIndex: this.state.hoverColumn == coluna.node ? 1 : '',
                                        cursor: 'pointer',
                                    }}
                                    onClick={() => { this.orderByNode(coluna.node) }}
                                >
                                    {this.state.orderYNode && this.state.orderYNode.node == coluna.node &&
                                        <FontAwesomeIcon icon={this.state.orderYNode.order == 1 ? faChevronDown : faChevronUp} style={{ marginRight: 5 }} />
                                    }
                                    <EllipsisText text={coluna.nome} style={{ minWidth: '', maxWidth: 'calc(100% - 20px)' }} />
                                </div>
                            )
                        })}

                        <div
                            className="cel-chart header-cel"
                            style={{
                                borderRadius: '0px 8px 8px 0px',
                                fontWeight: this.state.hoverColumn == 'totalEmpresa' ? '900' : '',
                                boxShadow: this.state.hoverColumn == 'totalEmpresa' ? Colors.boxShadow : '',
                                zIndex: this.state.hoverColumn == 'totalEmpresa' ? 1 : '',
                            }}
                        >
                            {this.getMessage('chartHeaderTotal')}
                            <CustomTooltip
                                tooltip={this.getMessage('chartHeaderTotalI')}
                            >
                                <div className="info">i</div>
                            </CustomTooltip>
                        </div>

                    </div>

                    {/* Corpo da tabela de Mapa de calor */}
                    {this.state.chart.map((linha, indexLinha) => {

                        let media = parseFloat(
                            linha.resultados.map((coluna) => {
                                return coluna.resultados.valor;
                            }).reduce((a, b) => a + b, 0) / linha.resultados.length
                        ).toFixed(1)

                        return (
                            <div key={`linha-chart-${linha.empresaAvaliada.id}`} className="line-chart line-body">

                                <div
                                    className="cel-chart header-cel"
                                    style={{
                                        backgroundColor: indexLinha % 2 == 0 ? 'rgb(170, 170, 170)' : 'rgb(190, 190, 190)',
                                        borderTopLeftRadius: indexLinha == 0 ? '8px' : '0px',
                                        fontWeight: this.state.hoverLine == indexLinha ? '900' : '',
                                        boxShadow: this.state.hoverLine == indexLinha ? Colors.boxShadow : '',
                                        zIndex: this.state.hoverLine == indexLinha ? 1 : '',
                                    }}
                                >
                                    {linha.empresaAvaliada.nome_fantasia}
                                </div>

                                {linha.resultados.map((coluna, indexColuna) => {
                                    return (
                                        <div
                                            key={`cel-chart-${coluna.node}`}
                                            className="cel-chart value-chart"
                                            style={{
                                                backgroundColor: coluna.resultados.cor,
                                                cursor: 'pointer',
                                                position: 'relative',
                                            }}
                                            onMouseEnter={() => this.setState({ hoverLine: indexLinha, hoverColumn: coluna?.node })}
                                            onMouseLeave={() => this.setState({ hoverLine: null, hoverColumn: null })}
                                            onClick={() => this.setState({openInfoNode: coluna.node, idAvaliacao: linha.idAvaliacao})}
                                        >
                                            <div 
                                                style={{
                                                    position: 'absolute',
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    justifyContent: 'end',
                                                    alignItems: 'start',
                                                    top: 5,
                                                    right: 5,
                                                    zIndex: 1,
                                                }}
                                            >
                                                <StarButton 
                                                    idNode={coluna.node}
                                                    idAvaliacao={linha.idAvaliacao}
                                                    isDisabled={true}
                                                    styles={{icon:{fontSize: '0.7rem'}}}
                                                    status={coluna?.resultados?.hasEstrela}
                                                    color={"white"}
                                                />
                                            </div>
                                            

                                            {parseFloat(coluna?.resultados?.valor || 0).toFixed(1)}%

                                            <div className="fixed-icon">
                                                {coluna?.resultados?.hasIndicadorProcessoTree ? (
                                                    coluna?.resultados?.hasIndicadorProcesso ? (
                                                        <FontAwesomeIcon icon={faCertificate}/>
                                                    ) : (
                                                        <div style={{...EssentialStyle.columnStart, alignItems: 'center'}}>
                                                            <FontAwesomeIcon icon={faCertificate} style={{ marginBottom: -3 }}/>
                                                            <FontAwesomeIcon icon={faChevronDown} style={{ fontSize: '0.5rem'}}/>
                                                        </div>
                                                    )
                                                ) : null}

                                                {coluna?.resultados?.hasPlanoAcaoTree ? (
                                                    coluna?.resultados?.hasPlanoAcao ? (
                                                        <FontAwesomeIcon icon={faLightbulb}/>
                                                    ) : (
                                                        <div style={{...EssentialStyle.columnStart, alignItems: 'center'}}>
                                                            <FontAwesomeIcon icon={faLightbulb} style={{ marginBottom: -3 }}/>
                                                            <FontAwesomeIcon icon={faChevronDown} style={{ fontSize: '0.5rem'}}/>
                                                        </div>
                                                    )
                                                ) : null}
                                            </div>
                                        </div>
                                    )
                                })}

                                <div
                                    className="cel-chart value-chart"
                                    style={{
                                        backgroundColor: linha.resultadoMedia.cor,
                                        borderTopRightRadius: indexLinha == 0 ? '8px' : '0px',
                                    }}
                                    onMouseEnter={() => this.setState({ hoverLine: indexLinha, hoverColumn: 'totalEmpresa' })}
                                    onMouseLeave={() => this.setState({ hoverLine: null, hoverColumn: null })}
                                    
                                >
                                    {parseFloat(linha.resultadoMedia.valor).toFixed(1)}%
                                </div>

                                {/* 
                                
                                Cálculo da média da coluna (todas colunas com mesmo peso - descontínuada momentaneamente)
                                <div
                                    className="cel-chart value-chart"
                                    style={{
                                        backgroundColor: DiagnosticoHelper.getColor(this.state.colors, media),
                                        borderTopRightRadius: indexLinha == 0 ? '8px' : '0px',
                                    }}
                                    onMouseEnter={() => this.setState({ hoverLine: indexLinha, hoverColumn: 'totalEmpresa' })}
                                    onMouseLeave={() => this.setState({ hoverLine: null, hoverColumn: null })}
                                >
                                    {media}%
                                </div> 
                                */}

                            </div>
                        )
                    })}

                    {/* Rodapé da tabela de Mapa de calor */}
                    <div className="line-chart line-footer">

                        <div
                            className="cel-chart header-cel"
                            style={{
                                backgroundColor: 'rgb(220, 220, 220)',
                                borderBottomLeftRadius: '8px',
                                fontWeight: this.state.hoverLine == 'totalColuna' ? '900' : '',
                                boxShadow: this.state.hoverLine == 'totalColuna' ? Colors.boxShadow : '',
                                zIndex: this.state.hoverLine == 'totalColuna' ? 1 : '',
                            }}
                        >
                            {this.getMessage('chartFooter')}
                        </div>

                        {/* {this.state.chartFooter.map((coluna, indexColunaFooter) => {
                            return (
                                <div 
                                    key={`cel-chart-footer-${coluna.node}`} 
                                    className="cel-chart value-chart"
                                    style={{ 
                                        backgroundColor: coluna.cor,
                                        borderBottomRightRadius: indexColunaFooter == (this.state.chartFooter.length -1) ? '8px' : '0px',
                                    }}
                                    onMouseEnter={() => this.setState({ hoverLine: 'totalColuna', hoverColumn: coluna.node != null ? coluna.node : 'totalEmpresa' })}
                                    onMouseLeave={() => this.setState({ hoverLine: null, hoverColumn: null })}
                                >
                                    {parseFloat(coluna.valor).toFixed(1)}%
                                </div>
                            )
                        })} */}

                        {this.state.chart[0].resultados.map((node) => {
                            let idNode = node.node;
                            let listSum = this.state.chart.map((linha) => {
                                return linha.resultados.find((result) => result.node == idNode).resultados.valor;
                            });

                            let media = listSum.reduce((a, b) => a + b, 0) / listSum.length;

                            return (
                                <div
                                    key={`cel-chart-footer-${idNode}`}
                                    className="cel-chart value-chart"
                                    style={{
                                        backgroundColor: DiagnosticoHelper.getColor(this.state.colors, media),
                                        borderTop: '1px solid rgb(220, 220, 220)',
                                    }}
                                    onMouseEnter={() => this.setState({ hoverLine: 'totalColuna', hoverColumn: idNode })}
                                    onMouseLeave={() => this.setState({ hoverLine: null, hoverColumn: null })}
                                >
                                    {parseFloat(media).toFixed(1)}%
                                </div>
                            )
                        })}

                        <div
                            key={`cel-chart-footer-total-empresa`}
                            className="cel-chart value-chart"
                            style={{
                                backgroundColor: DiagnosticoHelper.getColor(this.state.colors, mediaGeral),
                                borderBottomRightRadius: '8px',
                                borderTop: '1px solid rgb(220, 220, 220)',
                            }}
                            onMouseEnter={() => this.setState({ hoverLine: 'totalColuna', hoverColumn: 'totalEmpresa' })}
                            onMouseLeave={() => this.setState({ hoverLine: null, hoverColumn: null })}
                        >
                            { parseFloat(mediaGeral).toFixed(1) }%
                        </div>

                    </div>
                </div>
            )
        }

        const checks = [
            { condition: this.state.questionarios?.length == 0, message: 'hasNotQuestionarios' },
            { condition: this.state.tipos?.length == 0, message: 'hasNotTipoAvaliacao' },
            { condition: this.state.colunas?.length == 0, message: 'hasNotColuna' },
            { condition: !this.state.questionarioSelected, message: 'notSelectQuestionarios' },
            { condition: !this.state.tipoSelected, message: 'notSelectTipoAvaliacao' },
            { condition: !this.state.colunaSelected, message: 'notSelectColuna' },
        ];

        if (this.state.loadingChart) return loadingChart();
        for (let check of checks) {
            if (check.condition) return DiagnosticoHelper.renderEmpty(this.getMessage(check.message), { width: '100%', flex: '1' }, { height: '13rem' }, this.props.t);
        }
        if (this.state.chart.length == 0) return DiagnosticoHelper.renderEmpty(this.getMessage('empresaEmpty'), { width: '100%', flex: '1' }, { height: '13rem' }, this.props.t);
        return chart();
    }

    render() {
        if (this.state.loading) return <div style={{ ...EssentialStyle.rowFlexCenter, width: '100%', height: '100%' }}><DefaultLoader size={36} /></div>
        return this.renderBody();
    }
}

export default withTranslation()(GraficoMapaCalor);