import React from "react";
import { withTranslation } from "react-i18next";
import DataHelper from "../../../helper/DataHelper";
import { Form, InputGroup, Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faCalendarDays, faTimes, faCircleChevronLeft, faCircleChevronRight } from "@fortawesome/free-solid-svg-icons";
import AddTag from "../../tools/AddTag";
import Select from 'react-select'
import DefaultButton from "../../tools/DefaultButton";
import moment from "moment";
import Colors from "../../../constants/Colors";
import Sig from "../../../api/Sig";
import DatePicker from "react-datepicker";
import { forwardRef } from 'react';
import DefaultLoader from "../../tools/DefaultLoader";
import EssentialStyle from "../../../style/EssentialStyle";
import LayoutHelper from "../../../helper/LayoutHelper";
import Tabs from "../../tools/Tabs";
import DatePickerHelper from "../../../helper/DatePickerHelper";
import DiagnosticoHelper from "../../../helper/diagnostico/DiagnosticoHelper";
import IdiomaHelper from "../../../helper/IdiomaHelper";

class PlanoAcaoListSearch extends React.Component {
    state = {
        nodesQuestionario: [],
        selectedNodesQuestionario: null,
        indicadoresVinculados: [],
        indicadoresVinculadosSelecionados: null,
        loading: true,
        typingTimeout: null,
        name: this.props.nome,
        tipoPlano: null,
        vinculos: null,
        status: null,
        tags: null,
        colabs: [],
        responsables: [],
        dataInicio: null,
        dataFim: null,
        colabsList: [],
        tipoPlanoList: [],
        vinculoList: [],
        statusList: [],
        parceirosList: [],
        parceiros: [],
        parceirosChecked: this.props.parceirosChecked,
        isSmallScreen: this.props.isSmallScreen,
        type: this.props.type,
        activeTab: 'cadastro',
        lang: IdiomaHelper.getIdioma(),
        zIndex: 30
    }

    async componentDidMount() {
        await this.props.i18n.loadNamespaces(['client_src_components_modules_planoAcao_PlanoAcaoListSearch']);
        await this.loadData();

        if (this.state.type === 'relatorio') {
            this.setState({ activeTab: 'cadastro', dataInicio: moment().subtract(12, 'months').toDate(), dataFim: moment().toDate() }, () => this.filterCallback());
        }

        this.setState({ name: this.props.nome || null });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isSmallScreen !== this.props.isSmallScreen) {
            this.setState({ isSmallScreen: this.props.isSmallScreen });
        }
        if (prevProps.type !== this.props.type) {
            this.setState({ type: this.props.type }, () => {
                if (this.state.type === 'relatorio') {
                    this.setState({ activeTab: 'cadastro', dataInicio: moment().subtract(12, 'months').toDate(), dataFim: moment().toDate() }, () => this.filterCallback());
                } else {
                    this.setState({ activeTab: 'cadastro', dataInicio: null, dataFim: null, status: null }, () => this.filterCallback());
                }
            });
        }

        if (this.props.nome != prevProps.nome || (this.props.advancedFilter && this.props.advancedFilter != prevProps.advancedFilter && this.props.nome != this.state.name)) {
            this.setState({ name: this.props.nome });
        }

        if (this.props.parceirosChecked != prevProps.parceirosChecked) {
            this.setState({ parceirosChecked: this.props.parceirosChecked }, () => { this.loadData(); });
        }
    }

    async loadData() {
        this.setState({ loading: true });

        let req = await Sig.request('GET', 'planoAcao/getFilterList', { parceiro: this.state.parceirosChecked ? 1 : 0 });

        if (req && req.status === 200) {
            this.setState({ colabsList: req.colaboradores, tipoPlanoList: req.tipos, vinculoList: req.vinculos, statusList: req.statusList, parceirosList: req.parceirosList });
        }

        if (this.state.type === 'relatorioDiagnostico' && this.props.idPeriodoAvaliacao) {
            await this.getNodesQuestionario();
        }

        this.setState({ loading: false })
    }

    getNodesQuestionario = async () => {
        var nodesQuestionario = [];
        var indicadoresVinculados = [];
        var { colunas } = await DiagnosticoHelper.getColunasQuestionario(null, this.props.idPeriodoAvaliacao, null, null);

        if (colunas && colunas.length > 0) {
            let idiomaId = IdiomaHelper.getSiglaId(this.state.lang);

            await Promise.all(colunas.map(async (coluna) => {
                var { nodes } = await DiagnosticoHelper.getItensColuna(coluna.id, IdiomaHelper.getIdioma(), 2);
                nodes = nodes.filter(node => node.planos_acao?.length > 0).map(node => {
                    let descricao = '';

                    for (let obj of coluna.descricao) {
                        if (obj.id_idioma == idiomaId) {
                            descricao = obj.descricao;
                            break;
                        }
                    }
                    return { ...node, descricao: descricao + ": " + node.descricao };
                });

                nodesQuestionario = [...nodesQuestionario, ...nodes];

                if (nodes && nodes.length > 0) {
                    for (let node of nodes) {
                        indicadoresVinculados = [...indicadoresVinculados, ...node.indicadores];
                    }
                }
            }));

            this.setState({ nodesQuestionario, indicadoresVinculados });
        }
    }

    limpar = () => {
        this.setState({
            loading: true,
            activeTab: 'cadastro',
            nome: null,
            tipoPlano: null,
            vinculos: null,
            status: null,
            tags: null,
            colabs: [],
            responsables: [],
            dataInicio: this.state.type == 'relatorio' ? moment().subtract(12, 'months').toDate() : null,
            dataFim: this.state.type == 'relatorio' ? moment().toDate() : null,
            parceiros: [],
            parceirosChecked: this.props.parceirosChecked,
            selectedNodesQuestionario: null,
            indicadoresVinculadosSelecionados: null,
        }, () => {
            this.filterCallback();
            this.setState({ loading: false })
        });
    }

    renderDatePickerPeriodo() {
        const CustomPicker = forwardRef(({ value, onClick }, ref) => (
            <Form.Control onClick={onClick} ref={ref} value={value} readOnly />
        ));

        let dataMoment1 = this.state.dataInicio ? moment(this.state.dataInicio).toDate() : null;
        let dataMoment2 = this.state.dataFim ? moment(this.state.dataFim).toDate() : null;

        const tabs = [
            {
                name: 'inicio',
                title: this.props.i18n.t('client_src_components_modules_planoAcao_PlanoAcaoListSearch:data_de_inicio'),
                icon:
                    <div style={{ position: "relative" }}>
                        <FontAwesomeIcon style={{ fontSize: 20 }} icon={faCalendarDays} />
                        <FontAwesomeIcon style={{ position: "absolute", fontSize: 12, bottom: 0, left: -5 }} icon={faCircleChevronLeft} />
                    </div>
            },
            {
                name: 'cadastro',
                title: this.props.i18n.t('client_src_components_modules_planoAcao_PlanoAcaoListSearch:data_de_cadastro'),
                icon:
                    <div>
                        <FontAwesomeIcon style={{ fontSize: 20 }} icon={faCalendarDays} />
                    </div>
            },
            {
                name: 'fim',
                title: this.props.i18n.t('client_src_components_modules_planoAcao_PlanoAcaoListSearch:data_de_fim'),
                icon:
                    <div style={{ position: "relative" }}>
                        <FontAwesomeIcon style={{ fontSize: 20 }} icon={faCalendarDays} />
                        <FontAwesomeIcon style={{ position: "absolute", fontSize: 12, bottom: 0, right: -5 }} icon={faCircleChevronRight} />
                    </div>
            },
        ];

        return (
            <div className="d-flex flex-row gap-3">
                <DatePicker
                    wrapperClassName="w-100"
                    selected={dataMoment1}
                    startDate={dataMoment1}
                    endDate={dataMoment2}
                    selectsRange
                    onChange={(dates) => {
                        const [start, end] = dates;
                        this.setState({ dataInicio: start, dataFim: end });
                    }}
                    dateFormat={this.state.type === 'relatorio' || this.state.type === 'relatorioDiagnostico' ? "dd/MM/yyyy" : "MM/yyyy"}
                    showMonthYearPicker={this.state.type === 'relatorio' || this.state.type === 'relatorioDiagnostico' ? false : true}
                    customInput={<CustomPicker />}
                    renderCustomHeader={(props) =>
                        DatePickerHelper.renderCustomDatePickerHeader(props)
                    }
                />
                {this.state.type == 'relatorio' &&
                    <Tabs
                        isSmallScreen={this.state.isSmallScreen}
                        tabs={tabs}
                        activeTab={this.state.activeTab}
                        onTabChange={this.handleTabChange}
                        tabStyle={{ width: 50 }}
                        style={{ marginTop: 0 }}
                        onlyIcon={true}
                    />
                }
            </div>
        );
    }

    filterCallback = (closeFilter = false) => {

        this.props.filterCallback({
            nome: this.state.name,
            tipoData: this.state.activeTab ? this.state.activeTab : null,
            tipoPlano: this.state.tipoPlano ? this.state.tipoPlano.map(item => item.value) : null,
            vinculos: this.state.vinculos ? this.state.vinculos.map(item => item.value) : null,
            status: this.state.status ? this.state.status.map(item => item.value) : null,
            tags: this.state.tags ? this.state.tags.map(item => item.value) : null,
            responsables: this.state.responsables ? this.state.responsables.map(item => item.value) : null,
            colabs: this.state.colabs ? this.state.colabs.map(item => item.value) : null,
            dataInicio: this.state.dataInicio ? DataHelper.getDefaultDbDateFormat(this.state.dataInicio) : null,
            dataFim: this.state.dataFim ? DataHelper.getDefaultDbDateFormat(this.state.dataFim) : null,
            parceiros: this.state.parceiros ? this.state.parceiros.map(item => item.value) : null,
            parceirosChecked: this.state.parceirosChecked,
            selectedNodesQuestionario: this.state.selectedNodesQuestionario ? this.state.selectedNodesQuestionario.map(item => item.value) : null,
            indicadoresVinculadosSelecionados: this.state.indicadoresVinculadosSelecionados ? this.state.indicadoresVinculadosSelecionados.map(item => item.value) : null,
        }, closeFilter);
    }

    handleTabChange = (tab) => {
        if (this.state.activeTab === tab.name) return;
        var dataInicio = this.state.dataInicio;
        var dataFim = this.state.dataFim;
        this.setState({ activeTab: tab.name, dataInicio, dataFim });
    }

    renderLoading() {
        return (
            <div className="box-advancedSearch">
                <Form className="w-100" style={{ minHeight: 330, ...EssentialStyle.columnCenter }}>
                    <DefaultLoader />
                </Form>
            </div>
        )
    }

    renderNameFilter() {
        return (
            <Form.Group as={Col}>
                <Form.Label className="form-label-xsm">{this.props.i18n.t('client_src_components_modules_planoAcao_PlanoAcaoListSearch:nome_do_plano_de_acao')}</Form.Label>
                <Form.Control className="form-control-xsm" type="text" placeholder={this.props.i18n.t('client_src_components_modules_planoAcao_PlanoAcaoListSearch:placeholder_nome')} value={this.state.name || ''} onChange={(event) => { this.setState({ name: event.target.value }) }} />
            </Form.Group>
        );
    }

    renderTypeFilter() {
        return (
            <Form.Group as={Col}>
                <Form.Label className="form-label-xsm">{this.props.i18n.t('client_src_components_modules_planoAcao_PlanoAcaoListSearch:tipo_de_plano_de_acao')}</Form.Label>
                <Select
                    className="form-control-xsm"
                    options={DataHelper.formatSelectData(this.state.tipoPlanoList, 'id', 'nome').map((item, index) => {
                        return {
                            ...item,
                            label: this.props.i18n.t(`client_src_components_modules_planoAcao_PlanoAcaoListSearch:tipo_de_plano_de_acao_${index}`)
                        }
                    })}
                    placeholder={this.props.i18n.t('client_src_components_modules_planoAcao_PlanoAcaoListSearch:selecione_um_tipo')}
                    noOptionsMessage={DataHelper.getSelectEmptyMessage}
                    isClearable
                    isSearchable
                    isMulti
                    value={this.state.tipoPlano}
                    onChange={(value) => { this.setState({ tipoPlano: value }); }}
                    menuPortalTarget={document.body}
                    styles={{ menuPortal: base => ({ ...base, zIndex: this.state.zIndex }) }}
                />
            </Form.Group>
        );
    }

    renderStatusFilter() {
        return (
            <Form.Group as={Col}>
                <Form.Label className="form-label-xsm">{this.props.i18n.t('client_src_components_modules_planoAcao_PlanoAcaoListSearch:status_text')}</Form.Label>
                <Select
                    className="form-control-xsm"
                    options={DataHelper.formatSelectData(this.state.statusList, 'id', 'nome').map((item, index) => {
                        return {
                            ...item,
                            label: this.props.i18n.t(`client_src_components_modules_planoAcao_PlanoAcaoListSearch:status_${index}`)
                        }

                    })}
                    placeholder={this.props.i18n.t('client_src_components_modules_planoAcao_PlanoAcaoListSearch:selecione_um_status')}
                    noOptionsMessage={DataHelper.getSelectEmptyMessage}
                    isClearable
                    isSearchable
                    isMulti
                    value={this.state.status}
                    onChange={(value) => { this.setState({ status: value }); }}
                    menuPortalTarget={document.body}
                    styles={{ menuPortal: base => ({ ...base, zIndex: this.state.zIndex }) }}
                />
            </Form.Group>
        );
    }

    renderVinculoFilter() {
        return (
            <Form.Group as={Col}>
                <Form.Label className="form-label-xsm">{this.props.i18n.t('client_src_components_modules_planoAcao_PlanoAcaoListSearch:vinculo_com_resultados')}</Form.Label>
                <Select
                    className="form-control-xsm"
                    options={DataHelper.formatSelectData(this.state.vinculoList, 'id', 'nome').map((item) => {
                        return {
                            ...item,
                            label: this.props.i18n.t(`client_src_components_modules_planoAcao_PlanoAcaoListSearch:vinculo_${item.value}`)
                        }
                    })}
                    placeholder={this.props.i18n.t('client_src_components_modules_planoAcao_PlanoAcaoListSearch:selecione_um_vinculo')}
                    noOptionsMessage={DataHelper.getSelectEmptyMessage}
                    isClearable
                    isSearchable
                    isMulti
                    value={this.state.vinculos}
                    onChange={(value) => { this.setState({ vinculos: value }); }}
                    menuPortalTarget={document.body}
                    styles={{ menuPortal: base => ({ ...base, zIndex: this.state.zIndex }) }}
                />
            </Form.Group>
        );
    }

    renderTagsFilter() {
        return (
            <Form.Group as={Col}>
                <AddTag value={this.state.tags} fontSize={"xsm"} onChange={(value) => { this.setState({ tags: value }) }} zIndex={this.state.zIndex} parceiro={this.state.parceirosChecked} />
            </Form.Group>
        );
    }

    renderResponsablesFilter() {
        return (
            <Form.Group as={Col}>
                <Form.Label className="form-label-xsm">{this.props.i18n.t('client_src_components_modules_planoAcao_PlanoAcaoListSearch:responsavel')}</Form.Label>
                <Select
                    className="form-control-xsm"
                    options={DataHelper.formatSelectData(this.state.colabsList.filter(colab => !this.state.colabs.flatMap(colab => colab.value).includes(colab.id)), 'id', 'nome')}
                    placeholder={this.props.i18n.t('client_src_components_modules_planoAcao_PlanoAcaoListSearch:selecione_os_colaboradores')}
                    noOptionsMessage={DataHelper.getSelectEmptyMessage}
                    isClearable
                    isSearchable
                    isMulti
                    value={this.state.responsables}
                    onChange={(values) => { this.setState({ responsables: values }); }}
                    menuPortalTarget={document.body}
                    styles={{ menuPortal: base => ({ ...base, zIndex: this.state.zIndex }) }}
                />
            </Form.Group>
        );
    }

    renderPeopleFilter() {
        return (
            <Form.Group as={Col}>
                <Form.Label className="form-label-xsm">{this.props.i18n.t('client_src_components_modules_planoAcao_PlanoAcaoListSearch:colaboradores')}</Form.Label>
                <Select
                    className="form-control-xsm"
                    options={DataHelper.formatSelectData(this.state.colabsList.filter(colab => !this.state.responsables.flatMap(responsavel => responsavel.value).includes(colab.id)), 'id', 'nome')}
                    placeholder={this.props.i18n.t('client_src_components_modules_planoAcao_PlanoAcaoListSearch:selecione_os_colaboradores')}
                    noOptionsMessage={DataHelper.getSelectEmptyMessage}
                    isClearable
                    isSearchable
                    isMulti
                    value={this.state.colabs}
                    onChange={(value) => { this.setState({ colabs: value }); }}
                    menuPortalTarget={document.body}
                    styles={{ menuPortal: base => ({ ...base, zIndex: this.state.zIndex }) }}
                />
            </Form.Group>
        );
    }

    renderPeriodoFilter() {
        return (
            <Form.Group as={Col}>
                <Form.Label className="form-label-xsm">{this.props.i18n.t('client_src_components_modules_planoAcao_PlanoAcaoListSearch:periodo')}</Form.Label>
                {this.renderDatePickerPeriodo()}
            </Form.Group>
        );
    }

    renderParceiroFilter() {
        return (
            <Form.Group as={Col}>
                <Form.Label className="form-label-xsm">{this.props.i18n.t('client_src_components_modules_planoAcao_PlanoAcaoListSearch:empresas_parceiras')}</Form.Label>
                <Select
                    className="form-control-xsm"
                    options={DataHelper.formatSelectData(this.state.parceirosList, 'id', 'nome')}
                    placeholder={this.props.i18n.t('client_src_components_modules_planoAcao_PlanoAcaoListSearch:selecione_as_empresas_parceiras')}
                    noOptionsMessage={DataHelper.getSelectEmptyMessage}
                    isClearable
                    isSearchable
                    isDisabled={!this.state.parceirosChecked}
                    isMulti
                    value={this.state.parceiros}
                    onChange={(value) => { this.setState({ parceiros: value }); }}
                    menuPortalTarget={document.body}
                    styles={{ menuPortal: base => ({ ...base, zIndex: this.state.zIndex }) }}
                />
            </Form.Group>
        );
    }

    renderButtons() {
        return (
            <div className="mb-0 d-flex flex-row-reverse">
                <Form.Group>
                    <DefaultButton className="me-1" color={Colors.error} leftIcon={<FontAwesomeIcon icon={faTimes} />} title={this.props.i18n.t('client_src_components_modules_planoAcao_PlanoAcaoListSearch:limpar')} loading={this.state.loading} onClick={this.limpar} />
                    <DefaultButton color={Colors.success} leftIcon={<FontAwesomeIcon icon={faSearch} />} title={this.props.i18n.t('client_src_components_modules_planoAcao_PlanoAcaoListSearch:filtrar')} loading={this.state.loading} onClick={() => this.filterCallback(true)} />
                </Form.Group>
            </div>
        );
    }

    renderQuestionarioDiagnosticosFilter() {
        return (
            <Form.Group as={Col} xs={12} md={6}>
                <Form.Label className="form-label-xsm">{this.props.i18n.t('client_src_components_modules_planoAcao_PlanoAcaoListSearch:questionario_diagnostico')}</Form.Label>
                <Select
                    className="form-control-xsm"
                    options={DataHelper.formatSelectData(this.state.nodesQuestionario, 'id', 'descricao')}
                    placeholder={this.props.i18n.t('client_src_components_modules_planoAcao_PlanoAcaoListSearch:selecione_o_questionario_diagnostico')}
                    noOptionsMessage={DataHelper.getSelectEmptyMessage}
                    isClearable
                    isSearchable
                    isMulti
                    value={this.state.selectedNodesQuestionario}
                    onChange={(value) => { this.setState({ selectedNodesQuestionario: value }); }}
                    menuPortalTarget={document.body}
                    styles={{ menuPortal: base => ({ ...base, zIndex: this.state.zIndex }) }}
                />
            </Form.Group>
        );
    }

    renderIndicadorFilter() {
        return (
            <Form.Group as={Col} xs={12} md={6}>
                <Form.Label className="form-label-xsm">{this.props.i18n.t('client_src_components_modules_planoAcao_PlanoAcaoListSearch:indicador_vinculado_ao_questionario')}</Form.Label>
                <Select
                    className="form-control-xsm"
                    options={DataHelper.formatSelectData(this.state.indicadoresVinculados, 'id', 'descricao')}
                    placeholder={this.props.i18n.t('client_src_components_modules_planoAcao_PlanoAcaoListSearch:selecione_o_indicador_questionario')}
                    noOptionsMessage={DataHelper.getSelectEmptyMessage}
                    isClearable
                    isSearchable
                    isMulti
                    value={this.state.indicadoresVinculadosSelecionados}
                    onChange={(value) => { this.setState({ indicadoresVinculadosSelecionados: value }); }}
                    menuPortalTarget={document.body}
                    styles={{ menuPortal: base => ({ ...base, zIndex: this.state.zIndex }) }}
                />
            </Form.Group>
        );
    }

    renderDesktop() {
        return (
            <div className="box-advancedSearch">
                <Form className="w-100">
                    <Row className="mb-3">
                        {this.renderNameFilter()}
                        {this.renderTypeFilter()}
                    </Row>

                    <Row className="mb-1">
                        {this.renderStatusFilter()}
                        {this.renderVinculoFilter()}
                        {this.renderTagsFilter()}
                    </Row>

                    <Row className="mb-3">
                        {!this.state.parceirosChecked && this.renderResponsablesFilter()}
                        {!this.state.parceirosChecked && this.renderPeopleFilter()}
                        {this.renderPeriodoFilter()}
                    </Row>

                    {this.state.parceirosList.length > 0 &&
                        <Row className="mb-3">
                            {this.renderParceiroFilter()}
                        </Row>
                    }

                    {this.renderButtons()}
                </Form>
            </div>
        );
    }

    renderMobile() {
        return (
            <div
                className="box-advancedSearch"
                style={{
                    maxHeight: `calc(${window.innerHeight - 150}px - ${this.props.isSmallScreen ? '7rem' : '0px'})`,
                    overflowY: 'auto'
                }}
            >
                <Form className="w-100">
                    <Row className="mb-3">{this.renderNameFilter()}</Row>
                    <Row className="mb-3">{this.renderTypeFilter()}</Row>
                    <Row className="mb-3">{this.renderStatusFilter()}</Row>
                    <Row className="mb-3">{this.renderVinculoFilter()}</Row>
                    <Row className="mb-1">{this.renderTagsFilter()}</Row>
                    {!this.state.parceirosChecked && <Row className="mb-3">{this.renderResponsablesFilter()}</Row>}
                    {!this.state.parceirosChecked && <Row className="mb-3">{this.renderPeopleFilter()}</Row>}
                    <Row className="mb-3">{this.renderPeriodoFilter()}</Row>
                    {this.state.parceirosList.length > 0 && <Row className="mb-3">{this.renderParceiroFilter()}</Row>}
                    {this.renderButtons()}
                </Form>
            </div>
        );
    }

    renderRelatorioDiagnostico() {
        if (this.state.isSmallScreen) {
            return (
                <div
                    className="box-advancedSearch"
                    style={{
                        maxHeight: `calc(${window.innerHeight - 150}px - ${this.props.isSmallScreen ? '7rem' : '0px'})`,
                        overflowY: 'auto'
                    }}
                >
                    <Form className="w-100">
                        <Row className="mb-3">{this.renderNameFilter()}</Row>
                        <Row className="mb-3">{this.renderStatusFilter()}</Row>
                        <Row className="mb-3">{this.renderTypeFilter()}</Row>
                        <Row className="mb-3">{this.renderQuestionarioDiagnosticosFilter()}</Row>
                        <Row className="mb-3">{this.renderIndicadorFilter()}</Row>
                        <Row className="mb-1">{this.renderTagsFilter()}</Row>
                        {!this.state.parceirosChecked && <Row className="mb-3">{this.renderResponsablesFilter()}</Row>}
                        {!this.state.parceirosChecked && <Row className="mb-3">{this.renderPeopleFilter()}</Row>}
                        <Row className="mb-3">{this.renderPeriodoFilter()}</Row>
                        {this.state.parceirosChecked && this.state.parceirosList.length > 0 && <Row className="mb-3">{this.renderParceiroFilter()}</Row>}
                        {this.renderButtons()}
                    </Form>
                </div>
            );
        } else {
            return (
                <div className="box-advancedSearch">
                    <Form className="w-100">
                        <Row className="mb-3">
                            {this.renderNameFilter()}
                            {this.renderStatusFilter()}
                            {this.renderTypeFilter()}
                        </Row>

                        <Row className="mb-1">
                            {this.renderQuestionarioDiagnosticosFilter()}
                            {this.renderIndicadorFilter()}
                        </Row>

                        <Row className="mb-3">
                            {this.renderTagsFilter()}
                            {!this.state.parceirosChecked && this.renderResponsablesFilter()}
                            {!this.state.parceirosChecked && this.renderPeopleFilter()}
                            {this.renderPeriodoFilter()}
                            {this.state.parceirosChecked && this.state.parceirosList.length > 0 && this.renderParceiroFilter()}
                        </Row>


                        {this.renderButtons()}
                    </Form>
                </div>
            );
        }
    }

    render() {
        return this.state.loading ? this.renderLoading() : (this.props.type == 'relatorioDiagnostico' ? this.renderRelatorioDiagnostico() : (this.state.isSmallScreen ? this.renderMobile() : this.renderDesktop()));
    }
}

export default withTranslation()(PlanoAcaoListSearch);