import React from "react";

import "./ModalUploadResultadosAvaliacao.css";
import Modal from "../../../../tools/Modal";
import IdiomaHelper from "../../../../../helper/IdiomaHelper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload, faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import EssentialStyle from "../../../../../style/EssentialStyle";
import DefaultButton from "../../../../tools/DefaultButton";
import Colors from "../../../../../constants/Colors";
import DefaultLoader from "../../../../tools/DefaultLoader";
import DiagnosticoHelper from "../../../../../helper/diagnostico/DiagnosticoHelper";
import DataHelper from "../../../../../helper/DataHelper";
import Select from "react-select";
import SessionHelper from "../../../../../helper/SessionHelper";
import TableImporter from "../../../../tools/TableImporter/TableImporter";
import { withTranslation } from 'react-i18next';


class ModalUploadResultadosAvaliacao extends React.Component {

    state = {
        lang: IdiomaHelper.getIdioma(),

        loadingAvaliacoes: true,
        loadingDownloadModelo: false,

        importing: false,
        linesImported: 0,
        importComplete: false,

        openImporter: false,

        avaliacoes: [],
    }

    async componentDidMount() {
        await this.props.i18n.loadNamespaces(['client_src_helper_diagnostico_DiagnosticoHelper']);

        this.loadQuestionarios();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.idPeriodoAvaliacao !== this.props.idPeriodoAvaliacao) {
            this.loadQuestionarios();
        }
    }

    loadQuestionarios = async () => {
        this.setState({ loadingAvaliacoes: true });

        let avaliacoes = await DiagnosticoHelper.getAvaliacoes([0, 1, 2], this.props.idPeriodoAvaliacao, 1);
        let { id } = await SessionHelper.getUser();

        this.setState({ avaliacoes, idColaborador: id, loadingAvaliacoes: false });
    }

    loadModeloFile = async () => {
        this.setState({ loadingDownloadModelo: true });

        let { modelo } = await DiagnosticoHelper.getModeloAvaliacaoRespostas(this.state.avaliacoes);

        this.setState({ loadingDownloadModelo: false, openImporter: true, modelo });
    }

    importData = async (data) => {
        this.setState({ importing: true });

        // Função para enviar um chunk ao backend
        const sendChunk = async (chunk) => {
            const response = await DiagnosticoHelper.importAvaliacaoRespostas(chunk);

            if (response.error) {
                this.setState({ fileErrors: response.fileErrors, importing: false, importComplete: false });
                return false;
            } else {
                this.setState({ linesImported: this.state.linesImported + chunk.length });
                return true;
            }
        };

        // Enviar todos os chunks sequencialmente
        const sendAllChunks = async () => {
            for (const chunk of dataChunks) {
                let success = await sendChunk(chunk);
                if (!success) return;
            }
            this.setState({ importing: false, importComplete: true });
        };

        let completeData = data;
        // completeData.shift();

        let dataChunks = [];
        const chunkSize = 500;
        for (let i = 0; i < completeData.length; i += chunkSize) {
            dataChunks.push(completeData.slice(i, i + chunkSize));
        }

        await sendAllChunks();
    }

    message(lang = this.state.lang) {
        let message =  IdiomaHelper.getStruct({
            'pt_br': {
                title: 'Importação de Resultados de Avaliação',
                subTitle: 'Selecione as avaliações que deseja fazer a importação dos resultados',
                observacoes1: "Preencha SOMENTE as colunas de <strong>resposta</strong> e <strong>observacao</strong>.",
                observacoes2: 'Ao enviar os resultados, as avaliações serão finalizadas e não poderão ser alteradas.',
                buttonUpload: 'Upload',
                close: 'Fechar',
                back: 'Voltar',

                tableTitleQuestionario: 'Questionário',
                tableTitleTiposAvaliacao: 'Tipo de Avaliação',
                tableTitleEmpresas: 'Empresas',
                tableTitleStatus: 'Status',
                tableTitleSelect: 'Seletor',
                emptyAvaliacoes: 'Nenhuma avaliação disponível para importação de resultados.',
            },
            'en': {
                title: 'Upload Evaluation Results',
                subTitle: 'Select the evaluations you want to upload the results',
                observacoes1: 'Fill in ONLY the <strong>answer</strong> and <strong>observation</strong> columns.',
                observacoes2: 'By sending the results, the evaluations will be finalized and cannot be changed.',
                buttonUpload: 'Upload',
                close: 'Close',
                back: 'Back',

                tableTitleQuestionario: 'Questionnaire',
                tableTitleTiposAvaliacao: 'Evaluation Type',
                tableTitleEmpresas: 'Companies',
                tableTitleStatus: 'Status',
                tableTitleSelect: 'Selector',
                emptyAvaliacoes: 'No evaluations available for result upload.',
            },
            'es': {
                title: 'Upload Resultados de Evaluación',
                subTitle: 'Seleccione las evaluaciones que desea cargar los resultados',
                observacoes1: 'Complete SOLAMENTE las columnas de <strong>respuesta</strong> y <strong>observación</strong>.',
                observacoes2: 'Al enviar los resultados, las evaluaciones se finalizarán y no se podrán cambiar.',
                buttonUpload: 'Upload',
                close: 'Cerrar',
                back: 'Volver',
                
                tableTitleQuestionario: 'Cuestionario',
                tableTitleTiposAvaliacao: 'Tipo de Evaluación',
                tableTitleEmpresas: 'Empresas',
                tableTitleStatus: 'Estado',
                tableTitleSelect: 'Selector',
                emptyAvaliacoes: 'No hay evaluaciones disponibles para cargar resultados.',
            }

        })

        return message[lang];
    }

    renderTitle() {
        
        return (
            <div className="titleModal">
                <div className="titleLeft">
                    <FontAwesomeIcon icon={faUpload} />
                    {`${this.message().title}`}
                </div>
            </div>
        )
    }

    renderBody() {

        const loading = () => {
            return (
                <div className="bodyModal loading">
                    <DefaultLoader/>
                </div>
            )
        }

        const avaliacoesCheck = () => {

            const cellCheck = (avaliacao) => {
                return (
                    <div className="cellCheck"  style={{ width: '100%' }}>
                        <input 
                            type="checkbox" 
                            id={`check-avaliacao-${avaliacao.id}`}
                            onChange={(event) => {
                                let avaliacoes = this.state.avaliacoes.map(av => {
                                    if (av.id === avaliacao.id) {
                                        av.isCheck = event.target.checked;
                                        if (av.isCheck) {
                                            if (av.avaliadores.length > 0) {
                                                let avaliador = av.avaliadores.find(avaliador => avaliador.id === this.state.idColaborador);
                                                av.avaliador = avaliador ? avaliador : av.avaliadores[0];
                                            } else {
                                                av.avaliador = {};
                                            }
                                        } else {
                                            av.avaliador = {};
                                        }
                                    }
                                    return av;
                                });

                                this.setState({ avaliacoes });
                            }}
                        />

                        {avaliacao.isCheck &&
                            <Select
                                options={DataHelper.formatSelectData(avaliacao.avaliadores, "id", "nome")}
                                value={DataHelper.formatSelectData(avaliacao.avaliadores, "id", "nome").find(avaliador => avaliador.value === avaliacao.avaliador.id)}
                                onChange={(event) => {
                                    let avaliadores = avaliacao.avaliadores;
                                    let avaliador = avaliadores.find(avaliador => avaliador.id === event.value);

                                    let avaliacoes = this.state.avaliacoes.map(av => {
                                        if (av.id === avaliacao.id) {
                                            av.avaliador = avaliador;
                                        }
                                        return av;
                                    });

                                    this.setState({ avaliacoes });
                                }}
                            />
                        }

                    </div>
                );
            }

            let hasCheck = this.state.avaliacoes.filter(avaliacao => avaliacao.isCheck).length > 0;

            return (
                <div className="bodyModal">
                    <div className="topHeader">
                        <div className="subTitleContainer">
                            <div className="subTitle">{this.message().subTitle}</div>
                            <div className="observacoes"><FontAwesomeIcon icon={faExclamationTriangle}/> <span dangerouslySetInnerHTML={ { __html: this.message().observacoes1 } }/></div>
                            <div className="observacoes"><FontAwesomeIcon icon={faExclamationTriangle}/> <span dangerouslySetInnerHTML={ { __html: this.message().observacoes2 } }/></div>
                        </div>

                        <div className="">
                            {hasCheck && 
                                <DefaultButton
                                    title={this.message().buttonUpload}
                                    leftIcon={<FontAwesomeIcon icon={faUpload} />}
                                    color={Colors.success}
                                    onClick={() => {
                                        this.loadModeloFile();
                                    }}
                                />
                            }
                        </div>
                    </div>
                    <table className="table-avaliacoes">
                        <thead>
                            <tr>
                                <th colSpan={2}>{this.message().tableTitleQuestionario}</th>
                                <th colSpan={2}>{this.message().tableTitleTiposAvaliacao}</th>
                                <th colSpan={3}>{this.message().tableTitleEmpresas}</th>
                                <th colSpan={2}>{this.message().tableTitleStatus}</th>
                                <th colSpan={3}>{this.message().tableTitleSelect}</th>
                            </tr>
                        </thead>

                        <tbody>
                            {this.state.avaliacoes?.length > 0 && this.state.avaliacoes.map(avaliacao => {

                                let importResult = avaliacao.isCheck;
                                
                                return (
                                    <tr key={`key-avaliacao-check-${avaliacao.id}`}>
                                        <td colSpan={2} className={`${importResult ? 'import' : ''}`}>{avaliacao.questionario.nome}</td>
                                        <td colSpan={2} className={`${importResult ? 'import' : ''}`}>{DiagnosticoHelper.renderTipoAvaliacao(avaliacao.tipo, this.props.t)}</td>
                                        <td colSpan={3} className={`${importResult ? 'import' : ''}`}>{avaliacao.empresaAvaliada.nome_fantasia}</td>
                                        <td colSpan={2} className={`${importResult ? 'import' : ''}`}>{DiagnosticoHelper.renderStatusAvaliacao(avaliacao.status, avaliacao.isAtrasada, avaliacao, this.state.lang, this.props.t)}</td>
                                        <td colSpan={3} className={`${importResult ? 'import' : ''}`}>{cellCheck(avaliacao)}</td>
                                    </tr>
                                )
                            })}

                            {this.state.avaliacoes?.length == 0 && 
                                <tr>
                                    <td colSpan={12}>{this.message().emptyAvaliacoes}</td>
                                </tr>
                            }
                        </tbody>
                    </table>
                </div>
            )
        }

        const uploadFile = () => {

            return (
                <div className="bodyModal">
                    <TableImporter
                        fileModel={this.state.modelo}
                        lockColumns={["idAvaliacao", "idAvaliador", "idNode", "questionario", "empresa", "avaliador", "pergunta"]}
                        alvoColumns={["resposta"]}
                        onConfirm={(data) => {this.importData(data)}}
                        importing={this.state.importing}
                        fileErrors={this.state.fileErrors}
                        importComplete={this.state.importComplete}
                        onComplete={this.props.closeModal}
                        linesImported={this.state.linesImported}
                    />
                </div>
            )
        }

        if (this.state.loadingAvaliacoes || this.state.loadingDownloadModelo) return loading();
        if (!this.state.openImporter) return avaliacoesCheck();
        return uploadFile();
    }

    renderFooter() {
        return (
            <div style={{...EssentialStyle.rowFlexEnd, width: '100%', marginTop: 10}}>
                <DefaultButton 
                    title={this.state.openImporter ? this.message().back : this.message().close}
                    color={Colors.dark}
                    textColor={Colors.white}
                    onClick={() => {
                        this.state.openImporter ? 
                        this.setState({ openImporter: false }, () => this.loadQuestionarios()) 
                        : 
                        this.props.closeModal()
                    }}
                />
            </div>
        )
    }

    render() {
        return (
            <Modal
                className={"ModalUploadResultadosAvaliacao"} 
                style={{ 
                    width: '95vw', 
                    height: '100vh',
                    transition: 'height 0.5s ease', 
                    boxShadow: '0px 0px 20px 20px rgba(50, 50, 50, 0.5)', 
                    justifyContent: 'space-between',
                }} 
                onClose={this.props.closeModal}
            >
                {this.renderTitle()}
                {this.renderBody()}
                {this.renderFooter()}
            </Modal>
        )
    }
}

export default withTranslation()(ModalUploadResultadosAvaliacao);