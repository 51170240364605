import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusSquare, faFile, faChevronLeft, faCheck, faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import DefaultButton from "../../../tools/DefaultButton";
import Colors from "../../../../constants/Colors";
import { Form, Row, Col } from "react-bootstrap";
import Select from 'react-select'
import Sig from "../../../../api/Sig";
import DataHelper from "../../../../helper/DataHelper";
import { toast } from "react-toastify";
import moment from "moment";
import AddNorteadores from "../../../modules/pe/mapa/AddNorteadores";
import AddPerspectivas from "../../../modules/pe/mapa/AddPerspectivas";
import IntervalSelector from "../../../tools/IntervalSelector";
import DatePicker from "react-datepicker";
import { forwardRef } from 'react';
import DiagramHelper from "../../../../helper/pe/DiagramHelper";
import DefaultLoader from "../../../tools/DefaultLoader";
import EssentialStyle from "../../../../style/EssentialStyle";
import CustomTooltip from "../../../tools/CustomTooltip";

export default class AddMapa extends React.Component {
    state = {
        step: 0,
        loading: true,
        colabs: [],
        visoes: [],
        norteadores: [],
        intervalos: [],
        perspectivas: [],
        name: '',
        responsable: null,
        id_pe_visao: null,
        data_inicio: moment().startOf('year').format('YYYY-MM-DD'),
        data_fim: moment().endOf('year').format('YYYY-MM-DD'),
        participants: [],
        description: '',
        templateImage: null,
        templateId: null,
        newMapaId: null,
        tiposProgresso: 1,
        tipoProgresso: 1,
        permissao_indicador: 1,
        permissao_projeto: 1,
    }

    async componentDidMount() {
        await this.loadData();
    }

    async loadData() {
        this.setState({ loading: true });

        let visoes = (await Sig.request('GET', 'pe/visao/list', { relations: 0 }))?.visoes || [];
        let colabs = await Sig.request('GET', 'config/colaborador/getColaboradores') || [];
        let tiposProgresso = await Sig.request('GET', 'pe/mapa/getTiposProgresso', { mapa: null }) || [];

        this.setState({ colabs, visoes, tipoProgresso: tiposProgresso.default, tiposProgresso: tiposProgresso.tipos }, () => { this.setState({ loading: false }) });
    }

    intervalosIsValid() {
        let intervalos =[];
        let haveInfinito = false, response = true;

        for (let intervalo of this.state.intervalos) {

            intervalos.push(parseInt(intervalo.from));
            intervalos.push(intervalo.infinito == 1 ? 2147483647 : parseInt(intervalo.to));
            if (intervalo.infinito) haveInfinito = true;
        }

        intervalos.sort((a, b) => a - b);
        
        // Verificar se começa em ZERO
        if (intervalos[0] != 0) response = false;
        
        // Retira o menor e maior valor do array
        intervalos.shift();
        intervalos.pop();

        // Valida se todos elementos passuem duas repetiçoes e não sobra nenhum com menos ou mais de duas
        for (let i = 0; i < intervalos.length; i += 2) {
            if (intervalos[i] !== intervalos[i + 1]) response = false;;
        }

        return response;
    }

    addNew = async () => {

        if (!this.state.name) return toast.warn('Informe o Nome do Mapa Estratégico (*)');
        if (!this.state.responsable) return toast.warn('Selecione um Responsável (*)');
        if (!this.state.tipoProgresso && this.state.tipoProgresso != 0) return toast.warn('Selecione um tipo de Metodologia de Progresso dos Projetos (*)');

        this.setState({ loading: true });

        if (!this.intervalosIsValid()) {
            this.setState({ loading: false });
            return toast.warn('As faixas definidas não cobrem todo intervalo.');
        }

        let mapa = (await Sig.request('POST', `pe/mapa/add`, {
            nome: this.state.name,
            id_responsavel: this.state.responsable,
            id_pe_visao: this.state.id_pe_visao || null,
            data_inicio: DataHelper.getDefaultDbDateFormat(this.state.data_inicio),
            data_fim: DataHelper.getDefaultDbDateFormat(this.state.data_fim),
            colaboradores: this.state.participants.map((participant) => { return participant.value }) || [],
            tipo_progresso: this.state.tipoProgresso,
            permissao_indicador: Number(this.state.permissao_indicador),
            permissao_projeto: Number(this.state.permissao_projeto),
        }))?.mapa || null;

        if (mapa && mapa.id) {
            if (this.state.norteadores.length) {
                for (let norteador of this.state.norteadores) {
                    await Sig.request('POST', 'pe/mapa/addNorteador', {
                        id_pe_mapa: mapa.id,
                        nome: norteador.name,
                        content: norteador.content
                    });
                }
            }

            if (this.state.intervalos.length) {
                for (let intervalo of this.state.intervalos) {
                    await Sig.request('POST', 'pe/mapa/addIntervalo', {
                        id_pe_mapa: mapa.id,
                        minimo: parseInt(intervalo.from),
                        maximo: parseInt(intervalo.to),
                        cor: intervalo.color,
                        infinito: intervalo.infinito
                    });
                }
            }

            if (this.state.perspectivas.length) {
                for (let perspectiva of this.state.perspectivas) {
                    await Sig.request('POST', 'pe/mapa/addPerspectiva', {
                        id_pe_mapa: mapa.id,
                        nome: perspectiva.name
                    });
                }

                await Sig.request('POST', 'pe/mapa/generatePerspectivas', { id: mapa.id, width: DiagramHelper.getDiagramResolution().width });
            }

            if (mapa) {
                this.setState({ newMapaId: mapa.id }, this.step(mapa.id));
                toast.success('Mapa inserido com sucesso!');

            }
           
        }

        this.setState({ loading: false });
    }

    renderDatePickerInicio() {

        const ExampleCustomInput1 = forwardRef(({ value, onClick }, ref) => (
            <Form.Control 
                onChange={() => {}}
                onClick={onClick} 
                ref={ref} 
                value={value} >
            </Form.Control>
        ));
        let dataMoment1 = this.state.data_inicio;
        let momentConvertidoData1 = moment(dataMoment1).toDate();

        return (
            <DatePicker
                wrapperClassName="w-100"
                selected={momentConvertidoData1}
                type="date"
                onChange={(event) => {
                    let novoInicio = moment(event);
                    this.setState({ data_inicio: novoInicio });
                    this.setState({ data_fim: novoInicio });
                }}
                dateFormat="MM/yyyy"
                showMonthYearPicker
                customInput={<ExampleCustomInput1 />}
            />
        )
    };

    renderDatePickerFim() {

        const ExampleCustomInput2 = forwardRef(({ value, onClick }, ref) => (
            <Form.Control 
                onChange={() => {}}
                onClick={onClick} 
                ref={ref} 
                value={value} 
                className="w-100">
            </Form.Control>
        ));
        let dataMoment2 = this.state.data_inicio;
        let momentConvertidoDataInicio = moment(dataMoment2).toDate();

        let dataMoment3 = this.state.data_fim;
        let momentConvertidoDataFim = moment(dataMoment3).toDate();

        return (
            <DatePicker
                wrapperClassName="w-100"
                selected={momentConvertidoDataFim}
                type="date"
                onChange={(event) => {

                    let novoFimMoment = moment(event);

                    if (novoFimMoment >= momentConvertidoDataInicio) {
                        this.setState({ data_fim: novoFimMoment })
                    } else {
                        toast.info('Data de fim deve ser maior ou igual à data de início');
                    }
                }}
                dateFormat="MM/yyyy"
                showMonthYearPicker
                customInput={<ExampleCustomInput2 />}
            />
        )
    };

    renderForm() {

        return (
            <div className="mt-5 mb-5" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', alignContent: 'center', minHeight: 'inherit' }}>
                <Form className="w-75">
                    <Row className="mb-3">
                        <Form.Group as={Col}>
                            <Form.Label>Nome do mapa estratégico*</Form.Label>
                            <Form.Control 
                                type="text" 
                                placeholder="Mapa X..." 
                                onChange={(event) => { this.setState({ name: event.target.value }) }} 
                                defaultValue={this.state.name} 
                            />
                        </Form.Group>
                    </Row>

                    <Row className="mb-3">
                        <Form.Group as={Col}>
                            <Form.Label>Responsável*</Form.Label>
                            <Select 
                                options={DataHelper.formatSelectData(this.state.colabs, 'id', 'nome')} 
                                value={DataHelper.formatSelectData(this.state.colabs, 'id', 'nome').find((colab) => colab.value == this.state.responsable)}
                                placeholder={'Selecione o responsável'} 
                                noOptionsMessage={DataHelper.getSelectEmptyMessage} 
                                isClearable isSearchable 
                                onChange={(value) => { this.setState({ responsable: value ? value.value : null }) }} 
                            />
                        </Form.Group>

                        <Form.Group as={Col}>
                            <Form.Label>Participantes*</Form.Label>
                            <Select
                                isDisabled={this.state.responsable ? false : true}                                
                                options={DataHelper.removeColaborador(DataHelper.formatSelectData(this.state.colabs, 'id', 'nome'), this.state.responsable)} 
                                value={this.state.participants}
                                placeholder={'Selecione os participantes'} 
                                noOptionsMessage={DataHelper.getSelectEmptyMessage} 
                                isClearable isSearchable isMulti 
                                onChange={(value) => { this.setState({ participants: value }) }} />
                        </Form.Group>
                    </Row>

                    <Row className="mb-3">
                        <Form.Group as={Col} xs={3} md={3}>
                            <Form.Label>Inicio do período*</Form.Label>
                            <br></br>
                            {this.renderDatePickerInicio()}
                        </Form.Group>
                        <Form.Group as={Col} xs={3} md={3}>
                            <Form.Label>Fim do período*</Form.Label>
                            <br></br>
                            {this.renderDatePickerFim()}
                        </Form.Group>
                        <Form.Group as={Col} xs={6} md={6}>
                            <Form.Label>Metodologia de Progresso dos Projetos*</Form.Label>
                            <br></br>
                            <Select    
                                loading={this.state.loading}                    
                                options={this.state.tiposProgresso}
                                value={this.state.tiposProgresso.find((tipo) => tipo.value == this.state.tipoProgresso)}
                                placeholder={'Selecione uma opção'}
                                noOptionsMessage={DataHelper.getSelectEmptyMessage}
                                isSearchable
                                onChange={(value) => { this.setState({ tipoProgresso: value.value }) }}
                            />
                        </Form.Group>
                    </Row>

                    <Row className="mb-3">
                        <Form.Group as={Col}>
                            <Form.Label>Vincular com visão de futuro</Form.Label>
                            <Select 
                                options={DataHelper.formatSelectData(this.state.visoes, 'id', 'nome')}
                                value={DataHelper.formatSelectData(this.state.visoes, 'id', 'nome').find((visao) => visao.value == this.state.id_pe_visao) || []}
                                placeholder={'Selecione a visão'} 
                                noOptionsMessage={DataHelper.getSelectEmptyMessage}
                                isClearable isSearchable 
                                onChange={(value) => { this.setState({ id_pe_visao: value ? value.value : null }) }} />
                        </Form.Group>
                    </Row>

                    <AddNorteadores callback={(norteadores) => { this.setState({ norteadores }) }} />
                    <AddPerspectivas callback={(perspectivas) => { this.setState({ perspectivas }) }} />
                    <IntervalSelector callback={(intervalos) => { this.setState({ intervalos }) }} title />

                    <Row className="mb-5">
                        <Form.Group as={Col}>
                            <Form.Label>Permissões de Visualização</Form.Label>
                            <Form.Check type="checkbox" id="checkbox-indicadores">
                                <Form.Check.Input
                                    type="checkbox"
                                    style={{cursor: 'pointer'}}
                                    checked={this.state.permissao_indicador}
                                    onChange={() => this.setState({permissao_indicador: this.state.permissao_indicador === 1 ? 0 : 1})}
                                />
                                <Form.Check.Label style={{cursor: 'pointer', display: 'flex'}}>
                                    Exibir Performance de Indicadores para todos os colaboradores
                                    <CustomTooltip style={{width: 22}} tooltip={'Ao marcar essa opção, a performance dos indicadores estratégicos será exibida para todos os colaboradores, assim como as metas e os resultados. Ao não marcar, essas informações serão exibidas somente para participantes do Mapa Estratégico.'}>
                                        <FontAwesomeIcon icon={faInfoCircle} style={{marginLeft: 5}}/>
                                    </CustomTooltip>
                                </Form.Check.Label>
                            </Form.Check>
                            
                            <Form.Check type="checkbox" id="checkbox-projetos" >
                                <Form.Check.Input
                                    type="checkbox"
                                    style={{cursor: 'pointer'}}
                                    checked={this.state.permissao_projeto}
                                    onChange={() => this.setState({permissao_projeto: this.state.permissao_projeto === 1 ? 0 : 1})}
                                />
                                <Form.Check.Label style={{cursor: 'pointer', display: 'flex', width: ''}}>
                                    Exibir Progresso de Projetos para todos os colaboradores
                                    <CustomTooltip style={{width: 22}} tooltip={'Ao marcar essa opção, o progresso dos projetos será exibido para todos os colaboradores. Ao não marcar, essas informações serão exibidas somente para participantes do Mapa Estratégico.'}>
                                        <FontAwesomeIcon icon={faInfoCircle} style={{marginLeft: 5}}/>
                                    </CustomTooltip>
                                </Form.Check.Label>
                            </Form.Check>
                        </Form.Group>
                    </Row>

                    <div className="mb-3 d-flex flex-row-reverse">
                        <Form.Group>
                            <DefaultButton color={Colors.success} leftIcon={<FontAwesomeIcon icon={faPlusSquare} />} title={'Adicionar'} loading={this.state.loading} onClick={this.addNew} />
                        </Form.Group>
                    </div>
                </Form>

            </div>
        );
    }

    renderStepBack() {
        return (
            <div style={{ position: 'absolute', top: 10, left: 10 }}>
                <DefaultButton title={''} leftIcon={<FontAwesomeIcon icon={faChevronLeft} />} color={Colors.secondaryButton} textColor={Colors.dark} loading={this.state.loading} onClick={() => { this.step() }} style={{ marginRight: 8 }} />
            </div>
        );
    }

    step(id = 0) {
        this.setState({ loading: true });
        this.props.saveMapaCallback(id);
        this.setState({ loading: false });
    }

    renderSteps() {
        let stepContent;

        stepContent = this.renderForm();

        return stepContent;
    }

    renderLoader() {
        return (
            <div style={{width: '100%', height: '100%', ...EssentialStyle.columnCenter}}>
                <DefaultLoader/>
            </div>
        )
    }

    render() {
        return (
            <div style={{ minWidth: '100%' }}>
                {this.renderStepBack()}
                {this.state.loading ? this.renderLoader() : this.renderSteps()}
            </div>
        );
    }
}