import React from 'react';
import { withTranslation } from 'react-i18next';
import ReactDOM from 'react-dom';
import EssentialStyle from "../../../style/EssentialStyle";
import DefaultLoader from '../../tools/DefaultLoader';
import Colors from "../../../constants/Colors";
import { faAdd, faCheck, faClose, faPencil, faLink } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DefaultButton from '../../tools/DefaultButton';
import Sig from '../../../api/Sig';
import EllipsisText from '../../tools/EllipsisText';
import ResizableTimelineElement from '../../tools/ResizableTimelineElement/ResizableTimelineElement';
import 'moment/locale/pt-br';
import moment from 'moment';
import DataHelper from '../../../helper/DataHelper';
import UserAvatar from '../../tools/UserAvatar';
import TextEditor from '../../tools/TextEditor/TextEditor';
import { toast } from 'react-toastify';
import { Dropdown } from 'react-bootstrap';
import KeyboardHelper from '../../../helper/KeyboardHelper';
import DragAndDropHelper from '../../../helper/DragAndDropHelper';
import CustomTooltip from '../../tools/CustomTooltip';

class PlanoAcaoTimeline extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            isSmallScreen: this.props.isSmallScreen,
            etapas: [],
            daySize: this.props.granularidade === 'mes' ? 4 : this.props.granularidade === "ano" ? 1 : 16,
            granularidade: this.props.granularidade,
            editId: false,
            showEditTextButton: false,
            oldAtividade: "",
            edit: true,
            draggingItem: null,
            draggingOverItem: null,
            draggingOverItemTop: false
        };
        this.scrollRef = React.createRef();
    }

    componentDidMount = async () => {
        await this.props.i18n.loadNamespaces(['client_src_components_modules_planoAcao_PlanoAcaoTimeline']);
        await this.loadData();
    }

    scrollToCurrentDate = () => {
        if (!this.scrollRef.current)
            setTimeout(this.scrollToCurrentDate, 100);
        else {
            const left = (moment(new Date()).diff(moment(new Date(this.state.timelineStart)), 'days') * this.state.daySize);
            this.scrollRef?.current?.scrollTo({ top: 0, left: left, behavior: 'smooth' });
        }
    }

    componentDidUpdate = async (prevProps, prevState) => {
        if (prevProps.granularidade !== this.props.granularidade) {
            this.setState({
                daySize: this.props.granularidade === 'mes' ? 4 : this.props.granularidade === "ano" ? 1 : 16,
                granularidade: this.props.granularidade
            }, () => {
                this.scrollToCurrentDate();
            });
        }

        if (
            (prevProps.filter.people !== this.props.filter.people) ||
            (prevProps.filter.search !== this.props.filter.search) ||
            (prevProps.filter.status !== this.props.filter.status) ||
            (prevProps.filter.prioridades !== this.props.filter.prioridades) ||
            (prevProps.filter.data_inicio !== this.props.filter.data_inicio) ||
            (prevProps.filter.data_fim !== this.props.filter.data_fim) ||
            (prevProps.filter.ocultarConcluidos !== this.props.filter.ocultarConcluidos)
        ) {
            await this.loadData();
        }

        if (this.props.isSmallScreen !== prevProps.isSmallScreen) {
            this.setState({ isSmallScreen: this.props.isSmallScreen });
        }

        if (prevState.etapas !== this.state.etapas) {
            let timelineStart = Math.min(...this.state.etapas.map(e => moment(e.data_inicio).toDate().getTime()));
            timelineStart = moment(new Date(timelineStart)).subtract(12, 'months').startOf('month').startOf('day').toDate().getTime();
            timelineStart = Math.min(timelineStart, moment(new Date()).subtract(6, 'months').startOf('month').startOf('day').toDate().getTime());
            let timelineEnd = Math.max(...this.state.etapas.map(e => moment(e.data_fim).toDate().getTime()));
            timelineEnd = moment(new Date(timelineEnd)).add(12, 'months').endOf('month').endOf('day').toDate().getTime();
            timelineEnd = Math.max(timelineEnd, moment(new Date()).add(24, 'months').endOf('month').endOf('day').toDate().getTime());
            this.setState({ timelineStart, timelineEnd });
        }

        if (this.props.shouldUpdateEtapas && prevProps.shouldUpdateEtapas !== this.props.shouldUpdateEtapas) {
            await this.loadData(true);
        }
        
        if (prevState.editId === -1 && this.state.editId !== prevState.editId) {
            const etapaIndex = this.state.etapas.findIndex(e => e.id === -1);
            if (etapaIndex !== -1) {
                const etapas = [...this.state.etapas];
                etapas.splice(etapaIndex, 1);
                this.setState({ etapas }, () => { this.props.updateEtapas(); });
            }
        }

        if (this.props.timelineSort && prevProps.timelineSort !== this.props.timelineSort) {
            await this.loadData(false, true);
        }
    }

    loadData = async (quiet = false, autoScroll = true) => {
        if (!quiet) this.setState({ loading: true });
        let etapas = await Sig.request('POST', 'planoAcao/listEtapasPlano', {
            codigo: this.props.codigo,
            timelineSort: this.props.timelineSort,
            people: this.props.filter.people || [],
            search: this.props.filter.search || '',
            status: this.props.filter.status || [],
            prioridades: this.props.filter.prioridades || [],
            data_inicio: this.props.filter.data_inicio || '',
            data_fim: this.props.filter.data_fim || '',
            ocultarConcluidos: this.props.filter.ocultarConcluidos || false
        });
        if (etapas) {
            let timelineStart = Math.min(...this.state.etapas.map(e => moment(e.data_inicio).toDate().getTime()));
            timelineStart = moment(new Date(timelineStart)).subtract(12, 'months').startOf('month').startOf('day').toDate().getTime();
            timelineStart = Math.min(timelineStart, moment(new Date()).subtract(6, 'months').startOf('month').startOf('day').toDate().getTime());
            let timelineEnd = Math.max(...this.state.etapas.map(e => moment(e.data_fim).toDate().getTime()));
            timelineEnd = moment(new Date(timelineEnd)).add(12, 'months').endOf('month').endOf('day').toDate().getTime();
            timelineEnd = Math.max(timelineEnd, moment(new Date()).add(24, 'months').endOf('month').endOf('day').toDate().getTime());
            this.setState({ etapas: etapas || [], timelineStart, timelineEnd }, () => { 
                this.setState({ loading: false, edit: true }, () => {
                    this.checkTimelineIndex();
                    if (autoScroll)
                        this.scrollToCurrentDate();
                });
                this.props.updateEtapas(); 
            });
        } else {
            this.setState({ loading: false, etapas: [], timelineEnd: null, timelineStart: null });
        }
    }

    checkTimelineIndex = async () => {
        if (this.state.etapas.length === 0) return;
        let etapas = [...this.state.etapas];
        let etapasSemIndex = etapas.filter(e => e.timeline_index === 0);
        if (this.state.etapas.length === etapasSemIndex.length) {
            etapas.forEach(async (e, index) => {
                e.timeline_index = index + 1;
                const response = await Sig.request('POST', 'planoAcao/updateEtapaTimelineIndex', {
                    id: e.id,
                    timeline_index: e.timeline_index
                });
                if (!response || response.status !== 200) 
                    toast.error(this.props.i18n.t('client_src_components_modules_planoAcao_PlanoAcaoTimeline:erro_atualizar_indice'));
            });
        } else if (etapasSemIndex.length > 0) {
            let lastIndex = Math.max(...etapas.map(e => e.timeline_index));
            etapas.forEach(async e => {
                if (e.timeline_index !== 0) return; 
                e.timeline_index = lastIndex + 1;
                lastIndex++;
                const response = await Sig.request('POST', 'planoAcao/updateEtapaTimelineIndex', {
                    id: e.id,
                    timeline_index: e.timeline_index
                });
                if (!response || response.status !== 200) 
                    toast.error(this.props.i18n.t('client_src_components_modules_planoAcao_PlanoAcaoTimeline:erro_atualizar_indice'));
            });
            this.setState({ etapas });
        }
    }

    changeEtapaCallback(updatedEtapa) {
        if (updatedEtapa.id === -1) {
            return;
        }
        let etapas = [...this.state.etapas];
        const index = etapas.findIndex(m => m.id === updatedEtapa.id);
        if (etapas[index].data_inicio != updatedEtapa.data_inicio || etapas[index].data_fim != updatedEtapa.data_fim) {
            etapas[index] = updatedEtapa;
            this.setState({ etapas, edit: false }, async () => {
                const response = await Sig.request('POST', 'planoAcao/updatePeriodoEtapa', {
                    id: updatedEtapa.id,
                    data_inicio: DataHelper.getDefaultDbDateFormat(updatedEtapa.data_inicio),
                    data_fim: DataHelper.getDefaultDbDateFormat(updatedEtapa.data_fim),
                });
                this.props.updateEtapas();
                if (response && response.status === 200) {
                    this.loadData(true, false);
                }
                updatedEtapa.status = updatedEtapa.status_value;
                etapas.splice(index, 1, updatedEtapa);
                this.setState({ etapas, editId: false }, () => { this.props.updateEtapas(); });
            });
        }
    }

    getHeaderHeight = () => {
        let headerComponents = document.getElementsByClassName('pa-header-component');
        let headerHeight = 0;
        for (let headerComponent of headerComponents) {
            if(headerComponent.classList.contains('optional')) 
                // Inverter o comentário para mostrar os campos optional sempre
                headerHeight += (39 + (headerComponent.style.paddingBottom ? parseInt(headerComponent.style.paddingBottom.replace('px', '')) : 0));
                // headerHeight += 0;
            else
                headerHeight += headerComponent.clientHeight;
        }
        return headerHeight;
    }

    saveText = async (id) => {
        const etapaIndex = this.state.etapas.findIndex(e => e.id === id);
        const etapa = this.state.etapas[etapaIndex];
        if (etapa) {
            if (etapa.id === -1) {
                if (!etapa.atividade) {
                    toast.info(this.props.i18n.t('client_src_components_modules_planoAcao_PlanoAcaoTimeline:campo_atividade_vazio'));
                    return;
                }
                let atividade = await Sig.request(
                    'POST',
                    'planoAcao/addEtapa',
                    {
                        codigo: this.props.codigo,
                        atividade: etapa.atividade,
                        justificativa: etapa.justificativa || '<p></p>',
                        descricao: etapa.descricao || '<p></p>',
                        idColaborador: this.props.filter.people.length === 1 ? this.props.filter.people[0] : null,
                    }
                );
                atividade.status = atividade.status_value;
                let etapas = [...this.state.etapas];
                etapas.splice(etapaIndex, 1, atividade);
                this.setState({ etapas, oldAtividade: "" });
                if (atividade) {
                    atividade = await Sig.request('POST', 'planoAcao/updateResponsavelEtapa', {
                        id: atividade.id,
                        responsavel: etapa.id_colaborador
                    });
                    atividade = await Sig.request('POST', 'planoAcao/updatePeriodoEtapa', {
                        id: atividade.id,
                        data_inicio: DataHelper.getDefaultDbDateFormat(etapa.data_inicio),
                        data_fim: DataHelper.getDefaultDbDateFormat(etapa.data_fim),
                    });
                    this.props.updateEtapas();
                    atividade.status = atividade.status_value;
                    etapas = [...this.state.etapas];
                    etapas.splice(etapaIndex, 1, atividade);
                    this.setState({ etapas, editId: false }, () => { this.props.updateEtapas(); });
                    return;
                }
                this.setState({ editId: false });
            } else {
                if (!etapa.atividade) {
                    toast.info(this.props.i18n.t('client_src_components_modules_planoAcao_PlanoAcaoTimeline:campo_atividade_vazio'));
                    return;
                }
                await Sig.request('POST', 'planoAcao/updateEtapaText', {
                    id: etapa.id,
                    atividade: etapa.atividade,
                    justificativa: etapa.justificativa || '<p></p>',
                    descricao: etapa.descricao || '<p></p>',
                });
                this.setState({ editId: false, oldAtividade: "" });
            }
        }
    }
            
    addEtapa = () => {
        if (this.props.permissions?.canEdit == false) return;
        this.props.detailsModalCallback();
        this.setState({ etapas: [...this.state.etapas, { 
            id: -1, 
            data_inicio: moment(new Date()).format('YYYY-MM-DD'), 
            data_fim: moment(new Date()).add(5, "days").format('YYYY-MM-DD'), 
            atividade: '', descricao: '', status: "0", status_text: "Não Iniciada", status_value: "0",
            colaborador: this.props.responsavel, id_colaborador: this.props.responsavel.id 
        }]}, () => {
            if (this.state.editId) {
                let etapas = [...this.state.etapas];
                const etapa = etapas.find(e => e.id === this.state.editId);
                if (etapa && this.state.oldAtividade !== etapa.atividade) {
                    etapa.atividade = this.state.oldAtividade;
                    this.setState({ etapas, editId: -1, oldAtividade: "" });
                    return;
                }
            }
            this.setState({ editId: -1, oldAtividade: "" });
        });
    }

    onDragStartItem = async (e, item) => {
        this.setState({ draggingItem: item, draggingOverItem: null });
        setTimeout(() => { e.target.parentNode.style.display = "none"; }, 10);
    }

    onDragEndItem = async (e, item) => {
        if (this.state.draggingItem && this.state.draggingOverItem) {
            let etapas = [...this.state.etapas];
            let timelineIndex = this.state.draggingOverItem.timeline_index;
            if (!this.state.draggingOverItemTop && etapas.length - 1 > timelineIndex) {
                timelineIndex++;
            }
            const draggingItemIndex = etapas.findIndex(e => e.id === this.state.draggingItem.id);
            const draggingOverItemIndex = etapas.findIndex(e => e.timeline_index === timelineIndex);
            const draggingItem = etapas[draggingItemIndex];
            const draggingOverItem = etapas[draggingOverItemIndex];
            await Sig.request('POST', 'planoAcao/updateEtapaTimelineIndex', {
                id: draggingItem.id,
                timeline_index: draggingOverItem.timeline_index
            });
            etapas.splice(draggingItemIndex, 1);
            etapas.splice(draggingOverItemIndex, 0, draggingItem);
            this.setState({ draggingItem: null, draggingOverItem: null, etapas }, () => {
                this.loadData(true, false);
                setTimeout(() => { e.target.parentNode.style.display = "flex"; }, 10);
            });
        } else {
            this.setState({ draggingItem: null, draggingOverItem: null });
            setTimeout(() => { e.target.parentNode.style.display = "flex"; }, 10);
        }   
    }

    onDragEnterItem = async (e, item) => {
        const top = DragAndDropHelper.checkTopTimeline(e, 0.5, this.getHeaderHeight());
        if (item.id !== this.state.draggingItem.id || top !== this.state.draggingOverItemTop) {
            this.setState({ draggingOverItemTop: top }, () => { this.setState({ draggingOverItem: item }); });
        }
    }

    renderEditText = () => {
        const etapaIndex = this.state.etapas.findIndex(e => e.id === this.state.editId);
        const etapa = this.state.etapas[etapaIndex];
        return (
            <div style={{ display: "flex", height: "100%", width: "calc(100% - 40px)", fontWeight: 500, alignItems: 'center' }}>
                <TextEditor
                    defaultValue={etapa.atividade}
                    onChange={(text) => {
                        let etapas = [...this.state.etapas];
                        etapas[etapaIndex].atividade = text;
                        this.setState({ etapas });
                    }}
                    height={35}
                    mentions={DataHelper.formatMentionData(this.props.participantes, 'id', 'nome')}
                    hideToolbar
                    noMargin
                    disabledEnterEvent
                    onKeyDown={(evt) => {
                        KeyboardHelper.handleShortcut(
                            evt,
                            ["Enter", "Escape"],
                            [() => { 
                                if (evt.shiftKey) return;
                                this.saveText(etapa.id);
                            }, (e) => { 
                                e.preventDefault();
                                if (etapa.id !== -1 && this.state.oldAtividade !== etapa.atividade) {
                                    let etapas = [...this.state.etapas];
                                    etapas[etapaIndex].atividade = this.state.oldAtividade;
                                    this.setState({ etapas, editId: false, oldAtividade: "" });
                                    return;
                                }
                                this.setState({ editId: false, oldAtividade: "" });
                            }]
                        );
                    }} 
                />
                <DefaultButton 
                    style={{ marginLeft: 2, width: 35, height: 35, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                    leftIcon={<FontAwesomeIcon icon={faCheck} />} 
                    textColor={Colors.white} 
                    title={''} 
                    onClick={() => {
                        this.saveText(etapa.id);
                    }}
                />
                <DefaultButton 
                    style={{ marginLeft: 2, width: 35, height: 35, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                    leftIcon={<FontAwesomeIcon icon={faClose} />} 
                    textColor={Colors.white} 
                    title={''} 
                    onClick={() => {
                        if (etapa.id !== -1 && this.state.oldAtividade !== etapa.atividade) {
                            let etapas = [...this.state.etapas];
                            etapas[etapaIndex].atividade = this.state.oldAtividade;
                            this.setState({ etapas, editId: false, oldAtividade: "" });
                            return;
                        }
                        this.setState({ editId: false, oldAtividade: "" });
                    }}
                />
            </div>
        );
    }

    renderSelectResponsavel = (etapa) => {
        const portalTarget = document.getElementById('root');
        return (
            <Dropdown className="optionsModalEtapa">
                <Dropdown.Toggle
                    onChange={() => {}}
                    style={{ 
                        backgroundColor: "transparent", 
                        color: Colors.dark, 
                        border: 'none',
                        zIndex: 25, 
                        padding: 0,
                    }}
                > 
                    <UserAvatar user={etapa.colaborador} size={35} />
                </Dropdown.Toggle>
                {ReactDOM.createPortal(
                    <Dropdown.Menu style={{ padding: 2, color: Colors.dark, maxHeight: 200, overflow: 'hidden' }} disabled={this.props.permissions?.canEdit == false}> 
                        <div style={{ overflowY: "auto", maxHeight: 200 }}>
                        {
                            this.props.participantes.map((participante, index) => {
                                return (
                                    <Dropdown.Item 
                                        key={index} 
                                        style={{ backgroundColor: etapa.id_colaborador === participante.id ? Colors.homePage.lightGrey : "none" }} 
                                        disabled={etapa.id_colaborador === participante.id}
                                        onClick={() => {
                                            let etapas = [...this.state.etapas];
                                            etapas[etapas.findIndex(e => e.id === etapa.id)].colaborador = participante;
                                            etapas[etapas.findIndex(e => e.id === etapa.id)].id_colaborador = participante.id;
                                            this.setState({ etapas });
                                            if (etapa.id === -1) return;
                                            Sig.request('POST', 'planoAcao/updateResponsavelEtapa', {
                                                id: etapa.id,
                                                responsavel: participante.id
                                            });
                                        }}
                                    >
                                        <UserAvatar user={participante} size={35} showNameAdaptative showName onClick={() => {}} />
                                    </Dropdown.Item>
                                );
                            })
                        }
                        </div>
                    </Dropdown.Menu>
                , portalTarget)}
            </Dropdown>
        );
    }

    renderHasDependencias = (index) => {
        if (!this.state.etapas[index].has_dependencias) return null;
        return (
            <CustomTooltip tooltip={this.props.i18n.t('client_src_components_modules_planoAcao_PlanoAcaoTimeline:possui_dependencias')} placement="right">
                <FontAwesomeIcon icon={faLink} className={'icon dependencias'} />
            </CustomTooltip>
        );
    }

    renderTimeline = () => {
        const { timelineStart, timelineEnd } = this.state;
        let offsetLeft = 400;
        if (this.state.isSmallScreen) 
            offsetLeft = 250;
        const etapas = this.state.etapas;
        const totalDuration = timelineEnd - timelineStart;
        const quantMonths = Math.round(moment(new Date(timelineEnd)).diff(moment(new Date(timelineStart)), 'months', true));
        const quantDias = Math.round(moment(new Date(timelineEnd)).diff(moment(new Date(timelineStart)), 'days', true));
        const quantSemanas = Math.ceil(quantDias / 7);
        const quantAnos = Math.ceil(moment(new Date(timelineEnd)).diff(moment(new Date(timelineStart)), 'years', true));

        return (
            <div style={{
                width: '100%',
                height: "auto",
                maxHeight: this.state.isSmallScreen ? `unset` : `calc(100vh - ${this.getHeaderHeight()}px)`,
            }}>
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100%",
                    backgroundColor: Colors.white,
                    borderRadius: 8,
                    boxShadow: `0px 5px 5px 0px  rgba(50, 50, 50, 0.2)`,
                    maxHeight: "inherit",
                    overflow: 'hidden'
                }}>
                    {this.state.etapas && this.state.etapas.length > 0 ?
                        <div style={{
                            display: "flex",
                            flexDirection: "column",
                            width: '100%',
                            height: "max-content",
                            alignItems: 'center',
                            justifyContent: 'flex-start',
                            maxHeight: "inherit",
                            overflow: 'hidden'
                        }}>
                            <div ref={this.scrollRef} style={{
                                width: "max-content",
                                maxWidth: "100%",
                                height: "max-content",
                                maxHeight: "100%",
                                position: "relative",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "flex-start",
                                maxHeight: "inherit",
                                overflow: "auto"
                            }}>
                                <div style={{ 
                                    width: `${(this.state.daySize * quantDias) + offsetLeft}px`,
                                    minHeight: this.state.granularidade === "semana" ? "90px" : "60px", 
                                    display: "flex",
                                    flexDirection: "row",
                                    position: "sticky",
                                    top: 0,
                                    zIndex: 5 
                                }}>
                                    <div style={{
                                        width: offsetLeft,
                                        lineHeight: this.state.granularidade === "semana" ? "90px" : "60px",
                                        position: "sticky",
                                        left: 0,
                                        borderRight: `1px solid ${Colors.homePage.grey}`,
                                        height: this.state.granularidade === "semana" ? "90px" : "60px",
                                        display: "flex",
                                        zIndex: 10,
                                        backgroundColor: Colors.tag,
                                        fontWeight: 500,
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        borderBottom: `1px solid ${Colors.tag}`
                                    }}>
                                        {this.props.i18n.t('client_src_components_modules_planoAcao_PlanoAcaoTimeline:etapas')}
                                    </div>
                                    <div style={{
                                        width: `${this.state.daySize * quantDias}px`,
                                        display: "flex",
                                        flexDirection: "column",
                                        position: "relative",
                                        zIndex: 4
                                    }}>
                                        { this.state.granularidade === "ano" &&
                                            <div style={{
                                                display: "flex",
                                                minWidth: "100%",
                                                height: "30px",
                                                flexDirection: "row",
                                                justifyContent: 'center',
                                                alignItems: "center"
                                            }}>
                                            {
                                                Array.from({ length: quantAnos }, (_, i) => i).map((_, index) => {
                                                    const currentDate = moment(new Date(timelineStart)).add(index, 'years').toDate().getTime();
                                                    let daysInYear;
                                                    if (index === 0) {
                                                        daysInYear = Math.round(moment(new Date(currentDate)).endOf('year').diff(moment(new Date(currentDate)), 'days', true));
                                                    } else if (index === quantAnos - 1) {
                                                        daysInYear = Math.round(moment(new Date(timelineEnd)).diff(moment(new Date(currentDate)).startOf('year'), 'days', true));
                                                    } else {
                                                        daysInYear = Math.round(moment(new Date(currentDate)).endOf('year').diff(moment(new Date(currentDate)).startOf('year'), 'days', true));
                                                    }
                                                    return (
                                                        <div key={`year-${index}`} style={{
                                                            width: `${daysInYear * this.state.daySize}px`,
                                                            lineHeight: "30px",
                                                            fontWeight: 500,
                                                            minHeight: "30px",
                                                            display: "flex",
                                                            position: "relative",
                                                            borderBottom: `1px solid ${Colors.tag}`,
                                                            flexDirection: "column",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                            backgroundColor: index % 2 === 1 ? Colors.tag : Colors.white
                                                        }}>
                                                            {moment(new Date(currentDate)).format('YYYY')}
                                                        </div>
                                                    );
                                                })
                                            }
                                            </div>
                                        }
                                        <div style={{
                                            display: "flex",
                                            minWidth: "100%",
                                            height: this.state.granularidade === "mes" ? "60px" : "30px",
                                            flexDirection: "row",
                                            justifyContent: 'center',
                                            alignItems: "center"
                                        }}>
                                            {
                                                Array.from({ length: quantMonths }, (_, i) => i).map((_, index) => {
                                                    const daysInMonth = moment(new Date(timelineStart)).add(index, 'months').daysInMonth();
                                                    return (
                                                        <div key={`month-${index}`} style={{
                                                            width: `${daysInMonth * this.state.daySize}px`,
                                                            lineHeight: this.state.granularidade === "mes" ? "28px" : "30px",
                                                            fontWeight: 500,
                                                            minHeight: this.state.granularidade === "mes" ? "60px" : "30px",
                                                            display: "flex",
                                                            position: "relative",
                                                            borderBottom: `1px solid ${Colors.tag}`,
                                                            flexDirection: "column",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                            backgroundColor: index % 2 === 1 ? Colors.tag : Colors.white
                                                        }}>
                                                            {moment(new Date(timelineStart)).add(index, 'months').format(this.state.granularidade === "ano" ? 'MM' : 'MMM/YYYY')}
                                                        </div>
                                                    );
                                                })
                                            }
                                        </div>
                                        { this.state.granularidade === "semana" &&
                                            <div style={{
                                                display: "flex",
                                                minWidth: "100%",
                                                height: "30px",
                                                flexDirection: "row",
                                                justifyContent: 'center',
                                                alignItems: "center"
                                            }}>
                                            { 
                                                Array.from({ length: quantSemanas }, (_, i) => i).map((_, index) => {
                                                    const currentWeek = moment(new Date(timelineStart)).add(index, 'weeks');
                                                    let daysInWeek = 7;
                                                    if (index == 0) {
                                                        const weekdayStart = currentWeek.day();
                                                        daysInWeek -= weekdayStart - 1;
                                                    }
                                                    if (index == quantSemanas - 1) {
                                                        const weekdayEnd = currentWeek.day();
                                                        const daysToEnd = parseInt(moment(new Date(timelineEnd)).format('D')) - parseInt(currentWeek.format('D'));
                                                        if (daysToEnd) {
                                                            daysInWeek = weekdayEnd + daysToEnd;
                                                        } else {
                                                            daysInWeek = weekdayEnd;
                                                        }
                                                    }
                                                    return (
                                                        <div key={`week-${index}`} style={{
                                                            width: `${daysInWeek * this.state.daySize}px`,
                                                            lineHeight: "28px",
                                                            fontWeight: 500,
                                                            minHeight: "30px",
                                                            display: "flex",
                                                            position: "relative",
                                                            borderBottom: `1px solid ${Colors.tag}`,
                                                            flexDirection: "column",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                            backgroundColor: Colors.white,
                                                            borderTop: `1px solid ${Colors.tag}`,
                                                            borderRight: `1px solid ${Colors.tag}`,
                                                        }}>
                                                            {daysInWeek > 6 ? this.props.i18n.t("client_src_components_modules_planoAcao_PlanoAcaoTimeline:semana")+ " " + currentWeek.isoWeek() : daysInWeek >= 2 ? currentWeek.isoWeek() : null}
                                                        </div>
                                                    );
                                                })
                                            }
                                            </div>    
                                        }
                                        { this.state.granularidade === "semana" &&
                                            <div style={{
                                                display: "flex",
                                                minWidth: "100%",
                                                height: "30px",
                                                flexDirection: "row",
                                                alignItems: "center",
                                                fontSize: 10
                                            }}>
                                                {
                                                    Array.from({ length: quantDias }, (_, i) => i).map((_, index) => {
                                                        const currentDay = moment(new Date(timelineStart)).add(index, 'days');
                                                        return (
                                                            <div key={`day-${index}`} style={{
                                                                width: `${this.state.daySize}px`,
                                                                lineHeight: "28px",
                                                                fontWeight: 500,
                                                                minHeight: "30px",
                                                                display: "flex",
                                                                position: "relative",
                                                                borderBottom: `1px solid ${Colors.tag}`,
                                                                flexDirection: "column",
                                                                justifyContent: "center",
                                                                alignItems: "center",
                                                                backgroundColor: Colors.white,
                                                                borderRight: `1px solid ${Colors.tag}`,
                                                            }}>
                                                                {currentDay.date()}
                                                            </div>
                                                        );
                                                    })
                                                }
                                            </div>  
                                        }
                                    </div>
                                </div>
                                <div style={{
                                    width: "max-content",
                                    height: "100%",
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    alignItems: "flex-start",
                                    position: "relative",
                                    flexDirection: "column"
                                }}>
                                     {etapas.map((etapa, index) => {
                                        const etapaStart = moment(etapa.data_inicio).startOf('day').toDate().getTime() - timelineStart;
                                        const etapaDuration = moment(etapa.data_fim).endOf('day').toDate().getTime() - moment(etapa.data_inicio).startOf('day').toDate().getTime();
                                        const etapaPosition = Math.round((etapaStart / totalDuration) * quantDias) * this.state.daySize;
                                        const etapaLength = Math.round((etapaDuration / totalDuration) * quantDias) * this.state.daySize;
                                        return (
                                            <div key={index} 
                                                style={{ 
                                                    display: "flex", 
                                                    flexDirection: "row",
                                                    position: 'relative',
                                                    marginTop: this.state.draggingOverItem && this.state.draggingOverItem.id === etapa.id && this.state.draggingOverItemTop ? 40 : 0,
                                                    marginBottom: this.state.draggingOverItem && this.state.draggingOverItem.id === etapa.id && !this.state.draggingOverItemTop ? 40 : 0 
                                                }} 
                                            >
                                                <div style={{
                                                    height: "40px",
                                                    left: 0,
                                                    zIndex: 4,
                                                    width: offsetLeft,
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    padding: "0px 5px",
                                                    alignItems: "center",
                                                    borderRight: `1px solid ${Colors.homePage.grey}`,
                                                    position: "sticky",
                                                    backgroundColor: `${index % 2 === 0 ? Colors.white : Colors.tag}`
                                                }}
                                                    onMouseEnter={() => this.setState({ showEditTextButton: etapa.id })}
                                                    onMouseLeave={() => this.setState({ showEditTextButton: false })}
                                                    draggable={(this.state.editId || this.props.timelineSort !== "user" || !this.props.permissions?.canEdit) ? false : true}
                                                    onDragStart={(this.state.editId || this.props.timelineSort !== "user" || !this.props.permissions?.canEdit) ? () => {} : (e) => this.onDragStartItem(e, etapa)}
                                                    onDragEnd={(this.state.editId || this.props.timelineSort !== "user" || !this.props.permissions?.canEdit) ? () => {} : (e) => this.onDragEndItem(e, etapa)}
                                                    onDragEnter={(this.state.editId || this.props.timelineSort !== "user" || !this.props.permissions?.canEdit) ? () => {} : (e) => this.onDragEnterItem(e, etapa)}
                                                >
                                                    <div style={{ display: 'flex', height: "100%", width: "40px", alignItems: 'center', justifyContent: 'center' }}>
                                                        {this.renderSelectResponsavel(etapa)}
                                                    </div>
                                                    {
                                                    this.state.editId == etapa.id ?
                                                        this.renderEditText()
                                                    :
                                                        <>
                                                            <div style={{
                                                                height: "100%",
                                                                width: this.state.showEditTextButton === etapa.id && this.props.permissions?.canEdit ? "calc(100% - 80px)" : "calc(100% - 40px)",
                                                                fontWeight: 500
                                                            }}>
                                                                <EllipsisText text={DataHelper.removeHtmlAndReplaceListItems(etapa.atividade)} />
                                                            </div>
                                                            { this.renderHasDependencias(index) }
                                                            {
                                                                this.state.showEditTextButton === etapa.id && this.props.permissions?.canEdit &&
                                                                <DefaultButton
                                                                    width={40}
                                                                    leftIcon={<FontAwesomeIcon icon={faPencil} />}
                                                                    color={'transparent'}
                                                                    textColor={Colors.dark}
                                                                    loading={this.state.updatingTitle}
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        if (this.state.editId === etapa.id) return;
                                                                        if (this.state.editId && etapa.id !== -1 && this.state.oldAtividade !== "" && this.state.oldAtividade !== etapa.atividade) {
                                                                            let etapas = [...this.state.etapas];
                                                                            etapas.find(e => e.id === this.state.editId).atividade = this.state.oldAtividade;
                                                                            this.setState({ etapas, editId: etapa.id, oldAtividade: etapa.atividade });
                                                                            return;
                                                                        }
                                                                        this.setState({ editId: etapa.id, oldAtividade: etapa.atividade });
                                                                    }}
                                                                />
                                                            }
                                                        </>
                                                    }
                                                </div>
                                                <div style={{
                                                    left: offsetLeft,
                                                    width: `${this.state.daySize * quantDias}px`,
                                                    paddingTop: '5px',
                                                    paddingBottom: '5px',
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    backgroundColor: `${index % 2 === 0 ? Colors.white : Colors.tag}`
                                                }}>
                                                    <ResizableTimelineElement
                                                        element={{ ...etapa }}
                                                        elementPosition={etapaPosition}
                                                        elementLength={etapaLength}
                                                        width={this.state.daySize * quantDias}
                                                        changeElementCallback={(etapa) => this.changeEtapaCallback(etapa)}
                                                        timelineStart={timelineStart}
                                                        timelineEnd={timelineEnd}
                                                        edit={this.state.edit && this.props.permissions?.canEdit}
                                                        setEditId={etapa.id === -1 ? () => {} : (id) => {
                                                            if (etapa.id !== -1 && this.state.oldAtividade !== "" && this.state.oldAtividade !== etapa.atividade) {
                                                                let etapas = [...this.state.etapas];
                                                                etapas[index].atividade = this.state.oldAtividade;
                                                                this.setState({ etapas, editId: false, oldAtividade: "" });
                                                                this.props.detailsModalCallback(id);
                                                                return;
                                                            }
                                                            this.setState({ editId: false, oldAtividade: "" });
                                                            this.props.detailsModalCallback(id);
                                                        }}
                                                        isSmallScreen={this.state.isSmallScreen}
                                                        type={'etapa'}
                                                        daySize={this.state.daySize}
                                                    />
                                                </div>
                                            </div>
                                        );
                                    })}
                                    {
                                    this.state.etapas.length > 0 && this.state.etapas.findIndex(e => e.id === -1) === -1 && this.props.permissions?.canEdit ?
                                        <div key={etapas.length} style={{ display: "flex", flexDirection: "row" }}>
                                            <div onClick={() => this.addEtapa()}
                                            style={{
                                                height: "40px",
                                                left: 0,
                                                zIndex: 4,
                                                width: offsetLeft,
                                                display: "flex",
                                                justifyContent: "center",
                                                padding: "0px 5px",
                                                alignItems: "center",
                                                borderRight: `1px solid ${Colors.homePage.grey}`,
                                                position: "sticky",
                                                backgroundColor: `${etapas.length % 2 === 0 ? Colors.white : Colors.homePage.extraLightGrey}`,
                                                cursor: 'pointer'
                                            }}>
                                                <FontAwesomeIcon icon={faAdd} style={{ marginRight: 8 }} />
                                                <span style={{ fontWeight: 500 }}>
                                                    {this.props.i18n.t('client_src_components_modules_planoAcao_PlanoAcaoTimeline:adicionar_etapa')}
                                                </span>
                                            </div>
                                            <div style={{
                                                left: offsetLeft,
                                                width: `${this.state.daySize * quantDias}px`,
                                                paddingTop: '5px',
                                                paddingBottom: '5px',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                backgroundColor: `${etapas.length % 2 === 0 ? Colors.white : Colors.homePage.extraLightGrey}`
                                            }}>
                                            </div>
                                        </div>
                                    : 
                                        null
                                    }
                                </div>
                            </div>           
                        </div>
                    : 
                    <>
                        <span style={{ fontSize: 18, fontWeight: 500, color: Colors.homePage.grey, marginTop: 16 }}>
                            {this.props.i18n.t('client_src_components_modules_planoAcao_PlanoAcaoTimeline:sem_etapas_exibir')}
                        </span>
                        <DefaultButton
                            leftIcon={<FontAwesomeIcon icon={faAdd} />}
                            color={Colors.success}
                            textColor={Colors.white}
                            title={this.props.i18n.t('client_src_components_modules_planoAcao_PlanoAcaoTimeline:adicionar_etapa')}
                            loading={this.state.loading}
                            onClick={() => this.addEtapa()}
                            style={{ marginRight: 8, marginTop: 16, marginBottom: 16 }}
                            disabled={this.props.permissions?.canEdit == false}
                        />
                    </>
                    }
                </div>
            </div>
        );
    }

    renderLoading() {
        return (
            <div style={{ ...EssentialStyle.columnCenter, width: '100%', height: `calc(100vh - ${this.getHeaderHeight()}px)` }}>
                <DefaultLoader />
            </div>
        );
    }

    render() {
        return this.state.loading || !this.state.etapas ? this.renderLoading() : this.renderTimeline();
    }
}

export default withTranslation()(PlanoAcaoTimeline);