import React from "react";
import IdiomaHelper from "../../../../helper/IdiomaHelper";

import './Indicadores.css';
import Colors from "../../../../constants/Colors";
import DefaultButton from "../../../../components/tools/DefaultButton";
import Select from 'react-select';
import DataHelper from "../../../../helper/DataHelper";
import DiagnosticoHelper from "../../../../helper/diagnostico/DiagnosticoHelper";
import IndicadoresConfig from "./IndicadoresConfig/IndicadoresConfig";
import { withTranslation } from 'react-i18next';


class Indicadores extends React.Component {

    state = {
        lang: IdiomaHelper.getIdioma(),
        loadingPeriodosAvaliacao: true,
        periodosAvaliacao: [],
        idPeriodoAvaliacao: null,
        canEdit: false,
        canView: false,
    }

    async componentDidMount() {
        await this.props.i18n.loadNamespaces(['client_src_helper_diagnostico_DiagnosticoHelper']);

        this.loadPeriodos();
    }
    
    loadPeriodos = async () => {
        
        this.setState({ loadingPeriodosAvaliacao: true });

        let { periodos, canEdit, canView } = await DiagnosticoHelper.getPeriodosAvaliacao(this.props.idProcesso);
        
        let idPeriodoAvaliacao = null;

        let localId = DiagnosticoHelper.getPeriodoAvaliacaoLocal(this.props.idProcesso);

        if (localId && periodos.find(periodo => periodo.id == localId)) {
            idPeriodoAvaliacao = localId;
        } else {
            idPeriodoAvaliacao = periodos.length > 0 ? periodos[0].id : null;
        }
            

        this.setState({ periodosAvaliacao: periodos, idPeriodoAvaliacao, canEdit, canView, loadingPeriodosAvaliacao: false }, () => {
            
        });
    }

    changePeriodo = (idPeriodoAvaliacao) => {
        this.setState({ idPeriodoAvaliacao });
    }

    renderHeader() {
    
        const message = IdiomaHelper.getStruct({
            'pt_br': {
                selectPlaceholder: 'Selecione um Período de Avaliação',
            },
            'en': {
                selectPlaceholder: 'Select an Evaluation Period',
            },
            'es': {
                selectPlaceholder: 'Seleccione un Período de Evaluación',
            }
        });

        return (
            <div className="indicadores-header" style={{boxShadow: Colors.boxShadow}}>
                <div className="header-left">
                    <Select
                        styles={{ control: (base) => ({ ...base, border: 'none', minWidth: '40vh', marginRight: 2 }) }}
                        options={DataHelper.formatSelectData(this.state.periodosAvaliacao, 'id', 'nome')}
                        value={DataHelper.formatSelectData(this.state.periodosAvaliacao, 'id', 'nome').find(option => option.value === this.state.idPeriodoAvaliacao)}
                        onChange={(valueSelected) => {  this.changePeriodo(valueSelected.value); DiagnosticoHelper.setPeriodoAvaliacaoLocal(valueSelected.value, this.props.idProcesso) }}
                        placeholder={message[this.state.lang].selectPlaceholder}
                        noOptionsMessage={DataHelper.getSelectEmptyMessage}
                        isSearchable
                        menuPortalTarget={document.body}
                    />
                </div>

                <div className="header-right">
                    <DefaultButton
                        style={{ width: 38, height: 38 }}
                        fontSize={this.props.isSmallScreen ? 14 : "unset"}
                        color={Colors.secondaryButton}
                        textColor={Colors.dark}
                        link={'https://advis.freshdesk.com/support/solutions/folders/63000238312'}
                    />
                </div>
            </div>
        )
    }

    renderConfigIndicadores() {

        return (
            <div className="indicadores-config">
                <IndicadoresConfig
                    lang={this.state.lang}
                    idPeriodoAvaliacao={this.state.idPeriodoAvaliacao}
                />
            </div>
        )
    }

    render() {
        return (
            <div className="Indicadores">
                {this.renderHeader()}
                {this.state.idPeriodoAvaliacao ? this.renderConfigIndicadores() : DiagnosticoHelper.renderPeriodoNotSelected(this.props.t, this.state.loadingPeriodosAvaliacao)}
            </div>
        )
    }
}

export default withTranslation()(Indicadores);