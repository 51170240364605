import React from "react";
import { withTranslation } from "react-i18next";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp,
         faArrowDown,
         faMinus,
} from "@fortawesome/free-solid-svg-icons";
import Colors from "../../../../../constants/Colors";
import EssentialStyle from "../../../../../style/EssentialStyle";
import DefaultLoader from "../../../../tools/DefaultLoader";
import Highcharts from "highcharts/highcharts.js";
import HighchartsReact from "highcharts-react-official";
import Constants from "../../../../../constants/Api";
import moment from "moment";
import Sig from "../../../../../api/Sig";
import EllipsisText from "../../../../tools/EllipsisText";
import IdiomaHelper from "../../../../../helper/IdiomaHelper";

const gateway = Constants.getSigEndPoint();

class VinculosPlanoAcao extends React.Component {

    state = {
        data: null,
        id_colaborador: this.props.id_colaborador,
        status: [
            "Não Iniciada",
            "Em Desenvolvimento",
            "Concluída",
            "Atrasada",
            "Cancelada",
        ],
        loading: true,
        transform: 'translateY(+100%)',
        opacity: 0,
        expanded: this.props.expandedPageOne,
        vinculos: this.props.vinculos,
        vinculoAtual: 0,
        viewCard: null,
        indicadorExpandedHistory: null,
        yAxis: [],
        isSmallScreen: this.props.isSmallScreen,
    }

    async componentDidMount() {
        await this.props.i18n.loadNamespaces(['client_src_components_modules_inicio_charts_meusPlanosAcao_VinculosPlanoAcao']);

        if(this.state.vinculos.length > 0) {   
            this.setState({ vinculoAtual: 0});

            if(this.state.vinculos[this.state.vinculoAtual].resultadoVinculado?.periodos?.length > 0) {
                await this.getResultadoIndicador();
            } else {
                this.setState({ data: null, loading: false });
            }
        }
        this.triggerAnimations();
    }

    async componentDidUpdate(prevProps, prevState) {
        if(this.state.vinculos.length > 0 && prevState.vinculoAtual !== this.state.vinculoAtual) {   
            if(this.state.vinculos[this.state.vinculoAtual].resultadoVinculado?.periodos?.length > 0) {
                await this.getResultadoIndicador();
            }else {
                this.setState({ data: null, loading: false });
            }
        }

        if(prevProps.isSmallScreen !== this.props.isSmallScreen) {
            this.setState({ isSmallScreen: this.props.isSmallScreen });
        }

        if(this.state.expanded !== this.props.expandedPageOne)
            this.setState({ expanded: this.props.expandedPageOne });
    }

    async getResultadoIndicador() {
        this.setState({ loading: true });

        var vinculo = this.state.vinculos[this.state.vinculoAtual];
        var data_inicio = moment(new Date(vinculo.inicio_ano, vinculo.inicio_mes)).format('YYYY-MM-DD HH:mm:ss');
        var data_fim = moment(new Date(vinculo.fim_ano, vinculo.fim_mes)).format('YYYY-MM-DD HH:mm:ss');

        let { indicadorExpandedHistory } = await Sig.request('POST', 'config/colaborador/getIndicadorHistory', {
            id_indicador: vinculo.id_indicador,
            id_gmr_painel: vinculo.id_gmr_painel,
            data_inicio,
            data_fim,
            acumulado: false,
        });
        this.setState({ indicadorExpandedHistory }, this.calculaGrafico);

    }

    calculaGrafico(){

        const that = this;

        if(this.state.indicadorExpandedHistory == null || this.state.indicadorExpandedHistory == undefined) {
            this.setState({ data: null, yAxis: [], loading: false });
            return;
        }

        let type = null;
        let series = [];

        type = 'spline';

        let unidade = this.state.indicadorExpandedHistory?.unidade;
        let moeda = this.state.indicadorExpandedHistory?.moedaSimbolo;
        let yAxis = [];

        yAxis = [
            { // Primary yAxis Meta e Realizado
                labels: {
                    formatter: function () {
                        if (unidade == 0 || unidade == 1) {
                            return moeda + " " + this.value.toLocaleString('pt-BR', { minimumFractionDigits: 2 });
                        } else if (unidade == 2 || unidade == 3) {
                            return this.value + '%';
                        } else if (unidade == 6 || unidade == 7) {
                            return this.value + ' ' + that.props.t('client_src_components_modules_inicio_charts_meusPlanosAcao_VinculosPlanoAcao:dias');
                        } else if (unidade == 8 || unidade == 9) {
                            return this.value + ' ' + that.props.t('client_src_components_modules_inicio_charts_meusPlanosAcao_VinculosPlanoAcao:horas');
                        } else {
                            return this.value;
                        }
                    },
                    style: {
                        color: Highcharts.getOptions().colors[1]
                    }
                },
                title: {
                    text: this.props.t('client_src_components_modules_inicio_charts_meusPlanosAcao_VinculosPlanoAcao:visualization_types.metaRealizado'),
                    style: {
                        color: Highcharts.getOptions().colors[1]
                    }
                }
            }, { // Secondary yAxis Performance
                title: {
                    text: this.props.t('client_src_components_modules_inicio_charts_meusPlanosAcao_VinculosPlanoAcao:performance'),
                    style: {
                        color: Highcharts.getOptions().colors[0]
                    }
                },
                labels: {
                    format: "{value}%",
                    style: {
                        color: Highcharts.getOptions().colors[0]
                    }
                },
                opposite: true
            }];

        series.push({
            name: 'Meta',
            type: type,
            innerSize: '65%',
            yAxis: 0,
            data: this.state.indicadorExpandedHistory?.performanceMetaPeriodos.map((key) => ({
                y: parseFloat(key.meta),
                yFormated: key.metaValue,
            })),
            color: Colors.graphics[0]
        })
        series.push({
            name: 'Realizado',
            type: type,
            innerSize: '65%',
            yAxis: 0,
            data: this.state.indicadorExpandedHistory?.performanceRealizadoPeriodos.map((key) => ({
                y: parseFloat(key.realizado),
                yFormated: key.realizadoValue,
            })),
            color: Colors.graphics[1]
        })
        series.push({
            name: 'Performance',
            type: type,
            innerSize: '65%',
            yAxis: 1,
            data: this.state.indicadorExpandedHistory?.performancePeriodos.map((key) => ({
                y: parseFloat(key.performance),
                yFormated: key.performanceValue,
            })),
            color: Colors.graphics[2]
        })

        this.setState({ data: series , yAxis});
        this.setState({ loading: false });
    }

    getAnimations() {
        return {
            opacity: this.state.opacity,
            transform: this.state.transform,
            transition: 'opacity .3s ease-in-out, transform .6s ease-in-out'
        }
    }

    fadeAnimations(swipeTop = true) {
        this.setState({ opacity: 0, transform: `translateY(${swipeTop ? `-100%` : `+100%`})` });
    }

    triggerAnimations() {
        this.setState({ opacity: 1, transform: 'translateY(0)' });
    }

    renderLoading(bloco) {
        const trackColors = Highcharts.getOptions().colors.map(color =>
            new Highcharts.Color(color).setOpacity(0.3).get()
        );

        if (bloco === 2) {
            return (
                // <div style={{...EssentialStyle.columnCenter, width: '100%', overflow: 'hidden'}}>
                //     {Array.from({ length: this.props.numberSubordinados }).map((elem, index) => (
                //         <div key={`plhdr-${index}`} style={{ marginTop: 5, width: '100%' }}>
                //             {this.renderLoadingPlaceholder()}
                //         </div>
                //     ))}
                // </div>
                <></>
            );
        } else {
            return (
                <div style={{...EssentialStyle.columnCenter, width: '100%', height: '100%'}}>
                    <DefaultLoader size={50} />
                </div>
            );
        }
    }

    changePage(pageNumber) {
        var page = 0;
        var increase = true
        if(pageNumber !== null) {
            page = pageNumber;
            increase = pageNumber > this.state.vinculoAtual;
        } 

        if(page === this.state.vinculoAtual) return;

        this.fadeAnimations(increase);

        setTimeout(() => {
            this.setState({ vinculoAtual: page }, () => {
                this.triggerAnimations();
            });
        }, 400);
    }

    renderControls() {
        return (
            <div style={{
                ...EssentialStyle.columnSpaceAround,
                width: '100%',
                height: '100%',
                overflow: 'hidden',
                borderLeft: `0.5px solid ${Colors.homePage.lightGrey}`,
            }}>
                <div style={{
                    ...(this.state.isSmallScreen ? EssentialStyle.columnStart : EssentialStyle.columnCenter),
                    flexWrap: `${this.state.isSmallScreen ? 'unset' : 'wrap'}`,
                    overflowY: `${this.state.isSmallScreen ? 'auto' : 'hidden'}`,
                    overflowX: 'hidden',
                    width: '100%',
                    height: "100%"
                }}>
                    {
                        this.state.vinculos.map( (_, i) => (
                            <div
                                style={{
                                    width: 25,
                                    height: 25,
                                    textAlign: 'center',
                                    borderRadius: `50%`,
                                    backgroundColor: i === this.state.vinculoAtual ? Colors.info : Colors.notSelected,
                                    cursor:  i === this.state.vinculoAtual ? 'not-allowed' : 'pointer',
                                    margin: '15px 5px'
                                }}
                                onClick={() => { this.changePage(i) }}
                                key={`dot-page-${i}`}
                            >
                                {i+1}
                            </div>
                        ))
                    }
                </div>
            </div>
        );
    }

    getTendenciaIcons() {
        return {
            'Tendência de Ineficácia': <FontAwesomeIcon icon={faArrowDown} style={{ color: Colors.error }} />,
            'Inalterado': <FontAwesomeIcon icon={faMinus} style={{ color: Colors.homePage.grey }} />,
            'Tendência de Eficácia': <FontAwesomeIcon icon={faArrowUp} style={{ color: Colors.success }} />
        }
    }

    renderGraficoIndicador() {
        const that = this;

        return(
            <div style={{
                width: "100%",
                height: `${this.state.isSmallScreen ? "100%" : "95%"}`, 
            }}>
                { this.state.data == null ? 
                    <div style={{...EssentialStyle.columnCenter, width: '100%', height: '100%'}}>
                        <div style={{...EssentialStyle.columnCenter, width: '100%', height: '100%'}}>
                            {this.props.t('client_src_components_modules_inicio_charts_meusPlanosAcao_VinculosPlanoAcao:no_indicator_data')}
                        </div>
                    </div>
                :
                    <HighchartsReact
                        highcharts={Highcharts}
                        constructorType={'chart'}
                        containerProps={{ style: { height: "100%", width: "100%" } }}
                        options={{
                        chart: {
                            type: 'spline',
                            backgroundColor: 'transparent',
                            spacing: [0, 0, 0, 0],
                        },
                        title: {
                            text: '',
                            y: 0,
                        },
                        xAxis: {
                            categories: this.state.indicadorExpandedHistory?.periodo.map((key) => (key.mes + "/" + String(key.ano).slice(-2))),
                            accessibility: {
                                description: this.props.t('client_src_components_modules_inicio_charts_meusPlanosAcao_VinculosPlanoAcao:periodos_painel_gmr')
                            }
                        },
                        yAxis: this.state.yAxis,
                        legend: {
                            enabled: true,
                            verticalAlign: 'top',
                            layout: 'horizontal',
                            y: 0,
                            floating: false,
                            labelFormatter: function () {
                                return that.props.t('client_src_components_modules_inicio_charts_meusPlanosAcao_VinculosPlanoAcao:' + IdiomaHelper.getTextTokenName(this.name));
                            }
                        },
                        tooltip: {
                            shared: true,
                            useHTML: true,
                            headerFormat: '<span style="font-size: 15px">{point.key}</span><hr/><table>',
                            pointFormat: '<tr><span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.yFormated}</b></tr><br/>',
                            footerFormat: '</table>',
                            formatter: function () {
                                let s = '<span style="font-size: 15px">' + this.x + '</span><br/>';
                                //black line to separate header from data
                                s += '<hr style="border: 1px solid black">';
                                s+= '<table>';
                                if(this.points && this.points.length > 1) {
                                    this.points.map((point) => {
                                        s += '<tr><span style="color:' + point.color + '">\u25CF</span> ' + that.props.t(`client_src_components_modules_inicio_charts_meusPlanosAcao_VinculosPlanoAcao:${IdiomaHelper.getTextTokenName(point.series.name)}`) + ': <b>' + point.point.yFormated + '</b></tr><br/>';
                                    });
                                } else if(this.point) {
                                    s += '<tr><span style="color:' + this.point.color + '">\u25CF</span> ' + that.props.t(`client_src_components_modules_inicio_charts_meusPlanosAcao_VinculosPlanoAcao:${IdiomaHelper.getTextTokenName(this.point.series.name)}`) + ': <b>' + this.point.yFormated + '</b></tr><br/>';
                                }
                                return s + '</table>';
                            },
                        },
                        plotOptions: {
                            spline: {
                                marker: {
                                    radius: 4,
                                },
                                lineWidth: 2,
                                dashStyle: 'none'
                            }
                        },
                        credits: {
                            enabled: false
                        },
                        series: this.state.data
                        }}
                    />
                }
            </div>
        )
    }

    renderIndicador() {
        return(
            <div style={{ ...(this.props.column || this.props.expandedPageOne === "MeusPlanosAcao" || this.state.isSmallScreen ? EssentialStyle.columnSpaceBetween : EssentialStyle.rowSpaceBetween) , width: '100%', height: '100%', paddingLeft: "10px"}}>
                <div style={{...EssentialStyle.columnCenter, height: `${this.props.column || this.props.expandedPageOne === "MeusPlanosAcao" || this.state.isSmallScreen ? "40%" : "100%" }`, width:`${this.props.column || this.props.expandedPageOne === "MeusPlanosAcao" || this.state.isSmallScreen ? "100%" : "30%" }`, textAlign: "center", minHeight: `${this.state.isSmallScreen ? '140px' : '220px'}`}}>
                    <div style={{width: "100%", height: `${this.state.isSmallScreen ? '55%' : '40%'}`}}>
                        <div style={{width: 'calc(100%)', height: '35%', fontSize: 14, overflow: "hidden", color: Colors.homePage.grey, fontWeight: 500}}>
                            <EllipsisText text={this.state.vinculos[this.state.vinculoAtual].resultadoVinculado?.processo} />
                        </div>
                        <div style={{width: 'calc(100%)', height: '35%', fontSize: 14, overflow: "hidden", color: Colors.homePage.grey}}>
                            <EllipsisText text={this.state.vinculos[this.state.vinculoAtual].resultadoVinculado?.painel} />
                        </div>
                        <div style={{width: 'calc(100%)', height: '30%', fontSize: 14, overflow: "hidden", color: Colors.homePage.grey}}>
                            <EllipsisText text={this.state.vinculos[this.state.vinculoAtual].resultadoVinculado?.indicador} />
                        </div>
                    </div>
                    <div style={{...EssentialStyle.columnCenter, width: '100%', maxWidth: `${this.state.isSmallScreen ? '310px' : '200px'}`, height: `${this.state.isSmallScreen ? '45%' : '60%'}`, alignItems: "center", justifyContent: "center", border: `0.5px solid${Colors.homePage.lightGrey}`, borderRadius: 5}}>
                        <div style={{...(this.state.isSmallScreen ? EssentialStyle.rowFlexStart : EssentialStyle.columnCenter), width: `${this.state.isSmallScreen ? '98%' : 'unset'}`}}>
                            <div style={{...EssentialStyle.columnCenter, width: `${this.state.isSmallScreen ? '50%' : '100%'}`, height: `${this.state.isSmallScreen ? '100%' : '45%'}`}}>
                                <div style={{...EssentialStyle.columnCenter, width: '100%', height: '30%', fontSize: 12}}>
                                    {this.props.t('client_src_components_modules_inicio_charts_meusPlanosAcao_VinculosPlanoAcao:visualization_types.meta')}
                                </div>
                                <div style={{...(this.state.isSmallScreen ? EssentialStyle.columnEnd : EssentialStyle.columnCenter), width: '100%', height: '70%', fontSize: 40, lineHeight: "100%"}}>
                                    {this.state.vinculos[this.state.vinculoAtual].resultadoVinculado?.meta ? this.state.vinculos[this.state.vinculoAtual].resultadoVinculado?.meta + "%": "N/A"}
                                </div>
                            </div>
                            <div style={{...EssentialStyle.columnCenter, width: `${this.state.isSmallScreen ? '50%' : '100%'}`, height: `${this.state.isSmallScreen ? '100%' : '55%'}`}}>
                                <div style={{...EssentialStyle.columnCenter, width: '100%', height: '70%', fontSize: `${this.state.isSmallScreen ? "45px" : "60px"}`, lineHeight: "100%"}}>
                                    {this.state.vinculos[this.state.vinculoAtual].resultadoVinculado?.tendencia?.msg ? 
                                    this.getTendenciaIcons()[this.state.vinculos[this.state.vinculoAtual].resultadoVinculado?.tendencia?.msg] : null}
                                </div>
                                <div style={{...EssentialStyle.columnCenter, width: '100%', height: '30%', fontSize: `${this.state.isSmallScreen ? '12px' : '14px'}`, textAlign: "center"}}>
                                    {this.props.t(`client_src_components_modules_inicio_charts_meusPlanosAcao_VinculosPlanoAcao:tendencia.${IdiomaHelper.getTextTokenName(this.state.vinculos[this.state.vinculoAtual].resultadoVinculado?.tendencia?.msg)}`)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{...EssentialStyle.columnCenter, height: `${this.props.column || this.props.expandedPageOne === "MeusPlanosAcao" || this.state.isSmallScreen ? "60%" : "100%" }`, width:`${this.props.column || this.props.expandedPageOne === "MeusPlanosAcao" || this.state.isSmallScreen ? "100%" : "70%" }`, textAlign: "center"}}>
                    {this.renderGraficoIndicador()}   
                </div>
            </div>
        )
    }
    
    renderPesquisaOrganizacional() {
        return(
            <div style={{...EssentialStyle.rowSpaceBetween, width: '100%', height: '100%'}}>
                <div style={{...EssentialStyle.columnCenter, width: '100%', height: `${this.props.column || this.props.expandedPageOne === "MeusPlanosAcao" ? "50%" : "100%" }`, paddingLeft: "10px", textAlign: "center"}}>
                    <div style={{width: "100%", height: "40%"}}>
                        <div style={{width: 'calc(100%)', height: '30%', fontSize: 14, overflow: "hidden", color: Colors.homePage.grey, fontWeight: 500}}>
                            <EllipsisText text={this.state.vinculos[this.state.vinculoAtual].resultadoVinculado?.processo} />
                        </div>
                        <div style={{width: 'calc(100%)', height: '70%', fontSize: 14, overflow: "hidden", color: Colors.homePage.grey}}>
                            <EllipsisText text={this.state.vinculos[this.state.vinculoAtual].resultadoVinculado?.pergunta} />
                        </div>
                    </div>
                    <div style={{...EssentialStyle.columnCenter, width: '100%', minHeight: "120px", maxWidth: "250px", height: '50%', alignItems: "center", justifyContent: "center", border: `0.5px solid${Colors.homePage.lightGrey}`, borderRadius: 5}}>
                        <div style={{...EssentialStyle.columnCenter}}>
                            <div style={{...EssentialStyle.columnCenter, width: '100%', height: '20%', fontSize: 25, color: Colors.homePage.grey, lineHeight: "100%", justifyContent: "center"}}>
                                {this.props.t('client_src_components_modules_inicio_charts_meusPlanosAcao_VinculosPlanoAcao:resultado')}
                            </div>
                            <div style={{...EssentialStyle.columnCenter, width: '100%', height: '80%', fontSize: 100, lineHeight: "100%", 
                                        color:`${this.state.vinculos[this.state.vinculoAtual].resultadoVinculado?.color ? this.state.vinculos[this.state.vinculoAtual].resultadoVinculado.color : "black"}`}}>
                                {this.state.vinculos[this.state.vinculoAtual].resultadoVinculado?.resultado ? this.state.vinculos[this.state.vinculoAtual].resultadoVinculado.resultado : "N/A"}
                            </div>
                        </div>
                    </div>
                </div>
            </div>   
        )
    }

    renderPDI() {
        return(
            <div style={{...EssentialStyle.rowSpaceBetween, width: '100%', height: '100%'}}>
                <div style={{...EssentialStyle.columnCenter, width: '100%', height: `${this.props.column || this.props.expandedPageOne === "MeusPlanosAcao" ? "70%" : "95%" }`, paddingLeft: "10px", textAlign: "center"}}>
                    <div style={{width: "100%", height: "50%"}}>
                        <div style={{width: 'calc(100%)', height: '20%', fontSize: 14, overflow: "hidden", color: Colors.homePage.grey, fontWeight: 500}}>
                            <EllipsisText text={this.state.vinculos[this.state.vinculoAtual].resultadoVinculado?.processo} />
                        </div>
                        <div style={{width: 'calc(100%)', height: '20%', fontSize: 14, overflow: "hidden", color: Colors.homePage.grey}}>
                            <EllipsisText text={this.state.vinculos[this.state.vinculoAtual].resultadoVinculado?.pilar} />
                        </div>
                        <div style={{width: 'calc(100%)', height: '20%',  fontSize: 14, overflow: "hidden", color: Colors.homePage.grey}}>
                            <EllipsisText text={this.state.vinculos[this.state.vinculoAtual].resultadoVinculado?.competencia} />
                        </div>
                        <div style={{width: 'calc(100%)', height: '40%', fontSize: 14, overflow: "hidden", color: Colors.homePage.grey}}>
                            <EllipsisText text={this.state.vinculos[this.state.vinculoAtual].resultadoVinculado?.comportamento} />
                        </div>
                    </div>
                    <div style={{...EssentialStyle.columnCenter, width: '100%', minHeight: "120px", maxWidth: "250px", height: '50%', alignItems: "center", justifyContent: "center", border: `0.5px solid${Colors.homePage.lightGrey}`, borderRadius: 5}}>
                        <div style={{...EssentialStyle.columnCenter}}>
                            <div style={{...EssentialStyle.columnCenter, width: '100%', height: '20%', fontSize: 25, color: Colors.homePage.grey, lineHeight: "100%", justifyContent: "center"}}>
                                {this.props.t('client_src_components_modules_inicio_charts_meusPlanosAcao_VinculosPlanoAcao:resultado')}
                            </div>
                            <div style={{...EssentialStyle.columnCenter, width: '100%', height: '80%', fontSize: 100, lineHeight: "100%", textJustify: "center",
                                        color:`${this.state.vinculos[this.state.vinculoAtual].resultadoVinculado?.resultado?.color ? this.state.vinculos[this.state.vinculoAtual].resultadoVinculado.resultado.color : "black"}`}}>
                                {this.state.vinculos[this.state.vinculoAtual].resultadoVinculado?.resultado?.media ? this.state.vinculos[this.state.vinculoAtual].resultadoVinculado?.resultado?.media + "%" : "N/A"}
                            </div>
                        </div>
                    </div>
                </div>
            </div>   
        )
    }

    renderContent() {
        
        return (
            <div style={{...EssentialStyle.rowFlexStart, width: '100%', height: '100%'}}>
                <div style={{ display: 'flex', width: '100%', height: '100%', flexDirection: "row", overflow: "hidden"}}>
                    <div style={{...this.getAnimations(), display: 'flex', width: '90%', height: '100%', flexDirection: "column", overflow: "hidden"}}>
                        { this.state.loading ? this.renderLoading(1) 
                        :this.state.vinculos[this.state.vinculoAtual].tipo === "Gestão de Metas e Resultados" || 
                         this.state.vinculos[this.state.vinculoAtual].tipo === "Planejamento Estratégico de Gestão" ||
                         this.state.vinculos[this.state.vinculoAtual].tipo === "Análise de Problema" ? 
                            this.renderIndicador() 
                        : this.state.vinculos[this.state.vinculoAtual].tipo === "Pesquisa Organizacional" ? 
                            this.renderPesquisaOrganizacional() 
                        : this.state.vinculos[this.state.vinculoAtual].tipo === "Avaliação de Competências" ? 
                            this.renderPDI() 
                        : null}
                    </div>
                    {this.state.vinculos.length > 0 ? 
                        <div style={{ display: 'flex', width: '10%', minWidth: `${this.state.isSmallScreen ? '50px' : '70px'}`, height: '100%', margin: `0px ${this.state.isSmallScreen ? '5px' : '10px'}`}}>
                            {this.renderControls()}
                        </div>
                        : null
                    }
                </div>
            </div>
        )
    }

    render() {

        return (
            <div style={{ ...this.props.animations, display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', height: '100%' }}>
                {this.renderContent()}
            </div>
        )
    }
}

export default withTranslation()(VinculosPlanoAcao);    