import React from "react";

import DiagnosticoHelper from "../../../../../helper/diagnostico/DiagnosticoHelper";
import DefaultLoader from "../../../../tools/DefaultLoader";
import IdiomaHelper from "../../../../../helper/IdiomaHelper";

import RelatorioCard from "../RelatorioCard/RelatorioCard";
import { withTranslation } from 'react-i18next';

import './ListRelatorios.css';

class ListRelatorios extends React.Component {

    filterTimeout = null;
    state = {
        loading: true,
        idPeriodoAvaliacao: this.props.idPeriodoAvaliacao,
        relatorios: [],
        page: 1,
        hasMore: true,
        firstLoad: true,
        requesting: false,
    }

    async componentDidMount() {
        await this.props.i18n.loadNamespaces(['client_src_helper_diagnostico_DiagnosticoHelper']);

        await this.loadData();
        document.addEventListener('scroll', this.handleScroll);
    }

    handleScroll = () => {
        const listRelatoriosDiv = document.documentElement;
        if (listRelatoriosDiv) {
            if (listRelatoriosDiv.scrollTop + listRelatoriosDiv.clientHeight >= listRelatoriosDiv.scrollHeight) {
                if (!this.state.loading && !this.state.requesting && this.state.hasMore) {
                    this.loadData(this.state.page + 1);
                }
            }
        }
    }

    async componentDidUpdate(prevProps) {
        if (prevProps.idPeriodoAvaliacao !== this.props.idPeriodoAvaliacao) {
            this.setState({ idPeriodoAvaliacao: this.props.idPeriodoAvaliacao, firstLoad: true }, () => {
                this.loadData();
            });
        }

        if (prevProps.status !== this.props.status) {
            this.setState({ firstLoad: true }, () => {
                this.loadData();
            });
        }

        if (prevProps.textFilter !== this.props.textFilter) {
            if(this.filterTimeout) {
                clearTimeout(this.filterTimeout);
            }
            this.filterTimeout = setTimeout(() => {
                this.setState({ firstLoad: true }, () => {
                    this.loadData();
                });
            }, 1000);
        }
    }

    componentWillUnmount() {
        document.removeEventListener('scroll', this.handleScroll);
    }

    async loadData(page = 1, limit = 10) {
        this.setState({ loading: true });
        await this.loadRelatorios(page, limit);
        this.setState({ loading: false });
    }

    async loadRelatorios(page = 1, limit = 10) {
        this.setState({ requesting: true });

        let paramsRelatorios = {
            idPeriodoAvaliacao: this.state.idPeriodoAvaliacao,
            status: this.props.status,
            relations: 0,
            lang: this.props.lang,
            textFilter: this.props.textFilter
        }

        if(this.props.isEmpresaAvaliadora) {
            paramsRelatorios.avaliador = true;
            paramsRelatorios.avaliado = false;
        } else {
            paramsRelatorios.avaliador = false;
            paramsRelatorios.avaliado = true;
        }

        let relatorios = await DiagnosticoHelper.getRelatorios(
            paramsRelatorios.idPeriodoAvaliacao, 
            paramsRelatorios.textFilter,
            paramsRelatorios.status, 
            paramsRelatorios.avaliador, 
            paramsRelatorios.avaliado, 
            paramsRelatorios.relations, 
            paramsRelatorios.lang,
            page,
            limit
        );

        if(!relatorios || relatorios.length == 0) {
            this.setState({ hasMore: false });
            if(this.state.firstLoad) {
                this.setState({ relatorios: [], page, requesting: false, firstLoad: false });
            }
            return;
        }

        if(relatorios.length < limit) {
            this.setState({ hasMore: false });
        } else {
            this.setState({ hasMore: true });
        }

        this.setState({ relatorios: page == 1 ? relatorios : [...this.state.relatorios, ...relatorios], page, requesting: false, firstLoad: false });
    }

    renderLoading() {
        return (
            <div className="notice">
                <DefaultLoader />
            </div>
        )
    }

    renderEmpty() {
        let message = IdiomaHelper.getStruct({
            'pt_br': 'Nenhum relatório encontrado',
            'en': 'No report found',
            'es': 'No se encontró ningún informe'
        });

        return (
            <div className="notice">
                {DiagnosticoHelper.renderEmpty(message[this.props.lang], null, null, this.props.t)}
            </div>
        )
    }

    renderEmptyFiltro() {
        return (
            <div className="notice">
                Nenhum relatório encontrado com os filtros selecionados.
            </div>
        )
    }

    renderList() {
        let disabled = (this.props.status == 0 && !this.props.isEmpresaAvaliadora);
        let message = IdiomaHelper.getStruct({
            'pt_br': 'Excluir',
            'en': 'Delete',
            'es': 'Eliminar'
        });

        return (
            <div className={"listRelatorios"}>
                {this.state.relatorios.map((relatorio) => (
                    <RelatorioCard
                        key={relatorio.id}
                        relatorio={relatorio}
                        disabled={disabled}
                        lang={this.props.lang}
                        openRelatorio={(id) => this.props.openRelatorio(id)}
                        reloadPage={() => {
                            if(this.state.requesting) return;

                            let relatorios = this.state.relatorios.filter(rel => rel.id != relatorio.id);
                            this.setState({ relatorios }, () => {
                                if(this.state.hasMore)
                                    this.loadRelatorios(relatorios.length+1, 1);
                            });

                        }}
                    />
                ))}
                {
                    this.state.loading && 
                        <div style={{ textAlign: 'center', marginTop: '20px', width: '100%', height: '150px' }}>
                            <DefaultLoader />
                        </div>
                }
            </div>
        )
    }

    render() {
        if (this.state.loading && (this.state.firstLoad || this.state.relatorios?.length == 0)) return this.renderLoading();
        if (!this.state.relatorios || this.state.relatorios?.length == 0) return this.renderEmpty();
        if (this.state.relatorios?.length == 0) return this.renderEmptyFiltro();

        return this.renderList();
    }
}

export default withTranslation()(ListRelatorios);