export default class Api {
    static getEndPoint() {
        return `${window.location.protocol}//api.${window.location.hostname.replace('client.sig.', '')}`;
    }

    static getSigEndPoint() {
        return `${window.location.protocol}//${window.location.hostname.replace('client.', '')}`;
    }

    static isProd() {
        return window.location.hostname.includes('sig.grupoadvis.com');
    }
}