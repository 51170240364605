import React, { forwardRef } from "react";
import './RelatorioPerformanceItem.css';
import Colors from "../../../../../../constants/Colors";
import DiagnosticoHelper from "../../../../../../helper/diagnostico/DiagnosticoHelper";
import moment from "moment";
import DefaultLoader from "../../../../../tools/DefaultLoader";
import { Col, Form, Row, Modal, Button } from 'react-bootstrap';
import EssentialStyle from "../../../../../../style/EssentialStyle";
import IdiomaHelper from "../../../../../../helper/IdiomaHelper";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faTimes, faSave, faEdit } from '@fortawesome/free-solid-svg-icons';
import CustomTooltip from "../../../../../tools/CustomTooltip";
import Select from 'react-select';

import Highcharts from "highcharts/highcharts.js";
import highchartsMore from "highcharts/highcharts-more.js"
import accessibility from 'highcharts/modules/accessibility';
import HighchartsReact from "highcharts-react-official";
import solidgauge from "highcharts/modules/solid-gauge";
import GaugeCircle from "../../../../../tools/GaugeCircle/GaugeCircle";
import DefaultButton from "../../../../../tools/DefaultButton";
import { withTranslation } from 'react-i18next';

highchartsMore(Highcharts);
accessibility(Highcharts);
solidgauge(Highcharts);


class RelatorioPerformanceItem extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            miniLoading: false,

            idItem: this.props?.item?.id || null,
            grupos: this.props?.item?.grupos || [],
            data: this.props.data_performance || null,
            grafico: this.props?.item?.chartType || 'gauge',
            calculo: this.props?.item?.typeCalc || 'moda',
            typeVisualizacao: this.props?.item?.typeVisualizacao || 'moda',

            idEmpresaAvaliada: this.props?.idEmpresaAvaliada || null,
            status: this.props?.status || 1,
            canEdit: this.props?.canEdit || false,

            estrutura: [],

            editingGroupId: null,
            availableFaixas: [],
            selectedFaixa: null,
            editingGroupName: '',
            typesCalc: [],
            showModal: false,

            pinHeader: false,
            headerPosition: 'unset',
            headerTitleHeight: 0,
            headerFaixaHeight: 0,
            headerNomeGrupoHeight: 0,
        };

        this.itemRef = React.createRef();
        this.headerRef = React.createRef();
        this.headerFaixaRef = React.createRef();
        this.headerNomeGrupoRef = React.createRef();
        this.timeout = null;
    }
    

    async componentDidMount() {
        await this.props.i18n.loadNamespaces(['client_src_helper_diagnostico_DiagnosticoHelper']);
        
        this.setState({ typesCalc: DiagnosticoHelper.getOptionsModeloRelatorio('typesCalc', this.props.t) });

        this.loadIndicadoresPerformance(true);
        window.addEventListener('scroll', this.handleScroll);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.data_performance !== this.props.data_performance && prevProps.data_performance !== null) {
            this.setState({ data: this.props.data_performance }, () => {
                this.loadIndicadoresPerformance(false);
            });
        }

        if(this.headerRef?.current && this.headerRef?.current?.clientHeight != this.state.headerTitleHeight) {
            this.setState({ headerTitleHeight: this.headerRef?.current?.clientHeight });
        }

        if(this.headerNomeGrupoRef?.current && this.headerNomeGrupoRef?.current?.clientHeight != this.state.headerNomeGrupoHeight) {
            this.setState({ headerNomeGrupoHeight: this.headerNomeGrupoRef?.current?.clientHeight });
        }

        if(this.headerFaixaRef?.current && this.headerFaixaRef?.current?.clientHeight != this.state.headerFaixaHeight) {
            this.setState({ headerFaixaHeight: this.headerFaixaRef?.current?.clientHeight });
        }
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }
    
    handleScroll = () => {
        if(this.timeout) {
            return;
        }

        this.timeout = setTimeout(() => {
            this.checkScroll();
            this.timeout = null;
        }
        , 100);
    }

    checkScroll = () => {

        let minTop = this.props.pageHeaderHeight ? (this.props.pageHeaderHeight) : -20;

        if (this.itemRef?.current) {
            const { top, bottom } = this.itemRef?.current?.getBoundingClientRect();
            let pinHeader = false;
            if ((top < minTop) && !(bottom < (minTop + 200))) {
                pinHeader = true;
            }

            if(this.state.pinHeader !== pinHeader) {
                this.setState({ pinHeader });
            }
            let headerPosition = pinHeader ? (minTop - top) : 'unset';
            this.setState({ headerPosition });
        }
    }

    async loadIndicadoresPerformance(viewLoading = true) {
        this.setState({ loading: viewLoading ? true : false, miniLoading: true });

        let dataSelect = moment(this.state.data).format('YYYY-MM-DD');
        let idItem = this.state.idItem;

        let { estrutura, calculo } = await DiagnosticoHelper.getEstruturaIndicadoresPerformance(idItem, dataSelect);
        
        this.setState({ estrutura, calculo, loading: false, miniLoading: false });
    }

    message = () => {
        const message = IdiomaHelper.getStruct({
            'pt_br': {
                title: 'Performance',
                calculo: 'Tipo de cálculo da faixa',
                moda: 'Moda',
                modaInfo: 'A faixa que mais se repete no conjunto de indicadores deste grupo.',
                media: 'Média',
                mediaInfo: 'A faixa em que se enquadra a média aritmética das performances dos indicadores deste grupo.',
                realizado: 'Realizado',
                performance: 'Performance',
                calculoEditTitle: 'Edição do Tipo de Cálculo',
                calculoEditInfo: 'Selecione o tipo de cálculo para definir a faixa deste Grupo ou selecione uma faixa fixa para esse Grupo com base em sua avaliação.',
                salvar: 'Salvar',
                cancelar: 'Cancelar',
            },
            'en': {
                title: 'Performance',
                calculo: 'Calculation type of the range',
                moda: 'Mode',
                modaInfo: 'The range that repeats the most in the set of indicators in this group.',
                media: 'Average',
                mediaInfo: 'The range in which the arithmetic average of the performances of the indicators in this group fits.',
                realizado: 'Realized',
                performance: 'Performance',
                calculoEditTitle: 'Editing the Calculation Type',
                calculoEditInfo: 'Select the calculation type to define the range of this Group or select a fixed range for this Group based on its evaluation.',
                salvar: 'Save',
                cancelar: 'Cancel',
            },
            'es': {
                title: 'Performance',
                calculo: 'Tipo de cálculo del rango',
                moda: 'Moda',
                modaInfo: 'La franja que más se repite en el conjunto de indicadores de este grupo.',
                media: 'Média',
                mediaInfo: 'La franja en la que se encuadra la media aritmética de las actuaciones de los indicadores de este grupo.',
                realizado: 'Realizado',
                performance: 'Performance',
                calculoEditTitle: 'Edición del Tipo de Cálculo',
                calculoEditInfo: 'Seleccione el tipo de cálculo para definir la franja de este Grupo o seleccione una franja fija para este Grupo basada en su evaluación.',
                salvar: 'Salvar',
                cancelar: 'Cancelar',
            },
        });

        return message[this.props.lang];
    }

    infoGrupo = (faixa) => {
        return (
            <div dangerouslySetInnerHTML={{ __html: faixa.informativo }} />
        );

    }

    handleEditClick = (grupo, indicadores, faixa) => {
            const indicador = Object.values(indicadores)[0]; // Pega o primeiro indicador para acessar as faixas
            this.setState({
                editingGroupId: grupo.id,
                editingGroupName: grupo?.textos?.find(texto => texto.id_idioma === IdiomaHelper.getSiglaId(this.props.lang))?.descricao || '',
                availableFaixas: indicador.indicadorRef.faixas || [],
                selectedFaixa: faixa?.save ? faixa.id : this.state.calculo,
                showModal: true,
            });
    }

    handleFaixaChange = async (grupoId, faixaId) => {
        this.setState({ miniLoading: true, showModal: false });
        
        try {
            // Adicione aqui sua lógica para salvar a mudança via API
            await DiagnosticoHelper.updateGrupoFaixaPerformance(grupoId, faixaId, this.state.idItem);
            
            // Atualiza a interface
            await this.loadIndicadoresPerformance(false);
            this.props.onUpdateFaixa && this.props.onUpdateFaixa();

        } catch (error) {
            console.error("Erro ao atualizar faixa:", error);
        } finally {
            this.setState({ 
                editingGroupId: null,
                miniLoading: false 
            });
        }
    }

    handleCloseModal = () => {
        this.setState({ showModal: false, editingGroupId: null });
    }

    renderEditButton(grupo, indicadores, faixa) {
        return (
            this.props.canEdit && this.props.status != 1 ? (
                <FontAwesomeIcon 
                    icon={faEdit} 
                    style={{cursor: 'pointer', marginLeft: '5px'}}
                    onClick={() => this.handleEditClick(grupo, indicadores, faixa)}
                />
            ) : <div/>
        );
    }

    renderEditModal() {
        
        let faixas = this.state.availableFaixas || [];

        faixas.sort((a, b) => a.min + b.min);

        let faixasSelect = faixas.map(faixa => ({
            value: faixa.id,
            label: faixa.textos.find(t => t.id_idioma === IdiomaHelper.getSiglaId(this.props.lang))?.texto
        }));

        faixasSelect = [...this.state.typesCalc, ...faixasSelect];

        return (
            <Modal show={this.state.showModal} onHide={this.handleCloseModal}>
                <Modal.Header>
                    <Modal.Title>{this.message().calculoEditTitle}: <strong>{this.state.editingGroupName}</strong></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{
                        marginBottom: '15px',
                        fontSize: '0.8rem',
                        color: '#666',
                    }}>
                        {this.message().calculoEditInfo}
                    </div>

                    <Select
                        value={faixasSelect.find(faixa => faixa.value === this.state.selectedFaixa)}
                        options={faixasSelect}
                        onChange={(e) => this.setState({ selectedFaixa: e ? e.value : null })}
                        styles={{
                            control: (provided) => ({
                                ...provided,
                                fontSize: '0.8rem',
                                padding: '2px',
                                maxWidth: '100%',
                                minWidth: '100%',
                            })
                        }}
                        autoFocus
                    />
                </Modal.Body>
                <Modal.Footer>
                    <DefaultButton
                        color={Colors.error}
                        leftIcon={<FontAwesomeIcon icon={faTimes} />}
                        onClick={this.handleCloseModal}
                        loading={this.state.loading}
                        title={this.message().cancelar}
                    />
                    <DefaultButton
                        color={Colors.success}
                        leftIcon={<FontAwesomeIcon icon={faSave} />}
                        title={this.message().salvar}
                        loading={this.state.loading}
                        onClick={() => this.handleFaixaChange(this.state.editingGroupId, this.state.selectedFaixa)}
                    />
                </Modal.Footer>
            </Modal>
        );
    }
    
    renderDonut(minValue, maxValue, valor, valorFormat, title, color) {
        minValue = minValue < maxValue ? minValue : maxValue;
        maxValue = maxValue > minValue ? maxValue : minValue;
        return (
            <div className="chartBox">
                <GaugeCircle
                    min={minValue}
                    max={maxValue}
                    title={
                        title.length > 40 
                        ? title.substring(0, 40) + '...' 
                        : title
                    }
                    color={color}
                    progress={valor}
                    labelProgress={valorFormat}
                    titleStyle={{ fontSize: '12px'}}
                    style={{height: `180px`, width: "220px"}}
                />
                {/* <IndicadorGauge
                    min={minValue}
                    max={maxValue}
                    nome={
                        title.length > 40 
                        ? title.substring(0, 40) + '...' 
                        : title
                    }
                    metaValor={'aaa'}
                    realizadoValor={'bbb'}
                    gauge={true}
                /> */}
            </div>
        )
    }

    renderGauge(minValue, maxValue, valor, valorFormat, plotBands, title) {
        minValue = minValue < maxValue ? minValue : maxValue;
        maxValue = maxValue > minValue ? maxValue : minValue;
        return (
            <div className="chartBox">
                <HighchartsReact
                    highcharts={Highcharts}
                    containerProps={{ style: { height: "190px", width: "300px" } }}
                    options={{
                        chart: {
                            type: 'gauge',
                            plotBackgroundColor: null,
                            plotBackgroundImage: null,
                            plotBorderWidth: 0,
                            plotShadow: false,
                            backgroundColor: 'transparent',
                            margin: [20, 0, 2, 0],
                            spacing: [5, 2, 5, 2],
                        },
                        title: {
                            text: title || '',
                            style: {
                                fontSize: '12px',
                            }
                        },
                        credits: {
                            enabled: false
                        },
                        pane: {
                            startAngle: -150,
                            endAngle: 150,
                        },
                        yAxis: {
                            min: minValue,
                            max: maxValue,
                            minorTickInterval: 'auto',
                            minorTickWidth: 0,
                            minorTickLength: 0,
                            minorTickPosition: 'inside',
                            minorTickColor: '#666',
                            tickPixelInterval: 0,
                            tickWidth: 2,
                            tickPosition: 'inside',
                            tickLength: 10,
                            tickColor: '#666',
                            labels: {
                                enabled: false // Remove os números do eixo
                            },
                            plotBands,
                        },
                        series: [{
                            name: title,
                            data: [valor], // valor do velocímetro
                            tooltip: {
                                valueSuffix: ' %',
                                pointFormatter: function() {
                                    return `<span style="color:${this.color}">\u25CF</span> ${this.series.name}: <b>${valorFormat}</b><br/>`; // valor formatado
                                }
                            },
                            dataLabels: {
                                enabled: true,
                                format: valorFormat,
                                style: {
                                    fontSize: '14px',
                                    color: '#333',
                                    fontWeight: 'bold',
                                    textOutline: '1px contrast'
                                },
                            },
                        }]
                    }}
                />
            </div>
        )
    }

    renderIndicador(indicador) {

        // Resultados de Realizado
        let realizado = indicador.resultados.realizadoRealizadoRaw || 0; // realizado com no maximo 2 casas decimais
        let realizadoFormat = indicador.resultados.realizadoRealizado || '0'; // realizado formatado em string (Ex: R$ xxx,xx | xx,xx% ...)
        
        // Resultados de Performance
        let performance = indicador.resultados.performance || 0; // performance com n casas decimais
        let performanceFormat = indicador.resultados.performanceRealizado || '0'; // performance formatado em string (Ex: xx,xx%)

        // Faixas do Indicador
        let faixas = indicador.faixasRef;

        // Valor do gráfico
        let valor       = this.state.typeVisualizacao == 'realizado' ? realizado       : performance;
        let valorFormat = this.state.typeVisualizacao == 'realizado' ? realizadoFormat : performanceFormat;

        // Máximo valor de faixa
        let newMax = null;
        faixas.map((faixa) => {
            if (Number(faixa.max) > newMax || newMax === null) {
                newMax = Number(faixa.max);
            }

            if (Number(faixa.min) > newMax || newMax === null) {
                newMax = Number(faixa.min);
            }
        });

        // Minimo valor de faixa
        let newMin = null;
        faixas.map((faixa) => {
            if (Number(faixa.max) < newMin || newMin === null) {
                newMin = Number(faixa.max);
            }

            if (Number(faixa.min) < newMin || newMin === null) {
                newMin = Number(faixa.min);
            }
        });

        // Valor máximo para o caso
        let max = 100;
        if (this.state.grafico === 'donut') {
            max = this.state.typeVisualizacao == 'realizado' ? newMax : 100;
            valor = Number(valor) > Number(newMax) ? newMax : valor;

        } else if (this.state.grafico === 'gauge') {
            max = newMax;
            valor = Number(realizado) > Number(max) ? max : realizado;
        }

        // Valor mínimo para o caso
        let min = 0;
        if (this.state.grafico === 'donut') {
            min = this.state.typeVisualizacao == 'realizado' ? newMin : 0;
            valor = Number(valor) < Number(newMin) ? newMin : valor;
        } else {
            min = newMin;
            valor = Number(realizado) < Number(min) ? min : realizado;
        }
        

        // Informações do Indicador
        let title = indicador?.indicadorRef?.indicador?.nome || '';
        let color = indicador?.resultados?.faixa?.cor || '#000';

        if (this.state.grafico === 'donut') return this.renderDonut(Number(min), Number(max), Number(valor), valorFormat, title, color);

        // Faixas do gráfico
        let plotBands = faixas.map((faixa) => {
            return {
                from: faixa.min,
                to: faixa.max,
                color: faixa.cor,
                label: { text: null }
                // label: {
                //     text: faixa.textos.find(texto => texto.id_idioma === IdiomaHelper.getSiglaId(this.props.lang))?.texto || '',
                //     style: {
                //         fontSize: '10px',
                //         color: '#333'
                //     }
                // }
            }
        });

        if (this.state.grafico === 'gauge') return this.renderGauge(Number(min), Number(max), Number(valor), valorFormat, plotBands, title);
    }

    renderGrupo(grupo, indicadores, faixa) {

        indicadores = Object.values(indicadores);

        indicadores.sort((a, b) => {
            let nomeA = a?.indicadorRef?.indicador?.nome || '';
            let nomeB = b?.indicadorRef?.indicador?.nome || '';
            if (nomeA < nomeB) return -1;
            if (nomeA > nomeB) return 1;
            return 0;
        });

        return (
            <div className="grupoIndicadores" style={{ border: `0.5px solid #ccc` }}>
                
                <div className={`headerFaixa ${this.state.pinHeader ? 'pin-header' : ''}`} style={{ top: this.state.headerPosition , backgroundColor: faixa?.cor || '#e3e3e3' }} ref={this.headerFaixaRef}>
                    <span className="infoVisualizacao" >
                        {(this.message()[`${this.state.typeVisualizacao}`])}
                    </span>

                    {faixa?.textos?.find(texto => texto.id_idioma === IdiomaHelper.getSiglaId(this.props.lang))?.texto}
                    
                    <div style={{...EssentialStyle.rowFlexStart}}>
                        <CustomTooltip tooltip={this.infoGrupo(faixa)} maxWidth={'150px'}>
                            <span className="infoVisualizacao" >
                                {(this.message()[`${this.state.calculo}`])}
                            </span>
                            
                            <FontAwesomeIcon icon={faInfoCircle} />
                        </CustomTooltip>

                        {this.renderEditButton(grupo, indicadores, faixa)}
                    </div>
                        
                </div>

                <div className={`headerNome ${this.state.pinHeader ? 'pin-header' : ''}`} style={{ top: this.state.headerPosition }} ref={this.headerNomeGrupoRef}>
                    {grupo?.textos?.find(texto => texto.id_idioma === IdiomaHelper.getSiglaId(this.props.lang))?.descricao || ''}
                </div>

                <div className={`bodyCharts ${this.state.grafico}`} >

                    {indicadores.map((indicador) => {
                        return (
                            <React.Fragment key={indicador?.indicadorRef?.id || ''}>                              
                                {this.renderIndicador(indicador)}
                            </React.Fragment>
                        )
                    })}
                </div>
                {this.renderEditModal()}
            </div>
        )
    }

    renderGrupos() {

        let estrutura = this.state.estrutura;

        // Ordenar pelo drag_index
        estrutura.sort((a, b) => {
            return a?.grupo?.drag_index - b?.grupo?.drag_index;
        });

        return (
            <div className="RelatorioPerformanceItem" style={{ boxShadow: Colors.boxShadow }} ref={this.itemRef}>
                {this.state.miniLoading && <div className="miniLoading"><DefaultLoader size={15}/></div>}
                {this.state.pinHeader && <div style={{ transition: 'all 0.5s ease', minHeight: this.state.headerTitleHeight + this.state.headerFaixaHeight + this.state.headerNomeGrupoHeight + 12, position: "absolute", zIndex: 5, backgroundColor: "white",  width: "100%", top: this.state.headerPosition}}></div>}
                <div className={`headerIndicadoresPerformance ${this.state.pinHeader ? 'pin-header' : ''}`} style={{ top: this.state.headerPosition }} ref={this.headerRef}>
                    {this.message().title}
                </div>
                <Form style={{ width: '100%', ...EssentialStyle.columnCenter }}>
                    <Row className="g-2" style={{ width: '100%' }}>
                        {estrutura.map((grupo, index) => {
                            return (
                                <Col key={grupo?.grupo?.id || 'desagrupado'} md={DiagnosticoHelper.getWidthToForm(grupo.largura, this.props.isSmallScreen)}>
                                    <Form.Group>                                
                                        {this.renderGrupo(grupo.grupo, grupo.indicadores, grupo.faixa)}
                                    </Form.Group>
                                </Col>
                            )
                        })}
                    </Row>
                </Form>
            </div>
        )
    }

    renderLoading() {
        return (
            <div className="RelatorioPerformanceItem" style={{ boxShadow: Colors.boxShadow }}>
                <DefaultLoader/>
            </div>
        )
    }

    render() {
        
        if (this.state.loading) return this.renderLoading();
        if (this.state.estrutura.length === 0) return (null);
        return this.renderGrupos();
    }
}

export default withTranslation()(RelatorioPerformanceItem);