import React from "react";

import EssentialStyle from '../../../../style/EssentialStyle';
import DefaultLoader from '../../../tools/DefaultLoader';
import { Collapse } from "react-bootstrap";
import Colors from "../../../../constants/Colors";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import SessionHelper from '../../../../helper/SessionHelper';
import ChartHelper from '../../../../helper/inicio/ChartHelper';
import EllipsisText from "../../../tools/EllipsisText";
import DataHelper from "../../../../helper/DataHelper";

export default class RelatorioAtaTarefas extends React.Component {

    state={
        contadores: {},
        expanded: false,
        status: [
            "Não Iniciada",
            "Em Desenvolvimento",
            "Concluída",
            "Atrasada",
            "Cancelada",
        ],
        loading: true,
    }

    componentDidMount(){
        this.calculaContadores();
    }

    componentDidUpdate(prevProps){
        if(prevProps.atas !== this.props.atas){
            this.calculaContadores();
        }
    }

    calculaContadores = () => {

        this.setState({ loading: true });

        var defaultTarefaCount = {
            total: 0,
            "Não Iniciada": 0,
            "Em Desenvolvimento": 0,
            "Concluída": 0,
            "Atrasada": 0,
            "Cancelada": 0,
        }

        var contadores = {
            atas: [],
        }

        if(!this.props.atas || this.props.atas.length === 0) {
            this.setState({ loading: false, contadores });
            return;
        } 

        var atas = this.props.atas;

        atas.forEach(ata => {
            if(ata.topicos && ata.topicos.length > 0){
                ata.topicos.forEach(topico => {
                    if(topico.tarefas && topico.tarefas.length > 0){
                        topico.tarefas.forEach(tarefa => {
                            if(!contadores.atas[ata.id]){
                                contadores.atas[ata.id] = {tarefas: {...defaultTarefaCount}, participantes: [], nome: ata.assunto, id: ata.id};
                            }
                            contadores.atas[ata.id].tarefas.total++;
                            if((tarefa.status_text == "Em Desenvolvimento" || tarefa.status_text == "Não Iniciada") && tarefa.atrasada)
                                contadores.atas[ata.id].tarefas["Atrasada"]++;
                            else
                                contadores.atas[ata.id].tarefas[tarefa.status_text]++;
                            
                            if(tarefa.responsavel && tarefa.responsavel.id){
                                if(!contadores.atas[ata.id].participantes[tarefa.responsavel.id]){
                                    contadores.atas[ata.id].participantes[tarefa.responsavel.id] = {tarefas:{...defaultTarefaCount}, nome: tarefa.responsavel.nome, id: tarefa.responsavel.id};
                                }
                                contadores.atas[ata.id].participantes[tarefa.responsavel.id].tarefas.total++;
                                if((tarefa.status_text == "Em Desenvolvimento" || tarefa.status_text == "Não Iniciada") && tarefa.atrasada)
                                    contadores.atas[ata.id].participantes[tarefa.responsavel.id].tarefas["Atrasada"]++;
                                else
                                    contadores.atas[ata.id].participantes[tarefa.responsavel.id].tarefas[tarefa.status_text]++;
                            }
                        });
                    }
                });
            }
        });

        this.setState({ contadores, loading: false });
    }

    fieldIsFiltered = (filter) => {
        if(!filter) return false;
        var view = filter.view;
        var valuesFilter = filter.values;

        var isFiltered = false;
        if(this.props.filter?.view == view && this.props.filter?.values && this.props.filter?.values.length > 0){
            isFiltered = JSON.stringify(valuesFilter) == JSON.stringify(this.props.filter.values);
        }
        return isFiltered;
    }

    renderBarra(elemento, type) {
        if(!elemento) return null;
        const status = this.state.status;

        const statusCounts = elemento.tarefas;

        const total = elemento.tarefas.total;

        const renderDiv = (status, index) => {
            var filter = {view: "tarefas", values: [{ field: 'status_text', value: status }, {field: type, value: elemento.id}]};
            return (
                <div 
                    key={status+index}
                    onClick={() => {
                        if(this.fieldIsFiltered(filter))
                            this.props.filterCallback({view: "tarefas", values: []});
                        else
                            this.props.filterCallback(filter);
                    }}
                    style={{ 
                        ...EssentialStyle.rowFlexStart,
                        height: this.fieldIsFiltered(filter) ? 35 : 32,
                        width: `${(statusCounts[status] / total) * 100}%`,
                        minWidth: statusCounts[status] > 0 ? 15 : 0,
                        backgroundColor: ChartHelper.getColor(status),
                        transition: 'height 0.5s ease, boxShadow 0.5s ease',
                        overflow: 'visible',
                        cursor: "pointer",
                        filter: this.fieldIsFiltered(filter) ? "brightness(0.9)" : "unset",
                        boxShadow: this.fieldIsFiltered(filter) ? `0px 0px 10px 0px ${ChartHelper.getColor(status)}` : "none",
                    }}
                >
                    <span style={{ 
                        color: 'white', 
                        fontWeight: '500', 
                        marginLeft: 5, 
                        opacity: 1, 
                        transition: 'opacity 0.3s ease-in-out' 
                    }}>
                        {elemento.tarefas[status]}
                    </span>
                </div>
            );
        }

        return (
            <div style={{...EssentialStyle.rowSpaceBetween, width: '100%', height: 35, borderRadius: 5, marginLeft: 5, overflow: 'hidden'}}>
                {status.map(renderDiv)}
            </div>
        );
    }

    renderTarefas = () => {
        if(this.state.loading) {
            return (
            <div style={{...EssentialStyle.columnCenter, width: "100%", height: "auto", justifyContent: 'center', alignItems: 'center'}} >
                <DefaultLoader />
            </div>
           )
        }

        return (
            <div className="ata-tarefas-component" style={{ ...EssentialStyle.columnCenter, width: '100%', backgroundColor: SessionHelper.getBackgroundColor(), paddingTop: "3px", position: 'sticky', left: 0}}>
                <div style={{...EssentialStyle.columnCenter, minHeight: 35, boxShadow: `0px 1px 10px 0px  rgba(50, 50, 50, 0.1)`, borderRadius: 8, backgroundColor: Colors.white, width: '100%', overflow: "hidden"}}>

                    <div style={{ ...EssentialStyle.rowFlexCenter, cursor: 'pointer', minHeight: 35 }} onClick={() => this.setState({expanded: !this.state.expanded})} >
                        {this.state.expanded ?
                            <FontAwesomeIcon icon={faChevronUp} style={{ marginRight: 8 }} /> :
                            <FontAwesomeIcon icon={faChevronDown} style={{ marginRight: 8 }} />
                        }
                        {
                            this.state.expanded ? 'Ocultar Tarefas' : 'Tarefas'
                        }
                    </div>

                    <Collapse in={this.state.expanded} style={{ width: '100%', transition: "all 0.2s ease" }}>
                        <div style={{...(this.props.isSmallScreen ? EssentialStyle.columnCenter : EssentialStyle.rowFlexCenter), width: '100%'}}>
                            <div style={{...EssentialStyle.columnStart, width: '100%', height: 300, paddingTop: 0, gap: 15}}>
                                {this.state.loading ? 
                                    <div style={{ ...EssentialStyle.columnCenter, padding: 10, width: '100%', height: "100%"}}>
                                        <DefaultLoader />
                                    </div>
                                : 
                                    <div style={{...EssentialStyle.columnStart, paddingBottom: 5, width: "100%"}}>
                                        <div style={{...EssentialStyle.rowFlexCenter, height: 40, borderBottom: `1px solid ${Colors.homePage.extraLightGrey}`, width: "100%", textAlign: 'center'}}>
                                            <div style={{fontSize: 16, fontWeight: 500, color: Colors.homePage.grey, flex: 1, height: "100%", alignContent: "center", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap"}}>Ata de Reunião / Participante</div>
                                            <div style={{fontSize: 16, fontWeight: 500, color: Colors.homePage.grey, width: "30%", minWidth: 250, height: "100%", alignContent: "center", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap"}}>Tarefas</div>
                                        </div>
                                        <div style={{...EssentialStyle.columnStart, width: "100%", overflowY: "auto", maxHeight: 250, height: "auto"}}>
                                            {this.state.contadores.atas.map((ata, index) => {
                                                return (
                                                <>
                                                    <div key={"ata-"+index} style={{...EssentialStyle.rowFlexCenter, borderBottom: `1px solid ${Colors.homePage.extraLightGrey}`, width: "100%", height: 40, minHeight: 40}}>
                                                        <div style={{fontSize: 16, fontWeight: 500, color: Colors.homePage.darkGrey, flex: 1, height: "100%", alignContent: "center", paddingLeft: 10}}>
                                                            <EllipsisText text={DataHelper.removeHtmlAndReplaceListItems(ata.nome)} />
                                                        </div>
                                                        <div style={{...EssentialStyle.rowFlexStart, fontSize: 16, fontWeight: 500, color: Colors.homePage.darkGrey, width: "30%", minWidth: 250, height: "100%", alignContent: "center"}}>
                                                            {ata.tarefas ? 
                                                                <>
                                                                    <div style={{...EssentialStyle.rowFlexStart, height: "100%", width: "calc(100% - 45px)"}}>
                                                                        {this.renderBarra(ata, 'ata')}
                                                                    </div>
                                                                    <div
                                                                        onClick={() => {
                                                                            if(this.fieldIsFiltered({view: "tarefas", values: [{ field: 'ata', value: ata.id }]}))
                                                                                this.props.filterCallback({view: "atas", values: []});
                                                                            else
                                                                            this.props.filterCallback({view: "tarefas", values: [{ field: 'ata', value: ata.id }]})
                                                                    }} 
                                                                    style={{
                                                                        ...EssentialStyle.rowFlexCenter, cursor: "pointer",
                                                                        height: 30, width: 40, marginLeft: 5,
                                                                        borderLeft: `2px solid ${Colors.homePage.extraLightGrey}`, 
                                                                        borderRadius: this.fieldIsFiltered({view: "tarefas", values: [{ field: 'ata', value: ata.id }]}) ? 5 : 0,
                                                                        backgroundColor: this.fieldIsFiltered({view: "tarefas", values: [{ field: 'ata', value: ata.id }]}) ? Colors.homePage.extraLightGrey : "transparent"}}
                                                                        >
                                                                        {ata.tarefas.total}
                                                                    </div>
                                                                </>
                                                                : 
                                                                <div style={{...EssentialStyle.rowFlexCenter, height: "100%", width: "calc(100% - 45px)"}}>
                                                                    -
                                                                </div>
                                                            }
                                                        </div>
                                                    </div> 
                                                    {
                                                        ata.participantes && ata.participantes.map((participante, indexObj) => {
                                                            return (
                                                                <div key={"participante-"+indexObj} style={{...EssentialStyle.rowFlexCenter, borderBottom: `1px solid ${Colors.homePage.extraLightGrey}`, width: "100%", height: 40, minHeight: 40, backgroundColor: Colors.tag}}>
                                                                    <div style={{fontSize: 16, fontWeight: 500, color: Colors.homePage.darkGrey, flex: 1, height: "100%", alignContent: "center", paddingLeft: 20}}>
                                                                        <EllipsisText text={DataHelper.removeHtmlAndReplaceListItems(participante.nome)} />
                                                                    </div>
                                                                    <div style={{...EssentialStyle.rowFlexStart, fontSize: 16, fontWeight: 500, color: Colors.homePage.darkGrey, width: "30%", minWidth: 250, height: "100%", alignContent: "center"}}>
                                                                        {participante.tarefas ? 
                                                                            <>
                                                                                <div style={{...EssentialStyle.rowFlexStart, height: "100%", width: "calc(100% - 45px)"}}>
                                                                                    {this.renderBarra(participante, 'participante')}
                                                                                </div>
                                                                                <div
                                                                                    onClick={() => {
                                                                                        if(this.fieldIsFiltered({view: "tarefas", values: [{ field: 'participante', value: participante.id }]}))
                                                                                            this.props.filterCallback({view: "atas", values: []});
                                                                                        else
                                                                                        this.props.filterCallback({view: "tarefas", values: [{ field: 'participante', value: participante.id }]})
                                                                                    }} 
                                                                                    style={{
                                                                                        ...EssentialStyle.rowFlexCenter, cursor: "pointer",
                                                                                        height: 30, width: 40, marginLeft: 5,
                                                                                        borderLeft: `2px solid ${Colors.homePage.extraLightGrey}`, 
                                                                                        borderRadius: this.fieldIsFiltered({view: "tarefas", values: [{ field: 'participante', value: participante.id }]}) ? 5 : 0,
                                                                                        backgroundColor: this.fieldIsFiltered({view: "tarefas", values: [{ field: 'participante', value: participante.id }]}) ? Colors.homePage.extraLightGrey : "transparent"}}
                                                                                    >
                                                                                    {participante.tarefas.total}
                                                                                </div>
                                                                            </>
                                                                            : 
                                                                            <div style={{...EssentialStyle.rowFlexCenter, height: "100%", width: "calc(100% - 45px)"}}> 
                                                                                -
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                </div> 
                                                            )
                                                        })           
                                                    }
                                                </>
                                                )
                                            })}
                                        </div>
                                    </div>
                                }
                                </div>
                        </div>
                    </Collapse>
                </div>
            </div>
        );
    }


    render() {
        return (
            this.renderTarefas()
        )
    }
}