import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from "@fortawesome/free-solid-svg-icons";
import DiagnosticoHelper from "../../../../helper/diagnostico/DiagnosticoHelper";

import "./StarButton.css";


export default class StarButton extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            
            idNode: this.props?.idNode ?? null,
            idAvaliacao: this.props?.idAvaliacao ?? null,

            isDisabled: this.props?.isDisabled ?? false,
            status: this.props?.status ?? false,

            loadingChage: false,
        };
    }

    async componentDidMount() {
        if (this.props.status == undefined) {
            this.loadEstrela();
        }
    }

    async componentDidUpdate(prevProps) {
        if (prevProps.isDisabled !== this.props.isDisabled) {
            this.setState({isDisabled: this.props.isDisabled});
        }

        if (prevProps.status !== this.props.status) {
            this.setState({status: this.props.status});
        }
    }

    async loadEstrela() {
        this.setState({loading: true});

        let status = this.state.status;
        let isDisabled = this.state.isDisabled;

        if (this.state.idNode && this.state.idAvaliacao) {
            const response = await DiagnosticoHelper.getStarStatus(this.state.idNode, this.state.idAvaliacao);
            status = response.statusStar;
        } else {
            isDisabled = true;
        }

        this.setState({status, isDisabled, loading: false});
    }

    async handleClick() {
        if (this.state.isDisabled || this.state.loadingChage) return;

        this.setState({loadingChage: true, status: !this.state.status}, async () => {
            const response = await DiagnosticoHelper.updateStarStatus(this.state.idNode, this.state.idAvaliacao, this.state.status);
            this.setState({status: response.statusStar, loadingChage: false});
            this.props.onChange && this.props.onChange(response.statusStar);
        });
    }

    loadingStar() {

        if (this.state.isDisabled) return (null);
        return (
            <div className="star-button" style={{...this.props?.styles?.container ?? {} }}>
                <FontAwesomeIcon 
                    icon={faStar}
                    className={"star star-loading"}
                    style={{
                        ...this.props?.styles?.icon ?? {},
                        cursor: "not-allowed",
                    }}
                />
            </div>
        );
    }

    renderStar() {

        let color = this.state.status ? 
            (this.props.color ? this.props.color : "rgb(255, 178, 0)") 
            : 
            (this.state.isDisabled ? "transparent" : "rgba(0, 0, 0, 0.1)");

        let cursor = this.state.isDisabled ? "default" : "pointer";

        return (
            <div className="star-button" style={{...this.props?.styles?.container ?? {}}}>
                <FontAwesomeIcon 
                    icon={faStar}
                    className={"star"}
                    onClick={() => this.handleClick()}
                    style={{
                        ...this.props?.styles?.icon ?? {},
                        color: color,
                        cursor: cursor,
                    }}
                />
            </div>
        );
    }

    render() {
        if (this.state.loading || this.props.loading) return this.loadingStar();
        return this.renderStar();
    }
}