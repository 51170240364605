import React, { forwardRef } from "react";
import IdiomaHelper from "../../../../../helper/IdiomaHelper";

import './ModalCorrespondencia.css';
import Colors from "../../../../../constants/Colors";
import DefaultButton from "../../../../../components/tools/DefaultButton";

import DiagnosticoHelper from "../../../../../helper/diagnostico/DiagnosticoHelper";
import IndicadorPainelSelect from "../../../../tools/IndicadorPainelSelect/IndicadorPainelSelect";
import Modal from "../../../../tools/Modal";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartLine } from "@fortawesome/free-solid-svg-icons";
import EssentialStyle from "../../../../../style/EssentialStyle";



export default class ModalCorrespondencia extends React.Component {

    state = {
        faixas: this.props.select?.faixas || [],
        acumulado: this.props.select?.acumulado || false,
        select: this.props.select,
        showFaixas: false,

    }

    async saveIndicadorPainelEmpresa(select) {
        
        let idIndicadorReferecia = this.state.select.id;
        let idEmpresa = select.empresa.id;
        let idIndicador = select.indicador?.id || null;
        let idPainel = select.painel?.id || null;

        let periodoAvaliacao = await DiagnosticoHelper.saveIndicadorEquivalentePeriodo(idIndicadorReferecia, idEmpresa, idIndicador, idPainel);

        let newSelect = periodoAvaliacao?.indicadores?.find((indicador) => indicador.id === this.state.select.id );
        this.setState({ select: newSelect });
    }

    message() {
        const message = IdiomaHelper.getStruct({
            'pt_br': { 
                title: 'Configuração de Equivalência do Indicador',
                close: 'Fechar',
                acumulated: 'Acumulado',
                month: 'Mensal',
                altern_to: 'Alternar para',
                label_config: 'Configuração do Indicador de Referência',
                label_list: 'Lista de Equivalência dos Indicadores por Empresa',
                faixasTitle: 'Faixas do Indicador',
            },
            'en': { 
                title: 'Indicator Equivalence Configuration',
                close: 'Close',
                acumulated: 'Acumulated',
                month: 'Monthly',
                altern_to: 'Altern to',
                label_config: 'Reference Indicator Configuration',
                label_list: 'List of Indicator Equivalence by Company',
                faixasTitle: 'Indicator Ranges',
            },
            'es': { 
                title: 'Configuración de Equivalencia del Indicador',
                close: 'Cerrar',
                acumulated: 'Acumulado',
                month: 'Mensual',
                altern_to: 'Alternar para',
                label_config: 'Configuración del Indicador de Referencia',
                label_list: 'Lista de Equivalencia de Indicadores por Empresa',
            },
        });

        return message[this.props.lang];
    }

    renderTitle() {
        return (
            <div className="titleModal">
                <FontAwesomeIcon icon={faChartLine} onClick={this.props.close} />
                {`${this.message().title} ${this.state.select?.indicador?.nome}`}
            </div>
        )
    }

    renderBody() {

        const renderList = () => {

            return (
                <div className="listSelects">
                    {this.state.select.empresas.map((empresa, index) => {
                        return (
                            <div className="itemEmpresa" key={`empresa-select-indicador-painel-${empresa.empresa.id}`}>
                                <IndicadorPainelSelect 
                                    key={`select-indicador-painel-${empresa.empresa.id}`}
                                    lang={this.props.lang}
                                    empresa={empresa.empresa}
                                    indicador={empresa.indicador}
                                    painel={empresa.painel}
                                    sync={true}
                                    callback={(select) => this.saveIndicadorPainelEmpresa(select)}
                                />
                            </div>
                        )
                    })}
                </div>
            );
        }

        const renderLabel = (label) => {
            return (
                <div className="labelBody">
                    <span className="title">{this.message()[label]}</span>
                    <div className="title-line"></div>
                </div>
            )
        }

        return (
            <div className="bodyModal">
                {renderLabel('label_list')}
                {renderList()}
            </div>
        )
    }

    renderFooter() {
        return (
            <div style={{...EssentialStyle.rowFlexEnd, width: '100%', marginTop: 10}}>
                <DefaultButton 
                    title={this.message().close}
                    color={Colors.dark}
                    textColor={Colors.white}
                    onClick={() => this.props.closeModal(false)}
                />
            </div>
        )
    }

    render() {
        return (
            <Modal 
                className={"ModalCorrespondencia"} 
                style={{
                    width: '90vw',
                    minWidth: '90vw',
                    height: '90vh', 
                    transition: 'height 0.5s ease', 
                    boxShadow: '0px 0px 20px 20px rgba(50, 50, 50, 0.5)', 
                    justifyContent: 'space-between'
                }} 
                close={this.props.closeModal}
            >
                {this.renderTitle()}
                {this.renderBody()}
                {this.renderFooter()}
            </Modal>
        );
    }
}
