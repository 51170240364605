import React, { Suspense } from "react";

import EssentialStyle from "../../../../style/EssentialStyle";
import Colors from "../../../../constants/Colors";
import DefaultLoader from "../../../tools/DefaultLoader";

export default class RelatorioAtaLista extends React.Component {

    render() {
        
        const AtaReuniaoTarefaCard = React.lazy(() => import("../AtaReuniaoTopico/AtaReuniaoTopicoComponents/AtaReuniaoTarefaCard"));
        const AtaReuniaoCard = React.lazy(() => import("../AtaReuniaoCard/AtaReuniaoCard"));

        if(this.props.loading){
            return (
                <div style={{...EssentialStyle.columnCenter, width: '100%', height: '20vh'}}>
                    <DefaultLoader/>
                </div>
            )
        }

        if(!this.props.elements || this.props.elements.length === 0) {
            return (
                <div style={{...EssentialStyle.columnCenter, width: '100%', height: '20vh'}}>
                    <h3 style={{color: Colors.text, textAlign: 'center', color: Colors.homePage.lightGrey, fontWeight: 500}}>Nenhum resultado encontrado</h3>
                </div>
            )
        }
        
        return(
            <div style={{...EssentialStyle.columnStart, width: '100%', gap: 5, maxHeight: "100%", flex: 1}}>
                <Suspense key={"suspense-lista"} 
                    fallback={
                        <div style={{ ...EssentialStyle.columnCenter, height: "35vh", width: "100%"}}>
                            <DefaultLoader/>
                        </div>}
                >
                    {this.props.view === 'atas' ?
                            this.props.elements.map((ata, index) => {
                                return (
                                    <AtaReuniaoCard 
                                        key={`${ata.id}-ata-key`} 
                                        ata={ata} 
                                        isSmallScreen={this.props.isSmallScreen} 
                                        onClick={(clickedAta, novaAba = false) => { 
                                            window.parent.postMessage({ type: novaAba ? '_blank' :'navigate', url: `/ataReuniao/detalhar/${clickedAta.id}` }, '*'); 
                                        }}     
                                    />
                                )
                                })
                        : 
                    this.props.view === 'tarefas' ?
                            this.props.elements.map((tarefa, index) => {
                                var participantes = this.props.atas.find(ata => ata.id === tarefa.id_ata_reuniao)?.participantes;
                                return (
                                    <AtaReuniaoTarefaCard 
                                        key={`${tarefa.id}-tarefa-key`} 
                                        tarefa={tarefa} 
                                        isSmallScreen={this.props.isSmallScreen} 
                                        participantes={participantes} 
                                        noEdit
                                        onClick={(tarefa, novaAba = false) => { 
                                            window.parent.postMessage({ type: novaAba ? '_blank' :'navigate', url: `/ataReuniao/detalhar/${tarefa.id_ata_reuniao}` }, '*'); 
                                        }} 
                                    />
                                );
                            })
                        : null
                    }
                </Suspense>
            </div>
        );
    }
}