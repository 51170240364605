import React from 'react';
import { withTranslation } from 'react-i18next';

import { forwardRef } from 'react';
import ResizableTimelineElement from '../../../tools/ResizableTimelineElement/ResizableTimelineElement';
import moment from 'moment';
import Colors from "../../../../constants/Colors";
import EllipsisText from "../../../tools/EllipsisText";
import DatePicker from "react-datepicker";
import { Form, FormGroup } from 'react-bootstrap';
import EssentialStyle from "../../../../style/EssentialStyle";
import DataHelper from "../../../../helper/DataHelper";
import { InputGroup } from 'react-bootstrap';
import LayoutHelper from '../../../../helper/LayoutHelper';
import { faTrash, faAdd, faClose} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DefaultButton from '../../../tools/DefaultButton';
import Sig from "../../../../api/Sig";
import ReactDOM from 'react-dom';
import UserAvatar from '../../../tools/UserAvatar';
import DatePickerHelper from '../../../../helper/DatePickerHelper';

class TimelineMarcos extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            marcos: [...props.marcos],
            timelineStart: 0,
            timelineEnd: 0,
            qtdMonthsStart: this.props.granularidade == "ano" ? 24 : this.props.qtdMonthsStart || 6,
            qtdMonthsEnd: this.props.granularidade == "ano" ? 24 : this.props.qtdMonthsEnd || 12,
            edit: this.props.edit,
            editId: null,
            selectedMarco: null,
            isSmallScreen: LayoutHelper.isSmallScreen(),
            showDeleteModal: false,
            id: this.props.id,
            isFocusedInside: false,
            daySize: this.props.granularidade == 'mes' ? 4 : this.props.granularidade == "semana" ? 16 : 1,
            granularidade: this.props.granularidade,
        };
        this.scrollRef = React.createRef();
    }

    async componentDidMount() {
        await this.props.i18n.loadNamespaces(['client_src_components_modules_pe_canvas_TimelineMarcos']);
        
        window.addEventListener('resize', this.resize);

        var marcos = [...this.props.marcos];

        var timelineStart = Math.min(...marcos.map(m => moment(m.data_inicio).toDate().getTime()));
        timelineStart = moment(new Date(timelineStart)).subtract(this.state.qtdMonthsStart, 'months').startOf('month').startOf('day').toDate().getTime();
        var timelineEnd = Math.max(...marcos.map(m => moment(m.data_fim).toDate().getTime()));
        timelineEnd = moment(new Date(timelineEnd)).add(this.state.qtdMonthsEnd, 'months').endOf('month').endOf('day').toDate().getTime();

        this.setState({
            timelineStart,
            timelineEnd,
            qtdMonthsStart: this.state.granularidade == "ano" ? 24 : this.props.qtdMonthsStart || 6,
            qtdMonthsEnd: this.state.granularidade == "ano" ? 24 : this.props.qtdMonthsEnd || 12,
            daySize: this.props.granularidade == 'mes' ? 4 : this.props.granularidade == "semana" ? 16 : 1,
            marcos,
            edit: this.props.edit,
            selectedMarco: null,
            showDeleteModal: false,
            loading: false,
        });

        setTimeout(this.scrollToCurrentDate, 100);

    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.resize);
    }

    resize = () => {
        this.setState({ isSmallScreen: LayoutHelper.isSmallScreen(), windowWidth: window.innerWidth});
    }

    componentDidUpdate(prevProps, prevState) {

        if(this.state.granularidade != this.props.granularidade) {
            var qtdMonthsStart = this.state.qtdMonthsStart;
            var qtdMonthsEnd = this.state.qtdMonthsEnd;
            this.setState({ 
                granularidade: this.props.granularidade, 
                daySize: this.props.granularidade == 'mes' ? 4 : this.props.granularidade == "semana" ? 16 : 1,
                qtdMonthsStart: this.props.granularidade == "ano" ? 24 : this.props.qtdMonthsStart || 6, qtdMonthsEnd: this.props.granularidade == "ano" ? 24 : this.props.qtdMonthsEnd || 12
            }, () => {
                if(qtdMonthsStart != this.state.qtdMonthsStart || qtdMonthsEnd != this.state.qtdMonthsEnd){
                    var marcos = [...this.state.marcos];
                    var timelineStart = Math.min(...marcos.map(m => moment(m.data_inicio).toDate().getTime()));
                    timelineStart = moment(new Date(timelineStart)).subtract(this.state.qtdMonthsStart, 'months').startOf('month').startOf('day').toDate().getTime();
                    var timelineEnd = Math.max(...marcos.map(m => moment(m.data_fim).toDate().getTime()));
                    timelineEnd = moment(new Date(timelineEnd)).add(this.state.qtdMonthsEnd, 'months').endOf('month').endOf('day').toDate().getTime();
                    
                    this.setState({
                        timelineStart,
                        timelineEnd,
                    }, () => this.scrollToCurrentDate());
                } else{
                    this.scrollToCurrentDate();
                }
            });
        }
        
        if(prevState.editId !== this.state.editId && this.state.showDeleteModal === true) {
            this.setState({  showDeleteModal: false, });
        }
            
        if(prevProps.edit !== this.props.edit) {
            this.setState({ edit: this.props.edit });
        }

        if (prevState.marcos != this.state.marcos) {

            var marcos = [...this.state.marcos];

            var timelineStart = Math.min(...marcos.map(m => moment(m.data_inicio).toDate().getTime()));
            timelineStart = moment(new Date(timelineStart)).subtract(this.state.qtdMonthsStart, 'months').startOf('month').startOf('day').toDate().getTime();
            var timelineEnd = Math.max(...marcos.map(m => moment(m.data_fim).toDate().getTime()));
            timelineEnd = moment(new Date(timelineEnd)).add(this.state.qtdMonthsEnd, 'months').endOf('month').endOf('day').toDate().getTime();

            this.setState({
                timelineStart,
                timelineEnd,
            });
        }

        if(prevProps.marcos !== this.props.marcos) {

            var timelineStart = Math.min(...this.props.marcos.map(m => moment(m.data_inicio).toDate().getTime()));
            timelineStart = moment(new Date(timelineStart)).subtract(this.state.qtdMonthsStart, 'months').startOf('month').startOf('day').toDate().getTime();
            var timelineEnd = Math.max(...this.props.marcos.map(m => moment(m.data_fim).toDate().getTime()));
            timelineEnd = moment(new Date(timelineEnd)).add(this.state.qtdMonthsEnd, 'months').endOf('month').endOf('day').toDate().getTime();

            this.setState({ 
                marcos: [...this.props.marcos], 
                timelineStart,
                timelineEnd, 
            });
        }
    }

    scrollToCurrentDate = () => {
        if(!this.scrollRef.current) 
            setTimeout(this.scrollToCurrentDate, 100);
        else{
            var left = (moment(new Date()).diff(moment(new Date(this.state.timelineStart)), 'days') * this.state.daySize);
            this.scrollRef?.current?.scrollTo({ top: 0, left: left, behavior: 'smooth' })
        }
    }

    changeMarcoCallback(updatedMarco) {
        var marcos = [...this.state.marcos];
        const index = marcos.findIndex(m => m.id === updatedMarco.id);
        marcos[index] = updatedMarco;
        this.setState({marcos}, this.props.changeMarcosCallback(marcos));
    }

    addMarco(newMarco) {
        var marcos = [...this.state.marcos];
        marcos.unshift(newMarco);
        this.setState({ marcos, editId: newMarco.id }, this.props.changeMarcosCallback(marcos));
    }

    setEditId(id) {
        this.setState({ editId: id });
    }

    async removeMarcosItem(id) {
        var itemDeletado = await Sig.request('POST', 'pe/canvas/removeMarcosItem',
            {
                id: id,
            });
        
        if(itemDeletado) {
            var marcos = this.state.marcos.filter(m => m.id != id);
            this.setState({ marcos, showDeleteModal: false, editId: null }, this.props.changeMarcosCallback(marcos));
        }
    }

    renderRemoveItemModal(id) {
        var marco = this.state.marcos.find(m => m.id == id);
        return (
            <div style={{ padding: 30, position: "absolute", backgroundColor: Colors.white, borderRadius: "10px", zIndex: 20 }} >
                <h1 style={{ marginTop: 0, fontWeight: 'normal', color: Colors.disabled, fontSize: 25 }}>
                    {this.props.i18n.t('client_src_components_modules_pe_canvas_TimelineMarcos:remove_marco')}
                </h1>
                <p style={{ color: Colors.dark, fontSize: 15 }}>
                    {this.props.i18n.t('client_src_components_modules_pe_canvas_TimelineMarcos:remove_marco_confirmation', { descricao: marco.descricao })}
                </p>
                <div style={EssentialStyle.rowReverseFlexStart}>
                    <DefaultButton title={this.props.i18n.t('client_src_components_modules_pe_canvas_TimelineMarcos:cancel')} color={Colors.notSelected} textColor={Colors.dark} onClick={() => this.setState({ showDeleteModal: false })} style={{ marginLeft: 5 }} />
                    <DefaultButton title={this.props.i18n.t('client_src_components_modules_pe_canvas_TimelineMarcos:remove')} color={Colors.danger} textColor={Colors.dark} onClick={() => this.removeMarcosItem(marco.id) } style={{ marginLeft: 5 }} />
                </div>
            </div>
        );
    }


    renderDatePicker(disabled, value, onChangeCallback, minDate = new Date(2000, 0, 1), maxDate = moment().add(10, 'years').toDate()) {
        const CustomInput = forwardRef(({ value, onClick }, ref) => (
            <Form.Control onClick={onClick} ref={ref} value={value} disabled={disabled}>
            </Form.Control>
        ));

        let date = moment().toDate();
        if (value) date = moment(value).toDate();

        return (
            <DatePicker
                wrapperClassName="w-100"
                portalId="calendar-portal"
                selected={date}
                type="date"
                readOnly={disabled}
                minDate={minDate}
                maxDate={maxDate}
                onChange={(event) => {
                    let changedDate = moment(event);
                    onChangeCallback(changedDate);
                }}
                dateFormat="dd/MM/YYYY"
                customInput={<CustomInput/>}
                renderCustomHeader={(props) =>  
                    DatePickerHelper.renderCustomDatePickerHeader(props, minDate, maxDate)
                }
            />
        )
    };

    renderEditForm() {
        if(!this.state.editId) return null;
        
        var pesoAtual = this.state.marcos.filter( m => m.id != this.state.editId ).reduce((acc, marco) => acc + Number(marco.peso || 0), 0);
        var id = this.state.marcos.findIndex(m => m.id == this.state.editId); 
        var marco = this.state.marcos[id];

        return (
            <FormGroup>
                <div style={{ ...EssentialStyle.columnCenter, paddingBottom: 10, paddingTop: 10, minHeight: "150px", maxWidth:"600px", width: "100%"}}>
                    { this.state.showDeleteModal  ? this.renderRemoveItemModal(marco.id) : null}
                    <div style={{ ...EssentialStyle.rowSpaceEvenly }}>
                        <div style={{ ...EssentialStyle.rowSpaceBetween, width: `${this.state.isSmallScreen ? "100%" : "calc(100%-80px)"}`, marginBottom: 16 }}>
                            <Form.Group className={this.state.isSmallScreen ? "w-40" : "w-50"} style={{ marginRight: 8, justifyContent: "center" }}>
                                <Form.Label>{this.props.i18n.t('client_src_components_modules_pe_canvas_TimelineMarcos:description')}</Form.Label>
                                <Form.Control placeholder="Descrição" value={marco.descricao}
                                    onBlur={(event) => {
                                        var marcos = this.state.marcos;
                                        marcos[id].descricao = event.target.value;
                                        this.changeMarcoCallback(marcos[id]);
                                    }}
                                    onChange={(event) => {
                                        var marcos = [...this.state.marcos];
                                        marcos[id].descricao = event.target.value;
                                        this.changeMarcoCallback(marcos[id]);
                                    }}
                                />
                            </Form.Group>
                            <InputGroup className={this.state.isSmallScreen ? "w-30" : "w-25"} style={{ marginRight: 8, justifyContent: "center", zIndex: 15, position: "relative" }}>
                                <Form.Label>{this.state.isSmallScreen ? this.props.i18n.t('client_src_components_modules_pe_canvas_TimelineMarcos:start_date_small') : this.props.i18n.t('client_src_components_modules_pe_canvas_TimelineMarcos:start_date')}</Form.Label>
                                {this.renderDatePicker(
                                    marco.vinculo ? true : false,
                                    marco.data_inicio,
                                    (value) => { 
                                        var marcos = [...this.state.marcos];
                                        marcos[id].data_inicio = DataHelper.getDefaultDbDateFormat(value);
                                        this.changeMarcoCallback(marcos[id]);
                                    },
                                    moment().subtract(100, 'years').toDate(),
                                    marco.data_fim ? moment(marco.data_fim).toDate() : moment().add(100, 'years').toDate(),
                                )}
                            </InputGroup>
                            <InputGroup className={this.state.isSmallScreen ? "w-30" : "w-25"} style={{ marginRight: 8, justifyContent: "center", zIndex: 15, position: "relative"}}>
                                <Form.Label>{this.state.isSmallScreen ? this.props.i18n.t('client_src_components_modules_pe_canvas_TimelineMarcos:end_date_small') : this.props.i18n.t('client_src_components_modules_pe_canvas_TimelineMarcos:end_date')}</Form.Label>
                                {this.renderDatePicker(
                                    marco.vinculo ? true : false,
                                    marco.data_fim,
                                    (value) => { 
                                        var marcos = [...this.state.marcos];
                                        marcos[id].data_fim = DataHelper.getDefaultDbDateFormat(value);
                                        this.changeMarcoCallback(marcos[id]);       
                                    },
                                    marco.data_inicio ? moment(marco.data_inicio).toDate() : moment().subtract(100, 'years').toDate(),
                                )}
                            </InputGroup>

                        </div>
                        <div style={{ ...EssentialStyle.columnSpaceBetween, marginLeft: 10, minHeight: "100%", gap: "10px" }}>
                            <DefaultButton
                                tooltip={this.props.i18n.t('client_src_components_modules_pe_canvas_TimelineMarcos:close')}
                                tooltipPlacement={'top'}
                                leftIcon={<FontAwesomeIcon icon={faClose} />}
                                style={{ fontSize: "20px", width: "35px", height: "35px", padding: "0px", margin: "0px"}}
                                color={Colors.secondaryButton}
                                textColor={Colors.dark}
                                loading={this.state.loading}
                                onClick={ () => this.setState({ editId: null, showDeleteModal: false }) }
                            />
                            <DefaultButton
                                tooltip={this.props.i18n.t('client_src_components_modules_pe_canvas_TimelineMarcos:remove_tooltip')}
                                tooltipPlacement={'top'}
                                leftIcon={<FontAwesomeIcon icon={faTrash} />}
                                style={{ fontSize: "14px", width: "35px", height: "35px", padding: "0px", margin: "0px"}}
                                color={Colors.secondaryButton}
                                textColor={Colors.dark}
                                loading={this.state.loading}
                                onClick={ () => this.setState({ showDeleteModal: true }) }
                            />
                        </div>
                    </div>

                    <div style={{ ...EssentialStyle.rowSpaceEvenly, width: '100%' }}>
                        <Form.Label style={{ marginTop: 5, width: 110 }}>{this.props.i18n.t('client_src_components_modules_pe_canvas_TimelineMarcos:project_percentage')}</Form.Label>
                        <InputGroup style={{ minWidth: 120, width: 'calc(100% - 170px)', marginRight: 10 }}>
                            <Form.Control
                                type="range"
                                min="0"
                                max={100 - pesoAtual} 
                                step="1"
                                value={Number(marco.peso || 0)}
                                onMouseUp={(event) => {
                                    var marcos = [...this.state.marcos];
                                    marcos[id].peso = Number(event.target.value|| 0);
                                    this.changeMarcoCallback(marcos[id]);
                                }}
                                onTouchEnd={(event) => {
                                    var marcos = [...this.state.marcos];
                                    marcos[id].peso = Number(event.target.value|| 0);
                                    this.changeMarcoCallback(marcos[id]);
                                }}
                                onChange={(event) => {
                                    var marcos = [...this.state.marcos];
                                        marcos[id].peso = Number(event.target.value|| 0);
                                        this.setState(marcos);
                                }}
                            />
                        </InputGroup>
                        <Form.Label style={{ marginTop: 5, width: 50 }}>{Number(marco.peso || 0)}%</Form.Label>
                    </div>
                </div>
            </FormGroup>
        );
    }
    

    render() {

        var { timelineStart, timelineEnd } = this.state;
        
        var offsetLeft = 150;
        if(this.state.isSmallScreen) 
            offsetLeft = 100;
        
        var marcos = this.state.marcos;

        var totalDuration = timelineEnd - timelineStart;
        var quantMonths = Math.round(moment(new Date(timelineEnd)).diff(moment(new Date(timelineStart)), 'months', true));
        var quantDias = Math.round(moment(new Date(timelineEnd)).diff(moment(new Date(timelineStart)), 'days', true));
        var quantSemanas = Math.ceil(quantDias / 7);
        var quantAnos = Math.ceil(moment(new Date(timelineEnd)).diff(moment(new Date(timelineStart)), 'years', true));

        if(!marcos || marcos.length == 0 || this.state.loading) return null;
        
        return (
            <div style={{ minHeight: this.props.style?.minHeight || '30vh', height: this.props.style?.height || '60vh', padding: 10, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", ...this.props.style }} >
                {marcos && marcos.length > 0 ?
                    <div style={{ display: "flex", flexDirection: "column", width: '100%', height: "max-content", alignItems: 'center', justifyContent: 'flex-start', overflowY: "auto" }}>
                        {this.state.edit ? 
                            this.state.editId !== null ?
                                this.renderEditForm()
                            : 
                                <div style={{ ...EssentialStyle.columnCenter, width: '100%', maxWidth:"600px", fontSize: "1.5rem", minHeight: "150px", position: 'relative' }}>
                                    <span style={{height: "50px"}}>{this.props.i18n.t('client_src_components_modules_pe_canvas_TimelineMarcos:no_marco_selected')}</span>
                                    <div>
                                        <DefaultButton
                                            leftIcon={<FontAwesomeIcon icon={faAdd} />}
                                            color={Colors.success}
                                            textColor={Colors.white}
                                            title={this.props.i18n.t('client_src_components_modules_pe_canvas_TimelineMarcos:add_marco')}
                                            loading={this.state.loading}
                                            onClick={async () => {
                                                var data = await Sig.request('POST', 'pe/canvas/addMarcosItem',
                                                    {
                                                        id_pe_projeto_canvas: this.props.id_pe_projeto_canvas
                                                    }
                                                );

                                                if(data){
                                                    this.addMarco(data["item orcamento"]);
                                                }
                                            }}
                                            style={{ marginRight: 8, marginTop: 16 }}
                                        />
                                    </div>
                                </div>
                        : null}

                            <div ref={this.scrollRef} style={{ width: "max-content", maxWidth: "100%", height: "max-content", maxHeight: "100%", position: "relative", display: "flex", flexDirection: "column", justifyContent: "flex-start", overflow: "auto", borderRadius: this.props.softBorder ? 8 : 0 }}>
                                <div style={{ width: `${(this.state.daySize * quantDias) + offsetLeft }px`, display: "flex", flexDirection: "row", backgroundColor: "rgb(247, 248, 249)", position: "sticky", top: 0, zIndex: 5, borderBottom: `1px solid ${Colors.homePage.extraLightGrey}` }} >
                                    <div style={{
                                        width: offsetLeft, position: "sticky", left: 0,
                                        minHeight: "35px", display: "flex", zIndex: 10, backgroundColor: "rgb(247, 248, 249)",
                                        flexDirection: "column", justifyContent: "center", alignItems: "center",
                                        lineHeight: this.state.granularidade == "semana" ? "90px" : "60px",
                                        height: this.state.granularidade == "semana" ? "90px" : "60px",
                                    }}>
                                        {this.props.i18n.t('client_src_components_modules_pe_canvas_TimelineMarcos:marco')}
                                    </div>
                                    <div style={{ width: `${this.state.daySize * quantDias}px`, display: "flex", flexDirection: "column", position: "relative", zIndex: 4 }}>
                                        { this.state.granularidade == "ano" &&
                                            <div style={{display: "flex", minWidth: "100%", height: "30px", flexDirection: "row", justifyContent: 'center', alignItems: "center"}}>
                                            {
                                                Array.from({ length: quantAnos }, (_, i) => i).map((_, index) => {
                                                    var currentDate = moment(new Date(timelineStart)).add(index, 'years').toDate().getTime();
                                                    if(index == 0) {
                                                        var daysInYear = Math.round(moment(new Date(currentDate)).endOf('year').diff(moment(new Date(currentDate)), 'days', true));
                                                    }
                                                    else if(index == quantAnos - 1) {
                                                        var daysInYear = Math.round(moment(new Date(timelineEnd)).diff(moment(new Date(currentDate)).startOf('year'), 'days', true));
                                                    }
                                                    else {
                                                        var daysInYear = Math.round(moment(new Date(currentDate)).endOf('year').diff(moment(new Date(currentDate)).startOf('year'), 'days', true));
                                                    }

                                                    return (
                                                        <div key={"year-"+index} style={{
                                                            width: `${daysInYear * this.state.daySize}px`, lineHeight: "30px", fontWeight: 500, 
                                                            minHeight: "30px", display: "flex", position: "relative", borderBottom: `1px solid ${Colors.tag}` ,
                                                            flexDirection: "column", justifyContent: "center", alignItems: "center",
                                                            backgroundColor: index % 2 == 1 ? Colors.tag : Colors.white
                                                        }}>
                                                            {moment(new Date(currentDate)).format('YYYY')}
                                                        </div>
                                                    );
                                                })
                                            }
                                            </div>
                                        }
                                        <div style={{display: "flex", minWidth: "100%", height: this.state.granularidade == "mes" ? "60px" : "30px", flexDirection: "row", justifyContent: 'center', alignItems: "center"}}>
                                            {
                                                Array.from({ length: quantMonths }, (_, i) => i).map((_, index) => {
                                                    var daysInMonth = moment(new Date(timelineStart)).add(index, 'months').daysInMonth();
                                                    return (
                                                        <div key={"month-"+index} style={{
                                                            width: `${daysInMonth * this.state.daySize}px`, lineHeight: this.state.granularidade == "mes" ? "28px" : "30px", fontWeight: 500, 
                                                            minHeight: this.state.granularidade == "mes" ? "60px" : "30px", display: "flex", position: "relative", borderBottom: `1px solid ${Colors.tag}` ,
                                                            flexDirection: "column", justifyContent: "center", alignItems: "center",
                                                            backgroundColor: index % 2 == 1 ? Colors.tag : Colors.white
                                                        }}>
                                                            {moment(new Date(timelineStart)).add(index, 'months').format(this.state.granularidade == "ano" ? 'MM' : 'MMM/YYYY')}
                                                        </div>
                                                    );
                                                })
                                            }
                                        </div>
                                        { this.state.granularidade == "semana" &&
                                            <div style={{display: "flex", minWidth: "100%", height: "30px", flexDirection: "row", alignItems: "center"}}>
                                            { 
                                                Array.from({ length: quantSemanas }, (_, i) => i).map((_, index) => {
                                                    var currentWeek = moment(new Date(timelineStart)).add(index, 'weeks');
                                                    var daysInWeek = 7;
                                                    if(index == 0){
                                                        var weekdayStart = currentWeek.day();
                                                        daysInWeek -= weekdayStart - 1;
                                                    }
                                                    if(index == quantSemanas - 1){
                                                        var weekdayEnd = currentWeek.day();
                                                        var daysToEnd = parseInt(moment(new Date(timelineEnd)).format('D')) - parseInt(currentWeek.format('D'));
                                                        if(daysToEnd){
                                                            weekdayEnd += daysToEnd;
                                                        }
                                                        daysInWeek = weekdayEnd;
                                                    }
                                                    return (
                                                        <div key={"week-"+index} style={{
                                                            width: `${daysInWeek * this.state.daySize}px`, lineHeight: "28px", fontWeight: 500, 
                                                            minHeight: "30px", display: "flex", position: "relative", borderBottom: `1px solid ${Colors.tag}` ,
                                                            flexDirection: "column", justifyContent: "center", alignItems: "center",
                                                            backgroundColor: Colors.white, borderTop: `1px solid ${Colors.tag}`, borderRight: `1px solid ${Colors.tag}`,
                                                        }}>
                                                            {daysInWeek > 6 ? this.props.i18n.t('client_src_components_modules_pe_canvas_TimelineMarcos:week') + " " + currentWeek.isoWeek() : daysInWeek >= 2 ? currentWeek.isoWeek() : null}
                                                        </div>
                                                    );
                                                })
                                            }
                                            </div>    
                                        }
                                        { this.state.granularidade == "semana" &&
                                            <div style={{display: "flex", minWidth: "100%", height: "30px", flexDirection: "row", alignItems: "center", fontSize: 10}}>
                                                {
                                                    Array.from({ length: quantDias }, (_, i) => i).map((_, index) => {
                                                        var currentDay = moment(new Date(timelineStart)).add(index, 'days');
                                                        return (
                                                            <div key={"day-"+index} style={{
                                                                width: `${this.state.daySize}px`, lineHeight: "28px", fontWeight: 500, 
                                                                minHeight: "30px", display: "flex", position: "relative" ,
                                                                flexDirection: "column", justifyContent: "center", alignItems: "center",
                                                                backgroundColor: Colors.white, borderRight: `1px solid ${Colors.tag}`,
                                                            }}>
                                                                {currentDay.date()}
                                                            </div>
                                                        );
                                                    })
                                                }
                                            </div>  
                                        }
                                    </div>
                                </div>
                                <div style={{ width: "max-content", height: "100%", display: "flex", justifyContent: "flex-start", alignItems: "flex-start", position: "relative", flexDirection: "column" }}>

                                    {marcos.map((marco, index) => {
                                        
                                        const marcoStart = moment(marco.data_inicio).startOf('day').toDate().getTime() - timelineStart;
                                        const marcoDuration = moment(marco.data_fim).endOf('day').toDate().getTime() - moment(marco.data_inicio).startOf('day').toDate().getTime();
                                        const marcoPosition = Math.round((marcoStart / totalDuration) * quantDias) * this.state.daySize;
                                        const marcoLength = Math.round((marcoDuration / totalDuration) * quantDias) * this.state.daySize;
                                        
                                        return (
                                            <div key={index} style={{ display: "flex", flexDirection: "row" }}>
                                                <div style={{ height: "35px", left: 0, zIndex: 4, width: offsetLeft, display: "flex", justifyContent: "center", padding: "0px 5px", alignItems: "center", backgroundColor: `${index % 2 == 0 ? Colors.white : "rgb(247, 248, 249)"}`, borderRight: `1px solid ${Colors.homePage.extraLightGrey}`, position: "sticky" }}>
                                                    {
                                                    this.props.timelineCanvas ?
                                                        <EllipsisText text={marco.descricao} />
                                                    : marco.planoAcao ? 
                                                        <>
                                                            <div style={{display: 'flex', height: "100%", width: "40px", alignItems: 'center', justifyContent: 'center'}}>
                                                                <UserAvatar user={marco.planoAcao.responsavel} size={35} />
                                                            </div>
                                                            <div style={{height: "100%", width: "calc(100% - 40px)", fontWeight: 500}}>
                                                                <EllipsisText text={marco.planoAcao.nome} />
                                                            </div>
                                                        </>
                                                    :
                                                        
                                                        <div style={{height: "100%", width: "calc(100% - 40px)", fontWeight: 500, marginLeft: 40}}>
                                                            <EllipsisText text={marco.descricao} />
                                                        </div>
                                                    }
                                                </div>
                                                <div style={{ left: offsetLeft, width: `${this.state.daySize * quantDias}px`, paddingTop: '5px', paddingBottom: '5px', display: 'flex', flexDirection: 'column', backgroundColor: `${index % 2 == 1 ? "rgb(247, 248, 249)" : Colors.white}` }}>
                                                    <ResizableTimelineElement
                                                        element={{...marco}}
                                                        elementPosition={marcoPosition}
                                                        elementLength={marcoLength}
                                                        width={this.state.daySize * quantDias}
                                                        changeElementCallback={(marco) => this.changeMarcoCallback(marco)}
                                                        timelineStart={timelineStart}
                                                        timelineEnd={timelineEnd}
                                                        edit={this.state.edit && marco.vinculo == null}
                                                        setEditId={(id) => this.setEditId(id)}
                                                        selectOnClick={marco.vinculo ? true : false}
                                                        isSmallScreen={this.state.isSmallScreen}
                                                        daySize={this.state.daySize}
                                                        type={'marco'}
                                                        />
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                       
                    </div>
                    : 
                        this.state.edit ?
                            <DefaultButton
                            leftIcon={<FontAwesomeIcon icon={faAdd} />}
                            color={Colors.success}
                            textColor={Colors.white}
                            title={this.props.i18n.t('client_src_components_modules_pe_canvas_TimelineMarcos:add_marco')}
                            loading={this.state.loading}
                            onClick={async () => {
                                var data = await Sig.request('POST', 'pe/canvas/addMarcosItem',
                                    {
                                        id_pe_projeto_canvas: this.props.id_pe_projeto_canvas
                                    }
                                );

                                if(data){
                                    this.addMarco(data["item orcamento"]);
                                }
                            }}
                            style={{ marginRight: 8, marginTop: 16 }}
                            />
                        :
                            <p>{this.props.i18n.t('client_src_components_modules_pe_canvas_TimelineMarcos:no_marcos')}</p>
                    }
            </div>
        )
    }
    
}

export default withTranslation()(TimelineMarcos);