import React from "react";
import '../PlanoAcaoRelatorio.css';

import EssentialStyle from "../../../../../style/EssentialStyle";
import UserAvatar from "../../../../tools/UserAvatar";
import Colors from "../../../../../constants/Colors";
import { Collapse } from "react-bootstrap";
import moment from 'moment';
import { ProgressBar } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp, faEquals, faExternalLink } from '@fortawesome/free-solid-svg-icons';
import TooManyAvatars from '../../../../tools/TooManyAvatars';
import EllipsisText from '../../../../tools/EllipsisText';
import DataHelper from '../../../../../helper/DataHelper';
import TooManyTags from '../../../../tools/TooManyTags';
import DefaultLoader from '../../../../tools/DefaultLoader';
import PlanoAcaoHelper from '../../../../../helper/planoAcao/PlanoAcaoHelper';
import CustomTooltip from '../../../../tools/CustomTooltip';

export default class PlanoAcaoRelatorioLinhaTabela extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            expanded: true,
            progressBarHovered: false,
            clickEtapa: null
        }
        this.progressBar = null;
    }

    renderVinculos(vinculosPlano) {
        let vinculos = PlanoAcaoHelper.getVinculosTagsConfig();
        let showVinculos = [];

        if (vinculosPlano.length > 1) {
            vinculosPlano.sort((a, b) => {
                return a.tipo - b.tipo;
            });
        }

        vinculosPlano.forEach(vinculo => {
            let found = vinculos.find(v => v.key == vinculo.tipo);
            if (found) {
                if (!showVinculos.includes(found)) showVinculos.push(found);
            }
        });

        var width = 100;
        if(showVinculos.length > 2) 
            var width = 200;

        return (
            <div style={{ ...EssentialStyle.rowSpaceAround, minHeight: 28, width: width, flexWrap: 'wrap', padding: "5px 0px", gap: 2}}>
                {showVinculos.map((vinculo, key) => {
                    return (
                        <CustomTooltip key={key + Math.random()} placement={'bottom'} tooltip={vinculo.description}>
                            <div style={{ ...EssentialStyle.rowFlexStart, marginLeft: 2 }}>
                                <div style={{ backgroundColor: vinculo.color, color: vinculo.textColor, fontSize: 12, padding: 5, borderRadius: 5, fontWeight: 600 }}>{vinculo.text}</div>
                            </div>
                        </CustomTooltip>
                    );
                })}
            </div>
        );
    }

    renderStatusPlano(status) {
        let propStatus = status || 'Não Iniciado';

        return (
            <div style={{ ...EssentialStyle.rowFlexCenter }}>
                <div style={{ backgroundColor: Colors.planoAcao.status.backgroundColor[propStatus], color: Colors.planoAcao.status.color[propStatus], fontSize: 12, padding: 5, borderRadius: 5, fontWeight: 600, minWidth: 124 }}>{propStatus}</div>
            </div>
        );
    }

    renderStatusEtapa(status, atrasada, status_text) {

        return (
            <div style={{ ...EssentialStyle.rowFlexCenter}}>
                <div style={{ backgroundColor: atrasada ? Colors.error : PlanoAcaoHelper.getEtapaStatus()[status].backgroundColor, color: atrasada ? Colors.white : PlanoAcaoHelper.getEtapaStatus()[status].color, fontSize: 12, padding: 5, borderRadius: 5, fontWeight: 600, minWidth: 124 }}>{status_text}</div>
            </div>
        );
    }

    renderProggressBar(etapas) {
        let min = 1;
        let max = 100;
        let progress = 0;
        let total = 0;
        let done = 0;

        if (etapas && etapas.length > 0) {
            total = etapas.filter(e => e.status_value != 2).length;
            done = etapas.filter(e => e.status_value == 3).length;
            if(total > 0)
                progress = parseInt((done / total) * 100);
            else
                progress = 0;
        }

        if(this.progressBar && this.state.progressBarHovered) {
            this.progressBar.querySelector('.progress-bar').style.minWidth = '124px';
        } else if(this.progressBar && !this.state.progressBarHovered) {
            this.progressBar.querySelector('.progress-bar').style.minWidth = '0px';
        }

        let label = this.state.progressBarHovered ?
            (<div style={{ paddingLeft: 4, paddingRight: 4 }}>{done} de {total} concluídas</div>)
            : (<div style={{ paddingLeft: 4, paddingRight: 4 }}>{progress}%</div>);

        return (
            <div
                style={{ width: "100%" }}
                onMouseEnter={() => this.setState({ progressBarHovered: true })}
                onMouseLeave={() => this.setState({ progressBarHovered: false })}
            >
                <ProgressBar
                    min={min}
                    max={max}
                    ref={ref => this.progressBar = ref}
                    now={progress}
                    label={label}
                    style={{ height: 20 }}
                    className="progress-bar-tabela"
                />
            </div>
        );
    }

    renderTendencia() {
        if (this.props.item?.tendencia) {
            let icon = null;

            if (this.props.item?.tendencia?.value == -1) icon = <FontAwesomeIcon icon={faChevronDown} style={{ color: this.props.item?.tendencia?.color, fontSize: 18, marginRight: 4 }} />
            if (this.props.item?.tendencia?.value == 1) icon = <FontAwesomeIcon icon={faChevronUp} style={{ color: this.props.item?.tendencia?.color, fontSize: 18, marginRight: 4 }} />
            if (this.props.item?.tendencia?.value == null) icon = <FontAwesomeIcon icon={faEquals} style={{ color: this.props.item?.tendencia?.color, fontSize: 18, marginRight: 4 }} />

            return (
                <CustomTooltip tooltip={this.props.item?.tendencia?.msg}>
                    {icon}
                </CustomTooltip>
            );
        }
    }

    renderParceiroCell = (bordersRows) => {
        if(!this.props.parceiro) return null;

        return (
            <td style={{backgroundColor: Colors.homePage.extraLightGrey, ...bordersRows, padding: "0px 5px", whiteSpace: "wrap", maxWidth: 130}}>
                {this.props.item?.nome_empresa}
            </td>
        );
    }

    renderPlano() {

        var bordersRows = {borderBottom: `1px solid ${Colors.homePage.lightGrey}`, borderRight:`1px solid ${Colors.homePage.lightGrey}`}

        return (
            <>
                <tr key={"plano-"+this.props.item.id} onClick={() => this.setState({expanded: !this.state.expanded})} style={{height: 60, backgroundColor: Colors.homePage.extraLightGrey, borderBottom: `1px solid ${Colors.homePage.extraLightGrey}`}}>
                    <td colSpan={3} className={"sticky-column"} style={{ minWidth: 900, maxWidth: 900, left: 0, backgroundColor: Colors.homePage.extraLightGrey, ...bordersRows, padding: "0px 5px", overflow: 'hidden', cursor: "pointer"}} >
                        <div style={{width: "100%", display: 'flex', justifyContent: 'start', alignItems: 'center'}}>
                            <div style={{display: 'flex', flexDirection: 'column', marginLeft: 3}}>
                                <div title={this.props.item.nome} style={{width: "100%", textAlign: "left", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap", display: 'flex', gap: 5}}>
                                    {this.renderTendencia()}
                                    {this.props.item.nome}
                                </div>
                                <div style={{display: 'flex', width: '100%', justifyContent: 'start', alignItems: 'center'}}>
                                    <FontAwesomeIcon className="icon-container" icon={faExternalLink} style={{ color: Colors.homePage.darkGrey, fontSize: 14, marginRight: 4 }} onClick={(e) => {e.stopPropagation(); window.parent.postMessage({ type: '_blank', url: `/planoAcao/detalhar/${this.props.item.codigo}` }, '*')}}/>
                                    {this.props.item.tags && this.props.item.tags.length > 0 ?
                                        <div style={{minHeight: 30}}>
                                            <TooManyTags max={4} maxWidth={"900px"} tags={this.props.item.tags || []} />
                                        </div>
                                    : null
                                    }
                                </div>
                            </div>
                        </div>
                    </td>
                    
                    {this.renderParceiroCell(bordersRows)}

                    <td style={{backgroundColor: Colors.homePage.extraLightGrey, ...bordersRows, padding: "0px 5px", whiteSpace: "wrap", maxWidth: 130}}>
                        {this.props.item.tipoName}
                    </td>
                    <td style={{backgroundColor: Colors.homePage.extraLightGrey, ...bordersRows, padding: "0px 5px", whiteSpace: "nowrap", maxWidth: 200}}>
                        {this.props.item.vinculos && this.props.item.vinculos.length > 0 && this.renderVinculos(this.props.item.vinculos)}
                    </td>
                    <td style={{backgroundColor: Colors.homePage.extraLightGrey, ...bordersRows, padding: "0px 5px", whiteSpace: "nowrap"}}>
                        <div style={{...EssentialStyle.columnCenter, width: "100%", gap: 2}}>
                            {this.renderStatusPlano(this.props.item.status)}
                            {this.renderProggressBar(this.props.item.etapas)}
                        </div>
                    </td>
                    <td style={{backgroundColor: Colors.homePage.extraLightGrey, ...bordersRows, padding: "0px 5px", whiteSpace: "nowrap"}}>
                        {this.props.item.data_inicio && this.props.item.data_inicio != 0 ? moment(this.props.item.data_inicio).format("DD/MM/yy") : "--"}
                    </td>
                    <td style={{backgroundColor: Colors.homePage.extraLightGrey, ...bordersRows, padding: "0px 5px", whiteSpace: "nowrap"}}>
                        {this.props.item.data_fim && this.props.item.data_fim != 0 ? moment(this.props.item.data_fim).format("DD/MM/yy") : "--"}
                    </td>
                    <td style={{backgroundColor: Colors.homePage.extraLightGrey, ...bordersRows, padding: "0px 5px", whiteSpace: "nowrap"}}>
                        {this.props.item.orcamento_previsto && this.props.item.orcamento_previsto != 0 ? parseFloat(this.props.item.orcamento_previsto).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : "--"}
                    </td>
                    <td style={{backgroundColor: Colors.homePage.extraLightGrey, ...bordersRows, padding: "0px 5px", whiteSpace: "nowrap"}}>
                        {this.props.item.orcamento_realizado && this.props.item.orcamento_realizado != 0 ? parseFloat(this.props.item.orcamento_realizado).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : "--"}
                    </td>
                    <td style={{backgroundColor: Colors.homePage.extraLightGrey, ...bordersRows, padding: "0px 5px", whiteSpace: "nowrap", justifyContent: "center"}}>
                        <div style={{...EssentialStyle.rowFlexCenter}}>
                            <div style={{width: 40, height: 40}}>
                                <UserAvatar user={this.props.item.responsavel} size={40}/>
                            </div>
                        </div>
                    </td>
                    <td style={{backgroundColor: Colors.homePage.extraLightGrey, ...bordersRows, padding: "0px 5px", whiteSpace: "nowrap", justifyContent: "center"}}>
                        {this.props.item.superior ? 
                            <div style={{...EssentialStyle.rowFlexCenter}}>
                                <div style={{width: 40, height: 40}}>
                                    <UserAvatar user={this.props.item.superior} size={40}/> 
                                </div>
                            </div>
                        : "--"}
                    </td>
                    <td style={{backgroundColor: Colors.homePage.extraLightGrey, ...bordersRows, padding: "0px 5px", whiteSpace: "nowrap", justifyContent: "center"}}>
                        {this.props.item.participantes && this.props.item.participantes.length > 0 ? 
                            <div style={{...EssentialStyle.rowFlexCenter}}>
                                <TooManyAvatars participantsTotal={this.props.item.participantes.length} participantsVisiveis={3} arrayParticipants={this.props.item.participantes}/> 
                            </div>    
                        : "--"}
                    </td>
                    <td style={{backgroundColor: Colors.homePage.extraLightGrey, ...bordersRows, padding: "0px 5px", whiteSpace: "nowrap"}}>--</td>
                    <td style={{backgroundColor: Colors.homePage.extraLightGrey, borderBottom: `1px solid ${Colors.homePage.lightGrey}`, padding: "0px 5px", whiteSpace: "nowrap"}}>--</td>
                </tr>
                {this.renderEtapas()}
            </>
        );
    }

    renderEtapas = () => {
        if(!this.props.item?.etapas?.length) return null;

        let filteredEtapas = PlanoAcaoHelper.filterSelectedEtapas(this.props.item?.etapas || [], this.props.filter);

        return filteredEtapas.map((etapa) => {
            return(
                <PlanoAcaoRelatorioLinhaTabela key={"etapa-"+etapa.id} item={etapa} type={'etapa'} isSmallScreen={this.props.isSmallScreen} expanded={this.state.expanded}/>
            );
        });
    }

    renderEtapa = () => {

        let height = this.state.clickEtapa == this.props.item.id ? '' : 30;
        let ellipsis = this.state.clickEtapa == this.props.item.id ? {} : {textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap"}
        var bordersRows = {borderBottom: `1px solid ${Colors.homePage.lightGrey}`, borderRight:`1px solid ${Colors.homePage.lightGrey}`, backgroundColor: this.state.clickEtapa == this.props.item.id ?  Colors.white : Colors.background, transition: "height 1s ease" }

        return (
            <Collapse in={this.props.expanded} key={"etapa-"+this.props.item.id} style={{transition: "height 0.5s ease", height}}>
                <tr 
                    style={{height, transition: "height 0.5s ease", cursor: "pointer"}}
                    onClick={() => this.setState({clickEtapa: this.state.clickEtapa == this.props.item.id ? null : this.props.item.id }) }
                >
                    
                    <td title={DataHelper.removeHtmlTags(this.props.item.atividade)} className={"sticky-column"} style={{ left: 0, maxWidth: 300, width: 300, padding: "0px 5px", ...bordersRows}}>
                        <div style={{width: "100%", textAlign: "left", display: 'flex', alignItems: 'center'}}>
                            <FontAwesomeIcon className="icon-container" icon={faExternalLink} style={{ color: Colors.homePage.darkGrey, fontSize: 14, marginRight: 4, cursor: "pointer" }} onClick={() => window.parent.postMessage({ type: '_blank', url: `/planoAcao/detalhar/${this.props.item.codigoPlanoAcao}?etapa=${this.props.item.id}` }, '*')}/>
                            <div style={{width: 'calc(100% - 18px)', textAlign: "left", height, ...ellipsis }}>
                                { DataHelper.removeHtmlTags(this.props.item.atividade) }
                            </div>
                        </div>
                    </td>

                    <td title={DataHelper.removeHtmlTags(this.props.item.descricao)} className={"sticky-column"} style={{ left: 300, maxWidth: 300, width: 300, padding: "0px 5px", ...bordersRows}}>
                        <div style={{width: "100%", textAlign: "left", display: 'flex', alignItems: 'center', height, ...ellipsis}}>
                            { DataHelper.removeHtmlTags(this.props.item.descricao) }
                        </div>
                    </td>

                    <td title={DataHelper.removeHtmlTags(this.props.item.justificativa)} className={"sticky-column"} style={{ left: 600, maxWidth: 300, width: 300, padding: "0px 5px", ...bordersRows}}>
                        <div style={{width: "100%", textAlign: "left", display: 'flex', alignItems: 'center', height, ...ellipsis}}>
                            { DataHelper.removeHtmlTags(this.props.item.justificativa) }
                        </div>
                    </td>

                    {this.props.parceiro ? <td style={{padding: "0px 5px", ...bordersRows, whiteSpace: "nowrap"}}>--</td> : null}

                    <td style={{padding: "0px 5px", ...bordersRows, whiteSpace: "nowrap"}}>--</td>

                    <td style={{padding: "0px 5px", ...bordersRows, whiteSpace: "nowrap"}}>--</td>

                    <td style={{padding: "0px 5px", ...bordersRows, whiteSpace: "nowrap"}}>
                        {this.renderStatusEtapa(this.props.item.status, this.props.item.atrasada, this.props.item.status_text)}
                    </td>

                    <td style={{padding: "0px 5px", ...bordersRows, whiteSpace: "nowrap"}}>
                        {moment(this.props.item.data_inicio).format("DD/MM/yy")}
                    </td>

                    <td style={{padding: "0px 5px", ...bordersRows, whiteSpace: "nowrap"}}>
                        {moment(this.props.item.data_fim).format("DD/MM/yy")}
                    </td>

                    <td style={{padding: "0px 5px", ...bordersRows, whiteSpace: "nowrap"}}>
                        {this.props.item.orcamento_previsto && this.props.item.orcamento_previsto != 0 ? parseFloat(this.props.item.orcamento_previsto).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : "--"}
                    </td>

                    <td style={{padding: "0px 5px", ...bordersRows, whiteSpace: "nowrap"}}>
                        {this.props.item.orcamento_realizado && this.props.item.orcamento_realizado != 0 ? parseFloat(this.props.item.orcamento_realizado).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : "--"}
                    </td>

                    <td style={{padding: "0px 5px", ...bordersRows, whiteSpace: "nowrap", justifyContent: "center"}}>
                        <div style={{...EssentialStyle.rowFlexCenter}}>
                            <div style={{width: 40, height: 40}}>
                                <UserAvatar user={this.props.item.colaborador} size={40}/>
                            </div>
                        </div>
                    </td>

                    <td style={{padding: "0px 5px", ...bordersRows, whiteSpace: "nowrap", justifyContent: "center"}}>--</td>

                    <td style={{padding: "0px 5px", ...bordersRows, whiteSpace: "nowrap"}}>
                        {this.props.item.participantes && this.props.item.participantes.length > 0 ?
                            <div style={{...EssentialStyle.rowFlexCenter}}>
                                <TooManyAvatars participantsTotal={this.props.item.participantes.length} participantsVisiveis={3} arrayParticipants={this.props.item.participantes}/> 
                            </div>
                        : "--"}
                    </td>

                    <td style={{padding: "0px 5px", ...bordersRows, whiteSpace: "nowrap"}}>
                        {PlanoAcaoHelper.getPrioridadeIcon()[this.props.item.prioridade_text]}
                    </td>

                    <td style={{padding: "0px 5px", borderBottom: `1px solid ${Colors.homePage.lightGrey}`, whiteSpace: "nowrap"}}>
                        {this.props.item.qtdComentarios ? this.props.item.qtdComentarios : "--"}
                    </td>
                </tr>
            </Collapse>
        );
    }

    render() {
        
        if(this.props.item == null) return null;

        return (
            <>
                {this.props.type === 'plano' ? this.renderPlano() : this.props.type === 'etapa' ? this.renderEtapa() : null}
            </>
        );
    }
}