import React from "react";
import { withTranslation } from "react-i18next";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, 
         faChevronUp, 
         faChevronDown, 
         faEquals,
         faChevronLeft,
         faChevronRight
} from "@fortawesome/free-solid-svg-icons";
import SessionHelper from "../../../../../helper/SessionHelper";
import DataHelper from "../../../../../helper/DataHelper";
import Colors from "../../../../../constants/Colors";
import EssentialStyle from "../../../../../style/EssentialStyle";
import DefaultLoader from "../../../../tools/DefaultLoader";
import DefaultButton from "../../../../tools/DefaultButton";
import { Collapse } from "react-bootstrap";
import ChartHelper from "../../../../../helper/inicio/ChartHelper";
import CustomTooltip from "../../../../tools/CustomTooltip";
import Highcharts from "highcharts/highcharts.js";
import HighchartsReact from "highcharts-react-official";
import Constants from "../../../../../constants/Api";
import UserAvatar from "../../../../tools/UserAvatar";
import Accordion from 'react-bootstrap/Accordion';
import variablePie from "highcharts/modules/variable-pie.js";
import VinculosPlanoAcao from "./VinculosPlanoAcao";
import LayoutHelper from "../../../../../helper/LayoutHelper";
import OrcamentoPlanoAcao from "./OrcamentosPlanoAcao";
import IdiomaHelper from "../../../../../helper/IdiomaHelper";

const gateway = Constants.getSigEndPoint();
variablePie(Highcharts);

class PlanoAcao extends React.Component {

    state = {
        data: [],
        id_colaborador: this.props.id_colaborador,
        status: [
            "Não Iniciada",
            "Em Desenvolvimento",
            "Concluída",
            "Atrasada",
            "Cancelada",
        ],
        loading: true,
        transform: 'translateX(+100%)',
        opacity: 0,
        expanded: this.props.expandedPageOne,
        planoSelecionado: this.props.planoSelecionado,
        subscreens: [],
        subscreenAtual: null,
        viewCard: null,
        filter: [],
        isSmallScreen: this.props.isSmallScreen,
    }

    async componentDidMount() {
        await this.props.i18n.loadNamespaces(['client_src_components_modules_inicio_charts_meusPlanosAcao_PlanoAcao']);
        var subscreens = [];
        if(this.state.planoSelecionado.etapas?.length != 0){
            subscreens.push("etapas");
            if(this.state.planoSelecionado.orcamento != 0)
                subscreens.push("orcamento");
        }
        if(this.state.planoSelecionado.qtdVinculos != 0)
            subscreens.push("vinculos");
            
        this.setState({ subscreens, subscreenAtual: subscreens[0]});

        this.triggerAnimations();
        this.calculaGrafico();
    }

    componentDidUpdate(prevProps) {
        if(prevProps.isSmallScreen !== this.props.isSmallScreen) {
            this.setState({ isSmallScreen: this.props.isSmallScreen });
        }

        if(this.state.expanded !== this.props.expandedPageOne)
            this.setState({ expanded: this.props.expandedPageOne });
    }

    getAnimations() {
        return {
            opacity: this.state.opacity,
            transform: this.state.transform,
            transition: 'opacity .3s ease-in-out, transform .6s ease-in-out'
        }
    }

    fadeAnimations(swipeRight = true) {
        this.setState({ opacity: 0, transform: `translateX(${swipeRight ? `-100%` : `+100%`})` });
    }

    triggerAnimations() {
        this.setState({ opacity: 1, transform: 'translateX(0)' });
    }

    calculaGrafico(){
        this.setState({ loading: true});
        let data = [];

        let etapas = this.state.planoSelecionado.etapas;

        this.state.status.forEach(status => {
            let etapaStatus = etapas.filter(etapa => etapa.status === status);
            data.push({
                name: status,
                y: etapaStatus.length || 0,
                yPercent: Math.round((etapaStatus.length / etapas.length) * 100),
                color: ChartHelper.getColor(status),
            });
        });
        this.setState({ data, loading: false });
    }

    renderGrafico() {
        let that = this;

        return (
            <div
                style={{ ...this.getAnimations(), display: 'flex', justifyContent: 'center',
                height: `${this.props.expandedPageOne === "MeusPlanosAcao" ? "98%" : "100%" }`, width:`${this.props.expandedPageOne === "MeusPlanosAcao" ? "95%" : "100%"}`
                }}
            >
                <HighchartsReact
                    highcharts={Highcharts}
                    constructorType={'chart'}
                    containerProps={{ style: { height: "100%", width: "100%" } }}
                    options={{
                        chart: {
                            type: 'variablepie',
                            backgroundColor: 'transparent',
                            margin: [10, null, null, null]
                        },
                        title: {
                            text: "",
                        },
                        tooltip: {
                            borderWidth: 0,
                            backgroundColor: 'none',
                            shadow: false,
                            pointFormat: '<span style="font-size: 2em; text-align: center; color: {point.color}; font-weight: bold">{point.yPercent}%</span>',
                            positioner: function (labelWidth) {
                                return {
                                    x: 0,
                                    y: that.state.isSmallScreen ? 0 : (this.chart.plotHeight)
                                };
                            },
                            formatter: function () {
                                const translatedName = that.props.t(`client_src_components_modules_inicio_charts_meusPlanosAcao_PlanoAcao:status.${IdiomaHelper.getTextTokenName(this.point.name)}`);
                                return `<span>${translatedName}</span><br><span style="font-size: 2em; text-align: center; color: ${this.point.color}; font-weight: bold">${this.point.yPercent}%</span>`;
                            },  
                        },
                        legend: {
                            align: `${this.props.expandedPageOne === "MeusPlanosAcao" || this.state.isSmallScreen ? "center" : "right"}`,
                            verticalAlign: `${this.props.expandedPageOne === "MeusPlanosAcao" || this.state.isSmallScreen ? "bottom" : "middle"}`,
                            layout: 'vertical',
                            floating: false,
                            enabled: true,
                            labelFormatter: function () {
                                return that.props.t(`client_src_components_modules_inicio_charts_meusPlanosAcao_PlanoAcao:status.${IdiomaHelper.getTextTokenName(this.name)}`) + `: (${this.y})`;
                            }
                        },
                        plotOptions: {
                            variablepie: {
                                dataLabels: {
                                    enabled: false,
                                },
                                point: {
                                    events: {
                                        legendItemClick: function () {
                                            let filter = [...that.state.filter];
    
                                            if (filter.includes(this.name)) {
                                                filter = filter.filter((item) => item !== this.name);
                                            } else {
                                                filter.push(this.name);
                                            }
    
                                            that.setState({ filter });
                                        }
                                    }
                                }
                            }
                        },
                        series: [{
                            minPointSize: 40,
                            showInLegend: true,
                            innerSize: '65%',
                            borderRadius: 1,
                            data: this.state.data
                        }],
                        credits: {
                            enabled: false
                        },
                    }}
                />
            </div>
        )
    }

    renderTable() {
        return( 
            <div style={{ width: "100%", padding: '5px 5px 10px 3px', height: "90%"}}>
                <div style={{ alignItems: "center", padding: "5px 0px", display: "flex", flexDirection: "row", minWidth: "calc(100% - 15px)", width: "calc(100% - 15px)",
                            fontSize: `${this.state.isSmallScreen ? "12px" : "14px"}`, fontStyle: "normal", color: "#828282", borderBottom: `0.5px solid ${Colors.homePage.lightGrey}`}}>
                    <div style={{width: "5%", minWidth: `${this.state.isSmallScreen ? "15px" : "unset"}`, maxWidth: "35px", display: "flex", justifyContent: "center", alignItems: "center"}}>   
                        #                
                    </div>
                    <div style={{width: "20%", minWidth: `${this.state.isSmallScreen ? "82px" : "unset"}`, maxWidth: "105px", display: "flex", justifyContent: "center", alignItems: "center"}}>
                        {this.state.isSmallScreen ? this.props.t('client_src_components_modules_inicio_charts_meusPlanosAcao_PlanoAcao:status_text') : this.props.t('client_src_components_modules_inicio_charts_meusPlanosAcao_PlanoAcao:status_prazo')}
                    </div>
                    <div style={{width: "15%", maxWidth: "75px", minWidth: `${this.state.isSmallScreen ? "35px" : "unset"}`, display: "flex", justifyContent: "center", alignItems: "center"}}>
                        <CustomTooltip tooltip={"Prioridade"} placement="top" style={{alignItems: "center", width: "calc(100%)"}}>
                            <div style={{ width: "100%", display: "flex"}}>
                                <span style={{textAlign: "center", width: "100%", textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden"}}>
                                    {this.props.t('client_src_components_modules_inicio_charts_meusPlanosAcao_PlanoAcao:prioridade')}
                                </span>
                            </div>
                        </CustomTooltip>
                    </div>
                    <div style={{width: "40%", flexGrow: "1", flexShrink: "1", display: "flex", justifyContent: "center", alignItems: "center"}}>
                        {this.props.t('client_src_components_modules_inicio_charts_meusPlanosAcao_PlanoAcao:atividade')}
                    </div>
                    <div style={{width: "15%", minWidth: `${this.state.isSmallScreen ? "45px" : "unset"}`, maxWidth: "75px", display: "flex", justifyContent: "center", alignItems: "center"}}>
                        <CustomTooltip tooltip={this.props.t('client_src_components_modules_inicio_charts_meusPlanosAcao_PlanoAcao:responsavel')} placement="top" style={{alignItems: "center", width: "calc(100%)"}}>
                            <div style={{ width: "100%", display: "flex"}}>
                            <span style={{textAlign: "center", width: "100%", textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden"}}>
                                    {this.props.t('client_src_components_modules_inicio_charts_meusPlanosAcao_PlanoAcao:responsavel')}
                                </span>
                            </div>
                        </CustomTooltip>                    
                    </div>
                    <div style={{width: "5%", maxWidth: "35px", display: "flex", justifyContent: "center", alignItems: "center"}}>
                    </div>
                </div>
                <div style={{ width: `${this.state.isSmallScreen ? "calc(100% - 15px)" : "100%"}`, height: "100%", overflowY: "auto", scrollbarGutter: `${this.state.isSmallScreen ? "unset" : "stable"}`, boxSizing: "border-box" }}>
                    {this.renderEtapas()}
                </div>
            </div>)
    }

    renderEtapas() {
        let planoSelecionado = this.state.planoSelecionado;
        
        if(planoSelecionado === undefined || planoSelecionado === null || planoSelecionado.etapas.length === 0) 
            return null;

        return (
            <div style={{...EssentialStyle.columnStart, alignItems: 'start', height: '100%', width: '100%'}}>
                <Accordion defaultActiveKey="0" style={{ width: '100%' }}>
                    {planoSelecionado.etapas.map((etapa, index) => { 
                        if (!this.state.filter.includes(etapa.status)) {
                            return this.renderEtapa(etapa, index);
                        }
                    })}
                </Accordion>
            </div>
        )
    }

    renderEtapa(etapa, index){
        var dateText = this.props.t('client_src_components_modules_inicio_charts_meusPlanosAcao_PlanoAcao:sem_data');
        if(etapa.data_fim != null && etapa.data_fim !== "0000-00-00" && etapa.data_fim != 0){
            dateText = new Date(etapa.data_fim).toLocaleDateString({}, { day: "numeric", month: "short", year: "numeric", localeMatcher: "best fit" });
            dateText = dateText.replaceAll("de ", "");
            dateText = dateText.replace(".", "");
        }
        
        return (
            <div key={"etapa-"+index} style={{cursor: 'pointer', borderBottom: `0.5px solid ${Colors.homePage.lightGrey}`, transition: "background-color 0.5s ease", borderRadius: 5, padding: 3,
                         backgroundColor: etapa.id == this.state.viewCard ? Colors.homePage.extraLightGrey : 'transparent'}}
                onClick={() => this.setState({ 
                    viewCard: this.state.viewCard == etapa.id ? null : etapa.id 
                })} 
            >
                <div key={etapa.id} 
                style={{ display: "flex", 
                         margin: "0px", 
                         alignItems: "center", 
                         fontSize: `${this.state.isSmallScreen ? "12px" : "14px"}`, 
                         fontStyle: "normal" , 
                         color: "#828282", 
                         height: "40px", 
                         minWidth: "100%",
                }} >
                    <div style={{width: "5%", minWidth: `${this.state.isSmallScreen ? "15px" : "unset"}`, maxWidth: "35px", display: "flex", justifyContent: "center", alignItems: "center"}}>
                        {index + 1}
                    </div>
                    <div style={{width: "20%", minWidth: `${this.state.isSmallScreen ? "82px" : "unset"}`, maxWidth: "105px", display: "flex", justifyContent: "center", alignItems: "center"}}>
                        <div style={{display: "flex", justifyContent: "center", height: "30px", alignItems: "center"}}>
                            <CustomTooltip tooltip={this.props.t(`client_src_components_modules_inicio_charts_meusPlanosAcao_PlanoAcao:status.${etapa.status.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").replaceAll(' ', '_')}`)} placement="top" style={{alignItems: "center"}}>
                                <div style={{ width: `${this.state.isSmallScreen ? "75px" : "90px"}`, height: "20px", alignItems: "center", display: "flex", justifyContent: "center",
                                            backgroundColor: ChartHelper.getColor(etapa.status), color: "white", borderRadius: 5, fontWeight: "600"}} >
                                    {dateText}
                                </div>
                            </CustomTooltip>
                        </div>  
                    </div>
                    <div style={{width: "15%", maxWidth: "75px", minWidth: `${this.state.isSmallScreen ? "35px" : "unset"}`, display: "flex", justifyContent: "center", alignItems: "center"}}>
                        <CustomTooltip tooltip={this.props.t(`client_src_components_modules_inicio_charts_meusPlanosAcao_PlanoAcao:prioridades.${etapa.prioridade.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").replaceAll(' ', '_')}`)} placement="top">
                            {this.getPrioridadeIcon()[etapa.prioridade]}
                        </CustomTooltip>
                    </div>
                    <div style={{width: "40%", flexGrow: "1", display: "flex", justifyContent: "center", alignItems: "center"}}>
                        <div style={{ display: "inline-block", fontSize: `${this.state.isSmallScreen ? "12px" : "14px"}`, color: "black", width: 'calc(100%)', textAlign: "left", fontWeight: "500", 
                                    textWrap: "nowrap", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap"}}>
                            <span>{new DOMParser().parseFromString(etapa.atividade, 'text/html').body.textContent || ''}</span>
                        </div>  
                    </div>
                    <div style={{width: "15%", minWidth: `${this.state.isSmallScreen ? "45px" : "unset"}`, maxWidth: "75px", display: "flex", justifyContent: "center", alignItems: "center"}}>
                        <UserAvatar size={35} id={etapa.id_colaborador} placement={'top'}/>
                    </div>
                    <div style={{width: "5%", maxWidth: "35px", display: "flex", justifyContent: "space-arround", alignItems: "center", justifyContent: "center"}}>
                        <DefaultButton
                            color={Colors.dark}
                            tooltip={this.props.t('client_src_components_modules_inicio_charts_meusPlanosAcao_PlanoAcao:ir_para_etapa')}
                            tooltipPlacement={"top"}
                            leftIcon={<FontAwesomeIcon icon={faEye} size="xs"/>}
                            style={{ width: 20, height: 20, display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 0, margin: 0}}
                            onClick={(e) => {
                                e.stopPropagation();
                                window.parent.postMessage({ type: '_blank', url: `/atividades/editarEtapa/${etapa.id}`}, '*')
                            }}
                        />
                    </div>
                </div>
                <Collapse in={etapa.id == this.state.viewCard}>
                    <div style={{ transition: "height 0.5s ease"}}>
                        <div style={{display: "flex", flexDirection: "column"}}>
                            <div style={{display: "flex", flexDirection: "row"}}>
                                <div style={{color: Colors.homePage.grey, marginLeft: "15px", fontSize: `${this.state.isSmallScreen ? "12px" : "14px"}`}}>
                                    {this.props.t('client_src_components_modules_inicio_charts_meusPlanosAcao_PlanoAcao:atividade')}
                                </div>
                                <div style={{marginLeft: "10px", marginRight: "20px", height: "12px", flexGrow: 1, borderBottom: `0.3px solid ${Colors.homePage.grey}`}}>
                                </div>
                            </div>
                            <div style={{display: "flex", margin: "5px 20px 10px 15px"}}>
                                <div style={{color: Colors.homePage.darkGrey, fontSize: `${this.state.isSmallScreen ? "12px" : "14px"}`, fontWeight: "400"}}>
                                    {this.getAtividadeText(etapa.atividade)}
                                </div>
                            </div>
                        </div>
                    </div>
                </Collapse>
            </div>
        )
    }

    getAtividadeText(atividade) {
        const obj = {__html: atividade};
        return(
            <>
                <div dangerouslySetInnerHTML={obj} />
            </>
        )
    }

    getPrioridadeIcon() {
        return {
            'Baixa': <FontAwesomeIcon icon={faChevronDown} style={{ color: Colors.homePage.atividades.baixa }} />,
            'Média': <FontAwesomeIcon icon={faEquals} style={{ color: Colors.homePage.atividades.media }} />,
            'Alta': <FontAwesomeIcon icon={faChevronUp} style={{ color: Colors.homePage.atividades.alta }} />,
            'Urgente': <div style={{ ...EssentialStyle.columnCenter, color: Colors.homePage.atividades.urgente }}><FontAwesomeIcon icon={faChevronUp} style={{ marginBottom: -8 }} /><FontAwesomeIcon icon={faChevronUp} /></div>
        }
    }

    renderLoading(bloco) {
        const trackColors = Highcharts.getOptions().colors.map(color =>
            new Highcharts.Color(color).setOpacity(0.3).get()
        );

        if (bloco === 2) {
            return (
                <div style={{...EssentialStyle.columnStart, width: '100%', overflow: 'hidden'}}>
                    {Array.from({ length: this.props.numberSubordinados }).map((elem, index) => (
                        <div key={`plhdr-${index}`} style={{ marginTop: 5, width: '100%' }}>
                            {this.renderLoadingPlaceholder()}
                        </div>
                    ))}
                </div>
            );
        } else {
            return (
                <div style={{...EssentialStyle.columnCenter, width: '100%', height: '100%'}}>
                    <DefaultLoader size={50} />
                </div>
            );
        }

    }

    changePage(increase = true, pageNumber = null) {
        let page = this.state.subscreens.indexOf(this.state.subscreenAtual);
        if (increase) {
            page = page + 1;
        } else {
            page = page - 1;
        }

        if(pageNumber !== null) {
            page = pageNumber;
            increase = pageNumber > this.state.subscreens.indexOf(this.state.subscreenAtual);
        } 

        if(page === this.state.subscreens.indexOf(this.state.subscreenAtual)) return;

        if (page < 0) {
            page = this.state.subscreens.length - 1;
        } else if (page > (this.state.subscreens.length - 1)) {
            page = 0;
        }

        this.fadeAnimations(increase);

        setTimeout(() => {
            this.setState({ subscreenAtual: this.state.subscreens[page] }, () => {
                this.triggerAnimations();
            });
        }, 400);
    }

    renderControls() {
        return (
            <div style={{
                ...EssentialStyle.rowFlexCenter,
                width: '100%',
                height: 18,
            }}>
                <DefaultButton
                    leftIcon={<FontAwesomeIcon icon={faChevronLeft} style={{ color: SessionHelper.getColor() }} />}
                    color={'transparent'}
                    onClick={() => { this.changePage(false) }}
                    style={{ marginBottom: 0, marginRight: 10 }}
                />
                <div style={{
                    ...EssentialStyle.rowSpaceAround,
                    width: (this.state.subscreens.length) * 16
                }}>
                    {
                        this.state.subscreens.map( (subscreen, i) => (
                            <div
                                style={{
                                    width: this.state.subscreenAtual == subscreen ? 10 : 8,
                                    height: this.state.subscreenAtual == subscreen ? 10 : 8,
                                    borderRadius: `50%`,
                                    backgroundColor: this.state.subscreenAtual == subscreen ? SessionHelper.getColor() : Colors.notSelected,
                                    cursor:  this.state.subscreenAtual == subscreen ? 'not-allowed' : 'pointer',
                                    margin: '0 2px'
                                }}
                                onClick={() => { this.changePage(false, i) }}
                                key={`dot-page-${i}`}
                            />
                        ))
                    }
                </div>

                <DefaultButton
                    leftIcon={<FontAwesomeIcon icon={faChevronRight} style={{ color: SessionHelper.getColor() }} />}
                    color={'transparent'}
                    onClick={() => { this.changePage(true) }}
                    style={{marginBottom: 0, marginLeft: 10 }}
                />
            </div>
        );
    }

    getSubscreenName(){
        var subscreen = this.state.subscreenAtual;
        var name = "";
        switch(subscreen){
            case "etapas":
                name = this.props.t('client_src_components_modules_inicio_charts_meusPlanosAcao_PlanoAcao:etapas');
                break;
            case "orcamento":
                name = this.props.t('client_src_components_modules_inicio_charts_meusPlanosAcao_PlanoAcao:orcamento');
                break;
            case "vinculos":
                name = this.props.t('client_src_components_modules_inicio_charts_meusPlanosAcao_PlanoAcao:vinculos');
                break;
        }
        return name;
    }

    renderBody() {
        return (
            <div style={{...EssentialStyle.rowFlexStart, width: '100%', height: '100%'}}>
                 {(!this.state.loading && this.state.subscreens?.length === 0)
                    ? (
                        <div style={{ ...EssentialStyle.columnCenter, width: '100%', height: '100%'}}>
                            <img
                                src={`${gateway}/img/undraw/undraw_teamwork.svg`}
                                alt={this.props.t('client_src_components_modules_inicio_charts_meusPlanosAcao_PlanoAcao:alt_pessoas_colando_postits')}
                                style={{
                                    height: '85%',
                                    maxHeight: 200,
                                    width: 350,
                                }}
                            />
                            <span style={{ ...EssentialStyle.titleBoxHomePage }}>
                                {this.props.t('client_src_components_modules_inicio_charts_meusPlanosAcao_PlanoAcao:nenhuma_etapa_vinculo_1')} <strong>{this.props.t('client_src_components_modules_inicio_charts_meusPlanosAcao_PlanoAcao:nenhuma_etapa_vinculo_2')}</strong> {this.props.t('client_src_components_modules_inicio_charts_meusPlanosAcao_PlanoAcao:nenhuma_etapa_vinculo_3')}
                            </span>
                        </div> )
                    : this.state.subscreens.includes("etapas") ? 
                    (
                        <div style={{ display: 'flex', width: '100%', height: '100%', flexDirection: `${this.props.expandedPageOne === "MeusPlanosAcao" ? "row" : "column"}`, overflow: "hidden"}}>
                            <div style={{ display: 'flex', width:`${this.props.expandedPageOne === "MeusPlanosAcao" ? "50%" : "100%"}`, minWidth:"200px", justifyContent: "space-around",
                                        height: `${this.props.expandedPageOne === "MeusPlanosAcao" ? "100%" : "50%"}`, flexDirection: "column", height: `${this.state.isSmallScreen ? "400px" : "unset"}`}}>
                                {this.state.subscreenAtual != null ?
                                <div style={{ display: 'flex', alignItems: "center", justifyContent: "left", width: '100%', height: '5%'}}>
                                    <strong style={{borderBottom: `0.5px solid${Colors.homePage.lightGrey}`, color: Colors.homePage.grey, paddingLeft: "10px", paddingRight: "10px"}}>
                                        {this.getSubscreenName()}
                                    </strong>
                                </div>
                                : null}
                                <div style={{ display: 'flex', width: '100%', height: `${this.state.subscreens.length > 1 ? "85%" : "100%"}`}}>
                                    {this.state.loading ? 
                                        this.renderLoading(1) 
                                    : this.state.subscreenAtual === "etapas" ? 
                                        this.renderGrafico() : 
                                    this.state.subscreenAtual === "orcamento" ?
                                    <OrcamentoPlanoAcao
                                        expandedPageOne={this.props.expandedPageOne}
                                        etapas={this.state.planoSelecionado.etapas}
                                        id_colaborador={this.state.id_colaborador}
                                        animations={this.getAnimations()}
                                        isSmallScreen={this.state.isSmallScreen}/> 
                                    : <VinculosPlanoAcao 
                                        id_colaborador={this.state.id_colaborador} 
                                        vinculos={this.state.planoSelecionado.vinculos} 
                                        expandedPageOne={this.props.expandedPageOne}
                                        animations={this.getAnimations()} 
                                        isSmallScreen={this.state.isSmallScreen} />}
                                </div>
                                {this.state.subscreens.length > 1 ? 
                                    <div style={{ display: 'flex', width: '100%', height: '10%', paddingTop: 10, paddingBottom: 10}}>
                                        {this.renderControls()}
                                    </div>
                                    : null
                                }
                            </div>
                            <div style={{ display: 'flex', minWidth: "350px", width: `${this.props.expandedPageOne === "MeusPlanosAcao" ? "50%" : "100%"}`, height: `${this.props.expandedPageOne === "MeusPlanosAcao" ? "100%" : "50%"}`, marginLeft: `${this.state.isSmallScreen ? "5px" : "10px"}` }}>
                                {this.state.loading ? this.renderLoading(2) : this.renderTable() }
                            </div>
                        </div>) 
                    : 
                    (
                        <div style={{ display: 'flex', width: '100%', height: '100%', flexDirection: "column"}}>
                            <div style={{ display: 'flex', width: '100%', height: '100%', alignItems: 'center', justifyContent: 'center'}}>
                                {this.state.loading ? this.renderLoading(1) : 
                                <VinculosPlanoAcao 
                                    id_colaborador={this.state.id_colaborador} 
                                    vinculos={this.state.planoSelecionado.vinculos} 
                                    expandedPageOne={this.props.expandedPageOne} 
                                    animations={this.getAnimations()}
                                    column={true}
                                    isSmallScreen={this.state.isSmallScreen} /> } 
                            </div>
                        </div>
                    )

                }
            </div>
        )
    }

    render() {

        return (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', height: '100%', minHeight:"410px" }}>
                {this.renderBody()}
            </div>
        )
    }
}

export default withTranslation()(PlanoAcao);
