import React from "react";
import ReactDOM from "react-dom";
import './BoxCreateRelatorio.css';
import DiagnosticoHelper from "../../../../../helper/diagnostico/DiagnosticoHelper";
import IdiomaHelper from "../../../../../helper/IdiomaHelper";
import DefaultButton from "../../../../tools/DefaultButton";
import Colors from "../../../../../constants/Colors";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import DataHelper from "../../../../../helper/DataHelper";
import Select from "react-select";
import EssentialStyle from "../../../../../style/EssentialStyle";
import { toast } from "react-toastify";
import DefaultLoader from "../../../../tools/DefaultLoader";
import CustomConfirm from "../../../../tools/CustomConfirm";
import { confirmAlert } from "react-confirm-alert";
import { Dropdown, Form } from "react-bootstrap";

// Box para criar os relatórios para determinadas empreasas
export default class BoxCreateRelatorio extends React.Component {

    state = {
        loading: true,
        empresas: [],
        empresasSelected: [],
        selectedTypeOperation: 'todos',
        visualizationTypes: {
            'todos' : {
                'pt_br': 'Todas as empresas',
                'en': 'All companies',
                'es': 'Todas las empresas',
            },
            'selecionadas' : {
                'pt_br': 'Empresas selecionadas',
                'en': 'Selected companies',
                'es': 'Empresas seleccionadas',
            },
            'naoSelecionadas' : {
                'pt_br': 'Empresas não selecionadas',
                'en': 'Unselected companies',
                'es': 'Empresas no seleccionadas',
            },
        },
        tipos: [],
        tiposSelected: [],
    }

    async componentDidMount() {
        await this.loadData();
    }

    async loadData() {
        this.setState({ loading: true });

        let { empresas, tipos } = await DiagnosticoHelper.getInfosAddRelatorio(this.props.idPeriodoAvaliacao);
        
        empresas = DataHelper.formatSelectData(empresas, 'id', 'nome_fantasia');
        tipos = DataHelper.formatSelectData(tipos, 'id', 'nome')
        let tiposSelected = tipos;

        this.setState({ empresas, tipos, tiposSelected, loading: false });
    }

    async createRelatorio() {
        const message = IdiomaHelper.getStruct({
        'pt_br': { success: ' Relatório(s) criado com sucesso!', error: 'Erro ao criar relatório!', zero: 'Nenhuma Avaliação Finalizada para gerar relatório', empty: 'Selecione ao menos uma empresa para criar o relatório, ou selecione a opção de Todas Empresas!', emptyTipo: 'Selecione ao menos um Tipo de Avaliação para criar o relatório!' },
            'en': { success: ' Reports created successfully!', error: 'Error creating report!', zero: 'No Assessment Completed to generate report', empty: 'Select at least one company to create the report, or select the All Companies option!', emptyTipo: 'Select at least one Evaluation Type to create the report!' },
            'es': { success: ' Informe(s) creado(s) exitosamente!', error: 'Error al crear el informe!', zero: 'No se completó ninguna evaluación para generar el informe', empty: 'Seleccione al menos una empresa para crear el informe, o seleccione la opción de Todas las empresas!', emptyTipo: 'Seleccione al menos un Tipo de Evaluación para crear el informe!' },
        });

        if (this.state.selectedTypeOperation != 'todos' && this.state.empresasSelected.length == 0) {
            toast.error(message[this.props.lang].empty);
            return;
        }
        
        let tipos = this.state.tiposSelected.map(e => e.value);
        if (this.state.tiposSelected.length == 0) {
            toast.error(message[this.props.lang].emptyTipo);
            return;
        }

        let idEmpresas = [];
        if (this.state.selectedTypeOperation == 'todos') {
            idEmpresas = this.state.empresas.map(e => e.value);
        } else if (this.state.selectedTypeOperation == 'selecionadas') {
            idEmpresas = this.state.empresasSelected.map(e => e.value);
        } else if (this.state.selectedTypeOperation == 'naoSelecionadas') {
            idEmpresas = this.state.empresas.map(e => e.value);
            idEmpresas = idEmpresas.filter(e => !this.state.empresasSelected.map(e => e.value).includes(e));
        }

        let {error, relatoriosCriados} = await DiagnosticoHelper.createRelatorioPorModelo(this.props.idModelo, idEmpresas, tipos);

        if (error) {
            toast.error(message[this.props.lang].error);
        } else if (relatoriosCriados == 0) {
            toast.info(message[this.props.lang].zero);
        } else {
            toast.success(relatoriosCriados + message[this.props.lang].success);
        }
        
    }

    openInfo() {
        const message = IdiomaHelper.getStruct({
            'pt_br': { 
                title: 'Criar Relatórios', 
                text: `
                    <div>
                        <p>
                        Crie relatórios de diagnóstico para as <strong>Avaliações Finalizadas</strong> das empresas que desejar e tiver permissão.
                        </p>
                        <p style="font-style: italic;">
                            <strong>Para ter permissão:</strong> você deve ter permissão de edição do Módulo ou ser Avaliador da empresa ou ter uma permissão de acesso superior.
                        </p>
                        <ul>
                            <li>
                                <strong>"Todas as empresas"</strong>: gera relatórios para todas empresa com uma ou mais <strong>Avaliações Finalizadas</strong>.
                            </li>
                            <li>
                                <strong>"Empresas selecionadas"</strong>: gera relatórios para cada empresa selecionada com uma ou mais <strong>Avaliações Finalizadas</strong>.
                            </li>
                            <li>
                                <strong>"Empresas não selecionadas"</strong>: gera relatórios para todas empresa com uma ou mais <strong>Avaliações Finalizadas</strong>, exceto as selecionadas.
                            </li>
                        </ul>
                        <br/>
                        Os relatórios criados ficarão na aba <strong>"Em Elaboração"</strong> e não serão visíveis para os avaliados até serem publicados.
                    </div>
                `,
                close: 'Fechar' },
            'en': { 
                title: 'Create Report', 
                text: `
                    <div>
                        <p>
                        Here you can create diagnostic reports for the <strong>Finished Evaluations</strong> of the companies you want and have permission.
                        </p>
                        <p style="font-style: italic;">
                            <strong>To have permission:</strong> you must have permission to edit the Module or be an Evaluator of the company or have a higher access permission.
                        </p>
                        <ul>
                            <li>
                                <strong>"All companies"</strong>: generates reports for all companies with one or more <strong>Finished Evaluations</strong>.
                            </li>
                            <li>
                                <strong>"Selected companies"</strong>: generates reports for each selected company with one or more <strong>Finished Evaluations</strong>.
                            </li>
                            <li>
                                <strong>"Unselected companies"</strong>: generates reports for all companies with one or more <strong>Finished Evaluations</strong>, except the selected ones.
                            </li>
                        </ul>
                        <br/>
                        The created reports will be in the <strong>"In Preparation"</strong> tab and will not be visible to the evaluated until they are published.
                    </div>
                `,
                close: 'Close' },
            'es': { 
                title: 'Crear Informe', 
                text: `
                    <div>
                        <p>
                        Cree informes de diagnóstico para las <strong>Evaluaciones Finalizadas</strong> de las empresas que desee y tenga permiso.
                        </p>
                        <p style="font-style: italic;">
                            <strong>Para tener permiso:</strong> debe tener permiso para editar el Módulo o ser Evaluador de la empresa o tener un permiso de acceso superior.
                        </p>
                        <ul>
                            <li>
                                <strong>"Todas las empresas"</strong>: genera informes para todas las empresas con una o más <strong>Evaluaciones Finalizadas</strong>.
                            </li>
                            <li>
                                <strong>"Empresas seleccionadas"</strong>: genera informes para cada empresa seleccionada con una o más <strong>Evaluaciones Finalizadas</strong>.
                            </li>
                            <li>
                                <strong>"Empresas no seleccionadas"</strong>: genera informes para todas las empresas con una o más <strong>Evaluaciones Finalizadas</strong>, excepto las seleccionadas.
                            </li>
                        </ul>
                        <br/>
                        Los informes creados estarán en la pestaña <strong>"En Preparación"</strong> y no serán visibles para los evaluados hasta que se publiquen.
                    </div>
                `,
                close: 'Cerrar' },
        });

        confirmAlert({
            customUI: ({ onClose }) => (
                <CustomConfirm
                    title={message[this.props.lang].title}
                    message={<div dangerouslySetInnerHTML={{ __html: message[this.props.lang].text }} style={{ marginTop: 10, marginBottom: 10 }} />}
                    buttons={[
                        {
                            label: message[this.props.lang].close,
                            onClick: () => { onClose(); }
                        },
                    ]}
                />
            )
        });
    }

    renderBox() {
        const message = IdiomaHelper.getStruct({
            'pt_br': { title: 'Criar Relatórios', everyCompany: 'Todas as empresas', create: 'Criar', emptyCompany: 'Nenhuma empresa Selecionada', bannerPlaceHolderTiposAvaliacao: 'Selecione um Tipo de Avaliação ou mais', },
            'en': { title: 'Create Report', everyCompany: 'Every company', create: 'Create', emptyCompany: 'No company selected', bannerPlaceHolderTiposAvaliacao: 'Select one or more Evaluation Types', },
            'es': { title: 'Crear Informe', everyCompany: 'Todas las empresas', create: 'Crear', emptyCompany: 'Ninguna empresa seleccionada', bannerPlaceHolderTiposAvaliacion: 'Seleccione uno o más Tipos de Evaluación', },
        });

        const portalTarget = document.getElementById('root');

        return (
            <div className="BoxCreateRelatorio box">
                
                <div className="title">{message[this.props.lang].title} <FontAwesomeIcon icon={faInfoCircle} className={"info"} onClick={() => this.openInfo()}/></div>

                <Dropdown>
                    <Dropdown.Toggle
                        onChange={() => {}}
                        value={this.state.selectedTypeOperation} 
                        style={{
                            backgroundColor: Colors.secondaryButton, 
                            color: Colors.dark,
                            width: '100%',
                            borderColor: 'white',

                        }}
                        disabled={this.state.loading}
                    > 
                        <span style={{ marginRight: 5, marginLeft: 10, fontSize: 14 }}>{this.state.visualizationTypes[this.state.selectedTypeOperation][this.props.lang]}</span>
                    </Dropdown.Toggle>
                    
                    {ReactDOM.createPortal(<Dropdown.Menu> 
                        {Object.keys(this.state.visualizationTypes).map(key => (
                            <Dropdown.Item
                                key={`visualization-opt-${key}`} 
                                onClick={() => { this.setState({ selectedTypeOperation: key }) }} 
                                style={key === this.state.selectedTypeOperation ? { backgroundColor: Colors.dark } : {}} 
                                active={key === this.state.selectedTypeOperation}
                            >
                                <span style={{ fontSize: 12 }}>{this.state.visualizationTypes[key][this.props.lang]}</span>
                            </Dropdown.Item>
                        ))}
                    </Dropdown.Menu>
                    , portalTarget)}
                </Dropdown>

                {this.state.selectedTypeOperation != 'todos' && (
                    <Select
                        styles={{ 
                            container: (provided) => ({
                                ...provided,
                                width: '100%',
                                maxHeight: 100,
                                marginBottom: 10,
                                marginTop: 10,
                            }),
                        }}
                        isMulti
                        options={this.state.empresas}
                        value={this.state.empresasSelected}
                        onChange={(e) => this.setState({ empresasSelected: e })}
                        placeholder={this.state.selectedTypeOperation == 'selecionadas' ? message[this.props.lang].emptyCompany : message[this.props.lang].everyCompany}
                    />
                )}

                <Select
                    options={this.state.tipos}
                    placeholder={message[this.props.lang].bannerPlaceHolderTiposAvaliacao}
                    onChange={(value) => this.setState({ tiposSelected: value })}
                    value={this.state.tiposSelected}
                    isMulti
                    isSearchable
                    isCleanable
                    menuPortalTarget={document.body}
                    styles={{
                        menuPortal: base => ({ ...base, zIndex: 9999 }),
                        container: (provided) => ({
                            ...provided,
                            width: '100%',
                            marginTop: 10,
                        }),
                    }}
                />

                <div className="footer">
                    <DefaultButton
                        title={message[this.props.lang].create}
                        leftIcon={<FontAwesomeIcon icon={faPaperPlane} />}
                        color={Colors.success}
                        onClick={() => this.createRelatorio()}
                    />
                </div>
            </div>
        )
    }

    renderBoxLoading() {
        return (
            <div className="BoxCreateRelatorio box">
                <div style={{width: '100%', height: '100%', ...EssentialStyle.columnCenter}}>
                    <DefaultLoader/>
                </div>
            </div>
        )
    }

    render() {

        if (this.state.loading) return this.renderBoxLoading();
        return this.renderBox();
    }
}