import React from "react";

import './PreViewEscala.css';
import { Form, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusSquare, faPaperPlane, faTrash, faPen } from "@fortawesome/free-solid-svg-icons";
import Colors from "../../../../../../constants/Colors";
import DefaultButton from "../../../../../tools/DefaultButton";
import { toast } from "react-toastify";
import StarPicker from "../../../../../tools/StarPicker/StarPicker";
import IdiomaHelper from "../../../../../../helper/IdiomaHelper";
import EssentialStyle from "../../../../../../style/EssentialStyle";
import LayoutHelper from "../../../../../../helper/LayoutHelper";
import ConceitoPicker from "../../../../../tools/ConceitoPicker/ConceitoPicker";

export default class PreViewEscala extends React.Component {

    state = {
        escala: this.props.escala
    }

    componentDidMount() {
        this.setSliderColors();
    }

    componentDidUpdate(prevProps) {
        this.setSliderColors();
    }

    setSliderColors = () => {
        if (this.props.faixas.length > 0) {
            let gradient = 'linear-gradient(to right, ';
            
            this.props.faixas.forEach((intervalo, key) => {
                let vals = ['min', 'max'];

                if (intervalo.min > 100) intervalo.max = 100;
                if (intervalo.min > 100) intervalo.max = 100;

                vals.forEach((val, keyVal) => {
                    gradient += `${intervalo.cor} ${intervalo[val]}%,`;
                });
            });

            gradient = gradient.substring(0, gradient.length - 1);
            gradient += ')';


            LayoutHelper.setSliderColors(gradient, "preview-escala-slider");
        }
    }

    message = (lang) => {
        return IdiomaHelper.getStruct({
            'pt_br': { 
                estrela: 'Exemplo de Escala de Estrela',
                conceito: 'Exemplo da Escala de Conceitos',
                addConceito: 'Adicione um Conceito...',
                addValor: 'Valor...',
                numerica: 'Exemplo de Escala Numérica',
            },
            'en': { 
                estrela: 'Example of Star Scale',
                conceito: 'Example of Concept Scale',
                addConceito: 'Add a Concept...',
                addValor: 'Value...',
                numerica: 'Example of Numeric Scale',
            },
            'es': { 
                estrela: 'Ejemplo de Escala de Estrella',
                conceito: 'Ejemplo de Escala de Conceptos',
                addConceito: 'Agregar un Concepto...',
                addValor: 'Valor...',
                numerica: 'Ejemplo de Escala Numérica',
            },
        })[lang];
    }

    renderPreView() {

        const configEstrela = () => {
            return (
                <div className="estrela-container">
                    <ConceitoPicker
                        min={0}
                        max={100}
                        escala={this.props.escala}
                        value={this.props.escala.value}
                        type={'star'}
                        editConceitoCallback={(conceitoAtual) => {
                            if(conceitoAtual && conceitoAtual.id) {
                                this.props.editConceitoCallback(conceitoAtual);
                            }
                        }}
                        deleteConceitoCallback={ (conceito) => {
                            let newConceitos = this.props.escala.conceitos.filter(conc => conc.id && conc.id !== conceito.id);
                            this.props.callback({...this.props.escala, conceitos: newConceitos});
                        }}
                        editMode
                    />
                </div>
                
            )
        }

        const configNumerica = () => {
            return (
                
                <div className="numerico-container">
                    <div className="numerico-title">
                        {this.message(this.props.lang).numerica}
                    </div>

                    <div className="numerico">
                        <Form.Control 
                            type="number"
                            value={this.props.escala.value || 0}
                            placeholder={'xx% ...'}
                            min="0"
                            max={this.props.escala.max}
                            step="1"
                            onChange={(event) => { 
                                const value = parseInt(event.target.value, 10);
                                if (!isNaN(value) && value >= 0 && value <= this.props.escala.max || event.target.value == 0) {
                                    this.setState({ 
                                        escala: {...this.props.escala, value: value} 
                                    }, () => { this.props.callback(this.props.escala); });
                                }
                            }}
                            style={{width: 80}}
                        />
                        <Form.Control
                            id="preview-escala-slider"
                            style={{ height: 38 }}
                            type="range"
                            min="0"
                            max={this.props.escala.max}
                            value={this.props.escala.value || 0}
                            onChange={(event) => {
                                this.setState({ 
                                    escala: {...this.props.escala, value: event.target.value} 
                                });
                            }}
                            onMouseUp={() => { 
                                this.props.callback(this.props.escala); 
                            }}
                            onTouchEnd={() => { 
                                this.props.callback(this.props.escala); 
                            }}
                        />
                    </div>
                </div>
            )
        }

        const configConceito = () => {

            let conceitos = this.props.escala.conceitos;

            conceitos = conceitos.sort((a, b) => {
                return b.valor - a.valor;
            });

            conceitos = conceitos.map(conceito => {
                return {
                    ...conceito,
                    cor: conceito.cor ? conceito.cor : this.props.faixas.find(faixa => parseFloat(faixa.min) <= parseFloat(conceito.valor) && parseFloat(faixa.max) >= parseFloat(conceito.valor)).cor
                }
            });

            return (
                <div className="conceitos-container">
                    <div className="conceitos-title">
                        {this.message(this.props.lang).conceito}
                    </div>
                    {conceitos.map((conceito, index) => {
                        return (
                            <div key={`conceito-${index}`} className="conceito" style={{boxShadow: Colors.boxShadow }}>
                                <div className="conceito-left">
                                    <div className="conceito-cor" style={{ backgroundColor: conceito.cor }}/>

                                    <div className="conceito-delete" onClick={() => {
                                        let newConceitos = this.props.escala.conceitos.filter(conc => conc.id && conc.id !== conceito.id);
                                        this.props.callback({...this.props.escala, conceitos: newConceitos});
                                    }}>
                                        <FontAwesomeIcon icon={faTrash} style={{ color: Colors.error }}/>
                                    </div>

                                    <div className="conceito-edit" onClick={() => {
                                        let conceitoAtual = this.props.escala.conceitos.find(conc => conc.id && conc.id === conceito.id);
                                        if(!conceitoAtual.id)
                                            return;         
                                        this.props.editConceitoCallback(conceitoAtual);
                                    }}>
                                        <FontAwesomeIcon icon={faPen} style={{ color: Colors.dark }}/>
                                    </div>

                                    <div className="conceito-title">{conceito.descricao.find(desc => desc.id_idioma === IdiomaHelper.getSiglaId(this.props.lang)).conceito}</div>
                                </div>
                                <div className="conceito-right">
                                    <div className="conceito-valor">{conceito.valor}</div>
                                </div>
                            </div>
                        )
                    })}

                    <div className="conceito" style={{boxShadow: Colors.boxShadow}} onClick={() => {this.props.addConceitoCallback()}}>
                        <div className="conceito-left">
                            <div className="conceito-title" style={{fontStyle: "italic", color: '#868686'}}>{this.message(this.props.lang).addConceito}</div>
                        </div>
                        <div className="conceito-valor" style={{fontStyle: "italic", color: '#868686'}}>{this.message(this.props.lang).addValor}</div>
                    </div>
                </div>
            )
        }
        
        switch(this.props.escala.tipo) {
            case 'estrela':
                return configEstrela();
            case 'numerica':
                return configNumerica();
            case 'conceito':
                return configConceito();
        }
    }

    render() {

        return (
            <div className="PreViewEscala">
                {this.renderPreView()}
            </div>
        )
    }
}