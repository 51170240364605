import React from "react";

import DiagnosticoHelper from "../../../../../helper/diagnostico/DiagnosticoHelper";

import './GradeAvaliacao.css';
import DefaultLoader from "../../../../tools/DefaultLoader";
import EssentialStyle from "../../../../../style/EssentialStyle";
import DefaultButton from "../../../../tools/DefaultButton";
import TooManyAvatars from "../../../../tools/TooManyAvatars";
import IdiomaHelper from "../../../../../helper/IdiomaHelper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusSquare, faPaperPlane, faEdit, faClockRotateLeft, faEye, faTrash, faLink, faClose } from "@fortawesome/free-solid-svg-icons";
import Colors from "../../../../../constants/Colors";
import { Collapse, Form, Row, Col } from "react-bootstrap";
import Select from 'react-select';
import DataHelper from "../../../../../helper/DataHelper";
import { toast } from 'react-toastify';
import Modal from "../../../../tools/Modal";
import SessionHelper from "../../../../../helper/SessionHelper";
import { confirmAlert } from 'react-confirm-alert';
import CustomConfirm from "../../../../tools/CustomConfirm";

import Highcharts from "highcharts/highcharts.js";
import HighchartsReact from "highcharts-react-official";
import highchartsMore from 'highcharts/highcharts-more';
import variablePie from "highcharts/modules/variable-pie.js";
import accessibility from "highcharts/modules/accessibility.js";
import UserAvatar from "../../../../tools/UserAvatar";
import CustomTooltip from "../../../../tools/CustomTooltip";
import ModalAvaliadores from "../ModalAvaliadores/ModalAvaliadores";
import HistoricoAvaliacaoModal from "../../Avaliacao/Aplicacao/HistoricoAvaliacaoModal/HistoricoAvaliacaoModal";
import { withTranslation } from 'react-i18next';

highchartsMore(Highcharts);
variablePie(Highcharts);
accessibility(Highcharts);

class GradeAvaliacao extends React.Component {

    state = {

        loadingGradeAvaliacao: true,
        loadingBannerAvaliacao: true,
        loadingBannerResumo: true,
        loadingBannerDeleteAvaliacao: false,

        periodoAvaliacao: {},
        gradeAvaliacao: {},

        newAvaliacao: {
            questionarios: [],
            tipos: [],
            empresas: [],
            avaliadores: [],
            publicadores: [],
        },

        resumo: {},

        canEdit: false,
        canView: false,

        openBannerAddAvaliacao: false,
        openBannerResumo: false,
        openEditAvaliadores: false,
        openShareModal: false,
        openHistoricoModal: false,

        hasAvaliacoesComAvaliadores: false,
        hasAvaliacoesEnviadas: false,

        avaliadores: [],
    }

    async componentDidMount() {
        await this.props.i18n.loadNamespaces(['client_src_helper_diagnostico_DiagnosticoHelper']);

        if (this.props.idPeriodoAvaliacao) {
            this.loadGradeAvaliacao();
            this.loadInfosBannerAddAvaliacao();
            this.loadResumoAvaliacoes();
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.idPeriodoAvaliacao !== this.props.idPeriodoAvaliacao) {
            if (this.props.idPeriodoAvaliacao) {
                this.loadGradeAvaliacao();
                this.loadInfosBannerAddAvaliacao();
                this.loadResumoAvaliacoes();
            }
        }

        if (prevProps.reloadPeriodo !== this.props.reloadPeriodo) {
            if (this.props.idPeriodoAvaliacao) {
                this.loadGradeAvaliacao();
                this.loadResumoAvaliacoes();
            }
        }
    }

    loadGradeAvaliacao = async (load = true) => {
        this.setState({ loadingGradeAvaliacao: load ? true : false });

        let { periodoAvaliacao, gradeAvaliacao, canEdit, canView } = await DiagnosticoHelper.getGradeAvaliacao(this.props.idPeriodoAvaliacao);

        this.setState({
            periodoAvaliacao,
            gradeAvaliacao,
            canEdit,
            canView,
            loadingGradeAvaliacao: false,
            hasAvaliacoesComAvaliadores: this.gradeHasAvaliacoesComAvaliadores(gradeAvaliacao),
            hasAvaliacoesEnviadas: this.gradeHasAvaliacoesEnviadas(gradeAvaliacao),
        });
    }

    gradeHasAvaliacoesComAvaliadores = (gradeAvaliacao) => {
        return gradeAvaliacao.grade.some(questionario => questionario.tipos.some(tipo => tipo.avaliacoes.some(avaliacao => avaliacao.avaliadores.length > 0)));
    }

    gradeHasAvaliacoesEnviadas = (gradeAvaliacao) => {
        return gradeAvaliacao.grade.some(questionario => questionario.tipos.some(tipo => tipo.avaliacoes.some(avaliacao => avaliacao.status == 1 || avaliacao.status == 2 || avaliacao.status == 3)));
    }

    loadInfosBannerAddAvaliacao = async () => {
        this.setState({ loadingBannerAvaliacao: true, openBannerAddAvaliacao: false });

        let { questionarios, tipos, empresas, colaboradores } = await DiagnosticoHelper.getInfosAddAvaliacao(this.props.idPeriodoAvaliacao);

        questionarios = DataHelper.formatSelectData(questionarios, 'id', 'nome');
        tipos = DataHelper.formatSelectData(tipos, 'id', 'nome');
        empresas = DataHelper.formatSelectData(empresas, 'id', 'nome_fantasia');
        let avaliadores = DataHelper.formatSelectData(colaboradores, 'id', 'nome');

        this.setState({
            questionarios,
            tipos,
            empresas,
            avaliadores,
            loadingBannerAvaliacao: false,
        });
    }

    loadResumoAvaliacoes = async () => {

        this.setState({ loadingBannerResumo: true });

        let resumo = await DiagnosticoHelper.getInfosAdicionaisPeriodoAvaliacao(this.props.idPeriodoAvaliacao);

        this.setState({ resumo, loadingBannerResumo: false })

    }

    addAvaliacao = async () => {

        if (this.state.newAvaliacao.questionarios.length === 0) {
            return toast.error(this.message().errorQuestionario);
        }

        if (this.state.newAvaliacao.tipos.length === 0) {
            return toast.error(this.message().errorTiposAvaliacao);
        }

        this.setState({ loadingBannerAvaliacao: true, openBannerAddAvaliacao: false });

        let newAvaliacao = JSON.stringify(this.state.newAvaliacao);
        let { gradeAvaliacao } = await DiagnosticoHelper.setAvaliacao(this.props.idPeriodoAvaliacao, newAvaliacao, 1);

        this.setState({
            gradeAvaliacao,
            newAvaliacao: {
                questionarios: [],
                tipos: [],
                empresas: [],
                avaliadores: [],
                publicadores: [],
            },
            loadingBannerAvaliacao: false,
            hasAvaliacoesComAvaliadores: this.gradeHasAvaliacoesComAvaliadores(gradeAvaliacao),
            hasAvaliacoesEnviadas: this.gradeHasAvaliacoesEnviadas(gradeAvaliacao),
        }, () => {
            this.loadResumoAvaliacoes();
        })

    }

    saveAvaliacao = async () => {

        if (this.state.loadingBannerAvaliacao) {
            return;
        }

        this.setState({ loadingBannerAvaliacao: true });

        let { gradeAvaliacao } = await DiagnosticoHelper.saveAvaliacao(this.state.openEditAvaliadores, 1);

        this.setState({
            gradeAvaliacao,
            openEditAvaliadores: false,
            loadingBannerAvaliacao: false,
            hasAvaliacoesComAvaliadores: this.gradeHasAvaliacoesComAvaliadores(gradeAvaliacao),
            hasAvaliacoesEnviadas: this.gradeHasAvaliacoesEnviadas(gradeAvaliacao),
        })
    }

    deleteAvaliacao = async (avaliacao) => {

        let message = IdiomaHelper.getStruct({
            'pt_br': {
                title: 'Excluir Avaliação',
                message: 'Deseja realmente Excluir esta Avaliação? Essa ação não pode ser desfeita',
                confirm: 'Excluir',
                cancel: 'Cancelar',
            },
            'en': {
                title: 'Delete Evaluation',
                message: 'Do you really want to delete this Evaluation? This action cannot be undone',
                confirm: 'Delete',
                cancel: 'Cancel',
            },
            'es': {
                title: 'Eliminar Evaluación',
                message: '¿Realmente quieres eliminar esta Evaluación? Esta acción no se puede deshacer',
                confirm: 'Eliminar',
                cancel: 'Cancelar',
            }
        });

        confirmAlert({
            customUI: ({ onClose }) => (
                <CustomConfirm
                    title={message[this.props.lang].title}
                    message={<p style={{ marginTop: 10, marginBottom: 10 }}>{message[this.props.lang].message}</p>}
                    buttons={[
                        {
                            label: message[this.props.lang].confirm,
                            color: Colors.diagnostico.avaliacao.delete.backgroundColor[avaliacao.status],
                            textColor: Colors.light,
                            onClick: async () => {

                                if (!await DiagnosticoHelper.deleteAvaliacao(avaliacao.id)) {
                                    toast.error("Erro ao excluir Avaliação - Error deleting Evaluation - Error al eliminar el Evaluación");
                                } else {
                                    this.loadGradeAvaliacao(false);
                                    this.loadResumoAvaliacoes();
                                }

                                onClose();
                            }
                        },
                        {
                            label: message[this.props.lang].cancel,
                            onClick: () => { onClose(); }
                        },
                    ]}
                />
            )
        });
    }

    copyToClipboard = async (avaliacao, avaliador) => {

        let { url, status } = await DiagnosticoHelper.getLinkAvaliacaoAvaliador(avaliacao.id, avaliador.id);

        if (status != 200) {
            return;
        }

        if (window.location.protocol === 'https:') {
            try {
                await window.navigator.clipboard.writeText(url);
                toast.info("Link copiado para a área de transferência");
            } catch (e) {
            }
        } else {
            var tempTextarea = document.createElement("textarea");
            tempTextarea.value = url;
            document.body.appendChild(tempTextarea);
            tempTextarea.select();
            document.execCommand("copy");
            document.body.removeChild(tempTextarea);
            toast.info("Link copiado para a área de transferência");
        }
    }

    openAvaliacao = async (avaliacao, avaliador) => {
        let { url, status } = await DiagnosticoHelper.getLinkAvaliacaoAvaliador(avaliacao.id, avaliador.id);

        if (status != 200) {
            return;
        }

        window.open(url, '_blank');
    }


    sendMailAvaliacao = async (avaliacao, avaliadores = []) => {
        const message = IdiomaHelper.getStruct({
            'pt_br': {
                success: 'E-mail enviado com sucesso',
            },
            'en': {
                success: 'Email sent successfully',
            },
            'es': {
                success: 'Correo electrónico enviado con éxito',
            }
        });

        var req = await DiagnosticoHelper.sendMailAvaliacao(avaliacao.id, avaliadores.map(a => a.id) || []);

        if (req.status == 200 && req.avaliacao) {
            this.setState({
                gradeAvaliacao: { ...this.state.gradeAvaliacao, grade: [...this.state.gradeAvaliacao.grade.map(g => g.id == req.avaliacao.questionario.id ? { ...g, tipos: g.tipos.map(t => t.tipo == req.avaliacao.tipo ? { ...t, avaliacoes: t.avaliacoes.map(a => a.id == req.avaliacao.id ? req.avaliacao : a) } : t) } : g)] }
            }, () => {
                this.setState({
                    hasAvaliacoesComAvaliadores: this.gradeHasAvaliacoesComAvaliadores(this.state.gradeAvaliacao),
                    hasAvaliacoesEnviadas: this.gradeHasAvaliacoesEnviadas(this.state.gradeAvaliacao),
                });
                this.loadResumoAvaliacoes();
            }
            );
            toast.success(message[this.props.lang].success);
        }
    }

    renderConfirmSendAllAvaliacoes = () => {

        const message = IdiomaHelper.getStruct({
            'pt_br': {
                title: 'Enviar todas as avaliações',
                message: 'Deseja realmente enviar todas as avaliações?',
                titleJaEnviadas: 'Algumas avaliações já foram enviadas!',
                messageJaEnviadas: 'Deseja realmente enviar todas as avaliações? As avaliações que já foram enviadas serão reenviadas',
                confirm: 'Enviar',
                cancel: 'Cancelar',
            },
            'en': {
                title: 'Send all evaluations',
                message: 'Do you really want to send all evaluations?',
                titleJaEnviadas: 'Some evaluations have already been sent!',
                messageJaEnviadas: 'Do you really want to send all evaluations? Evaluations that have already been sent will be resent',
                confirm: 'Send',
                cancel: 'Cancel',
            },
            'es': {
                title: 'Enviar todas las evaluaciones',
                message: '¿Realmente quieres enviar todas las evaluaciones?',
                titleJaEnviadas: '¡Algunas evaluaciones ya han sido enviadas!',
                messageJaEnviadas: '¿Realmente quieres enviar todas las evaluaciones? Las evaluaciones que ya han sido enviadas se reenviarán',
                confirm: 'Enviar',
                cancel: 'Cancelar',
            }
        });

        confirmAlert({
            customUI: ({ onClose }) => (
                <CustomConfirm
                    title={this.state.hasAvaliacoesEnviadas ? message[this.props.lang].titleJaEnviadas : message[this.props.lang].title}
                    message={<p style={{ marginTop: 10, marginBottom: 10 }}>{this.state.hasAvaliacoesEnviadas ? message[this.props.lang].messageJaEnviadas : message[this.props.lang].message}</p>}
                    buttons={[
                        {
                            label: message[this.props.lang].confirm,
                            color: Colors.success,
                            textColor: Colors.light,
                            onClick: async () => {
                                this.sendAvaliacoes();
                                onClose();
                            }
                        },
                        {
                            label: message[this.props.lang].cancel,
                            onClick: () => { onClose(); }
                        },
                    ]}
                />
            )
        });
    }

    sendAvaliacoes = async () => {

        const message = IdiomaHelper.getStruct({
            'pt_br': {
                success: 'Avaliações enviadas com sucesso',
            },
            'en': {
                success: 'Evaluations sent successfully',
            },
            'es': {
                success: 'Evaluaciones enviadas con éxito',
            }
        });

        var req = await DiagnosticoHelper.sendAvaliacoesPeriodo(this.props.idPeriodoAvaliacao);

        if (req.status == 200 && req.gradeAvaliacao) {
            this.setState({
                periodoAvaliacao: req.periodoAvaliacao,
                gradeAvaliacao: req.gradeAvaliacao,
                canEdit: req.canEdit,
                canView: req.canView,
                hasAvaliacoesComAvaliadores: this.gradeHasAvaliacoesComAvaliadores(req.gradeAvaliacao),
                hasAvaliacoesEnviadas: this.gradeHasAvaliacoesEnviadas(req.gradeAvaliacao),
            }, () => {
                this.loadResumoAvaliacoes();
            });
            toast.success(message[this.props.lang].success);
        }
    }


    message(lang = this.props.lang) {
        const message = IdiomaHelper.getStruct({
            'pt_br': {
                openAddAvaliacao: 'Adicionar Avaliação à Grade',
                openResumo: 'Resumo',

                bannerLabelQuestionario: 'Questionário',
                bannerLabelTiposAvaliacao: 'Tipos de Avaliação',
                bannerLabelEmpresas: 'Empresas',
                bannerLabelAvaliadores: 'Avaliadores',
                bannerLabelPublicadores: 'Publicadores',
                bannerAddAvaliacao: 'Adicionar',
                bannerPlaceHolderQuestionario: 'Selecione um Questionário ou mais',
                bannerPlaceHolderTiposAvaliacao: 'Selecione um Tipo de Avaliação ou mais',
                bannerPlaceHolderEmpresas: 'Todas Empresas',
                bannerPlaceHolderAvaliadores: 'Nenhum Avaliador Selecionado',
                bannerPlaceHolderPublicadores: 'Nenhum Publicador Selecionado',
                selectionTodosAvaliadores: 'Todos os Avaliadores',
                selectionTodosPublicadores: 'Todos os Avaliadores como Publicadores',

                bannerLabelEmpresasAvaliadas: 'Empresas Avaliadas',
                bannerLabelResumoStatus: 'Status das Avaliações',
                bannerLabelAvaliacoesFinalizadas: 'Avaliações Finalizadas',

                errorQuestionario: 'Selecione ao menos um Questionário',
                errorTiposAvaliacao: 'Selecione ao menos um Tipo de Avaliação',
                tableTitleQuestionario: 'Questionário',
                tableTitleTiposAvaliacao: 'Tipo de Avaliação',
                tableTitleEmpresas: 'Empresas',
                tableTitleAvaliadores: 'Avaliadores',
                tableTitleStatus: 'Status',
                tableTitleAcoes: 'Ações',
                modalEditEmpresa: 'Empresa',
                modalEditTipo: 'Tipo de Avaliação',
                modalEditQuestionario: 'Questionário',
                modalEditAvaliadores: 'Avaliadores',
                cancel: 'Cancelar',
                save: 'Salvar',
                modalEdit: 'Editando Avaliadores',

                shareModalTitle: 'Links da Avaliação',
                enviarParaTodosAvaliadores: 'Enviar para todos os avaliadores',
                enviarIndividual: 'Enviar individualmente',
                copiarLink: 'Copiar link',
                abrirModalCompartilhamento: 'Abrir Menu de Compartilhamento',
                deletar: 'Deletar Avaliação',
                enviarTodasAvaliacoes: 'Enviar todas as avaliações',
                abrirAvalicao: 'Abrir Avaliação',

                abrir_historico: 'Abrir Histórico de Respostas da avaliação',
            },
            'en': {
                openAddAvaliacao: 'Add Evaluation to Grid',
                openResumo: 'Summary',

                bannerLabelQuestionario: 'Questionnaire',
                bannerLabelTiposAvaliacao: 'Evaluation Types',
                bannerLabelEmpresas: 'Companies',
                bannerLabelAvaliadores: 'Evaluators',
                bannerLabelPublicadores: 'Publishers',
                bannerAddAvaliacao: 'Add',
                bannerPlaceHolderQuestionario: 'Select one or more Questionnaires',
                bannerPlaceHolderTiposAvaliacao: 'Select one or more Evaluation Types',
                bannerPlaceHolderEmpresas: 'All Companies',
                bannerPlaceHolderAvaliadores: 'No Evaluators Selected',
                bannerPlaceHolderPublicadores: 'No Publishers Selected',
                selectionTodosAvaliadores: 'All Evaluators',
                selectionTodosPublicadores: 'All Evaluators as Publishers',

                bannerLabelEmpresasAvaliadas: 'Evaluated Companies',
                bannerLabelResumoStatus: 'Assessment status',
                bannerLabelAvaliacoesFinalizadas: 'Finalized Assessments',

                errorQuestionario: 'Select at least one Questionnaire',
                errorTiposAvaliacao: 'Select at least one Evaluation Type',
                tableTitleQuestionario: 'Questionnaire',
                tableTitleTiposAvaliacao: 'Evaluation Type',
                tableTitleEmpresas: 'Companies',
                tableTitleAvaliadores: 'Evaluators',
                tableTitleStatus: 'Status',
                tableTitleAcoes: 'Actions',
                modalEditEmpresa: 'Company',
                modalEditTipo: 'Evaluation Type',
                modalEditQuestionario: 'Questionnaire',
                modalEditAvaliadores: 'Evaluators',
                cancel: 'Cancel',
                save: 'Save',
                modalEdit: 'Editing Evaluators',

                shareModalTitle: 'Evaluation links',
                enviarParaTodosAvaliadores: 'Send to all evaluators',
                enviarIndividual: 'Send individually',
                copiarLink: 'Copy link',
                abrirModalCompartilhamento: 'Open Sharing Menu',
                deletar: 'Delete Evaluation',
                enviarTodasAvaliacoes: 'Send all evaluations',
                abrirAvalicao: 'Open Evaluation',
                
                abrir_historico: 'Open Evaluation Response History',
            },
            'es': {
                openAddAvaliacao: 'Agregar Evaluación a la Grade',
                openResumo: 'Resumen',

                bannerLabelQuestionario: 'Cuestionario',
                bannerLabelTiposAvaliacao: 'Tipos de Evaluación',
                bannerLabelEmpresas: 'Empresas',
                bannerLabelAvaliadores: 'Evaluadores',
                bannerLabelPublicadores: 'Publicadores',
                bannerAddAvaliacao: 'Agregar',
                bannerPlaceHolderQuestionario: 'Seleccione uno o más Cuestionarios',
                bannerPlaceHolderTiposAvaliacao: 'Seleccione uno o más Tipos de Evaluación',
                bannerPlaceHolderEmpresas: 'Todas las Empresas',
                bannerPlaceHolderAvaliadores: 'Ningún Evaluador Seleccionado',
                bannerPlaceHolderPublicadores: 'Ningún Publicador Seleccionado',
                selectionTodosAvaliadores: 'Todos los Evaluadores',
                selectionTodosPublicadores: 'Todos los Evaluadores como Publicadores',

                bannerLabelEmpresasAvaliadas: 'Empresas evaluadas',
                bannerLabelResumoStatus: 'Estado de evaluación',
                bannerLabelAvaliacoesFinalizadas: 'Evaluaciones finalizadas',

                errorQuestionario: 'Seleccione al menos un Cuestionario',
                errorTiposAvaliacao: 'Seleccione al menos un Tipo de Evaluación',
                tableTitleQuestionario: 'Cuestionario',
                tableTitleTiposAvaliacao: 'Tipo de Evaluación',
                tableTitleEmpresas: 'Empresas',
                tableTitleAvaliadores: 'Evaluadores',
                tableTitleStatus: 'Estado',
                tableTitleAcoes: 'Acciones',
                modalEditEmpresa: 'Empresa',
                modalEditTipo: 'Tipo de Evaluación',
                modalEditQuestionario: 'Cuestionario',
                modalEditAvaliadores: 'Evaluadores',
                cancel: 'Cancelar',
                save: 'Guardar',
                modalEdit: 'Editando Evaluadores',

                shareModalTitle: 'Enlaces de Evaluación',
                enviarParaTodosAvaliadores: 'Enviar a todos los evaluadores',
                enviarIndividual: 'Enviar por separado',
                copiarLink: 'Copiar el enlace',
                abrirModalCompartilhamento: 'Abrir menú de intercambio',
                deletar: 'Eliminar Evaluación',
                enviarTodasAvaliacoes: 'Enviar todas las evaluaciones',
                abrirAvalicao: 'Abrir Evaluación',

                abrir_historico: 'Abrir Historial de Respuestas de la Evaluación',
            }
        });

        return message[lang];
    }

    // ----- Funções de render -----

    renderAddAvaliacoes() {

        let canAddAvaliacao = this.state?.gradeAvaliacao?.canAddAvaliacao || false;

        if (canAddAvaliacao) return (
            <DefaultButton
                className={`button-avaliacoes ${this.state.loadingBannerAvaliacao && 'disabled'}`}
                style={{
                    backgroundColor: (this.state.loadingBannerAvaliacao ? Colors.disabled : Colors.success),
                    color: Colors.light,
                    border: "unset"
                }}
                onClick={() => {
                    !this.state.loadingBannerAvaliacao && this.setState({ openBannerAddAvaliacao: !this.state.openBannerAddAvaliacao })
                }}
                leftIcon={<FontAwesomeIcon icon={faPlusSquare} />}
                title={this.message().openAddAvaliacao}
                disabled={this.state.loadingBannerAvaliacao}
            />
        )
    }

    renderOpenResumoAvaliacao() {
        return (
            <DefaultButton
                className={`button-avaliacoes ${this.state.loadingBannerResumo && 'disabled'}`}
                style={{
                    backgroundColor: (this.state.loadingBannerResumo ? Colors.disabled : Colors.success),
                    color: Colors.light,
                    border: "unset"
                }}
                onClick={() => {
                    this.setState({ openBannerResumo: !this.state.openBannerResumo })
                }}
                leftIcon={<FontAwesomeIcon icon={faEye} />}
                title={this.message().openResumo}
                disabled={this.state.loadingBannerResumo}
            />
        )
    }

    renderSendAvaliacoes() {
        let canEnviarAvaliacao = this.state?.gradeAvaliacao?.canEnviarAvaliacao || false;

        if (canEnviarAvaliacao) return (
            <DefaultButton
                className={`button-avaliacoes ${!this.state.hasAvaliacoesComAvaliadores && 'disabled'}`}
                style={{
                    backgroundColor: (!this.state.hasAvaliacoesComAvaliadores ? Colors.disabled : Colors.success),
                    color: Colors.light,
                    border: "unset"
                }}
                onClick={() => {
                    this.renderConfirmSendAllAvaliacoes();
                }}
                leftIcon={<FontAwesomeIcon icon={faPaperPlane} />}
                title={''}
                tooltip={this.message().enviarParaTodosAvaliadores}
                disabled={!this.state.hasAvaliacoesComAvaliadores}
            />
        );
    }


    renderShareModal() {

        let canEdit = this.state?.canEdit || false;
        if (canEdit) return (
            <Modal
                className="ShareModal"
            >
                <div
                    className="ModalHeader"
                    style={{ color: Colors.light, backgroundColor: SessionHelper.getColor() }}
                >
                    <span
                        className="title"
                        style={{ color: Colors.light }}
                    >
                        {this.message().shareModalTitle}
                    </span>
                    <DefaultButton
                        className={"close"}
                        title={''}
                        leftIcon={<FontAwesomeIcon icon={faClose} />}
                        textColor={Colors.light}
                        color={"transparent"}
                        height={30}
                        width={30}
                        onClick={() => this.setState({ openShareModal: false })}
                    />
                </div>
                <div className="ModalBody">
                    {this.state.openShareModal && this.state.openShareModal.avaliadores?.length > 0 ?
                        this.state.openShareModal?.avaliadores?.map(avaliador => (
                            <div className="share-avaliador-card" style={EssentialStyle.card}>
                                <UserAvatar
                                    user={avaliador}
                                    showName
                                    showNameAdaptative
                                />
                                <div className="buttons">
                                    {this.state.openShareModal.status != 0 &&
                                        <CustomTooltip
                                            tooltip={this.message().enviarIndividual}
                                            tooltipPlacement={"auto"}
                                        >
                                            <FontAwesomeIcon
                                                className={"mail"}
                                                icon={faPaperPlane}
                                                onClick={() => this.sendMailAvaliacao(this.state.openShareModal, [avaliador])}
                                            />
                                        </CustomTooltip>
                                    }
                                    <CustomTooltip
                                        tooltip={this.message().copiarLink}
                                        tooltipPlacement={"auto"}
                                    >
                                        <FontAwesomeIcon
                                            className={"link"}
                                            icon={faLink}
                                            onClick={() => this.copyToClipboard(this.state.openShareModal, avaliador)}
                                        />
                                    </CustomTooltip>
                                </div>
                            </div>
                        ))
                        :
                        <>
                        </>
                    }
                </div>
            </Modal>
        )
    }

    renderHistoricoModal() {
        return (
            <HistoricoAvaliacaoModal avaliacao={this.state.openHistoricoModal} onClose={() => this.setState({ openHistoricoModal: false })} />
        )
    }

    renderBannerAddAvaliacao() {
        var avaliadoresSelected = this.state.newAvaliacao.avaliadores || [];
        if (avaliadoresSelected?.length == this.state.avaliadores?.length) {
            avaliadoresSelected = [{ value: "*", label: this.message().selectionTodosAvaliadores }];
        }

        var publicadoresSelected = this.state.newAvaliacao.publicadores || [];
        if (publicadoresSelected?.length == this.state.avaliadores?.length) {
            publicadoresSelected = [{ value: "*", label: this.message().selectionTodosPublicadores }];
        }

        return (
            <Collapse in={this.state.openBannerAddAvaliacao}>
                <div className="avaliacoes-banner">
                    <Form>
                        <Row className="mb-3">
                            <Form.Group className='mb-2' as={Col} xs={12} md={4}>
                                <Form.Label className={"labelBanner"} >{this.message().bannerLabelQuestionario}</Form.Label>
                                <Select
                                    options={this.state.questionarios}
                                    placeholder={this.message().bannerPlaceHolderQuestionario}
                                    onChange={(value) => this.setState({ newAvaliacao: { ...this.state.newAvaliacao, questionarios: value } })}
                                    value={this.state.newAvaliacao.questionarios}
                                    isMulti
                                    isSearchable
                                    isCleanable
                                    menuPortalTarget={document.body}
                                />
                            </Form.Group>
                            <Form.Group className='mb-2' as={Col} xs={12} md={4}>
                                <Form.Label className={"labelBanner"} >{this.message().bannerLabelTiposAvaliacao}</Form.Label>
                                <Select
                                    options={this.state.tipos}
                                    placeholder={this.message().bannerPlaceHolderTiposAvaliacao}
                                    onChange={(value) => this.setState({ newAvaliacao: { ...this.state.newAvaliacao, tipos: value } })}
                                    value={this.state.newAvaliacao.tipos}
                                    isMulti
                                    isSearchable
                                    isCleanable
                                    menuPortalTarget={document.body}
                                />
                            </Form.Group>
                            <Form.Group className='mb-2' as={Col} xs={12} md={4}>
                                <Form.Label className={"labelBanner"} >{this.message().bannerLabelEmpresas}</Form.Label>
                                <Select
                                    options={this.state.empresas}
                                    placeholder={this.message().bannerPlaceHolderEmpresas}
                                    onChange={(value) => this.setState({ newAvaliacao: { ...this.state.newAvaliacao, empresas: value } })}
                                    value={this.state.newAvaliacao.empresas}
                                    isMulti
                                    isSearchable
                                    isCleanable
                                    menuPortalTarget={document.body}
                                />
                            </Form.Group>
                            {this.state.newAvaliacao.tipos && this.state.newAvaliacao.tipos.some(tipo => tipo.value === 1) && (
                                <Form.Group className='mb-2' as={Col} xs={12} md={12}>
                                    <Form.Label className={"labelBanner"} >{this.message().bannerLabelAvaliadores}</Form.Label>
                                    <Select
                                        options={[{ value: "*", label: this.message().selectionTodosAvaliadores }, ...this.state.avaliadores]}
                                        placeholder={this.message().bannerPlaceHolderAvaliadores}
                                        onChange={(value) => {
                                            if (value && value.length > 0 && value.find(v => v.value === "*")) {
                                                this.setState({ newAvaliacao: { ...this.state.newAvaliacao, avaliadores: this.state.avaliadores } })
                                                return;
                                            }
                                            this.setState({ newAvaliacao: { ...this.state.newAvaliacao, avaliadores: value } })
                                        }}
                                        value={avaliadoresSelected}
                                        isMulti
                                        isSearchable
                                        isCleanable
                                        menuPortalTarget={document.body}
                                    />
                                </Form.Group>
                            )}
                            <Form.Group className='mb-2' as={Col} xs={12} md={12}>
                                <Form.Label className={"labelBanner"} >{this.message().bannerLabelPublicadores}</Form.Label>
                                <Select
                                    options={[{ value: "*", label: this.message().selectionTodosPublicadores }, ...this.state.avaliadores]}
                                    placeholder={this.message().bannerPlaceHolderPublicadores}
                                    onChange={(value) => {
                                        if (value && value.length > 0 && value.find(v => v.value === "*")) {
                                            this.setState({ newAvaliacao: { ...this.state.newAvaliacao, publicadores: this.state.avaliadores } })
                                            return;
                                        }
                                        this.setState({ newAvaliacao: { ...this.state.newAvaliacao, publicadores: value } })
                                    }}
                                    value={publicadoresSelected}
                                    isMulti
                                    isSearchable
                                    isCleanable
                                    menuPortalTarget={document.body}
                                />
                            </Form.Group>
                        </Row>

                        <Row>
                            <Form.Group as={Col} xs={12} md={12}>
                                <div style={EssentialStyle.rowFlexEnd}>
                                    <DefaultButton
                                        title={this.message().bannerAddAvaliacao}
                                        placeholder={this.message().bannerPlaceHolder}
                                        leftIcon={<FontAwesomeIcon icon={faPaperPlane} />}
                                        color={Colors.success}
                                        onClick={() => this.addAvaliacao()}
                                    />
                                </div>
                            </Form.Group>
                        </Row>
                    </Form>
                </div>
            </Collapse>
        )
    }

    renderBannerResumoAvaliacao() {
        return (
            <Collapse in={this.state.openBannerResumo}>
                <div className="avaliacoes-banner">
                    <Form>
                        <Row className="mb-3">

                            <Form.Group className='mb-2' as={Col} xs={12} md={4}>
                                <HighchartsReact
                                    highcharts={Highcharts}
                                    constructorType={'chart'}
                                    containerProps={{ style: { height: 160 } }}
                                    options={{
                                        chart: {
                                            type: 'solidgauge',
                                            backgroundColor: 'transparent',
                                            margin: [null, 25, -100, 25],
                                            spacing: [25, 15, -100, 15],
                                            events: {
                                                render: function () {
                                                    if (this.centerText) {
                                                        this.centerText.destroy();
                                                    }

                                                    const fontSize = Math.min(this.plotWidth, this.plotHeight) * 0.15;

                                                    this.centerText = this.renderer.text(
                                                        `${this.series[0].points[0].y.toFixed(0)}%`,
                                                        this.plotLeft + (this.plotWidth / 2),
                                                        this.plotTop + (this.plotHeight / 2) - 10 + fontSize / 3
                                                    )
                                                        .attr({
                                                            align: 'center',
                                                            'text-anchor': 'middle'
                                                        })
                                                        .css({
                                                            fontSize: `${fontSize}px`,
                                                            color: Colors.dark,
                                                            fontWeight: 'bold'
                                                        })
                                                        .add();
                                                }
                                            },
                                        },
                                        title: {
                                            text: this.message().bannerLabelEmpresasAvaliadas,
                                        },
                                        tooltip: {
                                            enabled: false
                                        },
                                        credits: {
                                            enabled: false
                                        },
                                        pane: {
                                            startAngle: -90,
                                            endAngle: 90,
                                            background: [{
                                                outerRadius: '120%',
                                                innerRadius: '80%',
                                                backgroundColor: Colors.notSelected,
                                                borderWidth: 0,
                                                shape: 'arc',
                                                startAngle: -90,
                                                endAngle: 90
                                            }]
                                        },
                                        yAxis: {
                                            min: 0,
                                            max: 100,
                                            lineWidth: 0,
                                            tickPositions: []
                                        },
                                        plotOptions: {
                                            solidgauge: {
                                                dataLabels: {
                                                    enabled: false
                                                },
                                                linecap: 'round',
                                                stickyTracking: false,
                                                rounded: true
                                            }
                                        },
                                        series: [{
                                            name: 'Conversion',
                                            data: [{
                                                color: this.state.resumo?.empresasAvaliadas?.total > 0 ?
                                                    (this.state.resumo?.empresasAvaliadas?.total == this.state.resumo?.empresasAvaliadas?.realizado ?
                                                        (Colors.success)
                                                        :
                                                        (this.state.resumo?.empresasAvaliadas?.realizado == 0 ?
                                                            (Colors.error)
                                                            :
                                                            (Colors.danger)
                                                        )
                                                    )
                                                    :
                                                    Colors.error,
                                                radius: '120%',
                                                innerRadius: '80%',
                                                y: this.state.resumo?.empresasAvaliadas?.total > 0 ? (this.state.resumo?.empresasAvaliadas?.realizado / this.state.resumo?.empresasAvaliadas?.total) * 100 : 0,
                                            }],
                                            animation: {
                                                duration: 1500 // Duração da animação em milissegundos
                                            }
                                        }]
                                    }}
                                />
                                <Form.Group className='text-center mt-2'>
                                    <Form.Label style={{ fontWeight: 'bold' }}>{`${this.state.resumo?.empresasAvaliadas?.realizado} / ${this.state.resumo?.empresasAvaliadas?.total}`}</Form.Label>
                                </Form.Group>
                            </Form.Group>

                            <Form.Group className='mb-2' as={Col} xs={12} md={4}>
                                <HighchartsReact
                                    highcharts={Highcharts}
                                    constructorType={'chart'}
                                    containerProps={{ style: { height: 200 } }}
                                    options={{
                                        chart: {
                                            type: 'pie',
                                            margin: [null, 0, 0, 0],
                                            spacing: [25, 0, 0, 0],
                                            backgroundColor: 'transparent',
                                        },
                                        title: {
                                            text: this.message().bannerLabelResumoStatus,
                                        },
                                        tooltip: {
                                            pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
                                        },
                                        plotOptions: {
                                            pie: {
                                                allowPointSelect: true,
                                                cursor: 'pointer',
                                                dataLabels: {
                                                    enabled: true,
                                                    format: '<b>{point.name}</b>: {point.y} ({point.percentage:.1f}%)'
                                                }
                                            }
                                        },
                                        series: [{
                                            name: 'Status',
                                            colorByPoint: true,
                                            data: this.state?.resumo?.avaliacoesStatus?.map((status) => (
                                                {
                                                    name: DiagnosticoHelper.getStatusAvaliacaoNome(status.status, this.props.t),
                                                    y: status.total,
                                                    color: Colors.diagnostico.avaliacao.status.backgroundColor[status.status],
                                                }
                                            )) || []
                                        }],
                                        credits: {
                                            enabled: false
                                        },
                                    }}
                                />
                            </Form.Group>

                            <Form.Group className='mb-2' as={Col} xs={12} md={4}>
                                <HighchartsReact
                                    highcharts={Highcharts}
                                    constructorType={'chart'}
                                    containerProps={{ style: { height: 160 } }}
                                    options={{
                                        chart: {
                                            type: 'solidgauge',
                                            backgroundColor: 'transparent',
                                            margin: [null, 25, -100, 25],
                                            spacing: [25, 15, -100, 15],
                                            events: {
                                                render: function () {
                                                    if (this.centerText) {
                                                        this.centerText.destroy();
                                                    }

                                                    const fontSize = Math.min(this.plotWidth, this.plotHeight) * 0.15;

                                                    this.centerText = this.renderer.text(
                                                        `${this.series[0].points[0].y.toFixed(0)}%`,
                                                        this.plotLeft + (this.plotWidth / 2),
                                                        this.plotTop + (this.plotHeight / 2) - 10 + fontSize / 3
                                                    )
                                                        .attr({
                                                            align: 'center',
                                                            'text-anchor': 'middle'
                                                        })
                                                        .css({
                                                            fontSize: `${fontSize}px`,
                                                            color: Colors.dark,
                                                            fontWeight: 'bold'
                                                        })
                                                        .add();
                                                }
                                            },
                                        },
                                        title: {
                                            text: this.message().bannerLabelAvaliacoesFinalizadas,
                                        },
                                        tooltip: {
                                            enabled: false
                                        },
                                        credits: {
                                            enabled: false
                                        },
                                        pane: {
                                            startAngle: -90,
                                            endAngle: 90,
                                            background: [{
                                                outerRadius: '120%',
                                                innerRadius: '80%',
                                                backgroundColor: Colors.notSelected,
                                                borderWidth: 0,
                                                shape: 'arc',
                                                startAngle: -90,
                                                endAngle: 90
                                            }]
                                        },
                                        yAxis: {
                                            min: 0,
                                            max: 100,
                                            lineWidth: 0,
                                            tickPositions: []
                                        },
                                        plotOptions: {
                                            solidgauge: {
                                                dataLabels: {
                                                    enabled: false
                                                },
                                                linecap: 'round',
                                                stickyTracking: false,
                                                rounded: true
                                            }
                                        },
                                        series: [{
                                            name: 'Conversion',
                                            data: [{
                                                color: this.state.resumo?.avaliacoesFinalizadas?.total > 0 ?
                                                    (this.state.resumo?.avaliacoesFinalizadas?.total == this.state.resumo?.avaliacoesFinalizadas?.realizado ?
                                                        (Colors.success)
                                                        :
                                                        (this.state.resumo?.avaliacoesFinalizadas?.realizado == 0 ?
                                                            (Colors.error)
                                                            :
                                                            (Colors.danger)
                                                        )
                                                    )
                                                    :
                                                    Colors.error,
                                                radius: '120%',
                                                innerRadius: '80%',
                                                y: this.state.resumo?.avaliacoesFinalizadas?.total > 0 ? (this.state.resumo?.avaliacoesFinalizadas?.realizado || 1 / this.state.resumo?.avaliacoesFinalizadas?.total || 1) * 100 : 0,
                                            }],
                                            animation: {
                                                duration: 1500 // Duração da animação em milissegundos
                                            }
                                        }]
                                    }}
                                />
                                <Form.Group className='text-center mt-2'>
                                    <Form.Label style={{ fontWeight: 'bold' }}>{`${this.state?.resumo?.avaliacoesFinalizadas?.realizado || 1} / ${this.state?.resumo?.avaliacoesFinalizadas?.total || 1 }`}</Form.Label>
                                </Form.Group>
                            </Form.Group>

                        </Row>
                    </Form>
                </div>
            </Collapse>
        )
    }

    renderTabelaAvalicao() {

        const renderCelResponsaveis = (avaliacao) => {

            let avaliadores = avaliacao.avaliadores || [];
            let isEditing = avaliacao.id == this.state.openEditAvaliadores?.id ? true : false;

            return (
                <td
                    colSpan={2}
                    className={`${isEditing ? 'editing' : ''}`}
                    style={{ padding: "3px 10px" }}
                >
                    <div style={{ ...EssentialStyle.rowSpaceBetween, gap: 5 }}>

                        <div style={{ ...EssentialStyle.columnCenter }}>
                            {avaliadores.length > 0 && (
                                <TooManyAvatars
                                    participantsTotal={avaliadores.length}
                                    participantsVisiveis={3}
                                    arrayParticipants={avaliadores}
                                />
                            )}
                        </div>

                        {/* {avaliacao.status != 3 && */}
                        <DefaultButton
                            leftIcon={<FontAwesomeIcon icon={faEdit} />}
                            style={{ padding: '3px 6px' }}
                            color={Colors.success}
                            className="button-edit-avaliadores"
                            onClick={() => {
                                if (!isEditing) {
                                    this.setState({
                                        openEditAvaliadores: {
                                            ...avaliacao,
                                            avaliadores: DataHelper.formatSelectData(avaliacao.avaliadores, 'id', 'nome'),
                                            publicadores: DataHelper.formatSelectData(avaliacao.publicadores, 'id', 'nome'),
                                        },
                                        openShareModal: false,
                                        openHistoricoModal: false
                                    })
                                } else {
                                    this.setState({ openEditAvaliadores: false })
                                }
                            }}
                        />
                        {/* } */}
                    </div>
                </td>
            )
        }

        const renderCelPublicadores = (avaliacao) => {

            let publicadores = avaliacao.publicadores || [];
            let isEditing = avaliacao.id == this.state.openEditAvaliadores?.id ? true : false;

            return (
                <td
                    colSpan={2}
                    className={`${isEditing ? 'editing' : ''}`}
                    style={{ padding: "3px 10px" }}
                >
                    <div style={{ ...EssentialStyle.rowSpaceBetween, gap: 5 }}>
                        <div style={{ ...EssentialStyle.columnCenter }}>
                            {publicadores.length > 0 &&
                                <TooManyAvatars
                                    participantsTotal={publicadores.length}
                                    participantsVisiveis={3}
                                    arrayParticipants={publicadores}
                                />
                            }
                        </div>

                        {/* {avaliacao.status != 3 && */}
                        <DefaultButton
                            leftIcon={<FontAwesomeIcon icon={faEdit} />}
                            style={{ padding: '3px 6px' }}
                            color={Colors.success}
                            className="button-edit-avaliadores"
                            onClick={() => {
                                if (!isEditing) {
                                    this.setState({
                                        openEditAvaliadores: {
                                            ...avaliacao,
                                            avaliadores: DataHelper.formatSelectData(avaliacao.avaliadores, 'id', 'nome'),
                                            publicadores: DataHelper.formatSelectData(avaliacao.publicadores, 'id', 'nome'),
                                        },
                                        openShareModal: false,
                                        openHistoricoModal: false
                                    })
                                } else {
                                    this.setState({ openEditAvaliadores: false })
                                }
                            }}
                        />
                        {/* } */}
                    </div>
                </td>
            )
        }

        const renderCelStatus = (avaliacao) => {
            return (
                <td colSpan={2}>
                    {DiagnosticoHelper.renderStatusAvaliacao(avaliacao.status, avaliacao.isAtrasada, avaliacao, this.props.lang, this.props.t)}
                </td>
            )
        }

        const renderCelAcoes = (avaliacao) => {

            return (
                <td colSpan={1}>
                    <div style={{ ...EssentialStyle.rowFlexCenter, gap: 10, flexWrap: 'wrap' }}>
                        <CustomTooltip
                            tooltip={this.message().enviarParaTodosAvaliadores}
                            placement={"auto"}
                        >
                            <FontAwesomeIcon
                                role="button"
                                className={`hoverScale${!this.state.gradeAvaliacao?.canEnviarAvaliacao || (avaliacao.avaliadores?.length == 0) ? '-disabled' : ''}`}
                                icon={faPaperPlane}
                                onClick={() => {
                                    if (avaliacao.avaliadores?.length == 0) {
                                        return;
                                    }

                                    this.sendMailAvaliacao(avaliacao);
                                }}
                            />
                        </CustomTooltip>
                        <CustomTooltip
                            tooltip={avaliacao.avaliadores?.find(a => a.id == SessionHelper.getUser().id) ? this.message().abrirAvalicao : this.message().abrirModalCompartilhamento}
                            placement={"auto"}
                        >
                            <FontAwesomeIcon
                                role="button"
                                className={`hoverScale${!this.state.gradeAvaliacao?.canEnviarAvaliacao || avaliacao.avaliadores?.length == 0 ? '-disabled' : ''}`}
                                icon={avaliacao?.avaliadores?.find(a => a.id == SessionHelper.getUser()?.id) ? faEye : faLink}
                                onClick={() => {
                                    if (avaliacao.avaliadores?.length == 0) {
                                        return;
                                    }

                                    let avaliador = avaliacao?.avaliadores?.find(a => a.id == SessionHelper.getUser()?.id)
                                    if(avaliador)
                                    {
                                        this.openAvaliacao(avaliacao, avaliador);
                                        return;
                                    }
                                    
                                    this.setState({
                                        openShareModal: avaliacao,
                                        openEditAvaliadores: false,
                                        openHistoricoModal: false
                                    })
                                }}
                            />
                        </CustomTooltip>
                        <CustomTooltip
                            tooltip={this.message().abrir_historico}
                            placement={"auto"}
                        >
                            <FontAwesomeIcon
                                role="button"
                                className={`hoverScale${avaliacao.status == 0 ? '-disabled' : ''}`}
                                icon={faClockRotateLeft}
                                onClick={() => {
                                    if (avaliacao.status == 0) {
                                        return;
                                    }

                                    this.setState({
                                        openHistoricoModal: avaliacao,
                                        openEditAvaliadores: false,
                                        openShareModal: false
                                    })
                                }}
                            />
                        </CustomTooltip>
                        <CustomTooltip
                            tooltip={this.message().deletar}
                            placement={"auto"}
                        >
                            <FontAwesomeIcon
                                className="hoverScale"
                                icon={faTrash}
                                style={{ color: Colors.error }}
                                onClick={() => { this.deleteAvaliacao(avaliacao) }}
                            />
                        </CustomTooltip>
                    </div>
                </td>
            )
        }

        let grade = this.state.gradeAvaliacao.grade || [];

        if (this.state.loadingGradeAvaliacao) return null;
        return (
            <table className="table-avaliacoes">
                <thead>
                    <tr>
                        <th colSpan={2}>{this.message().tableTitleQuestionario}</th>
                        <th colSpan={2}>{this.message().tableTitleTiposAvaliacao}</th>
                        <th colSpan={3}>{this.message().tableTitleEmpresas}</th>
                        <th colSpan={2}>{this.message().tableTitleAvaliadores}</th>
                        <th colSpan={2}>{this.message().bannerLabelPublicadores}</th>
                        <th colSpan={2}>{this.message().tableTitleStatus}</th>
                        <th colSpan={1}>{this.message().tableTitleAcoes}</th>
                    </tr>
                </thead>
                <tbody>
                    {grade?.map((questionario, index) => {
                        return questionario.tipos?.map((tipo, indexTipo) => {
                            return tipo.avaliacoes?.map((avaliacao, indexAvaliacao) => {
                                return (
                                    <tr key={`avaliacao-${avaliacao.id}`}>
                                        {indexTipo === 0 && indexAvaliacao === 0 && (
                                            <td rowSpan={questionario.rowSpan} colSpan={2}>{questionario.nome}</td>
                                        )}

                                        {indexAvaliacao === 0 && (
                                            <td rowSpan={tipo.rowSpan} colSpan={2}>{DiagnosticoHelper.renderTipoAvaliacao(tipo.tipo, this.props.t)}</td>
                                        )}

                                        <td colSpan={3}>{avaliacao.empresaAvaliada.nome_fantasia}</td>

                                        {renderCelResponsaveis(avaliacao)}
                                        {renderCelPublicadores(avaliacao)}
                                        {renderCelStatus(avaliacao)}
                                        {renderCelAcoes(avaliacao)}
                                    </tr>
                                )
                            })
                        })
                    })}
                </tbody>
            </table>
        )
    }

    renderEditingAvaliadores() {
        return(
            <ModalAvaliadores 
                avaliadores={this.state.avaliadores}
                openEditAvaliadores={this.state.openEditAvaliadores} 
                onClose={() => this.setState({ openEditAvaliadores: false })}
                onSave={() => this.saveAvaliacao()} 
                changeAvaliacao={(avaliacao) => {this.setState({ openEditAvaliadores: avaliacao })}}
                loading={this.state.loadingBannerAvaliacao}
                canEdit={this.state.canEdit}
                lang={this.props.lang}
            />
        )
    }

    renderLoading() {
        return (
            <div style={{ ...EssentialStyle.rowFlexCenter, width: '100%', height: '100%' }}>
                <DefaultLoader />
            </div>
        )
    }

    render() {

        if (this.state.loadingGradeAvaliacao) return this.renderLoading();
        return (
            <div className="GradeAvaliacao">
                <div className="top-avaliacoes">
                    {this.renderAddAvaliacoes()}
                    <div className="top-avaliacoes-right">
                        {this.renderOpenResumoAvaliacao()}
                        {this.renderSendAvaliacoes()}
                    </div>
                </div>
                {this.renderBannerAddAvaliacao()}
                {this.renderBannerResumoAvaliacao()}
                {this.renderTabelaAvalicao()}
                {this.state.openEditAvaliadores && this.renderEditingAvaliadores()}
                {this.state.openShareModal && this.renderShareModal()}
                {this.state.openHistoricoModal && this.renderHistoricoModal()}
            </div>
        )
    }
}

export default withTranslation()(GradeAvaliacao);