import React, { Component } from 'react';
import './UserTooltip.css';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import LayoutHelper from '../../../helper/LayoutHelper';
import UserCard from './UserCard';
import { Overlay, Popover } from 'react-bootstrap';

export default class UserTooltip extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false
        }
        this.ref = React.createRef();
        this.timeout = null;
    }

    componentWillUnmount() {
        this.handleMouseLeave();
    }

    handleMouseEnter = () => {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
            this.setState({ show: true });
            if (document.documentElement.scrollHeight <= window.innerHeight) {
                document.body.style.overflow = 'hidden';
            }
        }, 500);
    }

    handleMouseLeave = () => {
        clearTimeout(this.timeout);
        this.setState({ show: false });
        if (document.documentElement.scrollHeight <= window.innerHeight) {
            document.body.style.overflow = 'auto';
        }
    }

    renderTooltip = () => {
        const delay = { show: 500, hide: 200 };
        let id = `tooltip-${Math.floor(Math.random() * 1000000)}`;
        return (
            <Overlay 
                id={`${id}-id`} 
                target={this.ref.current} 
                show={this.state.show} 
                placement='bottom' 
                flip={true}
                delay={delay}
                offset={[0, 0]}
            >
                <Popover className='user-tooltip'>
                    <Popover.Body>
                        {
                            this.state.show ? this.props.onlyMail ?
                                <UserCard email={this.props.email} user={this.props.user}/> 
                            : 
                                <UserCard id={this.props.id} user={this.props.user}/>
                            : 
                                null
                        }
                    </Popover.Body>
                </Popover>
            </Overlay>
        );
    }

    render() {


        return (
            <span
                ref={this.ref}
                onMouseEnter={this.handleMouseEnter}
                onMouseLeave={this.handleMouseLeave}
                onClick={(e) => e.stopPropagation()}
                style={{...this.props.style, position: 'relative'}}
            >
                {this.props.children}
                {this.renderTooltip()}
            </span>
        );
    }
}