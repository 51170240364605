import React from 'react';
import Sig from '../../../../api/Sig';
import EssentialStyle from '../../../../style/EssentialStyle';
import DefaultLoader from '../../../tools/DefaultLoader';
import Colors from '../../../../constants/Colors';
import { Collapse } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import SessionHelper from '../../../../helper/SessionHelper';
import DefaultButton from '../../../tools/DefaultButton';
import RelatorioAtaStatus from './RelatorioAtaStatus';
import RelatorioAtaTarefas from './RelatorioAtaTarefas';
import RelatorioAtaLista from './RelatorioAtaLista';

export default class AtaRelatorio extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            atas: [],
            filteredElements: [],
            filter: {view: 'atas', values: []},
            loading: false,
            loadingFilter: false,
            view: 'atas',
            displayContent: true,
            activeTipoVisaoTab: 'lista',
            tipos: [],
        };
    }


    async componentDidUpdate(prevProps) {

        if (prevProps.filter != this.props.filter) {
            await this.loadAtas();
        }

    }

    async loadAtas() {

        let view = 'atas';

        this.setState({ loading: true, view});

        let { atas, tipos } = await Sig.request('POST', 'ataReuniao/relatorio', {filter: JSON.stringify(this.props.filter)});

        this.setState({
            tipos: tipos || [],
            atas: atas || [],
            filteredElements: [...atas] || [], 
            loading: false,
        });
    }

    filterElements = async () => {
        if(this.state.filter.view === 'atas') {
            var atas = await this.filterAtas();
            this.setState({  filteredElements: [...atas], view: this.state.filter.view, loadingFilter: false });
        }
        if(this.state.filter.view === 'tarefas') {
            var tarefas = await this.filterTarefas(this.state.atas.reduce((acc, ata) => {
                if(ata.topicos && ata.topicos.length > 0) {
                    acc = [...acc, ...ata.topicos.reduce((acc2, topico) => {
                        if(topico.tarefas && topico.tarefas.length > 0) {
                            acc2 = [...acc2, ...topico.tarefas];
                        }
                        return acc2;
                    }, [])];
                }
                return acc;
            }, []));
            this.setState({ loadingFilter: false, view: this.state.filter.view, filteredElements: tarefas });
        }
    }
    
    filterTarefas = async (tarefas) => {
        return new Promise((resolve, reject) => {
            if(this.state.filter.values && this.state.filter.values.length > 0) {
                tarefas = tarefas.filter(tarefa => {    
                    return !this.state.filter.values.some((filter) => {
                        switch(filter.field) {
                            case 'status_text':
                                if(filter.value == 'Atrasada') {
                                    if((tarefa.status_text == 'Em Desenvolvimento' || tarefa.status_text == 'Não Iniciada') && tarefa.atrasada) {
                                        return false;
                                    } else 
                                        return true;
                                } else 
                                    return !(tarefa.status_text == filter.value)
                            case 'participante':
                                return !(tarefa.responsavel?.id == filter.value)
                            case 'ata':
                                return !(tarefa.id_ata_reuniao == filter.value)
                            default:
                                return true;
                        }
                    })
                })
            }
            resolve([...tarefas]);
        })
    }

    filterAtas = async () => {
        return new Promise((resolve, reject) => {
            var atas = [...this.state.atas];
            if(this.state.filter.values && this.state.filter.values.length > 0) {
                atas = [...atas].filter(ata => {
                    return !this.state.filter.values.some((filter) => {
                        var field;

                        switch(filter.field) {
                            case 'tipoName':
                                field = ata.tipoName;
                                break;
                            case 'status':
                                field = ata.status;
                            default:
                                field = ata[filter.field];
                                break;
                        }

                        if(field === undefined || field === null) return true;

                        return !(field == filter.value)
                    })
                })
            }
            resolve([...atas]);
        })
    }

    filter = (filter) => {
        this.setState({ filter, loadingFilter: true }, () => this.filterElements());   
    }   

    renderList = () => {
        return (
            <RelatorioAtaLista elements={this.state.filteredElements} loading={this.state.loadingFilter} isSmallScreen={this.props.isSmallScreen} filter={this.state.filter} view={this.state.view} atas={this.state.atas}/>
        )
    }

    renderTable = () => {
        return null;
    }

    renderTimeline = () => {
        return null;
    }

    handleTipoVisaoTabs = (tab) => {
        this.setState({ activeTipoVisaoTab: tab.name });
    }

    handleTimelineTabChange = (tab) => {
        this.setState({ activeTabTimeline: tab.name });
    }

    renderContent = () => {

        const tabs = [
            { name: 'lista', title: 'Lista' },
            { name: 'tabela', title: 'Tabela' },
            { name: 'timeline', title: 'Timeline' },
        ];

        const timelineTabs = [
            { name: "ano", title: "Ano" },
            { name: 'mes', title: 'Mês' },
            { name: 'semana', title: 'Semana' },
        ];

        return(
        <>
            <div style={{...EssentialStyle.rowSpaceBetween, height: this.state.activeTipoVisaoTab == 'timeline' && this.props.isSmallScreen ? 90 : 40, minHeight: 40, backgroundColor: SessionHelper.getBackgroundColor(), width: "100%", position: "sticky", left: 0, flexWrap: "wrap", gap: 10}}>
                {/* {this.state.displayContent ? 
                    <Tabs 
                        isSmallScreen={this.props.isSmallScreen}
                        tabs={tabs}
                        activeTab={this.state.activeTipoVisaoTab}
                        onTabChange={this.handleTipoVisaoTabs}
                        style={{placeSelf: "center", marginTop: 0}}
                        tabStyle={{width: this.props.isSmallScreen ? '28vw' : 90, maxWidth: 150, height: 30, padding: 0, alignItems: "center", justifyContent: "center"}}
                    />
                : 
                    <div></div>
                } */}
                <div style={{ ...EssentialStyle.rowFlexCenter, gap: 10, marginRight: 10, position: 'sticky', left: "calc(100%)"}}>
                    {/* {this.state.activeTipoVisaoTab == 'timeline' && this.state.displayContent &&
                        <Tabs 
                            isSmallScreen={this.props.isSmallScreen}
                            tabs={timelineTabs}
                            activeTab={this.state.activeTabTimeline}
                            onTabChange={this.handleTimelineTabChange}
                            style={{placeSelf: "right", marginTop: 0}}
                            tabStyle={{width: this.props.isSmallScreen ? '28vw' : 90, maxWidth: 150, height: 30, padding: 0, alignItems: "center", justifyContent: "center"}}
                        />
                    } */}
                    <DefaultButton 
                        leftIcon={!this.state.displayContent ? <FontAwesomeIcon icon={faChevronDown} /> : <FontAwesomeIcon icon={faChevronUp} />}
                        color={"transparent"}
                        textColor={Colors.dark}
                        onClick={() => this.setState({ displayContent: !this.state.displayContent })}
                        style={{ width: 30, height: 30, alignItems: "center", justifyContent: "center", padding: 0 }}
                    />
                </div>
            </div>
            <div style={{...EssentialStyle.columnStart, borderRadius: 8, maxHeight: this.state.displayContent ? "unset" : 40 , flex: 1, backgroundColor: SessionHelper.getBackgroundColor(), transition: "all 0.5s ease"}}>
                <Collapse in={this.state.displayContent} style={{transition: "all 0.5s ease", height: "calc(100% - 40px)", maxHeight: "calc(100% - 40px)", width: "100%"}}>
                    <div>
                        {!this.state.loadingFilter ? 
                            <div>
                                {
                                this.state.activeTipoVisaoTab == 'lista' ? 
                                    this.renderList() : null
                                }
                            </div>
                        :   
                            <div style={{ ...EssentialStyle.columnCenter, padding: 10, width: '100%', marginTop: 10, height: "calc(100% - 40px)" }}>
                                <DefaultLoader />
                            </div>
                        }
                    </div>
                </Collapse>
            </div>
        </>
        )
    }

    render() {
        return (
        <div>
            <div className={"relatorio-atas"} style={{...EssentialStyle.columnStart, minHeight: `calc(100vh - ${this.props.headerSize}px)`, width: "100%", gap: 2}}>
                {this.state.loading ? 
                    <div style={{ ...EssentialStyle.rowFlexCenter, padding: 10, width: '100%', minHeight: `calc(100vh - ${this.props.headerSize}px)` }}>
                        <DefaultLoader />
                    </div> 
                : 
                <>
                    <div style={{maxHeight:"100%", width: "100%"}}>
                        <RelatorioAtaStatus atas={this.state.atas} tipos={this.state.tipos} isSmallScreen={this.props.isSmallScreen} filterCallback={this.filter} filter={this.state.filter} view={this.state.view}/>
                        <RelatorioAtaTarefas atas={this.state.atas} isSmallScreen={this.props.isSmallScreen} filterCallback={this.filter} filter={this.state.filter} view={this.state.view}/>
                        {this.renderContent()}
                    </div>
                </>
                }
            </div>
        </div>
        );
    }
}