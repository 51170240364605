import React from "react";
import '../../../planoAcao/PlanoAcaoRelatorio/PlanoAcaoRelatorio.css';

import EssentialStyle from '../../../../../style/EssentialStyle';
import DefaultLoader from '../../../../tools/DefaultLoader';
import { Collapse } from "react-bootstrap";
import Colors from "../../../../../constants/Colors";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import SessionHelper from '../../../../../helper/SessionHelper';
import UserAvatar from '../../../../tools/UserAvatar';
import ChartHelper from '../../../../../helper/inicio/ChartHelper';
import Tabs from "../../../../tools/Tabs";
import Sig from "../../../../../api/Sig";
import { toast } from "react-toastify";
import IdiomaHelper from "../../../../../helper/IdiomaHelper";
import EllipsisText from "../../../../tools/EllipsisText";

export default class RelatorioDiagnosticoColunas extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            page: 1,
            hasMore: false,
            loading: true,
            requesting: false,
            filter: {},
            firstLoad: true,
            partialLoading: false,
            expanded: false,
            status: [
                "Não Iniciado",
                "Em Desenvolvimento",
                "Concluído",
                "Atrasado",
                "Cancelado",
            ],
            statusEtapa: [
                "Não Iniciada",
                "Em Desenvolvimento",
                "Concluída",
                "Atrasada",
                "Cancelada",
            ],
            activeTab: 'planos',
            loading: true,
            filteredFields: [],
        }
        this.abortController = new AbortController();
    }

    async componentDidMount() {
        // if (document.getElementById('relatorio-nodes-paginated')) {
        //     document.getElementById('relatorio-nodes-paginated').addEventListener('scroll', this.handleScroll);
        // }

        this.loadData();
    }

    async componentDidUpdate(prevProps) {
        // if (document.getElementById('relatorio-nodes-paginated')) {
        //     let listPlanosRelatorio = document.getElementById('relatorio-nodes-paginated');

        //     if (listPlanosRelatorio) {
        //         listPlanosRelatorio.removeEventListener('scroll', this.handleScroll);
        //         listPlanosRelatorio.addEventListener('scroll', this.handleScroll);
        //     }
        // }

        if (prevProps.filter !== this.props.filter) {
            let prevWithoutIds = { ...prevProps.filter };
            let withoutIds = { ...this.props.filter };

            delete prevWithoutIds.ids_planos_nodes;
            delete prevWithoutIds.ids_etapas_nodes;
            delete withoutIds.ids_planos_nodes;
            delete withoutIds.ids_etapas_nodes;

            if (JSON.stringify(prevWithoutIds) !== JSON.stringify(withoutIds)) {
                let filter = { ...this.props.filter };
                delete filter.ids_planos_nodes;
                delete filter.ids_etapas_nodes;

                this.props.setFilterCallback(filter);
                
                if(this.state.requesting) {
                    this.abortController.abort();
                    this.abortController = new AbortController();
                }
    
                this.setState({ 
                    data: [],
                    loading: true,
                    requesting: false,
                    firstLoad: true,
                    filteredFields: []
                }, () => {
                    this.loadData();
                });
            }
        }
    }

    componentWillUnmount() {
        // if (document.getElementById('relatorio-nodes-paginated')) {
        //     document.getElementById('relatorio-nodes-paginated').removeEventListener('scroll', this.handleScroll);
        // }

        this.abortController.abort();
    }

    handleScroll = () => {
        const scrollableDiv = document.getElementById('relatorio-nodes-paginated');

        if (scrollableDiv) {
            if (scrollableDiv.scrollTop + scrollableDiv.clientHeight >= scrollableDiv.scrollHeight) {
                if (!this.state.loading && this.state.hasMore) {
                    this.loadData(this.state.page + 1);
                }
            }
        }
    }

    async loadData(page = 1) {
        let timeout = null;

        if (this.state.requesting) return;
        this.setState({ requesting: true, partialLoading: true });

        let filter = { ...this.props.filter };
        delete filter.ids_planos_nodes;
        delete filter.ids_etapas_nodes;

        var req = await Sig.request(
            'POST', 
            'planoAcao/listColunasDiagnostico', 
            { 
                page, 
                limit: 10, 
                filter: JSON.stringify(filter),
                id_periodo_avaliacao: this.props.id_periodo_avaliacao || 0,
                lang: IdiomaHelper.getIdioma()
            },
            Sig.getDefaultContentType(),
            Sig.getDefaultTransformTo(),
            this.abortController.signal
        );

        if (this.state.firstLoad) {
            this.setState({ loading: true, firstLoad: false });
        } else {
            this.setState({ loading: true });
        }

        if (timeout) {
            clearTimeout(timeout);
        }

        if (req.status != 200) {
            return;
        }

        if (req?.data?.length < 10)
            this.setState({ hasMore: false });
        else
            this.setState({ hasMore: true });

        this.setState(prevState => ({
            data: page === 1 ? req.data : [...prevState.data, ...req.data],
            page,
            loading: false
        }));

        this.setState({ requesting: false });
    }

    calculaContadores = (node) => {
        return { planos: node.planosCounter, etapas: node.etapasCounter };
    }

    isFiltered = (field) => {
        if(!this.state.filteredFields) return false;
        if (this.state.filteredFields.includes(field)) return true;
        return false;
    }

    filterField = (field, ids = [], tipo) => {
        if(!ids?.length) return;
        
        let filteredFields = [];
        if(!this.isFiltered(field)) filteredFields = [ field ];

        this.setState({ filteredFields }, () => {
            let filter = { ...this.props.filter };

            if(tipo == "etapas") {
                filter = { ...this.props.filter, ids_etapas_nodes: filteredFields.length ? ids : [], ids_planos_nodes: [] };
            } else {
                filter = { ...this.props.filter, ids_planos_nodes: filteredFields.length ? ids : [], ids_etapas_nodes: [] };
            }

            this.props.setFilterCallback(filter);
        });
    }

    getCursor = (ids) => {
        if (ids?.length) return "pointer";
        return "not-allowed";
    }

    renderBarra(node, contadores, tipo) {
        if (tipo != "etapas" && tipo != "planos" || !contadores) return null;
        const status = tipo == "etapas" ? this.state.statusEtapa : this.state.status;

        const statusCounts = contadores[tipo];

        const total = contadores[tipo].total?.length;

        const renderDiv = (status, index) => {
            return (
                <div
                    key={status}
                    onClick={() => {
                        this.filterField(`${node.id}-${status}-${index}`, statusCounts[status], tipo);
                    }}
                    style={{
                        ...EssentialStyle.rowFlexStart,
                        height: this.isFiltered(`${node.id}-${status}-${index}`) ? 35 : 32,
                        width: `${(statusCounts[status]?.length / total) * 100}%`,
                        minWidth: statusCounts[status]?.length > 0 ? 15 : 0,
                        backgroundColor: ChartHelper.getColor(status),
                        transition: 'height 0.5s ease, boxShadow 0.5s ease',
                        overflow: 'visible',
                        cursor: this.getCursor(statusCounts[status]),
                        filter: this.isFiltered(`${node.id}-${status}-${index}`) ? "brightness(0.9)" : "unset",
                        boxShadow: this.isFiltered(`${node.id}-${status}-${index}`) ? `0px 0px 10px 0px ${ChartHelper.getColor(status)}` : "none",
                    }}
                >
                    <span style={{
                        color: 'white',
                        fontWeight: '500',
                        marginLeft: 5,
                        opacity: 1,
                        transition: 'opacity 0.3s ease-in-out'
                    }}>
                        {statusCounts[status]?.length}
                    </span>
                </div>
            );
        }

        return (
            <div style={{ ...EssentialStyle.rowSpaceBetween, width: '100%', height: 35, borderRadius: 5, marginLeft: 5, overflow: 'hidden' }}>
                {status.map(renderDiv)}
            </div>
        );
    }

    renderColaboradorRow = (node, index, depth = 0) => {
        let filhos = this.state.data.filter(n => n.id_node_pai == node.id);

        let contadores = this.calculaContadores(node);
        let etapas = node?.etapasCounter?.total;
        let planos = node?.planosCounter?.total;

        return (
            <>
                
                <div 
                    key={"node-" + index} 
                    style={{ 
                        ...EssentialStyle.rowFlexCenter, borderBottom: `1px solid ${Colors.homePage.extraLightGrey}`, 
                        width: "100%", height: 40, minHeight: 40,
                        backgroundColor: depth == 0 ? "unset" : Colors.homePage.extraLightGrey,
                        filter: depth == 0 ? "unset" : `brightness(${0.8+(depth * 0.075)})`
                    }}
                >
                    <div style={{ fontSize: 16, fontWeight: 500, color: Colors.homePage.darkGrey, width: this.props.isSmallScreen ? "45%" : "35%", height: "100%", alignContent: "center", paddingLeft: 10 + (5*depth) }}>
                        <EllipsisText text={node?.descricao} />
                    </div>
                    {!this.props.isSmallScreen || this.state.activeTab === "planos" ?
                        <div style={{ ...EssentialStyle.rowFlexStart, fontSize: 16, fontWeight: 500, color: Colors.homePage.darkGrey, width: this.props.isSmallScreen ? "55%" : "25%", height: "100%", alignContent: "center" }}>
                            {planos?.length ?
                                <>
                                    <div style={{ ...EssentialStyle.rowFlexStart, height: "100%", width: "calc(100% - 45px)" }}>
                                        {this.renderBarra(node, contadores, "planos")}
                                    </div>
                                    <div
                                        onClick={() => {
                                            this.filterField(`${node.id}-geral-planos`, planos, "planos");
                                        }}
                                        style={{
                                            ...EssentialStyle.rowFlexCenter, cursor: this.getCursor(planos),
                                            height: 30, width: 40, marginLeft: 5,
                                            borderLeft: `2px solid ${Colors.homePage.extraLightGrey}`,
                                            borderRadius: this.isFiltered(`${node.id}-geral-planos`) ? 5 : 0,
                                            backgroundColor: this.isFiltered(`${node.id}-geral-planos`) ? Colors.homePage.extraLightGrey : "transparent"
                                        }}
                                        >
                                        {planos?.length}
                                    </div>
                                </>

                                :
                                <div style={{ ...EssentialStyle.rowFlexCenter, height: "100%", width: "calc(100% - 45px)" }}>
                                    -
                                </div>
                            }
                        </div>
                        : null
                    }
                    {!this.props.isSmallScreen || this.state.activeTab === "etapas" ?
                        <>
                            <div style={{ ...EssentialStyle.rowFlexStart, fontSize: 16, fontWeight: 500, color: Colors.homePage.darkGrey, width: this.props.isSmallScreen ? "35%" : "25%", height: "100%", alignContent: "center" }}>
                                {etapas?.length ?
                                    <>
                                        <div style={{ ...EssentialStyle.rowFlexStart, height: "100%", width: "calc(100% - 45px)" }}>
                                            {this.renderBarra(node, contadores, "etapas")}
                                        </div>
                                        <div
                                            onClick={() => {
                                                this.filterField(`${node.id}-geral-etapas`, etapas, "etapas");
                                            }}
                                            style={{
                                                ...EssentialStyle.rowFlexCenter, cursor: this.getCursor(etapas),
                                                height: 30, width: 40, marginLeft: 5,
                                                borderLeft: `2px solid ${Colors.homePage.extraLightGrey}`,
                                                borderRadius: this.isFiltered(`${node.id}-geral-etapas`) ? 5 : 0,
                                                backgroundColor: this.isFiltered(`${node.id}-geral-etapas`) ? Colors.homePage.extraLightGrey : "transparent"
                                            }}
                                            >
                                            {etapas?.length}
                                        </div>
                                    </>
                                    :
                                    <div style={{ ...EssentialStyle.rowFlexCenter, height: "100%", width: "calc(100% - 45px)" }}>
                                        -
                                    </div>
                                }
                            </div>
                            <div style={{ fontSize: 16, fontWeight: 500, color: Colors.homePage.darkGrey, width: this.props.isSmallScreen ? "20%" : "15%", height: "100%", alignContent: "center", textAlign: "center", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>
                                {node.atrasoMedio && node.atrasoMedio > 0 ? Math.round(node.atrasoMedio * 10) / 10 < 1.5 ? "1 dia" : Math.round(node.atrasoMedio * 10) / 10 + " dias" : "Em dia"}
                            </div>
                        </> : null
                    }
                </div>
                
                {filhos.length > 0 && filhos.map((nodeChild, indexChild) => {
                    return this.renderColaboradorRow(nodeChild, index+"-"+indexChild, depth + 1)
                })}
            </>
        );
    }

    renderEmpty = () => {
        return (
            <div style={{ ...EssentialStyle.columnCenter, padding: 10, width: '100%', marginTop: 10, height: 300 }}>
                <h2 style={{ color: Colors.homePage.grey, fontSize: 20, fontWeight: 500, textAlign: 'center' }}>Nenhum plano de ação encontrado</h2>
            </div>
        );
    }
    
    renderList() {
        if (this?.state?.data?.length === 0 && !this.state.loading) return this.renderEmpty();

        var rootNodes = this.state.data?.filter(node => node.id_node_pai == null || this.state.data?.find(n => n.id == node.id_node_pai) == null);

        return (
            <div style={{ width: "100%" }}>
                {rootNodes?.map((item, index) => this.renderColaboradorRow(item, index))}
                {this.state.requesting && <div style={{ ...EssentialStyle.columnCenter, padding: 10, width: '100%', marginTop: 10 }}><DefaultLoader /></div>}
                {this.state.isSmallScreen && <div style={{ height: '7rem', width: '100%', marginTop: 10 }} />}
            </div>
        );
    }

    renderNodes = () => {
        const tabs = [
            { name: 'planos', title: 'Planos de Ação' },
            { name: 'etapas', title: 'Etapas' },
        ];

        return (
            <div className="pa-nodes-component" style={{ ...EssentialStyle.columnCenter, width: '100%', backgroundColor: SessionHelper.getBackgroundColor(), paddingTop: "3px", position: 'sticky', left: 0 }}>
                <div style={{ ...EssentialStyle.columnCenter, minHeight: 35, boxShadow: `0px 1px 10px 0px  rgba(50, 50, 50, 0.1)`, borderRadius: 8, backgroundColor: Colors.white, width: '100%', overflow: "hidden" }}>

                    <div style={{ ...EssentialStyle.rowFlexCenter, cursor: 'pointer', minHeight: 35, gap: 8 }} onClick={() => this.setState({ expanded: !this.state.expanded })} >
                        {this.state.expanded ?
                            <FontAwesomeIcon icon={faChevronUp} /> :
                            <FontAwesomeIcon icon={faChevronDown} />
                        }
                        {
                            this.state.expanded ? 'Ocultar Classificação' : 'Classificação'
                        }
                    </div>

                    <Collapse in={this.state.expanded} style={{ width: '100%', transition: "all 0.2s ease" }}>
                        <div style={{ ...(this.props.isSmallScreen ? EssentialStyle.columnCenter : EssentialStyle.rowFlexCenter), width: '100%' }}>
                            <div style={{ ...EssentialStyle.columnStart, width: '100%', height: 300, paddingTop: 0, gap: 15 }}>
                                {this.state.loading ?
                                    <div style={{ ...EssentialStyle.columnCenter, padding: 10, width: '100%', height: "100%" }}>
                                        <DefaultLoader />
                                    </div>
                                    :
                                    <div style={{ ...EssentialStyle.columnStart, paddingBottom: 5, width: "100%" }}>
                                        {this.props.isSmallScreen &&
                                            <Tabs
                                                isSmallScreen={this.props.isSmallScreen}
                                                tabs={tabs}
                                                activeTab={this.state.activeTab}
                                                onTabChange={this.handleTabChange}
                                                tabStyle={{ width: this.props.isSmallScreen ? '40vw' : 90, maxWidth: 150 }}
                                                style={{ placeSelf: "center" }}
                                            />
                                        }
                                        <div style={{ ...EssentialStyle.rowFlexCenter, height: 40, borderBottom: `1px solid ${Colors.homePage.extraLightGrey}`, width: "100%", textAlign: 'center' }}>
                                            <div style={{ fontSize: 16, fontWeight: 500, color: Colors.homePage.grey, width: this.props.isSmallScreen ? "45%" : "35%", height: "100%", alignContent: "center", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>Classificação</div>
                                            {!this.props.isSmallScreen || this.state.activeTab === "planos" ?
                                                <div style={{ fontSize: 16, fontWeight: 500, color: Colors.homePage.grey, width: this.props.isSmallScreen ? "55%" : "25%", height: "100%", alignContent: "center", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>Planos de Ação</div>
                                                : null
                                            }
                                            {!this.props.isSmallScreen || this.state.activeTab === "etapas" ?
                                                <>
                                                    <div style={{ fontSize: 16, fontWeight: 500, color: Colors.homePage.grey, width: this.props.isSmallScreen ? "35%" : "25%", height: "100%", alignContent: "center", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>Etapas</div>
                                                    <div style={{ fontSize: 16, fontWeight: 500, color: Colors.homePage.grey, width: this.props.isSmallScreen ? "20%" : "15%", height: "100%", alignContent: "center", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>Atraso (média)</div>
                                                </> : null
                                            }
                                        </div>
                                        <div id={'relatorio-nodes-paginated'} style={{ ...EssentialStyle.columnStart, width: "100%", overflowY: "auto", maxHeight: 250, height: "auto" }}>
                                            {this.renderList()}
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </Collapse>
                </div>
            </div>
        );
    }

    render() {
        return this.renderNodes();
    }
}