import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import './RelatorioDividerItem.css';
import Colors from "../../../../../../constants/Colors";

export default class RelatorioDividerItem extends React.Component {

    render() {
        return (
            <div className="RelatorioDividerItem" style={{ boxShadow: Colors.boxShadow }}>
                 <span style={{flex: 1, height: 2, backgroundColor: '#c3c3c3'}}></span>
                <FontAwesomeIcon icon={faCircle} style={{fontSize: 5, margin: '0px 5px'}}/>
                <span style={{flex: 1, height: 2, backgroundColor: '#c3c3c3'}}></span>
            </div>
        );
    }
}