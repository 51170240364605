import React from "react";
import './AtaReuniaoCard.css';

import EssentialStyle from "../../../../style/EssentialStyle";
import TooManyTags from "../../../tools/TooManyTags";
import Tag from "../../../tools/Tag";
import UserAvatar from "../../../tools/UserAvatar";
import Colors from "../../../../constants/Colors";
import TooManyAvatars from "../../../tools/TooManyAvatars";
import CustomTooltip from "../../../tools/CustomTooltip";
import ProgressBarCustom from "../../../tools/ProgressBar/ProgressBarCustom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEquals, faChevronUp, faChevronDown, faCalendar, faMapMarkerAlt, faExclamationTriangle, faLock, faEllipsis } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment';
import LayoutHelper from "../../../../helper/LayoutHelper";
import Sig from "../../../../api/Sig";
import SessionHelper from "../../../../helper/SessionHelper";
import { Dropdown } from "react-bootstrap";
import ReactDOM from 'react-dom';
import { toast } from "react-toastify";
import AtaReuniaoStatus from "../AtaReuniaoStatus";

export default class AtaReuniaoCard extends React.Component {
    state = {
        loading: true,
        progressBarHovered: false,
        isSmallScreen: LayoutHelper.isSmallScreen()
    }

    async componentDidMount() {
        await this.loadData();
    }


    async loadData() {
        this.setState({ loading: true });
        this.setState({ loading: false });
    }

    renderOptions = () => {
        const portalTarget = document.getElementById('root');
        return (
            <Dropdown className="optionsAta" onClick={(e) => { e.stopPropagation() }}>
                <CustomTooltip style={{ height: 'auto' }} placement={"auto"} tooltip={"Opções da Ata de Reunião"}>
                    <Dropdown.Toggle
                        onChange={() => { }}
                        role="button"
                        style={{
                            backgroundColor: Colors.secondaryButton,
                            color: Colors.dark,
                            border: 'none',
                            zIndex: 25
                        }}
                        drop={'top'}
                    >
                        <FontAwesomeIcon icon={faEllipsis} />
                    </Dropdown.Toggle>
                </CustomTooltip>
                {ReactDOM.createPortal(
                    <Dropdown.Menu style={{ padding: 2, color: Colors.dark }}>
                        {this.props.ata.finalizado != null && this.props.ata.canEdit && 
                            <Dropdown.Item
                                key={`reabrir-ata-btn`}
                                onClick={
                                    async () => { 
                                        const req = await Sig.request("POST", "ataReuniao/reabrirAta", { id: this.props.ata.id});

                                        if(req.status && req.status == 200) {
                                            toast.success("Ata reaberta com sucesso!");
                                            this.props.updateAtaReuniaoCallback(req.ataReuniao);
                                        }
                                    }
                            }
                            >
                                <span style={{ fontSize: 14 }}>Reabrir</span>
                            </Dropdown.Item>
                        }
                        <Dropdown.Item
                            key={`clonar-ata-btn`}
                            onClick={() => {
                                this.props.cloneAtaReuniaoCallback(this.props.ata.id);
                            }}
                        >
                            <span style={{ fontSize: 14 }}>Clonar</span>
                        </Dropdown.Item>
                        <Dropdown.Item
                            key={`imprimir-ata-btn`}
                            onClick={async () => {
                                const toastId = toast.loading("Efetuando Download...");
                                try{
                                    const req = await Sig.request("GET", `ataReuniao/exportarAta`, { id: this.props.ata.id }, 'application/x-www-form-urlencoded; charset=UTF-8', 'blob');
                                    if(req.status && req.status == 200) {
                                        var url = window.URL.createObjectURL(new Blob([req]));
                                        var link = document.createElement('a');
                                        link.href = url;
                                        link.setAttribute('download', `Ata de Reunião - ${this.props.ata.id}.pdf`);
                                        document.body.appendChild(link);
                                        link.click();
                                        toast.update(toastId, { render: "Download Concluído!", type: "success", isLoading: false, autoClose: 5000 });
                                    } else{
                                        toast.update(toastId, { render: "Erro no Download!", type: "error", isLoading: false, autoClose: 5000 });
                                    }
                                } catch(e) {
                                    toast.update(toastId, { render: "Erro no Download!", type: "error", isLoading: false, autoClose: 5000 });
                                }
                            }}
                        >
                            <span style={{ fontSize: 14 }}>Imprimir</span>
                        </Dropdown.Item>
                        <Dropdown.Item
                            key={`excluir-ata-btn`}
                            onClick={() => {
                                this.props.deleteAtaReuniaoCallback(this.props.ata.id);
                            }}
                        >
                            <span style={{ fontSize: 14, color: Colors.error }}>Excluir</span>
                        </Dropdown.Item>
                    </Dropdown.Menu>
                    , portalTarget)}
            </Dropdown>
        );
    }

    renderConfidencial() {
        if (this.props.ata?.confidencial == 1) {
            return (
                <CustomTooltip tooltip={'Confidencial'} style={{ marginRight: 8 }}>
                    <FontAwesomeIcon icon={faLock} />
                </CustomTooltip>
            );
        }
    }

    renderTitle() {
        return (
            <div style={{ ...EssentialStyle.rowFlexStart, paddingLeft: 3, paddingBottom: 10 }}>
                {this.renderConfidencial()}
                <span style={{
                    fontSize: 18,
                    fontWeight: 500,
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    maxWidth: this.state.isSmallScreen ? '70vw' : '50vw'
                }}>
                    {this.props.ata.assunto}
                </span>
            </div>
        );
    }

    renderTags() {
        return (
            <div style={{ minHeight: 28 }}>
                <TooManyTags max={4} maxWidth={this.state.isSmallScreen ? '85vw' : '50vw'} tags={this.props.ata?.tags || []} />
            </div>
        );
    }

    renderPeople() {
        let spacing = 6;
        let style = { paddingLeft: 3, paddingTop: 10 };

        if (this.state.isSmallScreen) {
            spacing = 8;
            style = { ...EssentialStyle.columnStart, ...style };
        } else {
            style = { ...EssentialStyle.rowFlexStart, ...style };
        }


        return (
            <div style={style}>
                {this.props.ata?.participantes?.length ?
                    <div style={{ ...EssentialStyle.rowFlexStart, marginLeft: !this.state.isSmallScreen ? spacing * 2 : 0 }}>
                        <span style={{ marginRight: spacing * 2, fontSize: 14, color: Colors.dark }}>Participantes </span>
                        <TooManyAvatars participantsTotal={this.props.ata?.participantes?.length} participantsVisiveis={5} arrayParticipants={this.props.ata?.participantes} />
                    </div>
                : null}
            </div>
        );
    }

    renderPlace() {
        if(!this.props.ata?.local) return null;
        return (
            <div style={{ ...EssentialStyle.rowFlexStart, backgroundColor: Colors.tag, color: Colors.dark, fontSize: 12, padding: 5, borderRadius: 5, fontWeight: 600 }}>
                <FontAwesomeIcon icon={faMapMarkerAlt} style={{ color: Colors.dark, fontSize: 14, marginRight: 4 }} />
                <span>{this.props.ata?.local}</span>
            </div>
        );
    }

    renderLeft() {
        return (
            <div style={{ ...EssentialStyle.columnStart }}>
                {this.renderTitle()}
                {this.renderTags()}
                {this.renderPeople()}
            </div>
        );
    }

    renderPeriodo() {
        return (
            <div style={{ ...EssentialStyle.rowFlexStart, marginLeft: 2, backgroundColor: Colors.tag, color: Colors.dark, fontSize: 12, padding: 5, borderRadius: 5, fontWeight: 600 }}>
                <FontAwesomeIcon icon={faCalendar} style={{ color: Colors.dark, fontSize: 14, marginRight: 4 }} />
                <span>{this.props.ata?.data != 0 ? moment(this.props.ata?.data).format('DD/MM/YY') : "--"}{this.props.ata?.hora ? ` ${this.props.ata?.hora}` : ``}</span>
            </div>
        );
    }

    renderTipo() {
        let tipos = [
            { color: Colors.ataReuniao.tipo.backgroundColor[0], text: `Conselho`, description: `Conselho`, key: 0, textColor: Colors.light },
            { color: Colors.ataReuniao.tipo.backgroundColor[1], text: `Comitê`, description: `Comitê`, key: 4, textColor: Colors.light },
            { color: Colors.ataReuniao.tipo.backgroundColor[2], text: `Diretoria`, description: `Diretoria`, key: 1, textColor: Colors.light },
            { color: Colors.ataReuniao.tipo.backgroundColor[3], text: `Gerencial`, description: `Gerencial`, key: 2, textColor: Colors.light },
            { color: Colors.ataReuniao.tipo.backgroundColor[4], text: `Operacional`, description: `Operacional`, key: 3, textColor: Colors.light },
            { color: Colors.ataReuniao.tipo.backgroundColor[5], text: `Sócios`, description: `Sócios`, key: 5, textColor: Colors.light },
        ];

        let tipo = tipos.find(t => t.key == this.props.ata?.tipo);

        if (!tipo) return null;

        return (
            <div style={{ ...EssentialStyle.rowFlexEnd, minHeight: 28 }}>
                <CustomTooltip key={Math.random()} placement={'bottom'} tooltip={tipo.description}>
                    <div style={{ ...EssentialStyle.rowFlexStart, marginLeft: 2 }}>
                        <div style={{ backgroundColor: tipo.color, color: tipo.textColor, fontSize: 12, padding: 5, borderRadius: 5, marginRight: 2, fontWeight: 600 }}>{tipo.text}</div>
                    </div>
                </CustomTooltip>
            </div>
        );
    }

    renderRight() {
        let style = { height: 110 };

        if (this.state.isSmallScreen) {
            style = { ...EssentialStyle.columnSpaceBetween, alignItems: 'flex-start', marginTop: 20, ...style, width: '100%' };
        } else {
            style = { ...EssentialStyle.columnSpaceBetween, alignItems: 'flex-end', ...style };
        }

        return (
            <div style={style}>
                {this.renderOptions()}
                <div style={{ ...EssentialStyle.rowFlexEnd }}>
                    {this.renderPlace()}
                    <div style={{ width: 8 }}/>
                    {this.renderPeriodo()}
                    <div style={{ width: 8 }}/>
                    {this.renderTipo()}
                </div>
                <AtaReuniaoStatus ata={this.props.ata} />
            </div>
        );
    }

    renderCard() {
        let cardStyle = {
            ...EssentialStyle.card,
            marginTop: 10,
            padding: 10,
            cursor: 'pointer',
            width: "100%",
        };

        if (this.state.isSmallScreen) {
            cardStyle = {
                ...cardStyle,
                ...EssentialStyle.columnStart,
            };
        } else {
            cardStyle = {
                ...cardStyle,
                ...EssentialStyle.rowSpaceBetween,
            }
        }

        return (
            <div
                onClick={(e) => {
                    if (this.props.onClick) {
                        e.stopPropagation();
                        if (e.ctrlKey || e.metaKey) {
                            e.preventDefault();
                            this.props.onClick(this.props.ata, false);
                        } else {
                            this.props.onClick(this.props.ata, true);
                        }
                    }
                }}
                onMouseDown={(e) => {
                    if (e.button == 1 && this.props.onClick) {
                        e.stopPropagation();
                        e.preventDefault();
                        this.props.onClick(this.props.ata, false);
                    }
                }}
                style={cardStyle}
                className="ata-reuniao-card"
            >
                {this.renderLeft()}
                {this.renderRight()}
            </div>
        );
    }

    render() {
        return this.renderCard();
    }
}