import React from "react";
import './AtaReuniaoParticipantes.css';
import { Form, Col } from 'react-bootstrap';
import Select from 'react-select';
import DataHelper from '../../../../helper/DataHelper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faEnvelope, faX } from '@fortawesome/free-solid-svg-icons';
import CustomTooltip from "../../../tools/CustomTooltip";
import DefaultButton from "../../../tools/DefaultButton";
import Colors from "../../../../constants/Colors";

export default class AtaReuniaoParticipanteItem extends React.Component {

    state = {
        interno: this.props.participante.id || (!this.props.participante.id && !this.props.participante.nome && !this.props.participante.email) ? true : false,
        oldParticipante: null,
    }

    renderSwitch() {

        return (

            <div className="switch-container">
                <CustomTooltip tooltip={"Participante Interno"} placement="auto">
                    <FontAwesomeIcon className="left-icon" icon={faUser} />
                </CustomTooltip>

                <Form.Switch
                    className="ata-card-participante-switch"
                    type="switch"
                    disabled={this.props.noEdit}
                    checked={!this.state.interno}
                    onChange={(e) => {
                        var participante = {...this.props.participante} || {};
                        var oldParticipante = {...participante};
                        participante.id = this.state.oldParticipante?.id ? this.state.oldParticipante.id : null;
                        participante.nome = this.state.oldParticipante?.nome || null;
                        participante.email = this.state.oldParticipante?.email || null;
                        this.setState({ interno: !this.state.interno, oldParticipante });
                        this.props.changeCallback(this.props.index, participante);
                    }}
                />

                <CustomTooltip tooltip={"Participante Externo"} placement="auto">
                    <div className="right-icon">
                        <FontAwesomeIcon icon={faUser} />
                        <FontAwesomeIcon className="mail-icon" icon={faEnvelope} />
                    </div> 
                </CustomTooltip>
            </div>
        )
    }


    render() {
        return (
            <div className="participante-ata-card">
                {!this.props.noEdit &&
                    <Form.Group className="w-auto">
                        {this.renderSwitch()}
                    </Form.Group>
                }

                {this.state.interno ?
                    
                    <Form.Group className={"participante-select"}>
                        <Select
                            menuPortalTarget={document.body}
                            portalId={"participante-select"}
                            isDisabled={this.props.noEdit}
                            options={DataHelper.formatSelectData(this.props.selectsOptions.possiveisParticipantes, 'email', 'nome')}
                            placeholder={'Selecione o Colaborador'} noOptionsMessage={DataHelper.getSelectEmptyMessage}
                            value={DataHelper.formatSelectedData(this.props.participante, 'email', 'nome') || null}
                            onChange={(value) => { 
                                var participante = {...this.props.participante} || {};
                                var newParticipante = this.props.selectsOptions.possiveisParticipantes.find((participante) => participante.email == value.value);
                                if(!newParticipante) return;
                                participante.id = newParticipante.id;
                                participante.nome = newParticipante.nome;
                                participante.email = newParticipante.email;
                                this.props.changeCallback(this.props.index, participante);
                                if(this.state.oldParticipante)
                                    this.setState({ oldParticipante: null });
                            }}
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                        />
                    </Form.Group>
                :
                    <>
                        <Form.Group className={"participante-nome-input"}>
                            <Form.Control 
                                type="text" 
                                placeholder="Nome" 
                                disabled={this.props.noEdit}
                                value={this.props.participante.nome} 
                                onChange={(e) => {
                                    var participante = {...this.props.participante} || {};
                                    participante.nome = e.target.value;
                                    this.props.changeCallback(this.props.index, participante);
                                    if(this.state.oldParticipante)
                                        this.setState({ oldParticipante: null });
                                }} 
                            />
                        </Form.Group>

                        <Form.Group className={"participante-email-input"}>
                            <Form.Control 
                                type="email" 
                                placeholder="Email" 
                                disabled={this.props.noEdit}
                                value={this.props.participante.email} 
                                onChange={(e) => 
                                    {
                                        var participante = {...this.props.participante} || {};
                                        participante.email = e.target.value;
                                        this.props.changeCallback(this.props.index, participante);
                                        if(this.state.oldParticipante)
                                            this.setState({ oldParticipante: null });
                                    }
                                } />
                        </Form.Group>
                    </>
                }

                <Form.Group className={"participante-permissao-select"}>
                    <Select
                        menuPortalTarget={document.body}
                        isDisabled={this.props.noEdit}
                        options={DataHelper.formatSelectData(this.props.selectsOptions.tiposPermissao, 'id', 'nome')}
                        placeholder={'Selecione a Permissão do Participante'} noOptionsMessage={DataHelper.getSelectEmptyMessage}
                        value={DataHelper.formatSelectData(this.props.selectsOptions.tiposPermissao, 'id', 'nome').find((permissao) => permissao.value == this.props.participante.permissao) || null}
                        onChange={(value) => { 
                            var participante = {...this.props.participante} || {};
                            participante.permissao = value.value
                            this.props.changeCallback(this.props.index, participante);
                        }}
                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                    />
                </Form.Group>
                {
                    this.props.tipoReuniao && this.props.tipoReuniao.label == 'Conselho' &&
                    <Form.Group className={"participante-funcao-select"}>
                        <Select
                            menuPortalTarget={document.body}
                            isDisabled={this.props.noEdit && !this.props.canEditFuncao}
                            options={DataHelper.formatSelectData(this.props.selectsOptions.tiposFuncoes, 'id', 'nome')}
                            placeholder={'Selecione a Função'} noOptionsMessage={DataHelper.getSelectEmptyMessage}
                            value={DataHelper.formatSelectData(this.props.selectsOptions.tiposFuncoes, 'id', 'nome').find((funcao) => funcao.value == this.props.participante.funcao) || null}
                            onChange={(value) => { 
                                var participante = {...this.props.participante} || {};
                                participante.funcao = value.value
                                this.props.changeCallback(this.props.index, participante);
                            }}
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            />
                    </Form.Group>
                }
                { !this.props.noEdit ?
                    <Form.Group className={"participante-remover-button"}>
                    <DefaultButton 
                        leftIcon={<FontAwesomeIcon icon={faX} />} 
                        disabled={this.props.noEdit}
                        tooltip={"Remover Participante"} 
                        color={Colors.homePage.grey}
                        onClick={() => this.props.removeCallback(this.props.index)}
                        style={{padding: 0, borderRadius: 50, fontSize: 10, height: 20, width: 20, display: "flex", justifyContent: "center", alignItems: "center"}}
                    />
                    </Form.Group>
                    : 
                    <span style={{height: 20, width: 20}}/>
                }
            </div>
        );
    }
}
