import React from "react";

import './Questionario.css';
import DiagnosticoHelper from "../../../../../helper/diagnostico/DiagnosticoHelper";
import IdiomaHelper from "../../../../../helper/IdiomaHelper";
import Colors from "../../../../../constants/Colors";
import DefaultButton from "../../../../tools/DefaultButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusSquare, faInfoCircle, faCheckCircle, faExclamationTriangle, faUpload } from "@fortawesome/free-solid-svg-icons";
import DefaultLoader from "../../../../tools/DefaultLoader";
import { confirmAlert } from "react-confirm-alert";
import CustomConfirm from "../../../../tools/CustomConfirm";
import ConfigEscala from "./ConfigEscala/ConfigEscala";
import PreViewEscala from "./PreViewEscala/PreViewEscala";
import ConfigEstrutura from "./ConfigEstrutura/ConfigEstrutura";
import ConfigTabela from "./ConfigTabela/ConfigTabela";
import Modal from "../../../../tools/Modal";
import TableImporter from "../../../../tools/TableImporter/TableImporter";
import EssentialStyle from "../../../../../style/EssentialStyle";
import { withTranslation } from 'react-i18next';


class Questionario extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loadingQuestionario: false,
            loadingModelo: false,
            questionario: {
                escalas: [
                    { tipo: 'estrela', min: 0, max: 100, conceitos: [
                        { valor: 0, descricao: [], cor: '' },
                        { valor: 25, descricao: [], cor: '' },
                        { valor: 50, descricao: [], cor: '' },
                        { valor: 75, descricao: [], cor: '' },
                        { valor: 100, descricao: [], cor: '' },
                    ]}
                ],
                canEdit: true,
            },
            addConceito: false,
            seting: false,
            isLoadingQuestionario: false,
            showUploadModal: false,
            modelo: null,
            importing: false,
            importComplete: false,
            editConceito: null,
            linesImported: 0
        }
        this.timeout = null;
    }

    async componentDidMount() {
        await this.props.i18n.loadNamespaces(['client_src_helper_diagnostico_DiagnosticoHelper']);

    }

    componentDidUpdate(prevProps) {
        if ((prevProps.idQuestionario !== this.props.idQuestionario && prevProps.idQuestionario != null) 
            || prevProps.idPeriodoAvaliacao !== this.props.idPeriodoAvaliacao) {
            this.props.onTabChange('one'); 
            this.loadQuestionario();
        }

        if (prevProps.isEditingName !== this.props.isEditingName && prevProps.isEditingName == true && (prevProps.idQuestionario == this.props.idQuestionario) ) {
            this.setNameQuestionario();
        }
    }

    componentWillUnmount() {
        if (this.timeout) {
            clearTimeout(this.timeout);
            this.saveEscala();
        }
    }

    setNameQuestionario = async () => {
        
        let response = await DiagnosticoHelper.setQuestionarioNome(this.props.idPeriodoAvaliacao, this.state.questionario.id, this.props.nome);

        let questionario = { ...this.state.questionario };
        questionario.nome = response.nome;

        this.setState({ questionario }, () => this.props.setNameEditing(questionario.nome, null));
        
    }

    setEscalaCallback = async (value) => {
        
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
        if (Number(value?.value) && Number(value?.value) > Number(value?.max)) {
            value.value = value.max;
        }

        let questionario = { ...this.state.questionario };
        questionario.escalas[0] = { ...value };

        this.setState({ questionario, seting: true}, () => {
            this.timeout = setTimeout(async () => {
                await this.saveEscala();
            }, 1000);
        });
    }

    saveEscala = async () => {
    
        let questionarioSave = await DiagnosticoHelper.setQuestionarioEscala(this.state.questionario.id, this.state.questionario.escalas, this.props.idPeriodoAvaliacao);

        var questionario = { ...this.state.questionario };
        questionario.id = questionarioSave.id;
        questionario.escalas = questionarioSave.escalas;
        questionario.isApto = questionarioSave.isApto;

        this.setState({ questionario, seting: false }, () => this.props.setNameEditing(questionario.nome, null));
    }

    setEstruturaCallback = async (value) => {
        if (this.state.seting) {
            setTimeout(() => {
                this.setEstruturaCallback(value);
            }, 1000);
            return;
        }

        let questionario = { ...this.state.questionario };
        questionario.colunas = [...value];

        this.setState({ questionario, seting: true });

        let questionarioSave = await DiagnosticoHelper.setQuestionarioEstrutura(questionario.id, questionario.colunas, this.props.idPeriodoAvaliacao);

        questionario = { ...this.state.questionario };
        questionario.id = questionarioSave.id;
        questionario.colunas = [...questionarioSave.colunas];

        this.setState({ questionario, seting: false }, () => this.props.setNameEditing(questionario.nome, null));

    }

    setNodeQuestionarioCallback = async (idQuestionario, nodeToSave) => {
        if (this.state.seting) {
            setTimeout(() => {
                this.setNodeQuestionarioCallback(idQuestionario, nodeToSave);
            }, 1000);
            return;
        }

        this.setState({ seting: true });

        let { node, isApto } = await DiagnosticoHelper.setNodeQuestionario(idQuestionario, nodeToSave);

        // atualizar o node na tabela, porem o node ser filho de um node, para isso énecessário fazer uma função recursiva para encontrar esste item e atualiza-lo
        let updateNode = (node, tree) => {
            for (let i = 0; i < tree.length; i++) {
                if (tree[i].id == node.id) {
                    tree[i] = node;
                    return true; // Nó encontrado e atualizado
                }
                if (tree[i].filhos) {
                    if (updateNode(node, tree[i].filhos)) {
                        return true; // Nó encontrado e atualizado em um nível mais profundo
                    }
                }
            }
            return false; // Nó não encontrado
        };

        let tabela = this.state.questionario.tabela;

        if (!updateNode(node, tabela)) {
            tabela.push(node);
        }

        let questionario = { 
            ...this.state.questionario,
            tabela: tabela,
            isApto: isApto,
        };

        this.setState({questionario, seting: false });
        
    }

    deleteItemQuestionarioCallback = async (nodeToDelete) => {
        if (this.state.seting) {
            setTimeout(() => {
                this.deleteItemQuestionarioCallback(nodeToDelete);
            }, 1000);
            return;
        }

        this.setState({ seting: true });

        let { node, isApto } = await DiagnosticoHelper.deleteNodeQuestionario(nodeToDelete);


        // atualizar o node na tabela, porem o node ser filho de um node, para isso énecessário fazer uma função recursiva para encontrar esste item e atualiza-lo
        let updateNode = (node, tree) => {
            for (let i = 0; i < tree.length; i++) {
                if (tree[i].id == node.id) {
                    if (parseInt(node?.id) != parseInt(nodeToDelete)) {
                        tree[i] = node;
                    } else {
                        tree.splice(i, 1); // Excluir o item da árvore
                    }
                    return true; // Nó encontrado e atualizado
                }
                if (tree[i].filhos) {
                    if (updateNode(node, tree[i].filhos)) {
                        return true; // Nó encontrado e atualizado em um nível mais profundo
                    }
                }
            }
            return false; // Nó não encontrado
        };

        let tabela = this.state.questionario.tabela;

        updateNode(node, tabela);

        let questionario = { 
            ...this.state.questionario,
            tabela: tabela,
            isApto: isApto,
        };

        this.setState({questionario, seting: false });

    }

    loadQuestionario = async (load = true, idQuestionario = null) => {

        if (this.state.isLoadingQuestionario) {
            setTimeout(() => {
                this.loadQuestionario(load, idQuestionario);
            }, 1000);
            return;
        }

        idQuestionario = idQuestionario ?? this.props.idQuestionario;
        
        if (idQuestionario) {

            this.setState({ loadingQuestionario: load ? true : this.state.loadingQuestionario, isLoadingQuestionario: true });

            let questionario = await DiagnosticoHelper.getQuestionario(idQuestionario, this.props.idPeriodoAvaliacao, 1);

            this.setState({ questionario, loadingQuestionario: load ? false : this.state.loadingQuestionario, isLoadingQuestionario: false }, () => this.props.setNameEditing(questionario.nome, questionario.canEdit));
        }
    }

    loadQuestionarioColuna = async (load = true, idQuestionario = null) => {

        idQuestionario = idQuestionario ?? this.props.idQuestionario;
        
        if (idQuestionario) {

            this.setState({ loadingQuestionario: load ? true : this.state.loadingQuestionario });

            let { colunas } = await DiagnosticoHelper.getColunasQuestionario(idQuestionario);
            
            let questionario = {
                ...this.state.questionario,
                colunas: colunas,
            }

            this.setState({ questionario, loadingQuestionario: load ? false : this.state.loadingQuestionario }, () => this.props.setNameEditing(questionario.nome, questionario.canEdit));
        }
    }

    openImporter = async () => {
        this.setState({ loadingModelo: true });

        let modelo = await DiagnosticoHelper.getQuestionarioModelo(this.state.questionario.id);

        this.setState({ modelo, showUploadModal: true, loadingModelo: false });
    }

    closeImporter = () => {
        this.setState({ showUploadModal: false, importing: false, importComplete: false }, () => this.loadQuestionario(true, this.state.questionario?.id));
    }

    uploadQuestionario = async (data) => {
        this.setState({ importing: true });

        const response = await DiagnosticoHelper.importQuestionario(data, this.state.questionario.id);

        if (response.error) {
            this.setState({ fileErrors: response.fileErrors, importing: false, importComplete: false });
            return;
        } else {
            this.setState({ linesImported: this.state.linesImported + data.length, importing: false, importComplete: true });
        }
    }

    showInfos = () => {

        let message;

        switch (this.props.activeTab) {
            case 'one':
                message = IdiomaHelper.getStruct({
                    'pt_br': { 
                        title: 'Escala padrão do questionário', 
                        content: 'Aqui você pode configurar a escala padrão do questionário. A escala padrão será utilizada para avaliar os conceitos do questionário. Você pode configurar o tipo de escala (Estrela, Numérica, Conceitos), o valor mínimo e máximo para cada tipo e os conceitos da escala.',
                        close: 'Fechar',
                    },
                    'en': { 
                        title: 'Questionnaire standard scale', 
                        content: 'Here you can configure the standard scale of the questionnaire. The standard scale is the scale that will be used to evaluate the concepts of the questionnaire. You can configure the type of scale, the minimum and maximum value of the scale and the concepts of the scale.',
                        close: 'Close',
                    },
                    'es': { 
                        title: 'Escala estándar del cuestionario', 
                        content: 'Aquí puede configurar la escala estándar del cuestionario. La escala estándar es la escala que se utilizará para evaluar los conceptos del cuestionario. Puede configurar el tipo de escala, el valor mínimo y máximo de la escala y los conceptos de la escala.',
                        close: 'Cerrar',
                    },
                });
                break;
            case 'two':
                message = IdiomaHelper.getStruct({
                    'pt_br': { 
                        title: 'Estrutura do questionário', 
                        content: 'qui você pode configurar a estrutura do questionário, definindo a forma como as perguntas serão apresentadas. Você pode configurar a ordem e o tipo de cada coluna do questionário.',
                        close: 'Fechar',
                    },
                    'en': { 
                        title: 'Questionnaire structure', 
                        content: 'Here you can configure the structure of the questionnaire, defining how the questions will be presented. You can configure the order and type of each column of the questionnaire.',
                        close: 'Close',
                    },
                    'es': { 
                        title: 'Estructura del cuestionario', 
                        content: 'Aquí puede configurar la estructura del cuestionario, definiendo cómo se presentarán las preguntas. Puede configurar el orden y el tipo de cada columna del cuestionario.',
                        close: 'Cerrar',
                    },
                });
                break;
            case 'three':
                message = IdiomaHelper.getStruct({
                    'pt_br': { 
                        title: 'Conteúdo do Questionário',
                        content: 'Aqui você pode configurar o conteúdo do questionário, definindo as perguntas que serão incluídas no questionário e sua ordem e exibição.',
                        close: 'Fechar',
                    },
                    'en': { 
                        title: 'Questionnaire Content',
                        content: 'Here you can configure the content of the questionnaire, defining the questions that will be included in the questionnaire and their order and display.',
                        close: 'Close',
                    },
                    'es': { 
                        title: 'Contenido del Cuestionario',
                        content: 'Aquí puede configurar el contenido del cuestionario, definiendo las preguntas que se incluirán en el cuestionario y su orden y visualización.',
                        close: 'Cerrar',
                    },
                });
                break;
            default:
                message = IdiomaHelper.getStruct({
                    'pt_br': { 
                        title: 'Escala padrão do questionário', 
                        content: 'Aqui você pode configurar a escala padrão do questionário. A escala padrão é a escala que será utilizada para avaliar os conceitos do questionário. Você pode configurar o tipo de escala, o valor mínimo e máximo da escala e os conceitos da escala.',
                        close: 'Fechar',
                    },
                    'en': { 
                        title: 'Questionnaire standard scale', 
                        content: 'Here you can configure the standard scale of the questionnaire. The standard scale is the scale that will be used to evaluate the concepts of the questionnaire. You can configure the type of scale, the minimum and maximum value of the scale and the concepts of the scale.',
                        close: 'Close',
                    },
                    'es': { 
                        title: 'Escala estándar del cuestionario', 
                        content: 'Aquí puede configurar la escala estándar del cuestionario. La escala estándar es la escala que se utilizará para evaluar los conceptos del cuestionario. Puede configurar el tipo de escala, el valor mínimo y máximo de la escala y los conceptos de la escala.',
                        close: 'Cerrar',
                    },
                });
                break;
        }

        confirmAlert({
            customUI: ({ onClose }) => (
                <CustomConfirm
                    title={message[this.props.lang].title}
                    message={<div dangerouslySetInnerHTML={{ __html: message[this.props.lang].content }} style={{ marginTop: 10, marginBottom: 10 }} />}
                    buttons={[
                        {
                            label: message[this.props.lang].close,
                            onClick: () => { onClose(); }
                        },
                    ]}
                />
            )
        });

    }

    showIsApto = () => {

        let message;

        switch (this.state.questionario.isApto) {
            case true:
                message = IdiomaHelper.getStruct({
                    'pt_br': { 
                        title: 'Questionário Pronto para Uso', 
                        content: 'Parabéns! Seu Questionário está prontinho para ser utilizado e gerar Avaliações. Mas não se preocupe, você ainda pode fazer ajustes até que uma Avaliação seja gerada!',
                        close: 'Fechar',
                    },
                    'en': { 
                        title: 'Questionnaire Ready for Use',
                        content: 'Congratulations! Your questionnaire is ready to be used and generate evaluations. But don\'t worry, you can still make adjustments until an evaluation is generated!',
                        close: 'Close',
                    },
                    'es': { 
                        title: 'Cuestionario Listo para Usar',
                        content: '¡Felicidades! Su cuestionario está listo para ser utilizado y generar evaluaciones. Pero no te preocupes, aún puedes hacer ajustes hasta que se genere una evaluación!',
                        close: 'Cerrar',
                    },
                });
                break;
            case false:
                message = IdiomaHelper.getStruct({
                    'pt_br': { 
                        title: 'Questionário Não Apto para Avaliações', 
                        content: `
                            <p>Seu questionário não está apto para gerar avaliações. Por favor, verifique os seguintes pontos:</p>
                            <ul>
                                <li>A escala padrão do questionário não está definida ou está incompleta.</li>
                                <li>Não há nenhuma coluna definida.</li>
                                <li>A tabela não está completa. Para uma tabela completa, cada item deve ter itens filhos até a última coluna (se houver mais de uma coluna).</li>
                            </ul>
                        `,
                        close: 'Fechar',
                    },
                    'en': { 
                        title: 'Questionnaire Not Ready for Evaluations',
                        content: `
                            <p>Your questionnaire is not ready to generate evaluations. Please check the following points:</p>
                            <ul>
                                <li>The questionnaire's standard scale is not defined or is incomplete.</li>
                                <li>There is no defined column.</li>
                                <li>The table is not complete. For a complete table, each item must have child items up to the last column (if there is more than one column).</li>
                            </ul>
                        `,
                        close: 'Close',
                    },
                    'es': { 
                        title: 'Cuestionario No Listo para Evaluaciones',
                        content: `
                            <p>Su cuestionario no está listo para generar evaluaciones. Por favor, verifique los siguientes puntos:</p>
                            <ul>
                                <li>La escala estándar del cuestionario no está definida o está incompleta.</li>
                                <li>No hay una columna definida.</li>
                                <li>La tabla no está completa. Para una tabla completa, cada ítem debe tener ítems hijos hasta la última columna (si hay más de una columna).</li>
                            </ul>
                        `,
                        close: 'Cerrar',
                    },
                });
                break;
        }

        confirmAlert({
            customUI: ({ onClose }) => (
                <CustomConfirm
                    title={message[this.props.lang].title}
                    message={<div dangerouslySetInnerHTML={{ __html: message[this.props.lang].content }} style={{ marginTop: 10, marginBottom: 10 }} />}
                    buttons={[
                        {
                            label: message[this.props.lang].close,
                            onClick: () => { onClose(); }
                        },
                    ]}
                />
            )
        });

    }

    renderEmpty() {
        const message = IdiomaHelper.getStruct({
            'pt_br': { empty: 'Selecione ou Crie um Questionário', buttom: 'Adicionar Questionário' },
            'en': { empty: 'Select or Create a Questionnaire', buttom: 'Add Questionnaire' },
            'es': { empty: 'Seleccione o Cree un Cuestionario', buttom: 'Agregar Cuestionario' },
        });

        return (
            <div className={"emptyList"}>
                {DiagnosticoHelper.renderEmpty(message[this.props.lang].empty, {height: '16rem', marginBottom: '1rem'}, {height: '14rem'}, this.props.t)}
                <DefaultButton
                    title={message[this.props.lang].buttom}
                    leftIcon={<FontAwesomeIcon icon={faPlusSquare} />}
                    color={Colors.success}
                    onClick={() => { this.props.onAdd(); this.setEscalaCallback(this.state.questionario.escalas); }}
                    disabled={!this.props.canEdit}
                />
            </div>
        );
    }

    renderQuestionario() {
        
        const loadingQuestionario = () => {
            return (
                <div className="loadingQuestionario">
                    <DefaultLoader/>
                </div>
            )
        }

        const questionario = () => {
            switch (this.props.activeTab) {
                case 'one':
                    return this.renderTabOne();
                case 'two':
                    return this.renderTabTwo();
                case 'three':
                    return this.renderTabThree();
                default:
                    return null;
            }
        };

        if (this.state.loadingQuestionario) return loadingQuestionario();
        return questionario();

    }

    renderTabOne() {

        let message = IdiomaHelper.getStruct({
            'pt_br': { title: 'Escala padrão do questionário' },
            'en': { title: 'Questionnaire standard scale' },
            'es': { title: 'Escala estándar del cuestionario' },
        });

        const { isApto } = this.state.questionario;
        
        return (
            <div className="tabPage one">
                <div className="title-container">
                    <span className="title">{message[this.props.lang].title} <FontAwesomeIcon icon={faInfoCircle} style={{margin: '0px 5px', cursor: 'pointer'}} onClick={ () => this.showInfos() }/> </span>
                    <div className="title-line"></div>
                    <FontAwesomeIcon 
                        icon={isApto ? faCheckCircle : faExclamationTriangle} 
                        style={{ 
                            margin: '0px 5px 0px 15px', 
                            cursor: 'pointer',
                            color: isApto ? Colors.success : Colors.danger,
                        }}
                        onClick={ () => this.showIsApto() }
                    />
                </div>

                <div className="body">
                    <div className="body-left">
                        <ConfigEscala 
                            escala={this.state.questionario.escalas[0]}
                            lang={this.props.lang}
                            callback={(value) => { this.setEscalaCallback(value) }}
                            addConceito={this.state.addConceito}
                            blocked={!this.state.questionario.canEdit}
                            editConceito={this.state.editConceito}
                        />                    
                    </div>

                    <div className="body-right">
                        <PreViewEscala
                            escala={this.state.questionario.escalas[0]}
                            faixas={this.props.faixas}
                            lang={this.props.lang}
                            callback={(value) => { this.setEscalaCallback(value) }}
                            addConceitoCallback={() => {this.setState({addConceito: true})}}
                            editConceitoCallback={(value) => {this.setState({editConceito: value})}}
                            blocked={!this.state.questionario.canEdit}
                        />
                    </div>
                </div>
            </div>
        )
    }

    renderTabTwo() {
        let message = IdiomaHelper.getStruct({
            'pt_br': { title: 'Estrutura do questionário'},
            'en': { title: 'Questionnaire structure'},
            'es': { title: 'Estructura del cuestionario'},
        });

        const { isApto } = this.state.questionario;

        return (
            <div className="tabPage two">
                <div className="title-container">
                    <span className="title">{message[this.props.lang].title} <FontAwesomeIcon icon={faInfoCircle} style={{margin: '0px 5px', cursor: 'pointer'}} onClick={ () => this.showInfos() }/> </span>
                    <div className="title-line"></div>
                    <FontAwesomeIcon 
                        icon={isApto ? faCheckCircle : faExclamationTriangle} 
                        style={{ 
                            margin: '0px 5px 0px 15px', 
                            cursor: 'pointer',
                            color: isApto ? Colors.success : Colors.danger,
                        }} 
                        onClick={ () => this.showIsApto() }
                    />
                </div>
                <div className="body">
                    <ConfigEstrutura
                        colunas={this.state.questionario.colunas}
                        lang={this.props.lang}
                        callback={(value) => { this.setEstruturaCallback(value) }}
                        blocked={!this.state.questionario.canEdit}
                        blockedDrag={!this.state.questionario.canEditColunasOrdem}
                        reload={() => { this.state.questionario.canEdit && this.loadQuestionarioColuna(false, this.state.questionario?.id) }}
                    />
                </div>
            </div>
        )
    }

    renderTabThree() {
        let message = IdiomaHelper.getStruct({
            'pt_br': { title: 'Conteúdo do Questionário', upload: 'Importar Tabela', close: 'Fechar' },
            'en': { title: 'Questionnaire Content', upload: 'Import Table', close: 'Close' },
            'es': { title: 'Contenido del Cuestionario', upload: 'Tabla de Importación', close: 'Cerrar' },
        });

        const { isApto } = this.state.questionario;

        return (
            <div className="tabPage three">
                <div className="title-container">
                    <span className="title">{message[this.props.lang].title} <FontAwesomeIcon icon={faInfoCircle} style={{margin: '0px 5px', cursor: 'pointer'}} onClick={ () => this.showInfos() }/> </span>
                    <div className="title-line"></div>
                    { !this.state.loadingQuestionario && this.state.questionario.canEdit && !this.state.questionario?.colunas?.lenght > 0 && <DefaultButton
                        leftIcon={<FontAwesomeIcon icon={faUpload} />}
                        loading={this.state.loadingModelo}
                        style={{margin: '0px 5px 0px 15px'}}
                        title={message[this.props.lang].upload}
                        color={Colors.secondaryButton}
                        textColor={Colors.dark}
                        onClick={() => { this.openImporter(); }}
                        disabled={!this.state.questionario.canEdit || !this.state.questionario || this.state.loadingModelo || this.state.showUploadModal}
                    />}
                    <FontAwesomeIcon 
                        icon={isApto ? faCheckCircle : faExclamationTriangle} 
                        style={{ 
                            margin: '0px 5px 0px 15px', 
                            cursor: 'pointer',
                            color: isApto ? Colors.success : Colors.danger,
                        }} 
                        onClick={ () => this.showIsApto() }
                    />
                </div>
                <div className="body">
                    <ConfigTabela
                        colunas={this.state.questionario.colunas}
                        tabela={this.state.questionario.tabela}
                        idQuestionario={this.state.questionario.id}
                        idPeriodoAvaliacao={this.props.idPeriodoAvaliacao}
                        lang={this.props.lang}
                        callback={(idQuestionario, node) => { this.setNodeQuestionarioCallback(idQuestionario, node) }}
                        deleteCallback={(idNode) => { this.deleteItemQuestionarioCallback(idNode) }}
                        blocked={!this.state.questionario.canEdit}
                        reload={() => { this.state.questionario.canEdit && this.loadQuestionario(false, this.state.questionario?.id) }}
                    />
                </div>

                {this.state.showUploadModal && 
                    <Modal
                        style={{ 
                            width: '95vw', 
                            height: '100vh',
                            transition: 'height 0.5s ease', 
                            boxShadow: '0px 0px 20px 20px rgba(50, 50, 50, 0.5)', 
                            justifyContent: 'space-between',
                        }} 
                    >
                        <TableImporter
                            fileModel={this.state.modelo}
                            lang={this.props.lang}
                            onComplete={() => { this.closeImporter() }}
                            onConfirm={(data) => { this.uploadQuestionario(data) }}
                            importing={this.state.importing}
                            fileErrors={this.state.fileErrors}
                            importComplete={this.state.importComplete}
                            linesImported={this.state.linesImported}
                        />
                        <div style={{ ...EssentialStyle.rowFlexEnd, width: '100%' }}>
                            <DefaultButton
                                title={message[this.props.lang].close}
                                color={Colors.dark}
                                onClick={() => { this.closeImporter() }}
                            />
                        </div>
                    </Modal>
                }
            </div>
        )
    }

    render() {
        return (
            <div className="Questionario">
                {!this.props.idQuestionario ? this.renderEmpty() : this.renderQuestionario()}
            </div>
        )
    }
}

export default withTranslation()(Questionario);