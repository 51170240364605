import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusSquare } from '@fortawesome/free-solid-svg-icons'
import Colors from "../../constants/Colors";
import Sig from "../../api/Sig";
import DefaultButton from "../../components/tools/DefaultButton";
import AddSwot from "../../components/forms/pe/add/AddSwot";
import LoadingPage from "../LoadingPage";
import SwotView from "../../components/modules/pe/swot/SwotView";
import SwotList from "../../components/forms/pe/list/SwotList";

export default class SwotPage extends React.Component {

    state = {
        showWizard: false,
        loading: true,
        swots: [],
        id: null, // Adicionamos o estado para armazenar o ID da SWOT
    }

    async componentDidMount() {
        this.handleUrlParams();
    }

    handleUrlParams = () => {
        window.parent.postMessage({ type: 'get_url' }, '*');

        const listenerFunction = async (event) => {
            if (event.data.type === 'return_url') {
                const urlObj = new URL(event.data.url);
                const queryParams = new URLSearchParams(urlObj.search);
                const id = queryParams.get('id');

                if (!id) {
                    await this.loadData();
                } else {
                    this.setState({ id }, async () => await this.loadData());
                }

                window.removeEventListener('message', listenerFunction);
            }
        };

        window.addEventListener('message', listenerFunction);
    }

    async loadData() {
        this.setState({ loading: true });

        try {
            const { swots } = await Sig.request('GET', 'pe/swot/getSwots', { relations: 1 });
            this.setState({ swots, loading: false });
        } catch (error) {
            console.error('Erro ao carregar SWOTs:', error);
            this.setState({ loading: false });
        }
    }

    addSwotCallback = async (newSwotId) => {
        this.setState({ showWizard: false, id: null });
        await this.loadData();
    }

    listCallback = async () => {
        this.setState({ id: null });
        window.parent.postMessage({ type: 'delete_url_param', param: 'id' }, '*');
    }

    swotCallback = async (id) => {
        this.setState({ id });
        window.parent.postMessage({ type: 'add_url_param', param: 'id', value: id }, '*');
    }

    renderEmpty() {
        return (
            <div style={{ padding: 50, backgroundColor: 'white', marginTop: 20, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', boxShadow: Colors.boxShadow, border: '0.3px solid lightgrey', borderRadius: 5, textAlign: 'center' }}>
                <DefaultButton style={{ position: 'absolute', top: 30, right: 10 }} link={"https://advis.freshdesk.com/support/solutions/articles/63000283129-swot"} />
                <h5>Até o momento, sua empresa ainda não conduziu análises <strong>SWOT</strong> ou você não possui acesso a nenhuma delas.</h5>
                <div style={{ marginTop: 20 }} />
                <DefaultButton color={Colors.success} title={'Adicionar SWOT'} leftIcon={<FontAwesomeIcon icon={faPlusSquare} />} loading={this.state.loading} onClick={() => { this.setState({ showWizard: true }) }} />
            </div>
        );
    }

    render() {
        if (this.state.loading) return <LoadingPage />;
        if (this.state.showWizard) return <AddSwot addSwotCallback={(newSwotId) => { this.addSwotCallback(newSwotId) }} />;
        if (this.state.id) return <SwotView id={this.state.id} callback={this.listCallback} />;
        if (this.state.swots.length === 0) return this.renderEmpty();

        return <SwotList callback={this.addSwotCallback} swotCallback={this.swotCallback} />;
    }
}