import React from "react";
import '../PlanoAcaoRelatorio.css';

import EssentialStyle from '../../../../../style/EssentialStyle';
import DefaultLoader from '../../../../tools/DefaultLoader';
import { Collapse } from "react-bootstrap";
import Colors from "../../../../../constants/Colors";
import ChartHelper from '../../../../../helper/inicio/ChartHelper';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import SessionHelper from '../../../../../helper/SessionHelper';
import { debounce } from 'lodash';
import Sig from "../../../../../api/Sig";
import DataHelper from "../../../../../helper/DataHelper";


export default class PlanoAcaoRelatorioStatus extends React.Component {
    constructor(props) {
        super(props);
        this.debouncedLegendItemClick = debounce(this.legendItemClick, 150);
        this.chartRefStatus = React.createRef();
        this.chartRefTipo = React.createRef();
        this.chartRefRecomendacao = React.createRef();
        this.abortController = new AbortController();
    }

    state={
        expanded: true,
        dataStatus: [],
        dataTipo: [],
        dataRecomendacao: [],
        status: [],
        tipo: [],
        recomendacao: [],
        statusFilter: [],
        tipoFilter: [],
        recomendacaoFilter: [],
        loading: true,
        statusCounter: 0,
        tipoCounter: 0,
        recomendacaoCounter: 0,
        totalCounter: this.props?.counter
    }

    async componentDidMount(){
        await this.load();
    }

    load = async () => {
        this.setState({ loading: true }, () => {
            this.setChartData({ statuses: {}, tipos: {}, total: 0 });
        });

        let req = await Sig.request(
            'POST', 'planoAcao/getStatusTipoCounter', 
            { 
                filter: JSON.stringify(this.props.filter), 
                parceiro: this.props.parceiro ? 1 : 0, 
                relations: this.props.id_periodo_avaliacao ? 1 : 0, 
                id_periodo_avaliacao: this.props.id_periodo_avaliacao || 0
            },
            Sig.getDefaultContentType(),
            Sig.getDefaultTransformTo(),
            this.abortController.signal
        );
        
        if(req.status == 200) {
            this.setState({ statusCounter: req.statuses, tipoCounter: req.tipos, recomendacaoCounter: req.recomendacao }, () => {
                this.setChartData(req);
            });
        }

        this.setState({ loading: false });
    }

    getAvaliableCounters = (exclude) => {
        let counters = ['status', 'tipo', 'recomendacao'];

        if(exclude) {
            return counters.filter(v => v != exclude);
        }

        return counters;
    }

    updateCounters = async (counter) => {
        this.setState({ loading: true });

        let filter = { ...this.props.filter, status: this.state.statusFilter, tipoPlano: this.state.tipoFilter, recomendacao: this.state.recomendacaoFilter };

        let req = await Sig.request(
            'POST', 'planoAcao/getStatusTipoCounter', 
            { 
                filter: JSON.stringify(filter),
                parceiro: this.props.parceiro ? 1 : 0, 
                relations: this.props.id_periodo_avaliacao ? 1 : 0,
                id_periodo_avaliacao: this.props.id_periodo_avaliacao || 0
            },
            Sig.getDefaultContentType(),
            Sig.getDefaultTransformTo(),
            this.abortController.signal
        );
        
        if(req.status == 200) {
            this.setState({ statusCounter: req.statuses, tipoCounter: req.tipos, loading: false }, () => {
                this.setChartData(req);
            });
        }
    }

    componentWillUnmount() {
        this.abortController.abort();
    }

    setChartData = (data) => {
        if(data.total == 0) return;

        let statuses = Object.keys(data.statuses);
        let tipo = Object.keys(data.tipos);
        let recomendacao = Object.keys(data.recomendacao);

        let dataStatus = [];
        let dataTipo = [];
        let dataRecomendacao = [];

        statuses.forEach(status => {
            dataStatus.push({
                name: status,
                y: data.statuses[status] || 0,
                yPercent: Math.round((data.statuses[status] / data.total) * 100),
                color: ChartHelper.getColor(status),
            });
        });

        tipo.forEach(tipo => {
            dataTipo.push({
                name: tipo,
                y: data.tipos[tipo] || 0,
                yPercent: Math.round((data.tipos[tipo] / data.total) * 100),
            });
        });

        recomendacao.forEach(recomendacao => {
            dataRecomendacao.push({
                name: recomendacao,
                y: data.recomendacao[recomendacao] || 0,
                yPercent: Math.round((data.recomendacao[recomendacao] / data.total) * 100),
            });
        });

        this.setState({ dataStatus, dataTipo, dataRecomendacao, status: statuses, tipo, recomendacao });
    }

    componentDidUpdate(prevProps){
        if(prevProps.planos !== this.props.planos) return this.load();
        if(prevProps.filter !== this.props.filter) return this.load();
    }

    updateGraficos() {
        var statusIsFiltered = this.props.filter?.view == "planos" && this.props.filter?.values && this.props.filter?.values.length == 1 && this.props.filter?.values.every(v => v.field == "status");
        var tipoIsFiltered = this.props.filter?.view == "planos" && this.props.filter?.values && this.props.filter?.values.length == 1 && this.props.filter?.values.every(v => v.field == "tipo");
        var recomendacaoIsFiltered = this.props.filter?.view == "planos" && this.props.filter?.values && this.props.filter?.values.length == 1 && this.props.filter?.values.every(v => v.field == "recomendacao");

        if(!statusIsFiltered && this.chartRefStatus?.current) {
            this.chartRefStatus.current.chart.series[0].points.forEach(point => point.setVisible(true));
        } 
        if(!tipoIsFiltered && this.chartRefTipo?.current) {
            this.chartRefTipo.current.chart.series[0].points.forEach(point => point.setVisible(true));
        }
        if(!recomendacaoIsFiltered && this.chartRefRecomendacao?.current) {
            this.chartRefRecomendacao.current.chart.series[0].points.forEach(point => point.setVisible(true));
        }
    }

    renderPartialLoading = (counter = 'status') => {
        if(this.state.loading) {
            return <div style={{ marginRight: 10 }}><DefaultLoader size={14} color={Colors.homePage.grey} /></div>;
        }
    }

    renderGraficoPlanosStatus = () => {
        var width = this.props.isSmallScreen ? '100%' : 'unset';
        var height = this.props.isSmallScreen ? 300 : 200;

        let that = this;
        return (
            <div
                style={{ display: 'flex', flexDirection: "column", width: width, justifyContent: 'center', flex: 1, maxWidth: 500, minWidth: 300, minHeight: height}}
            >
                <span style={{...EssentialStyle.rowFlexCenter, fontSize: 18, fontWeight: 500, color: Colors.homePage.grey, textAlign: "center"}}>{this.renderPartialLoading('status')}Status</span>
                <HighchartsReact
                    ref={this.chartRefStatus}
                    highcharts={Highcharts}
                    constructorType={'chart'}
                    containerProps={{ style: { height: height, width: "100%", minHeight: height } }}
                    options={{
                        chart: {
                            type: 'variablepie',
                            backgroundColor: 'transparent',
                            margin: [`${this.props.isSmallScreen ? 10 : 30}`, null, null, null]
                        },
                        title: {
                            text: "",
                        },
                        tooltip: {
                            borderWidth: 0,
                            backgroundColor: 'none',
                            shadow: false,
                            pointFormat: '<span style="font-size: 2em; text-align: center; color: {point.color}; font-weight: bold">{point.yPercent}%</span>',
                            positioner: function (labelWidth) {
                                return {
                                    x: 0,
                                    y: `${that.state.isSmallScreen ? 0 : (this.chart.plotHeight-10)}`
                                };
                            }
                        },
                        legend: {
                            align: `${this.props.isSmallScreen ? "center" : "right"}`,
                            verticalAlign: `${this.props.isSmallScreen ? "bottom" : "middle"}`,
                            layout: 'vertical',
                            floating: false,
                            enabled: true,
                            labelFormatter: function () {
                                return this.name + ': ' + this.y;
                            },
                        },
                        plotOptions: {
                            variablepie: {
                                dataLabels: {
                                    enabled: false,
                                },
                                point: {
                                    events: {
                                         legendItemClick: function (e) {
                                            e.preventDefault();
                                            that.debouncedLegendItemClick(that, this, "status");
                                        },
                                        click: function (e) {
                                            that.debouncedLegendItemClick(that, this, "status");
                                        }
                                    }
                                },
                            }
                        },
                        series: [{
                            minPointSize: 30,
                            showInLegend: true,
                            innerSize: '65%',
                            borderRadius: 1,
                            data: this.state.dataStatus,
                        }],
                        credits: {
                            enabled: false
                        },
                    }}
                />
            </div>
        );
    }

    renderPlanosCounter = () => {
        var isNotFiltered = this.props.filter?.view == "planos" && (!this.props.filter?.values || this.props.filter?.values.length == 0);
        return (
            <div style={{...EssentialStyle.columnCenter, width: 250, height: 200, color: Colors.homePage.grey , filter: isNotFiltered && "brightness(0.8)", gap: 5, cursor: "pointer"}} onClick={this.clearFilters}>
                <div style={{fontSize: 100, fontWeight: 500, width: "100%", textAlign: "center", lineHeight: "100%"}}>
                    {this.state.totalCounter || '--'}
                </div>
                <div style={{fontSize: 26, fontWeight: 500, width: "100%", textAlign: "center", lineHeight: "100%"}}>
                    Planos de Ação
                </div>
            </div>
        )
    }

    clearFilters = () => {
        this.chartRefStatus.current.chart.series[0].points.forEach(point => point.setVisible(true));
        this.chartRefTipo.current.chart.series[0].points.forEach(point => point.setVisible(true));
        this.chartRefRecomendacao.current.chart.series[0].points.forEach(point => point.setVisible(true));

        this.setState({ statusFilter: [], tipoFilter: [] }, () => {
            this.props.setFilterCallback({ 
                ...this.props.filter, 
                status: this.state.statusFilter, 
                tipoPlano: this.state.tipoFilter, 
                recomendacao: this.state.recomendacaoFilter,
                loading: true 
            });
            this.updateCounters();
        });
    }

    getFieldStateKey = (fieldType) => {
        if(fieldType == "status") return "statusFilter";
        if(fieldType == "tipo") return "tipoFilter";
        if(fieldType == "recomendacao") return "recomendacaoFilter";
    }

    legendItemClick(componentContext, context, fieldType) {
        if(this.state.loading) return;

        let stateKey = this.getFieldStateKey(fieldType);        
        let isFiltered = this.state[stateKey].includes(context.name);
        let stateToSet = [];

        if(isFiltered) {
            stateToSet = this.state[stateKey].filter(v => v != context.name);
            context?.series?.points.forEach(point => {
                stateToSet?.length > 0 ? (stateToSet.includes(point.name) ? point.setVisible(true) : point.setVisible(false)) : point.setVisible(true);
            });
        } else {
            stateToSet = [...this.state[stateKey], context.name];
            if(stateToSet.length == context?.series?.points.length) stateToSet = [];
            context?.series?.points.forEach(point => {
                stateToSet?.length > 0 ? (stateToSet.includes(point.name) ? point.setVisible(true) : point.setVisible(false)) : point.setVisible(true);
            });
        }

        this.setState({ [stateKey]: stateToSet }, () => {
            this.props.setFilterCallback({ 
                ...this.props.filter, 
                status: this.state.statusFilter, 
                tipoPlano: this.state.tipoFilter,
                recomendacao: this.state.recomendacaoFilter,
            });
            this.updateCounters(fieldType);
        });
    }

    renderGraficoRecomendacao = () => {
        if(!this.props.id_periodo_avaliacao) return null;

        var width = this.props.isSmallScreen ? '100%' : 'unset';
        var height = this.props.isSmallScreen ? 300 : 200;

        let that = this;
        return (
            <div
                style={{ display: 'flex', flexDirection: "column", width: width, justifyContent: 'center', flex: 1, maxWidth: 500, minWidth: 300, minHeight: height}}
            >
                <span style={{...EssentialStyle.rowFlexCenter, fontSize: 18, fontWeight: 500, color: Colors.homePage.grey, textAlign: "center"}}>{this.renderPartialLoading('recomendacao')}Recomendações</span>
                <HighchartsReact
                    ref={this.chartRefRecomendacao}
                    highcharts={Highcharts}
                    constructorType={'chart'}
                    containerProps={{ style: { height: height, width: "100%", minHeight: height } }}
                    options={{
                        chart: {
                            type: 'variablepie',
                            backgroundColor: 'transparent',
                            margin: [`${this.props.isSmallScreen ? 10 : 30}`, null, null, null]
                        },
                        title: {
                            text: "",
                        },
                        tooltip: {
                            borderWidth: 0,
                            backgroundColor: 'none',
                            shadow: false,
                            pointFormat: '<span style="font-size: 2em; text-align: center; color: {point.color}; font-weight: bold">{point.yPercent}%</span>',
                            positioner: function (labelWidth) {
                                return {
                                    x: 0,
                                    y: `${that.state.isSmallScreen ? 0 : (this.chart.plotHeight-10)}`
                                };
                            }
                        },
                        legend: {
                            align: `${this.props.isSmallScreen ? "center" : "right"}`,
                            verticalAlign: `${this.props.isSmallScreen ? "bottom" : "middle"}`,
                            layout: 'vertical',
                            floating: false,
                            enabled: true,
                            labelFormatter: function () {
                                return this.name + ': ' + this.y;
                            }
                        },
                        plotOptions: {
                            variablepie: {
                                dataLabels: {
                                    enabled: false,
                                },
                                point: {
                                    events: {
                                        legendItemClick: function (e) {
                                            e.preventDefault();
                                            that.debouncedLegendItemClick(that, this, "recomendacao");
                                        },
                                        click: function (e) {
                                            that.debouncedLegendItemClick(that, this, "recomendacao");
                                        }
                                    }
                                }
                            }
                        },
                        series: [{
                            minPointSize: 30,
                            showInLegend: true,
                            innerSize: '65%',
                            borderRadius: 1,
                            data: this.state.dataRecomendacao
                        }],
                        credits: {
                            enabled: false
                        },
                    }}
                />
            </div>
        );
    }

    renderGraficoPlanosTipo = () => {
        if(this.props.id_periodo_avaliacao) return null;

        var width = this.props.isSmallScreen ? '100%' : 'unset';
        var height = this.props.isSmallScreen ? 300 : 200;

        let that = this;
        return (
            <div
                style={{ display: 'flex', flexDirection: "column", width: width, justifyContent: 'center', flex: 1, maxWidth: 500, minWidth: 300, minHeight: height}}
            >
                <span style={{...EssentialStyle.rowFlexCenter, fontSize: 18, fontWeight: 500, color: Colors.homePage.grey, textAlign: "center"}}>{this.renderPartialLoading('tipo')}Tipos</span>
                <HighchartsReact
                    ref={this.chartRefTipo}
                    highcharts={Highcharts}
                    constructorType={'chart'}
                    containerProps={{ style: { height: height, width: "100%", minHeight: height } }}
                    options={{
                        chart: {
                            type: 'variablepie',
                            backgroundColor: 'transparent',
                            margin: [`${this.props.isSmallScreen ? 10 : 30}`, null, null, null]
                        },
                        title: {
                            text: "",
                        },
                        tooltip: {
                            borderWidth: 0,
                            backgroundColor: 'none',
                            shadow: false,
                            pointFormat: '<span style="font-size: 2em; text-align: center; color: {point.color}; font-weight: bold">{point.yPercent}%</span>',
                            positioner: function (labelWidth) {
                                return {
                                    x: 0,
                                    y: `${that.state.isSmallScreen ? 0 : (this.chart.plotHeight-10)}`
                                };
                            }
                        },
                        legend: {
                            align: `${this.props.isSmallScreen ? "center" : "right"}`,
                            verticalAlign: `${this.props.isSmallScreen ? "bottom" : "middle"}`,
                            layout: 'vertical',
                            floating: false,
                            enabled: true,
                            labelFormatter: function () {
                                return this.name + ': ' + this.y;
                            }
                        },
                        plotOptions: {
                            variablepie: {
                                dataLabels: {
                                    enabled: false,
                                },
                                point: {
                                    events: {
                                        legendItemClick: function (e) {
                                            e.preventDefault();
                                            that.debouncedLegendItemClick(that, this, "tipo");
                                        },
                                        click: function (e) {
                                            that.debouncedLegendItemClick(that, this, "tipo");
                                        }
                                    }
                                }
                            }
                        },
                        series: [{
                            minPointSize: 30,
                            showInLegend: true,
                            innerSize: '65%',
                            borderRadius: 1,
                            data: this.state.dataTipo
                        }],
                        credits: {
                            enabled: false
                        },
                    }}
                />
            </div>
        );
    }

    renderStatus = () => {
        return (
            <div className="pa-status-component" style={{ ...EssentialStyle.columnCenter, width: '100%', backgroundColor: SessionHelper.getBackgroundColor(), paddingTop: "3px", position: 'sticky', left: 0, marginTop: 10}}>
                <div style={{...EssentialStyle.columnCenter, minHeight: 35, boxShadow: `0px 1px 10px 0px  rgba(50, 50, 50, 0.1)`, borderRadius: 8, backgroundColor: Colors.white, width: '100%', overflow: "hidden"}}>

                    <div style={{ ...EssentialStyle.rowFlexCenter, cursor: 'pointer', minHeight: 35 }} onClick={() => this.setState({expanded: !this.state.expanded})} >
                        {this.state.expanded ?
                            <FontAwesomeIcon icon={faChevronUp} style={{ marginRight: 8 }} /> :
                            <FontAwesomeIcon icon={faChevronDown} style={{ marginRight: 8 }} />
                        }
                        {
                            this.state.expanded ? 'Ocultar Status' : 'Status'
                        }
                    </div>

                    <Collapse in={this.state.expanded} style={{ width: '100%', transition: "all 0.2s ease" }}>
                        <div style={{...(this.props.isSmallScreen ? EssentialStyle.columnCenter : EssentialStyle.rowFlexCenter), width: '100%'}}>
                            <div style={{...(this.props.isSmallScreen ? EssentialStyle.columnCenter : EssentialStyle.rowSpaceAround), width: '100%', padding: 10, paddingTop: 0, gap: 15, padding: this.props.isSmallScreen ? 5 : "10px 20px", flexWrap: "wrap"}}>
                                {this.renderGraficoPlanosStatus()}
                                {this.renderPlanosCounter()}
                                {this.renderGraficoPlanosTipo()}
                                {this.renderGraficoRecomendacao()}
                            </div>
                        </div>
                    </Collapse>
                </div>
            </div>
        )
    }

    render() {
        return (
            this.renderStatus()
        )
    }
}